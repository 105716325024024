<template>
  <div>
    <input
      type="text"
      v-model="displayPrice"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
    />
    <span style="color: red; font-size: 11px">{{
      errMessage || errorMessage
    }}</span>
  </div>
</template>
<script>
export default {
  props: {
    price: [Number, String],
    errorMessage: String,
  },
  mounted() {
    this.internalValue = this.price;
  },
  data() {
    return {
      isInputActive: false,
      errMessage: null,
      internalValue: 0,
    };
  },
  methods: {
    setInternalValueIsNull() {
      this.internalValue = null;
    },
  },
  computed: {
    displayPrice: {
      get() {
        var _sefl = this;
        if (this.isInputActive) {
          if (this.internalValue == 0) return this.setInternalValueIsNull();
          return this.internalValue.toString();
        } else {
          _sefl.errMessage = null;
          return (
            '$ ' +
            this.internalValue
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
          );
        }
      },
      set(modifiedPrice) {
        if (!modifiedPrice) {
          this.errMessage = 'This field is required';
        } else if (!/^[0-9|.]+$/.test(modifiedPrice)) {
          this.errMessage = 'Amount incorrect format';
        } else {
          this.errMessage = null;
        }
        this.internalValue = modifiedPrice;
      },
    },
  },
  watch: {
    internalValue(modifiedPrice) {
      let newPrice = parseFloat(modifiedPrice);
      if (isNaN(newPrice) || newPrice <= 0) {
        newPrice = 0;
      }
      this.$emit('input', newPrice);
    },
    isInputActive(val) {
      if (!val) {
        this.internalValue = this.price;
      }
    },
  },
};
</script>
