import {
  FirebaseActions,
  firebase,
  auth,
} from '../../../../services/firebase.service';
import _ from 'lodash';

export default tenant => {
  const invoicedReportActions = new FirebaseActions(
    `/system_client/${tenant}/app_report/statistic/invoice`,
    'invoicedReport'
  );

  const paidReportActions = new FirebaseActions(
    `/system_client/${tenant}/app_report/statistic/invoice`,
    'paidReport'
  );

  const unbilledReportActions = new FirebaseActions(
    `/system_client/${tenant}/app_report/statistic/invoice`,
    'unbilledReport'
  );

  const overdueReportActions = new FirebaseActions(
    `/system_client/${tenant}/app_report/statistic/invoice`,
    'overdueReport'
  );

  const unpaidReportActions = new FirebaseActions(
    `/system_client/${tenant}/app_report/statistic/invoice`,
    'unpaidReport'
  );

  const reportTempList = [
    {
      type: 'invoiced',
      name: 'Invoiced',
      title: 'INVOICED',
      description: '',
      amount: 0,
      counter: 0,
      subTitle: '[VALUE] Invoices',
    },
    {
      type: 'paid',
      name: 'Paid',
      title: 'PAID',
      description: '',
      amount: 0,
      counter: 0,
      subTitle: '[VALUE] Invoices',
    },
    {
      type: 'un-billed',
      name: 'Unbilled',
      title: 'UNBILLED',
      description: '',
      amount: 0,
      counter: 0,
      subTitle: '[VALUE] Projects',
    },
    {
      type: 'overdue',
      name: 'Overdue',
      title: 'OVERDUE',
      description: '',
      amount: 0,
      counter: 0,
      subTitle: '[VALUE] Invoices',
    },
    {
      type: 'un-paid',
      name: 'Unpaid',
      title: 'UNPAID',
      description: '',
      amount: 0,
      counter: 0,
      subTitle: '[VALUE] Invoices',
    },
  ];

  const reportFuncList = [
    {
      type: 'invoiced',
      func: 'bindInvoicedReportBy',
    },
    {
      type: 'paid',
      func: 'bindPaidReportBy',
    },
    {
      type: 'un-billed',
      func: 'bindUnbilledReportBy',
    },
    {
      type: 'overdue',
      func: 'bindOverdueReportBy',
    },
    {
      type: 'un-paid',
      func: 'bindUnpaidReportBy',
    },
  ];

  return {
    createInvoiceReportDoc: invoicedReportActions.createDocument,
    async addInvoiceReport(
      { dispatch, getters },
      { type, amountToAdd, counterToAdd }
    ) {
      const report = getters.invoiceReportByType(type);
      if (_.isEmpty(report)) {
        return;
      }

      const amount = report.amount + amountToAdd;
      const counter = report.counter + counterToAdd;

      return await dispatch('createInvoiceReport', {
        data: {
          type,
          amount,
          counter,
        },
        addingOrigin: 'admin-web-app',
      });
    },

    async createInvoiceReport(
      { dispatch },
      { data: { type, amount, counter }, addingOrigin }
    ) {
      const reportTemp = reportTempList.find(item => item.type === type);
      if (_.isEmpty(reportTemp)) {
        return;
      }

      reportTemp.amount = amount || 0;
      reportTemp.counter = counter || 0;
      reportTemp.subTitle = reportTemp.subTitle.replace('[VALUE]', counter);
      if (counter <= 1) {
        reportTemp.subTitle = reportTemp.subTitle.replace('s', ''); // remove 's' if counter = 1
      }

      return await dispatch('createInvoiceReportDoc', {
        ...reportTemp,
        createdAt: firebase.firestore.Timestamp.now(),
        createdBy: auth.currentUser.displayName || auth.currentUser.email,
        createdFrom: addingOrigin || 'unknown',
      });
    },

    bindInvoicedReportBy: invoicedReportActions.bindFirstDocumentBy,
    bindPaidReportBy: paidReportActions.bindFirstDocumentBy,
    bindUnbilledReportBy: unbilledReportActions.bindFirstDocumentBy,
    bindOverdueReportBy: overdueReportActions.bindFirstDocumentBy,
    bindUnpaidReportBy: unpaidReportActions.bindFirstDocumentBy,

    bindReportByType({ dispatch }, type) {
      const funcItem = reportFuncList.find(item => item.type === type);
      if (_.isEmpty(funcItem)) {
        return;
      }

      return dispatch(funcItem.func, {
        prop: 'type',
        op: '==',
        val: funcItem.type,
      });
    },
  };
};
