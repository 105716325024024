import * as types from './types';
import userService from '../../../../services/user.service';

export default {
  getUserList({ commit }) {
    return userService.getUserList().then(users => {
      commit(types.SET_USERS_LIST, users);
      return;
    });
  },

  setUserId({ commit }, id) {
    commit(types.SET_USER_ID, id);
  },

  getUser({ commit }, uid) {
    return userService
      .getUser(uid)
      .then(user => {
        commit(types.SET_USER, user);
      })
      .catch(() => {
        commit(types.SET_USER, null);
      });
  },

  // eslint-disable-next-line no-unused-vars
  async getUserNotCommit({ commit }, uid) {
    return userService.getUser(uid);
  },
};
