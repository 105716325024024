import { FirebaseActions } from '../../services/firebase.service';

export default tenant => {
  const emailTemplateActions = new FirebaseActions(
    `/system_client/${tenant}/email_template`,
    'emailTemplate'
  );

  const emailNotificationActions = new FirebaseActions(
    `/system_client/${tenant}/email_notification`,
    'emailNotification'
  );

  return {
    getEmailTemplateBys: emailTemplateActions.getDocumentBys,
    createEmailNotification: emailNotificationActions.createDocument,

    getEmailTemplateByTemplateName({ dispatch }, template) {
      return dispatch('getEmailTemplateBys', [
        {
          prop: 'template',
          op: '==',
          val: template,
        },
      ]).then(list => {
        return list[0] || {};
      });
    },
  };
};
