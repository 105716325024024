<template>
  <f7-popup
    :opened="openedPopup"
    @popup:closed="closePopup"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Generate Purchase Order</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="handleClickGenerate()">Next</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-block>
        <!-- <p class="text-color-orange">
          Based on your input information, you will not pay tax on Purchase (*)
        </p> -->
        <p v-if="isBuildYourOwn">
          <a @click="isBuildYourOwn = !isBuildYourOwn">
            Create Purchase Order from Estimate
          </a>
          or Create your own Purchase Order
        </p>
        <p v-else>
          Create Purchase Order from Estimate or
          <a @click="isBuildYourOwn = !isBuildYourOwn"
            >Create your own Purchase Order</a
          >
        </p>
      </f7-block>

      <div v-if="isBuildYourOwn">
        <f7-list class="estimate-list">
          <f7-list-input
            type="select"
            label="Roof Type"
            :value="roofType"
            @change="onRoofTypeChanged($event.target.value)"
            error-message-force
            validate
            validate-on-blur
            :error-message="roofTypeErrorMessage"
          >
            <required-asterisk slot="label"></required-asterisk>
            <input-icon
              slot="media"
              icon="selection_pin_in_out"
            ></input-icon>
            <option
              value=""
              hidden
            >
              Select roof type
            </option>
            <option
              v-for="item in roofTypes"
              :key="item.id"
              :value="item.value"
            >
              {{ item.displayName }}
            </option>
          </f7-list-input>
        </f7-list>

        <f7-block-title v-if="filteredVendors.length > 0"
          >Vendors</f7-block-title
        >

        <f7-list class="estimate-list">
          <f7-list-item
            v-for="(item, index) in filteredVendors"
            :key="index"
            :title="item.name"
            :text-color="
              selectedVendors.some(r => r === item.id) ? 'primary' : 'default'
            "
            radio
            name="vendor-list"
            @change="v => updateSelectedVendors(item, v)"
          />
        </f7-list>
      </div>

      <div v-else>
        <f7-block-title>Estimate</f7-block-title>
        <f7-list
          class="estimate-list"
          media-list
        >
          <f7-list-item
            v-for="estimate in estimateList"
            :key="estimate.id"
            radio
            radio-icon="start"
            name="estimate-list"
            :value="estimate.id"
            :title="estimate.estimateNumber"
            :checked="estimate.id === (selectedEstimate || {}).id"
            @change="() => updateSelectedEstimate(estimate)"
            ><f7-chip
              slot="after"
              :text="estimateStatusBy(estimate.status).displayName"
              :color="estimateStatusBy(estimate.status).color"
            ></f7-chip
          ></f7-list-item>
        </f7-list>

        <f7-block-title v-if="selectedEstimate && selectedEstimate.buildings"
          >Specification #</f7-block-title
        >
        <f7-list
          class="building-list"
          media-list
        >
          <f7-list-item
            v-for="building in selectedEstimate
              ? selectedEstimate.buildings
              : []"
            :key="building.buildingId"
            name="building-list"
            checkbox
            :title="building.buildingName"
            :checked="(selectedBuildings || []).includes(building.buildingId)"
            @change="
              $event =>
                updateSelectedBuilding({
                  checked: $event.target.checked,
                  buildingId: building.buildingId,
                })
            "
          ></f7-list-item>
        </f7-list>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import {
  STATUS_ESTIMATE_ACTIVE,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';

export default {
  components: { InputIcon },

  props: {
    show: { type: Boolean, default: false },
  },

  data() {
    return {
      openedPopup: false,
      selectedEstimate: {},
      selectedBuildings: [],
      selectedVendors: [],

      isBuildYourOwn: false,
      actionId: '',
      projectId: '',

      STATUS_ESTIMATE_ACTIVE,

      roofType: '',
      productAllList: [],
    };
  },

  computed: {
    ...mapGetters('purchase-order/purchase-order-details-page/estimate', [
      'estimateList',
    ]),
    ...mapGetters('purchase-order/purchase-order-details-page/product-item', [
      'productItemList',
    ]),

    ...mapGetters('purchase-order/app-constant', [
      'roofTypeList',
      'estimateStatusBy',
    ]),
    ...mapGetters('purchase-order/purchase-order-details-page/price-list', [
      'priceListItem',
      'priceListItemList',
    ]),
    ...mapGetters('purchase-order/purchase-order-details-page/vendor', [
      'vendorList',
    ]),

    roofTypes() {
      return this.roofTypeList
        .filter(type => {
          return type.workSteps && type.workSteps.commercial;
        })
        .sort((a, b) => {
          return a.displayName < b.displayName ? -1 : 0;
        });
    },

    /**
     * validate and return true if can create purchase order
     */
    allowCreate() {
      return (
        !_.isEmpty(this.selectedEstimate) ||
        (this.isBuildYourOwn && this.selectedVendors.length === 1)
      );
    },
    /**
     * filteredVendors
     */
    filteredVendors() {
      const vendorIds = [...new Set(this.productAllList.map(r => r.vendorId))];
      return this.vendorList
        .filter(m => vendorIds.includes(m.id))
        .map(m => ({
          id: m.id,
          name: m.companyName,
        }));
    },

    roofTypeErrorMessage() {
      if (!this.v$.roofType.$error) return '';
      if (this.v$.roofType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    actualQty() {
      return (product, numberOfCopy) => {
        const actualQty = (product.actualQty || 0) * numberOfCopy;
        return actualQty;
      };
    },
    orderQty() {
      return (product, numberOfCopy) => {
        const roundUpNumber = numberOfFixedRounds => value => {
          value = Math.round(value * 1000) / 1000;
          const denominator = Math.pow(10, numberOfFixedRounds);
          const temp = parseFloat((value * denominator).toFixed(10)); //Remove zero after device
          return Math.ceil(temp) / denominator;
        };
        const productSize = (product.unitSize || 1) * (product.unitPack || 1);
        const actualQty = product.actualQty * numberOfCopy;
        const orderQty = actualQty / productSize;
        return roundUpNumber(0)(orderQty);
      };
    },
  },

  methods: {
    ...mapActions('purchase-order/purchase-order-details-page/estimate', [
      'bindEstimateListBys',
      'getBuilding',
      'updateEstimate',
    ]),

    ...mapActions('purchase-order/purchase-order-details-page/product-item', [
      'bindProductItemListBys',
      'unbindProductItemList',
    ]),
    ...mapActions('purchase-order/purchase-order-details-page/vendor', [
      'bindVendorList',
      // "bindPropertyList"
    ]),
    ...mapActions('purchase-order/purchase-order-details-page/price-list', [
      'bindActivePriceList',
      'bindPriceListItem',
      'unbindPriceListItemList',
      'unbindPriceListItem',
    ]),

    /**
     * updateSelectedEstimate
     */
    updateSelectedEstimate(estimate) {
      this.selectedBuildings = [];
      const self = this;
      if (estimate.status != 'active') {
        this.$ri.dialog.openInfoDialog({
          title: 'Generate Purchase Order',
          content: 'Do you want to update Estimate status to Active',
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
              this.selectedEstimate = null;
              return;
            } else if (index === 1) {
              self
                .updateEstimate({
                  id: estimate.id,
                  doc: { status: 'active' },
                })
                .then(() => {
                  self.selectedEstimate = estimate;
                });

              return;
            }
          },
        });
      } else {
        this.selectedEstimate = estimate;
      }
    },

    updateSelectedBuilding({ checked, buildingId }) {
      if (checked === true) {
        this.selectedBuildings.push(buildingId);
      }

      if (checked === false) {
        const index = this.selectedBuildings.findIndex(
          item => item === buildingId
        );

        this.selectedBuildings.splice(index, 1);
      }
    },

    initData() {
      this.bindEstimateListBys([
        {
          prop: 'projectId',
          val: this.projectId,
          op: '==',
        },
      ]).then(() => {
        const estActive = this.estimateList.filter(r => r.status === 'active');
        if (estActive.length === 1) {
          this.updateSelectedEstimate(estActive[0]);
        }
      });

      // if (_.isEmpty(this.productItemList)) {
      // TODO: double check to improve performance
      // this.bindProductItems();
      // }

      if (_.isEmpty(this.vendorList)) {
        this.bindVendorList();
      }

      // if (_.isEmpty(this.propertyList)) {
      //   this.bindPropertyList();
      // }
    },

    /**
     * openPopup
     */
    openPopup({ projectId, actionId }) {
      this.openedPopup = true;
      this.actionId = actionId;
      this.projectId = projectId;
      this.unbindProductItemList();
      this.initData();
    },

    /**
     * updateSelectedVendors
     */
    // updateSelectedVendors(vendor, event) {
    //   if (event.target.checked) {
    //     this.selectedVendors.push(vendor.id);
    //   } else {
    //     this.selectedVendors = this.selectedVendors.filter(
    //       r => r !== vendor.id
    //     );
    //   }
    // },

    updateSelectedVendors(vendor) {
      this.selectedVendors[0] = vendor.id;
    },

    handleClickGenerate() {
      if (this.isBuildYourOwn) {
        this.v$.$touch();
        if (this.v$.$invalid) {
          return;
        }
      }
      if (!this.allowCreate) {
        this.$ri.dialog.openInfoDialog({
          title: 'Generate Purchase Order',
          content: 'Please select at least one estimate/vendor.',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
              return;
            }
          },
        });
      } else {
        this.generatePurchaseOrderItems();
      }
    },

    /**
     * generatePurchaseOrder
     */
    generatePurchaseOrderItems() {
      let data = {};
      const self = this;
      if (this.isBuildYourOwn) {
        // generate from vendor
        data = this.generateItemsFromVendor();
        this.$emit('onCreatePO', {
          isBuildYourOwn: !!self.isBuildYourOwn,
          data,
          productByStatus: [],
        });
      } else {
        // generate from estimate
        this.generateItemsFromEstimates().then(async listPromise => {
          const data = [];
          for (const list of listPromise) {
            data.push(...list);
          }

          if (_.isEmpty(data)) {
            this.$ri.dialog.openInfoDialog({
              title: 'Generate Purchase Order',
              content:
                'The estimate of the building you are choosing has no item to purchase. Please choose another one.',
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  _sefl.app.dialog.close();
                  return;
                }
              },
            });
          }
          const productByStatus = [];
          this.unbindProductItemList();
          for (const po of data) {
            if (po.items[0].vendorId === '') {
              const product = await this.bindProductItemListBys([
                {
                  prop: 'status',
                  val: 'pi-waiting-for-approval',
                  op: '==',
                },
              ]);
              productByStatus.push(...product);
            }
          }
          this.$emit('onCreatePO', {
            isBuildYourOwn: !!self.isBuildYourOwn,
            data,
            productByStatus: productByStatus,
          });
        });
      }
    },

    async generateItemsFromEstimates() {
      const refs = [];
      for (const buildingId of this.selectedBuildings) {
        refs.push(
          this.getBuilding({
            estimateId: this.selectedEstimate.id,
            buildingId: buildingId,
          }).then(building => {
            const productItems = [];
            for (const section of building.productData || []) {
              for (const productItem of section.productList || []) {
                if (productItem.orderQty > 0) {
                  productItems.push({
                    ...productItem,
                    productItemId: productItem.id,
                    orderQty: this.orderQty(
                      productItem,
                      building.numberOfCopy || 1
                    ),
                    actualQty: this.actualQty(
                      productItem,
                      building.numberOfCopy || 1
                    ),
                    estimateId: this.selectedEstimate.id,
                    estimateNumber: this.selectedEstimate.estimateNumber,
                    buildingId: buildingId,
                    buildingName: building.buildingName,

                    propertyId: this.selectedEstimate.propertyId,
                    propertyName: this.selectedEstimate.propertyName || '',

                    roofType: building.roofType || '',
                    priceListId: building.priceListId || '',
                    vendorId: productItem.vendorId || '', // TODO: check
                  });
                }
              }
            }

            return productItems;
          })
        );
      }

      return Promise.all(refs).then(list => {
        const refs = [];
        for (const productItems of list) {
          refs.push(this.createPO(productItems));
        }

        return Promise.all(refs);
      });
    },

    createPO(productItemList) {
      const groupByVendors = productItemList.reduce((a, b) => {
        a[b.vendorId] = a[b.vendorId] || [];
        a[b.vendorId].push(b);
        return a;
      }, {});
      const workOrderTasks = Object.keys(groupByVendors).map((r, index) => ({
        taskIndex: index,
        items: groupByVendors[r],
        vendorId: r,
        isBuildYourOwn: false,
        roofType: groupByVendors[r][0] ? groupByVendors[r][0].roofType : '',
        priceListId: groupByVendors[r][0]
          ? groupByVendors[r][0].priceListId
          : '',
        estimateId: groupByVendors[r][0] ? groupByVendors[r][0].estimateId : '',
        estimateNumber: groupByVendors[r][0]
          ? groupByVendors[r][0].estimateNumber
          : '',
        buildingId: groupByVendors[r][0] ? groupByVendors[r][0].buildingId : '',
        buildingName: groupByVendors[r][0]
          ? groupByVendors[r][0].buildingName
          : '',

        propertyId: groupByVendors[r][0] ? groupByVendors[r][0].propertyId : '',
        propertyName: groupByVendors[r][0]
          ? groupByVendors[r][0].propertyName
          : '',
      }));
      return workOrderTasks;
    },

    generateItemsFromVendor() {
      return this.selectedVendors.map((r, index) => ({
        taskIndex: index,
        items: [],
        vendorId: r,
        isBuildYourOwn: true,
        roofType: this.roofType,
      }));
    },

    /**
     * closePopup
     */
    closePopup() {
      this.openedPopup = false;
      this.selectedEstimate = null;
      this.selectedVendors = [];
      this.selectedBuildings = [];

      this.isBuildYourOwn = false;
      this.actionId = '';
      // this.workOrderId = "";
      this.roofType = '';
      this.v$.$reset();

      this.unbindPriceListItemList();
      this.unbindPriceListItem();
      this.unbindProductItemList();
    },

    async onRoofTypeChanged(value) {
      this.$f7.preloader.show();
      this.roofType = value;
      this.productAllList = [];
      this.unbindProductItemList();
      this.unbindPriceListItemList();
      this.unbindPriceListItem();
      await this.bindActivePriceList(value);
      const product = await this.bindProductItemListBys([
        {
          prop: 'status',
          val: 'pi-active',
          op: '==',
        },
        {
          prop: 'roofTypes',
          val: value,
          op: 'array-contains',
        },
      ]);
      this.productAllList.push(...product);
      this.$f7.preloader.hide();
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {
    roofType: {
      required,
    },
  },

  watch: {
    selectedEstimate: {
      handler(val) {
        if (!_.isEmpty(val) && this.selectedEstimate.buildings.length === 1) {
          this.updateSelectedBuilding({
            checked: true,
            buildingId: this.selectedEstimate.buildings[0].buildingId,
          });
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}

.estimate-list.list ::v-deep .item-after {
  color: var(--f7-list-item-title-text-color);
}
</style>
