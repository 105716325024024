export default {
  projectList: state => {
    let projects = [...state.hits];
    return projects;
  },

  // Fulltext search
  order: state => state.order,
  searchText: state => state.searchText,

  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
  queryFilters: state => state.queryFilters,

  actionList: state => [...state.actionList].sort((a, b) => a.code - b.code),
  actionByCode: state => actionCode => {
    return state.actionList.find(action => action.code === actionCode) || {};
  },
};
