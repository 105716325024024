import * as types from './types';
import Vue from 'vue';

export default {
  setObjectList(state, payload) {
    Vue.set(state, 'objectList', payload);
  },
  setObjectSubscribe(state, payload) {
    Vue.set(state, 'objectSubscribe', payload);
  },
  setDetailId(state, id) {
    state.detailId = id;
  },

  [types.SET_VENDOR_LIST](state, payload) {
    Vue.set(state, 'objectList', payload);
  },
};
