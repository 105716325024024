export default {
  currentBtnType: state => {
    return state.currentBtnType;
  },

  qbExpenseList: state => {
    return state.qbAccounts.filter(
      item =>
        item.AccountType === 'Expense' || item.AccountType === 'Other Expense'
    );
  },

  qbBankAccountList: state => {
    return state.qbAccounts.filter(
      item => item.AccountType === 'Credit Card' || item.AccountType === 'Bank'
    );
  },

  qbVendorList: state => state.qbVendors,

  qbEmployeeList: state => state.qbEmployees,
  qbEmployeeById: state => id => state.qbEmployees.find(item => item.Id === id),

  qbCustomerList: state => state.qbCustomers,

  isShowIntro: state => state.isShowIntro,
};
