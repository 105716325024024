<template>
  <div class="image-container">
    <div class="image-part">
      <img
        class="image"
        :src="photo.photoUrl"
      />
    </div>
    <div class="overlay">
      <a
        @click="onView"
        class="btn-image"
        ><i class="icon f7-icons">eye</i></a
      >
      <a
        @click="onDelete"
        class="btn-image"
        ><i class="icon f7-icons">trash</i></a
      >
      <a
        @click="openSelectPhoto"
        class="btn-image"
        ><i class="icon f7-icons">pencil</i></a
      >
    </div>
    <div class="notes-container">
      <f7-input
        class="input-notes"
        label="Notes"
        placeholder="Enter Notes"
        :value="photo.notes"
        @change="onChangePhotoProp('notes', $event.target.value)"
        rows="2"
        type="textarea"
      >
      </f7-input>
    </div>
    <select-project-photo-popup
      ref="selectPhoto"
      :showProgressBar="true"
      :checkShowItem="checkShowItem"
    ></select-project-photo-popup>
    <photo-browser
      :photos="photos"
      theme="dark"
      ref="pageDark"
    ></photo-browser>
  </div>
</template>

<script>
import SelectProjectPhotoPopup from '../popup/SelectProjectPhotoPopup.vue';
import PhotoBrowser from '@/components/photo/PhotoBrowser.vue';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      photos: [],
    };
  },
  props: {
    photo: { type: Object, default: () => {} },
    projectId: { type: String, default: '' },
    checkShowItem: { type: Function, default: () => true },
  },
  components: {
    SelectProjectPhotoPopup,
    PhotoBrowser,
  },
  computed: {
    ...mapGetters({
      projectPhotoList: 'proposal/photo/projectPhotoList',
    }),
  },
  methods: {
    ...mapActions({
      bindProjectPhotoListBy: 'proposal/photo/bindProjectPhotoListBy',
    }),
    onDelete() {
      this.$emit('onDelete', this.photo);
    },
    async openSelectPhoto() {
      let photo = await this.$refs.selectPhoto.openPopup(this.projectId);
      this.$emit('onChangePhoto', { oldPhoto: this.photo, newPhoto: photo });
    },
    onChangePhotoProp(prop, value) {
      this.$emit('onChangePhotoProp', prop, value);
    },
    async onView() {
      if (_.isEmpty(this.projectPhotoList)) {
        await this.bindProjectPhotoListBy({
          prop: 'projectId',
          val: this.projectId,
          op: '==',
        });
      }
      this.photos = (this.projectPhotoList || []).filter(
        r => r.id === this.photo.id
      );
      this.$refs.pageDark.open(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  width: 340px;
  height: 250px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ddd;
  flex-direction: column;
  border-radius: 5px;
}
.image-part {
  height: 180px;
  display: flex;
  align-items: flex-end;
}
/* Make the image to responsive */
.image {
  display: block;
  max-width: 340px;
  height: auto;
  max-height: 180px;
}

.btn-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* The overlay effect - lays on top of the container and over the image */
.overlay {
  position: absolute;
  top: 35%;
  left: 20%;
  height: 30%;
  width: 60%;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  font-weight: bold;
  font-size: 20px;
  color: #f1f1f1;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  i {
    font-size: 30px;
  }
}

/* When you mouse over the container, fade in the overlay title */
.image-container:hover .overlay {
  opacity: 1;
}

.btn-image {
  padding: 5px;
  margin: 0 5px;
}
.btn-image:hover {
  i {
    font-weight: bold;
  }
}
.notes-container {
  width: 100%;
  height: 70px;
  .input-notes::v-deep {
    height: 100%;
    textarea {
      height: 100% !important;
      padding: 5px;
    }
  }
}
</style>
