import Vue from 'vue';

export default {
  setProjectPhotoAlbumList(state, payload) {
    Vue.set(state, 'projectPhotoAlbumList', payload);
  },
  setProjectPhotoAlbumSubscribe(state, payload) {
    Vue.set(state, 'projectPhotoAlbumSubscribe', payload);
  },
};
