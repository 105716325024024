// src/store/index.ts
import { initializeTenant } from './actions';

export const safetyHub = (tenant: string) => {
  return {
    safetyStore: initializeTenant(tenant),
  };
};

export default safetyHub;
