import { useSafetyStore } from '@/plugins/safety/store';

const storesDestroy = [useSafetyStore];

export const MODULE_NAME = 'safety';

export const destroyStore = (moduleName: string) => {
  if (moduleName != MODULE_NAME) return;

  storesDestroy.forEach(store => {
    const storeInstance = store();
    if (storeInstance.$dispose) {
      storeInstance.$dispose();
    }
  });
};
