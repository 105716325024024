import { FirebaseActions } from '../../../../services/firebase.service';
import { searchClient } from '../../../../services/search.service';
import * as types from './types';
import _ from 'lodash';
import * as constant from '../../../../utility/const';
import dashboardService from '../../../../services/dashboard.service';
import algoliaService from '../../../../services/algolia.service';

export default tenant => {
  const workOrderTask = new FirebaseActions(
    `/system_client/${tenant}/work_order_task`,
    'purchaseOrder'
  );

  const purchaseOrderIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  const purchaseOrderIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    bindWorkOrderTaskListBys: workOrderTask.bindCollectionBys,
    bindWorkOrderTask: workOrderTask.bindDocument,
    unbindWorkOrderTask: workOrderTask.unbindDocument,

    createWorkOrderTask: workOrderTask.createDocument,
    updateWorkOrderTask: workOrderTask.updateDocument,
    getPOListBys: workOrderTask.getDocumentBys,

    deleteWorkOrderTask({ dispatch }, id) {
      return dispatch('updateWorkOrderTask', {
        id,
        doc: { [constant.PROP_IS_DELETED]: true },
      });
    },

    bindPurchaseOrderList({ dispatch }) {
      return dispatch('bindWorkOrderTaskListBys', [
        {
          prop: 'taskType',
          val: 'purchase-order',
          op: '==',
        },
        {
          prop: 'isProjectArchived',
          val: false,
          op: '==',
        },
        {
          prop: constant.PROP_IS_DELETED,
          op: '!=',
          val: true,
        },
      ]);
    },

    async getPOList({ dispatch }, value) {
      const poList = await dispatch('getPOListBys', [
        {
          prop: 'taskNumber',
          val: value,
          op: '==',
        },
        {
          prop: 'isProjectArchived',
          val: false,
          op: '==',
        },
        {
          prop: constant.PROP_IS_DELETED,
          val: true,
          op: '!=',
        },
      ]);
      return poList;
    },

    bindPurchaseOrder({ dispatch, getters }, { poNumber }) {
      const po = getters.purchaseOrderByNumber(poNumber);

      if (_.isEmpty(po)) {
        return FirebaseActions.getCollectionWithConditions(
          `/system_client/${tenant}/work_order_task`,
          [{ prop: 'taskNumber', op: '==', val: poNumber }]
        ).then(list => {
          if (!_.isEmpty(list)) {
            const firstPO = list[0];
            return dispatch('bindWorkOrderTask', firstPO.id);
          }
        });
      }
      return dispatch('bindWorkOrderTask', po.id);
    },

    bindPurchaseOrderResponse({ commit }, actionId) {
      return FirebaseActions.getCollection(
        `/system_client/${tenant}/operation_dashboard/${actionId}/response`
      ).then(response => {
        commit(types.SET_PO_RESPONSE, response);
        return response;
      });
    },

    /**
     * Paging with FireStore
     */
    bindNextPage: workOrderTask.bindNextPage,
    bindPrevPage: workOrderTask.bindPrevPage,

    bindNextPOPage({ dispatch }, { limit, order }) {
      return dispatch('bindNextPage', {
        limit,
        order,
        andConditions: [
          { prop: 'taskType', val: 'purchase-order', op: '==' },
          { prop: 'isProjectArchived', val: false, op: '==' },
          {
            prop: constant.PROP_IS_DELETED,
            op: '!=',
            val: true,
          },
        ],
      });
    },

    bindPrevPOPage({ dispatch }, { limit, order }) {
      return dispatch('bindPrevPage', {
        limit,
        order,
        andConditions: [
          { prop: 'taskType', val: 'purchase-order', op: '==' },
          { prop: 'isProjectArchived', val: false, op: '==' },
          {
            prop: constant.PROP_IS_DELETED,
            op: '!=',
            val: true,
          },
        ],
      });
    },

    resetPOPage({ commit }) {
      commit(types.RESET_PAGE);
    },

    /**
     * Paging and Search with Algolia
     */
    searchPurchaseOrder(
      { state, commit, getters },
      { attributesToRetrieve, pageNumber }
    ) {
      const index =
        state.order === 'asc' ? purchaseOrderIndexAsc : purchaseOrderIndexDesc;
      const filters = getters.queryFilters;

      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_WORK_ORDER_TASK} AND isProjectArchived:false`,
        attributesToRetrieve:
          constant.WORK_ORDER_TASK_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.WORK_ORDER_TASK_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (pageNumber) {
        requestOptions.page = pageNumber;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    goToPage({ state, commit, getters }, { pageName }) {
      const index =
        state.order === 'asc' ? purchaseOrderIndexAsc : purchaseOrderIndexDesc;
      let pageNumber = 0;
      const filters = getters.queryFilters;
      if (typeof pageName === 'number' && 0 <= pageName < state.nbPages) {
        pageNumber = pageName;
      } else {
        switch (pageName) {
          case 'next':
            pageNumber = state.page + 1;
            break;

          case 'prev':
            pageNumber = state.page - 1;
            break;

          case 'first':
            pageNumber = 0;
            break;

          case 'last':
            pageNumber = state.nbPages - 1;
            break;

          default:
            pageName = 0;
            break;
        }
      }
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: pageNumber,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_WORK_ORDER_TASK} AND isProjectArchived:false`,
        attributesToRetrieve:
          constant.WORK_ORDER_TASK_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.WORK_ORDER_TASK_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMorePO({ state, commit, getters }, { attributesToRetrieve, page }) {
      const index =
        state.order === 'asc' ? purchaseOrderIndexAsc : purchaseOrderIndexDesc;
      const filters = getters.queryFilters;

      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_WORK_ORDER_TASK} AND isProjectArchived:false`,
        attributesToRetrieve:
          constant.WORK_ORDER_TASK_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.WORK_ORDER_TASK_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setPONumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },

    setPOSearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },

    // Allow search desc only
    // setPOSearchOrder({ commit }, payload) {
    //   commit(types.SET_PO_SEARCH_ORDER, payload);
    // },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    async loadCurrentPage({ state, dispatch }) {
      await dispatch('searchPurchaseOrder', {
        query: state.searchText,
        pageNumber: state.page,
      });
      const { hits, nbPages } = state;
      if (!hits.length && nbPages > 0) {
        await dispatch('searchPurchaseOrder', {
          query: state.searchText,
          pageNumber: nbPages - 1,
        });
      }
    },

    async createNewPurchaseOrder(
      // eslint-disable-next-line no-unused-vars
      { dispatch, commit, getters, rootGetters },
      { task: newTask, isGridView = false }
    ) {
      const user = rootGetters['setting/app/profile/user'];
      const doc = await dashboardService.addWOTaskDoc(user.tenantId, newTask);
      if (isGridView) {
        await algoliaService.algoliaUpdateItem({
          tenantId: tenant,
          collection: 'work_order_task',
          id: doc.id,
        });
        await dispatch('loadCurrentPage');
      } else {
        commit(types.PO_LIST_ITEM_ADD, {
          ...newTask,
          id: doc.id,
          taskNumber: doc.docNumber,
          createdAt: doc.createdAt,
        });
      }
      return doc;
    },

    async deletePurchaseOrder(
      { dispatch, commit },
      { id, isGridView = false }
    ) {
      await dispatch('deleteWorkOrderTask', id);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: 'work_order_task',
        id,
      });
      if (isGridView) {
        return dispatch('loadCurrentPage');
      } else {
        return commit(types.PO_LIST_ITEM_REMOVE, id);
      }
    },

    async updatePurchaseOrder({ dispatch, commit }, { id, doc }) {
      if (doc.projectId) {
        doc.isProjectArchived = await dispatch(
          'common/common/getIsProjectArchived',
          doc.projectId,
          { root: true }
        );
      }
      await dispatch('updateWorkOrderTask', { id, doc });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: 'work_order_task',
        id,
      });
      return commit(types.PO_LIST_ITEM_UPDATE, {
        id,
        doc,
      });
    },

    setSelectedStatusFilter({ commit }, payload) {
      commit(types.SET_SELECTED_STATUS_FILTER, payload);
    },
  };
};
