<template>
  <div>
    <data-table
      :headers="tableHeaders"
      :items="data"
      :pageSize="data.length"
      class="wrap-text-table"
    >
      <template v-slot:body="{ item }">
        <td
          :class="
            (item.strong ? 'label-cell label-cell_strong' : 'label-cell') +
            ' ' +
            (item.line ? 'label-cell_line' : '')
          "
        >
          {{ item.title }}
        </td>
        <td
          :class="
            (item.strong
              ? 'numeric-cell numeric-cell_strong'
              : 'numeric-cell') +
            ' ' +
            (item.line ? 'label-cell_line' : '')
          "
        >
          {{ item.actualAmount | currencyUSD }}
        </td>
        <td
          :class="
            (item.strong
              ? 'numeric-cell numeric-cell_strong'
              : 'numeric-cell') +
            ' ' +
            (item.line ? 'label-cell_line' : '')
          "
        >
          {{ item.estimateAmount | currencyUSD }}
        </td>
        <td
          :class="
            (item.strong
              ? 'numeric-cell numeric-cell_strong'
              : 'numeric-cell') +
            ' ' +
            (item.line ? 'label-cell_line' : '')
          "
        >
          <span v-if="item.isPercent">
            {{ item.deltaAmount | percent }}
          </span>
          <span v-else>
            {{ item.deltaAmount | currencyUSD }}
          </span>
        </td>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/datatables';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { DataTable },

  computed: {
    ...mapGetters('swimlane/finance', [
      'totalLaborAmount',
      'totalPurchaseOrderAmount',
      'totalExpenseAmount',
      'estimateMaterialCost',
      'estimateLaborCost',
      'estimateMiscCost',
      'estimateAccountReceivable',
      'estimateList',
      'buildings',
      'invoiceIsPayment',
    ]),
    ...mapGetters({
      detail: 'swimlane/card/detail',
    }),

    tableHeaders() {
      return [
        {
          text: 'Transaction Type',
          align: 'left',
          value: 'transactionType',
        },
        {
          text: 'Actual',
          align: 'right',
          value: 'actualAmount',
        },
        {
          text: 'Estimate',
          align: 'right',
          value: 'estimateAmount',
        },
        {
          text: 'Delta',
          align: 'right',
          value: 'deltaAmount',
        },
      ];
    },

    actualSubTotal() {
      return (
        this.totalLaborAmount +
        this.totalPurchaseOrderAmount +
        this.totalExpenseAmount
      );
    },

    estimateSubTotal() {
      return (
        this.estimateMaterialCost +
        this.estimateLaborCost +
        this.estimateMiscCost
      );
    },

    data() {
      return [
        {
          title: 'Material Cost',
          actualAmount: this.totalPurchaseOrderAmount,
          estimateAmount: this.estimateMaterialCost,
          deltaAmount:
            this.estimateMaterialCost - this.totalPurchaseOrderAmount,
        },
        {
          title: 'Labor Cost',
          actualAmount: this.totalLaborAmount,
          estimateAmount: this.estimateLaborCost,
          deltaAmount: this.estimateLaborCost - this.totalLaborAmount,
        },
        {
          title: 'Misc.',
          actualAmount: this.totalExpenseAmount,
          estimateAmount: this.estimateMiscCost,
          deltaAmount: this.estimateMiscCost - this.totalExpenseAmount,
        },
        {
          title: 'Subtotal Cost',
          actualAmount: this.actualSubTotal,
          estimateAmount: this.estimateSubTotal,
          deltaAmount: this.estimateSubTotal - this.actualSubTotal,
          line: true,
        },
        {
          title: 'Account Receivable',
          actualAmount: this.invoiceIsPayment,
          estimateAmount: this.estimateAccountReceivable,
          deltaAmount: this.estimateAccountReceivable - this.invoiceIsPayment,
        },
        {
          title: 'Profit',
          deltaAmount: this.invoiceIsPayment - this.actualSubTotal,
          strong: true,
          line: true,
        },
        {
          title: 'Delta Percentage',
          deltaAmount:
            (this.estimateAccountReceivable &&
              ((this.estimateAccountReceivable - this.invoiceIsPayment) /
                this.estimateAccountReceivable) *
                100) ||
            0,
          strong: true,
          isPercent: true,
        },
      ];
    },
  },

  methods: {
    ...mapActions('swimlane/finance', [
      'initEstimate',
      'getBuildingsByEstimateId',
      'setBuildings',
    ]),

    initData() {
      this.$f7.preloader.show();
      this.initEstimate(this.detail.id)
        .then(async () => {
          let buildings = [];
          for (let estimate of this.estimateList) {
            let res = await this.getBuildingsByEstimateId(estimate.id);
            res = res.map(r => ({
              ...r,
              estimate,
            }));
            buildings = buildings.concat(res);
          }
          this.setBuildings(buildings.filter(r => r.productData));
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.label-cell {
  &_strong {
    font-weight: 600;
  }

  &_line::before {
    height: 5px;
  }
}

.numeric-cell {
  &_strong {
    font-weight: 600;
  }
  &_line::before {
    height: 5px;
  }
}

.wrap-text-table {
  &::v-deep th {
    font-size: 14px;
    font-weight: bold;
  }

  // &::v-deep .card-content::before {
  //   height: 2px;
  //   content: "";
  //   background-color: var(--f7-table-cell-border-color);
  //   display: block;
  // }
}
</style>
