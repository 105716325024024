<template>
  <div>
    <!-- Name -->
    <f7-block-title v-show="contact && (contact.firstName || contact.lastName)"
      >Contact Name</f7-block-title
    >
    <f7-list
      v-show="contact && (contact.firstName || contact.lastName)"
      simple-list
    >
      <f7-list-item
        :title="
          contact
            ? (contact.firstName ? contact.firstName : '') +
              ' ' +
              (contact.lastName ? contact.lastName : '')
            : ''
        "
      ></f7-list-item>
    </f7-list>

    <!-- Job Position -->
    <f7-block-title v-show="contact && contact.jobPosition"
      >Job Position</f7-block-title
    >
    <f7-list
      v-show="contact && contact.jobPosition"
      simple-list
    >
      <f7-list-item :title="contact ? contact.jobPosition : ''"></f7-list-item>
    </f7-list>

    <!-- Phone -->
    <f7-block-title
      v-show="
        contact ? (contact.phones ? contact.phones.length > 0 : false) : false
      "
      >Phone Number</f7-block-title
    >
    <f7-list>
      <f7-list-item
        v-for="(phone, index) in contact ? contact.phones : []"
        :key="index"
        :header="phone.title"
        :title="phone.value"
      >
        <div slot="after-title">
          <f7-link
            class="external icon-link"
            :href="'tel:' + phone.value"
            icon-f7="phone"
          ></f7-link>
        </div>
      </f7-list-item>
    </f7-list>

    <!-- Email -->
    <f7-block-title
      v-show="
        contact ? (contact.emails ? contact.emails.length > 0 : false) : false
      "
      >Email</f7-block-title
    >
    <f7-list>
      <f7-list-item
        v-for="(email, index) in contact ? contact.emails : []"
        :key="index"
        :header="email.title"
        :title="email.value"
      >
        <div slot="after-title">
          <f7-link
            class="external icon-link"
            :href="'mailto:' + email.value"
            icon-f7="envelope"
          ></f7-link>
        </div>
      </f7-list-item>
    </f7-list>

    <!-- Address -->
    <f7-block-title
      v-show="
        contact
          ? contact.addresses
            ? contact.addresses.length > 0
            : false
          : false
      "
      >Address</f7-block-title
    >
    <f7-list>
      <f7-list-item
        v-for="(address, index) in contact ? contact.addresses : []"
        :key="index"
        :header="address.title"
        :title="getFullAddress(address)"
      ></f7-list-item>
    </f7-list>

    <!-- Company -->
    <f7-block-title v-if="companyRefs && companyRefs.length > 0">{{
      companyRefs.length > 1 ? 'Companies' : 'Company'
    }}</f7-block-title>
    <f7-list media-list>
      <f7-list-item
        v-for="companyRef in companyRefs || []"
        :key="companyRef.id"
        :title="companyRef.companyName"
        :subtitle="
          companyRef.addresses && companyRef.addresses.length > 0
            ? companyRef.addresses[0].value
            : ''
        "
      >
      </f7-list-item>
    </f7-list>

    <!-- Property -->
    <!-- <f7-block-title
      v-if="contactPropertyRefs && contactPropertyRefs.length > 0"
      >{{
        contactPropertyRefs.length > 1 ? "Properties" : "Property"
      }}</f7-block-title
    >
    <f7-list media-list>
      <f7-list-item
        v-for="propertyRef in contactPropertyRefs"
        :key="propertyRef.id"
        :title="propertyRef.propertyName"
        :subtitle="
          propertyRef.addresses && propertyRef.addresses.length > 0
            ? propertyRef.addresses[0].value
            : ''
        "
      >
      </f7-list-item>
    </f7-list> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getFullAddress } from '../../../../../utility/address';

export default {
  computed: {
    ...mapGetters('contact-book/contact', [
      'contact',
      'companyRefs',
      // "propertyRefs"
    ]),
  },
  methods: {
    getFullAddress(address) {
      return getFullAddress(address);
    },
  },
};
</script>

<style></style>
