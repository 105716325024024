<template>
  <div>
    <data-table
      :headers="tableHeaders"
      :items="materialsCosts"
      :pageSize="materialsCosts.length"
      class="wrap-text-table"
    >
      <template slot="card-header">
        <f7-block class="margin-top-half">
          <f7-row>
            <div>
              <strong>Total: </strong>
              <i>{{ totalPurchaseOrderAmount | currencyUSD }}</i>
            </div>
          </f7-row>
        </f7-block>
      </template>

      <!-- Table -->
      <template v-slot:body="{ item }">
        <td class="label-cell">{{ item.taskNumber }}</td>
        <td class="numeric-cell">{{ item.orderAmount | currencyUSD }}</td>
        <td class="numeric-cell">{{ item.actualAmount | currencyUSD }}</td>
        <td>{{ toDisplayDateString(item.orderDate) }}</td>
        <!-- <td>{{ item.invoiceNumber }}</td>
        <td>{{ toDisplayDateString(item.paidDate) }}</td> -->
        <td
          class="label-cell"
          v-html="item.attachmentFiles"
        ></td>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/datatables';
import { mapActions, mapGetters } from 'vuex';
import { toDisplayDateString } from '../../../../utility/datetime';

export default {
  components: { DataTable },

  computed: {
    ...mapGetters({
      detail: 'swimlane/card/detail',
    }),
    ...mapGetters('swimlane/finance', [
      'materialsCosts',
      'totalPurchaseOrderAmount',
    ]),

    tableHeaders() {
      return [
        {
          text: 'PONumber',
          align: 'left',
          value: 'taskNumber',
        },
        {
          text: 'Ordered Amount',
          align: 'right',
          value: 'orderedAmount',
        },
        {
          text: 'Actual Amount',
          align: 'right',
          value: 'actualAmount',
        },
        {
          text: 'Order Date',
          align: 'left',
          value: 'orderDate',
        },
        // {
        //   text: "Invoice#",
        //   align: "left",
        //   value: "invoiceNumber",
        //   width: "10%"
        // },
        // {
        //   text: "Paid Date",
        //   align: "left",
        //   value: "paidDate",
        //   width: "10%"
        // },
        {
          text: 'Attachment',
          align: 'right',
          value: 'attachment',
          width: '20%',
        },
      ];
    },
  },

  methods: {
    ...mapActions('swimlane/finance', ['initPurchaseOrder']),

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initData() {
      this.$f7.preloader.show();
      this.initPurchaseOrder(this.detail.id).finally(() => {
        this.$f7.preloader.hide();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label-cell {
  padding-left: 10px;
}
</style>
