import _ from 'lodash';
import paper from 'paper';
import { evaluate } from 'mathjs';

export default {
  data() {
    return {
      widthMobile: window.screen.width,
      heightMobile: window.screen.height - 70,
      evCache: new Array(),
      prevDiff: -1,
      dark: localStorage.getItem('themeDark') === 'true',
    };
  },

  methods: {
    // description top
    handleDrawDescriptionTop(
      from,
      to,
      content,
      positionContent = 'topCenter',
      height = 50,
      arrowInner = true
    ) {
      const strokeColor = this.dark ? 'white' : 'grey';

      new paper.Path({
        segments: [
          [from.x, from.y - 10],
          [from.x, from.y - height],
        ],
        strokeColor,
      });

      new paper.Path({
        segments: [
          [to.x, to.y - 10],
          [to.x, from.y - height],
        ],
        strokeColor,
      });

      const pointA = new paper.Point(from.x, from.y - height + 10);
      const pointB = new paper.Point(to.x, from.y - height + 10);
      const pointC = new paper.Point(pointA.x - 15, pointA.y);
      const pointD = new paper.Point(pointB.x + 15, pointB.y);

      if (arrowInner) {
        const path = new paper.Path({
          segments: [pointA, pointB],
          strokeColor,
        });
        this.drawArrow(path, pointB, 'right');
        this.drawArrow(path, pointA, 'left');
      } else {
        const pathLeft = new paper.Path({
          segments: [pointA, pointC],
          strokeColor,
        });

        const pathRight = new paper.Path({
          segments: [pointB, pointD],
          strokeColor,
        });
        this.drawArrow(pathLeft, pointA, 'diagonalLeft', pointC);
        this.drawArrow(pathRight, pointB, 'diagonalRight', pointD);
      }
      this.showText(from, to, content, positionContent, height);
    },

    // description bottom
    handleDrawDescriptionBottom(
      from,
      to,
      content,
      positionContent = 'bottomCenter',
      height = 50,
      arrowInner = true
    ) {
      const strokeColor = this.dark ? 'white' : 'grey';
      new paper.Path({
        segments: [
          [from.x, from.y + 10],
          [from.x, from.y + height],
        ],
        strokeColor,
      });

      new paper.Path({
        segments: [
          [to.x, to.y + 10],
          [to.x, from.y + height],
        ],
        strokeColor,
      });

      const pointA = new paper.Point(from.x, from.y + height - 10);
      const pointB = new paper.Point(to.x, from.y + height - 10);
      const pointC = new paper.Point(pointA.x - 15, pointA.y);
      const pointD = new paper.Point(pointB.x + 15, pointB.y);

      if (arrowInner) {
        const path3 = new paper.Path({
          segments: [pointA, pointB],
          strokeColor,
        });

        this.drawArrow(path3, pointB, 'right');
        this.drawArrow(path3, pointA, 'left');
      } else {
        const pathLeft = new paper.Path({
          segments: [pointA, pointC],
          strokeColor,
        });

        const pathRight = new paper.Path({
          segments: [pointB, pointD],
          strokeColor,
        });
        this.drawArrow(pathLeft, pointA, 'diagonalLeft', pointC);
        this.drawArrow(pathRight, pointB, 'diagonalRight', pointD);
      }

      this.showText(from, to, content, positionContent, height);
    },

    // description left
    handleDrawDescriptionLeft(from, to, content, width = 70, hasValue = true) {
      const strokeColor = this.dark ? 'white' : 'grey';
      new paper.Path({
        segments: [
          [from.x - 10, from.y],
          [from.x - width, from.y],
        ],
        strokeColor,
      });

      new paper.Path({
        segments: [
          [to.x - 10, to.y],
          [from.x - width, to.y],
        ],
        strokeColor,
      });

      const pointA = new paper.Point(from.x - width + 10, from.y);
      const pointB = new paper.Point(from.x - width + 10, to.y);

      const path3 = new paper.Path({
        segments: [pointA, pointB],
        strokeColor,
      });

      this.drawArrow(path3, pointA, 'top');
      this.drawArrow(path3, pointB, 'bottom');

      let text = new paper.PointText(
        from.x - width - (hasValue ? 40 : 10),
        from.y + (to.y - from.y) / 2
      );
      text.content = content;
      text.fillColor = this.dark ? 'white' : 'black';
    },

    // description right
    handleDrawDescriptionRight(from, to, content, width = 50) {
      const strokeColor = this.dark ? 'white' : 'grey';
      new paper.Path({
        segments: [
          [from.x + 10, from.y],
          [to.x + width, from.y],
        ],
        strokeColor,
      });

      new paper.Path({
        segments: [
          [to.x + 10, to.y],
          [to.x + width, to.y],
        ],
        strokeColor,
      });

      const pointA = new paper.Point(to.x + width - 10, from.y);
      const pointB = new paper.Point(to.x + width - 10, to.y);

      const path3 = new paper.Path({
        segments: [pointA, pointB],
        strokeColor,
      });

      this.drawArrow(path3, pointA, 'top');
      this.drawArrow(path3, pointB, 'bottom');

      let text = new paper.PointText(
        to.x + width,
        from.y + (to.y - from.y) / 2
      );
      text.content = content;
      text.fillColor = this.dark ? 'white' : 'black';
    },

    // description diagonal
    handleDrawDescriptionDiagonal(
      from,
      to,
      angle,
      radius = 0,
      content,
      positionContent = 'end',
      distance = 50,
      positionDesc = ''
    ) {
      let pointA = new paper.Point(from.x, from.y - radius - 10);
      let pointB = new paper.Point(to.x, to.y - 10);
      let pointC = new paper.Point(from.x, from.y - radius - distance);
      let pointD = new paper.Point(to.x, to.y - distance);
      const strokeColor = this.dark ? 'white' : 'grey';

      let more = 0;

      switch (positionDesc) {
        case 'minus':
          more = -90;
          break;
        case 'positive':
          more = 90;
          break;
        default:
          more = angle > 0 ? 90 : -90;
          break;
      }

      pointA = pointA.rotate(angle + more, from);
      pointB = pointB.rotate(angle + more, to);
      pointC = pointC.rotate(angle + more, from);
      pointD = pointD.rotate(angle + more, to);

      new paper.Path({
        segments: [pointA, pointC],
        strokeColor,
      });

      new paper.Path({
        segments: [pointB, pointD],
        strokeColor,
      });

      const centerPath1 = this.pointOnPathByStart(pointA, pointC, 2);
      const centerPath2 = this.pointOnPathByStart(pointB, pointD, 2);
      const end1 = pointC.clone().rotate(more, centerPath1);
      const end2 = pointD.clone().rotate(-1 * more, centerPath2);

      const path3 = new paper.Path({
        segments: [centerPath1, end1],
        strokeColor,
      });

      const path4 = new paper.Path({
        segments: [centerPath2, end2],
        strokeColor,
      });

      this.drawArrow(path3, centerPath1, 'diagonalLeft', end1);
      this.drawArrow(path4, centerPath2, 'diagonalRight', end2);

      let text = null;
      switch (positionContent) {
        case 'end':
          text = new paper.PointText(end2.x, end2.y + 30);
          break;
        case 'center':
          text = new paper.PointText(
            pointC.x + (pointD.x - pointC.x) / 2,
            pointC.y + (pointD.y - pointC.y) / 2
          );
          break;
      }
      text.content = content;
      text.fillColor = this.dark ? 'white' : 'black';
    },

    // show text
    showText(from, to, content, positionContent, height) {
      let text = null;
      switch (positionContent) {
        case 'topLeft':
          text = new paper.PointText(from.x - 50, from.y - height + 10);
          break;
        case 'topRight':
          text = new paper.PointText(to.x + 10, from.y - height + 10);
          break;
        case 'topCenter':
          text = new paper.PointText(
            from.x + (to.x - from.x) / 2,
            from.y - height
          );
          break;
        case 'bottomLeft':
          text = new paper.PointText(from.x - 50, from.y + height - 10);
          break;
        case 'bottomRight':
          text = new paper.PointText(to.x + 10, from.y + height - 10);
          break;
        case 'bottomCenter':
          text = new paper.PointText(
            from.x + (to.x - from.x) / 2,
            from.y + height + 10
          );
          break;
      }

      text.content = content;
      text.fillColor = this.dark ? 'white' : 'black';
    },

    // draw arrow
    drawArrow(path, end, direction, start = null) {
      let arrowVector = null;
      const strokeColor = this.dark ? 'white' : 'grey';

      switch (direction) {
        case 'top':
          arrowVector = new paper.Point(end.x, end.y - 10);
          break;
        case 'bottom':
          arrowVector = new paper.Point(end.x, end.y + 10);
          break;
        case 'left':
          arrowVector = new paper.Point(end.x - 10, end.y);
          break;
        case 'right':
          arrowVector = new paper.Point(end.x + 10, end.y);
          break;
        case 'diagonalLeft':
          arrowVector = this.pointOutPathByEnd(start, end, 2);
          break;
        case 'diagonalRight':
          arrowVector = this.pointOutPathByEnd(start, end, 2);
          break;
      }
      const arrowVectorR = arrowVector.rotate(150, end).clone();
      const arrowVectorL = arrowVector.rotate(-150, end).clone();
      let vectorItem = new paper.Group([
        path,
        new paper.Path([arrowVectorL, end, arrowVectorR]),
      ]);
      vectorItem.strokeColor = strokeColor;
    },

    // draw arrow arc
    drawArrowArc(end) {
      let arrowVector = new paper.Point(end.x + 10, end.y + 10);
      const arrowVectorR = arrowVector.rotate(150, end).clone();
      const arrowVectorL = arrowVector.rotate(-150, end).clone();
      new paper.Path({
        segments: [arrowVectorR, end, arrowVectorL],
        strokeColor: this.dark ? 'white' : 'grey',
      });
    },

    // draw arc
    drawArc(from, center, to, angle) {
      const through = from.rotate(angle / 2, center);

      let arc = new paper.Path.Arc(from, through, to);
      arc.strokeColor = this.dark ? 'white' : 'grey';

      let text = new paper.PointText(
        angle > 0 ? through.x + 5 : through.x - 25,
        angle > 0 ? through.y + 5 : through.y
      );
      text.content = `${Math.abs(angle)}°`;
      text.fillColor = this.dark ? 'white' : 'black';

      return arc;
    },

    // draw info product
    drawInfoProduct(points, content, directionStart, positiontext) {
      const path = new paper.Path({
        segments: points,
        strokeColor: this.dark ? 'white' : 'grey',
      });
      const start = points[0];
      const end = points[points.length - 1];

      this.drawArrow(path, start, directionStart);

      let text = null;
      switch (positiontext) {
        case 'bottom':
          text = new paper.PointText(end.x, end.y + 20);
          break;
        case 'top':
          text = new paper.PointText(end.x, end.y - 10);
          break;
        case 'right':
          text = new paper.PointText(end.x + 10, end.y);
          break;
        case 'left':
          text = new paper.PointText(end.x - 50, end.y - 10);
          break;
      }

      text.content = content;
      text.fillColor = this.dark ? 'white' : 'black';
    },

    // draw info product for more component
    drawInfoProductForMoreComponent(
      points,
      startPathCenter,
      endPathCenter,
      directionStart,
      directionEnd,
      directionText,
      distance,
      content
    ) {
      const strokeColor = this.dark ? 'white' : 'grey';
      const path = new paper.Path({
        segments: points,
        strokeColor,
      });
      const start = points[0];
      const end = points[points.length - 1];

      this.drawArrow(path, start, directionStart);
      this.drawArrow(path, end, directionEnd);

      const pointCenter = this.pointOnPathByStart(
        startPathCenter,
        endPathCenter,
        2
      );

      let text = null;
      let endText = null;
      switch (directionText) {
        case 'bottom':
          endText = new paper.Point(pointCenter.x, pointCenter.y + distance);
          text = new paper.PointText(endText.x, endText.y + 20);
          break;
        case 'top':
          endText = new paper.Point(pointCenter.x, pointCenter.y - distance);
          text = new paper.PointText(endText.x, endText.y - 10);
          break;
        case 'right':
          endText = new paper.Point(pointCenter.x + distance, pointCenter.y);
          text = new paper.PointText(endText.x + 10, endText.y);
          break;
        case 'left':
          endText = new paper.Point(pointCenter.x - distance, pointCenter.y);
          text = new paper.PointText(endText.x - 50, endText.y - 10);
          break;
      }

      new paper.Path({
        segments: [pointCenter, endText],
        strokeColor,
      });

      text.content = content;
      text.fillColor = this.dark ? 'white' : 'black';
    },

    // point on path by start
    pointOnPathByStart(start, end, part) {
      return new paper.Point(
        start.x + (end.x - start.x) / part,
        start.y + (end.y - start.y) / part
      );
    },

    // point on path by end
    pointOnPathByEnd(start, end, part) {
      return new paper.Point(
        end.x - (end.x - start.x) / part,
        end.y - (end.y - start.y) / part
      );
    },

    // point out path by end
    pointOutPathByEnd(start, end, part) {
      return new paper.Point(
        end.x + (end.x - start.x) / part,
        end.y + (end.y - start.y) / part
      );
    },

    // convert string to expesstion
    convertStringToExpression(string) {
      let parseValue = _.cloneDeep(string || '').replace(/\s/g, '+');
      parseValue = parseValue.replace(/"/g, '');
      let arr = parseValue.split('+');
      arr = arr.map(r => {
        if (r == '') {
          return 0;
        } else if (r.charAt(r.length - 1) == "'") {
          let number = r.replace(/'/g, '');
          return parseFloat(number) * 12; // 1' = 12"
        }
        return r;
      });
      parseValue = arr.join('+');
      return parseValue;
    },

    // convert expesstion to string
    convertExpesstionToString(exp) {
      let val = this.convertStringToExpression(exp);
      val = evaluate(val);
      let result = '';
      if (val >= 12) {
        result = `${Math.floor(val / 12)}'`;
        if (val % 12) {
          result = `${result} ${this.convertDecimalToString(val % 12)}`;
        }
      } else {
        result = this.convertDecimalToString(val);
      }
      return result;
    },

    // convert decimal to string
    convertDecimalToString(decimal) {
      const t = Math.floor(decimal);
      let m = decimal - t;
      let val = '';
      if (m == 0) {
        val = `${decimal}"`;
      } else if (t == 0) {
        val = this.convertDecimalToFraction(m);
      } else {
        val = `${t} ${this.convertDecimalToFraction(m)}"`;
      }
      return val;
    },

    // convert decimal to fraction
    convertDecimalToFraction(decimal) {
      const arr = decimal.toString().split('.');
      if (arr.length > 1) {
        let m = Math.pow(10, arr[1].length);
        const ucln = this.UCLN(decimal * m, m);
        return `${(decimal * m) / ucln}/${m / ucln}`;
      }
      return decimal;
    },

    UCLN(x, y) {
      while (x != y) {
        if (x > y) x = x - y;
        else y = y - x;
      }
      return x;
    },

    // remove project
    removeProject(paperScope) {
      paperScope && paperScope.project.remove();
    },

    // resizable layout in view
    resizableLayer(xBase = this.xBase, yBase = this.yBase) {
      const width = this.scope.project.activeLayer.bounds.width;
      const height = this.scope.project.activeLayer.bounds.height;
      this.scope.view.viewSize = new paper.Size(width + xBase, height + yBase);
    },

    setCursorStyle(value) {
      let canvas = this.$refs[this.assemblyId];
      canvas.style.cursor = value;
    },

    drawAfterZoom(newZoom, viewPosition) {
      const oldZoom = this.scope.view.zoom;
      const beta = oldZoom / newZoom;
      const mpos = viewPosition;
      const ctr = this.scope.view.center;

      const pc = mpos.subtract(ctr);
      const offset = mpos.subtract(pc.multiply(beta)).subtract(ctr);

      this.scope.view.zoom = newZoom;
      this.scope.view.center = this.scope.view.center.add(offset);
      this.scope.view.draw();
    },

    handleZoom(type) {
      let newZoom = this.scope.view.zoom;
      if (type === 'in' && newZoom <= 2) {
        newZoom = this.scope.view.zoom * 1.1;
      } else if (type === 'out' && newZoom >= 0.5) {
        newZoom = this.scope.view.zoom * 0.9;
      }
      const viewPosition = this.scope.view.center;
      this.drawAfterZoom(newZoom, viewPosition);
    },

    // on desktop
    handleMouseWheel(event) {
      let newZoom = this.scope.view.zoom;

      if (event.deltaY < 0 && newZoom <= 2) {
        newZoom = this.scope.view.zoom * 1.05;
      } else if (event.deltaY >= 0 && newZoom >= 0.5) {
        newZoom = this.scope.view.zoom * 0.95;
      }

      const mousePosition = new paper.Point(event.offsetX, event.offsetY);
      const viewPosition = this.scope.view.viewToProject(mousePosition);
      event.preventDefault();
      this.drawAfterZoom(newZoom, viewPosition);
    },

    // on mobile
    handlePointerDown(ev) {
      this.evCache.push(ev);
    },
    handlePointerMove(ev) {
      let newZoom = this.scope.view.zoom;

      // Find this event in the cache and update its record with this event
      for (var i = 0; i < this.evCache.length; i++) {
        if (ev.pointerId == this.evCache[i].pointerId) {
          this.evCache[i] = ev;
          break;
        }
      }
      // If two pointers are down, check for pinch gestures
      if (this.evCache.length == 2) {
        // Calculate the distance between the two pointers
        var curDiff = Math.abs(
          this.evCache[0].clientX - this.evCache[1].clientX
        );

        if (this.prevDiff > 0) {
          if (curDiff > this.prevDiff && newZoom <= 2) {
            newZoom = this.scope.view.zoom * 1.02;
          }
          if (curDiff < this.prevDiff && newZoom >= 0.5) {
            newZoom = this.scope.view.zoom * 0.98;
          }
        }

        // Cache the distance for the next move event
        this.prevDiff = curDiff;

        const mousePosition = new paper.Point(ev.offsetX, ev.offsetY);
        const viewPosition = this.scope.view.viewToProject(mousePosition);
        ev.preventDefault();
        this.drawAfterZoom(newZoom, viewPosition);
      }
    },
    handlePointerUp(ev) {
      // Remove this pointer from the cache and reset the target's
      for (var i = 0; i < this.evCache.length; i++) {
        if (this.evCache[i].pointerId == ev.pointerId) {
          this.evCache.splice(i, 1);
          break;
        }
      }
      // If the number of pointers down is less than two then reset diff tracker
      if (this.evCache.length < 2) {
        this.prevDiff = -1;
      }
    },

    createTool(scope) {
      scope.activate();
      return new paper.Tool();
    },

    handleMouseDown() {
      // in order to access functions in nested tool
      let self = this;

      // create drawing tool
      this.tool = this.createTool(this.scope);

      this.tool.onMouseUp = () => {
        this.setCursorStyle('default');
      };

      this.tool.onMouseDrag = event => {
        if (self.evCache.length != 2) {
          this.setCursorStyle('move');
          var pan_offset = event.point.subtract(event.downPoint);
          self.scope.view.center = self.scope.view.center.subtract(pan_offset);
        }
      };
    },
  },
};
