<template>
  <div class="add-new-button">
    <div class="spacing"></div>
    <f7-button
      slot="title"
      @click="open"
      small
      class="btn-fill-gray display-flex justify-content-center align-items-center"
    >
      <f7-icon
        size="18"
        f7="plus"
        class="margin-right-half"
      ></f7-icon>
      Add new company
    </f7-button>

    <f7-popup
      :opened="popupOpened"
      @popup:closed="close"
    >
      <f7-page v-show="isNew">
        <f7-navbar>
          <f7-nav-left>
            <f7-link @click.native="close">Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>New {{ title }}</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="create">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-list>
          <!-- Company Name -->
          <f7-list-input
            class="first-input"
            type="text"
            placeholder="Company Name"
            clear-button
            :value="currentCompany.companyName"
            @input="set('companyName', $event.target.value.trim())"
            error-message-force
            :error-message="companyNameErrorMessage"
            @blur="v$.$touch()"
          ></f7-list-input>
        </f7-list>

        <template
          v-if="companyTypes.length !== 1 || defaultTypesSelected.length"
        >
          <f7-block-header>Company Types</f7-block-header>
          <f7-list>
            <f7-list-item
              v-for="(type, index) in companyTypeList"
              :key="index"
              checkbox
              :checked="isCheckedCompanyType(type.value)"
              :disabled="isReadOnlyCompanyTypeField(type.value)"
              :title="type.displayName"
              :value="type.value"
              name="company-type-checkbox"
              @change="changeCompanyType($event.target.value)"
            ></f7-list-item>
          </f7-list>
        </template>

        <!-- account number -->
        <f7-list v-show="isVendor">
          <f7-list-input
            type="text"
            label="Account Number"
            placeholder="Your account number with this Vendor"
            clear-button
            :value="currentCompany.accountNumber"
            @input="set('accountNumber', $event.target.value.trim())"
          >
          </f7-list-input>
        </f7-list>

        <!-- tax code -->
        <f7-list v-if="isNeedTaxCode">
          <f7-list-input
            placeholder="Company Tax Code"
            ref="companyTaxCodeInput"
            :value="currentCompany.companyTaxCode"
            @input="set('companyTaxCode', $event.target.value.trim())"
            clear-button
            v-mask="'##-#######'"
            error-message-force
            :error-message="companyTaxCodeErrorMessage"
          ></f7-list-input>
        </f7-list>

        <!-- Phone -->
        <phone-input
          ref="phoneInput"
          isRequired
          :value="currentCompany.phones"
          @input="setPhones($event)"
        ></phone-input>

        <!-- Address -->
        <address-auto-complete-input
          ref="addressInput"
          :value="currentCompany.addresses"
          @input="setAddresses($event)"
        ></address-auto-complete-input>

        <!-- Other -->
        <other-input
          ref="otherInput"
          :value="currentCompany.others"
          @input="setOthers($event)"
        ></other-input>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import PhoneInput from '@/components/inputs/PhoneInput.vue';
import AddressAutoCompleteInput from '@/components/inputs/AddressAutoCompleteInput.vue';
import { mapActions, mapGetters } from 'vuex';
import { helpers, required } from '@vuelidate/validators';
import OtherInput from '@/components/inputs/OtherInput.vue';
import _ from 'lodash';
import { mask } from 'vue-the-mask';
import { useVuelidate } from '@vuelidate/core';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    PhoneInput,
    OtherInput,
    AddressAutoCompleteInput,
  },

  props: {
    title: { type: String, default: 'Company' },
    companyTypes: { type: Array, default: () => [] },
    isNeedTaxCode: { type: Boolean, default: true },
    defaultTypesSelected: { type: Array, default: () => [] },
  },

  directives: { mask },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    const validateEIN = helpers.withParams({ type: 'validateEIN' }, value => {
      if (!value) return true;
      return /^\d{2}-\d{7}$/.test(value);
    });

    return {
      currentCompany: {
        companyName: {
          required,
        },
        companyTaxCode: {
          validateEIN: this.isNeedTaxCode ? validateEIN : true,
        },
      },
    };
  },

  data() {
    return {
      currentCompany: {
        companyName: '',
        companyTypes: [],
      },

      popupOpened: false,

      isNew: false,
    };
  },

  computed: {
    ...mapGetters('common/contact', ['contactList']),

    ...mapGetters('common/company', [
      'companyGroup',
      'hits',
      'nbPages',
      'page',
      'companyTypeList',
    ]),

    isVendor() {
      return (
        this.currentCompany.companyTypes.findIndex(item => {
          return item === 'vendor';
        }) > -1
      );
    },

    companyNameErrorMessage() {
      if (!this.v$.currentCompany.companyName.$error) return null;
      if (this.v$.currentCompany.companyName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    companyTaxCodeErrorMessage() {
      if (!this.v$.currentCompany.companyTaxCode.$error) return null;
      if (this.v$.currentCompany.companyTaxCode.validateEIN.$invalid)
        return VALIDATION_MESSAGE.INVALID_TAX_CODE;
      return null;
    },

    isShowCheckbox() {
      return this.page + 1 === this.nbPages;
    },
  },

  methods: {
    ...mapActions('common/contact', ['getContact']),

    ...mapActions('common/company', [
      'createCompany',
      'resetSearch',
      'getCompanyListBys',
      'getCompanyTypeList',
      'setCompanyTypeList',
    ]),

    set(prop, value) {
      Vue.set(this.currentCompany, prop, value);
    },

    setPhones(val) {
      Vue.set(this.currentCompany, 'phones', val);
    },

    setAddresses(val) {
      Vue.set(this.currentCompany, 'addresses', val);
    },

    setOthers(val) {
      Vue.set(this.currentCompany, 'others', val);
    },

    changeCompanyType(val) {
      if (this.currentCompany.companyTypes.some(item => item === val)) {
        Vue.set(
          this.currentCompany,
          'companyTypes',
          this.currentCompany.companyTypes.filter(item => item != val)
        );
      } else {
        this.currentCompany.companyTypes.push(val);
      }
    },

    handleSelectAllChange() {
      this.selectAllChecked = !this.selectAllChecked;
      this.selectedItems = this.selectAllChecked
        ? this.hits.map(item => ({ ...item, checked: true }))
        : [];
    },

    async open() {
      if (this.companyTypes.length !== 1) {
        await this.getCompanyTypeList(this.companyTypes);
      }
      this.isNew = true;
      this.onlyAddNew = true;
      this.popupOpened = true;

      if (this.defaultTypesSelected.length) {
        Vue.set(
          this.currentCompany,
          'companyTypes',
          _.cloneDeep(this.defaultTypesSelected)
        );
        return;
      }

      Vue.set(
        this.currentCompany,
        'companyTypes',
        this.companyTypes.length === 1 ? this.companyTypes : []
      );
    },

    close() {
      this.popupOpened = false;
      this.isNew = false;
      this.currentCompany = {
        companyName: '',
        companyTypes: [],
      };
      this.onlyAddNew = false;
      this.searchFilters = '';

      this.allowInfinite = true;
      this.hasMoreItems = true;
      this.hasData = true;
      this.v$.$reset();
      this.$refs.phoneInput.resetData();
      this.setCompanyTypeList([]);
    },

    async onAdd() {
      Vue.set(
        this.currentCompany,
        'companyTypes',
        !_.isEmpty(this.companyTypes) ? this.companyTypes : []
      );
      if (this.companyTypes.length !== 1) {
        await this.getCompanyTypeList(this.companyTypes);
      }
      this.isNew = true;
      this.$nextTick(() => {
        if (this.$device.desktop) {
          this.$el.querySelector('.first-input input').focus();
        }
      });
    },

    async create() {
      this.v$.$touch();
      this.$refs.phoneInput.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      this.$refs.otherInput.v$.$touch();
      if (
        this.$refs.phoneInput.v$.$invalid ||
        this.$refs.addressInput.v$.$invalid ||
        this.$refs.otherInput.v$.$invalid ||
        this.v$.$invalid ||
        (await this.existsCompany())
      )
        return;

      this.$f7.preloader.show();
      this.createCompany(this.currentCompany)
        .then(id => {
          this.$f7.preloader.hide();
          id && this.$emit('onCreated', id);
        })
        .finally(() => {
          this.close();
        });
    },

    async existsCompany() {
      const companies = await this.getCompanyListBys([
        {
          prop: 'companyName',
          val: this.currentCompany.companyName,
          op: '==',
        },
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);

      if (!_.isEmpty(companies)) {
        this.$ri.dialog.openWarningDialog({
          title: 'Please choose another one',
          content: 'That file name has already existed!',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      }

      return !_.isEmpty(companies);
    },

    isCheckedCompanyType(type) {
      return this.currentCompany.companyTypes.some(item => item === type);
    },

    isReadOnlyCompanyTypeField(type) {
      return this.defaultTypesSelected.some(item => item === type);
    },
  },
};
</script>

<style lang="scss" scoped>
.item-inner {
  .item-title {
    display: flex !important;
  }

  .add-new-button {
    display: flex;

    .spacing {
      width: 30px;
    }
  }
}

.autocomplete-list-item {
  & > a {
    color: white !important;
  }
}
</style>
