<template>
  <f7-page
    v-if="
      project &&
      !loadingData &&
      (project.roofingChoicesStatus === ROOFING_CHOICE_SENT ||
        project.roofingChoicesStatus === ROOFING_CHOICE_CREATED)
    "
  >
    <f7-navbar>
      <f7-nav-left> </f7-nav-left>
      <f7-nav-title>Roofing Choices</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div>
      <f7-row
        no-gap
        class="bg-wrap"
        :style="`height: ${
          $device.desktop ? 'calc(100vh - var(--f7-navbar-height))' : 'auto'
        }`"
      >
        <f7-col
          width="100"
          large="30"
          class="h-100 scroll-container"
          style="box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1)"
        >
          <div
            class="option-container"
            :class="
              $device.desktop ? 'wrap-content-web' : 'wrap-content-mobile'
            "
          >
            <b
              class="title-detail"
              style="font-size: 20px"
              >Materials Option</b
            >
            <div class="bg-option padding">
              Please choose the 3 materials you like in the preferred order from
              1 to 3 (#2 and #3 are backup options).
              <br />
              Drag and drop the image of materials onto this frame:
            </div>
            <template v-if="$device.desktop">
              <div
                class="wrap-option"
                v-for="(item, index) in choices"
                :key="index"
              >
                <div style="flex: 1">
                  <b>Details</b>
                  <div class="bg-option detail-option">
                    <ul>
                      <li v-if="item.data && item.data.product">
                        {{ item.data.product.productItem }}
                      </li>
                      <li v-if="item.data && item.data.product">
                        {{
                          (item.data.selectedColor &&
                            item.data.selectedColor.colorName) ||
                          'No color name'
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <b class="numerical">{{ item.name }}</b>
                  <div
                    class="border-choice"
                    :id="item.id"
                    @drop="drop($event)"
                    @dragover="allowDrop"
                  ></div>
                </div>
              </div>
            </template>
            <template v-else>
              <f7-list
                accordion-list
                inset
                class="no-margin custom-accodion"
              >
                <f7-list-item accordion-item>
                  <div slot="title">
                    Option Selected: <b>{{ totalChoise }}</b> items
                  </div>
                  <f7-accordion-content>
                    <div class="option-container margin-top">
                      <div
                        class="wrap-option"
                        v-for="(item, index) in choices"
                        :key="index"
                      >
                        <div style="flex: 1">
                          <b>Details</b>
                          <div
                            class="bg-option detail-option detail-option_mobile"
                          >
                            <ul>
                              <li v-if="item.data && item.data.product">
                                {{ item.data.product.productItem }}
                              </li>
                              <li v-if="item.data && item.data.product">
                                {{
                                  (item.data.selectedColor &&
                                    item.data.selectedColor.colorName) ||
                                  'No color name'
                                }}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div>
                          <b class="numerical">{{ item.name }}</b>
                          <div
                            class="border-choice"
                            :id="item.id"
                          >
                            <img
                              v-if="item.data && item.data.selectedColor"
                              class="img-show"
                              :src="
                                item.data &&
                                item.data.selectedColor.photos[0].thumbnailUrl
                              "
                              :alt="
                                item.data &&
                                item.data.selectedColor.photos[0].name
                              "
                            />
                            <img
                              v-else-if="item.data && !item.data.selectedColor"
                              class="img-show"
                              src="../../../assets/img/no_image_default.png"
                              alt="No image available"
                            />
                            <img
                              v-else
                              class="img-show"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </f7-accordion-content>
                </f7-list-item>
              </f7-list>
            </template>
            <div>
              <f7-row>
                <f7-col class="padding-right-half"
                  ><f7-button
                    raise
                    outline
                    @click="reset"
                  >
                    Reset
                  </f7-button></f7-col
                >
                <f7-col class="padding-left-half"
                  ><f7-button
                    raise
                    fill
                    @click="submit"
                  >
                    Confirm
                  </f7-button></f7-col
                >
              </f7-row>
            </div>
          </div>
        </f7-col>
        <f7-col
          width="100"
          large="70"
          class="h-100 scroll-container"
        >
          <div
            :class="
              $device.desktop ? 'wrap-content-web' : 'wrap-content-mobile'
            "
          >
            <div
              v-for="(product, index) in project.roofingChoicesProducts || []"
              :key="index"
            >
              <div
                v-if="
                  product.colorPhotos && (product.colorPhotos || []).length > 0
                "
                style="margin-bottom: 24px"
              >
                <b style="font-size: 20px">
                  {{ product.productItem }}
                </b>
                <div class="img-grid margin-top">
                  <div
                    v-for="(color, index) in product.colorPhotos || []"
                    :key="index"
                  >
                    <div class="img-item">
                      <img
                        class="img-show"
                        :src="(color.photos[0] || {}).thumbnailUrl || ''"
                        :alt="color.colorName"
                        :id="`${product.id}_${index}`"
                        :draggable="$device.desktop ? true : false"
                        @dragstart="
                          drag($event, {
                            imgTagId: `${product.id}_${index}`,
                            product: product,
                            selectedColor: color,
                          })
                        "
                      />
                    </div>
                    <div
                      class="margin-top-half display-flex align-items-center"
                    >
                      <f7-checkbox
                        v-if="!$device.desktop"
                        :ref="`${product.id}_${index}`"
                        class="margin-right-half"
                        :checked="
                          choices
                            .map(
                              r =>
                                r.data &&
                                r.data.selectedColor &&
                                r.data.selectedColor.id
                            )
                            .includes(color.id)
                        "
                        @change="
                          onSelectPhotoChange({
                            imgTagId: `${product.id}_${index}`,
                            product: product,
                            selectedColor: color,
                          })
                        "
                      ></f7-checkbox>
                      <span>{{ color.colorName }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                style="margin-bottom: 24px"
              >
                <b style="font-size: 20px">
                  {{ product.productItem }}
                </b>
                <div class="img-grid margin-top">
                  <div>
                    <div class="img-item">
                      <img
                        class="img-show"
                        src="../../../assets/img/no_image_default.png"
                        alt="No image available"
                        :id="`${product.id}`"
                        :draggable="$device.desktop ? true : false"
                        @dragstart="
                          drag($event, {
                            imgTagId: `${product.id}`,
                            product: product,
                          })
                        "
                      />
                    </div>
                    <div
                      class="margin-top-half display-flex align-items-center"
                    >
                      <f7-checkbox
                        v-if="!$device.desktop"
                        :ref="`${product.id}`"
                        class="margin-right-half"
                        @change="
                          onSelectPhotoChange({
                            imgTagId: `${product.id}`,
                            product: product,
                          })
                        "
                      ></f7-checkbox>
                      <span>No color name</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
  <f7-page
    v-else-if="
      project &&
      !loadingData &&
      project.roofingChoicesStatus === ROOFING_CHOICE_SELECTED
    "
  >
    <f7-navbar>
      <f7-nav-left> </f7-nav-left>
      <f7-nav-title>Roofing Choices</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div>
      <img
        style="width: 100%"
        src="../assets/img/thankyouPage.jpg"
        alt="Thank You"
      />
    </div>
  </f7-page>
  <f7-page v-else-if="loadingData"></f7-page>
  <f7-page v-else>
    <f7-navbar>
      <f7-nav-left> </f7-nav-left>
      <f7-nav-title>Not found Roofing Choice</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-block-title medium
      >The roofing choice could not be found</f7-block-title
    >
    <f7-block strong>
      <p>The roofing choice was discarded or replaced with another option.</p>
    </f7-block>
  </f7-page>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

import {
  ROOFING_CHOICE_SELECTED,
  ROOFING_CHOICE_SENT,
  ROOFING_CHOICE_CREATED,
} from '../../../utility/const';

export default {
  data() {
    return {
      project: {},
      choices: [
        {
          name: '1st Choice',
          id: 'choice_0',
        },
        {
          name: '2nd Choice',
          id: 'choice_1',
        },
        {
          name: '3rd Choice',
          id: 'choice_2',
        },
      ],
      loadingData: true,
    };
  },

  async created() {
    const id = this.$f7route.query.id;

    try {
      this.$f7.preloader.show();
      this.loadingData = true;
      const base_api_url = import.meta.env.VITE_BASE_API;
      const response = await axios.get(`project/${id}`, {
        baseURL: base_api_url,
        withCredentials: true,
        headers: {
          token: this.$f7route.query.token,
        },
      });

      if (response.status === 200 && response.data.success) {
        const urlParams = new URLSearchParams(
          new URL(response.data.data.roofingChoicesWebUrl).search
        );
        const token = urlParams.get('token');
        if (token === this.$f7route.query.token) {
          this.project = response.data.data;
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.message);
    } finally {
      this.loadingData = false;
      this.$f7.preloader.hide();
    }
  },

  computed: {
    ROOFING_CHOICE_SELECTED() {
      return ROOFING_CHOICE_SELECTED;
    },

    ROOFING_CHOICE_CREATED() {
      return ROOFING_CHOICE_CREATED;
    },

    ROOFING_CHOICE_SENT() {
      return ROOFING_CHOICE_SENT;
    },

    totalChoise() {
      return this.choices.filter(item => !_.isEmpty(item.data)).length;
    },
  },

  methods: {
    submit() {
      if (this.totalChoise < 3) {
        //max option of choices
        this.$ri.dialog
          .openWarningDialog({
            title: 'Check again',
            content: 'Please select 3 choices!',
            hideCancelButton: true,
            // eslint-disable-next-line no-unused-vars
            onClick: (_sefl, index) => {
              _sefl.app.dialog.close();
            },
          })
          .open();
      } else {
        this.$ri.dialog
          .openWarningDialog({
            title: 'Do you want to submit?',
            content: 'Make sure that you have checked it before submitting',
            textButton: 'Confirm',

            onClick: async (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                const base_api_url = import.meta.env.VITE_BASE_API;
                await axios.post(
                  `project/update-choises/${this.project.id}`,
                  {
                    roofingChoicesSelected: this.choices,
                    roofingChoicesStatus: ROOFING_CHOICE_SELECTED,
                  },
                  {
                    baseURL: base_api_url,
                    withCredentials: true,
                    headers: {
                      token: this.$f7route.query.token,
                    },
                  }
                );

                _sefl.app.dialog.close();

                this.reset();
              }
            },
          })
          .open();
      }
    },

    reset() {
      this.$f7router.refreshPage();
    },

    allowDrop(event) {
      event.preventDefault();
    },

    drag(event, data) {
      event.dataTransfer.setData('text/plain', JSON.stringify(data));
    },

    drop(event) {
      event.preventDefault();
      var data = JSON.parse(event.dataTransfer.getData('text/plain'));

      const choiceItems = _.cloneDeep(this.choices);
      const choiceItem = choiceItems.find(item => item.id === event.target.id);
      if (choiceItem && _.isEmpty(choiceItem.data)) {
        event.target.appendChild(document.getElementById(data.imgTagId));
        choiceItem.data = data;
        this.choices = choiceItems;
      }
    },
    onSelectPhotoChange(data) {
      let choiceItems = _.cloneDeep(this.choices);
      if (choiceItems.some(r => r.data && r.data.imgTagId === data.imgTagId)) {
        choiceItems = choiceItems.map(r => {
          if (r.data && r.data.imgTagId === data.imgTagId) {
            delete r.data;
          }
          return r;
        });
      } else {
        if (this.totalChoise >= 3) {
          this.$refs[data.imgTagId][0].$refs.inputEl.checked = false;
          //max option of choices
          this.$ri.dialog
            .openWarningDialog({
              title: 'Limited Choices',
              content:
                'You can only choose 3 items. Please try removing and re-selecting another items.',
              hideCancelButton: true,
              onClick: _sefl => {
                _sefl.app.dialog.close();
              },
            })
            .open();
          return;
        }
        const index = choiceItems.findIndex(item => !item.data);
        choiceItems[index].data = data;
      }
      this.choices = choiceItems;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-content-web {
  padding: 40px 32px;
  box-sizing: border-box;
}

.wrap-content-mobile {
  padding: 20px 16px;
  box-sizing: border-box;
}

.option-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.bg-option {
  border-color: #f2f3ff;
  background: #f2f3ff;
  border-radius: 8px;
  padding: 1px;
  box-sizing: border-box;
}

.wrap-option {
  display: flex;
  gap: 24px;
}

.bg-wrap {
  background-color: white;
  color: black;
}

.detail-option {
  min-height: 100px;
  margin-top: 16px;

  &_mobile ul {
    background: none;
    list-style: inside;
    padding: 1rem !important;
  }
}

.border-choice {
  position: relative;
  border: dashed 2px black;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 16px;
  overflow: hidden;
}

.scroll-container {
  overflow-y: auto;
}

.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 20px;
}

.img-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  cursor: pointer;
  background-position: 50% center;
  background: #f2f3ff;
  border-radius: 8px;
  overflow: hidden;
}

.img-show {
  width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  object-fit: contain;
}

.title-detail {
  color: var(--f7-color-blue);
}

.custom-accodion ::v-deep .item-link {
  background: #f2f3ff;
}
</style>
