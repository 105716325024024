import { defineStore } from 'pinia';

export const useSafetyStore = defineStore({
  id: 'safety',
  state: () => ({
    count: 0,
  }),

  actions: {
    increase() {
      this.count++;
      console.log(this.count);
    },
    decrease() {
      this.count--;
      console.log(this.count);
    },
  },
});
