import { mapGetters, mapActions } from 'vuex';
import { firebase, auth } from '../../../services/firebase.service';
import _ from 'lodash';
import axiosService from '../../../services/axios.service';
import { toDisplayDateString } from '../../../utility/datetime';

import {
  // ACTION_CODE_CONSTRUCTION,
  STATUS_PO_RECEIVED,
  STATUS_PO_PARTIAL_RECEIVED,
  STATUS_PO_CANCEL,
  STATUS_PO_REQUEST_CANCEL,
  STATUS_PO_DRAFT,
  STATUS_PO_WAITING,
  ACTION_CODE_PO_ACCEPT_REQUEST_CANCEL,
  ACTION_CODE_PO_PARTIAL_RECEIVED,
  ACTION_CODE_PO_RECEIVED,
  ACTION_CODE_PO_REJECT_REQUEST_CANCEL,
  ACTION_CODE_PO_REQUEST_CANCEL,
  ENTITY_PURCHASE_ORDER,
  COLLECTION_WO_TASK,
  COLLECTION_OPRATION_CARD,
} from '../../../utility/const';

const roundUpNumber = numberOfFixedRounds => value => {
  value = Math.round(value * 1000) / 1000;
  const denominator = Math.pow(10, numberOfFixedRounds);
  const temp = parseFloat((value * denominator).toFixed(10));
  return Math.ceil(temp) / denominator;
};

export default {
  computed: {
    ...mapGetters('dashboard/project', ['card', 'users']),
    ...mapGetters('setting/app/system', ['setting']),
    ...mapGetters('dashboard/work-order', ['purchaseOrder']),

    ...mapGetters('dashboard/group', ['adminGroups']),
    ...mapGetters('common/app-constant', ['tenantId']),

    showNoTaxMessage() {
      return (
        this.card &&
        (this.card.taxExempt === true || this.card.resalesCertificate === true)
      );
    },
  },

  methods: {
    ...mapActions('dashboard/project', ['updateCard']),
    ...mapActions('setting/app/system', ['bindSetting']),
    ...mapActions('dashboard/work-order', ['updatePurchaseOrder']),
    ...mapActions('common/notification', ['createNotificationByType']),
    ...mapActions('dashboard/project', ['bindUserData']),
    ...mapActions('dashboard/group', ['getAdminGroups']),
    ...mapActions('dashboard/project', ['getActionListBys']),

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    async getConstructionActions() {
      return await this.getActionListBys([
        {
          prop: 'isDeleted',
          op: '==',
          val: false,
        },
        {
          prop: 'boards',
          op: 'array-contains',
          val: 'construction',
        },
      ]);
    },

    async purchaseOrderActionIdByCode(code) {
      const constructionActions = await this.getConstructionActions();

      const action = constructionActions.find(action => action.code === code);
      if (!_.isEmpty(action)) {
        return action.id;
      }

      return '';
    },

    async getActionId(status = '') {
      return await this.purchaseOrderActionIdByCode(
        (status => {
          let actionCode = 0;
          switch (status) {
            case STATUS_PO_DRAFT:
              actionCode = 500;
              break;

            case STATUS_PO_WAITING:
            case STATUS_PO_REQUEST_CANCEL:
              actionCode = 510;
              break;

            case STATUS_PO_CANCEL:
              actionCode = 520;
              break;

            case STATUS_PO_RECEIVED:
            case STATUS_PO_PARTIAL_RECEIVED:
              actionCode = 530;
              break;
          }
          return actionCode;
        })(status ? status : this.purchaseOrder.status)
      );
    },

    calculateOrderQty(actualQty, productItem) {
      const productSize = productItem.unitSize * productItem.unitPack;

      return roundUpNumber(0)(actualQty / (productSize || 1));
    },

    async saveNote() {
      const commentAndNote = _.cloneDeep(this.card.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: 'note',
          title: 'Purchase Order Note',
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        });

        return this.updateCard({
          id: this.card.id,
          doc: {
            commentAndNote,
          },
        });
      }
    },

    saveEmailData(emailData) {
      this.purchaseOrderEmails = emailData;
      // this.orderDate = [Date.now()];

      return this.updatePurchaseOrder({
        id: this.currentPurchaseOrder
          ? this.currentPurchaseOrder.id
          : this.purchaseOrder.id,
        doc: {
          purchaseOrderEmails: emailData,
          // orderDate: [Date.now()]
        },
      });
    },

    /**
     * Calculate the respone of PO task
     */
    getResponses(responses, purchaseOrder) {
      return responses.filter(item => {
        return (
          item.taskType === ENTITY_PURCHASE_ORDER &&
          ((purchaseOrder.status === STATUS_PO_WAITING &&
            [
              ACTION_CODE_PO_RECEIVED,
              ACTION_CODE_PO_PARTIAL_RECEIVED,
              ACTION_CODE_PO_REQUEST_CANCEL,
            ].includes(item.code)) ||
            (purchaseOrder.status === STATUS_PO_REQUEST_CANCEL &&
              [
                ACTION_CODE_PO_ACCEPT_REQUEST_CANCEL,
                ACTION_CODE_PO_REJECT_REQUEST_CANCEL,
              ].includes(item.code)) ||
            purchaseOrder.status === STATUS_PO_DRAFT)
        );
      });
    },

    addWOTaskNotification(user, purchaseOrder) {
      const assignee = user.uid;
      this.createNotificationByType({
        data: {
          assignees: [assignee],
          project: {
            title: purchaseOrder.taskNumber,
            id: purchaseOrder.id,
            type: 'task',
            entityName: COLLECTION_WO_TASK,
          },
        },
        type: 'assign-user',
      });
    },

    sendAddWOTaskMail(user, purchaseOrder) {
      const receiver = `${user.displayName || ''} <${user.email}>`;

      return axiosService
        .post('/notification/push-email', {
          to: receiver,
          subject: {
            assigner: auth.currentUser.displayName,
          },
          template: 'assign-wo-task',
          content: {
            assigner: auth.currentUser.displayName,
            task_number: purchaseOrder.taskNumber,
          },
          attachments: [],
          scheduleSendingTime: '',
        })
        .then(() => {
          // TODO: this trigger will be removed when implement email mail service scheduler
          return axiosService.post('/notification/trigger-email-service', {
            sender: '',
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('[Send Email]', error);
          throw new Error(error.message);
        });
    },
    async sendMailHandleProduct(
      productApprove,
      productRemoved,
      template,
      lastPL
    ) {
      let promises = [];
      if (_.isEmpty(this.setting)) {
        promises.push(this.bindSetting(this.tenantId));
      }
      if (_.isEmpty(this.users)) {
        promises.push(this.bindUserData());
      }
      if (_.isEmpty(this.adminGroups)) {
        promises.push(this.getAdminGroups());
      }
      await Promise.all(promises);
      const adminGroupUserIDs = [].concat.apply(
        [],
        (this.adminGroups || []).map(r => r.userIDs)
      );
      const receivers =
        template === 'approve-product'
          ? (
              this.users.filter(
                user =>
                  user.customClaims.role === 'owner' ||
                  adminGroupUserIDs.includes(user.uid)
              ) || []
            ).map(r => r.email)
          : [auth.currentUser.email];
      const sendMailPromises = [];
      for (const receiver of receivers) {
        sendMailPromises.push(
          axiosService
            .post('/notification/push-email', {
              to: receiver,
              subject: {
                price_list_number: lastPL.priceListNumber,
                price_list_name: lastPL.displayName,
              },
              template: template,
              content: {
                project_name: this.project.title || '',
                price_list_number: lastPL.priceListNumber || '',
                price_list_name: lastPL.displayName || '',
                created_by: auth.currentUser.displayName || '',
                product_removed_name:
                  productRemoved
                    .map(
                      (product, index) =>
                        `${index + 1}. ${product.productItem}${
                          product.vendorName
                            ? `. Vendor: ${product.vendorName}`
                            : ''
                        }`
                    )
                    .join('<br>') || '',
                product_name:
                  productApprove
                    .map(
                      (product, index) =>
                        `${index + 1}. ${product.productItem}${
                          product.vendorName
                            ? `. Vendor: ${product.vendorName}`
                            : ''
                        }`
                    )
                    .join('<br>') || '',
                detail_url:
                  `${import.meta.env.VITE_HOST_DOMAIN}/price-list/${lastPL.priceListNumber}?productTab=new-added-product` ||
                  '',
                roofing_company_name: this.setting.companyName || '',
                roofing_company_address: this.setting.address || '',
                roofing_company_phone: this.setting.phoneNumber || '',
              },
              attachments: [],
              scheduleSendingTime: '',
            })
            .then(() => {
              return axiosService.post('/notification/trigger-email-service', {
                sender: '',
              });
            })
            .catch(error => {
              // eslint-disable-next-line no-console
              console.error('[Send Email]', error);
              throw new Error(error.message);
            })
        );
      }
      Promise.all(sendMailPromises);
    },
  },
};
