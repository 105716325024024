<template>
  <f7-popup
    push
    :opened="checklistPopupOpened"
    @popup:closed="close"
  >
    <f7-view>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link @click.native="close">Close</f7-link>
          </f7-nav-left>
          <f7-nav-title>Edit Checklist</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="save">Save</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-block-title>Checklist</f7-block-title>
        <div class="card data-table">
          <table>
            <tbody>
              <tr
                v-for="(row, index) in checklist('scope-of-work')"
                :key="index"
              >
                <td class="checkbox-cell">
                  <f7-checkbox
                    :checked="row.checked"
                    @change="row.checked = !row.checked"
                  ></f7-checkbox>
                </td>
                <td class="label-cell">
                  <div style="margin-top: 5px">
                    <span>{{ index + 1 }}.&nbsp;</span>
                    <span
                      v-for="(item, itemIndex) in row.list"
                      :key="itemIndex"
                    >
                      <span
                        v-if="item.type === 'string'"
                        v-html="`&nbsp; ${item.value} &nbsp;`"
                      >
                      </span>
                      <input
                        v-if="item.type === 'input-text'"
                        class="text-box"
                        type="text"
                        :value="item.value"
                        @input="item.value = $event.target.value"
                      />
                      <f7-checkbox
                        v-if="item.type === 'input-checkbox'"
                        :checked="item.value"
                        @change="item.value = !item.value"
                      ></f7-checkbox>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <f7-block-title>Description</f7-block-title>
        <div class="card data-table">
          <table>
            <tbody>
              <tr
                v-for="(row, index) in checklist('description')"
                :key="index"
              >
                <td class="label-cell">
                  <div style="margin-top: 5px">
                    <!-- <span>{{ index + 1 }}.&nbsp;</span> -->
                    <span
                      v-for="(item, itemIndex) in row.list"
                      :key="itemIndex"
                    >
                      <span
                        v-if="item.type === 'string'"
                        v-html="`&nbsp; ${item.value} &nbsp;`"
                      >
                      </span>
                      <textarea
                        rows="5"
                        v-if="item.type === 'input-text'"
                        class="text-area"
                        type="textarea"
                        :value="item.value"
                        @input="item.value = $event.target.value"
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <f7-block-title>Total Payment</f7-block-title>
        <div class="card data-table">
          <table>
            <tbody>
              <tr
                v-for="(row, index) in checklist('total-sum-payment')"
                :key="index"
              >
                <td class="label-cell">
                  <div style="margin-top: 5px">
                    <!-- <span>{{ index + 1 }}.&nbsp;</span> -->
                    <span
                      v-for="(item, itemIndex) in row.list"
                      :key="itemIndex"
                    >
                      <span
                        v-if="item.type === 'string'"
                        v-html="`&nbsp; ${item.value} &nbsp;`"
                      >
                      </span>
                      <input
                        v-if="item.type === 'input-text'"
                        class="text-box"
                        type="number"
                        :value="item.value"
                        @input="item.value = $event.target.value"
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <f7-block-title>Initial Payment</f7-block-title>
        <div class="card data-table">
          <table>
            <tbody>
              <tr
                v-for="(row, index) in checklist('init-payment')"
                :key="index"
              >
                <td class="label-cell">
                  <div style="margin-top: 5px; max-width: 1024px">
                    <!-- <span>{{ index + 1 }}.&nbsp;</span> -->
                    <span
                      v-for="(item, itemIndex) in row.list"
                      :key="itemIndex"
                      :class="
                        getRowInputType(row) === 'date'
                          ? 'display-inline-flex'
                          : ''
                      "
                    >
                      <span
                        v-if="item.type === 'string'"
                        v-html="`&nbsp; ${item.value} &nbsp;`"
                      >
                      </span>
                      <input
                        v-if="
                          item.type === 'input-text' &&
                          getRowInputType(row) === 'number'
                        "
                        class="text-box"
                        type="number"
                        :value="item.value"
                        @input="item.value = $event.target.value"
                      />
                      <span
                        v-if="
                          item.type === 'input-text' &&
                          getRowInputType(row) === 'date'
                        "
                        class="date-input"
                        @click="selectDate(`${row.id}_${itemIndex}`)"
                      >
                        <span class="display-date">
                          {{
                            item.value ? formatDate(item.value) : 'MM/DD/YYYY'
                          }}
                          <f7-icon
                            f7="calendar"
                            size="18"
                          ></f7-icon>
                        </span>
                        <input
                          :ref="`dateInput_${row.id}_${itemIndex}`"
                          type="date"
                          :value="item.value"
                          @input="item.value = $event.target.value"
                          onclick="showPicker()"
                        />
                      </span>
                      <f7-checkbox
                        v-if="item.type === 'input-checkbox'"
                        :checked="item.value"
                        @change="item.value = !item.value"
                      ></f7-checkbox>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <f7-block-title>Note</f7-block-title>
        <div class="card data-table">
          <table>
            <tbody>
              <tr
                v-for="(row, index) in checklist('date-note')"
                :key="index"
              >
                <td class="label-cell">
                  <div style="margin-top: 5px">
                    <!-- <span>{{ index + 1 }}.&nbsp;</span> -->
                    <span
                      v-for="(item, itemIndex) in row.list"
                      :key="itemIndex"
                      class="display-inline-flex"
                    >
                      <span
                        v-if="item.type === 'string'"
                        v-html="`&nbsp; ${item.value} &nbsp;`"
                      >
                      </span>
                      <span
                        v-if="
                          item.type === 'input-text' &&
                          getRowInputType(row) === 'date'
                        "
                        class="date-input"
                        @click="selectDate(`${row.id}_${itemIndex}`)"
                      >
                        <span class="display-date">
                          {{
                            item.value ? formatDate(item.value) : 'MM/DD/YYYY'
                          }}
                          <f7-icon
                            f7="calendar"
                            size="18"
                          ></f7-icon>
                        </span>
                        <input
                          :ref="`dateInput_${row.id}_${itemIndex}`"
                          type="date"
                          :value="item.value"
                          @input="item.value = $event.target.value"
                          onclick="showPicker()"
                        />
                      </span>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>

<script>
import _ from 'lodash';
import { CONTRACT_CHECKLIST } from '@/utility/const';
import moment from 'moment';

export default {
  props: {
    contract: { type: Object, default: () => {} },
  },
  data() {
    return {
      checklistPopupOpened: false,
      contractChecklist: [],
    };
  },

  computed: {
    checklist() {
      return type => {
        return (
          this.contractChecklist.filter(
            item => item.type === type || item.type.includes(type)
          ) || []
        );
      };
    },
  },

  methods: {
    open() {
      this.checklistPopupOpened = true;
      this.contractChecklist =
        _.cloneDeep(this.contract.contractChecklist) || CONTRACT_CHECKLIST;
    },

    close() {
      this.checklistPopupOpened = false;
    },

    save() {
      this.close();
      this.$emit('save', this.contractChecklist);
    },
    getRowInputType(row) {
      if (row.id === 20 || row.id === 22 || row.id === 23) {
        return 'date';
      } else {
        return 'number';
      }
    },
    formatDate(val) {
      return moment(val).format('MM/DD/YYYY');
    },
    selectDate(id) {
      this.$refs[`dateInput_${id}`][0].click();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-box {
  display: inline;
  max-width: 120px;
  border: 1px solid grey;
  padding: 0px 6px;
  border-radius: 5px;
  margin: 2px 0px;
}

.text-area {
  display: inline;
  width: 100%;
  border: 1px solid grey;
  padding: 0px 6px;
  border-radius: 5px;
}
.date-input {
  position: relative;
  width: 130px;
  height: 23px;
  margin: 2px 0px;
  cursor: pointer;
  .display-date {
    position: absolute;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 0 6px;
    box-sizing: border-box;
    top: 0;
    width: 100%;
    height: 100%;
  }
  input {
    visibility: hidden;
  }
}
.ios input[type='date']:not(.has-value):before {
  color: lightgray;
  content: attr(placeholder);
}
</style>
