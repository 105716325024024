<template>
  <div>
    <f7-list accordion-list>
      <f7-list-item
        accordion-item
        accordion-item-opened
      >
        <template #title>
          <div class="title">
            <f7-icon
              f7="square_list"
              color="primary"
            >
            </f7-icon>
            <div class="info-label">Table of Content</div>
          </div>
        </template>
        <f7-accordion-content>
          <f7-list class="margin-left-half">
            <f7-block class="scroll-container">
              <f7-list-item
                v-for="(section, index) in sortedSections"
                :key="index"
                :title="section.sectionName"
                class="section-item"
                link="#"
                :text-color="
                  index === currentSectionIndex ? 'primary' : 'default'
                "
                @click="handleClickItem(section, index)"
              ></f7-list-item>
            </f7-block>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item accordion-item>
        <template #title>
          <div class="title">
            <f7-icon
              f7="text_badge_checkmark"
              color="primary"
            ></f7-icon>
            <div class="info-label">Details</div>
          </div>
        </template>
        <f7-accordion-content>
          <f7-list
            inline-labels
            class="margin-left-half"
          >
            <f7-list-item
              v-show="itemDetail.title"
              title="Title:"
              ><div style="width: 80%">
                {{ itemDetail.title || '' }}
              </div></f7-list-item
            >

            <f7-list-item title="Status:"
              ><f7-chip
                :text="onlineClass(itemDetail.status).text"
                :color="onlineClass(itemDetail.status).color"
              ></f7-chip
            ></f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import { clearSelectionHightLight } from '@/utility/common';

export default {
  props: {
    itemDetail: { type: Object, default: () => {} },
    sortedSections: { type: Array, default: () => [] },
    currentSectionIndex: { type: Number, default: 0 },
  },

  data: () => {
    return {};
  },

  methods: {
    handleClickItem(section, index) {
      this.$emit('sectionClick', { section, index });
      clearSelectionHightLight();
    },
    onlineClass(status) {
      let temp = {};
      switch (status) {
        case 'sent':
          temp = { color: 'green', text: 'Sent' };
          break;

        case 'viewed':
          temp = { color: 'pink', text: 'Viewed' };
          break;

        case 'signed':
          temp = { color: 'blue', text: 'Signed' };
          break;

        default:
          temp = { color: 'yellow', text: 'Draft' };
          break;
      }

      return temp;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-label {
  font-weight: 600;
  margin-left: 5px;
  margin-top: -2px;
}
.title {
  display: flex;
  row-gap: 15px;
}
.action-link:hover {
  color: var(--f7-theme-color);
}
.scroll-container {
  max-height: calc(100vh - 270px);
  overflow-x: auto;
  margin: 0px 0px 0px -15px;
  direction: ltr;
  scroll-behavior: smooth;
}
</style>
