import { gapiPromise } from './gapi';
import GoogleAuthService from './google.auth.service';

import gmailApi from './gmail.google.service';
import { getObjectCopy, encodeUnicode } from './utils';

const googleAuthService = new GoogleAuthService();

const {
  grantOfflineAccess,
  getOfflineAccessCode,
  login,
  logout,
  isAuthenticated,
  getUserData,
  refreshToken,
  isSignedIn,
  listenUserSignIn,
  setSession,
} = googleAuthService;

const clientConfig = {
  clientId:
    '292837351740-pet79ioqtol8c1k4lv9g8912e17f9i3j.apps.googleusercontent.com',
  scope: `https://mail.google.com/`,
  client_secret: '4z0Skdy0wQRQ9554EZfCchIa',
  refreshToken: true,
  ux_mode: 'redirect',
  redirect_uri: `${import.meta.env.VITE_HOST_DOMAIN}/contactbook/contacts/gmail-login`,
  prompt: 'select_account',
};

export default {
  install: function (Vue) {
    Vue.gapiLoadClientPromise = null;
    googleAuthService.clientConfig = getObjectCopy(clientConfig);

    const resolveAuth2Client = (resolve, reject) => {
      gapiPromise.then(() => {
        const gapi = window.gapi;
        if (!gapi) {
          // eslint-disable-next-line no-console
          console.error('Failed to load gapi!');
          return;
        }
        if (!gapi.auth) {
          gapi.load('client:auth2', () => {
            Vue.gapiLoadClientPromise = gapi.client
              .init(clientConfig)
              .then(() => {
                googleAuthService.authInstance = gapi.auth2.getAuthInstance();
                Vue.gapiLoadClientPromise.status = 0;

                resolve(gapi);
              })
              .catch(err => {
                if (err.error) {
                  const error = err.error;
                  // eslint-disable-next-line no-console
                  console.error(
                    'Failed to initialize gapi: %s (status=%s, code=%s)',
                    error.message,
                    error.status,
                    error.code,
                    err
                  );
                }
                reject(err);
              });
          });
        } else {
          resolve(gapi);
        }
      });
    };

    Vue.prototype.$gapi = {
      getGapiClient: () => {
        return new Promise((resolve, reject) => {
          if (
            Vue.gapiLoadClientPromise &&
            Vue.gapiLoadClientPromise.status === 0
          ) {
            return resolve(window.gapi);
          } else {
            resolveAuth2Client(resolve, reject);
          }
        });
      },
      getOfflineAccessCode,
      grantOfflineAccess: () => {
        return Vue.prototype.$gapi
          .getGapiClient()
          .then(grantOfflineAccess)
          .then(() => {
            googleAuthService.authInstance
              .grantOfflineAccess()
              .then(res => {
                //console.log(res);
                const refreshToken = res.code;
                localStorage.setItem('gapi.refresh_token', refreshToken);
              })
              .catch(e => {
                // eslint-disable-next-line no-console
                console.error(e);
              });
          });
      },
      login: (contactId, res, rej) => {
        return Vue.prototype.$gapi
          .getGapiClient()
          .then(() => login(res, rej, contactId));
      },
      refreshToken: () => {
        return Vue.prototype.$gapi.getGapiClient().then(refreshToken);
      },
      logout: (res, rej) => {
        return Vue.prototype.$gapi.getGapiClient().then(() => logout(res, rej));
      },
      listenUserSignIn: callback => {
        return Vue.prototype.$gapi
          .getGapiClient()
          .then(() => listenUserSignIn(callback));
      },
      isSignedIn: () => {
        return Vue.prototype.$gapi.getGapiClient().then(isSignedIn);
      },
      setSession: () => {
        return Vue.prototype.$gapi.getGapiClient().then(setSession);
      },
      isAuthenticated,
      getUserData,
    };
    Vue.prototype.$gapi.gmail = {
      getListMessages: (query, maxResults) => {
        return gmailApi.getListMessages(query, maxResults, () =>
          Vue.prototype.$gapi.refreshToken()
        );
      },
      getDetailMessage: id => {
        return gmailApi.getDetailMessage(id, () =>
          Vue.prototype.$gapi.refreshToken()
        );
      },
      getListThreads: (query, maxResults) => {
        return gmailApi.getListThreads(query, maxResults, () =>
          Vue.prototype.$gapi.refreshToken()
        );
      },
      getMessagesByThread: id => {
        return gmailApi.getMessagesByThread(id, () =>
          Vue.prototype.$gapi.refreshToken()
        );
      },
      isLoggedInGmail: () => {
        return gmailApi.isLoggedInGmail();
      },
      getAttachment: (messageId, attachmentId) => {
        return gmailApi.getAttachment(messageId, attachmentId, () =>
          Vue.prototype.$gapi.refreshToken()
        );
      },
      sendMessage: async (headersObj, message, uploadFiles, callback) => {
        var email = [
          'Content-Type: multipart/mixed; boundary="multiparts"\r\n',
          'MIME-Version: 1.0\r\n',
          `From: ${headersObj.From || ''}\r\n`,
          `To: ${encodeUnicode(headersObj.To)}\r\n`,
          `Cc: ${encodeUnicode(headersObj.Cc || '')}\r\n`,
          `Bcc: ${encodeUnicode(headersObj.Bcc || '')}\r\n`,
          `Subject: ${encodeUnicode(headersObj.Subject)} \r\n\r\n`,
          '--multiparts\r\n',
          'Content-Type: text/html; charset="UTF-8"\r\n',
          'MIME-Version: 1.0\r\n',
          'Content-Transfer-Encoding: 7bit\r\n\r\n',
          `${encodeUnicode(message)}\r\n\r\n`,
        ];
        for (let attachment of uploadFiles) {
          email.push('\r\n--multiparts\r\n');
          email.push(`Content-Type: ${attachment.type}\r\n`);
          email.push('MIME-Version: 1.0\r\n');
          email.push('Content-Transfer-Encoding: base64\r\n');
          email.push(
            `Content-Disposition: attachment; filename="${attachment.name}"\r\n\r\n`
          );
          email.push(attachment.data);
        }
        email.push('\r\n--multiparts--\r\n');
        email = email.join('');
        let data = {
          raw: window.btoa(email).replace(/\+/g, '-').replace(/\//g, '_'),
        };
        if (headersObj.ThreadId) {
          data = JSON.stringify({ ...data, threadId: headersObj.ThreadId });
        }
        return gmailApi
          .sendMessage(data, () => Vue.prototype.$gapi.refreshToken())
          .then(res => {
            callback(res);
          });
      },
    };
  },
};
