<template>
  <div>
    <div
      class="card data-table table-fixed-container"
      :class="{ 'contain-suggestion': $device.desktop || $device.ipad }"
    >
      <table>
        <thead>
          <tr>
            <th
              v-if="isActiveTab"
              class="checkbox-cell"
              style="width: 50px"
            >
              <f7-checkbox
                :checked="isSelectAll"
                @change="
                  $emit('selectAllItem', $event.target.checked, [
                    ...filteredList,
                  ])
                "
              ></f7-checkbox>
            </th>
            <th
              v-for="(item, index) in headers"
              :key="index"
              :class="`${textAlign(item.align)} ${item.cssClass}`"
            >
              {{ item.text }}
            </th>
          </tr>
          <tr v-if="hasFilter">
            <th v-if="isActiveTab"></th>
            <th
              v-for="(props, index) in headers"
              :key="index"
              class="input-cell"
            >
              <div
                v-if="props.filter"
                class="input"
              >
                <input
                  class="search-text-box"
                  v-if="props.filter.type === 'text'"
                  type="text"
                  :value="props.filter.value"
                  :placeholder="props.filter.placeholder || 'Filter'"
                  @input="
                    v =>
                      props.filter.onChange &&
                      props.filter.onChange(v.target.value)
                  "
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="filteredList && filteredList.length > 0">
          <tr
            v-for="(item, index) in filteredList"
            :key="index"
          >
            <td
              v-if="isActiveTab"
              class="checkbox-cell"
              style="width: 50px"
            >
              <f7-checkbox
                :indeterminate="!!item.qbTimeActivityId"
                :disabled="!!item.qbTimeActivityId"
                :checked="item.checked"
                @change="
                  $emit('checkBoxChange', $event.target.checked, item.id)
                "
              ></f7-checkbox>
            </td>
            <td>{{ (userById(item.user_id || '') || {}).displayName }}</td>
            <td>{{ projectName(item) }}</td>
            <td>{{ (item.qbClassRef || {}).name }}</td>
            <td>{{ (item.qbServiceItemRef || {}).name }}</td>
            <td class="numeric-cell">
              {{
                formatDuration(
                  item.approvedHour +
                    (item.hasLunchBreak ? item.lunchTimeInfo.hour : 0)
                )
              }}
            </td>
            <td class="numeric-cell">{{ item.payRate || 0 | currencyUSD }}</td>
            <td class="numeric-cell">{{ formatDuration(item.overtime) }}</td>
            <td class="numeric-cell">
              {{ item.payRateOvertime || 0 | currencyUSD }}
            </td>
            <td class="numeric-cell">{{ toDisplayDateString(item.date) }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              class="label-cell no-data"
              :colspan="headers.length + 1"
            >
              <div>No data!</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {
  // toDateCalendar
  // toDateFirebase
  toDisplayDateString,
  formatDuration,
} from '../../../../utility/datetime';
import { mapGetters } from 'vuex';
import { applyFilter } from '../../../../utility/filter-tools';

export default {
  props: {
    workingHoursList: Array,
    isActiveTab: Boolean,
  },

  data() {
    return {
      gridFilter: {
        employeeName: '',
        projectName: '',
        className: '',
        serviceItemName: '',
      },
      selectAll: false,
      formatDuration,
    };
  },

  computed: {
    ...mapGetters('quickbooks/common', ['qbEmployeeList', 'qbCustomerList']),
    ...mapGetters('quickbooks/user', ['userById']),
    ...mapGetters('quickbooks/project', ['projectById']),

    projectName() {
      return item => {
        return item.timeLogType === 'others'
          ? item.title || ''
          : (item.project || {}).title || '';
      };
    },

    qbCustomer() {
      return projectId => {
        return this.projectById(projectId).qbCustomerRef || {};
      };
    },

    employeeList() {
      return this.qbEmployeeList.map(item => {
        return {
          id: item.Id,
          name: item.DisplayName,
        };
      });
    },

    customerList() {
      return this.qbCustomerList.map(item => {
        return {
          id: item.Id,
          name: item.DisplayName,
        };
      });
    },

    hasFilter() {
      return this.headers.some(header => header.filter);
    },

    filteredList() {
      return applyFilter([...this.workingHoursList], '', [], {
        user_id: value => {
          const name = (this.userById(value || '') || {}).displayName;
          if (this.gridFilter.employeeName == '') return true;
          return name
            .toLowerCase()
            .includes(this.gridFilter.employeeName.toLowerCase());
        },
        project: value => {
          const name = (value || {}).title || '';
          if (this.gridFilter.projectName == '') return true;
          return name
            .toLowerCase()
            .includes(this.gridFilter.projectName.toLowerCase());
        },
        qbClassRef: value => {
          const name = (value || {}).name || '';
          if (this.gridFilter.className == '') return true;
          return name
            .toLowerCase()
            .includes(this.gridFilter.className.toLowerCase());
        },
        qbServiceItemRef: value => {
          const name = (value || {}).name || '';
          if (this.gridFilter.serviceItemName == '') return true;
          return name
            .toLowerCase()
            .includes(this.gridFilter.serviceItemName.toLowerCase());
        },
      });
    },
    isSelectAll() {
      const activeList =
        this.filteredList.filter(r => !r.qbTimeActivityId) || [];
      return activeList.length > 0 && activeList.every(r => r.checked);
    },
    headers() {
      let self = this;
      return [
        {
          text: 'Employee Name',
          value: 'employeeName',
          sortable: false,
          align: 'left',
          filter: {
            type: 'text',
            placeholder: 'Search employee name',
            value: self.gridFilter.employeeName,
            onChange: value => {
              self.gridFilter.employeeName = value;
            },
          },
        },
        {
          text: 'Project Name',
          value: 'Project',
          sortable: false,
          align: 'left',
          filter: {
            type: 'text',
            placeholder: 'Search project name',
            value: self.gridFilter.projectName,
            onChange: value => {
              self.gridFilter.projectName = value;
            },
          },
        },
        {
          text: 'Class',
          value: 'className',
          sortable: false,
          align: 'left',
          filter: {
            type: 'text',
            placeholder: 'Search class',
            value: self.gridFilter.className,
            onChange: value => {
              self.gridFilter.className = value;
            },
          },
        },
        {
          text: 'Service Item',
          value: 'serviceItemName',
          sortable: false,
          align: 'left',
          filter: {
            type: 'text',
            placeholder: 'Search service item',
            value: self.gridFilter.serviceItemName,
            onChange: value => {
              self.gridFilter.serviceItemName = value;
            },
          },
        },
        {
          text: 'Hours',
          value: 'hours',
          sortable: false,
          align: 'right',
        },
        {
          text: 'Pay Rate',
          value: 'payRate',
          sortable: false,
          align: 'right',
        },
        {
          text: 'Overtime',
          value: 'overtimeHours',
          sortable: false,
          align: 'right',
        },
        {
          text: 'OT Pay Rate',
          value: 'payRate',
          sortable: false,
          align: 'right',
        },
        {
          text: 'Submit Date',
          value: 'submit-date',
          sortable: false,
          align: 'right',
        },
      ];
    },
  },

  methods: {
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    textAlign(align) {
      return align === 'left'
        ? 'text-align-left'
        : align === 'center'
          ? 'text-align-center'
          : align === 'right'
            ? 'text-align-right'
            : 'text-align-left';
    },
  },
};
</script>
<style lang="scss" scoped>
// .parent-row {
//   background-color: #e2e2e2;
//   font-weight: bold;
// }

.no-data {
  text-align: center;
  color: grey;
}
.search-text-box {
  font-size: var(--f7-table-head-font-size);
  border: 1px solid grey;
  padding: 0px 6px;
  border-radius: 6px;
}
.input-cell {
  padding-top: 0px !important;
}
.table-fixed-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 382px);
  overflow-y: auto;
}

thead:nth-child(1) th {
  position: sticky;
  top: 0;
  background-color: var(--f7-color-bg-4-neutral);
  z-index: 20;
}
.table-head-label {
  color: var(--f7-color-text-neutral);
}
thead th::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: var(--f7-color-bg-select-neutral) 1px solid;
}
thead tr:nth-child(2) th {
  position: sticky;
  top: 32px;
  background-color: var(--f7-color-bg-4-neutral);
  z-index: 20;
}
</style>
