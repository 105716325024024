<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="doAfterClosed()"
    @popup:close="closePopup()"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="popupOpened = false">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title> {{ popupTitle }} - Settings </f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="done">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-block-title class="popup-card-title">Action Required</f7-block-title>

      <f7-list>
        <!-- Actions -->
        <f7-list-input
          :value="actionId"
          @input="changeActionId($event.target.value)"
          label="Move Project To"
          type="select"
        >
          <option
            v-for="action in actionListSorted"
            :key="action.id"
            :value="action.id"
          >
            {{ action.title }}
          </option>
          <input-icon
            slot="media"
            icon="arrow_2_squarepath"
          ></input-icon> </f7-list-input
      ></f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import InputIcon from '../icon/InputIcon.vue';
import { mapActions, mapGetters } from 'vuex';
import actionsMixins from '../../mixin/actions';
import { COLLECTION_OPRATION_CARD } from '@/utility/const';
const CODE_RE_OPEN_POPUP = 're-open-popup';

export default {
  components: { InputIcon },
  mixins: [actionsMixins],

  data() {
    return {
      popupOpened: false,
      actionId: '',
      code: '',
      data: {},
      limit: 25,
      order: 'desc',
    };
  },

  computed: {
    ...mapGetters('dashboard/project', ['users', 'card', 'action']),
    ...mapGetters('dashboard/project', ['actionListSorted', 'filterAction']),

    popupTitle() {
      return `${this.card ? this.card.title : ''}`;
    },

    userInfo() {
      return uid =>
        this.users.find(user => {
          return user.uid === uid;
        });
    },
  },

  methods: {
    ...mapActions('dashboard/project', ['updateCard', 'clearCurrentCard']),
    ...mapActions('common/notification', ['createNotificationByType']),
    openPopup() {
      this.popupOpened = true;
      this.initSettings();
    },

    doAfterClosed() {
      this.popupOpened = false;
      this.$emit('settingChanged', { code: this.code, data: this.data });
      this.clearData();
    },

    closePopup() {
      this.popupOpened = false;
    },

    done() {
      // process new setting
      const response = {
        nextActions: [this.actionId],
        nextState: 'open',
      };

      // calc the actions history
      let nextActionsTitle = response.nextActions
        .map(actionId => {
          return (this.filterAction(actionId) || {}).title || '';
        })
        .join(', ')
        .trim();

      let actionsHistory = this.calculateActionHistory(
        this.card,
        this.action,
        response
      );
      // Calculate next actions
      let nextActionIds = this.calculateNextActions(
        this.card,
        this.action,
        response
      );

      let actionsStatus = this.calculateActionStatus(this.card, this.action);

      // Assign the next action
      this.updateCard({
        id: this.card.id,
        doc: {
          status: response.nextState,
          actions: nextActionIds, //response.nextActions,
          actionsHistory: actionsHistory,
          actionsStatus: actionsStatus,
        },
      });

      // Send email notification
      (this.card.assigneeIDs || []).forEach(uid => {
        let receiverInfo = this.userInfo(uid);
        if (receiverInfo) {
          let receiver = `${receiverInfo.displayName} <${receiverInfo.email}>`;
          this.sendMail(this.card.title, receiver, nextActionsTitle);
        }
      });
      // Add activity
      this.createNotificationByType({
        data: {
          assignees: this.card.assigneeIDs,
          project: {
            title: this.card.title,
            id: this.card.id,
            nextTitle: nextActionsTitle,
            type: 'project',
            entityName: COLLECTION_OPRATION_CARD,
          },
        },
        type: 'resolve-action',
      });

      // Close the popup
      this.popupOpened = false;
    },

    initSettings() {
      if (this.action) {
        this.actionId = this.action.id;
      }
    },

    changeActionId(id) {
      const action = this.filterAction(id);
      this.actionId = action.id;
      this.data.actionId = action.id;
      this.data.actionCode = action.code;
      this.data.cardNumber = this.card.cardNumber;
      this.data.boardName = action.boards[0] || 'sales';
      this.code = CODE_RE_OPEN_POPUP;
    },

    clearData() {
      this.code = '';
      this.data = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-card-title {
  font-size: 16px;
}
</style>
