<template>
  <f7-page
    class="building-estimate-detail"
    v-show="estimate && Object.keys(estimate).length"
  >
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          v-if="!$device.desktop && !hasProject"
          @click="onClickBack"
          icon-f7="chevron_left"
        ></f7-link>
        <f7-link
          v-if="hasProject"
          @click="handleBackAction"
          icon-f7="chevron_left"
          >Back</f7-link
        >
      </f7-nav-left>
      <f7-nav-title>{{
        `${estimate.estimateName || ''}  ${
          currentBuilding.buildingName
            ? ` - ${currentBuilding.buildingName}`
            : ''
        }`
      }}</f7-nav-title>

      <f7-nav-right>
        <div
          class="box-switch-screen"
          :style="$device.desktop ? 'width: 150px;' : ''"
          @click="onChangeTypeSwitchScreen"
        >
          <f7-icon
            f7="arrow_right_arrow_left"
            size="20"
          ></f7-icon>
          <span v-show="$device.desktop">{{
            typeSwitchScreen === 'step' ? 'Single Page View' : 'Stepped View'
          }}</span>
        </div>
      </f7-nav-right>
    </f7-navbar>

    <step-navigator
      v-if="$device.desktop && typeSwitchScreen === 'step'"
      ref="stepNavigator"
      @onClickStep="onClickStep"
      :roofTypeCode="currentBuilding.roofType || ''"
    ></step-navigator>

    <mobile-step-navigator
      v-if="!$device.desktop && typeSwitchScreen === 'step'"
      @onPrevious="onMobilePrevious"
      @onNext="onMobileNext"
      @onLastStepNext="done"
      ref="stepNavigatorMobile"
      :roofTypeCode="currentBuilding.roofType || ''"
    ></mobile-step-navigator>

    <div
      style="
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-end;
        padding: 10px;
      "
      v-if="
        workSteps.length > 1 &&
        currentStep === lastStep.stepId &&
        isLastBuilding &&
        typeSwitchScreen === 'step'
      "
    >
      <f7-button
        fill
        v-show="estimate.status != STATUS_ESTIMATE_ACTIVE"
        @click="handleActiveEstimate"
        >Audit Estimate</f7-button
      >
      <new-proposal-button :isMarginTop="false"></new-proposal-button>
    </div>

    <!-- Content Steps -->
    <div v-if="$device.desktop && typeSwitchScreen === 'step'">
      <template v-for="(step, index) in workSteps">
        <component
          :ref="stepComponent(step.stepId)"
          v-show="currentStep === step.stepId"
          :is="stepComponent(step.stepId)"
          :key="index"
          :roofType="currentBuilding.roofType || ''"
        />
      </template>
    </div>

    <!-- Mobile -->
    <f7-swiper
      v-if="!$device.desktop && typeSwitchScreen === 'step'"
      ref="stepSwipper"
      pagination
      class="step-swipper"
    >
      <f7-swiper-slide
        v-for="(step, index) in workSteps"
        :key="index"
      >
        <div class="step-swiper-container">
          <component
            :ref="stepComponent(step.stepId)"
            :is="stepComponent(step.stepId)"
            :key="index"
            :roofType="currentBuilding.roofType || ''"
          />
        </div>
      </f7-swiper-slide>
    </f7-swiper>

    <f7-block
      v-if="$device.desktop && typeSwitchScreen === 'step'"
      class="display-flex justify-content-center"
    >
      <f7-button
        v-show="currentStep !== firstStep.stepId"
        class="btn-step margin-right"
        fill
        color="primary"
        @click="previous()"
        ><f7-icon
          f7="chevron_left"
          class="margin-right"
        ></f7-icon>
        Previous
      </f7-button>
      <f7-button
        v-show="currentStep !== lastStep.stepId"
        class="btn-step"
        fill
        color="blue"
        @click="next()"
        >Next
        <f7-icon
          f7="chevron_right"
          class="margin-left"
        ></f7-icon
      ></f7-button>
      <!-- btn next spec -->
      <f7-button
        v-show="
          currentStep === lastStep.stepId && isShowButtonNextSpecification
        "
        class="btn-step"
        fill
        color="primary"
        @click="onNextSpecification()"
        >Next
        <f7-icon
          f7="chevron_right"
          class="margin-left"
        ></f7-icon
      ></f7-button>
      <f7-button
        v-show="
          workSteps.length > 1 &&
          currentStep === lastStep.stepId &&
          !isShowButtonNextSpecification
        "
        class="btn-step"
        fill
        @click="onGotoSummary()"
        >Summary
        <f7-icon
          f7="chevron_right"
          class="margin-left"
        ></f7-icon
      ></f7-button>
    </f7-block>

    <div
      v-if="typeSwitchScreen != 'step'"
      class="container-single-screen"
      :style="isThemDart"
    >
      <div
        :style="$device.desktop ? 'width: 24.5%;' : 'width: calc(100% - 16px);'"
      >
        <box-single-view-infor
          :workSteps="workSteps"
          @openViewProductTablePopup="handleOpenViewProductTablePopup"
        ></box-single-view-infor>
      </div>

      <div
        v-if="$device.desktop"
        style="width: 74%"
      >
        <box-single-view-table
          :workSteps="convertWorkStepsForScreenSinglePage"
          :isLastBuilding="isLastBuilding"
          :isLastStep="currentStep === lastStep.stepId"
          @onGotoSummary="onGotoSummary"
          @handleActiveEstimate="handleActiveEstimate"
          @setOpenStepView="setOpenStepView"
        ></box-single-view-table>
      </div>
    </div>

    <estimate-change-status-popup
      ref="estimateChangeStatusPopup"
      @onClosedPopup="onEstimateChangeStatusPopupClosed"
    ></estimate-change-status-popup>

    <view-product-table-popup ref="viewProductTablePopup">
      <template v-slot:table>
        <box-single-view-table
          :workSteps="convertWorkStepsForScreenSinglePage"
          :isLastBuilding="isLastBuilding"
          @onGotoSummary="onGotoSummary"
          @handleActiveEstimate="handleActiveEstimate"
          @setOpenStepView="setOpenStepView"
        ></box-single-view-table>
      </template>
    </view-product-table-popup>
  </f7-page>
</template>

<script>
import StepNavigator from '../components/navigator/StepNavigator.vue';
import MobileStepNavigator from '../components/navigator/MobileStepNavigator.vue';

import RoofAndSubstrateInfoStep from '../components/steps/RoofAndSubstrateInfoStep.vue';
import TpoRoofAndSubstrateInfoStep from '../components/steps/TpoRoofAndSubstrateInfoStep.vue';
import MembraneInfoStep from '../components/steps/MembraneInfoStep.vue';
import RakeOrEdgeInfoStep from '../components/steps/RakeOrEdgeInfoStep.vue';
import InsulationInfoStep from '../components/steps/InsulationInfoStep.vue';
import InformationStep from '../components/steps/InformationStep.vue';
import ReviewStep from '../components/steps/ReviewStep.vue';

import MiscAndLaborStep from '../components/steps/MiscAndLaborStep.vue';
import LeadsVentsStep from '../components/steps/LeadsVentsStep.vue';
import PipeBootsAccessoriesStep from '../components/steps/PipeBootsAccessoriesStep.vue';

import ShingleRoofComponentsStep from '../components/steps/ShingleRoofComponentsStep.vue';
import StandingSeamRoofComponentsStep from '../components/steps/StandingSeamRoofComponentsStep.vue';

import TpoMetalStep from '../components/steps/TpoMetalStep.vue';
import ShingleMetalStep from '../components/steps/ShingleMetalStep.vue';
import ShingleReviewStep from '../components/steps/ShingleReviewStep.vue';
import StandingSeamReviewStep from '../components/steps/StandingSeamReviewStep.vue';
import StandingSeamMetalStep from '../components/steps/StandingSeamMetalStep.vue';

import ShingleRoofStep from '../components/steps/ShingleRoofStep.vue';
import StandingSeamRoofStep from '../components/steps/StandingSeamRoofStep.vue';

import ServiceInformationStep from '../components/steps/ServiceInformationStep.vue';
import ServiceReviewStep from '../components/steps/ServiceReviewStep.vue';
import ServiceProductStep from '../components/steps/ServiceProductStep.vue';

import EstimateChangeStatusPopup from '../components/popup/EstimateChangeStatusPopup.vue';
import NewProposalButton from '../components/buttons/NewProposalButton.vue';

import BoxSingleViewInfor from '../components/details/BoxSingleViewInfor.vue';
import BoxSingleViewTable from '../components/details/BoxSingleViewTable.vue';

import ViewProductTablePopup from '../components/popup/ViewProductTablePopup.vue';
import _ from 'lodash';

import {
  STEP_INFORMATION,
  STEP_SERVICE_INFORMATION,
  COMMON_SERVICE_STEPS,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
  STATUS_ESTIMATE_ACTIVE,
  CUSTOM_TABLE_TYPE_ESTIMATE_PRODUCT,
} from '../../../utility/const';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import buildingMixin from '../utility/building-mixins';

export default {
  components: {
    StepNavigator,
    RoofAndSubstrateInfoStep,
    TpoRoofAndSubstrateInfoStep,
    InformationStep,
    MembraneInfoStep,
    RakeOrEdgeInfoStep,
    InsulationInfoStep,
    MiscAndLaborStep,
    ReviewStep,

    MobileStepNavigator,
    ShingleRoofComponentsStep,
    LeadsVentsStep,
    PipeBootsAccessoriesStep,

    StandingSeamRoofComponentsStep,

    TpoMetalStep,
    ShingleMetalStep,
    ShingleReviewStep,
    StandingSeamReviewStep,
    StandingSeamMetalStep,

    ShingleRoofStep,
    StandingSeamRoofStep,

    ServiceInformationStep,
    ServiceProductStep,
    ServiceReviewStep,

    EstimateChangeStatusPopup,
    NewProposalButton,

    BoxSingleViewInfor,
    BoxSingleViewTable,

    ViewProductTablePopup,
  },

  mixins: [buildingMixin],

  data() {
    return {
      STEP_INFORMATION,
      STEP_SERVICE_INFORMATION,
      openStepView: '',

      BUSINESS_CODE_RESIDENTIAL,
      BUSINESS_CODE_COMMERCIAL,
      BUSINESS_CODE_SERVICE,
      STATUS_ESTIMATE_ACTIVE,
    };
  },

  async created() {
    this.setCurrentBuildingValue('');
    const params = this.$f7route.params;
    if (!params?.estimateNumber) return;
    if (params.estimateNumber && !params.clientType && !params.clientId) {
      this.setCurrentView('estimate-list');
    } else {
      this.setCurrentView('client');
    }
    this.$f7.preloader.show();
    this.setEstIdLink(params.estimateNumber);

    const promises = [];
    const docs = await this.getEstimateBys([
      {
        prop: 'estimateNumber',
        val: params.estimateNumber,
        op: '==',
      },
    ]);
    if (!_.isEmpty(docs) && docs.length === 1) {
      const estimate = docs[0];
      if (_.isEmpty(estimate)) return;
      promises.push(this.bindEstimate(estimate.id));

      if (estimate.projectId) {
        promises.push(this.getProject(estimate.projectId));
      }

      if (estimate.propertyId) {
        promises.push(this.getProperty(estimate.propertyId));
      }
      if (estimate.buildings) {
        this.setCurrentStep(this.defaultStep.stepId);

        const buildingRef = estimate.buildings.find(
          item => item.buildingName === this.$f7route.params.buildingName
        );
        if (buildingRef && buildingRef.buildingId) {
          // TODO: load buidling info

          await this.bindBuilding({
            estimateId: estimate.id,
            buildingId: buildingRef.buildingId,
          });

          if (this.$refs.stepSwipper) {
            this.$refs.stepSwipper.swiper.on('activeIndexChange', () => {
              this.onSlideChange();
            });
            //disable swipe on body
            this.$refs.stepSwipper.swiper.allowTouchMove = false;
          }

          if (this.$refs.stepNavigator) {
            this.$nextTick(() => {
              this.$refs.stepNavigator.setStepActives(this.defaultStep.stepId);
            });
          }

          if (this.building.priceListId) {
            promises.push(this.bindPriceListItem(this.building.priceListId));

            promises.push(
              this.getActiveProductItemsByPriceListId(this.building.priceListId)
            );

            promises.push(
              this.getAssemblyDrawingByPriceList(this.building.priceListId)
            );
          } else {
            // clear state priceListItem and productList
            promises.push(this.unbindPriceListItem());
          }

          promises.push(
            this.bindCustomTable({
              tableType: CUSTOM_TABLE_TYPE_ESTIMATE_PRODUCT,
              userId: this.currentUser.uid,
            })
          );

          promises.push(
            this.getDefaultColumns(CUSTOM_TABLE_TYPE_ESTIMATE_PRODUCT)
          );
        }
      }
      await Promise.all(promises);
      if (
        estimate.businessCode === BUSINESS_CODE_SERVICE &&
        _.isEmpty(this.currentBuilding.productData) &&
        this.currentBuilding.priceListId
      ) {
        await this.saveDefaultProducts();
      } else if (
        _.isEmpty(this.currentBuilding.productData) &&
        this.currentBuilding.priceListId
      ) {
        await this.getProductDataTemplate({
          roofType: this.currentBuilding.roofType,
        }).then(productData => {
          return this.saveBuildingValue('productData', productData);
        });
      }
      this.$f7.preloader.hide();
    } else {
      this.$f7.preloader.hide();
    }
  },

  computed: {
    ...mapGetters('estimate/estimate-page/estimate', [
      'building',
      'estimate',
      'currentBuilding',
      'estimateByNumber',
      'estimateListByProject',
      'estimateActiveListByProject',
      'currentStep',
      'hits',
      'paramsBack',
      'queryBack',
      'typeSwitchScreen',
    ]),

    ...mapGetters('common/app-constant', ['roofTypeBy', 'workStepBy']),

    ...mapGetters('estimate/estimate-page/price-list', ['priceListItemList']),
    ...mapGetters('estimate/estimate-page/common', ['currentView']),
    ...mapGetters('setting/app/profile', { currentUser: 'user' }),

    isExistedEstimate() {
      return this.hits.some(r => r.id === this.estimate.id);
    },

    isThemDart() {
      return localStorage.getItem('themeDark') === 'true'
        ? 'background-color: #000;'
        : 'background-color: #fff;';
    },

    isLastBuilding() {
      return (
        this.estimate.buildings &&
        this.estimate.buildings.length > 0 &&
        this.currentBuilding.id ===
          this.estimate.buildings[this.estimate.buildings.length - 1].buildingId
      );
    },

    isShowButtonNextSpecification() {
      if (_.isEmpty(this.estimate.buildings)) return;
      const index = (this.estimate.buildings || []).findIndex(
        e => e.buildingId === this.currentBuilding.id
      );
      return !(index + 1 === this.estimate.buildings.length);
    },

    nextBuilding() {
      if (this.estimate && _.isEmpty(this.estimate.buildings)) {
        return null;
      }
      let currentIndex = (this.estimate.buildings || []).findIndex(
        r => this.currentBuilding.id == r.buildingId
      );
      return this.estimate.buildings[currentIndex + 1] || null;
    },

    convertWorkStepsForScreenSinglePage() {
      return this.workSteps.filter(e => e.stepId !== 'tpo-roof-substrate-info');
    },

    workSteps() {
      if (_.isEmpty(this.estimate) || _.isEmpty(this.currentBuilding)) {
        return [this.defaultStep];
      }

      const roofType = this.roofTypeBy(this.currentBuilding.roofType);

      // This case when user select all roof type for this building
      if (this.currentBuilding.roofType === 'all-roof-type') {
        return COMMON_SERVICE_STEPS[BUSINESS_CODE_SERVICE];
      }

      if (
        _.isEmpty(roofType) ||
        _.isEmpty(roofType.workSteps) ||
        _.isEmpty(this.estimate.businessCode)
      ) {
        return [this.defaultStep];
      }

      return _.cloneDeep(roofType.workSteps[this.estimate.businessCode]).sort(
        (a, b) => a.index - b.index
      );
    },

    stepComponent() {
      return stepId => {
        return (this.workStepBy(stepId) || {}).componentName || '';
      };
    },

    defaultStep() {
      return {
        stepId:
          this.estimate.businessCode === BUSINESS_CODE_SERVICE
            ? this.STEP_SERVICE_INFORMATION
            : this.STEP_INFORMATION,
        index: 0,
      };
    },

    firstStep() {
      return this.workSteps ? this.workSteps[0] : [this.defaultStep];
    },

    lastStep() {
      return this.workSteps
        ? this.workSteps[this.workSteps.length - 1]
        : [this.defaultStep];
    },

    hasProject() {
      if (
        this.paramsBack &&
        this.paramsBack.projectId &&
        this.paramsBack.actionId
      ) {
        return this.paramsBack;
      }
      return false;
    },
  },

  methods: {
    ...mapActions('estimate/estimate-page/estimate', [
      'bindBuilding',
      'unbindBuilding',
      'setCurrentBuildingValue',
      'bindEstimate',
      'getEstimateBys',
      'updateEstimate',
      'getEstimatesByProject',
      'setCurrentStep',
      'setTypeSwitchScreen',
      'setIsActiveSummary',
      'getProductDataTemplate',
    ]),

    ...mapActions('estimate/estimate-page/price-list', [
      'bindPriceListItem',
      'unbindPriceListItem',
      'bindPriceListItemListBys',
    ]),

    ...mapActions('estimate/estimate-page/product-item', [
      'getActiveProductItemsByPriceListId',
    ]),

    ...mapActions('estimate/estimate-page/project', ['getProject']),
    ...mapActions('estimate/estimate-page/property', ['getProperty']),

    ...mapActions('estimate/estimate-page/assembly-drawing', [
      'getAssemblyDrawingByPriceList',
    ]),
    ...mapMutations('estimate/estimate-page/estimate', ['setEstIdLink']),
    ...mapActions('estimate/estimate-page/project', [
      'getBoardById',
      'getProjectById',
      'getTaskById',
      'getActionById',
    ]),
    ...mapActions('estimate/estimate-page/common', ['setCurrentView']),
    ...mapActions('common/custom-table', ['bindCustomTable']),
    ...mapActions('common/app-constant', ['getDefaultColumns']),

    handleOpenViewProductTablePopup() {
      this.$refs.viewProductTablePopup.open();
    },

    setOpenStepView(value) {
      this.openStepView = value;
    },

    onChangeTypeSwitchScreen() {
      this.$f7.preloader.show();
      this.setTypeSwitchScreen(
        this.typeSwitchScreen === 'step' ? 'single' : 'step'
      );

      setTimeout(() => {
        if (this.openStepView && this.openStepView === 'review') {
          if (this.currentStep !== this.lastStep.stepId) {
            this.onClickStep(
              this.workSteps.at(this.workSteps.length - 1)?.stepId
            );
          }
        } else if (
          this.openStepView &&
          this.workSteps.length === 3 &&
          this.openStepView === 'product'
        ) {
          const middleIndex = Math.floor(this.workSteps.length / 2);
          this.onClickStep(this.workSteps.at(middleIndex)?.stepId);
        }
        this.$f7.preloader.hide();
        this.setOpenStepView('');
      }, 1000);
    },

    onClickStep(stepType) {
      // DEV-464: TPO Estimate Bugs/Improvements
      // Switch/select tabs: allow in spite of including required fields\
      let stepComponentRef = this.$refs[this.stepComponent(this.currentStep)];
      // DEV-2616: If change page to Single Page View,it don't have step need to validate
      if (stepComponentRef && stepComponentRef.length > 0) {
        let validate = stepComponentRef[0].validate();
        if (validate) return;
      } else {
        return;
      }
      this.setCurrentStep(stepType);
      this.$refs[this.stepComponent(stepType)][0].init();
      this.$refs.stepNavigator &&
        this.$refs.stepNavigator.setStepActives(stepType);

      this.$forceUpdate();
    },

    onNextSpecification() {
      this.$ri.dialog.openWarningDialog({
        title: '',
        content: `The estimate of ${this.currentBuilding.buildingName} has been saved. Do you want to start estimate ${this.nextBuilding.buildingName} ?`,
        textButton: 'Yes',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();

            this.onGotoBuilding(this.nextBuilding.buildingName);
          }
        },
      });
    },

    onSlideChange() {
      const workSteps = _.cloneDeep(this.workSteps).sort(
        (a, b) => a.index - b.index
      );
      let index = this.$refs.stepSwipper
        ? this.$refs.stepSwipper.swiper.activeIndex
        : 0;
      this.setCurrentStep(workSteps[index].stepId);
      if (this.$refs.stepNavigatorMobile) {
        this.$nextTick(() => {
          this.$refs.stepNavigatorMobile.setStepActives(this.currentStep);
        });
      }
    },

    onMobileNext() {
      this.$nextTick(() => {
        let validate =
          this.$refs[this.stepComponent(this.currentStep)][0].validate();
        if (validate) return;
      });

      const workSteps = _.cloneDeep(this.workSteps).sort(
        (a, b) => a.index - b.index
      );
      this.$refs.stepSwipper.swiper.slideNext();
      let index = this.$refs.stepSwipper
        ? this.$refs.stepSwipper.swiper.activeIndex
        : 0;

      this.setCurrentStep(workSteps[index].stepId);
      this.$refs.stepNavigatorMobile &&
        this.$refs.stepNavigatorMobile.setStepActives(this.currentStep);
      // TODO: init data for next step
      this.$refs[this.stepComponent(this.currentStep)][0].init();
    },

    onMobilePrevious() {
      const workSteps = _.cloneDeep(this.workSteps).sort(
        (a, b) => a.index - b.index
      );
      this.$refs.stepSwipper.swiper.slidePrev();
      let index = this.$refs.stepSwipper
        ? this.$refs.stepSwipper.swiper.activeIndex
        : 0;
      this.setCurrentStep(workSteps[index].stepId);
      this.$refs.stepNavigatorMobile &&
        this.$refs.stepNavigatorMobile.setStepActives(this.currentStep);
    },

    initBuildingData(buildingData) {
      this.setCurrentBuildingValue('');
      for (const key in buildingData) {
        // if (
        //   this.currentBuilding.hasOwnProperty(key)
        //   // &&
        //   // this.focusing[key] !== true
        // ) {
        let value =
          buildingData[key] !== undefined
            ? buildingData[key]
            : this.currentBuilding[key]; // to init new value !undefined

        if (value !== null) {
          if (typeof value === 'object') {
            // this.currentBuilding[key] = _.cloneDeep(value);
            this.setCurrentBuildingValue({
              prop: key,
              value: _.cloneDeep(value),
            });
          } else {
            // this.currentBuilding[key] = value;
            this.setCurrentBuildingValue({ prop: key, value: value });
          }
        }
        // }
      }
    },

    onEstimateChangeStatusPopupClosed() {
      this.onGotoSummary();
    },

    handleActiveEstimate() {
      this.$refs.viewProductTablePopup.close();
      const self = this;
      if (this.estimate.status != STATUS_ESTIMATE_ACTIVE) {
        self.$ri.dialog.openWarningDialog({
          title: '',
          content:
            'Have you completed the Estimate? If so, do you want to update the status to Active?',
          textButton: 'Yes',
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
              this.$f7.preloader.show();

              self
                .updateEstimate({
                  id: self.estimate.id,
                  doc: {
                    status: STATUS_ESTIMATE_ACTIVE,
                  },
                })
                .then(() => {
                  this.$f7.preloader.hide();

                  return self.getEstimatesByProject(self.estimate.projectId);
                });
            }
          },
        });
      }
    },

    done() {
      const self = this;
      if (this.isLastBuilding) {
        if (this.estimate.status != STATUS_ESTIMATE_ACTIVE) {
          self.$ri.dialog.openWarningDialog({
            title: '',
            content:
              'Have you completed the Estimate? If so, do you want to update the status to Active?',
            textButton: 'Yes',
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
                self.onGotoSummary();
              } else if (index === 1) {
                // yes
                //change status to active
                _sefl.app.dialog.close();
                self
                  .updateEstimate({
                    id: self.estimate.id,
                    doc: {
                      status: STATUS_ESTIMATE_ACTIVE,
                    },
                  })
                  .then(() => {
                    return self.getEstimatesByProject(self.estimate.projectId);
                  })
                  .then(() => {
                    if (self.estimateActiveListByProject.length <= 1) {
                      self.onGotoSummary();
                    } else if (self.estimateActiveListByProject.length > 1) {
                      // TODO: show popup
                      self.$refs.estimateChangeStatusPopup.open({
                        createProposal: false,
                      });
                    }
                  });
              }
            },
          });
        } else {
          self.onGotoSummary();
        }
      } else {
        self.$ri.dialog.openWarningDialog({
          title: '',
          content: `The estimate of ${self.currentBuilding.buildingName} has been saved. Do you want to start estimate ${self.nextBuilding.buildingName} ?`,
          textButton: 'Yes',
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
              self.onGotoSummary();
            } else if (index === 1) {
              // yes
              self.onGotoBuilding(self.nextBuilding.buildingName);
            }
          },
        });
      }
    },

    previous() {
      const workSteps = _.cloneDeep(this.workSteps).sort(
        (a, b) => a.index - b.index
      );

      const currentStepIndex = workSteps.findIndex(
        item => item.stepId === this.currentStep
      );

      const previousStep = workSteps[currentStepIndex - 1];
      this.setCurrentStep(previousStep.stepId);

      this.$refs.stepNavigator.setStepActives(this.currentStep);
      this.$forceUpdate();
      return;
    },

    next() {
      // DEV-464: TPO Estimate Bugs/Improvements
      // Switch/select tabs: allow in spite of including required fields
      let validate =
        this.$refs[this.stepComponent(this.currentStep)][0].validate();
      if (validate) return;

      const workSteps = _.cloneDeep(this.workSteps).sort(
        (a, b) => a.index - b.index
      );

      const currentStepIndex = workSteps.findIndex(
        item => item.stepId === this.currentStep
      );

      const nextStep = workSteps[currentStepIndex + 1];

      // TODO: init data for next step
      this.$refs[this.stepComponent(nextStep.stepId)][0].init();

      // TODO: set current step
      this.setCurrentStep(nextStep.stepId);
      this.$refs.stepNavigator.setStepActives(this.currentStep);

      this.$forceUpdate();
      return;
    },

    onClickBack() {
      if (this.currentView === 'estimate-list') {
        this.$f7router.navigate(`/estimate/${this.estimate.estimateNumber}`);
      } else {
        this.$f7router.navigate(
          `/estimate/${this.$f7route.params.clientType}/${this.$f7route.params.clientId}/${this.estimate.estimateNumber}`
        );
      }
    },
    handleBackAction() {
      if (this.paramsBack.projectId && this.paramsBack.actionId) {
        this.navigateToBoard();
      } else if (this.paramsBack.clientType && this.paramsBack.clientId) {
        this.$f7router.navigate(
          `/estimate/${this.paramsBack.clientType}/${this.paramsBack.clientId}`,
          {
            pushState: true,
            reloadAll: true,
          }
        );
      } else {
        this.$f7router.navigate('/estimate', {
          pushState: true,
          reloadAll: true,
        });
      }
    },

    async navigateToBoard() {
      let board = {};
      let card = {};
      let task = {};
      let action = {};

      this.$f7.preloader.show();
      if (this.paramsBack.boardId) {
        board = await this.getBoardById(this.paramsBack.boardId);
      }
      if (this.paramsBack.projectId) {
        card = await this.getProjectById(this.paramsBack.projectId);
      }
      if (this.paramsBack.taskId) {
        task = await this.getTaskById(this.paramsBack.taskId);
      }
      if (this.paramsBack.actionId) {
        action = await this.getActionById(this.paramsBack.actionId);
      }

      const businessCode = this.paramsBack.businessCode || '';
      let url = '';
      switch (businessCode) {
        case 'commercial':
          url = `/dashboard/${this.paramsBack.boardType}/commercial/${this.paramsBack.boardId}/project/${card.cardNumber}/action/${action.code}`;
          if (this.paramsBack.boardId === 'construction') {
            url += `/task/${task.taskNumber}`;
          }
          this.$f7router.navigate(url, {
            pushState: true,
            reloadAll: true,
          });
          break;

        case 'residential':
          url = `/dashboard/${this.paramsBack.boardType}/residential/${card.cardNumber}/action/${action.code}`;
          this.$f7router.navigate(url, {
            pushState: true,
            reloadAll: true,
          });
          break;

        case 'service':
          this.$f7router.navigate(
            `/dashboard/${this.paramsBack.boardType}/${this.paramsBack.businessCode}/${board.boardNumber}/card/${card.cardNumber}?activeTab=${this.queryBack.activeTab}`,
            {
              pushState: true,
              reloadAll: true,
            }
          );
          break;
      }

      this.$f7.preloader.hide();
    },

    onGotoBuilding(buildingName) {
      this.$f7router.navigate(
        this.buildingUrl(this.estimate.estimateNumber, buildingName),
        {
          reloadCurrent: true,
        }
      );
    },

    onGotoSummary() {
      this.$refs.viewProductTablePopup.close();
      this.setIsActiveSummary(true);
      if (this.currentView === 'estimate-list') {
        this.$f7router.navigate(
          `/estimate/${this.estimate.estimateNumber}/summary`
        );
      } else {
        this.$f7router.navigate(
          `/estimate/${this.$f7route.params.clientType}/${this.$f7route.params.clientId}/${this.estimate.estimateNumber}/summary`
        );
      }
    },
  },

  watch: {
    building: {
      deep: true,
      immediate: true,
      handler(newBuilding, oldBuilding) {
        if (
          !_.isEmpty(newBuilding) &&
          newBuilding.id !== (oldBuilding || {}).id
        ) {
          this.initBuildingData(newBuilding);
        }
      },
    },
  },
  beforeDestroy() {
    this.setCurrentBuildingValue('');
    this.unbindBuilding();
  },
};
</script>

<style lang="scss">
.cre-proposal-btn {
  min-width: 120px;
}
.step-swipper {
  margin-top: 10px;
  height: calc(100% - 65px);
}
.step-swiper-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  > div {
    padding-bottom: 38px;
  }
}
.box-switch-screen {
  display: flex;
  align-items: center;

  gap: 10px;
  justify-content: flex-end;
  color: var(--f7-theme-color);
  cursor: pointer;
}

.container-single-screen {
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 16px);
  justify-content: space-between;
}

@media (min-width: 320px) and (max-width: 480px) {
  .block-title {
    margin-top: 10px;
  }
  .list {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .step-swiper-container {
    > div {
      padding-bottom: 80px;
    }
  }
}
</style>
