<template>
  <f7-popup
    tablet-fullscreen
    :opened="popupOpened"
    @popup:close="popupOpened = false"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="close">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title> Contract Preview </f7-nav-title>
      </f7-navbar>
      <build-contract-detail
        ref="buildContractDetail"
        :pageHeight="1680"
        :contract="contract || {}"
        :card="card || {}"
        :contact="contact || {}"
        :insuranceCompany="insuranceCompany || {}"
        :setting="setting || {}"
        :contractTemplate="buildContractTemplate"
        @sendEmail="sendEmailBuildContract"
      ></build-contract-detail>
    </f7-page>
  </f7-popup>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BuildContractDetail from '@/components/contract/BuildContractDetail.vue';

export default {
  components: { BuildContractDetail },

  data: () => {
    return {
      popupOpened: false,
      cardId: null,
    };
  },

  computed: {
    ...mapGetters('dashboard/contract', ['contract']),
    ...mapGetters('dashboard/project', ['card']),
    ...mapGetters({
      setting: 'setting/app/system/setting',
    }),
    ...mapGetters('dashboard/client', ['insuranceCompany', 'contact']),
    ...mapGetters('dashboard/template', ['buildContractTemplate']),
  },

  methods: {
    ...mapActions('dashboard/project', ['bindCard', 'unbindCard']),
    ...mapActions('dashboard/contract', ['bindContract', 'unbindContract']),
    ...mapActions('dashboard/client', [
      'getContact',
      'unbindContact',
      'getInsuranceCompany',
      'unbindInsuranceCompany',
    ]),
    async open(card) {
      if (card && card.id) {
        this.$f7.preloader.show();
        this.cardId = card.id;
        const promises = [];
        promises.push(this.bindCard(card.id));
        card.contractId && promises.push(this.bindContract(card.contractId));
        if (card.contactId) {
          promises.push(this.getContact(card.contactId));
        }
        if (card.insuranceCompanyId) {
          promises.push(this.getInsuranceCompany(card.insuranceCompanyId));
        }
        await Promise.all(promises);
        this.$f7.preloader.hide();
      }
      this.popupOpened = true;
      this.$refs.buildContractDetail.lowResDesktopScreen();
    },
    close() {
      this.popupOpened = false;
      if (this.cardId) {
        this.unbindCard();
        this.unbindContract();
        this.unbindContact();
        this.unbindInsuranceCompany();
        this.cardId = null;
      }
    },
    sendEmailBuildContract() {
      this.$emit('sendEmail');
    },
  },
};
</script>
<style scoped></style>
