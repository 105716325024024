<template>
  <f7-page class="safety-list">
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Employee Certificates</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <UserList ref="userList"> </UserList>
  </f7-page>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onUnmounted,
  onMounted,
  ref,
  defineEmits,
} from 'vue';
import { useMyCertificatesStore } from '../../../stores/my-certificates/actions';
import Menu from '../../../components/menu/Menu.vue';
import AddNewSafetyDocumentPopup from '../components/popups/AddNewSafetyDocumentPopup.vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import UserList from '../components/list/UserList.vue';

export default defineComponent({
  components: {
    Menu,
    UserList,
    AddNewSafetyDocumentPopup,
  },
  computed: {
    ...mapGetters('setting/app/profile', ['currentUser', 'isOwner']),
    ...mapGetters('setting/app/group', ['isAdminGroup']),
  },
  setup() {
    const instance = getCurrentInstance() as any;

    const store = useMyCertificatesStore();
    const onSearch = _.debounce(() => {
      instance.proxy.$f7.preloader.show();

      return store.searchMyCertificates([]).finally(() => {
        instance.proxy.$f7.preloader.hide();
      });
    }, 500);
    onMounted(() => {
      // onSearch();
    });
    onBeforeUnmount(() => {
      // destroyStore();
    });

    // onUnmounted: Được gọi sau khi component đã bị hủy
    onUnmounted(() => {});

    return {
      store,
    };
  },
});
</script>

<style scoped>
button {
  width: 100px;
  height: 50px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}
.safety-list ::v-deep .page-content {
  padding-top: calc(
    var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) +
      var(--f7-page-subnavbar-offset, 0px) +
      var(--f7-page-searchbar-offset, 0px) +
      var(--f7-page-content-extra-padding-top, 0px)
  );
  overflow: hidden;
}
</style>
