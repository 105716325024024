import Vue from 'vue';

export default {
  setCompanyList(state, payload) {
    Vue.set(state, 'companyList', payload);
  },
  setCompanySubscribe(state, payload) {
    Vue.set(state, 'companySubscribe', payload);
  },
};
