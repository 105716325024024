const languages = [
  { displayName: 'English (United States)', value: 'en-US' },
  { displayName: 'Español (México)', value: 'es-MX' },
  { displayName: 'Afrikaans', value: 'af-ZA' },
  { displayName: 'Bahasa Indonesia', value: 'id-ID' },
  { displayName: 'Bahasa Melayu', value: 'ms-MY' },
  { displayName: 'Català', value: 'ca-ES' },
  { displayName: 'Čeština', value: 'cs-CZ' },
  { displayName: 'Deutsch', value: 'de-DE' },
  { displayName: 'English (Australia)', value: 'en-AU' },
  { displayName: 'English (Canada)', value: 'en-CA' },
  { displayName: 'English (India)', value: 'en-IN' },
  { displayName: 'English (New Zealand)', value: 'en-NZ' },
  { displayName: 'English (South Africa)', value: 'en-ZA' },
  { displayName: 'English (United Kingdom)', value: 'en-GB' },
  { displayName: 'Español (Argentina)', value: 'es-AR' },
  { displayName: 'Español (Bolivia)', value: 'es-BO' },
  { displayName: 'Español (Chile)', value: 'es-CL' },
  { displayName: 'Español (Colombia)', value: 'es-CO' },
  { displayName: 'Español (Costa Rica)', value: 'es-CR' },
  { displayName: 'Español (Ecuador)', value: 'es-EC' },
  { displayName: 'Español (El Salvador)', value: 'es-SV' },
  { displayName: 'Español (España)', value: 'es-ES' },
  { displayName: 'Español (Estados Unidos)', value: 'es-US' },
  { displayName: 'Español (Guatemala)', value: 'es-GT' },
  { displayName: 'Español (Honduras)', value: 'es-HN' },
  { displayName: 'Español (Nicaragua)', value: 'es-NI' },
  { displayName: 'Español (Panamá)', value: 'es-PA' },
  { displayName: 'Español (Paraguay)', value: 'es-PY' },
  { displayName: 'Español (Perú)', value: 'es-PE' },
  { displayName: 'Español (Puerto Rico)', value: 'es-PR' },
  { displayName: 'Español (República Dominicana)', value: 'es-DO' },
  { displayName: 'Español (Uruguay)', value: 'es-UY' },
  { displayName: 'Español (Venezuela)', value: 'es-VE' },
  { displayName: 'Euskara', value: 'eu-ES' },
  { displayName: 'Français', value: 'fr-FR' },
  { displayName: 'Galego', value: 'gl-ES' },
  { displayName: 'Hrvatski', value: 'hr_HR' },
  { displayName: 'IsiZulu', value: 'zu-ZA' },
  { displayName: 'Íslenska', value: 'is-IS' },
  { displayName: 'Italiano (Italia)', value: 'it-IT' },
  { displayName: 'Italiano (Svizzera)', value: 'it-CH' },
  { displayName: 'Magyar', value: 'hu-HU' },
  { displayName: 'Nederlands', value: 'nl-NL' },
  { displayName: 'Norsk bokmål', value: 'nb-NO' },
  { displayName: 'Polski', value: 'pl-PL' },
  { displayName: 'Português (Brasil)', value: 'pt-BR' },
  { displayName: 'Português (Portugal)', value: 'pt-PT' },
  { displayName: 'Română', value: 'ro-RO' },
  { displayName: 'Slovenčina', value: 'sk-SK' },
  { displayName: 'Suomi', value: 'fi-FI' },
  { displayName: 'Svenska', value: 'sv-SE' },
  { displayName: 'Türkçe', value: 'tr-TR' },
  { displayName: 'български', value: 'bg-BG' },
  { displayName: 'Pусский', value: 'ru-RU' },
  { displayName: 'Српски', value: 'sr-RS' },
  { displayName: '한국어', value: 'ko-KR' },
  { displayName: '中文 (普通话, 中国大陆)', value: 'cmn-Hans-CN' },
  { displayName: '中文 (普通话, 香港)', value: 'cmn-Hans-HK' },
  { displayName: '中文 (台灣)', value: 'cmn-Hant-TW' },
  { displayName: '粵語 (香港)', value: 'yue-Hant-HK' },
  { displayName: '日本語', value: 'ja-JP' },
  { displayName: 'Lingua latīna', value: 'la' },
  { displayName: 'Vietnamese', value: 'vi-VN' },
];

const ACTIVE_LANGUAGES = Object.freeze({
  EN: {
    value: 'en',
    displayName: 'English',
  },
  ESP: {
    value: 'esp',
    displayName: 'Espanol',
  },

  getAllActiveLanguage() {
    return [this.EN, this.ESP];
  },
});

const convertToGoogleTranslateCode = value => {
  const langMap = {
    'en-US': 'en',
    'es-MX': 'es',
    'af-ZA': 'af',
    'id-ID': 'id',
    'ms-MY': 'ms',
    'ca-ES': 'ca',
    'cs-CZ': 'cs',
    'de-DE': 'de',
    'en-AU': 'en',
    'en-CA': 'en',
    'en-IN': 'en',
    'en-NZ': 'en',
    'en-ZA': 'en',
    'en-GB': 'en',
    'es-AR': 'es',
    'es-BO': 'es',
    'es-CL': 'es',
    'es-CO': 'es',
    'es-CR': 'es',
    'es-EC': 'es',
    'es-SV': 'es',
    'es-ES': 'es',
    'es-US': 'es',
    'es-GT': 'es',
    'es-HN': 'es',
    'es-NI': 'es',
    'es-PA': 'es',
    'es-PY': 'es',
    'es-PE': 'es',
    'es-PR': 'es',
    'es-DO': 'es',
    'es-UY': 'es',
    'es-VE': 'es',
    'eu-ES': 'eu',
    'fr-FR': 'fr',
    'gl-ES': 'gl',
    'hr-HR': 'hr',
    'zu-ZA': 'zu',
    'is-IS': 'is',
    'it-IT': 'it',
    'it-CH': 'it',
    'hu-HU': 'hu',
    'nl-NL': 'nl',
    'nb-NO': 'no',
    'pl-PL': 'pl',
    'pt-BR': 'pt',
    'pt-PT': 'pt',
    'ro-RO': 'ro',
    'sk-SK': 'sk',
    'fi-FI': 'fi',
    'sv-SE': 'sv',
    'tr-TR': 'tr',
    'bg-BG': 'bg',
    'ru-RU': 'ru',
    'sr-RS': 'sr',
    'ko-KR': 'ko',
    'cmn-Hans-CN': 'zh',
    'cmn-Hans-HK': 'zh',
    'cmn-Hant-TW': 'zh',
    'yue-Hant-HK': 'zh',
    'ja-JP': 'ja',
    la: 'la',
    'vi-VN': 'vi',
  };

  return langMap[value] || 'en';
};

export { languages, ACTIVE_LANGUAGES, convertToGoogleTranslateCode };
