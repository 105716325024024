<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>My Expenses</f7-nav-title>
      <f7-nav-right>
        <f7-link
          icon-f7="plus"
          @click.native="isShowAddPopup = true"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      disable-button-text
      placeholder="Search By Expenses Name, Expenses Type, ..."
      :clear-button="true"
      class="text-color-white search-list"
      :value="searchText"
      @input="
        setExpenseSearchText($event.target.value);
        onSearch();
      "
      @keypress.native.enter="onSearch()"
      @searchbar:clear="
        setExpenseSearchText('');
        onSearch();
      "
      @searchbar:disable="
        setExpenseSearchText('');
        onSearch();
      "
    ></f7-searchbar>
    <div
      class="no-padding-top page-content infinite-scroll-content"
      @infinite="loadMore"
    >
      <f7-list class="no-margin">
        <f7-list-group media-list>
          <f7-list-item
            v-for="item in hits"
            :title="item.expensesName"
            :key="item.id"
            :reload-detail="true"
            swipeout
            :link="`/my-expenses/${item.id}`"
            :text-color="item.id === (expense || {}).id ? 'primary' : 'default'"
          >
            <div
              slot="text"
              class="display-flex justify-content-space-between align-item-center"
            >
              <div class="font-text">
                Expenses Type: {{ getReportType(item.reportType).displayName }}
              </div>
              <div
                v-if="item.reportType === EXPENSE_TYPE_MILEAGE"
                class="font-text"
              >
                {{ parseFloat(item.mileage) | NUMBER }} miles
              </div>
              <div
                v-else
                class="font-text"
              >
                {{ item.amount | currencyUSD }}
              </div>
            </div>
            <div slot="footer">
              <div class="font-text">
                Submitted Date:
                {{
                  (item.updatedAt
                    ? convertDateTimeFromAlgoliaToDate(item.updatedAt)
                    : convertDateTimeFromAlgoliaToDate(item.createdAt))
                    | MMDDYYYY
                }}
              </div>
            </div>
            <f7-chip
              slot="after"
              :text="getStatus(item.status).displayName"
              :color="getStatus(item.status).color"
            ></f7-chip>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <div
        v-show="hasMoreItems"
        class="preloader infinite-scroll-preloader"
      ></div>
      <f7-block
        class="text-align-center"
        v-show="!hasData"
        >No Data</f7-block
      >
    </div>
    <add-new-employee-expense-popup
      :isShow="isShowAddPopup"
      :isMyExpense="true"
      @close="isShowAddPopup = false"
    ></add-new-employee-expense-popup>
  </f7-page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { EXPENSE_TYPE_MILEAGE } from '@/utility/const';

import { auth } from '../../../services/firebase.service';
import AddNewEmployeeExpensePopup from '@/plugins/expenses/components/popups/AddNewEmployeeExpensePopup.vue';
import Menu from '../../../components/menu/Menu.vue';
import _ from 'lodash';
import { convertDateTimeFromAlgoliaToDate } from '@/utility/date-time-tool';

export default {
  components: {
    AddNewEmployeeExpensePopup,
    Menu,
  },
  data: () => {
    return {
      EXPENSE_TYPE_MILEAGE,

      allowInfinite: true,
      hasMoreItems: true,
      hasData: true,
      isShowAddPopup: false,
      convertDateTimeFromAlgoliaToDate,
    };
  },

  methods: {
    ...mapActions('common/expense', [
      'searchExpense',
      'loadMoreExpense',
      'resetSearch',
      'unbindExpense',
      'setExpenseSearchText',
      'setPayeeId',
    ]),
    ...mapActions('common/app-constant', ['initConstant']),

    getStatus(status) {
      return this.expenseStatusList.find(x => x.value === status) || {};
    },
    getReportType(type) {
      return this.expenseTypeList.find(x => x.value === type) || {};
    },

    onSearch() {
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      this.handleSearch();
    },

    handleSearch: _.debounce(function () {
      const self = this;
      return self
        .searchExpense({})
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        })
        .finally(() => {
          self.hasMoreItems = false;
        });
    }, 500),

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreExpense({
        page: this.page + 1,
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },
  },

  computed: {
    ...mapGetters('common/expense', [
      'expense',
      'hits',
      'nbPages',
      'page',
      'searchText',
    ]),
    ...mapGetters('common/app-constant', [
      'expenseTypeList',
      'expenseStatusList',
    ]),
  },

  created() {
    this.setPayeeId(auth.currentUser.uid);
    this.setExpenseSearchText('');
    this.onSearch();
    if (_.isEmpty(this.expenseStatusList)) {
      this.initConstant();
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list.searchbar input').focus();
      }
    });
  },

  beforeDestroy() {
    this.resetSearch();
    this.unbindExpense();
  },
};
</script>
<style>
input[type='search'] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
