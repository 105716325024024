import moment from 'moment';
import { sortLatest } from '@/utility/date-time-tool';
import { groupBy } from '@/utility/filter-tools';
import { firebase } from '../../../../services/firebase.service';
import _ from 'lodash';

export default {
  projectPhotoList: state => sortLatest(state.projectPhotoList),
  projectPhotosByAlbum: state => sortLatest(state.projectPhotosByAlbum),
  projectPhotoListGroup: state => {
    return groupBy(sortLatest(state.projectPhotoList), i =>
      i.createdAt
        ? moment(i.createdAt.toDate()).format('dddd, MMMM Do YYYY')
        : ''
    );
  },
  projectPhotoSelected: state => selectedIds => {
    return state.projectPhotoList.filter(r => selectedIds.includes(r.id));
  },
  projectPhotoByAlbumSelected: state => selectedIds => {
    return state.projectPhotosByAlbum.filter(r => selectedIds.includes(r.id));
  },
  photo: state => state.projectPhoto,
  projectPhotosSize: state => state.projectPhotosSize,
  photoFilter: state => state.photoFilter,
  photoFilterConditions: state => {
    const { startDate, endDate } = state.photoFilter;
    let conditions = [];

    if (!_.isEmpty(startDate)) {
      let from = new Date(moment(startDate[0], 'YYYY-MM-DD').startOf('day'));
      conditions.push({
        prop: 'createdAt',
        val: new firebase.firestore.Timestamp.fromDate(from),
        op: '>=',
      });
    }
    if (!_.isEmpty(endDate)) {
      let to = new Date(moment(endDate[0], 'YYYY-MM-DD').endOf('day'));
      conditions.push({
        prop: 'createdAt',
        val: new firebase.firestore.Timestamp.fromDate(to),
        op: '<=',
      });
    }
    return conditions;
  },
};
