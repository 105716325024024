<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Subscription</f7-nav-title>
    </f7-navbar>
    <div
      class="error-message"
      v-if="isExpiredSubscription || listFailInvoice.length"
    >
      <f7-icon
        f7="exclamationmark_octagon"
        class="margin-right-half"
      ></f7-icon>
      Your subscription is unused. Please retry charge to continue using.
    </div>
    <div class="row action-bar">
      <div class="action-button">
        <a @click="openHistoryPopup">
          <i class="icon f7-icons">doc_text</i>
          View Transaction History
        </a>
      </div>
      <div
        v-if="isOwner"
        class="action-button"
      >
        <a @click="handleCloseAccount">
          <i class="icon f7-icons">escape</i>
          Close Account
        </a>
      </div>
    </div>
    <f7-block>
      <template v-if="isOwner">
        <f7-row class="justify-content-center">
          <f7-segmented
            style="width: 300px"
            round
            tag="p"
          >
            <f7-button
              large
              round
              outline
              :active="!yearly"
              @click="yearly = false"
              >Monthly</f7-button
            >
            <f7-button
              large
              round
              outline
              :active="yearly"
              @click="yearly = true"
              >Yearly</f7-button
            >
          </f7-segmented>
        </f7-row>
        <p class="text-align-center">
          Save up to 10% with the yearly pricing plan.
        </p>
      </template>
      <f7-row v-if="isOwner">
        <f7-col
          v-for="p in allPlans"
          :key="p.id"
          width="100"
          small="33"
        >
          <f7-card>
            <f7-card-header class="justify-content-center">
              {{ p.name }}&nbsp;
              <span
                class="text-color-red"
                v-if="p.id == currentPlanId && yearly == defaultYearly"
              >
                (Current Plan)
              </span>
            </f7-card-header>
            <f7-card-content>
              <p class="text-align-center">
                <span class="money">{{ calculatePrice(p) | currencyUSD }}</span
                >&nbsp;
                <span class="per-unit">{{
                  `${yearly ? 'per year' : 'per month'}`
                }}</span>
              </p>
              <p
                v-for="(feature, index) in p.features"
                :key="index"
                class="text-align-center"
              >
                {{ feature }}
              </p>
            </f7-card-content>
            <f7-card-footer class="justify-content-center">
              <f7-button
                v-if="p.id !== currentPlanId || yearly !== defaultYearly"
                fill
                @click="upgradeSubscriptionClick(p)"
              >
                {{ currentPrice > calculatePrice(p) ? 'Downgrade' : 'Upgrade' }}
                <!-- {{ p.id == currentPlanId ? "Current Plan" : "Upgrade" }} -->
              </f7-button>
              <template v-else>
                <div
                  v-if="isExpiredSubscription"
                  class="display-flex align-items-center"
                >
                  <span class="text-color-red"><b>Expired</b></span>
                </div>
                <span
                  v-else
                  class="text-color-red padding-top-half"
                >
                  Your {{ `${yearly ? 'yearly' : 'monthly'}` }} subscription
                  will renew on
                  {{ currentSubscription.paymentExpired.toDate() | MMDDYYYY }}
                </span>
              </template>
            </f7-card-footer>
          </f7-card>
        </f7-col>
      </f7-row>
      <f7-row v-else>
        <f7-col
          width="100"
          small="33"
        ></f7-col>
        <f7-col
          width="100"
          small="33"
        >
          <f7-card>
            <f7-card-header class="justify-content-center">
              {{ currentPlan.name }}&nbsp;
              <span class="text-color-red"> (Current Plan) </span>
            </f7-card-header>
            <f7-card-content>
              <p class="text-align-center">
                <span class="money">{{
                  calculatePrice(currentPlan) | currencyUSD
                }}</span
                >&nbsp;
                <span class="per-unit">{{
                  `${yearly ? 'per year' : 'per month'}`
                }}</span>
              </p>
              <p
                v-for="(feature, index) in currentPlan.features"
                :key="index"
                class="text-align-center"
              >
                {{ feature }}
              </p>
            </f7-card-content>
            <f7-card-footer class="justify-content-center">
              <template>
                <div
                  v-if="isExpiredSubscription"
                  class="display-flex align-items-center"
                >
                  <span class="text-color-red"><b>Expired</b></span>
                </div>
                <span
                  v-else
                  class="text-color-red padding-top-half"
                >
                  Your {{ `${yearly ? 'yearly' : 'monthly'}` }} subscription
                  will renew on
                  {{ currentSubscription.paymentExpired.toDate() | MMDDYYYY }}
                </span>
              </template>
            </f7-card-footer>
          </f7-card>
        </f7-col>
        <f7-col
          width="100"
          small="33"
        ></f7-col>
      </f7-row>
    </f7-block>
    <div v-if="listFailInvoice.length > 0">
      <f7-block-title>Failed Payments</f7-block-title>
      <data-table
        :headers="headers"
        :items="listFailInvoice"
        :pageSize="listFailInvoice.length"
      >
        <template v-slot:body="{ item }">
          <td>{{ item.invoiceNumber }}</td>
          <td>{{ item.amount | currencyUSD }}</td>
          <td>{{ item.created }}</td>
          <td class="numeric-cell">
            <f7-link
              class="margin-right-half"
              icon-f7="arrow_2_circlepath"
              @click="retryCharge(item.id)"
            >
              &nbsp;&nbsp;Retry Charge
            </f7-link>
          </td>
        </template>
      </data-table>
    </div>
    <f7-block-title
      class="display-flex justify-content-space-between align-items-center margin-top"
    >
      <span>Payment Methods</span>
      <f7-button
        v-if="isOwner"
        fill
        @click="handleAddPaymentMethod"
      >
        Add Payment Method
      </f7-button>
    </f7-block-title>
    <f7-list media-list>
      <f7-list-item
        v-for="(pm, index) in listPaymentMethod"
        :key="index"
        :title="`${jsUcfirst(pm.card.brand)} .... ${pm.card.last4}`"
        :subtitle="`(Expires ${pm.card.exp_month}/${pm.card.exp_year})`"
      >
        <div slot="after">
          <div v-if="defaultPaymentMethod === pm.id">
            <f7-chip
              slot="after"
              text="Default"
              color="blue"
            ></f7-chip>
          </div>
          <div v-else>
            <f7-link
              v-if="isOwner"
              @click.stop="setToDefault(pm.id)"
              class="cursor-pointer underline margin-right-half"
            >
              Set To Default
            </f7-link>
            <f7-link
              v-if="isOwner"
              @click.stop="handleDeletePaymentMethod(pm.id)"
              class="cursor-pointer underline"
            >
              Delete
            </f7-link>
          </div>
        </div>
      </f7-list-item>
    </f7-list>
    <billing-history-popup ref="billingHistoryPopup"></billing-history-popup>
  </f7-page>
</template>
<script>
import DataTable from '@/components/datatables';
import { mapGetters, mapActions } from 'vuex';
import BillingHistoryPopup from '../components/popup/BillingHistoryPopup.vue';
// import { loadStripe } from "@stripe/stripe-js";
import moment from 'moment';
import { signOut } from '../../../services/authentication.service';
// import { auth } from "../../../services/firebase.service";
import axiosService from '../../../services/axios.service';
import Menu from '../../../components/menu/Menu.vue';

export default {
  components: {
    BillingHistoryPopup,
    DataTable,
    Menu,
  },
  data() {
    return {
      yearly: null,
    };
  },

  created() {
    this.$f7.preloader.show();
    const promises = [];
    this.bindPlans();

    this.bindSetting(this.tenantId).then(() => {
      promises.push(this.getFailInvoices());
      promises.push(this.getPaymentMethods());
      if (this.$f7route.query.session_id) {
        promises.push(
          this.updatePaymentMethodFromSession({
            sessionId: this.$f7route.query.session_id,
            subscriptionId: this.currentSubscription.stripeSubscriptionId,
          }).then(() => {
            this.$f7route.query = {};
            this.$f7router.updateCurrentUrl('/administration/subscription');
            this.showToastMessage('Update payment method successful!');
            this.getPaymentMethods();
          })
        );
      }
      Promise.all(promises)
        .then(res => {
          if (res[0].success === false || res[1].success === false) {
            this.$ri.dialog.openErrorDialog({
              title: 'Error',
              content: res[0].message || res[1].message,
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  _sefl.app.dialog.close();
                }
              },
            });
          }
        })
        .catch(err => {
          this.showErrorMessage(err.message);
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    });
  },

  computed: {
    ...mapGetters('setting/app/system', [
      'currentSubscription',
      'listFailInvoice',
      'listPaymentMethod',
      'defaultPaymentMethod',
      'setting',
    ]),
    ...mapGetters('administration/plan', ['planList']),
    ...mapGetters('administration/user-list-page/user', ['users']),
    ...mapGetters('setting/app/profile', ['currentUser']),
    ...mapGetters('common/app-constant', ['tenantId']),

    isOwner() {
      if (this.currentUser) {
        return (this.currentUser.customClaims || {}).role === 'owner';
      }

      return false;
    },

    headers() {
      return [
        {
          text: 'Invoice Number',
          align: 'left',
          sortable: false,
          value: 'invoiceNumber',
        },
        {
          text: 'Amount',
          value: 'amount',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Created',
          value: 'created',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          align: 'right',
        },
      ];
    },
    allPlans() {
      return ([...this.planList] || []).sort((a, b) => a.price - b.price);
    },
    defaultYearly() {
      return !!(
        this.currentSubscription && this.currentSubscription.yearlyPlan
      );
    },
    currentPlanId() {
      if (!this.currentSubscription) return null;
      return this.currentSubscription.size || '';
    },
    currentPlan() {
      return this.planList.find(r => r.id === this.currentPlanId) || {};
    },
    currentPrice() {
      return this.defaultYearly
        ? this.currentPlan.price * 12 * 0.9
        : this.currentPlan.price;
    },

    isExpiredSubscription() {
      if (this.currentSubscription && this.currentSubscription.paymentExpired) {
        const currentDate = moment(new Date(), 'MM/DD/YYYY');
        const dueDate = moment(
          this.currentSubscription.paymentExpired.toDate(),
          'MM/DD/YYYY'
        );
        const secondsDiff = dueDate.diff(currentDate, 'seconds');
        const daysDiff = secondsDiff / 60 / 60 / 24;
        if (daysDiff < 0) return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions('setting/app/system', [
      'bindSetting',
      'upgradeSubscription',
      'createCheckoutSession',
      'updatePaymentMethod',
      'updatePaymentMethodFromSession',
      'getFailInvoices',
      'payInvoice',
      'getPaymentMethods',
      'deletePaymentMethod',
      'setDefaultPaymentMethod',
      'setListPaymentMethod',
      'closeAccount',
    ]),
    ...mapActions('administration/plan', ['bindPlans']),
    ...mapActions('administration/user-list-page/user', ['getUserList']),

    jsUcfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    calculatePrice(item) {
      return item.price * (this.yearly ? 12 : 1) * (this.yearly ? 0.9 : 1);
    },
    openHistoryPopup() {
      this.$refs.billingHistoryPopup.open();
    },

    getCloseAccountToken(tenantId, uid, closeAccountDate) {
      return axiosService.get(
        `/subscription/get-close-account-token?appUrl=${import.meta.env.VITE_HOST_DOMAIN}&tenantId=${tenantId}&uid=${uid}&closeAccountDate=${closeAccountDate}`
      );
    },

    sendCloseAccountEmail(token) {
      return axiosService.post('/notification/push-email', {
        to: this.currentUser.email,
        subject: {
          message: 'RooferIntel Close Account Email',
        },
        template: 'close-account',
        content: {
          accountName: this.currentUser.displayName,
          reopenUrl: `${import.meta.env.VITE_BASE_API}/tenant/reopen/${token}`,
        },
        attachments: [],
        scheduleSendingTime: '',
      });
    },

    handleCloseAccount() {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: 'Confirm close account',
        content:
          'You are about to <strong>close</strong> your account, you will no longer have access to your data.<br/>' +
          'To reactivate your account in the future, you will need to contact RooferTech Support.',
        cssClass: this.$device.desktop ? 'wide-dialog' : '',
        textButton: 'Yes, close my account',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            const tenantId = this.currentUser.tenantId;
            const uid = this.currentUser.uid;
            const closeAccountDate = new Date();

            app.$f7.preloader.show();
            app
              .getCloseAccountToken(tenantId, uid, closeAccountDate)
              .then(result => {
                if (result.data.success) {
                  return app
                    .sendCloseAccountEmail(result.data.token)
                    .then(() => true);
                }
                return false;
              })
              .then(emailSent => {
                if (emailSent) {
                  return app.closeAccount({
                    closeAccountDate: new Date(),
                    isAccountClosed: true,
                  });
                } else {
                  return null;
                }
              })
              .then(() => {
                signOut();
                window.location.href = '/login';
              })
              .catch(err => {
                app.showErrorMessage(err.message);
              })
              .finally(() => {
                this.$f7.preloader.hide();
              });
          }
        },
      });
    },

    async upgradeSubscriptionClick(plan) {
      // check users after upgrade
      await this.getUserList();
      const usersActive = this.users.filter(r => !r.disabled) || [];
      if (plan.limitUsers !== null && usersActive.length > plan.limitUsers) {
        this.$ri.dialog.openErrorDialog({
          title: 'Error',
          content: `
                    You have <b>${usersActive.length}</b> active users and you are purchasing <b>${plan.limitUsers}</b> license seats!<br>
                    - Increase the number of license seats or<br>
                    - Disable users from the Administration Panel
                    `,
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
        return;
      }

      // show confirm popup
      this.$ri.dialog.openSuccessDialog({
        title: `${
          this.currentPrice > this.calculatePrice(plan)
            ? 'Downgrade'
            : 'Upgrade'
        } Subscription.`,
        content: `Are you sure you want to switch to <b>${plan.name}</b> subscriptions?`,
        // hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$f7.preloader.show();
            this.upgradeSubscription({
              size: plan.id,
              priceId: this.yearly ? plan.yearly : plan.monthly,
              yearlyPlan: this.yearly,
            })
              .then(res => {
                if (res.success) {
                  this.getFailInvoices({
                    subscriptionId:
                      this.currentSubscription.stripeSubscriptionId,
                  }).then(res => {
                    if (res && res.data && res.data.length) {
                      this.showToastMessage('Upgrade Failed!');
                    } else {
                      this.showToastMessage('Upgrade successful!');
                    }
                  });
                } else {
                  this.$ri.dialog.openWarningDialog({
                    title: 'Error',
                    content: `${res.message}`,
                    hideCancelButton: true,
                    onClick: (_sefl, index) => {
                      if (index === 0) {
                        _sefl.app.dialog.close();
                      } else if (index === 1) {
                        _sefl.app.dialog.close();
                      }
                    },
                  });
                }
              })
              .catch(err => {
                this.showErrorMessage(err.message);
              })
              .finally(() => {
                this.$f7.preloader.hide();
              });
          }
        },
      });
    },
    async handleAddPaymentMethod() {
      this.$f7.preloader.show();
      try {
        // const stripe = await loadStripe(process.env.VITE_STRIPE_PUBLISHABLE_KEY);
        // const checkoutSession = await this.createCheckoutSession({
        //   frontendUrl: window.location.origin,
        //   subscriptionId: this.currentSubscription.stripeSubscriptionId
        // });
        // await stripe.redirectToCheckout({
        //   sessionId: checkoutSession.id
        // });
      } catch (err) {
        this.showErrorMessage(err.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },

    retryCharge(invoiceId) {
      this.$f7.preloader.show();
      this.payInvoice({ invoiceId })
        .then(res => {
          if (res.success) {
            this.getFailInvoices();
            this.showToastMessage('Pay successful!');
          } else {
            this.$ri.dialog.openErrorDialog({
              title: 'Error',
              content: `${res.message} Please check your payment method before paying.`,
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  _sefl.app.dialog.close();
                }
              },
            });
          }
        })
        .catch(err => {
          this.showErrorMessage(err.message);
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    setToDefault(paymentMethodId) {
      this.$f7.preloader.show();
      this.updatePaymentMethod({
        subscriptionId: this.currentSubscription.stripeSubscriptionId,
        paymentMethodId,
      })
        .then(res => {
          if (res.success) {
            this.showToastMessage('Set to default successful!');
            // set default payment method ID
            this.setDefaultPaymentMethod(paymentMethodId);
          } else {
            this.$ri.dialog.openErrorDialog({
              title: 'Error',
              content: `${res.message}`,
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  _sefl.app.dialog.close();
                }
              },
            });
          }
        })
        .catch(err => {
          this.showErrorMessage(err.message);
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    handleDeletePaymentMethod(paymentMethodId) {
      this.$f7.preloader.show();
      this.deletePaymentMethod({ paymentMethodId })
        .then(res => {
          if (res.success) {
            this.showToastMessage('Delete payment method successful!');
            // filter list payment
            this.setListPaymentMethod(
              this.listPaymentMethod.filter(pm => pm.id !== paymentMethodId)
            );
          } else {
            this.$ri.dialog.openErrorDialog({
              title: 'Error',
              content: `${res.message}`,
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  _sefl.app.dialog.close();
                }
              },
            });
          }
        })
        .catch(err => {
          this.showErrorMessage(err.message);
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    showToastMessage(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000,
        })
        .open();
    },

    showErrorMessage(messages) {
      this.$ri.dialog.openSuccessDialog({
        title: 'Connect to Stripe Online',
        content: messages,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            window.location.href = '/administration/subscription';
          }
        },
      });
    },
  },

  watch: {
    currentSubscription: {
      handler(val) {
        if (val) {
          this.yearly = val.yearlyPlan;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.money {
  font-size: 2rem;
}

.error-message {
  background-color: var(--f7-theme-color);
  padding: 8px 16px;
  color: white;
  display: flex;
  align-items: center;
}

.action-bar {
  position: sticky;
  z-index: 502;
  background-color: var(--f7-color-img-neutral);
  border: 1px solid rgb(216, 214, 214);
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;
}

.action-button {
  padding: 2px 10px;
  font-weight: 600;
  border-left: 1px solid rgb(216, 214, 214);
  box-sizing: border-box;

  a {
    color: gray;
    display: flex;
    align-items: center;

    i {
      padding-right: 4px;
    }
  }

  a:hover {
    font-weight: 600;
    color: var(--f7-theme-color);

    i {
      font-weight: 600;
    }
  }
}

.underline {
  text-decoration: underline;
}
</style>

<style>
.wide-dialog {
  width: 600px !important;
  margin-left: calc(-1 * calc(600px) / 2);
}
</style>
