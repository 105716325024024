<template>
  <f7-app :params="f7Params">
    <!-- <f7-statusbar></f7-statusbar> -->
    <f7-panel
      left
      reveal
      :class="{ 'view-height-mobile': !($device.desktop || $device.ipad) }"
    >
      <f7-view
        class="view-left"
        url="/panel-left"
        name="left"
        links-view=".view-main"
      ></f7-view>
    </f7-panel>

    <f7-view
      id="main-view"
      url="/"
      name="main"
      main
      class="safe-areas"
      :class="{ 'view-height-mobile': !($device.desktop || $device.ipad) }"
      :master-detail-breakpoint="768"
      :push-state-separator="''"
      :push-state="true"
      :masterDetailResizable="true"
      reload-all
      ios-dynamic-navbar
    >
    </f7-view>
    <template v-if="isShowGlobalSearch">
      <global-search-button></global-search-button>
      <global-notify-popup></global-notify-popup>
      <global-search-popup></global-search-popup>
    </template>
    <menu-mobile v-show="!($device.desktop || $device.ipad)" />
  </f7-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import routes from './services/routes.service';
import { getToken } from './services/authentication.service';
import { setLayoutTheme } from './utility/theme';
import _ from 'lodash';
import GlobalSearchButton from './components/buttons/GlobalSearchButton.vue';
import GlobalSearchPopup from './components/popups/GlobalSearchPopup.vue';
import GlobalNotifyPopup from './components/popups/GlobalNotifyPopup.vue';
import MenuMobile from './components/menu/MenuMobile.vue';
import {
  getMessagingToken,
  setupOnMessageListener,
} from '@/services/firebase.service';

export default {
  components: {
    GlobalSearchButton,
    GlobalSearchPopup,
    GlobalNotifyPopup,
    MenuMobile,
  },
  name: 'app',
  data() {
    let theme = 'auto';
    if (this.$device.ios) {
      theme = 'ios';
    } else if (this.$device.android) {
      theme = 'md';
    } else if (this.$device.desktop) {
      theme = 'aurora';
    }

    return {
      f7Params: {
        id: 'io.framework7.testapp',
        theme,
        routes,
        popup: {
          closeOnEscape: true,
        },
        sheet: {
          closeOnEscape: true,
        },
        popover: {
          closeOnEscape: true,
        },
        actions: {
          closeOnEscape: true,
        },
        calendar: {
          routableModals: false,
        },
      },
      hasLoggedRoute: false,
      flashTitleIntervalId: null,
      isHaveNotify: false,
      currentPath: '',
    };
  },

  computed: {
    ...mapState('setting/app/profile', ['dark']),
    ...mapGetters('setting/app/profile', [
      'currentUser',
      'getDisplayMenu',
      'checkRoleGuard',
      'checkGroupGuard',
    ]),
    ...mapGetters('setting/app/system', ['isShowMenuCover']),
    ...mapGetters('setting/app/group', ['groupList', 'groupListByUserId']),
    ...mapGetters('common/global-search', ['isShowGlobalSearch']),
    ...mapGetters('common/notification', ['userTokenList']),
  },

  created() {
    this.setRoutes(_.cloneDeep(this.f7Params.routes));

    const self = this;

    getToken(async () => {
      await this.initConstant();
      if (process.env.NODE_ENV === 'development') {
        const date = new Date();
        // eslint-disable-next-line no-console
        console.log(
          `[AUTH] Fetching profile data... ${date.toLocaleTimeString()}.${date.getMilliseconds()}`
        );
      }
      await this.getUserProfile();
      await this.getTokenListBys(this.currentUser.uid);
      // TODO: Disable check subscription for more discussion of payment feature
      // self.subcribeNotification();
      // allway bind group for checking left menu permission when init app
      await self.checkAdminGroup(self.currentUser.uid);
      await self.getGroupList([{ prop: 'isDeleted', op: '==', val: false }]);
      const groupsByUid = this.groupListByUserId(this.currentUser.uid);
      this.setCurrentGroups(groupsByUid);
      this.calculateListParentMenuItems();
      this.checkNotification();
      this.getNotificationListByUser();
      this.getUserList();
    });
    this.getTenantId();
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleEscKeyDown);
    window.removeEventListener(
      'visibilitychange',
      this.handleCheckVisibilitychange
    );
  },

  mounted() {
    // const viewResize = document.querySelector(".view-resize-handler");
    // viewResize.style.width = "2px";
    // viewResize.appendChild(this.$refs.lineHandler.$el);
    // viewResize.style.cursor = "auto";
    // defind the desktop device
    localStorage.setItem('desktop', this.$f7.device.desktop);
    window.addEventListener('keydown', this.handleEscKeyDown);
    this.handleCheckVisibilitychange();
    let showInstallNotificationCalled = false;
    // Log route hiện tại khi route thay đổi
    this.$f7.on('routeChange', route => {
      if (!this.hasLoggedRoute) {
        this.currentPath = route.path;
        this.hasLoggedRoute = true;
      }
      if (
        import.meta.env.MODE !== 'development' &&
        !showInstallNotificationCalled &&
        this.currentPath !== '/proposal-view/' &&
        this.currentPath !== '/roofing-choices-view/' &&
        this.currentPath !== '/agreement-view/' &&
        this.currentPath !== '/certificate-view/' &&
        this.currentPath !== '/letter-view/' &&
        this.currentPath !== '/contract-view/' &&
        this.currentPath !== '/invoices-view/'
      ) {
        this.showInstallNotification();
        showInstallNotificationCalled = true;
      }
    });
    setLayoutTheme(this, this.dark);

    this.detectHasNotify();
  },

  watch: {
    isHaveNotify: {
      handler(newVal) {
        if (newVal) {
          this.flashTitle();
        } else {
          this.clearIntervalFlatTittle();
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('common/app-constant', ['initConstant', 'getTenantId']),
    ...mapActions('common/notification', [
      'addNewUserToken',
      'updateUserToken',
      'getTokenListBys',
      'getNotificationListByUser',
      'getNotifyTemplatesList',
    ]),
    ...mapActions('common/user', ['getUserList']),
    ...mapActions('setting/app/profile', [
      'setRoutes',
      'getUserProfile',
      'calculateListParentMenuItems',
      'setCurrentGroups',
    ]),
    ...mapActions('setting/app/group', ['checkAdminGroup', 'getGroupList']),
    ...mapActions('web-contact/web-contact-info', ['subcribeCollection']), //TODO: need to review

    handleEscKeyDown(event) {
      if (event.key === 'Escape') {
        //Manually create 1 click on the left corner of the screen
        this.clickAtPosition();
        setTimeout(() => {
          const elements = document.querySelectorAll('.popup-behind');

          if (elements) {
            elements.forEach(e => {
              e.classList.remove('popup-behind');
            });
          }
        }, 500);
      }
    },

    clickAtPosition() {
      const screenHeight = window.innerHeight;

      const x = 100;
      const y = screenHeight - 100;

      const clickEvent = new MouseEvent('click', {
        clientX: x,
        clientY: y,
        bubbles: true,
      });

      document.dispatchEvent(clickEvent);
    },

    handleCheckVisibilitychange() {
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState !== 'hidden') {
          const broadcast = new BroadcastChannel('sw-messages');
          broadcast.postMessage({
            msg: 'backgroundMessageReceived',
            data: false,
          });
        }
      });
    },

    detectHasNotify() {
      const broadcast = new BroadcastChannel('sw-messages');
      broadcast.onmessage = event => {
        if (event.data && event.data.msg === 'backgroundMessageReceived') {
          this.isHaveNotify = event.data.data;
          this.getNotificationListByUser();
        }
      };
    },

    clearIntervalFlatTittle() {
      clearInterval(this.flashTitleIntervalId);
    },

    flashTitle() {
      const title = {
        default: 'RooferIntel Roofing Software Solutions',
        notify: 'You have new notifications!',
      };

      this.flashTitleIntervalId = setInterval(() => {
        document.title = title.notify;
        setTimeout(() => {
          document.title = title.default;
        }, 1000);
      }, 2000);
    },

    showToast(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000,
        })
        .open();
    },
    async showToastWithToken() {
      const curToken = await getMessagingToken();
      const isTokenUserId = !this.userTokenList.some(
        token => token.userId === this.currentUser.uid
      );
      if (this.userTokenList.length == 0 || isTokenUserId) {
        this.addNewUserToken({
          userId: this.currentUser.uid,
          userName: this.currentUser.displayName,
          userToken: curToken,
        });
      } else {
        const userToken = this.userTokenList.find(
          token => token.userId === this.currentUser.uid
        );
        const dataToken = curToken == '' ? userToken.userToken : curToken;
        this.updateUserToken({
          id: userToken.id,
          doc: {
            userToken: dataToken,
          },
        });
      }
      setupOnMessageListener(payload => {
        this.getNotificationListByUser();
        this.showToast(payload.notification.body);
      });
    },
    async checkNotification() {
      // Check if the OS is iOS
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      if (isIOS) {
        // Return early if it's iOS
        return;
      }

      if ('Notification' in window) {
        if (Notification.permission === 'default') {
          Notification.requestPermission().then(async permission => {
            if (permission === 'granted') {
              this.getNotifyTemplatesList();
              this.showToastWithToken();
            } else {
              this.showToast(
                'you need open notification permission in browser setting'
              );
            }
          });
        } else if (Notification.permission === 'granted') {
          this.getNotifyTemplatesList();
          this.showToastWithToken();
        } else {
          this.showToast(
            'you need open notification permission in browser setting'
          );
        }
      } else {
        alert('This browser does not support desktop notification');
      }
    },

    showInstallNotification() {
      if (
        (this.$device.ios && !this.isInStandaloneModeSafari()) ||
        (this.$device.android && !this.isInStandaloneModeChrome())
      ) {
        if (!this.notificationInstall) {
          let textContent =
            "<div style='font-weight: bold'>Want to install the app now?</div>" +
            '<ol><li>Tap ' +
            (this.$device.ios
              ? "<i class='icon f7-icons' style='font-size: 16px; margin-bottom: 6px;'>square_arrow_up</i>"
              : "<i class='icon f7-icons' style='font-size: 16px; margin-bottom: 6px;'>ellipsis_vertical</i>") +
            ' in the top right<br>' +
            '</li><li>Add to Home Screen</li></ol>';

          this.notificationInstall = this.$f7.notification.create({
            // icon: '<i class="icon icon-f7"></i>',
            title: 'RooferIntel WebApp',
            closeTimeout: 15000,
            closeButton: true,
            text: textContent,
            closeOnClick: true,
          });
        }
        this.notificationInstall.open();
      }
    },

    // Detects if device is in standalone mode
    isInStandaloneModeSafari() {
      return window.navigator.standalone === true;
    },

    isInStandaloneModeChrome() {
      return window.matchMedia('(display-mode: standalone)').matches;
    },

    subcribeNotification() {
      this.subcribeCollection(data => {
        if (data.length == 1 && data[0].type == 'added') {
          this.showSubcribeNofitication();
        }
      });
    },

    showSubcribeNofitication() {
      if (!this.user.allowReceiveNotification) return;
      const self = this;
      if (!self.notificationFull) {
        self.notificationFull = self.$f7.notification.create({
          icon: '<i class="icon icon-f7"></i>',
          title: 'RooferIntel',
          titleRightText: 'now',
          subtitle: 'You have a new lead!',
          text: "Please visit to <a href='/online-leads'>Online Leads</a> menu to see new leads.",
          closeTimeout: 7000,
        });
      }
      self.notificationFull.open();
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

// #main-view > .navbar {
//   background-color: rgba(255, 255, 255, 0.1);
// }

// #main-view > .navbar::after {
// height: 0px;
// }

.view-height-mobile {
  height: calc(
    100% - var(--f7-appbar-app-offset, 0px) - 56px
  ) !important; // 56 is menu mobile height
}

.panel-visible-by-breakpoint::before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
  content: '';
  z-index: 6000;
}

.view-left::after {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
  content: '';
  z-index: 6000;
}

.cursor-pointer {
  cursor: pointer;
}

.add-button-icon {
  font-size: 18px;
  color: var(--f7-theme-color);
  display: flex;
}

.ps__rail-x,
.ps__rail-y {
  z-index: 99999;
}

ol {
  padding-inline-start: 20px;
  margin: 6px 0;
}
</style>
