<template>
  <div
    class="no-padding-top page-content infinite-scroll-content"
    style="overflow: auto"
    @infinite="loadMore"
  >
    <div>
      <f7-list class="no-margin">
        <f7-list-group media-list>
          <f7-list-item
            v-for="item in hits"
            :title="item.safetyNumber"
            :link="toDetail(item)"
            :key="item.id"
            :reload-detail="true"
            :text-color="
              item.safetyNumber === safetyNumber ? 'primary' : 'default'
            "
          >
            <div slot="footer">
              <div class="font-text">
                Uploaded on:
                {{ convertDate(item.createdAt) }}
              </div>
            </div>
            <div slot="text">
              <div class="item-text-slot">
                {{ item.title }}
              </div>
            </div>
            <f7-chip
              slot="after"
              :text="getStatus(item.status).text"
              :color="getStatus(item.status).color"
            ></f7-chip>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <div
        v-show="hasMoreItems"
        class="preloader infinite-scroll-preloader"
      ></div>
      <f7-block
        class="text-align-center"
        v-show="!hasData"
        >No Data</f7-block
      >
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onUnmounted,
  ref,
  onMounted,
} from 'vue';
import { useSafetyStore } from '../../../../stores/safety-hub/actions';
import { convertDateTimeFromAlgoliaToDate } from '@/utility/date-time-tool';

export default defineComponent({
  components: {
    // Menu,
  },
  setup() {
    const instance = getCurrentInstance() as any;
    const store = useSafetyStore();
    const router = instance.proxy.$f7router;
    const count = ref(0);
    const safetyList = computed(() => store.safetyList);
    const hits = computed(() => store.hits);
    const page = computed(() => store.page);
    const nbPages = computed(() => store.nbPages);
    const safetyNumber = computed(() => store.safetyNumber);

    let allowInfinite = ref(true);
    let hasMoreItems = ref(true);
    let hasData = ref(true);

    const formatDateToMMDDYYYY = (date: Date) => {
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    };
    const convertDate = (updatedAt: any) => {
      const date = convertDateTimeFromAlgoliaToDate(updatedAt);
      return formatDateToMMDDYYYY(date);
    };

    const destroyStore = () => {
      store.$dispose();
    };

    const resetStore = () => {
      store.$reset();
    };
    const toDetail = (item?: { safetyNumber: string }) =>
      item ? `/safety/${item.safetyNumber}` : '#';

    const getStatus = (status: string) => {
      return status === 'active'
        ? { color: 'green', text: 'Active' }
        : { color: 'gray', text: 'Inactive' };
    };
    const init = () => {
      if (!hasMoreItems) {
        instance.proxy.preloader.show();
      }
      onSearch().then(() => {
        if (!hasMoreItems) {
          instance.proxy.preloader.hide();
        }
      });
    };
    const onSearch = () => {
      hasMoreItems.value = true;
      allowInfinite.value = true;
      hasData.value = true;
      return store
        .searchSafety([])
        .then(() => {
          if (page.value + 1 === nbPages.value) {
            hasMoreItems.value = false;
            allowInfinite.value = false;
          }
          if (hits.value.length === 0 && nbPages.value === 0) {
            hasData.value = false;
            hasMoreItems.value = false;
            allowInfinite.value = false;
          }
        })
        .finally(() => {
          hasMoreItems.value = false;
        });
    };

    const loadMore = () => {
      if (!allowInfinite.value) return;
      allowInfinite.value = false;
      hasMoreItems.value = true;
      hasData.value = true;

      store.loadMoreSafety([], page.value + 1).then(() => {
        if (hits.value.length === 0 && nbPages.value === 0) {
          hasData.value = false;
          hasMoreItems.value = false;
          return;
        }

        if (page.value + 1 === nbPages.value) {
          hasMoreItems.value = false;
          return;
        }
        allowInfinite.value = true;
      });
    };
    onMounted(() => {});

    onBeforeUnmount(() => {
      // destroyStore();
    });

    // onUnmounted: Được gọi sau khi component đã bị hủy
    onUnmounted(() => {});

    return {
      count,
      safetyList,
      toDetail,
      init,
      hits,
      convertDate,
      getStatus,
      allowInfinite,
      hasMoreItems,
      hasData,
      resetStore,
      destroyStore,
      loadMore,
      safetyNumber,
    };
  },
});
</script>

<style scoped>
.item-text-slot {
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
