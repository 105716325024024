<template>
  <div v-if="$f7.device.desktop || $f7.device.ipad">
    <div v-if="activeTabId === 'export'">
      <f7-row class="row1">
        <f7-toolbar
          class="custom-link"
          tabbar
        >
          <f7-link
            v-for="(link, index) in listDataExport"
            :key="index"
            class="button"
            @click="$emit('onClick', link.tabActive)"
            :tab-link="link.tabLink"
            :tab-link-active="tabActive === link.tabActive"
            :text="link.text"
          >
          </f7-link>
        </f7-toolbar>
      </f7-row>
    </div>

    <div v-if="activeTabId === 'import'">
      <f7-row class="row2">
        <f7-toolbar
          class="custom-link"
          tabbar
        >
          <f7-link
            v-for="(link, index) in listDataImport"
            :key="index"
            class="button"
            @click="$emit('onClick', link.tabActive)"
            :tab-link="link.tabLink"
            :tab-link-active="tabActive === link.tabActive"
            :text="link.text"
          >
          </f7-link>
        </f7-toolbar>
      </f7-row>
    </div>
  </div>
  <div v-else>
    <div
      class="input input-outline input-dropdown"
      style="padding: 10px 0; margin: 0 10px"
    >
      <select
        @change="handleChange"
        v-model="selected"
      >
        <option
          v-for="(link, index) in currentListData"
          :key="index"
          :value="link.tabActive"
        >
          {{ link.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeTabId: String,
    tabActive: String,
  },
  data: () => {
    return {
      selected: '',
      listDataExport: [
        {
          tabLink: '#exportCompany',
          text: 'Company',
          tabActive: 'export-company',
        },
        {
          tabLink: '#exportWorking',
          text: 'Working Hours',
          tabActive: 'export-working',
        },
        {
          tabLink: '#exportExpenses',
          text: 'Expenses',
          tabActive: 'export-expenses',
        },
        {
          tabLink: '#exportPO',
          text: 'PO Payable Invoice',
          tabActive: 'export-po',
        },
        {
          tabLink: '#mapProject',
          text: 'Map Project',
          tabActive: 'map-project',
        },
      ],
      listDataImport: [
        {
          tabLink: '#importCompany',
          text: 'Company',
          tabActive: 'import-company',
        },
        {
          tabLink: '#importInvoice',
          text: 'Invoices',
          tabActive: 'import-invoice',
        },
        {
          tabLink: '#importCheckPO',
          text: 'PO Check',
          tabActive: 'import-po',
        },
        {
          tabLink: '#importPayment',
          text: 'Payments',
          tabActive: 'import-payment',
        },
      ],
    };
  },
  created() {
    this.selected = this.tabActive;
  },
  computed: {
    currentListData() {
      return this.activeTabId === 'export'
        ? this.listDataExport
        : this.listDataImport;
    },
  },
  methods: {
    handleChange() {
      this.$emit('onClick', this.selected);
    },
  },
  watch: {
    tabActive() {
      this.selected = this.tabActive;
    },
  },
};
</script>

<style scoped>
.button {
  margin-left: 5px;
  font-weight: 500;
  background: white;
}
.custom-link {
  background-color: var(--f7-color-light-gray);
}
.custom-link ::v-deep .tab-link:not(.tab-link-active) {
  background-color: var(--f7-color-text-2-neutral);
  color: var(--f7-color-text-3-neutral);
}

.tab-link-active {
  color: white;
  background: #f05034;
}

.toolbar {
  left: 5px;
  background-color: var(--f7-color-bg-dark);
}

.row1 {
  width: 680px;
}

.row2 {
  width: 540px;
}
</style>
