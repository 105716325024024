<template>
  <div class="padding-half text-color-black">
    <h3>Financing Proposal</h3>
    <financing-proposal-table
      class="financing-proposal-table"
      :dataTable="data.financingProposal"
      isReadonly
    />
  </div>
</template>
<script>
import FinancingProposalTable from '../tables/FinancingProposalTable.vue';
export default {
  props: {
    data: Object,
  },
  components: {
    FinancingProposalTable,
  },
};
</script>
<style lang="scss" scoped>
.financing-proposal-table {
  &::v-deep .card {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0px;
  }
}
</style>
