<template>
  <signature-popup
    ref="signaturePopup"
    @saveSignature="save"
    :isUserSign="isCustomer"
  ></signature-popup>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { firebase } from '@/services/firebase.service';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { TEMPLATE_TYPE_AGREEMENT } from '../../../../utility/const';
import SignaturePopup from '../../../../components/popups/SignaturePopup.vue';

export default {
  components: { SignaturePopup },
  props: {
    agreement: Object,
    isCustomer: { type: Boolean, default: false },
  },
  data() {
    return {
      additionalData: {},
    };
  },
  computed: {
    ...mapGetters('setting/app/profile', ['currentUser']),
    ...mapGetters('common/user-signature', ['signatureList']),
    signatureSection() {
      return this.agreement.sections.find(
        r => r.sectionId === 'owners-acceptance'
      );
    },

    indexOfSignatureSection() {
      return this.agreement.sections.findIndex(
        r => r.sectionId === 'owners-acceptance'
      );
    },

    indexOfFooterSection() {
      return this.agreement.sections.findIndex(
        r => r.sectionId === 'footer-section'
      );
    },
  },
  methods: {
    ...mapActions('dashboard/agreement', ['uploadImage', 'updateAgreement']),
    ...mapActions('common/user-signature', ['getSignListBys']),
    ...mapActions('dashboard/template', ['getTemplateBy']),
    ...mapActions({
      getSetting: 'setting/app/system/getSetting',
    }),

    async openPopup() {
      const agreementSign = await this.$refs.signaturePopup.getAdditionalData();
      this.additionalData = this.agreement.userIp
        ? {
            signerIp: this.agreement.userIp,
            time: firebase.firestore.Timestamp.now(),
          }
        : agreementSign;
      if (this.currentUser.uid) {
        this.$f7.preloader.show();
        await this.getSignListBys(this.currentUser.uid);
        let userHasSignature = this.signatureList[0];

        if (userHasSignature && !this.isCustomer) {
          this.$f7.preloader.hide();
          this.checkAskSignBefore(() => {
            this.save({
              imageBase64: userHasSignature.userSignature,
              fullName: userHasSignature.userSignName,
              currentUser: this.currentUser,
              signerIp: this.additionalData,
              callback: () => {},
            });
          }, userHasSignature);
        } else {
          this.$f7.preloader.hide();
          this.$refs.signaturePopup.openPopup();
        }
      } else {
        this.$f7.preloader.hide();
        this.$refs.signaturePopup.openPopup();
      }
    },

    showErrorDialog(errorMessage) {
      this.$ri.dialog.openErrorDialog({
        title: 'Error',
        content: errorMessage,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        },
      });
    },
    checkAskSignBefore(callback, userHasSignature) {
      const app = this;
      const content = `<div style="margin-top: 16px">
        <div style="margin-bottom: 14px; width: 100%; border-radius: 4px; overflow: hidden; background-color: var(--f7-color-bg-13-neutral)">
            <img style="max-width: 257px; max-height: 200px; width: 100%; background-color: var(--f7-color-bg-13-neutral)" src="${userHasSignature.userSignature}" alt=""/>
          </div>
          <div style="text-align: center;">${userHasSignature.userSignName}</div>
        </div>`;
      app.$ri.dialog.openInfoDialog({
        title: 'Do you want to use the signature that has been saved before?',
        content,
        cancelButtonCaption: 'Sign again',
        textButton: 'Use it',
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
            this.$refs.signaturePopup.openPopup();
          } else if (index === 1) {
            _sefl.app.dialog.close();
            if (callback) callback();
          }
        },
      });
    },

    async uploadSignatureImage(imageBase64) {
      if (this.isCustomer) {
        try {
          const response = await axios.post(
            `agreement/upload-file/${this.agreement.id}`,
            {
              projectId: this.agreement.projectId,
              base64Data: imageBase64.split(',')[1],
              fileName: 'owner-signature',
              meta: { type: 'image/png' },
            },
            {
              baseURL: import.meta.env.VITE_BASE_API,
              withCredentials: true,
              headers: {
                token: this.$f7route.query.token,
              },
            }
          );
          return response.data;
        } catch (error) {
          return { success: false, message: error.message };
        }
      }
      const response = await this.uploadImage({
        projectId: this.agreement.projectId,
        agreementId: this.agreement.id,
        base64Data: imageBase64,
        fileName: 'company-signature',
      });
      return { success: true, data: response };
    },

    async getAgreementTemplate() {
      if (this.isCustomer) {
        try {
          const response = await axios.get(
            `agreement/agreement-template/${this.agreement.id}`,
            {
              baseURL: import.meta.env.VITE_BASE_API,
              withCredentials: true,
              headers: {
                token: this.$f7route.query.token,
              },
            }
          );
          return response.data;
        } catch (error) {
          return { success: false, message: error.message };
        }
      }
      const response = await this.getTemplateBy([
        {
          prop: 'templateType',
          op: '==',
          val: TEMPLATE_TYPE_AGREEMENT,
        },
      ]);
      return { success: true, data: response[0] || {} };
    },

    async getSettingDoc() {
      if (this.isCustomer) {
        try {
          const response = await axios.get(
            `agreement/setting/${this.agreement.id}`,
            {
              baseURL: import.meta.env.VITE_BASE_API,
              withCredentials: true,
              headers: {
                token: this.$f7route.query.token,
              },
            }
          );
          return response.data;
        } catch (error) {
          return { success: false, message: error.message };
        }
      }
      const response = await this.getSetting();
      return { success: true, data: response };
    },

    async updateAgreementDoc(doc) {
      if (this.isCustomer) {
        try {
          const response = await axios.post(
            `agreement/update/${this.agreement.id}`,
            doc,
            {
              baseURL: import.meta.env.VITE_BASE_API,
              withCredentials: true,
              headers: {
                token: this.$f7route.query.token,
              },
            }
          );
          return response.data;
        } catch (error) {
          return { success: false, message: error.message };
        }
      }
      await this.updateAgreement({
        id: this.agreement.id,
        doc,
      });
      return { success: true };
    },

    async save({ imageBase64, fullName, currentUser, signerIp, callback }) {
      this.$f7.preloader.show();
      if (this.isCustomer) {
        signerIp = _.cloneDeep(this.additionalData);
      }
      const replaceSignatureHtml = (html, newValue) => {
        const className = this.isCustomer
          ? 'customer-signature'
          : 'company-signature';
        const regex = new RegExp(
          `<div[^>]*style="flex: 1;border-bottom: 2px solid #1A79A1;"[^>]*class="${className}"[^>]*>.*?</div>`,
          'g'
        );

        const matches = html.match(regex);
        if (matches && matches.length > 0) {
          html = html.replace(matches[0], newValue);
          for (let i = 1; i < matches.length; i++) {
            html = html.replace(matches[i], '');
          }
        }
        return html;
      };
      const responseUpload = await this.uploadSignatureImage(imageBase64);
      if (!responseUpload.success) {
        this.showErrorDialog(responseUpload.message);
        this.$f7.preloader.hide();
        return;
      }
      let sections = _.cloneDeep(this.agreement.sections);
      const newHtmlValue = `<div style="width: 100%; display: flex; align-items: flex-end; justify-content: space-between; padding-bottom: 12px;">
        <div>
          <img style="max-width: 450px; max-height: 200px" src="${
            responseUpload.data.url
          }" alt=""/>
          <div style="text-align: center;">${fullName}</div>
        </div>
        <div style="text-align: right;">
          <span style="font-size: 16px;">Date: ${moment().format(
            'MM/DD/YYYY'
          )}</span>
        <div style="font-size: 12px; color: #A0A0A0;">IP: ${
          signerIp.signerIp
        }</div>
          </div>
      </div>`;
      if (this.signatureSection) {
        let htmlValue = _.cloneDeep(this.signatureSection).htmlValue;
        htmlValue = replaceSignatureHtml(htmlValue, newHtmlValue);
        sections = sections.filter(
          section => section.sectionId !== this.signatureSection.sectionId
        );
        let newSection = _.cloneDeep(this.signatureSection);
        newSection.htmlValue = htmlValue;
        sections.splice(this.indexOfSignatureSection, 0, newSection);
      } else {
        const responseGetAgreementTemplate = await this.getAgreementTemplate();
        if (!responseGetAgreementTemplate.success) {
          this.showErrorDialog(responseGetAgreementTemplate.message);
          this.$f7.preloader.hide();
          return;
        }
        if (!_.isEmpty(responseGetAgreementTemplate.data)) {
          // get org signature section in template
          let newSection = responseGetAgreementTemplate.data.sections.find(
            section => section.sectionId === 'owners-acceptance'
          );
          const responseGetSetting = await this.getSettingDoc();
          if (!responseGetSetting.success) {
            this.showErrorDialog(responseGetSetting.message);
            this.$f7.preloader.hide();
            return;
          }
          newSection.htmlValue = newSection.htmlValue.replace(
            '<span style="background-color: #f05034;">[Roofing Company Name]</span>',
            responseGetSetting.data.companyName || ''
          );
          newSection.htmlValue = replaceSignatureHtml(
            newSection.htmlValue,
            newHtmlValue
          );
          sections.splice(
            (this.indexOfFooterSection || sections.length) - 1,
            0,
            newSection
          );
        }
      }
      let i = 0;
      sections.forEach(section => {
        section.index = i++;
      });
      let doc = {};
      if (this.isCustomer) {
        doc = {
          ownerSigner: {
            signatureImageUrl: responseUpload.data.url,
            signatureImageFullPath: responseUpload.data.fullPath,
            signatureAt: firebase.firestore.Timestamp.now(),
            userSignId: currentUser.uid ? currentUser.uid : null,
            signerIp: signerIp,
            fullNameOfSigner: fullName,
          },
          status: 'signed',
          sections,
        };
      } else {
        doc = {
          companyRepresentativeSigner: {
            signatureImageUrl: responseUpload.data.url,
            signatureImageFullPath: responseUpload.data.fullPath,
            signatureAt: firebase.firestore.Timestamp.now(),
            userSignId: currentUser.uid ? currentUser.uid : null,
            signerIp: signerIp,
            fullNameOfSigner: fullName,
          },
          sections,
        };
      }
      const responseUpdateAgreement = await this.updateAgreementDoc(doc);
      if (!responseUpdateAgreement.success) {
        this.showErrorDialog(responseUpdateAgreement.message);
        this.$f7.preloader.hide();
        return;
      }
      this.$f7.preloader.hide();
      callback();
      this.$emit('reloadPages');
    },
  },
};
</script>
