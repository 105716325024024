<template>
  <f7-card>
    <div
      class="card-container"
      @click="openCard"
    >
      <f7-card-content class="break-work swimlane-card-content">
        <div class="swimlane-card-title swimlane-card-title_left">
          <!-- {{ task.taskNumber }} -->
          <f7-row tag="span">
            <f7-col
              width="40"
              tag="span"
              class="swimlane-card-title_left"
              >{{ task.title || '' }}
            </f7-col>
            <f7-col
              width="60"
              v-if="task.taskType === 'purchase-order'"
              tag="span"
              class="swimlane-card-title_right"
              ><span
                ><f7-chip
                  slot="after"
                  :text="purchaseOrderStatusBy(task.status).displayName"
                  :color="purchaseOrderStatusBy(task.status).color"
                ></f7-chip></span
            ></f7-col>
          </f7-row>
        </div>

        <div
          class="display-flex justify-content-space-between align-items-center"
        >
          <div>
            <span
              v-show="task.dueDate"
              class="card-number"
              :style="`background-color: ${dueDateColor(task.dueDate)}`"
              >{{ toDisplayDateString(task.dueDate) || '' }}</span
            >
          </div>
          <div class="display-flex">
            <div
              v-for="(uid, index) in task.assigneeIDs || task.assigneeIds"
              :key="uid"
              :class="index < 2 ? 'assignee-avatar' : ''"
            >
              <user-avatar
                v-if="index < 2"
                :avatarSize="27"
                :imageUrl="userInfo(uid) ? userInfo(uid).photoURL : ''"
                :name="userInfo(uid) ? userInfo(uid).displayName : ''"
              ></user-avatar>
            </div>
            <div
              v-if="(task.assigneeIDs || task.assigneeIds || []).length > 2"
              class="assignee-avatar more-assignee-avatar"
            >
              <span>{{
                `+${(task.assigneeIDs || task.assigneeIds).length - 2}`
              }}</span>
            </div>
          </div>
        </div>
      </f7-card-content>
    </div>
  </f7-card>
</template>

<script>
import UserAvatar from '@/components/avatars/UserAvatar.vue';
import { mapGetters, mapState } from 'vuex';
import {
  toDateCalendar,
  toDisplayDateString,
} from '../../../../utility/datetime';
import computedsMixins from '../../mixin/computeds';

export default {
  components: { UserAvatar },

  mixins: [computedsMixins],

  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapState('dashboard/project', ['users']),
    // ...mapGetters("swimlane/user", ["userList"]),
    ...mapGetters('dashboard/app-constant', ['purchaseOrderStatusBy']),

    userInfo() {
      return uid =>
        this.users.find(user => {
          return user.uid === uid;
        });
    },
  },

  methods: {
    toDateCalendar(val) {
      return toDateCalendar(val);
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    openCard() {
      this.$emit('openCard', this.task.id);
    },

    // expiredDate(dueDate) {
    //   if (!dueDate) return false;
    //   return moment(dueDate.toDate()).isBefore(moment(), "day");
    // },

    // sameCurrentDate(dueDate) {
    //   if (!dueDate) return false;
    //   return moment(dueDate.toDate()).isSame(moment(), "day");
    // }
  },
};
</script>

<style lang="scss" scoped>
a.initial-color {
  color: initial;
}

.card-container {
  cursor: pointer;
}

.break-work {
  word-break: break-all;
}

.swimlane-card-content {
  padding: 8px;
}

.swimlane-card-title {
  margin-bottom: 1em;
  &_right {
    // color: var(--f7-theme-color);
    text-align: right;
  }
}

.assignee-avatar {
  margin-left: 3px;
}

.swimlane-card-duedate {
  color: var(--f7-theme-color);
  border-color: var(--f7-theme-color);
}

.card-number {
  border-radius: 4px;
  color: white;
  padding: 2px 5px;
  font-size: 12px;
  margin-right: 5px;
}

.more-assignee-avatar {
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--f7-color-chip-neutral);
  font-size: 12px;
}
</style>
