<template>
  <div>
    <div
      class="card data-table table-fixed-container"
      :class="{ 'contain-suggestion': $device.desktop || $device.ipad }"
    >
      <table>
        <thead>
          <tr>
            <th
              v-if="isActiveTab"
              class="checkbox-cell"
              style="width: 50px"
            >
              <f7-checkbox
                :checked="isSelectAll"
                @change="
                  $emit('selectAllItem', $event.target.checked, [
                    ...filteredList,
                  ])
                "
              ></f7-checkbox>
            </th>
            <th
              v-for="(item, index) in headers"
              :key="index"
              :class="item.cssClass"
            >
              {{ item.text }}
            </th>
          </tr>
          <tr v-if="hasFilter">
            <th v-if="isActiveTab"></th>
            <th
              v-for="(props, index) in headers"
              :key="index"
              class="input-cell"
            >
              <div
                v-if="props.filter"
                class="input"
              >
                <input
                  class="search-text-box"
                  v-if="props.filter.type === 'text'"
                  type="text"
                  :placeholder="props.filter.placeholder || 'Filter'"
                  :value="props.filter.value"
                  @input="
                    v =>
                      props.filter.onChange &&
                      props.filter.onChange(v.target.value)
                  "
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="filteredList && filteredList.length > 0">
          <tr
            v-for="(item, index) in filteredList"
            :key="index"
          >
            <td
              v-if="isActiveTab"
              class="checkbox-cell"
              style="width: 50px"
            >
              <f7-checkbox
                :indeterminate="!!item.qbBillId"
                :disabled="!!item.qbBillId"
                :checked="item.checked"
                @change="
                  $emit('checkBoxChange', $event.target.checked, item.id)
                "
              ></f7-checkbox>
            </td>
            <td>
              {{ toDisplayDateString(item.createdAt) }}
            </td>

            <td>
              {{
                (userById(item.payeeId || item.userId) || {}).displayName || ''
              }}
            </td>
            <td>
              <span v-if="item.qbBillId">{{ item.qbVendorRef.name }}</span>
              <auto-complete-input
                v-else
                placeholder="Enter Vendor"
                :id="item.id"
                :data="vendorList"
                :value="(item.vendorRef || {}).name"
                @selected="selectedVendor(item, $event.target.value)"
              ></auto-complete-input>
            </td>
            <td>{{ getExpenseCategory(item.reportType) }}</td>
            <td>
              <span v-if="item.qbBillId">{{ item.qbAccountRef.name }}</span>
              <auto-complete-input
                v-else
                placeholder="Enter Category"
                :id="item.id"
                :data="expenseCategory"
                :value="(item.expenseCategoryRef || {}).name"
                @selected="selectedCategory(item, $event.target.value)"
              ></auto-complete-input>
            </td>

            <td>{{ item.note }}</td>
            <td>{{ item.amount | currencyUSD }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              class="label-cell no-data"
              :colspan="headers.length + 1"
            >
              <div>No data!</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import AutoCompleteInput from '../input/AutoCompleteInput.vue';

import { toDisplayDateString } from '../../../../utility/datetime';
import { applyFilter } from '../../../../utility/filter-tools';
import { mapGetters } from 'vuex';

export default {
  components: {
    AutoCompleteInput,
  },

  props: {
    expensesList: Array,
    isActiveTab: Boolean,
  },

  data() {
    return {
      gridFilter: {
        payee: '',
        category: '',
        reportedDate: '',
      },
      selectAll: false,
    };
  },

  computed: {
    ...mapGetters('quickbooks/common', ['qbExpenseList', 'qbVendorList']),
    ...mapGetters('quickbooks/user', ['userById']),
    ...mapGetters('quickbooks/app-constant', ['expenseTypeList']),

    vendorList() {
      return this.qbVendorList.map(item => {
        return {
          id: item.Id,
          name: item.DisplayName,
        };
      });
    },

    expenseCategory() {
      return this.qbExpenseList.map(item => {
        return {
          id: item.Id,
          name: item.Name,
        };
      });
    },

    hasFilter() {
      return this.headers.some(header => header.filter);
    },

    filteredList() {
      return applyFilter([...this.expensesList], '', [], {
        createdAt: value => {
          const date = this.toDisplayDateString(value);
          if (this.gridFilter.reportedDate == '') return true;
          return date
            .toLowerCase()
            .includes(this.gridFilter.reportedDate.toLowerCase());
        },
        payeeId: value => {
          const name = (this.userById(value || '') || {}).displayName || '';
          if (this.gridFilter.payee == '') return true;
          return name
            .toLowerCase()
            .includes(this.gridFilter.payee.toLowerCase());
        },
        reportType: value => {
          const category = this.getExpenseCategory(value) || '';
          if (this.gridFilter.category == '') return true;
          return category
            .toLowerCase()
            .includes(this.gridFilter.category.toLowerCase());
        },
      });
    },
    isSelectAll() {
      const activeList = this.filteredList.filter(r => !r.qbBillId) || [];
      return activeList.length > 0 && activeList.every(r => r.checked);
    },

    headers() {
      let self = this;
      return [
        {
          text: 'Reported Date',
          value: 'createdAt',
          sortable: false,
          align: 'left',
          filter: {
            type: 'text',
            placeholder: 'Search reported date',
            value: self.gridFilter.reportedDate,
            onChange: value => {
              self.gridFilter.reportedDate = value;
            },
          },
        },

        {
          text: 'Payee',
          value: 'payeeName',
          sortable: false,
          align: 'left',
          filter: {
            type: 'text',
            placeholder: 'Search Payee',
            value: self.gridFilter.payee,
            onChange: value => {
              self.gridFilter.payee = value;
            },
          },
        },
        {
          text: 'QB Vendor',
          value: 'qb-vendor',
          sortable: false,
          align: 'left',
        },

        {
          text: 'Category',
          value: 'category',
          sortable: false,
          align: 'left',
          filter: {
            type: 'text',
            placeholder: 'Search category',
            value: self.gridFilter.category,
            onChange: value => {
              self.gridFilter.category = value;
            },
          },
        },

        {
          text: 'QB Expense Category',
          value: 'expense-category',
          sortable: false,
          align: 'left',
        },

        {
          text: 'Memo/Note',
          value: 'note',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Amount',
          value: 'amount',
          sortable: false,
          align: 'left',
        },
      ];
    },
  },

  methods: {
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    selectedVendor(item, selected) {
      this.$emit('dataItemChange', 'vendorRef', selected, item.id);
    },

    selectedCategory(item, selected) {
      this.$emit('dataItemChange', 'expenseCategoryRef', selected, item.id);
    },

    clearVendor(item) {
      this.$emit('dataItemChange', 'vendorRef', {}, item.id);
    },

    clearCategory(item) {
      this.$emit('dataItemChange', 'expenseCategoryRef', {}, item.id);
    },

    getExpenseCategory(type) {
      return (
        (this.expenseTypeList.find(item => item.value === type) || {})
          .displayName || ''
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  text-align: center;
  color: grey;
}
.search-text-box {
  font-size: var(--f7-table-head-font-size);
  border: 1px solid grey;
  padding: 0px 6px;
  border-radius: 6px;
}
.input-cell {
  padding-top: 0px !important;
}

.table-fixed-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 382px);
  overflow-y: auto;
}

thead:nth-child(1) th {
  position: sticky;
  top: 0;
  background-color: var(--f7-color-bg-4-neutral);
  z-index: 20;
}
.table-head-label {
  color: var(--f7-color-text-neutral);
}

thead th::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: var(--f7-color-bg-select-neutral) 1px solid;
}
thead tr:nth-child(2) th {
  position: sticky;
  top: 32px;
  background-color: var(--f7-color-bg-4-neutral);
  z-index: 20;
}
</style>
