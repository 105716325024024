<template>
  <div>
    <f7-block>
      <filter-by-date
        @searchByDate="searchByDate"
        @exportToQuickbook="exportToQuickbook"
        @exportToExcel="exportToExcel"
      ></filter-by-date>
    </f7-block>

    <check-data-table
      :checkPayableList="invoiceList"
      :isActiveTab="true"
      @checkBoxChange="checkBoxChange"
    ></check-data-table>
  </div>
</template>
<script>
import Vue from 'vue';
import FilterByDate from '@/plugins/quickbooks/components/filter/FilterByDate.vue';
import CheckDataTable from '@/plugins/quickbooks/components/datatable/CheckDataTable.vue';

import quickbooksService from '../../../../services/quickbooks.service';
import { mapGetters } from 'vuex';

export default {
  components: {
    FilterByDate,
    CheckDataTable,
  },
  data: () => {
    return {
      selectedItems: [],
      invoiceList: [],
    };
  },

  methods: {
    exportToQuickbook() {},
    exportToExcel() {},

    searchByDate() {
      // quickbooksService.getBillList();
      this.$f7.preloader.show();
      quickbooksService
        .checkAuth()
        .then(data => {
          if (data.success) {
            return quickbooksService.getBillList().then(data => {
              // console.log(data);
              this.invoiceList = data;
              this.$f7.preloader.hide();
            });
          } else {
            this.$f7.preloader.hide();
            this.$ri.dialog.openInfoDialog({
              title: 'Connect to Quickbooks Online.',
              content:
                'You need to set up a connection between RooferIntel and Quickbook. It will take about 5 minutes. Do you want to proceed now?',
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  quickbooksService
                    .getAuthUri('qb-contact/export')
                    .then(uri => {
                      window.location.href = uri;
                    });
                }
              },
            });
          }
        })
        .catch(error => {
          if (error.name === 'unauthorized') {
            this.loginQB();
          } else {
            this.showErrorMessage(error.message);
          }
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    loginQB() {
      this.$ri.dialog.openInfoDialog({
        title: 'Connect to Quickbooks Online.',
        content:
          'You need to set up a connection between RooferIntel and Quickbook. It will take about 5 minutes. Do you want to proceed now?',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            quickbooksService.getAuthUri('qb-contact/export').then(uri => {
              window.location.href = uri;
            });
          }
        },
      });
    },

    showErrorMessage(messages) {
      this.$ri.dialog.openWarningDialog({
        title: 'Please choose another one',
        content: messages,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            window.location.href = '/qb-contact/export';
          }
        },
      });
    },

    checkBoxChange(checked, id) {
      let item = this.checkPayableList.find(item => item.id === id);
      Vue.set(item, 'checked', checked);
      this.selectedItems = this.checkPayableList.filter(
        item => item.checked === true
      );
    },
  },

  computed: {
    ...mapGetters('setting/app/profile', ['user']),

    checkPayableList() {
      return [
        {
          id: '1',
          check: 'Chk-3434',
          paidDate: new Date('8/15/2020'),
          checkAmount: '6000',
          invoiceNumber: 'INV-6454135',
          invoiceAmount: '6000',
        },
        {
          id: '2',
          check: 'Chk-5754',
          paidDate: new Date('8/17/2020'),
          checkAmount: '8500',
          invoiceNumber: 'INV-6454136',
          invoiceAmount: '8500',
        },
      ];
    },
  },
  watch: {
    selectedItems(newVal) {
      this.$emit('selectedItems', newVal);
    },
  },
};
</script>
