import {
  CODE_TIME_TRACKING_HOURS,
  CODE_TIME_TRACKING_MINUTES,
  CODE_STATUS_OPTION,
  ENTITY_TIME_TRACKING,
  CODE_TIME_LOG_TYPE,
  CODE_TIME_OFF_TYPE,
  TIME_LOG_TYPE_TIME_OFF,
} from '../../../../utility/const';

export default {
  hourList: state =>
    state.typeList
      .filter(item => item.code === CODE_TIME_TRACKING_HOURS)
      .sort((a, b) => a.value - b.value),

  minuteList: state =>
    state.typeList
      .filter(item => item.code === CODE_TIME_TRACKING_MINUTES)
      .sort((a, b) => a.value - b.value),

  timeTrackingStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION && item.entity === ENTITY_TIME_TRACKING
    ),

  timeLogTypeList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_TIME_LOG_TYPE &&
        item.value !== TIME_LOG_TYPE_TIME_OFF
    ),

  timeLogTypeByValue: state => value =>
    state.typeList.find(
      item => item.code === CODE_TIME_LOG_TYPE && item.value == value
    ),

  timeOffTypeList: state =>
    state.typeList.filter(item => item.code === CODE_TIME_OFF_TYPE),
};
