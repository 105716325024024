import { FirebaseActions } from '../../services/firebase.service';
import { searchClient } from '../../services/search.service';
import * as types from './types';
import * as constant from '../../utility/const';

export default tenant => {
  const assemblyTemplateActions = new FirebaseActions(
    `/system_client/${tenant}/assembly_template`,
    'assemblyTemplate'
  );
  const assemblyTemplateIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    getAssemblyTemplateListBys: assemblyTemplateActions.getDocumentBys,
    getAssemblyTemplate: assemblyTemplateActions.getDocument,
    getAssemblyTemplateListDoc({ commit, dispatch }, conditions) {
      return dispatch('getAssemblyTemplateListBys', conditions).then(res => {
        commit(types.SET_ASSEMBLY_TEMPLATE_LIST, res);
      });
    },
    async getAssemblyTemplateById({ commit, dispatch }, id) {
      if (!id) {
        return {};
      }
      const assemblyTemplateDoc = await dispatch('getAssemblyTemplate', id);
      commit(types.SET_ASSEMBLY_TEMPLATE, assemblyTemplateDoc || {});
      return assemblyTemplateDoc;
    },
    setSearchValue({ commit }, payload) {
      commit(types.SET_SEARCH_VALUE, payload);
    },
    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchAssemblyTemplate(
      { state, commit },
      // eslint-disable-next-line no-unused-vars
      { query, attributesToRetrieve, restrictSearchableAttributes, filters }
    ) {
      const index = assemblyTemplateIndexAsc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_ASSEMBLY_TEMPLATE}`,
        attributesToRetrieve:
          constant.ASSEMBLY_TEMPLATE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.ASSEMBLY_TEMPLATE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (restrictSearchableAttributes) {
        requestOptions.restrictSearchableAttributes =
          restrictSearchableAttributes;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index
        .search(state.searchValue, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreAssemblyTemplate(
      { state, commit },
      {
        // eslint-disable-next-line no-unused-vars
        query,
        attributesToRetrieve,
        restrictSearchableAttributes,
        page,
        filters,
      }
    ) {
      const index = assemblyTemplateIndexAsc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_ASSEMBLY_TEMPLATE}`,
        attributesToRetrieve:
          constant.ASSEMBLY_TEMPLATE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.ASSEMBLY_TEMPLATE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (restrictSearchableAttributes) {
        requestOptions.restrictSearchableAttributes =
          restrictSearchableAttributes;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchValue, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },
    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },
  };
};
