import Vue from 'vue';

export default {
  setProtestList(state, payload) {
    Vue.set(state, 'protestList', payload);
  },
  setProtestSubscribe(state, payload) {
    Vue.set(state, 'protestSubscribe', payload);
  },
};
