<template>
  <f7-row>
    <f7-col :width="$device.desktop ? 70 : 100">
      <f7-list
        v-if="
          rcStatus === ROOFING_CHOICE_CREATED ||
          rcStatus === ROOFING_CHOICE_SENT
        "
        :class="{
          'list-inline-center': $device.desktop,
          'list-inline-center-mobile': !$device.desktop,
        }"
        inset
        inline-labels
      >
        <f7-list-item>
          <div class="title">Roofing Choices</div>
          <div>
            {{
              rcStatus === ROOFING_CHOICE_SENT
                ? 'Waiting for customer to select!'
                : 'No data!'
            }}
          </div>
        </f7-list-item>
      </f7-list>

      <div v-if="rcStatus === ROOFING_CHOICE_SELECTED">
        <f7-block-title>Roofing Choices</f7-block-title>
        <f7-block>
          <div class="data-table data-table-init card no-margin">
            <div class="card-content">
              <div class="margin-top-half margin-left">
                <slot name="total"> </slot>
              </div>
              <table>
                <thead>
                  <tr>
                    <th
                      v-for="(header, index) in headersRoofing"
                      :key="index"
                      :style="
                        header.align
                          ? `text-align-${header.align}`
                          : 'text-align-left'
                      "
                    >
                      {{ header.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(choice, index) in project &&
                    (project.roofingChoicesSelected || [])"
                    :key="index"
                  >
                    <td>{{ choice.name }}</td>
                    <td>
                      <img
                        v-if="choice.data && choice.data.selectedColor"
                        width="70"
                        :src="choice.data.selectedColor.photos[0].thumbnailUrl"
                        :alt="choice.data.selectedColor.colorName"
                      />
                      <img
                        v-else
                        width="70"
                        :src="noImageDefault"
                        alt="No Image Available"
                      />
                    </td>
                    <td>
                      {{
                        choice.data && choice.data.selectedColor
                          ? choice.data.selectedColor.colorName
                          : 'No color name'
                      }}
                    </td>
                    <td>{{ choice.data.product.productItem }}</td>
                    <td>
                      <li>Vendor: {{ choice.data.product.vendorName }}</li>
                      <li>
                        Manufacturer: {{ choice.data.product.manufacturer }}
                      </li>
                      <li>SKU: {{ choice.data.product.sku }}</li>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </f7-block>
      </div>
      <f7-list
        :class="{
          'list-inline-center': $device.desktop,
          'list-inline-center-mobile': !$device.desktop,
        }"
        inset
        inline-labels
      >
        <f7-list-item>
          <div class="title">Roofing Build Contract</div>
          <div class="content">
            <f7-link
              v-if="
                contract &&
                ['sent', 'viewed', 'signed'].includes(contract.status) &&
                contract.contractWebUrl &&
                $f7route.query.tenant
              "
              target="_blank"
              external
              :href="urlViewContractDetail(contract && contract.contractWebUrl)"
              >View</f7-link
            >
            <f7-link
              v-else
              @click="onPreviewContract(contract)"
              >View</f7-link
            >
          </div>
        </f7-list-item>
      </f7-list>
      <f7-list
        :class="{
          'list-inline-center': $device.desktop,
          'list-inline-center-mobile': !$device.desktop,
        }"
        inset
        inline-labels
      >
        <f7-list-item>
          <div class="title">Schedule Production Date</div>
          <div class="content">
            {{ toDisplayDateString(project.productionDate) }}
          </div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Completed Date</div>
          <div class="content">
            {{ toDisplayDateString(project.completedDate) }}
          </div>
        </f7-list-item>
      </f7-list>
    </f7-col>
    <f7-col :width="$device.desktop ? 30 : 100">
      <f7-list
        inset
        accordion-list
      >
        <f7-list-item
          accordion-item
          :accordion-item-opened="certificateList.length > 0"
          title="Certificate of Completion"
        >
          <f7-accordion-content>
            <f7-list class="no-margin">
              <f7-list-item
                v-for="(item, index) in certificateList"
                :key="index"
              >
                <f7-link
                  slot="title"
                  style="white-space: break-spaces"
                  @click.native="
                    $f7route.query.tenant
                      ? gotoViewCertificateDetail(item.id)
                      : onPreviewCertificate(item)
                  "
                  >{{ item.title }}</f7-link
                >
                <div slot="footer">
                  {{ item.status === 'draft' ? 'Created' : 'Sent' }} on
                  {{ toDisplayDateString(item.createdAt) }} by
                  {{ item.createdBy }}
                </div>
              </f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
      <f7-list
        :class="{ 'no-margin-top': !$device.desktop }"
        class="list-inline-right"
        inset
        inline-labels
      >
        <f7-list-item title="Payment">
          <div slot="after">
            {{
              project.paymentStatus === PAYMENT_STATUS_PUT_A_LIEN
                ? 'Put a Lien'
                : project.paymentStatus === PAYMENT_STATUS_RECEIVED_PAYMENT
                  ? 'Received Payment'
                  : ''
            }}
          </div>
        </f7-list-item>
      </f7-list>
      <f7-list
        inset
        inline-labels
      >
        <f7-list-item title="Photo">
          <f7-link
            v-if="$f7route.query.tenant"
            slot="after"
            target="_blank"
            external
            :href="urlViewProjectPhoto()"
            >View</f7-link
          >
          <f7-link
            v-else
            slot="after"
            @click="openProjectPhotoPopup"
            >View</f7-link
          >
        </f7-list-item>
      </f7-list>
    </f7-col>
    <certificate-preview-popup
      ref="certificatePreviewPopup"
    ></certificate-preview-popup>
    <contract-preview-popup ref="contractPreviewPopup"></contract-preview-popup>
  </f7-row>
</template>
<script>
import {
  PAYMENT_STATUS_PUT_A_LIEN,
  PAYMENT_STATUS_RECEIVED_PAYMENT,
  ROOFING_CHOICE_CREATED,
  ROOFING_CHOICE_SELECTED,
  ROOFING_CHOICE_SENT,
} from '@/utility/const';
import { toDisplayDateString } from '@/utility/datetime';
import axiosService from '@/services/axios.service';
import { mapActions } from 'vuex';
import CertificatePreviewPopup from '../popup/CertificatePreviewPopup.vue';
import ContractPreviewPopup from '../popup/ContractPreviewPopup.vue';
import noImageDefault from '@/assets/img/no_image_default.png';
export default {
  components: {
    CertificatePreviewPopup,
    ContractPreviewPopup,
  },
  props: {
    project: Object,
    contract: Object,
    certificateList: Array,
  },
  data() {
    return {
      noImageDefault,
      PAYMENT_STATUS_PUT_A_LIEN,
      PAYMENT_STATUS_RECEIVED_PAYMENT,
      ROOFING_CHOICE_CREATED,
      ROOFING_CHOICE_SELECTED,
      ROOFING_CHOICE_SENT,
      toDisplayDateString,
    };
  },
  computed: {
    headersRoofing() {
      return [
        {
          text: 'Choice',
          align: 'left',
          sortable: false,
          value: 'totalAmount',
        },
        {
          text: 'Photo',
          align: 'left',
          sortable: false,
          value: 'paidAmount',
        },
        {
          text: 'Color',
          align: 'left',
          sortable: false,
          value: 'invoiceDate',
        },
        {
          text: 'Product item',
          align: 'left',
          sortable: false,
          value: 'paidDate',
        },
        {
          text: 'Detail',
          align: 'right',
          sortable: false,
          value: 'action',
        },
      ];
    },
    rcStatus() {
      return this.project ? this.project.roofingChoicesStatus || '' : '';
    },
  },
  methods: {
    ...mapActions('dashboard/app-constant', [
      'setIsOpenProjectPhotosViewPopup',
    ]),
    onPreviewCertificate(itemDetail) {
      this.$refs.certificatePreviewPopup.open(itemDetail);
    },
    onPreviewContract(itemDetail) {
      this.$refs.contractPreviewPopup.open(itemDetail);
    },
    urlViewProjectPhoto() {
      return `${import.meta.env.VITE_HOST_DOMAIN}/share-photo/${this.$f7route.query.tenant}/${this.project.id}`;
    },
    openProjectPhotoPopup() {
      this.setIsOpenProjectPhotosViewPopup(true);
    },
    urlViewContractDetail(contractWebUrl) {
      return contractWebUrl.replace('/contract-view/?', '/contract-preview/?');
    },
    async gotoViewCertificateDetail(id) {
      let url = '';
      const response = await axiosService.post('/tenant/get-anonymous-token', {
        entity: 'certificate',
        entityId: id,
      });
      if (response && response.status === 200) {
        url = `${import.meta.env.VITE_HOST_DOMAIN}/certificate-preview/?token=${response.data}&id=${id}`;
      }
      window.open(url, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.list-inline-center {
  ::v-deep .item-inner {
    gap: 16px;

    .title {
      flex: 1;
    }

    .content {
      flex: 2;
    }
  }
}

.list-inline-right,
.list-inline-center-mobile {
  ::v-deep .item-inner {
    gap: 16px;

    .title {
      flex: 1;
    }

    .content {
      flex: 1;
    }
  }
}
</style>
