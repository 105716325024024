<template>
  <f7-page v-if="Object.keys(itemDetail).length > 0">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          v-show="!isHiddenLeftBtn"
          @click.native="closePopup"
          >Cancel</f7-link
        >
        <f7-link
          v-if="isShowOnlineSignature"
          @click.native="$emit('openSignaturePopup')"
          >Sign Online</f7-link
        >
      </f7-nav-left>
      <f7-nav-title>
        {{ title }}
      </f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="exportPdf(true)">Download PDF</f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div
      :style="styleContent"
      ref="wrapPreviewContent"
      class="display-flex flex-direction-column align-items-center justify-content-flex-start text-color-black"
    >
      <div
        v-for="(page, index) in pdfPages"
        :key="index"
      >
        <div
          :class="pagePreviewCssClass"
          v-html="processedHtmlValue(page.htmlValue)"
        ></div>
      </div>
    </div>
    <div style="overflow: hidden; height: 0">
      <div
        v-for="(page, index) in pdfPages"
        :key="index"
      >
        <div
          :class="pagePreviewCssClass"
          :id="`${page.pageId}_${page.index}`"
          :ref="`${page.pageId}_${page.index}`"
          v-html="processedHtmlValue(page.htmlValue)"
        ></div>
      </div>
    </div>
    <slot name="other"></slot>
  </f7-page>
  <f7-page v-else>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          v-show="!isHiddenLeftBtn"
          @click.native="closePopup"
          >Cancel</f7-link
        >
      </f7-nav-left>
      <f7-nav-title>Not found document</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-block-title medium>The document could not be found</f7-block-title>
    <f7-block strong>
      <p>The document was discarded or replaced with another option.</p>
    </f7-block>
  </f7-page>
</template>
<script>
import jsPDF from 'jspdf';
import _ from 'lodash';

import { generateImage } from '../../../services/utils';

export default {
  props: {
    title: { type: String, default: 'Preview' },
    itemDetail: { type: Object, default: () => {} },
    isHiddenLeftBtn: { type: Boolean, default: false },
    entity: { type: String, default: '' },
    entityId: { type: String, default: '' },
    token: { type: String, default: '' },
    pageWidth: { type: Number, default: 1020 }, // 120 dpi, letter size
    pageHeight: { type: Number, default: 1320 },
    isShowOnlineSignature: { type: Boolean, default: false },
  },
  data() {
    return {
      pages: {},
      styleContent: '',
      modifiedSections: [],
    };
  },

  computed: {
    sortedSections() {
      return _.cloneDeep(this.itemDetail.sections || []).sort(
        (a, b) => a.index - b.index
      );
    },

    pdfPages() {
      let sections = [];
      (this.modifiedSections.length > 0
        ? this.modifiedSections
        : this.sortedSections
      ).forEach(section => {
        const a = this.breakSection(section);
        sections = sections.concat(a);
      });
      const pages = [];
      const headerSection = sections.find(
        section => section.sectionId === 'header-section'
      );

      const footerSection = sections.find(
        section => section.sectionId === 'footer-section'
      );

      for (let index = 0; index < sections.length; index++) {
        const section = sections[index];
        switch (section.sectionId) {
          case 'cover-section':
          case 'cover-page':
            pages.push({
              htmlValue: section.htmlValue,
              pageId: section.sectionId,
              index,
            });
            break;

          case 'header-section':
            break;

          case 'footer-section':
            break;

          default:
            pages.push({
              htmlValue: ` <div style="height: 100%; display: flex; flex-direction: column;"> ${
                index === 1
                  ? `<div>${headerSection ? headerSection.htmlValue : ''}</div>`
                  : ''
              } <div style="flex: 1; position: relative;"> ${
                section.htmlValue
              } </div> <div> ${
                footerSection ? footerSection.htmlValue : ''
              } </div> </div>`,
              pageId: section.sectionId,
              index,
            });
        }
      }

      return pages;
    },

    pagePreviewCssClass() {
      return this.pageHeight === 1680
        ? 'page-preview-content-legal'
        : 'page-preview-content';
    },
  },

  methods: {
    processedHtmlValue(htmlValue) {
      const processedValue = htmlValue.replace(
        /<a href="(https?:\/\/[^"]+)"/g,
        (match, url) => `<a href="#" onclick="window.open('${url}', '_blank')" `
      );
      return processedValue;
    },
    closePopup() {
      this.modifiedSections = [];
      this.$emit('close');
    },
    async lowResDesktopScreen() {
      this.$nextTick(() => {
        const letterFullWidth = this.pageWidth;
        const width = this.$refs.wrapPreviewContent.clientWidth;
        const height = this.$refs.wrapPreviewContent.clientHeight;
        const spaceHeight = ((width * height) / letterFullWidth - height) / 2;
        if (width < letterFullWidth) {
          const scaleRatio = width / letterFullWidth;
          this.styleContent = `transform: scale(${scaleRatio}); margin: ${spaceHeight}px 0`;
        } else {
          this.styleContent = '';
        }

        // TODO: break section

        // let divs = Array.from(
        //   this.$refs.wrapPreviewContent.getElementsByTagName("div")
        // );
        // let indexCount = 0;
        // let newSortedSections = this.sortedSections.map(section => {
        //   if (
        //     section.sectionId === "header-section" ||
        //     section.sectionId === "footer-section" ||
        //     section.sectionId === "cover-section"
        //   ) {
        //     return section;
        //   }
        //   let targetDivs = divs.filter(
        //     div =>
        //       div.style.textAlign === "justify" && div.style.margin === "56px"
        //   );
        //   let pageNumber = 1;
        //   let childDivs = targetDivs[indexCount].querySelectorAll("div");
        //   let divSections = section.htmlValue.split("</div>");
        //   for (let i = 0; i < childDivs.length; i++) {
        //     let childDiv = childDivs[i];
        //     let pageHeight =
        //       pageNumber === 1
        //         ? section.index !== 1
        //           ? this.pageHeight - 100
        //           : this.pageHeight - 300
        //         : section.index !== 1
        //         ? pageNumber * (this.pageHeight - 100)
        //         : (pageNumber - 1) * (this.pageHeight - 100) +
        //           (this.pageHeight - 300);

        //     if (childDiv.offsetHeight + childDiv.offsetTop > pageHeight) {
        //       let strChild = childDiv.outerHTML.replace("</div>", "").trim();
        //       let trimmedDivSections = divSections.map(section =>
        //         section.trim()
        //       );
        //       let count = -1;
        //       trimmedDivSections.map((section, index) => {
        //         if (
        //           section.trim().replace(/\s+/g, " ") ===
        //           strChild.trim().replace(/\s+/g, " ")
        //         ) {
        //           count = index;
        //         }
        //       });
        //       if (count !== -1) {
        //         let list = childDiv.querySelector("ul, ol");
        //         if (list) {
        //           let listItems = list.querySelectorAll("li");
        //           let newDiv = null;
        //           let newUl = null;
        //           listItems.forEach(li => {
        //             let liOffsetTotal = li.offsetHeight + li.offsetTop;
        //             if (liOffsetTotal > pageHeight) {
        //               if (!newDiv) {
        //                 newDiv = document.createElement("div");
        //                 newDiv.classList.add("break-page");
        //                 newDiv.style.marginTop = "15px";
        //                 newUl = document.createElement("ul");
        //                 newUl.style.listStyleType = "disc";
        //                 newUl.style.fontSize = "20px";
        //                 newDiv.appendChild(newUl);
        //                 childDiv.parentNode.insertBefore(
        //                   newDiv,
        //                   childDiv.nextSibling
        //                 );
        //                 pageNumber += 1;
        //               }
        //               newUl.appendChild(li);
        //             }
        //           });

        //           divSections[i] =
        //             childDiv.outerHTML + newDiv.outerHTML.replace("</div>", "");
        //         } else {
        //           if (childDiv.classList.length > 0) {
        //             childDiv = childDivs[i - 1];
        //             childDiv.classList.add("break-page");
        //             divSections[i - 1] = childDiv.outerHTML.replace(
        //               "</div>",
        //               ""
        //             );
        //           } else {
        //             childDiv.classList.add("break-page");
        //             divSections[i] = childDiv.outerHTML.replace("</div>", "");
        //           }
        //           pageNumber += 1;
        //         }
        //       }
        //     }
        //   }
        //   indexCount += 1;
        //   return {
        //     ...section,
        //     htmlValue: divSections.join("</div>")
        //   };
        // });
        // this.modifiedSections = newSortedSections;
      });
    },

    // TODO: break section
    breakSection(section) {
      const replaced1 = section.htmlValue.replace(
        `<div style="margin-top:15px" class="break-page">`,
        `<div style=" margin-top: 15px;" class="break-page">`
      );

      const replaced2 = replaced1.replace(
        `<div style="margin-top:15px;" class="break-page">`,
        `<div style=" margin-top: 15px;" class="break-page">`
      );
      const replaced3 = replaced2.replace(
        `<div class="break-page" style="margin-top: 15px;">`,
        `<div style=" margin-top: 15px;" class="break-page">`
      );
      const arr = replaced3.split(
        `<div style=" margin-top: 15px;" class="break-page">`
      );
      if (arr.length > 1) {
        for (let i = 0; i < arr.length; i++) {
          let item = arr[i];
          if (i === 0) {
            arr[i] = `${item}</div></div></font>`;
          } else if (i === arr.length - 1) {
            arr[i] = `<font face="'Sofia Pro', Verdana, Geneva, sans-serif">
                      <div class="">
                        <div style="text-align: justify; margin: 56px;" class="">
                          <div class="">${item}`;
          } else {
            arr[i] = `<font face="'Sofia Pro', Verdana, Geneva, sans-serif">
                      <div class="">
                        <div style="text-align: justify; margin: 56px;" class="">
                          <div class="">${item}</div></div></font>`;
          }
        }
      }
      return arr.map(r => ({
        ...section,
        htmlValue: r,
      }));
    },

    async exportPdf(isPreview = false) {
      this.$f7.dialog.preloader('Downloading PDF. Please wait...');
      // const width = 1020; //120 dpi
      // const height = 1320;

      if (!isPreview) {
        await this.lowResDesktopScreen();
      }
      const doc = new jsPDF('p', 'pt', [this.pageWidth, this.pageHeight]); //850x1100
      try {
        const images = await Promise.all(
          this.pdfPages.map(page =>
            generateImage(
              this.$refs[`${page.pageId}_${page.index}`][0].innerHTML,
              {
                pageWidth: this.pageWidth,
                pageHeight: this.pageHeight,
                token: this.token || this.$f7route.query.token || '',
                entity: this.entity,
                entityId: this.entityId,
              }
            )
          )
        );
        for (let index = 0; index < images.length; index++) {
          const image = images[index];

          if (!_.isEmpty(image)) {
            if (index > 0) {
              doc.addPage([this.pageWidth, this.pageHeight], 'p');
            }
            doc.setPage(index + 1);
            doc.addImage(
              image,
              'PNG',
              0,
              0,
              this.pageWidth,
              this.pageHeight,
              `page-${index}`,
              'FAST'
            );
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(10);
            const pageNumber = index + 1;
            const pageNumberString = `${pageNumber}`;
            const xPosition = this.pageWidth / 2;
            doc.text(pageNumberString, xPosition, this.pageHeight - 30);
          }
        }

        doc.save(
          `${
            this.itemDetail.title ||
            (this.itemDetail.proposalNumber
              ? 'Proposal-' +
                this.itemDetail.project.title +
                '_' +
                this.itemDetail.proposalNumber
              : this.itemDetail.project.title)
          }.pdf`
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
        this.$f7.dialog.close();
      }
      this.$f7.dialog.close();
      this.modifiedSections = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.page-preview-content {
  width: 1020px;
  height: 1320px;
  background-color: white;
  margin: auto auto;
  margin-top: 23px;
}

.page-preview-content-legal {
  width: 1020px;
  height: 1680px;
  background-color: white;
  margin: auto auto;
  margin-top: 23px;
}

.page-preview-content ::v-deep font > div {
  padding-top: 1px;
}

.page-preview-content-legal ::v-deep font > div {
  padding-top: 1px;
}
.page-preview-content ::v-deep a {
  pointer-events: all;
  text-decoration: underline;
}
</style>
