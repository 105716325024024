import * as types from './types';
import Vue from 'vue';

export default {
  setproductDetailId(state, id) {
    state.productDetailId = id;
  },

  [types.SET_FILTER_DATA_RESULT](
    state,
    { roofTypes, categoryIds, subCategoryIds, vendorIds, status }
  ) {
    Vue.set(state, 'vendorIdFilterList', vendorIds);
    Vue.set(state, 'categoryIdFilterList', categoryIds);
    Vue.set(state, 'subCategoryIdFilterList', subCategoryIds);
    Vue.set(state, 'roofTypeFilterList', roofTypes);
    Vue.set(state, 'statusFilterList', status);
  },

  [types.SET_SEARCH_RESULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(
      state,
      'hits',
      hits.map(r => {
        delete r.createdAt;
        delete r.updatedAt;
        delete r._highlightResult;
        delete r.objectID;
        return {
          ...r,
          priceListRefs: r.priceListRefs === '?' ? [] : r.priceListRefs,
        };
      })
    );
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.SET_NUMBER_OF_ROWS](state, payload) {
    Vue.set(state, 'hitsPerPage', payload);
  },
  [types.SET_SEARCH_TEXT](state, payload) {
    Vue.set(state, 'searchText', payload);
  },
  [types.RESET_SEARCH](state) {
    Vue.set(state, 'order', 'desc');
    Vue.set(state, 'hits', []);
    Vue.set(state, 'hitsPerPage', 25);
    Vue.set(state, 'nbHits', 0);
    Vue.set(state, 'nbPages', 0);
    Vue.set(state, 'page', 0);
  },
};
