/* eslint-disable no-unused-vars */
import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';
import _ from 'lodash';

export default tenant => {
  const messageActions = new FirebaseActions(
    `/system_client/${tenant}/operation_message`,
    'message'
  );

  return {
    getMessage: messageActions.getDocument,
    getMessageBys: messageActions.getDocumentBys,
    createMessage: messageActions.createDocument,
    updateMessage: messageActions.updateDocument,
    deleteMessage: messageActions.deleteDocument,

    async getMessageListBys({ commit, dispatch }, payload) {
      const messageList = await dispatch('getMessageBys', payload);
      commit(types.SET_CONTACT_MESSAGE_LIST, messageList || []);
      return messageList;
    },

    async getMessageById({ commit, dispatch }, id) {
      const messageDoc = await dispatch('getMessage', id);
      commit(types.SET_CONTACT_MESSAGE, messageDoc || {});
      return messageDoc;
    },

    async removeMessageContactRefList({ getters, dispatch }, payload) {
      let removeMessageList = getters.messageList.filter(r =>
        payload.threadIds.includes(r.threadId)
      );

      for (const message of removeMessageList) {
        await dispatch('deleteMessage', message.id);
      }

      const emailThreadIds = _.remove(
        _.cloneDeep(payload.contact.emailThreadIds),
        item => {
          return !payload.threadIds.includes(item);
        }
      );

      await dispatch(
        'contact-book/contact/updateContact',
        {
          id: payload.contact.id,
          doc: {
            emailThreadIds: emailThreadIds,
          },
        },
        { root: true }
      );
    },
  };
};
