export default {
  computed: {
    currentRouteIsScheduling() {
      return (
        this.$f7router.currentRoute.path.includes('my-scheduling') ||
        this.$f7router.currentRoute.path.includes('employee-scheduling')
      );
    },

    isFromScheduling() {
      return ['my-scheduling', 'employee-scheduling'].includes(
        this.$f7route.params.mainRouteFrom
      );
    },
  },
  methods: {
    handleBackToScheduling() {
      this.$f7.preloader.show(); // will be hidden in mounted of the page navigate
      const mainRouteFrom = this.$f7route.params.mainRouteFrom;
      const currentView = this.$f7route.params.currentView;
      const startWeek = this.$f7route.params.startWeek;
      const userId = this.$f7route.params.userId;
      const url = `/${mainRouteFrom}?currentView=${currentView}&startWeek=${startWeek}&currentUser=${userId}`;
      this.$f7router.navigate(url, {
        pushState: true,
        reloadAll: true,
      });
    },
  },
};
