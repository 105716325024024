<template>
  <div>
    <f7-list accordion-list>
      <f7-list-item
        accordion-item
        title="Building Information"
        id="building-information"
        @click="handleScrollTop"
      >
        <f7-accordion-content>
          <f7-list
            media-list
            class="text-content"
          >
            <f7-list-item
              header="Building/Section#"
              :title="currentBuilding.buildingName"
            >
              <input-icon
                slot="media"
                icon="building_2_fill"
              ></input-icon>
            </f7-list-item>
            <f7-list-item
              header="Number of Building/Sections"
              :title="currentBuilding.numberOfCopy"
            >
              <input-icon
                slot="media"
                icon="number"
              ></input-icon>
            </f7-list-item>
            <f7-list-item
              header="Slope Type"
              :title="
                isAllSlopeType
                  ? 'All'
                  : slopeType(currentBuilding.slopeType).displayName
              "
            >
              <input-icon
                slot="media"
                icon="arrow_down_right"
              ></input-icon>
            </f7-list-item>
            <f7-list-item
              header="Roof Type"
              :title="
                isAllRoofType
                  ? 'All'
                  : roofTypeBy(currentBuilding.roofType).displayName
              "
            >
              <input-icon
                slot="media"
                icon="selection_pin_in_out"
              ></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Tax Apply"
              :title="taxApplyTypeBy(estimate.taxApplyType).displayName"
            >
              <input-icon
                slot="media"
                icon="keyboard"
              ></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Price List"
              :title="priceListText"
            >
              <input-icon
                slot="media"
                icon="square_list"
              ></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Manufacturers"
              :title="manufacturerList"
            >
              <input-icon
                slot="media"
                icon="briefcase_fill"
              ></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Warranty"
              :title="
                tpoWarrantyOption(currentBuilding.warrantyType).displayName
              "
            >
              <input-icon
                slot="media"
                icon="pencil_ellipsis_rectangle"
              ></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Warranty Notes"
              :title="currentBuilding.warrantyNotes"
            >
              <input-icon
                slot="media"
                icon="text_justifyleft"
              ></input-icon>
            </f7-list-item>
            <!-- <f7-list-item
              header="Type of substrate"
              :title="substrate(currentBuilding.substrate).displayName"
            >
              <input-icon slot="media" icon="map"></input-icon>
            </f7-list-item> -->
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item
        accordion-item
        title="Description"
        @click="handleScrollTop"
      >
        <f7-accordion-content>
          <f7-list
            media-list
            class="text-content"
          >
            <f7-list-input
              type="textarea"
              :value="currentBuilding.description"
              readonly
              resizable
            ></f7-list-input>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
import { TAX_APPLY_TYPE } from '../../../../utility/const';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  components: {
    InputIcon,
  },

  data() {
    return {
      TAX_APPLY_TYPE,
    };
  },

  computed: {
    ...mapGetters('estimate/estimate-page/estimate', [
      'estimate',
      'currentBuilding',
    ]),
    ...mapGetters('estimate/estimate-page/price-list', ['priceList']),
    ...mapGetters('common/app-constant', [
      'roofTypeBy',
      'slopeType',
      'tpoWarrantyOption',
      'substrate',
    ]),

    manufacturerList() {
      let manus = (this.currentBuilding.productData || []).reduce(
        (manufacturers, r) => {
          let mns = [
            ...new Set(
              r.productList.map(x => (x || {}).manufacturer).filter(z => z)
            ),
          ];
          _.remove(mns, r => manufacturers.includes(r));
          return (manufacturers || []).concat(mns || []);
        },
        []
      );
      return manus.join(', ');
    },

    priceListText() {
      if (
        _.isEmpty(this.priceList.priceListNumber) &&
        _.isEmpty(this.priceList.displayName)
      ) {
        return '';
      }

      return this.priceList.priceListNumber + ': ' + this.priceList.displayName;
    },

    taxApplyTypeBy() {
      return type => {
        return this.TAX_APPLY_TYPE.find(item => item.value === type) || {};
      };
    },
    isAllSlopeType() {
      return (this.estimate || {}).slopeType === 'all-slope-type';
    },
    isAllRoofType() {
      return (this.estimate || {}).roofType === 'all-roof-type';
    },
  },

  methods: {
    handleScrollTop() {
      const targetElement = document.getElementById('building-information');
      targetElement.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style scoped>
.text-content ::v-deep .item-title-row {
  margin-top: 10px;
}
</style>
