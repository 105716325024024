<template>
  <div
    :class="`text-editor-container ${
      pageName !== BUILDING_DESCRIPTION_PAGE && 'hidden-overflow'
    }`"
  >
    <f7-text-editor
      ref="contentEdit"
      class="no-margin"
      style="height: 900px"
      :value="data.value"
      @texteditor:change="changeValue"
      :custom-buttons="customButtons"
      :buttons="buttons"
    ></f7-text-editor>
    <select-photo-popup ref="selectPhoto" />
    <select-color-popup ref="selectColor" />
  </div>
</template>

<script>
import SelectPhotoPopup from '../popup/SelectPhotoPopup.vue';
import SelectColorPopup from '../popup/SelectColorPopup.vue';
import { BUILDING_DESCRIPTION_PAGE } from '../../utility/const';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    SelectPhotoPopup,
    SelectColorPopup,
  },
  data() {
    let app = this;
    return {
      BUILDING_DESCRIPTION_PAGE,
      currentDoc: null,
      customButtons: {
        image: {
          content: '<i class="f7-icons">photo</i>',
          async onClick(editor) {
            const currentRange = editor.getSelectionRange();
            let photo = await app.$refs.selectPhoto.openPopup();
            if (photo && currentRange) {
              let image = `<img src=${photo.url} id=${photo.id} width="100%">`;
              editor.setSelectionRange(currentRange);
              document.execCommand('insertHTML', true, image);
            }
          },
        },
        textColor: {
          content: '<i class="f7-icons">pencil</i>',
          async onClick(editor) {
            const currentRange = editor.getSelectionRange();
            let color = await app.$refs.selectColor.openPopup();
            if (color && currentRange) {
              editor.setSelectionRange(currentRange);
              document.execCommand('foreColor', true, color);
            }
          },
        },
      },
      buttons: [
        ['bold', 'italic', 'underline', 'textColor'],
        ['orderedList', 'unorderedList'],
        ['link', 'image'],
        ['paragraph', 'h1', 'h2', 'h3'],
        ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
        ['subscript', 'superscript'],
        ['indent', 'outdent'],
      ],
    };
  },
  props: {
    data: Object,
    pageName: String,
  },
  computed: {
    ...mapGetters({
      proposal: 'proposal/proposal/proposal',
    }),
  },
  methods: {
    ...mapActions({
      setProposalField: 'proposal/proposal/setProposalField',
      copyPhoto: 'proposal/photo/copyPhoto',
    }),
    changeValue($event) {
      if (this.data.value === $event) return;
      var contanerElement = this.$refs.contentEdit.$el.getElementsByClassName(
        'text-editor-content'
      );
      var overFlowContent = this.isOverflown(contanerElement[0]);
      if (overFlowContent && this.pageName !== BUILDING_DESCRIPTION_PAGE) {
        this.$refs.contentEdit.f7TextEditor.setValue(this.data.value);
        this.$emit('change', { ...this.data, isOverflow: true });
      } else {
        this.$emit('change', { ...this.data, value: $event });
      }
    },
    isOverflown({ clientWidth, clientHeight, scrollWidth, scrollHeight }) {
      return scrollHeight > clientHeight || scrollWidth > clientWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-editor-container {
  position: relative;
  &::v-deep img {
    max-width: 100%;
  }
  .hidden-overflow ::v-deep .text-editor-content {
    overflow: hidden;
  }
}
</style>
