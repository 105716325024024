<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="popupOpened = false"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link
            popup-close
            @click.native="close"
            >Close</f7-link
          >
        </f7-nav-left>
        <f7-nav-title>Choose Project</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="next">Next</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list media-list>
        <!-- Project # -->
        <f7-list-item
          link
          @click.native="selectProject"
        >
          <div
            class="list-item-inner-start"
            slot="inner-start"
          >
            Project Title<required-asterisk />
          </div>
          <div
            class="list-item-title"
            slot="title"
          >
            {{ projectDetail ? projectDetail.title || 'Select project' : '' }}
          </div>
          <input-icon
            slot="media"
            icon="cube_box"
          ></input-icon>
          <div
            slot="text"
            style="color: red"
          >
            {{ projectErrorMessage }}
          </div>
        </f7-list-item>
      </f7-list>
      <project-list-popup
        ref="selectProject"
        @onSelected="onSelectedProject"
        queryFilters="scheduleOfValueId:?"
      ></project-list-popup>
    </f7-page>
  </f7-popup>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
import { mapGetters, mapActions } from 'vuex';
import { firebase } from '../../../../services/firebase.service';
// import { nowInHumanReadable } from "@/utility/date-time-tool";
import ProjectListPopup from '@/components/popups/ProjectListPopup.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    ProjectListPopup,
    InputIcon,
  },
  data() {
    return {
      currentScheduleOfValue: {
        projectId: '',
      },
      projectDetail: {},
      popupOpened: false,
    };
  },

  computed: {
    ...mapGetters('common/schedule-setting', ['scheduleSettingList']),
    projectErrorMessage() {
      if (!this.v$.currentScheduleOfValue.$error) return '';
      if (this.v$.currentScheduleOfValue.projectId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    defaultColumns() {
      return (
        this.scheduleSettingList.filter(r => r.createdBy === 'RooferIntel') ||
        []
      );
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {
    currentScheduleOfValue: {
      projectId: { required },
    },
  },

  methods: {
    ...mapActions('common/project', ['getProjectById']),
    ...mapActions('common/schedule-value', [
      'createScheduleValue',
      'unbindScheduleValue',
    ]),
    ...mapActions('dashboard/schedule', ['createSchedule']),
    ...mapActions('common/schedule-setting', ['getScheduleSettingList']),
    ...mapActions('dashboard/project', ['updateCard']),

    open() {
      this.popupOpened = true;
      this.currentScheduleOfValue.projectId = '';
      this.getScheduleSettingList();
    },

    navigateToSchedule(id) {
      this.unbindScheduleValue();
      this.$f7router.navigate(`/schedule-of-value/detail/${id}`, {
        pushState: true,
      });
    },
    initScheduleInfo() {
      let percentage = {
        id: new Date().getTime() + 1,
        name: 'Percentage',
        isInputPercent: true,
      };
      this.defaultColumns.forEach(r => {
        percentage[r.id + 'material'] = 70;
        percentage[r.id + 'labor'] = 30;
      });
      let scheduleInfomation = [
        {
          id: new Date().getTime(),
          name: 'Project Cost by Roof Type',
        },
        percentage,
        {
          id: 1,
          name: 'Building 1',
        },
        {
          id: 2,
          name: 'Building 2',
        },
      ];
      return scheduleInfomation;
    },
    async create() {
      const projectName = this.projectDetail.title || '';
      const scheduleOfValueId = await this.createScheduleValue({
        title: `${projectName} - Schedule of Value`,
        completedDate: firebase.firestore.Timestamp.now(),
        projectId: this.projectDetail.id,
        scheduleColunms: this.defaultColumns.map(r => r.id) || [],
        scheduleInfomation: this.initScheduleInfo(),
      });
      await this.updateCard({
        id: this.projectDetail.id,
        doc: {
          scheduleOfValueId: scheduleOfValueId,
        },
      });

      return scheduleOfValueId;
    },

    async next() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.$f7.preloader.show();
      try {
        const scheduleOfValueId = await this.create();
        this.popupOpened = false;
        this.navigateToSchedule(scheduleOfValueId);
      } finally {
        this.close();
        this.$f7.preloader.hide();
      }
    },
    selectProject() {
      this.$refs.selectProject.open();
    },
    async onSelectedProject(projectId) {
      this.currentScheduleOfValue.projectId = projectId;
      this.projectDetail = (await this.getProjectById(projectId)) || {};
    },
    close() {
      this.projectDetail = {};
      this.v$.$reset();
    },
  },
};
</script>

<style></style>
