<template>
  <SafetyDetail ref="" />
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onUnmounted,
  onMounted,
  ref,
} from 'vue';
import { useSafetyStore } from '../../../stores/safety-hub/actions';
import SafetyDetail from '../components/details/SafetyDetail.vue';
export default defineComponent({
  components: {
    // Menu,
    SafetyDetail,
  },
  setup() {
    const instance = getCurrentInstance() as any;

    const store = useSafetyStore();
    const router = instance.proxy.$f7router;

    onMounted(async () => {
      await store.getSafety();
    });
    // onBeforeUnmount: Được gọi trước khi component bị hủy
    onBeforeUnmount(() => {
      // destroyStore();
    });

    // onUnmounted: Được gọi sau khi component đã bị hủy
    onUnmounted(() => {});

    return {
      router,
    };
  },
});
</script>
