<template>
  <f7-popup
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="cancelChangePassword"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Change Password</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="doChangePassword">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list no-hairlines-md>
        <f7-list-input
          class="first-input"
          label="New password"
          type="password"
          placeholder="Input new password"
          :value="newPassword"
          @input="updateField('newPassword', $event.target.value)"
          clear-button
          :error-message="newPasswordErrorMessage"
          error-message-force
        ></f7-list-input>
        <f7-list-input
          label="Re-type password"
          type="password"
          placeholder="Retype new password"
          :value="confirmPassword"
          @input="updateField('confirmPassword', $event.target.value)"
          clear-button
          :error-message="confirmPasswordErrorMessage"
          error-message-force
        ></f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  props: {
    popupOpened: { type: Boolean, default: false },
  },
  data: () => ({
    newPassword: '',
    confirmPassword: '',
  }),
  methods: {
    ...mapActions('administration/user-list-page/user', ['changeUserPassword']),
    cancelChangePassword() {
      this.newPassword = '';
      this.confirmPassword = '';
      this.v$.$reset();
      this.$emit('close');
    },
    doChangePassword() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.changeUserPassword({ newPassword: this.newPassword }).then(() => {
        this.$f7.toast.create({
          text: 'Changed password success!',
          closeOnClick: true,
          closeButton: true,
          closeTimeout: 30000,
        });
        this.cancelChangePassword();
      });
    },
    updateField(fieldName, value) {
      this[fieldName] = value;
      this.v$[fieldName].$touch();
    },
    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector('.first-input input').focus();
      }
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    const checkPassword = password => {
      const passwordRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      return passwordRegex.test(password);
    };

    return {
      newPassword: {
        required,
        commonPasswordRule: password => checkPassword(password),
      },
      confirmPassword: {
        sameAsPassword: value => value === this.newPassword,
      },
    };
  },
  computed: {
    newPasswordErrorMessage() {
      if (!this.v$.newPassword.$error) return null;
      if (this.v$.newPassword.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.newPassword.commonPasswordRule.$invalid)
        return VALIDATION_MESSAGE.PASSWORD_MIN_LENGTH_TWO;
      return null;
    },
    confirmPasswordErrorMessage() {
      if (!this.v$.confirmPassword.$error) return null;
      if (this.v$.confirmPassword.sameAsPassword.$invalid)
        return VALIDATION_MESSAGE.PASSWORD_MISMATCH_TWO;
      return null;
    },
  },
};
</script>

<style></style>
