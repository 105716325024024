export default {
  invoicedReport: state => {
    return state.invoicedReportList ? state.invoicedReportList[0] : {} || {};
  },
  paidReport: state => {
    return state.paidReportList ? state.paidReportList[0] : {} || {};
  },
  unbilledReport: state => {
    return state.unbilledReportList ? state.unbilledReportList[0] : {} || {};
  },
  overdueReport: state => {
    return state.overdueReportList ? state.overdueReportList[0] : {} || {};
  },
  unpaidReport: state => {
    return state.unpaidReportList ? state.unpaidReportList[0] : {} || {};
  },

  invoiceReportByType: (_, getters) => type => {
    switch (type) {
      case 'invoiced':
        return getters.invoicedReport || {};

      case 'paid':
        return getters.paidReport || {};

      case 'un-billed':
        return getters.unbilledReport || {};

      case 'overdue':
        return getters.overdueReport || {};

      case 'un-paid':
        return getters.unpaidReport || {};

      default:
        return {};
    }
  },
};
