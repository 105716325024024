import {
  FirebaseActions,
  firestore,
} from '../../../../services/firebase.service';
import { searchClient } from '../../../../services/search.service';

import * as types from './types';
import {
  COLLECTION_ESTIMATE,
  ESTIMATE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
  ESTIMATE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
} from '../../../../utility/const';
export default tenant => {
  const EstimateActions = new FirebaseActions(
    `/system_client/${tenant}/estimate`,
    'estimate'
  );
  const estimateIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  const estimateIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    bindEstimateListBy: EstimateActions.bindCollectionBy,
    unbindEstimateList: EstimateActions.unbindCollection,

    bindEstimateListBys: EstimateActions.bindCollectionBys,

    bindEstimate: EstimateActions.bindDocument,
    unbindEstimate: EstimateActions.unbindDocument,

    createEstimate: EstimateActions.createDocument,
    updateEstimate: EstimateActions.updateDocument,

    getEstimateById: EstimateActions.getDocument,

    getEstimateBys: EstimateActions.getDocumentBys,
    async getEstimateListBys({ dispatch, commit }, conditions) {
      const estimateList = await dispatch('getEstimateBys', conditions);
      commit(types.SET_ESTIMATE_LIST, estimateList || []);
      return estimateList;
    },
    setEstimateField({ commit, state }, payload) {
      if (payload.value != state.estimate[payload.fieldName]) {
        commit(types.SET_ESTIMATE_FIELD, payload);
      }
    },

    getBuilding(_, { estimateId, buildingId }) {
      if (!estimateId || !buildingId) return;
      let collectionPath = `/system_client/${tenant}/estimate/${estimateId}/building`;
      return firestore
        .collection(collectionPath)
        .doc(buildingId)
        .get()
        .then(snap => {
          if (snap.exists) {
            let doc = snap.data();
            doc.id = snap.id;
            return doc;
          }
        });
    },

    getBuildingList(context, { estimateId }) {
      const collectionPath = `/system_client/${tenant}/estimate/${estimateId}/building`;
      return firestore
        .collection(collectionPath)
        .get()
        .then(snaps => {
          let docs = [];
          snaps.forEach(docSnap => {
            let doc = docSnap.data();
            doc.id = docSnap.id;
            docs.push(doc);
          });
          return docs;
        });
    },

    async getEstimateListByProjectId({ commit, dispatch }, projectId) {
      if (!projectId) return;
      const estimateList = await dispatch('getEstimateBys', [
        { prop: 'projectId', op: '==', val: projectId },
        { prop: 'isDeleted', op: '==', val: false },
        { prop: 'status', op: '==', val: 'active' },
      ]);

      for (let estimate of estimateList) {
        const buildings = await dispatch('getBuildingList', {
          estimateId: estimate.id,
        });

        estimate.buildings = buildings;
      }

      commit(types.SET_ESTIMATE_LIST, estimateList);
    },

    clearEstimateList({ commit }) {
      commit(types.SET_ESTIMATE_LIST, []);
    },
    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    async searchEstimate(
      { state, commit },
      { query, attributesToRetrieve, filters }
    ) {
      const index =
        state.order === 'asc' ? estimateIndexAsc : estimateIndexDesc;

      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${COLLECTION_ESTIMATE} AND isProjectArchived:false`,
        attributesToRetrieve: ESTIMATE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          ESTIMATE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index
        .search(query, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreEstimate(
      { state, commit },
      { query, attributesToRetrieve, filters, page }
    ) {
      const index =
        state.order === 'asc' ? estimateIndexAsc : estimateIndexDesc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${COLLECTION_ESTIMATE} AND isProjectArchived:false`,
        attributesToRetrieve: ESTIMATE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          ESTIMATE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index.search(query, requestOptions).then(result => {
        commit(types.ADD_MORE_RESULT, result);
        return result;
      });
    },

    setEstimateNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },

    setEstimateSearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },

    setEstimateSearchOrder({ commit }, payload) {
      commit(types.SET_SEARCH_ORDER, payload);
    },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },
  };
};
