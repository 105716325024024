/* eslint-disable no-unused-vars */
import { FirebaseActions } from '../../../../services/firebase.service';
import {
  CODE_PROPERTY_TYPE,
  CODE_JOB_TYPE,
  CODE_COMPANY_TYPE,
  CODE_PREPARATION_TASK_CHECKLIST,
  CODE_WO_TASK_TYPE,
  CODE_EXPENSE_TYPE,
  CODE_STATUS_OPTION,
  CODE_ROOF_TYPE,
  CODE_PAYMENT_MODE,
} from '@/utility/const';
import * as types from './types';
import {
  CODE_LEAD_SOURCE,
  ENTITY_RECURRING_INVOICE,
} from '../../../../utility/const';

export default tenant => {
  const typeAction = new FirebaseActions(
    `/system_client/${tenant}/app_constant`,
    'type'
  );

  return {
    getConstantTypeListBys: typeAction.getDocumentBys,
    // Use the in operator to combine up to 10 equality (==) clauses on the same field with a logical OR.
    // An in query returns documents where the given field matches any of the comparison values
    getConstantTypeList({ dispatch, commit, state }) {
      return dispatch('getConstantTypeListBys', [
        {
          prop: 'code',
          op: 'in',
          val: [
            CODE_PROPERTY_TYPE,
            CODE_JOB_TYPE,
            CODE_COMPANY_TYPE,
            CODE_PREPARATION_TASK_CHECKLIST,
            CODE_WO_TASK_TYPE,
            CODE_EXPENSE_TYPE,
            CODE_STATUS_OPTION,
            CODE_ROOF_TYPE,
            CODE_PAYMENT_MODE,
            CODE_LEAD_SOURCE,
          ],
        },
      ]).then(res => {
        commit(types.SET_CONSTANT_LIST, res);
      });
    },

    resetConstant({ commit }) {
      commit(types.SET_CONSTANT_LIST, []);
    },
  };
};
