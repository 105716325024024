<template>
  <div>
    <div class="custom-input">
      <div
        :class="`input ${getInputClass}`"
        :style="inputStyle"
      >
        <input
          type="text"
          v-model="internalValue"
          @input="onInputValue($event.target.value.trim())"
          @change="handleInputValue('changeValue')"
        />
      </div>
      <div
        :class="`input input-dropdown ${getInputClass}`"
        :style="inputStyle"
      >
        <select
          v-model="internalType"
          @change="changeType($event.target.value)"
        >
          <option value="percent">%</option>
          <option value="cash">$</option>
        </select>
      </div>
    </div>
    <span style="color: red; font-size: 11px">{{ errMessage }}</span>
  </div>
</template>
<script>
import { formatDots, formatPriceNumber } from '@/utility/number-tool';

export default {
  props: {
    value: [Number, String],
    type: { type: String, default: 'percent' },
    inputClass: String, // outline, bottom-line
    inputStyle: String,
    allowZero: { type: Boolean, default: true },
    limitPercent: { type: Boolean, default: false },
    isResetData: { type: Boolean, default: true },
  },
  data() {
    return {
      errMessage: '',
      internalValue: formatPriceNumber('0', false),
      internalType: 'percent',
      isInputActive: false,
      formatPriceNumber,
      formatDots,
    };
  },
  computed: {
    getInputClass() {
      switch (this.inputClass) {
        case 'outline':
          return 'input-outline';
        case 'bottom-line':
          return 'input-bottom-line';
        default:
          return '';
      }
    },
  },
  methods: {
    resetData() {
      this.errMessage = '';
      this.internalValue = formatPriceNumber('0', false);
      this.internalType = 'percent';
      this.isInputActive = false;
    },
    validate() {
      const value = formatDots(this.internalValue);
      if (!this.internalValue || !this.internalType) {
        this.errMessage = 'This field is required';
      } else if (!this.allowZero && value <= 0) {
        this.errMessage = 'Value must be greater than 0';
      } else if (
        this.limitPercent &&
        value > 100 &&
        this.internalType === 'percent'
      ) {
        this.errMessage = 'Max value is 100%';
      } else {
        this.errMessage = '';
      }
      return !!this.errMessage;
    },
    onInputValue(val) {
      this.isInputActive = true;
      this.internalValue = formatPriceNumber(val, false);
      this.handleInputValue('inputValue');
    },
    handleInputValue(event) {
      let newPrice = formatDots(this.internalValue);
      if (isNaN(newPrice)) {
        newPrice = 0;
      }
      if (event === 'changeValue') {
        this.isInputActive = false;
        this.internalValue = formatPriceNumber(
          newPrice === 0 ? '0' : newPrice.toFixed(2),
          false
        );
      }
      this.validate();
      this.$emit(event, newPrice);
    },
    changeType(type) {
      this.internalType = type;
      this.validate();
      this.$emit('changeType', type);
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!this.isInputActive) {
          this.internalValue = formatPriceNumber(
            isNaN(parseFloat(val)) || val === 0
              ? '0'
              : parseFloat(val).toFixed(2),
            false
          );
        }
      },
      immediate: true,
      deep: true,
    },
    type: {
      handler(val) {
        this.internalType = val;
      },
      immediate: true,
      deep: true,
    },
    isResetData(val) {
      if (val) {
        this.resetData();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-input {
  display: flex;
  align-items: center;
  gap: 8px;

  .input {
    &-outline input {
      padding: 4px 8px;
    }
    input {
      min-width: 70px;
    }
  }

  .input {
    &-outline select {
      padding: 4px 8px;
    }
    select {
      min-width: 50px;
    }
  }
}
.input-bottom-line {
  border-bottom: 1px solid;
}
</style>
