<template>
  <div>
    <standing-seam-estimate-info-mobile-form
      v-if="estimate.businessCode != 'service' && !$device.desktop"
    ></standing-seam-estimate-info-mobile-form>

    <tpo-building-info-form v-if="$device.desktop"></tpo-building-info-form>

    <standing-seam-building-measurement-form
      v-if="estimate.businessCode != 'service' && $device.desktop"
    ></standing-seam-building-measurement-form>

    <tpo-building-photo-form
      v-if="estimate.businessCode != 'service' && $device.desktop"
    ></tpo-building-photo-form>

    <f7-block-title class="row-action_left"
      >Product Items {{ currentBuilding.buildingName || '' }} (for total
      {{ currentBuilding.numberOfCopy || '' }}
      {{
        currentBuilding.numberOfCopy === 1 ? 'building' : 'buildings'
      }})</f7-block-title
    >

    <summary-product-table
      :isEditing="false"
      color="var(--f7-color-border-neutral)"
      textColor="var(--f7-color-text-neutral)"
    ></summary-product-table>
  </div>
</template>

<script>
import SummaryProductTable from '../tables/SummaryProductTable.vue';
import TpoBuildingInfoForm from '../forms/TpoBuildingInfoForm.vue';
import StandingSeamBuildingMeasurementForm from '../forms/StandingSeamBuildingMeasurementForm.vue';
import TpoBuildingPhotoForm from '../forms/TpoBuildingPhotoForm.vue';
import StandingSeamEstimateInfoMobileForm from '../forms/StandingSeamEstimateInfoMobileForm.vue';

import { mapGetters } from 'vuex';

export default {
  components: {
    SummaryProductTable,
    TpoBuildingInfoForm,
    StandingSeamBuildingMeasurementForm,
    // TpoBuildingAssemblyForm,
    TpoBuildingPhotoForm,
    StandingSeamEstimateInfoMobileForm,
  },

  props: { roofType: String },

  data() {
    return {};
  },

  computed: {
    ...mapGetters('estimate/estimate-page/estimate', [
      'estimate',
      'currentBuilding',
    ]),
  },

  methods: {
    validate() {},
    init() {},
  },
};
</script>

<style lang="scss" scoped>
.row-action {
  margin-top: 30px;
  &_right {
    margin-right: 2px;
  }

  &_left {
    margin-left: 10px;
    margin-bottom: 0px;
  }
}
</style>
