import { FirebaseActions } from '../../../../services/firebase.service';
import { PROP_IS_DELETED } from '../../../../utility/const';
import * as types from './types';

export default tenant => {
  const estimateActions = new FirebaseActions(
    `/system_client/${tenant}/estimate`,
    'estimate'
  );
  return {
    getEstimateListBys: estimateActions.getDocumentBys,
    updateEstimate: estimateActions.updateDocument,

    getEstimatesByProjectId({ dispatch, commit }, projectId) {
      return dispatch('getEstimateListBys', [
        {
          prop: 'projectId',
          val: projectId,
          op: '==',
        },
        { prop: PROP_IS_DELETED, val: true, op: '!=' },
        { prop: 'status', val: ['draft', 'active'], op: 'in' },
      ]).then(res => {
        commit(types.SET_ESTIMATE_LIST, res);
      });
    },

    deleteEstimate({ dispatch }, id) {
      return dispatch('updateEstimate', {
        id,
        doc: { [PROP_IS_DELETED]: true },
      });
    },

    resetEstimate({ commit }) {
      commit(types.SET_ESTIMATE_LIST, []);
    },
  };
};
