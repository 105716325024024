// src/store/index.ts
import { initializeTenant } from './actions';

export const myCertificates = (tenant: string) => {
  return {
    myCertificatesStore: initializeTenant(tenant),
  };
};

export default myCertificates;
