<template>
  <div>
    <f7-block-header>{{ title }}</f7-block-header>
    <f7-list>
      <!-- Main list item -->
      <f7-list-item
        v-for="(item, index) in proposalList"
        :key="index"
        swipeout
        text-color="blue"
      >
        <a
          class="cursor-pointer"
          slot="title"
          @click="openLink(item)"
          >{{ item.proposalNumber }}</a
        >
        <f7-checkbox
          slot="media"
          name="demo-radio-inline"
          :checked="item.isDefault === true"
          @change="onRadioChange(item.id, $event.target.checked)"
        ></f7-checkbox>

        <div slot="footer">{{ getStatusString(item.status) }}</div>
        <div
          slot="after"
          v-if="item.isDefault === true"
        >
          Official
        </div>
        <f7-swipeout-actions right>
          <f7-swipeout-button
            color="primary"
            :close="true"
            @click="removeProposal(item)"
            confirm-title="Confirm remove!"
            confirm-text="Are you sure you want to remove this proposal?"
            >Remove</f7-swipeout-button
          >
        </f7-swipeout-actions>
      </f7-list-item>

      <!-- Add list item -->
      <f7-list-item @click.native="toNewProposal">
        <div
          slot="media"
          class="add-button-icon cursor-pointer"
        >
          <f7-icon f7="plus_circle"></f7-icon>
        </div>
        <div
          class="cursor-pointer"
          slot="title"
        >
          {{ addButtonTitle }}
        </div>
      </f7-list-item>

      <!-- <f7-list-item @click="showProposalSumPopup"
        ><div slot="media" class="add-button-icon cursor-pointer">
          <f7-icon f7="sum"></f7-icon>
        </div>
        <div class="cursor-pointer" slot="title">
          View Proposal Summary
        </div></f7-list-item
      > -->
    </f7-list>
    <!-- <proposal-sum-popup ref="proposalSumPopup"></proposal-sum-popup> -->
  </div>
</template>

<script>
// import ProposalSumPopup from "../../plugins/dashboard/components/popup/ProposalSumPopup.vue";
import { toDisplayDateString } from '@/utility/datetime';
import { PROPOSAL_STATUS } from '@/utility/status.js';

export default {
  // components: { ProposalSumPopup },

  props: {
    value: { type: Object, default: () => {} },
    addButtonTitle: { type: String, default: 'Add a Proposal' },
    title: { type: String, default: 'Proposals' },
    proposalList: { type: Array, default: () => [] },
    action: { type: Object, default: () => {} },
    boardId: { type: String, default: '' },
    activeTab: { type: String, default: '' },
  },

  methods: {
    getStatusString(status) {
      return PROPOSAL_STATUS.getStatusName(status);
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    openLink(proposal) {
      const self = this;
      const boardId = this.boardId;
      const actionId = this.action.id;
      const projectId = this.value.id;
      const proposalNumber = proposal.proposalNumber;

      this.$emit('closePopup', () => {
        if (this.activeTab === 'tab-estimate') {
          this.$f7router.navigate(
            {
              name: 'proposal-detail',
              params: {
                boardType: 'swimlane', //self.$f7route.route.meta.boardType,
                businessCode: self.$f7route.route.meta.businessCode,
                boardId: boardId,
                actionId: actionId,
                projectId: projectId,
                proposalNumber: proposalNumber,
              },
              query: {
                activeTab: this.activeTab,
              },
            },
            { reloadAll: true, pushState: true }
          );
        } else {
          const url = `/dashboard/type/${
            self.$f7route.route.meta.boardType || 'swimlane'
          }/business/${
            self.$f7route.route.meta.businessCode
          }/board/${boardId}/action/${actionId}/project/${projectId}/proposal/${proposalNumber}`;

          this.$f7router.navigate(url, {
            pushState: true,
            reloadAll: true,
          });
        }
      });
    },

    onRadioChange(proposalID, value) {
      this.$emit('onUpdateProposal', {
        id: proposalID,
        doc: {
          isDefault: value,
        },
      });
    },

    toNewProposal() {
      this.$emit('onCreateNewProposal');
    },

    removeProposal(proposal) {
      this.$emit('onUpdateProposal', {
        id: proposal.id,
        doc: {
          projectId: '',
        },
      });
      this.$emit('onDeleteProposal', { id: proposal.id });
    },

    // showProposalSumPopup() {
    //   this.$refs.proposalSumPopup.show();
    // }
  },
};
</script>

<style></style>
