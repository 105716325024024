<template>
  <div>
    <div class="card data-table">
      <table>
        <thead>
          <tr>
            <th class="label-cell">Project#</th>
            <th class="label-cell">
              <span
                class="sortable-cell"
                :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
                @click="sort('actionTitle')"
                >Action Required</span
              >
              <div class="input input-dropdown">
                <select @change="changActionRequired">
                  <option value="0">All</option>
                  <option
                    v-for="action in filterActions(boardCode)"
                    :key="action.id"
                    :value="action.id"
                  >
                    {{ action.title }}
                  </option>
                </select>
              </div>
            </th>
            <th class="label-cell">
              <span
                class="sortable-cell"
                :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
                @click="sort('contactName')"
                >Contact Info</span
              >
              <div class="input">
                <input
                  @input="searchContact = $event.target.value.trim()"
                  type="text"
                  placeholder="Search contact"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>
            <th class="label-cell">
              <span
                class="sortable-cell"
                :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
                @click="sort('contactPhone')"
                lang=""
                >Phone Number</span
              >
              <!-- <div class="input">
                <input type="text" placeholder="Search phone number" />
              </div>-->
            </th>
            <th class="label-cell">
              <span
                class="sortable-cell"
                :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
                @click="sort('title')"
                >Project Information</span
              >
              <div class="input">
                <input
                  @input="searchProject = $event.target.value.trim()"
                  type="text"
                  placeholder="Search project"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>
            <th class="table-head-label">Assignees</th>
            <th
              class="label-cell sortable-cell"
              :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
              @click="sort('dueDate')"
            >
              Due Date
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="cursor-pointer"
            v-for="(card, index) in projectList"
            :key="`${card.id}-${index}`"
            @click="
              $emit('openCardDetailsPopup', {
                actionId: card.actionId,
                cardNumber: card.cardNumber,
              })
            "
          >
            <td class="label-cell">{{ card.cardNumber }}</td>
            <td class="label-cell">{{ card.actionTitle }}</td>
            <td class="label-cell">{{ card.contactName }}</td>
            <td class="label-cell">{{ card.contactPhone }}</td>
            <td class="label-cell">{{ card.title }}</td>

            <td class="label-cell">
              <!-- <f7-block v-show="users" strong class="display-flex"> -->
              <div class="assignee-cell">
                <div
                  v-for="(uid, index) in card.assigneeIDs"
                  :key="uid"
                  :class="index < maxUserDisplay ? 'assignee-avatar' : ''"
                >
                  <user-avatar
                    :avatarSize="25"
                    v-if="index < maxUserDisplay"
                    :imageUrl="userInfo(uid) ? userInfo(uid).photoURL : ''"
                    :name="userInfo(uid) ? userInfo(uid).displayName : ''"
                    :uid="uid"
                  ></user-avatar>
                </div>
                <div
                  v-if="(card.assigneeIDs || []).length > maxUserDisplay"
                  class="assignee-avatar more-assignee-avatar"
                  @mouseover="showRemainingAssignees = card.id"
                  @mouseleave="showRemainingAssignees = ''"
                >
                  <span>{{
                    `+${card.assigneeIDs.length - maxUserDisplay}`
                  }}</span>
                  <div
                    v-if="showRemainingAssignees === card.id"
                    class="remaining-assignees"
                  >
                    <ul>
                      <li
                        v-for="uid in card.assigneeIDs.slice(maxUserDisplay)"
                        :key="uid"
                      >
                        {{ userInfo(uid) ? userInfo(uid).displayName : '' }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- </f7-block> -->
            </td>
            <td class="label-cell">
              <!-- {{ toDisplayDateString(card.dueDate) }} -->
              <!-- {{ card.dueDate }} -->
              <f7-chip
                v-if="card.dueDate !== ''"
                slot="after"
                :text="toDisplayDateString(card.dueDate)"
                :color="dueDateColor(card.dueDate)"
              ></f7-chip>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/avatars/UserAvatar.vue';
import { mapGetters, mapActions } from 'vuex';
import methodsMixins from '../../mixin/methods';
import computedsMixins from '../../mixin/computeds';
import { sortLatest } from '@/utility/date-time-tool';

export default {
  mixins: [computedsMixins, methodsMixins],

  components: { UserAvatar },

  data() {
    return {
      maxUserDisplay: 5,
      filterActionId: '0',
      searchContact: '',
      searchProject: '',
      currentSort: null,
      currentSortDir: 'asc',
      showRemainingAssignees: '',
    };
  },

  computed: {
    ...mapGetters('dashboard/project', [
      'filterCardsGrid',
      'filterAction',
      'filterActions',
    ]),
    ...mapGetters('dashboard/project', ['boardCode', 'users']),
    ...mapGetters('dashboard/client', ['contactById']),

    userInfo() {
      return uid =>
        this.users.find(user => {
          return user.uid === uid;
        });
    },

    contactName() {
      return id => {
        let contact = this.contactById(id) || {};
        return `${contact.firstName || ''} ${contact.lastName || ''}`.trim();
      };
    },

    contactPhone() {
      return id => {
        let contact = this.contactById(id) || {};
        let phones = contact.phones || [];
        let phoneNumber =
          phones.find(item => item.code === 'main') || phones[0];
        return (phoneNumber || {}).value || '';
      };
    },

    actionNames() {
      return card => {
        let actions = card.actions;
        let actionsNames = [];

        for (const id of actions) {
          actionsNames.push(this.filterAction(id).title);
        }

        return actionsNames.join(', ').trim();
      };
    },

    projectList() {
      const filterCardsGrid = this.filterCardsGrid(this.boardCode).map(r => ({
        ...r,
        contactName: this.contactName(r.contactId),
        contactPhone: this.contactPhone(r.contactId),
      }));

      const listFilter = filterCardsGrid.filter(project => {
        return (
          (project.actionId === this.filterActionId ||
            this.filterActionId === '0') &&
          (project.contactName
            .toLowerCase()
            .includes(this.searchContact.toLowerCase()) ||
            this.searchContact === '') &&
          ((project.title || '')
            .toLowerCase()
            .includes(this.searchProject.toLowerCase()) ||
            this.searchProject === '')
        );
      });

      return sortLatest(listFilter);
    },
  },

  methods: {
    ...mapActions('dashboard/project', ['setCurrentCard', 'getAction']),

    changActionRequired(event) {
      this.filterActionId = event.target.value;
    },

    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
  },
};
</script>

<style lang="scss" scoped>
.assignee-avatar {
  margin-right: 5px;
}

.assignee-cell {
  display: flex;
  max-width: 400px;
}

.more-assignee-avatar {
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--f7-color-chip-neutral);
  font-size: 12px;
}

.remaining-assignees {
  position: absolute;
  width: 150px;
  top: 120%;
  left: -50%;
  transform: translateX(-50%);
  padding: 10px;
  z-index: 10;
  background-color: var(--f7-color-bg-15-neutral);
}

.remaining-assignees ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.remaining-assignees li {
  margin: 5px 0;
}
</style>
