<template>
  <!-- Building Measurement -->
  <f7-list :inset="$device.desktop">
    <f7-row class="full-row">
      <f7-col
        width="100"
        xsmall="50"
      >
        <f7-block-title> Measurement Information </f7-block-title>
        <f7-list :inline-labels="$f7.device.desktop || $f7.device.ipad">
          <!-- Field Area (SQ) -->
          <list-item-input
            label="Field Area (SQ)"
            readonly
            :value="
              (currentBuilding.fieldArea || 0) * currentBuilding.numberOfCopy
            "
          >
          </list-item-input>

          <!-- Ridge Length (LF) -->
          <list-item-input
            label="Ridge Length (LF)"
            readonly
            :value="
              (currentBuilding.ridgeLength || 0) * currentBuilding.numberOfCopy
            "
          ></list-item-input>
          <!-- Ridge Length (LF) -->
          <list-item-input
            label="Hip Length (LF)"
            readonly
            :value="
              (currentBuilding.hipLength || 0) * currentBuilding.numberOfCopy
            "
          ></list-item-input>

          <!-- Valley Length (LF) -->
          <list-item-input
            label="Valley Length (LF)"
            readonly
            :value="
              (currentBuilding.valleyLength || 0) * currentBuilding.numberOfCopy
            "
          ></list-item-input>

          <list-item-input
            label="Chimney"
            :value="
              (currentBuilding.chimney || 0) * currentBuilding.numberOfCopy
            "
            readonly
          >
            <input-icon
              slot="media"
              icon="app_badge"
            ></input-icon>
          </list-item-input>

          <!-- Ridge Vent  -->
          <f7-list-item
            disabled
            checkbox
            title="Ridge Vent "
            :checked="currentBuilding ? currentBuilding.ridgeVent : false"
          >
          </f7-list-item>
        </f7-list>
      </f7-col>

      <f7-col
        width="100"
        xsmall="50"
      >
        <f7-block-title> &nbsp;</f7-block-title>
        <f7-list :inline-labels="$f7.device.desktop || $f7.device.ipad">
          <list-item-input
            label="Side Wall (LF)"
            :value="
              (currentBuilding.sideWall || 0) * currentBuilding.numberOfCopy
            "
            readonly
          >
            <input-icon
              slot="media"
              icon="app_badge"
            ></input-icon>
          </list-item-input>

          <list-item-input
            label="Head Wall (LF)"
            :value="
              (currentBuilding.headWall || 0) * currentBuilding.numberOfCopy
            "
            readonly
          >
            <input-icon
              slot="media"
              icon="app_badge"
            ></input-icon>
          </list-item-input>

          <list-item-input
            label="Eave (LF)"
            :value="
              (currentBuilding.eaveLength || 0) * currentBuilding.numberOfCopy
            "
            readonly
          >
            <input-icon
              slot="media"
              icon="arrow_right_square"
            ></input-icon>
          </list-item-input>

          <list-item-input
            label="Rake (LF)"
            :value="
              (currentBuilding.rakeLength || 0) * currentBuilding.numberOfCopy
            "
            readonly
          >
            <input-icon
              slot="media"
              icon="arrow_right_square"
            ></input-icon>
          </list-item-input>

          <!-- Perimeter (LF) -->
          <list-item-input
            label="Perimeter (LF)"
            readonly
            placeholder="Enter Number"
            :value="
              (currentBuilding.perimeter || 0) * currentBuilding.numberOfCopy
            "
          ></list-item-input>
        </f7-list>
      </f7-col>
    </f7-row>
  </f7-list>
</template>

<script>
import ListItemInput from '../inputs/ListItemInput.vue';

import { mapGetters } from 'vuex';
export default {
  components: { ListItemInput },

  data() {
    return {
      priceListObject: {},
      vendorList: [],
      manufacturerList: [],
      insulationLayer: [],
      tpoWarrantyOptionList: [],
    };
  },

  computed: {
    ...mapGetters('estimate/estimate-page/estimate', ['currentBuilding']),
  },

  methods: {
    vendorByIds() {},
    changeVendors() {},
    manufacturersByIds() {},
    changeManufacturers() {},
  },
};
</script>

<style></style>
