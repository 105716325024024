<template>
  <div class="add-new-button">
    <div class="spacing"></div>
    <f7-button
      slot="title"
      @click="open"
      small
      class="btn-fill-gray display-flex align-items-center test2"
    >
      <f7-icon
        size="18"
        f7="plus"
        class="margin-right-half"
      ></f7-icon>
      Add new contact
    </f7-button>
    <!-- popup create -->
    <f7-popup
      :opened="popupOpened"
      @popup:closed="close"
    >
      <f7-page v-show="isNew">
        <f7-navbar>
          <f7-nav-left>
            <f7-link @click.native="close">Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>New Contact</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="create">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- Contact Name -->
        <f7-list>
          <f7-list-input
            class="first-input"
            type="text"
            placeholder="First name"
            label="First name"
            clear-button
            :value="currentContact.firstName"
            @input="set('firstName', $event.target.value.trim())"
            error-message-force
            :error-message="firstNameErrorMessage"
            @blur="v$.currentContact.firstName.$touch()"
            ><required-asterisk slot="label"></required-asterisk
          ></f7-list-input>
          <f7-list-input
            type="text"
            placeholder="Last name"
            label="Last name"
            clear-button
            :value="currentContact.lastName"
            @input="set('lastName', $event.target.value.trim())"
            error-message-force
            :error-message="lastNameErrorMessage"
            @blur="v$.currentContact.lastName.$touch()"
            ><required-asterisk slot="label"></required-asterisk
          ></f7-list-input>
        </f7-list>

        <!-- Phone -->
        <phone-input
          :id="currentContact.id"
          ref="phoneInput"
          isRequired
          :isCompared="false"
          :value="currentContact.phones"
          :listData="contactList"
          @input="setPhones($event)"
        ></phone-input>
        <!-- Email -->
        <email-input
          :id="currentContact.id"
          ref="emailInput"
          :value="currentContact.emails"
          @input="setEmails($event)"
        ></email-input>

        <!-- Address -->
        <address-auto-complete-input
          :id="currentContact.id"
          ref="addressInput"
          :value="currentContact.addresses"
          @input="setAddresses($event)"
        ></address-auto-complete-input>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import PhoneInput from '@/components/inputs/PhoneInput.vue';
import EmailInput from '@/components/inputs/EmailInput.vue';
import AddressAutoCompleteInput from '@/components/inputs/AddressAutoCompleteInput.vue';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    PhoneInput,
    EmailInput,
    AddressAutoCompleteInput,
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      currentContact: {
        firstName: {
          required,
        },

        lastName: {
          required,
        },
      },
    };
  },

  data() {
    return {
      currentContact: {},

      popupOpened: false,

      isNew: false,
    };
  },

  computed: {
    ...mapGetters('common/contact', ['contactList']),

    firstNameErrorMessage() {
      if (!this.v$.currentContact.firstName.$error) return null;
      if (this.v$.currentContact.firstName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    lastNameErrorMessage() {
      if (!this.v$.currentContact.lastName.$error) return null;
      if (this.v$.currentContact.lastName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },

  async created() {
    !this.contactList.length && (await this.getContactListBys());
  },

  methods: {
    ...mapActions('common/contact', [
      'createContact',
      'getContactListBys',
      'resetSearch',
    ]),

    open() {
      this.isNew = true;
      this.popupOpened = true;
    },

    set(prop, value) {
      Vue.set(this.currentContact, prop, value);
    },

    onDeleteContact(event) {
      this.$emit('onDeleteContact', event);
    },

    setPhones(val) {
      Vue.set(this.currentContact, 'phones', val);
    },

    setEmails(val) {
      Vue.set(this.currentContact, 'emails', val);
    },

    setAddresses(val) {
      Vue.set(this.currentContact, 'addresses', val);
    },

    close() {
      this.popupOpened = false;
      this.isNew = false;
      this.currentContact = {};
      this.v$.$reset();
      this.$refs.phoneInput.resetData();
    },

    create() {
      const self = this;
      this.v$.$touch();
      this.$refs.phoneInput.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      this.$refs.emailInput.v$.$touch();
      if (
        this.$refs.phoneInput.v$.$invalid ||
        this.$refs.addressInput.v$.$invalid ||
        this.$refs.emailInput.v$.$invalid ||
        this.v$.$invalid
      )
        return;

      this.$f7.preloader.show();
      return this.createContact(this.currentContact)
        .then(contactId => {
          self.$f7.preloader.hide();
          contactId && self.$emit('onCreated', contactId);
        })
        .finally(() => {
          self.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-inner {
  .item-title {
    display: flex !important;
  }

  .add-new-button {
    display: flex;

    .spacing {
      width: 30px;
    }
  }
}

.autocomplete-list-item {
  & > a {
    color: white !important;
  }
}
</style>
