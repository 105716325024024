import axiosService from './axios.service';
import 'firebase/auth';
import { firebase } from './firebase.service';

const auth = firebase.auth();

const addProposalDoc = (tenantId, data) => {
  data.createdBy = auth.currentUser
    ? auth.currentUser.displayName || auth.currentUser.email
    : '';
  data.createdById = auth.currentUser ? auth.currentUser.uid : '';
  data.id = '';
  data.isDeleted = false;
  return axiosService.post('/proposal/add', { tenantId, data }).then(res => {
    return res.data;
  });
};

export default {
  addProposalDoc,
};
