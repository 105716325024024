<template>
  <div>
    <f7-block-header>File A Claim</f7-block-header>
    <f7-list media-list>
      <f7-list-item>
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Insurance Company
        </div>
        <div
          class="list-item-title"
          slot="title"
        >
          {{ companyName }}
        </div>
        <input-icon
          slot="media"
          icon="globe"
        ></input-icon>
      </f7-list-item>
      <f7-list-input
        type="text"
        placeholder="Enter Policy"
        label="Policy#"
        clear-button
        :value="policy"
        @input="policy = $event.target.value.trim()"
        @blur="onUpdateCard('policy', $event.target.value)"
      >
        <input-icon
          slot="media"
          icon="person"
        ></input-icon>
      </f7-list-input>
      <f7-list-input
        type="text"
        placeholder="Enter claim"
        label="Claim#"
        clear-button
        :value="claim"
        @input="claim = $event.target.value.trim()"
        @blur="onUpdateCard('claim', $event.target.value)"
      >
        <input-icon
          slot="media"
          icon="person"
        ></input-icon>
      </f7-list-input>
    </f7-list>
    <f7-list
      accordion-list
      class="accordion-insurance"
    >
      <f7-list-item
        accordion-item
        class="accordion-insurance-item"
      >
        <div
          class="title-accordion"
          slot="title"
        >
          Agent Information
        </div>
        <f7-accordion-content>
          <f7-list>
            <f7-list-input
              type="text"
              placeholder="Enter agent name"
              label="Name"
              clear-button
              :value="agent.name"
              @input="agent.name = $event.target.value.trim()"
              @blur="
                onUpdateObject('agent', 'name', $event.target.value.trim())
              "
            >
              <input-icon
                slot="media"
                icon="person"
              ></input-icon>
            </f7-list-input>
            <f7-list-input
              label="Phone"
              placeholder="Enter agent phone"
              :value="agent.phone"
              @input="agent.phone = $event.target.value.trim()"
              @blur="
                onUpdateObject('agent', 'phone', $event.target.value.trim())
              "
              v-mask="'(+1) ###-###-####'"
              error-message-force
              :error-message="phoneErrorMessage('agent')"
            >
              <input-icon
                slot="media"
                icon="phone"
              ></input-icon>
            </f7-list-input>
            <f7-list-input
              label="Email"
              placeholder="Enter agent email"
              :value="agent.email"
              @input="agent.email = $event.target.value.trim()"
              @blur="
                onUpdateObject('agent', 'email', $event.target.value.trim())
              "
              error-message-force
              :error-message="emailErrorMessage('agent')"
            >
              <input-icon
                slot="media"
                icon="envelope"
              ></input-icon>
            </f7-list-input>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
    <f7-list
      accordion-list
      class="accordion-insurance"
    >
      <f7-list-item
        accordion-item
        class="accordion-insurance-item"
      >
        <div
          class="title-accordion"
          slot="title"
        >
          Desk Adjuster Information
        </div>
        <f7-accordion-content>
          <f7-list media-list>
            <f7-list-input
              type="text"
              placeholder="Enter adjuster name"
              label="Name"
              clear-button
              :value="deskAdjuster.name"
              @input="deskAdjuster.name = $event.target.value.trim()"
              @blur="
                onUpdateObject(
                  'deskAdjuster',
                  'name',
                  $event.target.value.trim()
                )
              "
            >
              <input-icon
                slot="media"
                icon="person"
              ></input-icon>
            </f7-list-input>
            <f7-list-input
              label="Phone"
              placeholder="Enter adjuster phone"
              :value="deskAdjuster.phone"
              @input="deskAdjuster.phone = $event.target.value.trim()"
              @blur="
                onUpdateObject(
                  'deskAdjuster',
                  'phone',
                  $event.target.value.trim()
                )
              "
              v-mask="'(+1) ###-###-####'"
              error-message-force
              :error-message="phoneErrorMessage('deskAdjuster')"
            >
              <input-icon
                slot="media"
                icon="phone"
              ></input-icon>
            </f7-list-input>
            <f7-list-input
              label="Email"
              placeholder="Enter adjuster email"
              :value="deskAdjuster.email"
              @input="deskAdjuster.email = $event.target.value.trim()"
              @blur="
                onUpdateObject(
                  'deskAdjuster',
                  'email',
                  $event.target.value.trim()
                )
              "
              error-message-force
              :error-message="emailErrorMessage('deskAdjuster')"
            >
              <input-icon
                slot="media"
                icon="envelope"
              ></input-icon>
            </f7-list-input>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
    <f7-list
      accordion-list
      class="accordion-insurance"
    >
      <f7-list-item
        accordion-item
        class="accordion-insurance-item"
      >
        <div
          class="title-accordion"
          slot="title"
        >
          Field Adjuster Information
        </div>
        <f7-accordion-content>
          <f7-list media-list>
            <f7-list-input
              type="text"
              placeholder="Enter adjuster name"
              label="Name"
              clear-button
              :value="fieldAdjuster.name"
              @input="fieldAdjuster.name = $event.target.value.trim()"
              @blur="
                onUpdateObject(
                  'fieldAdjuster',
                  'name',
                  $event.target.value.trim()
                )
              "
            >
              <input-icon
                slot="media"
                icon="person"
              ></input-icon>
            </f7-list-input>
            <f7-list-input
              label="Phone"
              placeholder="Enter adjuster phone"
              :value="fieldAdjuster.phone"
              @input="fieldAdjuster.phone = $event.target.value.trim()"
              @blur="
                onUpdateObject(
                  'fieldAdjuster',
                  'phone',
                  $event.target.value.trim()
                )
              "
              v-mask="'(+1) ###-###-####'"
              error-message-force
              :error-message="phoneErrorMessage('fieldAdjuster')"
            >
              <input-icon
                slot="media"
                icon="phone"
              ></input-icon>
            </f7-list-input>
            <f7-list-input
              label="Email"
              placeholder="Enter adjuster email"
              :value="fieldAdjuster.email"
              @input="fieldAdjuster.email = $event.target.value.trim()"
              @blur="
                onUpdateObject(
                  'fieldAdjuster',
                  'email',
                  $event.target.value.trim()
                )
              "
              error-message-force
              :error-message="emailErrorMessage('fieldAdjuster')"
            >
              <input-icon
                slot="media"
                icon="envelope"
              ></input-icon>
            </f7-list-input>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <f7-list media-list>
      <f7-list-input
        label="Inspect Date"
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
        }"
        placeholder="Enter Inspect Date"
        :value="insuranceInspectDate || []"
        @calendar:change="
          onUpdateDate($event);
          $f7.calendar.close();
        "
        validate
        validate-on-blur
      >
        <input-icon
          slot="media"
          icon="calendar"
        ></input-icon>
      </f7-list-input>
      <f7-list-input
        :class="{
          'inspect-time-placeholder': !insuranceInspectTime,
          'inspect-time-ios': $device.ios,
          'inspect-time-android': $device.android,
          'inspect-time-desktop': $device.desktop || $device.ipad,
        }"
        :style="inputDarkClass"
        label="Inspect Time"
        type="time"
        :value="insuranceInspectTime"
        @input="onUpdateCard('insuranceInspectTime', $event.target.value)"
        validate
        validate-on-blur
      >
        <input-icon
          slot="media"
          icon="clock"
        ></input-icon>
      </f7-list-input>
    </f7-list>
    <f7-list media-list>
      <photo-section-residential
        class="margin-top"
        ref="photoSectionResidential"
        :actionId="action.id"
      ></photo-section-residential>
      <f7-list-input
        label="Field Inspection"
        type="select"
        placeholder="Please choose..."
        :value="fieldInspection"
        @input="saveFieldInspection($event.target.value)"
        validate
        validate-on-blur
      >
        <input-icon
          slot="media"
          icon="selection_pin_in_out"
        ></input-icon>
        <option
          value=""
          disabled
        >
          Select Result
        </option>
        <option
          v-for="(type, index) in fieldInspectionList"
          :key="index"
          :value="type.value"
        >
          {{ type.displayName }}
        </option>
      </f7-list-input>
    </f7-list>
    <attachment-input
      :project-id="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>
    <user-input
      @input="setAssigneeIDs($event)"
      :value="displayCard.assigneeIDs"
    ></user-input>
    <note-input
      :value="displayCard.commentAndNote"
      @change="saveNote($event)"
    ></note-input>
    <date-popup
      ref="insuranceInspectionDueDatePopup"
      title="Enter Insurance Review Due Date"
      label="Due Date"
      v-model="insuranceClaimDueDate"
      @done="resolveInsuranceInspection"
      not-allow-past
    ></date-popup>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import methodsMixins from '../../mixin/methods';
import residentialMixins from '../../mixin/residential';
import UserInput from '../input/UserInput.vue';
import NoteInput from '../input/NoteInput.vue';
import InputIcon from '../icon/InputIcon.vue';
import AttachmentInput from '../../../dashboard/components/input/AttachmentInput.vue';
import DatePopup from '../popup/DatePopup.vue';
import PhotoSectionResidential from '../photo/PhotoSectionResidential.vue';
import { useVuelidate } from '@vuelidate/core';
import { email, minLength } from '@vuelidate/validators';
import { firebase, auth } from '../../../../services/firebase.service';
import { toDateCalendar, toDateFirebase } from '../../../../utility/datetime';
import moment from 'moment';
import { mask } from 'vue-the-mask';
import _ from 'lodash';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    UserInput,
    InputIcon,
    NoteInput,
    AttachmentInput,
    DatePopup,
    PhotoSectionResidential,
  },
  mixins: [methodsMixins, residentialMixins],
  directives: { mask },
  data: () => {
    return {
      currentResponse: {},
      displayCard: {},
      insuranceInspectDate: [],
      insuranceInspectTime: '',
      insuranceClaimDueDate: [],
      agent: {
        name: '',
        phone: '',
        email: '',
      },
      deskAdjuster: {
        name: '',
        phone: '',
        email: '',
      },
      fieldAdjuster: {
        name: '',
        phone: '',
        email: '',
      },
      policy: '',
      claim: '',
      fieldInspection: '',
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    ...mapGetters('dashboard/project', ['card', 'action']),
    // TODO insurance Company
    ...mapGetters('dashboard/client', ['insuranceCompany']),

    companyName() {
      return this.insuranceCompany ? this.insuranceCompany.companyName : '';
    },

    requireErrorMessage() {
      return (parent, children) => {
        if (children) {
          if (!this.v$[parent][children].$error) return null;
          if (this.v$[parent][children].required.$invalid)
            return VALIDATION_MESSAGE.REQUIRED_FIELD;
        } else {
          if (!this.v$[parent].$error) return null;
          if (this.v$[parent].required.$invalid)
            return VALIDATION_MESSAGE.REQUIRED_FIELD;
        }
        return null;
      };
    },
    emailErrorMessage() {
      return parent => {
        if (!this.v$[parent].email.$error) return null;
        if (this.v$[parent].email.commonEmailRule.$invalid)
          return VALIDATION_MESSAGE.INVALID_EMAIL;
        return null;
      };
    },

    phoneErrorMessage() {
      return parent => {
        if (!this.v$[parent].phone.$error) return null;
        if (this.v$[parent].phone.minLength.$invalid)
          return VALIDATION_MESSAGE.PHONE_NUMBER_FORMAT;
        // if (this.v$[parent].phone.commonPhoneRule.$invalid)
        //   return VALIDATION_MESSAGE.INVALID_PHONE_NUMBER;
        return null;
      };
    },

    inputDarkClass() {
      let theme =
        localStorage.getItem('themeDark') === 'true' ? 'dark' : 'light';
      return `color-scheme: ${theme};`;
    },

    fieldInspectionList() {
      return [
        {
          value: 'approval',
          displayName: 'Approval',
        },
        {
          value: 'denial',
          displayName: 'Denial',
        },
      ];
    },
  },

  methods: {
    ...mapActions('dashboard/project', ['updateCard']),

    initData() {
      if (!_.isEmpty(this.$refs.photoSectionResidential)) {
        this.$refs.photoSectionResidential.initData(this.displayCard.id);
      }
    },
    initCardDisplayValues() {
      if (!_.isEmpty(this.card)) {
        this.displayCard = _.cloneDeep(this.card);
        this.initData();
        if (
          this.displayCard.insuranceInspectDate &&
          (_.isEmpty(this.insuranceInspectDate) ||
            (!_.isEmpty(this.insuranceInspectDate) &&
              !moment(this.insuranceInspectDate[0]).isSame(
                moment(
                  toDateCalendar(this.displayCard.insuranceInspectDate)[0]
                ),
                'day'
              )))
        ) {
          this.insuranceInspectDate = toDateCalendar(
            this.displayCard.insuranceInspectDate
          );
        }
        if (this.displayCard.insuranceInspectTime) {
          this.insuranceInspectTime = this.displayCard.insuranceInspectTime;
        }
        this.agent = {
          name: '',
          phone: '',
          email: '',
          ...(this.displayCard.agent || {}),
        };
        this.deskAdjuster = {
          name: '',
          phone: '',
          email: '',
          ...(this.displayCard.deskAdjuster || {}),
        };
        this.fieldAdjuster = {
          name: '',
          phone: '',
          email: '',
          ...(this.displayCard.fieldAdjuster || {}),
        };
        this.policy = this.displayCard.policy || '';
        this.claim = this.displayCard.claim || '';
        this.fieldInspection = this.displayCard.fieldInspection || '';
      }
    },

    onUpdateObject(key, prop, value) {
      if (prop != 'name') {
        this.v$[key][prop].$touch();
        if (this.v$[key][prop].$invalid) return;
        this[key][prop] = value;
        this.onUpdateCard(key, this[key]);
      } else {
        this[key][prop] = value;
        this.onUpdateCard(key, this[key]);
      }
    },

    onUpdateDate(value) {
      if (!_.isEmpty(value)) {
        if (
          _.isEmpty(this.insuranceInspectDate) ||
          (!_.isEmpty(this.insuranceInspectDate) &&
            !moment(value[0]).isSame(
              moment(this.insuranceInspectDate[0]),
              'day'
            ))
        ) {
          this.insuranceInspectDate = value;
          this.onUpdateCard('insuranceInspectDate', toDateFirebase(value));
          this.onUpdateCard('dueDate', toDateFirebase(value));
        }
      }
    },

    onUpdateCard(field, value, isShowLoading) {
      if (value === undefined) return;
      isShowLoading && this.$f7.preloader.show();
      this.updateCard({
        id: this.card.id,
        doc: {
          [field]: value,
        },
      })
        .then(() => {
          if (['policy', 'claim'].includes(field)) {
            this.$emit('onChangeHashtag');
          }
        })
        .finally(() => {
          isShowLoading && this.$f7.preloader.hide();
        });
    },

    // Implement do something before resolve action
    doAction(response) {
      if (response.code === 'move-to-insurance-claim') {
        this.v$.$touch();
        if (this.v$.$invalid) return;
        this.currentResponse = response;
        this.$refs.insuranceInspectionDueDatePopup.open();
      }
    },

    // Implement save action
    resolveInsuranceInspection() {
      this.displayCard.dueDate = this.insuranceClaimDueDate;
      this.resolve();
    },

    async save() {
      this.$f7.preloader.show();
      let doc = {};
      doc.dueDate = toDateFirebase(this.displayCard.dueDate);
      doc.fieldInspection = this.fieldInspection || '';
      await this.updateCard({
        id: this.card.id,
        doc,
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },

    async saveNote(event) {
      if (event) {
        const commentAndNote =
          _.cloneDeep(this.displayCard.commentAndNote) || [];
        commentAndNote.push({
          code: 'note',
          title: 'Claim/Inspection Note',
          htmlContent: event,
          createdAt: firebase.firestore.Timestamp.now(),
          createdById: auth.currentUser.uid,
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        });

        this.onUpdateCard('commentAndNote', commentAndNote);
      }
    },

    saveFieldInspection(value) {
      this.fieldInspection = value;
      this.onUpdateCard('fieldInspection', value);
      if (value === 'denial') {
        this.onUpdateCard('approvalStatus', 'denied');
      } else if (value === 'approval') {
        this.onUpdateCard('approvalStatus', 'processing');
      }
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      agent: {
        phone: { minLength: minLength(17) },
        email: {
          commonEmailRule: email,
        },
      },
      deskAdjuster: {
        phone: { minLength: minLength(17) },
        email: {
          commonEmailRule: email,
        },
      },
      fieldAdjuster: {
        phone: { minLength: minLength(17) },
        email: {
          commonEmailRule: email,
        },
      },
    };
  },
  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}

.inspect-time-desktop ::v-deep input[type='time'] {
  width: 100px;
}

.inspect-time-android ::v-deep input[type='time'] {
  width: 100%;
}

.inspect-time-ios ::v-deep input[type='time']::-webkit-date-and-time-value {
  text-align: left;
  width: 100%;
}

.inspect-time-placeholder ::v-deep input[type='time']:before {
  content: 'HH:MM AA';
  color: #9d9d9d;
  position: absolute;
  background: var(--f7-color-gray-light-scroll);
}

.inspect-time-placeholder
  ::v-deep
  input[type='time']::-webkit-calendar-picker-indicator {
  filter: var(--f7-color-text-neutral);
}

.inspect-time-placeholder ::v-deep input[type='time']:focus:before {
  content: '';
}

.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}

.accordion-insurance ::v-deep {
  margin-top: -5px;

  & ul:before {
    height: 0px;
  }
}

.title-accordion {
  color: var(--f7-block-header-text-color);
}
</style>
