import axiosService from './axios.service';

async function getPaymentHistory() {
  const res = await axiosService.get('/subscription/payment-history');
  return res ? res.data : {};
}

async function getFailInvoices() {
  const res = await axiosService.get('/subscription/get-fail-invoices');
  return res ? res.data : {};
}

async function getPaymentMethods() {
  const res = await axiosService.get('/subscription/get-payment-methods');
  return res ? res.data : {};
}

async function payInvoice({ invoiceId }) {
  const res = await axiosService.post('/subscription/pay-invoice', {
    invoiceId,
  });
  return res ? res.data : {};
}

async function upgradeSubscription(data) {
  const res = await axiosService.post(
    '/subscription/upgrade-subscription',
    data
  );
  return res ? res.data : {};
}

async function createCheckoutSession(data) {
  const res = await axiosService.post(
    '/subscription/create-checkout-session',
    data
  );
  return res ? res.data : {};
}

async function updatePaymentMethod(data) {
  const res = await axiosService.post(
    '/subscription/update-payment-method',
    data
  );
  return res ? res.data : {};
}

async function updatePaymentMethodFromSession(data) {
  const res = await axiosService.post(
    '/subscription/update-payment-method-from-sessionid',
    data
  );
  return res ? res.data : {};
}

async function deletePaymentMethod({ paymentMethodId }) {
  const res = await axiosService.post('/subscription/delete-payment-method', {
    paymentMethodId,
  });
  return res ? res.data : {};
}

async function closeAccount({ closeAccountDate, isAccountClosed }) {
  const res = await axiosService.post('/subscription/close-account', {
    closeAccountDate,
    isAccountClosed,
  });
  return res ? res.data : {};
}

export {
  upgradeSubscription,
  getPaymentHistory,
  createCheckoutSession,
  updatePaymentMethodFromSession,
  updatePaymentMethod,
  deletePaymentMethod,
  getFailInvoices,
  payInvoice,
  getPaymentMethods,
  closeAccount,
};
