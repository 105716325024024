import { checkAuth } from '../../../services/authentication.service';
// import EstimateList from "../pages/EstimateList.vue";
// import EstimateTemplate from "../pages/EstimateTemplate.vue";

export default [
  {
    path: '',
    title: 'Estimate',
    displayRoles: ['owner', 'user'],
    routes: [
      {
        path: '/estimate',
        title: 'Estimate',
        parent: 'dashboard-main',
        name: 'estimate-main',
        displayRoles: ['owner', 'user'],
        icon: 'minus_slash_plus',
        // eslint-disable-next-line no-unused-vars
        async: checkAuth(routeTo => {
          // if (localStorage.getItem("desktop") === "true") {
          //   routeTo.route.master = false;
          //   return import("../pages/EstimateTable.vue");
          // }
          return import('../pages/ContactListPage.vue');
        }),
        master: true,
        detailRoutes: [
          // new
          {
            path: '/estimate/new',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'New Estimate',
            async: checkAuth(() => import('../pages/EstimateNewPage.vue')),
          },
          {
            path: '/estimate/:estimateNumber',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'Estimate Detail',
            async: checkAuth(() => import('../pages/EstimateDetailPage.vue')),
          },
          {
            path: '/estimate/:estimateNumber/edit',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'Estimate Detail',
            meta: { isEditing: true },
            async: checkAuth(() => import('../pages/EstimateDetailPage.vue')),
          },
          {
            path: '/estimate/:estimateNumber/summary',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'Summary',
            async: checkAuth(() => import('../pages/SummaryPage.vue')),
          },
          {
            path: '/estimate/:estimateNumber/building/:buildingName',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'Building Estimate Detail',
            async: checkAuth(
              () => import('../pages/BuildingEstimateDetailPage.vue')
            ),
          },
        ],
      },
      {
        path: '/estimate/:clientType/:clientId',
        checkAccessPath: 'dashboard-main/estimate-main',
        title: 'Estimate',
        name: 'estimate-list',
        displayRoles: ['owner', 'user'],
        icon: 'minus_slash_plus',
        // eslint-disable-next-line no-unused-vars
        async: checkAuth(routeTo => {
          return import('../pages/EstimateListPage.vue');
        }),
        master: true,
        detailRoutes: [
          // new
          {
            path: '/estimate/:clientType/:clientId/new',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'New Estimate',
            async: checkAuth(() => import('../pages/EstimateNewPage.vue')),
          },
          // from dashboard
          {
            path: '/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/property/:propertyId/estimate/:clientType/:clientId/new',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'New Estimate',
            name: 'new-estimate-swimlane-commercial',
            async: checkAuth(() => import('../pages/EstimateNewPage.vue')),
          },
          // from swimlane board
          {
            path: '/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/contact/:contactId/estimate/:clientType/:clientId/new',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'New Estimate',
            name: 'new-estimate-swimlane-service',
            async: checkAuth(() => import('../pages/EstimateNewPage.vue')),
          },
          // residential
          {
            path: '/dashboard/type/:boardType/business/:businessCode/action/:actionId/project/:projectId/contact/:contactId/estimate/:clientType/:clientId/new',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'New Estimate',
            name: 'new-estimate-swimlane-residential',
            async: checkAuth(() => import('../pages/EstimateNewPage.vue')),
          },

          {
            path: '/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/estimate/:clientType/:clientId/new',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'New Estimate',
            name: 'new-estimate-swimlane',
            async: checkAuth(() => import('../pages/EstimateNewPage.vue')),
          },

          // details

          {
            path: '/estimate/:clientType/:clientId/:estimateNumber',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'Estimate Detail',
            async: checkAuth(() => import('../pages/EstimateDetailPage.vue')),
          },
          {
            path: '/estimate/:clientType/:clientId/:estimateNumber/edit',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'Estimate Detail',
            meta: { isEditing: true },
            async: checkAuth(() => import('../pages/EstimateDetailPage.vue')),
          },
          {
            path: '/estimate/:clientType/:clientId/:estimateNumber/summary',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'Summary',
            async: checkAuth(() => import('../pages/SummaryPage.vue')),
          },
          {
            path: '/estimate/:clientType/:clientId/:estimateNumber/building/:buildingName',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'Building Estimate Detail',
            async: checkAuth(
              () => import('../pages/BuildingEstimateDetailPage.vue')
            ),
          },

          {
            path: '/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/estimate/:clientType/:clientId/:estimateNumber',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'Estimate Details',
            async: checkAuth(() => import('../pages/EstimateDetailPage.vue')),
          },
          {
            path: '/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/estimate/:clientType/:clientId/:estimateNumber/edit',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'Estimate Details',
            meta: { isEditing: true },
            async: checkAuth(() => import('../pages/EstimateDetailPage.vue')),
          },
          {
            path: '/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/task/:taskId/estimate/:clientType/:clientId/:estimateNumber',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'Estimate Details',
            async: checkAuth(() => import('../pages/EstimateDetailPage.vue')),
          },
          {
            path: '/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/task/:taskId/estimate/:clientType/:clientId/:estimateNumber/edit',
            checkAccessPath: 'dashboard-main/estimate-main',
            title: 'Estimate Details',
            async: checkAuth(() => import('../pages/EstimateDetailPage.vue')),
          },
        ],
      },
    ],
  },
];
