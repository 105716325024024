import Vue from 'vue';
import * as types from './types';

export default {
  setTimeTrackingList(state, payload) {
    Vue.set(state, 'timeTrackingList', payload);
  },
  setTimeTrackingSubscribe(state, payload) {
    Vue.set(state, 'timeTrackingSubscribe', payload);
  },
  setViewport(state, { startDate, endDate }) {
    state.viewport = { ...state.viewport, startDate, endDate };
  },
  openTimerPopup(state) {
    state.showTimerPopup = true;
  },
  closeTimerPopup(state) {
    state.showTimerPopup = false;
  },
  setDetailUid(state, data) {
    state.detailUid = data;
  },
  setStatusFilter(state, value) {
    state.statusFilter = value;
  },
  setPropertyFilter(state, value) {
    state.propertyFilter = value;
  },
  setProjectFilter(state, value) {
    state.projectFilter = value;
  },
  setWeekFilter(state, value) {
    state.weekFilter = value;
  },

  [types.CHANGE_TIME_TRACKING_ITEM](state, { id, item }) {
    const list = state.timeTrackingList.map(r => (r.id === id ? item : r));
    Vue.set(state, 'timeTrackingList', list);
  },

  [types.SET_TIME_TRACKING_LIST](state, payload) {
    Vue.set(state, 'timeTrackingList', payload);
  },

  [types.SET_DATES_TO_FILTER_QB](state, payload) {
    Vue.set(state, 'datesToFilterQB', payload);
  },
};
