import Vue from 'vue';

export default {
  setScheduleList(state, payload) {
    Vue.set(state, 'scheduleList', payload);
  },
  setScheduleSubscribe(state, payload) {
    Vue.set(state, 'scheduleSubscribe', payload);
  },
};
