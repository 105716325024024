export default {
  // workOrderList: [],
  // workOrder: {},

  workOrderActionList: [],
  workOrderAction: {},

  workOrderTaskList: [],
  workOrderTask: {},

  response: [],
  searchText: '',
  weekFilter: [],

  purchaseOrderSubscribe: null,
  workOrderTaskSubscribe: null,

  purchaseOrderList: [],
  purchaseOrder: {},
  poResponse: [],
};
