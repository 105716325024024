<template>
  <f7-popup
    :opened="isOpen"
    @popup:closed="handlePopupClosed"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Estimate</f7-nav-title>
        <f7-nav-right><f7-link @click="onDone">Done</f7-link></f7-nav-right>
      </f7-navbar>

      <f7-searchbar
        disable-button-text
        placeholder="Search estimate"
        :clear-button="true"
        :value="searchText"
        @input="handleSearch($event.target.value)"
        @searchbar:clear="
          setEstimateSearchText('');
          onSearch();
        "
        @searchbar:disable="
          setEstimateSearchText('');
          onSearch();
        "
        class="search-list-popup"
      ></f7-searchbar>

      <div
        class="page-content"
        style="padding-top: 0"
        @scroll="onScroll"
      >
        <f7-list class="search-list searchbar-found contact-list">
          <f7-list-item
            no-chevron
            link
            v-for="estimate in estimateListSearch(searchText)"
            :checkbox="isMultiEst"
            :key="estimate.id"
            :title="`${estimate.estimateNumber} - ${estimate.estimateName}`"
            :checked="
              isMultiEst ? selectedEstimates.includes(estimate.id) : false
            "
            @click.native="select(estimate.id)"
          >
            <div slot="after">{{ estimate.amount | currencyUSD }}</div>
          </f7-list-item>
        </f7-list>
        <div class="text-align-center margin-vertical-half">
          <f7-preloader v-show="hasMoreItems"></f7-preloader>
        </div>
        <f7-block
          class="text-align-center"
          v-show="!hasData"
          >No Data</f7-block
        >
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

export default {
  props: {
    selectedIds: {
      type: Array,
      default: () => [],
    },
    isMultiEst: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return {
      isOpen: false,
      selectedEstimates: [],
      projectId: null,
    };
  },
  created() {
    this.setEstimateSearchText('');
    this.onSearch();
  },
  computed: {
    ...mapGetters('invoices/estimate', [
      'estimateList',
      'order',
      'searchText',

      'hits',
      'hitsPerPage',
      'nbHits',
      'nbPages',
      'page',
    ]),

    estimateListSearch() {
      return searchText => {
        if (!this.projectId) {
          return this.hits;
        } else {
          return (this.estimateList || [])
            .filter(estimate => {
              let estimateName = (estimate.estimateName || '').toLowerCase();
              let estimateNumber = (
                estimate.estimateNumber || ''
              ).toLowerCase();

              return (
                (this.projectId
                  ? estimate.projectId === this.projectId
                  : true) &&
                (estimateName.indexOf(searchText.toLowerCase()) >= 0 ||
                  estimateNumber.indexOf(searchText.toLowerCase()) >= 0)
              );
            })
            .sort((a, b) => sortBy(b.estimateNumber, a.estimateNumber));
        }
      };
    },
  },

  methods: {
    ...mapActions('invoices/estimate', [
      'getEstimateListByProjectId',
      'searchEstimate',
      'loadMoreEstimate',
      'setEstimateNumberOfRows',
      'setEstimateSearchText',
      'resetSearch',
    ]),
    handleSearch: _.debounce(function (value) {
      this.setEstimateSearchText(value);
      this.onSearch();
    }, 500),
    // Can't use @infinite of frameword => use @scroll
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.loadMore();
      }
    },

    onSearch() {
      const self = this;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      return this.searchEstimate({})
        .then(() => {
          // console.log("[onSearch] load more estimate", data);
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        })
        .finally(() => {
          self.hasMoreItems = false;
        });
    },
    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreEstimate({
        page: this.page + 1,
      }).then(() => {
        // console.log("[loadMore] load more estimate", data);
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },
    async open(projectId = null) {
      this.$f7.preloader.show();
      if (!_.isEmpty(projectId)) {
        await this.getEstimateListByProjectId(projectId);

        this.selectedEstimates = _.cloneDeep(this.selectedIds);

        this.projectId = projectId;
        this.$f7.preloader.hide();
        this.isOpen = true;
      } else {
        this.$f7.preloader.hide();
        this.isOpen = true;
      }
    },

    select(estimateId) {
      if (!this.isMultiEst) {
        this.$emit('onSelected', estimateId);
        this.isOpen = false;
        return;
      }

      if (this.selectedEstimates.includes(estimateId)) {
        this.selectedEstimates = this.selectedEstimates.filter(
          id => id !== estimateId
        );
      } else {
        this.selectedEstimates.push(estimateId);
      }
    },

    onDone() {
      this.$emit('onSelected', this.selectedEstimates);
      this.isOpen = false;
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list-popup.searchbar input').focus();
      }
    },

    handlePopupClosed() {
      this.isOpen = false;
      this.selectedEstimates = [];
      this.projectId = null;
      this.setEstimateSearchText('');
    },
  },
};
</script>
