export default {
  order: state => state.order,
  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
  searchText: state => state.searchText,
  // options for filter
  vendorIdFilterList: state => state.vendorIdFilterList,
  categoryIdFilterList: state => state.categoryIdFilterList,
  subCategoryIdFilterList: state => state.subCategoryIdFilterList,
  roofTypeFilterList: state => state.roofTypeFilterList,
  statusFilterList: state => state.statusFilterList,
};
