export default {
  order: 'asc',
  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0,
  searchText: '',
  // options for filter
  vendorIdFilterList: [],
  categoryIdFilterList: [],
  subCategoryIdFilterList: [],
  roofTypeFilterList: [],
  statusFilterList: [],
};
