import { groupBy } from '@/utility/filter-tools';

export default {
  // Fulltext search
  order: state => state.order,
  contactSearchText: state => state.contactSearchText,
  queryFilters: state => state.queryFilters,
  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,

  contactGroup: state => {
    let dataGroup = groupBy(state.hits, item =>
      ((item.contactName || '').charAt(0) || '').toUpperCase()
    ).sort((a, b) => sortBy(a.key, b.key));
    return dataGroup.map(i => ({
      ...i,
      data: i.data,
    }));
  },

  emailsOfContact: state => {
    const emailValues = (state.hits || [])
      .filter(r => r.emailValues)
      .map(r => ({
        emailValues: r.emailValues,
        name: r.contactName,
      }));

    let merged = emailValues.reduce((old, current) => {
      const currentMapped = (
        (Array.isArray(current.emailValues) && current.emailValues) ||
        []
      ).map(r => ({
        email: r,
        name: current.name,
      }));
      return old.concat(currentMapped);
    }, []);
    merged = merged.filter(
      (value, index, self) =>
        index ===
        self.findIndex(t => t.email === value.email && t.name === value.name)
    );
    return merged;
  },

  phonesOfContact: state => {
    const phoneValues = (state.hits || [])
      .filter(r => r.phoneValues)
      .map(r => ({
        phoneValues: r.phoneValues,
        name: r.contactName,
      }));

    let merged = phoneValues.reduce((old, current) => {
      const currentMapped = (
        (Array.isArray(current.phoneValues) && current.phoneValues) ||
        []
      ).map(r => ({
        phone: r,
        name: current.name,
      }));
      return old.concat(currentMapped);
    }, []);
    merged = merged.filter(
      (value, index, self) =>
        index ===
        self.findIndex(t => t.phone === value.phone && t.name === value.name)
    );
    return merged;
  },

  contactList: state => state.contactList,
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
