import _ from 'lodash';

export default {
  order: state => state.order,
  searchText: state => state.searchText,

  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,

  productList: (state, getters, __, rootGetters) => {
    const currentBuilding =
      rootGetters['estimate/estimate-page/estimate/currentBuilding'];
    if (currentBuilding.roofType === 'all-roof-type') return state.productList;
    const productList = state.productList.filter(item =>
      item.roofTypes.includes(currentBuilding.roofType)
    );

    return _.isEmpty(currentBuilding.vendorIds)
      ? productList
      : productList.filter(r => currentBuilding.vendorIds.includes(r.vendorId));
  },

  product: state => state.product,

  productManufacturers: (state, getters) => {
    return getters.productList
      .map(r => r.manufacturer)
      .filter((value, index, self) => {
        //get unique values
        return value && self.indexOf(value) === index;
      });
  },

  productColors: (state, getters) => {
    return getters.productList
      .map(r => r.color)
      .filter((value, index, self) => {
        //get unique values
        return value && self.indexOf(value) === index;
      });
  },
  productColorAndManufacturers:
    (state, getters) => (subCategoryId, assemblyGA) => {
      let productColorsManufacturers = [];
      let products = getters.productList.filter(
        r =>
          r.subCategoryId == subCategoryId &&
          !_.isEmpty(r.productHashtag) &&
          r.productHashtag === assemblyGA
      );
      products.forEach(product => {
        let value = `${product.color},${product.manufacturer}`;
        if (!productColorsManufacturers.some(x => x.value == value)) {
          productColorsManufacturers.push({
            value,
            displayName: `${product.color} - ${product.manufacturer}`,
          });
        }
      });
      return productColorsManufacturers;
    },
};
