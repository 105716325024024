import project from './project';
import team from './team-management';
import employeeScheduling from './employee-scheduling';
import user from './user';
import scheduleChain from './schedule-chain';
import board from './board';
import column from './column';
import client from './client';
import scheduleEmailNotifications from './schedule-email-notifications';
import appConstant from './app-constant';
import album from './album';
import photo from './photo';
export default tenant => {
  return {
    namespaced: true,
    modules: {
      project: project(tenant),
      team: team(tenant),
      scheduling: employeeScheduling(tenant),
      chain: scheduleChain(tenant),
      notifications: scheduleEmailNotifications(tenant),
      user,
      board: board(tenant),
      column: column(tenant),
      client: client(tenant),
      'app-constant': appConstant(tenant),
      album: album(tenant),
      photo: photo(tenant),
    },
  };
};
