import Vue from 'vue';
import * as types from './types';
import _ from 'lodash';

export default {
  [types.SET_CONTACT_LIST](state, payload) {
    Vue.set(state, 'contactList', payload);
  },

  [types.SET_CONTACT](state, payload) {
    Vue.set(state, 'contact', payload);
  },

  [types.SET_IS_EDIT_CONTACT](state, payload) {
    Vue.set(state, 'isEditContact', payload);
  },

  [types.SET_COMPANY_REFS](state, payload) {
    Vue.set(state, 'companyRefs', payload);
  },

  [types.ADD_COMPANY_REFS](state, payload) {
    const foundItem = state.companyRefs.find(item => item.id === payload.id);

    // Not found
    if (_.isEmpty(foundItem)) {
      state.companyRefs.push(payload);
    }
  },

  [types.REMOVE_COMPANY_REFS](state, companyId) {
    state.companyRefs = state.companyRefs.filter(item => item.id !== companyId);
  },
};
