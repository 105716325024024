<template>
  <f7-popup
    class="demo-popup"
    :opened="isShow"
    @popup:closed="cancel"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{ editorTitle }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="add()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <!-- sku -->
        <f7-list-input
          label="SKU"
          placeholder="Product SKU"
          :value="currentProduct.sku"
          @input="onChangeProduct('sku', $event.target.value.trim())"
        ></f7-list-input>
      </f7-list>

      <f7-list>
        <!-- vendor -->
        <f7-list-item
          header="Vendor"
          link
          @click.native="selectVendor"
        >
          <div
            class="list-item-title"
            slot="title"
          >
            {{
              vendorById(currentProduct.vendorId)
                ? vendorById(currentProduct.vendorId).companyName
                : 'Select vendor'
            }}
          </div>
        </f7-list-item>
      </f7-list>

      <!-- Manufacturer -->
      <auto-complete-input
        label="Manufacturer"
        style="z-index: 103"
        placeholder="Enter manufacturer"
        v-model="currentProduct.manufacturer"
        :options="
          productManufacturers.map((r, index) => ({ id: index, text: r }))
        "
      ></auto-complete-input>

      <f7-list>
        <!-- Product Name -->
        <f7-list-input
          label="Product Name"
          placeholder="Product Name"
          :value="currentProduct.productItem"
          @input="onChangeProduct('productItem', $event.target.value.trim())"
          clear-button
        ></f7-list-input>
      </f7-list>

      <f7-list>
        <!-- Sub category -->
        <f7-list-input
          label="Sub category"
          type="select"
          :value="currentProduct.subCategoryId"
          @input="onChangeProduct('subCategoryId', $event.target.value.trim())"
          error-message-force
          :error-message="subCategoryIdErrorMessage"
        >
          <option
            v-for="sc in [
              { id: '', name: 'Select sub category' },
              ...filteredSubCategories,
            ]"
            :key="sc.id"
            :value="sc.id"
          >
            {{ sc.name }}
          </option>
        </f7-list-input>
      </f7-list>

      <f7-list>
        <!-- Technical Data -->
        <attachment-input-not-save
          title="Technical Data Attachment"
          :value="currentProduct.technicalData"
          @input="currentProduct.technicalData = $event"
        ></attachment-input-not-save>
      </f7-list>

      <f7-list>
        <!-- photo -->
        <UploadProductColorPhoto
          :value="currentProduct.colorPhotos"
          @input="currentProduct.colorPhotos = $event"
        >
        </UploadProductColorPhoto>
      </f7-list>

      <f7-list>
        <f7-list-item header="Vendor Price">
          <required-asterisk slot="header" />
          <input-price
            slot="title"
            ref="priceInput"
            :allowZero="false"
            :price="Number(currentProduct.price)"
            :isResetData="!isShow"
            @input="onChangeProduct('price', $event)"
          />
        </f7-list-item>
        <f7-list-item header="Markup">
          <required-asterisk slot="header" />
          <div slot="title">
            <input-percent-or-cash
              ref="markupInputPercentOrCash"
              inputClass="bottom-line"
              :value="markup.value"
              :type="markup.type"
              :isResetData="!isShow"
              @inputValue="changeMarkup('value', $event)"
              @changeType="changeMarkup('type', $event)"
            ></input-percent-or-cash>
          </div>
        </f7-list-item>
        <f7-list-item
          header="Price"
          :title="priceProductPLValue | currencyUSD"
        ></f7-list-item>

        <f7-list-input
          v-show="!isLabor"
          label="Waste Factor"
          placeholder="Waste Factor"
          :value="currentProduct.wasterFactor"
          type="number"
          min="0"
          @input="
            onChangeProduct(
              'wasterFactor',
              parseFloat($event.target.value.trim() || 0)
            )
          "
          error-message-force
          :error-message="wasterFactorErrorMessage"
        ></f7-list-input>
        <!-- Packaging -->
        <f7-list-input
          v-show="!isLabor"
          label="Packaging"
          placeholder="Packaging"
          :value="currentProduct.packaging"
          @input="onChangeProduct('packaging', $event.target.value.trim())"
          error-message-force
          :error-message="packagingErrorMessage"
        >
          <required-asterisk slot="label" />
        </f7-list-input>

        <!-- Unit Pack -->
        <f7-list-input
          v-show="!isLabor"
          label="Unit Packs"
          placeholder="Unit Packs"
          :value="currentProduct.unitPack"
          type="number"
          min="0"
          @input="
            onChangeProduct('unitPack', parseFloat($event.target.value.trim()))
          "
          error-message-force
          :error-message="unitPackErrorMessage"
        >
          <required-asterisk slot="label" />
        </f7-list-input>

        <!-- Unit Size -->
        <f7-list-input
          v-show="!isLabor"
          label="Unit Size"
          placeholder="Unit Size"
          :value="currentProduct.unitSize"
          type="number"
          min="0"
          @input="
            onChangeProduct('unitSize', parseFloat($event.target.value.trim()))
          "
          error-message-force
          :error-message="unitSizeErrorMessage"
        >
          <required-asterisk slot="label" />
        </f7-list-input>
        <!-- UoM -->
        <f7-list-input
          label="UoM"
          placeholder="UoM"
          :value="currentProduct.uom"
          @input="
            onChangeProduct('uom', $event.target.value.trim().toUpperCase())
          "
          error-message-force
          :error-message="uomErrorMessage"
          ><required-asterisk slot="label"
        /></f7-list-input>
        <!-- internal note -->
        <f7-list-input
          label="Internal Note"
          type="textarea"
          placeholder="Enter internal note..."
          :value="internalNote"
          @input="internalNote = $event.target.value.trim()"
        >
        </f7-list-input>
        <!-- note -->
        <f7-list-input
          label="Proposal Note"
          type="textarea"
          placeholder="Enter proposal note..."
          :value="proposalNote"
          @input="proposalNote = $event.target.value.trim()"
        >
        </f7-list-input>
      </f7-list>
    </f7-page>
    <company-list-popup
      ref="companyListPopup"
      @onSelected="currentProduct.vendorId = $event"
    ></company-list-popup>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required, minValue } from '@vuelidate/validators';
import AutoCompleteInput from '@/components/inputs/AutoCompleteInput/AutoCompleteInput.vue';
import CompanyListPopup from './CompanyListPopup.vue';
import AttachmentInputNotSave from '../inputs/AttachmentInputNotSave.vue';
import { uploadFile, removeFile } from '../../../../services/firebase.service';
import _ from 'lodash';
import Vue from 'vue';
import UploadProductColorPhoto from '@/components/inputs/UploadProductColorPhoto.vue';
import InputPrice from '@/components/inputs/InputPrice.vue';
import InputPercentOrCash from '@/components/inputs/InputPercentOrCash.vue';
import { VALIDATION_MESSAGE } from '@/utility/const';
import { handleColorPhoto } from '@/utility/common';

export default {
  finishCallback: null,
  components: {
    AutoCompleteInput,
    CompanyListPopup,
    AttachmentInputNotSave,
    UploadProductColorPhoto,
    InputPrice,
    InputPercentOrCash,
  },
  data: () => ({
    oldTechnicalData: [],
    oldPhotos: [],
    currentProduct: {
      sku: '',
      manufacturer: '',
      vendorId: '',
      productItem: '',
      roofTypes: [],
      packaging: '',
      unitPack: '',
      unitSize: '',
      price: 0,
      uom: '',
      note: '',
      wasterFactor: '',
      subCategoryId: '',
      categoryId: '',
      color: '',
      technicalData: [],
      photos: [],
      colorPhotos: [],
    },
    isCopied: false,
    editorTitle: '',
    isShow: false,
    internalNote: '',
    proposalNote: '',
    markup: {
      value: 0,
      type: 'percent',
    },
  }),
  methods: {
    ...mapActions('estimate/estimate-page/product-item', [
      'uploadPhoto',
      'copyPhoto',
    ]),
    ...mapActions({
      bindVendors: 'estimate/estimate-page/vendor/bind',
    }),
    cancel(ignoreCallback) {
      this.oldPhotos = [];
      this.oldTechnicalData = [];
      this.currentProduct = {
        sku: '',
        manufacturer: '',
        vendorId: '',
        productItem: '',
        subCategoryId: '',
        categoryId: '',
        uom: '',
        note: '',
        roofTypes: [],
        price: 0,
        wasterFactor: '',
        size: '',
        color: '',
        technicalData: [],
        photos: [],
        colorPhotos: [],
      };
      (this.isCopied = false), this.v$.$reset();
      this.isShow = false;
      this.internalNote = '';
      this.proposalNote = '';
      this.markup = {
        value: 0,
        type: 'percent',
      };
      !ignoreCallback && this.finishCallback(null);
    },
    selectVendor() {
      this.$refs.companyListPopup.open();
    },
    changeMarkup(property, value) {
      Vue.set(
        this.markup,
        property,
        property === 'value' ? parseFloat(value) : value
      );
      this.v$.markup[property] && this.v$.markup[property].$touch();
    },
    onChangeProduct(property, value) {
      Vue.set(this.currentProduct, property, value);
      this.v$.currentProduct[property] &&
        this.v$.currentProduct[property].$touch();
    },
    async handleUploadFile(attachments) {
      let attachs = [];

      for (const file of attachments) {
        let attachment = {
          name: file.name,
          fullPath: `product/technical-data/${+new Date() + file.name}`,
          url: '',
          description: file.description || '',
        };
        let url = await uploadFile(attachment.fullPath, file);

        attachment.url = url;
        attachs.push(attachment);
      }

      return attachs;
    },

    handleDataBeforeUpload(oldData, currentData) {
      let attachsBefore = _.cloneDeep(oldData);
      let attachsAfter = _.cloneDeep(currentData);

      let attachsRemove = _.differenceWith(
        attachsBefore,
        attachsAfter,
        _.isEqual
      );
      let attachsAdd = _.differenceWith(attachsAfter, attachsBefore, _.isEqual);
      let attachsRemain = _.differenceWith(
        attachsBefore,
        attachsRemove,
        _.isEqual
      );
      //delete file in storage
      if (!this.isCopied) {
        attachsRemove.forEach(attach => {
          removeFile(attach.fullPath);
        });
      }
      return { attachsAdd, attachsRemain };
    },
    async handleColorPhotoFiles(oldData, currentData) {
      const props = {
        oldData,
        currentData,
        uploadPhoto: this.uploadPhoto,
        copyPhoto: this.copyPhoto,
        isCopied: this.isCopied,
      };
      const result = await handleColorPhoto(props);

      const { updatedData, isCopied: updatedIsCopied } = result;
      this.isCopied = updatedIsCopied;
      return updatedData;
    },
    add() {
      this.v$.$touch();
      this.$refs.priceInput?.validate();
      this.$refs.markupInputPercentOrCash?.validate();
      if (
        this.v$.$invalid ||
        this.$refs.markupInputPercentOrCash?.validate() ||
        this.$refs.priceInput?.validate()
      ) {
        return;
      }
      this.$ri.dialog.openInfoDialog({
        title: 'Product Update Confirmation',
        content:
          'The changes will be applied to the Price List and the Product. Are you sure to proceed with this?',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.applyChange();
          }
        },
      });
    },
    applyChange() {
      this.$f7.preloader.show();
      let data = { ...this.currentProduct };
      const { technicalData, colorPhotos } = this.currentProduct;

      const technicalDataBeforeUpload = this.handleDataBeforeUpload(
        this.oldTechnicalData,
        technicalData
      );

      //upload db
      this.handleColorPhotoFiles(this.oldColorPhotos, colorPhotos)
        .then(colorPhotos => {
          data.colorPhotos = colorPhotos;
          return this.handleUploadFile(technicalDataBeforeUpload.attachsAdd);
        })
        .then(attachs => {
          data.technicalData = _.union(
            technicalDataBeforeUpload.attachsRemain || [],
            attachs
          );
          // labor will dont have some data
          if (this.isLabor) {
            data.wasterFactor = '';
            data.packaging = '';
            data.unitPack = '';
            data.unitSize = '';
          }
          this.finishCallback({
            productData: data,
            internalNote: this.internalNote,
            proposalNote: this.proposalNote,
            markup: this.markup,
            price: this.priceProductPLValue,
          });
          this.cancel(true);
          this.$f7.preloader.hide();
        });
    },
    startEditor(title, obj) {
      this.initData();
      this.editorTitle = title;
      if (title === 'Copy Product') {
        this.isCopied = true;
      }
      this.isShow = true;
      this.oldTechnicalData = _.cloneDeep(obj.technicalData || []);
      this.oldColorPhotos = _.cloneDeep(obj.colorPhotos || []);
      this.currentProduct = {
        ...this.currentProduct,
        ...obj,
        roofTypes: obj.roofTypes || [],
        price: !Number.isNaN(Number.parseFloat(obj.vendorPrice))
          ? obj.vendorPrice
          : obj.price || 0,
      };
      delete this.currentProduct.internalNote;
      delete this.currentProduct.proposalNote;
      delete this.currentProduct.markup;
      delete this.currentProduct.vendorPrice;
      this.internalNote = obj.internalNote || '';
      this.proposalNote = obj.proposalNote || '';
      this.markup = _.cloneDeep(obj.markup) || { value: 0, type: 'percent' };
      return new Promise(resolve => {
        this.finishCallback = resolve;
      });
    },
    initData() {
      if (_.isEmpty(this.vendors)) {
        this.$f7.preloader.show();
        this.bindVendors().then(() => {
          this.$f7.preloader.hide();
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      productManufacturers:
        'estimate/estimate-page/product-item/productManufacturers',
      productColors: 'estimate/estimate-page/product-item/productColors',
      categories: 'estimate/estimate-page/estimate/category/objectList',
      subCategories: 'estimate/estimate-page/estimate/sub-category/objectList',
      vendorById: 'estimate/estimate-page/vendor/objectById',
      vendors: 'estimate/estimate-page/vendor/objectList',
    }),
    priceProductPLValue() {
      let price = 0;
      if (this.markup.type === 'percent') {
        price =
          (this.currentProduct.price || 0) *
          (1 + (this.markup.value || 0) / 100);
      } else {
        price = (this.currentProduct.price || 0) + (this.markup.value || 0);
      }
      return parseFloat(price.toFixed(2));
    },
    filteredSubCategories() {
      if (!this.currentProduct.categoryId) return this.subCategories;
      return this.subCategories.filter(
        r => r.categoryId == this.currentProduct.categoryId
      );
    },
    isLabor() {
      let category = this.categories.find(
        r => r.id == this.currentProduct.categoryId
      );
      return category && category.name == 'Labor';
    },
    filteredCategories() {
      if (
        !this.currentProduct.roofTypes ||
        !this.currentProduct.roofTypes.length
      )
        return this.categories;
      return this.categories.filter(r =>
        r.roofTypes.some(x => this.currentProduct.roofTypes.includes(x))
      );
    },
    uomErrorMessage() {
      if (!this.v$.currentProduct.uom.$error) return null;
      if (this.v$.currentProduct.uom.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    subCategoryIdErrorMessage() {
      return null;
    },
    categoryIdErrorMessage() {
      return null;
    },
    wasterFactorErrorMessage() {
      if (!this.v$.currentProduct.wasterFactor.$error) return null;
      if (this.v$.currentProduct.wasterFactor.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.currentProduct.wasterFactor.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      return null;
    },
    packagingErrorMessage() {
      if (!this.v$.currentProduct.packaging.$error) return null;
      if (this.v$.currentProduct.packaging.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    unitPackErrorMessage() {
      if (!this.v$.currentProduct.unitPack.$error) return null;
      if (this.v$.currentProduct.unitPack.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.currentProduct.unitPack.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      return null;
    },
    unitSizeErrorMessage() {
      if (!this.v$.currentProduct.unitSize.$error) return null;
      if (this.v$.currentProduct.unitSize.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.currentProduct.unitSize.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      return null;
    },
    categoryDisplay() {
      const category = this.categories.find(
        r => r.id === this.currentProduct.categoryId
      );
      return (category || {}).name || 'Select category';
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  validations() {
    return {
      currentProduct: {
        packaging: {
          required(val) {
            if (this.isLabor) return true;
            return !!val;
          },
        },
        unitPack: {
          required(val) {
            if (this.isLabor) return true;
            return !!val;
          },
          minValue: minValue(0),
        },
        unitSize: {
          required(val) {
            if (this.isLabor) return true;
            return !!val;
          },
          minValue: minValue(0),
        },
        wasterFactor: {
          required(val) {
            if (this.isLabor) return true;
            return !!val;
          },
          minValue: minValue(0),
        },
        price: {
          required,
          minValue: minValue(0),
        },
        uom: {
          required,
        },
      },
      markup: {
        value: { required },
        type: { required },
      },
    };
  },
  watch: {
    'currentProduct.subCategoryId': function (v) {
      if (!v) return;
      if (this.currentProduct.categoryId) return;
      const subCategory = this.subCategories.find(r => r.id === v);
      if (subCategory) {
        this.currentProduct.categoryId = subCategory.categoryId;
      }
    },
    'currentProduct.categoryId': function (v) {
      if (!v) return;
      if (
        this.currentProduct.roofTypes &&
        this.currentProduct.roofTypes.length > 0
      ) {
        return;
      }
      const category = this.categories.find(r => r.id === v);
      if (category) {
        this.currentProduct.roofTypes = category.roofTypes;
      }
    },
  },
};
</script>
