<template>
  <f7-page v-if="user">
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>User Details</f7-nav-title>
      <f7-nav-right>
        <f7-link @click="onBtnEditClick">Edit</f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-list
      class="justify-content-center align-items-center display-flex infomation"
    >
      <upload-avatar
        textUpload="Edit"
        :avatarSize="70"
        :imageUrl="user.photoURL"
        :name="user.displayName"
      />
      <span class="no-margin infomation-name">{{ user.displayName }}</span>
      <span class="no-margin infomation-email">{{ displayEmail }}</span>
    </f7-list>

    <f7-block-title>Information</f7-block-title>
    <f7-list>
      <!-- <f7-list-item header="Full name" :title="user.displayName"></f7-list-item>-->
      <f7-list-item
        header="Login Method"
        :title="checkLoginMethod(LOGIN_METHOD_EMAIL) ? 'Email' : 'Phone Number'"
      ></f7-list-item>
      <f7-list-item
        header="Phone"
        :title="displayPhone"
      ></f7-list-item>
    </f7-list>

    <f7-list>
      <f7-list-item header="Groups">
        <chip-more
          slot="title"
          :deleteable="false"
          :limit="3"
          :data="userGroups"
        />
      </f7-list-item>
      <f7-list-item
        header="Pay Type"
        :title="userPayType"
      ></f7-list-item>
      <f7-list-item
        header="Default Loading Screen"
        :title="userMenu"
      ></f7-list-item>
      <!-- <f7-list-item header="Role" :title="userRole"></f7-list-item> -->
    </f7-list>

    <f7-list>
      <f7-list-item title="Email Verified">
        <f7-toggle
          slot="after"
          disabled
          :checked="user.emailVerified"
        ></f7-toggle>
      </f7-list-item>
      <f7-list-item title="Active">
        <f7-toggle
          slot="after"
          disabled
          :checked="!user.disabled"
        ></f7-toggle>
      </f7-list-item>
      <f7-list-item title="Receive Online Lead Email, SMS, Notification">
        <f7-toggle
          slot="after"
          disabled
          :checked="
            user.customClaims && user.customClaims.allowReceiveNotification
          "
        ></f7-toggle>
      </f7-list-item>
    </f7-list>
    <f7-list>
      <f7-list-button
        color="red"
        @click="changingPassword = true"
        title="Change user password"
      ></f7-list-button>
    </f7-list>

    <f7-list v-show="!showDetail">
      <f7-list-button
        color="red"
        @click.native="del"
        >Delete</f7-list-button
      >
    </f7-list>

    <!-- <f7-list v-show="!showDetail">
      <f7-list-button color="red" @click.native="toggleEmailVerified"
        >Toggle Email Verified</f7-list-button
      >
    </f7-list> -->

    <user-details-popup
      ref="userDetailsPopup"
      id="edit"
    ></user-details-popup>

    <change-password-popup
      :popupOpened="changingPassword"
      @close="changingPassword = false"
    ></change-password-popup>
  </f7-page>
  <f7-page v-else>
    <f7-navbar backLink="Back">
      <f7-nav-title>User Not Found</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-block-title medium>The user could not be found</f7-block-title>
    <f7-block strong>
      <p>It may have been moved or deleted by another user.</p>
    </f7-block>
  </f7-page>
</template>

<script>
import UserDetailsPopup from '../components/popup/UserDetailsPopup.vue';
import ChangePasswordPopup from '../components/popup/ChangePasswordPopup.vue';
import UploadAvatar from '@/components/uploads/UploadAvatar.vue';
import ChipMore from '@/components/chips/ChipMore.vue';
import { mapActions, mapGetters } from 'vuex';
import { removeFile } from '../../../services/firebase.service';
import _ from 'lodash';

import Vue from 'vue';
import {
  LOGIN_METHOD_EMAIL,
  LOGIN_METHOD_EMAIL_BY_PHONE,
} from '../../../utility/const';

export default {
  components: {
    UserDetailsPopup,
    ChangePasswordPopup,
    ChipMore,
    UploadAvatar,
  },

  data: () => {
    return {
      // popupOpened: false,
      changingPassword: false,
      showDetail: false,
      error: '',
      LOGIN_METHOD_EMAIL,
      LOGIN_METHOD_EMAIL_BY_PHONE,
    };
  },

  computed: {
    ...mapGetters('administration/user-list-page/user', ['user']),
    ...mapGetters('administration/user-list-page/group', ['groups']),
    ...mapGetters('administration/app-constant', ['roleList', 'payTypeList']),
    ...mapGetters('administration/user-list-page/menu', ['menus']),
    ...mapGetters('common/administration-user', ['userForGroup']),
    userGroups() {
      const groupIds = this.userForGroup.groupIDs || [];
      const groups = this.groups.filter(r => groupIds.includes(r.id));
      return groups.map(r => ({ id: r.id, name: r.displayName }));
    },

    userRole() {
      return (
        this.roleList.find(role => {
          return role.value === ((this.user || {}).customClaims || {}).role;
        }) || {}
      ).displayName;
    },
    userPayType() {
      return (
        this.payTypeList.find(type => {
          return type.value === ((this.user || {}).customClaims || {}).payType;
        }) || {}
      ).displayName;
    },

    userMenu() {
      return (
        this.menus.find(url => {
          return url.path === ((this.user || {}).customClaims || {}).homeUrl;
        }) || {}
      ).displayName;
    },

    displayPhone() {
      let numberFormat = (this.user.phoneNumber || '').slice(2);
      return this.user.phoneNumber
        ? '(+1) ' +
            numberFormat.substring(0, 3) +
            '-' +
            numberFormat.substring(3, 6) +
            '-' +
            numberFormat.substring(6)
        : null;
    },

    displayEmail() {
      let email = this.user.email;
      if (this.user.customClaims.displayEmail) {
        email = this.user.customClaims.displayEmail;
      }
      return email;
    },

    checkLoginMethod() {
      return method => {
        if (!_.isEmpty(this.user.customClaims)) {
          if (
            !this.user.customClaims.loginMethod &&
            method === LOGIN_METHOD_EMAIL
          ) {
            return true;
          }
          if (this.user.customClaims.loginMethod === method) {
            return true;
          }
        }

        return false;
      };
    },
  },

  created() {
    this.resetUser();
    this.$f7.preloader.show();
    Promise.all([
      this.getUser(this.$f7route.params.uid),
      this.getUserForGroupById(this.$f7route.params.uid),
    ]).finally(() => {
      this.$f7.preloader.hide();
    });
  },

  methods: {
    ...mapActions('administration/user-list-page/user', [
      'getUser',
      'deleteUser',
      'updateUser',
      'getUserList',
      'resetUser',
    ]),
    ...mapActions('common/administration-user', ['getUserForGroupById']),
    ...mapActions('administration/user-list-page/group', [
      'modifyRelationShip',
    ]),

    setGroups(val) {
      Vue.set(this, 'groups', val);
    },

    del() {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: 'Delete user',
        content: 'Are you sure you want to delete the user?',
        // hideCancelButton: true,
        textButton: 'Delete',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$f7.preloader.show();
            if (app.user.customClaims.avatar) {
              removeFile(app.user.customClaims.avatar.fullPath);
            }
            const groupIDs = _.cloneDeep(app.userForGroup.groupIDs || []);
            const uid = app.user.uid;
            app.deleteUser(uid).then(() => {
              for (const groupID of groupIDs) {
                app.modifyRelationShip({
                  id: groupID,
                  action: 'remove',
                  doc: {
                    id: uid,
                    key: 'userIDs',
                  },
                });
              }
              app.getUserList().then(() => {
                // app.popupOpened = false;
                app.$f7.preloader.hide();
                app.$f7router.navigate(`/administration/users`, {
                  pushState: true,
                  reloadAll: true,
                });
              });
            });
          }
        },
      });
    },

    toggleEmailVerified() {
      const currUser = _.cloneDeep(this.user);

      const claims = currUser.customClaims;
      this.updateUser({
        uid: currUser.uid,
        user: { emailVerified: !currUser.emailVerified, customClaims: claims },
      });
    },

    onBtnEditClick() {
      this.$refs.userDetailsPopup.openPopup({ isNew: false });
    },
    handleBack() {
      this.$f7router.back('/administration/users', { force: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.infomation {
  flex-direction: column;
}
.infomation-name {
  font-weight: bold;
  font-size: 16px;
}
.infomation-email {
  font-size: 12px;
}
</style>
