<template>
  <div
    class="data-table-footer"
    v-if="footerProps && footerProps.pageSizeOption"
  >
    <div class="data-table-rows-select">
      Rows per page:
      <div class="input input-dropdown">
        <select @change="changeRowPerPage($event.target.value)">
          <option
            v-for="perPage in footerProps.pageSizeOption"
            :key="perPage"
            :value="perPage"
          >
            {{ perPage }}
          </option>
        </select>
      </div>
    </div>
    <div class="data-table-pagination">
      <span class="data-table-pagination-label"
        >{{ pageStart }}-{{ pageStop }} of {{ totals }}</span
      >
      <a
        href="#"
        :class="`link ${
          pagination.page <= 1 || pagination.totalItems === pagination.pageSize
            ? 'disabled'
            : ''
        }`"
        @click="$emit('paginationNextPrev', 'prevPage')"
      >
        <i class="icon icon-prev color-gray"></i>
      </a>
      <a
        href="#"
        :class="`link ${
          Math.ceil(totals / pagination.pageSize) <= pagination.page
            ? 'disabled'
            : ''
        }`"
        @click="$emit('paginationNextPrev', 'nextPage')"
      >
        <i class="icon icon-next color-gray"></i>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    footerProps: Object,
    totals: {
      type: Number,
      default: 5,
    },
    pagination: Object,
  },
  computed: {
    pageStart() {
      var countStart =
        (this.pagination.page - 1) * this.pagination.pageSize + 1 || 1;
      return countStart > this.pageStop ? this.pageStop : countStart;
    },
    pageStop() {
      var countStop = this.pagination.page * this.pagination.pageSize;
      return countStop > this.totals
        ? countStop - (countStop - this.totals)
        : countStop;
    },
  },
  methods: {
    // Select option Row Per Page
    changeRowPerPage(value) {
      this.$emit('changeRowPerPage', {
        ...this.pagination,
        pageSize: value === 'All' ? this.pagination.totalItems : value,
        page:
          value === 'All'
            ? Math.ceil(this.totals / this.pagination.pageSize)
            : 1,
      });
    },
  },
};
</script>
