<template>
  <div>
    <f7-block-title v-show="$device.desktop">Gutter System</f7-block-title>
    <f7-card :class="{ 'margin-top-half': !$device.desktop }">
      <!-- <f7-card-header>Drawing board</f7-card-header> -->
      <f7-card-content>
        <div class="canvas-container">
          <canvas
            v-if="$device.desktop"
            :ref="buildingId"
            :id="buildingId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @mousedown="handleMouseDown"
            @mousewheel="handleMouseWheel"
          />
          <canvas
            v-else
            :ref="buildingId"
            :id="buildingId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @click="handleMouseDown"
            @pointerdown="handlePointerDown"
            @pointermove="handlePointerMove"
            @pointerup="handlePointerUp"
            @pointercancel="handlePointerUp"
            @pointerleave="handlePointerUp"
            @pointerout="handlePointerUp"
          />
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>

<script>
import paper from 'paper';
import { evaluate } from 'mathjs';
import canvasMixin from '../../utility/canvas-mixin';
import _ from 'lodash';
const inchToPx = 50;

export default {
  mixins: [canvasMixin],

  props: {
    data: Object,
    buildingId: String,
    isDark: { type: Boolean, default: true },
  },

  data: () => ({
    scope: null,
    dataToPx: {},
    yBase: 150,
    xBase: 200,
    width: 1000,
    height: 800,
    dark: localStorage.getItem('themeDark') === 'true',
  }),
  methods: {
    initCanvas() {
      this.removeProject(this.scope);
      this.scope = new paper.PaperScope();
      this.scope.setup(this.buildingId);
      if (!_.isEmpty(this.scope.view)) {
        this.scope.view.viewSize = new paper.Size(
          this.$device.desktop ? this.width : this.widthMobile,
          this.$device.desktop ? this.height : this.heightMobile
        );
      }
    },

    reset() {
      this.scope && this.scope.project.activeLayer.removeChildren();
    },

    handleDraw() {
      const { A, B, C, D, E, F, H } = this.dataToPx;
      const pointA = new paper.Point(this.xBase, this.yBase);
      const pointB = new paper.Point(pointA.x + F, pointA.y);
      let pointC = new paper.Point(pointB.x - H, pointB.y);
      pointC = pointC.rotate(-45, pointB);
      const pointD = new paper.Point(pointA.x, pointA.y + A);
      const pointE = new paper.Point(pointD.x + B, pointD.y);
      const pointF = new paper.Point(pointE.x, pointE.y + C - A);
      const pointG = new paper.Point(pointF.x + D, pointF.y);
      const pointH = new paper.Point(pointG.x, pointG.y - E);

      let path = new paper.Path({
        strokeColor: this.isDark ? (this.dark ? 'white' : 'black') : 'black',
        strokeWidth: 3,
      });
      path.add(pointC, pointB, pointA, pointD, pointE, pointF, pointG, pointH);

      // description
      this.handleDrawDescriptionTop(
        this.isDark,
        pointA,
        pointB,
        `F: ${this.data.F}`,
        'topRight',
        50
      );
      this.handleDrawDescriptionLeft(
        this.isDark,
        pointA,
        pointD,
        `A: ${this.data.A}`,
        50
      );
      this.handleDrawDescriptionLeft(
        this.isDark,
        pointA,
        pointF,
        `C: ${this.data.C}`,
        120
      );
      this.handleDrawDescriptionRight(
        this.isDark,
        pointH,
        pointG,
        `E: ${this.data.E}`,
        70
      );
      this.handleDrawDescriptionBottom(
        this.isDark,
        pointF,
        pointG,
        `D: ${this.data.D}`
      );
      this.handleDrawDescriptionBottom(
        this.isDark,
        pointD,
        pointE,
        `B: ${this.data.B}`,
        'bottomRight'
      );

      this.handleDrawDescriptionTop(
        this.isDark,
        pointA,
        pointH,
        `${this.convertExpesstionToString(`${this.data.B} ${this.data.D}`)}`,
        'topCenter',
        100
      );
      this.handleDrawDescriptionDiagonal(
        this.isDark,
        pointC,
        pointB,
        45,
        0,
        `H: ${this.data.H}`,
        'end',
        30
      );
    },

    drawAll() {
      this.handleDraw();
      this.resizableLayer();
    },
  },

  watch: {
    data: {
      handler(val) {
        const keys = ['A', 'B', 'C', 'D', 'E', 'F', 'H'];
        for (let i of keys) {
          let parseValue = this.convertStringToExpression(val[i]);
          this.dataToPx[i] = evaluate(parseValue) * inchToPx;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas-container {
  position: relative;
}
canvas[resize] {
  width: 100%;
  height: 100%;
}
</style>
