<template>
  <f7-popup
    class="demo-popup"
    :opened="popupCopyOpen"
    @popup:closed="cancel"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Copy price list</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="add()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list no-hairlines-md>
        <f7-list-input
          label="New Price list name"
          placeholder="Enter new price list name"
          clear-button
          error-message-force
          validate
          validate-on-blur
          :value="displayName"
          :error-message="displayNameErrorMessage"
          @input="displayName = $event.target.value"
          ><required-asterisk slot="label"
        /></f7-list-input>
        <f7-list-input
          type="datepicker"
          label="Start Date"
          placeholder="Start Date"
          error-message-force
          validate
          validate-on-blur
          :value="startDate"
          :error-message="startDateErrorMessage"
          :calendar-params="{
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            dateFormat: 'mm/dd/yyyy',
          }"
          @calendar:change="handleCalendarChange"
          ><required-asterisk slot="label"
        /></f7-list-input>
        <f7-list-input
          type="datepicker"
          label="End Date"
          placeholder="End Date"
          error-message-force
          validate
          :value="endDate"
          :error-message="endDateErrorMessage"
          :calendar-params="{
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            dateFormat: 'mm/dd/yyyy',
            disabled: date =>
              startDate && date < new Date(startDate).setHours(0, 0, 0, 0),
          }"
          @calendar:change="
            endDate = $event;
            $f7.calendar.close();
          "
          clear-button
        ></f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import moment from 'moment';
import { DEFAULT_STATUS_PRICE_LIST, VALIDATION_MESSAGE } from '@/utility/const';
import { firebase } from '../../../../services/firebase.service';

export default {
  props: {
    popupCopyOpen: { type: Boolean, default: false },
    itemCopyPrice: Object,
  },
  data: () => ({
    displayName: '',
    startDate: [new Date()],
    endDate: [],
  }),
  methods: {
    ...mapActions('price-list/price-list-page/price-list', ['createPrice']),

    ...mapActions('price-list/price-list-page/product-items', [
      'addPriceListRefs',
      'algoliaUpdateItems',
    ]),

    cancel() {
      this.displayName = '';
      this.startDate = [new Date()];
      this.endDate = [];
      this.$emit('close');
      this.v$.$reset();
    },
    add() {
      this.v$.$touch();
      const self = this;
      if (this.v$.$invalid) {
        return;
      } else {
        var { startDate, endDate, status, displayName } = this;
        if (!this.priceList.some(item => item.displayName === displayName)) {
          this.$f7.preloader.show();
          var data = {
            startDate: startDate[0], // server will convert to timestamp
            endDate: endDate[0] || '',
            status,
            displayName,
            roofTypes: this.itemCopyPrice.roofTypes,
            // products: this.itemCopyPrice.products,
            isDeleted: false,
            productRefs: this.itemCopyPrice.productRefs || [],
          };
          // create new price list
          this.createPrice(data)
            .then(async priceList => {
              const promises = [];
              for (const ref of data.productRefs) {
                // update the relationship of product and price list
                promises.push(
                  self.addPriceListRefs({
                    id: ref.productId,
                    priceListId: priceList.id,
                  })
                );
              }

              await Promise.all(promises);
              await this.algoliaUpdateItems(firebase.firestore.Timestamp.now());
              return priceList;
            })
            .then(priceList => {
              // show dialog to ask use naviagte to new prie list
              self.$ri.dialog.openSuccessDialog({
                title: 'Copy Price List',
                content: `New price list has been created. Would you like to navigate to the copied price list?`,

                onClick: (_sefl, index) => {
                  if (index === 0) {
                    self.cancel();
                  } else if (index === 1) {
                    self.$f7router.navigate(
                      `/price-list/${priceList.docNumber}`,
                      {
                        pushState: true,
                      }
                    );

                    self.cancel();
                  }
                },
              });
              self.$f7.preloader.hide();
            });
        } else {
          this.showNotificationFull(
            'Add new price list fail!',
            'There is an existing pricelist with the same name'
          );
        }
      }
    },
    handleCalendarChange(event) {
      this.startDate = event;
      if (this.endDate[0] < this.startDate[0]) {
        this.endDate = event;
      }
      this.$f7.calendar.close();
    },
    // Notifications
    showNotificationFull(subtitle, text) {
      const self = this;
      if (!self.notificationFull) {
        self.notificationFull = self.$f7.notification.create({
          icon: '<i class="icon icon-f7"></i>',
          title: 'RooferIntel',
          titleRightText: 'now',
          subtitle,
          text,
          closeTimeout: 3000,
        });
      }
      self.notificationFull.open();
    },
  },

  computed: {
    ...mapGetters('price-list/price-list-page/price-list', ['priceList']),
    ...mapGetters('setting/app/profile', ['user']),

    displayNameErrorMessage() {
      if (!this.v$.displayName.$error) return null;
      if (this.v$.displayName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    startDateErrorMessage() {
      if (this.v$.startDate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    endDateErrorMessage() {
      if (!this.v$.endDate.timeRules)
        return VALIDATION_MESSAGE.END_DATE_GREATER_THAN_START_DATE;
      return null;
    },
  },

  watch: {
    popupCopyOpen(val) {
      if (val === true) {
        this.displayName = '';
        this.status = DEFAULT_STATUS_PRICE_LIST;
        this.startDate = [new Date()];
        this.endDate = [];
      }
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    const timeRules = () => {
      const startDate = moment(this.startDate[0]).format('YYYY-MM-DD');
      const endDate = moment(this.endDate[0]).format('YYYY-MM-DD');
      return !moment(endDate).isBefore(startDate, 'day');
    };
    return {
      displayName: {
        required,
      },
      startDate: {
        required,
      },
      endDate: {
        timeRules,
      },
    };
  },
};
</script>
