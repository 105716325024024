import Vue from 'vue';

export default {
  setQaChecklistList(state, payload) {
    Vue.set(state, 'qaChecklistList', payload);
  },
  setQaChecklistSubscribe(state, payload) {
    Vue.set(state, 'qaChecklistSubscribe', payload);
  },
};
