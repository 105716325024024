<template>
  <f7-popup
    class="demo-popup"
    :opened="opened"
    @popup:closed="onClosed"
  >
    <f7-page>
      <!-- Navigate bar -->
      <f7-navbar>
        <f7-nav-left>
          <f7-link
            popup-close
            @click="cancel"
            >Cancel</f7-link
          >
        </f7-nav-left>
        <f7-nav-title>Estimates Status</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="done">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-block
        >Most of the projects have only one Active Estimate. Do you want to
        change the status of the following Estimate of the project to
        "Inactive"?</f7-block
      >

      <f7-list media-list>
        <!-- <f7-list-item title="With toggle">
          <template #media>
            <f7-icon icon="demo-list-icon"></f7-icon>
          </template>
        </f7-list-item> -->
        <f7-list-item
          v-for="(estimate, index) in estimateActiveListByProject"
          :key="index"
          :text-color="estimate.id === currentEstimate.id ? 'primary' : ''"
          :title="
            estimate.estimateNumber +
            `${estimate.id === currentEstimate.id ? ' (Current Estimate)' : ''}`
          "
          :text="'Created at: ' + toDisplayDateString(estimate.createdAt)"
        >
          <f7-toggle
            slot="after"
            :checked="estimate.status === 'active'"
            :disabled="estimate.id === currentEstimate.id"
            @toggle:change="changeEstimateStatus(estimate.id, $event)"
          ></f7-toggle>
          <!-- </template> -->
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  STATUS_ESTIMATE_ACTIVE,
  STATUS_ESTIMATE_INACTIVE,
} from '../../../../utility/const';

import { toDisplayDateString } from '../../../../utility/datetime';

export default {
  data() {
    return {
      opened: false,
      toDisplayDateString,
    };
  },

  computed: {
    ...mapGetters('estimate/estimate-page/estimate', [
      'estimateActiveListByProject',
    ]),

    ...mapGetters({
      currentEstimate: 'estimate/estimate-page/estimate/estimate',
    }),
  },

  methods: {
    ...mapActions('estimate/estimate-page/estimate', ['updateEstimate']),

    cancel() {
      this.opened = false;
    },

    done() {
      this.opened = false;
    },

    open() {
      this.opened = true;
    },

    onClosed() {
      this.opened = false;
      this.$emit('onClosedPopup');
    },

    changeEstimateStatus(estimateId, checked) {
      this.$f7.preloader.show();

      const status = checked
        ? STATUS_ESTIMATE_ACTIVE
        : STATUS_ESTIMATE_INACTIVE;
      this.updateEstimate({
        id: estimateId,
        doc: {
          status,
        },
      }).finally(() => {
        this.$f7.preloader.hide();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.md .status-icon {
  margin-bottom: 16px;
}

.md .desc-icon {
  margin-bottom: 80px;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}
</style>
