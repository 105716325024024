import {
  CODE_STATUS_OPTION,
  ENTITY_INVOICE,
  CODE_COMPANY_TYPE,
  CODE_EXPENSE_TYPE,
} from '@/utility/const';

export default {
  invoiceStatusList: state =>
    state.typeList.filter(
      item => item.code === CODE_STATUS_OPTION && item.entity === ENTITY_INVOICE
    ),
  companyTypeList: state =>
    state.typeList.filter(item => item.code === CODE_COMPANY_TYPE),
  expenseTypeList: state =>
    state.typeList.filter(item => item.code === CODE_EXPENSE_TYPE),
};
