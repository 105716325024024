// const propertyList = [
//   { id: 1, propertyName: "Land Mark", project: [1, 2, 3] },
//   { id: 2, propertyName: "The One", project: [4, 5, 6] },
//   { id: 3, propertyName: "Green Cover", project: [7, 8, 9, 10, 11] },
//   { id: 4, propertyName: "Rain Forest", project: [1, 3, 5, 6, 8, 12] }
// ];

export default {
  propertyList: [],
  propertySubscribe: null,
  property: {},
  projectOfProperty: [],
  addressOfProperty: '',
};
