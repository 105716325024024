import * as types from './types';
import Vue from 'vue';

export default {
  [types.SET_PROPOSAL_TEMPLATE_FIELD](state, payload) {
    Vue.set(state.proposalTemplate, payload.fieldName, payload.value);
  },
  [types.SET_CONTRACT_TEMPLATE](state, payload) {
    Vue.set(state, 'contractTemplate', payload);
  },
};
