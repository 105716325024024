<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-title>{{ project.title }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-row no-gap>
      <f7-col :width="$device.desktop ? 20 : 100">
        <f7-card v-if="$device.desktop">
          <f7-card-content>
            <f7-list accordion-list>
              <f7-list-item
                accordion-item
                accordion-item-opened
              >
                <template #title>
                  <div class="title">
                    <f7-icon
                      f7="square_list"
                      color="primary"
                    >
                    </f7-icon>
                    <div class="info-label">Commercial</div>
                  </div>
                </template>
                <f7-accordion-content>
                  <f7-list class="margin-left-half">
                    <f7-block class="scroll-container">
                      <f7-list-item
                        v-for="(menu, index) in leftMenu"
                        :key="index"
                        :title="menu.title"
                        link
                        :text-color="
                          menu.code === currentMenu ? 'primary' : 'default'
                        "
                        @click="currentMenu = menu.code"
                      ></f7-list-item>
                    </f7-block>
                  </f7-list>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
        <f7-block
          class="no-margin-bottom"
          v-else
        >
          <f7-segmented
            strong
            tag="p"
          >
            <f7-button
              v-for="(menu, index) in leftMenu"
              :key="index"
              class="custom-hover"
              :active="currentMenu === menu.code"
              @click.native="currentMenu = menu.code"
              >{{ menu.title }}</f7-button
            >
          </f7-segmented>
        </f7-block>
      </f7-col>
      <f7-col
        :width="$device.desktop ? 80 : 100"
        class="display-flex flex-direction-column"
        :style="$device.desktop ? '' : 'margin-bottom: 60px;'"
      >
        <div v-show="currentMenu === 'sale'">
          <commercial-sale
            :project="project"
            :contact="contact"
            :proposalList="proposalList"
            :proposalStatusList="proposalStatusList"
          />
        </div>
        <div v-show="currentMenu === 'construction'">
          <commercial-construction
            :project="project"
            :action="action"
          />
        </div>
        <div v-show="currentMenu === 'invoice'">
          <invoice-menu
            :invoiceList="invoiceList"
            :invoiceStatus="invoiceStatusList"
            :invoiceGroup="invoiceGroup"
          />
        </div>
        <div v-show="currentMenu === 'map'">
          <f7-card>
            <f7-card-content>
              <div
                :style="{
                  height: $device.desktop
                    ? 'calc(90vh - var(--f7-navbar-height))'
                    : 'calc(90vh - var(--f7-navbar-height) - 56px)',
                }"
              >
                <iframe
                  :src="viewableMapUrl"
                  allowfullscreen
                  frameborder="0"
                  style="border: 0"
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
            </f7-card-content>
          </f7-card>
        </div>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import axios from 'axios';
import CommercialSale from '../components/card-client-view/CommercialSale.vue';
import CommercialConstruction from '../components/card-client-view/CommercialConstruction.vue';
import InvoiceMenu from '../components/card-client-view/InvoiceMenu.vue';
import { getFullAddress } from '@/utility/address';
import axiosService from '@/services/axios.service';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  components: {
    CommercialSale,
    CommercialConstruction,
    InvoiceMenu,
  },
  props: {
    projectId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      project: {},
      contact: {},
      action: {},
      proposalList: [],
      proposalStatusList: [],
      invoiceList: [],
      invoiceStatusList: [],
      invoiceGroup: {},
      currentMenu: 'sale',
      idToken: '',
      leftMenu: [
        { title: 'Sale', code: 'sale' },
        { title: 'Construction', code: 'construction' },
        { title: 'Invoice', code: 'invoice' },
        { title: 'Map', code: 'map' },
      ],
    };
  },

  created() {
    if (!this.$f7route.query.id) return;
    this.initDataClient();
  },

  computed: {
    ...mapGetters('common/template', ['invoiceTemplate']),

    viewableMapUrl() {
      const address = getFullAddress(this.project.propertyAddress);
      return (
        address &&
        `https://www.google.com/maps/embed/v1/place?key=${this.$google.maps.apiKey}&q=${address}`
      );
    },
    projectIdComputed() {
      return this.projectId ? this.projectId : this.$f7route.query.id;
    },
  },

  methods: {
    ...mapActions('proposal/proposal', ['getProposalBys']),
    ...mapActions('dashboard/finance', [
      'initInvoice',
      'getInvoiceGroupListBys',
    ]),
    ...mapActions('common/template', ['getTemplateList', 'setTemplateList']),

    getDataByProjectId(path) {
      return axios.get(path, {
        baseURL: import.meta.env.VITE_BASE_API,
        withCredentials: true,
        headers: {
          token:
            this.projectId && this.idToken
              ? this.idToken
              : this.$f7route.query.token,
        },
      });
    },
    async getTokenByProjectId() {
      this.$f7.preloader.show();
      const response = await axiosService.post('/tenant/get-anonymous-token', {
        entity: 'project',
        entityId: this.projectIdComputed,
      });
      if (response && response.status === 200) {
        this.idToken = response.data;
      }
      this.$f7.preloader.hide();
    },
    resetData() {
      this.project = {};
      this.contact = {};
      this.action = {};
      this.proposalList = [];
      this.proposalStatusList = [];
      this.invoiceList = [];
      this.invoiceGroup = {};
      this.invoiceStatusList = [];
      this.currentMenu = 'sale';
      this.idToken = '';
      this.leftMenu = [
        { title: 'Sale', code: 'sale' },
        { title: 'Construction', code: 'construction' },
        { title: 'Invoice', code: 'invoice' },
        { title: 'Map', code: 'map' },
      ];
    },
    async initDataClient() {
      try {
        this.$f7.preloader.show();
        const promise = [
          this.getDataByProjectId(`project/${this.projectIdComputed}`),
          this.getDataByProjectId(
            `project/proposal-sent/${this.projectIdComputed}`
          ),
          this.getDataByProjectId(`project/invoice/${this.projectIdComputed}`),
          this.getDataByProjectId(
            `project/app-constant/status-option/proposal/${this.projectIdComputed}`
          ),
          this.getDataByProjectId(
            `project/app-constant/status-option/invoice/${this.projectIdComputed}`
          ),
          this.getDataByProjectId(
            `project/invoice-group/${this.projectIdComputed}`
          ),
          this.getDataByProjectId(
            `project/invoice-template/${this.projectIdComputed}`
          ),
        ];

        const responses = await Promise.all(promise);

        if (responses[0].status === 200 && responses[0].data.success) {
          this.project = responses[0].data.data;
          const res = await Promise.all([
            this.getDataByProjectId(
              `project/contact/${this.project.contactId}/${this.projectIdComputed}`
            ),
            this.getDataByProjectId(
              `project/action/${this.project.actions[0]}/${this.projectIdComputed}`
            ),
          ]);
          if (res[0].status === 200 && res[0].data.success) {
            this.contact = res[0].data.data;
          }
          if (res[1].status === 200 && res[1].data.success) {
            this.action = res[1].data.data;
          }
        }
        if (responses[1].status === 200 && responses[1].data.success) {
          this.proposalList = responses[1].data.data;
        }
        if (responses[2].status === 200 && responses[2].data.success) {
          this.invoiceList = (responses[2].data.data || []).filter(
            item => item.status !== 'in-complete' && item.status !== 'in-draft'
          );
        }
        if (responses[3].status === 200 && responses[3].data.success) {
          this.proposalStatusList = responses[3].data.data;
        }
        if (responses[4].status === 200 && responses[4].data.success) {
          this.invoiceStatusList = responses[4].data.data;
        }
        if (responses[5].status === 200 && responses[5].data.success) {
          this.invoiceGroup = responses[5].data.data.at(0) || {};
        }
        if (responses[6].status === 200 && responses[6].data.success) {
          this.setTemplateList([responses[6].data.data]);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async initDataAdmin() {
      try {
        this.$f7.preloader.show();

        const promises = [
          this.getDataByProjectId(`project/${this.projectIdComputed}`),
          this.getProposalBys([
            {
              prop: 'projectId',
              val: this.projectIdComputed,
              op: '==',
            },
          ]),
          this.getInvoiceGroupListBys([
            {
              prop: 'projectId',
              op: '==',
              val: this.projectIdComputed,
            },
          ]),
          this.getDataByProjectId(
            `project/app-constant/status-option/proposal/${this.projectIdComputed}`
          ),
          this.getDataByProjectId(
            `project/app-constant/status-option/invoice/${this.projectIdComputed}`
          ),
        ];
        if (_.isEmpty(this.invoiceTemplate)) {
          promises.push(this.getTemplateList());
        }
        const responses = await Promise.all(promises);
        if (responses[0].status === 200 && responses[0].data.success) {
          this.project = responses[0].data.data;
          const res = await Promise.all([
            this.getDataByProjectId(
              `project/contact/${this.project.contactId}/${this.projectIdComputed}`
            ),
            this.getDataByProjectId(
              `project/action/${this.project.actions[0]}/${this.projectIdComputed}`
            ),
          ]);
          if (res[0].status === 200 && res[0].data.success) {
            this.contact = res[0].data.data;
          }
          if (res[1].status === 200 && res[1].data.success) {
            this.action = res[1].data.data;
          }
        }

        this.proposalList = responses[1] || [];

        if (!_.isEmpty(responses[2])) {
          this.invoiceGroup = responses[2].at(0) || {};
          this.initInvoice(responses[2].at(0).id || '').then(data => {
            this.invoiceList = data || [];
          });
        }
        if (responses[3].status === 200 && responses[3].data.success) {
          this.proposalStatusList = responses[3].data.data;
        }
        if (responses[4].status === 200 && responses[4].data.success) {
          this.invoiceStatusList = responses[4].data.data;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },
  },

  watch: {
    projectId: {
      async handler(val) {
        if (val) {
          await this.getTokenByProjectId();
          await this.initDataAdmin();
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.info-label {
  font-weight: 600;
  margin-left: 5px;
  margin-top: -2px;
}

.title {
  display: flex;
  row-gap: 15px;
}

.scroll-container {
  max-height: calc(100vh - 270px);
  overflow-x: auto;
  margin: 0px 0px 0px -15px;
  direction: ltr;
  scroll-behavior: smooth;
}
</style>
