import { FirebaseActions } from '../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const priceListActions = new FirebaseActions(
    `/system_client/${tenant}/product_price_list`,
    'priceListItem'
  );
  return {
    getPriceListBys: priceListActions.getDocumentBys,
    unbindPriceListItemList: priceListActions.unbindCollection,

    bindPriceListItem: priceListActions.bindDocument,
    unbindPriceListItem: priceListActions.unbindDocument,
    async getPriceListItemListBys({ commit, dispatch }, payload) {
      const messageList = await dispatch('getPriceListBys', payload);
      commit(types.SET_PRICE_LIST_ITEM_LIST, messageList || []);
      return messageList;
    },
  };
};
