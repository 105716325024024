<template>
  <f7-list :inline-labels="$device.desktop">
    <f7-list-input
      v-for="(email, index) in value"
      :key="index"
      type="email"
      :value="email.value"
      @input="email.value = $event.target.value.trim()"
      class="contact-email-input"
      placeholder="Email"
      clear-button
      error-message-force
      :error-message="emailErrorMessage(index)"
      @blur="v$.value.$touch()"
      :ref="`email-input-${email.code}`"
    >
      <div slot="label">
        <f7-link
          :popover-open="
            emailTypeOptions.length ? `.popover-email-type-${id}` : false
          "
          @click.native="openEmailType(index)"
          @focus.native="onLinkFocus(email.code)"
          >{{ email.title }}</f7-link
        >
        <f7-icon
          style="float: right; margin-top: 4px"
          size="18"
          color="gray"
          f7="chevron_right"
        ></f7-icon>
      </div>
      <f7-icon
        class="cursor-pointer"
        color="red"
        f7="minus_circle_fill"
        slot="media"
        @click.native="deleteEmail(index)"
      ></f7-icon>
    </f7-list-input>

    <f7-list-item v-if="emailTypeOptions.length">
      <f7-link
        :popover-open="
          value.length && emailTypeOptions.length > 1
            ? `.popover-email-type-${id}`
            : false
        "
        class="cursor-pointer text-input"
        slot="title"
        @click.native="handleAdd"
      >
        add email<required-asterisk v-show="isRequired"></required-asterisk>
      </f7-link>
      <f7-icon
        class="add-button-icon"
        f7="plus_circle"
        slot="media"
      >
      </f7-icon>
      <div
        slot="footer"
        class="theme-color"
      >
        {{ emailsErrorMessage }}
      </div>
    </f7-list-item>

    <f7-popover
      ref="emailTypePopover"
      @popover:closed="sheetClosed"
      :class="`popover-email-type-${id}`"
    >
      <f7-list>
        <f7-list-item
          v-for="(type, index) in emailTypeOptions"
          :key="index"
          :title="type.title"
          link
          @click.native="selectEmailType(type.code)"
        ></f7-list-item>
      </f7-list>
    </f7-popover>
  </f7-list>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, email } from '@vuelidate/validators';
import _ from 'lodash';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  props: {
    id: String,
    value: {
      type: Array,
      default: () => [],
    },
    isRequired: { type: Boolean, default: false },
  },

  data: () => {
    return {
      emailTypes: [
        { code: 'main', title: 'Main' },
        { code: 'home', title: 'Home' },
        { code: 'work', title: 'Work' },
      ],
      isNew: false,
      currentEmailIndex: '',
    };
  },

  computed: {
    emailTypeOptions() {
      const codes = this.value.map(r => r.code) || [];
      return this.emailTypes.filter(r => !codes.includes(r.code));
    },
    emailErrorMessage() {
      return index => {
        if (this.v$.value.$each.$response.$errors[index].value.length === 0)
          return null;
        return (
          this.v$.value.$each.$response.$errors[index].value[0].$message || ''
        );
      };
    },
    emailsErrorMessage() {
      if (!this.v$.value.$error) return null;
      if (this.v$.value.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },

  methods: {
    onLinkFocus(code) {
      this.$refs[`email-input-${code}`][0].$el
        .querySelector('.contact-email-input input')
        .focus();
    },

    handleAdd() {
      if (!this.value.length) {
        this.addEmail('main');
      } else if (this.emailTypeOptions.length > 1) {
        this.isNew = true;
      } else if (this.emailTypeOptions.length === 1) {
        this.addEmail(this.emailTypeOptions[0].code);
      }
    },

    deleteEmail(index) {
      let emails = _.cloneDeep(this.value);
      emails.splice(index, 1);
      this.$emit('input', emails);
    },

    addEmail(value) {
      let emails = _.cloneDeep(this.value);
      const emailType = this.emailTypes.find(r => r.code === value) || {};
      emails.push({
        code: value,
        title: emailType.title,
        value: '',
      });
      this.$emit('input', emails);
      this.$nextTick(() => {
        this.onLinkFocus(value);
      });
    },

    sheetClosed() {
      this.isNew = false;
      this.currentEmailIndex = '';
    },

    openEmailType(index) {
      this.currentEmailIndex = index;
    },

    selectEmailType(type) {
      const obj = this.emailTypes.find(item => {
        return item.code === type;
      });
      if (this.isNew) {
        this.addEmail(type);
      } else {
        let emails = _.cloneDeep(this.value);
        emails[this.currentEmailIndex].code = obj.code;
        emails[this.currentEmailIndex].title = obj.title;
        this.$emit('input', emails);
        this.$nextTick(() => {
          this.onLinkFocus(type);
        });
      }
      this.$refs.emailTypePopover.close();
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      value: {
        required(val) {
          if (!this.isRequired) return true;
          return (val || []).length;
        },
        $each: helpers.forEach({
          value: {
            required: helpers.withMessage(
              VALIDATION_MESSAGE.REQUIRED_FIELD,
              required
            ),
            commonEmailRule: helpers.withMessage(
              VALIDATION_MESSAGE.INVALID_EMAIL,
              email
            ),
          },
        }),
      },
    };
  },
};
</script>

<style scoped>
.text-input {
  color: var(--f7-color-text-neutral);
}
</style>
