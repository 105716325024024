export default {
  assemblyDrawing: {},
  searchValue: '',

  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0,
};
