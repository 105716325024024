import Vue from 'vue';

export default {
  setEstimateList(state, payload) {
    Vue.set(state, 'estimateList', payload);
  },
  setEstimateSubscribe(state, payload) {
    Vue.set(state, 'estimateSubscribe', payload);
  },
};
