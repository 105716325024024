// import { checkAuth } from "../../../services/authentication.service";

import commercialDashboardRoutes from './commercialDashboard';
import constructionDashboardRoutes from './constructionDashboard';
import serviceDashboardRoutes from './serviceDashboard';
import residentialDashboardRoutes from './residentialDashboard';
import archiveDashboardRoutes from './archiveDashboard';

import RoofingChoicesClientViewPage from '../pages/RoofingChoicesClientViewPage.vue';
import AgreementClientViewPage from '../pages/AgreementClientViewPage.vue';
import CertificateClientViewPage from '../pages/CertificateClientViewPage.vue';
import LetterClientViewPage from '../pages/LetterClientViewPage.vue';
import BuildContractClientViewPage from '../pages/BuildContractClientViewPage.vue';
import CommercialCardClientViewPage from '../pages/CommercialCardClientViewPage.vue';
import ResidentialCardClientViewPage from '../pages/ResidentialCardClientViewPage.vue';
import ServiceCardClientViewPage from '../pages/ServiceCardClientViewPage.vue';
export default [
  // DashBoard
  {
    path: '',
    title: 'Dashboard',
    name: 'dashboard-main',
    displayRoles: ['owner', 'user'],
    routes: [
      ...commercialDashboardRoutes,
      ...constructionDashboardRoutes,
      ...residentialDashboardRoutes,
      ...serviceDashboardRoutes,
      ...archiveDashboardRoutes,
    ],
  },

  {
    path: '',
    title: 'Roofing Choices View',
    name: 'roofing-choices-view',
    routes: [
      {
        path: '/roofing-choices-view',
        title: 'roofing-choices',
        name: 'roofing-choices',
        icon: 'doc_text',
        component: RoofingChoicesClientViewPage,
      },
    ],
  },
  {
    path: '',
    title: 'Agreement View',
    name: 'agreement-view',
    routes: [
      {
        path: '/agreement-view',
        title: 'agreement',
        name: 'agreement',
        icon: 'doc_text',
        component: AgreementClientViewPage,
      },
    ],
  },
  {
    path: '',
    title: 'Agreement Preview',
    name: 'agreement-preview',
    routes: [
      {
        path: '/agreement-preview',
        title: 'agreement',
        name: 'agreement',
        icon: 'doc_text',
        component: AgreementClientViewPage,
      },
    ],
  },
  {
    path: '',
    title: 'Certificate View',
    name: 'certificate-view',
    routes: [
      {
        path: '/certificate-view',
        title: 'certificate',
        name: 'certificate',
        icon: 'doc_text',
        component: CertificateClientViewPage,
      },
    ],
  },
  {
    path: '',
    title: 'Certificate Preview',
    name: 'certificate-preview',
    routes: [
      {
        path: '/certificate-preview',
        title: 'certificate',
        name: 'certificate',
        icon: 'doc_text',
        component: CertificateClientViewPage,
      },
    ],
  },
  {
    path: '',
    title: 'Letter View',
    name: 'letter-view',
    routes: [
      {
        path: '/letter-view',
        title: 'letter',
        name: 'letter',
        icon: 'doc_text',
        component: LetterClientViewPage,
      },
    ],
  },
  {
    path: '',
    title: 'Letter Preview',
    name: 'letter-preview',
    routes: [
      {
        path: '/letter-preview',
        title: 'letter',
        name: 'letter',
        icon: 'doc_text',
        component: LetterClientViewPage,
      },
    ],
  },
  {
    path: '',
    title: 'Build Contract View',
    name: 'contract-view',
    routes: [
      {
        path: '/contract-view',
        title: 'contract-view',
        name: 'contract-view',
        icon: 'doc_text',
        component: BuildContractClientViewPage,
      },
    ],
  },
  {
    path: '',
    title: 'Build Contract Preview',
    name: 'contract-preview',
    routes: [
      {
        path: '/contract-preview',
        title: 'contract-preview',
        name: 'contract-preview',
        icon: 'doc_text',
        component: BuildContractClientViewPage,
      },
    ],
  },
  {
    path: '',
    title: 'Card View',
    name: 'commercial-card-view',
    routes: [
      {
        path: '/commercial-card-view',
        title: 'commercial-card-view',
        name: 'commercial-card-view',
        icon: 'doc_text',
        component: CommercialCardClientViewPage,
      },
    ],
  },
  {
    path: '',
    title: 'Card View',
    name: 'residential-card-view',
    routes: [
      {
        path: '/residential-card-view',
        title: 'residential-card-view',
        name: 'residential-card-view',
        icon: 'doc_text',
        component: ResidentialCardClientViewPage,
      },
    ],
  },
  {
    path: '',
    title: 'Card View',
    name: 'service-card-view',
    routes: [
      {
        path: '/service-card-view',
        title: 'service-card-view',
        name: 'service-card-view',
        icon: 'doc_text',
        component: ServiceCardClientViewPage,
      },
    ],
  },
];
