<template>
  <div>
    <data-table
      :headers="headers"
      :items="items"
      :pageSize="items.length"
      class="po-product-table"
      :style="$device.desktop ? '' : 'margin-bottom: 100px;'"
    >
      <template slot="card-header">
        <f7-block class="margin-top-half">
          <f7-row>
            <div>
              Vendor:
              <i>{{ (companyById(vendorId) || {}).companyName }}</i
              ><span>
                / Manufacturer: <i>{{ manufacturers }}</i></span
              >
            </div>
          </f7-row>
        </f7-block>
      </template>
      <!-- Table -->
      <template v-slot:body="{ item }">
        <td>
          {{ item.sku }}
        </td>
        <td>
          {{ item.productItem }}
        </td>
        <td>
          {{ item.manufacturer || '' }}
        </td>
        <td style="text-align: left">
          {{ item.actualQty || '' }}
        </td>
        <td>{{ item.uom }}</td>
        <td>
          {{
            item.unitSize && item.uom && item.packaging
              ? `${item.unitSize} ${item.uom}/${item.packaging}`
              : ''
          }}
        </td>
        <td>
          {{ item.orderQty || 0 }}
        </td>
        <td>
          {{ item.packaging }}
        </td>
        <td style="text-align: right">
          {{ item.price | currencyUSD }}
        </td>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/datatables';
import {
  STATUS_PO_CANCEL,
  STATUS_PO_DRAFT,
  STATUS_PO_PARTIAL_RECEIVED,
  STATUS_PO_RECEIVED,
  STATUS_PO_REQUEST_CANCEL,
  STATUS_PO_WAITING,
} from '@/utility/const';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      STATUS_PO_RECEIVED,
      STATUS_PO_PARTIAL_RECEIVED,
      STATUS_PO_CANCEL,
      STATUS_PO_REQUEST_CANCEL,
      STATUS_PO_DRAFT,
      STATUS_PO_WAITING,
    };
  },
  props: {
    vendorId: String,
    items: { type: Array, default: () => [] },
    woTaskStatus: { type: String, default: '' },

    // isBuildYourOwn: { type: Boolean, default: false }
  },
  components: {
    DataTable,
  },
  computed: {
    ...mapGetters('dashboard/client', ['companyById']),

    getQty() {
      return (status, receivedQty, orderQty) => {
        switch (status) {
          case STATUS_PO_DRAFT:
          case STATUS_PO_REQUEST_CANCEL:
          case STATUS_PO_CANCEL:
            return orderQty || 0;

          case STATUS_PO_WAITING:
          case STATUS_PO_RECEIVED:
          case STATUS_PO_PARTIAL_RECEIVED:
            return receivedQty || 0;

          default:
            return 0;
        }
      };
    },
    manufacturers() {
      return this.items
        .reduce((array, item) => {
          if (!array.includes(item.manufacturer)) {
            array.push(item.manufacturer);
          }

          return array;
        }, [])
        .join(', ');
    },
    headers() {
      let h = [
        {
          text: 'SKU',
          align: 'left',
          value: 'sku',
        },
        {
          text: 'Product Item',
          align: 'left',
          value: 'productItem',
        },
        {
          text: 'Manufacturer',
          align: 'left',
          value: 'manufacturer',
        },
        {
          text: 'Qty',
          align: 'left',
          value: 'actualQty',
        },
        {
          text: 'UoM',
          align: 'left',
          value: 'uom',
        },
        {
          text: 'Unit Size',
          align: 'left',
          value: 'unitSize',
        },
        {
          text: 'Order Qty',
          align: 'left',
          value: 'orderQty',
        },
        {
          text: 'Packaging',
          align: 'left',
          value: 'packaging',
        },
        {
          text: 'Price',
          align: 'right',
          value: 'price',
        },
      ];

      return h;
    },
  },
};
</script>

<style lang="scss" scoped>
.po-product-table ::v-deep tbody tr {
  td {
    padding: 10px;
  }
}

@media (max-width: 480px) and (orientation: portrait) {
  .po-product-table ::v-deep table {
    table-layout: fixed;
  }

  .po-product-table::v-deep thead tr {
    th {
      padding: 0px 10px;
    }
    //Product Item
    th:nth-child(1) {
      width: 140px;
    }
    // Package Qty
    th:nth-child(2) {
      width: 100px;
    }
    // Price
    th:nth-child(3) {
      width: 100px;
    }
    // Total Cost
    th:nth-child(4) {
      width: 160px;
    }
    // Total Cost
    th:nth-child(5) {
      width: 120px;
    }
    // Total Cost
    th:nth-child(6) {
      width: 120px;
    }
    // Total Cost
    th:nth-child(7) {
      width: 120px;
    }
    // Action
    th:nth-child(8) {
      width: 70px;
    }
    th:nth-child(9) {
      width: 70px;
    }
  }

  .po-product-table ::v-deep tbody tr {
    td {
      padding: 0px 10px;
    }
  }
}
</style>
