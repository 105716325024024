export default {
  estimate: {},
  estimateSubscribe: null,
  estimateList: [],
  order: 'desc',
  searchText: '',

  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0,
};
