import * as types from './types';
import Vue from 'vue';

export default {
  [types.SET_LAST_ITEM_REF](state, payload) {
    Vue.set(state, 'activityLastRef', payload.ref);
  },

  [types.MERGE_DATA_BATCH](state, payload) {
    Vue.set(state, 'activityList', state.activityList.concat(payload.data));
  },

  [types.ALL_DATA_LOADED](state) {
    Vue.set(state, 'activityAllLoaded', true);
  },

  [types.RESET_PAGE](state) {
    Vue.set(state, 'activityList', []);
    Vue.set(state, 'activityLastRef', null);
    Vue.set(state, 'activityAllLoaded', false);
  },
};
