<template>
  <f7-page class="photos-page-container">
    <f7-block
      v-if="photoFilter"
      class="display-flex"
      style="gap: 16px"
    >
      <div class="input-date-filter">
        <f7-icon
          color="primary"
          f7="calendar"
          size="18"
        ></f7-icon>
        <f7-input
          class="width-100"
          type="datepicker"
          placeholder="Start Date"
          clear-button
          :value="photoFilter.startDate"
          :calendar-params="{
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            dateFormat: 'mm/dd/yyyy',
          }"
          @calendar:change="
            $emit('onFilterDate', { field: 'startDate', value: $event });
            $f7.calendar.close();
          "
          @calendar:clear="
            $emit('onFilterDate', { field: 'startDate', value: [] })
          "
        ></f7-input>
      </div>
      <div>
        <div class="input-date-filter">
          <f7-icon
            color="primary"
            f7="calendar"
            size="18"
          ></f7-icon>
          <f7-input
            class="width-100"
            type="datepicker"
            placeholder="To Date"
            clear-button
            :value="photoFilter.endDate"
            :calendar-params="{
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
              dateFormat: 'mm/dd/yyyy',
              disabled: date =>
                photoFilter.startDate &&
                date < new Date(photoFilter.startDate).setHours(0, 0, 0, 0),
            }"
            @calendar:change="
              $emit('onFilterDate', { field: 'endDate', value: $event });
              $f7.calendar.close();
            "
            @calendar:clear="
              $emit('onFilterDate', { field: 'endDate', value: [] })
            "
          ></f7-input>
        </div>
        <div class="error-message">
          {{ endDateFilterErrorMessage }}
        </div>
      </div>
    </f7-block>
    <div
      ref="photosPageContainer"
      class="no-padding-top page-content main-page-content infinite-scroll-content"
      @infinite="$emit('loadMore')"
    >
      <div class="margin-left">
        Selected {{ selectedPhotoIds.length }} items
      </div>
      <div
        v-if="projectPhotoListGroup.length === 0 && !loading"
        class="camera"
      >
        <div class="bg-no-data">
          <f7-icon
            f7="photo"
            class="icon-no-data"
          ></f7-icon>
        </div>
        <p>No photo to load</p>
      </div>
      <div
        ref="imgsContainer"
        class="imgs-container"
        v-if="projectPhotoListGroup.length > 0 && !loading"
      >
        <div
          v-for="projectPhoto in projectPhotoListGroup"
          :key="projectPhoto.key"
        >
          <div
            class="display-flex flex-direction-row align-items-center margin-left-half"
            @click="
              $emit('handleSelectAllDay', {
                event: projectPhoto.data.every(photo =>
                  selectedPhotoIds.includes(photo.id)
                )
                  ? false
                  : true,
                ids: projectPhoto.data.map(photo => photo.id),
              })
            "
            @mouseenter="$emit('handleMouseEnter')"
          >
            <f7-checkbox
              :style="
                $device.desktop
                  ? 'transform: scale(1.5);'
                  : 'transform: scale(1.2); top: 8px'
              "
              class="margin-top-half margin-left checkbox-all"
              :checked="
                projectPhoto.data.every(photo =>
                  selectedPhotoIds.includes(photo.id)
                )
              "
              @click.native.stop
              @change.native.stop="
                $emit('handleSelectAllDay', {
                  event: $event.target.checked,
                  ids: projectPhoto.data.map(photo => photo.id),
                })
              "
            ></f7-checkbox>
            <f7-block-title style="cursor: pointer">{{
              projectPhoto.key
            }}</f7-block-title>
          </div>
          <f7-block class="margin-top-half block-image">
            <div class="img-grid">
              <div
                v-for="photo in projectPhoto.data"
                :key="photo.id"
                @touchstart="handlePressAndHold(photo.id)"
                @touchend="handleTouchEnd"
                @mouseenter="handleMouseEnter(photo.id)"
                @mouseleave="$emit('handleSelected', null)"
              >
                <div
                  class="img-item"
                  style="border-radius: 4px"
                  @click.stop="$emit('clickPhoto', photo.id, true)"
                >
                  <img
                    class="image-show"
                    :src="photo.thumbnailUrl"
                  />
                  <div
                    v-if="
                      $device.desktop &&
                      selectionMode &&
                      hoveredPhotoIds.includes(photo.id)
                    "
                    class="img-bg img-bg-select"
                  >
                    <div class="img-bg-content">
                      <f7-checkbox
                        style="
                          position: absolute;
                          top: 10%;
                          left: 10%;
                          transform: scale(1.5);
                        "
                        :checked="selectedPhotoIds.includes(photo.id)"
                        @click.native.stop
                        @change.native.stop="$emit('clickPhoto', photo.id)"
                      ></f7-checkbox>
                    </div>
                  </div>
                  <div
                    v-if="!$device.desktop && selectionMode"
                    class="img-bg img-bg-select"
                  >
                    <div class="img-bg-content">
                      <f7-checkbox
                        style="
                          position: absolute;
                          top: 10%;
                          left: 10%;
                          transform: scale(1.2);
                        "
                        :checked="selectedPhotoIds.includes(photo.id)"
                        @click.native.stop
                        @change.native.stop="$emit('clickPhoto', photo.id)"
                      ></f7-checkbox>
                    </div>
                  </div>
                  <div
                    v-else
                    class="img-bg img-bg-not-select"
                  >
                    <div
                      v-if="
                        photo.photoFullPath &&
                        (photo.photoFullPath.toLowerCase().includes('.mp4') ||
                          photo.photoFullPath.toLowerCase().includes('.mov') ||
                          photo.photoFullPath.toLowerCase().includes('.m4v') ||
                          photo.photoFullPath.toLowerCase().includes('.webm'))
                      "
                      class="play-button"
                    >
                      <i class="icon f7-icons size-20">play_circle_fill</i>
                    </div>
                  </div>
                </div>
                <div
                  class="img-title"
                  style="margin-top: 10px"
                >
                  {{ (photo.createdAt && photo.createdAt.toDate()) | TIME }}
                </div>
              </div>
            </div>
          </f7-block>
        </div>
      </div>
      <div
        v-show="hasMoreItems"
        class="preloader infinite-scroll-preloader"
      ></div>
    </div>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      pressHoldEvent: null,
    };
  },
  props: {
    selectionMode: Boolean,
    selectedPhotoIds: Array,
    projectPhotoListGroup: Array,
    loading: Boolean,
    isClientView: { type: Boolean, default: false },
    hasMoreItems: Boolean,
    hoveredPhotoIds: Array,
    photoFilter: Object,
    endDateFilterErrorMessage: String,
  },
  computed: {
    isAllChecked(data) {
      return data.every(photo => this.selectedPhotoIds.includes(photo.id));
    },
  },
  methods: {
    handleMouseEnter(id) {
      if (this.$device.desktop) {
        this.$emit('handleSelected', id);
      }
    },
    handlePressAndHold(id) {
      if (!this.$device.desktop) {
        this.pressHoldEvent = setTimeout(() => {
          this.$emit('handleSelected', id, true);
        }, 1000);
      }
    },
    handleTouchEnd() {
      clearTimeout(this.pressHoldEvent);
      this.pressHoldEvent = null;
    },
  },
};
</script>
<style scoped>
.photos-page-container ::v-deep .main-page-content {
  padding-bottom: 90px;
  /* 28px is the height of the date filter input */
  height: calc(100% - var(--f7-block-margin-vertical) * 2 - 28px);
}
.bg-no-data {
  width: 150px;
  height: 150px;
  background: var(--f7-color-img-neutral);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-no-data {
  font-size: 100px !important;
  color: var(--f7-color-icon-neutral);
}
.camera {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 20px;
}
.img-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  cursor: pointer;
  background-position: 50% center;
  background: white;
}
.img-bg {
  position: absolute;
  border-radius: 4px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.img-bg-select {
  transition: all 150ms ease-in-out 0s;
  background: rgba(0, 0, 0, 0.3);
}
.img-item:hover .img-bg-not-select {
  background: rgba(0, 0, 0, 0.2);
}
.img-bg-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 1;
}
.img-title {
  font-size: 12px;
}
.edit-button {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 24px;
  text-align: center;
  color: white;
  font-size: 12px;
  background: #6666667a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.play-button {
  position: absolute;
  width: 100%;
  top: 34%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-button:hover {
  font-weight: 600;
  background: #0808087a;
}

.image-show {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
}
.input-date-filter {
  display: flex;
  align-items: center;
  height: 28px;
  border: 1px solid #cfcfcf;
  border-radius: 22px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--f7-md-surface);
  gap: 8px;
  box-sizing: border-box;
}
.error-message {
  font-size: var(--f7-list-item-text-font-size);
  font-weight: var(--f7-list-item-text-font-weight);
  color: red;
}
.checkbox-all ::v-deep i {
  border-color: var(--f7-color-checkbox-color);
}
.custom-navbar ::v-deep .navbar-bg {
  background-color: black;
}
.block-image {
  padding: 0 20px 0 20px;
}
</style>
