import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const groupActions = new FirebaseActions(
    `/system_client/${tenant}/administration_group`,
    'group'
  );
  return {
    getGroupListBys: groupActions.getDocumentBys,

    getAdminGroups({ dispatch, commit }) {
      return dispatch('getGroupListBys', [
        {
          prop: 'code',
          op: '==',
          val: 'admin',
        },
        { prop: 'isDefault', op: '==', val: true },
      ]).then(res => {
        commit(types.SET_ADMIN_GROUPS, res);
      });
    },
  };
};
