import Vue from 'vue';
import * as types from './types';

export default {
  [types.SET_TAB_ACTIVE](state, payload) {
    state.tabActive = payload;
  },

  [types.SET_CONSTANT_LIST](state, payload) {
    Vue.set(state, 'typeList', payload);
  },

  [types.SET_IS_CLOSE_ARCHIVE_BOARD_PROJECT_DETAIL_POPUP](state, payload) {
    Vue.set(state, 'isCloseArchiveBoardProjectDetailPopup', payload);
  },

  [types.SET_IS_OPEN_PROJECT_PHOTOS_VIEW_POPUP](state, payload) {
    Vue.set(state, 'isOpenProjectPhotoViewPopup', payload);
  },
};
