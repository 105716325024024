<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="handlePopupClosed"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="cancel">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Edit Property</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="save">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <!-- Property Name -->
        <f7-list-input
          class="first-input"
          type="text"
          placeholder="Property name"
          clear-button
          :value="currentProperty.propertyName"
          @input="set('propertyName', $event.target.value.trim())"
          error-message-force
          :error-message="propertyNameErrorMessage"
          @blur="v$.$touch()"
        ></f7-list-input>
      </f7-list>

      <!-- Address -->
      <address-auto-complete-input
        ref="addressInput"
        isRequired
        :value="currentProperty.addresses"
        @input="set('addresses', $event)"
      ></address-auto-complete-input>
    </f7-page>
  </f7-popup>
</template>

<script>
import AddressAutoCompleteInput from '@/components/inputs/AddressAutoCompleteInput.vue';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Vue from 'vue';
import _ from 'lodash';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    AddressAutoCompleteInput,
  },

  data: () => {
    return {
      popupOpened: false,
      currentProperty: { propertyName: '' },
    };
  },

  computed: {
    ...mapGetters('contact-book/property', ['property']),

    propertyNameErrorMessage() {
      if (!this.v$.currentProperty.propertyName.$error) return null;
      if (this.v$.currentProperty.propertyName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },

  methods: {
    ...mapActions('contact-book/property', [
      'getPropertyById',
      'updateProperty',
      // "clearProperty"
    ]),

    set(prop, value) {
      Vue.set(this.currentProperty, prop, value);
    },

    async open(id) {
      this.popupOpened = true;
      const property = await this.getPropertyById(id);
      this.currentProperty = _.cloneDeep(property);
    },

    async close() {
      this.popupOpened = false;
    },

    handlePopupClosed() {
      this.popupOpened = false;
      // this.clearProperty();
      this.currentProperty = { propertyName: '' };
      this.v$.$reset();
      this.$refs.addressInput.resetData();
    },

    cancel() {
      this.popupOpened = false;
    },

    async save() {
      const self = this;
      this.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      if (this.v$.$invalid || this.$refs.addressInput.v$.$invalid) return;

      this.$f7.preloader.show();
      await this.updateProperty({
        id: this.property.id,
        doc: { ...this.currentProperty },
      });
      self.$f7.preloader.hide();
      await self.$emit('onUpdated', this.property.id);

      self.close();
    },

    handlePopupOpened() {
      this.$el.querySelector('.first-input input').focus();
    },
  },

  watch: {
    property: {
      handler(val) {
        if (!_.isEmpty(val)) {
          this.currentProperty = _.cloneDeep(val);
        }
      },
      immediate: true,
      deep: true,
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      currentProperty: {
        propertyName: {
          required,
        },
      },
    };
  },
};
</script>

<style></style>
