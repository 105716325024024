<template>
  <f7-popup
    tablet-fullscreen
    :opened="popupOpened"
    @popup:close="closePopup()"
    @popup:closed="doAfterClosed()"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="popupOpened = false">Back</f7-link>
        </f7-nav-left>
        <f7-nav-title> Preview Roofing Choices </f7-nav-title>
        <f7-nav-right v-if="isShowBtnSendEmail">
          <f7-link @click.native="$emit('sentRoofingChoices')"
            >Send Email</f7-link
          >
        </f7-nav-right>
      </f7-navbar>

      <f7-row
        no-gap
        class="bg-wrap"
        :style="`height: ${
          $device.desktop ? 'calc(100vh - var(--f7-navbar-height))' : 'auto'
        }`"
      >
        <f7-col
          width="100"
          large="30"
          class="h-100 scroll-container"
          style="box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1)"
        >
          <div
            class="option-container"
            :class="
              $device.desktop ? 'wrap-content-web' : 'wrap-content-mobile'
            "
          >
            <b
              class="title-detail"
              style="font-size: 20px"
              >Materials Option</b
            >
            <div class="bg-option padding">
              Please choose the 3 materials you like in the preferred order from
              1 to 3 (#2 and #3 are backup options).
              <br />
              Drag and drop the image of materials onto this frame:
            </div>
            <template v-if="$device.desktop">
              <div class="wrap-option">
                <div style="flex: 1">
                  <b>Details</b>
                  <div class="bg-option detail-option"></div>
                </div>
                <div>
                  <b class="numerical">1st Choice</b>
                  <div class="border-choice"></div>
                </div>
              </div>
              <div class="wrap-option">
                <div style="flex: 1">
                  <b>Details</b>
                  <div class="bg-option detail-option"></div>
                </div>
                <div>
                  <b class="numerical">2nd Choice</b>
                  <div class="border-choice"></div>
                </div>
              </div>
              <div class="wrap-option">
                <div style="flex: 1">
                  <b>Details</b>
                  <div class="bg-option detail-option"></div>
                </div>
                <div>
                  <b class="numerical">3rd Choice</b>
                  <div class="border-choice"></div>
                </div>
              </div>
            </template>
            <template v-else>
              <f7-list
                accordion-list
                inset
                class="no-margin custom-accodion"
              >
                <f7-list-item accordion-item>
                  <div slot="title">Option Selected: <b>0</b> items</div>
                  <f7-accordion-content>
                    <div class="option-container margin-top">
                      <div class="wrap-option">
                        <div style="flex: 1">
                          <b>Details</b>
                          <div class="bg-option detail-option"></div>
                        </div>
                        <div>
                          <b class="numerical">1st Choice</b>
                          <div class="border-choice"></div>
                        </div>
                      </div>
                      <div class="wrap-option">
                        <div style="flex: 1">
                          <b>Details</b>
                          <div class="bg-option detail-option"></div>
                        </div>
                        <div>
                          <b class="numerical">2nd Choice</b>
                          <div class="border-choice"></div>
                        </div>
                      </div>
                      <div class="wrap-option">
                        <div style="flex: 1">
                          <b>Details</b>
                          <div class="bg-option detail-option"></div>
                        </div>
                        <div>
                          <b class="numerical">3rd Choice</b>
                          <div class="border-choice"></div>
                        </div>
                      </div>
                    </div>
                  </f7-accordion-content>
                </f7-list-item>
              </f7-list>
            </template>
          </div>
        </f7-col>
        <f7-col
          width="100"
          large="70"
          class="h-100 scroll-container"
        >
          <div
            class="detail-container"
            :class="
              $device.desktop ? 'wrap-content-web' : 'wrap-content-mobile'
            "
          >
            <div
              v-for="(product, index) in roofingChoicesProducts"
              :key="index"
            >
              <b style="font-size: 20px">
                {{ product.productItem }}
              </b>
              <div
                v-if="
                  !product.colorPhotos || !(product.colorPhotos || []).length
                "
                class="img-grid margin-top"
              >
                <div>
                  <div class="img-item">
                    <img
                      class="img-show"
                      :src="noImageDefault"
                      alt="No Image Available"
                    />
                  </div>
                  <div class="margin-top-half display-flex align-items-center">
                    <f7-checkbox
                      v-if="!$device.desktop"
                      class="margin-right-half"
                    ></f7-checkbox>
                    <span>No color name</span>
                  </div>
                </div>
              </div>
              <div class="img-grid margin-top">
                <div
                  v-for="(color, index) in product.colorPhotos || []"
                  :key="index"
                >
                  <div class="img-item">
                    <img
                      class="img-show"
                      :src="(color.photos[0] || {}).thumbnailUrl || ''"
                      :alt="color.colorName"
                    />
                  </div>
                  <div class="margin-top-half display-flex align-items-center">
                    <f7-checkbox
                      v-if="!$device.desktop"
                      class="margin-right-half"
                    ></f7-checkbox>
                    <span>{{ color.colorName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </f7-col>
      </f7-row>
    </f7-page>
  </f7-popup>
</template>

<script>
import noImageDefault from '@/assets/img/no_image_default.png';
export default {
  data: () => {
    return {
      noImageDefault,
      popupOpened: false,
      roofingChoicesProducts: [],
      isShowBtnSendEmail: false,
    };
  },

  methods: {
    openPopup(roofingChoicesProducts, isShowBtnSendEmail) {
      this.popupOpened = true;
      this.isShowBtnSendEmail = isShowBtnSendEmail;
      this.roofingChoicesProducts = roofingChoicesProducts;
    },

    closePopup() {
      this.isShowBtnSendEmail = false;
      this.popupOpened = false;
    },

    doAfterClosed() {
      this.roofingChoicesProducts = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-content-web {
  padding: 40px 32px;
  box-sizing: border-box;
}
.wrap-content-mobile {
  padding: 20px 16px;
  box-sizing: border-box;
}
.option-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.bg-option {
  border-color: #f2f3ff;
  background: #f2f3ff;
  border-radius: 8px;
  padding: 1px;
  box-sizing: border-box;
}
.wrap-option {
  display: flex;
  gap: 24px;
}
.detail-option {
  min-height: 100px;
  margin-top: 16px;
}
.border-choice {
  border: dashed 2px black;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 16px;
}
.detail-container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.scroll-container {
  overflow-y: auto;
}
.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 20px;
}
.img-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  cursor: pointer;
  background-position: 50% center;
  background: #f2f3ff;
  border-radius: 8px;
  overflow: hidden;
}
.img-show {
  width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  object-fit: contain;
}
.title-detail {
  color: var(--f7-color-blue);
}
.custom-accodion ::v-deep .item-link {
  background: #f2f3ff;
}

.bg-wrap {
  background-color: white;
  color: black;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  cursor: pointer;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
}

::-webkit-scrollbar-thumb:active {
  background: #bbbbbb;
}
</style>
