<template>
  <div>
    <f7-page v-if="loadingData"></f7-page>
    <preview-page
      v-else
      ref="previewPage"
      title="Letter Preview"
      :itemDetail="letter || {}"
      :isHiddenLeftBtn="true"
      entity="letter"
      :entityId="letter.id"
      :isShowOnlineSignature="
        !letter.isDeleted &&
        letter.viewedAt &&
        !letter.signatureAt &&
        isLetterViewToSign
      "
      @openSignaturePopup="openSignaturePopup"
    ></preview-page>

    <letter-signature-popup
      ref="signaturePopup"
      :letter="letter || {}"
      @reloadPages="reloadPages"
      :isUserSign="true"
    ></letter-signature-popup>
  </div>
</template>
<script>
import PreviewPage from './PreviewPage.vue';
import LetterSignaturePopup from '../components/popup/LetterSignaturePopup.vue';
import { firebase } from '@/services/firebase.service';
import axios from 'axios';

export default {
  components: { PreviewPage, LetterSignaturePopup },
  data: () => {
    return {
      letter: {},
      loadingData: true,
    };
  },
  async mounted() {
    await this.reloadPages();
    if (
      this.letter &&
      this.letter.status === 'sent' &&
      this.isLetterViewToSign
    ) {
      try {
        // update status to viewed by client
        await axios.post(
          `letter/update/${this.$f7route.query.id}`,
          {
            status: 'viewed',
            viewedAt: firebase.firestore.Timestamp.now(),
          },
          {
            baseURL: import.meta.env.VITE_BASE_API,
            withCredentials: true,
            headers: {
              token: this.$f7route.query.token,
            },
          }
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      }
      await this.reloadPages();
    }
  },

  computed: {
    isLetterViewToSign() {
      return this.$f7.views.main.router.currentRoute.path.includes(
        'letter-view'
      );
    },
  },

  methods: {
    openSignaturePopup() {
      this.$refs.signaturePopup.openPopup();
    },
    async reloadPages() {
      this.$f7.preloader.show();
      this.loadingData = true;
      try {
        const response = await axios.get(`letter/${this.$f7route.query.id}`, {
          baseURL: import.meta.env.VITE_BASE_API,
          withCredentials: true,
          headers: {
            token: this.$f7route.query.token,
          },
        });

        if (
          response.status === 200 &&
          response.data.success &&
          response.data.data.isDeleted === false
        ) {
          this.letter = response.data.data;
        } else {
          // eslint-disable-next-line no-console
          console.error(response.data.message);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      } finally {
        this.loadingData = false;
        this.$f7.preloader.hide();
      }
    },
  },
  watch: {
    loadingData(newVal) {
      if (newVal === false) {
        this.$nextTick(() => {
          this.$refs.previewPage.lowResDesktopScreen();
        });
      }
    },
  },
};
</script>
