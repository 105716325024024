export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const PROJECT = 'PROJECT';
export const ACTION_LIST = 'ACTION_LIST';
export const SET_PROJECT_FILTER_FIELD = 'SET_PROJECT_FILTER_FIELD';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';

export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';
export const SET_NUMBER_OF_ROWS = 'SET_NUMBER_OF_ROWS';
export const SET_SEARCH_ORDER = 'SET_SEARCH_ORDER';
export const DELETE_SEARCH_ITEM = 'DELETE_SEARCH_ITEM';
export const RESET_SEARCH = 'RESET_SEARCH';
export const ADD_MORE_RESULT = 'ADD_MORE_RESULT';

export const PROJECT_LIST_ITEM_UPDATE_TOTAL_PHOTO =
  'PROJECT_LIST_ITEM_UPDATE_TOTAL_PHOTO';
export const RESET_DATA = 'RESET_DATA';
