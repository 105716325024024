<template>
  <f7-popup
    class="demo-popup"
    :opened="isShow"
    @popup:closed="cancel"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{ isEdit ? 'Edit Album' : 'Add New Album' }}
        </f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="save()">Next</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list>
        <f7-list-input
          class="first-input"
          placeholder="Album Name"
          label="Album Name"
          :value="albumName"
          @input="albumName = $event.target.value.trim()"
          error-message-force
          clear-button
          :error-message="displayNameErrorMessage"
        >
          <required-asterisk slot="label" />
        </f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { VALIDATION_MESSAGE } from '@/utility/const';
export default {
  components: {},
  props: {
    isShow: Boolean,
    isEdit: Boolean,
    notRedirect: Boolean,
  },
  data: () => ({
    albumName: '',
  }),
  methods: {
    ...mapActions('photo/album', [
      'createProjectPhotoAlbum',
      'updateProjectPhotoAlbum',
    ]),
    cancel() {
      this.albumName = '';
      this.v$.$reset();
      this.$emit('open', false, false);
    },
    save() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        if (this.projectPhotoAlbumList.some(i => i.name === this.albumName)) {
          this.$ri.dialog.openWarningDialog({
            title: 'Please choose another name',
            content: 'That name has already existed!',
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                _sefl.app.dialog.close();
              }
            },
          });
        } else {
          if (this.isEdit) {
            const data = {
              id: this.projectPhotoAlbum.id,
              doc: {
                name: this.albumName,
              },
              projectId: this.project.id,
            };
            this.updateProjectPhotoAlbum(data).then(() => {
              this.$emit('cancelSelect');
              this.cancel();
            });
          } else {
            const data = {
              name: this.albumName,
              projectId: this.project.id,
            };
            this.createProjectPhotoAlbum(data).then(id => {
              if (!this.notRedirect)
                this.$f7router.navigate(`/photo/${this.project.id}/${id}`);
              this.$emit('cancelSelect');
              this.cancel();
            });
          }
        }
      }
    },
    handlePopupOpened() {
      if (!this.isEdit && this.$device.desktop) {
        this.$el.querySelector('.first-input input').focus();
      }
    },
  },
  computed: {
    ...mapGetters('photo/album', [
      'projectPhotoAlbumList',
      'projectPhotoAlbum',
    ]),
    ...mapGetters('photo/project', ['project']),
    displayNameErrorMessage() {
      if (!this.v$.albumName.$error) return null;
      if (this.v$.albumName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },
  watch: {
    projectPhotoAlbum(val) {
      if (this.isEdit && val) {
        this.albumName = val.name;
      }
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  validations() {
    return {
      albumName: {
        required,
      },
    };
  },
};
</script>
