import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { convertDateTimeFromAlgoliaToDate } from '@/utility/date-time-tool';
import _ from 'lodash';
import commonMixin from './common-mixin';

export default {
  mixins: [commonMixin],

  computed: {
    ...mapGetters('invoices/app-constant', ['recurringInvoiceStatusList']),
    isInvoiceGrid() {
      return this.$f7router.currentRoute.path.includes('invoices-group');
    },
  },
  methods: {
    ...mapActions('invoices/recurring-invoices', ['deleteRecurringInvoice']),

    convertDateTimeFromAlgolia(val) {
      if (_.isEmpty(val)) return '';
      return moment(convertDateTimeFromAlgoliaToDate(val)).format('MM/DD/YYYY');
    },

    getRecurringStatus(status) {
      return (
        this.recurringInvoiceStatusList.find(x => x.value === status) || {}
      );
    },

    getRepeatEvery(repeatEvery) {
      if (!repeatEvery) return '';
      let arr = repeatEvery.split('_');
      if (parseFloat(arr[0]) > 1) {
        arr[1] = arr[1] + 's';
      } else {
        arr[0] = '';
      }
      const displayName = `${arr[0]} ${(arr[1] || '')
        .charAt(0)
        .toUpperCase()}${(arr[1] || '').slice(1)}`;
      return displayName;
    },

    nextInvoiceDate(item, isFromAlgolia = false) {
      if (!item.repeatEvery) return '';
      const startDate = item.startOn
        ? isFromAlgolia
          ? convertDateTimeFromAlgoliaToDate(item.startOn)
          : item.startOn.toDate()
        : new Date();
      const endDate = item.endOn
        ? isFromAlgolia
          ? convertDateTimeFromAlgoliaToDate(item.endOn)
          : item.endOn.toDate()
        : new Date();
      if (moment(startDate, 'MM/DD/YYYY').isAfter(moment(), 'day')) {
        return moment(startDate, 'MM/DD/YYYY').format('MM/DD/YYYY');
      }

      let arr = item.repeatEvery.split('_');
      if (arr.length > 1) {
        arr[1] = arr[1] + 's';
      }

      const nextInvoiceDate = moment(startDate, 'MM/DD/YYYY').add(
        parseInt(arr[0]),
        arr[1]
      );
      if (
        !item.neverExpires &&
        item.endOn &&
        nextInvoiceDate.isAfter(moment(endDate), 'day')
      ) {
        return '';
      }
      return nextInvoiceDate.format('MM/DD/YYYY');
    },

    handleDeleteRecurring(item, isDetail = false, callback) {
      this.$ri.dialog.openWarningDialog({
        title: 'Delete Recurring Invoice',
        content: `Are you sure you want to delete recurring invoice ${item.profileName}?`,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$f7.preloader.show();
            this.deleteRecurringInvoice({
              id: item.id,
              isInvoiceGrid: this.isInvoiceGrid,
            }).then(() => {
              this.$f7.preloader.hide();
              if (isDetail) {
                this.$f7router.navigate('/recurring-invoices', {
                  reloadAll: true,
                });
              }
              if (callback) callback();
              this.$f7.toast
                .create({
                  text: 'Recurring invoice was deleted!',
                  closeOnClick: true,
                  closeButton: false,
                  closeTimeout: 5000,
                })
                .open();
            });
          }
        },
      });
    },
  },
};
