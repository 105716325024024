import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { contractDataCalculator } from '../../../utility/contract-calculator';

export default {
  computed: {
    ...mapGetters('dashboard/template', ['buildContractTemplate']),
    ...mapGetters({
      setting: 'setting/app/system/setting',
    }),
    ...mapGetters('dashboard/client', ['insuranceCompany', 'contact']),
    ...mapGetters('dashboard/project', ['card']),
    ...mapGetters('dashboard/contract', ['contract']),

    ...mapGetters('common/app-constant', ['tenantId']),

    contactName() {
      if (!this.card || !this.card.contactId) return '';
      return this.contact
        ? this.contact.firstName + ' ' + this.contact.lastName
        : '';
    },

    contactPhone() {
      if (!this.card || !this.card.contactId) return '';
      const phones = this.contact.phones;
      if (!_.isEmpty(phones) && _.isArray(phones)) {
        let phone = phones.find(item => item.code === 'main');
        phone = phone || phones[0] || [];

        if (!_.isEmpty(phone)) {
          return phone.value;
        }
      }
      return '';
    },

    contactEmail() {
      if (!this.card || !this.card.contactId) return '';
      if (!_.isEmpty(this.contact)) {
        const emails = this.contact.emails;
        if (!_.isEmpty(emails) && _.isArray(emails)) {
          let address = emails.find(item => item.code === 'main');
          address = address || emails[0] || [];

          if (!_.isEmpty(address)) {
            return address.value;
          }
        }
      }

      return '';
    },

    insuranceCompanyName() {
      return this.insuranceCompany.companyName || '';
    },

    insuranceCompanyPhone() {
      const phones = this.insuranceCompany.phones || [];
      if (!_.isEmpty(phones) && _.isArray(phones)) {
        let phone = phones.find(item => item.code === 'main');
        phone = phone || phones[0] || [];

        if (!_.isEmpty(phone)) {
          return phone.value;
        }
      }
      return '';
    },

    isRemoveRoof() {
      return false;
    },

    isReplaceRoof() {
      return false;
    },
  },

  methods: {
    ...mapActions('dashboard/template', ['bindTemplateList']),
    ...mapActions('setting/app/system', ['bindSetting']),

    composeContractData(isNew) {
      const pairs = {};
      for (const key in contractDataCalculator) {
        if (Object.hasOwnProperty.call(contractDataCalculator, key)) {
          const func = contractDataCalculator[key];
          if (typeof func === 'function') {
            pairs[key] = func(this, isNew);
          }
        }
      }
      return pairs;
    },

    parseContractContent(isNew) {
      if (_.isEmpty(this.buildContractTemplate)) {
        return; //TODO: show error message
      }
      const contractSections = [];
      for (const section of this.buildContractTemplate.sections || []) {
        const tempSection = _.cloneDeep(section);

        // build data to replace
        const pairs = this.composeContractData(isNew);

        // replace data
        for (const key in pairs) {
          if (Object.hasOwnProperty.call(pairs, key)) {
            const value = pairs[key];
            const temp = _.cloneDeep(tempSection.htmlValue);
            tempSection.htmlValue = temp.replaceAll(
              `<span style="background-color: #f05034;">[${key}]</span>`,
              value
            );

            if (key === 'Roofing Logo URL') {
              tempSection.htmlValue = temp.replaceAll(`[${key}]`, value);
            }
          }
        }

        contractSections.push(tempSection);
      }
      return contractSections;
    },

    parseSignatureHtmlContent(signatureSection, signerRole, url, fullName) {
      const newHtmlValueSignSection = `<div style="padding: 8px 16px;display: flex;justify-content: space-between;align-items: center;flex-wrap: wrap;">
        <img style="max-height: 40px" src="${url}" /><div>${fullName}</div></div>`;

      const newHtmlValueSignAtSection = `<div style="height: 100%;display: flex;justify-content: center;align-items: center;">
        <span>${moment().format('MM/DD/YYYY')}</span></div>`;

      let htmlValue = _.cloneDeep(signatureSection).htmlValue;
      switch (signerRole) {
        case 'owner':
          htmlValue = htmlValue.replaceAll(
            '<div class="owner-sign">&nbsp;</div>',
            `${newHtmlValueSignSection}`
          );
          htmlValue = htmlValue.replaceAll(
            '<div class="owner-sign-at">&nbsp;</div>',
            `${newHtmlValueSignAtSection}`
          );
          break;
        case 'project-manager':
          htmlValue = htmlValue.replace(
            '<div class="project-manager-sign">&nbsp;</div>',
            `${newHtmlValueSignSection}`
          );
          htmlValue = htmlValue.replaceAll(
            '<div class="project-manager-sign-at">&nbsp;</div>',
            `${newHtmlValueSignAtSection}`
          );
          break;
        case 'contractor':
          htmlValue = htmlValue.replaceAll(
            '<div class="contractor-sign">&nbsp;</div>',
            `${newHtmlValueSignSection}`
          );
          htmlValue = htmlValue.replaceAll(
            '<div class="contractor-sign-at">&nbsp;</div>',
            `${newHtmlValueSignAtSection}`
          );
          break;
      }
      return htmlValue;
    },
  },
};
