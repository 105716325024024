import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  getExcelWorkbook,
  getEstimateSummaryExcelWorkbook,
} from './excel-export';
// import moment from "moment";
function alphabeToNumberic(val) {
  let base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    i,
    j,
    result = 0;

  for (i = 0, j = val.length - 1; i < val.length; i += 1, j -= 1) {
    result += Math.pow(base.length, j) * (base.indexOf(val[i]) + 1);
  }

  return result;
}
function excelAddressToColumnIndex(address) {
  const colIndex = address.replace(/\d/g, '');
  return alphabeToNumberic(colIndex);
}

function excelStyleToPdfStyle(style) {
  const pdfStyle = {};
  if (style) {
    const { alignment, font } = style;
    if (alignment) {
      if (alignment.horizontal) {
        pdfStyle.halign = alignment.horizontal;
      }
    }
    if (font) {
      if (font.bold) {
        pdfStyle.fontStyle = 'bold';
      }
    }
  }
  return pdfStyle;
}

function convertExcelModelToPdfTableBody(worksheetModel) {
  const { rows } = worksheetModel;
  const body = [];
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex];
    const pdfRow = [];
    for (let cellIndex = 0; cellIndex < row.cells.length; cellIndex++) {
      const cell = row.cells[cellIndex];

      const lastPdfCell = [...pdfRow].reverse()[0];
      if (lastPdfCell && lastPdfCell.excelCellAddress == cell.master) {
        lastPdfCell.colSpan++;
        continue;
      }

      if (pdfRow.length === 0) {
        const excelColIndex = excelAddressToColumnIndex(cell.address);
        if (excelColIndex !== 1) {
          pdfRow.push({
            colSpan: excelColIndex - 1,
            rowSpan: 1,
            content: '',
          });
        }
      }

      const pdfCell = {
        content: cell.value,
        colSpan: 1,
        rowSpan: 1,
        excelCellAddress: cell.address,
        styles: excelStyleToPdfStyle(cell.style),
      };
      pdfRow.push(pdfCell);
    }
    body.push(pdfRow);
  }

  return body;
}

function exportWorkOrderToPdf({ headers, values, totals, purchaseOrderInfo }) {
  const workbook = getExcelWorkbook({
    headers,
    values,
    totals,
    purchaseOrderInfo,
  });
  const worksheet = workbook.getWorksheet('Purchase Order');
  const pdfTableBody = convertExcelModelToPdfTableBody(worksheet.model);
  const doc = new jsPDF({
    orientation: 'landscape',
  });
  doc.autoTable({
    body: pdfTableBody,
    theme: 'grid',
  });

  doc.save(`purchase-order-${new Date().getTime()}.pdf`);
}

function exportEstimateSummaryToPdf({ headers, estimateNumber, values }) {
  const workbook = getEstimateSummaryExcelWorkbook({
    headers,
    estimateNumber,
    values,
  });
  const worksheet = workbook.getWorksheet(`${estimateNumber} Summary`);
  const pdfTableBody = convertExcelModelToPdfTableBody(worksheet.model);
  const doc = new jsPDF({
    orientation: 'landscape',
  });
  doc.autoTable({
    body: pdfTableBody,
    theme: 'grid',
  });

  doc.save(`${estimateNumber}-summary-${new Date().getTime()}.pdf`);
}

function exportProtestInsuranceClaimToPdf(data) {
  let doc = new jsPDF({
    orientation: 'p',
    unit: 'px',
    format: 'letter',
    floatPrecision: 0, // or "smart", default is 16
  });
  const htmlContent = data.htmlContent;
  const formatDoc = `<div style="line-height: 1.6;">${htmlContent}</div>`;
  const margins = {
    top: 30,
    bottom: 30,
    left: 30,
    width: 400,
  };
  doc.fromHTML(
    formatDoc,
    margins.left,
    margins.top,
    {
      width: margins.width,
    },
    function () {
      doc.save(`${data.title}.pdf`);
    },
    margins
  );
}

export { exportEstimateSummaryToPdf, exportProtestInsuranceClaimToPdf };

export default exportWorkOrderToPdf;
