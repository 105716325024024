import * as types from './types';
import userService from '../../services/user.service';
import _ from 'lodash';

export default {
  getUserList({ commit }) {
    return userService.getUserList().then(users => {
      commit(types.SET_USERS_LIST, users);
      return;
    });
  },

  getUserNotCommit(context, uid) {
    return userService.getUser(uid);
  },

  getUserEmails() {
    return userService.getUserList().then(users => {
      return _.uniqBy(users, 'email').map(user => user.email);
    });
  },

  checkGroupUsers({ commit, state, rootGetters }, groupLink) {
    const groupListByUserId =
      rootGetters['setting/app/group/groupListByUserId'];
    const activeUsers = state.userList.filter(user => !user.disabled);
    const userList = [];
    activeUsers.forEach(user => {
      const groupList = groupListByUserId(user.uid);
      const isInGroup = groupList.some(
        group => group.menuIDs && group.menuIDs.includes(groupLink)
      );

      if (isInGroup) {
        userList.push(user);
      }
    });
    const result = userList.length > 0 ? userList : [];
    commit('SET_GROUP_USERS', result);
  },
};
