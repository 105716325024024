import { matchProject } from '../../../../utility/filter-tools';
import { applyFilter, groupBy } from '@/utility/filter-tools';
import { STATUS_TIME_TRACKING_WFA } from '@/utility/const';
import { roundNumber } from '../../../../utility/number-tool';

export default {
  objectList: (
    { objectList, teamFilter, statusFilter, timeLogTypeFilter, projectFilter },
    getters
  ) => {
    let list = [...objectList];
    if (teamFilter.length > 0) {
      list = list.filter(r =>
        getters.usersFromTeams.some(u => u.uid == r.user_id)
      );
    }
    if (statusFilter.length > 0) {
      list = list.filter(r => statusFilter.includes(r.status + ''));
    }
    if (timeLogTypeFilter.length > 0) {
      list = list.filter(r => timeLogTypeFilter.includes(r.timeLogType + ''));
    }
    if (projectFilter) {
      list = list.filter(r => matchProject(r.project, projectFilter));
    }
    return list;
  },
  showTimerPopup: ({ showTimerPopup }) => showTimerPopup,
  viewport: ({ viewport }) => viewport,
  teamFilter: ({ teamFilter }) => teamFilter,
  usersFromTeams: (_, { teamFilter }, __, rootGetters) => {
    if (!teamFilter.length) return [];
    let users = rootGetters['time-tracking/user/userListByHours'];
    const teamList = rootGetters['common/team-management/teamList'];
    const teamListFiltered = teamList.filter(r => teamFilter.includes(r.id));
    const employeesIds = teamListFiltered.reduce(
      (a, b) => [...a, ...b.userIDs],
      []
    );
    users = users.filter(user => employeesIds.includes(user.uid));
    return users;
  },
  statusFilter: ({ statusFilter }) => statusFilter,
  timeLogTypeFilter: ({ timeLogTypeFilter }) => timeLogTypeFilter,
  projectFilter: ({ projectFilter }) => projectFilter,
  detailUid: ({ detailUid }) => detailUid,
  isMyTimeLog: ({ isMyTimeLog }) => isMyTimeLog,
  listUserTrackings: (
    _,
    { objectList: timeTrackingList, usersFromTeams },
    __,
    rootGetters
  ) => {
    let users = rootGetters['time-tracking/user/userListByHours'];
    if (usersFromTeams.length > 0) users = [...usersFromTeams];
    const arr = (users || []).map(x => {
      const allItems = timeTrackingList.filter(y => y.user_id === x.uid);
      const awaitingItems = allItems.filter(
        y => y.status === STATUS_TIME_TRACKING_WFA
      );
      const totalHours = timeTrackingList
        .filter(y => y.user_id === x.uid && y.status !== 'tt-rejected')
        .map(
          r =>
            (r.approvedHour
              ? parseFloat(r.approvedHour)
              : r.override
                ? parseFloat(r.overrideHour)
                : r.loggedHour) +
            (r.hasLunchBreak ? r.lunchTimeInfo.hour || 0 : 0)
        )
        // .map(r => Math.round(r * 100) / 100)
        .map(r => roundNumber(r, 3))
        .reduce((a, b) => a + b, 0);

      return {
        uid: x.uid,
        user_id: x.uid,
        displayName: x.displayName,
        photoURL: x.photoURL,
        totals: awaitingItems.length,
        allCount: allItems.length,
        totalHours,
      };
    });
    return arr.sort((a, b) => sortBy(a.displayName, b.displayName));
  },
  listUserTrackingGroup:
    (_, getters) =>
    (searchValue = '') => {
      const filteredUsers = applyFilter(
        getters.listUserTrackings,
        searchValue,
        ['displayName']
      );
      let dataGroup = groupBy(filteredUsers, item =>
        ((item.displayName || '').charAt(0) || '').toUpperCase()
      ).sort((a, b) => sortBy(a.key, b.key));
      return dataGroup.map(i => ({
        ...i,
        data: i.data.sort((a, b) => sortBy(a.displayName, b.displayName)),
      }));
    },
  detail: (_, { objectList, detailUid }) => {
    return objectList.filter(x => x.user_id === detailUid);
  },

  currentWeek: ({ currentWeek }) => currentWeek || [],
  timeLogInfoAvailable: state => state.timeLogInfoAvailable,
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
