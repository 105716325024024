<template>
  <div :class="typeSwitchScreen !== 'step' && 'container-info'">
    <f7-list
      :inset="$device.desktop"
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.INFO
        )
      "
    >
      <div
        :class="
          typeSwitchScreen === 'step' && $device.desktop
            ? 'container-info-step-row'
            : 'container-info-step-column'
        "
      >
        <div
          style="flex: 1"
          :class="typeSwitchScreen !== 'step' && 'container-info-content'"
        >
          <f7-list>
            <f7-list-item header="Field Area (SQ)">
              <f7-input
                slot="title"
                placeholder="Enter Field Area"
                :value="currentBuilding.fieldArea"
                type="number"
                min="0"
                @change="
                  setField(
                    'fieldArea',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_MEMBRANE,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_INSULATION,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="app_badge"
              ></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('fieldArea', 'Area', [
                    SECTION_TYPE_TPO_MEMBRANE,
                    SECTION_TYPE_TPO_INSULATION,
                    SECTION_TYPE_TPO_ACCESSORIES,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            <f7-list-item header="Wall Area (SQ)">
              <f7-input
                slot="title"
                placeholder="Enter Wall Area"
                :value="currentBuilding.wallArea"
                type="number"
                min="0"
                @change="
                  setField(
                    'wallArea',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_MEMBRANE,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_INSULATION,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="rectangle_split_3x1"
              ></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('wallArea', 'Area', [
                    SECTION_TYPE_TPO_MEMBRANE,
                    SECTION_TYPE_TPO_INSULATION,
                    SECTION_TYPE_TPO_ACCESSORIES,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>
          </f7-list>

          <f7-list>
            <!-- attached -->
            <f7-list-input
              type="select"
              label="Membrane Attached"
              :value="currentBuilding.membraneAttached || ''"
              @change="onChangeMembraneAttached($event.target.value)"
              error-message-force
              validate
              validate-on-blur
              :error-message="membraneAttachedErrorMessage"
            >
              <input-icon
                slot="media"
                icon="square_stack_3d_up"
              ></input-icon>
              <option value="">Select membrane attached</option>
              <option
                v-for="item in membraneAttachmentTypeList"
                :key="item.id"
                :value="item.value"
              >
                {{ item.displayName }}
              </option>
            </f7-list-input>
            <!-- thickness -->
            <f7-list-input
              type="select"
              label="Membrane Thickness"
              :value="currentBuilding.membraneThickness || ''"
              @change="
                setField('membraneThickness', $event.target.value);
                updateProducts({
                  roofType: ROOF_TYPE_TPO,
                  sectionId: SECTION_TYPE_TPO_MEMBRANE,
                });
              "
              error-message-force
              validate
              validate-on-blur
              :error-message="membraneThicknessErrorMessage"
            >
              <input-icon
                slot="media"
                icon="expand"
              ></input-icon>
              <option value="">Select membrane thickness</option>
              <option
                v-for="item in membraneThicknessList"
                :key="item.id"
                :value="item.value"
              >
                {{ item.displayName }}
              </option>
            </f7-list-input>

            <f7-list-input
              v-if="
                currentBuilding.membraneAttached ===
                ATTACHMENT_TYPE_TPO_MEMBRANE_MA
              "
              type="select"
              label="Fastening Pattern"
              :value="currentBuilding.fasteningPattern || ''"
              @change="
                setField('fasteningPattern', $event.target.value);
                updateQuantity({
                  roofType: ROOF_TYPE_TPO,
                  sectionId: SECTION_TYPE_TPO_MEMBRANE,
                });
              "
            >
              <input-icon
                slot="media"
                icon="map"
              ></input-icon>
              <option
                value=""
                hidden
              >
                Select fastener on center
              </option>
              <option
                v-for="item in fasteningPatternList"
                :key="item.id"
                :value="item.value"
              >
                {{ item.displayName }}
              </option>
            </f7-list-input>
          </f7-list>
        </div>
        <div
          style="flex: 1"
          :class="typeSwitchScreen !== 'step' && 'container-info-content'"
        >
          <f7-list>
            <f7-list-item
              title="Overflow Drain"
              name="demo-checkbox"
            >
              <input-icon
                slot="media"
                icon="flowchart"
              ></input-icon>
              <f7-toggle
                :checked="overflowDrain"
                @toggle:change="onChangeOverflowDrain"
              ></f7-toggle>
            </f7-list-item>
            <f7-list-item
              width="100"
              header="Number of Drain"
            >
              <f7-input
                slot="title"
                type="number"
                min="0"
                step="1"
                :value="currentBuilding.numberOfDrain || 0"
                @change="
                  setField(
                    'numberOfDrain',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  /*updateProducts({
                roofType: ROOF_TYPE_TPO,
                sectionId: SECTION_TYPE_TPO_INSULATION
              });

              updateProducts({
                roofType: ROOF_TYPE_TPO,
                sectionId: SECTION_TYPE_TPO_MEMBRANE
              });*/

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="number_square"
              ></input-icon>
              <span
                slot="header"
                class="input-title-desc"
              >
                (Need to double if Overflow Drain is checked)
              </span>
            </f7-list-item>
          </f7-list>

          <f7-list>
            <f7-list-item
              title="Overflow Scuppers"
              name="demo-checkbox"
            >
              <input-icon
                slot="media"
                icon="tray"
              ></input-icon>
              <f7-toggle
                :checked="overflowScuppers"
                @toggle:change="onChangeOverflowScuppers"
              ></f7-toggle>
            </f7-list-item>
            <f7-list-item
              width="100"
              header="Number of Scuppers"
            >
              <f7-input
                slot="title"
                type="number"
                min="0"
                step="1"
                :value="currentBuilding.numberOfScuppers || 0"
                @change="
                  setField(
                    'numberOfScuppers',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="number_square"
              ></input-icon>
              <span
                slot="header"
                class="input-title-desc"
              >
                (Need to double if Overflow Scuppers is checked)
              </span>
            </f7-list-item>
          </f7-list>

          <f7-list>
            <f7-list-item
              width="100"
              header="Number of Plumbing Penetrations"
            >
              <f7-input
                slot="title"
                type="number"
                min="0"
                step="1"
                :value="currentBuilding.numberOfPlumbingPenetrations || 0"
                @change="
                  setField(
                    'numberOfPlumbingPenetrations',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="layers"
              ></input-icon>
            </f7-list-item>

            <f7-list-item
              width="100"
              header="Termination Bar (LF)"
            >
              <f7-input
                slot="title"
                type="number"
                min="0"
                step="1"
                :value="currentBuilding.terminationBar || 0"
                @change="
                  setField(
                    'terminationBar',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="squares_below_rectangle"
              ></input-icon>
            </f7-list-item>
          </f7-list>
        </div>
      </div>
    </f7-list>

    <section-product-table
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.TABLE
        )
      "
      :items="tableData(SECTION_TYPE_TPO_MEMBRANE)"
      :roofType="ROOF_TYPE_TPO"
      :sectionId="SECTION_TYPE_TPO_MEMBRANE"
      @onChangeProductItem="
        onChangeProductItem(ROOF_TYPE_TPO, SECTION_TYPE_TPO_MEMBRANE, $event)
      "
    ></section-product-table>

    <section-product-table
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.TABLE
        )
      "
      :items="tableData(SECTION_TYPE_TPO_ACCESSORIES)"
      :roofType="ROOF_TYPE_TPO"
      :sectionId="SECTION_TYPE_TPO_ACCESSORIES"
      @onChangeProductItem="
        onChangeProductItem(ROOF_TYPE_TPO, SECTION_TYPE_TPO_ACCESSORIES, $event)
      "
    ></section-product-table>
    <!-- Takeoff select value -->
    <dynamic-single-select
      ref="selectValue"
      search-placeholder="Search in Takeoffs"
      title="Takeoffs from StackCT"
      :options="takeoffListOptions"
      @changed="changeBuildingValue"
    >
    </dynamic-single-select>
  </div>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
import SectionProductTable from '../tables/SectionProductTable.vue';
import DynamicSingleSelect from '../inputs/DynamicSingleSelect.vue';

import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import {
  ROOF_TYPE_TPO,
  SECTION_TYPE_TPO_MEMBRANE,
  SECTION_TYPE_TPO_INSULATION,
  SECTION_TYPE_TPO_ACCESSORIES,
  ATTACHMENT_TYPE_TPO_MEMBRANE_MA,
  CONTENT_TYPE_EST,
} from '../../../../utility/const';

import mixins from '../../utility/mixins';
import buildingMixins from '../../utility/building-mixins';
import { checkShowContentScreenSingleInEstimate } from '@/utility/common';

export default {
  components: {
    InputIcon,
    SectionProductTable,
    DynamicSingleSelect,
  },

  props: {
    showContentType: {
      type: String,
      default: 'all',
    },
  },

  mixins: [mixins, buildingMixins],

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {
    currentBuilding: {
      membraneAttached: {
        required,
      },
      membraneThickness: {
        required,
      },
    },
  },

  data() {
    return {
      ROOF_TYPE_TPO,
      SECTION_TYPE_TPO_MEMBRANE,
      SECTION_TYPE_TPO_INSULATION,
      SECTION_TYPE_TPO_ACCESSORIES,
      ATTACHMENT_TYPE_TPO_MEMBRANE_MA,
      CONTENT_TYPE_EST,

      overflowDrain: false,
      overflowScuppers: false,
    };
  },

  computed: {
    ...mapGetters('common/app-constant', [
      'membraneThicknessList',
      'membraneAttachmentTypeList',
      'fasteningPatternList',
    ]),
    ...mapGetters('estimate/estimate-page/estimate', [
      'currentBuilding',
      'typeSwitchScreen',
    ]),

    membraneAttachedErrorMessage() {
      // if (!this.v$.currentBuilding.$error) return "";
      // if (!this.v$.currentBuilding.membraneAttached.required)
      //   return "This field is required";
      return '';
    },

    membraneThicknessErrorMessage() {
      // if (!this.v$.currentBuilding.$error) return "";
      // if (!this.v$.currentBuilding.membraneThickness.required)
      //   return "This field is required";
      return '';
    },
  },

  methods: {
    init() {
      this.overflowDrain = this.currentBuilding.overflowDrain || false;
      this.overflowScuppers = this.currentBuilding.overflowScuppers || false;
    },
    checkShowContentScreenSingleInEstimate,

    changeBuildingValue(property, value, sections = []) {
      if (!property) return;
      this.setField(property, value || 0);
      sections.forEach(sectionId => {
        this.updateProducts({
          sectionId,
          roofType: ROOF_TYPE_TPO,
        });
      });
    },

    openSelectValue(propery, type, sections) {
      this.$refs.selectValue.openSelectPopup(propery, type, sections);
    },

    onChangeOverflowDrain(value) {
      this.overflowDrain = value;
      this.saveBuildingValue('overflowDrain', value);
    },

    onChangeOverflowScuppers(value) {
      this.overflowScuppers = value;
      this.saveBuildingValue('overflowScuppers', value);
    },

    validate() {
      this.v$.$touch();
      //disble validate for this step
      return false;
      //return this.v$.$invalid;
    },

    onChangeMembraneAttached(value) {
      this.setField('membraneAttached', value);
      if (value === ATTACHMENT_TYPE_TPO_MEMBRANE_MA) {
        this.setField(
          'fasteningPattern',
          this.currentBuilding.fasteningPattern || 'oc-06'
        );
      }

      this.updateProducts({
        roofType: ROOF_TYPE_TPO,
        sectionId: SECTION_TYPE_TPO_MEMBRANE,
      });
    },
  },
};
</script>

<style lang="scss">
.item-input select.input-invalid {
  color: var(--f7-color-text-neutral) !important;
}

.container-info-step-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 16px;
}

.container-info-step-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 5px;
}

.container-info {
  background-color: var(--f7-color-bg-dark);
  padding: 8px 0 8px 0;
}

.container-info-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
