<template>
  <f7-page>
    <!-- page header  -->
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link
      ></f7-nav-left>
      <f7-nav-title>Contact Details</f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="editContact">Edit</f7-link>
      </f7-nav-right>
    </f7-navbar>
    <!-- tabs -->
    <f7-block>
      <f7-segmented
        strong
        tag="p"
      >
        <f7-button
          :active="activeTab === 'contact-detail'"
          @click.native="activeTab = 'contact-detail'"
          >Info</f7-button
        >
        <f7-button
          :active="activeTab === 'contact-notes'"
          @click.native="activeTab = 'contact-notes'"
          >Notes</f7-button
        >
        <f7-button
          :active="activeTab === 'contact-email'"
          @click.native="activeTab = 'contact-email'"
          >Emails</f7-button
        >
        <f7-button
          :active="activeTab === 'contact-history'"
          @click.native="activeTab = 'contact-history'"
          >History</f7-button
        >
      </f7-segmented>
    </f7-block>
    <!-- tabs content -->
    <div v-show="activeTab === 'contact-detail'">
      <contact-detail-tab ref="contactDetailTab"></contact-detail-tab>
    </div>
    <div v-show="activeTab === 'contact-notes'">
      <contact-notes-tab></contact-notes-tab>
    </div>
    <div v-show="activeTab === 'contact-email'">
      <contact-email-tab ref="contactEmailTab"></contact-email-tab>
    </div>
    <div v-show="activeTab === 'contact-history'">
      <contact-history-tab ref="contactHistoryTab"></contact-history-tab>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ContactDetailTab from './tabs/ContactDetailTab';
import ContactNotesTab from './tabs/ContactNotesTab';
import ContactHistoryTab from './tabs/ContactHistoryTab';
import ContactEmailTab from './tabs/ContactEmailTab';

export default {
  data: () => {
    return { activeTab: 'contact-detail' };
  },

  async created() {
    this.$f7.preloader.show();
    this.activeTab =
      (this.$f7route.query && this.$f7route.query.activeTab) ||
      'contact-detail';
    // if (!this.isEditContact) {
    await this.getContactById(this.$f7route.params.contactId);

    this.$refs.contactEmailTab.initData();
    this.$refs.contactHistoryTab.initData();
    // }
    this.$f7.preloader.hide();
  },

  components: {
    ContactDetailTab,
    ContactNotesTab,
    ContactHistoryTab,
    ContactEmailTab,
  },

  computed: {
    ...mapGetters('contact-book/contact', [
      'contact',
      // "isEditContact",
      'isNewContact',
    ]),
  },

  methods: {
    ...mapActions('contact-book/contact', [
      'getContactById',
      // "setIsEditContact",
      'clearContact',
    ]),
    ...mapActions('common/common', ['resetEmailList']),

    editContact() {
      // this.setIsEditContact(true);
      this.$f7router.navigate(
        `/contactbook/contact/edit/${this.$f7route.params.contactId}`
      );
    },

    handleBack() {
      this.$f7router.navigate('/contactbook/contacts');
      this.clearContact('');
    },
  },

  beforeDestroy() {
    this.clearContact('');
    this.resetEmailList();
  },
};
</script>

<style lang="scss" scoped>
.scroll-bar {
  height: calc(100%);

  &::v-deep .ps__rail-y {
    visibility: hidden;
  }
}
</style>
