<template>
  <f7-popup
    :opened="popupOpened"
    @popup:close="() => closePopup()"
    @popup:opened="handlePopupOpened"
  >
    <f7-page v-if="detail">
      <f7-navbar>
        <f7-nav-left>
          <f7-link
            v-if="isDraft"
            popup-close
            >Cancel</f7-link
          >
          <f7-link
            v-else
            @click.native="onClickClose"
            >Close</f7-link
          >
        </f7-nav-left>
        <f7-nav-title>{{
          isDraft ? 'Add New Job/Project' : this.detail.title || 'Card details'
        }}</f7-nav-title>
        <f7-nav-right>
          <f7-link
            v-if="isDraft"
            @click="saveDraftCard"
            >Save</f7-link
          >
        </f7-nav-right>
      </f7-navbar>

      <card-detail-tabs
        :isDraft="isDraft"
        ref="cardDetailTabs"
        @closePopup="closePopup"
      ></card-detail-tabs>
    </f7-page>
  </f7-popup>
</template>

<script>
import CardDetailTabs from '../tabs/CardDetailTabs.vue';

import { mapMutations, mapGetters, mapActions } from 'vuex';
import dashboardService from '../../../../services/dashboard.service';
import backActionsMixin from '../../mixin/backActions';
import _ from 'lodash';

export default {
  mixins: [backActionsMixin],
  components: { CardDetailTabs },

  data() {
    return { activeButton: 'information', dueDate: [] };
  },

  computed: {
    ...mapGetters({
      detail: 'swimlane/card/detail',
      currentBoard: 'swimlane/board/currentBoard',
    }),
    ...mapGetters('setting/app/profile', ['user']),

    isDraft() {
      return this.detail && this.detail.isDraft;
    },

    popupOpened() {
      return !!this.detail;
    },
  },

  methods: {
    ...mapActions('swimlane/finance', ['resetDataInvoice']),

    ...mapMutations({
      setCardDetailId: 'swimlane/card/setCardDetailId',
    }),

    ...mapActions({
      update: 'swimlane/card/update',
      delete: 'swimlane/card/delete',
    }),

    ...mapActions('swimlane/client', [
      'getContact',
      'getCompany',
      'getProperty',
      'setContact',
      'setCompany',
      'setProperty',
    ]),

    clearData() {
      this.dueDate = [];
    },

    onClickClose() {
      if (this.currentRouteIsScheduling) {
        this.handleBackToScheduling();
      }
      this.closePopup();
    },

    closePopup(callback, ignoreDeleteDraft) {
      if (!this.popupOpened) return;
      this.$f7route.query = {};
      const url = this.$f7router.generateUrl({
        name: `${this.$f7route.route.meta.businessCode}-board`,
        params: {
          boardNumber: this.currentBoard.boardNumber,
        },
      });
      if (callback) {
        callback();
      }
      !ignoreDeleteDraft && this.tryDeleteDraftCard();
      this.$f7router.updateCurrentUrl(url);
      this.setCardDetailId(null);

      this.clearData();
      this.setContact({});
      this.setCompany({});
      this.setProperty({});
      this.resetDataInvoice();
    },

    tryDeleteDraftCard() {
      if (this.isDraft) {
        this.delete(this.detail.id);
      }
    },

    saveDraftCard() {
      this.$f7.preloader.show();
      dashboardService
        .updateCardDoc(this.user.tenantId, this.detail.id, {
          isDraft: false,
          businessCode: this.detail.businessCode,
        })
        .then(() => {
          this.closePopup(null, true);
          this.$f7.preloader.hide();
        });
    },

    handlePopupOpened() {
      if (this.isDraft && this.$device.desktop) {
        this.$refs.cardDetailTabs.$el
          .querySelector('.first-input input')
          .focus();
      }
    },

    async initClientData() {
      const refs = [];
      if (!_.isEmpty(this.detail)) {
        if (this.detail.contactId) {
          refs.push(this.getContact(this.detail.contactId));
        }
        if (this.detail.companyId) {
          refs.push(this.getCompany(this.detail.companyId));
        }
        if (this.detail.propertyId) {
          refs.push(this.getProperty(this.detail.propertyId));
        }
      }

      return Promise.all(refs);
    },
  },
  watch: {
    popupOpened(val) {
      if (val) {
        this.$f7.preloader.show();
        this.initClientData().then(() => this.$f7.preloader.hide());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-bar {
  height: calc(100%);
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}
</style>
