import Vue from 'vue';
import * as types from './types';

export default {
  setPriceListItemList(state, payload) {
    Vue.set(state, 'priceListItemList', payload);
  },
  setPriceListItemSubscribe(state, payload) {
    Vue.set(state, 'priceListItemSubscribe', payload);
  },
  [types.SET_PRICE_LIST_LIST](state, payload) {
    Vue.set(state, 'priceListItemList', payload);
  },
};
