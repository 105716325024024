import axios from 'axios';

const httpClient = axios.create({
  baseURL: 'https://gmail.googleapis.com/gmail/v1/',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
});

var refreshAccessToken = () => {};

// Request interceptor for API calls
httpClient.interceptors.request.use(
  async config => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}`,
    };
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

///Response interceptor for API calls
httpClient.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await refreshAccessToken();
      originalRequest.headers.Authorization =
        'Bearer ' + localStorage.getItem('gapi.access_token');
      return await httpClient(originalRequest);
    }
    return Promise.reject(error);
  }
);

const gmailClient = {
  get: async function (url, cbRefreshToken, options) {
    refreshAccessToken = cbRefreshToken;
    return await httpClient.get(url, options);
  },

  post: async function (url, data, cbRefreshToken, options) {
    refreshAccessToken = cbRefreshToken;
    return await httpClient.post(url, data, options);
  },
};

const getListMessages = (query = '', maxResults = 20, cbRefreshToken) => {
  let url = `/users/me/messages?`;
  if (query) {
    url += `q=${query}`;
  }
  url += `&maxResults=${maxResults}`;
  return gmailClient.get(url, cbRefreshToken);
};

const getListThreads = (query = '', maxResults = 20, cbRefreshToken) => {
  let url = `/users/me/threads?`;
  if (query) {
    url += `q=${query}`;
  }
  url += `&maxResults=${maxResults}`;
  return gmailClient.get(url, cbRefreshToken);
};

const getDetailMessage = (id, cbRefreshToken) => {
  let url = `/users/me/messages/${id}`;
  return gmailClient.get(url, cbRefreshToken);
};

const getMessagesByThread = (id, cbRefreshToken) => {
  let url = `/users/me/threads/${id}`;
  return gmailClient.get(url, cbRefreshToken);
};

const isLoggedInGmail = () => {
  return !!localStorage.getItem('gapi.access_token');
};

const getAttachment = (messageId, id, cbRefreshToken) => {
  let url = `users/me/messages/${messageId}/attachments/${id}`;
  return gmailClient.get(url, cbRefreshToken);
};

const sendMessage = (data, cbRefreshToken) => {
  let options = {
    headers: {
      'Content-Type': 'message/rfc822',
    },
  };
  let url = '/users/me/messages/send';
  return gmailClient.post(url, data, cbRefreshToken, options);
};
const gmailApi = {
  getListMessages,
  getDetailMessage,
  getListThreads,
  getMessagesByThread,
  isLoggedInGmail,
  getAttachment,
  sendMessage,
};

export default gmailApi;
