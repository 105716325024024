<template>
  <f7-row>
    <f7-col :width="$device.desktop ? 70 : 100">
      <f7-block-header>Pre-Construction</f7-block-header>
      <f7-list
        :class="{
          'list-inline-center': $device.desktop,
          'list-inline-center-mobile': !$device.desktop,
        }"
        inset
        inline-labels
      >
        <f7-list-item>
          <div class="title">Manufactures</div>
          <div class="content">
            {{
              project.manufacturesApproved === 'approved-design-system'
                ? 'Approved Design System'
                : project.manufacturesApproved === 'proposed-alternative-system'
                  ? 'Proposed Alternative System'
                  : ''
            }}
          </div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Approve/Proposed Date</div>
          <div class="content">
            {{ toDisplayDateString(project.manufacturesApprovedDate) }}
          </div>
        </f7-list-item>
      </f7-list>
      <f7-list
        :class="{
          'list-inline-center': $device.desktop,
          'list-inline-center-mobile': !$device.desktop,
        }"
        inset
        inline-labels
      >
        <f7-list-item>
          <div class="title">Client</div>
          <div class="content">
            {{
              project.clientApproved === 'approved-design-system'
                ? 'Approved Design System'
                : project.clientApproved === 'approved-alternative-system'
                  ? 'Approved Alternative System'
                  : ''
            }}
          </div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Approve Date</div>
          <div class="content">
            {{ toDisplayDateString(project.clientApprovedDate) }}
          </div>
        </f7-list-item>
      </f7-list>
      <f7-block-header>Construction</f7-block-header>
      <f7-list
        :class="{
          'no-margin-bottom': !$device.desktop,
          'list-inline-center': $device.desktop,
          'list-inline-center-mobile': !$device.desktop,
        }"
        inset
        inline-labels
      >
        <f7-list-item>
          <div class="title">Status</div>
          <div class="content">
            <f7-chip
              slot="after"
              :text="constructionStatus.title"
              :color="constructionStatus.color"
            ></f7-chip>
          </div>
        </f7-list-item>
      </f7-list>
    </f7-col>
    <f7-col :width="$device.desktop ? 30 : 100">
      <f7-block-header>Post-Construction</f7-block-header>
      <f7-list
        :class="{ 'no-margin-top': !$device.desktop }"
        class="list-inline-right"
        inset
        inline-labels
      >
        <f7-list-item>
          <div class="title">Project Completed Date</div>
          <div class="content">
            {{ toDisplayDateString(project.completedDate) }}
          </div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Payment</div>
          <div class="content">
            {{
              project.paymentStatus === PAYMENT_STATUS_PUT_A_LIEN
                ? 'Put a Lien'
                : project.paymentStatus === PAYMENT_STATUS_RECEIVED_PAYMENT
                  ? 'Received Payment'
                  : ''
            }}
          </div>
        </f7-list-item>
      </f7-list>
    </f7-col>
  </f7-row>
</template>
<script>
import {
  PAYMENT_STATUS_PUT_A_LIEN,
  PAYMENT_STATUS_RECEIVED_PAYMENT,
} from '@/utility/const';
import { toDisplayDateString } from '@/utility/datetime';

export default {
  props: {
    project: Object,
    action: Object,
  },
  data() {
    return {
      PAYMENT_STATUS_PUT_A_LIEN,
      PAYMENT_STATUS_RECEIVED_PAYMENT,
      toDisplayDateString,
    };
  },
  computed: {
    constructionStatus() {
      const board = this.action.boards && this.action.boards[0];
      return board === 'sales' || board === 'pre-construction'
        ? { title: 'Not Started', color: 'gray' }
        : board === 'post-construction'
          ? { title: 'Completed', color: 'green' }
          : { title: 'In Progress', color: 'blue' };
    },
  },
};
</script>
<style lang="scss" scoped>
.list-inline-center {
  ::v-deep .item-inner {
    gap: 16px;
    .title {
      flex: 1;
    }
    .content {
      flex: 2;
    }
  }
}
.list-inline-right,
.list-inline-center-mobile {
  ::v-deep .item-inner {
    gap: 16px;
    .title {
      flex: 1;
    }
    .content {
      flex: 1;
    }
  }
}
</style>
