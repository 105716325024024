import axiosService from './axios.service';
import 'firebase/auth';
import { firebase } from './firebase.service';

const auth = firebase.auth();

const addPriceListDoc = (tenantId, data) => {
  data.createdBy = auth.currentUser
    ? auth.currentUser.displayName || auth.currentUser.email
    : '';
  data.id = '';
  data.isDeleted = false;
  return axiosService.post('/price-list/add', { tenantId, data }).then(res => {
    return res.data;
  });
};

export default {
  addPriceListDoc,
};
