<template>
  <div>
    <f7-list style="margin-top: 5px">
      <f7-list-item
        link
        @click.native="openSelectBuildings"
      >
        <div
          class="list-item-title"
          slot="title"
        >
          <span>
            {{
              (data.buildingIds || []).length > 0
                ? buildingNames
                : 'Select Buildings'
            }}
          </span>
        </div>
        <input-icon
          slot="media"
          icon="building_2_fill"
          style="margin-top: 0"
        ></input-icon>
      </f7-list-item>
      <dynamic-muti-select
        ref="selectBuildings"
        search-placeholder="Search in buildings"
        title="Select buildings"
        :value="data.buildingIds || []"
        :options="buildingListOptions"
        @changed="changeBuildings"
      >
      </dynamic-muti-select>
    </f7-list>
    <table>
      <tr>
        <th
          v-for="(column, index) in headers"
          :class="`
              ${textAlign(column.align)}
              ${column.value}`"
          :key="index"
        >
          {{ column.text }}
        </th>
      </tr>
      <tr
        v-for="(item, index) in displayProductList"
        :key="index"
      >
        <td
          :key="column.value"
          v-for="column in headers"
          :class="`
              ${textAlign(column.align)}`"
        >
          <!-- amount/tax -->
          <div v-if="column.value === 'amount' || column.value === 'taxAmount'">
            {{ ((item || {})[column.value] || 0) | currencyUSD }}
          </div>
          <!-- total cost -->
          <div v-else-if="column.value === 'totalCost'">
            {{ summaryTotal | currencyUSD }}
          </div>
          <!-- others -->
          <span v-else>{{
            item[column.value] || (column.type == 'number' ? 0 : '')
          }}</span>
        </td>
      </tr>
      <!-- footer if table is labor and material-->
      <template
        v-if="proposal.tableContentType === TABLE_CONTENT_TYPE_LABOR_MATERIAL"
      >
        <tr>
          <td :colspan="headers.length - 2"><b>Sub Total</b></td>
          <td
            colspan="2"
            class="text-align-end"
          >
            <b>{{ summarySubTotal | currencyUSD }}</b>
          </td>
        </tr>
        <tr>
          <td :colspan="headers.length - 2"><b>Tax Amount</b></td>
          <td
            colspan="2"
            class="text-align-end"
          >
            <b>{{ summaryTotalTax | currencyUSD }}</b>
          </td>
        </tr>
        <tr>
          <td :colspan="headers.length - 2"><b>Total</b></td>
          <td
            colspan="2"
            class="text-align-end"
          >
            <b>{{ summaryTotal | currencyUSD }}</b>
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InputIcon from '@/components/icons/InputIcon.vue';
import DynamicMutiSelect from '../inputs/DynamicMultiSelect.vue';
import calcEstimateMixin from '../../mixins/calc-estimate-mixin';
import _ from 'lodash';
import {
  TABLE_CONTENT_TYPES,
  TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
  TABLE_CONTENT_TYPE_LABOR_MATERIAL,
} from '@/utility/const';

export default {
  mixins: [calcEstimateMixin],
  props: {
    data: Object,
  },
  data() {
    return {
      TABLE_CONTENT_TYPES,
      TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
      TABLE_CONTENT_TYPE_LABOR_MATERIAL,
    };
  },
  components: {
    InputIcon,
    DynamicMutiSelect,
  },
  computed: {
    ...mapGetters({
      proposal: 'proposal/proposal/proposal',
    }),
    headers() {
      let headers = [
        {
          text: 'Item Details',
          align: 'left',
          sortable: false,
          value: 'productName',
          showInDetail: true,
        },
        {
          text: 'Amount',
          align: 'right',
          sortable: false,
          value: 'amount',
          showInDetail: true,
        },
        {
          text: 'Tax Amount',
          align: 'right',
          sortable: false,
          value: 'taxAmount',
          showInDetail: true,
        },
      ];
      if (
        this.proposal.tableContentType === TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
      ) {
        headers.push({
          text: 'Total Cost',
          align: 'right',
          sortable: false,
          value: 'totalCost',
          showInDetail: true,
        });
      }
      return headers;
    },
    displayProductList() {
      const { project, estimate } = this.proposal;
      const itemDetails =
        this.summaryEstimate(project, _.cloneDeep(this.data.buildings)) || [];
      return this.proposal.tableContentType ===
        TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
        ? this.displaySimpleSummary(estimate, itemDetails)
        : this.displayLaborMaterial(estimate, itemDetails);
    },
    buildingNames() {
      return (this.data.buildings || []).map(r => r.buildingName).join(', ');
    },
    buildingList() {
      return (this.proposal.estimate.buildings || []).filter(
        r => !(this.proposal.buildingIDs || []).includes(r.buildingId)
      );
    },
    buildingListOptions() {
      return this.buildingList.map(r => ({
        id: r.buildingId,
        value: r.buildingName,
      }));
    },
    summarySubTotal() {
      return this.displayProductList.reduce(
        (accumulator, item) => accumulator + parseFloat(item.amount || 0),
        0
      );
    },

    summaryTotalTax() {
      return this.displayProductList.reduce(
        (accumulator, item) => accumulator + parseFloat(item.taxAmount || 0),
        0
      );
    },
    summaryTotal() {
      return this.summarySubTotal + this.summaryTotalTax;
    },
  },
  methods: {
    ...mapActions({
      setProposalField: 'proposal/proposal/setProposalField',
    }),
    openSelectBuildings() {
      this.$refs.selectBuildings.openSelectPopup();
    },
    changeBuildings(values) {
      const buildingIDs = _.cloneDeep(this.proposal.buildingIDs).concat(values);
      this.setProposalField({
        fieldName: 'buildingIDs',
        value: [...new Set(buildingIDs)],
      });
      this.$emit('change', { ...this.data, buildingIds: values });
    },
    textAlign(align) {
      return align === 'left'
        ? 'text-align-left'
        : align === 'center'
          ? 'text-align-center'
          : align === 'right'
            ? 'text-align-right'
            : 'text-align-left';
    },
  },
};
</script>

<style lang="scss" scoped>
.change-visible-columns {
  height: 30px;
  margin-left: auto;
  margin-right: 10px;
}
.select-items {
  width: 10px;
  height: 0px;
  position: absolute;
  visibility: hidden;
  right: 67px;
  top: -18px;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
table td ul {
  padding-inline-start: 20px;
  margin-block-end: 0;
  margin-block-start: 0;
}
table td {
  word-break: break-word;
}
.text-align-end {
  text-align: end;
}
</style>
