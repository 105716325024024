export default {
  assemblyTemplate: {},
  assemblyTemplateList: [],
  order: 'desc',
  searchValue: '',

  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0,
};
