import * as types from './types';
import Vue from 'vue';
import _ from 'lodash';

export default {
  [types.SET_SEARCH_RESULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(
      state,
      'hits',
      hits.map(r => {
        delete r._highlightResult;
        delete r.objectID;
        return r;
      })
    );
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.SET_NUMBER_OF_ROWS](state, payload) {
    Vue.set(state, 'hitsPerPage', payload);
  },
  [types.RESET_SEARCH](state) {
    Vue.set(state, 'order', 'desc');

    Vue.set(state, 'hits', []);
    Vue.set(state, 'hitsPerPage', 25);
    Vue.set(state, 'nbHits', 0);
    Vue.set(state, 'nbPages', 0);
    Vue.set(state, 'page', 0);
  },
  [types.ADD_MORE_RESULT](state, { hits, hitsPerPage, nbHits, nbPages, page }) {
    state.hits.push(...hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },
  [types.CONTRACT_LIST_ITEM_UPDATE](state, { id, doc }) {
    const index = state.hits.findIndex(item => item.id === id);
    if (index >= 0) {
      const contract = _.cloneDeep(state.hits[index]);

      if (
        doc.status &&
        state.selectedStatusFilter.length > 0 &&
        !state.selectedStatusFilter.includes(doc.status)
      ) {
        state.hits.splice(index, 1);
      } else {
        for (const key in doc) {
          if (Object.hasOwnProperty.call(doc, key) && doc[key] !== undefined) {
            contract[key] = doc[key];
          }
        }
        Vue.set(state.hits, index, contract);
      }
    }
  },
  [types.SET_SELECTED_STATUS_FILTER](state, payload) {
    Vue.set(state, 'selectedStatusFilter', payload);
  },
};
