<template>
  <div
    class="kanban-container"
    :style="{
      height:
        $device.desktop || $device.ipad
          ? 'calc(100% - 64px)'
          : 'calc(100% - 140px)',
    }"
  >
    <div
      v-for="(column, index) in sortedColumns"
      :key="column.id"
      class="column-width kanban-column-container"
    >
      <header-editable
        :value="column.name"
        :column-id="column.id"
        :column-index="index"
        :max-columns="sortedColumns.length"
        @delete="onDeleteColumn(column.id)"
        @edit="onEditColumn(column)"
        @move-left="moveColumn(column.id, index - 1)"
        @move-right="moveColumn(column.id, index + 1)"
      />
      <footer-add-new-card
        @add="onAddCardClick(column.id)"
        slot="footer"
      />
      <draggable
        :list="cardsInColumn(column.id)"
        :animation="200"
        class="column-content-container"
        ghost-class="ghost-card"
        :delay="$device.desktop ? 0 : 100"
        :delayOnTouchOnly="$device.desktop ? true : false"
        :touchStartThreshold="20"
        group="tasks"
        draggable=".item"
        @change="e => onDndChange(e, column.id)"
      >
        <task-card
          v-for="task in cardsInColumn(column.id)"
          :key="task.id"
          :task="task"
          class="item"
          @openCard="openCard(task)"
        ></task-card>
      </draggable>
    </div>
    <div class="column-width kanban-column-container">
      <header-add-new-column @add="openInputColumnName"></header-add-new-column>
    </div>
    <add-new-job-popup
      ref="addNewJobPopup"
      @openCard="openCard($event)"
    />
    <card-detail-popup />
  </div>
</template>

<script>
import { firebase, auth } from '../../../../services/firebase.service';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import draggable from 'vuedraggable';
import _ from 'lodash';

import TaskCard from '../TaskCard.vue';
import HeaderEditable from '../inputs/HeaderEditable.vue';
import HeaderAddNewColumn from '../inputs/HeaderAddNewColumn.vue';
import FooterAddNewCard from '../inputs/FooterAddNewCard.vue';
import CardDetailPopup from '../popup/CardDetailPopup.vue';
import AddNewJobPopup from '../popup/AddNewJobPopup.vue';
const PRIORITY_STEPER = 1000;

function calculateNewPriorityForItemInList(list, index) {
  if (!list || !list.length) {
    return 0;
  }
  if (index === 0) {
    const first = list[0];
    return first.priority - PRIORITY_STEPER;
  }
  if (list.length <= index) {
    const last = list.slice().pop();
    return (last.priority || 0) + PRIORITY_STEPER;
  }
  const previous = list[index - 1];
  const next = list[index];
  return ((previous.priority + next.priority) * 1.0) / 2;
}

export default {
  components: {
    TaskCard,
    draggable,
    HeaderEditable,
    HeaderAddNewColumn,
    FooterAddNewCard,
    CardDetailPopup,
    AddNewJobPopup,
  },

  props: {
    boardId: { type: String, default: () => null },
  },

  methods: {
    ...mapActions({
      createColumn: 'swimlane/column/create',
      deleteColumn: 'swimlane/column/delete',
      updateColumn: 'swimlane/column/update',
      updateCard: 'swimlane/card/update',
    }),
    ...mapMutations({
      setCardDetailId: 'swimlane/card/setCardDetailId',
    }),

    openInputColumnName() {
      const _sefl = this;
      _sefl.$f7.dialog.prompt('Input column name', null, name => {
        const maxColumnsPriority =
          Math.max(...[..._sefl.columns.map(r => r.priority), 0]) || 0;
        _sefl.createColumn({
          name,
          priority: maxColumnsPriority + 1,
          boardId: _sefl.boardId,
        });
      });
    },

    onEditColumn(column) {
      const _sefl = this;
      _sefl.$f7.dialog.prompt(
        'Input column name',
        null,
        name => {
          _sefl.updateColumn({
            id: column.id,
            doc: {
              name,
            },
          });
        },
        null,
        column.name
      );
    },

    cardsInColumn(columnId) {
      const cards = this.cards.filter(
        r => !r.isDraft && r.columnId == columnId
      );
      return cards.slice().sort((a, b) => a.priority - b.priority);
    },
    onDeleteColumn(id) {
      const cards = this.cardList.filter(r => !r.isDraft && r.columnId == id);
      if (cards.length > 0) {
        this.$ri.dialog.openInfoDialog({
          title: 'Delete column',
          content: 'Before you delete this column, ensure it is empty.',
          hideCancelButton: true,
          textButton: 'Ok',
          onClick: (_sefl, index) => {
            if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      } else {
        const app = this;
        this.$ri.dialog.openInfoDialog({
          title: 'Delete column',
          content: 'Are you sure you want to delete this column?',
          textButton: 'Delete',
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              app.deleteColumn(id);
              _sefl.app.dialog.close();
            }
          },
        });
      }
    },

    async onAddCardClick(columnId) {
      const minPriorityInColumn =
        Math.min(
          ...[...this.cardsInColumn(columnId).map(r => r.priority), 0]
        ) || 0;

      let actionsHistory = this.calculateActionHistory(null, columnId);

      this.$f7router.updateCurrentUrl(
        `/dashboard/swimlane/${this.$f7route.route.meta.businessCode}/${this.currentBoard.boardNumber}/new`
      );
      this.$refs.addNewJobPopup.open({
        priority: minPriorityInColumn - 1000,
        columnId: columnId,
        boardId: this.boardId,
        status: 'open',
        actionsHistory,
        businessCode: this.$f7route.route.meta.businessCode,
        isDraft: false,
      });
    },

    async onDndChange(dndEvent, columnId) {
      const eventKeys = Object.keys(dndEvent);
      for (let index = 0; index < eventKeys.length; index++) {
        const key = eventKeys[index];
        const event = dndEvent[key];
        switch (key) {
          case 'added':
            await this.addItemToColumn(event.element, columnId, event.newIndex);
            break;
          case 'removed':
            this.removeItemFromColumn(event.element, columnId, event.oldIndex);
            break;
          case 'moved':
            await this.moveItem(
              event.element,
              columnId,
              event.newIndex,
              event.oldIndex
            );
            break;
        }
      }
    },

    async addItemToColumn(item, columnId, index) {
      const itemsInColumns = this.cardsInColumn(columnId);
      const newPriority = calculateNewPriorityForItemInList(
        itemsInColumns,
        index
      );

      let actionsHistory = this.calculateActionHistory(item, columnId);

      await this.updateCard({
        id: item.id,
        doc: {
          columnId,
          priority: newPriority,
          actionsHistory,
        },
      });
    },

    async moveItem(item, columnId, newIndex) {
      const itemsInColumns = this.cardsInColumn(columnId).filter(
        r => r.id !== item.id
      );
      const newPriority = calculateNewPriorityForItemInList(
        itemsInColumns,
        newIndex
      );
      await this.updateCard({
        id: item.id,
        doc: {
          columnId,
          priority: newPriority,
        },
      });
    },

    /**
     * Add action history if the card is moved from a column to another column
     */
    calculateActionHistory(project, actionId) {
      // For create new project
      if (project === null) {
        return [
          {
            nextAction: this.action(actionId).name,
            previousAction: 'Job Created',
            resolvedAt: firebase.firestore.Timestamp.now(),
            resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
            resolvedById: auth.currentUser.uid,
          },
        ];
      }

      const currentCard = this.cardList.find(r => r.id === project.id);

      let actionsHistory = _.cloneDeep(currentCard.actionsHistory) || [];

      actionsHistory.push({
        nextAction: this.action(actionId).name,
        previousAction: this.action(project.columnId).name,
        resolvedAt: firebase.firestore.Timestamp.now(),
        resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
        resolvedById: auth.currentUser.uid,
      });

      return actionsHistory;
    },

    removeItemFromColumn() {},

    openCard(task) {
      const url = this.$f7router.generateUrl({
        name: `${this.$f7route.route.meta.businessCode}-card-detail`,
        params: {
          boardNumber: this.currentBoard.boardNumber,
          cardNumber: task.cardNumber,
        },
      });
      this.$f7router.updateCurrentUrl(url);
      this.setCardDetailId(task.id);
    },

    async moveColumn(columnId, newIndex) {
      const columnsWithoutCurrentColumn = this.sortedColumns.filter(
        r => r.id !== columnId
      );
      const newPriority = calculateNewPriorityForItemInList(
        columnsWithoutCurrentColumn,
        newIndex
      );
      await this.updateColumn({
        id: columnId,
        doc: {
          priority: newPriority,
        },
      });
    },
  },

  computed: {
    ...mapGetters({
      columns: 'swimlane/column/objectList',
      cardList: 'swimlane/card/objectList',
      cards: 'swimlane/card/filterdObject',
      action: 'swimlane/column/action',
      currentBoard: 'swimlane/board/currentBoard',
    }),

    sortedColumns() {
      return this.columns.slice().sort((a, b) => a.priority - b.priority);
    },
  },
};
</script>

<style scoped>
.kanban-container {
  display: flex;
  background-color: var(--f7-card-bg-color);
  overflow-x: auto;
}

.column-width {
  min-width: 320px;
  width: 320px;
}
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px dashed;
}

.kanban-column-container {
  display: flex;
  flex-direction: column;
  margin: 6px;
  background-color: var(--f7-page-bg-color);
  border-radius: var(--f7-card-border-radius);
  /* border: 1px solid; */
  box-shadow: var(--f7-elevation-1);
}
.column-content-container {
  height: 100%;
  overflow-y: auto;
}
</style>
