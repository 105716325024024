<template>
  <f7-popup
    tablet-fullscreen
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="cancel"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Metal Preview</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="getPDF">Download PDF</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div
        v-if="item"
        ref="htmlContent"
        class="page-content-pdf"
      >
        <div
          class="page-container"
          style="
            width: 1020px;
            min-height: 1320px;
            margin: auto;
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 30px 50px;
            background-color: white;
            box-shadow: 0 4px 4px rgba(87, 100, 126, 0.21);
            box-sizing: border-box;
            border-color: #e5e7f2;
            border-radius: 2px;
            font-size: 14px;
            padding-bottom: 60px;
            position: relative;
            color: #000;
          "
        >
          <!-- header -->
          <div
            class="page-header row align-items-flex-end"
            style="
              max-height: 150px;
              display: flex;
              border-bottom: solid 1px black;
              padding-bottom: 8px;
              margin-bottom: 20px;
            "
          >
            <div
              v-show="setting.logo"
              class="col-25"
            >
              <div class="logo display-flex">
                <img
                  :src="setting.logo"
                  width="150"
                />
              </div>
            </div>
            <div class="col-45">
              <div class="text-info bold">
                <span>{{ setting.companyName }}</span>
              </div>
              <div class="text-info">
                <span>{{ setting.address }}</span>
              </div>
              <div class="text-info">
                <span>{{ setting.phoneNumber }}</span>
              </div>
            </div>
            <div
              class="col-30 text-align-end"
              style="font-weight: 600"
            >
              {{ (assemblyItem.displayName || '').toUpperCase() }}
            </div>
          </div>
          <!-- diagram -->
          <div
            class="page-canvas"
            style="border: 1px solid gray; max-height: 1049px"
          >
            <img
              :src="urlBase64"
              style="width: 100%; max-height: 1049px"
            />
          </div>
          <!-- info -->
          <div
            class="page-info"
            style="margin: 20px 0"
          >
            <f7-block-title class="no-margin-horizontal">
              <u>Measurement</u>
            </f7-block-title>
            <f7-block class="no-padding-horizontal">
              {{ measurementInfo }}
            </f7-block>
          </div>
          <!-- footer -->
          <div
            class="page-footer"
            style="
              position: absolute;
              bottom: 30px;
              right: 50px;
              left: 50px;
              height: 20px;
              display: flex;
              padding-top: 8px;
              justify-content: space-between;
              border-top: solid 1px black;
            "
          >
            <div>
              {{ setting.address }}
            </div>
            <div class="text-align-end">
              {{ setting.phoneNumber }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>Not found proposal</div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import jsPDF from 'jspdf';
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

import { generateImage } from '../../../../services/utils';

export default {
  props: {
    item: { type: Object, default: () => {} },
    index: [Number, String],
    assemblyList: Array,
  },

  data: () => {
    return {
      popupOpened: false,
      urlBase64: null,
    };
  },
  mounted() {
    if (_.isEmpty(this.setting)) {
      this.bindSetting(this.tenantId);
    }
  },
  computed: {
    ...mapGetters({
      setting: 'setting/app/system/setting',
    }),

    assemblyItem() {
      return this.assemblyList.find(r => r.id === this.item.assemblyItem) || {};
    },

    ...mapGetters('common/app-constant', ['tenantId']),

    measurementInfo() {
      if (_.isEmpty(this.item) || _.isEmpty(this.item.measurement)) return '';
      let arr = [];
      let measurementKeys = Object.keys(this.item.measurement);
      measurementKeys = measurementKeys.filter(r => !r.includes('nail'));
      measurementKeys = measurementKeys.sort((a, b) => sortBy(a, b));
      for (let i of measurementKeys) {
        arr.push(`${i} = ${this.item.measurement[i]}`);
      }
      return arr.join(', ');
    },
  },

  methods: {
    ...mapActions({
      bindSetting: 'setting/app/system/bindSetting',
    }),
    open(urlBase64) {
      this.urlBase64 = urlBase64;
      this.popupOpened = true;
    },
    cancel() {
      this.popupOpened = false;
    },
    async getPDF() {
      var doc = new jsPDF();
      const pages =
        this.$refs.htmlContent.getElementsByClassName('page-container');
      this.$f7.preloader.show();

      for (let i = 0; i < pages.length; i++) {
        const canvasHtml = pages[i].outerHTML;
        const image = await generateImage(canvasHtml);

        if (!_.isEmpty(image)) {
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();
          if (i > 0) {
            doc.addPage('letter');
          }
          doc.setPage(i + 1);
          doc.addImage(image, 'PNG', 0, 0, width, height, i, 'FAST');
          doc.setTextColor(0, 0, 0);
          doc.setFontSize(10);
          const pageNumber = i + 1;
          const pageNumberString = `${pageNumber}`;
          const xPosition = width / 2;
          doc.text(pageNumberString, xPosition, height - 8);
        }
      }
      this.$f7.preloader.hide();
      doc.save(
        `${this.$f7route.params.estimateNumber}_${this.$f7route.params.buildingName}_${this.assemblyItem.displayName}.pdf`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.text-align-end {
  text-align: end;
}
</style>
