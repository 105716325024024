import { checkAuth } from '../../../services/authentication.service';

export default [
  // Setting
  {
    path: '',
    title: 'Settings',
    name: 'settings-main',
    displayRoles: ['owner', 'user'],
    routes: [
      // For personal setting
      {
        path: '/settings/profile',
        title: 'Account Profile',
        name: 'settings-profile',
        displayRoles: ['owner', 'user'],
        disableInGroup: true,
        async: checkAuth(() => import('../pages/Profile.vue')),
      },
      // For company setting
      {
        path: '/settings/general',
        title: 'General',
        parent: 'settings-main',
        displayRoles: ['owner', 'user'],
        name: 'settings-general',
        icon: 'gear_alt',
        async: checkAuth(() => import('../pages/general/General.vue')),
      },
      // {
      //   path: "/settings/general/edit",
      //   checkAccessPath: "settings-main/settings-general",
      //   name: "settings-general",
      //   displayRoles: ["owner", "user"],
      //   async: checkAuth(() => import("../pages/general/GeneralEdit.vue"))
      // }
      // {
      //   path: "/integration",
      //   title: "Integration",
      //   displayRoles: ["owner", "swadley"],
      //   icon: "",
      //   async: checkAuth(() => import("../pages/Integration.vue"))
      // }
    ],
  },
];
