<template>
  <div class="scheduling-form">
    <!-- employee -->
    <f7-list
      v-if="!isMyScheduling"
      media-list
    >
      <f7-list-item
        link
        @click.native="openSelectEmployees"
      >
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Employees<required-asterisk />
        </div>
        <div
          class="list-item-title"
          slot="title"
        >
          {{
            (userIds || []).length > 0 ? userByIds(userIds) : 'Select employees'
          }}
        </div>
        <input-icon
          slot="media"
          icon="person"
        ></input-icon>
        <div
          slot="text"
          style="color: red"
        >
          {{ usersErrorMessage }}
        </div>
      </f7-list-item>
    </f7-list>

    <!-- time log type -->
    <f7-list v-if="isShowDetail">
      <f7-list-item
        header="Time Log Type"
        :title="object.timeLogType"
      >
        <required-asterisk slot="header" />
        <f7-icon
          color="red"
          slot="media"
          f7="square_fill_line_vertical_square"
        ></f7-icon>
      </f7-list-item>
    </f7-list>
    <f7-list
      v-else
      media-list
    >
      <f7-list-item :disabled="isHasMultipleEmployeesInChain && isMyScheduling">
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Time Log Type<required-asterisk />
        </div>
        <f7-input
          slot="title"
          type="select"
          :value="object.timeLogType"
          @change="onChangeObject('timeLogType', $event.target.value)"
        >
          <option
            v-for="item in timeLogTypeList"
            :key="item.id"
            :value="item.value"
          >
            {{ item.displayName }}
          </option>
        </f7-input>
        <input-icon
          slot="media"
          icon="square_on_circle"
        ></input-icon>
        <div
          slot="text"
          style="color: red"
        >
          {{ timeLogTypeErrorMessage }}
        </div>
      </f7-list-item>
    </f7-list>

    <!-- project/timeoff/title -->
    <div v-if="isShowDetail">
      <f7-list v-if="object.timeLogType === TIME_LOG_TYPE_PROJECT">
        <f7-list-item
          header="Project/Job Name"
          :title="project && (project.title || '')"
        >
          <required-asterisk slot="header" />
          <f7-icon
            color="red"
            slot="media"
            f7="cube_box"
          ></f7-icon>
        </f7-list-item>
      </f7-list>
      <f7-list v-else-if="object.timeLogType === TIME_LOG_TYPE_TIME_OFF">
        <f7-list-item
          header="Time off"
          :title="object.timeOffType"
        >
          <required-asterisk slot="header" />
          <f7-icon
            color="red"
            slot="media"
            f7="cube_box"
          ></f7-icon>
        </f7-list-item>
      </f7-list>
      <f7-list v-else>
        <f7-list-item
          header="Title"
          :title="object.title"
        >
          <required-asterisk slot="header" />
          <f7-icon
            color="red"
            slot="media"
            f7="pencil_ellipsis_rectangle"
          ></f7-icon>
        </f7-list-item>
      </f7-list>
    </div>
    <div v-else>
      <f7-list
        v-if="object.timeLogType === TIME_LOG_TYPE_PROJECT"
        media-list
      >
        <f7-list-item
          link
          @click.native="selectProject"
          :disabled="isHasMultipleEmployeesInChain && isMyScheduling"
        >
          <div
            class="list-item-inner-start"
            slot="inner-start"
          >
            Project/Job Name<required-asterisk />
          </div>
          <div
            class="list-item-title"
            slot="title"
          >
            {{ project && project.id ? project.title : 'Select project/job' }}
          </div>
          <input-icon
            slot="media"
            icon="cube_box"
          ></input-icon>
          <div
            slot="text"
            style="color: red"
          >
            {{ projectErrorMessage }}
          </div>
        </f7-list-item>
      </f7-list>

      <f7-list
        v-else-if="object.timeLogType === TIME_LOG_TYPE_TIME_OFF"
        media-list
      >
        <f7-list-item
          :disabled="isHasMultipleEmployeesInChain && isMyScheduling"
        >
          <div
            class="list-item-inner-start"
            slot="inner-start"
          >
            Time off<required-asterisk />
          </div>
          <f7-input
            slot="title"
            type="select"
            :value="object.timeOffType"
            @change="onChangeObject('timeOffType', $event.target.value)"
          >
            <option
              v-for="item in timeOffTypeList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.displayName }}
            </option>
          </f7-input>
          <input-icon
            slot="media"
            icon="cube_box"
          ></input-icon>
          <div
            slot="text"
            style="color: red"
          >
            {{ timeOffTypeErrorMessage }}
          </div>
        </f7-list-item>
      </f7-list>

      <f7-list
        v-else
        media-list
      >
        <f7-list-item
          :disabled="isHasMultipleEmployeesInChain && isMyScheduling"
        >
          <div
            class="list-item-inner-start"
            slot="inner-start"
          >
            Title<required-asterisk />
          </div>
          <f7-input
            slot="title"
            type="text"
            placeholder="Enter Title"
            :value="object.title"
            @input="onChangeObject('title', $event.target.value.trim())"
          ></f7-input>
          <input-icon
            slot="media"
            icon="pencil_ellipsis_rectangle"
          ></input-icon>
          <div
            slot="text"
            style="color: red"
          >
            {{ titleErrorMessage }}
          </div>
        </f7-list-item>
      </f7-list>
    </div>

    <!-- date -->
    <div
      v-if="!endVsDueDate()"
      class="display-flex align-items-center padding-horizontal margin-bottom-half warning"
    >
      <f7-icon
        f7="exclamationmark_triangle_fill"
        color="yellow"
        class="icon-font-size margin-right-half"
      ></f7-icon>
      {{
        `Start Date to End Date is greater than the due date of the project (Due date: ${dueDateOfProject})`
      }}
    </div>

    <f7-row
      v-if="$device.desktop"
      class="margin-bottom"
    >
      <f7-col width="50">
        <input-date
          title="Start Date"
          :disabled="
            isShowDetail ||
            editType === 'thisShiftAndAllRemaining' ||
            editType === 'thisShift'
          "
          :errorMessage="startDateErrorMessage"
          :date="chainObject.startDate"
          @input="onChangeChainObject('startDate', $event)"
        ></input-date>
      </f7-col>
      <f7-col width="50">
        <input-date
          title="End Date"
          :disabled="isShowDetail || editType === 'thisShift'"
          :errorMessage="endDateErrorMessage"
          :date="chainObject.endDate"
          @input="onChangeChainObject('endDate', $event)"
        ></input-date>
      </f7-col>
    </f7-row>
    <div
      v-else
      class="margin-bottom"
    >
      <input-date
        title="Start Date"
        :disabled="
          isShowDetail ||
          editType === 'thisShiftAndAllRemaining' ||
          editType === 'thisShift'
        "
        :errorMessage="startDateErrorMessage"
        :date="chainObject.startDate"
        @input="onChangeChainObject('startDate', $event)"
      ></input-date>
      <input-date
        title="End Date"
        :disabled="isShowDetail || editType === 'thisShift'"
        :errorMessage="endDateErrorMessage"
        :date="chainObject.endDate"
        @input="onChangeChainObject('endDate', $event)"
      ></input-date>
    </div>

    <!-- select repeat date -->
    <div
      v-if="!isShowSelectDay() && editType !== 'thisShift'"
      class="margin-bottom padding-horizontal"
    >
      <f7-icon
        f7="repeat"
        color="red"
        class="icon-repeat"
      ></f7-icon>

      <div
        v-for="(day, index) in DAYS_OF_WEEK"
        :key="index"
        :class="`select-day-item ${
          isDayActive(day.id) ? 'select-day-item_active' : ''
        }`"
        @click="!isShowDetail && selectDay(day.id)"
      >
        <span>{{ day.value }}</span>
      </div>
      <div
        v-if="chainObject.daysSelected.length === 0"
        class="error-message"
      >
        Please select repeat days of week
      </div>
    </div>

    <!-- time -->
    <f7-row v-if="$device.desktop">
      <f7-col width="50">
        <input-time
          :readonly="isShowDetail"
          title="Start"
          icon="clock"
          :errorMessage="startTimeErrorMessage"
          :time="object.startTime"
          @input="onChangeObject('startTime', $event)"
        ></input-time>
      </f7-col>

      <f7-col width="50">
        <input-time
          :readonly="isShowDetail"
          title="Finish"
          icon="timer"
          :errorMessage="finishTimeErrorMessage"
          :time="object.finishTime"
          @input="onChangeObject('finishTime', $event)"
        ></input-time>
      </f7-col>
    </f7-row>
    <div v-else>
      <input-time
        :readonly="isShowDetail"
        title="Start"
        icon="clock"
        :errorMessage="startTimeErrorMessage"
        :time="object.startTime"
        @input="onChangeObject('startTime', $event)"
      ></input-time>
      <input-time
        :readonly="isShowDetail"
        title="Finish"
        icon="timer"
        :errorMessage="finishTimeErrorMessage"
        :time="object.finishTime"
        @input="onChangeObject('finishTime', $event)"
      ></input-time>
    </div>

    <!-- toggle email -->
    <f7-list media-list>
      <f7-list-item>
        <div class="toggle">
          <div class="toggle-content">
            <input-icon
              slot="media"
              icon="envelope"
            ></input-icon>
            <p>Notification of change by email:</p>
          </div>
          <div>
            <f7-toggle
              color="#f05034"
              @change="onToggleChange($event)"
              checked
            ></f7-toggle>
          </div>
        </div>
      </f7-list-item>
    </f7-list>

    <!-- notes -->
    <f7-list media-list>
      <f7-list-item>
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Notes
        </div>
        <f7-input
          :readonly="isShowDetail"
          type="textarea"
          :placeholder="!isShowDetail ? 'Enter Notes...' : ''"
          :value="object.notes"
          @input="onChangeObject('notes', $event.target.value)"
        ></f7-input>
        <input-icon
          slot="media"
          icon="square_pencil"
        ></input-icon>
      </f7-list-item>
    </f7-list>

    <!-- color -->
    <f7-list media-list>
      <f7-list-item>
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Color
        </div>
        <div>
          <div
            class="color-item"
            v-for="(color, index) in colors"
            :key="index"
            :style="`background-color: ${color}`"
            @click="!isShowDetail && onChangeObject('color', color)"
          >
            <f7-icon
              v-show="color === object.color"
              class="check-item"
              f7="checkmark"
              color="white"
            ></f7-icon>
          </div>
        </div>
        <input-icon
          slot="media"
          icon="circle_fill"
          :color="object.color"
        ></input-icon>
      </f7-list-item>
    </f7-list>

    <project-list-popup
      ref="selectProject"
      :isHiddenAddNew="false"
      @onSelected="handleSelectProject"
      @onAddNew="isShowAddProject = true"
    ></project-list-popup>
    <dynamic-muti-select
      ref="selectEmployees"
      search-placeholder="Search employee"
      title="Select Employees"
      :value="userIds"
      :options="userOptions"
      @changed="userIds = $event"
    ></dynamic-muti-select>
    <add-new-project
      :isShow="isShowAddProject"
      @close="isShowAddProject = false"
      @onCreated="handleProjectCreated"
    ></add-new-project>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProjectListPopup from '@/components/popups/ProjectListPopup.vue';
import AddNewProject from '../popups/AddNewProject.vue';
import InputIcon from '@/components/icons/InputIcon.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import InputTime from '@/plugins/scheduling/components/inputs/InputTime.vue';
import InputDate from '../inputs/InputDate.vue';
import DynamicMutiSelect from '../inputs/DynamicMultiSelect.vue';
import moment from 'moment';
import _ from 'lodash';
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

import {
  TIME_LOG_TYPE_PROJECT,
  TIME_LOG_TYPE_TIME_OFF,
  TIME_LOG_TYPE_OTHERS,
  TIME_OFF_TYPE_PTO,
  DAYS_OF_WEEK,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';

const colors = [
  '#ff3b30',
  '#4cd964',
  '#2196f3',
  '#ff2d55',
  '#ffcc00',
  '#9c27b0',
  '#673ab7',
  '#5ac8fa',
  '#009688',
  '#cddc39',
  '#ff6b22',
  '#8e8e93',
];

function defaultColor() {
  return colors[Math.floor(Math.random() * colors.length)];
}
const TIME_FORMAT = 'HH:mm';

export default {
  props: {
    isEdit: Boolean,
    isShow: Boolean,
    isInvalid: Boolean,
  },

  components: {
    InputIcon,
    ProjectListPopup,
    InputTime,
    InputDate,
    DynamicMutiSelect,
    AddNewProject,
  },

  data: () => {
    return {
      TIME_LOG_TYPE_PROJECT,
      TIME_LOG_TYPE_TIME_OFF,
      TIME_LOG_TYPE_OTHERS,
      TIME_OFF_TYPE_PTO,
      DAYS_OF_WEEK,

      isShowProjectListPopup: false,
      isShowAddProject: false,
      isHasMultipleEmployeesInChain: false,

      userIds: [],

      object: {
        userId: '',
        projectId: null,
        startTime: '',
        finishTime: '',
        notes: '',
        color: defaultColor(),
        timeLogType: TIME_LOG_TYPE_PROJECT,
        timeOffType: TIME_OFF_TYPE_PTO,
        title: '',
      },
      chainObject: {
        daysSelected: ['Mo', 'Tu', 'Th', 'We', 'Fr'],
        startDate: [new Date()],
        endDate: [new Date()],
      },
    };
  },

  methods: {
    ...mapActions('scheduling/project', ['getProject', 'resetProject']),

    clearData() {
      this.isShowProjectListPopup = false;
      this.isShowAddProject = false;
      this.isHasMultipleEmployeesInChain = false;
      this.userIds = [];
      this.object = {
        userId: '',
        projectId: null,
        startTime: '',
        finishTime: '',
        notes: '',
        color: defaultColor(),
        timeLogType: TIME_LOG_TYPE_PROJECT,
        timeOffType: TIME_OFF_TYPE_PTO,
        title: '',
      };
      this.chainObject = {
        daysSelected: ['Mo', 'Tu', 'Th', 'We', 'Fr'],
        startDate: [new Date()],
        endDate: [new Date()],
      };
    },

    onToggleChange(event) {
      this.$emit('onToggleChange', event.target.checked);
    },

    openSelectEmployees() {
      this.$refs.selectEmployees.openSelectPopup();
    },

    onChangeObject(key, val) {
      this.object[key] = val;
    },

    onChangeChainObject(key, val) {
      this.chainObject[key] = val;
      const startDate = moment(this.chainObject.startDate[0]).format(
        'YYYY-MM-DD'
      );
      const endDate = moment(this.chainObject.endDate[0]).format('YYYY-MM-DD');
      if (key === 'startDate' && moment(endDate).isBefore(startDate, 'day')) {
        this.chainObject.endDate = val;
      }
    },

    selectProject() {
      this.$refs.selectProject.open();
    },

    async handleSelectProject(id) {
      await this.getProject(id);
      this.onChangeObject('projectId', id);
    },

    validateDueDate(date) {
      if (
        this.object.timeLogType === 'project' &&
        !_.isEmpty(this.project.dueDate)
      ) {
        const dueDate = moment(this.project.dueDate.toDate()).format(
          'YYYY-MM-DD'
        );
        return !moment(dueDate).isBefore(date, 'day');
      }
      return true;
    },

    endVsDueDate() {
      return this.validateDueDate(
        moment(this.chainObject.endDate[0]).format('YYYY-MM-DD')
      );
    },

    isDayActive(day) {
      return this.chainObject.daysSelected.includes(day);
    },

    selectDay(day) {
      const days = _.cloneDeep(this.chainObject.daysSelected);
      if (!days.includes(day)) {
        this.chainObject.daysSelected.push(day);
      } else {
        this.chainObject.daysSelected = days.filter(r => r !== day);
      }
    },

    isShowSelectDay() {
      const weekOfStartDate = moment(
        this.chainObject.startDate[0],
        'MM/DD/YYYY'
      ).startOf('isoWeek');
      const weekOfEndDate = moment(
        this.chainObject.endDate[0],
        'MM/DD/YYYY'
      ).startOf('isoWeek');
      return weekOfStartDate.isSame(weekOfEndDate);
    },
    handleProjectCreated(id) {
      this.handleSelectProject(id);
      setTimeout(() => this.$refs.selectProject.handlePopupClosed(), 500);
    },
  },

  computed: {
    ...mapGetters('scheduling/chain', ['chain']),
    ...mapGetters('scheduling/project', ['project']),
    ...mapGetters('scheduling/user', [
      'userById',
      'filterUserList',
      'userByIds',
    ]),
    ...mapGetters('scheduling/scheduling', [
      'selectedItem',
      'filterBySelectedItem',
      'scheduleId',
      'schedule',
      'isMyScheduling',
      'editType',
      'isShowDetail',
      'userIdsByChainIdAndDate',
    ]),

    ...mapGetters('scheduling/app-constant', [
      'timeLogTypeList',
      'timeOffTypeList',
    ]),

    userOptions() {
      return (this.filterUserList || [])
        .sort((a, b) => sortBy(a.displayName, b.displayName))
        .map(r => ({ ...r, id: r.uid, value: r.displayName }));
    },

    dueDateOfProject() {
      return moment(this.project.dueDate.toDate()).format('MM/DD/YYYY');
    },

    colors() {
      return colors;
    },

    projectErrorMessage() {
      if (!this.v$.object.$error) return '';
      if (this.v$.object.projectId.projectRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    startTimeErrorMessage() {
      if (!this.v$.object.$error) return '';
      if (this.v$.object.startTime.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.object.startTime.existedStartTime.$invalid && this.isInvalid)
        return VALIDATION_MESSAGE.OVERLAP_DETECTED;
      if (this.v$.object.startTime.sameTime.$invalid)
        return VALIDATION_MESSAGE.START_FINISH_OVERLAP;
      return '';
    },
    finishTimeErrorMessage() {
      if (!this.v$.object.$error) return '';
      if (this.v$.object.finishTime.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.object.finishTime.finishTimeRule.$invalid)
        return VALIDATION_MESSAGE.FINISH_GREATER_THAN_START;
      if (
        this.v$.object.finishTime.existedFinishTime.$invalid &&
        this.isInvalid
      )
        return VALIDATION_MESSAGE.OVERLAP_DETECTED;
      if (this.v$.object.finishTime.sameTime.$invalid)
        return VALIDATION_MESSAGE.START_FINISH_OVERLAP;
      return '';
    },
    startDateErrorMessage() {
      if (this.v$.chainObject.startDate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    endDateErrorMessage() {
      if (this.v$.chainObject.endDate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.chainObject.endDate.dateRule.$invalid)
        return VALIDATION_MESSAGE.END_DATE_GREATER_THAN_START_DATE;
      return null;
    },
    timeLogTypeErrorMessage() {
      if (!this.v$.object.$error) return '';
      if (this.v$.object.timeLogType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    timeOffTypeErrorMessage() {
      if (!this.v$.object.$error) return '';
      if (this.v$.object.timeOffType.timeOffTypeRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    titleErrorMessage() {
      if (!this.v$.object.$error) return '';
      if (this.v$.object.title.titleRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    usersErrorMessage() {
      if (!this.v$.$error) return '';
      if (this.v$.userIds.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    // date rule
    const startDate = moment(this.chainObject.startDate[0]).format(
      'YYYY-MM-DD'
    );
    const endDate = moment(this.chainObject.endDate[0]).format('YYYY-MM-DD');
    const dateRule = () => {
      return !moment(endDate).isBefore(startDate, 'day');
    };

    // time rule
    const start = moment(this.object.startTime, TIME_FORMAT);
    const end = moment(this.object.finishTime, TIME_FORMAT);
    const sameTime = () => {
      return !start.isSame(end);
    };
    const finishTimeRule = () => {
      return !end.isBefore(start);
    };
    let list = [];
    if (
      moment(this.selectedItem.date, 'MM/DD/YYYY').isSame(startDate, 'day') &&
      moment(this.selectedItem.date, 'MM/DD/YYYY').isSame(endDate, 'day') &&
      this.selectedItem.userId === this.userIds[0] &&
      this.userIds.length === 1
    ) {
      list = _.cloneDeep(this.filterBySelectedItem);
    }
    if (this.isEdit) {
      list = list.filter(r => r.id !== this.scheduleId);
    }
    const existedStartTime = () => {
      if (list.length > 0) {
        for (const item of list) {
          const startTime = moment(item.startTime, TIME_FORMAT);
          const finishTime = moment(item.finishTime, TIME_FORMAT);
          if (
            startTime.isBetween(start, end) ||
            start.isBetween(startTime, finishTime) ||
            start.isSame(startTime)
          ) {
            return false;
          }
        }
      }
      return true;
    };
    const existedFinishTime = () => {
      if (list.length > 0) {
        for (const item of list) {
          const startTime = moment(item.startTime, TIME_FORMAT);
          const finishTime = moment(item.finishTime, TIME_FORMAT);
          if (
            finishTime.isBetween(start, end) ||
            end.isBetween(startTime, finishTime) ||
            end.isSame(finishTime)
          ) {
            return false;
          }
        }
      }
      return true;
    };

    // project rule
    const projectRule = () => {
      if (
        this.object.timeLogType === TIME_LOG_TYPE_PROJECT &&
        !this.object.projectId
      )
        return false;
      return true;
    };
    // time off type rule
    const timeOffTypeRule = () => {
      if (
        this.object.timeLogType === TIME_LOG_TYPE_TIME_OFF &&
        !this.object.timeOffType
      )
        return false;
      return true;
    };
    // title rule
    const titleRule = () => {
      if (
        this.object.timeLogType === TIME_LOG_TYPE_OTHERS &&
        !this.object.title
      )
        return false;
      return true;
    };
    return {
      userIds: {
        required(val) {
          if (!val.length) return false;
          return true;
        },
      },
      object: {
        projectId: {
          projectRule,
        },
        startTime: {
          required,
          existedStartTime,
          sameTime,
        },
        finishTime: {
          required,
          finishTimeRule,
          existedFinishTime,
          sameTime,
        },
        timeLogType: {
          required,
        },
        timeOffType: {
          timeOffTypeRule,
        },
        title: {
          titleRule,
        },
      },
      chainObject: {
        startDate: {
          required,
        },
        endDate: {
          required,
          dateRule,
        },
      },
    };
  },

  watch: {
    userIds: {
      handler(val) {
        this.$emit('onChangeUserIds', val);
      },
      deep: true,
    },
    object: {
      handler(val) {
        this.$emit('onChangeObject', val);
      },
      deep: true,
    },

    chainObject: {
      handler(val) {
        this.$emit('onChangeChainObject', val);
      },
      deep: true,
    },

    isShow(val) {
      if (val) this.resetProject();
      //create new schedule
      if (val && !this.isEdit) {
        const projectId = localStorage.getItem('projectIdService') || '';

        this.object = {
          ...this.object,
          startTime: '08:00',
          finishTime: '17:00',
          userId: '',
        };
        //create new schedule from service card: auto populate project
        if (projectId) {
          this.object.projectId = projectId;
          this.getProject(projectId);
        }

        if (!_.isEmpty(this.selectedItem)) {
          this.userIds = this.selectedItem.userId
            ? [this.selectedItem.userId]
            : [];
          this.chainObject.startDate = this.selectedItem.date
            ? [new Date(this.selectedItem.date)]
            : [new Date()];
          this.chainObject.endDate = this.selectedItem.date
            ? [new Date(this.selectedItem.date)]
            : [new Date()];
        }
      } else if (val && this.isEdit && !_.isEmpty(this.schedule)) {
        //edit schedule
        this.object = {
          userId: this.schedule.userId,
          projectId: this.schedule.projectId,
          startTime: this.schedule.startTime,
          finishTime: this.schedule.finishTime,
          notes: this.schedule.notes,
          color: this.schedule.color || defaultColor(),
          timeLogType: this.schedule.timeLogType || TIME_LOG_TYPE_PROJECT,
          timeOffType: this.schedule.timeOffType || TIME_OFF_TYPE_PTO,
          title: this.schedule.title || '',
        };

        if (this.schedule.scheduleChainId && !this.isMyScheduling) {
          this.userIds = _.cloneDeep(this.userIdsByChainIdAndDate);
        } else {
          this.userIds = [this.schedule.userId];
        }

        this.isHasMultipleEmployeesInChain =
          this.schedule?.scheduleChainId &&
          (this.userIdsByChainIdAndDate || []).length > 1;

        if (this.editType === 'thisShift') {
          this.chainObject.startDate = [new Date(this.selectedItem.date)];
          this.chainObject.endDate = [new Date(this.selectedItem.date)];
        } else if (this.editType === 'allShifts') {
          this.chainObject.daysSelected =
            _.cloneDeep(this.chain.daysSelected) || [];
          this.chainObject.startDate = (this.chain.startDate && [
            new Date(this.chain.startDate),
          ]) || [new Date(this.selectedItem.date)];
          this.chainObject.endDate = (this.chain.endDate && [
            new Date(this.chain.endDate),
          ]) || [new Date(this.selectedItem.date)];
        } else if (this.editType === 'thisShiftAndAllRemaining') {
          this.chainObject.daysSelected =
            _.cloneDeep(this.chain.daysSelected) || [];
          this.chainObject.startDate = [new Date(this.selectedItem.date)];
          this.chainObject.endDate = (this.chain.endDate && [
            new Date(this.chain.endDate),
          ]) || [new Date(this.selectedItem.date)];
        } else {
          this.chainObject.startDate = [new Date(this.selectedItem.date)];
          this.chainObject.endDate = [new Date(this.selectedItem.date)];
        }

        if (this.schedule.projectId) {
          this.getProject(this.schedule.projectId);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.toggle {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 0;

  & .toggle-content {
    display: flex;
    flex: 1;
    gap: 15px;
    align-items: center;

    p {
      font-size: var(--f7-input-font-size);
    }
  }
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
}

.color-item {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 4px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }
}

.check-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.warning {
  font-size: 12px;
  background-color: var(--f7-color-warning-bg-color);
}

.select-day-item {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 11px;
  background-color: #a5a5a5;
  color: white;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  cursor: pointer;

  &_active {
    background-color: red;
  }
}

.error-message {
  font-size: var(--f7-list-item-text-font-size);
  font-weight: var(--f7-list-item-text-font-weight);
  color: red;
  margin-left: calc(
    var(--f7-list-media-item-padding-horizontal) +
      var(--f7-list-item-media-margin)
  );
}

.icon-repeat {
  font-size: var(--swimlane-icon-font-size);
  margin-right: var(--f7-list-item-media-margin);
}
</style>

<style lang="scss">
.scheduling-form .item-title {
  width: 100%;
}
</style>
