<template>
  <send-email-popup
    ref="sendEmailPopup"
    popup-title="Compose new email"
    templateName="purchase-order"
    :projectId="projectId"
    :attachments="attachments"
    @closePopup="closePopupDetail"
    :listEmails="emailList"
  ></send-email-popup>
</template>

<script>
import SendEmailPopup from '@/components/popups/SendEmailPopup.vue';
import { mapActions, mapGetters } from 'vuex';
import { firebase } from '@/services/firebase.service';
import _ from 'lodash';
import { SENDER_CONFIG_EMAIL } from '../../../../utility/const';

export default {
  components: {
    SendEmailPopup,
  },
  props: {
    purchaseOrderEmails: { type: Array, default: () => [] },
    emailType: { type: String, default: '' },
    projectId: { type: String, default: '' },
    attachments: { type: Array, default: () => [] },
  },
  computed: {
    ...mapGetters('setting/app/profile', ['user']),
    ...mapGetters({
      setting: 'setting/app/system/setting',
    }),
    ...mapGetters('common/common', ['emailList']),

    ...mapGetters('common/app-constant', ['tenantId']),
  },
  methods: {
    ...mapActions('common/email-notification', [
      'getEmailTemplateByTemplateName',
    ]),
    ...mapActions({
      bindSetting: 'setting/app/system/bindSetting',
    }),
    ...mapActions('common/common', ['getEmailList']),

    closePopupDetail() {
      this.$emit('handleClose');
    },
    async getComposer({ defaultSubject, emails, vendorName, mainMessage }) {
      if (_.isEmpty(this.setting)) {
        await this.bindSetting(this.tenantId);
      }

      const composer = {};

      composer.to = emails || '';
      composer.from = `${this.user.displayName} <${SENDER_CONFIG_EMAIL}>`;
      composer.cc = this.user.email || '';
      //parse email template info
      let templateEmail = await this.getEmailTemplateByTemplateName(
        this.emailType
      );

      let valueObject = {};
      valueObject = {
        vendor_name: vendorName || '',
        message: mainMessage,
        roofing_company_name: this.setting.companyName || '',
        roofing_company_address: this.setting.address || '',
        roofing_company_phone: this.setting.phoneNumber || '',
      };
      composer.message = this.parseEmailTemplate(
        templateEmail.htmlContent,
        valueObject
      );
      composer.subject = defaultSubject;
      composer.template = this.emailType;

      composer.attachments = [];
      composer.scheduleSendingTime = firebase.firestore.Timestamp.now();
      composer.isSent = false;
      composer.numberOfAttempt = 0;
      return composer;
    },

    parseEmailTemplate(htmlContent, valueObject) {
      for (const key in valueObject) {
        // eslint-disable-next-line no-prototype-builtins
        if (valueObject.hasOwnProperty(key)) {
          const value = valueObject[key];
          const reg = new RegExp(`{${key}}`, 'g');
          htmlContent = htmlContent.replace(reg, value);
        }
      }
      return htmlContent;
    },

    async sendMail({ defaultSubject, emails, vendorName, mainMessage }) {
      this.$f7.preloader.show();
      const self = this;
      const composer = await this.getComposer({
        defaultSubject,
        emails,
        vendorName,
        mainMessage,
      });
      if (!this.emailList || this.emailList.length == 0) {
        await this.getEmailList();
      }
      await this.$refs.sendEmailPopup.open({
        composer,
        callback: receiverList => {
          let purchaseOrderEmails = _.cloneDeep(this.purchaseOrderEmails);
          purchaseOrderEmails.push(...receiverList);
          self.$ri.dialog.openSuccessDialog({
            title: 'The email is sent successfully',
            content: '',
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                this.$emit('input', purchaseOrderEmails);
                _sefl.app.dialog.close();
              }
            },
          });
        },
      });
      this.$f7.preloader.hide();
    },
  },
};
</script>
