<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          v-if="isShowBackBtn"
          @click="handleBackAction"
          >Back</f7-link
        >
        <f7-link
          v-else
          panel-open="left"
        >
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Labor Cost Analysis</f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="exportExcel()">Export Excel</f7-link>
      </f7-nav-right>
    </f7-navbar>

    <!-- filter -->
    <f7-card>
      <f7-card-content>
        <f7-row>
          <f7-col
            v-show="!isShowBackBtn"
            class="margin-vertical-half"
            medium="50"
            xlarge="25"
            width="100"
          >
            <div class="display-flex align-items-center">
              <label
                v-show="$f7.device.desktop || $f7.device.ipad"
                class="margin-right"
                >View:</label
              >
              <f7-input
                class="border-shadow btn-container custom-select"
                style="flex: 1"
                type="select"
                placeholder="Select view"
                :value="view"
                @change="onChangeFilter('view', $event.target.value)"
              >
                <option
                  v-for="(view, index) in VIEW_LIST"
                  :key="index"
                  :value="view.value"
                >
                  {{ view.title }}
                </option>
              </f7-input>
            </div>
          </f7-col>
          <f7-col
            v-show="!isShowBackBtn"
            class="margin-vertical-half"
            medium="50"
            xlarge="25"
            width="100"
          >
            <div class="display-flex align-items-center">
              <template v-if="view === 'weekly'">
                <label
                  v-show="$f7.device.desktop || $f7.device.ipad"
                  class="margin-right"
                  >Week:</label
                >
                <week-select
                  style="flex: 1"
                  @onChangeFilter="onChangeFilter('dates', $event)"
                ></week-select>
              </template>
              <template v-else-if="view === 'monthly'">
                <label
                  v-show="$f7.device.desktop || $f7.device.ipad"
                  class="margin-right"
                  >Month:</label
                >
                <month-select
                  class="border-shadow btn-container custom-select"
                  @onChangeFilter="onChangeFilter('dates', $event)"
                >
                </month-select>
              </template>
            </div>
          </f7-col>
          <f7-col
            v-show="!isShowBackBtn"
            class="margin-vertical-half"
            medium="50"
            xlarge="25"
            width="100"
          >
            <div class="display-flex align-items-center">
              <label
                v-show="$f7.device.desktop || $f7.device.ipad"
                class="margin-right"
                >Project Name:</label
              >
              <f7-list
                style="flex: 1"
                inline-labels
                class="border-shadow btn-container"
              >
                <f7-list-item
                  link
                  @click.native="openSelectProject"
                >
                  <div slot="title">
                    {{
                      this.projectId
                        ? projectById(this.projectId).title
                        : 'Select project'
                    }}
                  </div>
                  <f7-link
                    v-show="projectId"
                    slot="after"
                    icon-f7="multiply_circle"
                    color="red"
                    @click.stop="onChangeFilter('projectId', null)"
                  ></f7-link>
                </f7-list-item>
              </f7-list>
            </div>
          </f7-col>
          <f7-col
            class="margin-vertical-half"
            medium="50"
            xlarge="25"
            width="100"
          >
            <div class="display-flex align-items-center">
              <label
                v-show="$f7.device.desktop || $f7.device.ipad"
                class="margin-right"
                >Report Type:</label
              >
              <f7-input
                class="border-shadow btn-container custom-select"
                style="flex: 1"
                type="select"
                placeholder="Select type"
                :value="type"
                @change="type = $event.target.value"
              >
                <option
                  v-for="(type, index) in TYPE_LIST"
                  :key="index"
                  :value="type.value"
                >
                  {{ type.title }}
                </option>
              </f7-input>
            </div>
          </f7-col>
        </f7-row>
      </f7-card-content>
    </f7-card>

    <data-table
      :headers="headers"
      :items="displayReport"
      :pageSize="displayReport.length"
      :class="{ 'table-fixed': view === 'weekly' }"
    >
      <template slot="card-header">
        <f7-list>
          <f7-searchbar
            :placeholder="`Search by Employee Name ${
              !isShowBackBtn ? ', Project Name' : ''
            } `"
            :clear-button="true"
            disable-button-text
            @input="searchtext = $event.target.value"
            @searchbar:disable="searchtext = ''"
          ></f7-searchbar>
        </f7-list>
      </template>
      <template v-slot:body="{ item }">
        <template v-if="!item.footer">
          <td border>{{ item.displayName }}</td>
          <td v-show="!isShowBackBtn">{{ item.projectName }}</td>
        </template>
        <template v-else>
          <td
            :style="item.footer && `font-weight: 600`"
            :colspan="!isShowBackBtn ? 2 : 1"
          >
            {{ item.title }}
          </td>
        </template>
        <td
          v-for="date in dates"
          :key="date.id"
          :style="item.footer && `font-weight: 600`"
          class="numeric-cell"
        >
          {{ showValueByType(item[date.id]) }}
        </td>
        <td
          :style="item.footer && `font-weight: 600`"
          class="numeric-cell"
        >
          {{ showValueByType(item.total) }}
        </td>
      </template>
    </data-table>

    <f7-block>
      <b>Total: {{ displayTotal }}</b>
    </f7-block>

    <!-- popup -->
    <project-list-popup
      ref="selectProject"
      @onSelected="onChangeFilter('projectId', $event)"
    ></project-list-popup>
  </f7-page>
</template>
<script>
import DataTable from '@/components/datatables';
import WeekSelect from '../components/selects/WeekSelect.vue';
import MonthSelect from '../components/selects/MonthSelect.vue';
import ProjectListPopup from '@/components/popups/ProjectListPopup.vue';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import exportExcel from '../exports/labor-cost-analysis-excel-export';
import { currencyUSD } from '../../../utility/config';
import { TYPE_LIST, VIEW_LIST } from '../utility/const';
import reportService from '../../../services/report.service';
import { auth } from '../../../services/firebase.service';
import { applyFilter } from '@/utility/filter-tools';
import Menu from '../../../components/menu/Menu.vue';
import moment from 'moment';
function getCurrentWeek() {
  const weekStart = moment().startOf('isoWeek');
  let days = [];
  for (let i = 0; i <= 6; i++) {
    days.push({
      id: moment(weekStart).add(i, 'days').format('MMDDYYYY'),
      date: moment(weekStart).add(i, 'days').format('MM/DD/YYYY'),
      dateHeader: moment(weekStart).add(i, 'days').format('ddd MM/DD'),
      dateMoment: moment(weekStart).add(i, 'days'),
    });
  }
  return days;
}

export default {
  components: {
    DataTable,
    WeekSelect,
    MonthSelect,
    ProjectListPopup,
    Menu,
  },

  data() {
    return {
      VIEW_LIST,
      TYPE_LIST,
      dates: getCurrentWeek(),
      projectId: null,
      type: 'laborCost',
      view: 'weekly',
      reportData: [],
      searchtext: '',
    };
  },

  computed: {
    ...mapGetters('reports/project', ['projectById', 'projectList']),
    ...mapGetters('reports/user', ['userById', 'userList']),
    ...mapGetters('reports/time-tracking', [
      'timeTrackingList',
      'isShowBackBtn',
    ]),

    headers() {
      let headers = (this.dates || []).map(x => {
        let result = {
          text: x.dateHeader,
          align: 'right',
          type: 'number',
          sortable: false,
          value: x.id,
        };
        return result;
      });

      if (!this.isShowBackBtn) {
        headers.unshift({
          text: 'Project Name',
          align: 'left',
          type: 'text',
          sortable: false,
          value: 'projectName',
        });
      }

      headers.unshift({
        text: 'Employee Name',
        align: 'left',
        type: 'text',
        sortable: false,
        value: 'displayName',
      });

      headers.push({
        text: 'Total',
        field: 'totals',
        align: 'right',
        type: 'number',
        sortable: false,
        value: 'total',
      });

      return headers;
    },

    displayReport() {
      let list = [...this.reportData].map(r => ({
        ...r,
        projectName: this.getProjectName([...new Set(r.projectIds)]) || '',
      }));

      list = applyFilter([...list], this.searchtext, [
        'displayName',
        'projectName',
      ]);

      const footer = {
        footer: true,
        title: 'Sub-Total',
        total: {
          approvedHours: this.sumArray(
            list.map(r => (r.total ? r.total.approvedHours : 0)) || []
          ),
          amounts: this.sumArray(
            list.map(r => (r.total ? r.total.amounts : 0)) || []
          ),
        },
      };
      this.dates.forEach(x => {
        footer[x.id] = {
          approvedHours: this.sumArray(
            list.map(r => (r[x.id] ? r[x.id].approvedHours : 0)) || []
          ),
          amounts: this.sumArray(
            list.map(r => (r[x.id] ? r[x.id].amounts : 0)) || []
          ),
        };
      });
      list.push(footer);

      return list;
    },

    displayTotal() {
      const total = this.displayReport.find(r => r.footer).total;
      const res = this.showValueByType(total);
      if (res === '-') return 0;
      return res;
    },
  },

  methods: {
    ...mapActions('reports/user', ['getUserList']),
    ...mapActions('reports/project', ['bindProjectList']),
    ...mapActions('reports/time-tracking', [
      'bindTimeTrackingListBys',
      'setIsShowBackBtn',
    ]),

    updateRoute() {
      const startDate = this.dates[0].id;
      const url = this.$f7router.generateUrl({
        name: 'labor-cost-analysis',
        query: {
          ...this.$f7route.query,
          startDate: startDate,
          view: this.view,
          projectId: this.projectId,
        },
      });
      this.$f7router.updateCurrentUrl(url);
    },

    onChangeFilter(name, value) {
      this[name] = value;
      this.updateRoute();
      if (name !== 'view') {
        const startDate = this.dates[0].id;
        const endDate = this.dates[this.dates.length - 1].id;
        this.getDataByRange(startDate, endDate);
      }
    },

    handleBackAction() {
      this.setIsShowBackBtn(false);
      this.$f7router.navigate(
        `/report/labor-cost-by-project?projectId=${this.$f7route.query.projectId}&view=${this.$f7route.query.view}`,
        {
          pushState: true,
          reloadAll: true,
        }
      );
    },

    sumArray(arr) {
      let res = 0;
      if (!_.isEmpty(arr)) {
        res = arr.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
      }
      return res;
    },

    openSelectProject() {
      this.$refs.selectProject.open();
    },

    getProjectName(projectIds) {
      return (this.projectList || [])
        .filter(r => projectIds.includes(r.id))
        .map(r => r.title)
        .join(', ');
    },

    showValueByType(value) {
      if (!value) return '-';
      if (this.type === 'hourly') {
        return value.approvedHours || '-';
      } else if (this.type === 'laborCost') {
        return value.amounts ? currencyUSD(value.amounts) : '-';
      }
    },

    getDataByRange(startDate, endDate) {
      this.$f7.preloader.show();
      reportService
        .getLaborCostAnalysis(auth.currentUser.tenantId, {
          projectId: this.projectId,
          startDate,
          endDate,
        })
        .then(res => {
          this.reportData = res;
          this.$f7.preloader.hide();
        });
    },

    exportExcel() {
      exportExcel({
        dates: this.dates,
        projectName: this.projectId
          ? this.projectById(this.projectId).title
          : 'No Data!',
        headers: this.headers,
        values: this.displayReport,
        view: this.view,
        type: this.type,
      });
    },
  },

  created() {
    this.getUserList();
    this.bindProjectList();
  },

  mounted() {
    let startDate = moment();
    let endDate = null;
    if (this.$f7route.query.projectId) {
      this.projectId = this.$f7route.query.projectId;
    }
    if (this.$f7route.query.view) {
      this.view = this.$f7route.query.view;
    }
    if (this.$f7route.query.startDate) {
      startDate = moment(this.$f7route.query.startDate, 'MM/DD/YYYY');
    }
    if (this.view === 'weekly') {
      startDate = startDate.startOf('isoWeek');
      endDate = _.cloneDeep(startDate).endOf('isoWeek');
    } else if (this.view === 'monthly') {
      startDate = startDate.startOf('month');
      endDate = _.cloneDeep(startDate).endOf('month');
    }
    this.getDataByRange(
      startDate.format('MMDDYYYY'),
      endDate.format('MMDDYYYY')
    );
  },
};
</script>
<style lang="scss" scoped>
.border-shadow {
  border-radius: 4px;
  box-shadow: 0 1px 3px #b7b7b7;
  box-sizing: border-box;
}
.btn-container {
  min-width: 250px;
  min-height: 32px;
  display: flex;
  align-items: center;

  &::v-deep.list ul {
    width: 100%;
  }
}
.table-fixed {
  ::v-deep table:first-child {
    table-layout: fixed;

    td {
      word-wrap: break-word;
    }
  }
}
@media (max-width: 568px) and (orientation: portrait) {
  .table-fixed {
    ::v-deep table:first-child {
      table-layout: auto;
    }
  }
}
.custom-select {
  ::v-deep select,
  ::v-deep input {
    padding-left: 16px;
  }
}
</style>
