import axiosService from './axios.service';
import 'firebase/auth';
import { firebase } from './firebase.service';

const auth = firebase.auth();

const addSafetyDoc = (tenantId, data) => {
  data.id = '';
  data.isDeleted = false;
  return axiosService.post('/safety/add', { tenantId, data }).then(res => {
    return res.data;
  });
};

export default {
  addSafetyDoc,
};
