<template>
  <div>
    <data-table
      :headers="tableHeaders"
      :items="expenseList"
      :pageSize="expenseList.length"
      class="wrap-text-table"
    >
      <template slot="card-header">
        <f7-block class="margin-top-half">
          <f7-row>
            <div>
              <strong>Total: </strong>
              <i>{{ totalExpenseAmount | currencyUSD }}</i>
            </div>
          </f7-row>
        </f7-block>
      </template>

      <!-- Table -->
      <template v-slot:body="{ item }">
        <td class="label-cell">{{ userById(item.payeeId).displayName }}</td>
        <td>{{ item.approvedBy }}</td>
        <td class="numeric-cell">{{ item.amount | currencyUSD }}</td>
        <td>{{ reportName(item.reportType) }}</td>
        <td>{{ expenseStatus(item.status) }}</td>
        <td class="numeric-cell">{{ toDisplayDateString(item.createdAt) }}</td>
        <td class="numeric-cell">
          {{ toDisplayDateString(item.approvedDate) }}
        </td>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/datatables';
import { mapActions, mapGetters } from 'vuex';
import { toDisplayDateString } from '../../../../utility/datetime';

export default {
  components: { DataTable },

  computed: {
    ...mapGetters({
      detail: 'swimlane/card/detail',
    }),
    ...mapGetters('swimlane/finance', ['expenseList', 'totalExpenseAmount']),
    ...mapGetters('swimlane/user', ['userById']),
    ...mapGetters('swimlane/app-constant', [
      'expenseTypeList',
      'expenseStatusList',
    ]),

    tableHeaders() {
      return [
        {
          text: 'Submitted By',
          align: 'left',
          value: 'submittedBy',
          width: '10%',
        },
        {
          text: 'Approved By',
          align: 'left',
          value: 'approvedBy',
          width: '10%',
        },
        {
          text: 'Amount',
          align: 'right',
          value: 'amount',
          width: '10%',
        },
        {
          text: 'Expenses Type',
          align: 'left',
          value: 'expensesType',
          width: '10%',
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status',
          width: '10%',
        },
        {
          text: 'Submitted Date',
          align: 'right',
          value: 'submittedDate',
          width: '10%',
        },
        {
          text: 'Approved Date',
          align: 'right',
          value: 'approvedDate',
          width: '10%',
        },
      ];
    },

    reportName() {
      return type =>
        (this.expenseTypeList.find(item => item.value === type) || {})
          .displayName;
    },

    expenseStatus() {
      return value =>
        (this.expenseStatusList.find(item => item.value === value) || {})
          .displayName;
    },
  },

  methods: {
    ...mapActions('swimlane/finance', ['initExpense']),

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initData() {
      this.$f7.preloader.show();
      this.initExpense(this.detail.id).finally(() => {
        this.$f7.preloader.hide();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label-cell {
  padding-left: 10px;
}
</style>
