<template>
  <div>
    <f7-block-title>
      <div>{{ title }}</div>
    </f7-block-title>
    <f7-list media-list>
      <f7-list-item
        :class="showSystemLetterWarning(item) ? 'estimate-action' : ''"
        v-for="(item, index) in history"
        :key="index"
        :title="item.nextAction"
        :after="toLocaleString(item.resolvedAt)"
        :subtitle="'Resolved by ' + item.resolvedBy"
        :text="
          item.previousAction ? 'Previous action: ' + item.previousAction : ''
        "
      >
        <f7-icon
          v-show="showSystemLetterWarning(item)"
          text-color="red"
          slot="content"
          f7="info_circle"
          class="padding-right-half icon-info-required-system-letter"
        ></f7-icon>
      </f7-list-item>
    </f7-list>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  props: {
    title: { type: String, default: '' },
  },
  computed: {
    ...mapState('dashboard/project', ['card']),
    showSystemLetterWarning() {
      return item => {
        return (
          this.card.requiredSystemLetter === true &&
          this.requiredSystemLetterAttachmentFiles.length === 0 &&
          item.nextAction === 'Estimate'
        );
      };
    },
    history() {
      return this.card
        ? this.card.actionsHistory
          ? _.cloneDeep(this.card.actionsHistory).reverse()
          : {}
        : [];
    },
  },
  methods: {
    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    },
  },
};
</script>
