import axiosService from './axios.service';

const getAuthUri = redirectPath => {
  return axiosService
    .get(`/stackct/get-auth-uri?redirectPath=${redirectPath}`)
    .then(result => result.data)
    .catch(error => {
      throw new Error(error.response.data);
    });
};

const checkAuth = () => {
  return axiosService
    .get(`/stackct/is-authorized`)
    .then(result => result.data)
    .catch(error => {
      throw new Error(error.response.data);
    });
};

const refreshToken = () => {
  return axiosService
    .post(`/stackct/refresh-token`)
    .then(result => result.data)
    .catch(error => {
      throw new Error(error.response.data);
    });
};

const logout = () => {
  return axiosService
    .post(`/stackct/logout`)
    .then(result => result.data)
    .catch(error => {
      throw new Error(error.response.data);
    });
};

const getProjects = () => {
  return axiosService
    .get(`stackct/projects`)
    .then(result => result.data)
    .catch(error => {
      throw new Error(error.response.data);
    });
};

const getTakeoffs = takeoffId => {
  return axiosService
    .get(`stackct/takeoffs/${takeoffId}`)
    .then(result => result.data)
    .catch(error => {
      throw new Error(error.response.data);
    });
};

const getPages = takeoffId => {
  return axiosService
    .get(`stackct/Pages/${takeoffId}`)
    .then(result => result.data)
    .catch(error => {
      throw new Error(error.response.data);
    });
};

const getPlans = projectId => {
  return axiosService
    .get(`stackct/get-plans/${projectId}`)
    .then(result => result.data)
    .catch(error => {
      throw new Error(error.response.data);
    });
};

const getUserInfo = () => {
  return axiosService
    .get(`stackct/user-info`)
    .then(result => result.data)
    .catch(error => {
      throw new Error(error.response.data);
    });
};

export default {
  getAuthUri,
  checkAuth,
  getProjects,
  getTakeoffs,
  refreshToken,
  logout,
  getPlans,
  getPages,
  getUserInfo,
};
