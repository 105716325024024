<template>
  <div>
    <f7-list
      class="dashboard-mode"
      :class="{
        'swimland-dashboard-mode': isSwimlane,
      }"
      :style="`left: ${
        isSwimlane && ($device.desktop || $device.ipad)
          ? 'var(--f7-page-master-width)'
          : '0'
      }`"
    >
      <f7-menu>
        <a
          class="menu-item icon-only menu-item-title"
          @click="toBoard()"
        >
          <div class="menu-item-content">
            <i class="menu-item-title_icon f7-icons"
              >arrow_right_arrow_left_square</i
            >
            <span class="menu-item-title_only">Switch View</span>
          </div>
        </a>

        <a
          class="menu-item icon-only menu-item-title"
          @click="addNewTask"
        >
          <div class="menu-item-content">
            <i class="menu-item-title_icon f7-icons"
              >rectangle_stack_badge_plus</i
            >
            <span class="menu-item-title_only">Add New Task</span>
          </div>
        </a>

        <f7-link
          class="menu-item icon-only menu-item-title link"
          popover-open=".popover-share-job-link"
        >
          <div class="menu-item-content">
            <i class="menu-item-title_icon f7-icons">link</i>
            <span class="menu-item-title_only">Share Job</span>
          </div>
        </f7-link>
        <f7-popover class="popover-share-job-link">
          <f7-list>
            <f7-list-item
              title="Copy link"
              link
              popover-close
              @click="copyLink"
            ></f7-list-item>
            <f7-list-item
              title="Send by email"
              link
              popover-close
              @click="sendLinkToJobCard(project, true)"
            ></f7-list-item>
          </f7-list>
        </f7-popover>

        <f7-button
          v-if="$device.desktop && readyForWarranty"
          class="warranty-button"
          fill
          @click="onClickResolveAction"
        >
          Ready for Warranty Inspection
        </f7-button>
      </f7-menu>
    </f7-list>

    <!-- Extended FAB Center Bottom (Red) -->
    <div
      v-if="!$device.desktop && readyForWarranty"
      class="fab fab-extended fab-center-bottom color-primary warranty-button_desktop"
    >
      <a
        href="#"
        @click="onClickResolveAction"
      >
        <div class="fab-text">Ready for Warranty Inspection</div>
      </a>
    </div>
    <send-email-popup
      ref="sendEmailPopup"
      popup-title="Send By Email"
      templateName="link-to-job-card"
      :projectId="project && (project.id || '')"
      :attachments="project && (project.attachmentFiles || [])"
      :listEmails="listEmails"
    ></send-email-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SendEmailPopup from '@/components/popups/SendEmailPopup.vue';
import axiosService from '@/services/axios.service';
import sendEmail from '../../mixin/sendEmail';

export default {
  mixins: [sendEmail],
  components: {
    SendEmailPopup,
  },

  computed: {
    ...mapGetters('dashboard/project', { project: 'constructionProject' }),
    ...mapGetters('dashboard/work-order', ['filterTaskListGrid']),
    ...mapGetters('dashboard/client', ['listEmails']),
    isSwimlane() {
      return this.$f7route.route.meta.boardType === 'swimlane';
    },

    /**
     * DEV-217: The button needs to be visible all the time.
     */
    readyForWarranty() {
      return true;
    },
    ...mapGetters('common/app-constant', ['tenantId']),
  },

  methods: {
    toBoard() {
      this.$f7.preloader.show();
      if (this.$f7route.route.meta.boardType === 'swimlane') {
        this.$f7router.navigate(
          `/dashboard/grid/${this.$f7route.route.meta.businessCode}/${this.$f7route.route.meta.boardCode}/project/${this.$f7route.params.cardNumber}`,
          {
            pushState: true,
            reloadAll: true,
          }
          // preloader will hide in created() of page
        );
      } else if (this.$f7route.route.meta.boardType === 'grid') {
        this.$f7router.navigate(
          `/dashboard/swimlane/${this.$f7route.route.meta.businessCode}/${this.$f7route.route.meta.boardCode}/project/${this.$f7route.params.cardNumber}`,
          {
            pushState: true,
            reloadAll: true,
          }
        );
      }
    },

    /**
     * handle add new task click
     */
    addNewTask() {
      this.$emit('onAddNewTask');
    },

    /**
     * handle click resolve action
     */
    onClickResolveAction() {
      this.$ri.dialog.openInfoDialog({
        title: 'Resolve Action',
        content:
          'You are about to move the project to Warranty Inspection to close out the project. Are you sure you want to do this?',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$emit('onResolveConstructionAction');
          }
        },
      });
    },
    async generateCardWebUrl() {
      const response = await axiosService.post('/tenant/get-anonymous-token', {
        entity: 'project',
        entityId: this.project.id,
      });
      if (response && response.status === 200) {
        return `${import.meta.env.VITE_HOST_DOMAIN}/commercial-card-view/?token=${
          response.data
        }&tenant=${btoa(this.tenantId)}&id=${this.project.id}`;
      }
      return '';
    },
    async copyLink(event) {
      const url = await this.generateCardWebUrl();
      event.preventDefault();
      const tempInput = document.createElement('input');
      tempInput.value = url;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      this.showToast('Copied!');
    },
    showToast(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000,
        })
        .open();
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  background-color: transparent;
  color: var(--f7-theme-color);
}

.menu-item-title {
  font-size: var(--f7-toolbar-font-size);
  margin-right: 5px;

  &_only {
    margin-left: 3px;
  }

  &_icon {
    margin-left: 2px;
  }

  &_inactive {
    color: var(--f7-tabbar-link-inactive-color);
  }
}

.dashboard-mode {
  margin-bottom: 0px;
  margin-top: var(--swimlane-dashboard-mode-margin-top);
}

.swimland-dashboard-mode {
  position: fixed;
  width: 100%;
  right: 0;
  margin: 0;
  background: var(--f7-color-bg-8-neutral);
  padding: 4px 0;
}

.warranty-button {
  margin-left: 10px;

  &_desktop {
    width: 80%;
  }
}

.menu-item.active-state {
  background-color: #ffaeae;
}

.menu-item.link.active-state {
  opacity: 1;
}
</style>
