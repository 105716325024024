<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link @click="handleBackAction">Cancel</f7-link>
      </f7-nav-left>
      <f7-nav-title>Add New Estimate</f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="onClickNext()">Next</f7-link>
      </f7-nav-right>
    </f7-navbar>

    <estimate-new-form
      ref="newEstimateForm"
      @toDetails="toDetails($event)"
    ></estimate-new-form>

    <!-- Tobe delete -->
    <!-- <f7-list v-show="false">
      <f7-list-input
        class="number-building-input"
        placeholder="Enter number of buidings/sections"
        :value="numberOfBuildings"
        type="number"
        min="1"
        max="200"
        @input="onChangenumberOfBuildings(parseInt($event.target.value.trim()))"
        error-message-force
        validate
        validate-on-blur
        :error-message="numberOfBuildingsErrorMessage"
      >
        <input-icon slot="media" icon="number_circle"></input-icon>
        <div class="list-item-inner-start" slot="inner-start">
          Number of buidings/sections<required-asterisk />
        </div>
      </f7-list-input>

      <f7-list-input
        v-for="(building, index) in currentEstimate.buildings || []"
        :key="index"
        :placeholder="'Enter Building/Section# ' + (index + 1)"
        type="text"
        :value="
          currentEstimate.buildings[index]
            ? currentEstimate.buildings[index].buildingName
            : ''
        "
        @input="setBuildingName(index, $event.target.value)"
        error-message-force
        validate
        validate-on-blur
        :error-message="buildingNameErrorMessage(index)"
      >
        <input-icon slot="media" icon="building_2"></input-icon>
        <div class="list-item-inner-start" slot="inner-start">
          {{ "Building/Section# " + (index + 1) }}<required-asterisk />
        </div>
      </f7-list-input>
    </f7-list> -->
  </f7-page>
</template>

<script>
import EstimateNewForm from '../components/forms/EstimateNewForm.vue';

import { mapActions, mapGetters, mapMutations } from 'vuex';
// import { required, minValue } from "@vuelidate/validators";

import mixin from '../utility/mixins';
import buidingMixin from '../utility/building-mixins';
import _ from 'lodash';

export default {
  components: { EstimateNewForm },
  mixins: [mixin, buidingMixin],
  data() {
    return {
      popupAddNewServiceEstimate: false,
      numberOfBuildings: 1,
    };
  },

  created() {
    if (this.$f7route.query.view === 'estimate-list') {
      this.setCurrentView('estimate-list');
    } else {
      this.setCurrentView('client');
    }
    if (_.isEmpty(this.companyTypeList)) {
      this.initConstant();
    }
  },

  mounted() {
    this.setCurrentEstimateField(null);
    this.setEstIdLink(null);
    if (this.$device.desktop) {
      this.$el.querySelector('.first-input input').focus();
    }
  },

  computed: {
    ...mapGetters('common/app-constant', ['companyTypeList']),
    ...mapGetters('estimate/estimate-page/estimate', ['currentEstimate']),
    ...mapGetters('estimate/estimate-page/common', ['currentView']),
  },

  methods: {
    ...mapMutations('estimate/estimate-page/estimate', ['setEstIdLink']),
    ...mapActions('estimate/estimate-page/common', ['setCurrentView']),
    ...mapActions({
      createNewEstimate: 'estimate/estimate-page/estimate/createNewEstimate',
      setCurrentEstimateField:
        'estimate/estimate-page/estimate/setCurrentEstimateField',
    }),
    ...mapActions('common/app-constant', ['initConstant']),
    ...mapActions('estimate/estimate-page/estimate', [
      'setParamsBack',
      'setQueryBack',
    ]),

    setEstimateValue(prop, value) {
      this.setCurrentEstimateField({ fieldName: prop, value });
    },

    toDetails({ estimateNumber, hasProject, isEditing }) {
      if (hasProject) {
        this.$f7router.navigate(
          `/dashboard/type/${
            this.$f7route.params.boardType
          }/business/commercial/board/${this.$f7route.params.boardId}/action/${
            this.$f7route.params.actionId
          }/project/${
            this.$f7route.params.projectId
          }/estimate/${estimateNumber}${isEditing ? '/edit' : ''}`,
          {
            pushState: true,
          }
        );
      } else {
        if (this.currentView === 'estimate-list') {
          this.$f7router.navigate(
            `/estimate/${estimateNumber}${isEditing ? '/edit' : ''}`,
            {
              pushState: true,
            }
          );
        } else {
          this.$f7router.navigate(
            `/estimate/${this.$f7route.params.clientType}/${
              this.$f7route.params.clientId
            }/${estimateNumber}${isEditing ? '/edit' : ''}`,
            {
              pushState: true,
            }
          );
        }
      }
    },

    onClickNext() {
      const self = this;
      const params = this.$f7route.params || {};
      const query = this.$f7route.query || {};
      this.setQueryBack(query);
      this.setParamsBack(params);
      if (this.$refs.newEstimateForm.validate()) {
        this.$f7.preloader.show(); // loading hiden at onCreated of buildingEstimateDetailPage
        this.createNewEstimate().then(estimate => {
          self.setEstIdLink(estimate.estimateNumber);
          self.$f7router.navigate(
            self.buildingUrl(
              estimate.estimateNumber,
              estimate.buildings[0].buildingName,
              query.view === 'estimate-list'
                ? null
                : self.currentEstimate.contactId
            ),
            {
              pushState: true,
            }
          );
        });
      }
    },
  },
};
</script>

<style></style>
