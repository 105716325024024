<template>
  <div
    :style="`${actions.length > 0 ? 'margin-top: -28px' : ''}`"
    :class="`text-editor-container hidden-overflow`"
  >
    <div
      :id="`scroll_id_${section.index}`"
      :ref="`scroll_id_${section.index}`"
      :class="`${actionButton} dislay-overlay display-flex justify-content-space-between`"
    >
      <div class="dislay-overlay display-flex justify-content-start">
        <!-- <f7-button @click="addSection">
          <f7-icon color="primary" f7="plus_circle_fill"></f7-icon> Add Custom
          Section</f7-button
        >

        <f7-button @click="removeSection" v-show="!isDefaultSection">
          <f7-icon color="primary" f7="minus_circle_fill"></f7-icon> Remove This
          Section</f7-button
        > -->
      </div>

      <div class="dislay-overlay display-flex justify-content-end">
        <div
          class="margin-left-half"
          v-for="(action, index) in actions"
          :key="index"
        >
          <f7-button
            style="box-shadow: 0px 0px 15px gray"
            fill
            @click="handleActionClick(action.actionCode)"
            >{{ action.actionName }}</f7-button
          >
        </div>
      </div>
    </div>
    <!-- :ref="`${section.sectionId}_${section.index}`" class="text-editor-comp"
    style="--f7-text-editor-height: auto; width: 1020px; "
    :value="section.htmlValue" mode="popover" @texteditor:change="changeValue"
    :custom-buttons="customButtons" :buttons="buttons" -->

    <f7-text-editor
      :id="`${section.sectionId}_${section.index}`"
      :ref="`${section.sectionId}_${section.index}`"
      :class="`text-editor-comp ${sectionEditor}`"
      style="--f7-text-editor-height: auto; width: 1020px"
      :value="section.htmlValue"
      mode="popover"
      @texteditor:change="changeValue"
      :custom-buttons="customButtons"
      :buttons="buttons"
      @click.native="handleClickAction"
      @texteditor:blur="handleBlurAction"
    ></f7-text-editor>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    section: { type: Object, default: () => {} },
  },

  data() {
    let app = this;
    return {
      value: '',
      currentDoc: null,
      customButtons: {
        image: {
          content: '<i class="f7-icons">photo</i>',
          async onClick(editor) {
            const currentRange = editor.getSelectionRange();
            let photo = await app.$refs.selectPhoto.openPopup();
            if (photo && currentRange) {
              let image = `<img src=${photo.url} id=${photo.id} width="100%">`;
              editor.setSelectionRange(currentRange);
              document.execCommand('insertHTML', true, image);
            }
          },
        },
        textColor: {
          content: '<i class="f7-icons">pencil</i>',
          async onClick(editor) {
            const currentRange = editor.getSelectionRange();
            let color = await app.$refs.selectColor.openPopup();
            if (color && currentRange) {
              editor.setSelectionRange(currentRange);
              document.execCommand('foreColor', true, color);
            }
          },
        },
      },
      buttons: [
        ['bold', 'italic', 'underline', 'textColor'],
        [('orderedList', 'unorderedList')],
        // ["link", "image"],
        [/*"paragraph",*/ 'h1', 'h2', 'h3'],
        ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
        ['subscript', 'superscript'],
        ['indent', 'outdent'],
      ],
      actionButton: 'action-button-hide',
      sectionEditor: 'section-editor-unfocus',
    };
  },

  computed: {
    ...mapGetters('common/proposal-template', ['proposalTemplate']),

    actions() {
      switch (this.proposalTemplate.proposalType) {
        case 'commercial':
          return (this.section.actions || []).filter(
            action =>
              action.actionCode === 'edit-verbiage' ||
              action.actionCode === 'edit-table'
          );

        default:
          return (this.section.actions || []).filter(
            action => action.actionCode === 'edit-table'
          );
      }
    },

    isDefaultSection() {
      switch (this.section.sectionId) {
        case 'cover-page':
        case 'header-section':
        case 'footer-section':
          return true;

        default:
          return false;
      }
    },
  },

  methods: {
    isInViewport(parentTop) {
      let element = '';
      element =
        this.$refs[`${this.section.sectionId}_${this.section.index}`].$el;

      if (element) {
        const rect = element.getBoundingClientRect();
        return (
          rect.top + rect.height / 2 - parentTop >= 0 &&
          rect.top - 99 + rect.height / 2 - parentTop <=
            (window.innerHeight || document.documentElement.clientHeight)
        );
      }

      return false;
    },

    addSection() {
      this.$emit('addSection', this.section.index);
    },

    removeSection() {
      this.$emit('removeSection', this.section.index);
    },

    changeValue(value) {
      this.$emit('editorChange', { sectionId: this.section.sectionId, value });
    },

    moveSectionToView(section) {
      this.$refs[`scroll_id_${section.index}`].scrollIntoView();
    },

    handleClickAction() {
      // console.log("click");
      this.actionButton = 'action-button-show';
      this.sectionEditor = 'section-editor-focus';
    },

    handleBlurAction() {
      // console.log("blur");
      this.actionButton = 'action-button-hide';
      this.sectionEditor = 'section-editor-unfocus';
    },

    handleActionClick(actionCode) {
      // console.log(this.proposalTemplate);
      this.$emit('doAction', {
        actionCode,
        sectionId: this.section.sectionId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-editor-comp {
  margin: 0px;
}

.text-editor-comp ::v-deep .text-editor-content {
  padding: 0px;
  overflow: hidden;
}

.text-editor-comp ::v-deep font > div {
  margin: -23px 0px;
}

.section-editor-unfocus {
  // box-shadow: 0 1px 2px var(--f7-theme-color);
  transition: box-shadow 0.2s ease-in-out;
}

.section-editor-focus {
  box-shadow: 0px 0px 15px gray;
  transition: box-shadow 0.2s ease-in-out;
  z-index: 999;
}

.action-button-show {
  top: -5px;
  visibility: visible;
  opacity: 1;
  transition:
    visibility 0.2s,
    opacity 0.2s ease-in-out;
}

.action-button-hide {
  top: -5px;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0.2s,
    opacity 0.2s ease-in-out;
}

.dislay-overlay {
  position: relative;

  // top: -5px;
  // left: 20px;
  width: 100%;
  height: 100%;
}
</style>
