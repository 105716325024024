<template>
  <div>
    <f7-block-title v-show="$device.desktop">Sidewall/Headwall</f7-block-title>
    <f7-card :class="{ 'margin-top-half': !$device.desktop }">
      <f7-card-content>
        <div class="canvas-container">
          <!-- canvas -->
          <canvas
            v-if="$device.desktop"
            :ref="assemblyId"
            :id="assemblyId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @mousedown="handleMouseDown"
            @mousewheel="handleMouseWheel"
          />
          <canvas
            v-else
            :ref="assemblyId"
            :id="assemblyId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @click="handleMouseDown"
            @pointerdown="handlePointerDown"
            @pointermove="handlePointerMove"
            @pointerup="handlePointerUp"
            @pointercancel="handlePointerUp"
            @pointerleave="handlePointerUp"
            @pointerout="handlePointerUp"
          />
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>
<script>
import _ from 'lodash';
import paper from 'paper';
import { evaluate } from 'mathjs';
import canvasMixin from '../../utility/canvas-mixin';
const inchToPx = 40;
const radiusL = 5;
const radiusS = 3;

export default {
  mixins: [canvasMixin],

  props: {
    data: Object,
    assemblyId: String,
  },

  data() {
    return {
      scope: null,
      dataToPx: {},
      draws: {},
      yBase: 50,
      xBase: 200,
      width: 1200,
      height: 1000,
      dark: localStorage.getItem('themeDark') === 'true',
    };
  },

  mounted() {
    this.scope = new paper.PaperScope();
    this.scope.setup(this.assemblyId);
    this.drawAll();
  },

  methods: {
    initCanvas() {
      this.removeProject(this.scope);
      this.scope = new paper.PaperScope();
      this.scope.setup(this.assemblyId);
      if (!_.isEmpty(this.scope.view)) {
        this.scope.view.viewSize = new paper.Size(
          this.$device.desktop ? this.width : this.widthMobile,
          this.$device.desktop ? this.height : this.heightMobile
        );
      }
    },

    reset() {
      this.scope && this.scope.project.activeLayer.removeChildren();
    },

    setDraws(name, value) {
      this.draws[name] = value;
    },

    handleDraw1() {
      let path1 = new paper.Path({
        strokeColor: this.dark ? 'white' : 'black',
        strokeWidth: '2',
      });

      const pointA = new paper.Point(this.xBase, this.yBase);
      const pointB = new paper.Point(pointA.x, pointA.y + 3 * inchToPx);
      const pointC = new paper.Point(pointB.x + 1.5 * inchToPx, pointB.y);
      const pointC1 = new paper.Point(pointC.x, pointC.y + 1.5 * inchToPx);
      const pointC2 = new paper.Point(pointC1.x - radiusL * 2, pointC1.y);
      const centerC1C2 = this.pointOnPathByStart(pointC1, pointC2, 2);
      const throughC1C2 = pointC1.rotate(90, centerC1C2);

      const pointC3 = new paper.Point(pointC2.x, pointC2.y - 1 * inchToPx);
      const pointC4 = new paper.Point(pointC3.x - radiusL * 2, pointC3.y);
      const centerC3C4 = this.pointOnPathByStart(pointC3, pointC4, 2);
      const throughC3C4 = pointC3.rotate(-90, centerC3C4);

      const pointC5 = new paper.Point(pointC4.x, pointC4.y + 2 * inchToPx);

      path1.add(pointA, pointB, pointC, pointC1);
      path1.arcTo(throughC1C2, pointC2);
      path1.add(pointC3);
      path1.arcTo(throughC3C4, pointC4);
      path1.add(pointC5);

      let path2 = new paper.Path({
        strokeColor: this.dark ? 'white' : 'black',
        strokeWidth: '2',
      });

      const pointD = new paper.Point(centerC3C4.x, centerC3C4.y + 3 * inchToPx);
      let pointE = new paper.Point(pointD.x, pointD.y - inchToPx);
      pointE = pointE.rotate(135, pointD);
      let pointF = new paper.Point(pointE.x, pointE.y - 2.5 * inchToPx);
      pointF = pointF.rotate(-120 - 45, pointE);

      path2.add(centerC3C4, pointD, pointE, pointF);

      let path3 = new paper.Path({
        strokeColor: this.dark ? 'white' : 'black',
        strokeWidth: '2',
      });
      const pointF1 = new paper.Point(pointF.x, pointF.y - (1 / 2) * inchToPx);
      const pointF2 = new paper.Point(pointF1.x + radiusS * 2, pointF1.y);
      const centerF1F2 = this.pointOnPathByStart(pointF1, pointF2, 2);
      let throughF1F2 = pointF1.rotate(90, centerF1F2);

      const pointF3 = new paper.Point(
        pointF2.x,
        pointF2.y + (1 / 2) * inchToPx
      );

      path3.add(pointF, pointF1);
      path3.arcTo(throughF1F2, pointF2);
      path3.add(pointF3);
      path3.rotate(135, pointF);

      path2.join(path3);
    },

    handleDraw2() {
      const { A, B, C, angle } = this.dataToPx;
      let path1 = new paper.Path({
        strokeColor: this.dark ? 'white' : 'black',
        strokeWidth: '2',
      });
      let path2 = new paper.Path({
        strokeColor: this.dark ? 'white' : 'black',
        strokeWidth: '2',
      });
      const pointA = new paper.Point(
        this.xBase + 0.75 * inchToPx,
        this.yBase + 7 * inchToPx
      );
      const pointB = new paper.Point(pointA.x, pointA.y + A);
      const pointC = new paper.Point(pointB.x, pointB.y - B);
      const pointC1 = new paper.Point(pointC.x + radiusL * 2, pointC.y);
      const centerCC1 = this.pointOnPathByStart(pointC, pointC1, 2);
      const throughCC1 = pointC.rotate(90, centerCC1);
      const pointC2 = new paper.Point(pointC1.x, pointC1.y + C - radiusL);

      path1.add(pointA, pointB);

      path2.add(pointB, pointC);
      path2.arcTo(throughCC1, pointC1);
      path2.add(pointC2);
      path2.rotate(angle, pointB);

      path1.join(path2);

      this.handleDrawDescriptionLeft(pointA, pointB, `A: ${this.data.A}`);
      this.handleDrawDescriptionDiagonal(
        throughCC1.rotate(angle, pointB),
        pointB,
        angle,
        0,
        `B: ${this.data.B}`,
        'center'
      );
      this.handleDrawDescriptionDiagonal(
        pointC2.rotate(angle, pointB),
        throughCC1.rotate(angle, pointB),
        angle,
        radiusL,
        `C: ${this.data.C}`,
        'center',
        50,
        'minus'
      );
      this.drawArc(
        new paper.Point(pointB.x, pointB.y - 20),
        pointB,
        new paper.Point(pointB.x, pointB.y - 20).rotate(angle, pointB),
        angle
      );
    },

    drawAll() {
      this.handleDraw1();
      this.handleDraw2();
      this.resizableLayer(this.xBase, this.yBase * 2);
    },
  },

  watch: {
    data: {
      handler(val) {
        const keys = ['A', 'B', 'C'];
        for (let i of keys) {
          let parseValue = this.convertStringToExpression(val[i]);
          this.dataToPx[i] = evaluate(parseValue) * inchToPx;
        }
        this.dataToPx.angle = evaluate(_.cloneDeep(val.angle));
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.canvas-container {
  position: relative;
}
canvas[resize] {
  width: 100%;
  height: 100%;
}
</style>
