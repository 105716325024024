import Vue from 'vue';

export default {
  setWoTaskList(state, payload) {
    Vue.set(state, 'woTaskList', payload);
  },
  setWoTaskSubscribe(state, payload) {
    Vue.set(state, 'woTaskSubscribe', payload);
  },
};
