<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Invoices</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>

    <div>
      <invoice-grid ref="invoiceGrid"></invoice-grid>
    </div>
  </f7-page>
</template>
<script>
import { mapActions } from 'vuex';
import InvoiceGrid from '../components/tabs/InvoiceGrid.vue';
import Menu from '../../../components/menu/Menu.vue';

export default {
  components: {
    InvoiceGrid,
    Menu,
  },
  created() {
    this.$f7.preloader.show();
    this.bindConstantTypeList().finally(() => {
      this.$f7.preloader.hide();
    });
  },

  mounted() {
    this.$refs.invoiceGrid.initData();
    this.$refs.invoiceGrid.removeFilter();
  },

  methods: {
    ...mapActions('invoices/app-constant', ['bindConstantTypeList']),
    ...mapActions({
      resetSearchInvoices: 'invoices/invoices/resetSearch',
      resetSearchRecurringInvoices: 'invoices/recurring-invoices/resetSearch',
      setSelectedStatusFilterInvoice:
        'invoices/invoices/setSelectedStatusFilter',
      setSelectedStatusFilterRecurringInvoice:
        'invoices/recurring-invoices/setSelectedStatusFilter',
    }),
  },
  beforeDestroy() {
    this.resetSearchInvoices();
    this.resetSearchRecurringInvoices();
  },
};
</script>
<style lang="scss" scoped>
.custom-hover:hover {
  background: var(--step-background-color) !important;
}
.tab-block {
  margin: 15px 5px 5px 5px;
}
.tab-content-container {
  padding-top: 10px;
  overflow: visible;
}
</style>
