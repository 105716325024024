<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Pay Rate Detail </f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-block-title
      class="display-flex justify-content-space-between align-items-center"
    >
      <span>Pay Rate List</span>
      <f7-button
        fill
        @click="isShowPayRatePopup = true"
      >
        Add New Pay Rate
      </f7-button>
    </f7-block-title>
    <data-table
      :style="$device.desktop ? '' : 'margin-bottom: 100px;'"
      :headers="historyHeaders"
      :items="payRateHistory"
      :pageSize="(payRateHistory || []).length"
    >
      <template v-slot:body="{ item, index }">
        <td>{{ item.effectiveDate | MMDDYYYY }}</td>
        <td>{{ item.payRate | currencyUSD }}</td>
        <td>{{ item.payRateOvertime | currencyUSD }}</td>
        <td>
          <f7-chip
            slot="after"
            :text="statusPayRate(item, index).text"
            :color="statusPayRate(item, index).color"
          ></f7-chip>
        </td>
        <td v-if="statusPayRate(item, index).text === 'Future'">
          <f7-link
            class="margin-right-half"
            icon-f7="pencil"
            color="blue"
            @click="openEditPayRate(item.id)"
          ></f7-link>
          <f7-link
            icon-f7="trash"
            color="red"
            @click="openDeletePayRate(item.id)"
          ></f7-link>
        </td>
        <td v-else></td>
      </template>
    </data-table>

    <add-new-pay-rate-popup
      :isShow="isShowPayRatePopup"
      :isEdit="isEdit"
      @close="closePayRatePopup"
    ></add-new-pay-rate-popup>
  </f7-page>
</template>
<script>
import DataTable from '@/components/datatables';
import AddNewPayRatePopup from '@/plugins/time-tracking/components/popups/AddNewPayRatePopup.vue';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
export default {
  components: {
    DataTable,
    AddNewPayRatePopup,
  },

  data: () => {
    return {
      newPayRate: {},
      isShowPayRatePopup: false,
      currentDate: new Date(),
      isEdit: false,
    };
  },

  methods: {
    ...mapActions('time-tracking/pay-rate', [
      'bindPayRateListBy',
      'deletePayRate',
      'setPayRateId',
    ]),
    ...mapActions('time-tracking/user', ['getUser']),
    closePayRatePopup() {
      this.isShowPayRatePopup = false;
      this.isEdit = false;
    },
    openEditPayRate(id) {
      this.isShowPayRatePopup = true;
      this.isEdit = true;
      this.setPayRateId(id);
    },
    openDeletePayRate(id) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: 'Delete Pay Rate',
        content: 'Are you sure you want to to delete the Pay Rate?',
        textButton: 'Delete',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.deletePayRate(id).then(() => {
              _sefl.app.dialog.close();
            });
          }
        },
      });
    },
    latestPayRate(data) {
      const sorted = data.sort((a, b) => {
        return moment(b.effectiveDate) - moment(a.effectiveDate);
      });
      return sorted;
    },
    handleBack() {
      this.$f7router.back('/employee-pay-rate', { force: true });
    },
    statusPayRate(item, index) {
      const currentTime = this.currentDate.getTime();
      const effectiveDate = item?.effectiveDate?.getTime();
      const nextItem = this.payRateApplied[index - 1];
      const nextEffectiveDate = nextItem
        ? nextItem.effectiveDate.getTime()
        : null;

      if (effectiveDate > currentTime) {
        return { color: 'blue', text: 'Future' };
      }

      if (
        !nextEffectiveDate ||
        (effectiveDate <= currentTime &&
          nextEffectiveDate &&
          nextEffectiveDate > currentTime)
      ) {
        return { color: 'green', text: 'Current Pay Rate' };
      }

      return { color: 'gray', text: 'Previous' };
    },
  },

  computed: {
    ...mapGetters('time-tracking/pay-rate', ['payRateList']),
    ...mapGetters('time-tracking/user', ['user']),
    historyHeaders() {
      return [
        {
          text: 'Effective Date',
          value: 'effectiveDate',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Pay Rate',
          value: 'payRate',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Pay Rate Overtime',
          value: 'payRateOvertime',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          align: 'left',
        },
      ];
    },
    payRateApplied() {
      const data = this.payRateList || [];
      return this.latestPayRate(data);
    },
    currentPayRate() {
      return {
        ...this.payRateApplied[0],
        name: this.user.displayName,
      };
    },
    payRateHistory() {
      let history = [];
      const list = _.cloneDeep(this.payRateApplied);
      if (list.length > 0) {
        for (let i = 0; i < list.length; i += 1) {
          history = [
            ...history,
            {
              effectiveDate: list[i].effectiveDate,
              payRate: list[i].payRate,
              payRateOvertime: list[i].payRateOvertime,
              id: list[i].id,
            },
          ];
        }
      }
      return history;
    },
  },
  created() {
    this.getUser(this.$f7route.params.id);
    this.bindPayRateListBy({
      prop: 'userId',
      val: this.$f7route.params.id,
      op: '==',
    });
  },
};
</script>
