import column from './column';
import card from './card';
import board from './board';
import user from './user';
import photo from './photo';
import album from './album';
import estimate from './estimate';
import proposal from './proposal';
import history from './history';
import workOrder from './work-order';
import finance from './finance';
import appConstant from './app-constant';
import client from './client';
import emailTemplate from './email-template';
import group from './group';
export default tenant => {
  return {
    namespaced: true,
    modules: {
      column: column(tenant),
      card: card(tenant),
      board: board(tenant),
      user: user,
      photo: photo(tenant),
      album: album(tenant),
      estimate: estimate(tenant),
      proposal: proposal(tenant),
      history: history(tenant),
      'work-order': workOrder(tenant),
      finance: finance(tenant),
      'app-constant': appConstant(tenant),
      client: client(tenant),
      'email-template': emailTemplate(tenant),
      group: group(tenant),
    },
  };
};
