export default {
  productList: state => {
    return state.productList;
  },
  product: state => state.product,
  productByID: state => id =>
    state.productList.find(item => item.id === id) || {},
  productColors: state => {
    return state.productList
      .map(r => r.color)
      .filter((value, index, self) => {
        //get unique values
        return value && self.indexOf(value) === index;
      });
  },
  productsByPriceListRefsList: state => {
    return state.hits;
  },

  order: state => state.order,
  searchText: state => state.searchText,

  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
};
