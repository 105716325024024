<template>
  <f7-popup
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="cancelUpdateProfile"
  >
    <f7-view>
      <f7-page>
        <form>
          <f7-navbar>
            <f7-nav-left>
              <f7-link popup-close>Cancel</f7-link>
            </f7-nav-left>
            <f7-nav-title>Edit Profile</f7-nav-title>
            <f7-nav-right>
              <f7-link @click.native="save()">Done</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <f7-list
            class="justify-content-center align-items-center display-flex"
          >
            <upload-avatar
              textUpload="Edit"
              :avatarSize="70"
              :imageUrl="photoURL"
              :name="displayName"
              @upload="upload"
              :isEdit="true"
            />
          </f7-list>
          <f7-list
            inline-labels
            class="components-list searchbar-found"
            @submit.prevent="add"
          >
            <!-- displayName -->
            <f7-list-input
              label="Full Name"
              placeholder="John Doe"
              :value="displayName"
              @input="updateField('displayName', $event.target.value.trim())"
              error-message-force
              clear-button
              :error-message="newNameErrorMessage"
            >
              <required-asterisk slot="label" />
            </f7-list-input>
            <!-- email -->
            <f7-list-input
              :disabled="!isPhoneLogin"
              label="Email"
              placeholder="john.doe@email.com"
              :value="email"
              @input="updateField('email', $event.target.value.trim())"
              error-message-force
              :error-message="newEmailErrorMessage"
            >
              <!-- <required-asterisk slot="label" /> -->
            </f7-list-input>
            <!-- phoneNumber -->
            <f7-list-input
              :disabled="isPhoneLogin"
              label="Phone"
              :value="phoneNumber"
              @input="updateField('phoneNumber', $event.target.value.trim())"
              error-message-force
              v-mask="'(+1) ###-###-####'"
              :error-message="newPhoneErrorMessage"
            >
              <required-asterisk
                slot="label"
                v-show="!isPhoneLogin"
              />
            </f7-list-input>
            <f7-list-input
              label="Set Default Screen"
              type="select"
              defaultValue=""
              :value="customClaims.homeUrl"
              @change="customClaims.homeUrl = $event.target.value"
            >
              <option
                value=""
                disabled
                hidden
              >
                Select default screen
              </option>
              <option
                v-for="(menu, index) in menuOptions"
                :key="index"
                :value="menu.path"
              >
                {{ menu.displayName }}
              </option>
            </f7-list-input>
          </f7-list>
        </form>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import UploadAvatar from '@/components/uploads/UploadAvatar.vue';
import { mask } from 'vue-the-mask';
import {
  LOGIN_METHOD_EMAIL_BY_PHONE,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';

export default {
  directives: { mask },

  components: {
    UploadAvatar,
  },

  props: {
    popupOpened: { type: Boolean, default: false },
  },

  data: () => {
    return {
      displayName: '',
      email: '',
      phoneNumber: '',
      photoURL: '',
      customClaims: {
        homeUrl: '',
      },
    };
  },

  computed: {
    ...mapGetters('setting/profile-page/profile', ['user']),
    ...mapGetters('setting/profile-page/menu', ['menus']),
    ...mapGetters('setting/profile-page/group', ['groups']),
    ...mapGetters('common/administration-user', ['userForGroup']),

    menuOptions() {
      const groupByIds = this.groups.filter(r =>
        (this.userForGroup.groupIDs || []).includes(r.id)
      );
      let menuIds = [];
      groupByIds.forEach(r => {
        menuIds = menuIds.concat(r.menuIDs);
      });
      menuIds = [...new Set(menuIds)];
      const menus = this.menus.filter(r => menuIds.includes(r.id));
      return menus;
    },

    isPhoneLogin() {
      if (this.user.customClaims) {
        return (
          this.user.customClaims.loginMethod === LOGIN_METHOD_EMAIL_BY_PHONE
        );
      }

      return false;
    },

    newNameErrorMessage() {
      if (!this.v$.displayName.$error) return null;
      if (this.v$.displayName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    newEmailErrorMessage() {
      if (!this.v$.email.$error) return null;
      if (this.v$.email.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.email.commonEmailRule.$invalid)
        return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },

    newPhoneErrorMessage() {
      if (!this.v$.phoneNumber.$error) return null;
      if (this.v$.phoneNumber.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.phoneNumber.minLength.$invalid)
        return VALIDATION_MESSAGE.PHONE_NUMBER_FORMAT;
      // if (!this.v$.phoneNumber.commonPhoneRule) return VALIDATION_MESSAGE.INVALID_PHONE_NUMBER;
      return null;
    },
  },

  methods: {
    ...mapActions('setting/profile-page/profile', [
      'updateUserProfile',
      'uploadAvatar',
      'removeAvatar',
      'updateAvatarRef',
    ]),

    upload(file) {
      this.$f7.preloader.show();
      if (this.customClaims && this.customClaims.avatarRef) {
        // for the exist user, upload and then save data
        this.removeAvatar(this.customClaims.avatarRef);
      }

      this.uploadAvatar(file).then(data => {
        this.updateUserProfile({
          photoURL: data.url,
        }).then(() => {
          this.updateAvatarRef(data.fullPath);
          this.$f7.preloader.hide();
        });
      });
    },

    updateField(fieldName, value) {
      this[fieldName] = value;
      this.v$[fieldName].$touch();
    },

    cancelUpdateProfile() {
      this.v$.$reset();
      this.$emit('close');
    },

    save() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.$f7.preloader.show();

      this.updateUserProfile({
        // phoneNumber: this.phoneNumber.replace(/\(|\)|-| /g, ""),
        displayName: this.displayName,
        // email: this.email,
        customClaims: _.cloneDeep(this.customClaims),
      })
        .then(() => {
          this.$f7.preloader.hide();
          this.$emit('close');
        })
        .catch(error => {
          this.$f7.preloader.hide();
          this.$f7.notification
            .create({
              // icon: '<i class="icon icon-f7"></i>',
              title: 'Error',
              closeTimeout: 15000,
              closeButton: true,
              text: error.message,
              closeOnClick: true,
            })
            .open();
        });
    },

    formatPhone(val) {
      let numberFormat = (val || '').slice(2);
      if (numberFormat) {
        return (
          '(+1) ' +
          numberFormat.substring(0, 3) +
          '-' +
          numberFormat.substring(3, 6) +
          '-' +
          numberFormat.substring(6)
        );
      }
      return '';
    },

    async cloneUserInfo() {
      this.phoneNumber = this.formatPhone(this.user.phoneNumber);
      this.displayName = this.user.displayName;
      this.email = this.user.email;
      this.customClaims = _.cloneDeep(this.user.customClaims);
      this.photoURL = this.user.photoURL;
    },
  },

  watch: {
    user: {
      deep: true,
      handler(val) {
        if (!_.isEmpty(val)) {
          this.cloneUserInfo();
        }
      },
    },
    popupOpened: {
      deep: true,
      handler(val) {
        if (val === true) {
          this.cloneUserInfo(val);
        }
      },
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      displayName: {
        required,
      },
      email: {
        required,
        commonEmailRule: email,
      },
      phoneNumber: {
        required,
        minLength: minLength(17),
      },
    };
  },
};
</script>
