<template>
  <f7-card :id="`${task.id}-${task.columnId}`">
    <div
      class="card-container"
      @click="openCard"
    >
      <f7-card-content class="break-work swimlane-card-content">
        <div class="swimlane-card-title swimlane-card-title_left">
          {{ task.title }}
        </div>

        <div
          class="display-flex justify-content-space-between align-items-center"
        >
          <div>
            <span
              class="card-number"
              style="background-color: green"
              >{{ task.cardNumber }}</span
            >
            <span
              v-show="task.dueDate"
              class="card-number"
              :style="`background-color: ${
                expiredDate ? 'red' : sameCurrentDate ? 'orange' : 'green'
              }`"
              >{{ toDisplayDateString(task.dueDate) || '' }}</span
            >
          </div>
          <div class="display-flex">
            <div
              v-for="(uid, index) in task.assigneeIDs || task.assigneeIds"
              :key="uid"
              :class="index < 2 ? 'assignee-avatar' : ''"
            >
              <user-avatar
                v-if="index < 2"
                :avatarSize="27"
                :imageUrl="userInfo(uid) ? userInfo(uid).photoURL : ''"
                :name="userInfo(uid) ? userInfo(uid).displayName : ''"
              ></user-avatar>
            </div>
            <div
              v-if="(task.assigneeIDs || task.assigneeIds || []).length > 2"
              class="assignee-avatar more-assignee-avatar"
            >
              <span>{{
                `+${(task.assigneeIDs || task.assigneeIds).length - 2}`
              }}</span>
            </div>
          </div>
        </div>
      </f7-card-content>
    </div>
  </f7-card>
</template>

<script>
import UserAvatar from '@/components/avatars/UserAvatar.vue';
import { mapGetters } from 'vuex';
import { toDateCalendar, toDisplayDateString } from '../../../utility/datetime';
import moment from 'moment';

export default {
  components: { UserAvatar },

  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters('swimlane/user', ['userList']),

    userInfo() {
      return uid =>
        this.userList.find(user => {
          return user.uid === uid;
        });
    },

    expiredDate() {
      if (!this.task.dueDate) return false;
      return moment(this.task.dueDate.toDate()).isBefore(moment(), 'day');
    },

    sameCurrentDate() {
      if (!this.task.dueDate) return false;
      return moment(this.task.dueDate.toDate()).isSame(moment(), 'day');
    },
  },

  methods: {
    toDateCalendar(val) {
      return toDateCalendar(val);
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    openCard() {
      this.$emit('openCard');
    },
  },
};
</script>

<style lang="scss" scoped>
a.initial-color {
  color: initial;
}

.card-container {
  cursor: pointer;
}

.break-work {
  word-break: break-all;
}

.swimlane-card-content {
  padding: 8px;
}

.swimlane-card-title {
  margin-bottom: 1em;
}

.assignee-avatar {
  margin-left: 3px;
}

.swimlane-card-duedate {
  color: var(--f7-theme-color);
  border-color: var(--f7-theme-color);
}
.card-number {
  border-radius: 4px;
  color: white;
  padding: 2px 5px;
  font-size: 12px;
  margin-right: 5px;
}
.more-assignee-avatar {
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--f7-color-chip-neutral);
  font-size: 12px;
}
</style>
