import * as types from './types';
import Vue from 'vue';
import _ from 'lodash';

export default {
  setContactList(state, payload) {
    Vue.set(state, 'contactList', payload);
  },
  setContactSubscribe(state, payload) {
    Vue.set(state, 'contactSubscribe', payload);
  },
  setCompanyList(state, payload) {
    Vue.set(state, 'companyList', payload);
  },
  setCompanySubscribe(state, payload) {
    Vue.set(state, 'companySubscribe', payload);
  },
  setPropertyList(state, payload) {
    Vue.set(state, 'propertyList', payload);
  },
  setPropertySubscribe(state, payload) {
    Vue.set(state, 'propertySubscribe', payload);
  },
  setMessageList(state, payload) {
    Vue.set(state, 'messageList', payload);
  },
  setMessageSubscribe(state, payload) {
    Vue.set(state, 'messageSubscribe', payload);
  },
  [types.SET_CURRENT_CONTACT](state, contact) {
    if (!_.isEmpty(contact)) {
      // set current contact
      Vue.set(state, 'contactCompanyRefs', []);
      Vue.set(state, 'contactPropertyRefs', []);

      Vue.set(state, 'contact', contact);

      if (!_.isEmpty(contact.companyRefs)) {
        let index = 0;
        // get companies ref info
        contact.companyRefs.forEach(ref => {
          // get company info
          const company = state.companyList.find(company => {
            return company.id === ref.id;
          });

          // merge two objects
          const element = { ...company, ...ref };

          Vue.set(state.contactCompanyRefs, index++, element);
        });
      }

      if (!_.isEmpty(contact.propertyRefs)) {
        let index = 0;

        contact.propertyRefs.forEach(ref => {
          const property = state.propertyList.find(property => {
            return property.id === ref.id;
          });

          const element = { ...property, ...ref };

          Vue.set(state.contactPropertyRefs, index++, element);
        });
      }
    } else {
      Vue.set(state, 'contactCompanyRefs', []);
      Vue.set(state, 'contactPropertyRefs', []);

      Vue.set(state, 'contact', {});
    }
  },
  [types.SET_USERS_LIST](state, users) {
    Vue.set(state, 'users', users);
  },
  [types.SET_SEARCH_VALUE](state, value) {
    Vue.set(state, 'searchValue', value);
  },
  [types.SET_CURRENT_COMPANY](state, company) {
    if (!_.isEmpty(company)) {
      Vue.set(state, 'companyContactRefs', []);
      Vue.set(state, 'companyPropertyRefs', []);
      Vue.set(state, 'companyBranchRefs', []);

      Vue.set(state, 'company', company);

      if (!_.isEmpty(company.contactRefs)) {
        let index = 0;

        company.contactRefs.forEach(ref => {
          const contact = state.contactList.find(contact => {
            return contact.id === ref.id;
          });

          const element = { ...contact, ...ref };

          Vue.set(state.companyContactRefs, index++, element);
        });
      }

      if (!_.isEmpty(company.propertyRefs)) {
        let index = 0;

        company.propertyRefs.forEach(ref => {
          const property = state.propertyList.find(property => {
            return property.id === ref.id;
          });

          const element = { ...property, ...ref };

          Vue.set(state.companyPropertyRefs, index++, element);
        });
      }

      if (!_.isEmpty(company.branchRefs)) {
        let index = 0;

        company.branchRefs.forEach(ref => {
          const branch = state.companyList.find(company => {
            return company.id === ref.id;
          });

          const element = { ...branch, ...ref };

          Vue.set(state.companyBranchRefs, index++, element);
        });
      }
    } else {
      Vue.set(state, 'companyContactRefs', []);
      Vue.set(state, 'companyPropertyRefs', []);
      Vue.set(state, 'companyBranchRefs', []);
      Vue.set(state, 'company', {});
    }
  },

  [types.SET_CURRENT_PROPERTY](state, property) {
    if (!_.isEmpty(property)) {
      Vue.set(state, 'propertyContactRefs', []);
      Vue.set(state, 'propertyCompanyRefs', []);
      Vue.set(state, 'propertyProjectRefs', []);
      Vue.set(state, 'property', property);

      if (!_.isEmpty(property.contactRefs)) {
        let index = 0;

        property.contactRefs.forEach(ref => {
          const contact = state.contactList.find(contact => {
            return contact.id === ref.id;
          });

          const element = { ...contact, ...ref };

          Vue.set(state.propertyContactRefs, index++, element);
        });
      }

      if (!_.isEmpty(property.companyRefs)) {
        let index = 0;

        property.companyRefs.forEach(ref => {
          const company = state.companyList.find(company => {
            return company.id === ref.id;
          });

          const element = { ...company, ...ref };

          Vue.set(state.propertyCompanyRefs, index++, element);
        });
      }
    } else {
      Vue.set(state, 'propertyContactRefs', []);
      Vue.set(state, 'propertyCompanyRefs', []);
      Vue.set(state, 'propertyProjectRefs', []);
      Vue.set(state, 'property', {});
    }
  },

  [types.SET_CURRENT_CONTACT_DATA](state, data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        Vue.set(state.contact, key, value);
      }
    }
  },

  [types.SET_OLD_CONTACT_ID](state, id) {
    Vue.set(state, 'oldContactId', id);
  },

  [types.SET_CURRENT_COMPANY_DATA](state, data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        Vue.set(state.company, key, value);
      }
    }
  },

  [types.SET_OLD_COMPANY_ID](state, id) {
    Vue.set(state, 'oldCompanyId', id);
  },
  [types.SET_COMPANY_ACTIVE_TAB](state, tab) {
    Vue.set(state, 'companyDetailActiveTab', tab);
  },

  [types.SET_CURRENT_HISTORY](state, historyId) {
    let history = state.historyList.find(history => {
      return history.id === historyId;
    });
    Vue.set(state, 'history', history);
  },
  [types.SET_CREATED_PROPERTY_ID](state, id) {
    Vue.set(state, 'createdPropertyId', id);
  },
  [types.SET_CREATED_CONTACT_ID](state, id) {
    Vue.set(state, 'createdContactId', id);
  },
  [types.SET_CREATED_COMPANY_ID](state, id) {
    Vue.set(state, 'createdCompanyId', id);
  },
  [types.SET_IS_EDIT_COMPANY](state, value) {
    Vue.set(state, 'isEditCompany', value);
  },
  [types.SET_CONTACT_COMPANY_REFS](state, ref) {
    state.contactCompanyRefs.push(ref);
  },
  [types.SET_COMPANY_BRANCH_REFS](state, ref) {
    state.companyBranchRefs.push(ref);
  },
  [types.SET_IS_EDIT_CONTACT](state, value) {
    Vue.set(state, 'isEditContact', value);
  },
  [types.SET_BRANCH](state, value) {
    Vue.set(state, 'branch', value);
  },
  [types.SET_IS_NEW_COMPANY](state, value) {
    Vue.set(state, 'isNewCompany', value);
  },
  [types.SET_TEMP_CONTACT](state, value) {
    Vue.set(state, 'tempContact', value);
  },
  [types.SET_COMPANY_CONTACT_REFS](state, ref) {
    state.companyContactRefs.push(ref);
  },
  [types.SET_COMPANY_PROPERTY_REFS](state, ref) {
    state.companyPropertyRefs.push(ref);
  },
  [types.SET_PROPERTY_CONTACT_REFS](state, ref) {
    state.propertyContactRefs.push(ref);
  },
  [types.SET_IS_NEW_CONTACT](state, value) {
    Vue.set(state, 'isNewContact', value);
  },
  [types.SET_IS_EDIT_PROPERTY](state, value) {
    Vue.set(state, 'isEditProperty', value);
  },
  [types.SET_REMOVE_COMPANY_BRANCH_REFS](state, branchId) {
    const index = state.companyBranchRefs.findIndex(
      branch => branch.id === branchId
    );
    if (index !== -1) {
      state.companyBranchRefs.splice(index, 1);
    }
  },
  [types.UNSET_PROPERTY_COMPANY_REFS](state, data) {
    if (data.propertyId) {
      const index = state.companyPropertyRefs.findIndex(
        property => property.id === data.propertyId
      );
      if (index !== -1) {
        state.companyPropertyRefs.splice(index, 1);
      }
    } else if (data.companyId) {
      const index = state.propertyCompanyRefs.findIndex(
        company => company.id === data.companyId
      );
      if (index !== -1) {
        state.propertyCompanyRefs.splice(index, 1);
      }
    }
  },
  [types.UNSET_COMPANY_CONTACT_REFS](state, data) {
    if (data.contactId) {
      const index = state.companyContactRefs.findIndex(
        contact => contact.id === data.contactId
      );
      if (index !== -1) {
        state.companyContactRefs.splice(index, 1);
      }
    } else if (data.companyId) {
      const index = state.contactCompanyRefs.findIndex(
        company => company.id === data.companyId
      );
      if (index !== -1) {
        state.contactCompanyRefs.splice(index, 1);
      }
    }
  },
};
