// src/store/actions.ts
import { defineStore } from 'pinia';
import { state, State } from './state';
import { FirebaseActions, firebase } from '../../services/firebase.service';
import safetyService from '../../services/safety.service';
import * as constant from '../../utility/const';
import { searchClient } from '../../services/search.service';
import algoliaService from '../../services/algolia.service';

const auth = firebase.auth();
let tenantValue: string;
let SafetyActions: any;
let safetyIndexDesc: any;
let safetyIndexAsc: any;

export const initializeTenant = (tenant: string) => {
  tenantValue = tenant;
  SafetyActions = new FirebaseActions(
    `/system_client/${tenantValue}/safety`,
    'safety'
  );
  safetyIndexDesc = searchClient.initIndex(
    `${tenantValue}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  safetyIndexAsc = searchClient.initIndex(
    `${tenantValue}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );
};

interface Safety {
  safetyNumber?: string;
  title: string;
  status: string;
  fileName?: string;
}

interface SearchResult {
  hitsPerPage: number;
  hits: Safety[];
  nbHits: number;
  nbPages: number;
  page: number;
}

export const useSafetyStore = defineStore('safety', {
  state: (): State => state,
  actions: {
    async getSafety() {
      const data = await SafetyActions.getDocumentBys([
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);
      this.$state.safetyList = data;
    },
    async updateSafety(id: string, doc: Safety) {
      await SafetyActions.updateDocument('', { id, doc });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenantValue,
        collection: 'safety',
        collectionNameCopy: '',
        id: id,
      });

      await this.searchSafety([]);
    },
    async deleteSafety(id: string) {
      await SafetyActions.updateDocument('', { id, doc: { isDeleted: true } });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenantValue,
        collection: 'safety',
        collectionNameCopy: '',
        id: id,
      });
      await this.searchSafety([]);
    },
    async createNewSafety(data: Safety) {
      const safety = await safetyService.addSafetyDoc(tenantValue, data);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenantValue,
        collection: 'safety',
        collectionNameCopy: '',
        id: safety.id,
      });
      await this.searchSafety([]);
      return safety;
    },
    setSafetyNumber(value: string) {
      this.$state.safetyNumber = value;
    },
    setSearchText(searchText: string) {
      this.$state.searchText = searchText;
    },
    setSearchResult(result: SearchResult) {
      this.$state.hitsPerPage = result.hitsPerPage;
      this.$state.hits = result.hits;
      this.$state.nbHits = result.nbHits;
      this.$state.nbPages = result.nbPages;
      this.$state.page = result.page;
    },
    resetSearch() {
      this.$state.hits = [];
      this.$state.nbHits = 0;
      this.$state.nbPages = 0;
      this.$state.page = 0;
      this.$state.hitsPerPage = 25;
      this.$state.order = 'desc';
    },
    addMoreResult(result: SearchResult) {
      for (const hit of result.hits) {
        this.$state.hits.push(hit);
      }
      this.$state.hitsPerPage = result.hitsPerPage;
      this.$state.nbHits = result.nbHits;
      this.$state.nbPages = result.nbPages;
      this.$state.page = result.page;
    },
    async searchSafety(attributesToRetrieve: string[]): Promise<SearchResult> {
      const index =
        this.$state.order === 'asc' ? safetyIndexAsc : safetyIndexDesc;
      const requestOptions = {
        hitsPerPage: this.$state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_SAFETY}`,
        attributesToRetrieve: attributesToRetrieve.length
          ? attributesToRetrieve
          : constant.SAFETY_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          'safetyNumber',
          'title',
          'status',
          'fileName',
        ],
      };

      try {
        const result = await index.search(state.searchText, requestOptions);
        this.setSearchResult(result as SearchResult);
        return result as SearchResult;
      } catch (error) {
        console.error((error as Error).message);
        return { hitsPerPage: 0, hits: [], nbHits: 0, nbPages: 0, page: 0 };
      }
    },
    async loadMoreSafety(
      attributesToRetrieve: string[],
      page: number
    ): Promise<SearchResult> {
      const index =
        this.$state.order === 'asc' ? safetyIndexAsc : safetyIndexDesc;
      const requestOptions = {
        hitsPerPage: this.$state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_SAFETY}`,
        attributesToRetrieve: attributesToRetrieve.length
          ? attributesToRetrieve
          : constant.SAFETY_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          'safetyNumber',
          'title',
          'status',
          'fileName',
        ],
      };

      try {
        const result = await index.search(state.searchText, requestOptions);
        // this.setSearchResult(result as SearchResult);
        this.addMoreResult(result as SearchResult);
        return result as SearchResult;
      } catch (error) {
        console.error((error as Error).message);
        return { hitsPerPage: 0, hits: [], nbHits: 0, nbPages: 0, page: 0 };
      }
    },
  },
});
