import activity from './activity';
import client from './client';
import estimate from './estimate';
import proposal from './proposal';
import contract from './contract';
import project from './project';
import projectInactive from './project-inactive';
import photo from './photo';
import schedule from './schedule';
import workOrder from './work-order';
import productItem from './product-item';
import roofType from './roof-type';
import priceList from './price-list';
import subCategory from './sub-category';
import category from './category';
import finance from './finance';
import appConstant from './app-constant';
import webContactInfo from './web-contact-info';
import swimlane from './swimlane';
import scheduleSetting from './schedule-setting';
import album from './album';
import history from './history';
import template from './template';
import emailNotification from './email-notification';
import protestInsuranceClaim from './protest-insurance-claim';
import teamManagement from './team-management';
import employeeScheduling from './employee-scheduling';
import qaChecklist from './qa-checklist';
import agreement from './agreement';
import certificate from './certificate';
import letter from './letter';
import common from './common';
import shingleType from './shingle-type';
import group from './group';
export default tenant => {
  return {
    namespaced: true,
    modules: {
      'app-constant': appConstant(tenant),
      activity: activity(tenant),
      project: project(tenant),
      'project-inactive': projectInactive(tenant),
      photo: photo(tenant),
      album: album(tenant),
      client: client(tenant),
      estimate: estimate(tenant),
      proposal: proposal(tenant),
      contract: contract(tenant),
      schedule: schedule(tenant),
      'work-order': workOrder(tenant),
      'product-item': productItem(tenant),
      'roof-type': roofType(tenant),
      'price-list': priceList(tenant),
      'sub-category': subCategory(tenant),
      category: category(tenant),
      finance: finance(tenant),
      'web-contact-info': webContactInfo(tenant),
      swimlane: swimlane(tenant),
      'schedule-setting': scheduleSetting(tenant),
      history: history(tenant),
      template: template(tenant),
      'email-notification': emailNotification(tenant),
      'protest-insurance-claim': protestInsuranceClaim(tenant),
      'team-management': teamManagement(tenant),
      'employee-scheduling': employeeScheduling(tenant),
      'qa-checklist': qaChecklist(tenant),
      agreement: agreement(tenant),
      certificate: certificate(tenant),
      letter: letter(tenant),
      common: common,
      'shingle-type': shingleType(tenant),
      group: group(tenant),
    },
  };
};
