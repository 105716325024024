<template>
  <div>
    <f7-popup
      :opened="isPopupOpened"
      @popup:closed="handlePopupClosed"
      @popup:opened="() => {}"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>Edit Proposal Verbiage</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="saveAndClose">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-block-title>{{ verbiageItem.name }}</f7-block-title>
        <!-- <f7-block> -->
        <f7-text-editor
          :value="value"
          :buttons="buttons"
          @texteditor:change="value = $event"
        ></f7-text-editor>
        <!-- </f7-block> -->
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPopupOpened: false,
      verbiageItem: {},
      value: '',
      buttons: [
        ['bold', 'italic', 'underline', 'textColor'],
        ['orderedList', 'unorderedList'],
        // ["link", "image"],
        [/*"paragraph",*/ 'h1', 'h2', 'h3'],
        ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
        ['subscript', 'superscript'],
        ['indent', 'outdent'],
      ],
    };
  },

  methods: {
    openPopup(item) {
      this.verbiageItem = item;
      this.value = item.value;
      this.isPopupOpened = true;
    },

    handlePopupClosed() {
      this.verbiageItem = {};
      this.value = '';
      this.isPopupOpened = false;
    },

    saveAndClose() {
      const self = this;
      this.$ri.dialog.openWarningDialog({
        title: `Edit Proposal Verbiage`,
        content: `Are you sure you want to save your editing?`,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            self.$emit('onDone', {
              id: self.verbiageItem.id,
              value: self.value,
              callback: () => {
                self.isPopupOpened = false;
              },
            });
          }
        },
      });
    },
  },
};
</script>

<style></style>
