import * as types from './types';
import userService from '../../../../services/user.service';

export default {
  async getUserList({ commit }) {
    const users = await userService.getUserList();
    await commit(types.SET_USERS_LIST, users);
    return;
  },
  setUserId({ commit }, id) {
    commit(types.SET_USER_ID, id);
  },
  updateUser(_, { uid, user }) {
    return userService.updateUser(uid, user).then(updatedUser => {
      return updatedUser;
    });
  },
};
