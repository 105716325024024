import _ from 'lodash';
import { COMPANY_TYPE_VENDOR } from '../../../../utility/const';

export default {
  contactGroup:
    state =>
    (searchValue = '') => {
      let group = [];
      let contacts =
        searchValue != ''
          ? state.contactList.filter(contact => {
              let contactFirstName = contact.firstName
                ? contact.firstName.toLowerCase()
                : '';
              let contactLastName = contact.lastName
                ? contact.lastName.toLowerCase()
                : '';

              let contactName = contactFirstName + ' ' + contactLastName;
              return (
                contactFirstName.indexOf(searchValue.toLowerCase()) >= 0 ||
                contactLastName.indexOf(searchValue.toLowerCase()) >= 0 ||
                contactName.trim().indexOf(searchValue.toLowerCase()) >= 0
              );
            }) || []
          : state.contactList;

      contacts.forEach(contact => {
        let letter = contact.firstName
          ? contact.firstName.charAt(0).toUpperCase()
          : '' || contact.lastName
            ? contact.lastName.charAt(0).toUpperCase()
            : '';
        let index = group.findIndex(groupName => {
          return letter === Object.keys(groupName)[0];
        });
        if (index >= 0) {
          group[index][letter].push(contact);
        } else {
          group.push({ [letter]: [contact] });
        }
      });

      group.forEach(data => {
        let letter = Object.keys(data)[0];
        data[letter].sort((a, b) => {
          return (a.firstName ? a.firstName.charAt(1).toUpperCase() : 0) <
            (b.firstName ? b.firstName.charAt(1).toUpperCase() : 0)
            ? -1
            : 0;
        });
      });

      return group.sort((a, b) => {
        return Object.keys(b)[0] > Object.keys(a)[0] ? -1 : 0;
      });
    },

  companyGroup:
    state =>
    (searchValue = '', showAll = true) => {
      let group = [];
      // show all except Vendor
      let newCompanyList = showAll
        ? state.companyList
        : state.companyList.filter(
            item => !_.includes(item.companyTypes, COMPANY_TYPE_VENDOR)
          );

      let companies =
        searchValue != ''
          ? newCompanyList.filter(company => {
              let companyName = company.companyName
                ? company.companyName.toLowerCase()
                : '';

              return companyName.indexOf(searchValue.toLowerCase()) >= 0;
            }) || []
          : newCompanyList;

      companies.forEach(company => {
        let letter = company.companyName
          ? company.companyName.charAt(0).toUpperCase()
          : '';
        let index = group.findIndex(groupName => {
          return letter === Object.keys(groupName)[0];
        });
        if (index >= 0) {
          group[index][letter].push(company);
        } else {
          group.push({ [letter]: [company] });
        }
      });

      group.forEach(data => {
        let letter = Object.keys(data)[0];
        data[letter].sort((a, b) => {
          return (a.companyName ? a.companyName.charAt(0).toUpperCase() : '') <
            (b.companyName ? b.companyName.charAt(0).toUpperCase() : '')
            ? -1
            : 0;
        });
      });

      return group.sort((a, b) => {
        return Object.keys(b)[0] > Object.keys(a)[0] ? -1 : 0;
      });
    },

  propertyGroup:
    state =>
    (searchValue = '') => {
      let group = [];

      let properties =
        searchValue != ''
          ? state.propertyList.filter(property => {
              let propertyName = property.propertyName
                ? property.propertyName.toLowerCase()
                : '';

              return propertyName.indexOf(searchValue.toLowerCase()) >= 0;
            }) || []
          : state.propertyList;

      properties.forEach(property => {
        let letter = property.propertyName
          ? property.propertyName.charAt(0).toUpperCase()
          : '';
        let index = group.findIndex(groupName => {
          return letter === Object.keys(groupName)[0];
        });
        if (index >= 0) {
          group[index][letter].push(property);
        } else {
          group.push({ [letter]: [property] });
        }
      });

      group.forEach(data => {
        let letter = Object.keys(data)[0];
        data[letter].sort((a, b) => {
          return (a.propertyName
            ? a.propertyName.charAt(1).toUpperCase()
            : '') <
            (b.propertyName ? b.propertyName.charAt(1).toUpperCase() : '')
            ? -1
            : 0;
        });
      });

      return group.sort((a, b) => {
        return Object.keys(b)[0] > Object.keys(a)[0] ? -1 : 0;
      });
    },

  listEmails: state => {
    var listEmails = [];
    //email from contact
    state.contactList &&
      state.contactList.forEach(contact => {
        let emails = contact.emails;
        emails &&
          emails.forEach(email => {
            if (!listEmails.includes(email.value)) {
              listEmails.push(email.value);
            }
          });
      });

    //email from company
    state.companyList &&
      state.companyList.forEach(company => {
        let others = company.others;
        others &&
          others.forEach(other => {
            if (other.code == 'email' && !listEmails.includes(other.value)) {
              listEmails.push(other.value);
            }
          });
      });

    //email from useers
    state.users &&
      state.users.forEach(user => {
        if (!listEmails.includes(user.email)) {
          listEmails.push(user.email);
        }
      });

    return listEmails;
  },
  contactList: state => state.contactList,
  companyList: state => state.companyList,
  propertyList: state => state.propertyList,
  messageList: state => state.messageList,
  historyList: state => state.historyList,
  createdPropertyId: state => state.createdPropertyId,
  createdContactId: state => state.createdContactId,
  createdCompanyId: state => state.createdCompanyId,

  contact: state => state.contact,
  company: state => state.company,
  property: state => state.property,
  history: state => state.history,

  contactCompanyRefs: state => state.contactCompanyRefs,
  contactPropertyRefs: state => state.contactPropertyRefs,
  contactProjectRefs: state => state.contactProjectRefs,

  propertyContactRefs: state => state.propertyContactRefs,
  propertyCompanyRefs: state => state.propertyCompanyRefs,
  propertyProjectRefs: state => state.propertyProjectRefs,

  companyContactRefs: state => state.companyContactRefs,
  companyPropertyRefs: state => state.companyPropertyRefs,
  companyBranchRefs: state => state.companyBranchRefs,
  companyProjectRefs: state => state.companyProjectRefs,

  companyDetailActiveTab: state => state.companyDetailActiveTab,
  isNewContact: state => state.isNewContact,
  oldCompanyId: state => state.oldCompanyId,
  oldContactId: state => state.oldContactId,
  isEditCompany: state => state.isEditCompany,
  searchValue: state => state.searchValue,
  isEditContact: state => state.isEditContact,
  branch: state => state.branch,
  isNewCompany: state => state.isNewCompany,
  isEditProperty: state => state.isEditProperty,
  tempContact: state => state.tempContact,
};
