// src/store/actions.ts
import { defineStore } from 'pinia';
import { state, State } from './state';
import { FirebaseActions, firebase } from '../../services/firebase.service';
import * as constant from '../../utility/const';
import { searchClient } from '../../services/search.service';
import algoliaService from '../../services/algolia.service';
import { toDateFirebase } from '@/utility/datetime';

const auth = firebase.auth();
let tenantValue: string;
let myCertificatesActions: any;
let myCertificatesIndexDesc: any;
let myCertificatesIndexAsc: any;

export const initializeTenant = (tenant: string) => {
  tenantValue = tenant;
  myCertificatesActions = new FirebaseActions(
    `/system_client/${tenantValue}/my_certificates`,
    'myCertificates'
  );
  myCertificatesIndexDesc = searchClient.initIndex(
    `${tenantValue}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  myCertificatesIndexAsc = searchClient.initIndex(
    `${tenantValue}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );
};

interface MyCertificates {
  certificateAuthority: string;
  certificateName: string;
  certificateNumber?: string;
  completionDate: Date[] | firebase.firestore.Timestamp;
  expiredDate?: Date[] | firebase.firestore.Timestamp;
  note?: string;
  status: string;
  attachmentFiles?: Array<any>;
}

interface SearchResult {
  hitsPerPage: number;
  hits: MyCertificates[];
  nbHits: number;
  nbPages: number;
  page: number;
}

export const useMyCertificatesStore = defineStore('myCertificates', {
  state: (): State => state,
  actions: {
    async bindMyCertificates() {
      return myCertificatesActions.bindCollectionBy({
        prop: 'isDeleted',
        val: false,
        op: '==',
      });
    },
    async getMyCertificates() {
      const data = await myCertificatesActions.getDocumentBys([
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);
      this.$state.myCertificatesList = data;
    },
    async updateMyCertificates(
      id: string,
      doc: MyCertificates,
      filters: string
    ) {
      await myCertificatesActions.updateDocument('', { id, doc });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenantValue,
        collection: 'my_certificates',
        collectionNameCopy: '',
        id: id,
      });

      await this.searchMyCertificates([], filters);
    },
    async deleteMyCertificate(id: string, filter: string) {
      await myCertificatesActions.updateDocument('', {
        id,
        doc: { isDeleted: true },
      });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenantValue,
        collection: 'my_certificates',
        collectionNameCopy: '',
        id: id,
      });
      await this.searchMyCertificates([], filter);
    },
    async createNewMyCertificate(data: MyCertificates, filter: string) {
      const certificateId = await myCertificatesActions.createDocument(
        '',
        data
      );

      await algoliaService.algoliaUpdateItem({
        tenantId: tenantValue,
        collection: 'my_certificates',
        collectionNameCopy: '',
        id: certificateId,
      });
      await this.searchMyCertificates([], filter);
      return certificateId;
    },
    setSearchText(searchText: string) {
      this.$state.searchText = searchText;
    },
    setSearchResult(result: SearchResult) {
      this.$state.hitsPerPage = result.hitsPerPage;
      this.$state.hits = result.hits;
      this.$state.nbHits = result.nbHits;
      this.$state.nbPages = result.nbPages;
      this.$state.page = result.page;
    },
    resetSearch() {
      this.$state.hits = [];
      this.$state.nbHits = 0;
      this.$state.nbPages = 0;
      this.$state.page = 0;
      this.$state.hitsPerPage = 25;
      this.$state.order = 'desc';
    },
    setNumberOfRows(value: number) {
      this.$state.hitsPerPage = value;
    },
    async searchMyCertificates(
      attributesToRetrieve: string[],
      filters: string = ''
    ): Promise<SearchResult> {
      const index =
        this.$state.order === 'asc'
          ? myCertificatesIndexAsc
          : myCertificatesIndexDesc;
      // filters = `userId:${auth.currentUser?.uid}`;
      const requestOptions = {
        hitsPerPage: this.$state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_MY_CERTIFICATES}`,
        attributesToRetrieve: attributesToRetrieve.length
          ? attributesToRetrieve
          : constant.MY_CERTIFICATES_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          'certificateNumber',
          'certificateAuthority',
          'status',
          'certificateName',
        ],
        attributesToHighlight: [],
      };

      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      try {
        const result = await index.search(state.searchText, requestOptions);
        this.setSearchResult(result as SearchResult);
        return result as SearchResult;
      } catch (error) {
        console.error((error as Error).message);
        return { hitsPerPage: 0, hits: [], nbHits: 0, nbPages: 0, page: 0 };
      }
    },
    async loadMoreMyCertificates(
      attributesToRetrieve: string[],
      page: number
    ): Promise<SearchResult> {
      const index =
        this.$state.order === 'asc'
          ? myCertificatesIndexAsc
          : myCertificatesIndexDesc;
      const requestOptions = {
        hitsPerPage: this.$state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_MY_CERTIFICATES}`,
        attributesToRetrieve: attributesToRetrieve.length
          ? attributesToRetrieve
          : constant.MY_CERTIFICATES_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          'certificateNumber',
          'certificateAuthority',
          'status',
          'certificateName',
        ],
        attributesToHighlight: [],
      };

      try {
        const result = await index.search(state.searchText, requestOptions);
        this.setSearchResult(result as SearchResult);
        return result as SearchResult;
      } catch (error) {
        console.error((error as Error).message);
        return { hitsPerPage: 0, hits: [], nbHits: 0, nbPages: 0, page: 0 };
      }
    },
    async goToPage(pageName: string) {
      const index =
        this.$state.order === 'asc'
          ? myCertificatesIndexAsc
          : myCertificatesIndexDesc;
      let pageNumber = 0;
      if (
        typeof pageName === 'number' &&
        pageName >= 0 &&
        pageName < state.nbPages
      ) {
        pageNumber = pageName;
      } else {
        switch (pageName) {
          case 'next':
            pageNumber = state.page + 1;
            break;

          case 'prev':
            pageNumber = state.page - 1;
            break;

          case 'first':
            pageNumber = 0;
            break;

          case 'last':
            pageNumber = state.nbPages - 1;
            break;

          default:
            pageName = '0';
            break;
        }
      }
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: pageNumber,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_MY_CERTIFICATES}`,
        attributesToRetrieve:
          constant.MY_CERTIFICATES_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          'certificateNumber',
          'certificateAuthority',
          'status',
          'certificateName',
        ],
        attributesToHighlight: [],
      };

      try {
        const result = await index.search(state.searchText, requestOptions);
        this.setSearchResult(result as SearchResult);
        return result as SearchResult;
      } catch (error) {
        console.error((error as Error).message);
        return { hitsPerPage: 0, hits: [], nbHits: 0, nbPages: 0, page: 0 };
      }
    },
  },
});
