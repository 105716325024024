<template>
  <f7-page class="safety-list">
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Documents</f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-if="isOwner || isAdminGroup"
          icon-f7="plus"
          @click.native="openFileChooser"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <!-- <div class="search-fixed-header"> -->
    <div>
      <f7-searchbar
        style="flex: 1"
        disable-button-text
        placeholder="Search documents"
        :clear-button="true"
        class="search-list"
        @input="
          setSafetySearchText($event.target.value);
          onSearch();
        "
        @keypress.native.enter="onSearch()"
        @searchbar:clear="
          setSafetySearchText('');
          onSearch();
        "
        @searchbar:disable="
          setSafetySearchText('');
          onSearch();
        "
        :value="searchText"
      >
      </f7-searchbar>
    </div>
    <input
      v-show="false"
      ref="fileInput"
      type="file"
      multiple
      @change="openNewDocuments"
    />
    <SafetyList ref="safetyList"> </SafetyList>
    <AddNewSafetyDocumentPopup
      @done="onDone"
      :filesInput="filesInput"
      ref="addNewSafetyDocument"
    >
    </AddNewSafetyDocumentPopup>
  </f7-page>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onUnmounted,
  onMounted,
  ref,
  defineEmits,
} from 'vue';
import {
  firebase,
  uploadFile,
  removeFile,
  auth,
} from '../../../services/firebase.service';
import { useSafetyStore } from '../../../stores/safety-hub/actions';
import Menu from '../../../components/menu/Menu.vue';
import SafetyList from '../components/list/SafetyList.vue';
import AddNewSafetyDocumentPopup from '../components/popups/AddNewSafetyDocumentPopup.vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default defineComponent({
  components: {
    Menu,
    SafetyList,
    AddNewSafetyDocumentPopup,
  },
  computed: {
    ...mapGetters('setting/app/profile', ['currentUser', 'isOwner']),
    ...mapGetters('setting/app/group', ['isAdminGroup']),
  },
  setup() {
    const instance = getCurrentInstance() as any;

    const store = useSafetyStore();
    const router = instance.proxy.$f7router;
    const searchText = computed(() => store.searchText);
    const addNewSafetyDocument = ref<any>(null);
    const fileInput = ref<any>(null);
    const safetyList = ref<any>(null);
    const filesInput = ref([]);

    const openFileChooser = () => {
      fileInput.value.click();
    };
    const openNewDocuments = () => {
      const uploadFiles = fileInput.value.files;
      filesInput.value = Array.from(uploadFiles);
      const exist = checkExistFiles(uploadFiles);

      if (!exist && addNewSafetyDocument.value) {
        addNewSafetyDocument.value.open();
      } else {
        instance.proxy.$ri.dialog.openWarningDialog({
          title: 'Please choose another one',
          content: 'That file has already existed!',
          hideCancelButton: true,
          onClick: (_self: any, index: number) => {
            _self.app.dialog.close();
          },
        });
      }
      fileInput.value.value = '';
    };
    const onDone = (uploadFiles: any, titles: any[]) => {
      let value = [];
      for (const file of uploadFiles) {
        instance.proxy.$f7.preloader.show();
        const attachment = {
          fileName: file.name,
          fullPath: `safety/${file.name}`,
          url: '',
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser?.displayName || auth.currentUser?.email,
          title: titles[uploadFiles.indexOf(file)],
          status: 'active',
        };

        uploadFile(attachment.fullPath, file).then(url => {
          attachment.url = url;

          value.push(attachment);
          value.forEach(item => {
            store.createNewSafety(item).then(safety => {
              instance.proxy.$f7router.navigate(
                `/safety/${safety.safetyNumber}`
              );
              instance.proxy.$f7.preloader.hide();
            });
          });
        });
      }
    };
    const setSafetySearchText = (value: string) => {
      store.setSearchText(value);
    };
    const onSearch = _.debounce(() => {
      safetyList.value.init();
    }, 500);

    const checkExistFiles = (files: any) => {
      // Implement your logic for checking existing files
      return false;
    };
    onMounted(() => {
      store.getSafety();
      addNewSafetyDocument.value = instance.proxy.$refs.addNewSafetyDocument;
      fileInput.value = instance.proxy.$refs.fileInput;
      safetyList.value = instance.proxy.$refs.safetyList;
      onSearch();
    });
    // onBeforeUnmount: Được gọi trước khi component bị hủy
    onBeforeUnmount(() => {
      // destroyStore();
    });

    // onUnmounted: Được gọi sau khi component đã bị hủy
    onUnmounted(() => {});

    return {
      openNewDocuments,
      openFileChooser,
      filesInput,
      onDone,
      setSafetySearchText,
      onSearch,
      searchText,
    };
  },
});
</script>

<style scoped>
button {
  width: 100px;
  height: 50px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}
.safety-list ::v-deep .page-content {
  padding-top: calc(
    var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) +
      var(--f7-page-subnavbar-offset, 0px) +
      var(--f7-page-searchbar-offset, 0px) +
      var(--f7-page-content-extra-padding-top, 0px)
  );
  overflow: hidden;
}
</style>
