import { checkAuth } from '../../../services/authentication.service';
// import UserList from "../pages/UserList.vue";
// import UserDetails from "../pages/UserDetails.vue";
// import GroupList from "../pages/GroupList.vue";

export default [
  {
    path: '',
    title: 'Administration',
    name: 'administration-main',
    displayRoles: ['owner', 'user'],
    routes: [
      {
        path: '/administration/users',
        title: 'Users',
        parent: 'administration-main',
        name: 'administration-user',
        displayRoles: ['owner', 'user'],
        icon: 'person_badge_plus',
        // component: UserList,
        // beforeEnter: checkAuth,
        async: checkAuth(() => import('../pages/UserList.vue')),
        master: true,
        detailRoutes: [
          {
            path: '/administration/users/:uid',
            title: 'User Details',
            displayRoles: ['owner', 'user'],
            disableInGroup: true,
            // component: UserDetails,
            // beforeEnter: checkAuth,
            async: checkAuth(() => import('../pages/UserDetails.vue')),
          },
        ],
      },
      {
        path: '/administration/groups',
        title: 'Groups',
        parent: 'administration-main',
        name: 'administration-group',
        displayRoles: ['owner', 'user'],
        icon: 'person_2',
        // component: GroupList,
        // beforeEnter: checkAuth,
        async: checkAuth(() => import('../pages/GroupList.vue')),
        master: true,
        detailRoutes: [
          {
            name: 'GroupDetails',
            path: '/administration/groups/:id',
            title: 'Group Details',
            displayRoles: ['owner', 'user'],
            disableInGroup: true,
            // component: GroupDetails,
            // beforeEnter: checkAuth,
            async: checkAuth(() => import('../pages/GroupDetails.vue')),
          },
        ],
      },
      {
        path: '/administration/subscription',
        title: 'Subscription',
        parent: 'administration-main',
        name: 'administration-subscription',
        displayRoles: ['owner', 'user'],
        // disableInGroup: true,
        icon: 'text_badge_checkmark',
        async: checkAuth(() => import('../pages/Subscription.vue')),
      },
    ],
  },
];
