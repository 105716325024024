/* eslint-disable no-unused-vars */
import {
  FirebaseActions,
  firestore,
  StorageActions,
  firebase,
  auth,
} from '../../../../services/firebase.service';
import estimateService from '../../../../services/estimate.service';
import { searchClient } from '../../../../services/search.service';
import algoliaService from '../../../../services/algolia.service';

import {
  quantityCalculator,
  metalQuantityCalculator,
  productItemHashFunction,
  metalProductItemHashFunction,
  getWorkType,
  getBuildingInfoDefault,
} from '../../utility/calculation';

import {
  DEFAULT_STATUS_PRODUCT_ITEM,
  CALCULATION_PRODUCT_ITEM_COMMON,
  PROP_IS_DELETED,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_SERVICE,
  COLLECTION_ESTIMATE,
  ESTIMATE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
  ESTIMATE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
} from '../../../../utility/const';

import { getMainAddress } from '../../../../utility/address';

import _ from 'lodash';
import * as types from './types';
import { firestoreAction } from 'vuexfire';

export default tenant => {
  const BUILDING_OBJECT_NAME = 'building';
  const ESTIMATE_HISTORY_OBJECT_NAME = 'estimateHistory';
  const EstimateActions = new FirebaseActions(
    `/system_client/${tenant}/estimate`,
    'estimate',
    {
      modelSchema: {
        valley_LF: 'float',
        totals: 'float',
        total_amount: 'float',
        side_wall_LF: 'float',
        saleTax: 'float',
        ridge_LF: 'float',
        rake_LF: 'float',
        perimeter_LF: 'float',
        miscPercent: 'float',
        hip_LF: 'float',
        head_wall_LF: 'float',
        filed_SQFT: 'float',
        evae_LF: 'float',
        dataPercentage: 'float',
        fieldArea: 'float',
        wallArea: 'float',
        total_SQs: 'float',
        perimeter: 'float',
        panel_linear_FT: 'float',
        data: [
          {
            items: [
              {
                QTY: 'float',
                price: 'float',
                size: 'float',
                wasterFactor: 'float',
              },
            ],
          },
        ],
      },
    }
  );

  const TaxActions = new FirebaseActions(
    `/system_client/${tenant}/tax_rate`,
    'taxRate'
  );

  const estimateIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  const estimateIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    bindEstimateListBy: EstimateActions.bindCollectionBy,
    unbindEstimateList: EstimateActions.unbindCollection,

    bindEstimate: EstimateActions.bindDocument,
    unbindEstimate: EstimateActions.unbindDocument,

    // createEstimate: EstimateActions.createDocument,
    saveEstimate: EstimateActions.updateDocument,
    removeEstimate: EstimateActions.deleteDocument,

    getEstimateBys: EstimateActions.getDocumentBys,
    getEstimate: EstimateActions.getDocument,

    getTaxRateInfoBys: TaxActions.getDocumentBys,
    /**
     * [DEV-1231] Add 8.25% tax as automatic and 7% waste as automatic to the estimate
     * @param {*} param0
     * @returns
     */
    async getCurrentTaxInfoRate({ dispatch }) {
      let taxRateList = await dispatch('getTaxRateInfoBys', [
        { prop: 'isDeleted', op: '==', val: false },
        { prop: 'year', op: '==', val: new Date().getUTCFullYear() },
      ]);

      // If empty, try to get latest tax rate
      if (_.isEmpty(taxRateList)) {
        taxRateList = await dispatch('getTaxRateInfoBys', [
          { prop: 'isDeleted', op: '==', val: false },
        ]);
      }

      return _.isEmpty(taxRateList)
        ? {}
        : taxRateList
            .filter(
              item => item.startDate <= firebase.firestore.Timestamp.now()
            )
            .sort((a, b) => (a.startDate <= b.startDate ? 1 : -1))[0];
    },

    bindBuildingList(context, { estimateId }) {
      const payload = {
        collectionPath: `/system_client/${tenant}/estimate/${estimateId}/building`,
      };

      return firestoreAction((context, { collectionPath }) => {
        return context.bindFirestoreRef(
          `${BUILDING_OBJECT_NAME}List`,
          firestore.collection(collectionPath)
        );
      })(context, payload);
    },

    bindBuildingListBys(context, { estimateId, conditions = [] }) {
      const collectionPath = `/system_client/${tenant}/estimate/${estimateId}/building`;

      let ref = firestore.collection(collectionPath);
      conditions.forEach(condition => {
        ref = ref.where(condition.prop, condition.op, condition.val);
      });

      return firestoreAction(context => {
        return context.bindFirestoreRef(`${BUILDING_OBJECT_NAME}List`, ref);
      })(context, ref);
    },

    getBuildingList(context, { estimateId }) {
      const collectionPath = `/system_client/${tenant}/estimate/${estimateId}/building`;
      return firestore
        .collection(collectionPath)
        .get()
        .then(snaps => {
          let docs = [];
          snaps.forEach(docSnap => {
            let doc = docSnap.data();
            doc.id = docSnap.id;
            docs.push(doc);
          });
          return docs;
        });
    },

    bindBuilding(context, { estimateId, buildingId }) {
      const payload = {
        collectionPath: `/system_client/${tenant}/estimate/${estimateId}/building`,
        buildingId: buildingId,
      };

      return firestoreAction((context, { collectionPath, buildingId }) => {
        return context.bindFirestoreRef(
          BUILDING_OBJECT_NAME,
          firestore.collection(collectionPath).doc(buildingId),
          {
            reset: false,
          }
        );
      })(context, payload);
    },

    getBuilding(context, { estimateId, buildingId }) {
      const collectionPath = `/system_client/${tenant}/estimate/${estimateId}/building`;
      return firestore
        .collection(collectionPath)
        .doc(buildingId)
        .get()
        .then(snap => {
          if (snap.exists) {
            let doc = snap.data();
            doc.id = snap.id;
            return doc;
          }
        });
    },

    unbindBuilding(context) {
      return firestoreAction(context => {
        return context.unbindFirestoreRef(BUILDING_OBJECT_NAME);
      })(context);
    },

    updateBuilding({ dispatch }, { estimateId, buildingId, building }) {
      return Promise.all([
        firestore
          .collection(
            `/system_client/${tenant}/estimate/${estimateId}/building`
          )
          .doc(buildingId)
          .update(building),
        dispatch('saveEstimate', { id: estimateId, doc: {} }), // update updatedAt, updatedBy
      ]);
    },

    createBuilding(context, { estimateId, building }) {
      return firestore
        .collection(`/system_client/${tenant}/estimate/${estimateId}/building`)
        .add(building)
        .then(result => {
          const buildingId = result.id.toString();
          return result.update({ id: buildingId }).then(() => buildingId);
        })
        .then(buildingId => {
          return {
            buildingName: building.buildingName,
            buildingId: buildingId,
            numberOfCopy: building.numberOfCopy,
          };
        });
    },

    deleteBuilding({ dispatch }, { estimateId, buildingId }) {
      return Promise.all([
        firestore
          .collection(
            `/system_client/${tenant}/estimate/${estimateId}/building`
          )
          .doc(buildingId)
          .delete(),
        dispatch('saveEstimate', { id: estimateId, doc: {} }), // update updatedAt, updatedBy
      ]);
    },

    bindEstimateHistoryListBys(context, { estimateId, conditions = [] }) {
      const collectionPath = `/system_client/${tenant}/estimate/${estimateId}/history`;
      let ref = firestore.collection(collectionPath);
      conditions.forEach(condition => {
        ref = ref.where(condition.prop, condition.op, condition.val);
      });
      return firestoreAction(context => {
        return context.bindFirestoreRef(
          `${ESTIMATE_HISTORY_OBJECT_NAME}List`,
          ref
        );
      })(context, ref);
    },

    createEstimateHistory(context, { estimateId, history }) {
      history.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      history.createdBy = auth.currentUser
        ? auth.currentUser.displayName || auth.currentUser.email
        : '';
      return firestore
        .collection(`/system_client/${tenant}/estimate/${estimateId}/history`)
        .add(history)
        .then(result => {
          result.update({ id: result.id });
        });
    },

    async deleteEstimateHistoryBys(context, { estimateId, conditions = [] }) {
      const collectionPath = `/system_client/${tenant}/estimate/${estimateId}/history`;
      let ref = firestore.collection(collectionPath);
      conditions.forEach(condition => {
        ref = ref.where(condition.prop, condition.op, condition.val);
      });

      const products = await ref.get();
      const batch = firestore.batch();
      products.forEach(doc => {
        batch.delete(doc.ref);
      });
      return await batch.commit();
    },

    getProductDataTemplate(context, { roofType }) {
      const templates = (
        context.rootGetters['common/app-constant/roofTypeBy'](roofType) || {}
      ).estimateTemplate;
      if (_.isEmpty(templates)) {
        return [];
      }

      let currentBuilding =
        context.rootGetters['estimate/estimate-page/estimate/currentBuilding'];
      const refs = [];
      for (const template of templates) {
        const section = template;
        const seft = context;

        refs.push(
          seft
            .dispatch('calculateProductItemList', {
              roofType,
              sectionId: section.sectionId,
            })
            .then(sectionProducts => {
              if (_.isEmpty(sectionProducts)) {
                return [];
              }
              return seft.dispatch('calculateProductQty', {
                building: currentBuilding,
                sectionProducts: sectionProducts,
              });
            })
            .then(sectionProducts => {
              template.productList = sectionProducts;
              return template;
            })
        );
      }

      return Promise.all(refs).then(data => {
        return data;
      });
    },

    setCurrentBuildingValue({ commit }, { prop, value }) {
      commit(types.SET_CURRENT_BUILDING_FIELD, { prop, value });
    },

    calculateProductQty(
      { commit, getters, rootGetters },
      { building, sectionProducts }
    ) {
      if (_.isEmpty(sectionProducts) || _.isEmpty(building)) {
        return;
      }

      // Get sub category
      const subCategoryById =
        rootGetters[
          'estimate/estimate-page/estimate/sub-category/subCategoryById'
        ];

      const membraneThickness = rootGetters[
        'common/app-constant/membraneThickness'
      ](building.membraneThickness);

      const membraneAttachmentType = rootGetters[
        'common/app-constant/membraneAttachmentType'
      ](building.membraneAttached);

      const insulationLayers = rootGetters[
        'common/app-constant/insulationLayers'
      ](building.insulationLayers || []);

      // Calculate the product qty
      for (const productItem of sectionProducts) {
        if (productItem.isAddManually) {
          continue;
        }
        const subCat = subCategoryById(productItem.subCategoryId);
        const { actualQty, orderQty } = quantityCalculator({
          building,
          productItem,
          subCategory: subCat,
          membraneThickness,
          membraneAttachmentType,
          insulationLayers,
        });
        // console.log("actualQty, orderQty", actualQty, orderQty, productItem);

        productItem.orderQty = orderQty;
        productItem.actualQty = actualQty;
      }

      return sectionProducts;
    },

    calculateProductItemList(
      { commit, getters, rootGetters, state },
      { roofType, sectionId }
    ) {
      const sectionProducts = [];
      // const section = (getters.currentBuilding.productData || []).find(
      //   section => section.id === sectionId
      // );

      const categories =
        rootGetters['estimate/estimate-page/estimate/category/objectList'];
      const subCategories =
        rootGetters['estimate/estimate-page/estimate/sub-category/objectList'];

      // Get template of rooftype
      const templateSettings = (
        rootGetters['common/app-constant/roofTypeBy'](roofType) || {}
      ).estimateTemplate;

      // Get pricelist
      const priceList =
        rootGetters['estimate/estimate-page/price-list/priceList'];
      if (!categories || !subCategories || !priceList || !templateSettings) {
        // console.log("init calculate data false");
        return;
      }
      const templateSection = templateSettings.find(
        item => item.sectionId === sectionId
      );
      const allProductsOfPriceList = _.cloneDeep(priceList.productRefs || [])
        .filter(item => item.product.status === DEFAULT_STATUS_PRODUCT_ITEM)
        .map(r => r.product);

      const insulationLayers =
        rootGetters['common/app-constant/insulationLayers'];
      const membraneThickness =
        rootGetters['common/app-constant/membraneThickness'];
      const membraneAttachmentType =
        rootGetters['common/app-constant/membraneAttachmentType'];
      const roofThickness = rootGetters['common/app-constant/roofThickness'];
      const substrate = rootGetters['common/app-constant/substrate'];

      const category =
        categories.find(c => c.name === templateSection.category) || {};

      let subCategory = {};

      for (const subCategoryName of templateSection.subCategoryNames) {
        let bestPriceProds = [];
        // Get sub-category object
        subCategory =
          subCategories.find(
            subCat =>
              subCat.name === subCategoryName &&
              subCat.categoryId === category.id
          ) || {};

        const productItemCalculator =
          productItemHashFunction[
            subCategory.productItemFunctionMap ||
              CALCULATION_PRODUCT_ITEM_COMMON
          ];

        if (productItemCalculator) {
          bestPriceProds = productItemCalculator({
            category,
            subCategory,
            building: state.currentBuilding,
            insulationLayers: insulationLayers(
              state.currentBuilding.insulationLayers || []
            ),
            membraneThickness: membraneThickness(
              state.currentBuilding.membraneThickness
            ),
            membraneAttachmentType: membraneAttachmentType(
              state.currentBuilding.membraneAttached
            ),
            roofThickness: roofThickness(state.currentBuilding.roofThickness),
            substrate: substrate(state.currentBuilding.substrate),

            substrateThickness: state.currentBuilding.substrateThickness,
            allProductsOfPriceList,
          });
        }

        if (_.isEmpty(bestPriceProds) || bestPriceProds.length < 1) continue;

        sectionProducts.push(..._.cloneDeep(bestPriceProds));

        for (const item of bestPriceProds) {
          _.remove(allProductsOfPriceList, prod => prod.id === item.id);
        }
      }

      return sectionProducts;
    },

    calculateMetalProductItemList(
      { commit, getters, rootGetters, state },
      { roofType, sectionId, assembly }
    ) {
      // console.log(roofType, sectionId, assemblyList);
      const sectionProducts = [];
      // const section = (getters.currentBuilding.productData || []).find(
      //   section => section.id === sectionId
      // );

      const categories =
        rootGetters['estimate/estimate-page/estimate/category/objectList'];
      const subCategories =
        rootGetters['estimate/estimate-page/estimate/sub-category/objectList'];

      // Get template of rooftype
      const templateSettings = (
        rootGetters['common/app-constant/roofTypeBy'](roofType) || {}
      ).estimateTemplate;

      // Get pricelist
      const priceList =
        rootGetters['estimate/estimate-page/price-list/priceList'];

      // console.log("priceList", priceList);

      if (!categories || !subCategories || !priceList || !templateSettings) {
        // console.log("init calculate data false");
        return;
      }

      const templateSection = templateSettings.find(
        item => item.sectionId === sectionId
      );

      const allProductsOfPriceList = _.cloneDeep(priceList.productRefs || [])
        .filter(item => item.product.status === DEFAULT_STATUS_PRODUCT_ITEM)
        .map(r => r.product);

      // const insulationLayers =
      //   rootGetters["common/app-constant/insulationLayers"];
      // const membraneThickness =
      //   rootGetters["common/app-constant/membraneThickness"];
      // const membraneAttachmentType =
      //   rootGetters["common/app-constant/membraneAttachmentType"];
      // const roofThickness = rootGetters["common/app-constant/roofThickness"];
      // const substrate = rootGetters["common/app-constant/substrate"];

      const category =
        categories.find(c => c.name === templateSection.category) || {};

      // let subCategory = {};
      let bestPriceProds = [];

      // for (const subCategoryName of templateSection.subCategoryNames) {
      // Get sub-category object
      // subCategory = subCategories.find(x => x.name === subCategoryName) || {};

      // for (const assembly of assemblyList) {
      assembly.productList = [];
      const productItemCalculator =
        metalProductItemHashFunction[
          assembly.assemblyItem || CALCULATION_PRODUCT_ITEM_COMMON
        ];
      if (productItemCalculator) {
        bestPriceProds = productItemCalculator({
          category,
          // subCategory,
          building: state.currentBuilding,
          assembly: assembly,
          allProductsOfPriceList,
        });
      }

      // if (_.isEmpty(bestPriceProds) || bestPriceProds.length < 1) continue;

      // sectionProducts.push(..._.cloneDeep(bestPriceProds));
      if (!_.isEmpty(bestPriceProds)) {
        assembly.productList.push(..._.cloneDeep(bestPriceProds));
      }

      // }

      // for (const item of bestPriceProds) {
      //   _.remove(allProductsOfPriceList, prod => prod.id === item.id);
      // }
      // }

      return assembly;
    },

    calculateMetaProductQty(
      { commit, getters, rootGetters },
      { assembly, building }
    ) {
      // console.log("calculateMetaProductQty", assembly);
      if (_.isEmpty(assembly)) {
        return;
      }

      // Get sub category
      const subCategoryById =
        rootGetters[
          'estimate/estimate-page/estimate/sub-category/subCategoryById'
        ];

      // const membraneThickness = rootGetters[
      //   "common/app-constant/membraneThickness"
      // ](building.membraneThickness);

      // const membraneAttachmentType = rootGetters[
      //   "common/app-constant/membraneAttachmentType"
      // ](building.membraneAttached);

      // const insulationLayers = rootGetters[
      //   "common/app-constant/insulationLayers"
      // ](building.insulationLayers || []);

      // Calculate the product qty
      const productList = assembly.productList || [];
      for (const productItem of productList) {
        if (productItem.isAddManually) {
          continue;
        }
        const subCat = subCategoryById(productItem.subCategoryId);

        const { actualQty, orderQty } = metalQuantityCalculator({
          assembly,
          productItem,
          subCategory: subCat,
          building,
          // membraneThickness,
          // membraneAttachmentType,
          // insulationLayers
        });
        // console.log("actualQty, orderQty", actualQty, orderQty, productItem);

        productItem.orderQty = orderQty;
        productItem.actualQty = actualQty;
      }

      return assembly;
    },

    setEstimateField({ commit, state }, payload) {
      if (payload.value != state.estimate[payload.fieldName]) {
        commit('SET_ESTIMATE_FIELD', payload);
      }
    },

    setCurrentEstimateField({ commit }, payload) {
      commit('SET_CURRENT_ESTIMATE_FIELD', payload);
    },

    // Create new Estimate with initializing buildings
    //TODO: Init product data for each building
    async createNewEstimate({ dispatch, commit, getters, rootGetters }, doc) {
      const document = doc || getters.currentEstimate;
      const user = rootGetters['setting/app/profile/user'];

      const project = await dispatch(
        'estimate/estimate-page/project/getProjectById',
        document.projectId,
        { root: true }
      );
      const workType = getWorkType(project);
      const buildingInfoDefault = await getBuildingInfoDefault(project);
      // TODO: need to implement init product list
      if (document.priceListId) {
        await dispatch(
          'estimate/estimate-page/product-item/getActiveProductItemsByPriceListId',
          document.priceListId,
          { root: true }
        );
      }

      //TODO: need to implement init product list
      let productData = [];
      if (document.roofType) {
        productData = await dispatch('getProductDataTemplate', {
          roofType: document.roofType,
        });
      }

      const estimate = await estimateService.addEstimateDoc(
        user.tenantId,
        document
      );
      let buildingTax = {};
      if (estimate.businessCode === BUSINESS_CODE_SERVICE) {
        buildingTax = {
          taxApplyType: estimate.taxApplyType || '',
          saleTax: estimate.saleTax || 0,
        };
      } else {
        const saleTax = await dispatch('getTax', {
          projectData: project,
          buildingData: {
            workType: workType || buildingInfoDefault.workType,
          },
          estimateData: estimate,
        });
        buildingTax = {
          saleTax,
        };
      }
      const refs = [];
      for (const item of document.buildings) {
        refs.push(
          dispatch('createBuilding', {
            estimateId: estimate.id,
            building: {
              buildingName: item.buildingName,
              numberOfCopy: item.numberOfCopy,
              estimateId: estimate.id,
              productData,
              ...buildingInfoDefault,
              workType: workType || buildingInfoDefault.workType,
              ...buildingTax,
            },
          })
        );
      }

      const buildingsData = await Promise.all(refs);

      await dispatch('updateEstimate', {
        id: estimate.id,
        doc: {
          buildings: buildingsData,
        },
      });

      const updatedEstimate = {
        ...document,
        id: estimate.id,
        estimateNumber: estimate.docNumber,
        buildings: buildingsData,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      // TODO: Check project name, property name, contact name
      await algoliaService.algoliaUpdateItem({
        tenantId: user.tenantId,
        collection: 'estimate',
        id: updatedEstimate.id,
      });

      await dispatch('searchEstimate', {}); // re-search

      return updatedEstimate;
    },
    // get contact list has estimate
    getContactFacets({ commit }) {
      let hits = [];
      return estimateIndexDesc
        .browseObjects({
          query: '',
          filters: `entityName:${COLLECTION_ESTIMATE} AND NOT contactId:? AND isProjectArchived:false`,
          attributesToRetrieve: ['contactId', 'contactName'],
          batch: batch => {
            hits = hits.concat(batch);
          },
        })
        .then(() => {
          hits = hits.map(r => ({
            id: r.contactId,
            contactName: r.contactName,
          }));
          hits = _.uniqBy(hits, 'id');
          commit(types.SET_CONTACT_FACETS, hits);
        });
    },

    // get company list has estimate
    getCompanyFacets({ commit }) {
      let hits = [];
      return estimateIndexDesc
        .browseObjects({
          query: '',
          filters: `entityName:${COLLECTION_ESTIMATE} AND NOT companyId:? AND isProjectArchived:false`,
          attributesToRetrieve: ['companyId', 'companyName'],
          batch: batch => {
            hits = hits.concat(batch);
          },
        })
        .then(() => {
          hits = hits.map(r => ({
            id: r.companyId,
            companyName: r.companyName,
          }));
          hits = _.uniqBy(hits, 'id');
          commit(types.SET_COMPANY_FACETS, hits);
        });
    },

    resetCompanyContactFacets({ commit }) {
      commit(types.RESET_COMPANY_CONTACT_FACETS);
    },

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    async searchEstimate({ state, commit, getters }, { attributesToRetrieve }) {
      const index =
        state.order === 'asc' ? estimateIndexAsc : estimateIndexDesc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${COLLECTION_ESTIMATE} AND isProjectArchived:false`,
        attributesToRetrieve: ESTIMATE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          ESTIMATE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreEstimate(
      { state, commit, getters },
      { attributesToRetrieve, page }
    ) {
      const index =
        state.order === 'asc' ? estimateIndexAsc : estimateIndexDesc;
      const filters = getters.queryFilters;

      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${COLLECTION_ESTIMATE} AND isProjectArchived:false`,
        attributesToRetrieve: ESTIMATE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          ESTIMATE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index.search(state.searchText, requestOptions).then(result => {
        commit(types.ADD_MORE_RESULT, result);
        return result;
      });
    },

    setEstimateNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },

    setEstimateSearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },

    setEstimateSearchOrder({ commit }, payload) {
      commit(types.SET_SEARCH_ORDER, payload);
    },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    updateNumberOfCopyEstimateManually(
      { state, commit },
      { estId, buildingId, value }
    ) {
      const newHits = state.hits.map(est => {
        if (est.id === estId) {
          return {
            ...est,
            buildings: est.buildings.map(building => {
              if (building.buildingId === buildingId) {
                return {
                  ...building,
                  numberOfCopy: value,
                };
              }
              return building;
            }),
          };
        }
        return est;
      });

      commit(types.SET_SEARCH_RESULT, {
        hits: newHits,
        hitsPerPage: state.hitsPerPage,
        nbHits: state.nbHits,
        nbPages: state.nbPages,
        page: state.page,
      });
    },

    async updateEstimate({ dispatch, commit, rootGetters }, { id, doc }) {
      const user = rootGetters['setting/app/profile/user'];

      if (doc.projectId) {
        doc.isProjectArchived = await dispatch(
          'common/common/getIsProjectArchived',
          doc.projectId,
          { root: true }
        );
      }

      return dispatch('saveEstimate', { id, doc }).then(async () => {
        await algoliaService.algoliaUpdateItem({
          tenantId: user.tenantId,
          collection: 'estimate',
          id,
        });
        await dispatch('searchEstimate', {}); // re-search
      });
    },

    deleteEstimate({ dispatch, commit, rootGetters }, id) {
      const user = rootGetters['setting/app/profile/user'];

      return dispatch('saveEstimate', {
        id,
        doc: { [PROP_IS_DELETED]: true },
      }).then(async () => {
        await algoliaService.algoliaUpdateItem({
          tenantId: user.tenantId,
          collection: 'estimate',
          id,
        });
        await dispatch('searchEstimate', {}); // re-search
      });
    },

    getEstimatesByProject(
      { rootGetters, commit, dispatch, getters, rootState, state },
      projectId
    ) {
      return dispatch('getEstimateBys', [
        {
          prop: 'projectId',
          op: '==',
          val: projectId,
        },
      ]).then(estimates => {
        commit(types.SET_ESTIMATES_BY_PROJECT, estimates);
      });
    },

    setCurrentStep({ commit }, payload) {
      commit(types.SET_CURRENT_STEP, payload);
    },
    setParamsBack({ commit }, payload) {
      commit(types.SET_PARAMS_BACK, payload);
    },
    setQueryBack({ commit }, payload) {
      commit(types.SET_QUERY_BACK, payload);
    },
    // eslint-disable-next-line no-unused-vars
    async uploadDiagramPhoto({ commit }, fileData) {
      const base64Data = fileData.dataUrl;
      const file = fileData.info;
      const name = file.name || '';
      const fullPath = `estimate/diagrams/${name}`;
      return StorageActions.uploadFileBase64(fullPath, base64Data, file);
    },

    async removeDiagramPhoto({ commit }, fullPath) {
      return StorageActions.removeFile(fullPath);
    },

    /**
     * [DEV-1231] Add 8.25% tax as automatic and 7% waste as automatic to the estimate
     * @param {*} param0
     * @returns
     * DEV-1497 Estimate don't get tax base on location of property (Service, Residential)
     */
    async getTax({ getters, rootGetters, dispatch }, data) {
      const project =
        data?.projectData ||
        rootGetters['estimate/estimate-page/project/project'];
      const building = data?.buildingData || getters.building;
      const estimate = data?.estimateData || getters.estimate;
      let taxRate = 0;

      const calculateTax = async stateName => {
        if (_.isEmpty(stateName)) {
          return 0;
        }
        const taxRateInfo = await dispatch('getCurrentTaxInfoRate');
        const taxRate = taxRateInfo[stateName];
        return !_.isEmpty(taxRate)
          ? taxRate.stateTaxRate + taxRate.maxLocalTaxRate
          : 0;
      };
      if (
        getters.doesApplyTaxForMaterial(project, building, estimate) ||
        getters.doesApplyTaxForLabor(project, building, estimate)
      ) {
        if (
          project.businessCode === BUSINESS_CODE_COMMERCIAL ||
          (project.businessCode === BUSINESS_CODE_SERVICE &&
            project.customerType === 'commercial')
        ) {
          const property = await dispatch(
            'estimate/estimate-page/property/getPropertyById',
            project.propertyId,
            { root: true }
          );
          const address = getMainAddress(property.addresses);
          taxRate = await calculateTax(address.state);
        } else if (
          project.businessCode === BUSINESS_CODE_RESIDENTIAL ||
          (project.businessCode === BUSINESS_CODE_SERVICE &&
            (project.customerType === 'residential' ||
              project.customerType === 'multifamily'))
        ) {
          taxRate = await calculateTax((project.projectAddress || {}).state);
        }
      }

      return taxRate;
    },

    /**
     *! This function get TAX for new SERVICE estimate ONLY
     * @param {*} param0
     */
    async initTax({ dispatch, rootGetters }) {
      const project = rootGetters['estimate/estimate-page/project/project'];
      let taxRate = 0;

      const calculateTax = async stateName => {
        if (_.isEmpty(stateName)) {
          return 0;
        }
        const taxRateInfo = await dispatch('getCurrentTaxInfoRate');
        const taxRate = taxRateInfo[stateName];
        return !_.isEmpty(taxRate)
          ? taxRate.stateTaxRate + taxRate.maxLocalTaxRate
          : 0;
      };

      if (
        project.businessCode === BUSINESS_CODE_COMMERCIAL ||
        (project.businessCode === BUSINESS_CODE_SERVICE &&
          project.customerType === 'commercial')
      ) {
        const property = await dispatch(
          'estimate/estimate-page/property/getPropertyById',
          project.propertyId,
          { root: true }
        );
        const address = getMainAddress(property.addresses);
        taxRate = await calculateTax(address.state);
      } else if (
        project.businessCode === BUSINESS_CODE_RESIDENTIAL ||
        (project.businessCode === BUSINESS_CODE_SERVICE &&
          (project.customerType === 'residential' ||
            project.customerType === 'multifamily'))
      ) {
        taxRate = await calculateTax((project.projectAddress || {}).state);
      }

      await dispatch('setCurrentEstimateField', {
        fieldName: 'saleTax',
        value: taxRate,
      });
    },
    setSelectedStatusFilter({ commit }, payload) {
      commit(types.SET_SELECTED_STATUS_FILTER, payload);
    },
    setTypeSwitchScreen({ commit }, payload) {
      commit(types.SET_TYPE_SWITCH_SCREEN, payload);
    },
    setIsActiveSummary({ commit }, payload) {
      commit(types.SET_IS_ACTIVE_SUMMARY, payload);
    },
    setClientInfo({ commit }, payload) {
      commit(types.SET_CLIENT_INFO, payload);
    },
  };
};
