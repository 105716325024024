<template>
  <div class="swimlane-page">
    <!-- <body-swimlane
      ref="bodySwimlane"
      :actions="workOrderActionList"
      :cards="workOrderTaskList"
      :cardWidth="280"
      v-on="$listeners"
    ></body-swimlane> -->

    <kaban-swimlane v-on="$listeners"></kaban-swimlane>
  </div>
</template>

<script>
import KabanSwimlane from '../../components/swimlane/KabanSwimlane.vue';
// import { mapGetters } from "vuex";

export default {
  components: {
    KabanSwimlane,
  },

  // computed: {
  //   ...mapGetters("dashboard/work-order", [
  //     "workOrderActionList",
  //     "workOrderTaskList"
  //   ])
  // }
};
</script>

<style lang="scss" scoped>
.swimlane-page {
  padding-top: 0px;
  // height: 100%;
}
</style>
