<template>
  <div>
    <f7-list>
      <f7-list-item
        title="Project Title"
        :after="project.title"
      ></f7-list-item>
      <f7-list-item
        title="Property Name"
        :after="property.propertyName"
      ></f7-list-item>
      <f7-list-item
        v-if="woTask.buildingName"
        title="Specification #"
        :after="woTask.buildingName"
      ></f7-list-item>
    </f7-list>

    <f7-list>
      <f7-list-input
        label="Description"
        :value="description"
        type="textarea"
        @change="
          description = $event.target.value;
          updateWorkOrderTaskProp('description', $event.target.value);
        "
        :error-message="descriptionErrorMessage"
        error-message-force
        ><required-asterisk slot="label"></required-asterisk>
      </f7-list-input>

      <f7-list-input
        placeholder="Enter Due Date"
        label="Due Date"
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          openIn: 'customModal',
          header: true,
          footer: false,
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
          },
          closeOnSelect: true,
        }"
        :value="dueDate"
        @calendar:change="saveDueDate('dueDate', $event)"
        :error-message="dueDateErrorMessage"
        error-message-force
        ><required-asterisk slot="label"></required-asterisk
      ></f7-list-input>
    </f7-list>

    <attachment-input
      :project-id="woTask.id"
      modelType="work-order-tasks"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="attachmentFiles"
      @input="
        attachmentFiles = $event;
        updateWorkOrderTaskProp('attachmentFiles', $event);
      "
    ></attachment-input>

    <user-input
      :value="assigneeIds"
      modelType="wo-task"
      @input="updateWorkOrderTaskProp('assigneeIds', $event)"
      @onUserAdded="
        addWOTaskNotification($event);
        sendAddWOTaskMail($event);
      "
    ></user-input>

    <note-popup
      ref="notePopup"
      title="QA & QC Note"
      label="Enter Note"
      v-model="note"
      @done="handleNotePopupDone"
      validate
    ></note-popup>

    <slot name="response"></slot>
  </div>
</template>

<script>
import UserInput from '../input/UserInput.vue';
import AttachmentInput from '../input/AttachmentInput';
import NotePopup from '../popup/NotePopup.vue';

import { mapActions, mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import {
  toDateCalendar,
  toDateFirebase,
  // toDisplayDateString
} from '../../../../utility/datetime';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import constBoard from '../../mixin/constructionBoard';

import { firebase, auth } from '../../../../services/firebase.service';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: { UserInput, AttachmentInput, NotePopup },
  mixins: [constBoard],

  data() {
    return {
      description: '',
      dueDate: [],
      assigneeIds: [],
      attachmentFiles: [],
      note: '',

      currentResponse: {},
    };
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {
    description: {
      required,
    },
    dueDate: {
      required,
    },
  },

  watch: {
    workOrderTask: {
      immediate: true,
      deep: true,
      handler(val) {
        this.woTask = _.cloneDeep(val);
        this.description = this.woTask.description || '';
        this.dueDate = this.toDateCalendar(this.woTask.dueDate) || [];
        this.assigneeIds = this.woTask.assigneeIds || [];
        this.attachmentFiles = this.woTask.attachmentFiles || [];

        this.note = '';
      },
    },
  },

  computed: {
    ...mapGetters('dashboard/work-order', ['workOrderTask']),
    ...mapGetters('dashboard/project', { project: 'constructionProject' }),
    ...mapState('dashboard/client', ['property']),

    descriptionErrorMessage() {
      if (!this.v$.description.$error) return null;
      if (this.v$.description.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;

      return '';
    },

    dueDateErrorMessage() {
      if (!this.v$.dueDate.$error) return null;
      if (this.v$.dueDate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;

      return '';
    },
  },

  methods: {
    ...mapActions('dashboard/work-order', ['updateWorkOrderTask']),
    ...mapActions('dashboard/project', ['updateCard']),

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },

    // Implement do something before resolve action
    doAction(response) {
      const self = this;
      switch (response.code) {
        case 'start':
        case 'restart':
          this.v$.$touch();
          if (this.v$.$invalid) {
            return;
          }

          this.resolve(response);
          this.$emit('doClosePopup');
          break;

        case 'completed':
          this.v$.$touch();
          if (this.v$.$invalid) {
            return;
          }
          // this.resolve(response);
          // this.$emit("doClosePopup");
          this.currentResponse = response;
          this.$refs.notePopup.open();
          break;

        case 'failed':
          // this.resolve(response);
          // this.$emit("doClosePopup");

          this.currentResponse = response;
          this.$ri.dialog.openInfoDialog({
            title: 'QA & QC',
            content:
              'Please make sure to assign this task to an in charged person.',
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                self.$refs.notePopup.open();
              }
            },
          });
          break;

        // case "save-and-close":
        //   this.v$.$touch();
        //   if (this.v$.$invalid) {
        //     return;
        //   }
        //   this.save();
        //   this.$emit("doClosePopup");
        //   break;
      }
    },

    // Implement  resolve action
    async resolve(response) {
      this.$f7.preloader.show();
      await this.save();
      this.$emit('doResolve', response);
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      // console.log("save");
      await this.updateWorkOrderTask({
        id: this.woTask.id,
        doc: {
          dueDate: this.toDateFirebase(this.dueDate || '') || '',
          description: this.description || '',
          assigneeIds: this.assigneeIds || [],
          attachmentFiles: this.attachmentFiles || [],
        },
      });
    },

    saveNote() {
      const commentAndNote = _.cloneDeep(this.project.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: 'note',
          title: 'QA & QC Work Order Task Note',
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        });

        return this.updateCard({
          id: this.project.id,
          doc: {
            commentAndNote,
          },
        });
      }
    },

    handleNotePopupDone() {
      const self = this;
      this.saveNote().then(() => {
        self.resolve(self.currentResponse);
        self.$emit('doClosePopup');
      });
    },

    initData() {},
  },
};
</script>

<style></style>
