import { sortLatest } from '@/utility/date-time-tool';
import { TEMPLATE_TYPE } from '../../../../utility/const';

export default {
  projectPhotoAlbumList: (state, _, __, rootGetters) => {
    const albumListDefault = (
      state.projectPhotoAlbumList.filter(
        r => r.templateType === TEMPLATE_TYPE.DEFAULT
      ) || []
    ).sort((a, b) => a.priority - b.priority);
    const albumListSystem = (
      state.projectPhotoAlbumList.filter(
        r => r.templateType === TEMPLATE_TYPE.SYSTEM
      ) || []
    ).sort((a, b) => a.priority - b.priority);
    const albumListRest = sortLatest(
      state.projectPhotoAlbumList.filter(
        r =>
          r.templateType !== TEMPLATE_TYPE.DEFAULT &&
          r.templateType !== TEMPLATE_TYPE.SYSTEM
      ) || []
    );
    const albumList = albumListDefault.concat(albumListSystem, albumListRest);
    const photoList = sortLatest(
      rootGetters['dashboard/photo/projectPhotoList']
    );
    const result = albumList.map(album => ({
      ...album,
      photoLasted: photoList.find(photo => photo.albumId === album.id) || {},
      photoTotals: (photoList.filter(r => r.albumId === album.id) || []).length,
    }));
    return result;
  },
  projectPhotoAlbum: state => state.projectPhotoAlbum,
};
