import * as types from './types';
import Vue from 'vue';

export default {
  [types.SET_FILTER_DATA_RESULT_BY_PL](
    state,
    { roofTypes, categoryIds, subCategoryIds, vendorIds }
  ) {
    Vue.set(state.productsByPL, 'vendorIdFilterList', vendorIds);
    Vue.set(state.productsByPL, 'categoryIdFilterList', categoryIds);
    Vue.set(state.productsByPL, 'subCategoryIdFilterList', subCategoryIds);
    Vue.set(state.productsByPL, 'roofTypeFilterList', roofTypes);
  },

  [types.SET_SEARCH_RESULT_BY_PL](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(
      state.productsByPL,
      'hits',
      hits.map(r => {
        delete r.createdAt;
        delete r.updatedAt;
        delete r._highlightResult;
        delete r.objectID;
        return r;
      })
    );
    Vue.set(state.productsByPL, 'hitsPerPage', hitsPerPage);
    Vue.set(state.productsByPL, 'nbHits', nbHits);
    Vue.set(state.productsByPL, 'nbPages', nbPages);
    Vue.set(state.productsByPL, 'page', page);
  },

  [types.SET_NUMBER_OF_ROWS_BY_PL](state, payload) {
    Vue.set(state.productsByPL, 'hitsPerPage', payload);
  },

  [types.SET_SEARCH_ORDER_BY_PL](state, payload) {
    Vue.set(state.productsByPL, 'order', payload);
  },

  [types.SET_FILTER_DATA_RESULT_BY_DEFAULT](
    state,
    { roofTypes, categoryIds, subCategoryIds, vendorIds }
  ) {
    Vue.set(state.productsDefault, 'vendorIdFilterList', vendorIds);
    Vue.set(state.productsDefault, 'categoryIdFilterList', categoryIds);
    Vue.set(state.productsDefault, 'subCategoryIdFilterList', subCategoryIds);
    Vue.set(state.productsDefault, 'roofTypeFilterList', roofTypes);
  },

  [types.SET_SEARCH_RESULT_BY_DEFAULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(
      state.productsDefault,
      'hits',
      hits.map(r => {
        delete r.createdAt;
        delete r.updatedAt;
        delete r._highlightResult;
        delete r.objectID;
        return r;
      })
    );
    Vue.set(state.productsDefault, 'hitsPerPage', hitsPerPage);
    Vue.set(state.productsDefault, 'nbHits', nbHits);
    Vue.set(state.productsDefault, 'nbPages', nbPages);
    Vue.set(state.productsDefault, 'page', page);
  },

  [types.SET_NUMBER_OF_ROWS_BY_DEFAULT](state, payload) {
    Vue.set(state.productsDefault, 'hitsPerPage', payload);
  },

  [types.SET_SEARCH_ORDER_BY_DEFAULT](state, payload) {
    Vue.set(state.productsDefault, 'order', payload);
  },

  [types.RESET_SEARCH_BY_PL](state) {
    Vue.set(state.productsByPL, 'order', 'desc');
    Vue.set(state.productsByPL, 'hits', []);
    Vue.set(state.productsByPL, 'hitsPerPage', 25);
    Vue.set(state.productsByPL, 'nbHits', 0);
    Vue.set(state.productsByPL, 'nbPages', 0);
    Vue.set(state.productsByPL, 'page', 0);
    Vue.set(state.productsByPL, 'vendorIdFilterList', []);
    Vue.set(state.productsByPL, 'categoryIdFilterList', []);
    Vue.set(state.productsByPL, 'subCategoryIdFilterList', []);
    Vue.set(state.productsByPL, 'roofTypeFilterList', []);
  },

  [types.RESET_SEARCH_BY_DEFAULT](state) {
    Vue.set(state.productsDefault, 'order', 'desc');
    Vue.set(state.productsDefault, 'hits', []);
    Vue.set(state.productsDefault, 'hitsPerPage', 25);
    Vue.set(state.productsDefault, 'nbHits', 0);
    Vue.set(state.productsDefault, 'nbPages', 0);
    Vue.set(state.productsDefault, 'page', 0);
    Vue.set(state.productsDefault, 'vendorIdFilterList', []);
    Vue.set(state.productsDefault, 'categoryIdFilterList', []);
    Vue.set(state.productsDefault, 'subCategoryIdFilterList', []);
    Vue.set(state.productsDefault, 'roofTypeFilterList', []);
  },
};
