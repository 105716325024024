import Cookies from 'js-cookie';

export default {
  routes: [],
  user: {},
  dark:
    Cookies.getJSON('themeDark') !== undefined
      ? Cookies.getJSON('themeDark')
      : true,
  currentGroups: [],
};
