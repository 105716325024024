<template>
  <div>
    <body-swimlane
      ref="bodySwimlane"
      :actions="filterActions(boardCode)"
      :cards="cardList"
      :cardWidth="280"
      v-on="$listeners"
    ></body-swimlane>
  </div>
</template>

<script>
import BodySwimlane from '../../components/swimlane/BodySwimlane.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    BodySwimlane,
  },

  computed: {
    ...mapGetters('dashboard/project', ['filterActions']),
    ...mapGetters('dashboard/project', ['boardCode', 'cardList']),
  },
};
</script>
