<template>
  <div>
    <f7-block>
      <filter-by-date @searchByDate="searchByDate"></filter-by-date>
    </f7-block>

    <history-table
      :dataSource="workingHoursHistory"
      @showDetail="handleShowDetailPopup"
    ></history-table>
    <working-hours-history-popup
      :isShow="isShowHistoryPopup"
      title="Working Hours DateTime"
      :dataSource="historyHours"
      @close="handleCloseDetailPopup"
    ></working-hours-history-popup>
  </div>
</template>
<script>
import FilterByDate from '@/plugins/quickbooks/components/filter/FilterByDate.vue';
import WorkingHoursHistoryPopup from '@/plugins/quickbooks/components/popups/WorkingHoursHistoryPopup.vue';
import HistoryTable from '@/plugins/quickbooks/components/datatable/HistoryTable.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  lastWeekMonday,
  lastWeekSunday,
} from '../../../../utility/date-time-tool';

export default {
  components: {
    FilterByDate,
    WorkingHoursHistoryPopup,
    HistoryTable,
  },
  data: () => {
    return {
      isShowHistoryPopup: false,
      historyHours: [],
    };
  },
  created() {
    this.searchByDate({
      fromDate: [lastWeekMonday()],
      toDate: [lastWeekSunday()],
    });
  },
  methods: {
    ...mapActions('quickbooks/activity', ['popuplateWorkingHoursHistory']),

    searchByDate(dateRange) {
      this.popuplateWorkingHoursHistory(dateRange);
    },

    handleShowDetailPopup(value) {
      this.isShowHistoryPopup = true;
      this.historyHours = value;
    },

    handleCloseDetailPopup() {
      this.isShowHistoryPopup = false;
      this.historyHours = [];
    },
  },
  computed: {
    ...mapGetters('quickbooks/activity', ['workingHoursHistory']),

    // workingHoursHistoryList() {
    //   return [
    //     {
    //       id: "1",
    //       patchName: "Working Hours DateTime",
    //       reportedDate: new Date("8/15/2020")
    //     },
    //     {
    //       id: "2",
    //       patchName: "Working Hours DateTime",
    //       reportedDate: new Date("8/17/2020")
    //     },
    //     {
    //       id: "3",
    //       patchName: "Working Hours DateTime",
    //       reportedDate: new Date("8/19/2020")
    //     }
    //   ];
    // },
    // workingHoursList() {
    //   return [
    //     {
    //       parentRow: true,
    //       employeeName: "Trung Dang",
    //       aprovedWorkingHours: new Date("8/15/2020"),
    //       totalDutationByDate: 16
    //     },
    //     {
    //       id: "1",
    //       employeeName: "Trung Dang",
    //       aprovedWorkingHours: new Date("8/15/2020"),
    //       duration: 8,
    //       timeLogInfo: "Project 1"
    //     },
    //     {
    //       id: "1",
    //       employeeName: "Trung Dang",
    //       aprovedWorkingHours: new Date("8/15/2020"),
    //       duration: 8,
    //       timeLogInfo: "Project 2"
    //     },
    //     {
    //       parentRow: true,
    //       employeeName: "Chien Phan",
    //       aprovedWorkingHours: new Date("8/17/2020"),
    //       totalDutationByDate: 8
    //     },
    //     {
    //       id: "2",
    //       employeeName: "Chien Phan",
    //       aprovedWorkingHours: new Date("8/17/2020"),
    //       duration: 8,
    //       timeLogInfo: "Project 3"
    //     }
    //   ];
    // }
  },
};
</script>
