<template>
  <div>
    <f7-block-title v-show="$device.desktop"
      >Two-Piece Counter Flashing</f7-block-title
    >
    <f7-card :class="{ 'margin-top-half': !$device.desktop }">
      <!-- <f7-card-header>Counter Flashing</f7-card-header> -->
      <f7-card-content>
        <div class="canvas-container">
          <img
            class="display-none"
            :id="`counter-flashing_${assemblyId}`"
            :src="`${dark ? counterFlashingDark : counterFlashing}`"
          />
          <canvas
            v-if="$device.desktop"
            :ref="assemblyId"
            :id="assemblyId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @mousedown="handleMouseDown"
            @mousewheel="handleMouseWheel"
          />
          <canvas
            v-else
            :ref="assemblyId"
            :id="assemblyId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @click="handleMouseDown"
            @pointerdown="handlePointerDown"
            @pointermove="handlePointerMove"
            @pointerup="handlePointerUp"
            @pointercancel="handlePointerUp"
            @pointerleave="handlePointerUp"
            @pointerout="handlePointerUp"
          />
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>

<script>
import paper from 'paper';
import { evaluate } from 'mathjs';
import canvasMixin from '../../utility/canvas-mixin';
import _ from 'lodash';
import counterFlashingDark from '@/assets/img/metal-diagrams/counter-flashing-dark.jpeg';
import counterFlashing from '@/assets/img/metal-diagrams/counter-flashing.png';
const inchToPx = 35;
const radius = 5;

export default {
  mixins: [canvasMixin],

  props: {
    data: Object,
    assemblyId: String,
  },

  data: () => ({
    counterFlashingDark,
    counterFlashing,
    scope: null,
    dataToPx: {},
    yBase: 300,
    xBase: 300,
    width: 1000,
    height: 800,
    dark: localStorage.getItem('themeDark') === 'true',
  }),

  mounted() {
    this.scope = new paper.PaperScope();
    this.scope.setup(this.assemblyId);
    this.drawAll();
  },

  methods: {
    initCanvas() {
      this.removeProject(this.scope);
      this.scope = new paper.PaperScope();
      this.scope.setup(this.assemblyId);
      if (!_.isEmpty(this.scope.view)) {
        this.scope.view.viewSize = new paper.Size(
          this.$device.desktop ? this.width : this.widthMobile,
          this.$device.desktop ? this.height : this.heightMobile
        );
      }
    },

    reset() {
      this.scope && this.scope.project.activeLayer.removeChildren();
    },

    handleDraw1() {
      let img = new paper.Raster(`counter-flashing_${this.assemblyId}`);
      img.size = new paper.Size(462, 563);
      img.position = new paper.Point(this.xBase, this.yBase);
    },

    handleDraw2() {
      const { A, B, C, D, E } = this.dataToPx;
      const pointA = new paper.Point(this.xBase + 400, this.yBase - 250);
      const pointB = new paper.Point(pointA.x, pointA.y + A);
      let pointC = new paper.Point(pointB.x, pointB.y - B);
      pointC = pointC.rotate(100, pointB);

      // curve 1
      let pointD = new paper.Point(pointC.x, pointC.y - (C - inchToPx));
      pointD = pointD.rotate(-80 + -90, pointC);

      let pointD1 = new paper.Point(pointD.x, pointD.y - (inchToPx - radius));
      let pointD2 = new paper.Point(pointD1.x + radius * 2, pointD1.y); // r = 5
      let centerD1D2 = this.pointOnPathByStart(pointD1, pointD2, 2);
      let throughD1D2 = pointD1.rotate(90, centerD1D2);
      let pointD3 = new paper.Point(pointD2.x, pointD2.y + 20 - radius);

      // cuver 2
      let pointE3 = new paper.Point(centerD1D2.x, centerD1D2.y + radius / 2);
      let pointE2 = new paper.Point(pointE3.x, pointE3.y + 20 - radius);
      let pointE1 = new paper.Point(pointE2.x + radius * 2, pointE2.y);
      let centerE2E1 = this.pointOnPathByStart(pointE2, pointE1, 2);
      let throughE2E1 = pointE2.rotate(-90, centerE2E1);
      let pointE = new paper.Point(pointE1.x, pointE1.y - (D - 5));

      // rotate cuver 1
      pointD1 = pointD1.rotate(10 + 135, pointD);
      pointD2 = pointD2.rotate(10 + 135, pointD);
      centerD1D2 = centerD1D2.rotate(10 + 135, pointD);
      throughD1D2 = throughD1D2.rotate(10 + 135, pointD);
      pointD3 = pointD3.rotate(10 + 135, pointD);

      const path1 = new paper.Path({
        strokeColor: this.dark ? 'white' : 'black',
        strokeWidth: 2,
      });
      path1.add(pointA, pointB, pointC, pointD, pointD1);
      path1.arcTo(throughD1D2, pointD2);
      path1.add(pointD3);

      // rotate cuver 2
      pointE1 = pointE1.rotate(10 + 135, pointD);
      pointE2 = pointE2.rotate(10 + 135, pointD);
      centerE2E1 = centerE2E1.rotate(10 + 135, pointD);
      throughE2E1 = throughE2E1.rotate(10 + 135, pointD);
      pointE3 = pointE3.rotate(10 + 135, pointD);
      pointE = pointE.rotate(10 + 135, pointD);

      const path2 = new paper.Path({
        strokeColor: this.dark ? 'white' : 'black',
        strokeWidth: 2,
      });
      path2.add(pointE3, pointE2);
      path2.arcTo(throughE2E1, pointE1);
      path2.add(pointE);

      let pointF = new paper.Point(pointE.x, pointE.y - (E - 15));
      pointF = pointF.rotate(-35 + -120, pointE);

      let pointF1 = new paper.Point(pointF.x, pointF.y - 15);
      let pointF2 = new paper.Point(pointF1.x + 2.5, pointF1.y);
      let pointF3 = new paper.Point(pointF2.x, pointF2.y + 15);

      pointF1 = pointF1.rotate(25 + 120, pointF);
      pointF2 = pointF2.rotate(25 + 120, pointF);
      pointF3 = pointF3.rotate(25 + 120, pointF);

      path2.add(pointF, pointF1, pointF2, pointF3);

      // description
      this.handleDrawDescriptionLeft(pointA, pointB, `A: ${this.data.A}`);

      this.handleDrawDescriptionDiagonal(
        pointB,
        pointC,
        100,
        0,
        `B: ${this.data.B}`,
        'center',
        50,
        'minus'
      );

      this.handleDrawDescriptionDiagonal(
        pointC,
        throughD1D2,
        -80 + -90,
        0,
        `C: ${this.data.C}`,
        'center'
      );

      this.handleDrawDescriptionDiagonal(
        pointE,
        throughE2E1,
        -25,
        0,
        `D: ${this.data.D}`,
        'center'
      );

      this.handleDrawDescriptionDiagonal(
        pointE,
        pointF1,
        -35 + -120,
        0,
        `E: ${this.data.E}`,
        'center'
      );
    },

    drawAll() {
      this.handleDraw1();
      this.handleDraw2();
      this.resizableLayer(this.xBase / 3, this.yBase / 3);
    },
  },

  watch: {
    data: {
      handler(val) {
        const keys = ['A', 'B', 'C', 'D', 'E'];
        for (let i of keys) {
          let parseValue = this.convertStringToExpression(val[i]);
          this.dataToPx[i] = evaluate(parseValue) * inchToPx;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas-container {
  position: relative;
}
canvas[resize] {
  width: 100%;
  height: 100%;
}
</style>
