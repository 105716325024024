<template>
  <div>
    <f7-block-title>Add a note</f7-block-title>
    <f7-text-editor
      :value="commentContent"
      @texteditor:change="val => (commentContent = val)"
      placeholder="Add a note..."
      mode="popover"
      resizable
    />
    <f7-block
      v-show="commentContent != ''"
      class="save-comment-block"
    >
      <f7-col tag="span"></f7-col>
      <f7-col tag="span"></f7-col>
      <f7-col
        tag="span"
        class="btn-save"
      >
        <f7-button
          raised
          @click.native="saveComment"
          >Save</f7-button
        >
      </f7-col>
    </f7-block>

    <div
      v-for="(item, index) in listNotes"
      :key="index"
    >
      <f7-block-title>
        added by
        <strong>{{ item.createdBy }}</strong>
      </f7-block-title>

      <f7-block
        strong
        inset
      >
        <p v-html="item.htmlContent"></p>
      </f7-block>
      <f7-block-footer class="text-align-right">{{
        toLocaleString(item.createdAt)
      }}</f7-block-footer>
    </div>
  </div>
</template>

<script>
import { firebase, auth } from '../../../../../services/firebase.service';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => {
    return {
      commentContent: '',
    };
  },
  computed: {
    ...mapGetters('contact-book/contact', ['contact']),
    listNotes() {
      return this.contact
        ? this.contact.notes
          ? this.contact.notes.map(r => r).reverse()
          : []
        : [];
    },
  },
  methods: {
    ...mapActions('contact-book/contact', ['updateContact', 'getContactById']),
    async saveComment() {
      if (!_.isEmpty(this.contact)) {
        let notes = _.cloneDeep(this.contact.notes) || [];

        notes.push({
          title: 'note',
          code: 'notes',
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
          htmlContent: this.commentContent,
        });

        await this.updateContact({
          id: this.contact.id,
          doc: {
            notes: notes,
          },
        });

        await this.getContactById(this.contact.id);
        this.commentContent = '';
      }
    },

    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-bar {
  height: calc(100%);
}

.save-comment-block {
  margin-top: -10px;
  margin-right: 1px;
  display: flex;
  justify-content: space-between;
}
.btn-save {
  width: 18%;
  min-width: 120px;
}
</style>
