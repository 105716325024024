import Vue from 'vue';
import * as types from './types';

export default {
  setDetailId(state, data) {
    state.detailId = data;
  },
  setProductItemList(state, payload) {
    Vue.set(state, 'productItemList', payload);
  },
  setProductItemSubscribe(state, payload) {
    Vue.set(state, 'productItemSubscribe', payload);
  },

  [types.SET_PRODUCT_LIST](state, payload) {
    Vue.set(state, 'objectList', payload);
  },
  [types.SET_PRODUCT_ITEM_LIST](state, payload) {
    Vue.set(state, 'productItemList', payload);
  },
};
