import Vue from 'vue';

export default {
  setPropertyList(state, payload) {
    Vue.set(state, 'propertyList', payload);
  },
  setPropertySubscribe(state, payload) {
    Vue.set(state, 'propertySubscribe', payload);
  },
};
