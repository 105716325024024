<template>
  <div>
    <input
      type="text"
      v-model="internalValue"
      @input="onInput($event.target.value.trim())"
      @change="handleInputValue('change')"
    />
    <span
      v-show="isShowError"
      style="color: red; font-size: 11px"
      >{{ errMessage }}</span
    >
  </div>
</template>
<script>
import { formatDots, formatPriceNumber } from '@/utility/number-tool';

export default {
  props: {
    price: [Number, String],
    allowZero: { type: Boolean, default: true },
    isResetData: { type: Boolean, default: true },
    isShowError: { type: Boolean, default: true },
  },
  data() {
    return {
      errMessage: null,
      internalValue: formatPriceNumber('0'),
      isInputActive: false,
      formatPriceNumber,
      formatDots,
    };
  },
  methods: {
    resetData() {
      this.errMessage = null;
      this.internalValue = formatPriceNumber('0');
      this.isInputActive = false;
    },
    validate() {
      if (!this.internalValue) {
        this.errMessage = 'This field is required';
      } else if (!this.allowZero && formatDots(this.internalValue) <= 0) {
        this.errMessage = 'Value must be greater than 0';
      } else {
        this.errMessage = null;
      }
      return !!this.errMessage;
    },
    onInput(val) {
      this.isInputActive = true;
      this.internalValue = formatPriceNumber(val);
      this.handleInputValue('input');
    },
    handleInputValue(event) {
      let newPrice = formatDots(this.internalValue);
      if (isNaN(newPrice)) {
        newPrice = 0;
      }
      if (event === 'change') {
        this.internalValue = formatPriceNumber(
          newPrice === 0 ? '0' : newPrice.toFixed(2)
        );
      }
      this.validate();
      this.$emit(event, newPrice);
    },
  },
  watch: {
    price: {
      handler(val) {
        if (!this.isInputActive) {
          this.internalValue = formatPriceNumber(
            isNaN(parseFloat(val)) || val === 0 ? '0' : val.toFixed(2)
          );
        }
      },
      immediate: true,
      deep: true,
    },
    isResetData(val) {
      if (val) {
        this.resetData();
      }
    },
  },
};
</script>
