import axiosService from './axios.service';

const getUserList = async () => {
  return await axiosService.get(`/admin`).then(result => {
    return result.data;
  });
};

const getUser = async uid => {
  return await axiosService
    .get(`/admin/${uid}`)
    .then(result => {
      return result.data;
    })
    .catch(error => {
      throw new Error(error.response.data);
    });
};

const updateUser = (uid, user) => {
  return axiosService
    .put(`/admin`, { uid, user })
    .then(result => {
      return result.data;
    })
    .catch(error => {
      throw new Error(error.response.data);
    });
};

const addUser = user => {
  return axiosService
    .post(`/admin`, { user })
    .then(result => {
      return result.data;
    })
    .catch(error => {
      throw new Error(error.response.data);
    });
};

const deleteUser = uid => {
  return axiosService.delete(`/admin/${uid}`);
};

const changeUserPassword = (uid, newPassword) => {
  return axiosService
    .put(`/admin/change-password`, {
      uid: uid,
      password: newPassword,
    })
    .then(result => {
      return result.data;
    })
    .catch(error => {
      throw new Error(error.response.data);
    });
};

export default {
  getUserList,
  getUser,
  updateUser,
  addUser,
  deleteUser,
  changeUserPassword,
};
