import Vue from 'vue';
import moment from 'moment';

Vue.filter('capitalize', value => {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('MMDDYYYY', (value, nullText) => {
  if (!value) return nullText;
  if (typeof day === 'string') {
    return moment(value).format('MM/DD/YYYY');
  }
  return moment(value).format('MM/DD/YYYY');
});

Vue.filter('MMDDYYYYHHMMSS', (value, nullText) => {
  if (!value) return nullText;
  if (typeof day === 'string') {
    return moment(value).format('MM/DD/YYYY HH:mm:ss');
  }
  return moment(value).format('MM/DD/YYYY HH:mm:ss');
});

Vue.filter('TIME', (value, nullText) => {
  if (!value) return nullText;
  if (typeof day === 'string') {
    return moment(value).format('LT');
  }
  return moment(value).format('LT');
});

Vue.filter('DATETIME', (value, nullText) => {
  if (!value) return nullText;
  if (typeof day === 'string') {
    return moment(value).format('dddd, MMMM Do YYYY, HH:mm:ss');
  }
  return moment(value).format('dddd, MMMM Do YYYY, HH:mm:ss');
});

Vue.filter('HMMA', (value, nullText) => {
  if (!value) return nullText;
  if (typeof day === 'string') {
    return moment(value, 'HH:mm').format('h:mma');
  }
  return moment(value, 'HH:mm').format('h:mma');
});

Vue.filter('floatFixed', (value, option = 2) => {
  let newValue = +value;
  if (!newValue) return 0;
  return newValue.toFixed(option);
});

Vue.filter('number', value => {
  if (!value && value !== 0) return null;
  window.numberFomrater =
    window.numberFomrater ||
    new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 });
  return window.numberFomrater.format(value);
});

Vue.filter('NUMBER', value => {
  if (!value && value !== 0) return null;
  window.numberFomrater =
    window.numberFomrater || new Intl.NumberFormat('en-IN');
  return window.numberFomrater.format(value);
});

Vue.filter('currencyUSD', value => {
  if ([null, undefined, ''].includes(value)) return '';
  return parseFloat(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
});

Vue.filter('percent', value => {
  return Math.round(value * 100) / 100 + '%';
});

Vue.filter('timerFormat', seconds => {
  const second = (seconds % 60).toString().padStart(2, '0');
  const minutes = Math.floor(seconds / 60);
  const minute = (minutes % 60).toString().padStart(2, '0');
  const hours = Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0');
  return `${hours}:${minute}:${second}`;
});
