import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import SpreadsheetWriter from '../../utility/spreadsheet-writer';
import { currencyUSD } from '../../utility/config';

export const getExcelWorkbook = ({ title, headers, values }) => {
  var workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Employee pay rate');
  var writer = new SpreadsheetWriter(worksheet);
  const columns = [
    ...headers.map(r => ({
      header: r.name,
      key: r.key,
      width: 10,
      style: { numFmt: '"$"#,##0.00' },
    })),
  ];
  writer.bold();
  writer.textCenter();
  writer.write((title || '').toUpperCase());
  writer.mergeNext(7);
  writer.enter();
  writer.enter();
  //header
  columns.forEach(x => {
    writer.write(x['header']);
    writer.border();
    writer.fill('D9D9D9');
    writer.bold();
    writer.mergeNext();
    writer.right();
    writer.right();
  });
  values.forEach(row => {
    writer.enter();
    writer.write(row.displayName);
    writer.border();
    writer.bold();
    writer.mergeNext();
    writer.right();
    writer.right();

    writer.write(row.phoneNumber);
    writer.border();
    writer.mergeNext();
    writer.right();
    writer.right();

    const pr = row.payRates;
    if (pr.length > 0) {
      for (var i = 0; i < pr.length; i += 1) {
        writer.write(currencyUSD(pr[i].payRate));
        writer.border();
        writer.mergeNext();
        writer.right();
        writer.right();

        writer.write(currencyUSD(pr[i].payRateOvertime));
        writer.border();
        writer.mergeNext();
        writer.right();
        writer.right();

        writer.write(pr[i].effectiveDate);
        writer.border();
        writer.mergeNext();

        if (i < pr.length - 1) {
          writer.enter();
          writer.right(4);
        }
      }
    } else {
      writer.write('(no data)');
      writer.border();
      writer.mergeNext(5);
      writer.textCenter();
    }
  });
  writer.enter();
  return workbook;
};

function exportEmployeePayRate({ title, headers, values }) {
  const workbook = getExcelWorkbook({
    title,
    headers,
    values,
  });
  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${title}.xlsx`
    );
  });
}

export default exportEmployeePayRate;
