<template>
  <div>
    <!-- Name -->
    <f7-block-title v-show="company && company.companyName"
      >Company Name</f7-block-title
    >
    <f7-list
      v-show="company && company.companyName"
      simple-list
    >
      <f7-list-item :title="company ? company.companyName : ''"></f7-list-item>
    </f7-list>

    <!-- Company Type -->
    <f7-block-title v-show="companyTypes != ''">Company Type</f7-block-title>
    <f7-list
      v-show="companyTypes != ''"
      simple-list
    >
      <f7-list-item :title="getCompanyTypeTitle(companyTypes)"></f7-list-item>
    </f7-list>

    <!-- Company Type -->
    <div v-show="company && company.accountNumber">
      <f7-block-title>Your Account Number</f7-block-title>
      <f7-list simple-list>
        <f7-list-item
          :title="company ? company.accountNumber : ''"
        ></f7-list-item>
      </f7-list>
      <!-- <f7-block-footer
        >Your account number with this Verdor</f7-block-footer
      > -->
    </div>
    <!-- Phone -->
    <f7-block-title
      v-show="
        company ? (company.phones ? company.phones.length > 0 : false) : false
      "
      >Phone Number</f7-block-title
    >
    <f7-list>
      <f7-list-item
        v-for="(phone, index) in company ? company.phones : []"
        :key="index"
        :header="phone.title"
        :title="phone.value"
      >
        <div slot="after-title">
          <f7-link
            class="external icon-link"
            :href="'tel:' + phone.value"
            icon-f7="phone"
          ></f7-link>
        </div>
      </f7-list-item>
    </f7-list>

    <f7-block-title v-if="company && company.companyTaxCode">
      Tax Code
    </f7-block-title>
    <f7-list>
      <f7-list-item
        :title="company ? company.companyTaxCode : ''"
        v-if="company && company.companyTaxCode"
      >
      </f7-list-item>
    </f7-list>

    <!-- Address -->
    <f7-block-title
      v-if="
        company
          ? company.addresses
            ? company.addresses.length > 0
            : false
          : false
      "
      >Address</f7-block-title
    >
    <f7-list>
      <f7-list-item
        v-for="(address, index) in company ? company.addresses : []"
        :key="index"
        :header="address.title"
        :title="getFullAddress(address)"
      ></f7-list-item>
    </f7-list>

    <!-- Other -->
    <f7-block-title
      v-if="
        company ? (company.others ? company.others.length > 0 : false) : false
      "
      >Other</f7-block-title
    >
    <f7-list>
      <f7-list-item
        v-for="(other, index) in company ? company.others : []"
        :key="index"
        :header="other.title"
        :title="other.value"
      ></f7-list-item>
    </f7-list>

    <!-- Contact -->

    <f7-block-title v-if="contactRefs && contactRefs.length > 0">{{
      contactRefs.length > 1 ? 'Contacts' : 'Contact'
    }}</f7-block-title>
    <f7-list media-list>
      <f7-list-item
        v-for="contactRef in contactRefs"
        :key="contactRef.id"
        :title="contactRef.contactName ? contactRef.contactName : ''"
        :subtitle="
          contactRef.phones && contactRef.phones.length > 0
            ? contactRef.phones[0].value
            : ''
        "
      >
      </f7-list-item>
    </f7-list>

    <!-- Branch -->
    <f7-block-title v-if="branchRefs && branchRefs.length > 0">{{
      branchRefs.length > 1 ? 'Branches' : 'Branch'
    }}</f7-block-title>
    <f7-list media-list>
      <f7-list-item
        v-for="branchRef in branchRefs"
        :key="branchRef.id"
        :title="branchRef.companyName"
        :subtitle="
          branchRef.addresses ? getFullAddress(branchRef.addresses[0]) : ''
        "
        :text="formatDateRefString(branchRef.fromDate, branchRef.toDate)"
      >
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { formatDateRefString } from '@/utility/datetime';
import { getFullAddress } from '@/utility/address';

export default {
  computed: {
    ...mapGetters('contact-book/company', [
      'company',
      'contactRefs',
      'branchRefs',
    ]),

    ...mapGetters('common/app-constant', ['companyTypeList']),

    companyTypes() {
      let types = '';
      if (!_.isEmpty(this.company.companyTypes)) {
        types = this.company.companyTypes.join(', ');
      }
      return types;
    },
  },
  methods: {
    ...mapActions('contact-book/client', ['setCurrentCompany']),

    getCompanyTypeTitle(types) {
      let result = '';
      if (!_.isEmpty(types)) {
        for (const type of types.split(',')) {
          const found = this.companyTypeList.find(
            item => item.value === type.trim()
          );
          result += found ? found.displayName + ', ' : '';
        }

        if (result != '') {
          return result.substring(0, result.length - 2);
        }
      }
      return '';
    },
    getFullAddress(address) {
      return getFullAddress(address);
    },
    formatDateRefString(fromDate, toDate) {
      return formatDateRefString(fromDate, toDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-bar {
  height: calc(100%);

  &::v-deep .ps__rail-y {
    visibility: hidden;
  }
}
</style>
