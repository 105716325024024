import { checkAuth } from '../../../services/authentication.service';

export default [
  // swimlane
  {
    path: '/dashboard/swimlane/residential',
    title: 'Residential',
    name: 'residential-board',
    displayRoles: ['owner', 'user'],
    parent: 'dashboard-main',
    icon: 'house',
    isShowMenuMobile: true,
    meta: {
      businessCode: 'residential',
      boardType: 'swimlane',
      boardCode: 'residential',
    },
    async: checkAuth(
      () => import('../pages/layout/ResidentialSwimlaneLayout.vue')
    ),
  },
  {
    path: '/dashboard/swimlane/residential/new',
    checkAccessPath: 'dashboard-main/residential-board',
    displayRoles: ['owner', 'user'],
    meta: {
      isNew: true,
      businessCode: 'residential',
      boardType: 'swimlane',
      boardCode: 'residential',
    },
    async: checkAuth(
      () => import('../pages/layout/ResidentialSwimlaneLayout.vue')
    ),
  },
  {
    path: '/dashboard/swimlane/residential/:cardNumber/action/:actionCode',
    checkAccessPath: 'dashboard-main/residential-board',
    displayRoles: ['owner', 'user'],
    meta: {
      businessCode: 'residential',
      boardType: 'swimlane',
      boardCode: 'residential',
    },
    async: checkAuth(
      () => import('../pages/layout/ResidentialSwimlaneLayout.vue')
    ),
  },

  // grid
  {
    path: '/dashboard/grid/residential',
    checkAccessPath: 'dashboard-main/residential-board',
    displayRoles: ['owner', 'user'],
    meta: {
      businessCode: 'residential',
      boardType: 'grid',
      boardCode: 'residential',
    },
    async: checkAuth(
      () => import('../pages/layout/ResidentialGridViewLayout.vue')
    ),
  },
  {
    path: '/dashboard/grid/residential/new',
    checkAccessPath: 'dashboard-main/residential-board',
    displayRoles: ['owner', 'user'],
    meta: {
      isNew: true,
      businessCode: 'residential',
      boardType: 'grid',
      boardCode: 'residential',
    },
    async: checkAuth(
      () => import('../pages/layout/ResidentialGridViewLayout.vue')
    ),
  },
  {
    path: '/dashboard/grid/residential/:cardNumber/action/:actionCode',
    checkAccessPath: 'dashboard-main/residential-board',
    displayRoles: ['owner', 'user'],
    meta: {
      businessCode: 'residential',
      boardType: 'grid',
      boardCode: 'residential',
    },
    async: checkAuth(
      () => import('../pages/layout/ResidentialGridViewLayout.vue')
    ),
  },

  // from my scheduling / employee scheduling
  {
    path: '/:mainRouteFrom/currentView/:currentView/startWeek/:startWeek/currentUser/:userId/dashboard/swimlane/residential/:cardNumber/action/:actionCode',
    checkAccessPath: 'dashboard-main/residential-board',
    displayRoles: ['owner', 'user'],
    meta: {
      businessCode: 'residential',
      boardType: 'swimlane',
      boardCode: 'residential',
    },
    async: checkAuth(
      () => import('../pages/layout/ResidentialSwimlaneLayout.vue')
    ),
  },
];
