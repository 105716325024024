/* eslint-disable no-unused-vars */
import { FirebaseActions } from '../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const tableAction = new FirebaseActions(
    `/system_client/${tenant}/custom_table`,
    'customTable'
  );

  return {
    bindCustomTableList: tableAction.bindCollectionBys,
    unbindCustomTableList: tableAction.unbindCollection,
    createCustomTable: tableAction.createDocument,
    updateCustomTable: tableAction.updateDocument,

    bindCustomTable({ dispatch }, { tableType, userId }) {
      return dispatch('bindCustomTableList', [
        {
          prop: 'tableType',
          val: tableType,
          op: '==',
        },
        {
          prop: 'userId',
          val: userId,
          op: '==',
        },
      ]);
    },
  };
};
