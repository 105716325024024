export const SET_LAST_ITEM_REF = 'SET_LAST_ITEM_REF';
export const MERGE_DATA_BATCH = 'MERGE_DATA_BATCH';
export const ALL_DATA_LOADED = 'ALL_DATA_LOADED';
export const PROJECT_PHOTO = 'PROJECT_PHOTO';
export const PROJECT_PHOTOS_BY_ALBUM = 'PROJECT_PHOTOS_BY_ALBUM';
export const PROJECT_PHOTOS_SIZE = 'PROJECT_PHOTOS_SIZE';
export const SET_PROJECT_PHOTO_FILTER_FIELD = 'SET_PROJECT_PHOTO_FILTER_FIELD';

export const PROJECT_PHOTO_LIST_ITEM_UPDATE = 'PROJECT_PHOTO_LIST_ITEM_UPDATE';
export const PROJECT_PHOTO_LIST_ITEM_ADD = 'PROJECT_PHOTO_LIST_ITEM_ADD';
export const PROJECT_PHOTO_LIST_ITEM_REMOVE = 'PROJECT_PHOTO_LIST_ITEM_REMOVE';
export const RESET_PHOTO_FILTER = 'RESET_PHOTO_FILTER';
export const RESET_DATA = 'RESET_DATA';
