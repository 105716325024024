import * as types from './types';
import Vue from 'vue';

export default {
  [types.SET_PROJECT](state, data) {
    Vue.set(state, 'project', data);
  },

  [types.SET_USERS_LIST](state, users) {
    Vue.set(state, 'users', users);
  },

  [types.SET_ACTION_LIST](state, list) {
    Vue.set(state, 'actionList', list);
  },
};
