export default {
  // eslint-disable-next-line no-unused-vars
  invoiceGroupList: (state, _, __, _rootGetters) => {
    return state.invoiceGroupList || [];
  },
  invoiceGroup: state => {
    return state.invoiceGroup || {};
  },
  // invoiceByNumber: state => invoiceByNumber => {
  //   return state.invoiceList.find(r => r.invoiceNumber === invoiceByNumber);
  // },
  // Fulltext search
  order: state => state.order,
  searchText: state => state.searchText,

  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
  selectedStatusFilter: state => state.selectedStatusFilter,
  queryFilters: state => {
    return state.selectedStatusFilter.map(s => `status:${s}`).join(' OR ');
  },
};
