<template>
  <f7-page class="company-edit">
    <f7-navbar>
      <f7-nav-left>
        <f7-link @click="handleCancel">Cancel</f7-link>
      </f7-nav-left>
      <f7-nav-title>Edit Company</f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="save()">Save</f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-list>
      <!-- Company Name -->
      <f7-list-input
        class="first-input"
        type="text"
        label="Company Name"
        placeholder="Enter Company Name"
        clear-button
        :value="displayCompany.companyName"
        @input="setCompanyValue('companyName', $event.target.value.trim())"
        error-message-force
        :error-message="companyNameErrorMessage"
        @blur="v$.displayCompany.companyName.$touch()"
      >
        <required-asterisk slot="label"></required-asterisk>
      </f7-list-input>

      <!-- Company Types -->
      <f7-list-item
        ref="companyTypesPopup"
        title="Company Types"
        smart-select
        :smart-select-params="{
          openIn: 'popup',
          searchbar: true,
          searchbarPlaceholder: 'Search type',
          on: {
            opened: handlePopupOpened,
          },
        }"
      >
        <select
          name="types"
          multiple
          v-model="companyTypes"
        >
          <option
            v-for="(type, index) in companyTypeList"
            :key="index"
            :value="type.value"
          >
            {{ type.displayName }}
          </option>
          >
        </select>
      </f7-list-item>
    </f7-list>

    <f7-list v-show="isVendor">
      <f7-list-input
        type="text"
        label="Account Number"
        placeholder="Your account number with this Vendor"
        clear-button
        :value="displayCompany.accountNumber"
        @input="setCompanyValue('accountNumber', $event.target.value.trim())"
      >
      </f7-list-input>
    </f7-list>

    <!-- Phone -->
    <phone-input
      :id="displayCompany.id"
      ref="phoneInput"
      isRequired
      :value="displayCompany.phones"
      @input="setCompanyValue('phones', $event)"
    ></phone-input>

    <!-- Tax code -->
    <f7-list>
      <f7-list-input
        label="Company Tax Code"
        placeholder="Enter Tax Code"
        :id="displayCompany.id"
        ref="companyTaxCodeInput"
        :value="displayCompany.companyTaxCode || ''"
        v-mask="'##-#######'"
        error-message-force
        :error-message="companyTaxCodeErrorMessage"
        @input="setCompanyValue('companyTaxCode', $event.target.value)"
      ></f7-list-input>
    </f7-list>

    <!-- Address -->
    <address-auto-complete-input
      :id="displayCompany.id"
      ref="addressInput"
      :value="displayCompany.addresses"
      @input="setCompanyValue('addresses', $event)"
    ></address-auto-complete-input>

    <!-- Other -->
    <other-input
      :id="displayCompany.id"
      ref="otherInput"
      :value="displayCompany.others"
      @input="setCompanyValue('others', $event)"
    ></other-input>

    <!-- Contact -->
    <f7-block-title>Contacts</f7-block-title>
    <f7-list media-list>
      <f7-list-item
        :id="contactRef.id"
        v-for="contactRef in contactRefs"
        :key="contactRef.id"
        swipeout
        :title="contactRef.contactName"
        :subtitle="
          contactRef.phones && contactRef.phones.length > 0
            ? contactRef.phones[0].value
            : ''
        "
        @swipeout:deleted="onRemoveContact(contactRef.id)"
      >
        <f7-icon
          class="cursor-pointer"
          color="red"
          f7="minus_circle_fill"
          slot="media"
          @click.native="onDelete(contactRef.id)"
        ></f7-icon>
        <f7-swipeout-actions right>
          <f7-swipeout-button
            delete
            confirm-title="Remove Contact"
            confirm-text="Are you sure you want to remove this contact?"
            >Remove</f7-swipeout-button
          >
        </f7-swipeout-actions>
      </f7-list-item>
      <f7-list-item
        class="cursor-pointer"
        @click.native="selectContact"
      >
        <f7-icon
          class="add-button-icon"
          f7="plus_circle"
          slot="media"
        ></f7-icon>
        <div slot="subtitle">add contact</div>
      </f7-list-item>
    </f7-list>

    <!-- Branch -->
    <f7-block-title>Branches</f7-block-title>
    <f7-list media-list>
      <f7-list-item
        v-for="branchRef in branchRefs"
        :id="branchRef.id"
        :key="branchRef.id"
        swipeout
        :title="branchRef.companyName"
        :subtitle="
          branchRef.addresses && branchRef.addresses.length > 0
            ? branchRef.addresses[0].value
            : ''
        "
        :text="formatDateRefString(branchRef.fromDate, branchRef.toDate)"
        @swipeout:deleted="onRemoveBranch(branchRef.id)"
      >
        <f7-icon
          class="cursor-pointer"
          color="red"
          f7="minus_circle_fill"
          slot="media"
          @click.native="onDelete(branchRef.id)"
        ></f7-icon>
        <f7-swipeout-actions right>
          <f7-swipeout-button
            delete
            confirm-title="Remove Branch"
            confirm-text="Are you sure you want to remove this branch?"
            >Remove</f7-swipeout-button
          >
        </f7-swipeout-actions>
      </f7-list-item>

      <f7-list-item
        class="cursor-pointer"
        @click.native="selectBranch"
      >
        <f7-icon
          class="add-button-icon"
          f7="plus_circle"
          slot="media"
        ></f7-icon>
        <div slot="subtitle">add branch</div>
      </f7-list-item>
    </f7-list>

    <!-- Property -->
    <f7-block-title>Properties</f7-block-title>
    <f7-list media-list>
      <f7-list-item
        v-for="propertyRef in propertyRefs"
        :key="propertyRef.id"
        :id="propertyRef.id"
        swipeout
        :title="propertyRef.propertyName"
        :subtitle="
          propertyRef.addresses && propertyRef.addresses.length > 0
            ? propertyRef.addresses[0].value
            : ''
        "
        :text="formatDateRefString(propertyRef.fromDate, propertyRef.toDate)"
        @swipeout:deleted="onRemoveProperty(propertyRef.id)"
      >
        <f7-icon
          class="cursor-pointer"
          color="red"
          f7="minus_circle_fill"
          slot="media"
          @click.native="onDelete(propertyRef.id)"
        ></f7-icon>
        <f7-swipeout-actions right>
          <f7-swipeout-button
            delete
            confirm-title="Remove Property"
            confirm-text="Are you sure you want to remove this property?"
            >Remove</f7-swipeout-button
          >
        </f7-swipeout-actions>
      </f7-list-item>

      <f7-list-item
        class="cursor-pointer"
        @click.native="onOpenNewPropertyPopup"
      >
        <f7-icon
          class="add-button-icon"
          f7="plus_circle"
          slot="media"
        ></f7-icon>
        <div slot="subtitle">add new property</div>
      </f7-list-item>
    </f7-list>

    <!-- Delete button -->
    <f7-list>
      <f7-list-button
        color="red"
        @click.native="onDeleteCompany"
        >Delete Company</f7-list-button
      >
    </f7-list>

    <contact-list-popup
      ref="selectContact"
      :isHiddenAddNew="false"
      @onSelected="handleContactSelected"
      @onCreated="handleContactSelected"
    ></contact-list-popup>

    <company-list-popup
      for-branch
      ref="selectBranch"
      :isHiddenAddNew="false"
      @onSelected="handleBranchSelected"
      @onCreated="handleBranchSelected"
    ></company-list-popup>

    <property-new-popup
      ref="newProperty"
      @onCreated="handlePropertySelected"
    >
    </property-new-popup>

    <!-- <property-list-popup
      ref="selectProperty"
      :isHiddenAddNew="false"
      @onSelected="handlePropertySelected"
      @onCreated="handlePropertySelected"
    ></property-list-popup> -->
  </f7-page>
</template>

<script>
import { formatDateRefString } from '../../../../utility/datetime';
import { useVuelidate } from '@vuelidate/core';
import { required, maxLength, helpers } from '@vuelidate/validators';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import PhoneInput from '@/components/inputs/PhoneInput.vue';
import AddressAutoCompleteInput from '@/components/inputs/AddressAutoCompleteInput.vue';
import OtherInput from '@/components/inputs/OtherInput.vue';

import CompanyListPopup from '@/components/popups/CompanyListPopup.vue';
import ContactListPopup from '@/components/popups/ContactListPopup.vue';
// import PropertyListPopup from "@/components/popups/PropertyListPopup.vue";
import PropertyNewPopup from '../../components/popups/PropertyNewPopup.vue';
import { mask } from 'vue-the-mask';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    PhoneInput,
    AddressAutoCompleteInput,
    OtherInput,

    CompanyListPopup,
    ContactListPopup,
    // PropertyListPopup
    PropertyNewPopup,
  },

  directives: { mask },

  data: () => {
    return {
      displayCompany: {},
      companyTypes: [],
      isNew: false,
    };
  },

  watch: {
    company: {
      handler(val) {
        if (!_.isEmpty(val)) {
          this.displayCompany = _.cloneDeep(val);
          this.companyTypes = this.displayCompany.companyTypes || [];
        }
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    ...mapGetters('common/app-constant', ['companyTypeList']),

    ...mapGetters('contact-book/company', [
      'company',
      'contactRefs',
      'branchRefs',
      'propertyRefs',
    ]),

    isVendor() {
      return (
        this.companyTypes.findIndex(item => {
          return item === 'vendor';
        }) > -1
      );
    },

    companyNameErrorMessage() {
      if (!this.v$.displayCompany.companyName.$error) return null;
      if (this.v$.displayCompany.companyName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.displayCompany.companyName.maxLength.$invalid)
        return VALIDATION_MESSAGE.PROPERTY_NAME_LENGTH;
      return null;
    },

    companyTaxCodeErrorMessage() {
      if (!this.v$.displayCompany.companyTaxCode.$error) return null;
      if (this.v$.displayCompany.companyTaxCode.validateEIN.$invalid)
        return VALIDATION_MESSAGE.INVALID_TAX_CODE;
      return null;
    },
  },

  async created() {
    this.$f7.preloader.show();
    if (_.isEmpty(this.companyTypeList)) {
      await this.initConstant();
    }
    await this.getCompanyById(this.$f7route.params.companyId);
    this.$f7.preloader.hide();
  },

  methods: {
    ...mapActions('contact-book/company', [
      'updateCompany',
      'getCompany',
      'getCompanyById',
      'deleteCompany',

      'addContactRefs',
      'addBranchRefs',
      'addPropertyRefs',

      'removeContactRefs',
      'removeBranchRefs',
      'removePropertyRefs',

      'updateContactRefs',
      'updateBranchRefs',
      'updatePropertyRefs',

      'removeCompanyRefForContact',
      'removeCompanyRefForBranch',
      'removeCompanyRefForProperty',
    ]),

    ...mapActions('common/app-constant', ['initConstant']),

    ...mapActions('contact-book/contact', ['getContact']),
    ...mapActions('contact-book/property', ['getProperty']),

    async handleContactSelected(contactId) {
      const contact = await this.getContact(contactId);
      if (!_.isEmpty(contact)) {
        await this.addContactRefs(contact);
      }
    },

    async handleRemoveContact(contactId) {
      await this.removeContactRefs(contactId);
    },

    async handleBranchSelected(branchId) {
      const branch = await this.getCompany(branchId);
      if (!_.isEmpty(branch)) {
        await this.addBranchRefs(branch);
      }
    },

    async handleRemoveBranch(branchId) {
      await this.removeBranchRefs(branchId);
    },

    async handlePropertySelected(propertyId) {
      const property = await this.getProperty(propertyId);
      if (!_.isEmpty(property)) {
        await this.addPropertyRefs(property);
      }
    },

    async handleRemoveProperty(propertyId) {
      await this.removePropertyRefs(propertyId);
    },

    setCompanyValue(key, value) {
      Vue.set(this.displayCompany, key, value);
    },

    async save() {
      if (!this.validateData()) return;

      this.$f7.preloader.show();
      this.displayCompany.companyTypes = this.companyTypes;
      await this.updateCompany({
        id: this.displayCompany.id,
        doc: this.displayCompany,
      });

      await this.updateContactRefs();
      await this.updateBranchRefs();
      await this.updatePropertyRefs();
      // reload contact on UI
      this.getCompanyById(this.displayCompany.id);

      this.$f7.preloader.hide();
      this.handleBack();
    },

    validateData() {
      this.v$.$touch();
      this.$refs.phoneInput.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      this.$refs.otherInput.v$.$touch();
      if (
        this.$refs.phoneInput.v$.$invalid ||
        this.$refs.addressInput.v$.$invalid ||
        this.$refs.otherInput.v$.$invalid ||
        this.v$.$invalid
      ) {
        return false;
      }
      return true;
    },

    onRemoveContact(contactId) {
      this.removeContactRefs(contactId);
    },

    onRemoveProperty(propertyId) {
      this.removePropertyRefs(propertyId);
    },

    onRemoveBranch(branchId) {
      this.removeBranchRefs(branchId);
    },

    onOpenNewPropertyPopup() {
      if (!this.validateData()) return;
      this.$refs.newProperty.open();
    },

    // selectProperty() {
    //   if (!this.validateData()) return;
    //   this.$refs.selectProperty.open();
    // },

    selectBranch() {
      if (!this.validateData()) return;
      this.$refs.selectBranch.open();
    },

    selectContact() {
      if (!this.validateData()) return;
      this.$refs.selectContact.open();
    },

    onDelete(id) {
      this.$f7.swipeout.open(`#${id}`, 'right', () => {});
    },

    onDeleteCompany() {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: 'Delete company',
        content: 'Are you sure you want to delete this company?',
        // hideCancelButton: true,
        textButton: 'Delete',
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$f7.preloader.show();

            await app.deleteCompany(app.displayCompany.id);

            await app.removeCompanyRefForContact();
            await app.removeCompanyRefForBranch();
            await app.removeCompanyRefForProperty();

            app.$f7router.navigate('/contactbook/companies', {
              reloadAll: true,
            });

            app.$f7.preloader.hide();
          }
        },
      });
    },

    formatDateRefString(fromDate, toDate) {
      return formatDateRefString(fromDate, toDate);
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$refs.companyTypesPopup.f7SmartSelect.searchbar.$el[0]
          .querySelector('.searchbar input')
          .focus();
      }
    },

    handleCancel() {
      var historyLength = this.$f7router.history.length;
      var previousPage = this.$f7router.history[historyLength - 2];
      if (previousPage.includes('/contactbook')) {
        this.$f7router.back({
          url: previousPage,
          force: true,
        });
      } else {
        this.$f7router.back({
          url: '/contactbook/companies',
          force: true,
        });
      }
    },

    handleBack() {
      var historyLength = this.$f7router.history.length;
      var previousPage = this.$f7router.history[historyLength - 2];
      this.$f7router.back({
        url: previousPage,
        force: true, // force reload the detaild page
      });
    },
  },

  mounted() {
    if (this.isNew && this.$device.desktop) {
      this.$el.querySelector('.first-input input').focus();
    }
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    const validateEIN = helpers.withParams({ type: 'validateEIN' }, value => {
      if (!value) return true;
      return /^\d{2}-\d{7}$/.test(value);
    });

    return {
      displayCompany: {
        companyName: {
          required,
          maxLength: maxLength(150),
        },
        companyTaxCode: {
          validateEIN,
        },
        // addresses: {
        //   $each: helpers.forEach({
        //     address: {
        //       required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
        //     },
        //     city: {
        //       required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
        //     },
        //     state: {
        //       required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
        //     },
        //     zipcode: {
        //       required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
        //     },
        //     country: {
        //       required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
        //     }
        //   })
        // }
      },
    };
  },
};
</script>
<style lang="scss">
.company-edit .item-title.item-label a {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
}
</style>
