<template>
  <f7-popup
    class="demo-popup"
    :opened="popupAddNewProposal"
    @popup:close="closePopup()"
    @popup:closed="handlePopupClosed()"
  >
    <f7-page class="proposal-new">
      <!-- Navigate bar -->
      <f7-navbar>
        <f7-nav-left>
          <f7-link
            popup-close
            @click="popupAddNewProposal = false"
            >Cancel</f7-link
          >
        </f7-nav-left>
        <f7-nav-title>Add New Proposal</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="add()">Next</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list media-list>
        <!-- Project # -->
        <f7-list-item
          :disabled="hasProject"
          :link="!$f7route.params.projectId"
          @click.native="
            !$f7route.params.projectId ? selectProject() : () => {}
          "
        >
          <div
            class="list-item-inner-start"
            slot="inner-start"
          >
            Project Title<required-asterisk />
          </div>
          <div
            class="list-item-title"
            slot="title"
          >
            {{ projectDetail.title || 'Select project' }}
          </div>
          <input-icon
            slot="media"
            icon="cube_box"
          ></input-icon>
          <div
            slot="text"
            style="color: red"
          >
            {{ projectErrorMessage }}
          </div>
        </f7-list-item>

        <!-- Estimate -->
        <f7-list-item
          v-if="!proposalNew.projectId"
          @click.native="selectEstimate"
        >
          <div
            class="list-item-inner-start"
            slot="inner-start"
          >
            Estimate<required-asterisk />
          </div>
          <div
            class="list-item-title"
            slot="title"
          >
            Select estimate
          </div>
          <input-icon
            slot="media"
            icon="expand"
          ></input-icon>
          <div
            slot="text"
            style="color: red"
          >
            {{ estimateErrorMessage || requiredSelectProjectMessage }}
          </div>
        </f7-list-item>
        <f7-list-item
          v-else
          :disabled="!!$f7route.params.estimateId"
        >
          <div
            class="list-item-inner-start"
            slot="inner-start"
          >
            Estimate<required-asterisk v-show="!isServiceJob" />
          </div>
          <f7-input
            slot="title"
            type="select"
            :value="proposalNew.estimateId"
            @change="changeEstimate"
            :disabled="!!$f7route.params.estimateId"
          >
            <option value="">Select estimate</option>
            <option
              v-for="item in estimateListOptions"
              :key="item.id"
              :value="item.id"
            >
              {{ item.value }}
            </option>
          </f7-input>
          <input-icon
            slot="media"
            icon="minus_slash_plus"
          ></input-icon>
          <div
            slot="text"
            style="color: red"
          >
            {{ estimateErrorMessage }}
          </div>
        </f7-list-item>

        <!-- Building -->
        <f7-list-item
          link
          @click.native="openSelectBuildings"
        >
          <div
            class="list-item-inner-start"
            slot="inner-start"
          >
            Specification #
            <required-asterisk
              v-if="proposalNew.estimateId"
            ></required-asterisk>
          </div>
          <div
            class="list-item-title"
            slot="title"
          >
            <span>
              {{
                (proposalNew.buildingIDs || []).length > 0
                  ? buildingByIds(proposalNew.buildingIDs)
                  : 'Select Specification'
              }}
            </span>
          </div>
          <input-icon
            slot="media"
            icon="building_2"
          ></input-icon>
          <div
            slot="text"
            style="color: red"
          >
            {{ requiredSelectEstimateMessage || buildingListErrorMessage }}
          </div>
        </f7-list-item>

        <dynamic-muti-select
          ref="selectBuildings"
          search-placeholder="Search in buildings"
          title="Select buildings"
          :value="proposalNew.buildingIDs"
          :options="buildingListOptions"
          @changed="changeBuildings"
        >
        </dynamic-muti-select>
        <!-- Proposal template -->
        <f7-list-item
          link
          @click.native="selectProposalTemplate"
        >
          <div
            class="list-item-inner-start"
            slot="inner-start"
          >
            Proposal template<required-asterisk />
          </div>
          <div
            class="list-item-title"
            slot="title"
          >
            <span>
              {{
                proposalNew.proposalTemplateId
                  ? (proposalTemplateById(proposalNew.proposalTemplateId) || {})
                      .name
                  : 'Select proposal'
              }}
            </span>
          </div>
          <input-icon
            slot="media"
            icon="book"
          ></input-icon>
          <div
            slot="text"
            style="color: red"
          >
            {{ proposalTemplateMessage }}
          </div>
        </f7-list-item>
        <!-- content type -->
        <f7-list-input
          v-if="isServiceJob"
          label="Table Content Type"
          type="select"
          :value="proposalNew.tableContentType"
          @change="handleSelectTableContentType($event.target.value)"
          error-message-force
          :error-message="tableContentTypeErrorMessage"
        >
          <option
            value=""
            hidden
          >
            Select Table Content Type
          </option>
          <option
            v-for="c in TABLE_CONTENT_TYPES"
            :key="c.value"
            :value="c.value"
          >
            {{ c.name }}
          </option>
          <required-asterisk
            v-show="proposalNew.estimateId"
            slot="label"
          />
          <input-icon
            slot="media"
            icon="table"
          ></input-icon>
        </f7-list-input>
      </f7-list>
      <!-- popups -->
      <div>
        <!-- proposal template -->
        <proposal-template-list-popup
          ref="selectProposalTemplate"
          :projectDetail="projectDetail"
          @onSelected="onSelectProposalTemplate"
        ></proposal-template-list-popup>
        <!-- project list -->
        <project-list-popup
          ref="selectProject"
          :queryFilters="`(projectBusinessCode:${BUSINESS_CODE_COMMERCIAL} OR projectBusinessCode:${BUSINESS_CODE_SERVICE})`"
          @onSelected="onSelectedProject"
        ></project-list-popup>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';

import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import _ from 'lodash';
import ProposalTemplateListPopup from './ProposalTemplateListPopup';
import ProjectListPopup from '@/components/popups/ProjectListPopup.vue';
import DynamicMutiSelect from '../inputs/DynamicMultiSelect.vue';
import common from '../../utility/common';

// import {
//   BUILDING_PRODUCT_PAGE,
//   SUMMARY_ESTIMATE_PAGE,
//   SUMMARY_ESTIMATE_TABLE_TYPE,
//   BUILDING_DESCRIPTION_PAGE,
//   TEXT_EDITOR_RESIZABLE_TYPE,
//   HEADER_TYPE,
//   FOOTER_TYPE
// } from "../../utility/const";
import {
  LINK_ALBUM_FROM_CREATE_PROPOSAL,
  TABLE_CONTENT_TYPES,
  VALIDATION_MESSAGE,
  // TABLE_CONTENT_TYPE_ITEMIZE
} from '@/utility/const';
import {
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
} from '../../../../utility/const';
import { isVideo } from '@/utility/common';
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

export default {
  components: {
    InputIcon,
    ProposalTemplateListPopup,
    ProjectListPopup,
    DynamicMutiSelect,
  },

  data() {
    return {
      popupAddNewProposal: false,
      proposalNumber: '',
      isGoingToDetail: false,
      requiredSelectProjectMessage: '',
      requiredSelectEstimateMessage: '',
      projectDetail: {},
      TABLE_CONTENT_TYPES,
      BUSINESS_CODE_COMMERCIAL,
      BUSINESS_CODE_SERVICE,
      BUSINESS_CODE_RESIDENTIAL,
    };
  },

  created() {
    if (this.hasProject) {
      this.onSelectedProject(this.$f7route.params.projectId);
    }
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {
    proposalNew: {
      proposalTemplateId: {
        required,
      },
      projectId: {
        required,
      },
      estimateId: {
        required(val) {
          if (this.isServiceJob) return true;
          return !!val;
        },
      },
      buildingIDs: {
        required(val) {
          if (this.proposalNew.estimateId) return true;
          return !!val;
        },
      },
      tableContentType: {
        required(val) {
          if (
            !this.isServiceJob ||
            (this.isServiceJob && !this.proposalNew.estimateId)
          )
            return true;
          return !!val;
        },
      },
    },
  },

  mixins: [common],

  computed: {
    ...mapGetters({
      proposalNew: 'proposal/proposal/proposalNew',
      proposalTemplateById: 'proposal/template/proposalTemplateById',
      proposalTemplateByType: 'proposal/template/proposalTemplateByType',
      proposalTemplateList: 'proposal/template/proposalTemplateList',
      estimateById: 'proposal/estimate/estimateById',
      estimateList: 'proposal/estimate/estimateList',
      constantObjectList: 'proposal/app-constant/constantObjectList',
    }),
    ...mapGetters('setting/app/profile', ['user']),
    ...mapGetters('proposal/price-list', ['categoryById']),

    buildingListErrorMessage() {
      if (
        !_.isEmpty(this.proposalNew.estimateId) &&
        _.isEmpty(this.proposalNew.buildingIDs)
      )
        return VALIDATION_MESSAGE.REQUIRED_FIELD;

      if (!this.v$.proposalNew.$error) return '';
      if (this.v$.proposalNew.buildingIDs.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;

      return '';
    },

    proposalTemplateMessage() {
      if (!this.v$.proposalNew.$error) return '';
      if (this.v$.proposalNew.proposalTemplateId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    projectErrorMessage() {
      if (!this.v$.proposalNew.$error) return '';
      if (this.v$.proposalNew.projectId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    estimateErrorMessage() {
      if (!this.v$.proposalNew.$error) return '';
      if (this.v$.proposalNew.estimateId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    tableContentTypeErrorMessage() {
      if (!this.v$.proposalNew.$error) return '';
      if (this.v$.proposalNew.tableContentType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    estimateListOptions() {
      return this.estimateList
        .filter(r => r.projectId == this.proposalNew.projectId)
        .map(r => ({
          id: r.id,
          value: `${r.estimateNumber}${
            r.estimateName ? ' - ' + r.estimateName : ''
          }`,
          createdAt: r.createdAt,
        }))
        .sort((a, b) => sortBy(b.createdAt, a.createdAt));
    },
    buildingListOptions() {
      let estimate = this.estimateById(this.proposalNew.estimateId);
      return ((estimate || {}).buildings || []).map(r => ({
        id: r.buildingId,
        value: r.buildingName,
      }));
    },

    isServiceJob() {
      return this.projectDetail.businessCode === 'service';
    },

    hasProject() {
      return !!this.$f7route.params.projectId;
    },
  },

  methods: {
    ...mapActions({
      updateProposal: 'proposal/proposal/updateProposal',
      createNewProposal: 'proposal/proposal/createNewProposal',
      setNewProposalField: 'proposal/proposal/setNewProposalField',
      resetNewProposal: 'proposal/proposal/resetNewProposal',
      copyPhoto: 'proposal/proposal/copyPhoto',
      removePhoto: 'proposal/proposal/removePhoto',
      bindEstimateListBys: 'proposal/estimate/bindEstimateListBys',
      getPropertyById: 'proposal/property/getPropertyById',
      getProjectById: 'proposal/project/getProjectById',
      getCompanyById: 'proposal/company/getCompanyById',
      getContactById: 'proposal/contact/getContactById',
      createHistory: 'proposal/history/createHistory',
      getBuilding: 'proposal/estimate/getBuilding',
      bindConstantObjectList: 'proposal/app-constant/bindConstantObjectList',
      getProjectPhotoListBys: 'proposal/photo/getProjectPhotoListBys',
      getProjectPhotoAlbumListBys: 'proposal/album/getProjectPhotoAlbumListBys',
    }),
    ...mapActions('proposal/proposal', ['setParamsBack', 'setQueryBack']),

    ...mapActions('proposal/template', [
      'bindProposalTemplateList',
      'bindProposalTemplate',
    ]),
    ...mapActions('proposal/price-list', ['bindCategoryList']),

    buildingByIds(ids) {
      if (!ids) return '';
      return (
        this.buildingListOptions.filter(r => (ids || []).includes(r.id)) || []
      )
        .map(r => r.value)
        .join(', ');
    },

    async add() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      const params = this.$f7route.params || {};
      const query = this.$f7route.query || {};
      this.setQueryBack(query);
      this.setParamsBack(params);
      // this.$f7.preloader.show();
      this.$f7.dialog.preloader('Creating your proposal. Please wait...');
      // create proposal to get id to upload image
      const proposalCreated = await this.createNewProposal({});
      let promises = [];

      promises.push(
        this.projectDetail.propertyId
          ? this.getPropertyById(this.projectDetail.propertyId)
          : {}
      );
      promises.push(
        this.projectDetail.contactId || this.projectDetail.contactID
          ? this.getContactById(
              this.projectDetail.contactId || this.projectDetail.contactID
            )
          : {}
      );
      promises.push(
        this.projectDetail.companyId
          ? this.getCompanyById(this.projectDetail.companyId)
          : {}
      );

      promises.push(
        this.bindProposalTemplate(this.proposalNew.proposalTemplateId)
      );

      const getDataByProject = await Promise.all(promises);
      const property = getDataByProject[0];
      const contact = getDataByProject[1];
      const company = getDataByProject[2];

      // estimate page
      let estimateId = this.proposalNew.estimateId || '';
      let estimate = estimateId ? this.estimateById(estimateId) : {};

      const proposalTemplate =
        this.proposalTemplateById(this.proposalNew.proposalTemplateId) || {};

      // getProjectPhotoAlbumListBys projectId and linked is proposal
      const proposalAlbum = await this.getProjectPhotoAlbumListBys([
        {
          prop: 'projectId',
          val: this.projectDetail.id,
          op: '==',
        },
        {
          prop: 'linkedFroms',
          val: LINK_ALBUM_FROM_CREATE_PROPOSAL,
          op: 'array-contains',
        },
      ]);

      // getProjectPhotoListBys projectId and albumId
      let photosByProposalAlbum = [];
      if (!_.isEmpty(proposalAlbum)) {
        const res = await this.getProjectPhotoListBys([
          {
            prop: 'projectId',
            val: this.projectDetail.id,
            op: '==',
          },
          {
            prop: 'albumId',
            val: proposalAlbum[0].id,
            op: '==',
          },
        ]);

        photosByProposalAlbum = _.cloneDeep(res || []).filter(item => {
          return !isVideo(item.photoUrl);
        });
      }

      // get products on building
      let buildingList = [];
      let photosOfBuilding = [];

      if (!_.isEmpty(this.proposalNew.buildingIDs)) {
        // promises get building
        let buildingPromises = [];
        this.proposalNew.buildingIDs.forEach(buildingId => {
          if (buildingId) {
            buildingPromises.push(
              this.getBuilding({
                estimateId,
                buildingId,
              })
            );
          }
        });
        buildingList = await Promise.all(buildingPromises);

        const uploadRes = await Promise.all([
          this.uploadFirstExistRoofPhotos(proposalCreated.id, buildingList),
          this.uploadDiagramsForBuildings(proposalCreated.id, buildingList),
        ]);

        // map new photos to buildings
        buildingList = this.mapNewPhotosToBuildings(buildingList, uploadRes);

        // save all fullpath
        const allFirstExistingRoofPhotos =
          uploadRes[0].filter(r => r.fullPath) || [];
        const mergeDiagramsPhoto = [].concat.apply([], uploadRes[1]);
        const allDiagrams = mergeDiagramsPhoto.filter(r => r.fullPath) || [];
        photosOfBuilding = allFirstExistingRoofPhotos.concat(allDiagrams);
      }
      const sortedSections = _.cloneDeep(proposalTemplate.sections).sort(
        (a, b) => a.index - b.index
      );
      const sections = await this.compileSectionData({
        orgSections: sortedSections,
        company,
        contact,
        property,
        project: this.projectDetail,
        estimate,
        buildingList,
        projectPhotoListByAlbum: photosByProposalAlbum || [],
        album: proposalAlbum[0] || null,
      });
      const products = [];
      buildingList.forEach(building => {
        const productData = [];
        (building.productData || []).forEach(section => {
          productData.push(
            ...section.productList.map(item => {
              return {
                ...item,
                checked: this.isCheckedProduct(item),
                category: (this.categoryById(item.categoryId) || {}).name || '',
              };
            })
          );
        });

        products.push({
          buildingId: building.id,
          numberOfCopy: building.numberOfCopy || 1,
          productData,
        });
      });

      let data = {
        ...this.proposalNew,
        project: {
          id: this.projectDetail.id,
          cardNumber: this.projectDetail.cardNumber,
          title: this.projectDetail.title || '',
          businessCode: this.projectDetail.businessCode,
          customerType: this.projectDetail.customerType || '',
          taxExempt: this.projectDetail.taxExempt || false,
          resalesCertificate: this.projectDetail.resalesCertificate || false,
        },
        // visibleColumns: proposalTemplateById.visibleColumns || [
        //   "productItem",
        //   "color",
        //   "manufacturer",
        //   "photos",
        //   "technicalData",
        //   "proposalNote"
        // ],
        // pages,
        productTableColumns: products.length
          ? proposalTemplate.productTableColumns || []
          : [],
        sections,
        products,
        photosOfBuilding,
      };
      if (!_.isEmpty(estimate)) {
        data['estimate'] = {
          id: estimate.id,
          estimateNumber: estimate.estimateNumber,
          estimateName: estimate.estimateName || '',
          description: estimate.description || '',
          saleTax: estimate.saleTax || 0,
          crossProfitMarginRatio: estimate.crossProfitMarginRatio || 0,
          miscPercent: estimate.miscPercent || 0,
          taxApplyType: estimate.taxApplyType || null,
          buildings: estimate.buildings || [],
        };
      }
      if (!_.isEmpty(property)) {
        data['property'] = {
          id: property.id,
          propertyName: property.propertyName || '',
          addresses: property.addresses || [],
          phones: property.phones || [],
        };
      }
      if (!_.isEmpty(contact)) {
        data['contact'] = {
          id: contact.id,
          firstName: contact.firstName || '',
          lastName: contact.lastName || '',
          addresses: contact.addresses || [],
          phones: contact.phones || [],
          emails: contact.emails || [],
        };
      }
      if (!_.isEmpty(company)) {
        data['company'] = {
          id: company.id,
          companyName: company.companyName || '',
          addresses: company.addresses || [],
          phones: company.phones || [],
          others: company.others || [],
        };
      }
      // update proposal
      await this.updateProposal({
        id: proposalCreated.id,
        doc: data,
      });
      // save history
      this.createHistory({
        proposalId: proposalCreated.id,
        note: 'Created',
      });
      this.cancel();
      this.toDetail(proposalCreated.docNumber);
      this.$f7.preloader.hide();
      this.$f7.dialog.close();
    },

    // upload first exist roof photos
    uploadFirstExistRoofPhotos(proposalId, buildingList = []) {
      let promises = [];
      buildingList.forEach(building => {
        const firstExistingRoofPhotos = (building.attachmentFiles || []).find(
          r => {
            return (
              r.fileName.toLowerCase().includes('.png') ||
              r.fileName.toLowerCase().includes('.jpg') ||
              r.fileName.toLowerCase().includes('.jpeg')
            );
          }
        );
        promises.push(
          !_.isEmpty(firstExistingRoofPhotos)
            ? this.copyPhoto({
                proposalId,
                photo: {
                  name: `${building.id}-${firstExistingRoofPhotos.fileName}`,
                  fullPath: firstExistingRoofPhotos.fullPath,
                },
              })
            : {}
        );
      });
      return Promise.all(promises);
    },

    uploadDiagramsForBuildings(proposalId, buildingList = []) {
      let promises = [];
      buildingList.forEach(building => {
        promises.push(
          !_.isEmpty(building.assemblyList)
            ? this.uploadDiagrams(
                proposalId,
                building.id,
                building.assemblyList
              )
            : {}
        );
      });
      return Promise.all(promises);
    },

    uploadDiagrams(proposalId, buildingId, assemblyList = []) {
      let assemblyPromises = [];
      assemblyList.forEach(assembly => {
        assemblyPromises.push(
          assembly.diagramFullPath
            ? this.copyPhoto({
                proposalId,
                photo: {
                  name: `${buildingId}-${assembly.assemblyId}`,
                  fullPath: assembly.diagramFullPath,
                },
              })
            : {}
        );
      });
      return Promise.all(assemblyPromises);
    },

    mapNewPhotosToBuildings(buildingList, uploadRes) {
      const firstExistingRoofPhotos = uploadRes[0];
      const diagramPhotos = uploadRes[1];
      let newBuildings = _.clone(buildingList);
      newBuildings.forEach((building, i) => {
        const firstExistingRoofPhoto = (building.attachmentFiles || []).find(
          r => {
            return (
              r.fileName.toLowerCase().includes('.png') ||
              r.fileName.toLowerCase().includes('.jpg') ||
              r.fileName.toLowerCase().includes('.jpeg')
            );
          }
        );
        // map first existing Roof photo
        if (!_.isEmpty(firstExistingRoofPhoto)) {
          building['firstExistingRoofPhoto'] = {
            fileName: firstExistingRoofPhoto.fileName,
            fullPath: firstExistingRoofPhotos[i].fullPath,
            url: firstExistingRoofPhotos[i].url,
          };
        }
        // map diagram photos
        (building.assemblyList || []).forEach((assembly, j) => {
          if (assembly.diagramFullPath) {
            assembly.diagramFullPath = diagramPhotos[i][j].fullPath;
            assembly.diagramUrl = diagramPhotos[i][j].url;
          }
        });
      });
      return newBuildings;
    },

    mapBuildingObject(building) {
      return {
        id: building.id,
        buildingName: building.buildingName || '',
        slopeType: building.slopeType || null,
        roofType: building.roofType || null,
        substrate: building.substrate || '',
        fieldArea: building.fieldArea || 0,
        productData: building.productData || [],
        description: building.description || '',
        warrantyType: building.warrantyType || '',
        warrantyNotes: building.warrantyNotes || '',
        assemblyList: (building.assemblyList || []).map(assembly => ({
          assemblyItem: assembly.assemblyItem || '',
          assemblyGA: assembly.assemblyGA || '',
          length: assembly.length || 0,
          diagramUrl: assembly.diagramUrl || '',
          diagramFullPath: assembly.diagramFullPath || '',
        })),
        firstExistingRoofPhoto: building.firstExistingRoofPhoto || null,
      };
    },
    openSelectBuildings() {
      if (this.proposalNew.estimateId) {
        this.$refs.selectBuildings.openSelectPopup();
      } else if (!this.isServiceJob) {
        this.requiredSelectEstimateMessage = 'Please select estimate first';
      }
    },
    selectProject() {
      this.requiredSelectEstimateMessage = '';
      this.$refs.selectProject.open();
    },
    selectProposalTemplate() {
      this.$refs.selectProposalTemplate.open();
    },
    selectEstimate() {
      if (!this.proposalNew.projectId) {
        this.requiredSelectProjectMessage = 'Please select project title first';
      }
    },
    changeEstimate(event) {
      this.setNewProposalField({
        fieldName: 'estimateId',
        value: event.target.value,
      });
      this.setDefaultBuilding();
      this.requiredSelectEstimateMessage = '';
    },
    setDefaultBuilding() {
      let buildingIDs = this.buildingListOptions.map(r => r.id);
      this.setNewProposalField({
        fieldName: 'buildingIDs',
        value: buildingIDs,
      });
    },
    setDefaultEstimate() {
      let estimates = this.estimateListOptions.map(r => r.id);
      if (estimates.length === 1) {
        this.setNewProposalField({
          fieldName: 'estimateId',
          value: estimates.join(''),
        });
      } else if (estimates.length > 1) {
        this.setNewProposalField({
          fieldName: 'estimateId',
          value: estimates[0],
        });
      }
    },
    changeBuildings(values) {
      this.setNewProposalField({ fieldName: 'buildingIDs', value: values });
    },
    onSelectProposalTemplate(id, businessCode = '') {
      //save proposal template id to localstorage
      businessCode = businessCode || this.projectDetail.businessCode;
      const localStorageKey = `${businessCode}ProposalTemplateIdRemember`;
      localStorage.setItem(localStorageKey, id);
      this.setNewProposalField({ fieldName: 'proposalTemplateId', value: id });
    },
    async onSelectedProject(projectId = '') {
      this.projectDetail = (await this.getProjectById(projectId)) || {};
      this.setNewProposalField({ fieldName: 'projectId', value: projectId });
      //reset
      this.setNewProposalField({ fieldName: 'estimateId', value: '' });
      // bind estimate by projectId
      this.bindEstimateListBys([
        {
          prop: 'status',
          val: 'active',
          op: '==',
        },
        {
          prop: 'projectId',
          val: projectId,
          op: '==',
        },
      ]).then(() => {
        // set default building when add proposal from estimate
        this.setDefaultEstimate();
        this.setDefaultBuilding();
      });

      //get proposal template id remember from localstorage by business code
      const businessCode = this.projectDetail.businessCode || '';

      const keyToGet = `${businessCode}ProposalTemplateIdRemember`;
      let proposalTemplateId = localStorage.getItem(keyToGet) || '';
      if (proposalTemplateId) {
        this.onSelectProposalTemplate(proposalTemplateId, businessCode);
      } else {
        const template = this.proposalTemplateByType(
          this.projectDetail.businessCode
        );
        this.onSelectProposalTemplate(template.id, businessCode);
      }

      //get table content type remember from localstorage
      const tableContentTypeRemember =
        localStorage.getItem('tableContentTypeRemember') || '';
      if (tableContentTypeRemember) {
        this.handleSelectTableContentType(tableContentTypeRemember);
      }

      if (this.projectDetail.businessCode !== 'service') {
        this.setNewProposalField({
          fieldName: 'tableContentType',
          value: null,
        });
      }
      this.requiredSelectProjectMessage = '';
    },

    toDetail(proposalNumber) {
      this.proposalNumber = proposalNumber;
      this.isGoingToDetail = true;
      this.popupAddNewProposal = false;
    },

    cancel() {
      this.resetNewProposal();
    },

    init() {
      let promises = [];
      this.$f7.preloader.show();

      promises.push(this.bindCategoryList());

      if (_.isEmpty(this.constantObjectList)) {
        promises.push(this.bindConstantObjectList());
      }
      Promise.all(promises).then(() => {
        this.$f7.preloader.hide();
      });
    },

    async openPopup() {
      this.popupAddNewProposal = true;
      this.init();

      const self = this;
      const { proposalTemplate, projectId, estimateId } = this.$f7route.params;
      if (proposalTemplate) {
        if (_.isEmpty(this.proposalTemplateList)) {
          this.bindProposalTemplateList().then(() => {
            const template = self.proposalTemplateByType(proposalTemplate);
            self.setNewProposalField({
              fieldName: 'proposalTemplateId',
              value: template.id || '',
            });
          });
        } else {
          const template = self.proposalTemplateByType(proposalTemplate);

          self.setNewProposalField({
            fieldName: 'proposalTemplateId',
            value: template.id || '',
          });
        }
      }

      if (estimateId) {
        // bind estimate by id
        this.bindEstimateListBys([
          {
            prop: 'status',
            val: 'active',
            op: '==',
          },
          {
            prop: 'projectId',
            val: projectId,
            op: '==',
          },
        ]).then(() => {
          // set default building when add proposal from estimate
          this.setDefaultBuilding();
        });
        this.setNewProposalField({
          fieldName: 'estimateId',
          value: estimateId,
        });

        this.requiredSelectEstimateMessage = '';
      }

      if (projectId) {
        this.projectDetail = (await this.getProjectById(projectId)) || {};
        this.setNewProposalField({ fieldName: 'projectId', value: projectId });
        this.requiredSelectProjectMessage = '';
      }
    },

    closePopup() {
      this.popupAddNewProposal = false;
      this.projectDetail = {};
      this.cancel();
    },
    handlePopupClosed() {
      this.cancel();
      if (this.isGoingToDetail) {
        //this.$f7router.navigate(`/proposal/${this.proposalNumber}`);
        this.$emit('toDetail', {
          proposalNumber: this.proposalNumber,
          hasProject: this.hasProject,
        });
      } else {
        this.handleBackAction();
      }
    },
    handleSelectTableContentType(value) {
      localStorage.setItem('tableContentTypeRemember', value);
      this.setNewProposalField({
        fieldName: 'tableContentType',
        value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.md .status-icon {
  margin-bottom: 16px;
}

.md .desc-icon {
  margin-bottom: 80px;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}
</style>
<style lang="scss">
.proposal-new .item-title {
  width: 100%;
}
</style>
