import {
  FirebaseActions,
  firestore,
} from '../../../../services/firebase.service';
import * as types from './types';
import { PROP_IS_DELETED } from '../../../../utility/const';

export default tenant => {
  const estimateActions = new FirebaseActions(
    `/system_client/${tenant}/estimate`,
    'estimate'
  );
  return {
    getEstimateListBys: estimateActions.getDocumentBys,
    updateEstimate: estimateActions.updateDocument,
    // deleteEstimate: estimateActions.deleteDocument,

    getEstimatesByProjectId({ dispatch, commit }, projectId) {
      return dispatch('getEstimateListBys', [
        {
          prop: 'projectId',
          val: projectId,
          op: '==',
        },
        { prop: PROP_IS_DELETED, val: true, op: '!=' },
      ]).then(res => {
        commit(types.SET_ESTIMATE_LIST, res);
      });
    },

    getEstimatesBys({ dispatch, commit }, conditions) {
      return dispatch('getEstimateListBys', conditions).then(res => {
        commit(types.SET_ESTIMATE_LIST, res);
      });
    },

    deleteEstimate({ dispatch }, id) {
      return dispatch('updateEstimate', {
        id,
        doc: { [PROP_IS_DELETED]: true },
      });
    },

    getBuilding(_, { estimateId, buildingId }) {
      return FirebaseActions.getDocument(
        `/system_client/${tenant}/estimate/${estimateId}/building`,
        buildingId
      );
    },

    getBuildingList(context, { estimateId }) {
      const collectionPath = `/system_client/${tenant}/estimate/${estimateId}/building`;
      return firestore
        .collection(collectionPath)
        .get()
        .then(snaps => {
          let docs = [];
          snaps.forEach(docSnap => {
            let doc = docSnap.data();
            doc.id = docSnap.id;
            docs.push(doc);
          });
          return docs;
        });
    },
  };
};
