<template>
  <div>
    <input
      ref="inputRef"
      v-if="type === 'text'"
      type="text"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
    />
    <input
      v-else
      ref="inputRef"
      type="text"
      v-model="displayPercent"
      @blur="
        isInputActive = false;
        $emit('blur');
      "
      @focus="isInputActive = true"
    />
    <span style="color: red; font-size: 11px">{{
      errMessage || outOfRangeError
    }}</span>
  </div>
</template>
<script>
export default {
  props: {
    value: [String, Number],
    type: String,
    item: Object,
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
  },
  data() {
    return {
      isInputActive: false,
      errMessage: null,
      internalValue: 0,
    };
  },
  computed: {
    displayPercent: {
      get() {
        var _sefl = this;
        if (this.isInputActive) {
          return this.internalValue.toString();
        } else {
          _sefl.errMessage = null;
          if (this.item.name === 'Percentage') {
            return (
              this.internalValue
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') + '%'
            );
          } else {
            return this.internalValue;
          }
        }
      },
      set(modifiedPercent) {
        if (!modifiedPercent) {
          this.errMessage = 'This field is required';
        } else if (!/^[0-9|.]+$/.test(modifiedPercent)) {
          this.errMessage = 'Incorrect format';
        } else {
          this.errMessage = null;
        }
        this.internalValue = modifiedPercent;
      },
    },
    outOfRangeError() {
      if (this.internalValue < this.min || this.internalValue > this.max) {
        return `Value must be from ${this.min} - ${this.max}`;
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.internalValue = this.value;
  },
  watch: {
    internalValue(modifiedPercent) {
      let value = parseFloat(modifiedPercent);
      if (isNaN(value) && this.type === 'text') {
        value = modifiedPercent;
      }
      this.$emit('input', value);
    },
    value(val) {
      this.internalValue = val;
    },
    isInputActive(val) {
      if (!val) {
        this.internalValue = this.value;
      } else {
        setTimeout(() => {
          this.$refs.inputRef && this.$refs.inputRef.select();
        }, 1);
      }
    },
  },
};
</script>
