import { FirebaseActions } from '../../../../services/firebase.service';
import { PROP_IS_DELETED } from '../../../../utility/const';
import * as types from './types';
import _ from 'lodash';

export default tenant => {
  const projectPhotoAlbumActions = new FirebaseActions(
    `/system_client/${tenant}/project_photo_album`,
    'projectPhotoAlbum'
  );

  const projectPhotoActions = new FirebaseActions(
    `/system_client/${tenant}/project_photo`,
    'projectPhoto'
  );

  return {
    getProjectPhotoAlbumListBys: projectPhotoAlbumActions.getDocumentBys,
    getProjectPhotoAlbumItem: projectPhotoAlbumActions.getDocument,

    createAlbum: projectPhotoAlbumActions.createDocument,
    updateAlbum: projectPhotoAlbumActions.updateDocument,
    deleteAlbum: projectPhotoAlbumActions.deleteDocument,

    getProjectPhotoSizeBys: projectPhotoActions.getDocumentSizeBys,
    getProjectPhotoBys: projectPhotoActions.getDocumentAndLimitBys,

    getProjectPhotoAlbumListByProject({ commit, dispatch }, projectId) {
      return dispatch('getProjectPhotoAlbumListBys', [
        {
          prop: 'projectId',
          val: projectId,
          op: '==',
        },
        {
          prop: PROP_IS_DELETED,
          val: false,
          op: '==',
        },
      ]).then(res => {
        if (!_.isEmpty(res)) {
          const promises = [];
          for (const album of res) {
            promises.push(
              dispatch('getTotalPhotosAndPhotoLastedOfAlbum', album.id)
            );
          }
          Promise.all(promises);
        }
        return commit(types.PROJECT_PHOTO_ALBUM_LIST, res);
      });
    },

    getProjectPhotoAlbum({ commit, dispatch }, id) {
      return dispatch('getProjectPhotoAlbumItem', id).then(res => {
        return commit(types.PROJECT_PHOTO_ALBUM, res);
      });
    },

    createProjectPhotoAlbum({ dispatch }, data) {
      return dispatch('createAlbum', data).then(id => {
        dispatch('getProjectPhotoAlbumListByProject', data.projectId);
        return id;
      });
    },

    updateProjectPhotoAlbum({ dispatch }, { id, doc, projectId }) {
      return dispatch('updateAlbum', { id, doc }).then(() => {
        return dispatch('getProjectPhotoAlbumListByProject', projectId);
      });
    },

    deleteProjectPhotoAlbum({ dispatch }, { id, projectId }) {
      return dispatch('deleteAlbum', id).then(() => {
        return dispatch('getProjectPhotoAlbumListByProject', projectId);
      });
    },

    getTotalPhotosAndPhotoLastedOfAlbum({ commit, dispatch }, albumId) {
      const conditions = [
        {
          prop: 'albumId',
          val: albumId,
          op: '==',
        },
        {
          prop: PROP_IS_DELETED,
          val: false,
          op: '==',
        },
      ];
      const promises = [];
      promises.push(dispatch('getProjectPhotoSizeBys', conditions));
      promises.push(
        dispatch('getProjectPhotoBys', { andConditions: conditions, limit: 1 })
      );
      return Promise.all(promises).then(res => {
        return commit('SET_FIELD_ALBUM', {
          albumId,
          photoTotals: res[0] || 0,
          photoLasted: (res[1] && res[1][0]) || {},
        });
      });
    },

    resetAlbum({ commit }) {
      commit(types.RESET_DATA);
    },
  };
};
