import Vue from 'vue';
import * as types from './types';

export default {
  [types.SET_PROJECT_ID](state, id) {
    state.projectId = id;
  },

  [types.SET_PROJECT_LIST](state, payload) {
    Vue.set(state, 'projectList', payload);
  },
};
