import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const shingleTypeBuildContractActions = new FirebaseActions(
    `/system_client/${tenant}/product_shingle_type`,
    'shingleType'
  );

  return {
    bindShingleTypeListBy: shingleTypeBuildContractActions.bindCollectionBy,
    unbindShingleType: shingleTypeBuildContractActions.unbindDocument,

    createShingleType: shingleTypeBuildContractActions.createDocument,
    updateShingleType: shingleTypeBuildContractActions.updateDocument,
    deleteShingleType: shingleTypeBuildContractActions.deleteDocument,
    getShingleType: shingleTypeBuildContractActions.getDocument,
  };
};
