<template>
  <div>
    <f7-text-editor
      class="no-margin"
      ref="contentEdit"
      :style="isResizable ? '' : `height: ${height}px`"
      :value="data"
      @texteditor:change="changeValue"
      :custom-buttons="customButtons"
      :buttons="buttons"
      :resizable="isResizable"
      :placeholder="placeholder"
    ></f7-text-editor>
    <select-color-popup ref="selectColor" />
  </div>
</template>

<script>
import { customButtonForTextFormatBar } from '@/utility/common';
import SelectColorPopup from '../popups/SelectColorPopup.vue';
import { buttonsForTextFormatBar } from '@/utility/const';

export default {
  components: { SelectColorPopup },
  data() {
    return {
      currentDoc: null,
      currentRange: null,
      customButtons: customButtonForTextFormatBar(this, false),
      buttons: buttonsForTextFormatBar,
    };
  },
  props: {
    data: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    height: { type: Number, default: 250 },
    isResizable: { type: Boolean, default: false },
  },
  methods: {
    changeValue($event) {
      if (this.data === $event) return;
      const convertValueHtml = $event.replace(
        /<a href="(https?:\/\/[^"]+)"/g,
        (match, url) => `<a href="#" onclick="window.open('${url}', '_blank')" `
      );
      this.$emit('change', convertValueHtml);
    },
    openDialogInsertLinkTextEditor() {
      this.$f7.dialog
        .create({
          title: `<p style="font-size: 14px;">Insert Link</p>`,
          content: `<input type="text" id="insert-link" placeholder="Insert link URL" style="width: 100%; border: 1px solid #2a2a2a; padding: 4px; border-radius:3px;">`,
          buttons: [
            {
              text: 'Cancel',
              color: 'gray',
            },
            {
              text: 'OK',
              cssClass: 'primary',
            },
          ],

          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              const editor = this.$refs.contentEdit;
              if (this.currentRange) {
                editor.$el.f7TextEditor.setSelectionRange(this.currentRange);
                const inputElement = document.getElementById('insert-link');
                const inputValue = inputElement.value;
                document.execCommand('createLink', true, inputValue);
                _sefl.app.dialog.close();
                this.currentRange = null;
              }
            }
          },
        })
        .open();
    },
  },
};
</script>

<style>
img {
  max-width: 100%;
}
</style>
