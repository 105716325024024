<template>
  <div class="popup-scroll-bar">
    <!-- segmented buttons -->
    <f7-block>
      <f7-segmented
        strong
        tag="p"
      >
        <f7-button
          :active="activeButton === 'summary'"
          @click.native="activeButton = 'summary'"
          >Summary</f7-button
        >
        <f7-button
          :active="activeButton === 'materials-costs'"
          @click.native="activeButton = 'materials-costs'"
          >Materials Costs</f7-button
        >
        <f7-button
          :active="activeButton === 'labor-cost'"
          @click.native="activeButton = 'labor-cost'"
          >Labor Cost</f7-button
        >
        <f7-button
          :active="activeButton === 'misc'"
          @click.native="activeButton = 'misc'"
          >Misc</f7-button
        >
        <f7-button
          :active="activeButton === 'invoice-collections'"
          @click.native="activeButton = 'invoice-collections'"
          >Invoice Collections</f7-button
        >
      </f7-segmented>
    </f7-block>

    <div v-show="activeButton === 'summary'">
      <summary-finance ref="summaryEl"></summary-finance>
    </div>
    <div v-show="activeButton === 'materials-costs'">
      <materials-costs-finance ref="materialEl"></materials-costs-finance>
    </div>
    <div v-show="activeButton === 'labor-cost'">
      <labor-cost-finance ref="laborCostEl"></labor-cost-finance>
    </div>
    <div v-show="activeButton === 'misc'">
      <miscellaneous-finance ref="miscEl"></miscellaneous-finance>
    </div>
    <div v-show="activeButton === 'invoice-collections'">
      <invoice-collections-finance
        ref="invoiceEl"
        v-on="$listeners"
      ></invoice-collections-finance>
    </div>
  </div>
</template>

<script>
import SummaryFinance from '../finance/SummaryFinance.vue';
import InvoiceCollectionsFinance from '../finance/InvoiceCollectionsFinance.vue';
import LaborCostFinance from '../finance/LaborCostFinance.vue';
import MaterialsCostsFinance from '../finance/MaterialsCostsFinance.vue';
import MiscellaneousFinance from '../finance/MiscellaneousFinance.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    SummaryFinance,
    InvoiceCollectionsFinance,
    LaborCostFinance,
    MaterialsCostsFinance,
    MiscellaneousFinance,
  },

  data() {
    return {
      activeButton: 'materials-costs',
    };
  },

  mounted() {
    this.activeButton =
      (this.$f7route.query && this.$f7route.query.activeButton) || 'summary';
  },

  computed: {
    ...mapGetters({
      detail: 'swimlane/card/detail',
    }),
  },

  methods: {
    init() {
      this.$refs.materialEl.initData();
      this.$refs.laborCostEl.initData();
      this.$refs.miscEl.initData();
      this.$refs.summaryEl.initData();
      this.$refs.invoiceEl.initData();
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-bar {
  height: calc(100%);
}
</style>
