<template>
  <div :class="typeSwitchScreen !== 'step' && 'container-info'">
    <f7-list
      :inset="$device.desktop"
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.INFO
        )
      "
    >
      <div
        :class="
          typeSwitchScreen === 'step' && $device.desktop
            ? 'container-info-step-row'
            : 'container-info-step-column'
        "
      >
        <div
          style="flex: 1"
          :class="typeSwitchScreen !== 'step' && 'container-info-content'"
        >
          <f7-list>
            <f7-list-input
              v-show="
                currentBuilding.workType === 'recover' ||
                currentBuilding.workType === 'tear-off'
              "
              type="select"
              label="What is the existing roof thickness? (inches)"
              :value="currentBuilding.roofThickness || ''"
              @change="saveBuildingValue('roofThickness', $event.target.value)"
              error-message-force
              validate
              validate-on-blur
              :error-message="roofThicknessErrorMessage"
            >
              <input-icon
                slot="media"
                icon="expand"
              ></input-icon>
              <option
                value=""
                hidden
              >
                Select roof thickness
              </option>
              <option
                v-for="item in roofThicknessList"
                :key="item.id"
                :value="item.value"
              >
                {{ item.displayName }}
              </option>
            </f7-list-input>

            <f7-list-input
              type="select"
              :label="`What type of substrate?${
                currentBuilding.slopeType == 'steep-slope' ? ' (optional)' : ''
              }`"
              :value="currentBuilding.substrate || ''"
              @change="onChangeSubtrate($event.target.value)"
              error-message-force
              validate
              validate-on-blur
              :error-message="substrateErrorMessage"
            >
              <input-icon
                slot="media"
                icon="map"
              ></input-icon>
              <option
                value=""
                :hidden="!(currentBuilding.slopeType == 'steep-slope')"
              >
                Select type of substrate
              </option>
              <option
                v-for="item in substrateList"
                :key="item.id"
                :value="item.value"
              >
                {{ item.displayName }}
              </option>
            </f7-list-input>

            <f7-list-item
              header="Substrate thickness (inches)"
              :disabled="
                !(
                  currentBuilding.substrate === 'tpo-light-weight-concrete' ||
                  currentBuilding.substrate === 'tpo-structural-concrete'
                )
              "
            >
              <f7-input
                slot="title"
                type="number"
                min="0"
                :value="currentBuilding.substrateThickness || 0"
                @change="
                  setField(
                    'substrateThickness',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_INSULATION,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_MEMBRANE,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="number_square"
              ></input-icon>
            </f7-list-item>
          </f7-list>

          <attachment-input
            v-if="currentBuilding.workType != WORK_TYPE_NEW"
            :add-button-title="`${
              roofType === ROOF_TYPE_TPO
                ? 'Core Sample photo attachment'
                : 'Existing Roof Photos'
            }`"
            :estimateId="currentBuilding.estimateId"
            :buildingId="currentBuilding.id"
            attachment-type="attachment"
            :value="currentBuilding.attachmentFiles"
            @input="saveBuildingValue('attachmentFiles', $event)"
          ></attachment-input>
          <!-- number insulationLayers  -->
          <f7-list>
            <f7-list-item header="Field Area (SQ)">
              <f7-input
                slot="title"
                placeholder="Enter Field Area"
                :value="currentBuilding.fieldArea"
                type="number"
                min="0"
                @change="
                  setField(
                    'fieldArea',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_INSULATION,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_MEMBRANE,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="app_badge"
              ></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('fieldArea', 'Area', [
                    SECTION_TYPE_TPO_INSULATION,
                    SECTION_TYPE_TPO_MEMBRANE,
                    SECTION_TYPE_TPO_ACCESSORIES,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            <f7-list-item header="Wall Area (SQ)">
              <input-icon
                slot="media"
                icon="rectangle_split_3x1"
              ></input-icon>
              <f7-input
                slot="title"
                placeholder="Enter Wall Area"
                :value="currentBuilding.wallArea"
                type="number"
                min="0"
                @change="
                  setField(
                    'wallArea',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_INSULATION,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_MEMBRANE,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                "
              ></f7-input>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('wallArea', 'Area', [
                    SECTION_TYPE_TPO_INSULATION,
                    SECTION_TYPE_TPO_MEMBRANE,
                    SECTION_TYPE_TPO_ACCESSORIES,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            <f7-list-item header="Tapered Area (SQ)">
              <f7-input
                slot="title"
                placeholder="Enter Tapered Area"
                :value="currentBuilding.taperedArea"
                type="number"
                min="0"
                @change="
                  setField(
                    'taperedArea',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_INSULATION,
                  });
                "
              >
              </f7-input>
              <input-icon
                slot="media"
                icon="line_horizontal_3_decrease_circle"
              ></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('taperedArea', 'Area', [
                    SECTION_TYPE_TPO_INSULATION,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>
          </f7-list>
        </div>
        <div
          style="flex: 1"
          :class="typeSwitchScreen !== 'step' && 'container-info-content'"
        >
          <f7-list>
            <f7-list-item
              title="Overflow Drain"
              name="demo-checkbox"
            >
              <input-icon
                slot="media"
                icon="flowchart"
              ></input-icon>
              <f7-toggle
                :checked="overflowDrain"
                @toggle:change="onChangeOverflowDrain"
              ></f7-toggle>
            </f7-list-item>
            <f7-list-item
              width="100"
              header="Number of Drain"
            >
              <f7-input
                slot="title"
                type="number"
                min="0"
                step="1"
                :value="currentBuilding.numberOfDrain || 0"
                @change="
                  setField(
                    'numberOfDrain',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  /*updateProducts({
                roofType: ROOF_TYPE_TPO,
                sectionId: SECTION_TYPE_TPO_INSULATION
              });

              updateProducts({
                roofType: ROOF_TYPE_TPO,
                sectionId: SECTION_TYPE_TPO_MEMBRANE
              });*/

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="number_square"
              ></input-icon>
              <span
                slot="header"
                class="input-title-desc"
              >
                (Need to double if Overflow Drain is checked)
              </span>
            </f7-list-item>
          </f7-list>

          <f7-list>
            <f7-list-item
              title="Overflow Scuppers"
              name="demo-checkbox"
            >
              <input-icon
                slot="media"
                icon="tray"
              ></input-icon>
              <f7-toggle
                :checked="overflowScuppers"
                @toggle:change="onChangeOverflowScuppers"
              ></f7-toggle>
            </f7-list-item>
            <f7-list-item
              width="100"
              header="Number of Scuppers"
            >
              <f7-input
                slot="title"
                type="number"
                min="0"
                step="1"
                :value="currentBuilding.numberOfScuppers || 0"
                @change="
                  setField(
                    'numberOfScuppers',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="number_square"
              ></input-icon>
              <span
                slot="header"
                class="input-title-desc"
              >
                (Need to double if Overflow Scuppers is checked)
              </span>
            </f7-list-item>
          </f7-list>

          <f7-list>
            <f7-list-item
              width="100"
              header="Number of Plumbing Penetrations"
            >
              <f7-input
                slot="title"
                type="number"
                min="0"
                step="1"
                :value="currentBuilding.numberOfPlumbingPenetrations || 0"
                @change="
                  setField(
                    'numberOfPlumbingPenetrations',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="layers"
              ></input-icon>
            </f7-list-item>
            <f7-list-item
              width="100"
              header="Termination Bar (LF)"
            >
              <f7-input
                slot="title"
                type="number"
                min="0"
                step="1"
                :value="currentBuilding.terminationBar || 0"
                @change="
                  setField(
                    'terminationBar',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="squares_below_rectangle"
              ></input-icon>
            </f7-list-item>
          </f7-list>
        </div>
      </div>
    </f7-list>
    <!-- Takeoff select value -->
    <dynamic-single-select
      ref="selectValue"
      search-placeholder="Search in Takeoffs"
      title="Takeoffs from StackCT"
      :options="takeoffListOptions"
      @changed="changeBuildingValue"
    >
    </dynamic-single-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DynamicSingleSelect from '../inputs/DynamicSingleSelect.vue';
import AttachmentInput from '../inputs/AttachmentInput.vue';
import { required } from '@vuelidate/validators';
import InputIcon from '@/components/icons/InputIcon.vue';
import mixins from '../../utility/mixins';
import buildingMixins from '../../utility/building-mixins';
import { useVuelidate } from '@vuelidate/core';

import {
  ROOF_TYPE_TPO,
  WORK_TYPE_NEW,
  SECTION_TYPE_TPO_INSULATION,
  SECTION_TYPE_TPO_MEMBRANE,
  SECTION_TYPE_TPO_ACCESSORIES,
  CONTENT_TYPE_EST,
} from '../../../../utility/const';
import { checkShowContentScreenSingleInEstimate } from '@/utility/common';

export default {
  components: {
    AttachmentInput,
    InputIcon,
    DynamicSingleSelect,
  },

  props: {
    roofType: String,
    showContentType: {
      type: String,
      default: 'all',
    },
  },

  mixins: [mixins, buildingMixins],

  data() {
    return {
      ROOF_TYPE_TPO,
      WORK_TYPE_NEW,
      SECTION_TYPE_TPO_INSULATION,
      SECTION_TYPE_TPO_MEMBRANE,
      SECTION_TYPE_TPO_ACCESSORIES,
      CONTENT_TYPE_EST,

      overflowDrain: false,
      overflowScuppers: false,
    };
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {
    currentBuilding: {
      roofThickness: {
        required(val) {
          return (
            (val &&
              (this.workType === 'recover' || this.workType === 'tear-off')) ||
            this.workType === 'new'
          );
        },
      },
      substrate: {
        required,
      },
    },
  },

  computed: {
    ...mapGetters('common/app-constant', [
      'roofThicknessList',
      'substrateList',
      'substrate',
    ]),
    ...mapGetters('estimate/estimate-page/estimate', [
      'currentBuilding',
      'estimate',
      'typeSwitchScreen',
    ]),
    roofThicknessErrorMessage() {
      // if (!this.v$.currentBuilding.$error) return "";
      // if (!this.v$.currentBuilding.roofThickness.required)
      //   return "This field is required";
      return '';
    },

    substrateErrorMessage() {
      // if (!this.v$.currentBuilding.$error) return "";
      // if (!this.v$.currentBuilding.substrate.required)
      //   return "This field is required";
      return '';
    },

    workType() {
      return this.currentBuilding.workType || '';
    },
  },

  methods: {
    checkShowContentScreenSingleInEstimate,
    init() {
      this.overflowDrain = this.currentBuilding.overflowDrain || false;
      this.overflowScuppers = this.currentBuilding.overflowScuppers || false;
    },

    changeBuildingValue(property, value, sections = []) {
      if (!property) return;
      this.setField(property, value || 0);
      sections.forEach(sectionId => {
        this.updateProducts({
          sectionId,
          roofType: ROOF_TYPE_TPO,
        });
      });
    },

    onChangeSubtrate(value) {
      this.saveBuildingValue('substrate', value);
      if (
        !(
          value === 'tpo-light-weight-concrete' ||
          value === 'tpo-structural-concrete'
        )
      ) {
        const substrate = this.substrate(value) || {};
        this.setField('substrateThickness', parseFloat(substrate.length));
      } else {
        this.setField('substrateThickness', 0);
      }

      // if (this.currentBuilding.roofType === ROOF_TYPE_TPO) {
      const needFluteFillItems = [
        'tpo-trapazoidal-panel',
        'tpo-u-panel',
        'tpo-r-panel',
      ];
      if (needFluteFillItems.includes(value)) {
        this.saveBuildingValue('fluteFill', true);
      } else {
        this.saveBuildingValue('fluteFill', false);
      }
      this.updateProducts({
        roofType: ROOF_TYPE_TPO,
        sectionId: SECTION_TYPE_TPO_INSULATION,
      });

      this.updateProducts({
        roofType: ROOF_TYPE_TPO,
        sectionId: SECTION_TYPE_TPO_MEMBRANE,
      });
      // }
    },

    onChangeOverflowDrain(value) {
      this.overflowDrain = value;
      this.saveBuildingValue('overflowDrain', value);
    },

    onChangeOverflowScuppers(value) {
      this.overflowScuppers = value;
      this.saveBuildingValue('overflowScuppers', value);
    },
    openSelectValue(propery, type, sections) {
      this.$refs.selectValue.openSelectPopup(propery, type, sections);
    },

    validate() {
      this.v$.$touch();
      //disble validate for this step
      return false;
      //return this.v$.$invalid;
    },
  },
};
</script>

<style lang="scss">
.item-input select.input-invalid {
  color: var(--f7-color-text-neutral) !important;
}

.input-title-desc {
  color: gray;
}

.container-info-step-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 16px;
}

.container-info-step-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 5px;
}

.container-info {
  background-color: var(--f7-color-bg-dark);
  padding: 8px 0 8px 0;
}

.container-info-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
