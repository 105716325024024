<template>
  <f7-popup
    tablet-fullscreen
    :opened="popupOpened"
    @popup:close="close"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="popupOpened = false">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{ isNew ? 'Create New Certificate' : 'Certificate Detail' }}
        </f7-nav-title>
        <f7-nav-right>
          <f7-link
            v-show="
              isNew || (this.certificate && this.certificate.status === 'draft')
            "
            @click.native="onSave"
            >Save</f7-link
          >
        </f7-nav-right>
      </f7-navbar>

      <f7-row no-gap>
        <f7-col :width="$device.desktop ? 30 : 100">
          <f7-card v-if="$device.desktop">
            <f7-card-content>
              <detail-menu
                :itemDetail="certificate || {}"
                :sortedSections="sortedSections"
                :currentSectionIndex="currentSectionIndex"
                @sectionClick="sectionClick"
              ></detail-menu>
            </f7-card-content>
            <f7-card-footer class="display-flex justify-content-flex-end">
              <f7-button
                outline
                class="margin-right-half"
                @click="onPreview()"
                >Preview</f7-button
              >
              <f7-button
                v-if="isNew || (certificate && certificate.status === 'draft')"
                outline
                @click="sendEmail()"
                >Send Email</f7-button
              >
            </f7-card-footer>
          </f7-card>
          <detail-menu-mobile
            v-else
            style="z-index: 9999"
            ref="detailMenuMobile"
            :isNew="isNew"
            :itemDetail="certificate || {}"
            :sortedSections="sortedSections"
            :currentSectionIndex="currentSectionIndex"
            @sectionClick="sectionClick"
            @onPreview="onPreview"
            @sendEmail="sendEmail"
          ></detail-menu-mobile>
        </f7-col>
        <f7-col
          :width="$device.desktop ? 70 : 100"
          ref="wrapContent"
          class="display-flex flex-direction-column"
          :style="`height: ${
            $device.desktop
              ? 'calc(100vh - var(--f7-navbar-height))'
              : 'calc(100vh - var(--f7-navbar-height) - 30px)'
          };
            margin-top: ${$device.desktop ? '0' : '30px'}`"
        >
          <f7-card v-show="$device.desktop"
            ><f7-card-header>
              Project Name: {{ card && card.title }}
            </f7-card-header>
          </f7-card>
          <f7-block
            style="
              display: flex;
              justify-content: center;
              flex: 1;
              padding: 0;
              overflow-y: auto;
              position: relative;
              bottom: 5px;
              color: #000;
            "
            @scroll.native="onViewerScroll"
            ref="scrollViewer"
          >
            <div
              ref="scrollContainer"
              class="editor-section align-self-center"
              :style="styleContent"
            >
              <div
                v-for="(section, index) in sortedSections"
                :key="index"
              >
                <section-editor
                  :ref="`editor_${index}`"
                  :section="section"
                  :readonly="
                    !isNew && certificate && certificate.status !== 'draft'
                  "
                  @editorChange="handleEditorChange"
                ></section-editor>
              </div>
              <div class="tail-section"></div>
            </div>
          </f7-block>
        </f7-col>
      </f7-row>
    </f7-page>
  </f7-popup>
</template>

<script>
import DetailMenu from '../details/DetailMenu.vue';
import DetailMenuMobile from '../details/DetailMenuMobile.vue';
import SectionEditor from '../editors/SectionEditor.vue';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import { auth } from '../../../../services/firebase.service';
import { getFullAddress } from '../../../../utility/address';
import { nowInHumanReadable } from '../../../../utility/date-time-tool';
import { formatPhone } from '../../../../utility/config';
import { PHOTOS_SECTION_TEMPLATE_CERTIFICATION_OF_COMPLETION } from '@/utility/section-template';
import { clearSelectionHightLight } from '@/utility/common';

export default {
  components: {
    DetailMenu,
    DetailMenuMobile,
    SectionEditor,
  },

  data: () => {
    return {
      popupOpened: false,
      currentSectionIndex: 0,
      sortedSections: [],
      isChanged: false,
      isNew: false,
      albumId: null,
      styleContent: '',

      keyValueDict: {
        'Roofing Company Name': '&nbsp;',
        'Roofing Company Address': '&nbsp;',
        'Roofing Company Phone Number': '&nbsp;',
        'Roofing Company Website': '&nbsp;',
        'Roofing Company Email Address': '&nbsp;',

        'Project Name': '&nbsp;',

        'Project Engineer Name': '...',
        'Roof Type': 'Shingle Roof',
        'Total SQFT': '...',
        'Total Cost': '...',
        'Number of years of warranty': '...',

        'Create Date': '&nbsp;',
        'Completed Date': '&nbsp;',

        'Contact Name': '&nbsp;',
        'Contact Phone Number': '&nbsp;',
        'Completed Project Photos': '&nbsp;',
      },
    };
  },

  computed: {
    ...mapGetters('dashboard/project', ['card', 'action']),
    ...mapGetters('dashboard/certificate', ['certificate']),
    ...mapGetters('dashboard/template', ['certificateTemplate']),
    ...mapGetters({
      setting: 'setting/app/system/setting',
    }),

    ...mapGetters('dashboard/photo', ['projectPhotoList']),

    ...mapGetters('common/app-constant', ['tenantId']),

    projectPhotoByAlbum() {
      return (this.projectPhotoList || []).filter(
        r => r.albumId === this.albumId
      );
    },
  },

  methods: {
    ...mapActions({
      bindSetting: 'setting/app/system/bindSetting',
    }),
    ...mapActions('dashboard/template', ['bindTemplateList']),
    ...mapActions('dashboard/certificate', [
      'bindCertificate',
      'unbindCertificate',
      'updateCertificate',
      'createCertificate',
    ]),
    ...mapActions('dashboard/album', ['getProjectPhotoAlbumByActionsId']),

    lowResDesktopScreen() {
      this.$nextTick(() => {
        const letterFullWidth = 1020;
        const width = this.$refs.wrapContent.$el.clientWidth;
        // const height = this.$refs.wrapContent.$el.clientHeight;
        // const spaceHeight = ((width * height) / letterFullWidth - height) / 2;
        if (width < letterFullWidth) {
          const scaleRatio = width / letterFullWidth;
          this.styleContent = `transform: scale(${
            scaleRatio - 0.03
          }); transform-origin: top; max-height: 80vh; position: absolute`;
        } else {
          this.styleContent = '';
        }
      });
    },

    async open(id) {
      this.$f7.preloader.show();
      if (_.isEmpty(this.certificateTemplate)) {
        await this.bindTemplateList();
      }
      const album = await this.getProjectPhotoAlbumByActionsId({
        projectId: this.card.id,
        actionId: this.action.id,
      });
      this.albumId = album?.id;
      if (id) {
        await this.bindCertificate(id);
        this.sortedSections = _.cloneDeep(this.certificate.sections);
      } else {
        // isNew
        this.isNew = true;
        this.isChanged = true;
        if (_.isEmpty(this.setting)) {
          await this.bindSetting(this.tenantId);
        }
        this.sortedSections = this.compileSectionData();
      }
      this.$f7.preloader.hide();
      this.lowResDesktopScreen();
      this.popupOpened = true;
    },

    initKeyData() {
      if (!_.isEmpty(this.setting)) {
        if (this.setting.companyName) {
          this.keyValueDict['Roofing Company Name'] = this.setting.companyName;
        }
        if (this.setting.address) {
          this.keyValueDict['Roofing Company Address'] = this.setting.address;
        }
        if (this.setting.phoneNumber) {
          this.keyValueDict['Roofing Company Phone Number'] =
            this.setting.phoneNumber;
        }
        if (this.setting.companyWebsite) {
          this.keyValueDict['Roofing Company Website'] =
            this.setting.companyWebsite;
        }
      }
      if (this.card) {
        this.keyValueDict['Project Name'] = this.card.title;
      }
      if (!_.isEmpty(auth.currentUser)) {
        if (auth.currentUser.email) {
          this.keyValueDict['Roofing Company Email Address'] =
            auth.currentUser.email;
        }
        if (auth.currentUser.displayName) {
          this.keyValueDict['Contact Name'] = auth.currentUser.displayName;
        }
        if (auth.currentUser.phoneNumber) {
          this.keyValueDict['Contact Phone Number'] = formatPhone(
            auth.currentUser.phoneNumber
          );
        }
      }
      if (!_.isEmpty(this.card.completedDate)) {
        this.keyValueDict['Completed Date'] = getFullAddress(
          this.card.completedDate
        );
      }
      this.keyValueDict['Create Date'] = moment().format('MMM DD YYYY');
    },

    compileSectionData() {
      this.initKeyData();
      const templateSections = _.cloneDeep(
        this.certificateTemplate.sections
      ).sort((a, b) => a.index - b.index);
      const desSections = [];
      for (const orgSection of templateSections) {
        if (orgSection.sectionId === 'photo-section') {
          const photoSections =
            this.compilePhotoCompletionSection(
              orgSection,
              _.cloneDeep(this.projectPhotoByAlbum)
            ) || [];
          if (photoSections.length > 0) {
            desSections.push(...photoSections);
          }
        } else {
          desSections.push(this.compileSection(orgSection));
        }
      }
      return desSections;
    },

    compilePhotoCompletionSection(orgSection, photos) {
      const sections = [];
      let counter = 1;
      let tempList = [];
      const numberPhotoPerPage = 6; // 6 photos per page

      for (let index = 0; index < photos.length; index++) {
        const photo = photos[index];

        tempList.push(photo);
        if (counter === numberPhotoPerPage || index === photos.length - 1) {
          counter = 1;
          const photosHtml =
            PHOTOS_SECTION_TEMPLATE_CERTIFICATION_OF_COMPLETION.getTemplate(
              tempList
            );
          const htmlValue = orgSection.htmlValue.replace(
            '<span style="background-color: #f05034;">[Completed Project Photos]</span>',
            photosHtml
          );

          sections.push({
            htmlValue,
            photos,
            sectionId: orgSection.sectionId,
            sectionName: orgSection.sectionName,
            index: orgSection.index,
          });
          tempList = [];
        } else {
          counter++;
        }
      }
      return sections;
    },

    compileSection(orgSection) {
      let htmlValue = this.parseHtmlMetaContent(
        _.cloneDeep(orgSection.htmlValue)
      );
      return {
        htmlValue,
        sectionId: orgSection.sectionId,
        sectionName: orgSection.sectionName,
        index: orgSection.index,
      };
    },

    parseHtmlMetaContent(value) {
      if (!value) return '';
      if (typeof value === 'object') return value;
      let keys = Object.keys(this.keyValueDict);
      keys.forEach(key => {
        value = value.replaceAll(
          `<span style="background-color: #f05034;">[${key}]</span>`,
          this.keyValueDict[key]
        );
      });
      return value;
    },

    close() {
      this.popupOpened = false;
      this.currentSectionIndex = 0;
      this.sortedSections = [];
      this.isChanged = false;
      this.isNew = false;
      this.albumId = null;
      this.unbindCertificate();
      if (!this.$device.desktop) {
        this.$refs.detailMenuMobile.clearData();
      }
    },

    onViewerScroll() {
      const scrollContainer = this.$refs['scrollContainer'];
      const scrollViewer = this.$refs['scrollViewer'].$el;

      if (scrollContainer.getBoundingClientRect().top > this.scrollPos) {
        // Scroll direction UP
        for (let index = this.sortedSections.length - 1; index >= 0; index--) {
          const editor = this.$refs[`editor_${index}`][0];

          if (editor) {
            const isInViewport = editor.isInViewport(
              scrollViewer.getBoundingClientRect().top
            );

            // Set the last section visible
            if (isInViewport === true) {
              this.currentSectionIndex = index;
            }
          }
        }
      } else {
        // Scroll direction DOWN
        for (let index = 0; index < this.sortedSections.length; index++) {
          const editor = this.$refs[`editor_${index}`][0];
          if (editor) {
            const isInViewport = editor.isInViewport(
              scrollViewer.getBoundingClientRect().top
            );

            // Set the first section visible
            if (isInViewport === true) {
              this.currentSectionIndex = index;
              break;
            }
          }
        }
      }
      this.scrollPos = scrollContainer.getBoundingClientRect().top;
      clearSelectionHightLight();
    },

    sectionClick({ section, index }) {
      this.$refs[`editor_${index}`][0].moveSectionToView(section);
      this.currentSectionIndex = index;
    },

    handleEditorChange({ index, value }) {
      this.isChanged = true;
      const section = this.sortedSections.find(
        section => section.index === index
      );
      if (section) {
        section.htmlValue = value;
      }
    },

    checkAskSave(callback) {
      clearSelectionHightLight();
      const app = this;
      const content =
        this.isNew && !this.certificate.id
          ? 'Please save the data before performing this action.'
          : 'Do you want to save your changes?';

      if (this.isChanged === true) {
        app.$ri.dialog.openWarningDialog({
          title: 'Save changes',
          content,
          textButton: 'Save',
          onClick: async (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
              if (this.isNew && !this.certificate.id) return;
              if (callback) callback();
            } else if (index === 1) {
              _sefl.app.dialog.close();
              await app.onSave();
              if (callback) callback();
            }
          },
        });
      } else {
        if (callback) callback();
      }
    },

    async onSave() {
      clearSelectionHightLight();
      this.$f7.preloader.show();
      if (this.isNew && !this.certificate.id) {
        const id = await this.createCertificate({
          status: 'draft',
          projectId: this.card.id,
          title: `Certificate-${this.card.title}_${nowInHumanReadable()}`,
          sections: this.sortedSections,
        });
        await this.bindCertificate(id);
        this.isNew = false;
      } else {
        await this.updateCertificate({
          id: this.certificate.id,
          doc: {
            sections: this.sortedSections,
          },
        });
      }
      this.isChanged = false;
      this.$f7.preloader.hide();
    },

    sendEmail() {
      this.checkAskSave(() => {
        this.$emit('openSendEmailPopup', this.certificate.id);
      });
    },

    onPreview() {
      this.checkAskSave(() => {
        this.$emit('onPreview', this.certificate);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-container {
  overflow-x: auto;
  margin: 0px 10px 0px 10px;
  direction: ltr;
  scroll-behavior: smooth;
}

.editor-section {
  height: 100%;
  direction: ltr;
}
.tail-section {
  height: calc(100vh * 0.65);
}
</style>
