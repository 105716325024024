import * as types from './types';
import Vue from 'vue';

export default {
  [types.SET_BUILDINGS](state, response) {
    Vue.set(state, 'buildings', response);
  },
  [types.SET_TIME_TRACKING_LIST](state, response) {
    Vue.set(state, 'timeLogList', response);
  },
  [types.SET_WORK_ORDER_TASK_LIST](state, response) {
    Vue.set(state, 'purchaseOrderList', response);
  },
  [types.SET_EXPENSE_LIST](state, response) {
    Vue.set(state, 'expenseList', response);
  },
  [types.SET_ESTIMATE_LIST](state, response) {
    Vue.set(state, 'estimateList', response);
  },
  [types.SET_INVOICE_LIST](state, response) {
    Vue.set(state, 'invoiceList', response);
  },
  [types.SET_RECURRING_INVOICE_LIST](state, response) {
    Vue.set(state, 'recurringInvoiceList', response);
  },
};
