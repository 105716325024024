import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    'company'
  );

  return {
    getCompanyById: companyActions.getDocument,
  };
};
