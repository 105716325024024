import {
  FirebaseActions,
  StorageActions,
  firestore,
} from '../../../../services/firebase.service';
import * as constant from '../../../../utility/const';
import { firestoreAction } from 'vuexfire';
import proposalService from '../../../../services/proposal.service';
import algoliaService from '../../../../services/algolia.service';
import { searchClient } from '../../../../services/search.service';
import * as types from './types';

export default tenant => {
  const ProposalActions = new FirebaseActions(
    `/system_client/${tenant}/proposal`,
    'proposal',
    {
      modelSchema: {
        id: 'string',
        proposalNumber: 'string',
        estimateId: 'string',
        buildingIDs: 'array',
        status: 'string',
        propertyId: 'string',
        projectId: 'string',
      },
    }
  );

  const proposalIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  const proposalIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  const FinancingProposalDefaultActions = new FirebaseActions(
    `/system_client/${tenant}/financing_proposal_default`,
    'financingProposalDefault'
  );

  return {
    bindProposalListBy: ProposalActions.bindCollectionBy,
    unbindProposalList: ProposalActions.unbindCollection,

    bindProposal: ProposalActions.bindDocument,
    unbindProposal: ProposalActions.unbindDocument,

    createProposal: ProposalActions.createDocument,
    saveProposal: ProposalActions.updateDocument,
    // deleteProposal: ProposalActions.deleteDocument,
    getProposalById: ProposalActions.getDocument,

    getProposalBys: ProposalActions.getDocumentBys,

    updateSectionValue({ commit }, { index, value }) {
      commit('SET_PROPOSAL_SECTION', { index, value });
    },

    setProposal({ commit }, payload) {
      commit('SET_PROPOSAL', payload);
    },

    setProposalField({ commit, state }, payload) {
      if (payload.value != state.proposal[payload.fieldName]) {
        commit('SET_PROPOSAL_FIELD', payload);
      }
    },
    setNewProposalField({ commit, state }, payload) {
      if (payload.value != state.proposalNew[payload.fieldName]) {
        commit('SET_NEW_PROPOSAL_FIELD', payload);
      }
    },
    setImagesToDelete({ commit }, payload) {
      commit('SET_IMAGES_TO_DELETE', payload);
    },
    resetNewProposal({ commit }) {
      commit('SET_NEW_PROPOSAL', {
        proposalTemplateId: '',
        proposalName: '',
        status: 'draft',
        estimateId: '',
        buildingIDs: [],
        tableContentType: '',
      });
    },
    // eslint-disable-next-line no-unused-vars
    uploadPhoto({ commit }, { proposalId, fileData }) {
      const base64Data = fileData.dataUrl;
      const meta = fileData.info;
      const name = +new Date() + meta.name || '';
      const fullPath = `proposal/${proposalId}/${name}`;
      return StorageActions.uploadFileBase64(fullPath, base64Data, meta);
    },

    uploadSignImage(
      // eslint-disable-next-line no-unused-vars
      { commit },
      { proposalId, base64Data, fileName }
    ) {
      const name = `${+new Date()}_${fileName}`;
      const fullPath = `proposal/${proposalId}/${name}`;
      return StorageActions.uploadFileBase64(fullPath, base64Data, {});
    },

    // eslint-disable-next-line no-unused-vars
    removePhoto({ commit }, fullPath) {
      return StorageActions.removeFile(fullPath);
    },
    // eslint-disable-next-line no-unused-vars
    removeFolderProposalPhotos({ commit }, proposalId) {
      return StorageActions.removeFolderContents(`proposal/${proposalId}`);
    },
    // eslint-disable-next-line no-unused-vars
    copyPhoto({ commit }, { proposalId, photo }) {
      const toPath = `proposal/${proposalId}/${Date.now()}${photo.name}`;
      return StorageActions.copyFile(photo.fullPath, toPath);
    },
    async removeProposalPhotos({ dispatch, state }, proposalId) {
      let proposal = await dispatch('getProposalById', proposalId);
      if (!proposal) return;
      let promise = [];

      let proposalList =
        (state.proposalList || []).filter(r => r.id !== proposalId) || [];
      const existCopy = proposalList.some(
        r => r.copyFromId === proposalId || r.id === proposal.copyFromId
      );
      if (!existCopy) {
        promise.push(dispatch('removeFolderProposalPhotos', proposalId));
      }

      return Promise.all(promise);
    },
    removeRedundantPhotos({ dispatch, state }, proposal) {
      let promises = [];
      const existCopy = state.proposalList.some(
        r => r.copyFromId === proposal.id || r.id === proposal.copyFromId
      );
      if (state.imagesToDelete.length && !existCopy) {
        state.imagesToDelete.forEach(r => {
          promises.push(dispatch('removePhoto', r));
        });
      } else {
        promises.push(dispatch('setImagesToDelete', []));
      }
    },

    // financing proposal default
    bindFinancingProposalDefaultList:
      FinancingProposalDefaultActions.bindCollection,
    updateFinancingProposalDefault:
      FinancingProposalDefaultActions.updateDocument,
    // financing submodule of proposal
    bindSubFinancingPropsalList(context, { proposalId }) {
      const payload = {
        collectionPath: `/system_client/${tenant}/proposal/${proposalId}/financing_proposal`,
      };

      return firestoreAction((context, { collectionPath }) => {
        return context.bindFirestoreRef(
          'financingProposalList',
          firestore.collection(collectionPath)
        );
      })(context, payload);
    },
    updateSubFinancingProposal(
      context,
      { proposalId, financingProposalId, financingProposal }
    ) {
      return firestore
        .collection(
          `/system_client/${tenant}/proposal/${proposalId}/financing_proposal`
        )
        .doc(financingProposalId)
        .update(financingProposal);
    },

    createSubFinancingProposal(context, { proposalId, financingProposal }) {
      return firestore
        .collection(
          `/system_client/${tenant}/proposal/${proposalId}/financing_proposal`
        )
        .add(financingProposal)
        .then(result => {
          result.update({ id: result.id });
          return {
            financingProposalId: result.id,
          };
        });
    },

    deleteSubFinancingProposal(context, { proposalId, financingProposalId }) {
      return firestore
        .collection(
          `/system_client/${tenant}/proposal/${proposalId}/financing_proposal`
        )
        .doc(financingProposalId)
        .delete();
    },

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    async searchProposal({ state, commit, getters }, { attributesToRetrieve }) {
      const index =
        state.order === 'asc' ? proposalIndexAsc : proposalIndexDesc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_PROPOSAL} AND isProjectArchived:false`,
        attributesToRetrieve: constant.PROPOSAL_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          'proposalNumber',
          'proposalName',
          'status',
          'projectName',
          'contactName',
          'propertyName',
        ],
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreProposal(
      { state, commit, getters },
      { attributesToRetrieve, page }
    ) {
      const index =
        state.order === 'asc' ? proposalIndexAsc : proposalIndexDesc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_PROPOSAL} AND isProjectArchived:false`,
        attributesToRetrieve: constant.PROPOSAL_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          'proposalNumber',
          'proposalName',
          'status',
          'projectName',
          'contactName',
          'propertyName',
        ],
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },
    setProposalNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },

    setProposalSearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },

    // Allow search desc only
    // setProposalSearchOrder({ commit }, payload) {
    //   commit(types.SET_SEARCH_ORDER, payload);
    // },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    setProposalType({ commit }, payload) {
      commit(types.SET_PROPOSAL_TYPE, payload);
    },

    setIsChangeProposalTypeAndNavigate({ commit }, payload) {
      commit(types.SET_IS_CHANGE_NAVIGATE, payload);
    },

    async createNewProposal({ dispatch, rootGetters }, proposal) {
      const user = rootGetters['setting/app/profile/user'];
      const doc = await proposalService.addProposalDoc(user.tenantId, proposal);
      // save to algolia
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: 'proposal',
        id: doc.id,
      });
      await dispatch('searchProposal', {});
      return doc;
    },

    async updateProposal({ dispatch }, { id, doc }) {
      if (doc.projectId) {
        doc.isProjectArchived = await dispatch(
          'common/common/getIsProjectArchived',
          doc.projectId,
          { root: true }
        );
      }
      await dispatch('saveProposal', { id, doc });
      // save to algolia
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: 'proposal',
        id,
      });
      await dispatch('searchProposal', {});
    },
    async deleteProposal({ dispatch }, id) {
      await dispatch('saveProposal', {
        id,
        doc: { [constant.PROP_IS_DELETED]: true },
      });
      // save to algolia
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: 'proposal',
        id,
      });
      await dispatch('searchProposal', {});
    },
    setParamsBack({ commit }, payload) {
      commit(types.SET_PARAMS_BACK, payload);
    },
    setQueryBack({ commit }, payload) {
      commit(types.SET_QUERY_BACK, payload);
    },
    saveTempProposal({ commit }, payload) {
      commit(types.SET_TEMP_PROPOSAL, payload);
    },
    clearTempProposal({ commit }) {
      commit(types.CLEAR_TEMP_PROPOSAL);
    },
    setSelectedStatusFilter({ commit }, payload) {
      commit(types.SET_SELECTED_STATUS_FILTER, payload);
    },
    updateItemProposal({ commit }, payload) {
      commit(types.SET_ITEM_PROPOSAL, payload);
    },
  };
};
