<template>
  <div class="schedule-of-value">
    <f7-block-title>{{
      scheduleValue ? scheduleValue.title : ''
    }}</f7-block-title>
    <f7-row>
      <f7-col
        width="100"
        large="50"
      >
        <f7-list no-hairlines>
          <f7-list-input
            floating-label
            type="number"
            label="Mobilization(%)"
            :value="mobilization"
            placeholder="Mobilization(%)"
            @input="changeMobilization($event.target.value)"
            clear-button
          >
          </f7-list-input>
        </f7-list>
      </f7-col>
      <f7-col
        width="100"
        large="50"
      >
        <f7-list no-hairlines>
          <f7-list-input
            floating-label
            type="number"
            label="Tax(%)"
            :value="tax"
            placeholder="Tax(%)"
            @input="changeTax($event.target.value)"
            clear-button
          >
          </f7-list-input>
        </f7-list>
      </f7-col>
    </f7-row>

    <f7-block>
      <f7-row class="justify-content-space-between align-items-center">
        <div>
          <f7-block-title medium>Schedule of value inputs</f7-block-title>
        </div>
        <div class="display-flex">
          <f7-button
            class="margin-right-half"
            @click="addInfomation"
            fill
            >Add rows</f7-button
          >
          <f7-button
            class="margin-right-half"
            @click="isShowSetting = true"
            fill
            >Add columns</f7-button
          >
        </div>
      </f7-row>
    </f7-block>
    <div class="data-table">
      <table-comon
        :headers="headers"
        :items="scheduleInfomation"
      >
        <template v-slot:tdCommon="{ item, colunm, index }">
          <span v-if="(!index && colunm.value == 'name') || index == 1">
            <span class="totals">{{ item[colunm.value] }}</span>
          </span>
          <enter-input-schedule
            v-else
            :type="colunm.type"
            :item="item"
            :value="
              colunm.type === 'text'
                ? item[colunm.value]
                : item[colunm.value] || 0
            "
            @input="changeScheduleInfomation(colunm.value, item, $event)"
          />
        </template>
        <template v-slot:action="{ item, index }">
          <span
            v-if="!index"
            class="totals"
            >{{ item.totals | currencyUSD }}</span
          >
          <a
            v-else-if="index >= 2"
            tabindex="-1"
            href="#"
            @click="removeInfomation(item)"
          >
            <i
              class="f7-icons"
              style="color: red"
              >trash</i
            >
          </a>
        </template>
      </table-comon>
    </div>

    <generate-schedule
      :isShowGenerate="isShowGenerate"
      :mobilization="mobilization"
      @cancel="isShowGenerate = false"
    >
    </generate-schedule>
    <generate-schedule-splited
      :isShowGenerate="isShowGenerate"
      :mobilization="mobilization"
      :tax="tax"
    >
    </generate-schedule-splited>
    <list-item-schedule
      :isShowSetting="isShowSetting"
      @removeDataSchedule="removeDataSchedule"
      @changeSchedule="changeSchedule"
      @changeScheduleColunm="changeScheduleColunm"
      :items="scheduleSettingList"
      @addNewSchedule="addNewSchedule($event)"
      @cancel="isShowSetting = false"
    ></list-item-schedule>
  </div>
</template>
<script>
import TableComon from '../comons/TableComon.vue';
import GenerateSchedule from '../popup/GenerateSchedule.vue';
import GenerateScheduleSplited from '../popup/GenerateScheduleSplited.vue';
import EnterInputSchedule from '../inputs/EnterInputSchedule.vue';
import ListItemSchedule from '../popup/ListItemSchedule.vue';

import { mapGetters, mapMutations, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      isShowGenerate: false,
      isShowSetting: false,
    };
  },

  components: {
    TableComon,
    GenerateScheduleSplited,
    GenerateSchedule,
    EnterInputSchedule,
    ListItemSchedule,
  },

  watch: {
    scheduleValue: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!_.isEmpty(val)) {
          this.setScheduleInfo(val);
        }
      },
    },
  },

  methods: {
    ...mapActions('schedule/schedule-setting', ['setScheduleInfo']),
    ...mapMutations('schedule/schedule-setting', [
      'removeScheduleInfomation',
      'editScheduleInfomation',
      'addScheduleInfomation',
      'changeMobilization',
      'changeTax',
    ]),
    ...mapActions('common/schedule-setting', [
      'getScheduleSettingList',
      'createScheduleSetting',
      'updateScheduleSetting',
      'deleteScheduleSetting',
    ]),
    ...mapActions('common/schedule-value', ['updateScheduleValue']),

    removeInfomation(item) {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: 'Delete item',
        content: 'Are you sure you want to delete the item?',
        textButton: 'Delete',
        // hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.removeScheduleInfomation(item);
            _sefl.app.dialog.close();
          }
        },
      });
    },

    addInfomation() {
      let data = { id: new Date().getTime() };
      this.headers.forEach(x => {
        data[x.value] =
          x.value === 'name'
            ? 'Building ' + (this.scheduleInfomation.length - 1)
            : 0;
      });
      this.addScheduleInfomation(data);
    },

    changeScheduleInfomation(colunm, item, value) {
      const data = {
        colunm,
        id: item.id,
        value,
      };
      this.editScheduleInfomation(data);
    },
    changeScheduleColunm({ id, value }) {
      let scheduleColunms = _.cloneDeep(this.scheduleColunms) || [];
      if (value) {
        !scheduleColunms.includes(id) && scheduleColunms.push(id);
      } else {
        let index = scheduleColunms.findIndex(r => r === id);
        if (index >= 0) {
          scheduleColunms.splice(index, 1);
        }
      }
      this.updateScheduleValue({
        id: this.$f7route.params.id,
        doc: {
          scheduleColunms: scheduleColunms,
        },
      });
    },
    removeDataSchedule(id) {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: 'Delete item',
        content: 'Are you sure you want to delete the item?',
        textButton: 'Delete',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.deleteScheduleSetting(id);
            _sefl.app.dialog.close();
          }
        },
      });
    },

    changeSchedule(data) {
      this.updateScheduleSetting({
        id: data.id,
        doc: {
          [data.column]: data.value,
        },
      });
    },

    addNewSchedule(callback) {
      this.createScheduleSetting({
        name: '',
      }).then(() => {
        callback();
      });
    },
  },

  computed: {
    ...mapGetters('schedule/schedule-setting', [
      'scheduleInfomation',
      'mobilization',
      'tax',
    ]),
    ...mapGetters('common/schedule-setting', ['scheduleSettingList']),
    ...mapGetters('common/schedule-value', [
      'scheduleValue',
      'scheduleColunms',
    ]),
    headers() {
      let itemsSchedules =
        this.scheduleSettingList.filter(x =>
          this.scheduleColunms.includes(x.id)
        ) || [];
      let headers = itemsSchedules.map(x => {
        let result = {
          text: x.name,
          field: x.id,
          align: 'left',
          type: 'number',
          sortable: false,
          value: x.id,
        };
        return result;
      });
      headers.unshift({
        field: 'name',
        text: '',
        value: 'name',
        sortable: false,
        align: 'left',
        type: 'text',
      });
      headers.push({
        field: 'action',
        text: '',
        value: 'action',
        sortable: false,
        align: 'left',
      });
      return headers;
    },
  },

  created() {
    this.$f7.preloader.show();
    this.getScheduleSettingList().finally(() => {
      this.$f7.preloader.hide();
    });
  },

  mounted() {
    this.$f7.tooltip.create({
      targetEl: '.navbar-tooltip',
      text: 'Add new items',
    });
  },
};
</script>

<style lang="scss" scoped>
.totals {
  font-weight: bold;
}
.setting {
  display: flex;
  font-size: 30px;
  padding-left: 5px;
}
.layout-setting {
  display: flex;
  justify-content: center;
}

::v-deep table {
  table-layout: fixed !important;
}
::v-deep table thead tr th {
  width: 150px;
}
::v-deep table thead tr th:last-child {
  text-align: right !important;
}
::v-deep table tbody tr td:last-child {
  text-align: right !important;
}
::v-deep table thead tr th:first-child {
  width: 250px;
}
::v-deep table thead tr th:last-child {
  width: 120px;
}
</style>
