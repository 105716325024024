<template>
  <div>
    <f7-button
      :disabled="isDisableExportButton"
      fill
      @click="openConfirmExportToQb"
    >
      Export to Quickbook
    </f7-button>
    <!-- <f7-button fill @click="openConfirmExportToExcel">
        Export to excel
      </f7-button> -->
    <export-has-data-fail-popup
      :isShow="isShowExportDataFailPopup"
      @showFailRecords="showFailRecords"
      @close="isShowExportDataFailPopup = false"
    ></export-has-data-fail-popup>

    <expenses-history-popup
      v-if="currentBtnType === 'expenses'"
      title="Failed Records"
      :dataSource="expensesList"
      :isShow="isShowHistoryPopup"
      @close="isShowHistoryPopup = false"
    ></expenses-history-popup>
    <check-history-popup
      v-if="currentBtnType === 'check'"
      title="Failed Records"
      :dataSource="checkPayableList"
      :isShow="isShowHistoryPopup"
      @close="isShowHistoryPopup = false"
    ></check-history-popup>
    <invoice-history-popup
      v-if="currentBtnType === 'invoice'"
      title="Failed Records"
      :dataSource="invoiceList"
      :isShow="isShowHistoryPopup"
      @close="isShowHistoryPopup = false"
    ></invoice-history-popup>
  </div>
</template>
<script>
import ExportHasDataFailPopup from '@/plugins/quickbooks/components/popups/ExportHasDataFailPopup.vue';
import CheckHistoryPopup from '@/plugins/quickbooks/components/popups/CheckHistoryPopup.vue';
import ExpensesHistoryPopup from '@/plugins/quickbooks/components/popups/ExpensesHistoryPopup.vue';
import InvoiceHistoryPopup from '@/plugins/quickbooks/components/popups/InvoiceHistoryPopup.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    ExportHasDataFailPopup,
    CheckHistoryPopup,
    ExpensesHistoryPopup,
    InvoiceHistoryPopup,
  },

  props: {
    isDisableExportButton: { type: Boolean, default: true },
  },

  data: () => {
    return {
      isShowExportDataFailPopup: false,
      isShowHistoryPopup: false,
    };
  },
  methods: {
    openExportSuccessOrFailPopup(title, content) {
      this.$ri.dialog.openWarningDialog({
        title: `Export ${title}`,
        content: content,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        },
      });
    },

    openExportHasDataFailPopup() {
      this.isShowExportDataFailPopup = true;
    },

    showFailRecords() {
      this.isShowHistoryPopup = true;
    },

    openConfirmExportToQb() {
      const app = this;
      app.$ri.dialog.openInfoDialog({
        title: 'Export To Quickbook',
        content: 'Do you want to import the selected records into Quickbooks?',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$emit('exportToQuickbook');
            //success
            // app.openExportSuccessOrFailPopup(
            //   "success",
            //   "Imported Successfully!"
            // );

            //fail
            // app.openExportSuccessOrFailPopup("fail", "Failed to import!");

            //part of the data war fail to import
            // app.openExportHasDataFailPopup();

            _sefl.app.dialog.close();
          }
        },
      });
    },

    openConfirmExportToExcel() {
      const app = this;
      app.$ri.dialog.openInfoDialog({
        title: 'Export To Excel',
        content: 'Do you want to archive the records as Paid?',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$emit('exportToExcel');
            _sefl.app.dialog.close();
          }
        },
      });
    },
  },
  computed: {
    ...mapGetters('quickbooks/common', ['currentBtnType']),
    checkPayableList() {
      return [
        {
          id: '1',
          check: 'Chk-3434',
          paidDate: new Date('8/15/2020'),
          checkAmount: '6000',
          invoiceNumber: 'INV-6454135',
          invoiceAmount: '6000',
        },
      ];
    },
    expensesList() {
      return [
        {
          id: '1',
          employeeName: 'Trung Dang',
          reportedDate: new Date('8/15/2020'),
          amount: '30.25',
          expensesType: 'Mileage',
        },
      ];
    },
    invoiceList() {
      return [
        {
          id: '1',
          invoiceNumber: 'INV-6454135',
          invoiceDate: new Date('8/15/2020'),
          amount: '6000',
          vendorName: 'ABC Supply',
        },
      ];
    },
  },
};
</script>
