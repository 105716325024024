export default {
  contactList: state => state.contactList,

  contact: state => state.contact,

  contactById: state => id => {
    return state.contactList.find(contact => {
      return contact.id === id;
    });
  },

  contactGroup:
    state =>
    (searchValue = '') => {
      let group = [];
      let contacts =
        searchValue != ''
          ? state.contactList.filter(contact => {
              let contactFirstName = contact.firstName
                ? contact.firstName.toLowerCase()
                : '';
              let contactLastName = contact.lastName
                ? contact.lastName.toLowerCase()
                : '';

              let contactName = contactFirstName + ' ' + contactLastName;
              return (
                contactFirstName.indexOf(searchValue.toLowerCase()) >= 0 ||
                contactLastName.indexOf(searchValue.toLowerCase()) >= 0 ||
                contactName.trim().indexOf(searchValue.toLowerCase()) >= 0
              );
            }) || []
          : state.contactList;

      contacts.forEach(contact => {
        let letter = contact.firstName
          ? contact.firstName.charAt(0).toUpperCase()
          : '' || contact.lastName
            ? contact.lastName.charAt(0).toUpperCase()
            : '';
        let index = group.findIndex(groupName => {
          return letter === Object.keys(groupName)[0];
        });
        if (index >= 0) {
          group[index][letter].push(contact);
        } else {
          group.push({ [letter]: [contact] });
        }
      });

      group.forEach(data => {
        let letter = Object.keys(data)[0];
        data[letter].sort((a, b) => {
          return (a.firstName ? a.firstName.charAt(1).toUpperCase() : 0) <
            (b.firstName ? b.firstName.charAt(1).toUpperCase() : 0)
            ? -1
            : 0;
        });
      });

      return group.sort((a, b) => {
        return Object.keys(b)[0] > Object.keys(a)[0] ? -1 : 0;
      });
    },
};
