<template>
  <f7-popup
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="cancel"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title> Move to Album </f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="save()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list>
        <f7-list-input
          type="select"
          label="Album Name"
          :value="albumId"
          @change="albumId = $event.target.value"
          error-message-force
          validate
          validate-on-blur
          :error-message="albumErrorMessage"
        >
          <required-asterisk slot="label"></required-asterisk>
          <option
            value=""
            hidden
          >
            Select album
          </option>
          <option
            v-for="album in projectPhotoAlbumList"
            :key="album.id"
            :value="album.id"
          >
            {{ album.name }}
          </option>
        </f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  data: () => ({
    albumId: '',
    photoIdSelected: [],
    popupOpened: false,
  }),
  methods: {
    ...mapActions('photo/photo', ['updateProjectPhoto']),
    open(selectedIds) {
      this.photoIdSelected = selectedIds;
      this.popupOpened = true;
    },
    cancel() {
      this.albumId = '';
      this.photoIdSelected = [];
      this.popupOpened = false;
      this.v$.$reset();
    },
    save() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        this.$f7.preloader.show();
        const promises = [];
        for (const photoId of this.photoIdSelected) {
          promises.push(
            this.updateProjectPhoto({
              id: photoId,
              doc: { albumId: this.albumId },
            })
          );
        }
        Promise.all(promises).then(() => {
          this.cancel();
          this.$emit('cancelSelect');
          this.$f7.preloader.hide();
        });
      }
    },
  },
  computed: {
    ...mapGetters('photo/album', ['projectPhotoAlbumList']),
    albumErrorMessage() {
      if (!this.v$.albumId.$error) return null;
      if (this.v$.albumId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  validations() {
    return {
      albumId: {
        required,
      },
    };
  },
};
</script>
