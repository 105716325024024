<template>
  <div class="autocomplete-list-wrapper">
    <ul class="autocomplete-list">
      <auto-complete-item
        v-for="item in items"
        :key="item.id"
        :item="item"
        @click="onClick"
        @focus="onFocus"
        @blur="onBlur"
      >
        {{ item[display] }}
      </auto-complete-item>
    </ul>
  </div>
</template>

<script>
import AutoCompleteItem from './AutoCompleteItem';

export default {
  name: 'auto-complete-list',

  components: {
    AutoCompleteItem,
  },

  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },

    display: {
      type: String,
      default: 'text',
    },
  },

  methods: {
    onBlur(event, item) {
      this.$emit('item:blur', event, item);
    },

    onFocus(event, item) {
      this.$emit('item:focus', event, item);
    },

    onClick(event, item) {
      this.$emit('item:click', event, item);
    },
  },
};
</script>
<style lang="scss" scoped>
.autocomplete-list-wrapper {
  max-height: 200px;
  overflow-y: auto;
}
</style>
