import { FirebaseActions, firebase } from '../../services/firebase.service';
import notificationService from '../../services/notification.service';
import * as types from './types';
import _ from 'lodash';
const auth = firebase.auth();

export default tenant => {
  const notificationsActions = new FirebaseActions(
    `/system_client/${tenant}/notifications`,
    'notifications'
  );
  const tokenUserActions = new FirebaseActions(
    `/system_client/${tenant}/user_Tokens`,
    'userTokens'
  );
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    'project'
  );

  const boardActions = new FirebaseActions(
    `/system_client/${tenant}/swimlane_board`,
    'board'
  );

  const actionActions = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    'action'
  );

  const notifyTemplatesActions = new FirebaseActions(
    `/system_client/${tenant}/notification_templates`,
    'notifyTemplates'
  );

  return {
    unbindNotificationList: notificationsActions.unbindCollection,
    createNotification: notificationsActions.createDocument,
    getMoreNotification: notificationsActions.getMorePage,
    getNotificationListBys: notificationsActions.getDocumentBys,
    getNotificationBys: notificationsActions.getDocument,
    updateNotification: notificationsActions.updateDocument,
    addNewUserToken: tokenUserActions.createDocument,
    updateUserToken: tokenUserActions.updateDocument,
    getUserTokenListBys: tokenUserActions.getDocumentBys,
    getNotifyTemplatesListBys: notifyTemplatesActions.getDocumentBys,
    getProjectById: projectActions.getDocument,
    getBoardById: boardActions.getDocument,
    getActionById: actionActions.getDocument,

    async getTokenListBys({ commit, dispatch }, value) {
      const userTokenList = await dispatch('getUserTokenListBys', [
        {
          prop: 'userId',
          val: value,
          op: '==',
        },
      ]);
      await commit(types.SET_USER_TOKEN_LIST, userTokenList || []);
      return userTokenList;
    },

    async updateAllNotification({ commit, dispatch, rootGetters }) {
      const notifyListByUser =
        rootGetters['common/notification/notifyListByUser'];

      const updatePromises = notifyListByUser.map(notify =>
        dispatch('updateNotification', {
          id: notify.id,
          doc: { isRead: true },
        })
      );
      await dispatch('getNotificationListByUser');
      await Promise.all(updatePromises).then(() => {
        commit(types.UPDATE_NOTIFICATION_LIST);
      });
    },

    async getNotificationListByUser({ rootGetters, dispatch, commit }) {
      const user = rootGetters['setting/app/profile/user'];
      const notifyListByUser = await dispatch('getNotificationListBys', [
        { prop: 'isRead', val: false, op: '==' },
        { prop: 'to', val: user.uid, op: '==' },
      ]);
      commit(types.NOTIFICATION_LIST, notifyListByUser);
    },

    async updateNotificationBys({ commit, dispatch }, value) {
      const notify = await dispatch('getNotificationBys', value);
      await dispatch('updateNotification', {
        id: notify.id,
        doc: { isRead: true },
      }).then(async () => {
        await dispatch('getNotificationListByUser');
        commit(types.UPDATE_NOTIFICATION_LIST, value);
      });
    },

    resetNotificationPage({ commit }) {
      commit(types.RESET_PAGE);
    },

    async getNotifyTemplatesList({ commit, dispatch }) {
      const notifyTempList = await dispatch('getNotifyTemplatesListBys', [
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);
      await commit(types.SET_NOTIFY_TEMPLATE_LIST, notifyTempList || []);
      return notifyTempList;
    },

    async createNotificationByType({ dispatch, rootGetters }, { data, type }) {
      if (!data.assignees || data.assignees.length === 0) {
        return;
      }
      const notifyTempList = rootGetters['common/notification/notifyTempList'];
      const matchingTemplate = notifyTempList.find(
        template => template.template === type
      );
      let assignees = _.cloneDeep(data.assignees).filter(
        id => id !== auth.currentUser.uid
      );
      let results = [];
      if (matchingTemplate) {
        const currentUserDisplayName = auth.currentUser.displayName || '';
        const projectTitle = data.project.title || '';
        const projectType = data.project.type || '';
        const projectTo = data.project.to || '';
        const projectSubTitle = '(' + data.project.subTitle + ')' || '';
        const projectNextTitle = data.project.nextTitle || '';
        const projectOldName = data.oldName || '';
        const priceListName = data.priceList || '';
        let htmlContent = matchingTemplate.htmlContent;
        htmlContent = htmlContent
          .replace('{currentUser_displayName}', currentUserDisplayName)
          .replace('{project_title}', projectTitle)
          .replace('{project_type}', projectType)
          .replace('{project_to}', projectTo)
          .replace('{project_sub_title}', projectSubTitle)
          .replace('{project_next_title}', projectNextTitle)
          .replace('{project_old_name}', projectOldName)
          .replace('{price_list_name}', priceListName);

        for (const assignee of assignees) {
          const result = {
            from: auth.currentUser ? auth.currentUser.uid : '',
            activityType: type,
            message: htmlContent,
            to: assignee,
            projectName: projectTitle,
            projectId: data.project.id,
            isRead: false,
            startDate: data.project.startDate || '',
            priceListNumber: data.project.priceListNumber || '',
            proposalNumber: data.project.proposalNumber || '',
            entityName: data.project.entityName,
          };
          results.push(result);
        }
      }
      Promise.all(
        results.map(result =>
          dispatch('createNotification', result).then(() => {
            notificationService.pushNotification(result);
          })
        )
      ).catch(error => {
        // eslint-disable-next-line no-console
        console.error('An error occurred:', error);
      });
    },
    openGlobalNotifyPopup({ commit }, payload) {
      commit(types.SHOW_GLOBAL_NOTIFY_POPUP, payload);
    },
  };
};
