<template>
  <f7-popup
    :opened="isShowPopup"
    @popup:closed="cancel"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Team Member</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="save()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-searchbar
        disable-button-text
        placeholder="Search Member List"
        :clear-button="true"
        class="text-color-white search-list-popup"
        v-model="searchMember"
        @input="searchMember = $event.target.value"
        @searchbar:disable="searchMember = ''"
      ></f7-searchbar>
      <f7-list>
        <f7-list-item
          v-for="user in data"
          :key="user.id"
          :title="user.displayName"
          :style="
            selectedUserIds.includes(user.id) &&
            'background: var(--f7-color-bg-select-neutral)'
          "
        >
          <f7-checkbox
            slot="media"
            :checked="selectedUserIds.includes(user.id)"
            @change="onSelect(user.id)"
          ></f7-checkbox>
          <div slot="after">
            <span class="mx-5">Manager</span>
            <f7-toggle
              class="mx-5"
              :disabled="Boolean(managerId && user.id !== managerId)"
              :checked="user.id === managerId"
              @toggle:change="onChangeManager(user, $event)"
            ></f7-toggle>
            <span v-if="user.id === managerId">(*)</span>
            <span v-else>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapGetters } from 'vuex';
import { applyFilter } from '@/utility/filter-tools';
import _ from 'lodash';

export default {
  props: {
    userIDs: Array,
    managerID: String,
  },
  data: () => {
    return {
      searchMember: '',
      selectedUserIds: [],
      managerId: null,
      isShowPopup: false,
    };
  },
  methods: {
    open() {
      this.selectedUserIds = this.userIDs ? _.cloneDeep(this.userIDs) : [];
      this.managerId = this.managerID ? _.cloneDeep(this.managerID) : '';
      this.isShowPopup = true;
    },
    cancel() {
      this.searchMember = '';
      this.isShowPopup = false;
    },
    onSelect(id) {
      if (this.selectedUserIds.includes(id)) {
        var index = this.selectedUserIds.indexOf(id);
        if (index > -1) {
          this.selectedUserIds.splice(index, 1);
        }
      } else {
        this.selectedUserIds.push(id);
      }
    },
    onChangeManager(user, value) {
      if (this.managerId === '' && value) {
        this.managerId = user.id;
      } else if (this.managerId && !value) {
        this.managerId = '';
      }
    },
    save() {
      if (this.selectedUserIds.length < 1) {
        this.$ri.dialog.openInfoDialog({
          title: 'No members!',
          content: 'Please select members for the Team.',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      } else if (!this.managerId) {
        this.$ri.dialog.openInfoDialog({
          title: 'There is no any user is supervisor!',
          content: 'Please select a supervisor for the Team.',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      } else if (!this.selectedUserIds.includes(this.managerId)) {
        this.$ri.dialog.openInfoDialog({
          title: 'The supervisor must be on the team!',
          content: 'Please select a supervisor from the Team.',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      } else {
        this.$emit('selectMember', this.selectedUserIds, this.managerId);
        this.cancel();
      }
    },
    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list-popup.searchbar input').focus();
      }
    },
  },
  computed: {
    ...mapGetters('dashboard/project', ['activeUsers']),
    data() {
      const members = this.activeUsers.map(x => ({ ...x, id: x.uid }));
      return applyFilter(members, this.searchMember, ['displayName']);
    },
  },
};
</script>
<style scoped>
.mx-5 {
  margin: 0 5px;
}
</style>
