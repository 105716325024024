<template>
  <f7-view :class="`page ${dark ? 'theme-dark' : ''}`">
    <f7-page
      no-toolbar
      no-navbar
      no-swipeback
      login-screen
    >
      <div class="display-flex justify-content-center">
        <div class="logo-img">
          <img
            src="../assets/img/rooferintel_logo_black.png"
            width="45"
            alt="Rooferintel"
          />
        </div>
        <span class="logo-text"
          ><span :class="`${dark ? 'logo-text_white' : 'logo-text_black'}`"
            >Roofer</span
          ><span>Intel</span></span
        >
      </div>
      <!-- <form> -->
      <!-- <f7-login-screen-title>RooferIntel System</f7-login-screen-title> -->
      <f7-list>
        <f7-list-input
          ref="emailInput"
          label="Email or Phone Number"
          type="email"
          placeholder="Your email/phone number"
          :value="dataInput"
          @input="handleEmailInput"
          @blur="v$.email.$touch()"
          error-message-force
          autofocus
          :error-message="emailErrorMessage"
        ></f7-list-input>
        <f7-list-input
          v-if="isShowPassword === false"
          ref="passwordInput"
          label="Password"
          type="password"
          placeholder="Your password"
          :value="password"
          @input="password = $event.target.value.trim()"
          @keypress.native.enter="signIn"
          @blur="v$.password.$touch()"
          error-message-force
          :error-message="passwordErrorMessage"
          ><f7-icon
            class="cursor-pointer"
            @click.native="showPassword(true)"
            style="padding-right: 10px"
            color="gray"
            f7="eye_slash"
            slot="content-end"
          ></f7-icon>
        </f7-list-input>
        <f7-list-input
          v-if="isShowPassword === true"
          ref="passwordInput"
          label="Password"
          type="text"
          placeholder="Your password"
          :value="password"
          @input="password = $event.target.value.trim()"
          @keypress.native.enter="signIn"
          @blur="v$.$touch()"
          error-message-force
          :error-message="passwordErrorMessage"
          ><f7-icon
            class="cursor-pointer"
            @click.native="showPassword(false)"
            style="padding-right: 10px"
            color="gray"
            f7="eye"
            slot="content-end"
          ></f7-icon>
        </f7-list-input>
        <f7-list>
          <f7-button
            large
            round
            raised
            fill
            @click.native="signIn"
            >Sign In</f7-button
          >
        </f7-list>
        <f7-list>
          <f7-button
            v-show="showResendEmail"
            large
            round
            raised
            fill
            @click.native="resendEmailVerify"
            >Re-send email verify</f7-button
          >
        </f7-list>
        <f7-block>
          <f7-block-footer class="display-flex justify-content-space-between">
            <f7-link
              color="blue"
              @click.native="toLink('/register')"
            >
              Register
            </f7-link>
            <f7-link
              color="blue"
              @click.native="toLink('/reset-password')"
            >
              <p>
                Forgot password
                <f7-icon
                  size="14"
                  f7="question"
                ></f7-icon>
              </p>
            </f7-link>
          </f7-block-footer>
        </f7-block>
      </f7-list>
      <!-- </form> -->
    </f7-page>
  </f7-view>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
  signIn,
  saveIDToken,
  getLoginTenantId,
  sendEmailVerification,
} from '../services/authentication.service';
import { auth } from '../services/firebase.service';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, helpers, or } from '@vuelidate/validators';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  data: () => {
    return {
      dataInput: '',
      email: '',
      password: '',
      isShowPassword: false,
      showResendEmail: false,
      user: null,
    };
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  computed: {
    ...mapState('setting/app/profile', ['dark']),

    passwordErrorMessage() {
      if (!this.v$.password.$error) return null;
      if (this.v$.password.required.$invalid) return 'Password is required';
      if (this.v$.password.minLength.$invalid)
        return VALIDATION_MESSAGE.PASSWORD_MIN_LENGTH;
      if (this.v$.password.passwordRule.$invalid)
        return VALIDATION_MESSAGE.PASSWORD_COMPLEXITY;
      return null;
    },

    emailErrorMessage() {
      if (!this.v$.email.$error) return null;
      if (this.v$.email.required.$invalid)
        return 'Email/Phone number is required';
      if (this.v$.email.commonEmailRule.$invalid)
        return 'Invalid email or phone number';
      return null;
    },
  },

  validations() {
    const checkPassword = password => {
      const passwordRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      return passwordRegex.test(password);
    };

    const checkEmail = email => {
      const emailRegex1 = /\S+@\S+\.\S+/;
      const emailRegex2 = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

      return emailRegex1.test(email) || emailRegex2.test(email);
    };

    return {
      password: {
        required,
        minLength: minLength(8),
        passwordRule: password => checkPassword(password),
      },
      email: {
        required,
        commonEmailRule: email => checkEmail(email),
      },
    };
  },
  mounted() {
    const { email, emailVerified } = this.$f7route.query;
    if (email) {
      this.email = email;
    }
    if (emailVerified === 'false') {
      this.showEmailVerifyDialog();
    }
  },
  methods: {
    ...mapActions('setting/app/profile', ['setRoutes']),
    ...mapActions('setting/app/system', ['getSetting']),

    toLink(link) {
      this.$f7router.navigate(link, {
        pushState: true,
        reloadAll: true,
      });
    },

    showPassword(val) {
      this.isShowPassword = val;
    },

    showEmailVerifyDialog() {
      this.$ri.dialog.openWarningDialog({
        title: 'Email Verify',
        content: 'You do not verify email yet. Please verify your email.',
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.showResendEmail = true;
          }
        },
      });
    },

    resendEmailVerify() {
      const sefl = this;
      auth.onAuthStateChanged(user => {
        if (user) {
          sefl.$f7.preloader.show();
          sendEmailVerification(user.tenantId, user.uid)
            .then(function () {
              sefl.$f7.preloader.hide();
              // Email sent.
              sefl.$ri.dialog.openSuccessDialog({
                title: 'Email Verify',
                content:
                  'An email was sent to your inbox. Please check your inbox and verify your email by following the verifying step',
                hideCancelButton: true,
                onClick: (_sefl, index) => {
                  if (index === 0) {
                    _sefl.app.dialog.close();
                  } else if (index === 1) {
                    _sefl.app.dialog.close();
                  }
                },
              });
            })
            .catch(error => {
              sefl.$f7.dialog.alert(error.message, 'Error', () => {});
            })
            .finally(() => {
              sefl.$f7.preloader.hide();
            });
        }
      });
    },

    signIn() {
      // Validate input controls
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.$f7.preloader.show();

      getLoginTenantId(this.email).then(tenantIds => {
        const loginTenantId = tenantIds[0] || '';
        if (loginTenantId) {
          localStorage.setItem('login_tenant', loginTenantId);
        }

        // Make login API call
        signIn(loginTenantId, this.email, this.password)
          .then(async user => {
            const setting = await this.getSetting(user.tenantId);
            const data = await user.getIdTokenResult();

            if (setting.isAccountClosed) {
              if (data.claims && data.claims.role === 'owner') {
                this.$f7.notification.create({
                  icon: "<i class='color-red icon f7-icons'>exclamationmark_triangle_fill</i>",
                  title: 'Login failure',
                  text: 'You have closed your account. To reactivate your account, you will need to contact RooferTech Support.',
                  closeOnClick: true,
                  closeButton: true,
                  closeTimeout: 10000,
                });
              } else {
                this.$f7.notification.create({
                  icon: "<i class='color-red icon f7-icons'>exclamationmark_triangle_fill</i>",
                  title: 'Login failure',
                  text: 'Your account has been disabled, please contact your administrator..',
                  closeOnClick: true,
                  closeButton: true,
                  closeTimeout: 10000,
                });
              }
            } else if (user.emailVerified === false) {
              this.showEmailVerifyDialog();
            } else {
              saveIDToken(user).then(() => {
                window.location.href = '/';
              });
            }
          })
          .catch(error => {
            var notificationClickToClose = this.$f7.notification.create({
              icon: "<i class='color-red icon f7-icons'>exclamationmark_triangle_fill</i>",
              title: 'Login failure',
              text: error.message,
              closeOnClick: true,
              closeButton: true,
              closeTimeout: 15000,
            });
            notificationClickToClose.open();

            // eslint-disable-next-line no-console
            // console.error("[signIn]", error);
          })
          .finally(() => {
            this.$f7.preloader.hide();
          });
      });
    },

    handleEmailInput(event) {
      this.dataInput = event.target.value.trim();
      let phonePatten = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/g;
      if (phonePatten.test(this.dataInput)) {
        let rawPhoneNumber = this.dataInput.replace(/[^a-zA-Z0-9+]/g, '');
        rawPhoneNumber = rawPhoneNumber.replace('+1', '');
        this.email = `info+1${rawPhoneNumber}@rooferintel.com`;
      } else {
        this.email = this.dataInput;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.logo-text {
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 32px;
  font-weight: bold;
  color: var(--f7-theme-color);
  text-transform: uppercase;

  &_black {
    color: #000;
  }

  &_white {
    color: #fff;
  }
}
</style>
