/* eslint-disable no-unused-vars */
import { FirebaseActions } from '../../../../services/firebase.service';
import userService from '../../../../services/user.service';
import * as types from './types';

export default tenant => {
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    'project'
  );

  const boardActions = new FirebaseActions(
    `/system_client/${tenant}/swimlane_board`,
    'board'
  );

  const woTaskActions = new FirebaseActions(
    `/system_client/${tenant}/work_order_task`,
    'task'
  );

  const actionActions = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    'action'
  );

  return {
    bindProject: projectActions.bindDocument,
    unbindProject: projectActions.unbindDocument,
    updateProject: projectActions.updateDocument,

    getProjectById: projectActions.getDocument,
    getBoardById: boardActions.getDocument,
    getTaskById: woTaskActions.getDocument,

    getActionListBys: actionActions.getDocumentBys,
    getActionById: actionActions.getDocument,

    getActionList({ dispatch, commit }) {
      return dispatch('getActionListBys', [
        {
          prop: 'boards',
          op: 'array-contains',
          val: 'construction',
        },
        {
          prop: 'isDeleted',
          op: '==',
          val: false,
        },
      ]).then(res => {
        return commit(types.SET_ACTION_LIST, res);
      });
    },

    resetActionList({ commit }) {
      return commit(types.SET_ACTION_LIST, []);
    },

    getProject({ commit, dispatch }, id) {
      return dispatch('getProjectById', id).then(data => {
        commit(types.SET_PROJECT, data);
      });
    },

    resetProject({ commit }) {
      return commit(types.SET_PROJECT, {});
    },

    getUserData({ commit }) {
      return userService.getUserList().then(users => {
        commit(types.SET_USERS_LIST, users);
      });
    },
  };
};
