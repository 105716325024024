import { FirebaseActions } from '../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const MileagePayRateActions = new FirebaseActions(
    `/system_client/${tenant}/mileage_pay_rate`,
    'mileagePayRate'
  );

  return {
    getMileagePayRateBys: MileagePayRateActions.getDocumentBys,

    getMileagePayRateList({ dispatch, commit }) {
      return dispatch('getMileagePayRateBys', []).then(list => {
        commit(types.SET_MILEAGE_PAY_RATE_LIST, list || []);
        return list;
      });
    },
  };
};
