import { checkAuth } from '../../../services/authentication.service';

export default [
  {
    path: '',
    title: 'Product Catalog',
    name: 'product-main',
    displayRoles: ['owner', 'user'],
    routes: [
      {
        path: '/vendor',
        title: 'Products',
        name: 'product-list',
        parent: 'product-main',
        displayRoles: ['owner', 'user'],
        icon: 'cube_box',
        async: checkAuth(() => import('../pages/VendorList.vue')),
        master: true,
        detailRoutes: [
          {
            path: '/vendor/:vendorId',
            title: 'Edit Product',
            async: checkAuth(() => import('../pages/ProductItemList.vue')),
          },
        ],
      },
      {
        path: '/category',
        title: 'Category',
        name: 'product-category',
        parent: 'product-main',
        displayRoles: ['owner', 'user'],
        icon: 'layers_alt',
        async: checkAuth(() => import('../pages/CategoryList.vue')),
        master: true,
        detailRoutes: [
          {
            path: '/category/:categoryId',
            title: 'Sub categories',
            async: checkAuth(() => import('../pages/CategoryDetail.vue')),
          },
        ],
      },
    ],
  },
];
