<template>
  <f7-popup
    class="popup-color"
    :opened="showPopupChangeColor"
    @popup:close="closePopup()"
    @popup:closed="handlePopupClosed()"
  >
    <f7-list media-list>
      <f7-list-item>
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Color
        </div>
        <div>
          <div
            class="color-item"
            v-for="(color, index) in colors"
            :key="index"
            :style="`background-color: ${color}`"
            @click="onChangeObject(color)"
          >
            <f7-icon
              v-show="color === selectedColor"
              class="check-item"
              f7="checkmark"
              color="white"
            ></f7-icon>
          </div>
        </div>
        <input-icon
          slot="media"
          icon="circle_fill"
          :color="selectedColor"
        ></input-icon>
      </f7-list-item>
    </f7-list>
    <!-- popups -->
  </f7-popup>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
const colors = [
  '#000000',
  '#c0c0c0',
  '#808080',
  '#ffffff',
  '#800000',
  '#ff0000',
  '#800080',
  '#ff00ff',
  '#008000',
  '#00ff00',
  '#808000',
  '#ffff00',
  '#000080',
  '#0000ff',
  '#008080',
  '#00ffff',
  '#ffa500',
  '#f0f8ff',
  '#faebd7',
  '#7fffd4',
  '#ffe4c4',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#778899',
];

export default {
  components: {
    InputIcon,
  },
  data() {
    return {
      colors,
      showPopupChangeColor: false,
      selectedColor: '',
    };
  },
  methods: {
    openPopup() {
      this.showPopupChangeColor = true;
      return new Promise(resolve => {
        this.selectCallback = resolve;
      });
    },
    closePopup() {
      this.showPopupChangeColor = false;
    },
    done() {
      this.showPopupChangeColor = false;
      this.selectCallback(this.selectedColor);
    },

    handlePopupClosed() {},
    onChangeObject(val) {
      this.selectedColor = val;
      this.done();
    },
  },
};
</script>

<style lang="scss" scoped>
.color-item {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 4px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }
}
.check-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popup-color {
  height: 146px;
  width: 400px;
  left: auto;
  right: calc(50% - 150px);
  bottom: 55%;
  top: auto;
  z-index: 99999;
}
</style>
