export const SET_SCHEDULE_ID = 'SET_SCHEDULE_ID';
export const SELECT_ITEM = 'SELECT_ITEM';
export const SET_IS_MY_SCHEDULING = 'SET_IS_MY_SCHEDULING';
export const SET_EDIT_TYPE = 'SET_EDIT_TYPE';
export const SET_IS_SHOW_DETAIL = 'SET_IS_SHOW_DETAIL';

export const SET_SCHEDULE_LIST = 'SET_SCHEDULE_LIST';
export const SET_SCHEDULE = 'SET_SCHEDULE';
export const SCHEDULE_LIST_ITEM_ADD = 'SCHEDULE_LIST_ITEM_ADD';
export const SCHEDULE_LIST_ITEM_UPDATE = 'SCHEDULE_LIST_ITEM_UPDATE';
export const SCHEDULE_LIST_ITEM_REMOVE = 'SCHEDULE_LIST_ITEM_REMOVE';
export const SET_SCHEDULE_BY_CHAINID_DATE = 'SET_SCHEDULE_BY_CHAINID_DATE';
export const RESET_DATA = 'RESET_DATA';
