import axiosService from "./axios.service";

const translateText = async data => {
  try {
    const response = await axiosService.post("/translate", {
        translatedText: data.translatedText,
        targetLanguage: data.targetLanguage
    });
    return response.data
  } catch (error) {
    console.error("[Translate]", error);
    throw new Error(error.message);
  }
};

export default {
  translateText
};
