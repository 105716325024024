import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';
import _ from 'lodash';

export default tenant => {
  const scheduleActions = new FirebaseActions(
    `/system_client/${tenant}/employee_scheduling`,
    'schedule'
  );
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    'project'
  );

  return {
    getScheduleListBys: scheduleActions.getDocumentBys,

    addSchedule: scheduleActions.createDocument,
    saveSchedule: scheduleActions.updateDocument,
    removeSchedule: scheduleActions.deleteDocument,

    getProjectById: projectActions.getDocument,

    getScheduleList({ dispatch, commit }, conditions) {
      return dispatch('getScheduleListBys', conditions).then(res => {
        return commit(types.SET_SCHEDULE_LIST, res);
      });
    },

    async composeDataSchedule({ dispatch }, doc) {
      if (doc.projectId) {
        const project = (await dispatch('getProjectById', doc.projectId)) || {};
        if (!_.isEmpty(project)) {
          doc.project = {
            id: project.id,
            cardNumber: project.cardNumber || '',
            title: project.title || '',
            businessCode: project.businessCode || '',
            customerType: project.customerType || '',
            contactName: project.contactName || '',
            propertyName: project.propertyName || '',
            propertyAddress: project.propertyAddress || {},
            projectAddress: project.projectAddress || {},
          };
        }
      }
      return doc;
    },

    async createSchedule({ dispatch, commit }, doc) {
      const newData = await dispatch('composeDataSchedule', doc);
      return dispatch('addSchedule', newData).then(id => {
        commit(types.SCHEDULE_LIST_ITEM_ADD, {
          ...newData,
          id,
        });

        return id;
      });
    },

    async updateSchedule({ dispatch, commit }, { id, doc }) {
      const newData = await dispatch('composeDataSchedule', doc);
      return dispatch('saveSchedule', { id, doc: newData }).then(() => {
        return commit(types.SCHEDULE_LIST_ITEM_UPDATE, {
          id,
          doc: newData,
        });
      });
    },

    deleteSchedule({ dispatch, commit }, id) {
      return dispatch('removeSchedule', id).then(() => {
        return commit(types.SCHEDULE_LIST_ITEM_REMOVE, id);
      });
    },

    setEditType({ commit }, type) {
      commit(types.SET_EDIT_TYPE, type);
    },

    setScheduleId({ commit }, id) {
      commit(types.SET_SCHEDULE_ID, id);
    },

    selectItem({ commit }, item) {
      commit(types.SELECT_ITEM, item);
    },

    setIsMyScheduling({ commit }, value) {
      commit(types.SET_IS_MY_SCHEDULING, value);
    },

    setIsShowDetail({ commit }, value) {
      commit(types.SET_IS_SHOW_DETAIL, value);
    },

    getSchedulesByUserAndProject({ dispatch }, { userId, projectId }) {
      const conditions = [
        {
          prop: 'projectId',
          val: projectId,
          op: '==',
        },
        {
          prop: 'userId',
          val: userId,
          op: '==',
        },
      ];
      return dispatch('getScheduleListBys', conditions);
    },

    getSchedulesByUserAndDate({ dispatch }, { startDate, endDate, userId }) {
      const conditions = [
        {
          prop: 'date',
          val: startDate,
          op: '>=',
        },
        {
          prop: 'date',
          val: endDate,
          op: '<=',
        },
        {
          prop: 'userId',
          val: userId,
          op: '==',
        },
      ];

      return dispatch('getScheduleListBys', conditions);
    },

    getSchedulesByChainIdAndDate(
      { dispatch, commit },
      { startDate, endDate, chainId }
    ) {
      const conditions = [
        {
          prop: 'date',
          val: startDate,
          op: '>=',
        },
        {
          prop: 'date',
          val: endDate,
          op: '<=',
        },
        {
          prop: 'scheduleChainId',
          val: chainId,
          op: '==',
        },
      ];

      return dispatch('getScheduleListBys', conditions).then(res => {
        return commit(types.SET_SCHEDULE_BY_CHAINID_DATE, res);
      });
    },

    resetScheduling({ commit }) {
      commit(types.RESET_DATA);
    },
  };
};
