import * as types from './types';
import Vue from 'vue';
import _ from 'lodash';

export default {
  setInvoiceItemList(state, payload) {
    Vue.set(state, 'invoiceItemList', payload);
  },
  setInvoiceItemSubscribe(state, payload) {
    Vue.set(state, 'invoiceItemSubscribe', payload);
  },
  [types.SET_SEARCH_RESULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(state, 'hits', hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.SET_NUMBER_OF_ROWS](state, payload) {
    Vue.set(state, 'hitsPerPage', payload);
  },

  [types.SET_SEARCH_TEXT](state, payload) {
    Vue.set(state, 'searchText', payload);
  },
  [types.SET_SELECTED_STATUS_FILTER](state, payload) {
    Vue.set(state, 'selectedStatusFilter', payload);
  },
  // Allow search desc only
  // [types.SET_SEARCH_ORDER](state, payload) {
  //   Vue.set(state, "order", payload);
  // },

  [types.RESET_SEARCH](state) {
    Vue.set(state, 'order', 'desc');
    Vue.set(state, 'searchText', '');

    Vue.set(state, 'hits', []);
    Vue.set(state, 'hitsPerPage', 25);
    Vue.set(state, 'nbHits', 0);
    Vue.set(state, 'nbPages', 0);
    Vue.set(state, 'page', 0);
  },

  [types.ADD_MORE_RESULT](state, { hits, hitsPerPage, nbHits, nbPages, page }) {
    state.hits.push(...hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.INVOICE_LIST_ITEM_UPDATE](state, { id, doc }) {
    const index = state.hits.findIndex(item => item.id === id);

    if (index >= 0) {
      const item = _.cloneDeep(state.hits[index]);

      if (
        doc.status &&
        state.selectedStatusFilter.length > 0 &&
        !state.selectedStatusFilter.includes(doc.status)
      ) {
        state.hits.splice(index, 1);
      } else {
        for (const key in doc) {
          if (Object.hasOwnProperty.call(doc, key) && doc[key] !== undefined) {
            item[key] = doc[key];
          }
        }
        Vue.set(state.hits, index, item);
      }
    }
  },

  [types.INVOICE_LIST_ITEM_ADD](state, invoice) {
    if (
      state.selectedStatusFilter.length === 0 ||
      state.selectedStatusFilter.includes(invoice.status)
    ) {
      state.hits.unshift(invoice);
    }
  },

  [types.INVOICE_LIST_ITEM_REMOVE](state, id) {
    const index = state.hits.findIndex(item => item.id === id);
    if (index >= 0) {
      state.hits.splice(index, 1);
    }
  },

  [types.SET_INVOICE_LIST](state, payload) {
    Vue.set(state, 'invoiceList', _.cloneDeep(payload));
  },
};
