<template>
  <f7-popup
    class="demo-popup"
    :opened="isShowPopup"
    @popup:closed="cancel"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Service Item Setting</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="save()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-searchbar
        disable-button-text
        placeholder="Search Service Item List"
        :clear-button="true"
        class="text-color-white search-list-popup"
        v-model="searchValue"
        @input="searchValue = $event.target.value"
        @searchbar:disable="searchValue = ''"
      ></f7-searchbar>
      <f7-list>
        <f7-list-item
          v-for="item in data"
          :key="item.value"
          :title="item.name"
        >
          <f7-checkbox
            slot="media"
            :checked="selectedServiceValues.includes(item.value)"
            @change="onSelect(item.value)"
          ></f7-checkbox>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { applyFilter } from '@/utility/filter-tools';
import _ from 'lodash';

export default {
  data: () => {
    return {
      isShowPopup: false,
      searchValue: '',
      selectedServiceValues: [],
    };
  },
  methods: {
    ...mapActions('time-tracking/service-item-setting', [
      'createServiceItemSettingList',
      'deleteServiceItemSetting',
      'deleteServiceItemSettingList',
    ]),
    open() {
      this.isShowPopup = true;
      this.selectedServiceValues = this.serviceItemSettingList.length
        ? this.serviceItemSettingList.map(r => r.value)
        : this.serviceItemQBList.map(r => r.value);
    },
    cancel() {
      this.isShowPopup = false;
      this.searchValue = '';
      this.selectedServiceValues = [];
    },
    onSelect(id) {
      if (this.selectedServiceValues.includes(id)) {
        const deleteSelectedItem = this.selectedServiceValues.filter(
          item => item !== id
        );
        this.selectedServiceValues = deleteSelectedItem;
      } else {
        this.selectedServiceValues.push(id);
      }
    },
    save() {
      this.$f7.preloader.show();
      let itemsBefore = _.cloneDeep(
        this.serviceItemSettingList.map(r => r.value)
      );
      let itemsAfter = _.cloneDeep(this.selectedServiceValues);

      let itemsRemove = _.differenceWith(itemsBefore, itemsAfter, _.isEqual);
      let itemsAdd = _.differenceWith(itemsAfter, itemsBefore, _.isEqual);

      let promises = [];
      if (!_.isEmpty(itemsRemove)) {
        itemsRemove = this.serviceItemSettingList.filter(r =>
          itemsRemove.includes(r.value)
        );
        for (const item of itemsRemove) {
          promises.push(this.deleteServiceItemSettingList(item.id));
        }
      }
      if (!_.isEmpty(itemsAdd)) {
        itemsAdd = this.serviceItemQBList.filter(r =>
          itemsAdd.includes(r.value)
        );
        for (const item of itemsAdd) {
          promises.push(this.createServiceItemSettingList(item));
        }
      }
      Promise.all(promises).then(() => {
        this.$f7.preloader.hide();
        this.cancel();
      });
    },
    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list-popup.searchbar input').focus();
      }
    },
  },
  computed: {
    ...mapGetters('time-tracking/quickbooks', ['serviceItemQBList']),
    ...mapGetters('time-tracking/service-item-setting', [
      'serviceItemSettingList',
    ]),
    data() {
      return applyFilter(this.serviceItemQBList, this.searchValue, ['name']);
    },
  },
};
</script>
<style scoped>
.mx-5 {
  margin: 0 5px;
}
</style>
