import {
  FirebaseActions,
  StorageActions,
} from '../../services/firebase.service';
import contractService from '../../services/contract.service';
export default tenant => {
  const ContractActions = new FirebaseActions(
    `/system_client/${tenant}/contract`,
    'contract'
  );

  return {
    updateContract: ContractActions.updateDocument,
    createNewContract({ rootGetters }, contract) {
      const user = rootGetters['setting/app/profile/user'];
      return contractService.addContractDoc(user.tenantId, contract);
    },
    // eslint-disable-next-line no-unused-vars
    uploadImage({ commit }, { contractId, base64Data, fileName }) {
      const name = `${+new Date()}_${fileName}`;
      const fullPath = `contracts/${contractId}/${name}`;
      return StorageActions.uploadFileBase64(fullPath, base64Data, {});
    },
  };
};
