import moment from 'moment';
export default {
  priceListItemList: state =>
    state.priceListItemList
      .filter(item => {
        const compareDate = moment();
        const startDate =
          item && item.startDate && moment(item.startDate.toDate());
        const isStatusValid =
          !item.status.includes('pl-inactive') &&
          !item.status.includes('pl-incomplete');
        if (item && item.endDate) {
          const endDate = moment(item.endDate.toDate());
          return (
            isStatusValid &&
            compareDate.isBetween(startDate, endDate, 'days', true)
          );
        } else {
          return isStatusValid && compareDate.isSameOrAfter(startDate, 'days');
        }
      })
      .sort((a, b) => a.createdAt - b.createdAt),

  priceListItem: (state, _, __, rootGetters) => priceListId => {
    const productList =
      rootGetters['invoices/product/productsByPriceListRefsList'] || [];

    const price =
      state.priceListItemList.find(item => item.id === priceListId) || {};
    const productRefs = ((price && price.productRefs) || []).map(productRef => {
      const product =
        productList.find(product => product.id === productRef.productId) || {};
      return {
        ...product,
        ...productRef,
      };
    });

    return {
      ...price,
      productRefs,
    };
  },

  priceList: (state, _, __, rootGetters) => {
    const productList =
      rootGetters['invoices/product/productsByPriceListRefsList'] || [];
    const price = state.priceListItem || {};

    const productRefs = ((price && price.productRefs) || []).map(productRef => {
      const product =
        productList.find(product => product.id === productRef.productId) || {};
      return {
        ...productRef,
        product: { ...product, price: productRef.price },
      };
    });

    return {
      ...price,
      productRefs,
    };
  },
  currentPriceList: state => state.priceListItem,
};
