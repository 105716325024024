import dashboardRoutes from '../plugins/dashboard/router';
import settingsRoutes from '../plugins/settings/router';
import adminRoutes from '../plugins/administration/router';

import PriceListRoutes from '../plugins/price-list/router';
import EstimateRoutes from '../plugins/estimate/router';
import ProposalRoutes from '../plugins/proposal/router';
import ProposalV2Routes from '../plugins/proposal-v2/router';

import PurchaseOrder from '../plugins/purchase-order/router';
import ProductRouters from '../plugins/products/router';
import ScheduleRoutes from '../plugins/schedule/router';
import TimeTrackingRoutes from '../plugins/time-tracking/router';

// import SwimlaneRoutes from "../plugins/swimlane/router";
import PhotosRoutes from '../plugins/photos/router';
import ExpensesRoutes from '../plugins/expenses/router';
import InvoicesRoutes from '../plugins/invoices/router';
import quickbooksRoutes from '../plugins/quickbooks/router';
import SchedulingRoutes from '../plugins/scheduling/router';

import contactBookRoutes from '../plugins/contact-book/router';
import reportsRoutes from '../plugins/reports/router';

import metalSheetRoutes from '../plugins/metal-sheet/router';
import webContactRoutes from '../plugins/web-contact/router';

import PanelLeft from '../pages/PanelLeft.vue';
import PanelRight from '../pages/PanelRight.vue';
import NotFound from '../pages/404.vue';

import LoginPage from '../pages/LoginPage.vue';
import Account from '../pages/Account.vue';
import ResetPassword from '../pages/ResetPassword.vue';
import ResetPasswordVerify from '../pages/ResetPasswordVerify.vue';
import Register from '../pages/Register.vue';
import CheckoutSuccess from '../pages/CheckoutSuccess.vue';
// import ChangePassword from "../pages/ChangePassword.vue";
import EmailSent from '../pages/EmailSent.vue';
import VerifyEmail from '../pages/VerifyEmail.vue';
import PermissionDenied from '../pages/PermissionDenied.vue';

import ReopenPage from '../pages/Reopen.vue';

import SafetyRoutes from '../plugins/safety/router';

// Main Pages
const routes = [
  // Index page
  {
    path: '/',
    redirect: () => {
      return localStorage.getItem('homeUrl') || '/settings/profile';
    },
  },
  {
    path: '/reopen',
    component: ReopenPage,
  },
  // Left Panel
  {
    path: '/panel-left',
    component: PanelLeft,
  },
  // {
  //   path: "/reveal-panel",
  //   component: PanelLeft
  // },
  // Right Panel
  {
    path: '/panel-right',
    component: PanelRight,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/account',
    component: Account,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/reset-password/:code',
    component: ResetPasswordVerify,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/checkout-success',
    component: CheckoutSuccess,
  },
  {
    path: '/permission-denied',
    component: PermissionDenied,
  },
  // {
  //   path: "/change-password",
  //   component: ChangePassword
  // },
  {
    path: '/email-sent',
    component: EmailSent,
  },
  {
    path: '/verify-email',
    component: VerifyEmail,
  },
];

routes.push(...webContactRoutes);
routes.push(...dashboardRoutes);
routes.push(...contactBookRoutes);
routes.push(...PhotosRoutes);

// routes.push(...SwimlaneRoutes);
routes.push(...TimeTrackingRoutes);
routes.push(...quickbooksRoutes);
routes.push(...EstimateRoutes);
routes.push(...ProposalRoutes);
routes.push(...ProposalV2Routes);
routes.push(...PurchaseOrder);

routes.push(...ScheduleRoutes);
routes.push(...ProductRouters);
routes.push(...PriceListRoutes);

routes.push(...ExpensesRoutes);
routes.push(...SchedulingRoutes);
routes.push(...InvoicesRoutes);

routes.push(...adminRoutes);
routes.push(...settingsRoutes);
routes.push(...reportsRoutes);

routes.push(...metalSheetRoutes);

routes.push(...SafetyRoutes);

// Default route (404 page). MUST BE THE LAST
routes.push({
  path: '(.*)',
  component: NotFound,
});

export default routes;
