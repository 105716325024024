/* eslint-disable no-unused-vars */
import * as types from './types';

export default {
  setDestroyFlag({ commit }, payload) {
    commit(types.SET_DESTROY_FLAG, payload);
  },
  setClientType({ commit }, payload) {
    commit(types.SET_CLIENT_TYPE, payload);
  },
  setCurrentView({ commit }, payload) {
    commit(types.SET_CURRENT_VIEW, payload);
  },
};
