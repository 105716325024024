<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          @click="$f7router.back()"
          icon-f7="chevron_left"
          >Back</f7-link
        >
      </f7-nav-left>
      <f7-nav-title>Property Details</f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="openEditProperty">Edit</f7-link>
      </f7-nav-right>
    </f7-navbar>

    <!-- Name -->
    <f7-block-title>Property Name</f7-block-title>
    <f7-list simple-list>
      <f7-list-item
        :title="property ? property.propertyName : ''"
      ></f7-list-item>
    </f7-list>
    <!-- Address -->
    <f7-block-title
      v-show="
        property
          ? property.addresses
            ? property.addresses.length > 0
            : false
          : false
      "
      >Address</f7-block-title
    >
    <f7-list>
      <f7-list-item
        v-for="(address, index) in property ? property.addresses : []"
        :key="index"
        :header="address.title"
        :title="getFullAddress(address)"
      ></f7-list-item>
    </f7-list>

    <f7-block-title>Add a note</f7-block-title>
    <f7-text-editor
      :value="commentContent"
      @texteditor:change="val => (commentContent = val)"
      placeholder="Add a note..."
      mode="popover"
      resizable
    />
    <f7-block
      v-show="commentContent != ''"
      class="save-comment-block"
    >
      <f7-col tag="span"></f7-col>
      <f7-col tag="span"></f7-col>
      <f7-col
        tag="span"
        class="btn-save"
      >
        <f7-button
          raised
          @click.native="saveComment"
          >Save</f7-button
        >
      </f7-col>
    </f7-block>

    <div
      v-for="(item, index) in listNotes"
      :key="index"
    >
      <f7-block-title>
        added by
        <strong>{{ item.createdBy }}</strong>
      </f7-block-title>

      <f7-block
        strong
        inset
      >
        <p v-html="item.htmlContent"></p>
      </f7-block>
      <f7-block-footer class="text-align-right">{{
        toLocaleString(item.createdAt)
      }}</f7-block-footer>
    </div>

    <property-edit-popup
      ref="editProperty"
      @onUpdated="handlePropertyUpdated"
    >
    </property-edit-popup>
  </f7-page>
</template>

<script>
import PropertyEditPopup from '../../components/popups/PropertyEditPopup.vue';

import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import { firebase, auth } from '../../../../services/firebase.service';
import { getFullAddress } from '../../../../utility/address';

export default {
  components: { PropertyEditPopup },

  data: () => {
    return { commentContent: '' };
  },

  computed: {
    ...mapGetters('contact-book/property', ['property']),
    ...mapGetters('contact-book/company', ['company']),

    listNotes() {
      return this.property
        ? this.property.notes
          ? this.property.notes.map(r => r).reverse()
          : []
        : [];
    },
  },

  async created() {
    this.$f7.preloader.show();

    if (_.isEmpty(this.company)) {
      await this.getCompanyById(this.$f7route.params.companyId);
    }

    await this.getPropertyById(this.$f7route.params.propertyId);

    this.$f7.preloader.hide();
  },

  methods: {
    ...mapActions('contact-book/property', [
      'getProperty',
      'getPropertyById',
      'updateProperty',
    ]),

    ...mapActions('contact-book/company', [
      'getCompanyById',
      'addPropertyRefs',
      'removePropertyRefs',
      'updatePropertyRefs',
    ]),

    openEditProperty() {
      this.$refs.editProperty.open(this.property.id);
    },

    getFullAddress(address) {
      return getFullAddress(address);
    },

    async saveComment() {
      if (!_.isEmpty(this.property)) {
        this.$f7.preloader.show();
        let notes = _.cloneDeep(this.property.notes) || [];

        notes.push({
          title: 'note',
          code: 'notes',
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
          htmlContent: this.commentContent,
        });

        await this.updateProperty({
          id: this.property.id,
          doc: {
            notes,
          },
        });

        await this.getPropertyById(this.property.id);
        this.commentContent = '';

        this.$f7.preloader.hide();
      }
    },

    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    },

    async handlePropertyUpdated(propertyId) {
      this.$f7.preloader.show();

      const property = await this.getProperty(propertyId);

      if (!_.isEmpty(property)) {
        await this.removePropertyRefs(propertyId); //remove old ref
        await this.addPropertyRefs(property); // add new ref
        await this.updatePropertyRefs(); // save changed
      }

      await this.getPropertyById(this.property.id); // reload content of property detail page
      this.$f7.preloader.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.save-comment-block {
  margin-top: -10px;
  margin-right: 1px;
  display: flex;
  justify-content: space-between;
}
.btn-save {
  width: 18%;
  min-width: 120px;
}
</style>
