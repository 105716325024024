<template>
  <div class="popup-scroll-bar">
    <note-editor
      title="Add a comment"
      :value="commentContent"
      @onChange="val => (commentContent = val)"
      :isShowAvatar="false"
    ></note-editor>

    <f7-block
      v-show="commentContent != ''"
      class="save-comment-block"
    >
      <f7-row tag="p">
        <f7-col tag="span"></f7-col>
        <f7-col tag="span"></f7-col>
        <f7-col tag="span">
          <f7-button
            raised
            @click.native="saveComment"
            >Save</f7-button
          >
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-block>
      <f7-segmented
        strong
        tag="p"
      >
        <f7-button
          :active="activeButton === 'all'"
          @click.native="buttonClick('all')"
          >All</f7-button
        >
        <f7-button
          :active="activeButton === 'note'"
          @click.native="buttonClick('note')"
          >Notes</f7-button
        >
        <f7-button
          :active="activeButton === 'comment'"
          @click.native="buttonClick('comment')"
          >Comments</f7-button
        >
      </f7-segmented>
    </f7-block>

    <div
      v-for="(item, index) in list"
      :key="index"
    >
      <f7-block-title>
        <strong>{{ item.createdBy }}</strong>
        added a {{ item.title }}
      </f7-block-title>

      <f7-block
        strong
        inset
        class="note-content"
      >
        <p v-html="item.htmlContent"></p>
      </f7-block>
      <f7-block-footer class="text-align-right">{{
        toLocaleString(item.createdAt)
      }}</f7-block-footer>
    </div>
  </div>
</template>

<script>
import { firebase, auth } from '../../../../services/firebase.service';
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import NoteEditor from '../note/NoteEditor.vue';

export default {
  components: { NoteEditor },

  data: () => {
    return {
      commentContent: '',
      activeButton: 'all',
    };
  },

  computed: {
    ...mapState('dashboard/project', ['card']),

    list() {
      return this.card
        ? this.card.commentAndNote
          ? this.card.commentAndNote
              .filter(item => {
                return (
                  this.activeButton === 'all' || item.code === this.activeButton
                );
              })
              .reverse()
          : []
        : [];
    },
  },

  methods: {
    ...mapActions('dashboard/project', ['updateCard', 'setCurrentCard']),

    buttonClick(val) {
      this.activeButton = val;
    },

    saveComment() {
      if (!_.isEmpty(this.card)) {
        let commentAndNote = _.cloneDeep(this.card.commentAndNote) || [];

        commentAndNote.push({
          title: 'Comment',
          code: 'comment',
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
          htmlContent: this.commentContent,
        });
        this.updateCard({
          id: this.card.id,
          doc: {
            commentAndNote: commentAndNote,
          },
        }).then(() => {
          this.setCurrentCard(this.card.id);
          this.commentContent = '';
        });
      }
    },

    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    },

    showMainContent() {
      this.activeButton = 'all';
    },

    init() {},

    clearActionData() {},
  },
};
</script>

<style lang="scss" scoped>
.scroll-bar {
  height: calc(100%);
}

.save-comment-block {
  margin-top: -10px;
  margin-right: 1px;
}
.note-content {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
