<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="close"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="close">close</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{
          `${estimate.estimateName || ''} - ${
            currentBuilding.buildingName || ''
          }`
        }}</f7-nav-title>
      </f7-navbar>
      <slot name="table"></slot>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      popupOpened: false,
    };
  },
  computed: {
    ...mapGetters('estimate/estimate-page/estimate', [
      'estimate',
      'currentBuilding',
    ]),
  },
  methods: {
    open() {
      this.popupOpened = true;
    },

    close() {
      this.popupOpened = false;
    },
  },
};
</script>

<style></style>
