<template>
  <f7-page>
    <!-- Navigation bar -->
    <f7-navbar :sliding="false">
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Residential</f7-nav-title>
    </f7-navbar>

    <div class="full-screen">
      <dashboard-menu @onToNewProject="toNewProject"></dashboard-menu>

      <residential-grid-tabs
        @openCardDetailsPopup="openCardDetailsPopup"
      ></residential-grid-tabs>

      <!-- Popup on desktop -->
      <project-details-popup
        ref="projectDetailsPopup"
        :cardNumber="$f7route.params.cardNumber"
        :actionId="$f7route.params.actionId"
        @navigateToBoard="navigateToBoard"
        @showSetting="openSettingPopup"
      ></project-details-popup>

      <project-one-page-details-popup
        ref="projectOnePageDetailsPopup"
        :cardNumber="$f7route.params.cardNumber"
        :actionId="$f7route.params.actionId"
        @navigateToBoard="navigateToBoard"
        @showSetting="openSettingPopup"
      ></project-one-page-details-popup>

      <project-setting-popup
        ref="projectSettingPopup"
        @settingChanged="handleSettingChanged"
      ></project-setting-popup>

      <add-new-residential-project-popup
        ref="addNewPopup"
        @openCardDetailsPopup="openCardDetailsPopup"
      ></add-new-residential-project-popup>
    </div>
  </f7-page>
</template>

<script>
import ProjectDetailsPopup from '../../components/popup/ProjectDetailsPopup.vue';
import ProjectOnePageDetailsPopup from '../../components/popup/ProjectOnePageDetailsPopup.vue';
import ProjectSettingPopup from '../../components/popup/ProjectSettingPopup.vue';
import DashboardMenu from '../../components/menu/DashboardMenu.vue';
import AddNewResidentialProjectPopup from '../../components/popup/AddNewResidentialProjectPopup.vue';
import ResidentialGridTabs from '../../components/tabs/ResidentialGridTabs.vue';
import { mapGetters, mapActions } from 'vuex';
import Menu from '@/components/menu/Menu.vue';
import _ from 'lodash';
export default {
  components: {
    DashboardMenu,
    AddNewResidentialProjectPopup,
    ProjectDetailsPopup,
    ProjectOnePageDetailsPopup,
    ProjectSettingPopup,
    ResidentialGridTabs,
    Menu,
  },

  computed: {
    ...mapGetters('dashboard/project', ['boardCode', 'cardList']),
    ...mapGetters('dashboard/certificate', ['certificateList']),
  },
  created() {
    this.$f7.preloader.show();
    const promises = [];
    const boardCode =
      this.$f7route.route.meta.boardCode || this.$f7route.params.boardCode;
    const businessCode =
      this.$f7route.route.meta.businessCode ||
      this.$f7route.params.businessCode;
    promises.push(this.bindClientData()); //TODO: need to uncomment
    promises.push(this.getConstantTypeList());
    promises.push(this.setCurrentBoard(boardCode));
    promises.push(this.setCurrentBusinessCode(businessCode));

    if (_.isEmpty(this.users)) {
      promises.push(this.bindUserData());
    }
    if (_.isEmpty(this.certificateList)) {
      promises.push(
        this.bindCertificateListBys([
          {
            prop: 'isDeleted',
            val: false,
            op: '==',
          },
        ])
      );
    }
    promises.push(
      this.bindCardList({
        businessCode,
        boardCode,
      })
    );
    Promise.all(promises)
      .then(() => {
        if (
          this.$f7route.params.cardNumber &&
          this.$f7route.params.actionCode
        ) {
          const card = this.cardList.find(
            r => r.cardNumber === this.$f7route.params.cardNumber
          );
          this.getActionBys([
            {
              prop: 'code',
              val: Number(this.$f7route.params.actionCode),
              op: '==',
            },
          ]).then(action => {
            if (
              _.isEmpty(action) ||
              (card && !(card.actions || []).includes(action[0].id))
            ) {
              this.$f7.notification
                .create({
                  title: 'Not found action',
                  closeTimeout: 15000,
                  closeButton: true,
                  text: `Not found action code <b>${this.$f7route.params.actionCode}</b>. Please choose another action code.`,
                  closeOnClick: true,
                })
                .open();
              return;
            }
            this.setCurrentCard(this.$f7route.params.cardNumber);
            this.setCurrentAction(action[0].id);
            this.setCurrentResponse(action[0].id);
            // this.$refs.projectDetailsPopup.initCardData(
            //   this.$f7route.params.cardNumber,
            //   action[0].id
            // );
            // this.$refs.projectDetailsPopup.openPopup(false);

            this.openDetailsPopup(
              this.$f7route.params.cardNumber,
              action[0].id
            );
          });
        }

        if (this.$f7route.route.meta.isNew) {
          this.$refs.addNewPopup.openPopup();
        }
      })
      .finally(() => {
        this.$f7.preloader.hide();
      });
  },

  methods: {
    ...mapActions('dashboard/project', [
      'setCurrentBoard',
      'setCurrentBusinessCode',
      'bindCardList',
      'setCurrentCard',
      'setCurrentAction',
      'setCurrentResponse',
      'bindUserData',
      'getActionBys',
      'getAction',
    ]),
    ...mapActions('dashboard/client', ['bindClientData']),
    ...mapActions('dashboard/app-constant', ['getConstantTypeList']),
    ...mapActions('dashboard/certificate', ['bindCertificateListBys']),
    ...mapActions('dashboard/common', ['setResidentialGridCurrentTab']),

    toNewProject() {
      window.history.pushState('', '', `/dashboard/grid/residential/new`);
      this.$refs.addNewPopup.openPopup();
    },

    navigateToBoard() {
      //clear query param
      window.history.pushState(
        '',
        '',
        `/dashboard/grid/${this.$f7route.route.meta.businessCode}`
      );
    },

    async openCardDetailsPopup({ actionId, cardNumber }) {
      if (_.isEmpty(cardNumber) || _.isEmpty(actionId)) {
        return;
      }

      // get action by id
      const action = await this.getAction(actionId);
      this.setCurrentCard(cardNumber);
      this.$f7router.updateCurrentUrl(
        `/dashboard/grid/${this.$f7route.route.meta.businessCode}/${cardNumber}/action/${action.code}`
      );

      this.openDetailsPopup(cardNumber, actionId);
    },

    openSettingPopup() {
      this.$refs.projectSettingPopup.openPopup();
    },

    handleSettingChanged({ code, data }) {
      const self = this;
      if (code === 're-open-popup') {
        this.$refs.projectOnePageDetailsPopup.closePopup(() => {
          this.setResidentialGridCurrentTab(data.actionCode);
          self.openCardDetailsPopup({
            actionId: data.actionId,
            cardNumber: data.cardNumber,
          });
        });
      }
    },

    openDetailsPopup(cardNumber, actionId) {
      const self = this;
      if (this.$f7.device.desktop || this.$f7.device.ipad) {
        this.$refs.projectOnePageDetailsPopup
          .initCardData(cardNumber, actionId)
          .then(() => {
            self.$refs.projectOnePageDetailsPopup.openPopup();
          });
      } else {
        this.$refs.projectDetailsPopup.initCardData(cardNumber, actionId);
        this.$refs.projectDetailsPopup.openPopup(false);
      }
    },
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.full-screen {
  width: calc(100vw);
  position: fixed;
  z-index: 9999;
  top: 5%;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-x: scroll;
  overflow-y: scroll;
}
</style>
