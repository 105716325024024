import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const contactActions = new FirebaseActions(
    `/system_client/${tenant}/operation_contact`,
    'contact'
  );

  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    'company'
  );

  return {
    createContact: contactActions.createDocument,
    createCompany: companyActions.createDocument,
    updateContact: contactActions.updateDocument,
  };
};
