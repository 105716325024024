import { applyFilter, groupBy } from '@/utility/filter-tools';
import { STATUS_TIME_TRACKING_WFA } from '@/utility/const';

export default {
  timeTrackingList: ({ timeTrackingList }) => {
    return timeTrackingList;
  },
  statusFilter: ({ statusFilter }) => statusFilter,
  timeLogTypeFilter: ({ timeLogTypeFilter }) => timeLogTypeFilter,
  projectFilter: ({ projectFilter }) => projectFilter,
  listUserTrackings: (
    _,
    { timeTrackingList: timeTrackingList },
    __,
    rootGetters
  ) => {
    let users = rootGetters['time-tracking/user/userListByHours'];
    const myTeams = rootGetters['common/team-management/teamList'];
    const myEmployeesIds = myTeams.reduce((a, b) => [...a, ...b.userIDs], []);
    users = users.filter(user => myEmployeesIds.includes(user.uid));
    const arr = (users || []).map(x => {
      const allItems = timeTrackingList.filter(y => y.user_id === x.uid);
      const awaitingItems = allItems.filter(
        y => y.status === STATUS_TIME_TRACKING_WFA
      );
      return {
        uid: x.uid,
        user_id: x.uid,
        displayName: x.displayName,
        totals: awaitingItems.length,
        allCount: allItems.length,
      };
    });
    return arr;
  },
  listUserTrackingGroup:
    (_, getters) =>
    (searchValue = '') => {
      const filteredUsers = applyFilter(
        getters.listUserTrackings,
        searchValue,
        ['displayName']
      );
      let dataGroup = groupBy(filteredUsers, item =>
        ((item.displayName || '').charAt(0) || '').toUpperCase()
      ).sort((a, b) => sortBy(a.key, b.key));
      return dataGroup.map(i => ({
        ...i,
        data: i.data.sort((a, b) => sortBy(a.displayName, b.displayName)),
      }));
    },
  detail: (_, { timeTrackingList, detailUid }) => {
    return timeTrackingList.filter(x => x.user_id === detailUid);
  },
  isShowBackBtn: ({ isShowBackBtn }) => isShowBackBtn,
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
