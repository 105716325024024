<template>
  <div>
    <f7-block-title>
      <div>{{ title }}</div>
    </f7-block-title>
    <f7-list>
      <!-- Main list item -->
      <f7-list-item
        v-for="(item, index) in estimateList"
        :key="index"
        swipeout
        text-color="blue"
      >
        <a
          class="cursor-pointer"
          slot="title"
          @click="openLink(item)"
          >{{ item.estimateNumber }}</a
        >
        <f7-checkbox
          slot="media"
          name="demo-radio-inline"
          :checked="item.isDefault === true"
          @change="onRadioChange(item.id, $event.target.checked)"
        ></f7-checkbox>

        <div slot="footer">{{ getStatusString(item.status) }}</div>
        <div
          slot="after"
          v-if="item.isDefault === true"
        >
          Official
        </div>
        <f7-swipeout-actions right>
          <f7-swipeout-button
            color="primary"
            :close="true"
            @click="removeEstimate(item)"
            confirm-title="Confirm remove!"
            confirm-text="Are you sure you want to remove this estimate?"
            >Remove</f7-swipeout-button
          >
        </f7-swipeout-actions>
      </f7-list-item>

      <!-- Add list item -->
      <f7-list-item @click.native="createNewEstimate">
        <div
          slot="media"
          class="add-button-icon cursor-pointer"
        >
          <f7-icon f7="plus_circle"></f7-icon>
        </div>
        <div
          class="cursor-pointer"
          slot="title"
        >
          {{ titleButton }}
        </div>
      </f7-list-item>

      <f7-list-item @click="showEstimateSumPopup"
        ><div
          slot="media"
          class="add-button-icon cursor-pointer"
        >
          <f7-icon f7="sum"></f7-icon>
        </div>
        <div
          class="cursor-pointer"
          slot="title"
        >
          View Estimate Summary
        </div></f7-list-item
      >
    </f7-list>
    <estimate-sum-popup
      :estimateList="estimateList"
      :project="card"
      ref="estimateSumPopup"
    ></estimate-sum-popup>
  </div>
</template>

<script>
import EstimateSumPopup from '../popup/EstimateSumPopup.vue';
import { toDisplayDateString } from '@/utility/datetime';
import { mapGetters, mapActions } from 'vuex';
import { PROP_IS_DELETED } from '../../../../utility/const';
import _ from 'lodash';

export default {
  data: () => {
    return {
      boardId: 'residential',
      titleButton: 'Add an Estimate',
      activeTab: '',
    };
  },
  props: {
    title: { type: String, default: '' },
  },
  components: { EstimateSumPopup },

  computed: {
    ...mapGetters('dashboard/project', ['card', 'action']),
    ...mapGetters('dashboard/estimate', ['estimateList']),
  },
  methods: {
    ...mapActions('dashboard/estimate', [
      'updateEstimate',
      'deleteEstimate',
      'getEstimatesBys',
    ]),
    getEstimates() {
      if (!_.isEmpty(this.card)) {
        this.getEstimatesBys([
          {
            prop: 'projectId',
            val: this.card.id,
            op: '==',
          },
          {
            prop: PROP_IS_DELETED,
            val: false,
            op: '==',
          },
          { prop: 'status', val: ['incomplete', 'active', 'draft'], op: 'in' },
        ]);
      }
    },

    getStatusString(status) {
      switch (status) {
        case 'active':
          return 'Active';

        case 'inactive':
          return 'Inactive';

        default:
          return 'Draft';
      }
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    openLink(estimate) {
      const self = this;
      const boardId = this.boardId;
      const actionId = this.action.id;
      const projectId = this.card.id;
      const contactId = estimate.contactId;
      const estimateNumber = estimate.estimateNumber;

      this.$emit('doClosePopup', () => {
        const url = `/dashboard/type/${
          self.$f7route.route.meta.boardType || 'swimlane'
        }/business/${
          self.$f7route.route.meta.businessCode
        }/board/${boardId}/action/${actionId}/project/${projectId}/estimate/contact/${contactId}/${estimateNumber}${
          self.activeTab ? `?activeTab=${self.activeTab}` : ''
        }`;

        this.$f7router.navigate(url, {
          pushState: true,
          reloadAll: true,
        });
      });
    },

    onRadioChange(estimateId, value) {
      this.$f7.preloader.show();
      this.updateEstimate({
        id: estimateId,
        doc: {
          value,
        },
      })
        .then(() => {
          this.getEstimates();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    removeEstimate(estimate) {
      this.$f7.preloader.show();
      this.updateEstimate({
        id: estimate.id,
        doc: {
          projectId: '',
          [PROP_IS_DELETED]: true,
        },
      })
        .then(() => {
          this.getEstimates();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    showEstimateSumPopup() {
      this.$refs.estimateSumPopup.show();
    },
    closeActionPopup(callback) {
      this.$emit('closePopup', callback);
    },
    createNewEstimate() {
      this.$f7.preloader.show();
      const actionId = this.action.id;
      const projectId = this.card.id;
      const contactId = this.card.contactId || '-';
      // const boardId = this.displayCard.boardId;
      const self = this;

      this.closeActionPopup(() => {
        self.$f7.preloader.hide();
        self.$f7router.navigate(
          {
            name: 'new-estimate-swimlane-residential',
            params: {
              boardType: self.$f7route.route.meta.boardType,
              businessCode: self.$f7route.route.meta.businessCode,
              // boardId,
              actionId,
              projectId,
              contactId,
              clientType: 'contact',
              clientId: contactId,
            },
            query: {
              activeTab: 'tab-action',
            },
          },
          { reloadAll: true }
        );
      });
    },
  },
};
</script>

<style></style>
