import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const historyActions = new FirebaseActions(
    `/system_client/${tenant}/proposal_history`,
    'history'
  );

  return {
    bindHistoryListBy: historyActions.bindCollectionBy,
    createHistory: historyActions.createDocument,
  };
};
