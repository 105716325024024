import webContactInfo from './web-contact-info';
import user from './user';
import project from './project';
import client from './client';
import swimlane from './swimlane';

export default tenant => {
  return {
    namespaced: true,
    modules: {
      'web-contact-info': webContactInfo(tenant),
      user,
      project: project(tenant),
      client: client(tenant),
      swimlane: swimlane(tenant),
    },
  };
};
