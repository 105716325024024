import axiosService from './axios.service';
import 'firebase/auth';
import { firebase } from './firebase.service';

const auth = firebase.auth();

// add doc and generate number
const addBoardDoc = (tenantId, data) => {
  data.createdBy = auth.currentUser
    ? auth.currentUser.displayName || auth.currentUser.email
    : '';
  data.id = '';
  return axiosService
    .post('dash-board/add-board', { tenantId, data })
    .then(res => {
      return res.data;
    });
};

// add doc and generate number
const addCardDoc = (tenantId, data) => {
  data.createdBy = auth.currentUser
    ? auth.currentUser.displayName || auth.currentUser.email
    : '';
  data.id = '';
  return axiosService
    .post('dash-board/add-card', { tenantId, data })
    .then(res => {
      return res.data;
    });
};

// update doc and generate number
const updateCardDoc = (tenantId, docId, data) => {
  data.updatedBy = auth.currentUser
    ? auth.currentUser.displayName || auth.currentUser.email
    : '';
  return axiosService
    .post('dash-board/update-card', { tenantId, docId, data })
    .then(res => {
      return res.data;
    });
};

// add doc and generate number
const addWOTaskDoc = (tenantId, data) => {
  data.createdBy = auth.currentUser
    ? auth.currentUser.displayName || auth.currentUser.email
    : '';
  data.id = '';
  return axiosService
    .post('dash-board/add-wo-task', { tenantId, data })
    .then(res => {
      return res.data;
    });
};

// update doc and generate number
const updateWOTaskDoc = (tenantId, docId, data) => {
  data.updatedBy = auth.currentUser
    ? auth.currentUser.displayName || auth.currentUser.email
    : '';
  return axiosService
    .post('dash-board/update-wo-task', { tenantId, docId, data })
    .then(res => {
      return res.data;
    });
};

export default {
  addBoardDoc,
  addCardDoc,
  updateCardDoc,
  addWOTaskDoc,
  updateWOTaskDoc,
};
