<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ vendor ? vendor.companyName : '' }}</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <product-list />
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProductList from '../components/list/ProductList.vue';

export default {
  components: {
    ProductList,
  },

  methods: {
    ...mapActions('product/product-item-page/vendor', ['bindVendor']),
    handleBack() {
      this.$f7router.back('/vendor', { force: true });
    },
  },

  computed: {
    ...mapGetters('product/product-item-page/vendor', ['vendor']),
  },

  created() {
    this.bindVendor(this.$f7route.params.vendorId);
  },
};
</script>
