/* eslint-disable no-unused-vars */
import { FirebaseActions } from '../../../../services/firebase.service';
import {
  CODE_SCHEDULING_VIEWS,
  CODE_TIME_OFF_TYPE,
  CODE_TIME_LOG_TYPE,
  CODE_COMPANY_TYPE,
} from '../../../../utility/const';
import * as types from './types';

export default tenant => {
  const typeAction = new FirebaseActions(
    `/system_client/${tenant}/app_constant`,
    'type'
  );

  return {
    getConstantTypeListBy: typeAction.getDocumentBys,
    // Use the in operator to combine up to 10 equality (==) clauses on the same field with a logical OR.
    // An in query returns documents where the given field matches any of the comparison values
    getConstantTypeList({ dispatch, commit }) {
      return dispatch('getConstantTypeListBy', [
        {
          prop: 'code',
          op: 'in',
          val: [
            CODE_SCHEDULING_VIEWS,
            CODE_TIME_OFF_TYPE,
            CODE_TIME_LOG_TYPE,
            CODE_COMPANY_TYPE,
          ],
        },
      ]).then(res => {
        return commit(types.SET_CONSTANT_LIST, res);
      });
    },

    resetConstant({ commit }) {
      commit(types.SET_CONSTANT_LIST, []);
    },
  };
};
