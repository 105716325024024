import axiosService from './axios.service';
import 'firebase/auth';

const getLaborCostOfProject = (tenantId, data) => {
  return axiosService
    .get(
      `/report/labor-cost-of-project/${tenantId}?projectId=${data.projectId}&view=${data.view}`
    )
    .then(res => {
      return res.data;
    });
};

const getLaborCostAnalysis = (tenantId, data) => {
  return axiosService
    .get(
      `/report/labor-cost-analysis/${tenantId}?startDate=${
        data.startDate
      }&endDate=${data.endDate}&projectId=${data.projectId || ''}`
    )
    .then(res => {
      return res.data;
    });
};

export default {
  getLaborCostOfProject,
  getLaborCostAnalysis,
};
