import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const projectPhotoAlbumActions = new FirebaseActions(
    `/system_client/${tenant}/project_photo_album`,
    'projectPhotoAlbum'
  );

  return {
    bindProjectPhotoAlbumListBy: projectPhotoAlbumActions.bindCollectionBy,
    unbindProjectPhotoAlbumList: projectPhotoAlbumActions.unbindCollection,
    getProjectPhotoAlbumListBys: projectPhotoAlbumActions.getDocumentBys,
  };
};
