<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Account Profile</f7-nav-title>
      <f7-nav-right>
        <f7-link @click="popupEdit = true">Edit</f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-list
      class="justify-content-center align-items-center display-flex infomation"
    >
      <upload-avatar
        textUpload="Edit"
        :avatarSize="70"
        :imageUrl="user.photoURL"
        :name="user.displayName"
        @upload="upload"
      />
      <span class="no-margin infomation-name">{{ user.displayName }}</span>
      <span class="no-margin infomation-email">{{ displayEmail }}</span>
    </f7-list>

    <f7-block-title>Information</f7-block-title>
    <f7-list>
      <!-- <f7-list-item header="Full name" :title="user.displayName"></f7-list-item>
      <f7-list-item header="Email" :title="user.email"></f7-list-item>-->
      <f7-list-item
        header="Phone"
        :title="displayPhone"
      ></f7-list-item>

      <f7-list-item header="Groups">
        <chip-more
          slot="title"
          :deleteable="false"
          :limit="3"
          :data="userGroups"
        />
      </f7-list-item>
      <f7-list-item
        header="Pay Type"
        :title="userPayType"
      ></f7-list-item>
      <f7-list-item
        header="Default Loading Screen"
        :title="userMenu"
      ></f7-list-item>
      <!-- <f7-list-item header="Role" :title="role"></f7-list-item> -->
    </f7-list>

    <!--! languge -->
    <select-language></select-language>

    <f7-block-title>Appearance</f7-block-title>
    <f7-list>
      <f7-list-item title="Dark Theme">
        <!-- <f7-icon slot="media" icon="icon-f7"></f7-icon> -->
        <f7-toggle
          slot="after"
          :checked="dark"
          @change="setTheme(!dark)"
        ></f7-toggle>
      </f7-list-item>
    </f7-list>

    <f7-list>
      <f7-list-item title="Email Verified">
        <f7-toggle
          slot="after"
          disabled
          :checked="user.emailVerified"
        ></f7-toggle>
      </f7-list-item>
      <f7-list-item
        title="Active"
        v-if="role === 'owner'"
      >
        <f7-toggle
          slot="after"
          disabled
          :checked="!user.disabled"
        ></f7-toggle>
      </f7-list-item>
      <f7-list-item title="Receive Online Lead Email, SMS, Notification">
        <f7-toggle
          slot="after"
          disabled
          :checked="(user.customClaims || {}).allowReceiveNotification"
        ></f7-toggle>
      </f7-list-item>
    </f7-list>
    <f7-list>
      <f7-list-button
        color="red"
        @click="changingPassword = true"
        title="Change user password"
      ></f7-list-button>
    </f7-list>
    <!-- {{ groupList }}
    {{ groups }} -->

    <f7-list>
      <f7-list-button
        title="Logout"
        color="red"
        @click.native="logout"
        panel-close
      ></f7-list-button>
    </f7-list>

    <change-password-popup
      :popupOpened="changingPassword"
      @close="changingPassword = false"
    ></change-password-popup>
    <edit-profile
      :popupOpened="popupEdit"
      @close="popupEdit = false"
    />
  </f7-page>
</template>

<script>
import ChangePasswordPopup from '../components/popup/ChangePasswordPopup.vue';
import UploadAvatar from '@/components/uploads/UploadAvatar.vue';
import ChipMore from '@/components/chips/ChipMore.vue';
import EditProfile from '../components/popup/EditProfilePopup.vue';

import { mapActions, mapGetters, mapState } from 'vuex';
import { signOut, removeToken } from '../../../services/authentication.service';
import { setLayoutTheme } from '../../../utility/theme.js';
import { removeMessagingToken } from '@/services/firebase.service';

import roles from '../mock/roles';
// import Vue from "vue";
import _ from 'lodash';

import Menu from '../../../components/menu/Menu.vue';
import SelectLanguage from '../components/selects/SelectLanguage.vue';

export default {
  components: {
    // UserDetailsPopup,
    ChangePasswordPopup,
    ChipMore,
    UploadAvatar,
    EditProfile,
    Menu,
    SelectLanguage,
  },

  data: () => {
    return {
      popupOpened: false,
      changingPassword: false,
      popupEdit: false,
    };
  },

  computed: {
    ...mapGetters('setting/profile-page/profile', ['user']),
    ...mapGetters('setting/profile-page/group', ['groupList']),
    ...mapState('setting/profile-page/profile', ['dark']),
    ...mapGetters('setting/profile-page/app-constant', ['payTypeList']),
    ...mapGetters('setting/profile-page/menu', ['menus']),
    ...mapGetters('common/administration-user', ['userForGroup']),

    displayPhone() {
      let numberFormat = (this.user.phoneNumber || '').slice(2);
      return this.user.phoneNumber
        ? '(+1) ' +
            numberFormat.substring(0, 3) +
            '-' +
            numberFormat.substring(3, 6) +
            '-' +
            numberFormat.substring(6)
        : '';
    },

    displayEmail() {
      let email = this.user.email;
      if (this.user.customClaims && this.user.customClaims.displayEmail) {
        email = this.user.customClaims.displayEmail;
      }
      return email;
    },

    userGroups() {
      const groupIds = this.userForGroup.groupIDs || [];
      const groups = this.groupList.filter(r => groupIds.includes(r.id));
      return groups.map(r => ({ id: r.id, name: r.displayName }));
    },

    role() {
      return this.user && this.user.customClaims
        ? this.user.customClaims.role
        : '';
    },
    userPayType() {
      return (
        this.payTypeList.find(type => {
          return type.value === ((this.user || {}).customClaims || {}).payType;
        }) || {}
      ).displayName;
    },
    userMenu() {
      return (
        (this.menus || []).find(url => {
          return url.path === ((this.user || {}).customClaims || {}).homeUrl;
        }) || {}
      ).displayName;
    },
  },

  async created() {
    await this.getUserProfile();
    if (_.isEmpty(this.groupList)) {
      this.bindGroupList();
    }
    if (_.isEmpty(this.payTypeList)) {
      this.bindConstantTypeList();
    }
    this.getUserForGroupById(this.user.uid);
    this.getMenus(this.$f7router.routes);
  },

  methods: {
    ...mapActions('setting/profile-page/profile', ['getUserProfile']),
    ...mapActions('setting/profile-page/profile', ['setSettingsThemeDark']),
    ...mapActions('setting/profile-page/app-constant', [
      'bindConstantTypeList',
    ]),
    ...mapActions('common/administration-user', ['getUserForGroupById']),
    ...mapActions('setting/profile-page/menu', ['getMenus']),
    setTheme(dark) {
      setLayoutTheme(this, dark);
      this.setSettingsThemeDark(dark);
    },

    upload(event) {
      let formData = new FormData();
      formData.append('file', event.target.files[0]);
      return formData;
    },

    ...mapActions('setting/profile-page/group', [
      'bindGroupList',
      'unbindGroupList',
    ]),

    getRole(val) {
      return (
        roles.find(role => {
          return role.value === val;
        }) || {}
      );
    },

    // setGroups(val) {
    //   Vue.set(this, "groups", val);
    // },

    async logout() {
      await removeMessagingToken();
      signOut().then(() => {
        removeToken();
        // this.$f7router.navigate("/login", {
        //   pushState: true,
        //   reloadAll: true
        // });
        window.location.href = '/login';
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.infomation {
  flex-direction: column;
}
.infomation-name {
  font-weight: bold;
  font-size: 16px;
}
.infomation-email {
  font-size: 12px;
}
</style>
