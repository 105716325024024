<template>
  <f7-list
    class="auto-complete-input"
    @keydown.native="onKeydown"
    @keyup.native="onKeyup"
  >
    <f7-list-input
      :label="label"
      id="value"
      :value="value"
      @input="onSearch($event.target.value)"
      @blur="onBlurSearch"
      @input:clear="$emit('input', '')"
      :placeholder="placeholder || 'Enter value'"
      clear-button
      error-message-force
      :error-message="errorMessage"
    >
    </f7-list-input>
    <div
      class="dropdown-content"
      v-if="predictions.length > 0 && showPredictions"
    >
      <auto-complete-list
        :items="predictions"
        @item:click="onItemClick"
        @item:blur="onItemBlur"
      />
    </div>
  </f7-list>
</template>
<script>
import { useVuelidate } from '@vuelidate/core';
import AutoCompleteList from './AutoCompleteList';

const KEYCODE = {
  ESC: 27,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ENTER: 13,
  SPACE: 32,
  TAB: 9,
};
export default {
  components: {
    AutoCompleteList,
  },
  props: {
    value: String,
    placeholder: String,
    label: String,
    errorMessage: String,
    options: Array,
    maxItem: Number,
  },
  data: () => {
    return {
      predictions: [],
      showPredictions: true,
      isKeyUpOrDown: false,
    };
  },
  computed: {},
  methods: {
    getInputElement() {
      return this.$el.querySelector('#value input');
    },
    select(value) {
      this.$emit('input', value.text);
      this.$emit('change', value.text);
    },
    hide() {
      this.showPredictions = false;
    },
    show() {
      this.showPredictions = true;
    },
    onFocus(event) {
      if (this.query) {
        if (!this.predictions.length) {
          this.onKeyup(event);
        }

        this.show();
      }
    },

    onBlur(event) {
      if (!this.$el.contains(event.relatedTarget)) {
        this.hide();
      }
    },

    onItemBlur(event) {
      this.onBlur(event);
    },

    onItemClick(event, child) {
      this.select(child.item);
      this.predictions = false;
    },
    search() {
      return new Promise(resolve => {
        if (!this.getInputElement().value) {
          this.predictions = false;
          this.showPredictions = false;
        } else {
          let valueSearch = this.getInputElement().value;
          resolve(
            (this.options || []).filter(r =>
              r.text.toLowerCase().includes(valueSearch.toLowerCase())
            )
          );
        }
      });
    },
    async onSearch(value) {
      this.$emit('input', value);
      this.search().then(
        response => {
          this.predictions = response;
          this.showPredictions = true;
        },
        error => {
          if (error) {
            this.predictions = false;
          }
        }
      );
    },
    onBlurSearch() {
      this.v$.$touch();
      if (!this.isKeyUpOrDown) {
        setTimeout(() => this.hide(), 500);
      }
    },
    up() {
      const focused = this.$el.querySelector('a:focus');
      if (focused && focused.parentElement.previousElementSibling) {
        focused.parentElement.previousElementSibling.querySelector('a').focus();
      } else {
        const links = this.$el.querySelectorAll('a');
        links[links.length - 1] && links[links.length - 1].focus();
      }
    },

    down() {
      const focused = this.$el.querySelector('a:focus');

      if (focused && focused.parentElement.nextElementSibling) {
        focused.parentElement.nextElementSibling.querySelector('a').focus();
      } else {
        if (!this.$el.querySelector('a')) {
          this.predictions = this.options;
          this.showPredictions = true;
        } else {
          this.$el.querySelector('a').focus();
        }
      }
    },

    onKeydown(event) {
      this.isKeyUpOrDown = true;
      const element = this.$el.querySelector('[tabindex]');

      if (element && event.keyCode === KEYCODE.TAB) {
        event.preventDefault() && element.focus();
      }
      this.isKeyUpOrDown = false;
    },

    onKeyup(event) {
      this.isKeyUpOrDown = true;
      switch (event.keyCode) {
        case KEYCODE.ENTER:
        case KEYCODE.SPACE:
          if (this.$el.querySelector('.is-focused')) {
            this.$el
              .querySelector('.is-focused a')
              .dispatchEvent(new Event('mousedown'));
          }
          return;
        case KEYCODE.ESC:
          this.hide();
          this.getInputElement().blur();
          event.preventDefault();
          return;
        case KEYCODE.UP:
          this.up();
          event.preventDefault();
          return;
        case KEYCODE.DOWN:
          this.down();
          event.preventDefault();
          return;
      }

      this.search().then(
        response => {
          this.predictions = response;
          this.showPredictions = true;
        },
        error => {
          if (error) {
            this.predictions = false;
          }
        }
      );

      this.isKeyUpOrDown = false;
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {},
};
</script>

<style lang="scss" scoped>
.dropdown-content {
  position: absolute;
  background-color: #f6f6f6;
  width: 85%;
  min-width: 200px;
  max-width: 400px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1000;
  top: 35px;
  left: 10px;
  background-color: #fff;
}

.row {
  background-color: #fff;
}

.auto-complete-input {
  margin: 0;
}

.space {
  width: 1em;
  height: 1em;
}
</style>
<style>
.auto-complete-input.list ul::after {
  display: none;
}
</style>
