<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="
      popupOpened = false;
      isNew = false;
    "
    @popup:opened="handlePopupOpened"
  >
    <f7-page v-show="!isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Client Company</f7-nav-title>
        <f7-nav-right>
          <!-- <f7-link icon-f7="plus" @click.native="onAdd"></f7-link> -->
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar
        disable-button-text
        placeholder="Search Company"
        :clear-button="true"
        :value="searchValue"
        @input="searchValue = $event.target.value"
        @searchbar:disable="searchValue = ''"
        class="search-list-popup"
      ></f7-searchbar>

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found"></f7-list-item>
      </f7-list>

      <f7-list class="search-list searchbar-found contact-list">
        <f7-list-group
          v-for="(group, index) in companyGroup(searchValue)"
          :key="index"
        >
          <f7-list-item
            :title="group.key"
            group-title
          ></f7-list-item>
          <f7-list-item
            no-chevron
            link
            v-for="company in group.data"
            :key="company.id"
            :title="company.companyName"
            @click.native="select(company)"
          ></f7-list-item>
        </f7-list-group>
      </f7-list>
    </f7-page>
    <f7-page v-show="isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="onAddCancel">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>New Company</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="create">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <!-- Company Name -->
        <f7-list-input
          class="first-input"
          type="text"
          placeholder="Company name"
          clear-button
          :value="currentCompany.companyName"
          @input="set('companyName', $event.target.value.trim())"
          error-message-force
          :error-message="companyNameErrorMessage"
          @blur="v$.$touch()"
        ></f7-list-input>
      </f7-list>

      <!-- Company Types -->
      <f7-list>
        <f7-list-item
          ref="companyTypesPopup"
          title="Company Types"
          smart-select
          :smart-select-params="{
            openIn: 'popup',
            searchbar: true,
            searchbarPlaceholder: 'Search type',
            on: {
              opened: handlePopupOpened,
            },
          }"
        >
          <select
            name="types"
            multiple
            v-model="currentCompany.companyTypes"
          >
            <option
              v-for="(type, index) in companyTypeOptions"
              :key="index"
              :value="type.value"
            >
              {{ type.displayName }}
            </option>
            >
          </select>
        </f7-list-item>
      </f7-list>

      <!-- Phone -->
      <phone-input
        :id="currentCompany.id"
        ref="phoneInput"
        isRequired
        :value="currentCompany.phones"
        @input="setPhones($event)"
      ></phone-input>

      <!-- Address -->
      <address-auto-complete-input
        :id="currentCompany.id"
        ref="addressInput"
        :value="currentCompany.addresses"
        @input="setAddresses($event)"
      ></address-auto-complete-input>

      <!-- Other -->
      <other-input
        :id="currentCompany.id"
        ref="otherInput"
        :value="currentCompany.others"
        @input="setOthers($event)"
      ></other-input>
    </f7-page>
  </f7-popup>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import PhoneInput from '@/components/inputs/PhoneInput.vue';
import AddressAutoCompleteInput from '@/components/inputs/AddressAutoCompleteInput.vue';
import OtherInput from '@/components/inputs/OtherInput.vue';

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import _ from 'lodash';
import Vue from 'vue';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    PhoneInput,
    AddressAutoCompleteInput,
    OtherInput,
  },
  data() {
    return {
      popupOpened: false,
      searchValue: '',
      isNew: false,
      currentCompany: {
        companyName: '',
        companyTypes: [],
      },
    };
  },

  computed: {
    ...mapGetters({
      companyList: 'invoices/company/companyList',
      companyGroup: 'invoices/company/objectGroup',
    }),
    ...mapGetters('invoices/app-constant', ['companyTypeList']),
    companyTypeOptions() {
      return this.companyTypeList.filter(r => r.value != 'vendor');
    },
    companyNameErrorMessage() {
      if (!this.v$.currentCompany.companyName.$error) return null;
      if (this.v$.currentCompany.companyName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },

  methods: {
    ...mapActions({
      bindCompanyList: 'invoices/company/bindCompanyList',
      createCompany: 'invoices/company/create',
    }),

    set(prop, value) {
      Vue.set(this.currentCompany, prop, value);
    },

    initData() {
      if (_.isEmpty(this.companyList)) {
        this.$f7.preloader.show();
        this.bindCompanyList().then(() => {
          this.$f7.preloader.hide();
        });
      }
    },

    open() {
      this.initData();
      this.popupOpened = true;
    },

    select(company) {
      this.$emit('onSelected', company);
      this.popupOpened = false;
    },

    onAdd() {
      this.isNew = true;
      this.$nextTick(() => {
        if (this.$device.desktop) {
          this.$el.querySelector('.first-input input').focus();
        }
      });
    },

    onAddCancel() {
      this.isNew = false;
      this.currentCompany = {
        companyTypes: [],
      };
      this.v$.$reset();
    },

    setPhones(val) {
      Vue.set(this.currentCompany, 'phones', val);
    },

    setAddresses(val) {
      Vue.set(this.currentCompany, 'addresses', val);
    },

    setOthers(val) {
      Vue.set(this.currentCompany, 'others', val);
    },

    existsCompany() {
      const found = this.companyList.find(company => {
        return company.companyName === this.currentCompany.companyName;
      });

      if (!_.isEmpty(found)) {
        this.$ri.dialog.openWarningDialog({
          title: 'Please choose another one',
          content: 'That company has already existed!',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      }

      return !_.isEmpty(found);
    },

    create() {
      this.v$.$touch();
      this.$refs.phoneInput.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      this.$refs.otherInput.v$.$touch();
      if (
        this.$refs.phoneInput.v$.$invalid ||
        this.$refs.addressInput.v$.$invalid ||
        this.$refs.otherInput.v$.$invalid ||
        this.v$.$invalid ||
        this.existsCompany()
      )
        return;
      this.currentCompany.companyTypes = this.currentCompany.companyTypes || [];
      this.$f7.preloader.show();
      this.createCompany(this.currentCompany).then(id => {
        this.$emit('onSelected', { ...this.currentCompany, id });
        this.popupOpened = false;
        this.onAddCancel();
        this.$f7.preloader.hide();
      });
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list-popup.searchbar input').focus();
      }
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      currentCompany: {
        companyName: {
          required,
        },
      },
    };
  },
};
</script>
