import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const priceListActions = new FirebaseActions(
    `/system_client/${tenant}/product_price_list`,
    'priceListItem'
  );

  const productActions = new FirebaseActions(
    `/system_client/${tenant}/product_product_items`,
    'product'
  );

  const categoryActions = new FirebaseActions(
    `/system_client/${tenant}/product_category`,
    'category'
  );

  return {
    bindCategoryList: categoryActions.bindCollection,
    getPriceListById: priceListActions.getDocument,

    getProductsBys: productActions.getDocumentBys,

    getProductsByPriceList({ dispatch }, priceListId) {
      return dispatch('getPriceListById', priceListId).then(priceList => {
        return dispatch('getProductsBys', [
          {
            prop: 'status',
            val: 'pi-active',
            op: '==',
          },
          {
            prop: 'priceListRefs',
            val: priceList.id,
            op: 'array-contains',
          },
        ]).then(orgProducts => {
          const joinProducts = (priceList.productRefs || []).map(ref => {
            const orgProduct =
              orgProducts.find(product => product.id === ref.productId) || {};
            return {
              ...orgProduct,
              ...ref,
            };
          });
          return joinProducts;
        });
      });
    },
  };
};
