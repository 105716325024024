import Vue from 'vue';
import * as types from './types';

export default {
  [types.SET_CONTACT_HISTORY_LIST](state, payload) {
    Vue.set(state, 'historyList', payload);
  },

  [types.SET_CONTACT_HISTORY](state, payload) {
    Vue.set(state, 'history', payload);
  },
};
