import _ from 'lodash';
import { toDisplayDateString } from '../../../../utility/datetime';
import { sortLatest } from '@/utility/date-time-tool';
import { getFullAddress } from '../../../../utility/address';

export default {
  // workOrderList: state => state.workOrderList,
  // workOrder: state => state.workOrder,
  workOrderListByType: state => type =>
    state.workOrderList.filter(item => item.workOrderType === type),

  workOrderActionList: state =>
    _.cloneDeep(state.workOrderActionList).sort((a, b) => a.code - b.code),
  workOrderAction: state => state.workOrderAction,

  workOrderTaskList: (state, getters, rootState) => {
    const userFilter = rootState.dashboard.project.userFilter;

    let newList =
      state.workOrderTaskList.map(task => {
        return {
          ...task,
          title: task.taskNumber,
        };
      }) || [];

    newList = sortLatest(newList);

    if (userFilter && userFilter.length > 0) {
      newList = newList.filter(card => {
        for (const uid of userFilter) {
          if ((card.assigneeIds || []).includes(uid)) {
            return true;
          }
        }

        return false;
      });
    }
    let searchText = state.searchText.toLowerCase();

    if (searchText) {
      newList = newList.filter(card => {
        if (card.title) {
          var cardNumber = card.title.toLowerCase();
        }
        if (card.status) {
          var status = card.status.toLowerCase();
        }
        if (card.projectname) {
          var projectName = card.projectName.toLowerCase();
        }
        if (card.roofTypeName) {
          var roofTypeName = card.roofTypeName.toLowerCase();
        }
        if (card.vendorName) {
          var vendorName = card.vendorName.toLowerCase();
        }
        if (card.projectAddress) {
          var projectAddress = getFullAddress(
            card ? card.projectAddress : {}
          ).toLowerCase();
        }

        if (
          (cardNumber || []).includes(searchText) ||
          (projectName || []).includes(searchText) ||
          (roofTypeName || []).includes(searchText) ||
          (vendorName || []).includes(searchText) ||
          (status || []).includes(searchText) ||
          (projectAddress || []).includes(searchText)
        ) {
          return true;
        }
      });
    }
    if (state.weekFilter.length > 0) {
      newList = newList.filter(card => {
        for (let i = 0; i <= 6; i++) {
          if (
            (toDisplayDateString(card.dueDate) || []).includes(
              state.weekFilter[i]
            )
          ) {
            return true;
          }
        }
      });
    }

    return newList;
  },

  filterTaskList: (state, getter) => actionId => {
    return getter.workOrderTaskList.filter(card => {
      return card.actions ? card.actions.includes(actionId) : false;
    });
  },

  filterTaskListGrid: (state, getters) => {
    let actions = getters.workOrderActionList;
    let cards = [];
    for (const action of actions) {
      cards.push(
        ..._.cloneDeep(getters.filterTaskList(action.id)).map(item => {
          item.actionTitle = action.title;
          item.actionId = action.id;
          item.actionCode = action.code;
          return item;
        })
      );
    }

    return cards;
  },

  workOrderTask: state => state.workOrderTask,
  response: state =>
    _.cloneDeep(state.response || []).sort((a, b) => a.index - b.index),

  purchaseOrderList: state => sortLatest(state.purchaseOrderList),
  purchaseOrder: state => state.purchaseOrder || {},
  poResponse: state => state.poResponse,
};
