<template>
  <f7-popup
    class="demo-popup"
    :opened="opened"
    @popup:closed="cancel"
  >
    <f7-page>
      <!-- Navigate bar -->
      <f7-navbar>
        <f7-nav-left>
          <f7-link
            popup-close
            @click="cancel"
            >Cancel</f7-link
          >
        </f7-nav-left>
        <f7-nav-title>Copy Estimate</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="add()">Copy</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <!-- select options -->
      <f7-list media-list>
        <!-- Estimate Name -->
        <f7-list-input
          label="Estimate Name"
          placeholder="Enter Estimate Name"
          type="text"
          :value="currentEstimate.estimateName"
          @input="currentEstimate.estimateName = $event.target.value"
        >
          <input-icon
            slot="media"
            icon="doc_text"
          ></input-icon>
        </f7-list-input>

        <!-- Project -->
        <f7-list-input
          placeholder="Select Job"
          type="text"
          :value="
            currentProject && currentProject.cardNumber && currentProject.title
              ? currentProject.cardNumber + ' - ' + currentProject.title
              : ''
          "
          @focus="selectProject"
          class="job-title"
        >
          <input-icon
            slot="media"
            icon="cube_box"
          ></input-icon>
          <div
            class="list-item-inner-start"
            slot="label"
          >
            Job Title<required-asterisk />
          </div>
        </f7-list-input>

        <!-- Property -->
        <f7-list-item
          v-show="currentEstimate.businessCode === BUSINESS_CODE_COMMERCIAL"
        >
          <div
            class="list-item-inner-start"
            slot="inner-start"
          >
            Property Name
          </div>
          <div
            class="list-item-title"
            slot="title"
          >
            <span>
              {{ currentEstimate.propertyName || '' }}
            </span>
          </div>
          <input-icon
            slot="media"
            icon="building_2_fill"
          ></input-icon>
        </f7-list-item>

        <f7-list-item
          v-show="
            currentEstimate.businessCode === BUSINESS_CODE_SERVICE ||
            currentEstimate.businessCode === BUSINESS_CODE_RESIDENTIAL
          "
        >
          <div
            class="list-item-inner-start"
            slot="inner-start"
          >
            Contact Name
          </div>
          <div
            class="list-item-title"
            slot="title"
          >
            {{ currentEstimate.contactName || '' }}
          </div>
          <input-icon
            slot="media"
            icon="building_2_fill"
          ></input-icon>
        </f7-list-item>
      </f7-list>

      <!-- popups -->

      <project-list-popup
        ref="selectProject"
        :queryFilters="queryFilters"
        @onSelected="onSelectedProject"
      ></project-list-popup>
    </f7-page>
  </f7-popup>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
import ProjectListPopup from '@/components/popups/ProjectListPopup.vue';

import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import Vue from 'vue';
import mixins from '../../utility/mixins';

import {
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
  BUSINESS_CODE_RESIDENTIAL,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';

export default {
  components: {
    ProjectListPopup,
    InputIcon,
  },

  mixins: [mixins],

  data() {
    return {
      opened: false,
      currentEstimate: {
        projectId: '',
        propertyId: '',
        buildingOrSection: '',
        estimateName: '',
        businessCode: '',
      },

      BUSINESS_CODE_COMMERCIAL,
      BUSINESS_CODE_SERVICE,
      BUSINESS_CODE_RESIDENTIAL,
      currentProject: {},
    };
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {
    currentEstimate: {
      projectId: {
        required,
      },
    },
  },

  callback: null,

  computed: {
    ...mapGetters('estimate/estimate-page/project', [
      'project',
      'latestProject',
      'projectById',
    ]),

    projectErrorMessage() {
      if (!this.v$.currentEstimate.$error) return '';
      if (this.v$.currentEstimate.projectId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    queryFilters() {
      let estimateFilter = '';

      if (this.$f7route.params.clientType === 'contact') {
        estimateFilter += `contactId:${this.$f7route.params.clientId}`;
      } else if (this.$f7route.params.clientType === 'company') {
        estimateFilter += `companyId:${this.$f7route.params.clientId}`;
      }
      if (this.currentEstimate.businessCode) {
        estimateFilter += ` AND projectBusinessCode:${this.currentEstimate.businessCode}`;
      }

      const queryFilter = `${estimateFilter}`;

      return queryFilter;
    },
  },

  methods: {
    ...mapActions('estimate/estimate-page/project', [
      'bindProjectListBy',
      'unbindProjectList',
      'getProject',
    ]),

    ...mapActions('estimate/estimate-page/contact', ['getContactById']),
    ...mapActions('estimate/estimate-page/company', ['getCompanyById']),

    ...mapActions('estimate/estimate-page/property', ['getPropertyById']),

    async onSelectedProject(projectId = '') {
      const project = await this.getProject(projectId);
      this.currentProject = project;
      if (!project) {
        return;
      }

      this.setEstimateValue('projectId', projectId);
      this.setEstimateValue('projectName', project.title);
      this.setEstimateValue('projectNumber', project.cardNumber);

      if (project.contactId) {
        this.getContactById(project.contactId).then(contact => {
          if (contact) {
            this.setEstimateValue('contactId', contact.id);
            this.setEstimateValue(
              'contactName',
              (
                (contact.firstName || '') +
                ' ' +
                (contact.lastName || '')
              ).trim()
            );
          }
        });
      }
      if (project.companyId) {
        this.getCompanyById(project.companyId).then(company => {
          if (company) {
            this.setEstimateValue('companyId', company.id);
            this.setEstimateValue('companyName', company.companyName || '');
          }
        });
      }

      if (project.propertyId) {
        this.getPropertyById(project.propertyId).then(property => {
          if (property) {
            this.setEstimateValue('propertyId', property.id);
            this.setEstimateValue('propertyName', property.propertyName || '');
            this.setEstimateValue('estimateName', property.propertyName || '');
          }
        });
      } else {
        this.setEstimateValue('estimateName', project ? project.title : '');
      }
    },

    setEstimateValue(prop, value) {
      Vue.set(this.currentEstimate, prop, value);
    },

    add() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      const data = {
        ...this.currentEstimate,
      };
      this.cancel(true);
      this.callback && this.callback(data);
    },

    cancel(ignoreCallback) {
      this.currentEstimate = {
        projectId: '',
        propertyId: '',
        contactId: '',
      };
      !ignoreCallback && this.callback && this.callback();
      this.opened = false;
    },

    selectProject() {
      this.$el.querySelector('.job-title input').blur();
      this.$refs.selectProject.open();
    },

    openPopup({ businessCode }) {
      this.currentEstimate.businessCode = businessCode;
      this.opened = true;
      return new Promise(resolve => {
        this.callback = resolve;
      });
    },
  },
  beforeDestroy() {
    this.currentProject = {};
  },
};
</script>

<style lang="scss" scoped>
.md .status-icon {
  margin-bottom: 16px;
}

.md .desc-icon {
  margin-bottom: 80px;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}
</style>
