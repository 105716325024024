<template>
  <f7-popup
    class="demo-popup"
    :opened="isShow"
    @popup:closed="cancel"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{ isEdit ? 'Edit Pay Rate' : 'Add New Pay Rate' }}
        </f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="isEdit ? edit() : add()">
            {{ isEdit ? 'Save' : 'Done' }}
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <f7-list-item>
          <div
            class="list-item-container"
            slot="title"
          >
            <div class="list-item-title">
              New Pay Rate
              <required-asterisk slot="label"></required-asterisk>
            </div>
            <input-price
              ref="inputPrice"
              :price="Number(currentPayRate.payRate)"
              :allowZero="false"
              :isResetData="!isShow"
              @input="changePayRateEdit('regular', $event)"
            />
          </div>
        </f7-list-item>

        <f7-list-item>
          <div
            class="list-item-container"
            slot="title"
          >
            <div
              class="list-item-title"
              slot="title"
            >
              New Pay Rate Overtime<required-asterisk
                slot="label"
              ></required-asterisk>
            </div>
            <input-price
              ref="inputPriceOvertime"
              :price="Number(currentPayRate.payRateOvertime)"
              :allowZero="false"
              :isResetData="!isShow"
              @input="changePayRateEdit('overtime', $event)"
            />
          </div>
        </f7-list-item>
      </f7-list>
      <f7-list inline-labels>
        <f7-list-input
          type="datepicker"
          label="Effective Date"
          placeholder="mm/dd/yyyy"
          error-message-force
          validate
          validate-on-blur
          :value="currentPayRate.effectiveDate"
          :error-message="effectiveDateErrorMessage"
          :calendar-params="{
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            minDate: isEdit && [new Date()],
            dateFormat: 'mm/dd/yyyy',
          }"
          @calendar:change="
            currentPayRate.effectiveDate = $event;
            $f7.calendar.close();
          "
        ></f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { tomorrow } from '@/utility/date-time-tool';
import InputPrice from '@/components/inputs/InputPrice.vue';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    InputPrice,
  },

  props: {
    isShow: Boolean,
    isEdit: Boolean,
  },

  data: () => {
    return {
      currentPayRate: {
        payRate: 0,
        payRateOvertime: 0,
        effectiveDate: [tomorrow()],
      },
    };
  },

  methods: {
    ...mapActions('time-tracking/pay-rate', ['createPayRate', 'updatePayRate']),
    cancel() {
      this.currentPayRate = {
        payRate: 0,
        payRateOvertime: 0,
        effectiveDate: [tomorrow()],
      };
      this.v$.$reset();
      this.$emit('close');
    },
    changePayRateEdit(payType, value) {
      switch (payType) {
        case 'regular':
          this.currentPayRate.payRate = value;
          this.currentPayRate.payRateOvertime = value * 1.5;
          break;

        case 'overtime':
          this.currentPayRate.payRateOvertime = value;
          break;
      }
    },

    validatePayRate() {
      let found = null;
      const { effectiveDate } = this.currentPayRate;
      if (!this.isEdit) {
        found = this.payRateList.find(pr => {
          return pr.effectiveDate.getTime() === effectiveDate[0].getTime();
        });
      } else {
        const payRates = this.payRateList.filter(i => i.id !== this.payRateId);
        found = payRates.find(pr => {
          return pr.effectiveDate.getTime() === effectiveDate[0].getTime();
        });
      }

      if (!_.isEmpty(found)) {
        this.$ri.dialog.openWarningDialog({
          title: 'Please choose another one',
          content: 'That date has already existed!',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      }

      return !_.isEmpty(found);
    },

    add() {
      this.v$.$touch();
      this.$refs.inputPrice.validate();
      this.$refs.inputPriceOvertime.validate();
      const { payRate, payRateOvertime, effectiveDate } = this.currentPayRate;
      if (
        this.v$.$invalid ||
        this.validatePayRate() ||
        payRate <= 0 ||
        payRateOvertime <= 0
      ) {
        return;
      } else {
        const data = {
          userId: this.$f7route.params.id,
          payRate,
          payRateOvertime,
          effectiveDate: this.$google.firebase.firestore.Timestamp.fromDate(
            effectiveDate[0]
          ),
        };
        this.createPayRate(data).then(() => this.cancel());
      }
    },
    edit() {
      this.v$.$touch();
      this.$refs.inputPrice.validate();
      this.$refs.inputPriceOvertime.validate();
      const { payRate, effectiveDate, payRateOvertime } = this.currentPayRate;
      if (
        this.v$.$invalid ||
        this.validatePayRate() ||
        payRate <= 0 ||
        payRateOvertime <= 0
      ) {
        return;
      } else {
        const data = {
          payRate,
          payRateOvertime,
          effectiveDate: this.$google.firebase.firestore.Timestamp.fromDate(
            effectiveDate[0]
          ),
        };
        this.updatePayRate({
          id: this.payRateId,
          doc: data,
        }).then(() => this.cancel());
      }
    },
    handlePopupOpened() {
      if (!this.isEdit && this.$device.desktop) {
        this.$refs.inputPrice.$el.querySelector('input').focus();
      }
    },
  },

  computed: {
    ...mapGetters('time-tracking/pay-rate', [
      'payRateList',
      'payRate',
      'payRateId',
    ]),
    effectiveDateErrorMessage() {
      if (this.v$.currentPayRate.effectiveDate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    currentPayRateErrorMessage() {
      if (this.v$.currentPayRate.payRate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.currentPayRate.payRate.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      return null;
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      currentPayRate: {
        effectiveDate: {
          required,
        },
      },
    };
  },

  watch: {
    isEdit(val) {
      if (val && !_.isEmpty(this.payRate)) {
        this.currentPayRate = {
          ...this.payRate,
          effectiveDate: [this.payRate.effectiveDate.toDate()],
        };
      }
    },
  },
};
</script>
<style scoped>
.list-item-title {
  font-weight: 400;
}
.list-item-container {
  display: grid;
  grid-template-columns: minmax(100px, 250px) 1fr;
  align-items: center;
  column-gap: 30px;
}

input-price {
  justify-self: end;
}
</style>
