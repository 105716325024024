import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';
export default tenant => {
  const TemplateActions = new FirebaseActions(
    `/system_client/${tenant}/swimlane_board`,
    'object'
  );

  return {
    create: TemplateActions.createDocument,
    update: TemplateActions.updateDocument,
    delete: TemplateActions.deleteDocument,

    getBys: TemplateActions.getDocumentBys,

    getBoardBys({ dispatch, commit }, conditions) {
      return dispatch('getBys', conditions).then(res => {
        commit(types.SET_BOARD_LIST, res);
        return res;
      });
    },

    resetBoard({ commit }) {
      commit(types.SET_BOARD_LIST, []);
    },
  };
};
