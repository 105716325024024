import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const activityActions = new FirebaseActions(
    `/system_client/${tenant}/activity`,
    'activity'
  );
  return {
    // bindActivityList: activityActions.bindCollection,
    unbindActivityList: activityActions.unbindCollection,
    createActivity: activityActions.createDocument,
    getMoreActivity: activityActions.getMorePage,
    resetActivityPage({ commit }) {
      commit(types.RESET_PAGE);
    },
  };
};
