<template>
  <f7-popup
    :opened="emailPopupOpened"
    @popup:closed="emailPopupOpened = false"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="emailPopupOpened = false">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Email Details</f7-nav-title>
        <f7-nav-right>
          <!-- <f7-link @click.native="onSend">Send</f7-link> -->
        </f7-nav-right>
      </f7-navbar>
      <f7-list>
        <f7-list-input
          label="To"
          floating-label
          type="textarea"
          resizable
          readonly
          :value="email.receiver"
          ><input-icon
            slot="media"
            icon="envelope"
          ></input-icon
        ></f7-list-input>
        <f7-list-input
          label="Cc"
          floating-label
          type="textarea"
          resizable
          readonly
          :value="email.cc"
          ><input-icon
            slot="media"
            icon="square_on_square"
          ></input-icon
        ></f7-list-input>
        <!-- subject -->
        <f7-list-input
          label="Subject"
          floating-label
          type="textarea"
          resizable
          readonly
          :value="email.subject"
          ><input-icon
            slot="media"
            icon="square_favorites_alt"
          ></input-icon
        ></f7-list-input>

        <!-- <f7-list-input
          label="Message"
          floating-label
          type="texteditor"
          readonly
          :text-editor-params="{
            buttons: []
          }"
          resizable
          :value="email.content ? email.content.message : ''"
          ><input-icon slot="media" icon="text_quote"></input-icon
        ></f7-list-input> -->
      </f7-list>
      <f7-block-header v-if="showMessage">Message</f7-block-header>
      <f7-list inset>
        <f7-list-item
          class="padding"
          v-html="email.content"
        ></f7-list-item>
      </f7-list>
      <f7-block-header v-if="showAttachments">Attachments</f7-block-header>
      <f7-list>
        <!-- Main list item -->
        <f7-list-item
          v-for="(item, index) in email.attachments || []"
          :key="index"
          swipeout
          text-color="blue"
        >
          <a
            class="cursor-pointer"
            slot="title"
            @click="openLink(item.url)"
          >
            {{ item.fileName }}
          </a>
          <div slot="footer">
            Uploaded on {{ toDisplayDateString(item.createdAt) }} by
            {{ item.createdBy }}
          </div>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { toDisplayDateString } from '../../../../utility/datetime';
import InputIcon from '../icons/InputIcon.vue';

export default {
  components: { InputIcon },

  props: {
    value: { type: Object, default: () => {} },
    title: String,
    emailType: String,
    projectId: String,
    defaultSubject: String,
    defaultEmailAddress: String,
    attachmentTitle: { type: String, default: 'Attachments' },
    useAttachment: { type: Boolean, default: false },
    attachments: { type: Array, default: () => [] },
  },

  data: () => {
    return {
      emailPopupOpened: false,
      email: {},
    };
  },

  computed: {
    showMessage() {
      return this.email.content ? true : false;
    },

    showAttachments() {
      return this.email.attachments && this.email.attachments.length > 0
        ? true
        : false;
    },
  },

  methods: {
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    openLink(url) {
      window.open(url, '_blank');
    },

    // clearData() {
    //   this.emailAddress = ";";
    //   this.subject = "";
    //   this.message = "";
    //   this.attachmentFiles = [];
    // },

    show(data) {
      this.email = data;
      this.emailPopupOpened = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
