import Vue from 'vue';

export default {
  setInvoiceList(state, payload) {
    Vue.set(state, 'invoiceList', payload);
  },
  setInvoiceSubscribe(state, payload) {
    Vue.set(state, 'invoiceSubscribe', payload);
  },
};
