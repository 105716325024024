import project from './project';
import board from './board';
import column from './column';
import photo from './photo';
import album from './album';
import client from './client';
import appConstant from './app-constant';

export default tenant => {
  return {
    namespaced: true,
    modules: {
      'app-constant': appConstant(tenant),
      project: project(tenant),
      client: client(tenant),
      photo: photo(tenant),
      album: album(tenant),
      board: board(tenant),
      column: column(tenant),
    },
  };
};
