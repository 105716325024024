/* eslint-disable no-unused-vars */
import {
  FirebaseActions,
  StorageActions,
} from '../../../../services/firebase.service';
import { searchClient } from '../../../../services/search.service';
import {
  COLLECTION_PRODUCT_PRODUCT_ITEMS,
  PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
} from '../../../../utility/const';
import * as types from './types';
import _ from 'lodash';

export default tenant => {
  const productActions = new FirebaseActions(
    `/system_client/${tenant}/product_product_items`,
    'product'
  );

  const vendorAction = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    'vendor'
  );
  const categoryActions = new FirebaseActions(
    `/system_client/${tenant}/product_category`,
    'category'
  );
  const subCategoryActions = new FirebaseActions(
    `/system_client/${tenant}/product_sub_category`,
    'subCategory'
  );

  const productItemIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    getVendor: vendorAction.getDocument,
    getCategory: categoryActions.getDocument,
    getSubCategory: subCategoryActions.getDocument,

    getProduct: productActions.getDocument,
    getProductList: productActions.getDocumentBys,
    addProduct: productActions.createDocument,
    saveProduct: productActions.updateDocument,

    async composeDataProduct({ dispatch }, doc) {
      let product = _.cloneDeep(doc);
      if (product.vendorId) {
        const vendor = await dispatch('getVendor', product.vendorId);
        product.vendorName = vendor ? vendor.companyName || '' : '';
      }
      if (product.categoryId) {
        const category = await dispatch('getCategory', product.categoryId);
        product.categoryName = category ? category.name || '' : '';
      }
      if (product.subCategoryId) {
        const subCategory = await dispatch(
          'getSubCategory',
          product.subCategoryId
        );
        product.subCategoryName = subCategory ? subCategory.name || '' : '';
      }
      return product;
    },

    async createProduct({ dispatch }, data) {
      const newProduct = await dispatch('composeDataProduct', data);
      const id = await dispatch('addProduct', newProduct);
      return id;
    },

    async updateProduct({ dispatch }, { id, doc }) {
      const newProduct = await dispatch('composeDataProduct', doc);
      return dispatch('saveProduct', { id, doc: newProduct });
    },

    populateProductList({ commit, dispatch }, productRefs = []) {
      commit(types.CLEAN_UP_PRODUCT_ITEMS);
      for (const ref of productRefs) {
        dispatch('getProduct', ref.productId || ' ').then(product => {
          if (product) {
            product.price = ref.price;
            commit(types.ADD_PRODUCT_ITEM, product);
          }
        });
      }
    },

    async getActiveProductItemsByPriceListId(
      { rootGetters, commit, dispatch, getters, rootState, state },
      priceListId
    ) {
      const currentBuilding =
        rootGetters['estimate/estimate-page/estimate/currentBuilding'];
      const vendorIds = currentBuilding.vendorIds || [];

      const conditions = [
        {
          prop: 'priceListRefs',
          val: priceListId,
          op: 'array-contains',
        },
        {
          prop: 'status',
          val: 'pi-active',
          op: '==',
        },
      ];

      const results = [];
      if (!_.isEmpty(vendorIds)) {
        for (let i = 0; i < vendorIds.length; i += 10) {
          const vendorIdsChunk = vendorIds.slice(i, i + 10);
          const queryConditions = [
            ...conditions,
            {
              prop: 'vendorId',
              val: vendorIdsChunk,
              op: 'in',
            },
          ];
          const res = await dispatch('getProductList', queryConditions);
          results.push(...res);
        }
      } else {
        const res = await dispatch('getProductList', conditions);
        results.push(...res);
      }

      commit(types.ADD_PRODUCT_LIST, results);

      return results;
    },

    // eslint-disable-next-line no-unused-vars
    async uploadPhoto({ commit }, fileData) {
      const base64Data = fileData.dataUrl;
      const file = fileData.info;
      const name = +new Date() + file.name || '';
      const fullPath = `product/photos/${name}`;
      return StorageActions.uploadFileBase64(fullPath, base64Data, file);
    },
    copyPhoto({ commit }, photo) {
      const toPath = `product/photos/${Date.now()}-${photo.name}`;
      return StorageActions.copyFile(photo.fullPath, toPath);
    },
    searchProduct(
      { state, commit, rootGetters },
      { query, attributesToRetrieve, restrictSearchableAttributes, filters }
    ) {
      const currentBuilding =
        rootGetters['estimate/estimate-page/estimate/currentBuilding'];

      const index = productItemIndexAsc;
      const requestOptions = {
        hitsPerPage: state.numberOfRow,
        cacheable: false,
        filters: `entityName:${COLLECTION_PRODUCT_PRODUCT_ITEMS}`,
        attributesToRetrieve: PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          'productItem',
          'manufacturer',
          'sku',
          'price',
          'colorPhotos.colorName',
          'vendorName',
          'categoryName',
          'subCategoryName',
        ],
      };
      if (!_.isEmpty(attributesToRetrieve)) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes =
          restrictSearchableAttributes;
      }

      if (!_.isEmpty(currentBuilding.vendorIds) && filters) {
        let vendorIdFilter = '';

        vendorIdFilter = 'AND ( ';
        for (let i = 0; i < currentBuilding.vendorIds.length; i++) {
          vendorIdFilter += `vendorId:${currentBuilding.vendorIds[i]}`;
          if (i < currentBuilding.vendorIds.length - 1) {
            vendorIdFilter += ' OR ';
          }
        }
        vendorIdFilter += ' )';

        requestOptions.filters += ` AND ${filters} ${vendorIdFilter}`;
      } else if (_.isEmpty(currentBuilding.vendorIds) && filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index
        .search(query, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    goToPage(
      { state, commit, rootGetters },
      {
        pageName,
        query,
        attributesToRetrieve,
        filters,
        restrictSearchableAttributes,
      }
    ) {
      const currentBuilding =
        rootGetters['estimate/estimate-page/estimate/currentBuilding'];
      const index = productItemIndexAsc;

      let pageNumber = 0;
      switch (pageName) {
        case 'next':
          pageNumber = state.page + 1;
          break;

        case 'prev':
          pageNumber = state.page - 1;
          break;

        case 'first':
          pageNumber = 0;
          break;

        case 'last':
          pageNumber = state.nbPages - 1;
          break;

        default:
          pageName = 0;
          break;
      }

      const requestOptions = {
        hitsPerPage: state.numberOfRow,
        page: pageNumber,
        cacheable: false,
        filters: `entityName:${COLLECTION_PRODUCT_PRODUCT_ITEMS}`,
        attributesToRetrieve: PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          'productItem',
          'manufacturer',
          'sku',
          'price',
          'colorPhotos.colorName',
          'vendorName',
          'categoryName',
          'subCategoryName',
        ],
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (!_.isEmpty(currentBuilding.vendorIds) && filters) {
        let vendorIdFilter = '';

        vendorIdFilter = 'AND ( ';
        for (let i = 0; i < currentBuilding.vendorIds.length; i++) {
          vendorIdFilter += `vendorId:${currentBuilding.vendorIds[i]}`;
          if (i < currentBuilding.vendorIds.length - 1) {
            vendorIdFilter += ' OR ';
          }
        }
        vendorIdFilter += ' )';

        requestOptions.filters += ` AND ${filters} ${vendorIdFilter}`;
      } else if (_.isEmpty(currentBuilding.vendorIds) && filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes =
          restrictSearchableAttributes;
      }

      return index
        .search(query, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setProductNumberOfRows({ commit }, value) {
      commit(types.SET_NUMBER_OF_ROW, value);
    },

    setSearchText({ commit }, searchText = '') {
      commit(types.SET_SEARCH_TEXT, searchText);
    },
  };
};
