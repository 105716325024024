import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const paymentRecordActions = new FirebaseActions(
    `/system_client/${tenant}/payment_record`,
    'paymentRecord'
  );

  return {
    bindPaymentRecordList: paymentRecordActions.bindCollection,
    bindPaymentRecordListBy: paymentRecordActions.bindCollectionBy,
    unbindPaymentRecordList: paymentRecordActions.unbindCollection,

    bindPaymentRecord: paymentRecordActions.bindDocument,
    unbindPaymentRecord: paymentRecordActions.unbindDocument,

    updatePaymentRecord: paymentRecordActions.updateDocument,
    deletePaymentRecord: paymentRecordActions.deleteDocument,
    getPaymentRecordById: paymentRecordActions.getDocument,
  };
};
