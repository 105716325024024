import {
  FirebaseActions,
  StorageActions,
} from '../../../../services/firebase.service';
import * as constant from '../../../../utility/const';
import * as types from './types';

export default tenant => {
  const agreementActions = new FirebaseActions(
    `/system_client/${tenant}/agreement`,
    'agreement'
  );

  return {
    bindAgreementListBys: agreementActions.bindCollectionBys,
    unbindAgreementListBy: agreementActions.unbindCollection,
    bindAgreement: agreementActions.bindDocument,
    unbindAgreement: agreementActions.unbindDocument,
    createAgreement: agreementActions.createDocument,
    updateAgreement: agreementActions.updateDocument,
    getAgreement: agreementActions.getDocument,
    getAgreementBys: agreementActions.getDocumentBys,

    async getAgreementsByProjectId({ dispatch, commit }, projectId) {
      const list = await dispatch('getAgreementBys', [
        {
          prop: 'projectId',
          val: projectId,
          op: '==',
        },
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);

      commit(types.SET_AGREEMENT_LIST, list);
      return list;
    },
    deleteAgreement({ dispatch }, id) {
      return dispatch('updateAgreement', {
        id,
        doc: { [constant.PROP_IS_DELETED]: true },
      });
    },

    // eslint-disable-next-line no-unused-vars
    uploadImage({ commit }, { projectId, agreementId, base64Data, fileName }) {
      const name = `${+new Date()}_${fileName}`;
      const fullPath = `projects/${projectId}/agreements/${agreementId}/${name}`;
      return StorageActions.uploadFileBase64(fullPath, base64Data, {});
    },
  };
};
