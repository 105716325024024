import * as types from './types';
import Vue from 'vue';

export default {
  editScheduleInfomation(state, data) {
    state.scheduleInfomation = state.scheduleInfomation.map(x => {
      if (x.id === data.id) {
        x[data.colunm] = data.value;
        return x;
      }
      return x;
    });
  },

  addScheduleInfomation(state, data) {
    state.scheduleInfomation.push(data);
  },

  removeScheduleInfomation(state, item) {
    state.scheduleInfomation = state.scheduleInfomation.filter(
      x => x.id !== item.id
    );
  },

  changeMobilization(state, val) {
    Vue.set(state, 'mobilization', val);
  },

  changeTax(state, val) {
    Vue.set(state, 'tax', val);
  },

  [types.SET_SCHEDULE_INFO](state, schedule) {
    Vue.set(
      state,
      'scheduleInfomation',
      schedule.scheduleInfomation || [
        {
          id: new Date().getTime(),
          name: 'Project Cost by Roof Type',
        },
        {
          id: new Date().getTime() + 1,
          name: 'Percentage',
          isInputPercent: true,
        },
        {
          id: 1,
          name: 'Building 1',
        },
        {
          id: 2,
          name: 'Building 2',
        },
      ]
    );
    Vue.set(state, 'mobilization', schedule.mobilization || null);
    Vue.set(state, 'tax', schedule.tax || null);
    Vue.set(state, 'scheduleColunms', schedule.scheduleColunms || []);
  },
};
