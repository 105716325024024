import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line no-unused-vars
// import createLogger from "vuex/dist/logger";
import { vuexfireMutations } from 'vuexfire';

Vue.use(Vuex);
const debug = process.env.NODE_ENV === 'development';

// common store
import common from '../stores';

// Setting
import setting from '../plugins/settings/store';

// Dashboard
import dashboard from '../plugins/dashboard/store';
// Admin
import administration from '../plugins/administration/store';
// Price-list
import priceList from '../plugins/price-list/store';
// Estimate
import estimate from '../plugins/estimate/store';

import proposal from '../plugins/proposal/store';
// Schedule
import schedule from '../plugins/schedule/store';
// Time tracking
import timeTracking from '../plugins/time-tracking/store';
//Products
import product from '../plugins/products/store';
// swimlane
import swimlane from '../plugins/swimlane/store';
// Photos
import photo from '../plugins/photos/store';
// Quickbooks
import quickbooks from '../plugins/quickbooks/store';
// Expense
import expense from '../plugins/expenses/store';
// Expense
import invoices from '../plugins/invoices/store';
// contact book
import contactBook from '../plugins/contact-book/store';
// Scheduling
import scheduling from '../plugins/scheduling/store';
// Report
import reports from '../plugins/reports/store';
// Purchase Order
import purchaseOrder from '../plugins/purchase-order/store';
// Metal Sheet
// import metalSheet from "../plugins/metal-sheet/store";

import contactInfo from '../plugins/web-contact/store';
import safetyHub from '../stores/safety-hub/index';
import myCertificates from '../stores/my-certificates';
export default tenant => {
  return new Vuex.Store({
    strict: debug,
    // plugins: debug ? [createLogger()] : [],
    modules: {
      dashboard: dashboard(tenant),
      setting: setting(tenant),
      administration: administration(tenant),
      'price-list': priceList(tenant),
      estimate: estimate(tenant),
      proposal: proposal(tenant),
      'time-tracking': timeTracking(tenant),
      schedule: schedule(tenant),
      product: product(tenant),
      photo: photo(tenant),
      quickbooks: quickbooks(tenant),
      expense: expense(tenant),
      invoices: invoices(tenant),
      swimlane: swimlane(tenant),
      'contact-book': contactBook(tenant),
      scheduling: scheduling(tenant),
      reports: reports(tenant),
      'purchase-order': purchaseOrder(tenant),
      // "metal-sheet": metalSheet(tenant),
      'web-contact': contactInfo(tenant),
      common: common(tenant),
      'safety-hub': safetyHub(tenant),
      'my-certificates': myCertificates(tenant),
    },
    mutations: {
      ...vuexfireMutations,
    },
  });
};
