import * as types from './types';
import Vue from 'vue';
import _ from 'lodash';

export default {
  [types.SET_SCHEDULE_SETTING_LIST](state, payload) {
    Vue.set(state, 'scheduleSettingList', payload);
  },
  [types.SCHEDULE_SETTING_LIST_ITEM_UPDATE](state, { id, doc }) {
    const index = state.scheduleSettingList.findIndex(item => item.id === id);

    if (index >= 0) {
      const est = _.cloneDeep(state.scheduleSettingList[index]);

      for (const key in doc) {
        if (Object.hasOwnProperty.call(doc, key) && doc[key] !== undefined) {
          est[key] = doc[key];
        }
      }
      Vue.set(state.scheduleSettingList, index, est);
    }
  },

  [types.SCHEDULE_SETTING_LIST_ITEM_ADD](state, doc) {
    state.scheduleSettingList.push(doc);
  },

  [types.SCHEDULE_SETTING_LIST_ITEM_REMOVE](state, id) {
    const index = state.scheduleSettingList.findIndex(item => item.id === id);
    if (index >= 0) {
      state.scheduleSettingList.splice(index, 1);
    }
  },
};
