export default {
  objectList: [],
  detailId: null,
  objectSubscribe: null,
  filter: { title: null },

  userFilter: [],
  searchText: '',
  weekFilter: [],
  currentProject: {},
};
