// src/store/state.ts
export interface State {
  count: number;
  myCertificatesList: Array<any>;
  order: string;
  searchText: string;
  queryFilters: Array<any>;
  hitsPerPage: number;
  hits: Array<any>;
  nbHits: number;
  nbPages: number;
  page: number;
}

export const state: State = {
  count: 0,
  myCertificatesList: [],
  order: '',
  searchText: '',
  queryFilters: [],
  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0,
};
