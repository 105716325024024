<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Employee Time Logs</f7-nav-title>
      <f7-nav-right>
        <f7-link
          icon-f7="plus"
          @click="isShowAddPopup = true"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      class="search-list"
      disable-button-text
      placeholder="Search"
      :clear-button="true"
      :value="searchText"
      @input="searchText = $event.target.value"
      @searchbar:disable="searchText = ''"
    ></f7-searchbar>
    <div>
      <f7-list class="no-margin-top">
        <f7-list-item
          title="Week"
          :footer="showWeekRange"
        >
          <div
            slot="after"
            class="btn-container"
          >
            <f7-button
              small
              icon-size="14"
              icon-f7="arrowtriangle_left_fill"
              class="flex-center btn-container_prev"
              @click="prevWeek"
            ></f7-button>
            <f7-button
              small
              icon-size="20"
              icon-f7="calendar"
              class="btn-container_select"
              @click="selectCalendar"
            ></f7-button>
            <f7-button
              small
              icon-size="14"
              icon-f7="arrowtriangle_right_fill"
              class="flex-center btn-container_next"
              @click="nextWeek"
            ></f7-button>
          </div>
        </f7-list-item>
        <f7-list-item
          title="Filter by teams"
          smart-select
          :smart-select-params="{ openIn: 'popover', routableModals: false }"
          ref="teamFilterSmartSelect"
        >
          <select
            multiple
            @change="changeTeamFilter"
          >
            <option
              v-for="s in teamOptions"
              :key="s.id"
              :value="s.id"
            >
              {{ s.teamName }}
            </option>
          </select>
        </f7-list-item>
        <f7-list-item
          title="Filter by statuses"
          smart-select
          :smart-select-params="{ openIn: 'popover', routableModals: false }"
          ref="statusFilterSmartSelect"
        >
          <select
            multiple
            @change="changeStatusFilter"
          >
            <option
              v-for="s in timeTrackingStatusList"
              :key="s.id"
              :value="s.value"
            >
              {{ s.displayName }}
            </option>
          </select>
        </f7-list-item>
        <f7-list-item
          title="Filter by time log types"
          smart-select
          :smart-select-params="{ openIn: 'popover', routableModals: false }"
          ref="timeLogTypeFilterSmartSelect"
        >
          <select
            multiple
            @change="changeTimeLogTypeFilter"
          >
            <option
              v-for="s in timeLogTypeList"
              :key="s.id"
              :value="s.value"
              :selected="timeLogTypeFilter.includes(s.value)"
            >
              {{ s.displayName }}
            </option>
          </select>
        </f7-list-item>
        <f7-list-input
          placeholder="Filter by project"
          :clear-button="true"
          disable-button-text
          :value="projectFilter"
          @input="v => setProjectFilter(v.target.value)"
        ></f7-list-input>
      </f7-list>
      <f7-list class="no-margin">
        <f7-list-group media-list>
          <f7-list-item
            v-for="item in timeTrackingComputed"
            :key="item.id"
            :reload-detail="true"
            :title="item.displayName"
            :link="`/employees-time-logs/detail/${item.user_id}`"
            :text-color="item.user_id === detailUid ? 'primary' : 'default'"
            swipeout
          >
            <div slot="text">
              <div
                class="display-flex justify-content-space-between align-items-center"
              >
                Total Hours:
                <f7-chip
                  :text="`${formatDuration(item.totalHours)}`"
                  color="success"
                ></f7-chip>
              </div>
            </div>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <add-new-employee-time-tracking
        :isShow="isShowAddPopup"
        @close="isShowAddPopup = false"
      ></add-new-employee-time-tracking>

      <!-- calendar input hidden -->
      <input
        class="display-none"
        type="text"
        readonly="readonly"
        id="calendar-input"
      />
    </div>
  </f7-page>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { applyFilter } from '@/utility/filter-tools';
import { formatDuration } from '@/utility/datetime';
import AddNewEmployeeTimeTracking from '../components/popups/AddNewEmployeeTimeTracking.vue';
import moment from 'moment';
import _ from 'lodash';

import Menu from '../../../components/menu/Menu.vue';

function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

function getCurrentWeek() {
  const weekStart = moment().startOf('isoWeek');
  let days = [];
  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days').format('MM/DD/YYYY'));
  }
  return days;
}

export default {
  data() {
    return {
      searchText: '',
      isShowAddPopup: false,
      // currentWeek: getCurrentWeek(),
      currentDate: [new Date()],
      fromDate: [new Date(moment(new Date(), 'MM/DD/YYYY').subtract(6, 'd'))],
      toDate: [new Date()],
      formatDuration,
    };
  },

  methods: {
    ...mapActions('common/team-management', ['getTeamList']),
    ...mapActions('time-tracking/user', ['getUserList']),
    ...mapActions({
      setCurrentWeek: 'time-tracking/time-tracking/setCurrentWeek',
      getTimeTrackingList: 'time-tracking/time-tracking/getTimeTrackingList',
    }),
    ...mapMutations({
      setTeamFilter: 'time-tracking/time-tracking/setTeamFilter',
      setStatusFilter: 'time-tracking/time-tracking/setStatusFilter',
      setTimeLogTypeFilter: 'time-tracking/time-tracking/setTimeLogTypeFilter',
      setProjectFilter: 'time-tracking/time-tracking/setProjectFilter',
      setIsMyTimeLog: 'time-tracking/time-tracking/setIsMyTimeLog',
    }),
    ...mapActions('time-tracking/app-constant', ['getConstantTypeList']),
    ...mapActions('time-tracking/quickbooks', [
      'getClassQBList',
      'getServiceItemQBList',
    ]),
    ...mapActions('time-tracking/service-item-setting', [
      'getServiceItemSettingList',
    ]),

    changeTeamFilter() {
      const value = this.$refs.teamFilterSmartSelect.f7SmartSelect.getValue();
      this.setTeamFilter(value);
    },

    changeStatusFilter() {
      const value = this.$refs.statusFilterSmartSelect.f7SmartSelect.getValue();
      this.setStatusFilter(value);
    },
    changeTimeLogTypeFilter() {
      const value =
        this.$refs.timeLogTypeFilterSmartSelect.f7SmartSelect.getValue();
      this.setTimeLogTypeFilter(value);
    },
    bindDataByDate(currentWeek) {
      let from = new Date(currentWeek[0]);
      let to = new Date(
        moment(new Date(currentWeek[6]), 'MM/DD/YYYY').add(1, 'days')
      );
      return this.getTimeTrackingList([
        {
          prop: 'date',
          val: this.$google.firebase.firestore.Timestamp.fromDate(from),
          op: '>=',
        },
        {
          prop: 'date',
          val: this.$google.firebase.firestore.Timestamp.fromDate(to),
          op: '<',
        },
      ]);
    },
    selectCalendar() {
      this.$f7.calendar
        .create({
          inputEl: '#calendar-input',
          openIn: 'customModal',
          backdrop: true,
          cssClass: 'calendar-hover',
          value: this.currentDate,
          rangesClasses: [
            {
              cssClass: 'current-week',
              range: {
                from: new Date(this.currentWeek[0]),
                to: new Date(this.currentWeek[6]),
              },
            },
          ],
          on: {
            change: (calendar, value) => {
              if (
                !moment(this.currentDate[0]).isSame(moment(value[0]), 'day')
              ) {
                this.currentDate = value;
                const weekStart = moment(value[0], 'MM/DD/YYYY').startOf(
                  'isoWeek'
                );
                this.getCurrentWeekTimeLogs(weekStart);
                calendar.close();
              }
            },
          },
        })
        .open();
    },
    getCurrentWeekTimeLogs(weekStart) {
      this.$f7.preloader.show();
      let days = [];
      for (let i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, 'days').format('MM/DD/YYYY'));
      }
      // this.currentWeek = days;
      this.setCurrentWeek(days);
      this.bindDataByDate(this.currentWeek).then(() =>
        this.$f7.preloader.hide()
      );
    },
    prevWeek() {
      const weekStart = moment(this.currentWeek[0], 'MM/DD/YYYY')
        .subtract(1, 'weeks')
        .startOf('isoWeek');
      this.currentDate = [new Date(weekStart)];
      this.getCurrentWeekTimeLogs(weekStart);
    },
    nextWeek() {
      const weekStart = moment(this.currentWeek[0], 'MM/DD/YYYY')
        .add(1, 'weeks')
        .startOf('isoWeek');
      this.currentDate = [new Date(weekStart)];
      this.getCurrentWeekTimeLogs(weekStart);
    },
  },

  components: {
    AddNewEmployeeTimeTracking,
    Menu,
  },

  computed: {
    ...mapGetters('common/team-management', ['teamList']),
    ...mapGetters('time-tracking/user', ['userListByHours']),
    ...mapGetters({
      listUserTrackings: 'time-tracking/time-tracking/listUserTrackings',

      currentWeek: 'time-tracking/time-tracking/currentWeek',
      detailUid: 'time-tracking/time-tracking/detailUid',
      statusFilter: 'time-tracking/time-tracking/statusFilter',
      timeLogTypeFilter: 'time-tracking/time-tracking/timeLogTypeFilter',
      projectFilter: 'time-tracking/time-tracking/projectFilter',
    }),

    ...mapGetters('time-tracking/app-constant', [
      'timeTrackingStatusList',
      'timeLogTypeList',
    ]),

    ...mapGetters('setting/app/group', ['isAdminGroup']),

    teamOptions() {
      return (_.cloneDeep(this.teamList) || []).sort((a, b) =>
        sortBy(a.teamName, b.teamName)
      );
    },

    timeTrackingComputed() {
      return applyFilter([...this.listUserTrackings], this.searchText, [
        'displayName',
      ]);
    },

    showWeekRange() {
      if (this.currentWeek.length === 0) return 'Filter by week';
      const from = moment(this.currentWeek[0], 'MM/DD/YYYY').format('MMM Do');
      const to = moment(this.currentWeek[6], 'MM/DD/YYYY').format('MMM Do');
      return `${from} - ${to}`;
    },
  },

  created() {
    const promises = [];
    const self = this;
    this.$f7.preloader.show();

    return this.setCurrentWeek(getCurrentWeek()).then(() => {
      promises.push(self.bindDataByDate(self.currentWeek));
      promises.push(self.getUserList());
      promises.push(self.getTeamList());
      promises.push(self.setTeamFilter([]));
      promises.push(self.setStatusFilter([]));
      promises.push(self.setTimeLogTypeFilter([]));
      promises.push(self.setProjectFilter(''));
      promises.push(self.setIsMyTimeLog(false));
      promises.push(self.getConstantTypeList());
      promises.push(self.getClassQBList());
      promises.push(self.getServiceItemQBList());
      promises.push(self.getServiceItemSettingList());
      return Promise.all(promises).finally(() => {
        self.$f7.preloader.hide();
      });
    });
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list.searchbar input').focus();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.chang-language {
  padding-top: 20px;
}

.current-week {
  background-color: #000 !important;
}
</style>
