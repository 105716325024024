<template>
  <f7-popup
    class="demo-popup"
    :opened="isShow"
    @popup:closed="close"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Quickbook Integration Notitication</f7-nav-title>
      </f7-navbar>
      <f7-block>
        <f7-row>
          <!-- <div class="intro-content">
            Quickbook Integration Notitication
          </div> -->
          <ol style="padding-inline-start: 20px">
            <li>
              <p>
                Integration: To exchange data between RoofterIntel and
                Quickbook, the user needs to set up an integration between the
                two.
              </p>
            </li>
            <li>
              <p>
                Authentication: The user clicks on any Import/Export button and
                will be redirected to Quickbook authentication page to enter
                Quickbook username and password. The process takes approximately
                5 minutes to fully integrate.
              </p>
            </li>
          </ol>
        </f7-row>
      </f7-block>
      <div class="checkbox-item">
        <label class="checkbox"
          ><input
            type="checkbox"
            :checked="dontShowQBInstruction"
            @change="dontShowQBInstruction = !dontShowQBInstruction"
          /><i class="icon-checkbox"></i> </label
        ><span>Don't show again.</span>
      </div>
    </f7-page>
  </f7-popup>
</template>
<script>
export default {
  data: () => {
    return {
      isShow: false,
      dontShowQBInstruction: false,
    };
  },
  methods: {
    open() {
      this.isShow = true;
    },
    close() {
      if (this.dontShowQBInstruction) {
        localStorage.setItem(
          'dontShowQBInstruction',
          this.dontShowQBInstruction
        );
      }
      this.isShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  span {
    margin-left: 5px;
  }
}
.intro-content {
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
}
</style>
