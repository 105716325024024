import Vue from 'vue';
import {
  f7AccordionContent,
  f7AccordionItem,
  f7AccordionToggle,
  f7Accordion,
  f7ActionsButton,
  f7ActionsGroup,
  f7ActionsLabel,
  f7Actions,
  f7App,
  f7Appbar,
  f7Badge,
  f7BlockFooter,
  f7BlockHeader,
  f7BlockTitle,
  f7Block,
  f7Button,
  f7CardContent,
  f7CardFooter,
  f7CardHeader,
  f7Card,
  f7Checkbox,
  f7Chip,
  f7Col,
  f7FabButton,
  f7FabButtons,
  f7Fab,
  f7Gauge,
  f7Icon,
  f7Input,
  f7Link,
  f7ListButton,
  f7ListGroup,
  f7ListIndex,
  f7ListInput,
  f7ListItemCell,
  f7ListItemContent,
  f7ListItemRow,
  f7ListItem,
  f7List,
  f7LoginScreenTitle,
  f7LoginScreen,
  f7MenuDropdownItem,
  f7MenuDropdown,
  f7MenuItem,
  f7Menu,
  f7Message,
  f7MessagebarAttachment,
  f7MessagebarAttachments,
  f7MessagebarSheetImage,
  f7MessagebarSheetItem,
  f7MessagebarSheet,
  f7Messagebar,
  f7MessagesTitle,
  f7Messages,
  f7NavLeft,
  f7NavRight,
  f7NavTitleLarge,
  f7NavTitle,
  f7Navbar,
  f7PageContent,
  f7Page,
  f7Panel,
  f7PhotoBrowser,
  f7Popover,
  f7Popup,
  f7Preloader,
  f7Progressbar,
  f7Radio,
  f7Range,
  f7RoutableModals,
  f7Row,
  f7Searchbar,
  f7Segmented,
  f7Sheet,
  f7SkeletonBlock,
  f7SkeletonText,
  // f7Statusbar,
  f7Stepper,
  f7Subnavbar,
  f7SwipeoutActions,
  f7SwipeoutButton,
  f7SwiperSlide,
  f7Swiper,
  f7Tab,
  f7Tabs,
  f7TextEditor,
  f7Toggle,
  f7Toolbar,
  f7TreeviewItem,
  f7Treeview,
  f7View,
  f7Views,
} from 'framework7-vue';

Vue.component(f7Panel.name, f7Panel);
Vue.component(f7AccordionContent.name, f7AccordionContent);
Vue.component(f7AccordionItem.name, f7AccordionItem);
Vue.component(f7AccordionToggle.name, f7AccordionToggle);
Vue.component(f7Accordion.name, f7Accordion);
Vue.component(f7ActionsButton.name, f7ActionsButton);
Vue.component(f7ActionsGroup.name, f7ActionsGroup);
Vue.component(f7ActionsLabel.name, f7ActionsLabel);
Vue.component(f7Actions.name, f7Actions);
Vue.component(f7App.name, f7App);
Vue.component(f7Appbar.name, f7Appbar);
Vue.component(f7Badge.name, f7Badge);
Vue.component(f7BlockFooter.name, f7BlockFooter);
Vue.component(f7BlockHeader.name, f7BlockHeader);
Vue.component(f7BlockTitle.name, f7BlockTitle);
Vue.component(f7Block.name, f7Block);
Vue.component(f7Button.name, f7Button);
Vue.component(f7CardContent.name, f7CardContent);
Vue.component(f7CardFooter.name, f7CardFooter);
Vue.component(f7CardHeader.name, f7CardHeader);
Vue.component(f7Card.name, f7Card);
Vue.component(f7Checkbox.name, f7Checkbox);
Vue.component(f7Chip.name, f7Chip);
Vue.component(f7Col.name, f7Col);
Vue.component(f7FabButton.name, f7FabButton);
Vue.component(f7FabButtons.name, f7FabButtons);
Vue.component(f7Fab.name, f7Fab);
Vue.component(f7Gauge.name, f7Gauge);
Vue.component(f7Icon.name, f7Icon);
Vue.component(f7Input.name, f7Input);
Vue.component(f7Link.name, f7Link);
Vue.component(f7ListButton.name, f7ListButton);
Vue.component(f7ListGroup.name, f7ListGroup);
Vue.component(f7ListIndex.name, f7ListIndex);
Vue.component(f7ListInput.name, f7ListInput);
Vue.component(f7ListItemCell.name, f7ListItemCell);
Vue.component(f7ListItemContent.name, f7ListItemContent);
Vue.component(f7ListItemRow.name, f7ListItemRow);
Vue.component(f7ListItem.name, f7ListItem);
Vue.component(f7List.name, f7List);
Vue.component(f7LoginScreenTitle.name, f7LoginScreenTitle);
Vue.component(f7LoginScreen.name, f7LoginScreen);
Vue.component(f7MenuDropdownItem.name, f7MenuDropdownItem);
Vue.component(f7MenuDropdown.name, f7MenuDropdown);
Vue.component(f7MenuItem.name, f7MenuItem);
Vue.component(f7Menu.name, f7Menu);
Vue.component(f7Message.name, f7Message);
Vue.component(f7MessagebarAttachment.name, f7MessagebarAttachment);
Vue.component(f7MessagebarAttachments.name, f7MessagebarAttachments);
Vue.component(f7MessagebarSheetImage.name, f7MessagebarSheetImage);
Vue.component(f7MessagebarSheetItem.name, f7MessagebarSheetItem);
Vue.component(f7MessagebarSheet.name, f7MessagebarSheet);
Vue.component(f7Messagebar.name, f7Messagebar);
Vue.component(f7MessagesTitle.name, f7MessagesTitle);
Vue.component(f7Messages.name, f7Messages);
Vue.component(f7NavLeft.name, f7NavLeft);
Vue.component(f7NavRight.name, f7NavRight);
Vue.component(f7NavTitleLarge.name, f7NavTitleLarge);
Vue.component(f7NavTitle.name, f7NavTitle);
Vue.component(f7Navbar.name, f7Navbar);
Vue.component(f7PageContent.name, f7PageContent);
Vue.component(f7Page.name, f7Page);
Vue.component(f7Panel.name, f7Panel);
Vue.component(f7PhotoBrowser.name, f7PhotoBrowser);
Vue.component(f7Popover.name, f7Popover);
Vue.component(f7Popup.name, f7Popup);
Vue.component(f7Preloader.name, f7Preloader);
Vue.component(f7Progressbar.name, f7Progressbar);
Vue.component(f7Radio.name, f7Radio);
Vue.component(f7Range.name, f7Range);
Vue.component(f7RoutableModals.name, f7RoutableModals);
Vue.component(f7Row.name, f7Row);
Vue.component(f7Searchbar.name, f7Searchbar);
Vue.component(f7Segmented.name, f7Segmented);
Vue.component(f7Sheet.name, f7Sheet);
Vue.component(f7SkeletonBlock.name, f7SkeletonBlock);
Vue.component(f7SkeletonText.name, f7SkeletonText);
// Vue.component(f7Statusbar.name, f7Statusbar);
Vue.component(f7Stepper.name, f7Stepper);
Vue.component(f7Subnavbar.name, f7Subnavbar);
Vue.component(f7SwipeoutActions.name, f7SwipeoutActions);
Vue.component(f7SwipeoutButton.name, f7SwipeoutButton);
Vue.component(f7SwiperSlide.name, f7SwiperSlide);
Vue.component(f7Swiper.name, f7Swiper);
Vue.component(f7Tab.name, f7Tab);
Vue.component(f7Tabs.name, f7Tabs);
Vue.component(f7TextEditor.name, f7TextEditor);
Vue.component(f7Toggle.name, f7Toggle);
Vue.component(f7Toolbar.name, f7Toolbar);
Vue.component(f7TreeviewItem.name, f7TreeviewItem);
Vue.component(f7Treeview.name, f7Treeview);
Vue.component(f7View.name, f7View);
Vue.component(f7Views.name, f7Views);

import ImageUploader from 'vue-image-upload-resize';
Vue.use(ImageUploader);
