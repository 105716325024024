export default {
  proposalList: [],
  proposal: {},
  proposalNew: {
    proposalTemplateId: '',
    proposalName: '',
    status: 'draft',
    estimateId: '',
    buildingIDs: [],
  },
  imagesToDelete: [],
  financingProposalDefaultList: [],
  financingProposalList: [],
  order: 'desc',
  searchText: '',
  proposalType: 'proposal',
  isChangeProposalTypeAndNavigate: false,

  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0,
  paramsBack: {},
  queryBack: {},
  tempProposal: {},
  selectedStatusFilter: [],
};
