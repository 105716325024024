<template>
  <div>
    <f7-button
      class="flex-center"
      small
      :popover-open="`.${popoverClass}`"
    >
      <f7-icon
        class="icon"
        :size="iconSize"
        :f7="icon"
      ></f7-icon>
    </f7-button>
    <f7-popover
      :backdrop="false"
      style="width: 300px"
      :class="popoverClass"
    >
      <f7-list>
        <f7-list-item
          popover-close
          link
          @click.native="$emit('clickItem', 'thisShift')"
          title="This shift"
        ></f7-list-item>
        <f7-list-item
          popover-close
          link
          @click.native="$emit('clickItem', 'thisShiftAndAllRemaining')"
          title="This shift and all the remaining shifts"
        ></f7-list-item>
        <f7-list-item
          popover-close
          link
          @click.native="$emit('clickItem', 'allShifts')"
          title="All shifts"
        ></f7-list-item>
      </f7-list>
    </f7-popover>
  </div>
</template>
<script>
export default {
  props: {
    icon: String,
    iconSize: Number,
    popoverClass: String,
  },
};
</script>

<style lang="scss" scoped>
.icon {
  color: var(--f7-theme-color);
  font-size: var(--swimlane-icon-font-size);
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
