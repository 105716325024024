<template>
  <div>
    <f7-text-editor
      ref="textEditorResizable"
      class="no-margin"
      resizable
      :value="data.value"
      @texteditor:change="changeValue"
      :buttons="buttons"
    ></f7-text-editor>
  </div>
</template>

<script>
const buttons = [
  ['bold', 'italic', 'underline', 'textColor'],
  ['orderedList', 'unorderedList'],
  ['paragraph', 'h1', 'h2', 'h3'],
  ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
  ['subscript', 'superscript'],
  ['indent', 'outdent'],
];

export default {
  props: {
    data: Object,
  },
  data() {
    return {
      buttons,
    };
  },
  methods: {
    changeValue($event) {
      if (this.data.value === $event) return;
      var contanerElement =
        this.$refs.textEditorResizable.$el.getElementsByClassName(
          'text-editor-content'
        );
      var overFlowContent = this.isOverflown(contanerElement[0]);
      if (!overFlowContent) {
        this.$emit('change', { ...this.data, value: $event });
      } else {
        this.$refs.textEditorResizable.f7TextEditor.setValue(this.data.value);
        this.$emit('change', { ...this.data, isOverflow: true });
      }
    },
    isOverflown({ clientWidth, clientHeight, scrollWidth, scrollHeight }) {
      return scrollHeight > clientHeight || scrollWidth > clientWidth;
    },
  },
};
</script>

<style scoped></style>
