<template>
  <f7-popup
    class="address-popup"
    :opened="popupOpened"
    @popup:closed="closePopup"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left></f7-nav-left>
        <f7-nav-title>View Address</f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <iframe
        :src="viewableMapUrl"
        allowfullscreen
        frameborder="0"
        style="border: 0"
        width="100%"
        height="100%"
      ></iframe>
    </f7-page>
  </f7-popup>
</template>

<script>
export default {
  data() {
    return {
      address: null,
      popupOpened: false,
      iframeHeight: 400,
    };
  },
  fetchingTimer: null,
  computed: {
    viewableMapUrl() {
      return (
        `https://www.google.com/maps/embed/v1/place?key=${this.$google.maps.apiKey}` +
        `&q=${this.address}`
      );
    },
  },
  methods: {
    openPopup(address) {
      this.address = address;
      this.popupOpened = true;
    },
    closePopup() {
      this.address = null;
      this.popupOpened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.address-popup ::v-deep .page-content {
  overflow: hidden;
}
</style>
