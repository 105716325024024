export default {
  projectList: state => {
    return state.projectList;
  },

  project: state => state.project,

  projectById: state => projectId => {
    return state.projectList.find(project => project.id == projectId) || {};
  },

  projects:
    state =>
    (searchValue = '') => {
      let projectList =
        searchValue != ''
          ? state.projectList.filter(project => {
              let title = project.title ? project.title.toLowerCase() : '';
              let cardNumber = project.cardNumber
                ? project.cardNumber.toLowerCase()
                : '';
              return (
                (title + cardNumber).indexOf(searchValue.toLowerCase()) >= 0
              );
            }) || []
          : state.projectList;

      projectList = projectList.filter(
        ({ status }) => !status || !['cancel', 'close'].includes(status)
      );
      projectList.sort((a, b) => sortBy(b.createdAt, a.createdAt));

      return projectList;
    },
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
