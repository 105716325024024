<template>
  <f7-block
    strong
    class="no-margin display-flex justify-content-space-between width-100"
    style="gap: 8px; position: fixed; height: 53px"
  >
    <div>
      <b>{{ proposal.proposalNumber }}</b>
    </div>
    <div
      class="display-flex"
      style="gap: 16px"
    >
      <div @click="activeMenu('content')">
        <f7-icon
          size="18"
          class="cursor-pointer"
          f7="square_list"
          color="primary"
        ></f7-icon>
        <div
          v-show="isShowContent"
          class="dl-item"
        >
          <f7-list class="no-margin">
            <f7-list-item
              v-for="(section, index) in sortedSections"
              :key="index"
              :title="section.sectionName"
              class="section-item"
              link="#"
              :text-color="
                index === currentSectionIndex ? 'primary' : 'default'
              "
              @click="$emit('sectionClick', { section, index })"
            ></f7-list-item>
          </f7-list>
        </div>
      </div>
      <div>
        <div @click="activeMenu('detail')">
          <f7-icon
            size="18"
            class="cursor-pointer"
            f7="text_badge_checkmark"
            color="primary"
          ></f7-icon>
        </div>
        <div
          v-show="isShowDetail"
          class="dl-item"
        >
          <f7-list class="no-margin">
            <f7-list-input
              label="Proposal"
              :value="proposal.proposalNumber || ''"
              readonly
              @click.native="activeMenu('detail')"
            ></f7-list-input>

            <f7-list-input
              label="Project"
              :value="(proposal.project && proposal.project.title) || ''"
              readonly
              @click.native="activeMenu('detail')"
            ></f7-list-input>

            <f7-list-item
              title="Status"
              @click.native="activeMenu('detail')"
              ><f7-chip
                :text="onlineClass(proposal.status).text"
                :color="onlineClass(proposal.status).color"
              ></f7-chip
            ></f7-list-item>

            <f7-list-input
              label="Property"
              :value="
                (proposal.property && proposal.property.propertyName) || ''
              "
              readonly
              @click.native="activeMenu('detail')"
            ></f7-list-input>

            <f7-list-input
              label="Estimate"
              :value="
                (proposal.estimate &&
                  `${proposal.estimate.estimateNumber} - ${proposal.estimate.estimateName}`) ||
                ''
              "
              readonly
              @click.native="activeMenu('detail')"
            ></f7-list-input>

            <f7-list-input
              v-if="isServiceJob"
              label="Table Content Type"
              type="select"
              placeholder="Select Table Content Type"
              :value="proposal.tableContentType"
              @change="
                $emit('changeTableContentType', $event.target.value);
                activeMenu('detail');
              "
            >
              <option
                v-for="c in TABLE_CONTENT_TYPES"
                :key="c.value"
                :value="c.value"
              >
                {{ c.name }}
              </option>
            </f7-list-input>
          </f7-list>
        </div>
      </div>
      <div @click="activeMenu('history')">
        <f7-icon
          size="18"
          class="cursor-pointer"
          f7="clock"
          color="primary"
        ></f7-icon>
        <div
          v-show="isShowHistory"
          class="dl-item"
        >
          <activity-list></activity-list>
        </div>
      </div>
      <div @click="activeMenu('action')">
        <f7-icon
          size="18"
          class="cursor-pointer"
          f7="square_arrow_right"
          color="primary"
        ></f7-icon>
        <div
          v-show="isShowAction"
          class="dl-item"
        >
          <f7-list class="no-margin">
            <f7-list-item
              v-if="proposal.status !== 'sent' && proposal.status !== 'viewed'"
              class="action-link"
              link="#"
              title="Mark as Sent"
              @click="$emit('markAsSentProposal')"
            ></f7-list-item>
            <f7-list-item
              v-if="proposal.status !== 'sent' && proposal.status !== 'viewed'"
              link="#"
              class="action-link"
              title="Send Email"
              @click="$emit('sendEmailProposal')"
            ></f7-list-item>

            <f7-list-item
              v-if="proposal.status !== 'withdraw'"
              link="#"
              class="action-link"
              title="Withdraw"
              @click="$emit('withdrawProposal')"
            ></f7-list-item>
          </f7-list>
        </div>
      </div>
      <div @click="$emit('onPreview')">
        <f7-icon
          size="18"
          class="cursor-pointer"
          f7="eye"
          color="primary"
        ></f7-icon>
      </div>
      <div @click="$emit('onCopy')">
        <f7-icon
          size="18"
          class="cursor-pointer"
          f7="doc_on_doc"
          color="primary"
        ></f7-icon>
      </div>
      <div
        v-if="
          proposal.status === 'draft' && !proposal.companyRepresentativeSigner
        "
        @click="$emit('openSignaturePopup')"
      >
        <f7-icon
          size="18"
          class="cursor-pointer"
          f7="signature"
          color="primary"
        ></f7-icon>
      </div>
    </div>
  </f7-block>
</template>

<script>
import { TABLE_CONTENT_TYPES, BUSINESS_CODE_SERVICE } from '@/utility/const';
import ActivityList from '../list/ActivityList.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    ActivityList,
  },
  props: {
    sortedSections: { type: Array, default: () => [] },
    currentSectionIndex: { type: Number, default: 0 },
  },

  data: () => {
    return {
      TABLE_CONTENT_TYPES,
      isShowContent: false,
      isShowDetail: false,
      isShowHistory: false,
      isShowAction: false,
    };
  },

  computed: {
    ...mapGetters({
      proposal: 'proposal/proposal/proposal',
    }),

    isServiceJob() {
      return (
        this.proposal.project &&
        this.proposal.project.businessCode === BUSINESS_CODE_SERVICE
      );
    },
  },

  methods: {
    onlineClass(status) {
      return status === 'sent'
        ? { color: 'green', text: 'Sent' }
        : status === 'withdraw'
          ? { color: '', text: 'Withdrawn' }
          : status === 'viewed'
            ? { color: 'pink', text: 'Viewed' }
            : { color: 'yellow', text: 'Draft' };
    },
    activeMenu(title) {
      switch (title) {
        case 'content':
          this.isShowContent = !this.isShowContent;
          this.isShowDetail = false;
          this.isShowHistory = false;
          this.isShowAction = false;
          break;
        case 'detail':
          this.isShowDetail = !this.isShowDetail;
          this.isShowHistory = false;
          this.isShowContent = false;
          this.isShowAction = false;
          break;
        case 'history':
          this.isShowHistory = !this.isShowHistory;
          this.isShowContent = false;
          this.isShowDetail = false;
          this.isShowAction = false;
          break;
        case 'action':
          this.isShowAction = !this.isShowAction;
          this.isShowHistory = false;
          this.isShowContent = false;
          this.isShowDetail = false;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dl-item {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 9999;
  max-height: 60vh;
  overflow-y: scroll;
  box-shadow: rgba(33, 35, 38, 0.15) 0px 15px 10px -10px;
}
</style>
