import { getFullAddress } from '@/utility/address';
import { applyFilter, groupBy } from '@/utility/filter-tools';
export default {
  objectList: ({ objectList }) =>
    objectList.map(r => {
      const { phones, addresses } = r;
      return {
        ...r,
        phoneNumber: ((phones || [])[0] || {}).value,
        address: getFullAddress((addresses || [])[0]),
      };
    }),
  detail: ({ objectList, detailId }) => objectList.find(r => r.id === detailId),
  objectGroup:
    (_, getters) =>
    (searchValue = '') => {
      const filteredObjects = applyFilter(getters.objectList, searchValue, [
        'companyName',
      ]);
      let dataGroup = groupBy(filteredObjects, item =>
        ((item.companyName || '').charAt(0) || '').toUpperCase()
      ).sort((a, b) => sortBy(a.key, b.key));
      return dataGroup.map(i => ({
        ...i,
        data: i.data.sort((a, b) => sortBy(a.companyName, b.companyName)),
      }));
    },
  objectById:
    ({ objectList }) =>
    id => {
      return objectList.find(r => r.id === id);
    },
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
