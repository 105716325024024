<template>
  <div class="border-shadow btn-container">
    <f7-button
      color="gray"
      icon-f7="arrowtriangle_left_fill"
      class="flex-center btn-container_prev"
      @click="prevWeek"
    ></f7-button>
    <f7-link
      color="black"
      class="btn-container_select"
      @click.native="selectCalendar"
    >
      {{ showWeekRange }}
    </f7-link>
    <f7-button
      color="gray"
      icon-f7="arrowtriangle_right_fill"
      class="flex-center btn-container_next"
      @click="nextWeek"
    ></f7-button>
  </div>
</template>

<script>
import moment from 'moment';
function getCurrentWeek() {
  const weekStart = moment().startOf('isoWeek');
  let days = [];
  for (let i = 0; i <= 6; i++) {
    days.push({
      id: moment(weekStart).add(i, 'days').format('MMDDYYYY'),
      date: moment(weekStart).add(i, 'days').format('MM/DD/YYYY'),
      dateHeader: moment(weekStart).add(i, 'days').format('ddd MM/DD'),
      dateMoment: moment(weekStart).add(i, 'days'),
    });
  }
  return days;
}

export default {
  data: () => {
    return {
      currentWeek: getCurrentWeek(),
      currentDate: [new Date()],
    };
  },

  methods: {
    selectCalendar() {
      this.$f7.calendar
        .create({
          inputEl: '#calendar-input',
          openIn: 'customModal',
          backdrop: true,
          cssClass: 'calendar-hover',
          value: this.currentDate,
          rangesClasses: [
            {
              cssClass: 'current-week',
              range: {
                from: new Date(this.currentWeek[0].date),
                to: new Date(this.currentWeek[6].date),
              },
            },
          ],
          on: {
            change: (calendar, value) => {
              if (
                !moment(this.currentDate[0]).isSame(moment(value[0]), 'day')
              ) {
                this.currentDate = value;
                const weekStart = moment(value[0], 'MM/DD/YYYY').startOf(
                  'isoWeek'
                );
                this.setCurrentWeek(weekStart);
                this.$emit('onChangeFilter', this.currentWeek);
                calendar.close();
              }
            },
          },
        })
        .open();
    },
    setCurrentWeek(weekStart) {
      let days = [];
      for (let i = 0; i <= 6; i++) {
        days.push({
          id: moment(weekStart).add(i, 'days').format('MMDDYYYY'),
          date: moment(weekStart).add(i, 'days').format('MM/DD/YYYY'),
          dateHeader: moment(weekStart).add(i, 'days').format('ddd MM/DD'),
          dateMoment: moment(weekStart).add(i, 'days'),
        });
      }
      this.currentWeek = days;
    },
    prevWeek() {
      const weekStart = moment(this.currentWeek[0].date, 'MM/DD/YYYY')
        .subtract(1, 'weeks')
        .startOf('isoWeek');
      this.currentDate = [new Date(weekStart)];
      this.setCurrentWeek(weekStart);
      this.$emit('onChangeFilter', this.currentWeek);
    },
    nextWeek() {
      const weekStart = moment(this.currentWeek[0].date, 'MM/DD/YYYY')
        .add(1, 'weeks')
        .startOf('isoWeek');
      this.currentDate = [new Date(weekStart)];
      this.setCurrentWeek(weekStart);
      this.$emit('onChangeFilter', this.currentWeek);
    },
  },
  computed: {
    showWeekRange() {
      const from = moment(this.currentWeek[0].date, 'MM/DD/YYYY').format(
        'MMM Do'
      );
      const to = moment(this.currentWeek[6].date, 'MM/DD/YYYY').format(
        'MMM Do'
      );
      return `${from} - ${to}`;
    },
  },

  mounted() {
    if (
      this.$f7route.query.startDate &&
      this.$f7route.query.view === 'weekly'
    ) {
      const startDate = moment(
        this.$f7route.query.startDate,
        'MM/DD/YYYY'
      ).startOf('isoWeek');
      if (startDate) {
        this.currentDate = [new Date(startDate)];
        this.setCurrentWeek(startDate);
        this.$emit('onChangeFilter', this.currentWeek);
      }
    }
  },
};
</script>

<style lang="scss">
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-shadow {
  border-radius: 4px;
  box-shadow: 0 1px 3px #b7b7b7;
  box-sizing: border-box;
}
.btn-container {
  min-width: 250px;
  min-height: 32px;
  display: flex;

  &_prev {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &_next {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &_select {
    flex-grow: 1;
    border-left: 1px solid #ededed;
    border-right: 1px solid #ededed;
  }
}
.current-week {
  background-color: #dc5858;
}
.calendar-hover {
  .calendar-months {
    .calendar-row {
      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
}
</style>
