import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const expenseActions = new FirebaseActions(
    `/system_client/${tenant}/employee_expenses`,
    'expense'
  );

  return {
    bindExpenseList: expenseActions.bindCollection,
    bindExpenseListBys: expenseActions.bindCollectionBys,
    unbindExpenseList: expenseActions.unbindCollection,

    bindExpense: expenseActions.bindDocument,
    unbindExpense: expenseActions.unbindDocument,
  };
};
