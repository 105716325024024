import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    'company'
  );

  return {
    getCompanyById: companyActions.getDocument,
    getCompany({ commit, dispatch }, id) {
      return dispatch('getCompanyById', id).then(data => {
        return commit(types.SET_COMPANY, data);
      });
    },
    resetCompany({ commit }) {
      return commit(types.SET_COMPANY, {});
    },
  };
};
