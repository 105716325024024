<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="closePopup"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Edit Contact Information</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="save">Save</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <phone-input
        :id="currentContact.id"
        ref="phoneInput"
        isRequired
        :isCompared="false"
        :value="currentContact.phones"
        :listData="contactList"
        @input="setPhones($event)"
      ></phone-input>

      <email-input
        :id="currentContact.id"
        ref="emailInput"
        :value="currentContact.emails"
        @input="setEmails($event)"
      ></email-input>

      <address-auto-complete-input
        :id="currentContact.id"
        ref="addressInput"
        :value="currentContact.addresses"
        @input="setAddresses($event)"
      ></address-auto-complete-input>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import PhoneInput from '@/components/inputs/PhoneInput.vue';
import EmailInput from '@/components/inputs/EmailInput.vue';
import AddressAutoCompleteInput from '@/components/inputs/AddressAutoCompleteInput.vue';
import { HISTORY_TYPE } from '@/utility/const';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Vue from 'vue';

export default {
  components: {
    PhoneInput,
    EmailInput,
    AddressAutoCompleteInput,
  },

  data() {
    return {
      popupOpened: false,
      currentContact: {},
    };
  },
  async created() {
    await this.getContactListBys();
  },
  computed: {
    ...mapGetters('swimlane/client', ['contact']),
    ...mapGetters('contact-book/contact', ['contactList']),
  },

  methods: {
    ...mapActions({
      updateContact: 'swimlane/client/updateContact',
      getContact: 'swimlane/client/getContact',
      createHistory: 'swimlane/history/createHistory',
    }),
    ...mapActions('contact-book/contact', ['getContactListBys']),

    setPhones(val) {
      Vue.set(this.currentContact, 'phones', val);
    },

    setEmails(val) {
      Vue.set(this.currentContact, 'emails', val);
    },

    setAddresses(val) {
      Vue.set(this.currentContact, 'addresses', val);
    },

    validateData() {
      this.v$.$touch();
      this.$refs.phoneInput.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      this.$refs.emailInput.v$.$touch();
      if (
        this.$refs.phoneInput.v$.$invalid ||
        this.$refs.addressInput.v$.$invalid ||
        this.$refs.emailInput.v$.$invalid ||
        this.v$.$invalid
      ) {
        return false;
      }

      return true;
    },

    open() {
      this.popupOpened = true;
      if (!_.isEmpty(this.contact)) {
        this.currentContact = _.cloneDeep(this.contact);
      }
    },

    closePopup() {
      this.popupOpened = false;
      this.currentContact = {};
    },

    save() {
      const contactId = this.currentContact.id;
      if (!this.validateData()) return;
      this.$f7.preloader.show();
      const oldContactData = _.cloneDeep(this.contact || {});
      this.updateContact({
        id: contactId,
        doc: this.currentContact,
      })
        .then(() => {
          let history = {
            type: HISTORY_TYPE.CONTACT,
            itemId: contactId,
            data: oldContactData,
          };
          return this.createHistory(history);
        })
        .then(() => {
          this.closePopup();
          this.$f7.preloader.hide();
        })
        .finally(() => {
          this.getContact(contactId);
        });
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      currentContact: {
        // addresses: {
        //   $each: {
        //     address: {
        //       required
        //     },
        //     city: {
        //       required
        //     },
        //     state: {
        //       required
        //     },
        //     zipcode: {
        //       required
        //     },
        //     country: {
        //       required
        //     }
        //   }
        // }
      },
    };
  },
};
</script>
