<template>
  <f7-popup
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="cancel"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title
          >{{ paymentRecord.id ? 'Edit payment for' : ' Payment for' }}
          {{ paymentRecord.invoiceNumber }}</f7-nav-title
        >
        <f7-nav-right>
          <f7-link @click="done()">{{
            paymentRecord.id ? 'Save' : 'Record Payment'
          }}</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list :inset="$device.desktop">
        <div class="row">
          <div class="col-100">
            <f7-list
              :inline-labels="$device.desktop"
              :class="{ 'no-margin': !$device.desktop }"
              no-hairlines-md
            >
              <!-- Customer Name -->
              <f7-list-input
                label="Client Name"
                placeholder="Client Name"
                :value="paymentRecord.customerName"
                @change="
                  onChangePaymentRecordProp('customerName', $event.target.value)
                "
                type="text"
                error-message-force
                :error-message="requireErrorMessage('customerName')"
              >
                <required-asterisk slot="label" />
              </f7-list-input>
              <!-- Amount Received(USD) -->
              <f7-list-input
                label="Amount Received(USD)"
                :input="false"
              >
                <required-asterisk slot="label" />
                <input-price
                  ref="amountReceivedInput"
                  slot="input"
                  :allowZero="false"
                  :price="Number(paymentRecord.amountReceived)"
                  :isResetData="!popupOpened"
                  @input="onChangePaymentRecordProp('amountReceived', $event)"
                />
              </f7-list-input>
              <f7-list-input
                label="Bank Charges (if any)"
                :input="false"
              >
                <input-price
                  slot="input"
                  :isShowError="false"
                  :price="Number(paymentRecord.bankCharges)"
                  :isResetData="!popupOpened"
                  @input="onChangePaymentRecordProp('bankCharges', $event)"
                />
              </f7-list-input>
              <!-- Payment Date -->
              <f7-list-input
                label="Payment Date"
                type="datepicker"
                placeholder="MM/DD/YYYY"
                :calendar-params="{
                  backdrop: true,
                  openIn: 'customModal',
                  header: true,
                  footer: false,
                  dateFormat: 'mm/dd/yyyy',
                }"
                :value="paymentRecord.paymentDate || []"
                @calendar:change="
                  onChangePaymentRecordProp('paymentDate', $event);
                  $f7.calendar.close();
                "
                error-message-force
                :error-message="requireErrorMessage('paymentDate')"
              >
                <required-asterisk slot="label" />
              </f7-list-input>
              <!-- Payment Mode -->
              <f7-list-input
                label="Payment Mode"
                placeholder="Select payment mode"
                :value="paymentRecord.paymentMode"
                type="select"
                @change="
                  onChangePaymentRecordProp('paymentMode', $event.target.value)
                "
                error-message-force
                :error-message="requireErrorMessage('paymentMode')"
              >
                <required-asterisk slot="label" />
                <option
                  value=""
                  hidden
                >
                  Select payment mode
                </option>
                <option
                  v-for="item in paymentOptions"
                  :key="item.id"
                  :value="item.value"
                >
                  {{ item.displayName }}
                </option>
              </f7-list-input>
              <!-- Check -->
              <f7-list-input
                v-show="paymentRecord.paymentMode == 'check'"
                label="Check#"
                placeholder="Check#"
                :value="paymentRecord.check"
                @change="
                  onChangePaymentRecordProp('check', $event.target.value)
                "
                type="text"
              >
              </f7-list-input>
              <!-- Notes -->
              <f7-list-input
                label="Notes"
                placeholder="Enter Notes"
                :value="paymentRecord.notes"
                @change="
                  onChangePaymentRecordProp('notes', $event.target.value)
                "
                type="textarea"
              >
              </f7-list-input>
            </f7-list>
          </div>
        </div>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { minValue, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import invoiceMixins from '../../mixins/invoice-mixin';
//import { currencyUSD, MMDDYYYY } from "@/utility/config";
import InputPrice from '@/components/inputs/InputPrice.vue';
import Vue from 'vue';
import _ from 'lodash';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    InputPrice,
  },
  mixins: [invoiceMixins],
  data: () => {
    return {
      invoiceDetail: {},
      popupOpened: false,
      originPaymentRecord: {},
      paymentRecord: {
        invoiceNumber: '',
        customerName: '',
        amountReceived: 0,
        bankCharges: 0,
        paymentMode: 'cash',
        paymentAmount: 0,
        paymentDate: [new Date()],
      },
    };
  },
  computed: {
    ...mapGetters('invoices/contact', ['listEmails']),
    ...mapGetters('setting/app/profile', ['user']),
    ...mapGetters('invoices/app-constant', ['paymentModeList']),
    totalAmount() {
      return this.total(this.invoice);
    },
    paymentOptions() {
      return this.paymentModeList;
    },
  },
  methods: {
    ...mapActions('invoices/contact', ['bindEmailList']),
    ...mapActions('invoices/invoices', [
      'updateInvoice',
      'getInvoiceByGroupId',
    ]),
    ...mapActions('invoices/payment-record', ['updatePaymentRecord']),
    ...mapActions('invoices/invoice-group', [
      'getInvoiceGroupById',
      'updateInvoiceGroup',
    ]),
    onChangePaymentRecordProp(prop, value) {
      Vue.set(this.paymentRecord, prop, value);
    },
    async done() {
      if (!this.validate()) return;
      let data = {
        ...this.paymentRecord,
        paymentDate: this.paymentRecord.paymentDate[0] || '',
      };
      // create record payment
      this.$f7.preloader.show();
      await this.createOrUpdate(data);
      //update invoice date and payment record.
      const oldPaidAmount = this.invoiceDetail.paidAmount || 0;
      const newPaidAmount =
        oldPaidAmount -
        parseFloat(this.originPaymentRecord.amountReceived || 0) +
        parseFloat(this.paymentRecord.amountReceived);
      let status =
        newPaidAmount >= this.totalAmount ? 'in-paid' : 'in-partial-paid';
      await this.updateInvoice({
        id: this.invoiceDetail.id,
        doc: {
          status,
          paidAmount: newPaidAmount,
          paidDate: data.paymentDate,
        },
      });
      const invoiceGroupData = await this.getInvoiceGroupById(
        this.invoiceDetail.invoiceGroupId
      );
      await this.updateInvoiceGroup({
        id: this.invoiceDetail.invoiceGroupId,
        doc: {
          totalPaidInvoiceAmount:
            (invoiceGroupData.totalPaidInvoiceAmount || 0) +
            newPaidAmount -
            oldPaidAmount,
          lastPaidDate: data.paymentDate,
        },
      });
      await this.getInvoiceByGroupId(this.invoiceDetail.invoiceGroupId);
      this.$f7.preloader.hide();
      this.cancel();
    },
    createOrUpdate(data) {
      if (data.id) {
        return this.updatePaymentRecord({
          id: data.id,
          doc: data,
        });
      } else {
        return this.createPaymentRecord(data);
      }
    },
    requireErrorMessage(prop) {
      if (!this.v$.paymentRecord[prop].$error) return null;
      if (this.v$.paymentRecord[prop].required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    showToastMessage(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000,
        })
        .open();
    },
    async open({ invoice, paymentRecord }) {
      this.invoiceDetail = _.cloneDeep(invoice);
      if (paymentRecord) {
        this.originPaymentRecord = _.cloneDeep(paymentRecord);
        this.paymentRecord = {
          ...this.paymentRecord,
          ...paymentRecord,
          paymentDate: paymentRecord.paymentDate?.toDate
            ? [new Date(paymentRecord.paymentDate.toDate())]
            : [new Date()],
        };
      } else {
        this.paymentRecord = {
          ...this.paymentRecord,
          invoiceNumber: invoice.invoiceNumber,
          customerName: invoice.clientName || '',
          paymentDate: invoice.paymentDate?.toDate
            ? [new Date(invoice.paymentDate.toDate())]
            : [new Date()],
          amountReceived: parseFloat(
            (this.total(invoice) - (invoice.paidAmount || 0)).toFixed(2)
          ),
        };
      }

      this.popupOpened = true;
    },
    cancel() {
      this.popupOpened = false;
      this.originPaymentRecord = {};
      this.paymentRecord = {
        invoiceNumber: '',
        customerName: '',
        amountReceived: 0,
        bankCharges: 0,
        paymentMode: 'cash',
        paymentAmount: 0,
        paymentDate: [new Date()],
      };
      this.v$.$reset();
    },
    validate() {
      this.v$.$touch();
      this.$refs.amountReceivedInput?.validate();
      if (this.v$.$invalid || this.$refs.amountReceivedInput?.validate()) {
        return false;
      }
      return true;
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  validations() {
    return {
      paymentRecord: {
        customerName: {
          required,
        },
        amountReceived: {
          required,
          minValue: minValue(0.01),
        },
        paymentDate: {
          required,
        },
        paymentMode: {
          required,
        },
      },
    };
  },
};
</script>
<style land="scss" scoped></style>
