import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import { firebase, auth } from '../../../services/firebase.service';
import {
  ROOFING_CHOICE_SENT,
  SENDER_CONFIG_EMAIL,
} from '../../../utility/const';
import axiosService from '@/services/axios.service';

export default {
  computed: {
    ...mapGetters('dashboard/project', ['card', 'constructionProject']),
    ...mapGetters('setting/app/profile', ['user']),
    ...mapGetters({
      setting: 'setting/app/system/setting',
    }),
    ...mapGetters('dashboard/agreement', ['agreementList', 'agreementById']),

    ...mapGetters('dashboard/contract', ['contract']),

    ...mapGetters('common/app-constant', ['tenantId']),
  },
  methods: {
    ...mapActions({
      getContactById: 'dashboard/client/getContactById',
    }),
    ...mapActions('dashboard/project', ['updateCard']),
    ...mapActions('dashboard/agreement', ['updateAgreement']),
    ...mapActions('dashboard/certificate', ['updateCertificate']),
    ...mapActions('dashboard/letter', ['updateLetter']),
    ...mapActions('common/contract', ['updateContract']),

    ...mapActions('dashboard/email-notification', [
      'getEmailTemplateByTemplateName',
    ]),
    ...mapActions({
      bindSetting: 'setting/app/system/bindSetting',
    }),

    parseEmailTemplate(htmlContent, valueObject) {
      for (const key in valueObject) {
        // eslint-disable-next-line no-prototype-builtins
        if (valueObject.hasOwnProperty(key)) {
          const value = valueObject[key];
          const reg = new RegExp(`{${key}}`, 'g');
          htmlContent = htmlContent.replace(reg, value);
        }
      }
      return htmlContent;
    },

    async getComposer({
      templateName,
      detailUrl,
      isConstructionBoard = false,
    }) {
      const composer = {};

      let clientEmail = '';
      let contact = {};
      try {
        contact = isConstructionBoard
          ? this.constructionProject.contactId &&
            (await this.getContactById(this.constructionProject.contactId))
          : this.card.contactId &&
            (await this.getContactById(this.card.contactId));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      }

      const clientName =
        contact &&
        `${contact.firstName || ''} ${contact.lastName || ''}`.trim();
      const contactEmailAddress =
        contact &&
        ((contact.emails || []).find(r => r.code == 'main') || {}).value;
      if (contactEmailAddress) {
        clientEmail = contactEmailAddress;
      }
      // composer.clientName = clientName;
      composer.to = clientEmail || '';
      composer.from = `${this.user.displayName} <${SENDER_CONFIG_EMAIL}>`;
      composer.cc = this.user.email || '';

      let templateEmail =
        await this.getEmailTemplateByTemplateName(templateName);

      let valueObject = {
        //TODO: Support update client logo in setting (DEV-987)
        logo_url:
          'https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/proposal%2F1618341271487Swadley%20Logo.webp?alt=media&token=87d2a6c8-bc88-4225-84f9-de8f321503b0', //(proposal.pages[0] || {}).components[0].data.logo || "",
        client_name: clientName || '',
        roofing_company_name: this.setting.companyName || '',
        roofing_company_address: this.setting.address || '',
        roofing_company_phone: this.setting.phoneNumber || '',
        detail_url: detailUrl,
        project_title: isConstructionBoard
          ? this.constructionProject.title
          : this.card.title,
      };

      composer.message = this.parseEmailTemplate(
        templateEmail.htmlContent,
        valueObject
      );
      composer.subject = this.parseEmailTemplate(
        templateEmail.subject,
        valueObject
      );

      composer.template = templateName;

      composer.attachments = [];
      composer.scheduleSendingTime = firebase.firestore.Timestamp.now();
      composer.isSent = false;
      composer.numberOfAttempt = 0;

      return composer;
    },

    async sentRoofingChoices() {
      this.$f7.preloader.show();
      if (_.isEmpty(this.setting)) {
        await this.bindSetting(this.tenantId);
      }

      const self = this;
      const composer = await this.getComposer({
        templateName: 'roofing-choices',
        detailUrl: this.card.roofingChoicesWebUrl,
      });

      await this.$refs.sendEmailPopup.open({
        composer,
        callback: () => {
          self.updateCard({
            id: self.card.id,
            doc: {
              roofingChoicesStatus: ROOFING_CHOICE_SENT,
              roofingChoicesSentAt: firebase.firestore.Timestamp.now(),
              roofingChoicesSentBy:
                auth.currentUser.displayName || auth.currentUser.email,
            },
          });
        },
      });

      this.$f7.preloader.hide();
    },

    async sendAgreement(id) {
      const agreement = this.agreementById(id);
      if (!agreement.companyRepresentativeSigner) {
        return this.$ri.dialog.openWarningDialog({
          title: 'Sign Online',
          content: `Please have the ${
            this.setting.companyName || 'company'
          } representative sign before sending an email to the owner.`,
          hideCancelButton:
            this.$refs.addNewAgreementPopup.popupOpened || false,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
              !this.$refs.addNewAgreementPopup.popupOpened &&
                this.$refs.addNewAgreementPopup.open(id);
            }
          },
        });
      }
      this.$f7.preloader.show();
      if (_.isEmpty(this.setting)) {
        await this.bindSetting(this.tenantId);
      }
      try {
        const self = this;
        const response = await axiosService.post(
          '/tenant/get-anonymous-token',
          { entity: 'agreement', entityId: agreement.id }
        );

        if (response && response.status === 200) {
          const composer = await this.getComposer({
            templateName: 'agreement',
            detailUrl: `${import.meta.env.VITE_HOST_DOMAIN}/agreement-view/?token=${response.data}&id=${agreement.id}`,
          });

          await this.$refs.sendEmailPopup.open({
            composer,
            callback: () => {
              self.updateAgreement({
                id: agreement.id,
                doc: {
                  status: 'sent',
                  sentAt: firebase.firestore.Timestamp.now(),
                  sentBy:
                    auth.currentUser.displayName || auth.currentUser.email,
                },
              });
            },
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('[get-anonymous-token]', error.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },

    async sendCertificate(id) {
      this.$f7.preloader.show();
      if (_.isEmpty(this.setting)) {
        await this.bindSetting(this.tenantId);
      }

      const self = this;
      const response = await axiosService.post('/tenant/get-anonymous-token', {
        entity: 'certificate',
        entityId: id,
      });

      const composer = await this.getComposer({
        templateName: 'certificate',
        detailUrl: `${import.meta.env.VITE_HOST_DOMAIN}/certificate-view/?token=${response.data}&id=${id}`,
      });

      await this.$refs.sendEmailPopup.open({
        composer,
        callback: () => {
          self.updateCertificate({
            id,
            doc: {
              status: 'sent',
              sentAt: firebase.firestore.Timestamp.now(),
              sentBy: auth.currentUser.displayName || auth.currentUser.email,
            },
          });
        },
      });

      this.$f7.preloader.hide();
    },

    async sendLetter(id) {
      this.$f7.preloader.show();
      if (_.isEmpty(this.setting)) {
        await this.bindSetting(this.tenantId);
      }

      try {
        const self = this;
        const response = await axiosService.post(
          '/tenant/get-anonymous-token',
          {
            entity: 'letter',
            entityId: id,
          }
        );

        const composer = await this.getComposer({
          templateName: 'letter-authorization',
          detailUrl: `${import.meta.env.VITE_HOST_DOMAIN}/letter-view/?token=${response.data}&id=${id}`,
        });

        await this.$refs.sendEmailPopup.open({
          composer,
          callback: () => {
            self.updateLetter({
              id,
              doc: {
                status: 'sent',
                sentAt: firebase.firestore.Timestamp.now(),
                sentBy: auth.currentUser.displayName || auth.currentUser.email,
              },
            });
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('[get-anonymous-token]', error.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async sendBuildContract(id) {
      if (
        !this.contract.projectManagerSigner ||
        !this.contract.contractorSigner
      ) {
        return this.$ri.dialog.openWarningDialog({
          title: 'Sign Online',
          content: `Please have the project manager and contractor sign before sending an email to the owner.`,
          hideCancelButton: this.$refs.buildContractPopup.popupOpened || false,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
              !this.$refs.buildContractPopup.popupOpened &&
                this.$refs.buildContractPopup.open();
            }
          },
        });
      }

      this.$f7.preloader.show();
      if (_.isEmpty(this.setting)) {
        await this.bindSetting(this.tenantId);
      }

      const self = this;
      const composer = await this.getComposer({
        templateName: 'build-contract',
        detailUrl: this.contract.contractWebUrl,
      });

      await this.$refs.sendEmailPopup.open({
        composer,
        callback: () => {
          self.updateContract({
            id,
            doc: {
              status: 'sent',
              sentAt: firebase.firestore.Timestamp.now(),
              sentBy: auth.currentUser.displayName || auth.currentUser.email,
            },
          });
        },
      });

      this.$f7.preloader.hide();
    },
    async sendLinkToJobCard(project, isConstructionBoard = false) {
      this.$f7.preloader.show();
      if (_.isEmpty(this.setting)) {
        await this.bindSetting(this.tenantId);
      }

      try {
        const response = await axiosService.post(
          '/tenant/get-anonymous-token',
          {
            entity: 'project',
            entityId: project.id,
          }
        );

        const composer = await this.getComposer({
          templateName: 'link-to-job-card',
          detailUrl: `${import.meta.env.VITE_HOST_DOMAIN}/${
            project.businessCode
          }-card-view/?token=${response.data}&tenant=${btoa(
            this.tenantId
          )}&id=${project.id}`,
          isConstructionBoard,
        });

        await this.$refs.sendEmailPopup.open({
          composer: { ...composer, to: '' },
          callback: () => {},
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('[get-anonymous-token]', error.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },
  },
};
