import Vue from 'vue';

export default {
  setConstantObjectList(state, payload) {
    Vue.set(state, 'constantObjectList', payload);
  },
  setConstantObjectSubscribe(state, payload) {
    Vue.set(state, 'constantObjectSubscribe', payload);
  },
};
