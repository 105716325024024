/* eslint-disable no-unused-vars */
import {
  COMPANY_TYPE_VENDOR,
  CODE_PROPERTY_TYPE,
  CODE_JOB_TYPE,
  CODE_COMPANY_TYPE,
  CODE_PREPARATION_TASK_CHECKLIST,
  CODE_WO_TASK_TYPE,
  CODE_EXPENSE_TYPE,
  CODE_STATUS_OPTION,
  ENTITY_EXPENSE,
  ENTITY_ACTION_REQUIRED,
  CODE_ROOF_TYPE,
  ENTITY_PURCHASE_ORDER,
  ENTITY_INVOICE,
} from '@/utility/const';
import { ENTITY_RECURRING_INVOICE } from '../../../../utility/const';

export default {
  propertyTypeList: state =>
    state.typeList.filter(item => item.code === CODE_PROPERTY_TYPE),
  jobTypeList: state =>
    state.typeList.filter(item => item.code === CODE_JOB_TYPE),
  companyTypeList: state =>
    state.typeList.filter(item => item.code === CODE_COMPANY_TYPE),
  companyTypeListExceptVendor: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_COMPANY_TYPE && item.value !== COMPANY_TYPE_VENDOR
    ),
  preparationTaskChecklistList: state =>
    state.typeList.filter(
      item => item.code === CODE_PREPARATION_TASK_CHECKLIST
    ),
  woTaskTypeList: state =>
    state.typeList.filter(item => item.code === CODE_WO_TASK_TYPE),

  expenseTypeList: state =>
    state.typeList.filter(item => item.code === CODE_EXPENSE_TYPE),
  expenseStatusList: state =>
    state.typeList.filter(
      item => item.code === CODE_STATUS_OPTION && item.entity === ENTITY_EXPENSE
    ),
  actionRequiredStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION &&
        item.entity === ENTITY_ACTION_REQUIRED
    ),

  roofTypeList: state =>
    state.typeList.filter(item => item.code === CODE_ROOF_TYPE),

  roofTypeBy: (state, getters, rootState, rootGetters) => value =>
    getters.roofTypeList.find(item => item.value === value) || {},

  purchaseOrderStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION &&
        item.entity === ENTITY_PURCHASE_ORDER
    ),

  purchaseOrderStatusBy: (state, getters, rootState, rootGetters) => value =>
    getters.purchaseOrderStatusList.find(item => item.value === value) || {},

  invoiceStatusList: state =>
    state.typeList.filter(
      item => item.code === CODE_STATUS_OPTION && item.entity === ENTITY_INVOICE
    ),

  recurringInvoiceStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION &&
        item.entity === ENTITY_RECURRING_INVOICE
    ),
};
