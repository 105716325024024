<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="handlePopupClosed"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="cancel">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>New Property</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="create">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <!-- Property Name -->
        <f7-list-input
          class="first-input"
          type="text"
          placeholder="Property name"
          clear-button
          :value="currentProperty.propertyName"
          @input="set('propertyName', $event.target.value.trim())"
          error-message-force
          :error-message="propertyNameErrorMessage"
          @blur="v$.$touch()"
        ></f7-list-input>
      </f7-list>

      <!-- Address -->
      <address-auto-complete-input
        ref="addressInput"
        isRequired
        :value="currentProperty.addresses"
        @input="set('addresses', $event)"
      ></address-auto-complete-input>
    </f7-page>
  </f7-popup>
</template>

<script>
import AddressAutoCompleteInput from '@/components/inputs/AddressAutoCompleteInput.vue';
import { mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Vue from 'vue';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    AddressAutoCompleteInput,
  },

  data: () => {
    return {
      popupOpened: false,
      currentProperty: { propertyName: '' },
    };
  },

  computed: {
    propertyNameErrorMessage() {
      if (!this.v$.currentProperty.propertyName.$error) return null;
      if (this.v$.currentProperty.propertyName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },

  methods: {
    ...mapActions('contact-book/property', ['createProperty']),

    set(prop, value) {
      Vue.set(this.currentProperty, prop, value);
    },

    async open() {
      this.popupOpened = true;
    },

    async close() {
      this.popupOpened = false;
    },

    handlePopupClosed() {
      this.currentProperty = { propertyName: '' };
      this.v$.$reset();
      this.$refs.addressInput.resetData();
      this.close();
    },

    cancel() {
      this.popupOpened = false;
    },

    create() {
      const self = this;
      this.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      if (this.v$.$invalid || this.$refs.addressInput.v$.$invalid) return;

      this.$f7.preloader.show();
      return this.createProperty(this.currentProperty)
        .then(propertyId => {
          propertyId && self.$emit('onCreated', propertyId);
        })
        .then(() => {
          self.$f7.preloader.hide();
          self.close();
        });
    },

    handlePopupOpened() {
      this.$el.querySelector('.first-input input').focus();
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      currentProperty: {
        propertyName: {
          required,
        },
      },
    };
  },
};
</script>

<style></style>
