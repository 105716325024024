import company from './company';
import common from './common';
import employeeExpenses from './employee-expenses';
import woTask from './work-order-task';
import timeTracking from './time-tracking';
import user from './user';
import activity from './activity';
import appConstant from './app-constant';
import project from './project';
import property from './property';
import invoice from './invoices';
import payment from './payment-record';

import _ from 'lodash';

export default tenant => {
  return {
    namespaced: true,
    modules: {
      company: company(tenant),
      common,
      'employee-expenses': employeeExpenses(tenant),
      'work-order-task': woTask(tenant),
      'time-tracking': timeTracking(tenant),
      user: _.cloneDeep(user),
      activity: activity(tenant),
      'app-constant': appConstant(tenant),
      project: project(tenant),
      property: property(tenant),
      invoices: invoice(tenant),
      'payment-record': payment(tenant),
    },
  };
};
