<template>
  <div>
    <f7-block>
      <f7-row class="justify-content-space-between align-items-center">
        <div>
          <f7-block-title medium>Schedule of value by cost type</f7-block-title>
        </div>
        <div class="display-flex">
          <f7-button
            @click="exportToExcel"
            fill
            class="margin-right-half"
            >Export Excel</f7-button
          >
          <f7-button
            @click="exportToPdf"
            fill
            class="margin-right-half"
            >Export PDF</f7-button
          >
        </div>
      </f7-row>
    </f7-block>
    <div class="data-table">
      <data-table
        :headers="headers"
        :items="itemList"
        :pageSize="itemList.length"
        id="generated-results"
      >
        <template v-slot:body="{ item }">
          <template v-if="item.isInputPercent">
            <td
              v-for="(h, index) in headers"
              :key="h.id"
              class="text-align-left"
            >
              <span
                v-if="
                  (!index && h.value == 'name') || index == headers.length - 1
                "
              >
                <span v-show="h.value != 'totals'">{{ item[h.value] }}</span>
              </span>
              <enter-input-schedule-percent
                v-else
                :item="item"
                :value="h.type === 'text' ? item[h.value] : item[h.value] || 0"
                @input="changeScheduleInfomation(h.value, item, $event)"
              />
            </td>
          </template>
          <template v-else-if="item.isTax">
            <td
              v-for="h in headers"
              :key="h.id"
              class="text-align-left"
            >
              <span v-if="h.field === 'name'">{{ item[h.field] }}</span>
              <span v-else>{{ item[h.field] | currencyUSD }}</span>
            </td>
          </template>
          <template v-else-if="item.isFooter">
            <td :colspan="headers.length - 2"></td>
            <td>
              <span class="title-footer">
                {{ item.title }}
              </span>
            </td>
            <td>
              <span v-if="item.title == 'Mobilization'">
                {{ item.value | currencyUSD }}
              </span>
              <span v-else>
                {{ item.value | currencyUSD }}
              </span>
            </td>
          </template>
          <template v-else>
            <td
              v-for="h in headers"
              :key="h.id"
              class="text-align-left"
            >
              <span v-if="h.field === 'name'">{{ item[h.field] }}</span>
              <span v-else>{{ item[h.field] || 0 | currencyUSD }}</span>
            </td>
          </template>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/datatables';
import { mapGetters, mapMutations } from 'vuex';
import exportScheduleOfValue from '../../excel-export';
import exportScheduleOfValuesToPdf from '../../pdf-export';
import EnterInputSchedulePercent from '../inputs/EnterInputSchedulePercent.vue';
export default {
  components: {
    DataTable,
    EnterInputSchedulePercent,
  },
  props: {
    isShowGenerate: Boolean,
    mobilization: [String, Number],
    tax: [String, Number],
  },
  computed: {
    ...mapGetters('schedule/schedule-setting', [
      'generateScheduleByCostType',
      'scheduleInfomation',
    ]),
    ...mapGetters('common/schedule-setting', ['scheduleSettingList']),
    ...mapGetters('common/schedule-value', [
      'scheduleValue',
      'scheduleColunms',
    ]),

    headers() {
      let itemsSchedules = (this.scheduleSettingList || []).filter(x =>
        this.scheduleColunms.includes(x.id)
      );
      let headers = [];
      (itemsSchedules || []).forEach(x => {
        let material = {
          text: `${x.name} Material `,
          field: x.id + 'material',
          align: 'left',
          type: 'number',
          sortable: false,
          value: x.id + 'material',
        };
        headers.push(material);
        let labor = {
          text: `${x.name} Labor `,
          field: x.id + 'labor',
          align: 'left',
          type: 'number',
          sortable: false,
          value: x.id + 'labor',
        };
        headers.push(labor);
      });

      headers.unshift({
        field: 'name',
        text: '',
        value: 'name',
        sortable: false,
        align: 'left',
        type: 'text',
      });

      headers.push({
        text: 'Total',
        field: 'totals',
        align: 'left',
        type: 'number',
        sortable: false,
        value: 'totals',
      });
      return headers;
    },
    footerTablePDF() {
      let subTotal = 0;
      this.generateScheduleByCostType.forEach(x => {
        subTotal += x.totals;
      });
      return [
        {
          name: 'Sub-Total',
          value: subTotal,
        },
        {
          name: 'Mobilization',
          value:
            (this.mobilization / 100) *
            (this.scheduleInfomation[0] || {}).totals,
        },
        {
          name: 'Total',
          value: this.scheduleInfomation[0].totals || 0,
        },
      ];
    },
    itemList() {
      let list = (this.generateScheduleByCostType || []).concat(
        this.itemTax,
        this.footerMolibization
      );
      return list;
    },
    itemTax() {
      let itemsSchedules = (this.scheduleSettingList || []).filter(x =>
        this.scheduleColunms.includes(x.id)
      );
      let taxObject = {
        isTax: true,
        name: 'Tax',
        totals: 0,
      };
      (itemsSchedules || []).forEach(x => {
        taxObject[`${x.id}material`] = 0;
        (this.generateScheduleByCostType || []).forEach(y => {
          (Object.keys(y) || []).forEach(key => {
            if (key.includes(`${x.id}materialtax`)) {
              taxObject[`${x.id}material`] += parseFloat(y[key]) || 0;
              taxObject.totals += parseFloat(y[key]) || 0;
            }
          });
        });
      });
      return [taxObject];
    },
    footerMolibization() {
      return [
        {
          isFooter: true,
          title: 'Sub-Total',
          value: this.subTotal,
        },
        {
          isFooter: true,
          title: 'Mobilization',
          value:
            (this.mobilization / 100) *
            (this.scheduleInfomation[0] || {}).totals,
        },
        {
          isFooter: true,
          title: 'Total',
          value: (this.scheduleInfomation[0] || {}).totals || 0,
        },
      ];
    },
    exportHeaders() {
      return this.headers
        .map(r => ({ name: r.text, id: r.field }))
        .filter(r => r.id != 'name' && r.id != 'totals');
    },
    subTotal() {
      let subTotal = 0;
      this.generateScheduleByCostType.forEach(x => {
        if (x.name == 'Percentage') return;
        subTotal += x.totals;
      });
      subTotal += this.itemTax[0].totals;
      return subTotal;
    },
  },
  methods: {
    ...mapMutations('schedule/schedule-setting', ['editScheduleInfomation']),
    changeScheduleInfomation(colunm, item, value) {
      let laborIndex = colunm.indexOf('labor');
      let materialIndex = colunm.indexOf('material');
      let key, laborValue, materialValue;
      if (laborIndex >= 0) {
        key = colunm.slice(0, laborIndex);
        laborValue = value;
        materialValue = 100 - value;
      } else if (materialIndex >= 0) {
        key = colunm.slice(0, materialIndex);
        materialValue = value;
        laborValue = 100 - value;
      }
      //update for total of labor and material is 100
      const dataLabor = {
        colunm: key + 'labor',
        id: item.id,
        value: laborValue,
      };
      this.editScheduleInfomation(dataLabor);
      const dataMarterial = {
        colunm: key + 'material',
        id: item.id,
        value: materialValue,
      };
      this.editScheduleInfomation(dataMarterial);
    },
    // onBlur() {
    //   let percentage =
    //     this.generateScheduleByCostType && this.generateScheduleByCostType[0];
    //   if (percentage && percentage.totals.toFixed(2) != 100) {
    //     this.showMessageWarningTotal(
    //       "Total of percentage should be equal 100%"
    //     );
    //   }
    // },
    showMessageWarningTotal(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 2000,
          horizontalPosition: 'center',
          cssClass: 'toast-warning',
        })
        .open();
    },
    exportToExcel() {
      exportScheduleOfValue({
        title: this.scheduleValue.title || '',
        headers: this.exportHeaders,
        values: this.generateScheduleByCostType,
        itemTax: this.itemTax,
        subTotal: this.subTotal,
        totals: this.scheduleInfomation[0].totals,
        mobilization:
          (this.mobilization / 100) *
            (this.scheduleInfomation[0] || {}).totals || 0,
      });
    },
    exportToPdf() {
      exportScheduleOfValuesToPdf({
        title: this.scheduleValue.title || '',
        headers: this.exportHeaders,
        values: this.generateScheduleByCostType,
        itemTax: this.itemTax,
        subTotal: this.subTotal,
        totals: this.scheduleInfomation[0].totals,
        mobilization:
          (this.mobilization / 100) *
            (this.scheduleInfomation[0] || {}).totals || 0,
      });
    },
  },
};
</script>

<style lang="scss">
.title-footer {
  font-weight: bold;
}

.tooltip {
  display: flex !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 5px;
    padding: 2px 4px 2px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition:
      opacity 0.15s,
      visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

.toast-warning {
  width: 300px !important;
  left: calc(50% + 130px) !important;
  bottom: 50px !important;
}
</style>
