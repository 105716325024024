export const SET_PO_RESPONSE = 'SET_PO_RESPONSE';
// export const SET_TASK_PROPERTY_BY_ID = "SET_TASK_PROPERTY_BY_ID";
export const PAGINATION_SET_PAGE = 'PAGINATION_SET_PAGE';
export const PAGINATION_SET_CURRENT_PAGE_NUMBER =
  'PAGINATION_SET_CURRENT_PAGE_NUMBER';
export const RESET_PAGE = 'RESET_PAGE';

export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';
export const SET_NUMBER_OF_ROWS = 'SET_NUMBER_OF_ROWS';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
// export const SET_PO_SEARCH_ORDER = "SET_PO_SEARCH_ORDER"; // Allow search desc only
export const RESET_SEARCH = 'RESET_SEARCH';
export const ADD_MORE_RESULT = 'ADD_MORE_RESULT';

export const PO_LIST_ITEM_UPDATE = 'PO_LIST_ITEM_UPDATE';
export const PO_LIST_ITEM_ADD = 'PO_LIST_ITEM_ADD';
export const PO_LIST_ITEM_REMOVE = 'PO_LIST_ITEM_REMOVE';
export const SET_SELECTED_STATUS_FILTER = 'SET_SELECTED_STATUS_FILTER';
