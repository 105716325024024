/* eslint-disable no-unused-vars */
import _ from 'lodash';

export default {
  project: state => state.project,

  actionList: state => state.actionList,

  constructionAction: state => {
    return state.actionList.find(item => item.code === 500);
  },

  // Tranfer action ids to action codes
  constructionActionCodeById: (state, getters) => ids => {
    let codes = [];
    ids.forEach(id => {
      const action = getters.actionList.find(action => action.id === id);
      if (action) {
        codes.push(action.code);
      }
    });

    return codes;
  },

  constructionActionIdByCode: (state, getters) => code => {
    const action = getters.actionList.find(action => action.code === code);
    if (!_.isEmpty(action)) {
      return action.id;
    }

    return '';
  },

  users: state => {
    const result = [];
    state.users.forEach(user => {
      const email =
        user.customClaims.loginMethod === 'email_by_phone'
          ? user.customClaims.displayEmail || ''
          : user.email;
      result.push({ ...user, email });
    });
    return result;
  },
};
