import vendor from './vendor';
import category from './category';
import subCategory from './sub-category';
import roofType from './roof-type';
import priceList from './price-list';
import priceListHistory from './price-list-history';
import productItem from './product';
import appConstant from './app-constant';

import common from './common';

import _ from 'lodash';

export default tenant => {
  return {
    namespaced: true,
    modules: {
      'roof-type': roofType(tenant),

      'price-list-page': {
        namespaced: true,
        modules: {
          common: _.cloneDeep(common),
          category: _.cloneDeep(category(tenant)),
          'sub-category': _.cloneDeep(subCategory(tenant)),
          vendor: _.cloneDeep(vendor(tenant)),
          'price-list': _.cloneDeep(priceList(tenant)),
          'price-list-history': _.cloneDeep(priceListHistory(tenant)),
          'product-items': _.cloneDeep(productItem(tenant)),
          'app-constant': _.cloneDeep(appConstant(tenant)),
        },
      },
    },
  };
};
