import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const WorkOrderActions = new FirebaseActions(
    `/system_client/${tenant}/work_order_task`,
    'woTask',
    {
      modelSchema: {
        percentageOfCompletion: 'float',
        items: [
          {
            actualQty: 'float',
            orderQty: 'float',
          },
        ],
      },
    }
  );

  return {
    bindWorkOrderTaskBys: WorkOrderActions.bindCollectionBys,
  };
};
