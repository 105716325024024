<template>
  <f7-view :class="`page ${dark ? 'theme-dark' : ''}`">
    <f7-page
      no-toolbar
      no-navbar
      no-swipeback
      login-screen
    >
      <f7-login-screen-title>The email has been sent!</f7-login-screen-title>
      <f7-block>
        <f7-block-footer>
          Go to
          <f7-link
            @click.native="toLink('/login')"
            href="#"
          >
            <p>login page</p>
          </f7-link>
        </f7-block-footer>
      </f7-block>
    </f7-page>
  </f7-view>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState('setting/app/profile', ['dark']),
  },

  methods: {
    toLink(link) {
      this.$f7router.navigate(link, {
        pushState: true,
        reloadAll: true,
      });
    },
  },
};
</script>

<style></style>
