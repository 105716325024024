import * as types from './types';
import Vue from 'vue';

export default {
  [types.SET_USERS_LIST](state, userList) {
    Vue.set(state, 'userList', userList);
  },
  [types.SET_USER_ID](state, userId) {
    Vue.set(state, 'userId', userId);
  },
  [types.RESET_DATA](state) {
    Vue.set(state, 'userList', []);
    Vue.set(state, 'userId', null);
  },
  [types.SET_TEAM_FILTER](state, value) {
    Vue.set(state, 'teamFilter', value);
  },
  [types.SET_EMPLOYEE_FILTER](state, value) {
    Vue.set(state, 'employeeFilter', value);
  },
};
