<template>
  <div>
    <f7-list :inset="$device.desktop">
      <f7-row>
        <f7-col
          width="100"
          large="50"
        >
          <f7-block-title>Estimate Information</f7-block-title>
          <f7-list
            style="margin-top: 0px"
            media-list
          >
            <!-- Estimate Name -->
            <f7-list-input
              label="Estimate Name"
              :placeholder="isEditing ? 'Enter Estimate Name' : ''"
              :readonly="!isEditing"
              :value="currentEstimate.estimateName"
              @change="$emit('changeEstimateName', $event.target.value)"
            >
            </f7-list-input>

            <!-- Status -->
            <f7-list-input
              label="Status"
              type="select"
              v-if="isEditing"
              :value="currentEstimate.status"
              @change="$emit('changeStatus', $event.target.value)"
            >
              <option
                v-for="item in estimateStatusOptionList"
                :key="item.id"
                :value="item.value"
              >
                {{ item.displayName }}
              </option>
            </f7-list-input>
            <f7-list-input
              v-else
              label="Status"
              :value="statusBy(currentEstimate.status).displayName"
              readonly
            ></f7-list-input>
            <!-- Tax Apply -->
            <template v-if="project.businessCode === 'service'">
              <f7-list-input
                v-if="isEditing"
                label="Tax Apply"
                type="select"
                :value="currentEstimate.taxApplyType"
                @change="$emit('changeTaxApplyType', $event.target.value)"
              >
                <option
                  v-for="item in TAX_APPLY_TYPE"
                  :key="item.id"
                  :value="item.value"
                >
                  {{ item.displayName }}
                </option>
              </f7-list-input>
              <f7-list-input
                v-else
                label="Tax Apply"
                :value="
                  taxApplyTypeBy(currentEstimate.taxApplyType).displayName
                "
                readonly
              ></f7-list-input>
              <!-- tax -->
              <f7-list-item>
                <div
                  class="list-item-inner-start"
                  slot="inner-start"
                >
                  Tax
                </div>
                <cell-number-input
                  slot="title"
                  :readonly="!isEditing"
                  numberType="percent"
                  placeholder="Sales Tax"
                  noStyle
                  :value="currentEstimate.saleTax"
                  :fractionDigits="3"
                  @done="$emit('changeSaleTax', parseFloat($event))"
                ></cell-number-input>
              </f7-list-item>
            </template>
          </f7-list>

          <f7-block-title> Job Information </f7-block-title>
          <f7-list>
            <f7-list-input
              label="Job Title"
              :value="project ? project.title : ''"
              readonly
            ></f7-list-input>

            <f7-list-input
              label="Job Type"
              :value="
                project ? capitalizeFirstLetter(project.businessCode) : ''
              "
              readonly
            ></f7-list-input>
          </f7-list>
          <f7-block-title> Description </f7-block-title>
          <f7-list>
            <f7-list-input
              type="textarea"
              :placeholder="isEditing ? 'Enter Description' : ''"
              :readonly="!isEditing"
              :value="currentEstimate.description"
              @change="$emit('changeDescription', $event.target.value)"
            >
            </f7-list-input>
          </f7-list>
        </f7-col>

        <f7-col
          width="100"
          large="50"
        >
          <f7-block-title :inline-labels="!$device.desktop"
            >Specification/Building</f7-block-title
          >
          <f7-list v-if="!$device.desktop">
            <f7-list-item
              class="building-link"
              link="#"
              title="Summary"
              @click="summaryLink()"
            >
              <f7-icon
                color="primary"
                slot="media"
                f7="sum"
              ></f7-icon>
            </f7-list-item>
            <f7-list-item
              class="building-link"
              link="#"
              v-for="(building, index) in currentEstimate.buildings || []"
              :key="index"
              :title="building ? building.buildingName : ''"
              @click="gotoBuildingDetails(building)"
            >
              <div
                slot="after"
                class="margin-right-half"
              >
                {{ building.numberOfCopy ? `x ${building.numberOfCopy} ` : '' }}
              </div>
              <f7-icon
                color="primary"
                slot="media"
                f7="building_2_fill"
              ></f7-icon>
              <div
                @click.stop="onCopyBuilding(building)"
                slot="after"
                class="margin-right-half add-button-icon cursor-pointer"
              >
                <f7-icon
                  color="blue"
                  f7="rectangle_on_rectangle"
                  size="22"
                ></f7-icon>
              </div>
              <div
                @click.stop="onDeleteBuilding(building)"
                slot="after"
                class="add-button-icon cursor-pointer"
              >
                <f7-icon
                  f7="trash"
                  size="22"
                ></f7-icon>
              </div>
            </f7-list-item>
            <f7-list-item
              class="building-link"
              link="#"
              title="Add new specification"
              @click="addNewBuilding(currentEstimate)"
              ><f7-icon
                color="primary"
                slot="media"
                f7="plus_app"
              ></f7-icon>
            </f7-list-item>
          </f7-list>

          <!-- number of buiding/section  -->
          <f7-list
            v-if="$device.desktop"
            media-list
          >
            <f7-list-item
              class="building-section"
              v-for="(building, index) in currentEstimate.buildings || []"
              :key="index"
              :header="'Specification # ' + (index + 1)"
              link="#"
              @click="gotoBuildingDetails(building)"
            >
              <f7-icon
                class="margin-top-half"
                color="primary"
                slot="media"
                f7="building_2"
              ></f7-icon>

              <div
                slot="before-title"
                style="width: 25%"
              >
                {{ building ? building.buildingName : '' }}
              </div>

              <div
                slot="after-title"
                class="number-of-copy"
                style="display: flex; justify-content: space-between; flex: 1"
              >
                {{ `x ${building.numberOfCopy} (buildings/sections)` }}
                <div style="display: flex; gap: 10px">
                  <div
                    @click.stop="onCopyBuilding(building)"
                    class="margin-right-half add-button-icon cursor-pointer"
                  >
                    <f7-icon
                      color="blue"
                      f7="rectangle_on_rectangle"
                    ></f7-icon>
                  </div>
                  <div
                    @click.stop="onDeleteBuilding(building)"
                    class="add-button-icon cursor-pointer"
                  >
                    <f7-icon f7="trash"></f7-icon>
                  </div>
                </div>
              </div>
            </f7-list-item>
          </f7-list>
        </f7-col>
      </f7-row>
    </f7-list>
    <add-new-specification ref="addNewSpecification" />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import buildingMixins from '../../utility/building-mixins';
import CellNumberInput from '@/components/inputs/CellNumberInput.vue';
import AddNewSpecification from '../popup/AddNewSpecification.vue';
import { TAX_APPLY_TYPE } from '@/utility/const';

export default {
  components: { CellNumberInput, AddNewSpecification },

  mixins: [buildingMixins],

  data() {
    return { TAX_APPLY_TYPE };
  },

  props: {
    currentEstimate: Object,
    isEditing: Boolean,
  },

  created() {
    // get status constant when refreshing detail page does not affect status value display
    this.initConstant();
  },

  computed: {
    ...mapGetters('estimate/estimate-page/common', ['currentView']),
    ...mapGetters('estimate/estimate-page/project', ['project']),

    ...mapGetters('common/app-constant', [
      'roofTypeBy',
      'slopeType',
      'workType',
      'estimateStatusOptionList',
      'tpoWarrantyOption',
    ]),

    ...mapGetters('estimate/estimate-page/price-list', [
      'priceList',
      'priceListDefault',
    ]),

    taxApplyTypeBy() {
      return type => {
        return this.TAX_APPLY_TYPE.find(item => item.value === type) || {};
      };
    },

    statusBy() {
      const self = this;
      return value => {
        return (
          self.estimateStatusOptionList.find(item => item.value === value) || {}
        );
      };
    },

    capitalizeFirstLetter() {
      return string => {
        return string && string.charAt(0).toUpperCase() + string.slice(1);
      };
    },
  },

  methods: {
    ...mapActions('common/app-constant', ['initConstant']),

    ...mapActions('estimate/estimate-page/estimate', [
      'updateEstimate',
      'createBuilding',
    ]),

    ...mapActions('estimate/estimate-page/estimate', [
      'setParamsBack',
      'setQueryBack',
    ]),
    changeManufacturer(e) {
      let values = Array.from(e.target.selectedOptions, option => option.value);
      this.$emit('selectManufacture', values);
    },
    async addNewBuilding(estimate) {
      if (_.isEmpty(estimate)) {
        return;
      }
      this.$refs.addNewSpecification.openPopup({
        projectInfo: this.project,
        estimateInfo: estimate,
      });
    },
    gotoBuildingDetails(building) {
      const params = this.$f7route.params || {};
      const query = this.$f7route.query || {};
      this.setQueryBack(query);
      this.setParamsBack(params);
      if (!_.isEmpty(this.currentEstimate)) {
        const url = this.buildingEstimateDetailLink(
          this.currentEstimate,
          building
        );
        // show load and hide load after building created
        this.$f7.preloader.show();
        this.$f7router.navigate(url, {
          pushState: true,
          reloadCurrent: true,
        });
      }
    },

    buildingEstimateDetailLink(estimate, building) {
      return this.buildingUrl(estimate.estimateNumber, building.buildingName);
    },

    summaryLink() {
      if (!_.isEmpty(this.currentEstimate)) {
        let url = `/estimate/${this.$f7route.params.clientType}/${this.$f7route.params.clientId}/${this.currentEstimate.estimateNumber}/summary`;
        if (this.currentView === 'estimate-list') {
          url = `/estimate/${this.currentEstimate.estimateNumber}/summary`;
        }

        this.$f7router.navigate(url, {
          pushState: true,
          reloadCurrent: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.full-row {
  width: 100%;
}

.building-link {
  // color: var(--f7-theme-color);
}

.number-of-copy {
  margin-left: 8em;
}

.building-section ::v-deep .item-title-row::before {
  top: 10px !important;
}
</style>
