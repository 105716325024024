<template>
  <f7-list class="no-margin">
    <div class="btn-upload">
      <image-uploader
        v-show="isEdit"
        :maxWidth="256"
        :preview="false"
        :quality="0.7"
        class="image-uploader"
        accept="image/*"
        doNotResize="gif"
        :autoRotate="false"
        outputFormat="verbose"
        @input="onUploadImage"
        ref="imageUploader"
      ></image-uploader>

      <div
        class="bg-upload cursor-pointer"
        v-show="isEdit"
        @click="onLinkClick"
      >
        <span style="color: white; text-align: center; font-size: 12px">{{
          isEdit ? textUpload : ''
        }}</span>
      </div>
      <user-logo
        :avatarSize="avatarSize"
        :imageUrl="imageUrl"
        :name="'Upload'"
        border
      />
    </div>
  </f7-list>
</template>
<script>
import UserLogo from '@/components/avatars/UserLogo';

export default {
  components: {
    UserLogo,
  },

  props: {
    imageUrl: String,
    avatarSize: Number,
    name: String,
    textUpload: String,
    isEdit: { type: Boolean, default: false },
    isEdited: Boolean,
  },

  methods: {
    onUploadImage(data) {
      this.$emit('uploadImage', data);
    },

    onLinkClick() {
      const input =
        this.$refs.imageUploader.$el.getElementsByTagName('input')[0];
      if (input) {
        input.click();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-upload {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 25%;
  position: absolute;
  width: 100%;
  background: black;
  bottom: 0px;
  opacity: 0.5;
}
.btn-upload {
  position: relative;
  overflow: hidden;
  display: inline-block;
  // border-radius: 50%;
}

.image-uploader ::v-deep input[type='file'] {
  position: absolute;
  opacity: 0;
  z-index: 0;
  max-width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}
</style>
