<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Groups</f7-nav-title>
      <f7-nav-right>
        <f7-link
          icon-f7="plus"
          @click.native="popupOpened = true"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      :value="searchValue"
      disable-button-text
      placeholder="Search group"
      :clear-button="true"
      class="text-color-white search-list"
      @input="e => (searchValue = e.target.value)"
      @searchbar:disable="searchValue = ''"
    ></f7-searchbar>
    <div>
      <f7-list class="no-margin">
        <f7-list-group
          v-for="item in groupedGroups"
          :key="item.title"
          media-list
        >
          <f7-list-item
            :title="item.title"
            group-title
          ></f7-list-item>
          <f7-list-item
            v-for="group in item.listGroup"
            :key="group.id"
            :title="group.displayName | capitalize"
            :subtitle="group.total_UserIds"
            :reload-detail="true"
            :link="`/administration/groups/${group.id}`"
            :text-color="group.id === selectedId ? 'primary' : 'default'"
          >
            <div slot="text">
              <div>{{ group.total_menuIDs }}</div>
            </div>
            <f7-chip
              slot="after"
              :text="onlineClass(group.disabled).text"
              :color="onlineClass(group.disabled).color"
            ></f7-chip>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <group-details-popup
        :isNew="true"
        :popupOpened="popupOpened"
        @close="popupOpened = false"
      />
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import GroupDetailsPopup from '../components/popup/GroupDetailsPopup.vue';

import Menu from '../../../components/menu/Menu.vue';

export default {
  components: { GroupDetailsPopup, Menu },

  data: () => ({
    popupOpened: false,
    searchValue: '',
  }),

  created() {
    this.setDestroyFlag(false);
    this.$f7.preloader.show();
    const refs = [];

    refs.push(this.getUserList());
    refs.push(this.bindGroupList());

    Promise.all(refs).finally(() => {
      this.setDestroyFlag(true);
      this.$f7.preloader.hide();
    });

    this.getMenus(this.$f7router.routes);
  },

  beforeDestroy() {
    if (this.destroyFlag === true) {
      this.unbindGroupList();
      this.removeUserList();
      this.setDetailId('');
    }
  },

  computed: {
    ...mapGetters('administration/group-list-page/group', ['groups', 'group']),
    ...mapGetters({
      destroyFlag: 'administration/group-list-page/common/destroyFlag',
    }),

    selectedId() {
      return (this.group || {}).id;
    },

    filteredGroups() {
      return this.groups
        .map(x => ({
          ...x,
          active: x.disabled === true ? 'Active' : 'Inactive',
          total_UserIds: `Users: ${x.userIDs.length || 0}`,
          total_menuIDs: `Menu items: ${x.menuIDs.length || 0}`,
        }))
        .filter(u => {
          let {
            displayName = '',
            userIDs = [],
            menuIDs = [],
            active = '',
            total_menuIDs,
            total_UserIds,
          } = u;
          displayName = displayName.toLowerCase();
          total_menuIDs = total_menuIDs.toLowerCase();
          total_UserIds = total_UserIds.toLowerCase();
          const search = this.searchValue.toLowerCase();
          return (
            displayName.indexOf(search) >= 0 ||
            userIDs.length == search ||
            menuIDs.length == search ||
            active == this.searchValue.toLocaleString() ||
            total_menuIDs.indexOf(search) >= 0 ||
            total_UserIds.indexOf(search) >= 0
          );
        });
    },

    groupedGroups() {
      const grouped = this.filteredGroups
        .slice()
        .sort(
          (a, b) =>
            a.displayName.toUpperCase().charCodeAt(0) -
            b.displayName.toUpperCase().charCodeAt(0)
        )
        .reduce((b, a) => {
          const title = a.displayName.charAt(0).toUpperCase();
          let gr = b.find(r => r.title === title);
          if (!gr) {
            gr = {
              title,
              listGroup: [],
            };
            b.push(gr);
          }
          gr.listGroup.push(a);
          return b;
        }, []);
      return grouped;
    },
  },

  methods: {
    ...mapActions('administration/group-list-page/group', [
      'bindGroupList',
      'unbindGroupList',
    ]),
    ...mapMutations('administration/group-list-page/group', ['setDetailId']),
    ...mapActions({
      setDestroyFlag: 'administration/group-list-page/common/setDestroyFlag',
    }),
    ...mapActions('administration/group-list-page/menu', ['getMenus']),
    ...mapActions('administration/group-list-page/user', [
      'getUserList',
      'removeUserList',
    ]),

    selectListIndex() {},
    onlineClass(status) {
      return status === true
        ? { color: '', text: 'Inactive' }
        : status === false
          ? { color: 'green', text: 'Active' }
          : { color: 'yellow', text: 'Pending' };
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list.searchbar input').focus();
      }
    });
  },
};
</script>
