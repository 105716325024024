import Vue from 'vue';
import * as types from './types';

export default {
  [types.SET_CLASS_QB_LIST](state, payload) {
    Vue.set(state, 'classQBList', payload);
  },

  [types.SET_SERVICE_ITEM_QB_LIST](state, payload) {
    Vue.set(state, 'serviceItemQBList', payload);
  },
};
