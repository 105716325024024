export default {
  priceList: state => state.priceList,
  productRefs: state => {
    const priceListItem = state.priceList.find(
      r => r.priceListNumber === state.priceListDetailNumber
    );
    return (priceListItem && priceListItem.productRefs) || [];
  },
  priceListDetail: state => {
    if (state.priceList.length === 0) return {};
    const priceListItem = state.priceList.find(
      r => r.priceListNumber === state.priceListDetailNumber
    );

    if (!priceListItem) return {};

    return priceListItem;
  },
};
