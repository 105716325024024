import * as types from './types';
import Vue from 'vue';
import _ from 'lodash';

export default {
  [types.RESET_DATA](state) {
    Vue.set(state, 'projectPhotoList', []);
    Vue.set(state, 'projectPhotoLastRef', null);
    Vue.set(state, 'projectPhotoAllLoaded', false);
    Vue.set(state, 'projectPhoto', {});
    Vue.set(state, 'projectPhotosByAlbum', []);
    Vue.set(state, 'projectPhotosSize', 0);
  },

  [types.SET_LAST_ITEM_REF](state, payload) {
    Vue.set(state, 'projectPhotoLastRef', payload.ref);
  },

  [types.MERGE_DATA_BATCH](state, payload) {
    Vue.set(
      state,
      'projectPhotoList',
      state.projectPhotoList.concat(payload.data)
    );
  },

  [types.ALL_DATA_LOADED](state) {
    Vue.set(state, 'projectPhotoAllLoaded', true);
  },

  [types.PROJECT_PHOTO](state, payload) {
    Vue.set(state, 'projectPhoto', payload);
  },

  [types.PROJECT_PHOTOS_BY_ALBUM](state, payload) {
    Vue.set(state, 'projectPhotosByAlbum', payload);
  },

  [types.PROJECT_PHOTOS_SIZE](state, payload) {
    Vue.set(state, 'projectPhotosSize', payload);
  },

  [types.PROJECT_PHOTO_LIST_ITEM_UPDATE](state, { id, doc }) {
    // update item of projectPhotoList
    const indexProjectPhotoList = state.projectPhotoList.findIndex(
      item => item.id === id
    );
    if (indexProjectPhotoList >= 0) {
      let photo = _.cloneDeep(state.projectPhotoList[indexProjectPhotoList]);

      for (const key in doc) {
        if (Object.hasOwnProperty.call(doc, key) && doc[key] !== undefined) {
          photo[key] = doc[key];
        }
      }
      Vue.set(state.projectPhotoList, indexProjectPhotoList, photo);
    }
    // update item of projectPhotosByAlbum
    const indexProjectPhotosByAlbum = state.projectPhotosByAlbum.findIndex(
      item => item.id === id
    );
    if (indexProjectPhotosByAlbum >= 0) {
      let photo = _.cloneDeep(
        state.projectPhotosByAlbum[indexProjectPhotosByAlbum]
      );
      for (const key in doc) {
        if (Object.hasOwnProperty.call(doc, key) && doc[key] !== undefined) {
          photo[key] = doc[key];
        }
      }
      Vue.set(state.projectPhotosByAlbum, indexProjectPhotosByAlbum, photo);
    }
    // update projectPhoto
    if (state.projectPhoto.id === id) {
      Vue.set(state, 'projectPhoto', {
        ...state.projectPhoto,
        ...doc,
      });
    }
  },

  [types.PROJECT_PHOTO_LIST_ITEM_ADD](state, data) {
    if (data.albumId) {
      state.projectPhotosByAlbum.unshift(data);
    }
    state.projectPhotoList.unshift(data);
  },

  [types.PROJECT_PHOTO_LIST_ITEM_REMOVE](state, id) {
    const indexProjectPhotoList = state.projectPhotoList.findIndex(
      item => item.id === id
    );
    if (indexProjectPhotoList >= 0) {
      state.projectPhotoList.splice(indexProjectPhotoList, 1);
    }
    const indexProjectPhotosByAlbum = state.projectPhotosByAlbum.findIndex(
      item => item.id === id
    );
    if (indexProjectPhotosByAlbum >= 0) {
      state.projectPhotosByAlbum.splice(indexProjectPhotosByAlbum, 1);
    }
  },

  [types.RESET_SELECT_STATE](state) {
    Vue.set(state, 'selectionMode', false);
    Vue.set(state, 'selectedPhotoIds', []);
  },
  [types.CHANGE_SELECT_MODE](state, isSelect) {
    Vue.set(state, 'selectionMode', isSelect);
  },
  [types.UPDATE_SELECTED_IDS](state, id) {
    let selectedPhotoIds = state.selectedPhotoIds;
    if (selectedPhotoIds.includes(id)) {
      var index = selectedPhotoIds.indexOf(id);
      if (index > -1) {
        selectedPhotoIds.splice(index, 1);
      }
    } else {
      selectedPhotoIds.push(id);
    }
    Vue.set(state, 'selectedPhotoIds', _.cloneDeep(selectedPhotoIds));
  },
};
