import moment from 'moment';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { sortBy } from '@/utility/date-time-tool';

export const overtimeMixin = {
  computed: {
    ...mapGetters('setting/app/profile', { currentUser: 'user' }),
    ...mapGetters({ currentWeek: 'time-tracking/time-tracking/currentWeek' }),
  },

  methods: {
    ...mapActions({
      updateTimeTracking: 'time-tracking/time-tracking/update',
      getWeeklyTimeLogs: 'time-tracking/time-tracking/getWeeklyTimeLogs',
      getTimeTrackingList: 'time-tracking/time-tracking/getTimeTrackingList',
    }),

    getWeekOfDate(date) {
      const weekStart = moment(date, 'MM/DD/YYYY').startOf('isoWeek');
      let days = [];
      for (let i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, 'days').format('MM/DD/YYYY'));
      }
      return days;
    },

    calculateOvertime(timeLogList) {
      const result = [];
      let totalHoursOfWeek = 0;
      let overTimePoint = 40;
      let temp = {};
      for (const timeLog of timeLogList) {
        if (timeLog.status !== 'tt-rejected') {
          temp = _.cloneDeep(timeLog);
          temp.overtime = 0;
          temp.hasOvertime = false;
          let duration = temp.override ? temp.overrideHour : temp.loggedHour;
          if (temp.hasLunchBreak) {
            duration += temp.lunchTimeInfo.hour;
          }
          totalHoursOfWeek += duration;

          if (totalHoursOfWeek > overTimePoint) {
            // for overtime
            const overtime = totalHoursOfWeek - overTimePoint;
            overTimePoint = totalHoursOfWeek;
            temp.overtime = overtime;
            temp.hasOvertime = true;
          }

          result.push(temp);
        }
      }

      return result;
    },

    async composeOvertime(date, userId) {
      const days = this.getWeekOfDate(
        new Date(moment(date).format('MM/DD/YYYY'))
      );

      const timeLogs = await this.getWeeklyTimeLogs({
        fromDate: days[0],
        toDate: days[6],
        userId,
      });
      const timeLogsIncludedOvertime = this.calculateOvertime(
        sortBy(timeLogs, 'date', false)
      );
      const refs = [];
      timeLogsIncludedOvertime.forEach(item => {
        refs.push(
          this.updateTimeTracking({
            id: item.id,
            doc: {
              overtime: item.overtime,
              hasOvertime: item.hasOvertime,
            },
          })
        );
      });

      return Promise.all(refs);
    },

    reloadTimelogList() {
      let from = new Date(this.currentWeek[0]);
      let to = new Date(
        moment(new Date(this.currentWeek[6]), 'MM/DD/YYYY').add(1, 'days')
      );
      return this.getTimeTrackingList([
        {
          prop: 'date',
          val: this.$google.firebase.firestore.Timestamp.fromDate(from),
          op: '>=',
        },
        {
          prop: 'date',
          val: this.$google.firebase.firestore.Timestamp.fromDate(to),
          op: '<',
        },
      ]);
    },
  },
};
