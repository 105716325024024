<template>
  <div>
    <f7-list
      class="color-list"
      v-for="(color, index) in colors"
      :key="index"
    >
      <f7-list-input
        :value="color.colorName"
        @input="color.colorName = $event.target.value.trim()"
        class="contact-input"
        placeholder="Enter Color Name"
        clear-button
        @blur="$emit('input', colors)"
      >
        <div slot="label">
          <span style="font-size: var(--f7-font-size)">Color</span>
        </div>
        <f7-icon
          class="cursor-pointer"
          color="red"
          f7="minus_circle_fill"
          slot="media"
          @click.native="handleColorDelete(index)"
        ></f7-icon>
      </f7-list-input>

      <f7-list-item
        class="remove-after"
        v-show="color.photos.length > 0"
        text-color="blue"
      >
        <div class="image-list">
          <div
            class="image-preview"
            v-for="(photo, photoIndex) in color.photos"
            :key="photoIndex"
          >
            <img
              class="image-show"
              :src="photo.thumbnailUrl"
            />
            <div class="img-bg"></div>
            <div
              class="delete-image cursor-pointer"
              @click="handlePhotoDelete(index, photoIndex)"
            >
              <f7-icon
                color="red"
                f7="trash"
              ></f7-icon>
            </div>
          </div>
        </div>
      </f7-list-item>

      <f7-list-item>
        <div class="upload-button">
          <f7-button
            style="border-radius: 1px"
            @click="openFileInput(color)"
            ><f7-icon
              f7="plus"
              size="20"
            ></f7-icon>
            <span style="color: var(--f7-text-color)">
              Upload Photo</span
            ></f7-button
          >
        </div>
      </f7-list-item>
    </f7-list>

    <!-- add more color button -->
    <f7-list class="color-list-last">
      <f7-list-item
        class="cursor-pointer"
        @click="addColorItem"
      >
        <div
          slot="media"
          class="cursor-pointer display-flex align-items-center"
        >
          <f7-icon
            color="red"
            f7="plus_circle"
          ></f7-icon>
        </div>
        <div
          class="list-item-title"
          slot="title"
        >
          Add Color
        </div>
      </f7-list-item>
    </f7-list>

    <image-uploader
      ref="fileInput"
      :preview="false"
      :quality="0.7"
      class="display-none"
      accept="image/*"
      doNotResize="gif"
      :autoRotate="false"
      outputFormat="verbose"
      @input="handleFileUpload"
    ></image-uploader>
  </div>
</template>

<script>
import _ from 'lodash';
import { uuid } from 'vue-uuid';

export default {
  props: {
    value: { type: Array, default: () => [] },
  },

  data() {
    return {
      colors: [],
      color: {},
    };
  },

  watch: {
    value: {
      handler(val) {
        this.colors = _.cloneDeep(val);
      },
      deep: true,
    },
  },

  methods: {
    handleFileUpload(fileData) {
      let data = [];

      let attachments = _.cloneDeep(this.color.photos);
      if (attachments.some(r => r.name === fileData.info.name)) {
        this.$ri.dialog.openWarningDialog({
          title: 'Please choose another one',
          content: 'That file name has already existed!',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
        data = [];
      } else {
        data.push({ ...fileData, thumbnailUrl: fileData.dataUrl });
        attachments = attachments.concat(data);

        this.color.photos = attachments;
        this.$emit('input', this.colors);
      }
      this.$refs.fileInput.$el.lastChild.value = '';
    },

    handleColorDelete(index) {
      this.colors.splice(index, 1);
      this.$emit('input', this.colors);
    },

    handlePhotoDelete(colorIndex, photoIndex) {
      const color = this.colors[colorIndex];
      if (color) {
        color.photos.splice(photoIndex, 1);
        this.$emit('input', this.colors);
      }
    },

    openFileInput(color) {
      this.color = color;
      this.$refs.fileInput.$el.lastChild.click();
    },

    addColorItem() {
      this.colors.push({
        id: uuid.v1(),
        colorName: '',
        photos: [],
      });
      this.$emit('input', this.colors);
    },
  },
};
</script>

<style lang="scss" scoped>
.remove-after ::v-deep .item-inner::after {
  content: none;
}

.image-list {
  margin-top: 0.5rem;
  // margin-bottom: 5px;
  margin-left: calc(var(--f7-list-item-media-margin));
  padding-left: calc(
    var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left)
  );
}

.upload-button {
  margin-bottom: 0.5rem;
  margin-left: calc(var(--f7-list-item-media-margin));
  padding-left: calc(
    var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left)
  );
}

.color-list {
  margin-bottom: -1px;
  margin-top: -1px;
}

.color-list-last {
  margin-top: 0px;
}

.image-uploader ::v-deep #fileInput {
  display: none;
}
.image-preview {
  border-radius: 4px;
  margin-right: 10px;
  width: 75px;
  height: 75px;
  border: 1px solid #e0e0e0;
  position: relative;
  background-position: 50% center;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
  box-sizing: border-box;

  .img-bg {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .delete-image {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: none;
  }

  &:hover {
    .img-bg {
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 1));
    }
    .delete-image {
      display: inline-block;
    }
  }
}
.image-show {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
