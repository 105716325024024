<template>
  <f7-page class="contact-list">
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Estimate</f7-nav-title>
      <f7-nav-right>
        <f7-link
          class="switch-view-tooltip"
          icon-f7="arrow_right_arrow_left_square"
          @click.native="switchView"
        ></f7-link>
        <f7-link
          icon-f7="plus"
          @click.native="openNewEstimate"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <contact-list v-show="currentView === 'client'" />
    <estimate-list v-show="currentView === 'estimate-list'" />
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Menu from '../../../components/menu/Menu.vue';
import ContactList from '../components/list/ContactList.vue';
import EstimateList from '../components/list/EstimateList.vue';

export default {
  components: {
    Menu,
    ContactList,
    EstimateList,
  },
  mounted() {
    this.createTooltip();
  },
  computed: {
    ...mapGetters('estimate/estimate-page/common', ['currentView']),
  },
  methods: {
    ...mapActions('estimate/estimate-page/common', [
      'setCurrentView',
      'setClientType',
    ]),

    switchView() {
      if (this.currentView === 'client') {
        this.setCurrentView('estimate-list');
      } else {
        this.setCurrentView('client');
      }
    },
    openNewEstimate() {
      this.$f7router.navigate(`/estimate/new?view=${this.currentView}`);
    },
    createTooltip() {
      if (!this.$device.desktop) return;

      this.$f7.tooltip.create({
        targetEl: '.switch-view-tooltip',
        text: 'Switch View',
      });
    },
  },
  beforeDestroy() {
    const currentPath = (
      (this.$f7 && this.$f7.views.main.router.currentRoute.route) ||
      {}
    ).path;
    if (!currentPath.includes('/estimate')) {
      this.setCurrentView('client');
      this.setClientType('company');
    }
  },
};
</script>
