import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import { firebase } from '../../../services/firebase.service';
import { SENDER_CONFIG_EMAIL } from '../../../utility/const';
import axiosService from '@/services/axios.service';

export default {
  computed: {
    ...mapGetters({
      card: 'swimlane/card/detail',
    }),
    ...mapGetters('setting/app/profile', ['user']),
    ...mapGetters({
      setting: 'setting/app/system/setting',
    }),
    ...mapGetters('common/app-constant', ['tenantId']),
  },

  methods: {
    ...mapActions({
      getContactById: 'swimlane/client/getContactById',
    }),
    ...mapActions('common/email-notification', [
      'getEmailTemplateByTemplateName',
    ]),
    ...mapActions({
      bindSetting: 'setting/app/system/bindSetting',
    }),

    parseEmailTemplate(htmlContent, valueObject) {
      for (const key in valueObject) {
        // eslint-disable-next-line no-prototype-builtins
        if (valueObject.hasOwnProperty(key)) {
          const value = valueObject[key];
          const reg = new RegExp(`{${key}}`, 'g');
          htmlContent = htmlContent.replace(reg, value);
        }
      }
      return htmlContent;
    },

    async getComposer({ templateName, detailUrl }) {
      const composer = {};

      let clientEmail = '';
      let contact = {};
      try {
        contact =
          this.card.contactId &&
          (await this.getContactById(this.card.contactId));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      }
      const clientName =
        contact &&
        `${contact.firstName || ''} ${contact.lastName || ''}`.trim();
      const contactEmailAddress =
        contact &&
        ((contact.emails || []).find(r => r.code == 'main') || {}).value;
      if (contactEmailAddress) {
        clientEmail = contactEmailAddress;
      }
      // composer.clientName = clientName;
      composer.to = clientEmail || '';
      composer.from = `${this.user.displayName} <${SENDER_CONFIG_EMAIL}>`;
      composer.cc = this.user.email || '';

      let templateEmail =
        await this.getEmailTemplateByTemplateName(templateName);

      let valueObject = {
        //TODO: Support update client logo in setting (DEV-987)
        logo_url:
          'https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/proposal%2F1618341271487Swadley%20Logo.webp?alt=media&token=87d2a6c8-bc88-4225-84f9-de8f321503b0', //(proposal.pages[0] || {}).components[0].data.logo || "",
        client_name: clientName || '',
        roofing_company_name: this.setting.companyName || '',
        roofing_company_address: this.setting.address || '',
        roofing_company_phone: this.setting.phoneNumber || '',
        detail_url: detailUrl,
        project_title: this.card.title,
      };

      composer.message = this.parseEmailTemplate(
        templateEmail.htmlContent,
        valueObject
      );
      composer.subject = this.parseEmailTemplate(
        templateEmail.subject,
        valueObject
      );

      composer.template = templateName;

      composer.attachments = [];
      composer.scheduleSendingTime = firebase.firestore.Timestamp.now();
      composer.isSent = false;
      composer.numberOfAttempt = 0;

      return composer;
    },

    async sendLinkToJobCard(projectId) {
      this.$f7.preloader.show();
      if (_.isEmpty(this.setting)) {
        await this.bindSetting(this.tenantId);
      }

      try {
        const response = await axiosService.post(
          '/tenant/get-anonymous-token',
          {
            entity: 'project',
            entityId: projectId,
          }
        );

        const composer = await this.getComposer({
          templateName: 'link-to-job-card',
          detailUrl: `${import.meta.env.VITE_HOST_DOMAIN}/service-card-view/?token=${
            response.data
          }&tenant=${btoa(this.tenantId)}&id=${projectId}`,
        });

        await this.$refs.sendEmailPopup.open({
          composer: { ...composer, to: '' },
          callback: () => {},
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('[get-anonymous-token]', error.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },
  },
};
