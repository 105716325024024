<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="close()"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Estimate Summary</f7-nav-title>
        <f7-nav-right> </f7-nav-right>
      </f7-navbar>
      <f7-list
        accordion-list
        accordion-opposite
      >
        <f7-list-item
          v-for="(estimate, index) in summary"
          :key="index"
          accordion-item
          :title="estimate.estimateNumber"
        >
          <f7-accordion-content>
            <data-table
              :headers="headers(summaryEstimateByRoofType(estimate).headers)"
              :items="summaryEstimateByRoofType(estimate).data"
              :pageSize="summaryEstimateByRoofType(estimate).data.length"
              class="wrap-text-table dark-header"
            >
              <template v-slot:body="{ item }">
                <td
                  v-for="(h, index) in headers(
                    summaryEstimateByRoofType(estimate).headers
                  )"
                  :key="index"
                  class="text-align-left"
                >
                  <span
                    v-if="h.field === 'name'"
                    :class="{ bold: item.isBold }"
                    >{{ item[h.field] }}</span
                  >
                  <span
                    :class="{ bold: item.isBold }"
                    v-else-if="
                      h.field === 'profitMargin' && item.name !== 'Total'
                    "
                  >
                    {{ item[h.field] | currencyUSD }}
                    <i style="color: grey">{{
                      ` (${(item.crossProfitMarginRatio || 0).toFixed(3)}%)`
                    }}</i>
                  </span>
                  <span
                    :class="{ bold: item.isBold }"
                    v-else
                    >{{ item[h.field] | currencyUSD }}</span
                  >
                </td>
              </template>
            </data-table>
          </f7-accordion-content>
          <f7-row slot="after">
            <f7-button
              fill
              small
              class="button-action margin-right-half"
              @click="exportToExcel(estimate)"
              >Export to Excel</f7-button
            >
            <f7-button
              fill
              small
              class="button-action"
              @click="exportToPdf(estimate)"
              >Export to Pdf</f7-button
            >
          </f7-row>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import DataTable from '@/components/datatables';
import { exportEstimateSummaryToExcel } from '../../utility/excel-export';
import { exportEstimateSummaryToPdf } from '../../utility/pdf-export';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import estimateCalculation from '../../mixin/estimateCalculation';

export default {
  mixins: [estimateCalculation],

  components: { DataTable },

  props: {
    project: { type: Object, default: () => {} },
    estimateList: { type: Array, default: () => [] },
  },

  data: () => {
    return {
      popupOpened: false,
      summary: [],
    };
  },

  computed: {
    ...mapGetters('dashboard/roof-type', ['roofTypeList']),

    summaryEstimateByRoofType() {
      return estimate => {
        let listRoofs = _.cloneDeep(this.roofTypeList);
        listRoofs.push({
          id: 'all-roof-type',
          code: 'roof-type',
          value: 'all-roof-type',
          displayName: 'Multiple Roof Types',
        });
        const roofTypes = estimate.buildingList.map(r => r.roofType);
        let headers =
          listRoofs.filter(r => roofTypes.some(i => i === r.value)) || [];

        let data = [];

        data = estimate.buildingList
          .map(building => {
            let object = { totals: 0 };
            for (let h of headers) {
              object[h.value] =
                building.roofType === h.value
                  ? this.totalSubtotal(estimate, building)
                  : 0;
              object['totals'] += parseFloat(object[h.value]);
            }

            return {
              rooftype: building.roofType,
              name: building.buildingName,
              subTotal: this.totalSubtotal(estimate, building),
              profitMargin: this.totalProfit(estimate, building),
              taxAmount: this.totalTax(this.project, building, estimate),
              totalCost: this.totalCost(this.project, building, estimate),
              crossProfitMarginRatio: estimate.crossProfitMarginRatio || 0,
              ...object,
            };
          })
          .sort((a, b) => this.sortBy(a.name, b.name));

        let total = {
          name: 'Total',
          totals: 0,
          profitMargin: 0,
          netSales: 0,
          isBold: true,
          isCost: true,
        };

        for (let h of headers) {
          total[h.value] = this.sumArray(
            data.map(r => {
              return r.rooftype === h.value ? r.subTotal : 0;
            })
          );
          total['profitMargin'] = this.sumArray(
            data.map(r => {
              return r.profitMargin;
            })
          );
          total['taxAmount'] = this.sumArray(
            data.map(r => {
              return r.taxAmount;
            })
          );
          total['totalCost'] = this.sumArray(
            data.map(r => {
              return r.totalCost;
            })
          );
          total['totals'] = this.sumArray(
            data.map(r => {
              return r.totals;
            })
          );
        }

        data.push(total);

        headers.unshift({
          displayName: '',
          value: 'name',
        });
        headers.push({
          displayName: 'Sub Total',
          value: 'totals',
        });
        headers.push({
          displayName: 'Profit Margin',
          value: 'profitMargin',
        });
        headers.push({
          displayName: 'Tax Amount',
          value: 'taxAmount',
        });
        headers.push({
          displayName: 'Total Cost',
          value: 'totalCost',
        });

        return {
          headers,
          data,
        };
      };
    },

    headers() {
      return headers => {
        return headers.map(x => ({
          field: x.value,
          text: x.displayName,
          align: 'left',
          sortable: false,
          value: x.value,
        }));
      };
    },
  },

  methods: {
    ...mapActions('dashboard/roof-type', ['bindRoofTypeList']),
    ...mapActions('dashboard/estimate', ['getBuildingList']),

    close() {
      this.popupOpened = false;
      this.summary = [];
    },

    show() {
      this.popupOpened = true;
      this.bindRoofTypeList();
      this.initData();
    },

    async initData() {
      this.$f7.preloader.show();
      const list = this.estimateList.filter(estimate => estimate.isDefault);
      for (const estimate of list) {
        const buildings = await this.getBuildingList({
          estimateId: estimate.id,
        });
        this.summary.push({
          ...estimate,
          buildingList: buildings,
        });
      }
      this.$f7.preloader.hide();
    },

    sumArray(arr) {
      let res = 0;
      if (!_.isEmpty(arr)) {
        res = arr.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
      }
      return res;
    },

    sortBy(a, b) {
      return a > b ? 1 : b > a ? -1 : 0;
    },

    exportToExcel(estimate) {
      exportEstimateSummaryToExcel({
        headers: this.headers(this.summaryEstimateByRoofType(estimate).headers),
        estimateNumber: estimate.estimateNumber,
        values: this.summaryEstimateByRoofType(estimate).data,
      });
    },
    exportToPdf(estimate) {
      exportEstimateSummaryToPdf({
        headers: this.headers(this.summaryEstimateByRoofType(estimate).headers),
        estimateNumber: estimate.estimateNumber,
        values: this.summaryEstimateByRoofType(estimate).data,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: 600;
}
.dark-header::v-deep .card-content table thead {
  background: #bbbbbb;
  th {
    color: white;
    font-weight: 500;
  }
}
</style>
