/* eslint-disable no-unused-vars */
import _ from 'lodash';

function getPercentValue(percent, value) {
  return _.round(((percent || 0) * (value || 0)) / 100.0, 2);
}

function materialSubTotal(building) {
  let result = 0;
  (building.productData || []).forEach(section => {
    if (section.sectionId !== 'labor') {
      result += (section.productList || []).reduce(
        (accumulator, item) =>
          (item.orderQty || 0) * (item.price || 0) + accumulator,
        0
      );
    }
  });
  return result;
}

function materialMisc(estimate, building) {
  return getPercentValue(
    (estimate && estimate.miscPercent) || 0,
    materialNetSales(estimate, building)
  );
}

function materialNetSales(estimate, building) {
  const subTotal = materialSubTotal(building) || 0;
  const crossProfitMarginRatio =
    (estimate && estimate.crossProfitMarginRatio) || 0;
  const miscPercent = (estimate && estimate.miscPercent) || 0;

  return subTotal / ((100 - (crossProfitMarginRatio + miscPercent)) / 100) || 0;
}

function materialProfit(estimate, building) {
  return getPercentValue(
    (estimate && estimate.crossProfitMarginRatio) || 0,
    materialNetSales(estimate, building)
  );
}

function laborSubTotal(building) {
  let subTotals = 0;
  (building.productData || []).forEach(section => {
    if (section.sectionId === 'labor') {
      subTotals += (section.productList || []).reduce(
        (currentValue, item) =>
          (item.orderQty || 0) * (item.price || 0) + currentValue,
        0
      );
    }
  });
  return subTotals;
}

function laborNetSales(estimate, building) {
  const subTotal = laborSubTotal(building) || 0;
  const crossProfitMarginRatio =
    (estimate && estimate.crossProfitMarginRatio) || 0;

  return subTotal / ((100 - crossProfitMarginRatio) / 100) || 0;
}

function laborProfit(estimate, building) {
  return getPercentValue(
    (estimate && estimate.crossProfitMarginRatio) || 0,
    laborNetSales(estimate, building)
  );
}

export default {
  // Labor
  totalLaborAmount: (state, getters, rootState, rootGetters) => {
    return getters.laborList.reduce(
      (value, currItem) => value + currItem.amount,
      0
    );
  },

  laborList: (state, getters, rootState, rootGetters) => {
    return state.timeLogList;
  },

  // PO
  totalPurchaseOrderAmount: (state, getters, rootState, rootGetters) => {
    return (getters.materialsCosts || []).reduce((preValue, currItem) => {
      return preValue + currItem.actualAmount;
    }, 0);
  },

  materialsCosts: (state, getters, rootState, rootGetters) => {
    return state.purchaseOrderList
      .filter(r => r.status !== 'cancel')
      .map(item => {
        return {
          createdAt: item.createdAt,
          taskNumber: item.taskNumber,
          orderAmount: getters.getOrderAmount(item),
          actualAmount: getters.getActualAmount(item),
          orderDate: item.orderDate,
          attachmentFiles: getters.getAttachmentLink(item),
        };
      })
      .sort((a, b) => a.createdAt - b.createdAt);
  },

  getOrderAmount: (state, getters, rootState, rootGetters) => po => {
    return po.items.reduce((pre, curr) => {
      return (curr.orderQty || 0) * curr.price + pre;
    }, 0);
  },

  getActualAmount: (state, getters, rootState, rootGetters) => po => {
    return po.items.reduce((pre, curr) => {
      return (curr.receivedQty || 0) * curr.price + pre;
    }, 0);
  },

  getAttachmentLink: (state, getters, rootState, rootGetters) => po => {
    let result = '';
    if (po.attachmentFiles) {
      po.attachmentFiles.forEach(file => {
        result += `<a href='${file.url}' target='_blank' class='text-color-orange margin-left link external'>${file.fileName}</a>`;
      });
    }
    return result;
  },

  // Expense
  totalExpenseAmount: (state, getters, rootState, rootGetters) => {
    return getters.expenseList.reduce((value, item) => value + item.amount, 0);
  },

  expenseList: (state, getters, rootState, rootGetters) => {
    return state.expenseList;
  },

  // Estimate
  estimateList: (state, getters, rootState, rootGetters) => state.estimateList,

  buildings: state => state.buildings,

  estimateMaterialCost: (state, getters, rootState, rootGetters) => {
    return getters.buildings.reduce((prev, building) => {
      return prev + materialSubTotal(building);
    }, 0);
  },

  estimateMiscCost: (state, getters, rootState, rootGetters) => {
    return getters.buildings.reduce((prev, building) => {
      return prev + materialMisc(building.estimate, building);
    }, 0);
  },

  estimateLaborCost: (state, getters, rootState, rootGetters) => {
    return getters.buildings.reduce((prev, building) => {
      return prev + laborSubTotal(building);
    }, 0);
  },

  estimateAccountReceivable: (state, getters, rootState, rootGetters) => {
    return getters.buildings.reduce((prev, building) => {
      return (
        prev +
        materialProfit(building.estimate, building) +
        laborProfit(building.estimate, building)
      );
    }, 0);
  },

  invoiceIsPayment: (state, getters, rootState, rootGetters) => {
    return (state.invoiceList || []).reduce((prev, invoice) => {
      return prev + (invoice.paidAmount || 0);
    }, 0);
  },

  invoiceList: state => {
    return state.invoiceList;
  },

  recurringInvoiceList: state => {
    return state.recurringInvoiceList;
  },
};
