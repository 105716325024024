import Vue from 'vue';
import * as types from './types';

export default {
  [types.SET_PROPERTY_LIST](state, payload) {
    Vue.set(state, 'propertyList', payload);
  },

  [types.SET_PROPERTY](state, payload) {
    Vue.set(state, 'property', payload);
  },
};
