<template>
  <div>
    <multiple-table
      :headers="headersFilter"
      :items="productDataSorted"
      :pageSize="0"
      :lengthWidth="lengthWidth"
      :color="color"
      :textColor="textColor"
      :isEditing="isEditing"
      @addSection="$emit('addSection', $event)"
      @addNewItem="$emit('addNewItem', $event)"
    >
      <!-- Body -->
      <template v-slot:multipleBody="{ item, sectionId }">
        <!-- <td v-if="$f7.device.desktop || $f7.device.ipad">
          <f7-link @click="onEdit(item, sectionId)">{{ item.sku }}</f7-link>
        </td> -->
        <td>
          <span v-if="!isEditing">
            <f7-link @click="onEdit(item, sectionId)">
              {{ item.productItem }}
            </f7-link>
          </span>

          <product-auto-complete-input
            v-else
            :value="item.productItem"
            :data="productsByCategory(item)"
            @selected="changeProductItem(sectionId, item, $event.target.value)"
          ></product-auto-complete-input>
        </td>

        <td data-collapsible-title="Note">
          <long-content-block
            :seedId="`note-${item.id}`"
            :content="item.proposalNote || ''"
          ></long-content-block>
        </td>

        <td class="label-cell">
          {{ subCategoryName(item.subCategoryId) }}
        </td>

        <td
          title="Order Qty"
          class="text-align-right bg-total"
        >
          <p style="width: 85px">
            {{ orderQty(item) + ' ' + (item.packaging || '') }}
          </p>
        </td>

        <td
          title="Actual Qty"
          class="text-align-right"
        >
          {{ actualQty(item) + ' ' + (item.uom || '') }}
        </td>

        <td title="Price">
          <cell-number-input
            placeholder="Price"
            numberType="currency"
            :value="item.price"
            :readonly="!isEditing"
          ></cell-number-input>
        </td>

        <td class="text-align-right">
          {{ (orderQty(item) * (item.price || 0)) | currencyUSD }}
        </td>
        <td
          v-if="$f7.device.desktop || $f7.device.ipad"
          class="text-align-center"
        >
          <f7-checkbox
            :checked="!!item.priceOverride"
            disabled
          ></f7-checkbox>
        </td>

        <td
          v-if="$f7.device.desktop || $f7.device.ipad"
          title="Color"
        >
          <f7-link
            v-for="(color, index) in item.colorPhotos || []"
            :key="index"
            @click="clickPhoto(color)"
            :id="`review_step_${item.id}_${color.id}`"
          >
            {{
              `${color.colorName}${
                index + 1 === (item.colorPhotos || []).length ? '' : ',&nbsp;'
              }`
            }}
          </f7-link>
        </td>
        <td v-if="$f7.device.desktop || $f7.device.ipad">
          <span>{{ item.manufacturer }}</span>
        </td>
        <td v-if="$f7.device.desktop || $f7.device.ipad">
          <ul class="technical-data">
            <li
              v-for="(attach, index) in item.technicalData"
              :key="index"
              :id="`${item.id}-${attach.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`"
            >
              <a
                class="display-block"
                @click="openLink(item, attach)"
              >
                {{ attach.name }}
              </a>
            </li>
          </ul>
        </td>
        <td
          v-if="$f7.device.desktop || $f7.device.ipad"
          title="Waste Factor"
          class="text-align-right"
        >
          {{ item.wasterFactor }}
        </td>

        <td
          v-if="isEditing"
          class="text-align-right"
        >
          <a href="#">
            <i
              class="f7-icons"
              style="color: red"
              >trash</i
            >
          </a>
        </td>
      </template>

      <!-- Footer -->
      <template v-slot:footer-multiple="{ sectionId }">
        <tr>
          <td :colspan="$f7.device.desktop || $f7.device.ipad ? 5 : 4"></td>
          <td class="text-align-right text-sub-total">Total:</td>
          <td
            colspan="2"
            class="text-align-right bg-total text-sub-total"
          >
            {{ sectionBySectionId(sectionId).totalCost | currencyUSD }}
          </td>
          <td
            v-if="$device.desktop"
            colspan="5"
          ></td>
          <td
            v-if="isEditing"
            colspan="1"
          ></td>
        </tr>
        <tr v-if="sectionId === lastMaterialSectionId">
          <td :colspan="$f7.device.desktop || $f7.device.ipad ? 3 : 2">
            <div class="text-align-right">
              <f7-link
                class="text-color-orange"
                target="_blank"
                external
                href="https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/sales_tax_application.pdf?alt=media&token=108b7062-453f-4850-86ca-d40b60a87bd4"
              >
                <span
                  >Base on your input information. You
                  <strong
                    ><i>{{ taxMessage(doesApplyTaxForMaterial()) }}</i>
                  </strong>
                  tax on Material
                </span>
              </f7-link>
            </div>
            <br />
          </td>
          <td
            colspan="3"
            class="bg-total"
          >
            <div>
              <div class="text-sub-total">Sub Total</div>
              <div class="sale-tax-container">
                <div class="sale-tax-text">Misc</div>
                <cell-number-input
                  numberType="percent"
                  placeholder="Misc"
                  :value="estimate.miscPercent"
                  @done="
                    saveEstimateValueFromBuilding(
                      'miscPercent',
                      parseFloat($event)
                    )
                  "
                ></cell-number-input>
              </div>
              <div class="sale-tax-container">
                <div class="sale-tax-text">Material Profit</div>
                <cell-number-input
                  numberType="percent"
                  placeholder="Profit Margin"
                  :value="estimate.crossProfitMarginRatio || null"
                  :fractionDigits="3"
                  @done="
                    saveEstimateValueFromBuilding(
                      'crossProfitMarginRatio',
                      parseFloat($event || 0) > 99.999
                        ? 99.999
                        : parseFloat($event || 0)
                    )
                  "
                ></cell-number-input>
              </div>

              <div class="text-bold">Material Net Sales</div>
              <div class="sale-tax-container">
                <div class="sale-tax-text">Material Tax</div>
                <cell-number-input
                  numberType="percent"
                  placeholder="Sales Tax"
                  :readonly="!doesApplyTaxForMaterial()"
                  :value="doesApplyTaxForMaterial() ? building.saleTax : 0.0"
                  :fractionDigits="3"
                  @done="changeTax(parseFloat($event))"
                ></cell-number-input>
              </div>
              <div class="text-bold">Total Material Cost</div>
            </div>
          </td>
          <td
            colspan="2"
            class="bg-total"
          >
            <div class="text-sub-total text-align-right">
              {{ materialSubTotal() | currencyUSD }}
            </div>
            <div class="text-align-right">
              {{ materialMisc() | currencyUSD }}
            </div>
            <div class="text-align-right">
              {{ materialProfit() | currencyUSD }}
            </div>
            <div class="text-bold text-align-right">
              {{ materialNetSales() | currencyUSD }}
            </div>
            <div class="text-align-right">
              {{ materialTax() | currencyUSD }}
            </div>
            <div class="text-bold text-align-right">
              {{ (materialNetSales() + materialTax()) | currencyUSD }}
            </div>
          </td>
          <td
            v-if="$device.desktop"
            colspan="5"
          ></td>
          <td
            v-if="isEditing"
            colspan="1"
          ></td>
        </tr>

        <tr v-if="sectionId === 'labor'">
          <td :colspan="$f7.device.desktop || $f7.device.ipad ? 3 : 2">
            <div class="text-align-right">
              <f7-link
                class="text-color-orange"
                target="_blank"
                external
                href="https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/sales_tax_application.pdf?alt=media&token=108b7062-453f-4850-86ca-d40b60a87bd4"
              >
                <span
                  >Base on your input information. You
                  <strong
                    ><i>{{ taxMessage(doesApplyTaxForLabor()) }}</i>
                  </strong>
                  tax on Labor
                </span>
              </f7-link>
            </div>
          </td>
          <td
            colspan="3"
            class="bg-total"
          >
            <div>
              <div class="text-sub-total">Sub Total</div>

              <div class="sale-tax-container">
                <div class="sale-tax-text">Labor Profit</div>
                <cell-number-input
                  numberType="percent"
                  placeholder="Profit Margin"
                  :value="estimate.crossProfitMarginRatio || null"
                  :fractionDigits="3"
                  @done="
                    saveEstimateValueFromBuilding(
                      'crossProfitMarginRatio',
                      parseFloat($event || 0) > 99.999
                        ? 99.999
                        : parseFloat($event || 0)
                    )
                  "
                ></cell-number-input>
              </div>

              <div class="text-bold">Labor Net Sales</div>

              <div class="sale-tax-container">
                <div class="sale-tax-text">Labor Tax</div>
                <cell-number-input
                  numberType="percent"
                  placeholder="Sales Tax"
                  :readonly="!doesApplyTaxForLabor()"
                  :value="doesApplyTaxForLabor() ? building.saleTax : 0.0"
                  :fractionDigits="3"
                  @done="changeTax(parseFloat($event))"
                ></cell-number-input>
              </div>

              <div class="text-bold">Total Labor Cost</div>
            </div>
          </td>

          <td
            colspan="2"
            class="bg-total"
          >
            <div class="text-sub-total text-align-right">
              {{ laborSubTotal() | currencyUSD }}
            </div>
            <div class="text-align-right">
              {{ laborProfit() | currencyUSD }}
            </div>
            <div class="text-bold text-align-right">
              {{ laborNetSales() | currencyUSD }}
            </div>
            <div class="text-align-right">
              {{ laborTax() | currencyUSD }}
            </div>
            <div class="text-bold text-align-right">
              {{ (laborNetSales() + laborTax()) | currencyUSD }}
            </div>
          </td>
          <td
            v-if="$device.desktop"
            colspan="5"
          ></td>
          <td
            v-if="isEditing"
            colspan="1"
          ></td>
        </tr>
      </template>

      <!-- profit-margin -->
      <template v-slot:profit-margin="{ headers, color, textColor }">
        <div class="container-profit-margin">
          <div
            :style="`background: ${color}; color: ${textColor}; ; padding:10px 0; width:100%`"
          >
            <div class="margin-left-half align-items-center section-name">
              Profit Margin
            </div>
          </div>
          <div style="width: 95%">
            <ranger-progress
              :min="0"
              :max="100"
              :value="crossProfitMarginRatio"
              :changed="dragEnd"
            />
          </div>
          <table
            :style="
              $device.desktop
                ? 'padding: 10px; margin-top:10px; width:520px'
                : `padding: 10px; margin-top:10px;`
            "
            class="bg-total"
          >
            <tr style="width: 100%">
              <td
                colspan="3"
                class="bg-total"
              >
                <div class="text-sub-total">Total Cost</div>
              </td>
              <td
                colspan="1"
                class="bg-total"
              ></td>
              <td
                colspan="2"
                class="bg-total"
              >
                <div class="text-sub-total text-align-right">
                  {{
                    (materialSubTotal() + materialMisc() + laborSubTotal())
                      | currencyUSD
                  }}
                </div>
              </td>
            </tr>
            <tr style="width: 100%">
              <td
                colspan="3"
                class="bg-total"
              >
                <div class="sale-tax-text">Gross Profit Margin</div>
              </td>
              <td
                colspan="1"
                class="bg-total"
              >
                <cell-number-input
                  numberType="percent"
                  placeholder="Profit Margin"
                  :value="estimate.crossProfitMarginRatio || null"
                  :fractionDigits="3"
                  @done="
                    saveEstimateValueFromBuilding(
                      'crossProfitMarginRatio',
                      parseFloat($event || 0) > 99.999
                        ? 99.999
                        : parseFloat($event || 0)
                    )
                  "
                ></cell-number-input>
              </td>
              <td
                colspan="2"
                class="bg-total"
              >
                <div class="text-align-right">
                  {{ (materialProfit() + laborProfit()) | currencyUSD }}
                </div>
              </td>
            </tr>
            <tr style="width: 100%">
              <td
                colspan="3"
                class="bg-total"
              >
                <div class="text-bold primary-color">Net Sales</div>
              </td>
              <td
                colspan="1"
                class="bg-total"
              ></td>
              <td
                colspan="2"
                class="bg-total"
              >
                <div class="text-bold text-align-right primary-color">
                  {{ (materialNetSales() + laborNetSales()) | currencyUSD }}
                </div>
              </td>
            </tr>
            <tr style="width: 100%">
              <td
                colspan="3"
                class="bg-total"
              >
                <div class="sale-tax-text">Sales Tax</div>
              </td>
              <td
                colspan="1"
                class="bg-total"
              >
                <cell-number-input
                  v-show="
                    (doesApplyTaxForLabor() && doesApplyTaxForMaterial()) ||
                    (!doesApplyTaxForLabor() && !doesApplyTaxForMaterial())
                  "
                  numberType="percent"
                  placeholder="Sales Tax"
                  :readonly="
                    !(doesApplyTaxForLabor() && doesApplyTaxForMaterial())
                  "
                  :value="
                    doesApplyTaxForLabor() && doesApplyTaxForMaterial()
                      ? building.saleTax
                      : 0.0
                  "
                  :fractionDigits="3"
                  @done="
                    saveEstimateValueFromBuilding('saleTax', parseFloat($event))
                  "
                ></cell-number-input>
              </td>
              <td
                colspan="2"
                class="bg-total"
              >
                <div class="text-align-right">
                  {{ (materialTax() + laborTax()) | currencyUSD }}
                </div>
              </td>
            </tr>
            <tr style="width: 100%">
              <td
                colspan="3"
                class="bg-total"
              >
                <div class="text-bold">Retail Total</div>
              </td>
              <td
                colspan="1"
                class="bg-total"
              ></td>
              <td
                colspan="2"
                class="bg-total"
              >
                <div class="text-bold text-align-right">
                  <cell-number-input
                    class="cell-input-bold"
                    numberType="currency"
                    placeholder="Retail Total"
                    :value="retailTotal"
                    @done="calcProfitMarginFromRetailTotal($event)"
                  ></cell-number-input>
                </div>
              </td>
            </tr>
            <tr
              style="width: 100%"
              v-if="estimate.businessCode != 'service'"
            >
              <td
                colspan="3"
                class="bg-total"
              >
                <div class="text-sub-total">Cost Per SQ</div>
              </td>
              <td
                colspan="1"
                class="bg-total"
              ></td>
              <td
                colspan="2"
                class="bg-total"
              >
                <div class="text-sub-total text-align-right">
                  {{ costPerSqft | currencyUSD }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </template>
    </multiple-table>
    <product-editor-popup ref="productEditorRef" />
    <f7-photo-browser
      :photos="productPhotos"
      theme="dark"
      :ref="`detail_pageDark`"
      type="popup"
    ></f7-photo-browser>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MultipleTable from './MultipleTable';
import ProductAutoCompleteInput from '../inputs/ProductAutoCompleteInput.vue';
import RangerProgress from '../inputs/RangerProgress.vue';
import CellNumberInput from '@/components/inputs/CellNumberInput.vue';
import LongContentBlock from '@/components/blocks/LongContentBlock.vue';
import {
  isVideo,
  isImage,
  createMediaArray,
  openAttackLink,
} from '@/utility/common';
import buildingMixins from '../../utility/building-mixins';
import {
  BUSINESS_CODE_SERVICE,
  TAX_MATERIAL_ONLY,
} from '../../../../utility/const';

import ProductEditorPopup from '../popup/ProductEditorPopup.vue';
import _ from 'lodash';
import { ptbh } from '../../../../utility/number-tool';

export default {
  components: {
    MultipleTable,
    ProductAutoCompleteInput,
    RangerProgress,
    CellNumberInput,
    ProductEditorPopup,
    LongContentBlock,
  },

  mixins: [buildingMixins],

  props: {
    lengthWidth: [String, Number],
    color: String,
    textColor: String,
    isEditing: Boolean,
  },

  data() {
    return {
      data: [],
      headers: [
        // {
        //   text: "SKU",
        //   align: "left",
        //   sortable: false,
        //   value: "sku"
        // },
        {
          text: 'Product Item',
          align: 'left',
          sortable: false,
          value: 'productItem',
        },
        {
          text: 'Proposal Note',
          value: 'proposalNote',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Sub Category',
          value: 'subCategory',
          sortable: false,
          align: 'left',
        },

        {
          text: 'Order Qty',
          value: 'Order Qty',
          sortable: false,
          align: 'right',
          cssClass: 'bg-gray',
        },

        {
          text: 'Qty',
          value: 'actualQty',
          sortable: false,
          align: 'right',
        },

        // {
        //   text: "Packaging",
        //   value: "packaging",
        //   sortable: false,
        //   align: "left"
        // },
        // {
        //   text: "Unit Packs",
        //   value: "unitPack",
        //   sortable: false,
        //   align: "left"
        // },
        // {
        //   text: "Unit Size",
        //   value: "unitSize",
        //   sortable: false,
        //   align: "left"
        // },
        // {
        //   text: "UoM",
        //   value: "UoM",
        //   sortable: false,
        //   align: "left"
        // },

        {
          text: 'Price',
          value: 'price',
          sortable: false,
          align: 'right',
        },

        {
          text: 'Total Cost',
          value: 'totalCost',
          sortable: false,
          align: 'right',
        },
        {
          text: 'Price Override',
          value: 'priceOverride',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Color',
          value: 'color',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Manufacturer',
          value: 'manufacturer',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Technical Data',
          value: 'technicalData',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Waste Factor',
          value: 'waster_factor',
          sortable: false,
          align: 'right',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ],
      productPhotos: [],

      crossProfitMarginRatio: 0,
    };
  },

  mounted() {
    this.crossProfitMarginRatio = this.estimate.crossProfitMarginRatio || 0;
  },

  watch: {
    estimate: {
      deep: true,
      handler(val) {
        this.crossProfitMarginRatio = val.crossProfitMarginRatio || 0;
      },
    },

    netSales: {
      deep: true,
      handler(val) {
        if (val !== this.currentBuilding?.netSales) {
          this.saveBuildingValue('netSales', val);
        }
      },
    },
  },

  computed: {
    ...mapGetters('estimate/estimate-page/estimate', ['currentBuilding']),
    ...mapGetters({
      subCategories: 'estimate/estimate-page/estimate/sub-category/objectList',
      vendors: 'estimate/estimate-page/estimate/vendor/objectList',
      subCategoryById:
        'estimate/estimate-page/estimate/sub-category/subCategoryById',
      currentPriceList: 'estimate/estimate-page/price-list/currentPriceList',
    }),
    ...mapGetters('estimate/estimate-page/estimate', ['estimate']),
    ...mapGetters('estimate/estimate-page/project', ['project']),

    ...mapGetters('estimate/estimate-page/estimate', [
      'materialSubTotal',
      'materialMisc',
      'materialProfit',
      'materialTax',
      'materialNetSales',
      'laborSubTotal',
      'laborNetSales',
      'laborProfit',
      'laborTax',
      'doesApplyTaxForMaterial',
      'doesApplyTaxForLabor',
      'retailTotal',
      'orderQty',
      'netSales',
    ]),

    isServiceProject() {
      return this.project.businessCode === BUSINESS_CODE_SERVICE;
    },

    productDataSorted() {
      const list = _.cloneDeep(this.currentBuilding.productData || [])
        .map(section => {
          const productListOrder = (section.productList || []).filter(
            item => !!item.actualQty || !!item.orderQty
          );
          return {
            ...section,
            productList: productListOrder,

            totalCost: (productListOrder || [])
              .map(
                product => (this.orderQty(product) || 0) * (product.price || 0)
              )
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              ),
          };
        })
        .sort((a, b) => {
          return a.index - b.index;
        });

      return list;
    },

    headersFilter() {
      let h = this.headers;
      if (!(this.$f7.device.desktop || this.$f7.device.ipad)) {
        h = h.filter(
          item =>
            item.value != 'sku' &&
            item.value != 'color' &&
            item.value != 'manufacturer' &&
            item.value != 'technicalData' &&
            item.value != 'waster_factor' &&
            item.value != 'priceOverride'
        );
      }

      if (this.isEditing) {
        return h;
      }

      return h.filter(x => x.value !== 'actions');
    },

    taxMessage() {
      return doesPayTax => {
        const payTax = doesPayTax ? 'WILL PAY' : 'WILL NOT PAY';
        return payTax;
      };
    },

    shouldShowSaleWarning() {
      if (
        this.estimate.businessCode === 'service' &&
        this.estimate.taxApplyType === TAX_MATERIAL_ONLY
      ) {
        return false;
      }

      return (
        !!(this.project || {}).taxExempt === false &&
        parseFloat(this.building.saleTax) !== 0 &&
        this.building.saleTax !== null
      );
    },

    // Custom
    costPerSqft() {
      return this.totalArea
        ? (this.materialNetSales() +
            this.laborNetSales() +
            this.materialTax() +
            this.laborTax()) /
            this.totalArea
        : 0;
    },

    totalArea() {
      return (
        parseFloat(this.currentBuilding.fieldArea || 0) +
        parseFloat(this.currentBuilding.wallArea || 0)
      );
    },

    subCategoryName() {
      return subCategoryId => {
        return this.subCategoryById(subCategoryId).name || '';
      };
    },

    lastMaterialSectionId() {
      const sectionList = this.productDataSorted
        .filter(item => item.productList && item.productList.length > 0)
        .map(item => {
          return { sectionId: item.sectionId };
        })
        .filter(item => item.sectionId !== 'labor');

      return (sectionList[sectionList.length - 1] || {}).sectionId;
    },

    sectionBySectionId() {
      return sectionId => {
        const section =
          this.productDataSorted.find(r => r.sectionId === sectionId) || {};
        return section;
      };
    },

    actualQty() {
      return product => {
        const numberOfCopy = this.building.numberOfCopy || 1;
        const actualQty = (product.actualQty || 0) * numberOfCopy;
        return actualQty;
      };
    },
  },

  methods: {
    ...mapActions('estimate/estimate-page/estimate', [
      'updateProductData',
      'updateBuilding',
    ]),

    ...mapActions({
      updateProduct: 'product/product-item-page/product-item/update',
    }),

    ...mapActions('estimate/estimate-page/price-list', [
      'updatePriceList',
      'createHistory',
      'getPriceListById',
    ]),

    changeTax(tax) {
      const promises = [];
      promises.push(this.saveBuildingValue('saleTax', tax));
      if (this.estimate?.businessCode === BUSINESS_CODE_SERVICE) {
        promises.push(this.saveEstimateValueFromBuilding('saleTax', tax));
        //update tax for rest buildings
        const buildings = (this.estimate.buildings || []).filter(
          r => r.buildingId !== this.currentBuilding.id
        );
        for (const building of buildings) {
          promises.push(
            this.updateBuilding({
              estimateId: this.estimate.id,
              buildingId: building.buildingId,
              building: {
                saleTax: tax,
              },
            })
          );
        }
      }
      return Promise.all(promises);
    },

    openLink(item, attach) {
      this.productPhotos = createMediaArray(item.technicalData);
      openAttackLink(attach, this.productPhotos, this.$refs[`detail_pageDark`]);
    },

    async onEdit(item, sectionId) {
      const editData = await this.$refs.productEditorRef.startEditor(
        'Edit Product',
        item
      );
      if (!editData) return;
      this.updateProductInfoAndPrice(sectionId, editData);
    },

    updateProductInfoAndPrice(sectionId, editData) {
      const product = editData.productData;
      const productData = this.currentBuilding.productData || [];
      let section = productData.find(item => item.sectionId === sectionId);
      const sectionProducts = _.cloneDeep(section.productList);
      //update product for estimate
      let index = sectionProducts.findIndex(r => r.id == product.id);
      if (index < 0) {
        return;
      }
      sectionProducts[index] = {
        ...product,
        vendorPrice: product.price,
        markup: editData.markup,
        price: editData.price,
        internalNote: editData.internalNote,
        proposalNote: editData.proposalNote,
      };

      this.saveProductData({
        sectionId: sectionId,
        productList: sectionProducts,
      });

      //update product from product catalog
      this.updateProduct({
        id: product.id,
        doc: {
          sku: product.sku,
          productItem: product.productItem,
          categoryId: product.categoryId,
          subCategoryId: product.subCategoryId,
          price: product.price,
          wasterFactor: product.wasterFactor,
          vendorId: product.vendorId,
          uom: product.uom,
          unitSize: product.unitSize,
          unitPack: product.unitPack,
          note: product.note,
          technicalData: product.technicalData,
          packaging: product.packaging,
          photos: product.photos,
          color: product.color,
          manufacturer: product.manufacturer,
        },
      });
      //update price for price list
      this.getPriceListById(this.currentPriceList.id).then(priceList => {
        if (priceList) {
          let productRefs = _.cloneDeep(priceList.productRefs) || [];
          let priceIndex = productRefs.findIndex(
            r => r.productId == product.id
          );
          if (priceIndex >= 0) {
            productRefs[priceIndex] = {
              ...productRefs[priceIndex],
              vendorPrice: product.price,
              price: editData.price,
              markup: editData.markup,
              internalNote: editData.internalNote,
              proposalNote: editData.proposalNote,
            };
            this.updatePriceList({
              id: this.currentPriceList.id,
              doc: { productRefs },
            });
            const oldPrice = priceList.productRefs[priceIndex].price;
            if (product.price != oldPrice) {
              this.createHistory({
                priceListId: this.currentPriceList.id,
                productId: productRefs[priceIndex].productId,
                oldPrice: oldPrice,
                newPrice: editData.price,
                productItem: product.productItem,
                manufacturer: product.manufacturer,
                vendorId: product.vendorId,
              });
            }
          }
        }
      });
    },

    dragEnd(values) {
      this.saveEstimateValueFromBuilding('crossProfitMarginRatio', values);
    },

    clickPhoto(item) {
      this.productPhotos = (item || {}).photos || [];
      if (this.productPhotos.length > 0) {
        setTimeout(() => this.$refs[`detail_pageDark`].open(0), 100);
      }
    },

    createColorPhotoTooltip() {
      let productList = [];
      this.productDataSorted.forEach(items => {
        productList = productList.concat(items.productList);
      });
      productList.forEach(item => {
        (item.colorPhotos || []).forEach(color => {
          const el = this.$f7.tooltip.get(
            `#review_step_${item.id}_${color.id}`
          );
          if (el) {
            this.$f7.tooltip.destroy(`#review_step_${item.id}_${color.id}`);
          }
          const photo = (color.photos || [])[0];
          const tooltipContent = photo
            ? `<img class="tooltip-image-show" src="${photo.thumbnailUrl}" />`
            : `<span>No image !</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${item.id}-${color.id}`,
            cssClass: photo ? 'tooltip-image-preview' : '',
            text: tooltipContent,
          });
        });
      });
      productList.forEach(attachMent => {
        (attachMent.technicalData || []).forEach(item => {
          const el1 = this.$f7.tooltip.get(
            `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`
          );
          if (el1) {
            this.$f7.tooltip.destroy(
              `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`
            );
          }
          const tooltipContent = isImage(item.url)
            ? `<img class="tooltip-image-show" src="${item?.url}" />`
            : isVideo(item.url)
              ? `<video class="tooltip-image-show" src="${item?.url}" ></video>`
              : `<span>${item?.name}</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`,
            cssClass:
              isImage(item.url) || isVideo(item.url)
                ? 'tooltip-image-preview'
                : '',
            text: tooltipContent,
          });
        });
      });
    },

    showErrorDialog(message) {
      this.$ri.dialog.openErrorDialog({
        title: 'Error',
        content: message,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        },
      });
    },

    calcProfitMarginFromRetailTotal(retailTotal) {
      const mSub = this.materialSubTotal();
      const lSub = this.laborSubTotal();
      const miscP = this.estimate.miscPercent;
      const taxP = this.building.saleTax;

      const a = retailTotal;
      const b =
        retailTotal * miscP +
        mSub * 100 +
        lSub * 100 +
        taxP * mSub +
        taxP * lSub -
        retailTotal * 100 * 2;
      const c =
        miscP * taxP * lSub -
        100 * miscP * (retailTotal - lSub) -
        100 * taxP * (mSub + lSub) -
        10000 * (mSub + lSub - retailTotal);

      const res = ptbh(a, b, c);
      let profit = 0;

      if (
        res &&
        typeof res === 'number' &&
        res >= 0 &&
        _.round(res, 3) <= 99.999
      ) {
        profit = parseFloat(res.toFixed(3));
      } else if (res && typeof res === 'object') {
        let { x1, x2 } = res;
        x1 = _.round(x1, 3);
        x2 = _.round(x2, 3);
        if ((x1 > 99.999 || x1 < 0) && x2 >= 0 && x2 <= 99.999) {
          profit = x2;
        } else if ((x2 > 99.999 || x2 < 0) && x1 >= 0 && x1 <= 99.999) {
          profit = x1;
        } else if (
          x1 >= 0 &&
          x1 <= 99.999 &&
          x2 >= 0 &&
          x2 <= 99.999 &&
          x1 <= x2
        ) {
          profit = x1;
        } else if (
          x1 >= 0 &&
          x1 <= 99.999 &&
          x2 >= 0 &&
          x2 <= 99.999 &&
          x2 <= x1
        ) {
          profit = x2;
        } else {
          this.showErrorDialog('Invalid Retail total. Please try again!.');
          return;
        }
      } else {
        this.showErrorDialog('Invalid Retail total. Please try again!.');
        return;
      }

      this.saveEstimateValueFromBuilding('crossProfitMarginRatio', profit);
    },
  },

  updated() {
    this.createColorPhotoTooltip();
  },
};
</script>

<style lang="scss" scoped>
.text-bold {
  font-weight: bold;
}

.bg-total {
  background: #efefef;
}
.theme-dark .bg-total {
  background-color: #333333;
}
.text-sub-total {
  font-weight: 500;
}
.sale-tax-container {
  display: flex;
  justify-content: space-between;
}
.sale-tax-text {
  /* flex: 70px; */
  white-space: nowrap;
  margin-right: 5px;
}
.sale-tax-percent {
  flex: 1;
  text-align: right;
}
.sale-tax-percent ::v-deep input {
  text-align: right;
}

.section-name {
  font-size: 15px;
  font-weight: bold;
  align-items: center;
}

.primary-color {
  color: var(--f7-theme-color);
}
.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profit-margin-slider {
  margin-top: 35px;
}

.profit-margin-slider ::v-deep .vue-slider-process {
  background-color: var(--f7-theme-color);
}

.profit-margin-slider ::v-deep .vue-slider-dot-tooltip-inner {
  border-color: var(--f7-theme-color);
  background-color: var(--f7-theme-color);
}

.profit-margin-slider ::v-deep .vue-slider-dot-handle-focus {
  box-shadow: 0px 0px 1px 2px var(--f7-theme-color);
}
.profix-margin-input ::v-deep input {
  padding-right: 16px !important;
}
.cell-input-bold ::v-deep input {
  font-weight: bold;
}
.container-profit-margin {
  width: 100%;
  display: grid;
  place-items: center;
  padding-bottom: 8px;
}
.technical-data {
  width: 120px;
  display: inline-block;
  list-style-type: disc;
}
.technical-data a {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.technical-data li {
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--f7-theme-color);
}

.technical-data li::marker {
  color: var(--f7-color-text-neutral);
}
</style>
