import { FirebaseActions } from '../../../../services/firebase.service';
import * as constant from '../../../../utility/const';

export default tenant => {
  const propertyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_property`,
    'property'
  );

  return {
    bindPropertyListBys: propertyActions.bindCollectionBys,
    bindPropertyList({ dispatch }) {
      return dispatch('bindPropertyListBys', [
        {
          prop: constant.PROP_IS_DELETED,
          op: '!=',
          val: true,
        },
      ]);
    },
    unbindPropertyList: propertyActions.unbindCollection,

    getPropertyById: propertyActions.getDocument,
  };
};
