import * as types from './types';
import {
  FirebaseActions,
  firebase,
} from '../../../../services/firebase.service';
import _ from 'lodash';
import * as constant from '../../../../utility/const';
import algoliaService from '../../../../services/algolia.service';

export default tenant => {
  const contactActions = new FirebaseActions(
    `/system_client/${tenant}/operation_contact`,
    'contact'
  );

  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    'company'
  );

  const propertyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_property`,
    'property'
  );

  return {
    bindClientData({ state, dispatch }) {
      const promises = [];
      if (_.isEmpty(state.contactList)) {
        promises.push(dispatch('getContactList'));
      }
      if (_.isEmpty(state.companyList)) {
        promises.push(dispatch('getCompanyList'));
      }
      if (_.isEmpty(state.propertyList)) {
        promises.push(dispatch('getPropertyList'));
      }
      return Promise.all(promises);
    },

    async addCompanyContactRelationShip({ dispatch }, data) {
      let contact = await dispatch('getContactById', data.contactId);
      let company = await dispatch('getCompanyById', data.companyId);
      if (!contact || !company) return;

      const companyRefs = _.cloneDeep(contact.companyRefs) || [];
      const contactRefs = _.cloneDeep(company.contactRefs) || [];

      const promises = [];

      // update for company
      if (!contactRefs.find(r => r.id === data.contactId)) {
        contactRefs.push({
          createdRefAt: firebase.firestore.Timestamp.now(),
          id: data.contactId,
          contactName: `${contact.firstName} ${contact.lastName}`.trim(),
          phones: contact.phones || [],
        });

        await dispatch('updateCompany', {
          id: data.companyId,
          doc: {
            contactRefs: contactRefs,
          },
        });
        promises.push(dispatch('getCompany', data.companyId));
      }

      // update for contact
      if (!companyRefs.find(r => r.id === data.companyId)) {
        companyRefs.push({
          createdRefAt: new firebase.firestore.Timestamp.fromDate(new Date()),
          id: data.companyId,
          companyName: company.companyName,
        });

        await dispatch('updateContact', {
          id: data.contactId,
          doc: {
            companyRefs: companyRefs,
          },
        });
        promises.push(dispatch('getContact', data.contactId));
      }
      return Promise.all(promises);
    },

    async addPropertyCompanyRelationShip({ dispatch }, data) {
      let property = await dispatch('getPropertyById', data.propertyId);
      let company = await dispatch('getCompanyById', data.companyId);
      if (!property || !company) return;

      const companyRefs = _.cloneDeep(property.companyRefs) || [];
      const propertyRefs = _.cloneDeep(company.propertyRefs) || [];

      const promises = [];

      // update for property
      if (!companyRefs.find(r => r.id === data.companyId)) {
        companyRefs.push({
          createdRefAt: new firebase.firestore.Timestamp.fromDate(new Date()),
          id: data.companyId,
          companyName: company.companyName,
        });

        await dispatch('updateProperty', {
          id: data.propertyId,
          doc: {
            companyRefs: companyRefs,
          },
        });
        promises.push(dispatch('getProperty', data.propertyId));
      }

      // update for company
      if (!propertyRefs.find(r => r.id === data.propertyId)) {
        propertyRefs.push({
          createdRefAt: new firebase.firestore.Timestamp.fromDate(new Date()),
          id: data.propertyId,
          propertyName: property.propertyName,
          addresses: property.addresses,
        });

        await dispatch('updateCompany', {
          id: data.companyId,
          doc: {
            propertyRefs: propertyRefs,
          },
        });
        promises.push(dispatch('getCompany', data.companyId));
      }
      return Promise.all(promises);
    },

    createContact: contactActions.createDocument,
    saveContact: contactActions.updateDocument,
    deleteContact: contactActions.deleteDocument,
    getContactById: contactActions.getDocument,
    unbindContactDoc: contactActions.unbindDocument,

    bindContactListBys: contactActions.bindCollectionBys,
    getContactListBys: contactActions.getDocumentBys,
    unbindContactList: contactActions.unbindCollection,

    async getContactList({ commit, dispatch }) {
      const list = await dispatch('getContactListBys', [
        {
          prop: constant.PROP_IS_DELETED,
          op: '!=',
          val: true,
        },
      ]);
      await commit(types.SET_CONTACT_LIST, list);
      return list;
    },
    async pushContact({ commit }, contact) {
      return commit(types.PUSH_CONTACT, contact);
    },

    async setContact({ commit }, contact) {
      return commit(types.SET_CONTACT, contact);
    },

    async getContact({ commit, dispatch }, contactId) {
      const contact = await dispatch('getContactById', contactId);
      await commit(types.SET_CONTACT, contact);
      return contact;
    },

    async unbindContact({ dispatch, commit }) {
      await dispatch('unbindContactDoc');
      await commit(types.SET_CONTACT, {});
    },

    createCompany: companyActions.createDocument,
    saveCompany: companyActions.updateDocument,
    deleteCompany: companyActions.deleteDocument,
    getCompanyById: companyActions.getDocument,
    unbindCompanyDoc: companyActions.unbindDocument,

    bindCompanyListBys: companyActions.bindCollectionBys,
    getCompanyListBys: companyActions.getDocumentBys,
    unbindCompanyList: companyActions.unbindCollection,

    unbindInsuranceCompany({ commit }) {
      commit(types.SET_INSURANCE_COMPANY, {});
    },

    async getCompanyList({ commit, dispatch }) {
      const list = await dispatch('getCompanyListBys', [
        {
          prop: constant.PROP_IS_DELETED,
          op: '!=',
          val: true,
        },
      ]);

      await commit(types.SET_COMPANY_LIST, list);
    },

    async getCompany({ commit, dispatch }, companyId) {
      const company = await dispatch('getCompanyById', companyId);
      await commit(types.SET_COMPANY, company);
      return company;
    },

    setCompany({ commit }, data) {
      return commit(types.SET_COMPANY, data);
    },

    async getInsuranceCompany({ commit, dispatch }, companyId) {
      const insuranceCompany = await dispatch('getCompanyById', companyId);
      await commit(types.SET_INSURANCE_COMPANY, insuranceCompany);
      return insuranceCompany;
    },

    async unbindCompany({ dispatch, commit }) {
      await dispatch('unbindCompanyDoc');
      await commit(types.SET_COMPANY, {});
    },

    createProperty: propertyActions.createDocument,
    saveProperty: propertyActions.updateDocument,
    deleteProperty: propertyActions.deleteDocument,
    getPropertyById: propertyActions.getDocument,
    unbindPropertyDoc: propertyActions.unbindDocument,

    bindPropertyListBys: propertyActions.bindCollectionBys,
    getPropertyListBys: propertyActions.getDocumentBys,
    unbindPropertyList: propertyActions.unbindCollection,
    async getPropertyList({ commit, dispatch }) {
      const list = await dispatch('getPropertyListBys', [
        {
          prop: constant.PROP_IS_DELETED,
          op: '!=',
          val: true,
        },
      ]);
      await commit(types.SET_PROPERTY_LIST, list);
    },

    async getProperty({ commit, dispatch }, propertyId) {
      const property = await dispatch('getPropertyById', propertyId);
      await commit(types.SET_PROPERTY, property);
      return property;
    },

    setProperty({ commit }, data) {
      return commit(types.SET_PROPERTY, data);
    },

    async unbindProperty({ dispatch, commit }) {
      await dispatch('unbindPropertyDoc');
      await commit(types.SET_PROPERTY, {});
    },

    // update and save to algolia
    updateContact({ dispatch }, { id, doc }) {
      return dispatch('saveContact', { id, doc }).then(() => {
        return algoliaService.algoliaUpdateItem({
          tenantId: tenant,
          collection: 'operation_contact',
          id,
        });
      });
    },

    updateCompany({ dispatch }, { id, doc }) {
      return dispatch('saveCompany', { id, doc }).then(() => {
        return algoliaService.algoliaUpdateItem({
          tenantId: tenant,
          collection: 'operation_company',
          id,
        });
      });
    },

    updateProperty({ dispatch }, { id, doc }) {
      return dispatch('saveProperty', { id, doc }).then(() => {
        return algoliaService.algoliaUpdateItem({
          tenantId: tenant,
          collection: 'operation_property',
          id,
        });
      });
    },
  };
};
