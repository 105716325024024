import { FirebaseActions } from '../../../../services/firebase.service';
// import * as types from "./types";

export default tenant => {
  const workOrder = new FirebaseActions(
    `/system_client/${tenant}/work_order_task`,
    'workOrder'
  );

  return {
    bindWorkOrderListBys: workOrder.bindCollectionBys,
    unbindWorkOrderList: workOrder.unbindCollection,
  };
};
