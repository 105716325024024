import { FirebaseActions } from '../../../../services/firebase.service';
import * as constant from '../../../../utility/const';
import * as types from './types';

export default tenant => {
  const certificateActions = new FirebaseActions(
    `/system_client/${tenant}/certificate`,
    'certificate'
  );

  return {
    bindCertificateListBys: certificateActions.bindCollectionBys,
    unbindCertificateListBy: certificateActions.unbindCollection,
    bindCertificate: certificateActions.bindDocument,
    unbindCertificate: certificateActions.unbindDocument,
    createCertificate: certificateActions.createDocument,
    updateCertificate: certificateActions.updateDocument,
    getCertificate: certificateActions.getDocument,
    getCertificateBys: certificateActions.getDocumentBys,
    async getCertificateByProjectId({ dispatch, commit }, projectId) {
      const list = await dispatch('getCertificateBys', [
        {
          prop: 'projectId',
          val: projectId,
          op: '==',
        },
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);

      commit(types.SET_CERTIFICATE_LIST, list);
      return list;
    },
    async getCertificateList({ dispatch, commit }) {
      const list = await dispatch('getCertificateBys', [
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);

      commit(types.SET_CERTIFICATE_LIST, list);
      return list;
    },
    deleteCertificate({ dispatch }, id) {
      return dispatch('updateCertificate', {
        id,
        doc: { [constant.PROP_IS_DELETED]: true },
      });
    },
  };
};
