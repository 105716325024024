<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="handlePopupClosed"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>On My Way</f7-nav-title>
        <f7-nav-right>
          <f7-link
            v-show="activeTab === 'message'"
            @click.native="onSend"
            >Send</f7-link
          >
        </f7-nav-right>
      </f7-navbar>

      <f7-block>
        <f7-segmented
          strong
          tag="p"
        >
          <f7-button
            :active="activeTab === 'message'"
            @click.native="activeTab = 'message'"
            >Message</f7-button
          >
          <f7-button
            :active="activeTab === 'message-template'"
            @click.native="activeTab = 'message-template'"
            >Message Template</f7-button
          >
        </f7-segmented>
      </f7-block>

      <!-- tabs content -->
      <div v-show="activeTab === 'message'">
        <f7-block-title>Message</f7-block-title>
        <f7-list>
          <f7-text-editor
            placeholder="Enter message..."
            mode="keyboard-toolbar"
            resizable
            :value="message"
            @texteditor:change="v => (message = v)"
          >
          </f7-text-editor>
          <!-- <f7-list-input
            label="Message"
            type="textarea"
            resizable
            :value="message"
            @input="message = $event.target.value.trim()"
            :error-message-force="true"
            :error-message="errorMessage"
            @blur="v$.$touch()"
          >
            <input-icon slot="media" icon="doc_text"></input-icon>
            <required-asterisk slot="label"></required-asterisk>
          </f7-list-input> -->
        </f7-list>
      </div>
      <div
        v-show="activeTab === 'message-template'"
        style="position: relative"
      >
        <f7-block-title>Message Template</f7-block-title>
        <f7-text-editor
          placeholder="Enter message..."
          resizable
          :value="template"
          @texteditor:change="v => (template = v)"
        >
        </f7-text-editor>
        <!-- <f7-button @click.stop="onSaveTemplate">Save</f7-button> -->
        <div class="actions-create">
          <f7-button
            fill
            class="margin-right-half save-button"
            @click.stop="onSaveTemplate"
            >Save</f7-button
          >
        </div>

        <f7-block class="info-block"
          >Please do not change keywords between brackets {}.</f7-block
        >
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
// import { required } from "@vuelidate/validators";
// import InputIcon from "@/components/icons/InputIcon.vue";
import { getFullAddress } from '@/utility/address';
import { googlePlaceApiMixin } from '@/services/place.google.service';
import { useVuelidate } from '@vuelidate/core';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  //   components: {
  //     InputIcon
  //   },

  mixins: [googlePlaceApiMixin],

  data: () => {
    return {
      popupOpened: false,
      emitDone: false,
      message: '',
      template: '',

      activeTab: 'message',
      emailData: {
        traveling_time: '',
        company_name: '',
        company_address: '',
        company_phone: '',
        company_website: '',
      },
    };
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  computed: {
    ...mapGetters('swimlane/email-template', ['onMyWayTemplate']),
    ...mapGetters('setting/app/system', ['setting']),
    ...mapGetters('common/app-constant', ['tenantId']),
  },

  methods: {
    ...mapActions('swimlane/email-template', [
      'getOnMyWayEmailTemplate',
      'updateEmailTemplate',
    ]),

    ...mapActions('setting/app/system', ['bindSetting']),

    onSaveTemplate() {
      const self = this;
      this.$f7.preloader.show();
      return this.updateEmailTemplate({
        id: this.onMyWayTemplate.id,
        doc: {
          htmlContent: this.template,
        },
      })
        .then(() => {
          self.updateMessage();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    secondsToHm(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      // var s = Math.floor((d % 3600) % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? ' hour ' : ' hours ') : '';
      var mDisplay = m > 0 ? m + (m == 1 ? ' minute' : ' minutes') : '';
      return hDisplay + mDisplay;
    },

    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          location => resolve(location),
          reject
        );
      });
    },

    getLocationAddress(contact) {
      if (contact.addresses && contact.addresses.length > 0) {
        let address = contact.addresses.find(item => item.code === 'main');
        if (address) {
          return getFullAddress(address);
        } else {
          address = contact.addresses[0];
          return getFullAddress(address);
        }
      }

      return '';
    },

    getDuration(contactObj) {
      const self = this;
      const clientAddress = this.getLocationAddress(contactObj);
      return this.getCurrentPosition()
        .then(location => {
          const { latitude, longitude } = location.coords;
          return {
            latitude,
            longitude,
          };
        })
        .then(({ latitude, longitude }) => {
          return self.lookupAddress(latitude, longitude);
        })
        .then(({ formattedAddress }) => {
          return self.calculateDistance(clientAddress, [formattedAddress]);
        })
        .then(data => {
          return self.secondsToHm(data.duration);
        })
        .then(duration => {
          self.emailData.traveling_time = duration;
          return;
        });
    },

    getEmailTemplate() {
      const self = this;
      return this.getOnMyWayEmailTemplate().then(() => {
        self.template = self.onMyWayTemplate.htmlContent;
      });
    },

    parseMessage(htmlContent) {
      for (const key in this.emailData) {
        if (this.emailData.hasOwnProperty(key)) {
          const value = this.emailData[key];
          const reg = new RegExp(`{${key}}`, 'g');
          htmlContent = htmlContent.replace(reg, value);
        }
      }

      return htmlContent;
    },

    updateMessage() {
      this.message = this.parseMessage(_.cloneDeep(this.template));
    },

    async getCompanyInfo() {
      if (_.isEmpty(this.setting)) {
        await this.bindSetting(this.tenantId);
      }

      this.emailData.company_name = this.setting.companyName;
      this.emailData.company_address = this.setting.address;
      this.emailData.company_phone = this.setting.phoneNumber;
      this.emailData.company_website = this.setting.companyWebsite;
    },

    open(contactObj) {
      const self = this;
      this.emitDone = false;
      this.popupOpened = true;

      this.$f7.preloader.show();
      return Promise.all([
        this.getDuration(contactObj),
        this.getEmailTemplate(),
        this.getCompanyInfo(),
      ])
        .then(() => {
          self.updateMessage();
        })
        .catch(() => {
          self.$f7.preloader.hide();
        })
        .finally(() => {
          self.$f7.preloader.hide();
        });
    },

    onSend() {
      this.emitDone = true;
      this.popupOpened = false;
    },

    handlePopupClosed() {
      this.popupOpened = false;
      if (this.emitDone) {
        this.$emit('done', this.emailData);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.actions-create {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  //   top: -3px;
  right: 16px;
}

.save-button {
  min-width: 120px;
}

.info-block {
  margin-top: 50px;
}
</style>
