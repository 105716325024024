<template>
  <f7-page class="invoice-page">
    <f7-navbar>
      <!-- <f7-nav-left>
        <f7-link @click="backToGrid" icon-f7="chevron_left">Invoices</f7-link>
      </f7-nav-left> -->
      <f7-nav-title>Invoice Detail</f7-nav-title>
      <f7-nav-right>
        <f7-link
          @click="onDownloadPDF"
          icon-f7="cloud_download"
        >
          Download PDF</f7-link
        >
      </f7-nav-right>
    </f7-navbar>
    <invoice-detail
      ref="invoiceDetail"
      :invoiceDetail="invoiceDetailView"
      :invoiceGroup="invoiceGroupDetail"
      :invoiceTemplate="invoiceTemplate"
    ></invoice-detail>
  </f7-page>
</template>

<script>
import InvoiceDetail from '../components/details/InvoiceDetail.vue';

import { firebase } from '@/services/firebase.service';
import invoiceMixins from '../mixins/invoice-mixin';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

export default {
  components: {
    InvoiceDetail,
  },
  mixins: [invoiceMixins],
  data: () => {
    return {
      invoiceDetail: {},
      invoiceGroupDetail: {},
      invoiceTemplate: {},
    };
  },
  async mounted() {
    this.$f7.preloader.show();
    await this.reloadPages();
    if (this.invoiceDetail.status == 'in-sent') {
      try {
        // update status to viewed by client
        await axios.post(
          `invoice/update/${this.$f7route.query.id}`,
          {
            status: 'in-viewed',
            viewedAt: firebase.firestore.Timestamp.now(),
          },
          {
            baseURL: import.meta.env.VITE_BASE_API,
            withCredentials: true,
            headers: {
              token: this.$f7route.query.token,
            },
          }
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      }
      await this.reloadPages();
    }
    this.$f7.preloader.hide();
  },
  computed: {
    invoiceDetailView() {
      return {
        ..._.cloneDeep(this.invoiceDetail || {}),
        invoiceDate:
          (this.invoiceDetail || {}).invoiceDate &&
          this.invoiceDetail.invoiceDate.toDate
            ? moment(
                this.invoiceDetail.invoiceDate.toDate(),
                'MM/DD/YYYY'
              ).format('MM/DD/YYYY')
            : '',
        dueDate:
          (this.invoiceDetail || {}).dueDate &&
          this.invoiceDetail.dueDate.toDate
            ? moment(this.invoiceDetail.dueDate.toDate(), 'MM/DD/YYYY').format(
                'MM/DD/YYYY'
              )
            : '',
      };
    },
  },

  methods: {
    async reloadPages() {
      this.$f7.preloader.show();
      this.loadingData = true;
      try {
        const response = await axios.get(`invoice/${this.$f7route.query.id}`, {
          baseURL: import.meta.env.VITE_BASE_API,
          withCredentials: true,
          headers: {
            token: this.$f7route.query.token,
          },
        });
        if (
          response.status === 200 &&
          response.data.success &&
          response.data.data.invoice.isDeleted === false
        ) {
          const invoiceTemplate = await this.getInvoiceTemplate();
          this.invoiceTemplate = invoiceTemplate;
          this.invoiceDetail = response.data.data.invoice;
          this.invoiceGroupDetail = response.data.data.invoiceGroup;
        } else {
          // eslint-disable-next-line no-console
          console.error(response.data.message);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      } finally {
        this.loadingData = false;
        this.$f7.preloader.hide();
      }
    },
    async getInvoiceTemplate() {
      try {
        const response = await axios.get(
          `invoice/invoice-template/${this.$f7route.query.id}`,
          {
            baseURL: import.meta.env.VITE_BASE_API,
            withCredentials: true,
            headers: {
              token: this.$f7route.query.token,
            },
          }
        );
        return response.data.data;
      } catch (error) {
        return { success: false, message: error.message };
      }
    },
    onDownloadPDF() {
      this.$refs.invoiceDetail.handleDownloadPDF(
        this.invoiceDetail,
        this.invoiceGroupDetail
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.summary {
  background: white;
  border-radius: 4px;
}
.page-form {
  width: 825px;
  padding: 30px 25px 30px 50px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  box-sizing: border-box;
  border-color: #e5e7f2;
  background-color: white;
  box-shadow: 0 4px 4px rgba(87, 100, 126, 0.21);
  border-radius: 2px;
  position: relative;
  padding-bottom: 60px;
}
.invoice-page ::v-deep .page-content {
  width: 100%;
  display: flex;
  justify-content: center;
}
.text-info {
  line-height: 2;
}
.text-align-right {
  text-align: right;
}
.bold {
  font-weight: 500;
}
.logo {
  width: 92%;
  img {
    width: 100%;
  }
}
a {
  gap: 5px;
}
.action-bar {
  position: sticky;
  z-index: 502;
  background-color: #eee;
  border: 1px solid rgb(216, 214, 214);
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;
}
.invoice-button {
  padding: 2px 10px;
  font-weight: 600;
  border-left: 1px solid rgb(216, 214, 214);
  box-sizing: border-box;
  a {
    color: gray;
    display: flex;
    align-items: center;
    i {
      padding-right: 4px;
    }
  }
  a:hover {
    font-weight: 600;
    color: var(--f7-theme-color);
    i {
      font-weight: 600;
    }
  }
}
</style>
