import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const swimlaneBoardActions = new FirebaseActions(
    `/system_client/${tenant}/swimlane_board`,
    'swimlaneBoard'
  );

  const swimlaneColumnActions = new FirebaseActions(
    `/system_client/${tenant}/swimlane_column`,
    'swimlaneColumn'
  );

  return {
    getSwimlaneBoardBys: swimlaneBoardActions.getDocumentBys,
    getDefaultServiceBoard({ dispatch }, businessCode) {
      return dispatch('getSwimlaneBoardBys', [
        {
          prop: 'businessCode',
          op: '==',
          val: businessCode,
        },
        {
          prop: 'default',
          op: '==',
          val: true,
        },
      ]).then(data => {
        if (data && data[0]) {
          return data[0];
        }

        return null;
      });
    },

    getSwimlaneColumnBys: swimlaneColumnActions.getDocumentBys,
    getFirstColumn({ dispatch }, boardId) {
      return dispatch('getSwimlaneColumnBys', [
        {
          prop: 'boardId',
          op: '==',
          val: boardId,
        },
      ]).then(data => {
        if (data) {
          const sorted = data.sort((a, b) => a.priority - b.priority);
          return sorted[0];
        }

        return null;
      });
    },
  };
};
