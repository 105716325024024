<template>
  <div ref="avatarContainer">
    <f7-block-header>{{ mainLabel }}</f7-block-header>
    <f7-block
      v-show="users"
      strong
      class="display-flex"
    >
      <div
        v-for="(uid, index) in value"
        :key="uid"
        :class="index < usersHidden ? 'assignee-avatar' : ''"
      >
        <user-avatar
          v-if="index < usersHidden"
          :avatarSize="35"
          :imageUrl="userInfo(uid) ? userInfo(uid).photoURL : ''"
          :name="userInfo(uid) ? userInfo(uid).displayName : ''"
          :uid="uid"
        ></user-avatar>
      </div>
      <div
        v-if="(value || value || []).length > usersHidden"
        :class="`assignee-avatar more-assignee-avatar more-assignee-user-tooltip-${uuid}`"
      >
        <span>{{ `+${(value || value).length - usersHidden}` }}</span>
      </div>
      <div
        class="assignee-avatar cursor-pointer"
        v-if="!readOnly"
      >
        <user-avatar
          :avatarSize="35"
          @click.native="addChip()"
          icon-add
        ></user-avatar>
      </div>
    </f7-block>

    <f7-popup
      :opened="popupOpened"
      @popup:closed="popupOpened = false"
      @popup:opened="handlePopupOpened"
    >
      <f7-page>
        <f7-navbar title="Select User">
          <f7-nav-right>
            <f7-link @click.native="closePopup">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-searchbar
          search-container=".search-list"
          search-in=".item-title"
          :disable-button="!$theme.aurora"
          disable-button-text="Cancel"
          placeholder="Search in users"
          :clear-button="true"
          ref="searchUserPopup"
        ></f7-searchbar>

        <f7-list class="search-list">
          <f7-list-item
            v-for="user in users"
            :key="user.uid"
            checkbox
            :title="user.displayName"
            :checked="checkedUser(user.uid)"
            @change="selectUser(user.uid)"
          ></f7-list-item>
        </f7-list>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import UserAvatar from '@/components/avatars/UserAvatar.vue';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { auth } from '@/services/firebase.service';
import axiosService from '@/services/axios.service';
import { uuid } from 'vue-uuid';
export default {
  components: {
    UserAvatar,
  },

  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modelType: { type: String, default: 'project' },
    readOnly: { type: Boolean, default: false },
  },

  data: () => {
    return {
      popupOpened: false,
      usersHidden: 0,
      tooltip: null,
      uuid: uuid.v4(),
    };
  },
  mounted() {
    if (this.value.length > this.usersHidden) {
      this.createTooltip(this.value);
    }
  },
  computed: {
    ...mapGetters('purchase-order/purchase-order-details-page/project', [
      'users',
      'project',
    ]),

    userInfo() {
      return uid =>
        this.users.find(user => {
          return user.uid === uid;
        });
    },

    userLetter() {
      return user => (user ? user.displayName.charAt(0) : '');
    },

    checkedUser() {
      return uid => this.value.includes(uid);
    },

    mainLabel() {
      return this.value && this.value.length > 1 ? 'Assignees' : 'Assignee';
    },
  },

  methods: {
    deleteChip(uid) {
      let list = _.cloneDeep(this.value);
      list = _.remove(list, id => {
        return uid != id;
      });

      this.$emit('input', list);
    },
    createTooltip(value) {
      if (!this.$device.desktop) return;

      if (this.tooltip) {
        this.tooltip.destroy();
      }
      const maxUsers = 10;
      let displayNames = value
        .slice(this.usersHidden)
        .map(uid => {
          const userInfoResult = this.userInfo(uid);
          return userInfoResult ? userInfoResult.displayName : 'Access denied';
        })
        .filter(name => name !== '');

      let moreUsersText = '';
      if (displayNames.length > maxUsers) {
        moreUsersText = `<br/>and more ${
          displayNames.length - maxUsers
        } users...`;
        displayNames = displayNames.slice(0, maxUsers);
      }

      displayNames = displayNames.join('<br/>');
      this.$nextTick(() => {
        this.tooltip = this.$f7.tooltip.create({
          targetEl: `.more-assignee-user-tooltip-${this.uuid}`,
          text: displayNames + moreUsersText,
        });
      });
    },
    calculateUsersHidden() {
      const avatarWidth = 40;
      let usersHidden = 5;
      if (this.$device.desktop || this.$device.ipad) {
        const widthPopup = window.innerWidth / 2;
        const containerWidth =
          this.$refs.avatarContainer.offsetWidth === 0
            ? widthPopup
            : this.$refs.avatarContainer.offsetWidth;
        usersHidden = Math.floor((containerWidth - 120) / avatarWidth);
      }
      return usersHidden;
    },

    addChip() {
      this.popupOpened = true;
    },

    selectUser(uid) {
      let list = _.cloneDeep(this.value);

      if (list.includes(uid)) {
        list = _.remove(list, item => {
          return item != uid;
        });
        this.$emit('handleNotify', { userId: uid, type: 'remove-user' });
      } else {
        list.push(uid);

        let receiverInfo = this.userInfo(uid);
        if (this.modelType === 'project') {
          let receiver = `${receiverInfo.displayName || ''} <${
            receiverInfo.email
          }>`;
          this.sendMail(receiver);
        } else {
          this.$emit('handleNotify', { userId: uid, type: 'assign-user' });
        }
      }

      this.$emit('input', list);
    },

    closePopup() {
      this.popupOpened = false;
    },

    sendMail(receiver) {
      return axiosService
        .post('/notification/push-email', {
          to: receiver,
          subject: {
            assigner: auth.currentUser.displayName,
          },
          template: 'assign-project',
          content: {
            assigner: auth.currentUser.displayName,
            project_title: this.project.title || '',
          },
          attachments: [],
          scheduleSendingTime: '',
        })
        .then(() => {
          // TODO: this trigger will be removed when implement email mail service scheduler
          return axiosService.post('/notification/trigger-email-service', {
            sender: '',
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('[Send Email]', error);
          throw new Error(error.message);
        });
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$refs.searchUserPopup.$el
          .querySelector('.searchbar input')
          .focus();
      }
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.usersHidden = this.calculateUsersHidden();
        if (newValue.length > this.usersHidden) {
          this.createTooltip(newValue);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  margin-right: 5px;
}

.assignee-avatar {
  margin-right: 5px;
}
.more-assignee-avatar {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--f7-color-chip-neutral);
  font-size: 12px;
  cursor: pointer;
}
</style>
