import Vue from 'vue';
import * as types from './types';

export default {
  setObjectList(state, payload) {
    Vue.set(state, 'objectList', payload);
  },
  setObjectSubscribe(state, payload) {
    Vue.set(state, 'objectSubscribe', payload);
  },
  update(state, payload) {
    const id = payload.id;
    state.objectList = state.objectList.map(r => {
      if (r.id === id) {
        return {
          ...r,
          ...payload,
        };
      }
      return r;
    });
  },
  setCardDetailId(state, id) {
    state.detailId = id;
  },
  patchFilter(state, patcher) {
    state.filter = {
      ...state.filter,
      ...patcher,
    };
  },

  [types.SET_FILTER_USERS](state, payload) {
    Vue.set(state, 'userFilter', payload);
  },

  [types.SET_FILTER_SEARCH](state, payload) {
    Vue.set(state, 'searchText', payload);
  },

  [types.SET_FILTER_WEEK](state, payload) {
    Vue.set(state, 'weekFilter', payload);
  },

  [types.SET_CURRENT_PROJECT](state, project) {
    Vue.set(state, 'currentProject', project);
  },
};
