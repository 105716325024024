export default {
  users: state => {
    const result = [];
    state.users.forEach(user => {
      const email =
        (user.customClaims || {}).loginMethod === 'email_by_phone'
          ? user.customClaims.displayEmail || ''
          : user.email;
      result.push({ ...user, email });
    });
    return result;
  },
};
