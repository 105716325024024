/* eslint-disable no-unused-vars */
import { FirebaseActions } from '../../../../services/firebase.service';
import { CODE_COMPANY_TYPE } from '@/utility/const';

export default tenant => {
  const typeAction = new FirebaseActions(
    `/system_client/${tenant}/app_constant`,
    'type'
  );

  return {
    bindConstantTypeListBy: typeAction.bindCollectionBy,
    unbindConstantTypeList: typeAction.unbindCollection,
    // Use the in operator to combine up to 10 equality (==) clauses on the same field with a logical OR.
    // An in query returns documents where the given field matches any of the comparison values
    bindConstantTypeList({ dispatch }) {
      return dispatch('bindConstantTypeListBy', {
        prop: 'code',
        op: 'in',
        val: [CODE_COMPANY_TYPE],
      });
    },
  };
};
