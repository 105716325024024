<template>
  <project-photos-by-album-popup
    ref="projectPhotoByAlbumPopup"
    title="Select Photos"
    :isShowNoteInput="false"
    :projectId="projectId"
    :projectPhotoAlbumList="projectPhotoAlbumList || []"
    :projectPhotoList="projectPhotoList || []"
    :selectedPhotos="selectedPhotos"
    :isUploadVideo="isUploadVideo"
    :uploadProjectPhoto="onUploadProjectPhoto"
    :createProjectPhoto="onCreateProjectPhoto"
    @onDone="done"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProjectPhotosByAlbumPopup from '@/components/popups/ProjectPhotosByAlbumPopup.vue';

export default {
  props: {
    selectedPhotos: Array,
    isUploadVideo: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ProjectPhotosByAlbumPopup,
  },
  data() {
    return {
      selectCallback: null,
      projectId: '',
    };
  },
  computed: {
    ...mapGetters({
      projectPhotoList: 'invoices/photo/projectPhotoList',
      projectPhotoAlbumList: 'invoices/album/projectPhotoAlbumList',
    }),
  },
  methods: {
    ...mapActions({
      bindProjectPhotoListBy: 'invoices/photo/bindProjectPhotoListBy',
      createProjectPhoto: 'invoices/photo/createProjectPhoto',
      uploadPhoto: 'invoices/photo/uploadPhoto',
      bindProjectPhotoAlbumListBy: 'invoices/album/bindProjectPhotoAlbumListBy',
    }),
    loadPhoto(projectId) {
      if (!projectId) {
        this.projectId = '';
        return;
      }
      if (this.projectId == projectId) return;
      this.projectId = projectId;
      this.$f7.preloader.show();
      let promises = [];
      promises.push(
        this.bindProjectPhotoAlbumListBy({
          prop: 'projectId',
          val: projectId,
          op: '==',
        })
      );
      promises.push(
        this.bindProjectPhotoListBy({
          prop: 'projectId',
          val: projectId,
          op: '==',
        })
      );
      return Promise.all(promises).then(() => {
        this.$f7.preloader.hide();
      });
    },
    async openPopup(projectId) {
      await this.loadPhoto(projectId);
      this.$refs.projectPhotoByAlbumPopup.openPopup();

      return new Promise(resolve => {
        this.selectCallback = resolve;
      });
    },
    done({ photos, callback }) {
      this.$emit('change', photos);
      callback();
    },
    onUploadProjectPhoto({ file, projectId }) {
      return this.uploadPhoto({ file, projectId });
    },
    onCreateProjectPhoto(photo) {
      return this.createProjectPhoto(photo);
    },
  },
};
</script>
