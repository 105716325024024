import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';
import { toDateFirebase } from '../../../../utility/datetime';
import { getTomorrow } from '../../../../utility/date-time-tool';

export default tenant => {
  const activityActions = new FirebaseActions(
    `/system_client/${tenant}/activity`,
    'activity'
  );
  return {
    createActivity: activityActions.createDocument,
    getActivityListBys: activityActions.getDocumentBys,

    popuplateExpenseHistory({ commit, dispatch }, dateRange) {
      dispatch('getActivityListBys', [
        {
          prop: 'activityType',
          val: 'export-qb-expense',
          op: '==',
        },
        {
          prop: 'createdAt',
          val: toDateFirebase([dateRange.fromDate]),
          op: '>=',
        },
        {
          prop: 'createdAt',
          val: toDateFirebase([getTomorrow(dateRange.toDate)]),
          op: '<',
        },
      ]).then(data => {
        commit(types.SET_EXPENSE_HISTORY, data);
      });
    },

    popuplateWorkingHoursHistory({ commit, dispatch }, dateRange) {
      dispatch('getActivityListBys', [
        {
          prop: 'activityType',
          val: 'export-qb-working-hours',
          op: '==',
        },
        {
          prop: 'createdAt',
          val: toDateFirebase([dateRange.fromDate]),
          op: '>=',
        },
        {
          prop: 'createdAt',
          val: toDateFirebase([getTomorrow(dateRange.toDate)]),
          op: '<',
        },
      ]).then(data => {
        commit(types.SET_WORKING_HOURS_HISTORY, data);
      });
    },
  };
};
