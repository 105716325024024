export default {
  actionList: [],
  cardList: [],
  cardSubscribe: null,
  card: {},
  action: {},
  response: [],

  users: [],
  userFilter: [],
  boardCode: '',
  searchText: '',
  weekFilter: [],
  businessCode: '',

  constructionProjectId: '',
  constructionProjectNumber: '',
  constructionProject: {},
  constructionProjectList: [],
  constructionProjectSubscribe: null,
  isDeleteTempleProject: true,
  archiveCardList: [],
  currActionIdsAfterMoveOrAdd: [],

  conditionToDisplayResponseBtn: '',
  currentSection: {},
};
