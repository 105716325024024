/* eslint-disable no-unused-vars */
import { FirebaseActions } from '../../../../services/firebase.service';
import { CODE_STATUS_OPTION } from '@/utility/const';
import * as types from './types';

export default tenant => {
  const typeAction = new FirebaseActions(
    `/system_client/${tenant}/app_constant`,
    'type'
  );

  return {
    getConstantTypeListBys: typeAction.getDocumentBys,
    // Use the in operator to combine up to 10 equality (==) clauses on the same field with a logical OR.
    // An in query returns documents where the given field matches any of the comparison values
    async getConstantTypeList({ dispatch, commit }) {
      const list = await dispatch('getConstantTypeListBys', [
        {
          prop: 'code',
          op: 'in',
          val: [CODE_STATUS_OPTION],
        },
      ]);
      commit(types.SET_CONSTANT_LIST, list);
    },

    resetConstant({ commit }) {
      commit(types.SET_CONSTANT_LIST, []);
    },
  };
};
