import {
  DEFAULT_STATUS_ESTIMATE,
  DEFAULT_ESTIMATE_MISC,
} from '@/utility/const';

export default {
  estimate: {},
  estimateList: [],
  estimateNumber: null,
  // totalEstimate: null,
  editingObject: {},
  dataPatches: [],
  currentEstimate: {
    miscPercent: DEFAULT_ESTIMATE_MISC,
    status: DEFAULT_STATUS_ESTIMATE,
    buildings: [
      // {
      //   buildingName: "SPEC_1",
      //   buildingId: "",
      //   numberOfCopy: 1
      // }
    ],
  },

  buildingList: [],
  building: {},
  currentBuilding: { assemblyList: [] },
  estimateHistoryList: [],
  currentStep: '',

  order: 'desc',
  searchText: '',

  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0,

  estimateListByProject: [],

  contactList: [],
  companyList: [],
  paramsBack: {},
  queryBack: {},
  selectedStatusFilter: [],

  typeSwitchScreen: 'step',
  isActiveSummary: false,
  clientInfo: {},
};
