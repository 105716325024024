import _ from 'lodash';
import { getFullAddress } from '../../../../utility/address';
import { toDisplayDateString } from '../../../../utility/datetime';

export default {
  objectList: ({ objectList }) => objectList,
  filterdObject: ({ objectList, userFilter, searchText, weekFilter }) => {
    let newList = [];
    if (userFilter && userFilter.length > 0) {
      newList = _.cloneDeep(objectList).filter(card => {
        for (const uid of userFilter) {
          if ((card.assigneeIDs || []).includes(uid)) {
            return true;
          }
        }

        return false;
      });
    } else {
      newList = _.cloneDeep(objectList);
    }
    if (searchText) {
      let newSearchText = searchText.toLowerCase();
      newList = newList.filter(card => {
        let title = card.title.toLowerCase();
        let cardNumber = card.cardNumber.toLowerCase();
        let projectAddress = getFullAddress(
          card ? card.projectAddress : {}
        ).toLowerCase();

        if (
          (title || []).includes(newSearchText) ||
          (cardNumber || []).includes(newSearchText) ||
          (projectAddress || []).includes(newSearchText)
        ) {
          return true;
        }
      });
    }
    if (weekFilter.length > 0) {
      newList = newList.filter(card => {
        for (let i = 0; i <= 6; i++) {
          if (
            (toDisplayDateString(card.dueDate) || []).includes(weekFilter[i])
          ) {
            return true;
          }
        }
      });
    }
    return newList;
  },
  detail: ({ objectList, detailId }) => objectList.find(r => r.id === detailId),
  filter: ({ filter }) => filter,
  currentProject: state => state.currentProject,
};
