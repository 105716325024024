import { mapGetters, mapActions } from 'vuex';
import { firebase, auth } from '../../../services/firebase.service';
import _ from 'lodash';
import axiosService from '@/services/axios.service';
import { toDisplayDateString } from '../../../utility/datetime';
import {
  // ACTION_CODE_CONSTRUCTION,
  STATUS_PO_RECEIVED,
  STATUS_PO_PARTIAL_RECEIVED,
  STATUS_PO_CANCEL,
  STATUS_PO_REQUEST_CANCEL,
  STATUS_PO_DRAFT,
  STATUS_PO_WAITING,
  ACTION_CODE_PO_ACCEPT_REQUEST_CANCEL,
  ACTION_CODE_PO_PARTIAL_RECEIVED,
  ACTION_CODE_PO_RECEIVED,
  ACTION_CODE_PO_REJECT_REQUEST_CANCEL,
  ACTION_CODE_PO_REQUEST_CANCEL,
  ENTITY_PURCHASE_ORDER,
  COLLECTION_WO_TASK,
} from '../../../utility/const';

const roundUpNumber = numberOfFixedRounds => value => {
  value = Math.round(value * 1000) / 1000;
  const denominator = Math.pow(10, numberOfFixedRounds);
  const temp = parseFloat((value * denominator).toFixed(10));
  return Math.ceil(temp) / denominator;
};

export default {
  computed: {
    ...mapGetters('purchase-order/purchase-order-details-page/project', [
      'constructionActionIdByCode',
      'users',
    ]),
    ...mapGetters('purchase-order/purchase-order-details-page/project', [
      'project',
    ]),
    ...mapGetters('purchase-order/purchase-order-details-page/group', [
      'adminGroups',
    ]),
    ...mapGetters('setting/app/system', ['setting']),
    ...mapGetters('common/app-constant', ['tenantId']),

    showNoTaxMessage() {
      return (
        this.project &&
        (this.project.taxExempt === true ||
          this.project.resalesCertificate === true)
      );
    },
  },

  methods: {
    ...mapActions('purchase-order/purchase-order-details-page/project', [
      'updateProject',
      'getUserData',
    ]),
    ...mapActions('setting/app/system', ['bindSetting']),
    ...mapActions('purchase-order/purchase-order-details-page/group', [
      'getAdminGroups',
    ]),

    ...mapActions('purchase-order/purchase-order', ['updatePurchaseOrder']),
    ...mapActions('common/notification', ['createNotificationByType']),

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    getActionId(status = '') {
      return this.constructionActionIdByCode(
        (status => {
          let actionCode = 0;
          switch (status) {
            case STATUS_PO_DRAFT:
              actionCode = 500;
              break;

            case STATUS_PO_WAITING:
            case STATUS_PO_REQUEST_CANCEL:
              actionCode = 510;
              break;

            case STATUS_PO_CANCEL:
              actionCode = 520;
              break;

            case STATUS_PO_RECEIVED:
            case STATUS_PO_PARTIAL_RECEIVED:
              actionCode = 530;
              break;
          }
          return actionCode;
        })(status ? status : this.purchaseOrder.status)
      );
    },

    calculateOrderQty(actualQty, productItem) {
      const productSize = productItem.unitSize * productItem.unitPack;

      return roundUpNumber(0)(actualQty / (productSize || 1));
    },

    async saveNote() {
      const commentAndNote = _.cloneDeep(this.project.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: 'note',
          title: 'Purchase Order Note',
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        });

        return this.updateProject({
          id: this.project.id,
          doc: {
            commentAndNote,
          },
        });
      }
    },

    saveEmailData(emailData) {
      this.purchaseOrderEmails = emailData;
      // this.orderDate = [Date.now()];

      return this.updatePurchaseOrder({
        id: this.currentPurchaseOrder
          ? this.currentPurchaseOrder.id
          : this.purchaseOrder.id,
        doc: {
          purchaseOrderEmails: emailData,
          // orderDate: [Date.now()]
        },
      });
    },

    /**
     * Calculate the respone of PO task
     */
    getResponses(responses, purchaseOrder) {
      return responses.filter(item => {
        return (
          item.taskType === ENTITY_PURCHASE_ORDER &&
          ((purchaseOrder.status === STATUS_PO_WAITING &&
            [
              ACTION_CODE_PO_RECEIVED,
              ACTION_CODE_PO_PARTIAL_RECEIVED,
              ACTION_CODE_PO_REQUEST_CANCEL,
            ].includes(item.code)) ||
            (purchaseOrder.status === STATUS_PO_REQUEST_CANCEL &&
              [
                ACTION_CODE_PO_ACCEPT_REQUEST_CANCEL,
                ACTION_CODE_PO_REJECT_REQUEST_CANCEL,
              ].includes(item.code)) ||
            purchaseOrder.status === STATUS_PO_DRAFT)
        );
      });
    },
    async sendMailHandleProduct(
      productApprove,
      productRemoved,
      template,
      lastPL
    ) {
      let promises = [];
      if (_.isEmpty(this.setting)) {
        promises.push(this.bindSetting(this.tenantId));
      }
      if (_.isEmpty(this.users)) {
        promises.push(this.getUserList());
      }
      if (_.isEmpty(this.adminGroups)) {
        promises.push(this.getAdminGroups());
      }
      await Promise.all(promises);
      const adminGroupUserIDs = [].concat.apply(
        [],
        (this.adminGroups || []).map(r => r.userIDs)
      );
      const receivers =
        template === 'approve-product'
          ? (
              this.users.filter(
                user =>
                  user.customClaims.role === 'owner' ||
                  adminGroupUserIDs.includes(user.uid)
              ) || []
            ).map(r => r.email)
          : [auth.currentUser.email];
      const sendMailPromises = [];
      for (const receiver of receivers) {
        sendMailPromises.push(
          axiosService
            .post('/notification/push-email', {
              to: receiver,
              subject: {
                price_list_number: lastPL.priceListNumber,
                price_list_name: lastPL.displayName,
              },
              template: template,
              content: {
                project_name: this.project.title || '',
                price_list_number: lastPL.priceListNumber || '',
                price_list_name: lastPL.displayName || '',
                created_by: auth.currentUser.displayName || '',
                product_removed_name:
                  productRemoved
                    .map(
                      (product, index) =>
                        `${index + 1}. ${product.productItem}${
                          product.vendorName
                            ? `. Vendor: ${product.vendorName}`
                            : ''
                        }`
                    )
                    .join('<br>') || '',
                product_name:
                  productApprove
                    .map(
                      (product, index) =>
                        `${index + 1}. ${product.productItem}${
                          product.vendorName
                            ? `. Vendor: ${product.vendorName}`
                            : ''
                        }`
                    )
                    .join('<br>') || '',
                detail_url:
                  `${import.meta.env.VITE_HOST_DOMAIN}/price-list/${lastPL.priceListNumber}?productTab=new-added-product` ||
                  '',
                roofing_company_name: this.setting.companyName || '',
                roofing_company_address: this.setting.address || '',
                roofing_company_phone: this.setting.phoneNumber || '',
              },
              attachments: [],
              scheduleSendingTime: '',
            })
            .then(() => {
              return axiosService.post('/notification/trigger-email-service', {
                sender: '',
              });
            })
            .catch(error => {
              // eslint-disable-next-line no-console
              console.error('[Send Email]', error);
              throw new Error(error.message);
            })
        );
      }
      Promise.all(sendMailPromises);
    },
  },
};
