<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="handlePopupClosed"
    @popup:opened="handlePopupOpened"
  >
    <div class="page">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Edit {{ safetyDetail.safetyNumber }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onDone">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div
        media-list
        class="page-content"
      >
        <f7-list
          :inline-labels="$device.desktop"
          class="margin-top"
          :class="{ 'no-margin': !$device.desktop }"
          style="border-radius: 3px; overflow: hidden"
          no-hairlines
        >
          <f7-list-input
            label="Document Name"
            placeholder="Enter document name"
            :value="safetyDetail.title"
            @input="onChangeDocumentName($event.target.value)"
            type="text"
            error-message-force
          >
            <required-asterisk slot="label" />
          </f7-list-input>
          <f7-list-input
            label="Status"
            placeholder="Select status"
            :value="safetyDetail.status"
            type="select"
            @change="onChangeStatus($event.target.value)"
            error-message-force
          >
            <!-- <required-asterisk slot="label" /> -->
            <option
              disabled
              value=""
            >
              Select status
            </option>
            <option
              v-for="item in statusList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </option>
          </f7-list-input>
        </f7-list>
      </div>
    </div>
  </f7-popup>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, getCurrentInstance } from 'vue';
import { useSafetyStore } from '../../../../stores/safety-hub/actions';

interface FileInput {
  name: string;
}
interface SafetyItem {
  safetyNumber: string;
  title: string;
  status: string;
  fileName?: string;
  id: string;
}
export default defineComponent({
  props: {
    filesInput: { type: Array as () => FileInput[], default: () => [] },
  },
  setup(props, { emit }) {
    const store = useSafetyStore();
    const instance = getCurrentInstance() as any;

    const popupOpened = ref(false);
    const safetyDetail = reactive<SafetyItem>({
      safetyNumber: '',
      title: '',
      status: '',
      fileName: '',
      id: '',
    });
    const statusList = ref([
      { value: 'active', text: 'Active' },
      { value: 'inactive', text: 'Inactive' },
    ]);
    /**
     * Open the popup
     */
    const open = async (item: SafetyItem) => {
      popupOpened.value = true;
      Object.assign(safetyDetail, item);
    };

    /**
     * Handle popup opened
     */
    const handlePopupOpened = () => {};

    /**
     * Handle popup closed
     */
    const handlePopupClosed = () => {
      popupOpened.value = false;
      Object.assign(safetyDetail, {
        safetyNumber: '',
        title: '',
        status: '',
        fileName: '',
      });
    };

    const onChangeStatus = (value: string) => {
      safetyDetail.status = value;
    };
    const onChangeDocumentName = (value: string) => {
      safetyDetail.title = value;
    };
    /**
     * Emit done event with files and descriptions
     */
    const onDone = async () => {
      instance.proxy.$f7.preloader.show();
      const { id, title, status } = safetyDetail;

      await store.updateSafety(id, { title, status });
      emit('done');
      instance.proxy.$f7.preloader.hide();
      popupOpened.value = false;
    };

    return {
      popupOpened,
      open,
      handlePopupOpened,
      handlePopupClosed,
      onDone,
      safetyDetail,
      statusList,
      onChangeStatus,
      onChangeDocumentName,
    };
  },
});
</script>

<style scoped lang="scss">
.list ::v-deep ul::before,
.list ::v-deep ul::after {
  display: none;
}

.list ::v-deep ul {
  border-radius: 4px;
  margin: 0px 10px 0px 10px;
}
.list ::v-deep .item-label {
  font-size: 14px;
  font-weight: bold;
  max-width: 95%;
}
.item-label ::v-deep .icon {
  right: -80%;
}
i.icon-clear {
  margin-right: 2%;
  position: absolute;
  // top: calc(100vh - 885px);
  top: calc(10px);
  right: 0%;
}
</style>
