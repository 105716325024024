<template>
  <f7-popup
    tablet-fullscreen
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="closePopup"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Invoice Detail</f7-nav-title>
        <f7-nav-right></f7-nav-right>
      </f7-navbar>

      <div
        style="
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: center;
        "
      >
        <div class="box-header">
          <p style="padding-left: 10px">{{ invoice.invoiceNumber || '' }}</p>
          <div class="box-action">
            <div
              @click="onEdit"
              v-show="
                checkVisibleButton(invoiceDetail.status, 'edit') &&
                !invoice.syncFromQB
              "
            >
              <f7-icon
                size="18"
                class="cursor-pointer"
                f7="pencil"
                color="primary"
              ></f7-icon>
            </div>
            <div
              @click="onDownloadPDF"
              v-show="checkVisibleButton(invoiceDetail.status, 'download-pdf')"
            >
              <f7-icon
                size="18"
                class="cursor-pointer"
                f7="arrow_down_doc"
                color="primary"
              ></f7-icon>
            </div>
            <div
              @click="onSentEmail"
              v-show="checkVisibleButton(invoiceDetail.status, 'send-email')"
            >
              <f7-icon
                size="18"
                class="cursor-pointer"
                f7="envelope"
                color="primary"
              ></f7-icon>
            </div>
            <div
              @click="onMarkAsSentInvoice(invoice)"
              v-show="checkVisibleButton(invoiceDetail.status, 'mark-as-sent')"
            >
              <f7-icon
                size="18"
                class="cursor-pointer"
                f7="checkmark"
                color="primary"
              ></f7-icon>
            </div>
            <div
              @click="onRecordPayment"
              v-show="
                checkVisibleButton(invoiceDetail.status, 'payment-record')
              "
            >
              <f7-icon
                size="18"
                class="cursor-pointer"
                f7="money_dollar_circle"
                color="primary"
              ></f7-icon>
            </div>
            <div
              @click="onCancelInvoice(invoice)"
              v-show="checkVisibleButton(invoiceDetail.status, 'cancel')"
            >
              <f7-icon
                size="18"
                class="cursor-pointer"
                f7="escape"
                color="primary"
              ></f7-icon>
            </div>
          </div>
        </div>

        <div
          style="
            transform: scale(0.45);
            transform-origin: top;
            margin-top: 50px;
            max-height: 80vh;
          "
        >
          <invoice-detail-skeleton
            v-if="!invoice"
            :invoiceDetail="invoiceDetail"
          ></invoice-detail-skeleton>

          <invoice-detail
            v-else
            :invoiceDetail="invoiceDetail"
            :invoiceTemplate="invoiceTemplate"
          ></invoice-detail>
        </div>
      </div>

      <payment-record-table
        v-if="paymentRecordList && paymentRecordList.length > 0"
        :items="paymentRecordList"
        @onEdit="onEditRecordPayment"
        @onDelete="onDeleteRecordPayment"
      >
      </payment-record-table>
    </f7-page>
  </f7-popup>
</template>

<script>
import InvoiceDetail from '../details/InvoiceDetail.vue';
import PaymentRecordTable from '../tables/PaymentRecordTable.vue';

import { mapActions, mapGetters } from 'vuex';
import invoiceMixins from '../../mixins/invoice-mixin';
import _ from 'lodash';
import moment from 'moment';
import { INVOICE_STATUS_OVERDUE } from '../../utility/const';
import InvoiceDetailSkeleton from '../skeleton/SkeletonBlock.vue';

export default {
  components: {
    InvoiceDetail,
    PaymentRecordTable,
    InvoiceDetailSkeleton,
  },

  mixins: [invoiceMixins],

  data: () => {
    return {
      popupOpened: false,
    };
  },

  computed: {
    ...mapGetters('invoices/invoices', ['invoice']),
    ...mapGetters('invoices/payment-record', ['paymentRecordList']),
    ...mapGetters('common/template', ['invoiceTemplate']),

    invoiceDetail() {
      return {
        ..._.cloneDeep(this.invoice || {}),
        invoiceDate:
          (this.invoice || {}).invoiceDate && this.invoice.invoiceDate.toDate
            ? moment(this.invoice.invoiceDate.toDate(), 'MM/DD/YYYY').format(
                'MM/DD/YYYY'
              )
            : '',
        dueDate:
          (this.invoice || {}).dueDate && this.invoice.dueDate.toDate
            ? moment(this.invoice.dueDate.toDate(), 'MM/DD/YYYY').format(
                'MM/DD/YYYY'
              )
            : '',
        status:
          this.getOverdueDays(this.invoice) > 0
            ? INVOICE_STATUS_OVERDUE
            : this.invoice.status,
      };
    },
  },

  methods: {
    ...mapActions({
      bindInvoice: 'invoices/invoices/bindInvoice',
      updateInvoice: 'invoices/invoices/updateInvoice',
      bindPaymentRecordListBy:
        'invoices/payment-record/bindPaymentRecordListBy',
      deletePaymentRecord: 'invoices/payment-record/deletePaymentRecord',
    }),

    open(invoice) {
      this.$f7.preloader.show();
      const promises = [];
      promises.push(
        this.bindPaymentRecordListBy({
          prop: 'invoiceNumber',
          op: '==',
          val: invoice.invoiceNumber,
        })
      );

      promises.push(this.bindInvoice(invoice.id));
      Promise.all(promises).finally(() => {
        this.$f7.preloader.hide();
      });
      this.popupOpened = true;
    },

    closePopup() {
      this.popupOpened = false;
    },

    // eslint-disable-next-line no-unused-vars
    gotoPaymentReceived(check) {
      //TODO go to payment received
    },

    onEdit() {
      if (this.popupOpened) {
        this.popupOpened = false;
      }
      this.$emit('onEdit');
    },

    onCancel() {
      this.$emit('onCancelInvoice');
    },

    onMarkAsSent() {
      this.$emit('onMarkAsSent');
    },

    onRecordPayment() {
      this.$emit('onRecordPayment');
    },

    onEditRecordPayment(paymentRecord) {
      this.$emit('onEditRecordPayment', paymentRecord);
    },

    onDeleteRecordPayment() {
      // this.$ri.dialog.openWarningDialog({
      //   title: "Delete payment record",
      //   content: `Are you sure you want to delete payment record ${paymentRecord.paymentNumber}?`,
      //   onClick: (_sefl, index) => {
      //     if (index === 0) {
      //       _sefl.app.dialog.close();
      //     } else if (index === 1) {
      //       this.$f7.preloader.show();
      //       this.deletePaymentRecord(paymentRecord.id).then(() => {
      //         this.$f7.preloader.hide();
      //         this.$f7.toast
      //           .create({
      //             text: "Invoice was deleted!",
      //             closeOnClick: true,
      //             closeButton: false,
      //             closeTimeout: 5000
      //           })
      //           .open();
      //       });
      //     }
      //   }
      // });
    },

    onSentEmail() {
      this.$emit('onSentEmail');
    },

    onDownloadPDF() {
      this.$emit('onDownloadPDF');
    },
  },
};
</script>

<style>
.box-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 999;
  background-color: var(--f7-color-bg-4-neutral);
}
.box-action {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-right: 10px;
  align-items: center;
}
</style>
