import { FirebaseActions } from '../../../../services/firebase.service';
import * as constant from '../../../../utility/const';

export default tenant => {
  const qaChecklistActions = new FirebaseActions(
    `/system_client/${tenant}/qa-checklist`,
    'qaChecklist'
  );

  return {
    bindQaChecklistListBys: qaChecklistActions.bindCollectionBys,
    unbindQaChecklistList: qaChecklistActions.unbindCollection,
    createQaChecklist: qaChecklistActions.createDocument,
    updateQaChecklist: qaChecklistActions.updateDocument,
    deleteQaChecklist({ dispatch }, id) {
      return dispatch('updateQaChecklist', {
        id,
        doc: { [constant.PROP_IS_DELETED]: true },
      });
    },
  };
};
