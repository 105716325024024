import { checkAuth } from '../../../services/authentication.service';

export default [
  // Construction Swimlane
  {
    path: '/dashboard/swimlane/commercial/construction/project',
    displayRoles: ['owner', 'user'],
    meta: {
      businessCode: 'commercial',
      boardType: 'swimlane',
      boardCode: 'construction',
    },
    async: checkAuth(() => import('../pages/project/ProjectListPage.vue')),
    master: true,
    checkAccessPath: 'dashboard-main/commercial-board',
    detailRoutes: [
      // Main page
      {
        path: '/dashboard/swimlane/commercial/construction/project/:cardNumber',
        checkAccessPath: 'dashboard-main/commercial-board',
        displayRoles: ['owner', 'user'],
        meta: {
          businessCode: 'commercial',
          boardType: 'swimlane',
          boardCode: 'construction',
        },
        async: checkAuth(
          () => import('../pages/layout/ConstructionSwimlaneLayout.vue')
        ),
      },
      // Create new task
      {
        path: '/dashboard/swimlane/commercial/construction/project/:cardNumber/task/new',
        checkAccessPath: 'dashboard-main/commercial-board',
        displayRoles: ['owner', 'user'],
        meta: {
          businessCode: 'commercial',
          boardType: 'swimlane',
          boardCode: 'construction',
          isNewTask: true,
        },
        async: checkAuth(
          () => import('../pages/layout/ConstructionSwimlaneLayout.vue')
        ),
      },

      // Construction Dashboard of a project
      {
        path: '/dashboard/swimlane/commercial/construction/project/:cardNumber/action/:actionCode/task/:taskNumber',
        checkAccessPath: 'dashboard-main/commercial-board',
        displayRoles: ['owner', 'user'],
        name: 'construction-task-detail',
        meta: {
          businessCode: 'commercial',
          boardType: 'swimlane',
          boardCode: 'construction',
        },
        async: checkAuth(
          () => import('../pages/layout/ConstructionSwimlaneLayout.vue')
        ),
      },
    ],
  },
  // Construction Gridview
  {
    path: '/dashboard/grid/commercial/construction/project',
    checkAccessPath: 'dashboard-main/commercial-board',
    displayRoles: ['owner', 'user'],
    meta: {
      businessCode: 'commercial',
      boardType: 'grid',
      boardCode: 'construction',
    },
    async: checkAuth(() => import('../pages/project/ProjectListPage.vue')),
    master: true,
    detailRoutes: [
      {
        path: '/dashboard/grid/commercial/construction/project/:cardNumber',
        checkAccessPath: 'dashboard-main/commercial-board',
        displayRoles: ['owner', 'user'],
        meta: {
          businessCode: 'commercial',
          boardType: 'grid',
          boardCode: 'construction',
        },
        async: checkAuth(
          () => import('../pages/layout/ConstructionGridLayout.vue')
        ),
      },
      // Create new task
      {
        path: '/dashboard/grid/commercial/construction/project/:cardNumber/task/new',
        checkAccessPath: 'dashboard-main/commercial-board',
        displayRoles: ['owner', 'user'],
        meta: {
          businessCode: 'commercial',
          boardType: 'grid',
          boardCode: 'construction',
          isNewTask: true,
        },
        async: checkAuth(
          () => import('../pages/layout/ConstructionGridLayout.vue')
        ),
      },

      // Construction Dashboard of a project
      {
        path: '/dashboard/grid/commercial/construction/project/:cardNumber/action/:actionCode/task/:taskNumber',
        checkAccessPath: 'dashboard-main/commercial-board',
        displayRoles: ['owner', 'user'],
        name: 'construction-task-detail',
        meta: {
          businessCode: 'commercial',
          boardType: 'grid',
          boardCode: 'construction',
        },
        async: checkAuth(
          () => import('../pages/layout/ConstructionGridLayout.vue')
        ),
      },
    ],
  },

  // from my scheduling / employee scheduling
  {
    path: '/:mainRouteFrom/currentView/:currentView/startWeek/:startWeek/currentUser/:userId/dashboard/swimlane/commercial/construction/project',
    displayRoles: ['owner', 'user'],
    meta: {
      businessCode: 'commercial',
      boardType: 'swimlane',
      boardCode: 'construction',
    },
    async: checkAuth(() => import('../pages/project/ProjectListPage.vue')),
    master: true,
    checkAccessPath: 'dashboard-main/commercial-board',
    detailRoutes: [
      // Main page
      {
        path: '/:mainRouteFrom/currentView/:currentView/startWeek/:startWeek/currentUser/:userId/dashboard/swimlane/commercial/construction/project/:cardNumber',
        checkAccessPath: 'dashboard-main/commercial-board',
        displayRoles: ['owner', 'user'],
        meta: {
          businessCode: 'commercial',
          boardType: 'swimlane',
          boardCode: 'construction',
        },
        async: checkAuth(
          () => import('../pages/layout/ConstructionSwimlaneLayout.vue')
        ),
      },
    ],
  },
];
