import * as types from './types';
import {
  FirebaseActions,
  StorageActions,
} from '../../../../services/firebase.service';
import userService from '../../../../services/user.service';
import dashboardService from '../../../../services/dashboard.service';
import _ from 'lodash';

export default tenant => {
  // const currentTenant
  const dashboardActions = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    'action'
  );
  const dashboardCards = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    'card'
  );
  const constructionboardCards = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    'constructionProject'
  );
  const archiveCards = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    'archiveCard'
  );
  const contactActions = new FirebaseActions(
    `/system_client/${tenant}/operation_contact`,
    'contact'
  );
  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    'company'
  );
  const propertyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_property`,
    'property'
  );
  return {
    getContact: contactActions.getDocument,
    getCompany: companyActions.getDocument,
    getProperty: propertyActions.getDocument,

    setCurrentResponse({ commit, state }, actionId) {
      const action = state.actionList.find(action => {
        return action.id === actionId;
      });
      if (action && action.response) {
        return;
      }
      return FirebaseActions.getCollection(
        `/system_client/${tenant}/operation_dashboard/${actionId}/response`
      ).then(response => {
        commit(types.SET_ACTION_RESPONSE, response);
      });
    },

    setCurrentCard({ commit }, id) {
      commit(types.SET_CURRENT_CARD, id);
    },

    setCurrentAction({ commit }, id) {
      commit(types.SET_CURRENT_ACTION, id);
    },

    setCurrentCardValues({ commit }, values) {
      return commit(types.SET_CURRENT_CARD_VALUES, values);
    },

    clearCurrentCard({ commit }) {
      commit(types.CLEAR_CURRENT_CARD);
    },

    async bindCardList({ state, dispatch }, { businessCode, boardCode }) {
      await dispatch('getActionListByBoard', boardCode);
      const acitonIds = state.actionList.map(item => item.id);
      if (!_.isEmpty(businessCode) && !_.isEmpty(acitonIds)) {
        await dispatch('bindCardListBys', [
          {
            prop: 'status',
            val: 'open',
            op: '==',
          },
          {
            prop: 'businessCode',
            val: businessCode,
            op: '==',
          },
          {
            prop: 'actions',
            op: 'array-contains-any',
            val: acitonIds,
          },
        ]);
      }
    },

    async getFirstConstructionProject({ state, dispatch }) {
      await dispatch('getActionListByBoard', 'construction');
      const acitonIds = state.actionList.map(item => item.id);
      if (!_.isEmpty(acitonIds)) {
        const list = await dispatch('getProjectBys', [
          {
            prop: 'status',
            val: 'open',
            op: '==',
          },
          {
            prop: 'businessCode',
            val: 'commercial',
            op: '==',
          },
          {
            prop: 'actions',
            op: 'array-contains-any',
            val: acitonIds,
          },
        ]);

        if (!_.isEmpty(list)) {
          return list.sort((a, b) => b.createdAt - a.createdAt)[0];
        }
      }

      return null;
    },

    bindUserData({ commit }) {
      userService.getUserList().then(users => {
        commit(types.SET_USERS_LIST, users);
      });
    },

    setFilterUsers({ commit }, payload) {
      commit(types.SET_FILTER_USERS, payload);
    },

    setFilterSearch({ commit }, payload) {
      commit(types.SET_FILTER_SEARCH, payload);
    },

    setFilterWeek({ commit }, payload) {
      commit(types.SET_FILTER_WEEK, payload);
    },

    setCurrentBoard({ commit }, boardCode) {
      commit(types.SET_CURRENT_BOARD, boardCode);
    },

    setCurrentBusinessCode({ commit }, businessCode) {
      commit(types.SET_CURRENT_BUSINESS_CODE, businessCode);
    },

    setConstructionProjectId({ commit }, projectId) {
      commit(types.SET_CONSTRUCTION_PROJECT_ID, projectId);
    },

    setConstructionProjectNumber({ commit }, projectNumber) {
      commit(types.SET_CONSTRUCTION_PROJECT_NUMBER, projectNumber);
    },

    setIsDeleteTempleProject({ commit }, value) {
      commit(types.SET_IS_DELETE_TEMPLE_PROJECT, value);
    },

    bindActionListBy: dashboardActions.bindCollectionBy,
    getActionListBys: dashboardActions.getDocumentBys,
    unbindActionList: dashboardActions.unbindCollection,
    getActionBys: dashboardActions.getDocumentBys,
    getAction: dashboardActions.getDocument,
    async getActionList({ commit, dispatch }) {
      const list = await dispatch('getActionListBys', [
        {
          prop: 'isDeleted',
          op: '==',
          val: false,
        },
      ]);

      commit(types.SET_ACTION_LIST, list);
    },

    async getActionListByBoard({ commit, dispatch }, boardCode) {
      const list = await dispatch('getActionListBys', [
        {
          prop: 'isDeleted',
          op: '==',
          val: false,
        },
        {
          prop: 'boards',
          op: 'array-contains',
          val: boardCode,
        },
      ]);

      commit(types.SET_ACTION_LIST, list);
    },

    getProjectBys: dashboardCards.getDocumentBys,
    bindCardListBy: dashboardCards.bindCollectionBy,
    bindCardListBys: dashboardCards.bindCollectionBys,
    unbindCardList: dashboardCards.unbindCollection,

    bindCard: dashboardCards.bindDocument,
    unbindCard: dashboardCards.unbindDocument,
    saveCard: dashboardCards.updateDocument,
    createCard: dashboardCards.createDocument,
    deleteTempleCard: dashboardCards.deleteDocument,

    bindArchiveCardListBy: archiveCards.bindCollectionBy,

    bindConstructionProject: constructionboardCards.bindDocument,
    bindConstructionProjectListBys: constructionboardCards.bindCollectionBys,

    async getProjectByCardNumber({ commit, dispatch }, cardNumber) {
      const project = await dispatch('getProjectBys', [
        {
          prop: 'cardNumber',
          val: cardNumber,
          op: '==',
        },
      ]);
      commit(
        types.SET_CONSTRUCTION_PROJECT_ID,
        project.length > 0 ? project[0].id : ''
      );
      return project;
    },

    async composeDataProject({ dispatch }, doc) {
      let project = _.cloneDeep(doc);
      if (project.contactId) {
        const contact = await dispatch('getContact', project.contactId);
        project.contactName = contact
          ? ((contact.firstName || '') + ' ' + (contact.lastName || '')).trim()
          : '';
      }
      if (project.companyId) {
        const company = await dispatch('getCompany', project.companyId);
        project.companyName = company ? company.companyName || '' : '';
      }
      if (project.propertyId) {
        const property = await dispatch('getProperty', project.propertyId);
        project.propertyName = property ? property.propertyName || '' : '';
        project.propertyAddress =
          ((property && property.addresses) || []).find(
            r => r.code === 'main'
          ) ||
          ((property && property.addresses) || [])[0] ||
          {};
      }
      return project;
    },

    async createProject({ dispatch }, { tenantId, project }) {
      const newProject = await dispatch('composeDataProject', project);
      return dashboardService.addCardDoc(tenantId, newProject);
    },

    async updateProject({ dispatch }, { tenantId, id, project }) {
      const newProject = await dispatch('composeDataProject', project);
      return dashboardService.updateCardDoc(tenantId, id, newProject);
    },

    async updateCard({ dispatch }, { id, doc }) {
      const newProject = await dispatch('composeDataProject', doc);
      return dispatch('saveCard', { id, doc: newProject });
    },

    getCardInColumn({ dispatch }, columnId) {
      return dispatch('getProjectBys', [
        {
          prop: 'columnId',
          op: '==',
          val: columnId,
        },
      ]);
    },

    setCurrentActionIdsAfterMoveOrAdd({ commit }, value) {
      commit(types.SET_CURRENT_ACTION_IDS_AFTER_MOVE_OR_ADD, value);
    },

    setConditionToDisplayResponseBtn({ commit }, condition) {
      commit(types.SET_CONDITION_TO_DISPLAY_RESPONSE_BTN, condition);
    },

    setCurrentSection({ commit }, section) {
      commit(types.SET_CURRENT_SECTION, section);
    },

    // eslint-disable-next-line no-unused-vars
    uploadImage({ commit }, { projectId, base64Data, fileName, folderName }) {
      const name = `${+new Date()}_${fileName}`;
      const fullPath = `projects/${projectId}/${folderName}/${name}`;
      return StorageActions.uploadFileBase64(fullPath, base64Data, {});
    },
  };
};
