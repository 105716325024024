<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Company Details</f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="editCompany">Edit</f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-block>
      <f7-segmented
        strong
        tag="p"
      >
        <f7-button
          :active="activeTab === 'company-detail'"
          @click.native="activeTab = 'company-detail'"
          >Info</f7-button
        >
        <f7-button
          :active="activeTab === 'company-properties'"
          @click.native="activeTab = 'company-properties'"
          >Properties</f7-button
        >
      </f7-segmented>
    </f7-block>
    <div v-show="activeTab === 'company-detail'">
      <company-detail-tab></company-detail-tab>
    </div>
    <div v-show="activeTab === 'company-properties'">
      <company-properties-tab></company-properties-tab>
    </div>
    <!-- Name -->
  </f7-page>
</template>

<script>
import CompanyDetailTab from './tabs/CompanyDetailTab';
import CompanyPropertiesTab from './tabs/CompanyPropertiesTab';
import { mapActions } from 'vuex';

export default {
  components: {
    CompanyDetailTab,
    CompanyPropertiesTab,
  },

  data() {
    return {
      activeTab: 'company-detail',
    };
  },

  async created() {
    this.$f7.preloader.show();

    this.activeTab =
      (this.$f7route.query && this.$f7route.query.activeTab) ||
      'company-detail';

    if (this.$f7route.params.companyId) {
      await this.getCompanyById(this.$f7route.params.companyId);
    }

    this.$f7.preloader.hide();
  },

  methods: {
    ...mapActions('contact-book/company', ['getCompanyById']),

    editCompany() {
      this.$f7router.navigate(
        `/contactbook/company/edit/${this.$f7route.params.companyId}`
      );
    },

    handleBack() {
      this.$f7router.navigate('/contactbook/companies');
      this.setCurrentCompany('');
    },
  },
};
</script>
