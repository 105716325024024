/* eslint-disable no-unused-vars */
import scheduleSetting from './schedule-setting';

export default tenant => {
  return {
    namespaced: true,
    modules: {
      'schedule-setting': scheduleSetting,
    },
  };
};
