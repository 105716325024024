<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="close"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left> </f7-nav-left>
        <f7-nav-title>{{
          listType === 'menu' ? 'Menu Items' : 'Assign User'
        }}</f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar
        disable-button-text
        :placeholder="`Search ${listType === 'menu' ? 'menu items' : 'user'}`"
        :clear-button="true"
        :value="searchValue"
        @input="searchValue = $event.target.value"
        @searchbar:disable="searchValue = ''"
        @searchbar:clear="searchValue = ''"
        class="search-list-popup"
      ></f7-searchbar>
      <div class="page-nav">
        <div class="select-all-container">
          <f7-checkbox
            :id="'selectAllCheckbox'"
            :checked="selectAllChecked"
            @change="handleSelectAllChange"
          ></f7-checkbox>
          <label
            style="cursor: pointer"
            :for="'selectAllCheckbox'"
            @click.prevent="handleSelectAllChange"
          >
            Select All
          </label>
        </div>
      </div>
      <f7-list class="search-list searchbar-found contact-list margin-page">
        <f7-list-item
          no-chevron
          checkbox
          v-for="item in filteredGroups"
          :key="item.id"
          :value="item.id"
          :checked="
            selectedList ? selectedList.some(x => x === item.id) : false
          "
          :title="item.displayName"
          @change.native="handleCheckboxChange($event.target.checked, item)"
        >
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import _ from 'lodash';

export default {
  data: () => {
    return {
      popupOpened: false,
      selectAllChecked: false,
      toggleSelectChecked: false,
      selectedItems: [],
      searchValue: '',
      listType: '',
    };
  },
  props: {
    selected: {
      type: [Object, Array],
      default: () => [],
    },
    menus: Array,
    users: Array,
    errorMessage: String,
    isRequired: {
      default: false,
      type: Boolean,
    },
  },

  methods: {
    handleSelectAllChange() {
      this.selectAllChecked = !this.selectAllChecked;
      this.selectedItems = this.selectAllChecked
        ? this.computedGroups.map(item => item.id)
        : [];
      this.$emit('select', this.selectedItems, this.listType);
    },
    close() {
      this.popupOpened = false;
      this.selectAllChecked = false;
      (this.selectedItems = []), (this.searchValue = ''), (this.listType = '');
    },
    open(value) {
      this.listType = value;
      this.popupOpened = true;
      const isSelectedListFull =
        this.selectedList?.length === this.computedGroups.length;
      const hasSelectedItems = this.selectedList?.length > 0;

      this.selectAllChecked = isSelectedListFull;
      if (hasSelectedItems) {
        this.selectedItems =
          value === 'menu'
            ? this.selected.menuIDs || this.selected
            : this.selected.userIDs;
      }
    },
    handleCheckboxChange(checked, item) {
      if (checked && !this.selectedItems.includes(item)) {
        this.selectedItems = [...this.selectedItems, item.id];
      } else {
        this.selectedItems = this.selectedItems.filter(r => r !== item.id);
      }
      const isSelectedListFull =
        this.selectedItems?.length === this.computedGroups.length;
      this.selectAllChecked = isSelectedListFull;
      this.$emit('select', this.selectedItems, this.listType);
    },
  },
  computed: {
    selectedList() {
      if (this.listType === 'menu') {
        return this.selected.menuIDs || this.selected;
      } else {
        return this.selected.userIDs;
      }
    },
    computedGroups() {
      return this.listType === 'menu' ? this.menus : this.users;
    },
    filteredGroups() {
      if (!this.searchValue) {
        return this.computedGroups;
      }
      const searchLower = this.searchValue.toLowerCase();
      return this.computedGroups.filter(group =>
        group.displayName.toLowerCase().includes(searchLower)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.page-nav {
  display: flex;
  padding: 10px 10px 10px 16px;
  justify-content: space-between;
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  z-index: 9999;
  background-color: var(--f7-list-group-title-bg-color);
}
.select-all-container,
.toggle-select-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.select-all-container label,
.toggle-select-container label {
  color: #ef4e3a;
  background-color: var(--f7-list-group-title-bg-color);
}
.margin-page {
  margin-top: 43px;
}
</style>
