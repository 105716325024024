<template>
  <div class="popup-scroll-bar">
    <!-- segmented buttons -->
    <f7-block>
      <f7-segmented
        strong
        tag="p"
      >
        <f7-button
          :active="activeButton === 'contact-info'"
          @click.native="activeButton = 'contact-info'"
          >Contact Info</f7-button
        >
        <!-- <f7-button
          :active="activeButton === 'work-order'"
          @click.native="activeButton = 'work-order'"
          >Work Order</f7-button
        > -->
        <f7-button
          :active="activeButton === 'action-details'"
          @click.native="activeButton = 'action-details'"
          >Action Details</f7-button
        >
      </f7-segmented>
    </f7-block>

    <div v-show="activeButton === 'contact-info'">
      <f7-block-header>Contact information</f7-block-header>
      <f7-list>
        <!-- contact name -->
        <!-- add this attribute to change link @click.native="navigateToSelectContact" -->
        <f7-list-item
          link
          @click.native="navigateToSelectContact"
          v-show="contactName"
          header="Contact Name"
          :title="contactName ? contactName : 'Select contact'"
        >
          <f7-icon
            slot="media"
            color="--f7-theme-color"
            f7="person"
          ></f7-icon>
        </f7-list-item>
        <!-- Phone -->
        <f7-list-item
          v-for="(phone, index) in (contact || {}).phones"
          :key="`phone-${index}`"
        >
          <div slot="header">{{ 'Phone Number' }}</div>
          <div
            class="list-item-title"
            slot="title"
          >
            {{ phone.value }}
          </div>
          <f7-icon
            slot="media"
            color="primary"
            f7="phone"
          ></f7-icon>
          <div slot="after-title">
            <f7-link
              class="external icon-link"
              :href="'tel:' + phone.value"
              icon-f7="phone_circle_fill"
            ></f7-link>
          </div>
        </f7-list-item>

        <!-- Email -->
        <f7-list-item
          v-for="(email, index) in (contact || {}).emails"
          :key="`email-${index}`"
        >
          <div slot="header">{{ 'Email' }}</div>
          <div
            class="list-item-title"
            slot="title"
          >
            {{ email.value }}
          </div>
          <f7-icon
            slot="media"
            color="primary"
            f7="envelope"
          ></f7-icon>
          <div slot="after-title">
            <f7-link
              class="external icon-link"
              :href="'mailto:' + email.value"
              icon-f7="envelope_circle_fill"
            ></f7-link>
          </div>
        </f7-list-item>

        <!-- Address -->
        <f7-list-item
          v-for="(address, index) in (contact || {}).addresses"
          :key="`address-${index}`"
        >
          <div slot="header">{{ 'Address' }}</div>
          <div
            class="list-item-title"
            slot="title"
          >
            {{ getFullAddress(address) }}
          </div>
          <f7-icon
            slot="media"
            color="primary"
            f7="map"
          ></f7-icon>
          <div slot="after-title">
            <f7-link
              class="external icon-link"
              :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
              icon-f7="placemark_fill"
            ></f7-link>
          </div>
        </f7-list-item>
        <!-- company name -->
        <f7-list-item
          v-if="$f7route.route.meta.boardCode !== BUSINESS_CODE_RESIDENTIAL"
          link
          @click.native="navigateToSelectCompany"
          header="Company Name"
          :title="companyName ? companyName : 'Select company'"
          :footer="companyAddress"
        >
          <f7-icon
            slot="media"
            color="--f7-theme-color"
            f7="globe"
          ></f7-icon>
          <div
            class="item-error-message"
            slot="footer"
          >
            {{ selectCompanyMessage }}
          </div>
        </f7-list-item>
        <!-- Property Name -->
        <f7-list-item
          v-if="$f7route.route.meta.boardCode !== BUSINESS_CODE_RESIDENTIAL"
          link
          @click.native="navigateToSelectProperty"
          header="Property Name"
          :title="propertyName ? propertyName : 'Select property'"
          :footer="propertyAddress"
        >
          <f7-icon
            slot="media"
            color="--f7-theme-color"
            f7="building_2"
          ></f7-icon>
          <div
            class="item-error-message"
            slot="footer"
          >
            {{ !companyName ? selectPropertyMessage : '' }}
          </div>
        </f7-list-item>
        <!-- Property -->
        <!-- Project Title -->
        <!-- <f7-list-item
          v-show="card && card.title"
          header="Project Title"
          :title="(card && card.title) || ''"
        >
          <f7-icon slot="media" color="--f7-theme-color" f7="ticket"></f7-icon>
        </f7-list-item> -->
        <f7-list-input
          v-show="card && card.title"
          label="Project Title"
          type="text"
          placeholder="Enter project title"
          clear-button
          :value="displayCard.title"
          @input="displayCard.title = $event.target.value.trim()"
          @change="onUpdateCard('title', $event.target.value.trim())"
        >
          <required-asterisk slot="label"></required-asterisk>
          <input-icon
            slot="media"
            icon="ticket"
          ></input-icon>
        </f7-list-input>
      </f7-list>
    </div>

    <!-- <div v-show="activeButton === 'work-order'">
      <work-order-table v-on="$listeners"></work-order-table>
    </div> -->

    <div v-show="activeButton === 'action-details'">
      <!-- reridential-->
      <f7-block-header
        v-if="card && card.businessCode === BUSINESS_CODE_RESIDENTIAL"
        class="display-flex justify-content-space-between"
      >
        <div>Residential</div>
        <div>Due Date: {{ toDisplayDateString(card && card.dueDate) }}</div>
      </f7-block-header>
      <f7-list
        v-if="card && card.businessCode === BUSINESS_CODE_RESIDENTIAL"
        accordion-list
        accordion-opposite
      >
        <f7-list-item
          v-for="(item, index) in actionList('residential')"
          :key="index"
          accordion-item
          :class="`accordion-item_${item.componentName}`"
        >
          <div
            slot="title"
            :style="`font-weight: 600; color: ${
              item.status === STATUS_ACTION_REQUIRED_PENDING
                ? getStatus(item.status).color
                : ''
            }`"
          >
            {{ item.title }}
          </div>
          <f7-accordion-content>
            <f7-block>
              <!-- Lead -->
              <f7-list v-if="item.componentName == 'LeadsAction'">
                <!-- Insurance Company -->
                <f7-list-item title="Insurance Company">
                  <div slot="after-title">
                    {{ insuranceCompany.companyName || 'No data!' }}
                  </div>
                </f7-list-item>
                <!-- Inspector Name -->
                <f7-list-item title="Inspector Name">
                  <div slot="after-title">
                    {{ (card && card.inspectorName) || 'No data!' }}
                  </div>
                </f7-list-item>
                <!-- Inspect Date/Time -->
                <f7-list-item title="Inspect Date/Time">
                  <div slot="after-title">
                    {{
                      toDisplayDateString(card && card.inspectDate) +
                        ' - ' +
                        (card && card.inspectTime) || 'No data!'
                    }}
                  </div>
                </f7-list-item>

                <!-- Leads Source -->
                <f7-list-item title="Leads Source">
                  <div slot="after-title">
                    {{
                      getLeadSource((card && card.leadSource) || '')
                        .displayName || 'No data!'
                    }}
                  </div>
                </f7-list-item>

                <!-- Sale Reps -->
                <f7-list-item title="Sale Reps">
                  <div slot="after-title">
                    {{ (card && card.salesRep) || 'No data!' }}
                  </div>
                </f7-list-item>

                <!-- Date of Loss -->
                <f7-list-item title="Date of Loss">
                  <div slot="after-title">
                    {{
                      toDisplayDateString(card && card.dateOfLoss) || 'No data!'
                    }}
                  </div>
                </f7-list-item>
              </f7-list>

              <!-- Claim/Inspection -->
              <f7-list v-if="item.componentName == 'InsuranceInspectionAction'">
                <!-- Insurance Company -->
                <f7-list-item title="Insurance Company">
                  <div slot="after-title">
                    {{
                      (insuranceCompany && insuranceCompany.companyName) ||
                      'No data!'
                    }}
                  </div>
                </f7-list-item>
                <!-- Policy -->
                <f7-list-item title="Policy#">
                  <div slot="after-title">
                    {{ (card && card.policy) || 'No data!' }}
                  </div>
                </f7-list-item>
                <!-- Claim -->
                <f7-list-item title="Claim#">
                  <div slot="after-title">
                    {{ (card && card.claim) || 'No data!' }}
                  </div>
                </f7-list-item>
              </f7-list>
              <!-- Insurance Review -->
              <f7-list v-if="item.componentName == 'InsuranceClaimAction'">
                <!-- Due date -->
                <f7-list-item title="Due Date">
                  <div slot="after-title">
                    {{
                      toDisplayDateString(card && card.dueDate) || 'No data!'
                    }}
                  </div>
                </f7-list-item>
                <!-- Approval Status -->
                <f7-list-item title="Approval Status">
                  <div slot="after-title">
                    {{ (card && card.approvalStatus) || 'No data!' }}
                  </div>
                </f7-list-item>
              </f7-list>
              <!-- Build Contract -->
              <f7-list v-if="item.componentName == 'ProposalResidentialAction'">
                <!-- Due date -->
                <f7-list-item title="Due Date">
                  <div slot="after-title">
                    {{
                      toDisplayDateString(card && card.dueDate) || 'No data!'
                    }}
                  </div>
                </f7-list-item>
                <!-- Shingle Type -->
                <f7-list-item title="Shingle Type">
                  <div slot="after-title">
                    {{ (card && card.shingleType) || 'No data!' }}
                  </div>
                </f7-list-item>
                <!-- Shingle Color -->
                <f7-list-item title="Shingle Color">
                  <div slot="after-title">
                    {{ (card && card.shingleColor) || 'No data!' }}
                  </div>
                </f7-list-item>
              </f7-list>
              <!-- Schedule Build -->
              <f7-list v-if="item.componentName == 'ScheduleProductionAction'">
                <!-- Cost Tracking -->
                <f7-list-item title="Cost Tracking">
                  <f7-checkbox
                    v-if="
                      card &&
                      card.scheduleProductionTaskStatus &&
                      card.scheduleProductionTaskStatus.costTracking !=
                        undefined
                    "
                    disabled
                    slot="after"
                    :checked="
                      card && card.scheduleProductionTaskStatus.costTracking
                    "
                  ></f7-checkbox>
                </f7-list-item>
                <!-- Material Tracking -->
                <f7-list-item title="Material Tracking">
                  <f7-checkbox
                    v-if="
                      card &&
                      card.scheduleProductionTaskStatus &&
                      card.scheduleProductionTaskStatus.materialTracking !=
                        undefined
                    "
                    disabled
                    slot="after"
                    :checked="
                      card && card.scheduleProductionTaskStatus.materialTracking
                    "
                  ></f7-checkbox>
                </f7-list-item>
                <!-- Schedule Production Date -->
                <f7-list-item title="Schedule Production Date">
                  <f7-checkbox
                    v-if="
                      card &&
                      card.scheduleProductionTaskStatus &&
                      card.scheduleProductionTaskStatus
                        .scheduleProductionDate != undefined
                    "
                    disabled
                    slot="after"
                    :checked="
                      card &&
                      card.scheduleProductionTaskStatus.scheduleProductionDate
                    "
                  ></f7-checkbox>
                </f7-list-item>
                <!-- Schedule Crews -->
                <f7-list-item title="Schedule Crews">
                  <f7-checkbox
                    v-if="
                      card &&
                      card.scheduleProductionTaskStatus &&
                      card.scheduleProductionTaskStatus.scheduleCrews !=
                        undefined
                    "
                    disabled
                    slot="after"
                    :checked="
                      card && card.scheduleProductionTaskStatus.scheduleCrews
                    "
                  ></f7-checkbox>
                </f7-list-item>
              </f7-list>
              <!-- Completion -->
              <f7-list v-if="item.componentName == 'CompletionAction'">
                <!-- Completed Date -->
                <f7-list-item title="Completed Date">
                  <f7-checkbox
                    v-if="
                      card &&
                      card.completionTaskStatus &&
                      card.completionTaskStatus.completedDate != undefined
                    "
                    disabled
                    slot="after"
                    :checked="card && card.completionTaskStatus.completedDate"
                  ></f7-checkbox>
                </f7-list-item>
                <!-- QA Check list -->
                <f7-list-item title="QA Check List">
                  <f7-checkbox
                    v-if="
                      card &&
                      card.completionTaskStatus &&
                      card.completionTaskStatus.qaChecklist != undefined
                    "
                    disabled
                    slot="after"
                    :checked="card && card.completionTaskStatus.qaChecklist"
                  ></f7-checkbox>
                </f7-list-item>
                <!-- Photo Completion -->
                <f7-list-item title="Photo Completion">
                  <f7-checkbox
                    v-if="
                      card &&
                      card.completionTaskStatus &&
                      card.completionTaskStatus.photosCompletion != undefined
                    "
                    disabled
                    slot="after"
                    :checked="
                      card && card.completionTaskStatus.photosCompletion
                    "
                  ></f7-checkbox>
                </f7-list-item>
                <!-- Invoice -->
                <f7-list-item title="Invoice">
                  <f7-checkbox
                    v-if="
                      card &&
                      card.completionTaskStatus &&
                      card.completionTaskStatus.invoice != undefined
                    "
                    disabled
                    slot="after"
                    :checked="card && card.completionTaskStatus.invoice"
                  ></f7-checkbox>
                </f7-list-item>
                <!-- Certificate Of Completion -->
                <f7-list-item title="Certificate Of Completion">
                  <f7-checkbox
                    v-if="
                      card &&
                      card.completionTaskStatus &&
                      card.completionTaskStatus.certificateOfCompletion !=
                        undefined
                    "
                    disabled
                    slot="after"
                    :checked="
                      card && card.completionTaskStatus.certificateOfCompletion
                    "
                  ></f7-checkbox>
                </f7-list-item>
              </f7-list>

              <f7-list v-if="item.componentName == 'CollectionAction'">
              </f7-list>
            </f7-block>
          </f7-accordion-content>
          <f7-chip
            slot="after"
            :text="getStatus(item.status).displayName"
            :color="getStatus(item.status).color"
          ></f7-chip>
        </f7-list-item>
      </f7-list>
      <!-- sales -->
      <f7-block-header
        v-if="card && card.businessCode === BUSINESS_CODE_COMMERCIAL"
        class="display-flex justify-content-space-between"
      >
        <div>Sales</div>
        <div>Due Date: {{ toDisplayDateString(card && card.dueDate) }}</div>
      </f7-block-header>
      <f7-list
        v-if="card && card.businessCode === BUSINESS_CODE_COMMERCIAL"
        accordion-list
        accordion-opposite
      >
        <f7-list-item
          v-for="(item, index) in actionList('sales')"
          :key="index"
          accordion-item
          :class="`accordion-item_${item.componentName}`"
        >
          <div
            slot="title"
            :style="`font-weight: 600; color: ${
              item.status === STATUS_ACTION_REQUIRED_PENDING
                ? getStatus(item.status).color
                : ''
            }`"
          >
            {{ item.title }}
          </div>
          <f7-accordion-content>
            <f7-block>
              <!-- Contact Lead -->
              <f7-list v-if="item.componentName == 'ContactLeadAction'">
                <f7-list-item title="Lead Source">
                  <div slot="after-title">
                    {{
                      getLeadSource((card && card.leadSource) || '')
                        .displayName || 'No data!'
                    }}
                  </div>
                </f7-list-item>
                <!-- date -->
                <!-- Contacted Date -->
                <f7-list-item title="Contacted Date">
                  <div slot="after-title">
                    {{
                      toDisplayDateString((card && card.contactedDate) || []) ||
                      'No data!'
                    }}
                  </div>
                </f7-list-item>
                <!-- site visit -->
                <f7-list-item title="Job Type">
                  <div slot="after-title">
                    {{ jobType(card && card.jobType) || 'No data!' }}
                  </div>
                </f7-list-item>

                <!-- number -->
                <f7-list-item title="Number of Buildings/Sections">
                  <div slot="after-title">
                    {{ (card && card.numberOfBuildsOrSections) || 'No data!' }}
                  </div>
                </f7-list-item>

                <!-- number -->
                <f7-list-item title="Number of Roof Systems">
                  <div slot="after-title">
                    {{ (card && card.numberOfRoofSytem) || 'No data!' }}
                  </div>
                </f7-list-item>

                <!-- boolean -->
                <f7-list-item title="Loose Gravel">
                  <f7-toggle
                    v-if="card && card.looseGravel != undefined"
                    disabled
                    slot="after"
                    :checked="card && card.looseGravel"
                  ></f7-toggle>
                  <div
                    v-else
                    slot="after-title"
                  >
                    No data!
                  </div>
                </f7-list-item>
                <!-- budget meeting -->
                <!-- number -->
                <f7-list-item title="Client Bugdget">
                  <div slot="after-title">
                    {{
                      (card &&
                        card.clientBudget &&
                        formatPriceNumber(card.clientBudget.toString())) ||
                      'No data!'
                    }}
                  </div>
                </f7-list-item>

                <!-- boolean -->
                <f7-list-item title="Tax Exempt">
                  <f7-toggle
                    v-if="card && card.taxExempt != undefined"
                    disabled
                    slot="after"
                    :checked="card && card.taxExempt"
                  ></f7-toggle>
                  <div
                    v-else
                    slot="after-title"
                  >
                    No data!
                  </div>
                </f7-list-item>
                <!-- boolean -->
                <f7-list-item title="Resale Certificate">
                  <f7-toggle
                    v-if="card && card.resalesCertificate != undefined"
                    disabled
                    slot="after"
                    :checked="card && card.resalesCertificate"
                  ></f7-toggle>
                  <div
                    v-else
                    slot="after-title"
                  >
                    No data!
                  </div>
                </f7-list-item>
              </f7-list>
              <!-- Estimate -->
              <f7-list v-if="item.componentName == 'EstimateAction'">
                <!-- date -->
                <!-- <f7-list-item title="Estimate Completed Date">
                  <div slot="after-title">
                    {{
                      toDisplayDateString(card && card.estimateCompletedDate) ||
                        "No data!"
                    }}
                  </div>
                </f7-list-item> -->
                <!-- boolean -->
                <!-- <f7-list-item title="Manufacturer Verbal Approved System">
                  <f7-toggle
                    v-if="card && card.manufacturerVerbal != undefined"
                    disabled
                    slot="after"
                    :checked="card && card.manufacturerVerbal"
                  ></f7-toggle>
                  <div v-else slot="after-title">No data!</div>
                </f7-list-item> -->
                <!-- boolean -->
                <f7-list-item title="Required System Letter">
                  <f7-toggle
                    v-if="card && card.requiredSystemLetter != undefined"
                    disabled
                    slot="after"
                    :checked="card && card.requiredSystemLetter"
                  ></f7-toggle>
                  <div
                    v-else
                    slot="after-title"
                  >
                    No data!
                  </div>
                </f7-list-item>
              </f7-list>
              <!-- Proposal -->
              <f7-list v-if="item.componentName == 'ProposalAction'">
                <!-- date -->
                <f7-list-item title="Proposal Date">
                  <div slot="after-title">
                    {{
                      toDisplayDateString(card && card.proposalDate) ||
                      'No data!'
                    }}
                  </div>
                </f7-list-item>
              </f7-list>
              <!-- Presentation -->
              <f7-list v-if="item.componentName == 'PresentationAction'">
                <!-- date -->
                <f7-list-item title="Presentation Date">
                  <div slot="after-title">
                    {{
                      toDisplayDateString(card && card.presentationDate) ||
                      'No data!'
                    }}
                  </div>
                </f7-list-item>
              </f7-list>
              <!-- Follow-up & Pre-Sales -->
              <f7-list v-if="item.componentName == 'FollowUpAction'">
                <!-- dates -->
                <f7-list-item title="Follow up Date">
                  <div slot="after-title">
                    {{
                      formatArrayDate((card && card.followUpDates) || []) ||
                      'No data!'
                    }}
                  </div>
                </f7-list-item>
              </f7-list>
              <!-- Closing -->
              <f7-list v-if="item.componentName == 'ClosingAction'">
                <!-- number -->
                <f7-list-item title="Total Project Value">
                  <div slot="after-title">
                    {{
                      (card &&
                        card.totalProjectValue &&
                        formatPriceNumber(card.totalProjectValue.toString())) ||
                      'No data!'
                    }}
                  </div>
                </f7-list-item>
                <!-- text -->
                <f7-list-item title="Mobilization %">
                  <div slot="after-title">
                    {{ (card && card.mobilization) || 'No data!' }}
                  </div>
                </f7-list-item>
                <!-- boolean -->
                <f7-list-item title="Retainage">
                  <f7-toggle
                    v-if="card && card.retainage != undefined"
                    disabled
                    slot="after"
                    :checked="card && card.retainage"
                  ></f7-toggle>
                  <div
                    v-else
                    slot="after-title"
                  >
                    No data!
                  </div>
                </f7-list-item>
              </f7-list>
            </f7-block>
          </f7-accordion-content>
          <f7-chip
            slot="after"
            :text="getStatus(item.status).displayName"
            :color="getStatus(item.status).color"
          ></f7-chip>
        </f7-list-item>
      </f7-list>
      <!-- pre-construstion -->
      <f7-block-header
        v-if="card && card.businessCode === BUSINESS_CODE_COMMERCIAL"
        >Pre-Construction</f7-block-header
      >
      <f7-list
        v-if="card && card.businessCode === BUSINESS_CODE_COMMERCIAL"
        accordion-list
        accordion-opposite
      >
        <f7-list-item
          v-for="(item, index) in actionList('pre-construction')"
          :key="index"
          accordion-item
          :class="`accordion-item_${item.componentName}`"
        >
          <div
            slot="title"
            :style="`font-weight: 600; color: ${
              item.status === STATUS_ACTION_REQUIRED_PENDING
                ? getStatus(item.status).color
                : ''
            }`"
          >
            {{ item.title }}
          </div>
          <f7-accordion-content>
            <f7-block>
              <!-- PreInstallation -->
              <f7-list v-if="item.componentName == 'PreInstallationAction'">
                <!-- Manufactures -->
                <f7-block>
                  <f7-block-header>Manufactures</f7-block-header>
                </f7-block>
                <f7-list>
                  <f7-list-item title="Approved Design System">
                    <f7-radio
                      disabled
                      value="approved-design-system"
                      name="manufacturesInfo"
                      slot="media"
                      :checked="
                        card &&
                        card.manufacturesApproved === 'approved-design-system'
                      "
                    ></f7-radio>
                  </f7-list-item>
                  <f7-list-item title="Proposed Alternative System">
                    <f7-radio
                      disabled
                      value="proposed-alternative-system"
                      name="manufacturesInfo"
                      slot="media"
                      :checked="
                        card &&
                        card.manufacturesApproved ===
                          'proposed-alternative-system'
                      "
                    ></f7-radio>
                  </f7-list-item>
                  <!-- dates -->
                  <f7-list-item title="Approved Date">
                    <div slot="after-title">
                      {{
                        toDisplayDateString(
                          (card && card.manufacturesApprovedDate) || []
                        ) || 'No data!'
                      }}
                    </div>
                  </f7-list-item>
                </f7-list>
                <!-- Client -->
                <f7-block>
                  <f7-block-header>Client</f7-block-header>
                </f7-block>
                <f7-list>
                  <f7-list-item title="Approved Design System">
                    <f7-radio
                      disabled
                      value="approved-design-system"
                      name="clientInfo"
                      slot="media"
                      :checked="
                        card && card.clientApproved === 'approved-design-system'
                      "
                    ></f7-radio>
                  </f7-list-item>
                  <f7-list-item title="Approved Alternative System">
                    <f7-radio
                      disabled
                      value="approved-alternative-system"
                      name="clientInfo"
                      slot="media"
                      :checked="
                        card &&
                        card.clientApproved === 'approved-alternative-system'
                      "
                    ></f7-radio>
                  </f7-list-item>
                  <!-- dates -->
                  <f7-list-item title="Approved Date">
                    <div slot="after-title">
                      {{
                        toDisplayDateString(
                          (card && card.clientApprovedDate) || []
                        ) || 'No data!'
                      }}
                    </div>
                  </f7-list-item>
                </f7-list>
              </f7-list>
              <!-- Submittals -->
              <f7-list v-if="item.componentName == 'SubmittalsAction'">
                <div v-if="card && card.submittalEmails != undefined">
                  <f7-block>
                    <f7-block-header> Submittals Emails </f7-block-header>
                  </f7-block>
                  <email-list
                    :value="card && card.submittalEmails"
                  ></email-list>
                </div>
                <f7-list-item
                  v-else
                  title="Submittals Emails"
                >
                  <div slot="after-title">No data!</div>
                </f7-list-item>
              </f7-list>
              <!-- Schedule of Value -->
              <f7-list v-if="item.componentName == 'ScheduleValueAction'">
                <f7-list v-if="card && card.scheduleOfValueId != undefined">
                  <f7-list-item title="Schedule of Value Completed Date">
                    <div slot="after-title">
                      {{
                        toDisplayDateString(
                          schedule ? schedule.completedDate : []
                        )
                      }}
                    </div>
                  </f7-list-item>
                  <f7-list-item>
                    <a
                      class="cursor-pointer"
                      slot="title"
                    >
                      {{ schedule ? schedule.title : '' }}
                    </a>
                    <div slot="footer">
                      Created by
                      {{ schedule ? schedule.createdBy : '' }}
                    </div>
                  </f7-list-item>
                </f7-list>
                <f7-list v-else>
                  <f7-list-item title="Schedule of Value Completed Date">
                    <div slot="after-title">No data!</div>
                  </f7-list-item>
                </f7-list>
              </f7-list>
            </f7-block>
          </f7-accordion-content>
          <f7-chip
            slot="after"
            :text="getStatus(item.status).displayName"
            :color="getStatus(item.status).color"
          ></f7-chip>
        </f7-list-item>
      </f7-list>
      <!-- construction -->
      <f7-block-header
        v-if="card && card.businessCode === BUSINESS_CODE_COMMERCIAL"
        >Construction</f7-block-header
      >
      <f7-list
        v-if="card && card.businessCode === BUSINESS_CODE_COMMERCIAL"
        accordion-list
        accordion-opposite
      >
        <f7-list-item
          v-for="(item, index) in actionList('construction')"
          :key="index"
          accordion-item
          :class="`accordion-item_${item.componentName}`"
        >
          <div
            slot="title"
            :style="`font-weight: 600; color: ${
              item.status === STATUS_ACTION_REQUIRED_PENDING
                ? getStatus(item.status).color
                : ''
            }`"
          >
            {{ item.title }}
          </div>
          <f7-accordion-content> </f7-accordion-content>
          <f7-chip
            slot="after"
            :text="getStatus(item.status).displayName"
            :color="getStatus(item.status).color"
          ></f7-chip>
        </f7-list-item>
      </f7-list>
      <!-- post construction -->
      <f7-block-header
        v-if="card && card.businessCode === BUSINESS_CODE_COMMERCIAL"
        >Post Construction</f7-block-header
      >
      <f7-list
        v-if="card && card.businessCode === BUSINESS_CODE_COMMERCIAL"
        accordion-list
        accordion-opposite
      >
        <f7-list-item
          v-for="(item, index) in actionList('post-construction')"
          :key="index"
          accordion-item
          :class="`accordion-item_${item.componentName}`"
        >
          <div
            slot="title"
            :style="`font-weight: 600; color: ${
              item.status === STATUS_ACTION_REQUIRED_PENDING
                ? getStatus(item.status).color
                : ''
            }`"
          >
            {{ item.title }}
          </div>
          <f7-accordion-content>
            <f7-block>
              <!-- Warranty Inspection -->
              <f7-list v-if="item.componentName == 'WarrantyInspectionAction'">
                <!-- dates -->
                <f7-list-item title="Schedule Warranty Date">
                  <div slot="after-title">
                    {{
                      toDisplayDateString(card && card.scheduleWarrantyDate) ||
                      'No data!'
                    }}
                  </div>
                </f7-list-item>
              </f7-list>
              <!-- Closeout Folder -->
              <f7-list v-if="item.componentName == 'CloseOutFolderAction'">
                <div v-if="card && card.closeOutFolderEmails != undefined">
                  <f7-block>
                    <f7-block-header> Close Out Folder Emails </f7-block-header>
                  </f7-block>
                  <email-list
                    :value="card && card.closeOutFolderEmails"
                  ></email-list>
                </div>
                <f7-list-item
                  v-else
                  title="Close Out Folder Emails"
                >
                  <div slot="after-title">No data!</div>
                </f7-list-item>
              </f7-list>
              <!-- Retainage -->
              <f7-list v-if="item.componentName == 'RetainageAction'">
                <div v-if="card && card.retainageEmails != undefined">
                  <f7-block>
                    <f7-block-header> Retainage Emails </f7-block-header>
                  </f7-block>
                  <email-list
                    :value="card && card.retainageEmails"
                  ></email-list>
                </div>
                <f7-list-item
                  v-else
                  title="Retainage Emails"
                >
                  <div slot="after-title">No data!</div>
                </f7-list-item>
              </f7-list>
            </f7-block>
          </f7-accordion-content>
          <f7-chip
            slot="after"
            :text="getStatus(item.status).displayName"
            :color="getStatus(item.status).color"
          ></f7-chip>
        </f7-list-item>
      </f7-list>
      <contact-list-popup
        ref="selectContact"
        :isHiddenAddNew="true"
        @onSelected="
          $event => {
            getContact($event).then(() => {
              handleContactSelected($event);
            });
          }
        "
      ></contact-list-popup>
      <company-list-popup
        ref="selectCompany"
        :contactId="contact.id"
        :isHiddenAddNew="true"
        :companyTypes="[
          COMPANY_TYPE_INSURANCE,
          COMPANY_TYPE_GENERAL_CONTRACTOR,
          COMPANY_TYPE_PROPERTY_OWNER,
          COMPANY_TYPE_PROPERTY_MANAGEMENT,
          COMPANY_TYPE_TENANT,
        ]"
        :queryFilters="`${
          contact.companyRefs && contact.companyRefs.length
            ? `contactIdRefs:${contact.id} AND`
            : ''
        } NOT companyTypes:${COMPANY_TYPE_VENDOR}`"
        @onSelected="
          $event => {
            $event
              ? getCompany($event).then(() => {
                  handleCompanySelected($event);
                })
              : setCompany({}).then(() => {
                  handleCompanySelected($event);
                });
          }
        "
      ></company-list-popup>

      <property-list-popup
        ref="selectProperty"
        :companyId="company.id"
        :isHiddenAddNew="true"
        :queryFilters="`${
          company.propertyRefs && company.propertyRefs.length
            ? `companyIdRefs:${company.id}`
            : ''
        }`"
        @onSelected="
          $event => {
            $event
              ? getProperty($event).then(() => {
                  handlePropertySelected($event);
                })
              : setProperty({}).then(() => {
                  handlePropertySelected($event);
                });
            handleUpdatePO($event);
          }
        "
      ></property-list-popup>
    </div>
  </div>
</template>

<script>
import EmailList from '../list/EmailList.vue';
import ContactListPopup from '../../../../components/popups/ContactListPopup.vue';
import CompanyListPopup from '../../../../components/popups/CompanyListPopup.vue';
import PropertyListPopup from '../../../../components/popups/PropertyListPopup.vue';
import InputIcon from '../icon/InputIcon.vue';
// import WorkOrderTable from "../table/WorkOrderTable.vue";

import { mapActions, mapGetters, mapState } from 'vuex';
import { toDisplayDateString } from '@/utility/datetime';
import { getFullAddress } from '@/utility/address';
import {
  STATUS_ACTION_REQUIRED_TODO,
  STATUS_ACTION_REQUIRED_IN_PROGRESS,
  STATUS_ACTION_REQUIRED_DONE,
  STATUS_ACTION_REQUIRED_PENDING,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_RESIDENTIAL,
  COMPANY_TYPE_INSURANCE,
  COMPANY_TYPE_GENERAL_CONTRACTOR,
  COMPANY_TYPE_PROPERTY_MANAGEMENT,
  COMPANY_TYPE_PROPERTY_OWNER,
  COMPANY_TYPE_VENDOR,
  COMPANY_TYPE_TENANT,
} from '../../../../utility/const';
import methodsMixins from '../../mixin/methods';
import _ from 'lodash';

export default {
  mixins: [methodsMixins],

  components: {
    EmailList,
    ContactListPopup,
    CompanyListPopup,
    PropertyListPopup,
    InputIcon,
  },

  data: () => {
    return {
      activeButton: 'contact-info',
      STATUS_ACTION_REQUIRED_TODO,
      STATUS_ACTION_REQUIRED_IN_PROGRESS,
      STATUS_ACTION_REQUIRED_DONE,
      STATUS_ACTION_REQUIRED_PENDING,
      BUSINESS_CODE_COMMERCIAL,
      BUSINESS_CODE_RESIDENTIAL,
      COMPANY_TYPE_INSURANCE,
      COMPANY_TYPE_GENERAL_CONTRACTOR,
      COMPANY_TYPE_PROPERTY_MANAGEMENT,
      COMPANY_TYPE_PROPERTY_OWNER,
      COMPANY_TYPE_VENDOR,
      COMPANY_TYPE_TENANT,
      selectCompanyMessage: '',
      selectPropertyMessage: '',
      displayCard: {
        contactId: '',
        companyId: '',
        propertyId: '',
      },
    };
  },

  computed: {
    ...mapState('dashboard/project', ['card']),
    ...mapGetters('dashboard/client', [
      'contact',
      'company',
      'property',
      'insuranceCompany',
    ]),

    ...mapGetters('dashboard/app-constant', [
      'jobTypeList',
      'actionRequiredStatusList',
      'getLeadSource',
    ]),
    ...mapGetters('dashboard/project', ['actionListSorted', 'action']),
    ...mapState('dashboard/schedule', ['schedule']),

    actionList() {
      return boardName => {
        let list = this.actionListSorted.filter(item =>
          item.boards.includes(boardName)
        );
        list = list.map(action => {
          let status = '';
          if (action.code == this.action.code) {
            status = STATUS_ACTION_REQUIRED_IN_PROGRESS;
          } else {
            status =
              this.card &&
              ((
                (this.card.actionsStatus || []).find(
                  ar => ar.actionId == action.id
                ) || {}
              ).status ||
                STATUS_ACTION_REQUIRED_TODO);
          }

          return {
            ...action,
            status,
          };
        });
        return list;
      };
    },

    contactName() {
      return !_.isEmpty(this.contact)
        ? (
            (this.contact.firstName || '') +
            ' ' +
            (this.contact.lastName || '')
          ).trim()
        : '';
    },

    contactPhone() {
      return this.contact && this.contact.phones
        ? this.contact.phones[0]
          ? this.contact.phones[0].value
          : ''
        : '';
    },

    companyName() {
      return this.displayCard ? this.displayCard.companyName || '' : '';
    },

    companyAddress() {
      return this.company && this.company.addresses
        ? this.company.addresses[0]
          ? getFullAddress(this.company.addresses[0]) ||
            this.company.addresses[0].value
          : ''
        : '';
    },

    propertyName() {
      return this.displayCard ? this.displayCard.propertyName || '' : '';
    },

    propertyAddress() {
      return this.property && this.property.addresses
        ? this.property.addresses[0]
          ? getFullAddress(this.property.addresses[0]) ||
            this.property.addresses[0].value
          : ''
        : '';
    },

    jobType() {
      return value => {
        const jobType = this.jobTypeList.find(item => item.value === value);
        return jobType ? jobType.displayName : '';
      };
    },
  },

  methods: {
    // ...mapActions("dashboard/work-order", ["bindWorkOrderTaskListBys"]),
    ...mapActions('dashboard/schedule', ['bindSchedule', 'unbindSchedule']),
    ...mapActions('dashboard/project', [
      'setCurrentCardValues',
      'updateCard',
      'createCard',
      'setIsDeleteTempleProject',
    ]),
    ...mapActions('dashboard/client', [
      'getContact',
      'getCompany',
      'setCompany',
      'getProperty',
      'setProperty',
      'getInsuranceCompany',
    ]),
    ...mapActions('dashboard/work-order', [
      'getWorkOrderTaskListBys',
      'updateWorkOrderTask',
    ]),

    getStatus(status) {
      return this.actionRequiredStatusList.find(x => x.value === status) || {};
    },

    async handleUpdatePO(propertyId) {
      this.$f7.preloader.show();
      const [propertySelected, poList] = await Promise.all([
        this.getProperty(propertyId),
        this.getWorkOrderTaskListBys([
          {
            prop: 'isDeleted',
            op: '==',
            val: false,
          },
          {
            prop: 'projectId',
            op: '==',
            val: this.card.id,
          },
        ]),
      ]);
      const promise = [];

      poList.forEach(po => {
        promise.push(
          this.updateWorkOrderTask({
            id: po.id,
            doc: {
              propertyId: propertySelected.id,
              propertyName: propertySelected.propertyName,
            },
          })
        );
      });

      await Promise.all(promise);
      this.$f7.preloader.hide();
    },

    showMainContent() {
      this.activeButton = 'contact-info';
      // if (!_.isEmpty(this.card) && !_.isEmpty(this.card.id)) {
      //   this.bindWorkOrderTaskListBys([
      //     {
      //       prop: "projectId",
      //       val: this.card.id,
      //       op: "=="
      //     }
      //   ]);
      // }
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    formatArrayDate(array) {
      let newArray = array.map(item => {
        return toDisplayDateString(item.date);
      });
      return newArray.join(', ');
    },

    closeActionPopup() {
      this.$emit('doClosePopup');
    },

    getFullAddress(address) {
      return getFullAddress(address);
    },

    navigateToSelectContact() {
      this.setCurrentCardValues(this.card);
      this.$refs.selectContact.open();
    },

    async handleContactSelected(id) {
      this.setCurrentCardValues({ contactId: id });
      this.onUpdateCard('contactId', id);
      this.changeProjectTitle();
      this.$f7route.route.meta.boardCode !== BUSINESS_CODE_RESIDENTIAL &&
        this.$refs.selectCompany.autoFillCompany();
    },

    navigateToSelectCompany() {
      if (!this.card.contactId) {
        this.selectCompanyMessage = 'Please select contact first';
        return;
      }
      this.setCurrentCardValues(this.card);
      this.$refs.selectCompany.open();
    },

    handleCompanySelected(id) {
      this.setCurrentCardValues({ companyId: id });
      this.onUpdateCard('companyId', id);
      this.changeProjectTitle();
      this.$f7route.route.meta.boardCode !== BUSINESS_CODE_RESIDENTIAL &&
        this.$refs.selectProperty.autoFillProperty();
    },

    navigateToSelectProperty() {
      if (!this.card.contactId || !this.card.companyId) {
        this.selectPropertyMessage = 'Please select contact and company first';
        return;
      }
      this.setCurrentCardValues(this.card);
      this.$refs.selectProperty.open();
    },

    async handlePropertySelected(id) {
      this.setCurrentCardValues({ propertyId: id });
      this.onUpdateCard('propertyId', id);
      this.changeProjectTitle();
    },

    resetErrorMessage() {},

    onUpdateCard(field, value, isShowLoading) {
      if (_.isEmpty(value)) return;
      isShowLoading && this.$f7.preloader.show();
      this.updateCard({
        id: this.card.id,
        doc: {
          [field]: value,
        },
      }).finally(() => {
        isShowLoading && this.$f7.preloader.hide();
      });
    },

    changeProjectTitle() {
      let projectTitle = '';
      if (this.card.businessCode === BUSINESS_CODE_COMMERCIAL) {
        projectTitle = this.propertyName || '';
        if (!_.isEmpty(this.property.addresses)) {
          projectTitle += ` - ${this.getPropertyAddress(this.property)}`;
        }
      } else if (this.card.businessCode === BUSINESS_CODE_RESIDENTIAL) {
        projectTitle = this.contactName || '';
        if (!_.isEmpty(this.displayCard.projectAddress)) {
          projectTitle += ` - ${getFullAddress(
            this.displayCard.projectAddress
          )}`;
        }
      }
      this.onUpdateCard('title', projectTitle);
    },
    getPropertyAddress(property) {
      if (!_.isEmpty(property)) {
        const addresses = property.addresses;
        if (!_.isEmpty(addresses) && _.isArray(addresses)) {
          let address = addresses.find(item => item.code === 'main');
          address = address || addresses[0] || [];

          if (!_.isEmpty(address)) {
            return getFullAddress(address) || address.value;
          }
        }
      }

      return '';
    },
    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card) || {};
        if (this.card.insuranceCompanyId) {
          this.getInsuranceCompany(this.card.insuranceCompanyId);
        }
      }
    },

    /**
     * Init data
     */
    init() {},

    /**
     * Clear data
     */
    clearActionData() {
      this.selectPropertyMessage = '';
      this.selectCompanyMessage = '';
    },
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (!_.isEmpty(newVal)) {
          if (!_.isEmpty(newVal.scheduleOfValueId)) {
            this.unbindSchedule();
            this.bindSchedule(newVal.scheduleOfValueId);
          }

          if (newVal.contactId && this.selectCompanyMessage != '') {
            this.selectCompanyMessage = '';
          }
          if (
            newVal.contactId &&
            newVal.companyId &&
            this.selectPropertyMessage != ''
          ) {
            this.selectPropertyMessage = '';
          }
        }
        this.initCardDisplayValues();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-link {
  margin-left: 7px;
}

.scroll-bar {
  height: calc(100%);
}

::v-deep .accordion-item {
  &_PreConstructionMettingAction,
  &_UpfrontPaymentAction,
  &_ConstructionAction,
  &_PunchlistAction,
  &_LienCloseProjectAction {
    .item-content::before {
      content: '' !important;
    }
  }
}
.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}
</style>
