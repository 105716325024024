export default {
  objectList: ({ objectList }) =>
    objectList.slice().sort((a, b) => {
      const aCreatedAt = a.createdAt || { nanoseconds: 0, seconds: 0 };
      const bCreatedAt = b.createdAt || { nanoseconds: 0, seconds: 0 };
      const toValue = a => {
        return a.seconds * 1000 + a.nanoseconds / 1000000;
      };
      return toValue(bCreatedAt) - toValue(aCreatedAt);
    }),

  detail: ({ objectList, detailId }) => objectList.find(r => r.id === detailId),

  timerList: ({ timerList }) =>
    timerList.slice().sort((a, b) => {
      const aCreatedAt = a.createdAt || { nanoseconds: 0, seconds: 0 };
      const bCreatedAt = b.createdAt || { nanoseconds: 0, seconds: 0 };
      const toValue = a => {
        return a.seconds * 1000 + a.nanoseconds / 1000000;
      };
      return toValue(bCreatedAt) - toValue(aCreatedAt);
    }),
};
