<template>
  <f7-chip
    :text="name"
    :deleteable="deleteable"
    :media-bg-color="mediaColor || `primary`"
    @click="$emit('remove')"
  >
    <img
      v-if="imageUrl"
      slot="media"
      :src="imageUrl"
    />
    <span
      slot="media"
      v-else
      >{{ chipName }}</span
    >
  </f7-chip>
</template>
<script>
export default {
  props: {
    imageUrl: String,
    name: [String, Number],
    mediaColor: String,
    deleteable: Boolean,
  },
  computed: {
    chipName() {
      if (typeof this.name === 'string') {
        return this.name.charAt(0).toUpperCase();
      } else {
        return this.name;
      }
    },
  },
};
</script>
