<script>
import actionsMixin from '../../mixin/actions';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  ACTION_CODE_CONSTRUCTION,
  COLLECTION_OPRATION_CARD,
} from '@/utility/const';
import _ from 'lodash';

export default {
  render: () => null,

  mixins: [actionsMixin],

  computed: {
    ...mapGetters('dashboard/project', [
      'actionByCode',
      'responseSorted',
      'filterAction',
      'constructionProject',
    ]),
    ...mapState('dashboard/project', ['users']),

    requestMaterialResponse() {
      return this.responseSorted.find(
        item => item.code === 'warranty-inspection'
      );
    },

    userInfo() {
      return uid =>
        this.users.find(user => {
          return user.uid === uid;
        });
    },
  },

  methods: {
    ...mapActions('dashboard/project', [
      'updateCard',
      'setCurrentResponse',
      'getAction',
    ]),
    ...mapActions('common/notification', ['createNotificationByType']),

    doResolve() {
      const project = _.cloneDeep(this.constructionProject);
      const action = _.cloneDeep(this.actionByCode(ACTION_CODE_CONSTRUCTION));

      this.$f7.preloader.show();

      this.setCurrentResponse(action.id)
        // Update project info
        .then(() => {
          const response = _.cloneDeep(this.requestMaterialResponse);

          const self = this;
          // calc the actions history
          let nextActionsTitle = response.nextActions
            .map(actionId => {
              return self.filterAction(actionId).title || '';
            })
            .join(', ')
            .trim();

          // calc the actions history
          let actionsHistory = self.calculateActionHistory(
            project,
            action,
            response
          );

          let nextActionIds = self.calculateNextActions(
            project,
            action,
            response
          );

          // Assign the next action
          return self
            .updateCard({
              id: project.id,
              doc: {
                status: response.nextState,
                actions: nextActionIds,
                actionsHistory: actionsHistory,
              },
            })
            .then(() => {
              return { nextActionsTitle, nextActionIds };
            });
        })
        // Send email and add activity
        .then(({ nextActionsTitle, nextActionIds }) => {
          const self = this;

          // Send email notification
          (project.assigneeIDs || []).forEach(uid => {
            let receiverInfo = self.userInfo(uid);
            if (receiverInfo) {
              let receiver = `${receiverInfo.displayName} <${receiverInfo.email}>`;

              self.sendMail(project.title, receiver, nextActionsTitle);
            }
          });

          // Add activity
          self.createNotificationByType({
            data: {
              assignees: project.assigneeIDs,
              project: {
                title: project.title,
                id: project.id,
                nextTitle: nextActionsTitle,
                type: 'project',
                entityName: COLLECTION_OPRATION_CARD,
              },
            },
            type: 'resolve-action',
          });
          let nextAction = {};
          if (nextActionIds.length > 0) {
            self
              .getAction(nextActionIds[0])
              .then(res => {
                nextAction = res;
              })
              .finally(() => {
                const data = {
                  cardNumber: project.cardNumber || '',
                  actionId: nextAction.id || '',
                  actionCode: nextAction.code || '',
                  boardName: action.boards[0] || 'sales',
                };

                // Close the popup
                this.afterResolve(data);
              });
          }
        })
        .then(() => {
          this.$f7.preloader.hide();
        });
    },

    afterResolve(data) {
      this.$f7router.navigate(
        `/dashboard/${this.$f7route.route.meta.boardType}/${this.$f7route.route.meta.businessCode}/post-construction/project/${data.cardNumber}/action/${data.actionCode}`,
        { reloadAll: true }
      );
    },
  },
};
</script>
