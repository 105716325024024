<template>
  <div class="input-schedule">
    <input
      ref="inputRef"
      v-if="type === 'text'"
      type="text"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
    />
    <input
      v-else
      ref="inputRef"
      type="text"
      v-model="displayPrice"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
    />
    <span style="color: red; font-size: 11px">{{ errMessage }}</span>
  </div>
</template>
<script>
export default {
  props: {
    value: [String, Number],
    type: String,
    item: Object,
  },
  data() {
    return {
      isInputActive: false,
      errMessage: null,
      internalValue: 0,
    };
  },
  computed: {
    displayPrice: {
      get() {
        var _sefl = this;
        if (this.isInputActive) {
          return this.internalValue.toString();
        } else {
          _sefl.errMessage = null;
          if (this.item.name === 'Project Cost by Roof Type') {
            return (
              '$' +
              this.internalValue
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
            );
          } else {
            return this.internalValue;
          }
        }
      },
      set(modifiedPrice) {
        if (!modifiedPrice) {
          this.errMessage = 'This field is required';
        } else if (!/^[0-9|.]+$/.test(modifiedPrice)) {
          this.errMessage = 'Price incorrect format';
        } else {
          this.errMessage = null;
        }
        this.internalValue = modifiedPrice;
      },
    },
  },
  mounted() {
    this.internalValue = this.value;
  },
  watch: {
    internalValue(modifiedPrice) {
      if (this.type === 'number') {
        let newPrice = parseFloat(modifiedPrice);
        if (isNaN(newPrice)) {
          newPrice = 0;
        }

        this.$emit('input', newPrice);
      } else {
        this.$emit('input', modifiedPrice);
      }
    },
    value(val) {
      this.internalValue = val;
    },
    isInputActive(val) {
      if (!val) {
        this.internalValue = this.value;
      } else {
        this.$refs.inputRef.select();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-schedule {
  border: solid 1px;
  border-color: rgb(185, 185, 185);
  border-radius: 3px;
  // background-color: rgb(185, 185, 185);
  max-width: 120px;
}
</style>
