<template>
  <div class="invoice-overview">
    <div
      style="
        margin-left: 24px;
        font-size: 16px;
        font-weight: bold;
        margin-top: 12px;
      "
    >
      Overview
    </div>
    <div
      :class="[
        !$device.desktop ? 'container-content-mobile' : '',
        'container-content',
      ]"
    >
      <f7-card
        v-for="(type, index) in types"
        :key="index"
        class="block-content"
      >
        <div class="display-flex justify-content-space-between">
          <span style="color: #aaa; font-weight: 600; font-size: smaller">{{
            invoiceReportByType(type).title
          }}</span>
          <f7-icon f7="money_dollar_circle"></f7-icon>
        </div>
        <div>
          <span style="font-weight: 600">{{
            (invoiceReportByType(type).amount || 0) | currencyUSD
          }}</span>
        </div>
        <span class="chip">
          <div class="chip-label">{{ invoiceReportByType(type).subTitle }}</div>
        </span>
      </f7-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    types: ['invoiced', 'paid', 'un-paid', 'un-billed', 'overdue'],
  }),

  async created() {
    for (const type of this.types) {
      this.bindReportByType(type);
    }
  },

  destroyed() {},

  computed: {
    ...mapGetters({
      invoiceReportByType: 'invoices/report-invoice/invoiceReportByType',
      invoicedReport: 'invoices/report-invoice/invoicedReport',
    }),
  },

  methods: {
    ...mapActions({
      createInvoiceReport: 'invoices/report-invoice/createInvoiceReport',
      addInvoiceReport: 'invoices/report-invoice/addInvoiceReport',
      bindReportByType: 'invoices/report-invoice/bindReportByType',
    }),
  },
};
</script>

<style scoped>
.invoice-overview {
  overflow-x: auto;
}

.container-content {
  display: flex;
  gap: 16px;
  margin: 0 14px;
}

.container-content-mobile {
  gap: 0px;
  margin: 8px 14px;
  overflow: auto;
}

.block-content {
  box-sizing: border-box;
  background: var(--f7-color-bg-12-neutral);
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  width: 184px;
  border-radius: 12px;
  padding: 12px 16px;
}
.block-content > div {
  padding-bottom: 12px;
}
.chip {
  background-color: var(--f7-color-bg-14-neutral);
}
</style>
