<template>
  <f7-card>
    <f7-card-header>
      {{
        itemSelected.length === 0
          ? 'Expenses To Quickbooks'
          : selectedMessage(itemSelected.length)
      }}
    </f7-card-header>
    <f7-card-content class="no-padding">
      <f7-toolbar
        tabbar
        top
        class="top-tab"
      >
        <f7-link
          tab-link="#action"
          tab-link-active
          >Action</f7-link
        >
        <f7-link tab-link="#history">History</f7-link>
      </f7-toolbar>
      <f7-tabs animated>
        <f7-tab
          id="action"
          tab-active
        >
          <action-tab-in-expenses
            v-on="$listeners"
            @selectedItems="handleItemSelect"
          ></action-tab-in-expenses>
        </f7-tab>
        <f7-tab id="history">
          <history-tab-in-expenses></history-tab-in-expenses>
        </f7-tab>
      </f7-tabs>
    </f7-card-content>
  </f7-card>
</template>

<script>
import ActionTabInExpenses from '../../components/tabs/ActionTabInExpenses.vue';
import HistoryTabInExpenses from '../../components/tabs/HistoryTabInExpenses.vue';

export default {
  components: {
    ActionTabInExpenses,
    HistoryTabInExpenses,
  },
  data: () => {
    return {
      itemSelected: [],
    };
  },
  computed: {
    selectedMessage() {
      return counter => {
        if (counter === 1) {
          return '1 item selected';
        } else {
          return `${counter} items selected`;
        }
      };
    },
  },
  methods: {
    handleItemSelect(updatedData) {
      this.itemSelected = updatedData;
    },
  },
};
</script>

<style scoped lang="scss">
.top-tab {
  top: 0 !important;
}
</style>
