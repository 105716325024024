<template>
  <f7-toolbar
    tabbar
    scrollable
    top
    :inner="false"
    v-if="$device.desktop"
  >
    <f7-row class="row-toolbar no-gap no-wrap scroll">
      <div>
        <f7-row class="justify-content-start no-wrap margin-top-half">
          <f7-link
            @click.native="navigateToBoard($event, 'sales')"
            :tab-link-active="boardCode === 'sales'"
            >Sale</f7-link
          >
          <f7-icon
            color="gray"
            f7="chevron_right"
          ></f7-icon>
          <f7-link
            @click.native="navigateToBoard($event, 'pre-construction')"
            :tab-link-active="boardCode === 'pre-construction'"
            >Pre-Construction</f7-link
          >
          <f7-icon
            color="gray"
            f7="chevron_right"
          ></f7-icon>
          <f7-link
            @click.native="setDefaultProjectView($event)"
            :tab-link-active="boardCode === 'construction'"
            >Construction</f7-link
          >
          <f7-icon
            color="gray"
            f7="chevron_right"
          ></f7-icon>
          <f7-link
            @click.native="navigateToBoard($event, 'post-construction')"
            :tab-link-active="boardCode === 'post-construction'"
            >Post Construction</f7-link
          ></f7-row
        >
      </div>
      <div>
        <f7-row class="filter-navbar no-wrap">
          <div class="display-flex input-search-filter-commercial">
            <f7-icon
              color="primary"
              f7="search"
            ></f7-icon>
            <f7-input
              style="flex: 1"
              placeholder="Search..."
              type="text"
              :clear-button="true"
              :value="searchText"
              @input="onSearch($event.target.value.trim())"
            ></f7-input>
          </div>
          <div class="input-date-filter-commercial">
            <f7-icon
              color="primary"
              f7="calendar"
            ></f7-icon>
            <f7-link
              class="date-link"
              @click.native="selectCalendar"
            >
              <span
                style="color: var(--f7-color-text-neutral)"
                v-if="isChangeWeek"
              >
                {{ showWeekRange }}
              </span>
              <span v-else>Filter Due Date</span>
            </f7-link>
            <div
              class="display-flex align-items-center"
              @click="clearFilterWeek"
            >
              <f7-icon
                class="clear-button"
                :class="isChangeWeek ? '' : 'display-none'"
                f7="xmark_circle_fill"
              ></f7-icon>
            </div>
          </div>
          <user-filter @changeFilterUsers="onChangeFilter"></user-filter>
        </f7-row>
      </div>
    </f7-row>
  </f7-toolbar>

  <f7-toolbar
    v-else
    tabbar
    scrollable
    top
  >
    <f7-link
      @click.native="navigateToBoard($event, 'sales')"
      :tab-link-active="boardCode === 'sales'"
      >Sale</f7-link
    >
    <f7-icon
      color="gray"
      f7="chevron_right"
    ></f7-icon>
    <f7-link
      @click.native="navigateToBoard($event, 'pre-construction')"
      :tab-link-active="boardCode === 'pre-construction'"
      >Pre-Construction</f7-link
    >
    <f7-icon
      color="gray"
      f7="chevron_right"
    ></f7-icon>
    <f7-link
      @click.native="setDefaultProjectView($event)"
      :tab-link-active="boardCode === 'construction'"
      >Construction</f7-link
    >
    <f7-icon
      color="gray"
      f7="chevron_right"
    ></f7-icon>
    <f7-link
      @click.native="navigateToBoard($event, 'post-construction')"
      :tab-link-active="boardCode === 'post-construction'"
      >Post Construction</f7-link
    >
    <div class="display-flex input-search-filter-commercial margin-right-half">
      <f7-icon
        color="primary"
        f7="search"
        class="margin-right-half"
      ></f7-icon>
      <f7-input
        style="flex: 1"
        placeholder="Search..."
        type="text"
        :clear-button="true"
        :value="searchText"
        @input="onSearch($event.target.value.trim())"
      ></f7-input>
    </div>
    <div class="input-date-filter-commercial margin-right-half">
      <f7-icon
        color="primary"
        f7="calendar"
      ></f7-icon>
      <f7-link
        class="date-link"
        @click.native="selectCalendar"
      >
        <span
          style="color: black"
          v-if="isChangeWeek"
        >
          {{ showWeekRange }}
        </span>
        <span v-else>Filter Due Date</span>
      </f7-link>
      <div
        class="display-flex align-items-center"
        @click="clearFilterWeek"
      >
        <f7-icon
          class="clear-button"
          :class="isChangeWeek ? '' : 'display-none'"
          f7="xmark_circle_fill"
        ></f7-icon>
      </div>
    </div>
    <user-filter
      class="margin-right-half"
      @changeFilterUsers="onChangeFilter"
    ></user-filter>
  </f7-toolbar>
</template>

<script>
import UserFilter from '../../components/filter/UserFilter.vue';
import { mapActions, mapGetters } from 'vuex';
import { sortLatest } from '@/utility/date-time-tool';
import _ from 'lodash';
import moment from 'moment';

function getCurrentWeek() {
  const weekStart = moment().startOf('isoWeek');
  let days = [];
  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days').format('MM/DD/YYYY'));
  }
  return days;
}
export default {
  components: { UserFilter },
  data: () => {
    return {
      currentWeek: getCurrentWeek(),
      currentDate: [new Date()],
      searchText: '',
      filterWeek: [],
      isChangeWeek: false,
    };
  },
  computed: {
    ...mapGetters('dashboard/project', ['boardCode']),

    constructionProjects() {
      return sortLatest(this.constructionProjectList);
    },

    ...mapGetters('dashboard/project', [
      'constructionProjectList',
      'actionByCode',
    ]),
    showWeekRange() {
      const from = moment(this.currentWeek[0], 'MM/DD/YYYY').format('MMM Do');
      const to = moment(this.currentWeek[6], 'MM/DD/YYYY').format('MMM Do');
      return `${from} - ${to}`;
    },
  },

  created() {
    this.setCurrentBoard(
      this.$f7route.route.meta.boardCode || this.$f7route.params.boardCode
    );
  },

  methods: {
    ...mapActions('dashboard/project', [
      'setCurrentBoard',
      // "setCurrentBusinessCode",
      'bindCardList',
      'getFirstConstructionProject',
    ]),

    ...mapActions('dashboard/project', [
      'bindConstructionProjectListBys',
      'getActionList',
      'setFilterUsers',
      'setFilterSearch',
      'setFilterWeek',
    ]),
    ...mapActions({
      setFilterSearchWoTask: 'dashboard/work-order/setFilterSearch',
      setFilterWeekWoTask: 'dashboard/work-order/setFilterWeek',
    }),

    navigateToBoard(event, boardCode) {
      const self = this;
      const boardType = this.$f7route.route.meta.boardType;
      const businessCode = this.$f7route.route.meta.businessCode;

      if (this.boardCode == 'construction') {
        this.$f7router.navigate(
          `/dashboard/${boardType}/${businessCode}/${boardCode}`,
          {
            reloadAll: true,
            pushState: true,
          }
        );
      } else {
        this.$f7.preloader.show();

        this.setCurrentBoard(boardCode);
        this.bindCardList({
          businessCode,
          boardCode,
        })
          .then(() => {
            window.history.pushState(
              '',
              '',
              `/dashboard/${boardType}/${businessCode}/${boardCode}`
            );
          })
          .finally(() => {
            self.$f7.preloader.hide();
          });
      }
    },

    navigateToConstructionBoard(firstProject) {
      const boardType = this.$f7route.route.meta.boardType;
      if (!_.isEmpty(firstProject)) {
        if (this.$device.desktop || this.$device.ipad) {
          this.$f7router.navigate(
            `/dashboard/${boardType}/commercial/construction/project/${firstProject.cardNumber}`,
            {
              reloadAll: true,
              pushState: true,
            }
          );
        } else {
          this.$f7router.navigate(
            `/dashboard/${boardType}/commercial/construction/project`,
            {
              reloadAll: true,
              pushState: true,
            }
          );
        }
      } else {
        return this.$ri.dialog.openWarningDialog({
          title: 'Construction Board',
          content: 'The Construction board has no project, please visit later.',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              return;
            }
          },
        });
      }
    },

    async setDefaultProjectView(event) {
      if (event) {
        event.preventDefault();
      }

      const firstProject = await this.getFirstConstructionProject();
      if (!_.isEmpty(firstProject)) {
        this.navigateToConstructionBoard(firstProject);
      }
    },
    onSearch(value) {
      this.searchText = value;
      this.setFilterSearch(value);
      this.setFilterSearchWoTask(value);
    },
    onChangeFilter(listUserId) {
      this.setFilterUsers(_.cloneDeep(listUserId));
    },
    onFilterWeek(days) {
      this.filterWeek = days;
      this.setFilterWeek(days);
      this.setFilterWeekWoTask(days);
    },

    selectCalendar() {
      this.$f7.calendar
        .create({
          inputEl: '#calendar-input',
          openIn: 'customModal',
          backdrop: true,
          cssClass: 'calendar-filter',
          value: this.currentDate,
          rangesClasses: [
            {
              cssClass: 'current-week',
              range: {
                from: new Date(this.currentWeek[0]),
                to: new Date(this.currentWeek[6]),
              },
            },
          ],
          on: {
            change: (calendar, value) => {
              if (
                !moment(this.currentDate[0]).isSame(moment(value[0]), 'day')
              ) {
                this.currentDate = value;
                const weekStart = moment(value[0], 'MM/DD/YYYY').startOf(
                  'isoWeek'
                );
                this.setCurrentWeek(weekStart);
                calendar.close();
              }
            },
          },
        })
        .open();
    },

    setCurrentWeek(weekStart) {
      let days = [];
      for (let i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, 'days').format('MM/DD/YYYY'));
      }
      this.isChangeWeek = true;
      this.currentWeek = days;
      this.onFilterWeek(days);
    },

    clearFilterWeek() {
      this.isChangeWeek = false;
      this.currentWeek = getCurrentWeek();
      this.currentDate = [new Date()];
      this.onFilterWeek([]);
    },
  },

  destroyed() {
    this.onSearch('');
    this.clearFilterWeek();
    this.setFilterUsers([]);
  },
};
</script>

<style lang="scss" scoped>
.row-toolbar {
  width: 100%;
}
.input-search-filter-commercial {
  display: flex;
  align-items: center;
  height: 28px;
  border: 1px solid #cfcfcf;
  border-radius: 22px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--f7-md-surface);
  min-width: 220px;
  box-sizing: border-box;
  gap: 8px;
}
.input-date-filter-commercial {
  display: flex;
  align-items: center;
  height: 28px;
  border: 1px solid #cfcfcf;
  border-radius: 22px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--f7-md-surface);
  min-width: 220px;
  gap: 8px;
  box-sizing: border-box;

  .date-link {
    flex: 1;
    justify-content: flex-start;
    flex-direction: row;
    padding: 0 !important;
    text-transform: unset;
  }
}

.current-week {
  background-color: #dc5858;
}
.input-search-filter-commercial::after {
  height: 0;
}
.filter-navbar {
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
  gap: 20px;
}
.clear-button {
  cursor: pointer;
  color: var(
    --f7-searchbar-input-clear-button-color,
    var(--f7-input-clear-button-color)
  );
}
@media (max-width: 1366px) {
  .input-search-filter-commercial,
  .input-date-filter-commercial {
    width: 160px;
    min-width: 160px;
  }
}

.scroll {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 2px;
  }
}

.no-wrap {
  flex-wrap: nowrap;
}
</style>
