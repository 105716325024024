<template>
  <div class="cell-input">
    <suggestions
      class="v-suggestions_mine"
      v-model="query"
      :options="options"
      :onInputChange="onInputChange"
      :onItemSelected="onItemSelected"
      :readonly="readonly"
      :isUseWidthDivSuggest="true"
    >
      <div
        slot="item"
        slot-scope="props"
        class="row v-suggestions_row"
      >
        <div class="col size">{{ props.item.name }}</div>
      </div>
    </suggestions>
  </div>
</template>

<script>
import Suggestions from '@/components/suggestions/Suggestions.vue';

export default {
  components: { Suggestions },
  props: {
    readonly: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
    value: { type: String, default: '' },
  },

  data() {
    return {
      editing: false,
      currentValue: '',
      options: {},
      query: '',
    };
  },

  watch: {
    value(newValue) {
      this.query = newValue;
    },
  },

  created() {
    this.query = this.value;
  },

  methods: {
    onInputChange(query) {
      return this.data.filter(item => {
        return item.name.toLowerCase().includes(query.toLowerCase());
      });
    },

    onItemSelected(item) {
      this.$emit('selected', { target: { value: item } });
    },

    // clear() {
    //   this.$emit("clear");
    // }
  },
};
</script>

<style lang="scss" scoped>
.v-suggestions_mine ::v-deep input {
  border: 1px solid var(--f7-color-bg-6-neutral);
  border-radius: 3px;
  padding: 3px 16px 3px 3px;
  width: 100%;
}
.v-suggestions_mine ::v-deep input:hover,
.v-suggestions_mine ::v-deep input:focus {
  border-color: rgb(94, 130, 209);
}

.v-suggestions_mine ::v-deep .items {
  border: 1px solid rgb(161, 161, 161);
}

.v-suggestions_mine ::v-deep .suggestions {
  width: 100%;
  top: 19px;
}
.v-suggestions_mine ::v-deep .suggestions ul.items {
  max-height: 310px;
  overflow: auto;
}

.v-suggestions_row ::v-deep .col {
  word-break: break-word;
}
</style>
