import * as types from './types';
import Vue from 'vue';

export default {
  setProjectList(state, payload) {
    Vue.set(state, 'projectList', payload);
  },
  setProjectSubscribe(state, payload) {
    Vue.set(state, 'projectSubscribe', payload);
  },
  [types.CHANGE_PROJECT_ITEM](state, { id, item }) {
    const list = state.projectList.map(r => (r.id === id ? item : r));
    Vue.set(state, 'projectList', list);
  },

  [types.SET_PROJECT_LIST](state, payload) {
    Vue.set(state, 'projectList', payload);
  },
};
