import Vue from 'vue';
import * as types from './types';
import _ from 'lodash';

export default {
  [types.SET_PROJECT_ID](state, id) {
    state.projectId = id;
  },
  [types.PROJECT](state, payload) {
    Vue.set(state, 'project', payload);
  },
  [types.ACTION_LIST](state, payload) {
    Vue.set(state, 'actionList', payload);
  },
  [types.SET_PROJECT_FILTER_FIELD](state, { field, value }) {
    Vue.set(state.projectFilter, field, value);
  },
  [types.SET_SEARCH_TEXT](state, searchText) {
    Vue.set(state, 'searchText', searchText);
  },

  [types.SET_SEARCH_RESULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(state, 'hits', hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.SET_NUMBER_OF_ROWS](state, payload) {
    Vue.set(state, 'hitsPerPage', payload);
  },

  [types.SET_SEARCH_ORDER](state, payload) {
    Vue.set(state, 'order', payload);
  },

  [types.DELETE_SEARCH_ITEM](state, payload) {
    const deletedItem = state.hits.findIndex(item => item.id === payload);
    state.hits.splice(deletedItem, 1);
  },

  [types.RESET_SEARCH](state) {
    Vue.set(state, 'order', 'desc');

    Vue.set(state, 'hits', []);
    Vue.set(state, 'hitsPerPage', 25);
    Vue.set(state, 'nbHits', 0);
    Vue.set(state, 'nbPages', 0);
    Vue.set(state, 'page', 0);
  },
  [types.ADD_MORE_RESULT](state, { hits, hitsPerPage, nbHits, nbPages, page }) {
    state.hits.push(...hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.PROJECT_LIST_ITEM_UPDATE_TOTAL_PHOTO](state, { id, action }) {
    const index = state.hits.findIndex(item => item.id === id);

    if (index >= 0) {
      const item = _.cloneDeep(state.hits[index]);

      if (action === 'add') {
        item.totalPhotos++;
      } else if (action === 'delete') {
        item.totalPhotos--;
      }
      Vue.set(state.hits, index, item);
    }
  },

  [types.RESET_DATA](state) {
    Vue.set(state, 'project', {});
    Vue.set(state, 'projectId', null);
    Vue.set(state, 'actionList', []);
    Vue.set(state, 'order', 'desc');
    Vue.set(state, 'projectFilter', {
      jobTypes: [],
      collection: 'project-open',
      createdFromDate: [],
      createdToDate: [],
    }),
      Vue.set(state, 'searchText', ''),
      Vue.set(state, 'hits', []);
    Vue.set(state, 'hitsPerPage', 25);
    Vue.set(state, 'nbHits', 0);
    Vue.set(state, 'nbPages', 0);
    Vue.set(state, 'page', 0);
  },
};
