<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          v-if="!$device.desktop"
          icon-f7="chevron_left"
          @click.native="handleBackAction"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ safetyDetail.title }}</f7-nav-title>
    </f7-navbar>
    <div>
      <div class="display-flex justify-content-center">
        <f7-card :style="$device.desktop ? 'width:54%' : 'width:100%'"
          ><f7-card-header class="display-flex justify-content-space-between">
            <div>Document Detail</div>
            <div class="display-flex">
              <f7-button
                v-if="$device.desktop"
                small
                outline
                class="margin-left-half"
                @click="openEditPopup"
                >Edit</f7-button
              >
              <f7-link
                class="margin-right-half"
                v-if="!$device.desktop"
                icon-f7="pencil"
                @click.native="openEditPopup"
              ></f7-link>

              <f7-button
                v-if="$device.desktop"
                small
                outline
                class="margin-left-half"
                @click="deleteSafety"
                >Delete</f7-button
              >

              <f7-link
                class="margin-right-half"
                v-if="!$device.desktop"
                icon-f7="trash"
                @click.native="deleteSafety"
              ></f7-link>
            </div>
          </f7-card-header>
        </f7-card>
      </div>
      <div
        :style="styleContent"
        ref="wrapPreviewContent"
        class="display-flex flex-direction-column align-items-center justify-content-flex-start margin-horizontal"
      >
        <iframe
          v-if="safetyDetail?.fullPath?.toLowerCase().includes('.pdf')"
          :src="`${safetyDetail.url}#toolbar=0&view=fitH&scrollbar=0`"
          style="height: calc(100vh - 120px)"
          :width="$device.desktop ? '54%' : '100%'"
        ></iframe>
        <div
          v-else
          ref="docxContainer"
          style="padding: 50px; margin-top: 20px; height: 700px"
        ></div>
      </div>
    </div>
    <edit-safety-document-popup
      @done="initData"
      ref="editSafetyDocument"
    />
  </f7-page>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import { useSafetyStore } from '../../../../stores/safety-hub/actions';
import EditSafetyDocumentPopup from '../popups/EditSafetyDocumentPopup.vue';
import _ from 'lodash';
import { renderAsync } from 'docx-preview';
import firebase from 'firebase/app';

export default defineComponent({
  components: {
    // Menu,
    EditSafetyDocumentPopup,
    // PdfViewer,
  },
  props: {
    pageWidth: { type: Number, default: 1020 },
    pageHeight: { type: Number, default: 1320 },
  },
  computed: {
    pagePreviewCssClass() {
      return this.pageHeight === 1680
        ? 'page-preview-content-legal'
        : 'page-preview-content';
    },
  },
  setup() {
    const instance = getCurrentInstance() as any;

    const store = useSafetyStore();
    const router = instance.proxy.$f7router;
    const styleContent = ref('');
    const safetyDetail = ref<Record<string, any>>({});
    const editSafetyDocument = ref<any>(null);
    const docxContainer = ref<any>(null);
    const storage = firebase.storage();

    const initData = async () => {
      if (!store) return;
      instance.proxy.$f7.preloader.show();
      await store.getSafety();
      let safetyNumber = instance.proxy.$f7route.params.safetyNumber;
      store.setSafetyNumber(safetyNumber);
      safetyDetail.value = safetyByNumber(safetyNumber);
      if (safetyDetail?.value?.fullPath?.toLowerCase().includes('.docx')) {
        await loadDocx();
      }
      instance.proxy.$f7.preloader.hide();
    };

    const handleBackAction = () => {
      instance.proxy.$f7router.navigate('/safety');
    };
    const safetyByNumber = (safetyNumber: string) => {
      return store.safetyList.find(r => r.safetyNumber === safetyNumber);
    };

    const openEditPopup = () => {
      if (editSafetyDocument.value) {
        editSafetyDocument.value.open(safetyDetail.value);
      }
    };
    const deleteSafety = () => {
      instance.proxy.$ri.dialog.openWarningDialog({
        title: 'Delete Document',
        content: 'Are you sure you want to to delete the document?',
        textButton: 'Delete',
        onClick: async (_sefl: any, index: number) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            instance.proxy.$f7.preloader.show();
            await store.deleteSafety(safetyDetail.value.id);
            instance.proxy.$f7router.navigate('/safety', {
              pushState: true,
              reloadAll: true,
            });
            instance.proxy.$f7.preloader.hide();
          }
        },
      });
    };
    const loadDocx = async () => {
      try {
        const url = await storage
          .ref(safetyDetail.value.fullPath)
          .getDownloadURL();

        const response = await fetch(url);

        if (!response.ok) {
          throw new Error('Failed to fetch the file');
        }

        const arrayBuffer = await response.arrayBuffer();
        const container = docxContainer.value;

        // Clear container before rendering
        container.innerHTML = '';

        // Render DOCX content
        await renderAsync(arrayBuffer, container);
      } catch (error) {
        console.error('Error loading DOCX file:', error);
      }
    };
    onMounted(async () => {
      docxContainer.value = instance.proxy.$refs.docxContainer;
      await initData();
      editSafetyDocument.value = instance.proxy.$refs.editSafetyDocument;
    });

    // onBeforeUnmount: Được gọi trước khi component bị hủy
    onBeforeUnmount(() => {
      // destroyStore();
    });

    // onUnmounted: Được gọi sau khi component đã bị hủy
    onUnmounted(() => {});

    return {
      handleBackAction,
      styleContent,
      safetyDetail,
      openEditPopup,
      deleteSafety,
      initData,
    };
  },
});
</script>
