<template>
  <div>
    <form>
      <f7-block-title v-if="!$device.desktop">
        <f7-row
          class="display-flex align-items-center"
          style="row-gap: 10px"
        >
          <f7-row
            class="display-flex align-items-center justify-content-between"
            style="width: 100%"
          >
            <div
              class="display-flex"
              style="gap: 15px"
            >
              <f7-button
                outline
                color="primary"
                style="width: calc(100vw - 120px)"
                @click="updateNewPrice"
                >Update vendors' prices</f7-button
              >
              <f7-icon
                f7="doc_on_doc"
                color="primary"
                @click.native="popupCopyOpen = true"
                size="25"
              ></f7-icon>
              <f7-icon
                f7="trash"
                color="primary"
                @click.native="removePriceList"
                size="25"
              ></f7-icon>
            </div>
          </f7-row>
          <f7-col style="width: max-content">
            <span class="time-update-text">{{
              currentPriceList.updatePriceTime &&
              currentPriceList.updatePriceTime !== ''
                ? formatTimestamp(currentPriceList.updatePriceTime)
                : ''
            }}</span>
            <div class="margin-top-half">Information</div>
          </f7-col>
        </f7-row>
      </f7-block-title>
      <f7-block-title
        v-else
        class="display-flex justify-content-space-between align-items-center"
      >
        Information
        <div
          class="display-flex"
          style="gap: 8px"
        >
          <span class="time-update-text">{{
            currentPriceList.updatePriceTime &&
            currentPriceList.updatePriceTime !== ''
              ? formatTimestamp(currentPriceList.updatePriceTime)
              : ''
          }}</span>
          <f7-button
            outline
            color="primary"
            @click="updateNewPrice"
            >Update vendors' prices</f7-button
          >
          <f7-button
            outline
            color="primary"
            @click="popupCopyOpen = true"
            >Copy PL</f7-button
          >
          <f7-button
            outline
            color="primary"
            @click="removePriceList"
            >Delete PL</f7-button
          >
        </div>
      </f7-block-title>
      <div class="row row-info">
        <div class="col-100 medium-60 col-info">
          <f7-list>
            <f7-list-input
              placeholder="Enter Price List Name"
              label="Price List Name"
              clear-button
              error-message-force
              validate
              :error-message="displayNameErrorMessage"
              :value="currentPriceList.displayName"
              @input="currentPriceList.displayName = $event.target.value.trim()"
              @blur="
                updatePriceField('displayName', $event.target.value.trim())
              "
              ><required-asterisk slot="label"
            /></f7-list-input>

            <f7-list-item header="Markup">
              <required-asterisk slot="header" />
              <div slot="title">
                <input-percent-or-cash
                  inputStyle="border-bottom: 1px solid"
                  :value="(currentPriceList.markup || {}).value"
                  :type="(currentPriceList.markup || {}).type"
                  @changeValue="updateMarkupField('value', $event)"
                  @changeType="updateMarkupField('type', $event)"
                ></input-percent-or-cash>
              </div>
            </f7-list-item>
            <f7-list-item class="roof-type-box">
              <multi-select
                required
                header="Roof Type"
                :value="currentPriceList.roofTypes"
                :selectionSource="
                  roofTypeList.map(item => ({
                    key: item.value,
                    value: item.displayName,
                  }))
                "
                :error-message="roofTypeErrorMessage"
                @input="
                  currentPriceList.roofTypes = $event;
                  updatePriceField('roofTypes', $event);
                "
              >
              </multi-select>
            </f7-list-item>
          </f7-list>
        </div>

        <div class="col-100 medium-40 col-info">
          <f7-list>
            <f7-list-input
              label="Start Date"
              type="datepicker"
              placeholder="MM/DD/YYYY"
              readonly
              error-message-force
              validate
              validate-on-blur
              :error-message="startDateErrorMessage"
              :value="currentPriceList.startDate"
              :calendar-params="{
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
                dateFormat: 'mm/dd/yyyy',
              }"
              @calendar:change="
                onUpdateDate('startDate', $event);
                $f7.calendar.close();
              "
              ><required-asterisk slot="label"
            /></f7-list-input>
            <f7-list-input
              label="End Date"
              type="datepicker"
              placeholder="MM/DD/YYYY"
              readonly
              validate-on-blur
              error-message-force
              validate
              :value="currentPriceList.endDate"
              :error-message="endDateErrorMessage"
              :calendar-params="{
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
                dateFormat: 'mm/dd/yyyy',
                disabled: date =>
                  currentPriceList.startDate &&
                  date <
                    new Date(currentPriceList.startDate).setHours(0, 0, 0, 0),
              }"
              @calendar:change="
                onUpdateDate('endDate', $event);
                $f7.calendar.close();
              "
              clear-button
              @input:clear="onClearEndDate"
            ></f7-list-input>

            <f7-list-input
              label="Status"
              type="select"
              :value="currentPriceList.status"
              @change="
                currentPriceList.status = $event.target.value;
                updatePriceField('status', $event.target.value);
              "
            >
              <required-asterisk slot="label" />
              <option
                v-for="status in priceListStatusList"
                :key="status.value"
                :value="status.value"
              >
                {{ status.displayName }}
              </option>
            </f7-list-input>
          </f7-list>
        </div>
      </div>
    </form>
    <f7-block-title>
      <f7-row class="align-items-flex-end">
        <f7-col>
          <f7-toolbar
            class="custom-link"
            tabbar
          >
            <f7-link
              class="tab-button"
              text="Product List"
              :tab-link-active="tabActive === 'product-list'"
              @click="onClickTab('product-list')"
            >
            </f7-link>
            <f7-link
              class="tab-button"
              text="New Added Product"
              :tab-link-active="tabActive === 'new-added-product'"
              @click="onClickTab('new-added-product')"
            >
              <f7-chip
                :text="totalProductWFA || '0'"
                color="black"
              ></f7-chip>
            </f7-link>
          </f7-toolbar>
        </f7-col>
        <f7-col
          v-if="$device.desktop"
          class="display-flex justify-content-flex-end"
        >
          <template v-if="tabActive === 'product-list'">
            <f7-button
              fill
              class="flex-shrink-1 margin-right-half"
              color="primary"
              @click="startImportProduct"
              >Import</f7-button
            >
            <f7-button
              class="flex-shrink-1 margin-right-half"
              fill
              color="primary"
              @click="startAddNewProduct"
              >Create new product</f7-button
            >
            <f7-button
              class="flex-shrink-1"
              fill
              color="primary"
              @click="openPopupAddNewProduct"
              >Add from Library</f7-button
            >
          </template>
          <template
            v-else-if="
              tabActive === 'new-added-product' &&
              productComputed.length &&
              (isOwner || isAdminGroup)
            "
          >
            <f7-button
              outline
              class="flex-shrink-1 margin-right-half"
              color="primary"
              @click="removeProducts"
              >Remove Products</f7-button
            >
            <f7-button
              class="flex-shrink-1"
              fill
              color="green"
              @click="approveProducts"
              >Approve Products</f7-button
            >
          </template>
        </f7-col>
      </f7-row>
      <f7-row
        v-if="!$device.desktop"
        class="margin-top-half"
      >
        <f7-col>
          <template v-if="tabActive === 'product-list'">
            <f7-button
              outline
              class=""
              color="primary"
              @click="startImportProduct"
              >Import</f7-button
            >
            <f7-button
              class="margin-top-half"
              outline
              color="primary"
              @click="startAddNewProduct"
              >Create new product</f7-button
            >
            <f7-button
              class="margin-top-half"
              outline
              color="primary"
              @click="openPopupAddNewProduct"
              >Add from Library</f7-button
            >
          </template>
          <template
            v-else-if="
              tabActive === 'new-added-product' &&
              productComputed.length &&
              (isOwner || isAdminGroup)
            "
          >
            <f7-button
              outline
              class=""
              color="primary"
              @click="removeProducts"
              >Remove Products</f7-button
            >
            <f7-button
              class="margin-top-half"
              fill
              color="green"
              @click="approveProducts"
              >Approve Products</f7-button
            >
          </template>
        </f7-col>
      </f7-row>
    </f7-block-title>

    <pin-data-table
      :headers="headers"
      :items="productComputed"
      @pinArray="receivePinData"
      :pageSize="(productComputed || []).length"
      :pinWidth="[getRowLeft(0), getRowLeft(1)]"
      :checkbox="
        tabActive === 'new-added-product' && (isOwner || isAdminGroup)
          ? true
          : false
      "
      @selected:change="selectProductItem"
    >
      <!-- Custom Card Header  -->
      <template slot="card-header">
        <f7-card-content>
          <f7-list>
            <f7-searchbar
              placeholder="Search in Product items"
              class="search-list-popup"
              :clear-button="true"
              disable-button-text
              :value="searchText"
              @input="
                setSearchText($event.target.value);
                onSearch();
              "
              @searchbar:clear="
                setSearchText('');
                onSearch();
              "
              @searchbar:disable="
                setSearchText('');
                onSearch();
              "
            >
              <f7-button
                outline
                color="primary"
                class="margin-left-half"
                style="min-width: 120px"
                @click="exportToExcel"
                >Export to Excel</f7-button
              >
            </f7-searchbar>
          </f7-list>
        </f7-card-content>
      </template>

      <template v-slot:body="{ item }">
        <td
          :style="{
            left: pinArrayData && pinArrayData[0] ? getRowLeft(0) : 'auto',
            boxShadow: pinArrayData
              ? getLastTrue(pinArrayData, 0)
                ? '7px 0px 5px #000'
                : ''
              : '',
          }"
          id="column-pin-0"
          :class="`${
            pinArrayData && pinArrayData[0] && !$device.desktop
              ? 'pinned-row'
              : ''
          }`"
        >
          {{ item.sku }}
        </td>
        <td
          :style="{
            left: pinArrayData && pinArrayData[1] ? getRowLeft(1) : 'auto',
            boxShadow: pinArrayData
              ? getLastTrue(pinArrayData, 1)
                ? '7px 0px 5px #000'
                : ''
              : '',
          }"
          :class="`${
            pinArrayData && pinArrayData[1] && !$device.desktop
              ? 'pinned-row'
              : ''
          }`"
        >
          {{ item.productItem }}
        </td>
        <td>{{ item.manufacturer }}</td>
        <td>{{ item.vendorName }}</td>
        <td>{{ productRoofTypeNames(item.roofTypes) }}</td>
        <td>{{ item.categoryName }}</td>
        <td>{{ item.subCategoryName }}</td>
        <td>
          <ul class="technical-data">
            <li
              v-for="(attach, index) in item.technicalData || []"
              :key="index"
              :id="`${item.id}-${attach.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`"
            >
              <a
                class="display-block"
                @click.stop="openLink(item, attach)"
              >
                {{ attach.name }}
              </a>
            </li>
          </ul>
        </td>
        <td>
          <f7-chip
            :text="productItemStatusByValue(item.status).displayName"
            :color="productItemStatusByValue(item.status).color"
          ></f7-chip>
        </td>
        <td>
          <f7-link
            v-for="(color, index) in item.colorPhotos || []"
            :key="index"
            @click.stop="clickPhoto(color)"
            :id="`product-PL-${color.id}-${item.id}`"
          >
            {{
              `${color.colorName}${
                index + 1 === (item.colorPhotos || []).length ? '' : ',&nbsp;'
              }`
            }}
          </f7-link>
        </td>
        <td>{{ item.packaging }}</td>
        <td>{{ item.unitPack }}</td>
        <td>{{ item.unitSize }}</td>
        <td>{{ item.uom }}</td>
        <td>{{ item.vendorPrice | currencyUSD }}</td>
        <td>
          <input-percent-or-cash
            :ref="`productMarkupInputPercentOrCash_${item.id}`"
            inputClass="outline"
            :value="(item.markup || {}).value"
            :type="(item.markup || {}).type"
            @changeValue="changeProductMarkup(item, 'value', $event)"
            @changeType="changeProductMarkup(item, 'type', $event)"
          ></input-percent-or-cash>
        </td>
        <td>
          <div>{{ item.price | currencyUSD }}</div>
        </td>
        <td class="text-align-center">
          <div
            class="display-flex justify-content-center"
            style="width: 70px; gap: 6px"
          >
            <a
              class="display-flex align-items-center"
              href="#"
              @click.stop="onEdit(item)"
            >
              <i
                class="f7-icons text-color-blue"
                style="margin: 2px; font-size: 20px"
                >pencil</i
              >
            </a>
            <template v-if="tabActive === 'product-list'">
              <a
                class="display-flex align-items-center"
                href="#"
                @click.stop="copyProduct(item)"
              >
                <i
                  class="f7-icons text-color-blue"
                  style="margin: 2px; font-size: 20px"
                  >doc_on_doc</i
                >
              </a>
              <a
                class="display-flex align-items-center"
                href="#"
                @click.stop="openConfirmDelete(item)"
              >
                <i
                  class="f7-icons"
                  style="color: red; font-size: 20px"
                  >trash</i
                >
              </a>
            </template>
          </div>
        </td>
      </template>

      <template slot="paging-footer">
        <div
          v-if="productComputed.length > 0"
          class="data-table-footer"
          :class="$device.desktop ? '' : 'mobile-container'"
        >
          <div class="data-table-rows-select">
            Per page:
            <div class="input input-dropdown">
              <select
                @input="onChangeLimit($event.target.value.trim())"
                :value="productsByPL.hitsPerPage"
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
              </select>
            </div>
          </div>
          <div
            class="data-table-pagination"
            :style="$device.desktop ? '' : 'margin:0'"
          >
            <span
              v-if="productsByPL.page === 0"
              class="display-flex align-items-center color-gray"
              :class="$device.desktop ? 'margin-left' : ''"
              disabled
              ><f7-icon f7="chevron_left"></f7-icon>First</span
            >
            <a
              v-else
              @click="onGoToPage('first')"
              class="display-flex align-items-center margin-left"
              :class="$device.desktop ? 'margin-left' : ''"
              disabled
              ><f7-icon f7="chevron_left"></f7-icon>First</a
            >

            <span
              v-if="productsByPL.page === 0"
              class="margin-left color-gray"
              >Previous</span
            >
            <a
              v-else
              @click="onGoToPage('prev')"
              class="margin-left"
              >Previous</a
            >

            <span class="data-table-pagination-label margin-left">{{
              pagingMessage
            }}</span>

            <a
              v-if="productsByPL.page < productsByPL.nbPages - 1"
              @click="onGoToPage('next')"
              class="margin-left"
              >Next</a
            >
            <span
              v-else
              class="margin-left color-gray"
              >Next</span
            >

            <a
              v-if="productsByPL.page < productsByPL.nbPages - 1"
              @click="onGoToPage('last')"
              class="display-flex align-items-center margin-left"
              >Last<f7-icon f7="chevron_right"></f7-icon
            ></a>
            <span
              v-else
              class="display-flex align-items-center margin-left color-gray"
              >Last<f7-icon f7="chevron_right"></f7-icon
            ></span>
          </div>
        </div>
      </template>
    </pin-data-table>

    <add-new-product-popup
      ref="addNewProductPopup"
      @addNew="addProductToPriceList"
    />
    <product-editor-popup
      ref="productEditorRef"
      :roofTypeList="roofTypeOptions"
      :categories="categories"
      :subCategories="subCategories"
      :productItemStatusList="productItemStatusOptions"
      :vendorById="getVendorById"
    />
    <input
      v-if="showFileInput"
      type="file"
      style="display: none"
      ref="fileUploadControl"
      @change="fileUploadChange"
    />

    <copy-price-list-popup
      :popupCopyOpen="popupCopyOpen"
      @close="popupCopyOpen = false"
      :itemCopyPrice="priceListDetail"
    />

    <f7-photo-browser
      :photos="productPhotos"
      theme="dark"
      :ref="`detail_pageDark`"
      type="popup"
    ></f7-photo-browser>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { comparationSort } from '@/utility/filter-tools';
import { firebase } from '../../../../services/firebase.service';
import PinDataTable from '@/components/pindatatables';
import InputPercentOrCash from '@/components/inputs/InputPercentOrCash.vue';
import AddNewProductPopup from '../popup/AddNewProductPopup.vue';
import ProductEditorPopup from '../popup/ProductEditorPopup.vue';
import MultiSelect from '@/components/inputs/MultiSelect.vue';
import CopyPriceListPopup from '../popup/CopyPriceListPopup.vue';
import moment from 'moment';
import xlsxToJson from '@/utility/xlsx-to-json.js';
import _ from 'lodash';
import { uuid } from 'vue-uuid';
import {
  isVideo,
  isImage,
  createMediaArray,
  openAttackLink,
} from '@/utility/common';
import {
  DEFAULT_STATUS_PRICE_LIST,
  DEFAULT_STATUS_PRODUCT_ITEM,
  COLLECTION_PRICE_LIST,
  VALIDATION_MESSAGE,
} from '@/utility/const';
import {
  STATUS_PRODUCT_ITEM_ACTIVE,
  STATUS_PRODUCT_ITEM_WFA,
} from '../../../../utility/const';
import exportPriceList from '../../utility/excel-export';
import {
  toDateFirebase,
  toDisplayDateString,
} from '../../../../utility/datetime';
import Vue from 'vue';

export default {
  components: {
    PinDataTable,
    InputPercentOrCash,
    AddNewProductPopup,
    MultiSelect,
    ProductEditorPopup,
    CopyPriceListPopup,
  },
  data: () => {
    return {
      popupCopyOpen: false,
      popupCreateNewProduct: false,
      showFileInput: true,
      currentPriceList: {
        displayName: '',
        startDate: [new Date()],
        endDate: [],
        status: DEFAULT_STATUS_PRICE_LIST,
        productRefs: [],
        roofTypes: [],
        updatePriceTime: '',
        markup: {
          value: 0,
          type: 'percent',
        },
      },
      gridFilter: {
        vendorId: '',
        categoryId: '',
        subCategoryId: '',
        roofType: '',
        status: '',
      },
      productPhotos: [],
      tabActive: 'product-list',
      selectedProductsWT: [],
      totalProductWFA: 0,
      typeImage: '',
      colorPhotosImport: [],
      pinArrayData: [null, true],
    };
  },

  computed: {
    ...mapGetters('price-list/price-list-page/price-list', [
      'priceListDetail',
      'priceList',
      'productRefs',
    ]),
    ...mapGetters('price-list/price-list-page/product-items', [
      'productsByPL',
      'searchText',
    ]),
    ...mapGetters('price-list/roof-type', ['roofTypeList']),
    ...mapGetters({
      categories: 'price-list/price-list-page/category/objectList',
      subCategories: 'price-list/price-list-page/sub-category/objectList',
      vendors: 'price-list/price-list-page/vendor/objectList',
      vendorById: 'price-list/price-list-page/vendor/objectById',
      productItemStatusList:
        'price-list/price-list-page/app-constant/productItemStatusList',
      productItemStatusByValue:
        'price-list/price-list-page/app-constant/productItemStatusByValue',
      priceListStatusList:
        'price-list/price-list-page/app-constant/priceListStatusList',
    }),
    ...mapGetters('setting/app/profile', ['isOwner']),
    ...mapGetters('setting/app/group', ['isAdminGroup']),
    ...mapGetters('common/user', ['userGroupList']),

    pagingMessage() {
      const fromRow =
        this.productsByPL.page * this.productsByPL.hitsPerPage + 1;
      const toRow =
        this.productsByPL.page * this.productsByPL.hitsPerPage +
        this.productsByPL.hits.length;
      return `${fromRow} - ${toRow} of ${this.productsByPL.nbHits}`;
    },

    productItemStatusOptions() {
      if (this.tabActive === 'product-list') {
        return this.productItemStatusList.filter(
          r => r.value !== STATUS_PRODUCT_ITEM_WFA
        );
      } else if (this.tabActive === 'new-added-product') {
        return this.productItemStatusList.filter(
          r => r.value === STATUS_PRODUCT_ITEM_WFA
        );
      }
      return this.productItemStatusList;
    },

    roofTypeOptions() {
      let condition = item =>
        (this.currentPriceList.roofTypes || []).some(i => i == item.value);
      return this.roofTypeList.filter(condition);
    },
    vendorFilterOptions() {
      return this.vendors.filter(r =>
        (this.productsByPL.vendorIdFilterList || []).includes(r.id)
      );
    },
    categoriesFilterOptions() {
      return this.categories.filter(r =>
        (this.productsByPL.categoryIdFilterList || []).includes(r.id)
      );
    },
    subCategoriesFilterOptions() {
      return this.subCategories.filter(r =>
        (this.productsByPL.subCategoryIdFilterList || []).includes(r.id)
      );
    },
    roofTypeFilterOptions() {
      return this.roofTypeList.filter(r =>
        (this.productsByPL.roofTypeFilterList || []).includes(r.value)
      );
    },

    statusFilterOptions() {
      return this.productItemStatusList.filter(r =>
        (this.productsByPL.statusFilterList || []).includes(r.value)
      );
    },

    getRowLeft() {
      return index => {
        const tdElement = document.getElementById('column-pin-0');
        const width =
          tdElement && tdElement.offsetWidth ? tdElement.offsetWidth : 0;
        const truePinnedHeaders = this.pinArrayData.filter(Boolean);
        if (truePinnedHeaders.length < 2) {
          return '0px';
        }
        const pinnedRows = index === 1 ? width : 0;
        return pinnedRows + 'px';
      };
    },

    headers() {
      let self = this;
      return [
        {
          text: 'SKU',
          value: 'sku',
          sortable: false,
          isPin: true,
          align: 'left',
        },
        {
          text: 'Product name',
          value: 'productItem',
          sortable: false,
          isPin: true,
          align: 'left',
        },
        {
          text: 'Manufacturer',
          value: 'manufacturer',
          sortable: false,
          isPin: false,
          align: 'left',
        },
        {
          text: 'Vendor Name',
          value: 'vendor',
          sortable: false,
          isPin: false,
          align: 'left',
          filter: {
            type: 'select',
            value: self.gridFilter.vendorId,
            selectSource: [
              { value: '', text: 'All vendors' },
              ...this.vendorFilterOptions
                .map(r => ({
                  value: r.id,
                  text: r.companyName,
                }))
                .sort((a, b) => comparationSort(a.text, b.text)),
            ],
            onChange: value => {
              self.gridFilter.vendorId = value;
              this.onSearch();
            },
          },
        },
        {
          text: 'Roof type',
          value: 'roofType',
          sortable: false,
          align: 'left',
          isPin: false,
          filter: {
            type: 'select',
            value: self.gridFilter.roofType,
            selectSource: [
              { value: '', text: 'All roof types' },
              ...this.roofTypeFilterOptions
                .map(r => ({
                  value: r.value,
                  text: r.displayName,
                }))
                .sort((a, b) => comparationSort(a, b)),
            ],
            onChange: value => {
              self.gridFilter.roofType = value;
              this.onSearch();
            },
          },
        },
        {
          text: 'Product category',
          value: 'productCategory',
          sortable: false,
          align: 'left',
          isPin: false,
          filter: {
            type: 'select',
            value: self.gridFilter.categoryId,
            selectSource: [
              { value: '', text: 'All categories' },
              ...this.categoriesFilterOptions
                .map(r => ({
                  value: r.id,
                  text: r.name,
                }))
                .sort((a, b) => comparationSort(a.text, b.text)),
            ],
            onChange: value => {
              self.gridFilter.categoryId = value;
              this.onSearch();
            },
          },
        },
        {
          text: 'Sub category',
          value: 'productSubCategory',
          sortable: false,
          align: 'left',
          isPin: false,
          filter: {
            type: 'select',
            value: self.gridFilter.subCategoryId,
            selectSource: [
              { value: '', text: 'All sub categories' },
              ...this.subCategoriesFilterOptions
                .map(r => ({
                  value: r.id,
                  text: r.name,
                }))
                .sort((a, b) => comparationSort(a.text, b.text)),
            ],
            onChange: value => {
              self.gridFilter.subCategoryId = value;
              this.onSearch();
            },
          },
        },
        {
          text: 'Technical data',
          value: 'technicalData',
          sortable: false,
          isPin: false,
          align: 'left',
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          align: 'left',
          isPin: false,
          filter:
            this.tabActive === 'product-list'
              ? {
                  type: 'select',
                  value: self.gridFilter.status,
                  selectSource: [
                    { value: '', text: 'All statuses' },
                    ...this.statusFilterOptions.map(r => ({
                      value: r.value,
                      text: r.displayName,
                    })),
                  ],
                  onChange: value => {
                    self.gridFilter.status = value;
                    this.onSearch();
                  },
                }
              : false,
        },
        {
          text: 'Color',
          value: 'colorPhotos',
          sortable: false,
          isPin: false,
          align: 'left',
        },
        {
          text: 'Packaging',
          value: 'packaging',
          sortable: false,
          isPin: false,
          align: 'left',
        },
        {
          text: 'Unit Packs',
          value: 'unitPack',
          sortable: false,
          isPin: false,
          align: 'left',
        },
        {
          text: 'Unit Size',
          value: 'unitSize',
          sortable: false,
          isPin: false,
          align: 'left',
        },
        {
          text: 'UoM',
          value: 'uom',
          sortable: false,
          isPin: false,
          align: 'left',
        },
        {
          text: 'Vendor Price',
          value: 'vendorPrice',
          sortable: false,
          isPin: false,
          align: 'left',
        },
        {
          text: 'Markup',
          value: 'markup',
          sortable: false,
          isPin: false,
          align: 'left',
        },
        {
          text: 'Price',
          value: 'price',
          sortable: false,
          isPin: false,
          align: 'left',
        },
        {
          text: 'Actions',
          value: '',
          sortable: false,
          isPin: false,
          align: 'center',
        },
      ];
    },

    productComputed() {
      const productRefs = this.priceListDetail.productRefs || [];
      return this.productsByPL.hits.map(r => {
        const overrideProduct =
          productRefs.find(p => p.productId === r.id) || {};
        let data = {
          ...r,
          vendorPrice: !Number.isNaN(
            Number.parseFloat(overrideProduct.vendorPrice)
          )
            ? overrideProduct.vendorPrice
            : r.price || 0,
          markup: overrideProduct.markup || { value: 0, type: 'percent' },
          price: overrideProduct.price || 0,
          internalNote: overrideProduct.internalNote || '',
          proposalNote: overrideProduct.proposalNote || '',
          estServiceDefaultForTypes:
            overrideProduct.estServiceDefaultForTypes || [],
        };
        if (
          this.tabActive === 'new-added-product' &&
          this.selectedProductsWT.length
        ) {
          data.checked = this.selectedProductsWT.some(p => p.id === r.id);
        }
        return data;
      });
    },

    displayNameErrorMessage() {
      if (!this.v$.currentPriceList.displayName.$error) return null;
      if (this.v$.currentPriceList.displayName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    roofTypeErrorMessage() {
      if (!this.v$.currentPriceList.roofTypes.$error) return null;
      if (this.v$.currentPriceList.roofTypes.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    startDateErrorMessage() {
      if (this.v$.currentPriceList.startDate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (!this.v$.currentPriceList.startDate.startDateRules)
        return VALIDATION_MESSAGE.START_DATE_LESS_THAN_OR_EQUAL_END_DATE;
      return null;
    },
    endDateErrorMessage() {
      if (!this.v$.currentPriceList.endDate.endDateRules)
        return VALIDATION_MESSAGE.END_DATE_GREATER_THAN_START_DATE;
      return null;
    },

    roofTypeNames() {
      const listName = (this.priceListDetail.roofTypes || []).map(
        r =>
          (
            (this.roofTypeList || []).find(roofType => roofType.value == r) ||
            {}
          ).displayName
      );
      return listName.join(', ');
    },

    compileConditionsToFilterDataByPL() {
      const filters = [`priceListRefs:${this.priceListDetail.id}`];
      if (this.tabActive === 'product-list') {
        filters.push(`NOT status:${STATUS_PRODUCT_ITEM_WFA}`);
      } else if (this.tabActive === 'new-added-product') {
        filters.push(`status:${STATUS_PRODUCT_ITEM_WFA}`);
      }
      return {
        filters: filters.join(' AND '),
      };
    },

    compileConditionsToSearchByPL() {
      // filter
      const filters = [`priceListRefs:${this.priceListDetail.id}`];
      const { vendorId, categoryId, subCategoryId, roofType, status } =
        this.gridFilter;

      if (this.tabActive === 'product-list') {
        filters.push(`NOT status:${STATUS_PRODUCT_ITEM_WFA}`);
      } else if (this.tabActive === 'new-added-product') {
        filters.push(`status:${STATUS_PRODUCT_ITEM_WFA}`);
      }
      if (vendorId) filters.push(`vendorId:${vendorId}`);
      if (categoryId) filters.push(`categoryId:${categoryId}`);
      if (subCategoryId) filters.push(`subCategoryId:${subCategoryId}`);
      if (roofType) filters.push(`roofTypes:${roofType}`);
      if (status) filters.push(`status:${status}`);

      return {
        filters: filters.join(' AND '),
      };
    },

    exportPriceListInfo() {
      return {
        displayName: `${this.priceListDetail.priceListNumber}-${this.priceListDetail.displayName}`,
        priceListNumber: this.priceListDetail.priceListNumber,
        roofTypes: this.roofTypeNames,
        startDate: toDisplayDateString(this.priceListDetail.startDate),
        endDate: toDisplayDateString(this.priceListDetail.endDate) || '',
        status: (
          this.priceListStatusList.find(
            f => f.value == this.priceListDetail.status
          ) || {}
        ).displayName,
      };
    },
  },

  created() {
    if (this.$f7route.params.priceListNumber) {
      this.rebindWatch(this.priceListDetail);
    }
    this.tabActive = this.$f7route.query.productTab || 'product-list';
  },

  mounted() {
    this.onSearch();
    this.getTotalProductWFTByPL();
  },

  methods: {
    ...mapActions('price-list/price-list-page/price-list', [
      'updatePrice',
      'deletePrice',
      'getPriceList',
    ]),
    ...mapActions('price-list/price-list-page/price-list-history', [
      'createHistory',
      'deleteHistoryBys',
    ]),
    ...mapActions('price-list/price-list-page/product-items', [
      'updateProduct',
      'createProduct',
      'addPriceListRefs',
      'removePriceListRefs',
      'searchProductItems',
      'goToPage',
      'setProductNumberOfRows',
      'getFilterData',
      'getTotalProductWFT',
      'algoliaUpdateItems',
      'uploadPhoto',
      'getProductsByPriceListRefsAndStatus',
      'setSearchText',
    ]),
    ...mapActions('common/user', ['checkGroupUsers']),
    ...mapActions('common/notification', ['createNotificationByType']),

    getTotalProductWFTByPL() {
      this.getTotalProductWFT(this.priceListDetail.id).then(
        size => (this.totalProductWFA = size)
      );
    },

    receivePinData(data) {
      this.pinArrayData = data;
    },
    getLastTrue(Array, index) {
      let last = -1;
      for (let i = 0; i < Array.length; i++) {
        if (Array[i] === true) {
          last = i;
        }
      }
      if (last === index) {
        return true;
      }
      return false;
    },

    clearFilterSearch() {
      this.gridFilter = {
        vendorId: '',
        categoryId: '',
        subCategoryId: '',
        roofType: '',
        status: '',
      };
      this.setSearchText('');
    },

    async getFilterDataForPriceListTab() {
      await this.getFilterData({
        dataType: 'products-by-price-list',
        ...this.compileConditionsToFilterDataByPL,
      });
      const { vendorId, categoryId, subCategoryId, roofType, status } =
        this.gridFilter;
      const {
        vendorIdFilterList,
        categoryIdFilterList,
        subCategoryIdFilterList,
        roofTypeFilterList,
        statusFilterList,
      } = this.productsByPL;
      if (!vendorIdFilterList.includes(vendorId)) this.gridFilter.vendorId = '';
      if (!categoryIdFilterList.includes(categoryId))
        this.gridFilter.categoryId = '';
      if (!subCategoryIdFilterList.includes(subCategoryId))
        this.gridFilter.subCategoryId = '';
      if (!roofTypeFilterList.includes(roofType)) this.gridFilter.roofType = '';
      if (!statusFilterList.includes(status)) this.gridFilter.status = '';
    },

    onSearch() {
      this.handleSearch();
    },

    handleSearch: _.debounce(async function () {
      this.$f7.preloader.show();
      await this.getFilterDataForPriceListTab();
      await this.searchProductItems({
        dataType: 'products-by-price-list',
        ...this.compileConditionsToSearchByPL,
      });
      this.createColorPhotoTooltip();
      this.$f7.preloader.hide();
    }, 500),

    onGoToPage(pageName) {
      this.$f7.preloader.show();
      this.goToPage({
        dataType: 'products-by-price-list',
        pageName,
        ...this.compileConditionsToSearchByPL,
      }).finally(() => {
        this.createColorPhotoTooltip();
        this.$f7.preloader.hide();
      });
    },

    async loadCurrentProductPage() {
      this.$f7.preloader.show();
      await this.getFilterDataForPriceListTab();
      await this.searchProductItems({
        dataType: 'products-by-price-list',
        pageNumber: this.productsByPL.page,
        ...this.compileConditionsToSearchByPL,
      });
      const { hits, nbPages } = _.cloneDeep(this.productsByPL);
      if (!hits.length && nbPages > 0) {
        await this.searchProductItems({
          dataType: 'products-by-price-list',
          pageNumber: nbPages - 1,
          ...this.compileConditionsToSearchByPL,
        });
      }
      this.createColorPhotoTooltip();
      this.$f7.preloader.hide();
    },

    onChangeLimit(value) {
      this.setProductNumberOfRows({
        dataType: 'products-by-price-list',
        value,
      });
      this.onSearch();
    },

    getCurrentTimestamp() {
      return firebase.firestore.Timestamp.now();
    },

    toDateFirebase(value) {
      return toDateFirebase(value);
    },

    async onClickTab(tabActive) {
      this.tabActive = tabActive;
      // clear grid filter
      this.clearFilterSearch();
      // clear select product
      this.selectedProductsWT = [];
      await this.onSearch();
    },

    startImportProduct() {
      this.$refs.fileUploadControl.click();
    },
    async fileUploadChange(e) {
      const jsonResult = await xlsxToJson(e.target.files[0], 'Products');

      await this.doImport(jsonResult);
      this.showFileInput = false;
      this.$nextTick(() => {
        this.showFileInput = true;
      });
    },
    async urlToBase64(url) {
      try {
        // download image from url
        const response = await fetch(url);
        const blob = await response.blob();
        this.typeImage = blob.type;
        // convert to base64
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(',')[1]);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        return null;
      }
    },
    async addPhotos(colors) {
      this.$f7.preloader.show();
      let addedColors = [];
      if (!_.isEmpty(colors)) {
        for (const color of colors) {
          // iterate through the colors array
          const photos = [];
          if (color.photos && color.photos.length > 0) {
            // handle if color.photos is not empty
            for (const file of color.photos) {
              const urlParts = file.url.split('/'); // get url
              const name = urlParts[urlParts.length - 1]; // get name from end of url
              const base64Data = await this.urlToBase64(file.url); //convert file.url to base64

              // update file
              file.dataUrl = `data:${this.typeImage};base64,${base64Data}`;
              file.info = { type: this.typeImage, name };
              file.thumbnailUrl = file.dataUrl;

              const { fullPath, url } = await this.uploadPhoto(file); // upload photo to db

              // push data to photos array
              photos.push({
                thumbnailUrl: url,
                thumbnailFullPath: fullPath,
                url: url,
                name: name,
                fullPath: fullPath,
              });
            }
          }
          // push data to addedColors array
          addedColors.push({
            id: uuid.v1(),
            colorName: color.colorName,
            photos: photos,
          });
        }
      }
      this.colorPhotosImport = addedColors;
      this.$f7.preloader.hide();
    },
    async doImport(jsonResult) {
      const importableData = [];
      for (let index = 0; index < jsonResult.length; index++) {
        const object = jsonResult[index];

        const roofType = (
          this.roofTypeOptions.find(
            r => r.displayName == object['Roof type']
          ) || {}
        ).value;
        if (!roofType) {
          this.informImportError(
            `Invalid roof type ${object['Roof type']} in row ${
              index + 2
            }. Roof type must belong roof type of this Price list!`
          );
          return;
        }

        const categoryId = (
          this.categories.find(
            r =>
              r.name == object['Product category'] &&
              r.roofTypes.includes(roofType)
          ) || {}
        ).id;

        if (!categoryId) {
          this.informImportError(
            `Invalid category ${object['Product category']} in row ${index + 2}`
          );
          return;
        }
        const subCategoryId = (
          this.subCategories.find(
            r => r.name == object['Sub category'] && r.categoryId === categoryId
          ) || {}
        ).id;
        if (!subCategoryId) {
          this.informImportError(
            `Invalid sub category ${object['Sub category']} in row ${index + 2}`
          );
          return;
        }

        // Check existing vendors
        const vendor = (this.vendors || []).find(
          r => r.companyName == object['Vendor Name']
        );
        if (!vendor) {
          this.informImportError(
            !object['Vendor Name']
              ? 'Missing Vendor Name colunm from excel file.'
              : `Not existing vendor '${object['Vendor Name']}' in row ${
                  index + 2
                }`
          );
          return;
        }

        // add photo to db before import
        await this.addPhotos(object['Color']);

        const importObject = {
          sku: object['SKU'] || 'n/a',
          manufacturer: object['Manufacturer'] || 'n/a',
          productItem: object['Product name'] || 'n/a',
          subCategoryId,
          categoryId,
          uom: object['UoM'].toString().trim().toUpperCase() || 'n/a',
          note: object['Note'] || '',
          roofTypes: [roofType],
          price: object['Vendor Price'] || 0,
          wasterFactor: object['Waste factor'] || 1,
          packaging: object['Packaging'] || '',
          unitPack: object['Unit packs'] || 1,
          unitSize: object['Unit sizes'] || 1,
          colorPhotos: this.colorPhotosImport,
          vendorId: vendor.id,
          status: DEFAULT_STATUS_PRODUCT_ITEM,
          productHashtag: object['Product hashtag'] || 'TBD',
          per100lf: object['Per 100 LF'] || 0,
          dimension: object['Dimension'] || '',
        };
        const internalNote = object['Internal Note'] || '';
        const proposalNote = object['Proposal Note'] || '';

        const markup = this.priceListDetail.markup || {
          value: 0,
          type: 'percent',
        };
        let price = importObject.price || 0;
        if (markup.type === 'percent') {
          price = price * (1 + markup.value / 100);
        } else {
          price += markup.value;
        }
        price = parseFloat(price.toFixed(2));
        importableData.push({
          productData: importObject,
          markup,
          price,
          internalNote,
          proposalNote,
        });
      }
      this.$f7.dialog.preloader('Importing product items. Please wait...');
      const currentTimestamp = this.getCurrentTimestamp();

      let productIdsAddToPriceList = [];
      for (let index = 0; index < importableData.length; index++) {
        let product = importableData[index].productData;
        product.priceListRefs = [this.priceListDetail.id];
        let pid = await this.createProduct(product);
        productIdsAddToPriceList.push({
          productId: pid,
          vendorPrice: product.price,
          price: importableData[index].price,
          markup: importableData[index].markup,
          internalNote: importableData[index].internalNote,
          proposalNote: importableData[index].proposalNote,
        });
      }
      //update product refs
      let productRefs = _.cloneDeep(this.productRefs);
      for (let i = 0; i < productIdsAddToPriceList.length; i++) {
        let item = productIdsAddToPriceList[i];
        let index = productRefs.findIndex(r => r.productId == item.productId);
        if (index > -1) {
          productRefs.splice(index, 1, item);
        } else {
          productRefs.push(item);
        }
      }
      await this.updatePrice({
        id: this.priceListDetail.id,
        doc: {
          productRefs: productRefs,
        },
      });
      await this.algoliaUpdateItems(currentTimestamp);
      this.$f7.dialog.close();
      this.onSearch();
    },
    informImportError(err) {
      const self = this;
      self.$f7.notification
        .create({
          icon: '<i class="icon icon-f7"></i>',
          title: 'RooferIntel',
          titleRightText: 'now',
          text: err,
          subtitle: 'Import error',
          closeTimeout: 3000,
        })
        .open();
    },
    getVendorById(id) {
      return this.vendorById(id);
    },
    openLink(item, attach) {
      this.productPhotos = createMediaArray(item.technicalData);
      openAttackLink(attach, this.productPhotos, this.$refs[`detail_pageDark`]);
    },

    clickPhoto(item) {
      this.productPhotos = (item || {}).photos || [];
      if (this.productPhotos.length > 0) {
        setTimeout(() => this.$refs[`detail_pageDark`].open(0), 100);
      }
    },

    openPopupAddNewProduct() {
      this.$refs.addNewProductPopup.openPopup();
    },

    rebindWatch(val) {
      if (!_.isEmpty(val)) {
        this.currentPriceList = {
          displayName: val.displayName,
          endDate: !_.isEmpty(val.endDate) ? [val.endDate.toDate()] : [],
          startDate: !_.isEmpty(val.startDate) ? [val.startDate.toDate()] : [],
          updatePriceTime: val.updatePriceTime,
          status: val.status,
          productRefs: (val.productRefs || []).map(r => ({
            productId: r.productId,
            price: r.price,
            vendorPrice: r.vendorPrice || 0,
            markup: r.markup || { value: 0, type: 'percent' },
            internalNote: r.internalNote || '',
            proposalNote: r.proposalNote || '',
            estServiceDefaultForTypes: r.estServiceDefaultForTypes || [],
          })),
          roofTypes: val.roofTypes || [],
          markup: val.markup || {
            value: 0,
            type: 'percent',
          },
        };
      }
    },

    removeProductItem(product, isDeleteProduct = false) {
      const arr = JSON.parse(JSON.stringify(this.currentPriceList.productRefs));
      this.currentPriceList.productRefs = arr.filter(
        x => x.productId !== String(product.id)
      );
      const promises = [];
      // update productRefs for price list
      promises.push(
        this.updatePrice({
          id: this.priceListDetail.id,
          doc: { productRefs: this.currentPriceList.productRefs },
        })
      );
      // update priceListRefs for product
      promises.push(
        this.removePriceListRefs({
          id: product.id,
          priceListId: this.priceListDetail.id,
        })
      );
      // delete product history
      promises.push(
        this.deleteHistoryBys({
          priceListId: this.priceListDetail.id,
          productId: product.id,
        })
      );

      if (isDeleteProduct) {
        promises.push(
          this.updateProduct({
            id: product.id,
            doc: { isDeleted: true },
          })
        );
      }
      return Promise.all(promises);
    },

    openConfirmDelete(product) {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: 'Delete product item',
        content: 'Are you sure you want to delete the product item?',
        textButton: 'Delete',
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$f7.preloader.show();
            const currentTimestamp = this.getCurrentTimestamp();
            app.removeProductItem(product).then(async () => {
              _sefl.app.dialog.close();
              await app.algoliaUpdateItems(currentTimestamp);
              app.$f7.preloader.hide();
              app.loadCurrentProductPage();
            });
          }
        },
      });
    },

    changeProductMarkup(item, prop, value) {
      if (this.$refs[`productMarkupInputPercentOrCash_${item.id}`]?.validate())
        return;
      this.$f7.preloader.show();
      let price = item.vendorPrice || 0;
      this.currentPriceList.productRefs = (
        this.currentPriceList.productRefs || []
      ).map(x => {
        if (item.id === x.productId) {
          const markup = {
            ...item.markup,
            [prop]: prop === 'value' ? parseFloat(value) || 0 : value,
          };
          if (markup.type === 'percent') {
            price = price * (1 + markup.value / 100);
          } else {
            price += markup.value;
          }
          price = parseFloat(price.toFixed(2));
          return {
            ...x,
            markup,
            price,
          };
        }
        return x;
      });

      const promises = [];
      promises.push(
        this.updatePrice({
          id: this.priceListDetail.id,
          doc: {
            productRefs: this.currentPriceList.productRefs,
          },
        })
      );
      promises.push(
        this.createHistory({
          priceListId: this.priceListDetail.id,
          productId: item.id,
          oldPrice: item.price,
          newPrice: price,
          productItem: item.productItem,
          manufacturer: item.manufacturer,
          vendorId: item.vendorId,
          vendorName: item.vendorName,
        })
      );

      Promise.all(promises).then(() => this.$f7.preloader.hide());
    },
    onClearEndDate() {
      if (_.isEmpty(this.currentPriceList.endDate)) return;
      this.currentPriceList.endDate = [];
      this.v$.currentPriceList.endDate &&
        this.v$.currentPriceList.endDate.$touch();
      this.updatePriceField('endDate', '');
    },
    onUpdateDate(field, value) {
      if (
        (!_.isEmpty(this.currentPriceList[field]) &&
          moment(value[0]).isSame(
            moment(this.currentPriceList[field][0]),
            'day'
          )) ||
        (_.isEmpty(this.currentPriceList[field]) && _.isEmpty(value))
      )
        return;

      this.currentPriceList[field] = value;
      this.v$.currentPriceList[field] &&
        this.v$.currentPriceList[field].$touch();
      if (field === 'startDate') {
        const startDate = moment(value[0]).format('YYYY-MM-DD');
        const endDate = moment(this.currentPriceList.endDate[0]).format(
          'YYYY-MM-DD'
        );
        if (
          moment(endDate).isBefore(startDate, 'day') &&
          this.currentPriceList.endDate &&
          this.currentPriceList.endDate.length > 0
        ) {
          this.updatePriceField('endDate', toDateFirebase(value), true);
        }
      }
      this.updatePriceField(field, toDateFirebase(value), true);
    },

    updatePriceField(field, value, isShowLoading = false) {
      this.v$.currentPriceList[field] &&
        this.v$.currentPriceList[field].$touch();
      if (!value && field !== 'endDate') return;
      isShowLoading && this.$f7.preloader.show();
      this.updatePrice({
        id: this.priceListDetail.id,
        doc: {
          [field]: value,
        },
      }).finally(() => {
        isShowLoading && this.$f7.preloader.hide();
      });
    },
    updateNewPrice() {
      const currentTimestamp = this.getCurrentTimestamp();
      Vue.set(this.currentPriceList, 'updatePriceTime', currentTimestamp);
      this.updatePriceField('updatePriceTime', currentTimestamp);
      this.updateProductRefs(true);
    },
    updateMarkupField(field, value) {
      this.v$.currentPriceList.markup[field] &&
        this.v$.currentPriceList.markup[field].$touch();
      this.$refs.plMarkupInputPercentOrCash?.validate();
      if (
        this.v$.currentPriceList.markup.$invalid ||
        this.$refs.plMarkupInputPercentOrCash?.validate()
      )
        return;
      const markup = {
        ...this.currentPriceList.markup,
        [field]: field === 'value' ? parseFloat(value) : value,
      };
      Vue.set(this.currentPriceList, 'markup', markup);
      this.updatePriceField('markup', markup);
      this.updateProductRefs();
    },
    formatTimestamp(data) {
      const date = new Date(data.seconds * 1000 + data.nanoseconds / 1000000);

      // Extract components
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12;
      const strMinutes = minutes < 10 ? '0' + minutes : minutes;
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();

      // Format the string
      return `Prices last updated at ${hours}:${strMinutes} ${ampm}, ${
        month < 10 ? '0' + month : month
      }/${day < 10 ? '0' + day : day}/${year}`;
    },
    async updateProductRefs(isUpdateVendors = false) {
      this.$f7.preloader.show();
      let productRefs = _.cloneDeep(this.priceListDetail.productRefs) || [];
      const markup = this.currentPriceList.markup;
      const productItems = await this.getProductsByPriceListRefsAndStatus({
        priceListId: this.priceListDetail.id,
      });
      productRefs = productRefs.map(i => {
        const vendorProduct = productItems.find(r => r.id === i.productId);
        let price = isUpdateVendors ? vendorProduct.price : i.vendorPrice;
        if (markup.type === 'percent') {
          price = price * (1 + markup.value / 100);
        } else {
          price += markup.value;
        }
        return {
          ...i,
          vendorPrice: isUpdateVendors ? vendorProduct.price : i.vendorPrice,
          markup,
          price: parseFloat(price.toFixed(2)),
        };
      });
      await this.updatePrice({
        id: this.priceListDetail.id,
        doc: { productRefs },
      });
      this.$f7.preloader.hide();
    },

    removePriceList() {
      const self = this;
      this.$ri.dialog.openWarningDialog({
        title: 'Delete Price List',
        content: 'Are you sure you want to delete the price list?',
        textButton: 'Delete',
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            self.$f7.preloader.show();
            await self.checkGroupUsers('product-main/price-list');
            self
              .updatePrice({
                id: self.priceListDetail.id,
                doc: {
                  isDeleted: true,
                },
              })
              .then(async () => {
                const arrayOfUids = self.userGroupList.map(
                  userGroup => userGroup.uid
                );
                await self.createNotificationByType({
                  data: {
                    assignees: arrayOfUids,
                    project: {
                      title: self.priceListDetail.displayName,
                      id: self.priceListDetail.id,
                      entityName: COLLECTION_PRICE_LIST,
                      priceListNumber: self.priceListDetail.priceListNumber,
                    },
                  },
                  type: 'remove-price-list',
                });
                self.$f7.preloader.hide();

                _sefl.app.dialog.close();
                self.$f7router.navigate('/price-list', {
                  reloadAll: true,
                });
              });
          }
        },
      });
    },

    productRoofTypeNames(roofTypes = []) {
      const rt = (this.roofTypeList || []).filter(r =>
        roofTypes.includes(r.value)
      );
      return rt.map(r => r.displayName).join(', ');
    },
    technicalDataNames(technicalData = []) {
      return technicalData.map(r => r.name).join(', ');
    },
    async copyProduct(item) {
      const addData = await this.$refs.productEditorRef.startEditor(
        'Copy Product',
        {
          ...item,
          priceListRefs: [this.priceListDetail.id],
        }
      );

      if (!addData) return;
      this.$f7.dialog.preloader('Updating product items. Please wait...');
      const currentTimestamp = this.getCurrentTimestamp();

      const productId = await this.createProduct(addData.productData);
      // update pricelist
      let productRefs = _.cloneDeep(this.currentPriceList.productRefs) || [];
      productRefs.push({
        productId: productId,
        vendorPrice: addData.productData.price,
        price: addData.price,
        markup: addData.markup,
        internalNote: addData.internalNote,
        proposalNote: addData.proposalNote,
      });
      await this.updatePrice({
        id: this.priceListDetail.id,
        doc: { productRefs },
      });
      await this.algoliaUpdateItems(currentTimestamp);
      this.$f7.dialog.close();
      this.loadCurrentProductPage();
    },
    async startAddNewProduct() {
      const addData = await this.$refs.productEditorRef.startEditor(
        'Add new product',
        {
          sku: '',
          manufacturer: '',
          productItem: '',
          subCategoryId: '',
          categoryId: '',
          uom: '',
          roofTypes: [],
          price: '',
          wasterFactor: '',
          size: '',
          vendorId: '',
          color: '',
          status: DEFAULT_STATUS_PRODUCT_ITEM,
          priceListRefs: [this.priceListDetail.id],
          markup: this.currentPriceList.markup,
        }
      );
      if (!addData) return;
      this.$f7.dialog.preloader('Updating product items. Please wait...');
      const currentTimestamp = this.getCurrentTimestamp();

      const productId = await this.createProduct(addData.productData);
      // update pricelist
      let productRefs = _.cloneDeep(this.currentPriceList.productRefs) || [];
      productRefs.push({
        productId: productId,
        vendorPrice: addData.productData.price,
        price: addData.price,
        markup: addData.markup,
        internalNote: addData.internalNote,
        proposalNote: addData.proposalNote,
      });
      await this.updatePrice({
        id: this.priceListDetail.id,
        doc: { productRefs },
      });
      await this.algoliaUpdateItems(currentTimestamp);
      this.$f7.dialog.close();
      this.loadCurrentProductPage();
    },

    async addProductToPriceList(data) {
      const currentTimestamp = this.getCurrentTimestamp();
      // update priceListRefs for product
      const productIDsBefore = (
        _.cloneDeep(this.priceListDetail.productRefs) || []
      ).map(r => r.productId);
      const productIDsAfter = _.cloneDeep(data).map(r => r.productId);

      let productIDsRemove = _.differenceWith(
        productIDsBefore,
        productIDsAfter,
        _.isEqual
      );
      let productIDsAdd = _.differenceWith(
        productIDsAfter,
        productIDsBefore,
        _.isEqual
      );

      // product change price
      const productsBefore = (
        _.cloneDeep(this.priceListDetail.productRefs) || []
      ).map(r => ({
        productId: r.productId,
        price: r.price,
      }));
      const productsAfter = _.cloneDeep(data).map(r => ({
        productId: r.productId,
        price: r.price,
      }));

      let productsRemoveHistory = _.differenceWith(
        productsBefore,
        productsAfter,
        _.isEqual
      );

      this.$f7.dialog.preloader('Updating product items. Please wait...');
      const promises = [];

      promises.push(
        this.updatePrice({
          id: this.priceListDetail.id,
          doc: {
            productRefs: data,
          },
        })
      );
      for (const id of productIDsAdd) {
        promises.push(
          this.addPriceListRefs({
            id,
            priceListId: this.priceListDetail.id,
          })
        );
      }
      for (const id of productIDsRemove) {
        promises.push(
          this.removePriceListRefs({
            id,
            priceListId: this.priceListDetail.id,
          })
        );
        // delete product history
        promises.push(
          this.deleteHistoryBys({
            priceListId: this.priceListDetail.id,
            productId: id,
          })
        );
      }
      for (const item of productsRemoveHistory) {
        // delete product history
        promises.push(
          this.deleteHistoryBys({
            priceListId: this.priceListDetail.id,
            productId: item.productId,
          })
        );
      }
      await Promise.all(promises);
      await this.algoliaUpdateItems(currentTimestamp);
      this.$f7.dialog.close();
      this.onSearch();
    },

    async onEdit(item) {
      delete item.checked;
      const currentTimestamp = this.getCurrentTimestamp();
      const editData = await this.$refs.productEditorRef.startEditor(
        'Edit product',
        item
      );
      if (!editData) return;
      this.$f7.preloader.show();
      const promises = [];
      // update product
      promises.push(
        this.updateProduct({
          id: editData.productData.id,
          doc: {
            ...editData.productData,
          },
        })
      );
      // update product on pricelist
      let productRefs = _.cloneDeep(this.currentPriceList.productRefs) || [];
      productRefs = productRefs.map(r => {
        if (r.productId === editData.productData.id) {
          return {
            productId: r.productId,
            vendorPrice: editData.productData.price,
            price: editData.price,
            markup: editData.markup,
            internalNote: editData.internalNote,
            proposalNote: editData.proposalNote,
            estServiceDefaultForTypes: r.estServiceDefaultForTypes || [],
          };
        }
        return r;
      });
      promises.push(
        this.updatePrice({
          id: this.priceListDetail.id,
          doc: { productRefs },
        })
      );
      await Promise.all(promises);
      await this.algoliaUpdateItems(currentTimestamp);
      this.$f7.preloader.hide();
      await this.loadCurrentProductPage();
      // update selected product
      if (!_.isEmpty(this.selectedProductsWT)) {
        const index = this.selectedProductsWT.findIndex(
          i => i.id === editData.productData.id
        );
        if (index >= 0) {
          this.selectedProductsWT[index] = (this.productComputed || []).find(
            r => r.id === editData.productData.id
          );
        }
      }
    },

    createColorPhotoTooltip() {
      this.productComputed.forEach(item => {
        (item.colorPhotos || []).forEach(color => {
          const tooltipElement = this.$f7.tooltip.get(
            `#product-PL-${color.id}-${item.id}`
          );
          if (tooltipElement) {
            this.$f7.tooltip.destroy(`#product-PL-${color.id}-${item.id}`);
          }
          const photo = (color.photos || [])[0];
          const tooltipContent = photo
            ? `<img class="tooltip-image-show" src="${photo.thumbnailUrl}" />`
            : `<span>No image !</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${item.id}-${color.id}`,
            cssClass: photo ? 'tooltip-image-preview' : '',
            text: tooltipContent,
          });
        });
      });
      this.productComputed.forEach(attachMent => {
        (attachMent.technicalData || []).forEach(item => {
          const el1 = this.$f7.tooltip.get(
            `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`
          );
          if (el1) {
            this.$f7.tooltip.destroy(
              `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`
            );
          }
          const tooltipContent = isImage(item.url)
            ? `<img class="tooltip-image-show" src="${item?.url}" />`
            : isVideo(item.url)
              ? `<video class="tooltip-image-show" src="${item?.url}" ></video>`
              : `<span>${item?.name}</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`,
            cssClass:
              isImage(item.url) || isVideo(item.url)
                ? 'tooltip-image-preview'
                : '',
            text: tooltipContent,
          });
        });
      });
    },

    selectProductItem({ id, checked }) {
      if (checked && !this.selectedProductsWT.some(r => r.id === id)) {
        const product = this.productComputed.find(r => r.id === id);
        this.selectedProductsWT.push(product);
      } else if (checked == false) {
        this.selectedProductsWT = this.selectedProductsWT.filter(
          r => r.id != id
        );
      }
    },

    removeProducts() {
      const app = this;
      // validate before approve
      if (!app.selectedProductsWT.length) {
        app.$ri.dialog.openErrorDialog({
          title: 'Remove Error',
          content: 'Please select products before clicking remove',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
        return;
      }
      app.$ri.dialog.openWarningDialog({
        title: 'Remove Products',
        content: `Are you sure you want to remove ${
          app.selectedProductsWT.length
        } selected ${
          app.selectedProductsWT.length == 1 ? 'product' : 'products'
        }?`,
        textButton: 'Remove',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$f7.preloader.show();
            const currentTimestamp = this.getCurrentTimestamp();
            const promises = [];
            for (const product of app.selectedProductsWT) {
              promises.push(app.removeProductItem(product, true));
            }
            Promise.all(promises).then(async () => {
              app.selectedProductsWT = [];
              _sefl.app.dialog.close();
              await app.algoliaUpdateItems(currentTimestamp);
              app.$f7.preloader.hide();
              app.getTotalProductWFTByPL();
              app.onSearch();
            });
          }
        },
      });
    },

    approveProducts() {
      const app = this;
      // validate before approve
      if (!app.selectedProductsWT.length) {
        app.$ri.dialog.openErrorDialog({
          title: 'Approve Error',
          content: 'Please select products before clicking approve',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
        return;
      }

      const keysRequired = [
        'vendorId',
        'roofTypes',
        'subCategoryId',
        'categoryId',
        'status',
      ];
      for (const product of app.selectedProductsWT) {
        for (const key of keysRequired) {
          if (product[key] === '?') product[key] = '';
          if (_.isEmpty(product[key])) {
            app.$ri.dialog.openErrorDialog({
              title: 'Missing information',
              content:
                'Please fill in the information of the selected products before clicking approve',
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  _sefl.app.dialog.close();
                }
              },
            });
            return;
          }
        }
      }

      app.$ri.dialog.openWarningDialog({
        title: 'Approve Products',
        content: `Are you sure you want to approve ${
          app.selectedProductsWT.length
        } selected ${
          app.selectedProductsWT.length == 1 ? 'product' : 'products'
        }?`,
        textButton: 'Approve',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$f7.preloader.show();
            const currentTimestamp = this.getCurrentTimestamp();
            const promises = [];
            for (const product of app.selectedProductsWT) {
              promises.push(
                app.updateProduct({
                  id: product.id,
                  doc: { status: STATUS_PRODUCT_ITEM_ACTIVE },
                })
              );
            }
            Promise.all(promises).then(async () => {
              app.selectedProductsWT = [];
              _sefl.app.dialog.close();
              await app.algoliaUpdateItems(currentTimestamp);
              app.$f7.preloader.hide();
              app.getTotalProductWFTByPL();
              app.onSearch();
            });
          }
        },
      });
    },

    exportValues(list) {
      const productRefs = this.priceListDetail.productRefs || [];
      return list.map(r => {
        const overrideProduct =
          productRefs.find(p => p.productId === r.id) || {};

        //handle color photos to export
        const colorPhotosJSON = {
          color: (r.colorPhotos || []).map(color => {
            const { colorName, photos } = color;
            return {
              colorName,
              photos: photos.map(photo => ({ url: photo.url })),
            };
          }),
        };
        const technicalDataUrls = (r.technicalData || []).map(
          techData => techData.url
        );

        return {
          ...r,
          vendor: r.vendorName,
          productCategory: r.categoryName,
          productSubCategory: r.subCategoryName,
          roofType: this.productRoofTypeNames(r.roofTypes),
          status: this.productItemStatusByValue(r.status).displayName,
          colorPhotos: JSON.stringify(colorPhotosJSON),
          price: overrideProduct.price,
          technicalData: technicalDataUrls,
        };
      });
    },

    async exportToExcel() {
      // get all data by search conditions maximum for data is 20000 records
      this.$f7.preloader.show();
      let list = [];
      let { hits, nbPages, page } = await this.searchProductItems({
        ...this.compileConditionsToSearchByPL,
        isGetAll: true,
      });
      list.push(...hits);
      while (page < nbPages - 1) {
        const data = await this.searchProductItems({
          ...this.compileConditionsToSearchByPL,
          isGetAll: true,
          pageNumber: page + 1,
        });
        list.push(...data.hits);
        nbPages = data.nbPages;
        page = data.page;
      }

      this.$f7.preloader.hide();
      exportPriceList({
        pricelist: this.exportPriceListInfo,
        headers: this.headers.filter(r => r.value !== ''),
        values: this.exportValues(list),
      });
    },
  },

  watch: {
    priceListDetail: {
      handler(val) {
        this.rebindWatch(val);
      },
      deep: true,
      immediate: true,
    },
    productsByPL: {
      handler(val) {
        if (!_.isEmpty(val.hits)) {
          this.getPriceList();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  beforeDestroy() {
    this.onChangeLimit(25);
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    const startDate = moment(this.currentPriceList.startDate[0]).format(
      'YYYY-MM-DD'
    );
    const endDate =
      moment(this.currentPriceList.endDate[0]).format('YYYY-MM-DD') || '';
    const startDateRules = () => {
      if (
        this.currentPriceList.endDate &&
        this.currentPriceList.endDate.length == 0
      ) {
        return true;
      } else return !moment(startDate).isAfter(endDate, 'day');
    };
    const endDateRules = () => {
      if (
        this.currentPriceList.endDate &&
        this.currentPriceList.endDate.length == 0
      ) {
        return true;
      } else return !moment(endDate).isBefore(startDate, 'day');
    };
    return {
      currentPriceList: {
        displayName: {
          required,
        },
        roofTypes: {
          required,
        },
        startDate: {
          required,
          startDateRules,
        },
        endDate: {
          endDateRules,
        },
        markup: {
          value: {
            required,
          },
          type: {
            required,
          },
        },
      },
    };
  },
};
</script>

<style scoped lang="scss">
.image-preview {
  border-radius: 4px;
  width: 70px;
  height: 70px;
  border: 1px solid #e0e0e0;
  position: relative;
  background-position: 50% center;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.technical-data {
  width: 120px;
  display: inline-block;
  list-style-type: disc;
}

.technical-data a {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.technical-data li {
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--f7-theme-color);
}

.technical-data li::marker {
  color: var(--f7-color-text-neutral);
}

.image-show {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tab-button {
  flex-direction: row;
  width: auto;
  font-weight: 500;
  background: var(--f7-color-bg-2-neutral);
  color: var(--f7-color-text-neutral);
  padding: 0 15px;
  font-size: 16px;
  gap: 8px;
}

.custom-link {
  width: fit-content !important;
}

.custom-link ::v-deep .toolbar-inner {
  justify-content: flex-start;
  gap: 16px;
  padding-left: 0px;
  width: fit-content;
}

.custom-link ::v-deep .tab-link:not(.tab-link-active) {
  background-color: white;
}

.tab-link-active {
  color: white;
  background: #f05034;
}

.pinned-row {
  position: sticky;
  z-index: 2;
  background-color: var(--f7-color-bg-4-neutral);
}

.mobile-container {
  justify-content: center;
  flex-direction: column-reverse;
}

.mobile-container > * {
  padding: 10px 0;
}

.pinned-row::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 100%;
  box-shadow: 7px 0 5px rgba(10, 10, 10, 0.9);
  top: 0;
  right: 0;
}

.roof-type-box ::v-deep {
  .list {
    margin: 0;
    width: 100%;
  }

  ul {
    padding-left: 0;

    .item-content {
      padding-left: 0;
    }
  }
}

.row-info {
  margin: 0 10px;
}

.col-info {
  border: none;

  &::v-deep {
    .list ul {
      border-radius: 4px;

      &::before,
      &::after {
        display: none;
      }
    }
  }
}

.time-update-text {
  color: var(--f7-color-text-5-neutral);
  font-size: 13px;
  line-height: 26px;
}
</style>
