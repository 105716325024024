/* eslint-disable no-unused-vars */
import {
  FirebaseActions,
  firestore,
} from '../../../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const timeLogAction = new FirebaseActions(
    `/system_client/${tenant}/weekly_labor_cost`,
    'timeLog'
  );

  const workOrderTaskAction = new FirebaseActions(
    `/system_client/${tenant}/work_order_task`,
    'purchaseOrder'
  );

  const expenseActions = new FirebaseActions(
    `/system_client/${tenant}/employee_expenses`,
    'expense'
  );

  const estimateActions = new FirebaseActions(
    `/system_client/${tenant}/estimate`,
    'estimate'
  );

  const invoiceActions = new FirebaseActions(
    `/system_client/${tenant}/invoice`,
    'invoice'
  );

  const RecurringInvoiceActions = new FirebaseActions(
    `/system_client/${tenant}/recurring-invoice`,
    'recurringInvoice'
  );

  return {
    getTimeTrackingBys: timeLogAction.getDocumentBys,
    getWorkOrderTaskBys: workOrderTaskAction.getDocumentBys,
    getExpenseListBys: expenseActions.getDocumentBys,
    getEstimateListBys: estimateActions.getDocumentBys,
    getInvoiceListBys: invoiceActions.getDocumentBys,
    getRecurringInvoiceBys: RecurringInvoiceActions.getDocumentBys,

    initTimeTracking({ commit, dispatch }, projectId) {
      return dispatch('getTimeTrackingBys', [
        {
          prop: 'projectId',
          op: '==',
          val: projectId,
        },
        { prop: 'expenseType', op: '==', val: 'labor' },
      ]).then(res => commit(types.SET_TIME_TRACKING_LIST, res));
    },

    initPurchaseOrder({ commit, dispatch }, projectId) {
      return dispatch('getWorkOrderTaskBys', [
        {
          prop: 'projectId',
          op: '==',
          val: projectId,
        },
        { prop: 'taskType', op: '==', val: 'purchase-order' },
      ]).then(res => commit(types.SET_WORK_ORDER_TASK_LIST, res));
    },

    getInvoiceGroupByProjectId(context, { projectId, tenant }) {
      let collectionPath = `/system_client/${tenant}/invoice_group`;
      return firestore
        .collection(collectionPath)
        .where('projectId', '==', projectId)
        .where('isProjectArchived', '==', false)
        .get()
        .then(snap => {
          const docs = snap.docs.map(doc => {
            return { id: doc.id, ...doc.data() };
          });
          return docs;
        });
    },

    initExpense({ commit, dispatch }, projectId) {
      return dispatch('getExpenseListBys', [
        {
          prop: 'projectId',
          op: '==',
          val: projectId,
        },
        { prop: 'status', op: '==', val: 'ex-approved' },
      ]).then(res => commit(types.SET_EXPENSE_LIST, res));
    },

    initEstimate({ commit, dispatch }, projectId) {
      return dispatch('getEstimateListBys', [
        {
          prop: 'projectId',
          op: '==',
          val: projectId,
        },
        { prop: 'status', op: '==', val: 'active' },
        { prop: 'isDefault', op: '==', val: true },
      ]).then(res => commit(types.SET_ESTIMATE_LIST, res));
    },

    async initInvoice({ commit, dispatch }, invoiceGroupId) {
      if (!invoiceGroupId) return;
      const invoiceList = await dispatch('getInvoiceListBys', [
        { prop: 'invoiceGroupId', op: '==', val: invoiceGroupId },
      ]);
      commit(types.SET_INVOICE_LIST, invoiceList);
    },

    getBuildingsByEstimateId(_, estimateId) {
      return FirebaseActions.getCollection(
        `/system_client/${tenant}/estimate/${estimateId}/building`
      );
    },

    setBuildings({ commit }, buildings) {
      commit(types.SET_BUILDINGS, buildings);
    },

    async getRecurringInvoiceByInvoiceGroupId(
      { commit, dispatch },
      invoiceGroupId
    ) {
      if (!invoiceGroupId) return;
      const recurringInvoiceList = await dispatch('getRecurringInvoiceBys', [
        { prop: 'invoiceGroupId', op: '==', val: invoiceGroupId },
      ]);

      commit(types.SET_RECURRING_INVOICE_LIST, recurringInvoiceList);
    },

    resetFinance({ commit }) {
      commit(types.SET_TIME_TRACKING_LIST, []);
      commit(types.SET_EXPENSE_LIST, []);
      commit(types.SET_INVOICE_LIST, []);
      commit(types.SET_RECURRING_INVOICE_LIST, []);
      commit(types.SET_ESTIMATE_LIST, []);
      commit(types.SET_WORK_ORDER_TASK_LIST, []);
      commit(types.SET_BUILDINGS, []);
    },

    resetDataInvoice({ commit }) {
      commit(types.SET_INVOICE_LIST, []);
      commit(types.SET_RECURRING_INVOICE_LIST, []);
    },
  };
};
