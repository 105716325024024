<template>
  <f7-page>
    <f7-tabs animated>
      <f7-tab
        ref="tab-action"
        :id="'-tab-1'"
        tab-active
        ><action-tab
          ref="actionTab"
          v-on="$listeners"
        ></action-tab
      ></f7-tab>

      <f7-tab
        ref="tab-estimate"
        :id="'-tab-2'"
      >
        <estimate-tab
          v-on="$listeners"
          ref="estimateTab"
        ></estimate-tab>
      </f7-tab>

      <!-- <f7-tab ref="tab-work-order" :id="'-tab-3'">
        <work-order-tab ref="workOrderTab" v-on="$listeners"></work-order-tab>
      </f7-tab> -->

      <f7-tab
        ref="tab-files"
        :id="'-tab-4'"
      >
        <file-tab ref="fileTab"></file-tab>
      </f7-tab>

      <f7-tab
        ref="tab-finance"
        :id="'-tab-5'"
      >
        <finance-tab
          :initData="false"
          ref="financeTab"
          v-on="$listeners"
        ></finance-tab>
      </f7-tab>
    </f7-tabs>

    <f7-toolbar
      tabbar
      bottom
      labels
    >
      <f7-link
        @click="showTab($event, 'tab-action')"
        :tab-link="'#' + '-tab-1'"
        :tab-link-active="tabActive === 'tab-action'"
        text="Action"
        icon-f7="info_circle_fill"
      ></f7-link>
      <f7-link
        @click="showTab($event, 'tab-estimate')"
        :tab-link="'#' + '-tab-2'"
        :text="$device.desktop ? 'Estimate/Proposal' : 'EST/PRPSL'"
        icon-f7="checkmark_alt_circle_fill"
        :tab-link-active="tabActive === 'tab-estimate'"
      ></f7-link>
      <!-- <f7-link
        @click="showTab($event, 'tab-work-order')"
        :tab-link="'#' + '-tab-3'"
        text="Work Order"
        icon-f7="wrench_fill"
        :tab-link-active="tabActive === 'tab-work-order'"
      ></f7-link> -->
      <f7-link
        @click="showTab($event, 'tab-files')"
        :tab-link="'#' + '-tab-4'"
        text="Files"
        icon-f7="doc_circle_fill"
        :tab-link-active="tabActive === 'tab-files'"
      ></f7-link>
      <f7-link
        @click="showTab($event, 'tab-finance')"
        :tab-link="'#' + '-tab-5'"
        text="Finance"
        icon-f7="money_dollar_circle_fill"
        :tab-link-active="tabActive === 'tab-finance'"
      ></f7-link>
    </f7-toolbar>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ActionTab from '../tab/ActionTab.vue';
import EstimateTab from '../tab/EstimateTab.vue';
import FileTab from '../tab/FileTab.vue';
import FinanceTab from '../tab/FinanceTab.vue';
// import WorkOrderTab from "../tab/WorkOrderTab.vue";

export default {
  components: { ActionTab, EstimateTab, FileTab, FinanceTab },
  props: {
    isDraft: Boolean,
  },

  data() {
    return {
      tabActive: 'tab-action',
    };
  },
  mounted() {
    let activeTab = this.$f7route.query && this.$f7route.query.activeTab;
    this.tabActive = activeTab ? activeTab : 'tab-action';
    this.showTab(null, this.tabActive);
  },
  computed: {
    ...mapGetters({
      detail: 'swimlane/card/detail',
    }),
  },
  methods: {
    ...mapActions('swimlane/estimate', ['getEstimatesByProjectId']),
    ...mapActions('swimlane/proposal', ['getProposalsByProjectId']),
    initData() {
      this.getEstimatesByProjectId(this.detail.id);
      this.getProposalsByProjectId(this.detail.id);
    },
    showTab(event, name) {
      this.initData();
      if (event) {
        event.preventDefault();
      }
      this.$refs[name].show();
      this.tabActive = name;

      switch (name) {
        case 'tab-files':
          this.$refs.fileTab.init();
          break;
        case 'tab-estimate':
          this.$refs.estimateTab.init();
          break;
        case 'tab-finance':
          this.$refs.financeTab.init();
          break;
        // case "tab-work-order":
        //   this.$refs.workOrderTab.init();
        //   break;
      }
    },

    clearData() {
      this.$refs.actionTab.clearData();
    },
  },
};
</script>

<style lang="scss">
.popup-scroll-bar {
  height: 100%;
  overflow-y: auto;
}
</style>
