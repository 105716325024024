import { FirebaseActions } from '@/services/firebase.service';

export default tenant => {
  const TemplateActions = new FirebaseActions(
    `/system_client/${tenant}/swimlane_column`,
    'object'
  );

  return {
    getBys: TemplateActions.getDocumentBys,
  };
};
