import * as types from './types';
import {
  firebase,
  FirebaseActions,
  StorageActions,
} from '../../../../services/firebase.service';
import { PROP_IS_DELETED } from '../../../../utility/const';
import { downloadFolder } from '../../../../services/jszip.service';

export default tenant => {
  const projectPhotoActions = new FirebaseActions(
    `/system_client/${tenant}/project_photo`,
    'projectPhoto'
  );

  return {
    getProjectPhotoListBys: projectPhotoActions.getDocumentBys,
    getPhoto: projectPhotoActions.getDocument,

    addProjectPhoto: projectPhotoActions.createDocument,
    saveProjectPhoto: projectPhotoActions.updateDocument,
    removeProjectPhoto: projectPhotoActions.deleteDocument,

    getProjectPhotosByAlbum({ dispatch, commit }, albumId) {
      return dispatch('getProjectPhotoListBys', [
        {
          prop: 'albumId',
          val: albumId,
          op: '==',
        },
        {
          prop: PROP_IS_DELETED,
          val: false,
          op: '==',
        },
      ]).then(res => {
        return commit(types.PROJECT_PHOTOS_BY_ALBUM, res);
      });
    },

    getProjectPhoto({ commit, dispatch }, id) {
      return dispatch('getPhoto', id).then(res => {
        return commit(types.PROJECT_PHOTO, res);
      });
    },

    // CRUD => update data list
    createProjectPhoto({ dispatch, commit }, data) {
      return dispatch('addProjectPhoto', data).then(id => {
        return commit(types.PROJECT_PHOTO_LIST_ITEM_ADD, {
          ...data,
          createdAt: firebase.firestore.Timestamp.now(),
          id,
        });
      });
    },

    updateProjectPhoto({ dispatch, commit }, { id, doc }) {
      return dispatch('saveProjectPhoto', { id, doc }).then(() => {
        return commit(types.PROJECT_PHOTO_LIST_ITEM_UPDATE, {
          id,
          doc,
        });
      });
    },

    deleteProjectPhoto({ dispatch, commit }, id) {
      return dispatch('removeProjectPhoto', id).then(() => {
        return commit(types.PROJECT_PHOTO_LIST_ITEM_REMOVE, id);
      });
    },

    // eslint-disable-next-line no-unused-vars
    async uploadPhoto({ commit }, { file, projectId }) {
      const name = +new Date() + file.name;
      const fullPath = `project/photos/${projectId}/${name}`;
      return StorageActions.uploadFile(fullPath, file);
    },

    // eslint-disable-next-line no-unused-vars
    uploadBase64Photo({ commit }, { base64Data, fullPath }) {
      return StorageActions.uploadFileBase64(fullPath, base64Data, {});
    },

    // eslint-disable-next-line no-unused-vars
    downloadPhoto({ commit }, { fullPath, name, photoUrl = '' }) {
      return StorageActions.downloadFile({ fullPath, name, photoUrl });
    },
    // eslint-disable-next-line no-unused-vars
    async removePhoto({ commit }, fullPath) {
      return StorageActions.removeFile(fullPath);
    },
    async downloadAlbum({ dispatch }, { albumId, albumName }) {
      const photosByAlbum = await dispatch('getProjectPhotoListBys', [
        {
          prop: 'albumId',
          val: albumId,
          op: '==',
        },
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);
      return downloadFolder(
        albumName,
        photosByAlbum.map(r => ({
          name: r.photoName,
          fullPath: r.photoFullPath,
          photoUrl: r.photoUrl,
        }))
      );
    },
    changeSelectionMode({ commit }, mode) {
      commit(types.CHANGE_SELECT_MODE, mode);
    },
    cancelSelectionMode({ commit }) {
      commit(types.RESET_SELECT_STATE);
    },
    onSelect({ commit }, id) {
      commit(types.UPDATE_SELECTED_IDS, id);
    },

    resetPhoto({ commit }) {
      commit(types.RESET_DATA);
    },
  };
};
