<template>
  <f7-popup
    class="demo-popup"
    :opened="isShow"
    @popup:opened="handlePopupOpened"
    @popup:closed="
      cancel();
      $emit('close');
    "
  >
    <f7-page class="time-tracking-form">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Add New Time Tracking</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="add()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <!-- employee -->
      <f7-list>
        <f7-list-item
          link
          @click.native="isShowUserListPopup = true"
          v-if="isEnableSelectEmployee"
        >
          <div slot="header">Employee Name<required-asterisk /></div>
          <div slot="title">
            {{
              (currenttimeTracking.user_ids || []).length > 0
                ? userByIds(currenttimeTracking.user_ids)
                : 'Select employee'
            }}
          </div>
          <input-icon
            slot="media"
            icon="person"
          ></input-icon>
          <div
            slot="footer"
            style="color: red"
          >
            {{ userErrorMessage }}
          </div>
        </f7-list-item>

        <!-- time log type -->
        <f7-list-item>
          <div slot="header">Time Log Type<required-asterisk /></div>
          <f7-input
            slot="title"
            type="select"
            :value="currenttimeTracking.timeLogType"
            @change="changeTimeLogType"
          >
            <option
              v-for="item in timeLogTypeList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.displayName }}
            </option>
          </f7-input>
          <input-icon
            slot="media"
            icon="square_fill_line_vertical_square"
          ></input-icon>
          <div
            slot="footer"
            style="color: red"
          >
            {{ timeLogTypeErrorMessage }}
          </div>
        </f7-list-item>

        <!-- project/timeoff/title -->
        <f7-list-item
          v-show="currenttimeTracking.timeLogType === TIME_LOG_TYPE_PROJECT"
          link
          @click.native="selectProject"
        >
          <div slot="header">Project<required-asterisk /></div>
          <div slot="title">
            {{
              currenttimeTracking.project
                ? currenttimeTracking.project.title
                : 'Select project'
            }}
          </div>
          <input-icon
            slot="media"
            icon="cube_box"
          ></input-icon>
          <div
            slot="footer"
            style="color: red"
          >
            {{ projectErrorMessage }}
          </div>
        </f7-list-item>

        <f7-list-input
          v-show="currenttimeTracking.timeLogType === TIME_LOG_TYPE_PROJECT"
          label="Building/Section#"
          placeholder="Enter Building/Section#"
          type="text"
          :value="currenttimeTracking.buildingOrSection"
          @input="currenttimeTracking.buildingOrSection = $event.target.value"
        >
          <input-icon
            slot="media"
            icon="building_2_fill"
          ></input-icon>
        </f7-list-input>

        <f7-list-item
          v-show="currenttimeTracking.timeLogType === TIME_LOG_TYPE_TIME_OFF"
        >
          <div slot="header">Time Off<required-asterisk /></div>
          <f7-input
            slot="title"
            type="select"
            :value="currenttimeTracking.timeOffType"
            @change="currenttimeTracking.timeOffType = $event.target.value"
          >
            <option
              v-for="item in timeOffTypeList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.displayName }}
            </option>
          </f7-input>
          <input-icon
            slot="media"
            icon="cube_box"
          ></input-icon>
          <div
            slot="footer"
            style="color: red"
          >
            {{ timeOffTypeErrorMessage }}
          </div>
        </f7-list-item>

        <f7-list-item
          v-show="currenttimeTracking.timeLogType === TIME_LOG_TYPE_OTHERS"
        >
          <div slot="header">Title<required-asterisk /></div>
          <f7-input
            slot="title"
            type="text"
            placeholder="Enter Title"
            :value="currenttimeTracking.title"
            @input="currenttimeTracking.title = $event.target.value.trim()"
          ></f7-input>
          <input-icon
            slot="media"
            icon="pencil_ellipsis_rectangle"
          ></input-icon>
          <div
            slot="footer"
            style="color: red"
          >
            {{ titleErrorMessage }}
          </div>
        </f7-list-item>

        <!-- from date -->
        <f7-list-input
          v-show="
            ![TIME_LOG_TYPE_LUNCHTIME].includes(currenttimeTracking.timeLogType)
          "
          label="From Date"
          type="datepicker"
          placeholder="MM/DD/YYYY"
          error-message-force
          validate
          validate-on-blur
          :value="currenttimeTracking.fromDate"
          :error-message="dateErrorMessage"
          :calendar-params="{
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            dateFormat: 'mm/dd/yyyy',
          }"
          @calendar:change="handleCalendarChange"
        >
          <input-icon
            slot="media"
            icon="calendar"
          ></input-icon>
          <required-asterisk slot="label" />
        </f7-list-input>

        <!-- to date -->
        <f7-list-input
          v-show="
            ![TIME_LOG_TYPE_LUNCHTIME].includes(currenttimeTracking.timeLogType)
          "
          label="To Date"
          type="datepicker"
          placeholder="MM/DD/YYYY"
          error-message-force
          validate
          validate-on-blur
          :value="currenttimeTracking.toDate || [new Date()]"
          :error-message="endDateErrorMessage"
          :calendar-params="{
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            dateFormat: 'mm/dd/yyyy',
          }"
          @calendar:change="
            currenttimeTracking.toDate = $event;
            $f7.calendar.close();
          "
        >
          <input-icon
            slot="media"
            icon="calendar"
          ></input-icon>
          <required-asterisk slot="label" />
        </f7-list-input>

        <!-- billable -->
        <f7-list-item title="Billable">
          <f7-toggle
            slot="after"
            :checked="currenttimeTracking.billable"
            @toggle:change="currenttimeTracking.billable = $event"
          ></f7-toggle>
          <input-icon
            slot="media"
            icon="money_dollar_circle"
          ></input-icon>
        </f7-list-item>

        <!-- enter time -->
        <f7-list-item>
          <div slot="header">
            {{
              ![TIME_LOG_TYPE_TIME_OFF, TIME_LOG_TYPE_LUNCHTIME].includes(
                currenttimeTracking.timeLogType
              )
                ? 'Hours Worked'
                : 'Number of Hours (per day)'
            }}<required-asterisk />
          </div>
          <template
            v-if="currenttimeTracking.timeLogType !== TIME_LOG_TYPE_LUNCHTIME"
          >
            <input-spent-time
              slot="title"
              :spentTime="spentTime"
              :maxValue="maxTimeValue"
              @changeSpentTime="changeSpentTime"
            ></input-spent-time>
            <div
              slot="footer"
              style="color: red"
            >
              {{ spentTimeErrorMessage }}
            </div>
          </template>
          <template v-else>
            <f7-input
              slot="title"
              type="number"
              min="0"
              placeholder="Hours"
              :value="currenttimeTracking.overrideHour"
              @input="currenttimeTracking.overrideHour = $event.target.value"
            ></f7-input>
            <div
              slot="footer"
              style="color: red"
            >
              {{ overrideHourErrorMessage }}
            </div>
          </template>
          <input-icon
            slot="media"
            icon="clock"
          ></input-icon>
        </f7-list-item>

        <template>
          <!-- class -->
          <f7-list-item>
            <div slot="header">Class</div>
            <f7-input
              slot="title"
              type="select"
              :value="currenttimeTracking.classItem"
              @change="currenttimeTracking.classItem = $event.target.value"
            >
              <option
                value=""
                hidden
              >
                Select Class
              </option>
              <option
                v-for="item in classQBList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </f7-input>
            <input-icon
              slot="media"
              icon="rectangle_grid_2x2"
            ></input-icon>
          </f7-list-item>

          <!-- service item -->
          <f7-list-item>
            <div
              class="display-flex justify-content-space-between"
              slot="header"
            >
              <div>Service Item</div>
              <f7-link
                color="primary"
                icon-f7="gear_alt_fill"
                @click.native="openServiceItemSettingPopup"
              ></f7-link>
            </div>
            <f7-input
              slot="title"
              type="select"
              :value="currenttimeTracking.serviceItem"
              @change="currenttimeTracking.serviceItem = $event.target.value"
            >
              <option
                value=""
                hidden
              >
                Select Service Item
              </option>
              <option
                v-for="item in serviceItemOptions"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </f7-input>
            <input-icon
              slot="media"
              icon="list_bullet_below_rectangle"
            ></input-icon>
          </f7-list-item>
        </template>

        <!-- note -->
        <f7-list-input
          label="Note"
          type="textarea"
          placeholder="Enter note..."
          :value="currenttimeTracking.note"
          @input="currenttimeTracking.note = $event.target.value"
        >
          <input-icon
            slot="media"
            icon="square_pencil"
          ></input-icon>
        </f7-list-input>
      </f7-list>
      <project-list-popup
        ref="selectProject"
        @onSelected="onSelectedProject"
      ></project-list-popup>
      <user-list-popup
        ref="userListPopup"
        :isShow="isShowUserListPopup"
        :usersSelected="currenttimeTracking.user_ids"
        :type="type"
        @close="isShowUserListPopup = false"
        @onSelected="currenttimeTracking.user_ids = $event"
      ></user-list-popup>

      <service-item-setting-popup
        ref="serviceItemSettingPopup"
      ></service-item-setting-popup>
    </f7-page>
  </f7-popup>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { minValue, required } from '@vuelidate/validators';
import InputIcon from '@/components/icons/InputIcon.vue';
import ProjectListPopup from '@/components/popups/ProjectListPopup.vue';
import UserListPopup from './UserListPopup.vue';
import ServiceItemSettingPopup from './ServiceItemSettingPopup.vue';
import { getFullAddress } from '@/utility/address';
import moment from 'moment';

import { googlePlaceApiMixin } from '@/services/place.google.service';
import { overtimeMixin } from '../../mixin/overtime-mixin';

import InputSpentTime from '../../components/inputs/InputSpentTime.vue';
import _ from 'lodash';
import Vue from 'vue';

import {
  STATUS_TIME_TRACKING_WFA,
  TIME_LOG_TYPE_PROJECT,
  TIME_LOG_TYPE_TIME_OFF,
  TIME_LOG_TYPE_OTHERS,
  TIME_OFF_TYPE_PTO,
  TIME_LOG_TYPE_LUNCHTIME,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
  BUSINESS_CODE_RESIDENTIAL,
  COLLECTION_EMPLOYEE_TIME_LOG,
  COLLECTION_MY_TIME_LOG,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';

export default {
  props: {
    isShow: Boolean,
    redirect: Boolean,
    item: {
      type: Object,
      default: () => ({}),
    },
    type: String,
  },
  components: {
    InputIcon,
    ProjectListPopup,
    UserListPopup,
    InputSpentTime,
    ServiceItemSettingPopup,
  },
  mixins: [googlePlaceApiMixin, overtimeMixin],
  data() {
    return {
      TIME_LOG_TYPE_PROJECT,
      TIME_LOG_TYPE_TIME_OFF,
      TIME_LOG_TYPE_OTHERS,
      TIME_OFF_TYPE_PTO,
      TIME_LOG_TYPE_LUNCHTIME,

      isShowUserListPopup: false,
      spentTime: {
        hours: '-1',
        minutes: '-1',
      },
      currenttimeTracking: {
        date: [new Date()],
        fromDate: [new Date()],
        toDate: [new Date()],
        projectId: null,
        project: null,
        note: '',
        reject_reason: '',
        approvedHour: 0,
        timeLogType: TIME_LOG_TYPE_PROJECT,
        timeOffType: TIME_OFF_TYPE_PTO,
        title: '',
        buildingOrSection: '',
        user_ids: [],
        classItem: null,
        serviceItem: null,
        overrideHour: 1,
        billable: true,
      },
    };
  },

  methods: {
    ...mapActions({
      createTimeTracking: 'time-tracking/time-tracking/create',
      getProject: 'time-tracking/project/getProject',
    }),
    ...mapActions('common/notification', ['createNotificationByType']),
    ...mapActions('common/user', ['checkGroupUsers']),

    openServiceItemSettingPopup() {
      this.$refs.serviceItemSettingPopup.open();
    },
    changeSpentTime(field, val) {
      Vue.set(this.spentTime, field, Number(val));
    },
    changeTimeLogType($event) {
      if ($event.target.value === TIME_LOG_TYPE_TIME_OFF) {
        this.currenttimeTracking.override = true;
        if (
          (this.spentTime.hours <= 0 && this.spentTime.minutes <= 0) ||
          this.spentTime.hours >= 8
        ) {
          this.spentTime.hours = 8;
          this.spentTime.minutes = 0;
        }
      }
      this.currenttimeTracking.timeLogType = $event.target.value;
    },
    selectProject() {
      this.$refs.selectProject.open();
    },
    handleCalendarChange(event) {
      this.currenttimeTracking.fromDate = event;
      if (
        this.currenttimeTracking.toDate[0] <
        this.currenttimeTracking.fromDate[0]
      ) {
        this.currenttimeTracking.toDate = event;
      }
      this.$f7.calendar.close();
    },
    async onSelectedProject(projectId = '') {
      this.currenttimeTracking.projectId = projectId;
      this.currenttimeTracking.project = await this.getProject(projectId);
    },
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          location => resolve(location),
          reject
        );
      });
    },

    calcOverrideHour() {
      const { hours, minutes } = this.spentTime;
      return Number(hours) + Number(minutes) / 60;
    },
    handlePopupOpened() {
      if (this.item && this.item.date) {
        const newDate = new Date(this.item.date);
        this.currenttimeTracking.fromDate = [newDate];
        this.currenttimeTracking.toDate = [newDate];
      }
      if (this.item && this.item.userId) {
        this.currenttimeTracking.user_ids = [this.item.userId];
      }
    },
    async add() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        this.$f7.preloader.show();
        let { user_ids, fromDate, toDate, timeLogType } =
          this.currenttimeTracking;
        if (timeLogType === TIME_LOG_TYPE_PROJECT) {
          this.askAddLunchtime({
            fromDate: fromDate[0],
            toDate: toDate[0],
            userIds: user_ids,
          });
        } else {
          this.handleAdd();
        }
      }
    },

    async askAddLunchtime({ fromDate, toDate, userIds }) {
      const lunchTimeLogs = [];

      const lunchTimeLogsPromises = userIds.map(userId =>
        this.getWeeklyTimeLogs({
          fromDate,
          toDate,
          userId,
        })
      );

      const lunchTimeLogsResults = await Promise.all(lunchTimeLogsPromises);
      lunchTimeLogs.push(...lunchTimeLogsResults);
      // total days between fromDate-toDate
      const startDate = moment(fromDate, 'YYYY-MM-DD').startOf('day');
      const endDate = moment(toDate, 'YYYY-MM-DD').endOf('day');
      const days = endDate.diff(startDate, 'days');
      // Check if each user has lunchtime for all days
      const usersWithLunch = lunchTimeLogs.filter(
        r => (r.filter(item => item.hasLunchBreak) || []).length <= days
      );
      if (usersWithLunch.length === userIds.length) {
        this.$f7.preloader.hide();

        this.$ri.dialog.openInfoDialog({
          title: 'Lunchtime',
          content: 'Does your time log include lunchtime?',
          textButton: 'Yes',
          cancelButtonCaption: 'No',
          onClick: async (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
              this.handleAdd();
            } else if (index === 1) {
              _sefl.app.dialog.close();
              this.handleAdd(true);
            }
          },
        });
      } else {
        this.handleAdd();
      }
      this.$f7.preloader.hide();
    },

    async handleAdd(isAddLunchTime = false) {
      let {
        user_ids,
        date,
        fromDate,
        toDate,
        projectId,
        project,
        note,
        reject_reason,
        approvedHour,
        timeLogType,
        timeOffType,
        title,
        buildingOrSection,
        classItem,
        serviceItem,
        billable,
        // overrideHour
      } = this.currenttimeTracking;
      const self = this;
      const calcOverrideHour = this.calcOverrideHour();

      this.$emit('close'); // close first and update data later
      this.$f7.preloader.hide();
      this.$f7.preloader.show();
      let startLocation = null;
      let endLocation = null;
      try {
        const location = await this.getCurrentPosition();
        const {
          latitude,
          longitude,
          altitude,
          accuracy,
          altitudeAccuracy,
          heading,
          speed,
        } = location.coords;
        startLocation = {
          latitude,
          longitude,
          altitude,
          accuracy,
          altitudeAccuracy,
          heading,
          speed,
        };
        endLocation = {
          latitude,
          longitude,
          altitude,
          accuracy,
          altitudeAccuracy,
          heading,
          speed,
        };
      } catch {
        startLocation = null;
        endLocation = null;
      }

      if (startLocation && endLocation) {
        try {
          const address = await this.lookupAddress(
            startLocation.latitude,
            startLocation.longitude
          );
          startLocation.formattedAddress = address.formattedAddress;
          startLocation.placeId = address.placeId;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(e);
        }
        try {
          const address = await this.lookupAddress(
            endLocation.latitude,
            endLocation.longitude
          );
          endLocation.formattedAddress = address.formattedAddress;
          endLocation.placeId = address.placeId;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(e);
        }
      }
      let distance = 0;
      let gResult = null;
      // get project address
      if (
        timeLogType === TIME_LOG_TYPE_PROJECT &&
        !_.isEmpty(project) &&
        startLocation &&
        endLocation
      ) {
        let propertyAddress = {};
        if (
          project.businessCode === BUSINESS_CODE_COMMERCIAL ||
          (project.businessCode === BUSINESS_CODE_SERVICE &&
            project.customerType === 'commercial')
        ) {
          propertyAddress = project.propertyAddress;
        } else if (
          project.businessCode === BUSINESS_CODE_RESIDENTIAL ||
          (project.businessCode === BUSINESS_CODE_SERVICE &&
            project.customerType === 'residential')
        ) {
          propertyAddress = project.projectAddress;
        }
        if (!_.isEmpty(propertyAddress)) {
          try {
            const distanceResult = await this.calculateDistanceFromThreePoints(
              getFullAddress(propertyAddress),

              {
                lat: startLocation.latitude,
                lng: startLocation.longitude,
              },
              {
                lat: endLocation.latitude,
                lng: endLocation.longitude,
              }
            );
            distance = distanceResult.distance;
            gResult = distanceResult.gResult;
          } catch {
            gResult = [];
            distance = 0;
          }
        }
      }

      let promises = [];

      for (const user_id of user_ids) {
        let data = {
          user_id,
          date: this.$google.firebase.firestore.Timestamp.fromDate(
            new Date(moment(date[0]).format('MM/DD/YYYY'))
          ),
          projectId: timeLogType === TIME_LOG_TYPE_PROJECT ? projectId : null,
          project: timeLogType === TIME_LOG_TYPE_PROJECT ? project : {},
          note,
          overrideHour: calcOverrideHour,
          reject_reason,
          approvedHour,
          override: true,
          isDeleted: false,
          timeLogType,
          timeOffType:
            timeLogType === TIME_LOG_TYPE_TIME_OFF ? timeOffType : null,
          title:
            timeLogType === TIME_LOG_TYPE_OTHERS
              ? title
              : timeLogType === TIME_LOG_TYPE_LUNCHTIME
                ? 'Lunchtime'
                : '',
          buildingOrSection:
            timeLogType === TIME_LOG_TYPE_PROJECT ? buildingOrSection : '',
          startLocation,
          endLocation,
          distance,
          gResult: JSON.parse(JSON.stringify(gResult)),
          status: STATUS_TIME_TRACKING_WFA,
          qbClassRef:
            timeLogType === TIME_LOG_TYPE_PROJECT ||
            timeLogType === TIME_LOG_TYPE_OTHERS
              ? this.classQBList.find(r => r.value === classItem) || {}
              : {},
          qbServiceItemRef:
            timeLogType === TIME_LOG_TYPE_PROJECT ||
            timeLogType === TIME_LOG_TYPE_OTHERS
              ? this.serviceItemQBList.find(r => r.value === serviceItem) || {}
              : {},
          billable,
        };
        promises.push(
          this.createNotificationByType({
            data: {
              assignees: [user_id],
              project: {
                title: data.title,
                id: data.id || '',
                entityName: COLLECTION_MY_TIME_LOG,
              },
            },
            type: 'add-timelog-user',
          })
        );
        await this.checkGroupUsers('hr-main/employees-time-logs');
        const arrayOfUids = this.userGroupList.map(userGroup => userGroup.uid);
        promises.push(
          this.createNotificationByType({
            data: {
              assignees: arrayOfUids,
              project: {
                title: data.title,
                id: data.id || '',
                entityName: COLLECTION_EMPLOYEE_TIME_LOG,
              },
            },
            type: 'add-timelog-admin',
          })
        );
        if (timeLogType === TIME_LOG_TYPE_PROJECT) {
          if (isAddLunchTime) {
            data.lunchTimeInfo = {
              hour: -1,
              title: 'Lunch Break Included',
            };
            data.hasLunchBreak = isAddLunchTime;
          }
          const dates = this.getDateRange(fromDate[0], toDate[0]);
          for (const r of dates || []) {
            data.date = this.$google.firebase.firestore.Timestamp.fromDate(
              new Date(r)
            );
            promises.push(this.createTimeTracking(data));
          }
        }

        // else if (timeLogType === TIME_LOG_TYPE_LUNCHTIME) {
        //   promises.push(this.createTimeTracking(data));
        // }
        else {
          // calculate dates for time log type=other

          const dates = this.getDateRange(fromDate[0], toDate[0]);

          for (const r of dates || []) {
            data.date = this.$google.firebase.firestore.Timestamp.fromDate(
              new Date(r)
            );

            const lunchTimeByDateAndUser = await this.getWeeklyTimeLogs({
              fromDate: r,
              toDate: r,
              userId: user_id,
            });

            if (
              !(lunchTimeByDateAndUser.filter(item => item.hasLunchBreak) || [])
                .length &&
              isAddLunchTime
            ) {
              data.lunchTimeInfo = {
                hour: -1,
                title: 'Lunch Break Included',
              };
              data.hasLunchBreak = isAddLunchTime;
            }

            promises.push(this.createTimeTracking(data));
          }
        }
      }

      Promise.all(promises)
        .then(async () => {
          const dates = this.getDateRange(fromDate[0], toDate[0]);
          for (const user_id of user_ids || []) {
            for (const date of dates || []) {
              await self.composeOvertime(date, user_id);
            }
          }
        })
        .then(async () => {
          return await this.reloadTimelogList();
        })
        .catch(error =>
          this.$ri.dialog
            .openErrorDialog({
              title: 'Error',
              content: error.message,
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  _sefl.app.dialog.close();
                }
              },
            })
            .open()
        )
        .finally(() => {
          this.cancel();
          this.$f7.preloader.hide();
        });
    },

    getDateRange(start, end) {
      let startDate = moment(start);
      const endDate = moment(end);
      let dates = [];
      dates.push(startDate.format('MM/DD/YYYY'));
      while (!startDate.isSame(endDate, 'day')) {
        startDate = startDate.add(1, 'days');
        dates.push(startDate.format('MM/DD/YYYY'));
      }

      return dates;
    },

    cancel() {
      this.currenttimeTracking = {
        date: [new Date()],
        fromDate: [new Date()],
        toDate: [new Date()],
        projectId: null,
        project: null,
        note: '',
        reject_reason: '',
        approvedHour: 0,
        timeLogType: TIME_LOG_TYPE_PROJECT,
        timeOffType: TIME_OFF_TYPE_PTO,
        title: '',
        buildingOrSection: '',
        user_ids: null,
        classItem: null,
        serviceItem: null,
        overrideHour: 1,
        billable: true,
      };
      this.spentTime = {
        hours: '-1',
        minutes: '-1',
      };
      this.v$.$reset();
      if (this.$refs.userListPopup) {
        this.$refs.userListPopup.clearSelectedUsers();
      }
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    const endDateRules = () => {
      if (
        this.currenttimeTracking.fromDate === '' ||
        this.currenttimeTracking.toDate === ''
      )
        return true;
      const startDate = moment(this.currenttimeTracking.fromDate[0]).format(
        'YYYY-MM-DD'
      );
      const endDate = moment(this.currenttimeTracking.toDate[0]).format(
        'YYYY-MM-DD'
      );
      return !moment(endDate).isBefore(startDate, 'day');
    };

    const projectRule = () => {
      if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_PROJECT &&
        !this.currenttimeTracking.projectId
      )
        return false;
      return true;
    };

    const timeOffTypeRule = () => {
      if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_TIME_OFF &&
        !this.currenttimeTracking.timeOffType
      )
        return false;
      return true;
    };

    const titleRule = () => {
      if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_OTHERS &&
        !this.currenttimeTracking.title
      )
        return false;
      return true;
    };

    const spentRequiredRule = () => {
      if (this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_LUNCHTIME)
        return true;
      let condition1 = this.spentTime.hours >= 0;
      let condition2 = this.spentTime.minutes >= 0;
      let condition3 = +this.spentTime.hours + +this.spentTime.minutes > 0;

      if (!(condition1 && condition2 && condition3)) return false;
      return true;
    };

    const overrideHourRule = () => {
      if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_LUNCHTIME &&
        !this.currenttimeTracking.overrideHour
      )
        return false;
      return true;
    };

    return {
      currenttimeTracking: {
        user_ids: {
          required,
        },
        date: {
          required,
        },
        timeLogType: {
          required,
        },
        projectId: {
          projectRule,
        },
        timeOffType: {
          timeOffTypeRule,
        },
        title: {
          titleRule,
        },
        toDate: {
          endDateRules,
        },
        overrideHour: {
          overrideHourRule,
          minValue: this.currenttimeTracking.override ? minValue(0) : true,
        },
      },
      spentTime: {
        spentRequiredRule,
      },
    };
  },
  computed: {
    ...mapGetters('time-tracking/user', ['userById', 'userByIds']),

    ...mapGetters('time-tracking/app-constant', [
      'timeLogTypeList',
      'timeOffTypeList',
    ]),
    ...mapGetters('time-tracking/quickbooks', [
      'classQBList',
      'serviceItemQBList',
    ]),
    ...mapGetters('time-tracking/service-item-setting', ['serviceItemOptions']),
    ...mapGetters('common/user', ['userGroupList']),

    maxTimeValue() {
      if (this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_TIME_OFF)
        return 8;
      else return 24;
    },
    userErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return '';
      if (this.v$.currenttimeTracking.user_ids.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    endDateErrorMessage() {
      if (!this.v$.currenttimeTracking.toDate.endDateRules)
        return VALIDATION_MESSAGE.END_DATE_GREATER_THAN_START_DATE;
      return null;
    },
    formattedFromDate() {
      return moment(this.currenttimeTracking.fromDate[0]).format('MM/DD/YYYY');
    },
    formattedToDate() {
      return moment(this.currenttimeTracking.toDate[0]).format('MM/DD/YYYY');
    },
    dateErrorMessage() {
      if (!this.v$.currenttimeTracking.date.$error) return null;
      if (this.v$.currenttimeTracking.date.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    timeLogTypeErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return '';
      if (this.v$.currenttimeTracking.timeLogType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    projectErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return '';
      if (this.v$.currenttimeTracking.projectId.projectRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    timeOffTypeErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return '';
      if (!this.v$.currenttimeTracking.timeOffType.timeOffTypeRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    titleErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return '';
      if (this.v$.currenttimeTracking.title.titleRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },

    overrideHourErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return null;
      if (this.v$.currenttimeTracking.overrideHour.overrideHourRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.currenttimeTracking.overrideHour.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_OR_EQUAL_TO_ZERO;
      return null;
    },
    spentTimeErrorMessage() {
      if (!this.v$.spentTime.$error) return null;
      if (this.v$.spentTime.spentRequiredRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    isEnableSelectEmployee() {
      return _.isEmpty(this.item);
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-clock {
  padding-bottom: 2px;
}

.timer-count {
  margin-left: 5px;
}

.button-action {
  margin-top: 10px;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
}
</style>
<style lang="scss">
.time-tracking-form .item-title {
  width: 100%;
}
</style>
