<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="handlePopupClosed"
  >
    <f7-view>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-left>
          <f7-nav-title>Project Photos</f7-nav-title>
          <f7-nav-right>
            <f7-link
              v-if="selectionMode"
              @click="cancelSelection"
              >Cancel Select</f7-link
            >
          </f7-nav-right>
        </f7-navbar>

        <div
          ref="photosPageContainer"
          class="no-padding-top page-content infinite-scroll-content"
          @infinite="loadMore"
        >
          <photos
            ref="photos"
            :projectPhotoListGroup="projectPhotoListGroup"
            :selectionMode="selectionMode"
            :selectedPhotoIds="selectedPhotoIds"
            :hoveredPhotoIds="hoveredPhotoIds"
            :loading="loadingPhoto"
            @clickPhoto="clickPhoto"
            @clickEdit="clickEdit"
            @handleSelected="handleSelected"
            @handleSelectAllDay="handleSelectAllDay"
          ></photos>
          <div
            v-show="hasMoreItems"
            class="preloader infinite-scroll-preloader"
          ></div>
        </div>

        <photo-browser
          :photos="photoList"
          theme="dark"
          ref="pageDark"
        ></photo-browser>
        <edit-photo-popup ref="editPhoto"> </edit-photo-popup>

        <delete-photo
          v-if="selectedPhotoIds.length > 0"
          slot="fixed"
          :class="{ 'btn-photo': $device.desktop }"
          :selectedIds="selectedPhotoIds"
          @cancelSelect="cancelSelection"
        ></delete-photo>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>

<script>
import Photos from '../../components/photo/components/Photos.vue';
import EditPhotoPopup from '../../components/photo/components/EditPhotoPopup.vue';
import PhotoBrowser from '../../components/photo/components/PhotoBrowser.vue';
import DeletePhoto from '../../components/photo/buttons/DeletePhoto.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Photos,
    EditPhotoPopup,
    PhotoBrowser,
    DeletePhoto,
  },

  data: () => {
    return {
      popupOpened: false,
      loadingPhoto: null,
      hasMoreItems: true,
      allowInfinite: true,

      currentPhotosContainHeight: 0,
      pagePhotosContainHeight: 0,
      hoveredPhotoIds: [],
    };
  },

  computed: {
    ...mapGetters('swimlane/card', ['detail']),
    ...mapGetters('swimlane/photo', [
      'selectionMode',
      'selectedPhotoIds',
      'projectPhotoList',
      'projectPhotoListGroup',
      'projectPhotosSize',
    ]),

    photoList() {
      return this.projectPhotoList.map(i => ({
        id: i.id,
        url: i.photoUrl,
        comments: i.comments,
      }));
    },
  },

  methods: {
    ...mapActions('swimlane/photo', [
      'changeSelectionMode',
      'cancelSelectionMode',
      'onSelect',
      'getMoreProjectPhotos',
      'getTotalPhotoByProjectId',
      'resetPhoto',
    ]),
    open() {
      this.loadingPhoto = true;
      this.allowInfinite = true;
      this.hasMoreItems = true;

      this.getTotalPhotoByProjectId(this.detail.id)
        .then(() => {
          this.popupOpened = true;
          if (this.projectPhotoList.length === this.projectPhotosSize) {
            this.allowInfinite = false;
            this.hasMoreItems = false;
          }
          this.loadingPhoto = false;
          this.loadHeight();
        })
        .finally(() => {
          this.hasMoreItems = false;
        });
    },

    loadMore() {
      if (
        !this.allowInfinite ||
        this.projectPhotoList.length === this.projectPhotosSize
      ) {
        this.allowInfinite = false;
        this.hasMoreItems = false;
        return;
      }
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.getMoreProjectPhotos(this.detail.id)
        .then(() => {
          this.loadHeight();
          if (this.projectPhotoList.length === this.projectPhotosSize) {
            this.allowInfinite = false;
            this.hasMoreItems = false;
            return;
          }
          this.allowInfinite = true;
        })
        .finally(() => {
          this.hasMoreItems = false;
        });
    },

    loadHeight() {
      this.$nextTick(() => {
        this.currentPhotosContainHeight =
          (this.$refs.photos.$refs.imgsContainer || {}).offsetHeight || 0;
        this.pagePhotosContainHeight =
          (this.$refs.photosPageContainer || {}).offsetHeight || 0;
      });
    },

    handlePopupClosed() {
      this.popupOpened = false;
      this.loadingPhoto = null;
      this.hasMoreItems = true;
      this.allowInfinite = true;

      this.currentPhotosContainHeight = 0;
      this.pagePhotosContainHeight = 0;
      this.cancelSelectionMode();
    },

    clickPhoto(id, isShowPhoto = false) {
      if (isShowPhoto) {
        const index = this.photoList.findIndex(i => i.id === id);
        this.$refs.pageDark.open(index);
      } else {
        this.onSelect(id);
      }
    },
    clickEdit(id) {
      const photo = this.projectPhotoList.find(i => i.id === id);
      this.$refs.editPhoto.open(photo);
    },
    onSelection() {
      this.changeSelectionMode(true);
    },
    cancelSelection() {
      this.cancelSelectionMode();
    },
    handleSelected(id, isSelectPhoto = false) {
      if (this.selectedPhotoIds.length === 0 && id === null) {
        this.changeSelectionMode(false);
        this.hoveredPhotoIds = [];
      } else if (id === null) {
        const lastIndex = this.hoveredPhotoIds.length - 1;
        if (
          lastIndex >= 0 &&
          this.hoveredPhotoIds[lastIndex] !== null &&
          !this.selectedPhotoIds.includes(this.hoveredPhotoIds[lastIndex])
        ) {
          this.hoveredPhotoIds.splice(lastIndex, 1);
        }
      } else if (id && !this.hoveredPhotoIds.includes(id)) {
        this.changeSelectionMode(true);
        this.hoveredPhotoIds.push(id);
        if (isSelectPhoto) {
          this.clickPhoto(id);
        }
      }
    },
    handleSelectAllDay(data) {
      const photosIds = (data.ids || []).filter(
        i => !this.selectedPhotoIds.includes(i)
      );
      if (data.event) {
        for (const id of photosIds) {
          this.onSelect(id);
        }
        this.hoveredPhotoIds = this.hoveredPhotoIds.concat(photosIds);
        this.changeSelectionMode(true);
      } else {
        for (const id of photosIds) {
          this.onSelect(id);
        }
        this.hoveredPhotoIds = this.hoveredPhotoIds.filter(
          i => !data.ids.includes(i)
        );
        this.cancelSelectionMode();
      }
    },
  },

  watch: {
    currentPhotosContainHeight: {
      handler(val) {
        if (val && val <= this.pagePhotosContainHeight) {
          this.loadMore();
        }
      },
    },
  },
};
</script>

<style></style>
