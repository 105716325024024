<template>
  <f7-popup
    class="demo-popup"
    :opened="isShow"
    @popup:closed="cancel"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{
          isShowDetail ? 'Detail Shift' : 'Edit Shift'
        }}</f7-nav-title>
        <f7-nav-right v-if="!isShowDetail">
          <f7-link @click.native="edit()"> Save </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <schedule-detail
        isEdit
        ref="scheduleDetail"
        :isShow="isShow"
        :isInvalid="isInvalid"
        @onChangeObject="onChangeObject"
        @onChangeChainObject="onChangeChainObject"
        @onChangeUserIds="onChangeUserIds"
        @onToggleChange="onToggleChange"
      ></schedule-detail>
    </f7-page>
  </f7-popup>
</template>
<script>
import ScheduleDetail from '../detail/ScheduleDetail.vue';

import { useVuelidate } from '@vuelidate/core';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import emailNotificationMixins from '../../mixins/email-notifications';

const TIME_FORMAT = 'HH:mm';

import {
  TIME_LOG_TYPE_PROJECT,
  TIME_LOG_TYPE_TIME_OFF,
  TIME_LOG_TYPE_OTHERS,
  BUSINESS_CODE_SERVICE,
} from '../../../../utility/const';

export default {
  components: {
    ScheduleDetail,
  },
  props: {
    isShow: Boolean,
  },
  data: () => {
    return {
      TIME_LOG_TYPE_PROJECT,
      TIME_LOG_TYPE_TIME_OFF,
      TIME_LOG_TYPE_OTHERS,
      isInvalid: false,
      object: {},
      chainObject: {},
      userIds: [],
      isSendEmail: true,
    };
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  mixins: [emailNotificationMixins],
  methods: {
    ...mapActions('scheduling/project', [
      'getProjectById',
      'updateAssigneeIdsProject',
      'updateAdditionalAssigneeIdsProjectAlgolia',
      'updateDueDate',
    ]),
    ...mapActions('scheduling/chain', [
      'getScheduleChain',
      'updateChain',
      'createChain',
    ]),
    ...mapActions('scheduling/scheduling', [
      'createSchedule',
      'deleteSchedule',
      'updateSchedule',
      'getScheduleListBys',
      'getSchedulesByUserAndProject',
      'getSchedulesByUserAndDate',
      'setIsShowDetail',
      'setEditType',
    ]),

    cancel() {
      (this.isInvalid = false), (this.chainObject = {}), (this.object = {});
      this.$emit('close');
      this.setIsShowDetail(false);
      this.setEditType(null);
      this.$refs.scheduleDetail.v$.$reset();
      this.$refs.scheduleDetail.clearData();
    },

    onToggleChange(val) {
      this.isSendEmail = val;
    },

    onChangeObject(val) {
      this.object = val;
    },

    onChangeChainObject(val) {
      this.chainObject = val;
    },

    onChangeUserIds(val) {
      this.userIds = val;
    },

    showOverlapErrorDialog(userIds) {
      this.$ri.dialog.openErrorDialog({
        title: 'Error',
        content: `${this.userByIds(
          userIds
        )} have been overlapping time. Please recheck the time?`,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        },
      });
    },

    convertDateToTimestamp(date) {
      return this.$google.firebase.firestore.Timestamp.fromDate(
        new Date(moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY'))
      );
    },

    handleDataBeforeEdit() {
      let startDate = moment(this.chainObject.startDate[0]);
      const endDate = moment(this.chainObject.endDate[0]);
      let dates = [];
      dates.push(startDate.format('MM/DD/YYYY'));
      while (!startDate.isSame(endDate, 'day')) {
        startDate = startDate.add(1, 'days');
        dates.push(startDate.format('MM/DD/YYYY'));
      }
      let data = [];
      if (this.chainObject.daysSelected.length > 0) {
        dates = dates.filter(r => {
          return this.chainObject.daysSelected.includes(
            moment(r, 'MM/DD/YYYY').format('dd')
          );
        });
      }
      for (let date of dates || []) {
        for (let userId of this.userIds) {
          data.push({
            ...this.object,
            userId,
            date: this.$google.firebase.firestore.Timestamp.fromDate(
              new Date(date)
            ),
          });
        }
      }
      return data;
    },

    async validateForEditChain() {
      let userError = [];
      for (let userId of this.userIds) {
        const res = await this.getSchedulesByUserAndDate({
          startDate: this.convertDateToTimestamp(this.chainObject.startDate[0]),
          endDate: this.convertDateToTimestamp(this.chainObject.endDate[0]),
          userId,
        });

        let list = res.filter(r => r.id !== this.scheduleId);
        if (this.schedule.scheduleChainId) {
          list = list.filter(
            r => r.scheduleChainId !== this.schedule.scheduleChainId
          );
        }

        const data = this.handleDataBeforeEdit();
        if (list.length > 0) {
          for (const currentData of data) {
            const start = moment(currentData.startTime, TIME_FORMAT);
            const end = moment(currentData.finishTime, TIME_FORMAT);
            for (const availableData of list) {
              const startTime = moment(availableData.startTime, TIME_FORMAT);
              const finishTime = moment(availableData.finishTime, TIME_FORMAT);
              if (
                startTime.isBetween(start, end) ||
                start.isBetween(startTime, finishTime) ||
                start.isSame(startTime) ||
                finishTime.isBetween(start, end) ||
                end.isBetween(startTime, finishTime) ||
                end.isSame(finishTime)
              ) {
                userError.push(userId);
              }
            }
          }
        }
      }
      return userError;
    },

    async getCurrentProjectIds() {
      let currentProjectIds = [];
      if (
        this.typeEdit === 'thisShiftAndAllRemaining' ||
        this.typeEdit === 'allShifts'
      ) {
        currentProjectIds = _.cloneDeep(this.projectIdsByChainIdAndDate);
      } else {
        currentProjectIds = [this.schedule.projectId];
      }
      return currentProjectIds;
    },

    async handleUpdateAssigneeIds(oldSchedule) {
      const { projectId } = this.object;
      let oldProjectIds = oldSchedule.projectId ? [oldSchedule.projectId] : [];
      let oldUserIds = [oldSchedule.userId];

      if (oldSchedule.scheduleChainId) {
        oldUserIds = _.cloneDeep(this.userIdsByChainIdAndDate);
        oldProjectIds = _.cloneDeep(this.projectIdsByChainIdAndDate);
      }

      if (!projectId && _.isEmpty(oldProjectIds)) return;

      const newProject =
        (projectId && (await this.getProjectById(projectId))) || {};
      let newAssigneeIds = newProject.assigneeIDs || [];

      let array = [];
      let projectAdditionalAssigneeIds = [];

      if (!_.isEmpty(oldProjectIds)) {
        const getProjectPromises = [];
        for (const projectId of oldProjectIds) {
          getProjectPromises.push(this.getProjectById(projectId));
        }
        const res = await Promise.all(getProjectPromises);
        const oldProjects = [].concat.apply([], res);

        for (const project of oldProjects) {
          let currentAssigneeIds = _.cloneDeep(project.assigneeIDs) || [];
          let additionalAssigneeIdsRemoved = [];
          for (const userId of oldUserIds) {
            const list = await this.getSchedulesByUserAndProject({
              userId: userId,
              projectId: project.id,
            });
            if (list.length === 0) {
              currentAssigneeIds = (currentAssigneeIds || []).filter(
                r => r !== userId
              );
              additionalAssigneeIdsRemoved.push(userId);
            }
          }
          if (project.businessCode === BUSINESS_CODE_SERVICE) {
            array.push({
              projectId: project.id,
              assigneeIDs: currentAssigneeIds,
            });
            if (project.id === projectId) {
              newAssigneeIds = currentAssigneeIds;
            }
          } else if (
            !_.isEmpty(additionalAssigneeIdsRemoved) &&
            project.businessCode !== BUSINESS_CODE_SERVICE
          ) {
            projectAdditionalAssigneeIds.push({
              projectId: project.id,
              userIds: additionalAssigneeIdsRemoved,
              action: 'delete',
            });
          }
        }
      }

      if (projectId && newProject.businessCode === BUSINESS_CODE_SERVICE) {
        newAssigneeIds = _.uniq([...newAssigneeIds, ...this.userIds]);
        array.push({
          projectId: projectId,
          assigneeIDs: newAssigneeIds,
        });
      } else if (
        projectId &&
        newProject.businessCode !== BUSINESS_CODE_SERVICE
      ) {
        projectAdditionalAssigneeIds.push({
          projectId: projectId,
          userIds: this.userIds,
          action: 'create',
        });
      }
      const promises = [];
      if (array.length > 0) {
        promises.push(this.updateAssigneeIdsProject(array));
      }
      if (projectAdditionalAssigneeIds.length > 0) {
        promises.push(
          this.updateAdditionalAssigneeIdsProjectAlgolia(
            projectAdditionalAssigneeIds
          )
        );
      }
      return Promise.all(promises);
    },

    dateToValue(a) {
      return a.seconds * 1000 + a.nanoseconds / 1000000;
    },
    sortLatestByDate(list) {
      const listSort = (list || []).slice().sort((a, b) => {
        const aDate = a.date || { nanoseconds: 0, seconds: 0 };
        const bDate = b.date || { nanoseconds: 0, seconds: 0 };
        return (this.dateToValue(aDate) - this.dateToValue(bDate)) * -1;
      });
      return listSort;
    },

    async handleUpdateScheduleChain() {
      const { startDate, endDate, daysSelected } = this.chainObject;
      let doc = {};

      if (this.isMyScheduling) {
        let schedulesByChainId = await this.getSchedulesByChainId();

        if (_.isEmpty(schedulesByChainId)) return;

        schedulesByChainId = this.sortLatestByDate(schedulesByChainId);

        doc = {
          startDate: schedulesByChainId[schedulesByChainId.length - 1].date,
          endDate: schedulesByChainId[0].date,
        };
      } else {
        if (this.editType === 'thisShiftAndAllRemaining') {
          doc = {
            daysSelected,
            endDate: this.convertDateToTimestamp(endDate[0]),
          };
        } else if (this.editType === 'allShifts') {
          doc = {
            daysSelected,
            startDate: this.convertDateToTimestamp(startDate[0]),
            endDate: this.convertDateToTimestamp(endDate[0]),
          };
        }
      }

      return this.updateChain({
        id: this.chain.id,
        doc,
      });
    },

    getSchedulesByChainId() {
      if (this.chain.id) {
        return this.getScheduleListBys([
          {
            prop: 'scheduleChainId',
            val: this.chain.id,
            op: '==',
          },
        ]);
      }
      return [];
    },

    // update due date when due date < end date
    updateDueDateOfObject() {
      if (this.object.projectId) {
        this.updateDueDate({
          projectId: this.object.projectId,
          endDate: moment(this.chainObject.endDate[0]),
        });
      }
    },

    editThisShift() {
      let promises = [];

      const data = this.handleDataBeforeEdit().map(r => ({
        ...r,
        scheduleChainId: this.chain.id,
      }));
      let currentSchedules = this.schedulesByChainIdAndDateAndUser.map(r => ({
        userId: r.userId,
        projectId: r.projectId,
        startTime: r.startTime,
        finishTime: r.finishTime,
        notes: r.notes,
        color: r.color,
        timeLogType: r.timeLogType,
        timeOffType: r.timeOffType,
        title: r.title,
        date: r.date,
        scheduleChainId: r.scheduleChainId,
      }));
      let newSchedules = _.cloneDeep(data);

      let removeSchedules = _.differenceWith(
        currentSchedules,
        newSchedules,
        _.isEqual
      );
      let addSchedules = _.differenceWith(
        newSchedules,
        currentSchedules,
        _.isEqual
      );
      let updateSchedules = _.differenceWith(
        currentSchedules,
        removeSchedules,
        _.isEqual
      );

      // delete schedule
      removeSchedules.length > 0 &&
        removeSchedules.forEach(r => {
          const remove = this.schedulesByChainIdAndDateAndUser.find(
            current =>
              moment(current.date.toDate()).isSame(
                moment(r.date.toDate(), 'day')
              ) && current.userId === r.userId
          );
          promises.push(this.deleteSchedule(remove.id));
          this.deleteReminderEmailNotificationBySchedule(remove.id);
        });

      // create schedule
      addSchedules.length > 0 &&
        addSchedules.forEach(r => {
          promises.push(
            new Promise((resolve, reject) => {
              this.createSchedule(r)
                .then(id => {
                  this.addRemindScheduleStartEmailNotification({
                    ...r,
                    id: id,
                  });
                  resolve(id);
                })
                .catch(err => reject(err));
            })
          );
        });

      // update schedule
      updateSchedules.length > 0 &&
        updateSchedules.forEach(r => {
          const update = this.schedulesByChainIdAndDateAndUser.find(
            current =>
              moment(current.date.toDate()).isSame(
                moment(r.date.toDate(), 'day')
              ) && current.userId === r.userId
          );
          promises.push(
            this.updateSchedule({
              id: update.id,
              doc: r,
            })
          );
          if (this.isSendEmail) {
            this.updateReminderEmailNotification({ ...r, id: update.id });
          }
        });

      promises.push(this.updateDueDateOfObject());

      return Promise.all(promises);
    },

    async editSingleShift() {
      let promises = [];

      const data = this.handleDataBeforeEdit();
      let scheduleChainId = null;
      if (
        !moment(this.chainObject.startDate[0]).isSame(
          moment(this.chainObject.endDate[0])
        )
      ) {
        scheduleChainId = await this.createChain({
          ...this.chainObject,
          startDate: this.convertDateToTimestamp(this.chainObject.startDate[0]),
          endDate: this.convertDateToTimestamp(this.chainObject.endDate[0]),
        });
      }
      let currentSchedules = _.cloneDeep([
        {
          userId: this.schedule.userId,
          projectId: this.schedule.projectId,
          startTime: this.schedule.startTime,
          finishTime: this.schedule.finishTime,
          notes: this.schedule.notes,
          color: this.schedule.color,
          timeLogType: this.schedule.timeLogType,
          timeOffType: this.schedule.timeOffType,
          title: this.schedule.title,
          date: this.convertDateToTimestamp(this.schedule.date),
        },
      ]);
      let newSchedules = _.cloneDeep(data);
      let removeSchedules = _.differenceWith(
        currentSchedules,
        newSchedules,
        _.isEqual
      );
      let addSchedules = _.differenceWith(
        newSchedules,
        currentSchedules,
        _.isEqual
      );
      let updateSchedules = _.differenceWith(
        currentSchedules,
        removeSchedules,
        _.isEqual
      );

      // delete schedule
      removeSchedules.length > 0 &&
        promises.push(this.deleteSchedule(this.scheduleId));
      removeSchedules.length > 0 &&
        this.deleteReminderEmailNotificationBySchedule(this.scheduleId);

      // create schedule
      addSchedules.length > 0 &&
        addSchedules.forEach(r => {
          promises.push(
            new Promise((resolve, reject) => {
              let scheduleData = { ...r };
              if (scheduleChainId)
                scheduleData.scheduleChainId = scheduleChainId;
              this.createSchedule(scheduleData)
                .then(id => {
                  this.addRemindScheduleStartEmailNotification({
                    ...scheduleData,
                    id: id,
                  });
                  resolve(id);
                })
                .catch(err => reject(err));
            })
          );
        });

      // update schedule
      updateSchedules.length > 0 &&
        updateSchedules.forEach(r => {
          let scheduleData = { ...r };
          if (scheduleChainId) scheduleData.scheduleChainId = scheduleChainId;
          promises.push(
            this.updateSchedule({
              id: this.scheduleId,
              doc: scheduleData,
            })
          );

          if (this.isSendEmail) {
            this.updateReminderEmailNotification({
              id: this.scheduleId,
              ...scheduleData,
            });
          }
        });
      promises.push(this.updateDueDateOfObject());

      return Promise.all(promises);
    },

    editReminingShifts() {
      let promises = [];

      promises.push(this.updateDueDateOfObject());

      // update schedule and create schedule and remove schedule
      const data = this.handleDataBeforeEdit().map(r => ({
        ...r,
        scheduleChainId: this.chain.id,
      }));

      let currentSchedules = _.cloneDeep(
        this.schedulesByChainIdAndDateAndUser.map(r => ({
          userId: r.userId,
          projectId: r.projectId,
          startTime: r.startTime,
          finishTime: r.finishTime,
          notes: r.notes,
          date: r.date,
          color: r.color,
          timeLogType: r.timeLogType,
          timeOffType: r.timeOffType,
          title: r.title,
          scheduleChainId: r.scheduleChainId,
        }))
      );
      let newSchedules = _.cloneDeep(data);

      let removeSchedules = _.differenceWith(
        currentSchedules,
        newSchedules,
        _.isEqual
      );
      let addSchedules = _.differenceWith(
        newSchedules,
        currentSchedules,
        _.isEqual
      );
      let updateSchedules = _.differenceWith(
        currentSchedules,
        removeSchedules,
        _.isEqual
      );

      // delete schedule
      removeSchedules.length > 0 &&
        removeSchedules.forEach(r => {
          const remove = this.schedulesByChainIdAndDateAndUser.find(
            current =>
              moment(current.date.toDate()).isSame(
                moment(r.date.toDate(), 'day')
              ) && current.userId === r.userId
          );
          promises.push(this.deleteSchedule(remove.id));
          this.deleteReminderEmailNotificationBySchedule(remove.id);
        });

      // create schedule
      addSchedules.length > 0 &&
        addSchedules.forEach(r => {
          promises.push(
            new Promise((resolve, reject) => {
              this.createSchedule(r)
                .then(id => {
                  this.addRemindScheduleStartEmailNotification({
                    ...r,
                    id: id,
                  });
                  resolve(id);
                })
                .catch(err => reject(err));
            })
          );
        });

      // update schedule
      updateSchedules.length > 0 &&
        updateSchedules.forEach(r => {
          const update = this.schedulesByChainIdAndDateAndUser.find(
            current =>
              moment(current.date.toDate()).isSame(
                moment(r.date.toDate(), 'day')
              ) && current.userId === r.userId
          );
          promises.push(
            this.updateSchedule({
              id: update.id,
              doc: r,
            })
          );
          if (this.isSendEmail) {
            this.updateReminderEmailNotification({ ...r, id: update.id });
          }
        });

      return Promise.all(promises).then(() => {
        this.handleUpdateScheduleChain();
      });
    },

    async edit() {
      const { startDate, endDate, daysSelected } = this.chainObject;
      this.$refs.scheduleDetail.v$.$touch();
      if (
        this.$refs.scheduleDetail.v$.$invalid ||
        (!moment(startDate[0]).isSame(moment(endDate[0])) &&
          !this.$refs.scheduleDetail.isShowSelectDay() &&
          daysSelected.length === 0)
      ) {
        this.isInvalid = true;
        return;
      } else {
        this.$f7.preloader.show();
        this.isInvalid = false;
        const { timeLogType, projectId, timeOffType, title } = this.object;
        // clean data before add
        this.object = {
          ...this.object,
          projectId: timeLogType === TIME_LOG_TYPE_PROJECT ? projectId : null,
          timeOffType:
            timeLogType === TIME_LOG_TYPE_TIME_OFF ? timeOffType : null,
          title: timeLogType === TIME_LOG_TYPE_OTHERS ? title : '',
        };
        // clean data before add
        this.chainObject.daysSelected =
          !this.$refs.scheduleDetail.isShowSelectDay() ? daysSelected : [];

        const oldSchedule = _.cloneDeep(this.schedule);
        // let schedulesByOldChainId = await this.getSchedulesByChainId();

        const usersInValidChain = await this.validateForEditChain();
        if (usersInValidChain.length >= 1) {
          this.$f7.preloader.hide();
          this.showOverlapErrorDialog(usersInValidChain);
          return;
        }

        if (this.editType === 'thisShift') {
          await this.editThisShift();
        } else if (
          this.editType === 'thisShiftAndAllRemaining' ||
          this.editType === 'allShifts'
        ) {
          await this.editReminingShifts();
        } else {
          await this.editSingleShift();
        }

        await this.handleUpdateAssigneeIds(oldSchedule);
        this.cancel();
        this.$f7.preloader.hide();
        //send email notification
        if (this.isSendEmail) {
          this.sendUpdatedEmailNotification(oldSchedule);
        }
      }
    },
  },

  computed: {
    ...mapGetters('scheduling/chain', ['chain']),
    ...mapGetters('scheduling/user', ['userById', 'userByIds']),
    ...mapGetters('setting/app/profile', ['currentUser']),
    ...mapGetters('scheduling/scheduling', [
      'selectedItem',
      'scheduleId',
      'schedule',
      'editType',
      'isShowDetail',
      'schedulesByChainIdAndDateAndUser',
      'userIdsByChainIdAndDate',
      'projectIdsByChainIdAndDate',
      'isMyScheduling',
    ]),
  },
};
</script>
