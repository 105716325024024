<template>
  <div>
    <div class="data-table">
      <data-table
        :headers="headers"
        :items="items"
        :pageSize="items.length"
      >
        <template v-slot:body="{ item, index }">
          <td
            v-for="h in headers"
            :key="h.id"
            class="text-align-left"
          >
            <slot
              v-if="!!$scopedSlots[h[field]]"
              :name="h[field]"
              :item="item || {}"
              :index="index"
            >
            </slot>
            <slot
              v-else-if="$scopedSlots.tdCommon"
              :item="item || {}"
              :index="index"
              :colunm="h"
              name="tdCommon"
            ></slot>
            <span v-else>{{ item[h[field]] }}</span>
          </td>
        </template>
      </data-table>
    </div>
  </div>
</template>
<script>
import DataTable from '@/components/datatables';
export default {
  props: {
    headers: Array,
    items: Array,
    field: {
      type: String,
      default: () => 'value',
    },
  },
  components: {
    DataTable,
  },
};
</script>
