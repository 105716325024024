<template>
  <div
    :class="`container ${$device.desktop ? 'desktop' : 'mobile'}`"
    @click="openSelectPhotos"
  >
    <div class="content">
      <f7-icon
        f7="photo"
        class="icon-no-data"
        :style="`font-size: ${$device.desktop ? '100px' : '50px'}`"
      ></f7-icon>
      <span style="font-size: 16px">Select photos</span>
    </div>

    <select-project-photo-popup
      ref="selectPhotos"
      :selectedPhotos="selectedPhotos"
      :isUploadVideo="false"
      @change="onSelectedPhoto"
    ></select-project-photo-popup>
  </div>
</template>

<script>
import SelectProjectPhotoPopup from '../popups/SelectProjectPhotoPopup.vue';

export default {
  data() {
    return {};
  },
  props: {
    projectId: { type: String, default: '' },
    selectedPhotos: { type: Array, default: () => [] },
  },
  components: {
    SelectProjectPhotoPopup,
  },
  methods: {
    onEdit() {
      //this.$emit("onDelete",this.id);
    },
    async openSelectPhotos() {
      let photo = await this.$refs.selectPhotos.openPopup(this.projectId);
      this.onSelectedPhoto(photo);
    },
    onSelectedPhoto(photos) {
      this.$emit('onSelectPhotos', photos);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  border-width: 2px;
  border-style: dashed;
  border-color: var(--f7-color-bg-12-neutral);
  background: var(--f7-color-bg-11-neutral);
  border-radius: 5px;
  display: grid;
  place-items: center;
  color: rgb(104, 101, 101);
  cursor: pointer;
}
.desktop {
  width: 200px;
  height: 200px;
  margin: 10px;
}
.mobile {
  width: 170px;
  height: 170px;
  margin: 10px;
}
.content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container:hover {
  background-color: var(--f7-color-week-hover-neutral);
}
.icon-no-data {
  color: var(--f7-color-icon-neutral);
}
</style>
