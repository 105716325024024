import { evaluate } from 'mathjs';
import _ from 'lodash';

const stretchOutFormula = {
  collectorHead: '(X W)*2',
  embedded: 'a1 b1 d2',
  copingWithCleatFastener: '2*(f1) a1 sqrt((b1)^2 (c1)^2) d1 2*(e1)',
  copingWithCleat: '2*(f1) a1 sqrt(b1^2 (c1)^2) d1 2*(e1)',
  copingWithFrontBackCleat: '2*(f1) a1 sqrt((b1)^2 (c1)^2) d1 2*(e1)',
  downspoutTransition: '2*(A) 2*(B)',
  boxDownspout: '2*(A) 2*(B)',
  gutterSystem: 'H F A B (C - A) D E',
  headwallFlashing: 'A B C',
  pitchPan: '2*(B) 2*(D) min((A),(C))',
  counterFlashing: 'A B C D E',
  valley: '',
  sidewallOrHeadwall: 'A B C',
  ridge: 'A B',
  zeeTrim: 'A B C',
  extendedEaveOrRake: 'a1 b1 c1 d1',
};

const cleatstretchOutFormula = {
  collectorHead: '',
  embedded: 'a2 (b2-d2) c2 d2',
  copingWithCleatFastener: 'a2 b2',
  copingWithCleat: 'a2 b2 c2',
  copingWithFrontBackCleat: 'a2 b2 c2',
  downspoutTransition: '',
  boxDownspout: '',
  gutterSystem: '',
  headwallFlashing: '',
  pitchPan: '',
  counterFlashing: '',
  valley: '',
  sidewallOrHeadwall: '',
  ridge: '',
  zeeTrim: '',
  extendedEaveOrRake: 'a2 b2 c2',
};

const backCleatstretchOutFormula = {
  collectorHead: '',
  embedded: '',
  copingWithCleatFastener: '',
  copingWithCleat: '',
  copingWithFrontBackCleat: 'a3 b3 c3',
  downspoutTransition: '',
  boxDownspout: '',
  gutterSystem: '',
  headwallFlashing: '',
  pitchPan: '',
  counterFlashing: '',
  valley: '',
  sidewallOrHeadwall: '',
  ridge: '',
  zeeTrim: '',
  extendedEaveOrRake: '',
};

function getStretchOut(value) {
  let stretchOut = 0;
  let expression = stretchOutFormula[value] || '';
  Object.keys(this).forEach(key => {
    expression = expression.replaceAll(key, this[key] || '');
  });
  let parseValue = convertStringToExpression(expression);
  stretchOut = evaluate(parseValue);
  stretchOut = roundValue(stretchOut);
  return convertDecimalToString(stretchOut);
}

function getCleatStretchOut(value) {
  let cleatStretchOut = 0;
  let expression = cleatstretchOutFormula[value] || '';
  Object.keys(this).forEach(key => {
    expression = expression.replaceAll(key, this[key] || '');
  });
  let parseValue = convertStringToExpression(expression);
  cleatStretchOut = evaluate(parseValue);
  cleatStretchOut = roundValue(cleatStretchOut);
  return convertDecimalToString(cleatStretchOut);
}

function getBackCleatStretchOut(value) {
  let cleatStretchOut = 0;
  let expression = backCleatstretchOutFormula[value] || '';
  Object.keys(this).forEach(key => {
    expression = expression.replaceAll(key, this[key] || '');
  });
  let parseValue = convertStringToExpression(expression);
  cleatStretchOut = evaluate(parseValue);
  cleatStretchOut = roundValue(cleatStretchOut);
  return convertDecimalToString(cleatStretchOut);
}

function roundValue(value) {
  //round value to 1/8
  return Math.round(value * 8) / 8;
}

function convertStringToValue(stringValue) {
  let expression = convertStringToExpression(stringValue);
  return evaluate(expression);
}
function convertStringToExpression(string) {
  let parseValue = _.cloneDeep(string || '').replace(/\s/g, '+');
  parseValue = parseValue.replace(/"/g, '');
  let arr = parseValue.split('+');
  arr = arr.map(r => {
    if (r == '') {
      return 0;
    } else {
      return r.replace(/'/g, '*12');
    }
  });
  parseValue = arr.join('+');
  return parseValue;
}

// check expesstion is valid
function isValidExpresstion(value) {
  try {
    let val = convertStringToExpression(value);
    evaluate(val);
    return true;
  } catch (err) {
    return false;
  }
}

// convert decimal to string
function convertDecimalToString(decimal) {
  if (Number.isNaN(decimal)) return '0';
  const t = Math.floor(decimal);
  let m = decimal - t;
  let val = '';
  if (m == 0) {
    val = `${decimal}`;
  } else if (t == 0) {
    val = convertDecimalToFraction(m);
  } else {
    val = `${t} ${convertDecimalToFraction(m)}`;
  }
  return val;
}

// convert decimal to fraction
function convertDecimalToFraction(decimal) {
  const arr = decimal.toString().split('.');
  if (arr.length > 1) {
    let m = Math.pow(10, arr[1].length);
    const ucln = UCLN(decimal * m, m);
    return `${(decimal * m) / ucln}/${m / ucln}`;
  }
  return decimal;
}

function UCLN(x, y) {
  while (x != y) {
    if (x > y) x = x - y;
    else y = y - x;
  }
  return x;
}

export {
  convertStringToValue,
  getCleatStretchOut,
  getBackCleatStretchOut,
  getStretchOut,
  isValidExpresstion,
};
