import { FirebaseActions } from '@/services/firebase.service';
import * as types from './types';
import { searchClient } from '../../../../services/search.service';
import dashboardService from '../../../../services/dashboard.service';
import algoliaService from '../../../../services/algolia.service';
import {
  COLLECTION_OPRATION_CARD,
  OPERATION_CARD_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
  OPERATION_CARD_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
  PROP_IS_DELETED,
} from '../../../../utility/const';

export default tenant => {
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    'project'
  );

  const dashboardActions = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    'action'
  );

  const projectIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  const contactActions = new FirebaseActions(
    `/system_client/${tenant}/operation_contact`,
    'contact'
  );
  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    'company'
  );
  const propertyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_property`,
    'property'
  );

  return {
    getContact: contactActions.getDocument,
    getCompany: companyActions.getDocument,
    getProperty: propertyActions.getDocument,

    getProjectItem: projectActions.getDocument,
    getProjectBys: projectActions.getDocumentBys,

    getCardInColumn({ dispatch }, columnId) {
      return dispatch('getProjectBys', [
        {
          prop: 'columnId',
          op: '==',
          val: columnId,
        },
      ]);
    },

    getProject({ dispatch, commit }, id) {
      return dispatch('getProjectItem', id).then(res => {
        return commit(types.PROJECT, res);
      });
    },
    setProjectId({ commit }, id) {
      commit(types.SET_PROJECT_ID, id);
    },

    getActionListBys: dashboardActions.getDocumentBys,
    getActionList({ dispatch, commit }) {
      return dispatch('getActionListBys', [
        {
          prop: 'isDeleted',
          op: '==',
          val: false,
        },
      ]).then(res => {
        return commit(types.ACTION_LIST, res);
      });
    },

    setProjectFilterField({ commit }, { field, value }) {
      commit(types.SET_PROJECT_FILTER_FIELD, { field, value });
    },

    setSearchText({ commit }, searchText = '') {
      commit(types.SET_SEARCH_TEXT, searchText);
    },

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchProject(
      { state, commit, dispatch, getters },
      { attributesToRetrieve }
    ) {
      const index = projectIndexDesc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${COLLECTION_OPRATION_CARD}`,
        attributesToRetrieve: OPERATION_CARD_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          OPERATION_CARD_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (getters.queryFilters) {
        requestOptions.filters += ` AND ${getters.queryFilters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(async result => {
          result.hits = await dispatch(
            'calcTotalPhotosOfProjects',
            result.hits
          );
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreProject(
      { state, commit, dispatch, getters },
      { attributesToRetrieve, page }
    ) {
      const index = projectIndexDesc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${COLLECTION_OPRATION_CARD}`,
        attributesToRetrieve: OPERATION_CARD_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          OPERATION_CARD_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (getters.queryFilters) {
        requestOptions.filters += ` AND ${getters.queryFilters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(async result => {
          result.hits = await dispatch(
            'calcTotalPhotosOfProjects',
            result.hits
          );
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    async calcTotalPhotosOfProjects({ dispatch }, projects) {
      const promises = [];
      for (const project of projects) {
        promises.push(
          dispatch(
            'photo/photo/getProjectPhotosSizeBys',
            [
              {
                prop: 'projectId',
                val: project.id,
                op: '==',
              },
              {
                prop: PROP_IS_DELETED,
                val: false,
                op: '==',
              },
            ],
            { root: true }
          )
        );
      }
      const resTotal = await Promise.all(promises);
      for (let i = 0; i < projects.length; i++) {
        projects[i].totalPhotos = resTotal[i];
      }
      return projects;
    },

    setProjectNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },

    setProjectSearchOrder({ commit }, payload) {
      commit(types.SET_SEARCH_ORDER, payload);
    },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    // eslint-disable-next-line no-unused-vars
    async createNewProject({ dispatch, commit, getters, rootGetters }, data) {
      const user = rootGetters['setting/app/profile/user'];
      if (data.contactId) {
        const contact = await dispatch('getContact', data.contactId);
        data.contactName = contact
          ? ((contact.firstName || '') + ' ' + (contact.lastName || '')).trim()
          : '';
      }
      if (data.companyId) {
        const company = await dispatch('getCompany', data.companyId);
        data.companyName = company ? company.companyName || '' : '';
      }
      if (data.propertyId) {
        const property = await dispatch('getProperty', data.propertyId);

        data.propertyName = property ? property.propertyName || '' : '';
        data.propertyAddress =
          (property.addresses || []).find(r => r.code === 'main') ||
          (property.addresses || [])[0] ||
          {};
      }
      const doc = await dashboardService.addCardDoc(user.tenantId, data);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: 'operation_card',
        id: doc.id,
      });
      await dispatch('searchProject', {});
      return doc;
    },

    updateTotalPhotosProject({ commit }, { id, action }) {
      return commit(types.PROJECT_LIST_ITEM_UPDATE_TOTAL_PHOTO, {
        id,
        action,
      });
    },

    resetProject({ commit }) {
      commit(types.RESET_DATA);
    },
  };
};
