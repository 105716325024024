import * as constant from '../../../../utility/const';

export default {
  assemblyDrawing: state => state.assemblyDrawing,

  assemblyDrawingList: state =>
    state.assemblyDrawingList.filter(
      item => item.status === constant.STATUS_ASSEMBLY_DRAWING_ACTIVE
    ),

  assemblyDrawingListByRoofType: state => roofType => {
    return state.assemblyDrawingList.filter(
      item =>
        item.status === constant.STATUS_ASSEMBLY_DRAWING_ACTIVE &&
        item.roofType === roofType
    );
  },

  assemblyDrawingById: (state, getters) => id =>
    getters.assemblyDrawingList.find(item => item.id === id) || {},
};
