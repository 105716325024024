<template>
  <f7-popup
    class="demo-popup"
    :opened="isShow"
    @popup:closed="handlePopupClosed"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="cancel()">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{
            dataUrls.length > 0 && !isShowCamera
              ? `Selected ${selectedPhotos.length} photos`
              : 'Take Photos'
          }}
        </f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="save()">{{
            isShowCamera ? `Done` : `Upload`
          }}</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <fullscreen-view-camera
        v-show="isShowCamera"
        @approve="picture => {}"
        @loading="loading => {}"
        @snap="handleTakePhoto"
        @close="() => {}"
        :fullscreen-z-index="17"
        :must-approve="false"
        overlay-mask=""
        :start-on-mounted="false"
        :visible-overlay="false"
        ref="fullscreenCameraRef"
      >
        <template v-slot:header>
          <slot name="header">
            <div>Take Photos</div>
          </slot>
        </template>
      </fullscreen-view-camera>

      <div v-show="!isShowCamera">
        <div
          ref="imgsContainer"
          v-if="dataUrls.length > 0"
        >
          <f7-block class="margin-top-half block-image">
            <div class="img-grid">
              <div
                v-for="photo in dataUrls"
                :key="photo.id"
              >
                <div
                  class="img-item"
                  @click.stop="clickPhoto(photo.id)"
                >
                  <img
                    class="image-show"
                    :src="photo.dataUrl"
                  />

                  <div class="img-bg">
                    <div class="img-bg-content">
                      <f7-checkbox
                        style="
                          position: absolute;
                          top: 10%;
                          left: 10%;
                          transform: scale(1.5);
                        "
                        :checked="selectedPhotos.includes(photo.id)"
                        @change="clickPhoto(photo.id)"
                      ></f7-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </f7-block>
        </div>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import FullscreenViewCamera from '@/components/camera/FullscreenViewCamera.vue';
import ImageTools from '../../../../utility/image-tools';
import { uuid } from 'vue-uuid';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { FullscreenViewCamera },

  props: {
    albumId: String,
  },

  data: () => ({
    isShow: false,
    isShowCamera: true,
    dataUrls: [],
    numberOfPhotos: 0,
    photoPromises: [],
    selectionMode: true,
    hoveredPhotoIds: [],
    selectedPhotos: [],
  }),

  computed: {
    ...mapGetters('photo/project', ['project']),
  },

  methods: {
    ...mapActions('photo/photo', ['uploadPhoto', 'createProjectPhoto']),

    handleTakePhoto() {
      const promise = new Promise((resolve, reject) => {
        this.$refs.fullscreenCameraRef.snapAsBlob().then(file => {
          if (file && file instanceof Blob) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.dataUrls.push({
                file,
                dataUrl: reader.result,
                id: uuid.v4(),
              });
              resolve();
            };
            reader.onerror = reject;
          } else {
            resolve();
          }
        });
      });
      this.photoPromises.push(promise);
      return promise;
    },

    async open() {
      this.isShowCamera = true;
      this.isShow = true;
      const camera = await this.$refs.fullscreenCameraRef.start();
      if (camera) {
        camera.start();
      }
    },

    async processUploadPhoto({ file, imgTool }) {
      if (
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/webp'
      ) {
        let photo = {};
        const name = file.name || `${uuid.v4()}.${file.type.split('/')[1]}`;
        file.name = name;
        const thumbnailTemp = await imgTool.resize(file, {
          width: 256,
          height: 256,
        });
        const thumbnailUploaded = await this.uploadPhoto({
          file: thumbnailTemp,
          projectId: this.project.id,
        });
        photo = {
          thumbnailUrl: thumbnailUploaded.url,
          thumbnailFullPath: thumbnailUploaded.fullPath,
        };
        const photoTemp = await imgTool.resize(file, {});

        const photoUploaded = await this.uploadPhoto({
          file: photoTemp,
          projectId: this.project.id,
        });

        photo = {
          ...photo,
          projectId: this.project.id,
          photoUrl: photoUploaded.url,
          photoName: photoTemp.name || name,
          albumId: this.albumId ? this.albumId : null,
          photoFullPath: photoUploaded.fullPath,
        };

        await this.createProjectPhoto(photo);
      }
    },

    async save() {
      if (this.isShowCamera) {
        this.$refs.fullscreenCameraRef.stop();
        this.isShowCamera = false;
        this.$f7.preloader.show();
        await Promise.all(this.photoPromises);
        this.$f7.preloader.hide();
        this.selectedPhotos = this.dataUrls.map(photo => photo.id);
      } else {
        this.$f7.preloader.show();
        const imgTool = new ImageTools(this.$f7.device);
        const batchSize = 5;
        for (let i = 0; i < this.dataUrls.length; i += batchSize) {
          let batch = this.dataUrls.slice(i, i + batchSize);
          let uploadPromises = batch
            .filter(rawData => this.selectedPhotos.includes(rawData.id))
            .map(rawData => {
              const file = rawData.file;
              return this.processUploadPhoto({ file, imgTool });
            });
          await Promise.all(uploadPromises);
        }
        this.$f7.preloader.hide();
        this.isShow = false; // close popup
      }
    },

    handlePopupOpened() {},

    handlePopupClosed() {
      this.resetData();
    },

    resetData() {
      this.dataUrls = [];
      this.selectedPhotos = [];
    },

    async cancel() {
      await this.$refs.fullscreenCameraRef.stop();
      this.isShow = false;
    },

    clickPhoto(id) {
      if (this.selectedPhotos.includes(id)) {
        this.selectedPhotos = this.selectedPhotos.filter(
          photoId => photoId !== id
        );
      } else {
        this.selectedPhotos.push(id);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 20px;
}
.img-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  cursor: pointer;
  background-position: 50% center;
  background: white;
  border-radius: 4%;
}
.img-bg {
  position: absolute;
  border-radius: 4px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.img-bg-select {
  transition: all 150ms ease-in-out 0s;
  background: rgba(0, 0, 0, 0.3);
}
.img-item:hover .img-bg-not-select {
  background: rgba(0, 0, 0, 0.2);
}
.img-bg-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 1;
}
.img-title {
  font-size: 12px;
}
.edit-button {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 24px;
  text-align: center;
  color: white;
  font-size: 12px;
  background: #6666667a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.play-button {
  position: absolute;
  width: 100%;
  top: 34%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-button:hover {
  font-weight: 600;
  background: #0808087a;
}

.image-show {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: fill;
  border-radius: 4%;
}
.input-date-filter {
  display: flex;
  align-items: center;
  height: 28px;
  border: 1px solid #cfcfcf;
  border-radius: 22px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--f7-md-surface);
  gap: 8px;
  box-sizing: border-box;
}
.error-message {
  font-size: var(--f7-list-item-text-font-size);
  font-weight: var(--f7-list-item-text-font-weight);
  color: red;
}
.checkbox ::v-deep i {
  border-color: white;
}
.custom-navbar ::v-deep .navbar-bg {
  background-color: black;
}
.block-image {
  padding: 0 20px 0 20px;
}
</style>
