import axiosService from '@/services/axios.service';
import { firebase, auth } from '../../../services/firebase.service';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import {
  STATUS_ACTION_REQUIRED_DONE,
  STATUS_ACTION_REQUIRED_PENDING,
} from '../../../utility/const';

export default {
  computed: {
    ...mapGetters('dashboard/project', ['filterAction']),
  },
  methods: {
    ...mapActions('dashboard/project', ['setCurrentActionIdsAfterMoveOrAdd']),

    calculateNextActions(project, action, response) {
      let currActionId = action.id;
      let currProjectActionIds = project.actions;
      let nextResActionIds = response.nextActions;

      // remove current action id from project's actions
      if (currProjectActionIds.includes(currActionId)) {
        currProjectActionIds = currProjectActionIds.filter(
          id => id !== currActionId
        );
      }

      // include new action Ids
      currProjectActionIds = currProjectActionIds.concat(
        ['cancel', 'close', 'put-a-lien'].includes(response.nextState)
          ? currActionId
          : nextResActionIds
      );

      //  remove duplicates
      currProjectActionIds = currProjectActionIds.filter(
        (value, index, self) => {
          return self.indexOf(value) === index;
        }
      );

      // save actions after moving the card to scroll top columns of those actions
      this.setCurrentActionIdsAfterMoveOrAdd(currProjectActionIds);

      return currProjectActionIds;
    },

    calculateActionHistory(project, action, response) {
      let actionsHistory =
        project && project.actionsHistory
          ? _.cloneDeep(project.actionsHistory)
          : [];
      response.nextActions.forEach(actionId => {
        actionsHistory.push({
          nextAction: this.filterAction(actionId)
            ? this.filterAction(actionId).title || ''
            : '',
          resolvedAt: firebase.firestore.Timestamp.now(),
          resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
          previousAction: action.title,
        });
      });

      return actionsHistory;
    },

    sendMail(projectTitle, receiver, nextActionsTitle) {
      return axiosService
        .post('/notification/push-email', {
          to: receiver,
          subject: {
            project_title: projectTitle || '',
            next_action: nextActionsTitle,
          },
          template: 'resolve-action',
          content: {
            project_title: projectTitle || '',
            next_action: nextActionsTitle,
            user_display_name: auth.currentUser.displayName,
          },
          attachments: [],
          scheduleSendingTime: '',
        })
        .then(() => {
          // TODO: this trigger will be removed when implement email mail service scheduler
          return axiosService.post('/notification/trigger-email-service', {
            sender: '',
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('[Send Email]', error);
          throw new Error(error.message);
        });
    },

    calculateActionStatus(project, action) {
      const status = this.actionStatus(project, action);
      let actionsStatus =
        project && project.actionsStatus
          ? _.cloneDeep(project.actionsStatus)
          : [];
      actionsStatus = actionsStatus.filter(r => r.actionId !== action.id);
      actionsStatus.push({
        actionId: action.id,
        status,
      });

      return actionsStatus;
    },

    getAttachmentFilesOfCardByType(project, type) {
      return (project.attachmentFiles || []).filter(
        r => r.attachmentType === type
      );
    },

    actionStatus(project, action) {
      switch (action.componentName) {
        case 'ContactLeadAction': {
          const inValidTaxExempt =
            project.taxExempt &&
            !this.getAttachmentFilesOfCardByType(project, 'taxExempt').length;
          const inValidResalesCertificate =
            project.resalesCertificate &&
            !this.getAttachmentFilesOfCardByType(project, 'resalesCertificate')
              .length;
          if (inValidTaxExempt || inValidResalesCertificate) {
            return STATUS_ACTION_REQUIRED_PENDING;
          }
          return STATUS_ACTION_REQUIRED_DONE;
        }
        case 'EstimateAction': {
          const inValidManufacturerVerbal =
            project.manufacturerVerbal &&
            !this.getAttachmentFilesOfCardByType(project, 'manufacturerVerbal')
              .length;
          const inValidRequiredSystemLetter =
            project.requiredSystemLetter &&
            !this.getAttachmentFilesOfCardByType(
              project,
              'requiredSystemLetter'
            ).length;
          if (inValidManufacturerVerbal || inValidRequiredSystemLetter) {
            return STATUS_ACTION_REQUIRED_PENDING;
          }
          return STATUS_ACTION_REQUIRED_DONE;
        }
        default: {
          return STATUS_ACTION_REQUIRED_DONE;
        }
      }
    },
  },
};
