import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const ClassActions = new FirebaseActions(
    `/system_client/${tenant}/class_QB`,
    'classQB'
  );
  const ServiceItemActions = new FirebaseActions(
    `/system_client/${tenant}/service_item_QB`,
    'serviceItemQB'
  );

  return {
    bindClassQBList: ClassActions.bindCollection,
    unbindClassQBList: ClassActions.unbindCollection,

    getClassQBListBys: ClassActions.getDocumentBys,
    async getClassQBList({ commit, dispatch }) {
      const classList = await dispatch('getClassQBListBys', [
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);
      await commit(types.SET_CLASS_QB_LIST, classList);
      return classList;
    },

    bindServiceItemQBList: ServiceItemActions.bindCollection,
    unbindServiceItemQBList: ServiceItemActions.unbindCollection,

    getServiceItemQBListBys: ServiceItemActions.getDocumentBys,
    async getServiceItemQBList({ commit, dispatch }) {
      const serviceItemList = await dispatch('getServiceItemQBListBys', [
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);
      await commit(types.SET_SERVICE_ITEM_QB_LIST, serviceItemList);
      return serviceItemList;
    },
  };
};
