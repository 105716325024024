<template>
  <f7-page class="proposal-list">
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Proposal/Contract</f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-show="proposalType === 'proposal'"
          icon-f7="plus"
          @click.native="openNewproposalPopup"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <div class="client-type">
      <div class="display-flex align-items-center">
        <f7-radio
          value="proposal"
          name="proposalType"
          class="margin-right-half"
          :checked="proposalType === 'proposal'"
          @change="onChangeProposalType('proposal')"
        ></f7-radio
        >Proposal
      </div>
      <div class="display-flex align-items-center">
        <f7-radio
          value="contract"
          name="proposalType"
          class="margin-right-half"
          :checked="proposalType === 'contract'"
          @change="onChangeProposalType('contract')"
        ></f7-radio
        >Roofing Build Contract
      </div>
    </div>
    <!-- <div class="search-fixed-header"> -->
    <div class="display-flex">
      <f7-searchbar
        style="flex: 1"
        disable-button-text
        :placeholder="`Search ${proposalType === 'proposal' ? 'proposal' : 'contract'}`"
        :clear-button="true"
        class="search-list"
        @input="
          setProposalSearchText($event.target.value.trim());
          onSearch();
        "
        @keypress.native.enter="onSearch()"
        @searchbar:clear="
          setProposalSearchText('');
          onSearch();
        "
        @searchbar:disable="
          setProposalSearchText('');
          onSearch();
        "
        :value="searchText"
      >
        <f7-button
          :style="$device.desktop ? '' : 'margin-left: 5px'"
          style="text-overflow: inherit"
          popover-open=".popover-menu-filter"
        >
          <f7-icon f7="funnel"></f7-icon> </f7-button
      ></f7-searchbar>
      <f7-popover :class="`popover-menu-filter`">
        <f7-list>
          <f7-list-item
            v-for="(status, index) in proposalStatusList"
            :key="index"
            popover-close
            :title="status.displayName"
            style="cursor: pointer"
            @change="filterByStatus($event.target.checked, status.value)"
            :checked="selectedStatusFilterComputed.includes(status.value)"
            checkbox
          >
          </f7-list-item>
        </f7-list>
      </f7-popover>
    </div>
    <!-- </div> -->
    <proposal-list
      v-show="proposalType === 'proposal'"
      ref="proposalList"
    ></proposal-list>
    <contract-list
      v-show="proposalType === 'contract'"
      ref="contractList"
    ></contract-list>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Menu from '../../../components/menu/Menu.vue';
import ProposalList from '../components/list/ProposalList.vue';
import ContractList from '../components/list/ContractList.vue';
import _ from 'lodash';

export default {
  components: {
    Menu,
    ProposalList,
    ContractList,
  },

  created() {
    if (_.isEmpty(this.proposalStatusList)) {
      this.bindConstantObjectList();
    }
  },

  mounted() {
    if (this.isChangeProposalTypeAndNavigate) {
      this.setProposalSearchText('');
      this.autoFocusSearchInput();
      this.onSearch();
    }
    this.setIsChangeProposalTypeAndNavigate(false);
  },

  methods: {
    ...mapActions('proposal/proposal', [
      'setProposalSearchText',
      'setProposalType',
      'setProposal',
      'setIsChangeProposalTypeAndNavigate',
      'setSelectedStatusFilter',
    ]),
    ...mapActions('proposal/contract', ['unbindContract']),
    ...mapActions({
      proposalResetSearch: 'proposal/proposal/resetSearch',
      contractResetSearch: 'proposal/contract/resetSearch',
    }),
    ...mapActions('proposal/app-constant', ['bindConstantObjectList']),
    onSearch: _.debounce(function () {
      this.$nextTick(() => {
        if (this.proposalType === 'proposal') {
          this.$refs.proposalList && this.$refs.proposalList.init();
        } else if (this.proposalType === 'contract') {
          this.$refs.contractList && this.$refs.contractList.init();
        }
      });
    }, 500),
    filterByStatus(checked, status) {
      // update checked status for statusFilter
      this.$nextTick(() => {
        if (this.proposalType === 'proposal') {
          this.$refs.proposalList &&
            this.$refs.proposalList.filterByStatus(checked, status);
        } else if (this.proposalType === 'contract') {
          this.$refs.contractList &&
            this.$refs.contractList.filterByStatus(checked, status);
        }
      });
      this.$forceUpdate();
    },

    openNewproposalPopup() {
      this.$f7router.navigate('/proposal/new');
    },

    onChangeProposalType(value) {
      this.setProposalType(value);
      this.setProposal({});
      this.unbindContract();
      let currentPath =
        _.cloneDeep(this.$f7.views.main.router.currentRoute.path) || '';
      if (currentPath !== '/proposal') {
        this.setIsChangeProposalTypeAndNavigate(true);
        if (this.proposalType === 'contract') {
          const proposalNumber =
            this.$f7.views.main.router.currentRoute.params.proposalNumber || '';
          const findItemInHits = this.hits.find(
            e => e.proposalNumber === proposalNumber
          );
          this.$refs.proposalList
            .checkBeforeNavigation(findItemInHits)
            .then(() => {
              this.$f7router.navigate('/proposal', {
                pushState: true,
                reloadAll: true,
              });
            });
        } else {
          this.$f7router.navigate('/proposal', {
            pushState: true,
            reloadAll: true,
          });
        }
      }
      this.setSelectedStatusFilter([]);
      this.$nextTick(() => {
        if (this.proposalType === 'proposal') {
          this.$refs.proposalList && this.$refs.proposalList.removeFilter();
        } else if (this.proposalType === 'contract') {
          this.$refs.contractList && this.$refs.contractList.removeFilter();
        }
      });
    },
    autoFocusSearchInput() {
      if (this.$device.desktop) {
        this.$nextTick(() => {
          this.$el.querySelector('.search-list.searchbar input').focus();
        });
      }
    },
  },

  computed: {
    ...mapGetters('proposal/proposal', [
      'searchText',
      'proposalType',
      'hits',
      'isChangeProposalTypeAndNavigate',
    ]),
    ...mapGetters('proposal/app-constant', ['proposalStatusList']),
    ...mapGetters({
      selectedStatusFilterProposal: 'proposal/proposal/selectedStatusFilter',
    }),
    ...mapGetters({
      selectedStatusFilterContract: 'proposal/contract/selectedStatusFilter',
    }),
    selectedStatusFilterComputed() {
      return this.proposalType === 'proposal'
        ? this.selectedStatusFilterProposal
        : this.selectedStatusFilterContract;
    },
  },

  beforeDestroy() {
    this.setProposalSearchText('');
    this.proposalResetSearch();
    this.contractResetSearch();
  },

  watch: {
    proposalType: {
      handler(val, old) {
        if (val && val !== old && !this.isChangeProposalTypeAndNavigate) {
          this.setProposalSearchText('');
          this.autoFocusSearchInput();
          this.onSearch();
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
.item-text-slot {
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.client-type {
  position: absolute;
  left: 0;
  top: calc(var(--f7-navbar-height) + var(--f7-safe-area-top));
  height: var(--f7-navbar-height);
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}
.proposal-list ::v-deep .page-content {
  padding-top: calc(
    var(--f7-page-navbar-offset, 0px) * 2 +
      var(--f7-page-toolbar-top-offset, 0px) +
      var(--f7-page-subnavbar-offset, 0px) +
      var(--f7-page-searchbar-offset, 0px) +
      var(--f7-page-content-extra-padding-top, 0px)
  );
  overflow: hidden;
}
.list-filter ::v-deep .item-link .item-inner {
  display: none;
}
/* list-filter has a line ::before ane ::after, I want to remove
them */
.list-filter ::v-deep ul::before,
.list-filter ::v-deep ul::after {
  display: none;
}
/* .search-list {
  top: calc(var(--f7-navbar-height) * 2 + var(--f7-safe-area-top)) !important;
} */
</style>
