<template>
  <div>
    <f7-block>
      <f7-row class="justify-content-space-between align-items-center">
        <div>
          <f7-block-title medium>Schedule of value by roof type</f7-block-title>
        </div>
        <div class="display-flex">
          <f7-button
            @click="exportToExcel"
            fill
            class="margin-right-half"
            >Export Excel</f7-button
          >
          <f7-button
            @click="exportToPdf"
            fill
            class="margin-right-half"
            >Export PDF</f7-button
          >
        </div>
      </f7-row>
    </f7-block>
    <div class="data-table">
      <data-table
        :headers="headers"
        :items="(generateSchedule || []).concat(footerMolibization)"
        :pageSize="(generateSchedule || []).length + footerMolibization.length"
        id="generated-results"
      >
        <template v-slot:body="{ item }">
          <template v-if="!item.isFooter">
            <td
              v-for="h in headers"
              :key="h.id"
              class="text-align-left"
            >
              <span v-if="h.field === 'name'">{{ item[h.field] }}</span>
              <span v-else>{{ item[h.field] || 0 | currencyUSD }}</span>
            </td>
          </template>
          <template v-else>
            <td :colspan="headers.length - 2"></td>
            <td>
              <span class="title-footer">
                {{ item.title }}
              </span>
            </td>
            <td>
              <span v-if="item.title == 'Mobilization'">
                {{ item.value | currencyUSD }}
              </span>
              <span v-else>
                {{ item.value | currencyUSD }}
              </span>
            </td>
          </template>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/datatables';
import { mapGetters } from 'vuex';
import exportScheduleOfValue from '../../excel-export';
import exportScheduleOfValuesToPdf from '../../pdf-export';
export default {
  components: {
    DataTable,
  },
  props: {
    isShowGenerate: Boolean,
    mobilization: [String, Number],
  },
  computed: {
    ...mapGetters('schedule/schedule-setting', [
      'generateSchedule',
      'scheduleInfomation',
    ]),
    ...mapGetters('common/schedule-setting', ['scheduleSettingList']),
    ...mapGetters('common/schedule-value', [
      'scheduleValue',
      'scheduleColunms',
    ]),

    headers() {
      let itemsSchedules = (this.scheduleSettingList || []).filter(x =>
        this.scheduleColunms.includes(x.id)
      );
      let headers = (itemsSchedules || []).map(x => {
        let result = {
          text: x.name,
          field: x.id,
          align: 'left',
          type: 'number',
          sortable: false,
          value: x.id,
        };
        return result;
      });

      headers.unshift({
        field: 'name',
        text: '',
        value: 'name',
        sortable: false,
        align: 'left',
        type: 'text',
      });

      headers.push({
        text: 'Total',
        field: 'totals',
        align: 'left',
        type: 'number',
        sortable: false,
        value: 'totals',
      });
      return headers;
    },
    footerTablePDF() {
      let subTotal = 0;
      this.generateSchedule.forEach(x => {
        subTotal += x.totals;
      });
      return [
        {
          name: 'Sub-Total',
          value: subTotal,
        },
        {
          name: 'Mobilization',
          value:
            (this.mobilization / 100) *
            (this.scheduleInfomation[0] || {}).totals,
        },
        {
          name: 'Total',
          value: this.scheduleInfomation[0].totals || 0,
        },
      ];
    },
    footerMolibization() {
      let subTotal = 0;
      this.generateSchedule.forEach(x => {
        subTotal += x.totals;
      });
      return [
        {
          isFooter: true,
          title: 'Sub-Total',
          value: subTotal,
        },
        {
          isFooter: true,
          title: 'Mobilization',
          value:
            (this.mobilization / 100) *
            (this.scheduleInfomation[0] || {}).totals,
        },
        {
          isFooter: true,
          title: 'Total',
          value: (this.scheduleInfomation[0] || {}).totals || 0,
        },
      ];
    },
  },
  methods: {
    exportToExcel() {
      let subTotal = 0;
      this.generateSchedule.forEach(x => {
        subTotal += x.totals;
      });
      exportScheduleOfValue({
        title: this.scheduleValue.title || '',
        headers: this.scheduleSettingList.filter(r =>
          this.scheduleColunms.includes(r.id)
        ),
        values: this.generateSchedule,
        subTotal: subTotal,
        totals: this.scheduleInfomation[0].totals,
        mobilization:
          (this.mobilization / 100) * (this.scheduleInfomation[0] || {}).totals,
      });
    },
    exportToPdf() {
      let subTotal = 0;
      this.generateSchedule.forEach(x => {
        subTotal += x.totals;
      });
      exportScheduleOfValuesToPdf({
        title: this.scheduleValue.title || '',
        headers: this.scheduleSettingList.filter(r =>
          this.scheduleColunms.includes(r.id)
        ),
        values: this.generateSchedule,
        subTotal: subTotal,
        totals: this.scheduleInfomation[0].totals,
        mobilization:
          (this.mobilization / 100) * (this.scheduleInfomation[0] || {}).totals,
      });
    },
  },
};
</script>
<style scoped>
.title-footer {
  font-weight: bold;
}
</style>
