import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const vendorActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    'object'
  );

  return {
    bind: ({ dispatch }) => {
      dispatch('bindBys', [
        {
          prop: 'companyTypes',
          op: 'array-contains',
          val: 'vendor',
        },
      ]);
    },
    bindBys: vendorActions.bindCollectionBys,
    unbind: vendorActions.unbindCollection,
    updateProduct: vendorActions.updateDocument,
    getBys: vendorActions.getDocumentBys,
    create: vendorActions.createDocument,

    async getVendors({ commit, dispatch }) {
      const list = await dispatch('getBys', [
        {
          prop: 'companyTypes',
          op: 'array-contains',
          val: 'vendor',
        },
      ]);
      commit(types.SET_VENDOR_LIST, list);
    },
  };
};
