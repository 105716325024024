import { auth } from './firebase.service';
import axiosService from './axios.service';

const getError = data => {
  if (data.code >= 200 && data.code < 300) {
    throw { message: data.message, name: 'unauthorized' };
  } else if (data.code >= 300) {
    throw {
      message: data.message.error || data.message,
      name: (data.message.error || data.message || '').toString().toLowerCase(),
    };
  }
};

const getAuthUri = async redirectPath => {
  return axiosService
    .get(`/quickbooks/authUri?redirectPath=${redirectPath}`)
    .then(result => result.data.data.authUri)
    .catch(err => {
      throw new Error(err.message);
    });
};

const logout = async () => {
  return axiosService
    .post(`/quickbooks/logout`, {})
    .then(result => result.data);
};

const checkAuth = async () => {
  const result = await axiosService.get(`/quickbooks/checkToken`);
  return result.data;
};

const getCustomer = async () => {
  return axiosService
    .get(
      `/quickbooks/company/${
        auth.currentUser.tenantId || ''
      }/query/select * from Customer where Active = true orderby MetaData.CreateTime desc MAXRESULTS 1000`
    )
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.QueryResponse.Customer;
      } else {
        return getError(result.data);
      }
    });
};

const getBillList = async () => {
  return axiosService
    .get(
      `/quickbooks/company/${
        auth.currentUser.tenantId || ''
      }/query/select * from bill` // where BillableStatus = 'Billable'`
    )
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.QueryResponse.Bill;
      } else {
        return getError(result.data);
      }
    });
};

const createCustomer = async data => {
  return axiosService
    .post(
      `/quickbooks/company/${auth.currentUser.tenantId || ''}/customer`,
      data
    )
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.Customer;
      } else {
        return getError(result.data);
      }
    });
};

const createPurchase = async data => {
  return axiosService
    .post(
      `/quickbooks/company/${auth.currentUser.tenantId || ''}/purchase`,
      data
    )
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.Purchase;
      } else {
        return getError(result.data);
      }
    });
};

const createBill = async data => {
  return axiosService
    .post(`/quickbooks/company/${auth.currentUser.tenantId || ''}/bill`, data)
    .then(response => {
      const result = response.data;
      if (result.success) {
        return result.data;
      } else {
        throw new Error(result.message);
      }
    });
};

const createTimeActivity = async data => {
  return axiosService
    .post(
      `/quickbooks/company/${auth.currentUser.tenantId || ''}/time-activity`,
      data
    )
    .then(response => {
      const result = response.data;
      if (result.success) {
        return result.data;
      } else {
        throw new Error(result.message);
      }
    });
};

const getAccount = async () => {
  return axiosService
    .get(`/quickbooks/company/${auth.currentUser.tenantId || ''}/account`)
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.QueryResponse.Account;
      } else {
        return getError(result.data);
      }
    });
};

const getVendor = async () => {
  return axiosService
    .get(`/quickbooks/company/${auth.currentUser.tenantId || ''}/vendor`)
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.QueryResponse.Vendor;
      } else {
        return getError(result.data);
      }
    });
};

const getEmployee = async () => {
  return axiosService
    .get(`/quickbooks/company/${auth.currentUser.tenantId || ''}/employee`)
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.QueryResponse.Employee;
      } else {
        return getError(result.data);
      }
    });
};

const getCustomerDetail = async customerId => {
  return axiosService
    .get(
      `/quickbooks/company/${
        auth.currentUser.tenantId || ''
      }/customer/${customerId}`
    )
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.Customer;
      } else {
        return getError(result.data);
      }
    });
};

const getInvoices = async () => {
  if (!localStorage.getItem('login_tenant')) return [];
  return axiosService
    .get(`/quickbooks/company/${localStorage.getItem('login_tenant')}/invoices`)
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.QueryResponse.Invoice;
      } else {
        return getError(result.data);
      }
    });
};

const getPayments = async () => {
  if (!localStorage.getItem('login_tenant')) return [];
  return axiosService
    .get(`/quickbooks/company/${localStorage.getItem('login_tenant')}/payments`)
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.QueryResponse.Payment;
      } else {
        return getError(result.data);
      }
    });
};

const getPaymentMethod = async paymentMethodId => {
  if (!localStorage.getItem('login_tenant')) return null;
  return axiosService
    .get(
      `/quickbooks/company/${localStorage.getItem(
        'login_tenant'
      )}/paymentmethod/${paymentMethodId}`
    )
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.PaymentMethod;
      } else {
        return getError(result.data);
      }
    });
};

const getClasses = async () => {
  if (!localStorage.getItem('login_tenant')) return [];
  return axiosService
    .get(`/quickbooks/company/${localStorage.getItem('login_tenant')}/classes`)
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.QueryResponse.Class;
      } else {
        return getError(result.data);
      }
    });
};

const getServiceItems = async () => {
  if (!localStorage.getItem('login_tenant')) return [];
  return axiosService
    .get(
      `/quickbooks/company/${localStorage.getItem(
        'login_tenant'
      )}/service-items`
    )
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.QueryResponse.Item;
      } else {
        return getError(result.data);
      }
    });
};

const queryEntity = async query => {
  return axiosService
    .get(
      `/quickbooks/company/${auth.currentUser.tenantId || ''}/query/${query}`
    )
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data.QueryResponse;
      } else {
        return getError(result.data);
      }
    });
};

const getUserInfo = async () => {
  if (!localStorage.getItem('login_tenant')) return [];
  return axiosService
    .get(
      `/quickbooks/company/${localStorage.getItem('login_tenant')}/user-info`
    )
    .then(result => {
      if (result.data && result.data.success) {
        return result.data.data;
      } else {
        return getError(result.data);
      }
    });
};

const syncData = async data => {
  return axiosService
    .post(
      `/quickbooks/company/${auth.currentUser.tenantId || ''}/sync-data`,
      data
    )
    .then(result => {
      if (result.data && result.data.success) {
        return true;
      } else {
        return getError(result.data);
      }
    });
};

export default {
  getAuthUri,
  logout,
  checkAuth,
  getCustomer,
  createCustomer,
  getBillList,
  createPurchase,
  getAccount,
  getVendor,
  getEmployee,
  getInvoices,
  getPayments,
  queryEntity,
  createBill,
  createTimeActivity,
  getCustomerDetail,
  getPaymentMethod,
  getClasses,
  getServiceItems,
  getUserInfo,
  syncData,
};
