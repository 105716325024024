<template>
  <f7-page class="project-photo-page-container">
    <f7-navbar>
      <f7-nav-title>Project Photos</f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-if="selectedIds.length > 0"
          @click="cancelSelect"
        >
          Cancel
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <!-- tabs -->
    <f7-block class="tab-block">
      <f7-segmented
        strong
        tag="p"
      >
        <f7-button
          class="custom-hover"
          :active="currentTab === 'album'"
          @click.native="onTab('album')"
          >Albums</f7-button
        >
        <f7-button
          class="custom-hover"
          :active="currentTab === 'photo'"
          @click.native="onTab('photo')"
          >Photos</f7-button
        >
      </f7-segmented>
    </f7-block>
    <div
      v-if="currentTab === 'album'"
      class="margin-top margin-left"
    >
      Selected {{ selectedIds.length }} items
    </div>
    <f7-tabs
      class="tabs-container"
      animated
    >
      <div v-show="currentTab === 'album'">
        <albums-client-view
          :selectionMode="selectionMode"
          :selectedAlbumIds="selectedIds"
          :projectAlbumList="projectAlbumList"
          :projectPhotoList="projectPhotoList"
          :loading="loadingAlbum"
          :hoveredAlbumIds="hoveredIds"
          @clickAlbum="clickAlbum"
          @handleSelected="handleSelected"
        ></albums-client-view>
      </div>
      <div v-show="currentTab === 'photo'">
        <photos
          :projectPhotoListGroup="projectPhotoListGroup"
          :selectionMode="selectionMode"
          :selectedPhotoIds="selectedIds"
          :hoveredPhotoIds="hoveredIds"
          :loading="loadingPhoto"
          :isClientView="true"
          @clickPhoto="clickPhoto"
          :photoFilter="photoFilter"
          :endDateFilterErrorMessage="endDateFilterErrorMessage"
          @handleSelected="handleSelected"
          @handleMouseEnter="createTooltips"
          @handleSelectAllDay="handleSelectAllDay"
          @onFilterDate="onFilterDate"
        ></photos>
      </div>
    </f7-tabs>
    <template v-if="selectedIds.length > 0">
      <f7-fab
        :class="`tab-btn tab-btn_1 download-tooltips-${uuid}`"
        position="right-bottom"
        slot="fixed"
        color="default"
        @click="handleDownload"
      >
        <f7-icon f7="cloud_download"></f7-icon>
      </f7-fab>
    </template>
    <photo-browser
      :photos="photoList"
      :isClientView="true"
      @clickDownload="clickDownload"
      theme="dark"
      ref="pageDark"
    ></photo-browser>
  </f7-page>
</template>
<script>
import Photos from '../components/list/Photos.vue';
import AlbumsClientView from '../components/list/AlbumsClientView.vue';
import PhotoBrowser from '../components/popups/PhotoBrowser.vue';
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { sortLatest } from '@/utility/date-time-tool';
import { groupBy } from '@/utility/filter-tools';
import { uuid } from 'vue-uuid';

export default {
  components: {
    Photos,
    AlbumsClientView,
    PhotoBrowser,
  },
  props: {
    projectId: {
      type: String,
      default: '',
    },
  },
  watch: {
    projectId: {
      handler(val) {
        if (val) {
          this.resetPhotoFilter();
          this.photoTabInitData();
        }
      },
      deep: true,
    },
  },
  data: () => {
    return {
      projectPhotoList: [],
      projectAlbumList: [],
      selectionMode: false,
      selectedIds: [],
      currentTab: 'album',
      loadingPhoto: false,
      loadingAlbum: false,
      hoveredIds: [],
      endDateFilterErrorMessage: '',
      uuid: uuid.v4(),
    };
  },
  methods: {
    ...mapActions('photo/photo', [
      'getProjectPhotoByTenant',
      'resetPhotoFilter',
      'downloadPhoto',
      'resetPhoto',
      'downloadAlbumByTenant',
      'setPhotoFilterField',
      'resetPhotoFilter',
    ]),
    ...mapActions('photo/album', ['getProjectAlbumByTenant']),
    onTab(tab) {
      if (tab !== this.currentTab) this.cancelSelect();
      this.currentTab = tab;
      this.$f7router.updateCurrentUrl(
        `${this.$f7router.currentRoute.path}?currentTab=${tab}`
      );
    },
    cancelSelect() {
      this.selectionMode = false;
      this.selectedIds = [];
      this.$nextTick(() => {
        this.createTooltips();
      });
    },
    onSelect(id) {
      if (this.selectedIds.includes(id)) {
        var index = this.selectedIds.indexOf(id);
        if (index > -1) {
          this.selectedIds.splice(index, 1);
        }
      } else {
        this.selectedIds.push(id);
      }
    },
    clickPhoto(id, isShowPhoto = false) {
      if (isShowPhoto) {
        const index = this.projectPhotoList.findIndex(i => i.id === id);
        this.$refs.pageDark.open(index);
      } else {
        this.onSelect(id);
      }
    },
    clickAlbum(id, isShowPhoto = false) {
      if (isShowPhoto) {
        this.resetPhotoFilter();
        if (
          !(this.$f7route.params.projectId && this.$f7route.params.tenantId)
        ) {
          this.$emit('openPopupAlbum', id);
        } else {
          this.$f7router.navigate(
            `/share-photo/${this.$f7route.params.tenantId}/${this.$f7route.params.projectId}/${id}`,
            {
              pushState: true,
              reloadAll: true,
            }
          );
        }
      } else {
        this.onSelect(id);
      }
    },
    clickDownload(id) {
      const existedPhotoId = this.selectedIds.find(i => i === id);
      if (!existedPhotoId) {
        this.selectedIds.push(id);
      }
      this.handleDownload();
    },
    onFilterDate({ field, value }) {
      if (
        (_.isEmpty(value) && _.isEmpty(this.photoFilter[field])) ||
        (!_.isEmpty(value) &&
          !_.isEmpty(this.photoFilter[field]) &&
          moment(value[0]).isSame(moment(this.photoFilter[field][0]), 'day')) ||
        this.currentTab !== 'photo'
      )
        return;

      this.setPhotoFilterField({ field, value });
      const { startDate, endDate } = this.photoFilter;
      let fromDate = '';
      let toDate = '';
      if (!_.isEmpty(value) && !_.isEmpty(startDate) && !_.isEmpty(endDate)) {
        if (field === 'endDate') {
          fromDate = moment(startDate[0]);
          toDate = moment(value[0]);
        } else {
          fromDate = moment(value[0]);
          toDate = moment(endDate[0]);
        }
      }
      if (fromDate && toDate && toDate.isBefore(fromDate, 'day')) {
        this.endDateFilterErrorMessage =
          'To date must be greater than or equal from date';
        return;
      } else {
        this.endDateFilterErrorMessage = '';
      }
      this.photoTabInitData();
    },

    photoTabInitData() {
      this.currentTab = this.$f7route.query.currentTab || 'album';

      const projectId = this.projectIdComputed;
      const tenant = this.tenantIdComputed;

      this.loadingPhoto = true;
      this.loadingAlbum = true;
      this.$f7.preloader.show();

      Promise.all([
        this.getProjectPhotoByTenant({
          id: projectId,
          tenant: tenant,
        }),
        this.getProjectAlbumByTenant({
          id: projectId,
          tenant: tenant,
        }),
      ])
        .then(([photos, albums]) => {
          this.projectPhotoList = photos;
          this.projectAlbumList = albums;
        })
        .finally(() => {
          this.loadingPhoto = false;
          this.loadingAlbum = false;
          this.$f7.preloader.hide();
        });
    },
    handleSelectAllDay(data) {
      this.createTooltips();
      if (data.event) {
        const photosIds = (data.ids || []).filter(
          i => !this.selectedIds.includes(i)
        );
        this.selectedIds = this.selectedIds.concat(photosIds);
        this.hoveredIds = this.hoveredIds.concat(photosIds);
        this.selectionMode = true;
      } else {
        this.selectedIds = this.selectedIds.filter(i => !data.ids.includes(i));
        this.hoveredIds = this.hoveredIds.filter(i => !data.ids.includes(i));
      }
    },
    createTooltips() {
      if (!this.$device.desktop) return;

      const tooltips = [
        {
          targetEl: `.download-tooltips-${this.uuid}`,
          text: `Download ${this.currentTab}`,
        },
      ];
      tooltips.forEach(tooltip => {
        const el = this.$f7.tooltip.get(tooltip.targetEl);
        if (el) {
          this.$f7.tooltip.destroy(tooltip.targetEl);
        }
        this.$f7.tooltip.create({
          targetEl: tooltip.targetEl,
          cssClass: 'tooltip-fab-button',
          text: tooltip.text,
        });
      });
    },
    handleSelected(id, isSelectPhoto = false) {
      this.createTooltips();
      if (this.selectedIds.length === 0 && id === null) {
        this.selectionMode = false;
        this.hoveredIds = [];
      } else if (id === null) {
        const lastIndex = this.hoveredIds.length - 1;
        if (
          lastIndex >= 0 &&
          this.hoveredIds[lastIndex] !== null &&
          !this.selectedIds.includes(this.hoveredIds[lastIndex])
        ) {
          this.hoveredIds.splice(lastIndex, 1);
        }
      } else if (id && !this.hoveredIds.includes(id)) {
        this.selectionMode = true;
        this.hoveredIds.push(id);
        if (isSelectPhoto) {
          this.clickPhoto(id);
        }
      }
    },
    async handleDownload() {
      if (this.currentTab === 'photo') {
        this.$f7.toast
          .create({
            text: 'Downloading files...',
            closeOnClick: true,
            closeButton: false,
            closeTimeout: 3000,
          })
          .open();
        const allPhotos = this.projectPhotoListGroup
          .map(group => group.data)
          .flat();
        const selectedPhotos = allPhotos.filter(photo =>
          this.selectedIds.includes(photo.id)
        );
        selectedPhotos.forEach(i =>
          this.downloadPhoto({
            fullPath: i.photoFullPath,
            name: i.photoName,
            photoUrl: i.photoUrl,
          })
        );
      } else {
        this.$f7.toast
          .create({
            text: 'Downloading albums...',
            closeOnClick: true,
            closeButton: false,
            closeTimeout: 3000,
          })
          .open();
        const albumsSelected = this.projectAlbumList.filter(r =>
          this.selectedIds.includes(r.id)
        );
        const tenant = this.tenantIdComputed;

        albumsSelected.forEach(album => {
          this.downloadAlbumByTenant({
            albumId: album.id,
            albumName: album.name,
            tenant: tenant,
          });
        });
      }
      this.cancelSelect();
      this.createTooltips();
    },
  },
  computed: {
    ...mapGetters('photo/photo', ['projectPhotosSize', 'photoFilter']),
    ...mapGetters('dashboard/app-constant', ['isOpenProjectPhotoViewPopup']),
    ...mapGetters('common/app-constant', ['tenantId']),

    photoList() {
      return this.projectPhotoList.map(i => ({
        id: i.id,
        url: i.photoUrl,
        comments: i.comments,
      }));
    },
    projectPhotoListGroup() {
      return groupBy(sortLatest(this.projectPhotoList), i =>
        i.createdAt
          ? moment(i.createdAt.toDate()).format('dddd, MMMM Do YYYY')
          : ''
      );
    },

    projectIdComputed() {
      return !this.$f7route.params.projectId
        ? this.projectId
        : this.$f7route.params.projectId;
    },
    tenantIdComputed() {
      return !this.$f7route.params.tenantId
        ? this.tenantId
        : atob(this.$f7route.params.tenantId);
    },
  },
  mounted() {
    if (this.$f7route.params.projectId && this.$f7route.params.tenantId) {
      this.resetPhotoFilter();
      this.photoTabInitData();
    }
  },
};
</script>
<style lang="scss" scoped>
.project-photo-page-container ::v-deep > .page-content {
  overflow: hidden;
}
.tabs-container ::v-deep .tabs .page > .page-content {
  padding-top: 0;
}
.btn-icon {
  position: absolute;
  left: 25px;
  top: 10px;
}
.custom-hover:hover {
  background: var(--step-background-color) !important;
}
.tab-block {
  margin: 15px 5px 5px 5px;
}
.tab-btn {
  right: var(--global-search-btn-right);
  &_1 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        var(--f7-fab-size) + 16px
    );
  }
}
.custom-navbar ::v-deep .navbar-bg {
  background-color: black;
}
</style>
