<template>
  <f7-page class="branch-edit">
    <f7-navbar>
      <f7-nav-left>
        <f7-link @click="$f7router.back()">Cancel</f7-link>
      </f7-nav-left>
      <f7-nav-title>
        {{
          createFor === 'contact' ? 'New Company' : 'New Branch'
        }}</f7-nav-title
      >
      <f7-nav-right>
        <f7-link @click.native="create">Done</f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-list>
      <!-- Company Name -->
      <f7-list-input
        class="first-input"
        type="text"
        placeholder="Company name"
        clear-button
        :value="displayCompany.companyName"
        @input="setCompanyValue('companyName', $event.target.value.trim())"
        error-message-force
        :error-message="companyNameErrorMessage"
        @blur="v$.$touch()"
      ></f7-list-input>

      <!-- Company Types -->
      <f7-list-item
        ref="branchCompanyTypesPopup"
        title="Company Types"
        smart-select
        :smart-select-params="{
          openIn: 'popup',
          searchbar: true,
          searchbarPlaceholder: 'Search type',
          on: {
            opened: handlePopupOpened,
          },
        }"
      >
        <select
          name="types"
          multiple
          v-model="companyTypes"
        >
          <option
            v-for="(type, index) in companyTypeList"
            :key="index"
            :value="type.value"
          >
            {{ type.displayName }}
          </option>
        </select>
      </f7-list-item>
    </f7-list>

    <!-- Phone -->
    <phone-input
      :id="displayCompany.id"
      ref="phoneInput"
      isRequired
      :value="displayCompany.phones"
      @input="setCompanyValue('phones', $event)"
    ></phone-input>

    <address-auto-complete-input
      :id="displayCompany.id"
      ref="addressInput"
      :value="displayCompany.addresses"
      @input="setCompanyValue('addresses', $event)"
    ></address-auto-complete-input>

    <!-- Other -->
    <other-input
      :id="displayCompany.id"
      ref="otherInput"
      :value="displayCompany.others"
      @input="setCompanyValue('others', $event)"
    ></other-input>
  </f7-page>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

import PhoneInput from '@/components/inputs/PhoneInput.vue';
import AddressAutoCompleteInput from '@/components/inputs/AddressAutoCompleteInput.vue';
import OtherInput from '@/components/inputs/OtherInput.vue';
import _ from 'lodash';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    PhoneInput,
    AddressAutoCompleteInput,
    OtherInput,
  },

  data: () => {
    return {
      displayCompany: {},
      companyTypes: [],
    };
  },

  computed: {
    ...mapGetters('contact-book/contact', ['createdCompanyId', 'companyList']),
    ...mapGetters('contact-book/app-constant', ['companyTypeList']),

    companyNameErrorMessage() {
      if (!this.v$.displayCompany.companyName.$error) return null;
      if (this.v$.displayCompany.companyName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    createFor() {
      return this.$f7route.route.meta.createFor;
    },
  },
  methods: {
    ...mapActions('contact-book/client', [
      'createCompany',
      'setCreatedCompanyId',
    ]),
    setCompanyValue(key, value) {
      Vue.set(this.displayCompany, key, value);
    },

    existsCompany() {
      const found = this.companyList.find(company => {
        return company.companyName === this.displayCompany.companyName;
      });

      if (!_.isEmpty(found)) {
        this.$ri.dialog.openWarningDialog({
          title: 'Please choose another one',
          content: 'That company has already existed!',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      }

      return !_.isEmpty(found);
    },

    create() {
      if (!this.validateData() || this.existsCompany()) return;
      this.$f7.preloader.show();
      this.displayCompany.companyTypes = this.companyTypes;
      this.createCompany(this.displayCompany).then(id => {
        this.setCreatedCompanyId(id);
        this.$f7.preloader.hide();
        this.$f7router.back();
      });
    },

    validateData() {
      this.v$.$touch();
      this.$refs.phoneInput.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      this.$refs.otherInput.v$.$touch();
      if (
        this.$refs.phoneInput.v$.$invalid ||
        this.$refs.addressInput.v$.$invalid ||
        this.$refs.otherInput.v$.$invalid ||
        this.v$.$invalid
      ) {
        return false;
      }
      return true;
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$refs.branchCompanyTypesPopup.f7SmartSelect.searchbar.$el[0]
          .querySelector('.searchbar input')
          .focus();
      }
    },
  },

  mounted() {
    if (this.$device.desktop) {
      this.$el.querySelector('.first-input input').focus();
    }
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      displayCompany: {
        companyName: {
          required,
        },
        // addresses: {
        //   $each: helpers.forEach({
        //     address: {
        //       required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
        //     },
        //     city: {
        //       required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
        //     },
        //     state: {
        //       required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
        //     },
        //     zipcode: {
        //       required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
        //     },
        //     country: {
        //       required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
        //     }
        // })
        // }
      },
    };
  },
};
</script>

<style lang="scss">
.branch-edit .item-title.item-label a {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
}
</style>
