/* eslint-disable no-unused-vars */
import _ from 'lodash';
import * as constant from '../../utility/const';

const PROP_DISPLAY_NAME = 'displayName';
const PROP_VALUE = 'value';
const by = prop => (a, b) => (a[prop] > b[prop] ? 1 : -1);

export default {
  //TODO: impelement getter for each constant type
  /**
   * get company types
   * @param {*} state
   * @returns
   */
  estimateStatusList: state =>
    state.constantObjectList.filter(
      item =>
        item.code === constant.CODE_STATUS_OPTION &&
        item.entity === constant.ENTITY_ESTIMATE
    ),
  companyTypeList: state =>
    state.constantObjectList.filter(
      item => item.code === constant.CODE_COMPANY_TYPE
    ),

  defaultColumns: state => state.defaultColumns,

  slopeTypeList: state =>
    state.constantObjectList.filter(
      item => item.code === constant.CODE_SLOPE_TYPE
    ),
  slopeType: (state, getters, rootState, rootGetters) => value =>
    getters.slopeTypeList.find(item => item.value === value) || {},

  roofTypeList: state =>
    state.constantObjectList.filter(
      item => item.code === constant.CODE_ROOF_TYPE
    ) || [],

  roofTypeListBySlopeType: (state, getters, rootState, rootGetters) => value =>
    getters.roofTypeList.filter(item => item.slopeType === value) || [],

  roofTypeBy: (state, getters, rootState, rootGetters) => value =>
    getters.roofTypeList.find(item => item.value === value) || {},

  fasteningPatternList: state =>
    _.cloneDeep(
      state.constantObjectList.filter(
        item => item.code === constant.CODE_TPO_FASTENING_PATTERN
      )
    ).sort(by(PROP_VALUE)),

  fasteningPattern: (state, getters, rootState, rootGetters) => value =>
    getters.fasteningPatternList.find(item => item.value === value) || {},

  workTypeList: state =>
    state.constantObjectList.filter(
      item => item.code === constant.CODE_WORK_TYPE
    ),
  workType: (state, getters, rootState, rootGetters) => value =>
    getters.workTypeList.find(item => item.value === value) || {},

  roofThicknessList: state =>
    _.cloneDeep(
      state.constantObjectList.filter(
        item =>
          item.code === constant.CODE_ROOF_THICKNESS &&
          item.roofType === constant.ROOF_TYPE_TPO
      )
    ).sort(by(PROP_VALUE)),

  roofThickness: (state, getters, rootState, rootGetters) => value =>
    getters.roofThicknessList.find(item => item.value === value) || {},

  substrateList: state =>
    _.cloneDeep(
      state.constantObjectList.filter(
        item =>
          item.code === constant.CODE_SUBSTRATE &&
          item.roofType === constant.ROOF_TYPE_TPO
      )
    ).sort(by(PROP_DISPLAY_NAME)),

  substrate: (state, getters, rootState, rootGetters) => value =>
    getters.substrateList.find(item => item.value === value) || {},

  insulationTypeList: (state, getters, rootState, rootGetters) => {
    const currentBuilding =
      rootGetters['estimate/estimate-page/estimate/currentBuilding'];

    let insList = _.cloneDeep(
      state.constantObjectList.filter(
        item =>
          item.code === constant.CODE_INSULATION_TYPE &&
          item.sectionType === constant.SECTION_TYPE_TPO_INSULATION
      )
    ).sort(by(PROP_DISPLAY_NAME));

    // https://rooferintel.atlassian.net/browse/DEV-801
    // There will be no “fanfold” if Structural Concrete substrate
    if (currentBuilding.substrate === 'tpo-structural-concrete') {
      insList = insList.filter(item => item.value !== 'tpo-fandfold');
    }

    return insList;
  },

  insulationType: (state, getters, rootState, rootGetters) => value =>
    getters.insulationTypeList.find(item => item.value === value) || {},

  insulationThicknessList: state =>
    _.cloneDeep(
      state.constantObjectList.filter(
        item => item.code === constant.CODE_INSULATION_THICKNESS
      )
    ).sort(by(PROP_DISPLAY_NAME)),

  insulationThicknessListByType:
    (state, getters, rootState, rootGetters) => value =>
      getters.insulationThicknessList.filter(
        item => item.insulationType === value
      ) || [],
  insulationThickness: (state, getters, rootState, rootGetters) => value =>
    getters.insulationThicknessList.find(item => item.value === value) || {},

  insulationAttachmentTypeList: state =>
    _.cloneDeep(
      state.constantObjectList.filter(
        item =>
          item.code === constant.CODE_ROOF_ATTACHMENT_TYPE &&
          item.sectionType === constant.SECTION_TYPE_TPO_INSULATION
      )
    ).sort(by(PROP_DISPLAY_NAME)),

  insulationAttachmentTypeListByType:
    (state, getters, rootState, rootGetters) => value =>
      getters.insulationAttachmentTypeList.filter(
        item => item.layerType === value
      ) || [],
  insulationAttachmentType: (state, getters, rootState, rootGetters) => value =>
    getters.insulationAttachmentTypeList.find(item => item.value === value) ||
    {},

  membraneThicknessList: state =>
    _.cloneDeep(
      state.constantObjectList.filter(
        item =>
          item.code === constant.CODE_MEMBRANE_THICKNESS &&
          item.membraneType === constant.SECTION_TYPE_TPO_MEMBRANE
      )
    ).sort(by(PROP_DISPLAY_NAME)),

  membraneThickness: (state, getters, rootState, rootGetters) => value =>
    getters.membraneThicknessList.find(item => item.value === value) || {},

  insulationLayers: (state, getters) => layers => {
    return (layers || []).map(item => {
      return {
        coverageRate: parseFloat(item.coverageRate || 0),
        perimeterCoverageRate: parseFloat(item.perimeterCoverageRate || 0),
        cornerCoverageRate: parseFloat(item.cornerCoverageRate || 0),
        screwsPerBoard: parseFloat(item.screwsPerBoard || 0),
        insulationType: getters.insulationType(item.insulationType),
        insulationAttachmentType: getters.insulationAttachmentType(
          item.attached
        ),
        insulationThickness: getters.insulationThickness(item.thickness),
      };
    });
  },

  membraneAttachmentTypeList: state =>
    state.constantObjectList.filter(
      item =>
        item.code === constant.CODE_ROOF_ATTACHMENT_TYPE &&
        item.sectionType === constant.SECTION_TYPE_TPO_MEMBRANE
    ),
  membraneAttachmentType: (state, getters, rootState, rootGetters) => value =>
    getters.membraneAttachmentTypeList.find(item => item.value === value) || {},

  roofTypeGroup:
    (state, getters, rootState, rootGetters) =>
    (searchValue = '') => {
      let group = [];

      let roofTypes =
        searchValue != ''
          ? getters.roofTypeList.filter(roofType => {
              let displayName = roofType.displayName
                ? roofType.displayName.toLowerCase()
                : '';

              return displayName.indexOf(searchValue.toLowerCase()) >= 0;
            }) || []
          : getters.roofTypeList;

      roofTypes.forEach(roofType => {
        let letter = roofType.displayName
          ? roofType.displayName.charAt(0).toUpperCase()
          : '';
        let index = group.findIndex(groupName => {
          return letter === Object.keys(groupName)[0];
        });
        if (index >= 0) {
          group[index][letter].push(roofType);
        } else {
          group.push({ [letter]: [roofType] });
        }
      });

      group.forEach(data => {
        let letter = Object.keys(data)[0];
        data[letter].sort((a, b) => {
          return (a.displayName ? a.displayName.charAt(1).toUpperCase() : '') <
            (b.displayName ? b.displayName.charAt(1).toUpperCase() : '')
            ? -1
            : 0;
        });
      });

      return group.sort((a, b) => {
        return Object.keys(b)[0] > Object.keys(a)[0] ? -1 : 0;
      });
    },

  tpoWarrantyOptionList: state =>
    state.constantObjectList
      .filter(
        item =>
          item.code === constant.CODE_WARRANTY_OPTION &&
          item.roofType === constant.ROOF_TYPE_TPO
      )
      .sort(by(PROP_DISPLAY_NAME)),

  tpoWarrantyOption: (state, getters, rootState, rootGetters) => value =>
    getters.tpoWarrantyOptionList.find(item => item.value === value) || {},

  tpoAttachmentMethodList: state =>
    state.constantObjectList
      .filter(
        item =>
          item.code === constant.CODE_ATTACHMENT_METHOD &&
          item.roofType === constant.ROOF_TYPE_TPO
      )
      .sort(by(PROP_DISPLAY_NAME)),

  estimateStatusOptionList: state =>
    state.constantObjectList.filter(
      item =>
        item.code === constant.CODE_STATUS_OPTION &&
        item.entity === constant.ENTITY_ESTIMATE
    ),
  estimateStatusOptionBy: (state, getters, rootState, rootGetters) => value =>
    getters.estimateStatusOptionList.find(item => item.value === value) || {},

  workStepBy: state => value =>
    state.constantObjectList.find(
      item =>
        item.code === constant.CODE_ESTIMATE_WORK_STEPS && item.value === value
    ) || {},

  expenseTypeList: state =>
    state.constantObjectList.filter(
      item => item.code === constant.CODE_EXPENSE_TYPE
    ),
  expenseStatusList: state =>
    state.constantObjectList.filter(
      item =>
        item.code === constant.CODE_STATUS_OPTION &&
        item.entity === constant.ENTITY_EXPENSE
    ),
  assemblyDrawingStatus: state =>
    state.constantObjectList.filter(
      item =>
        item.code === constant.CODE_STATUS_OPTION &&
        item.entity === constant.ENTITY_ASSEMBLY_DRAWING
    ) || [],

  assemblyDrawingStatusBy: (state, getters, rootState, rootGetters) => value =>
    getters.assemblyDrawingStatus.find(item => item.value === value) || {},

  tenantId: state => state.tenantId,
};
