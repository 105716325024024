<template>
  <div>
    <f7-block-header>{{ title }}</f7-block-header>
    <f7-list>
      <!-- Main list item -->
      <f7-list-item
        v-for="(email, index) in value"
        :key="index"
      >
        <a
          class="cursor-pointer"
          slot="title"
          @click="showDetails(email)"
          >{{ email.subject }}</a
        >
        <div slot="footer">
          Sent at {{ toDisplayDateString(email.createdAt) }} by
          {{ email.createdBy }}
        </div>
      </f7-list-item>
    </f7-list>

    <email-details-popup ref="emailDetailsPopup"></email-details-popup>
  </div>
</template>

<script>
import { toDisplayDateString } from '../../../../utility/datetime';
import EmailDetailsPopup from '../popup/EmailDetailsPopup.vue';
export default {
  props: {
    value: { type: Array, default: () => [] },
    title: String,
  },

  components: { EmailDetailsPopup },

  methods: {
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    showDetails(email) {
      this.$refs.emailDetailsPopup.show(email);
    },
  },
};
</script>

<style></style>
