export default {
  projectList: state => state.projectList,
  projects: (state, _, __, rootGetters) => {
    const propertyList = rootGetters['invoices/property/propertyList'];

    const projectResult = state.projectList.map(project => {
      const property =
        propertyList.find(property => property.id === project.propertyId) || {};
      return {
        ...project,
        property,
      };
    });
    return projectResult;
  },

  projectId: state => state.projectId,

  projectDetail: state =>
    state.projectList.find(r => r.id === state.projectId) || {},

  project: state => state.project,

  projectsSort:
    state =>
    (searchValue = '') => {
      let projectList =
        searchValue != ''
          ? state.projectList.filter(project => {
              let title = project.title ? project.title.toLowerCase() : '';
              let cardNumber = project.cardNumber
                ? project.cardNumber.toLowerCase()
                : '';
              return (
                (title + cardNumber).indexOf(searchValue.toLowerCase()) >= 0
              );
            }) || []
          : state.projectList;

      projectList = projectList.filter(({ status }) => status === 'open');

      projectList.sort((a, b) => sortBy(b.createdAt, a.createdAt));

      return projectList;
    },

  projectById: (_, getters) => id => getters.projects.find(r => r.id === id),

  latestProject: state => {
    let sorted = [...state.projectList].sort((a, b) => {
      return b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime();
    });

    return sorted[0] || {};
  },
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
