<template>
  <div>
    <f7-popup
      :opened="isPopupOpened"
      @popup:closed="handlePopupClosed"
      @popup:opened="() => {}"
    >
      <f7-page>
        <f7-block class="display-flex justify-content-right">
          <f7-button
            fill
            @click="openPriceListPopup"
          >
            More Products
          </f7-button>
        </f7-block>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>Edit Product</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="saveAndClose">Save</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <!-- Products table -->
        <data-table
          :headers="headers"
          :items="productItemComputed"
          :pageSize="productItemComputed.length"
          :checkbox="true"
          @selected:change="handleCheckboxChecked"
        >
          <template slot="card-header">
            <f7-list>
              <f7-searchbar
                placeholder="Search in items"
                :clear-button="true"
                disable-button-text
                @input="searchtext = $event.target.value"
                @searchbar:disable="searchtext = ''"
                ref="searchProductPopup"
              ></f7-searchbar>
            </f7-list>
          </template>
          <template v-slot:body="{ item }">
            <td>{{ item.productItem }}</td>

            <td>{{ item.manufacturer }}</td>
            <td>{{ item.category }}</td>
            <td>
              <cell-number-input
                numberType="decimal"
                :fractionDigits="0"
                placeholder="Qty"
                :value="item.orderQty"
                @click.native.stop
                @done="
                  onChangeProp('orderQty', parseFloat($event), item, () => {})
                "
              ></cell-number-input>
            </td>
            <td>
              <ul>
                <li
                  v-for="(attach, index) in item.technicalData"
                  :key="index"
                >
                  <a
                    class="display-block"
                    @click.stop="openLink(attach.url)"
                  >
                    {{ attach.name }}
                  </a>
                </li>
              </ul>
            </td>
            <td>{{ item.packaging }}</td>
            <td>{{ item.color }}</td>
            <!-- <td>{{ item.price | currencyUSD }}</td> -->
            <td>
              <cell-number-input
                numberType="currency"
                :fractionDigits="2"
                placeholder="Price"
                :value="item.price"
                @click.native.stop
                @done="
                  onChangeProp('price', parseFloat($event), item, () => {})
                "
              ></cell-number-input>
            </td>
          </template>
        </data-table>

        <add-product-item-popup
          ref="addProductItemPopup"
          :productItemList="productsInPriceList"
          @onAddProductItems="addProductItems"
        ></add-product-item-popup>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import DataTable from '@/components/datatables';
import CellNumberInput from '@/components/inputs/CellNumberInput.vue';
import AddProductItemPopup from './AddProductItemPopup.vue';

import { mapGetters, mapActions } from 'vuex';
import { applyFilter } from '@/utility/filter-tools';
import common from '../../utility/common';

import _ from 'lodash';

export default {
  components: {
    DataTable,
    CellNumberInput,
    AddProductItemPopup,
  },

  mixins: [common],

  props: {
    // productItemList: Array,
    // manufacturers: Array,
    // vendorIds: Array,
    // section: Object,
    // priceListProductItems: Array,
    // estimateId: String
  },

  data: () => ({
    isPopupOpened: false,
    products: [],
    headers: [
      // {
      //   text: "Vendor",
      //   align: "left",
      //   sortable: false,
      //   value: "vendor"
      // },
      {
        text: 'Product Item',
        value: 'productItem',
        sortable: false,
        align: 'left',
      },
      {
        text: 'Manufacturer',
        align: 'left',
        sortable: false,
        value: 'manufacturer',
      },
      {
        text: 'Category',
        align: 'left',
        sortable: false,
        value: 'category',
      },
      {
        text: 'Qty',
        value: 'qty',
        sortable: false,
        align: 'right',
      },
      // {
      //   text: "SKU",
      //   value: "sku",
      //   sortable: false,
      //   align: "left"
      // },

      {
        text: 'Technical Data',
        value: 'technicalData',
        sortable: false,
        align: 'left',
      },
      {
        text: 'Packaging',
        value: 'packaging',
        sortable: false,
        align: 'left',
      },
      {
        text: 'Color',
        value: 'color',
        sortable: false,
        align: 'left',
      },

      // {
      //   text: "Unit Packs",
      //   value: "unitPack",
      //   sortable: false,
      //   align: "left"
      // },
      // {
      //   text: "Unit Size",
      //   value: "unitSize",
      //   sortable: false,
      //   align: "left"
      // },
      // {
      //   text: "UOM",
      //   value: "UoM",
      //   sortable: false,
      //   align: "left"
      // },
      {
        text: 'Price',
        value: 'price',
        sortable: false,
        align: 'right',
      },
    ],

    searchtext: '',
    sectionIndex: 0,
    buildingId: '',
    sectionId: '',
    building: {},
    productsInPriceList: [],
  }),

  computed: {
    ...mapGetters({
      subCategoryObjects:
        'estimate/estimate-page/estimate/sub-category/objectList',
      vendors: 'estimate/estimate-page/estimate/vendor/objectList',
    }),

    ...mapGetters({
      proposal: 'proposal/proposal/proposal',
      proposalTemplate: 'proposal/template/proposalTemplate',
    }),

    ...mapGetters('proposal/price-list', ['categoryById']),

    // productItemList() {
    //   return (
    //     (
    //       (this.proposal.products || []).find(
    //         item => item.buildingId === this.buildingId
    //       ) || {}
    //     ).productData || []
    //   );
    // },

    productItemComputed() {
      let list = _.cloneDeep(this.products);
      // console.log(list.filter(item => item.checked));
      //Filter by statu active
      // list = list.filter(x => x.status === DEFAULT_STATUS_PRODUCT_ITEM);

      // Filter vendor
      if (this.vendorIds) {
        list = list.filter(x => this.vendorIds.includes(x.vendorId));
      }

      // Filter manufacturer
      if (this.manufacturers) {
        list = list.filter(x => this.manufacturers.includes(x.manufacturer));
      }
      return applyFilter([...list], this.searchtext, [
        'productItem',
        'sku',
        i => i.manufacturer,
        'category',
        // i => this.vendorName(i.vendorId),
        // i => this.subCategoryName(i.subCategoryId),
        'uom',
        i => this.technicalDataNames(i.technicalData),
      ]);
    },
  },

  methods: {
    ...mapActions({ getBuilding: 'proposal/estimate/getBuilding' }),
    ...mapActions('proposal/price-list', ['getProductsByPriceList']),
    ...mapActions('proposal/price-list', ['bindCategoryList']),

    openPopup(index, estimateId, buildingId, sectionId) {
      this.sectionIndex = index;
      this.buildingId = buildingId;
      this.sectionId = sectionId;
      // console.log(estimateId, buildingId);
      this.getBuilding({ estimateId, buildingId }).then(building => {
        this.building = building;
        this.products = this.getProductItemList();
        this.isPopupOpened = true;
      });
    },

    saveAndClose() {
      const self = this;
      this.$emit('onSave', {
        index: this.sectionIndex,
        allProducts: this.products,
        sectionId: this.sectionId,
        buildingId: this.buildingId,
        callback: () => {
          self.isPopupOpened = false;
        },
      });
    },

    handleCheckboxChecked(event) {
      const foundProduct = this.products.find(item => item.id === event.id);
      if (foundProduct) {
        foundProduct.checked = event.checked;
      }
    },

    handlePopupClosed() {
      this.products = [];
      this.isPopupOpened = false;
    },

    // eslint-disable-next-line no-unused-vars
    onChangeProp(propName, value, product, callback) {
      const cloneProducts = _.cloneDeep(this.products);
      const foundProduct = cloneProducts.find(item => {
        return item.id === product.id;
      });

      if (foundProduct) {
        foundProduct[propName] = value;
        foundProduct.checked = this.isCheckedProduct(foundProduct);
        if (propName === 'orderQty') {
          foundProduct['qtyOverride'] = true;
        }
      }
      this.products = cloneProducts;
    },

    getProductItemList() {
      return _.cloneDeep(
        (
          (this.proposal.products || []).find(
            item => item.buildingId === this.buildingId
          ) || {}
        ).productData || []
      );
    },

    openPriceListPopup() {
      const self = this;
      this.productsInPriceList = [];
      this.$f7.dialog.preloader('Loading product items. Please wait...');

      this.bindCategoryList()
        .then(() => {
          return self
            .getProductsByPriceList((self.building || {}).priceListId || '')
            .then(products => {
              self.productsInPriceList = products.map(product => {
                return {
                  ...product,
                  category:
                    (self.categoryById(product.categoryId) || {}).name || '',
                };
              });
            });
        })
        .finally(() => {
          this.$f7.dialog.close();
          this.$refs.addProductItemPopup.openPopup();
        });
    },

    addProductItems(products) {
      const cloneProducts = _.cloneDeep(products);
      const productsWithOrderQty = cloneProducts.map(item => ({
        ...item,
        orderQty: 1,
      }));
      this.products.unshift(...productsWithOrderQty);
      this.products = _.uniqBy(this.products, 'id');
    },

    //------------------
    openLink(url) {
      window.open(url, '_blank');
    },

    addProductItem() {
      if (this.products.length === 0) {
        this.showNotification(
          'Add new product item fail',
          'Please select a product item to add'
        );
      } else {
        this.$emit('addDataRowEst', this.products);
      }
    },

    showNotification(subtitle, text) {
      const self = this;
      if (!self.notificationFull) {
        self.notificationFull = self.$f7.notification.create({
          icon: '<i class="icon icon-f7"></i>',
          title: 'RooferIntel',
          titleRightText: 'now',
          subtitle,
          text,
          closeTimeout: 3000,
        });
      }
      self.notificationFull.open();
    },

    vendorName(id) {
      const vendor = this.vendors.find(r => r.id === id);
      return (vendor || {}).companyName;
    },

    subCategoryName(subCategoryId) {
      const subCategory = this.subCategoryObjects.find(
        r => r.id == subCategoryId
      );
      return (subCategory || {}).name;
    },

    technicalDataNames(technicalData = []) {
      return technicalData.map(r => r.name).join(', ');
    },

    addMoreProduct() {
      this.$refs.moreProductPopup.open();
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$refs.searchProductItemPopup.$el
          .querySelector('.searchbar input')
          .focus();
      }
    },
  },
};
</script>

<style scoped></style>
