<template>
  <div>
    <f7-row>
      <f7-col
        v-if="!$device.desktop"
        width="100"
        class="display-flex justify-content-flex-end"
        :class="{ summary: !$device.desktop }"
      >
        <f7-button
          v-if="isEdit"
          fill
          color="primary"
          class="margin-right"
          @click="addAnotherLine"
        >
          Add another line
        </f7-button>
        <f7-button
          v-if="isEdit"
          fill
          color="primary"
          @click="selectFromPriceList"
        >
          Select from Price list
        </f7-button>
      </f7-col>
    </f7-row>
    <data-table
      :headers="isEdit ? productTableHeaders : detailTableHeaders"
      :items="dataItems"
      :pageSize="dataItems.length"
      class="label-cell dark-header"
      :class="{ 'no-margin-horizontal': $device.desktop }"
    >
      <template v-slot:body="{ item, index }">
        <td class="display-flex align-items-center">
          <input
            :readonly="!isEdit"
            style="width: 100%"
            ref="input"
            type="text"
            placeholder="Type or select from Price list"
            :value="item.productName"
            @change="
              updateProductProperty({
                propName: 'productName',
                value: $event.target.value,
                index,
              });
              validate();
            "
            :class="{ 'error-input': productNameErrorMessage(index) }"
          />
        </td>
        <td class="numeric-cell">
          <div v-if="!isEdit">
            {{ item.quantity }}
          </div>
          <cell-number-input
            v-else
            placeholder="Quantity"
            :value="item.quantity"
            :fractionDigits="0"
            @done="
              updateProductProperty({
                propName: 'quantity',
                value: $event,
                index,
              });
              validate();
            "
            :error-message="productQuantityErrorMessage(index)"
          ></cell-number-input>
        </td>
        <td v-if="isEdit">
          <cell-number-input
            placeholder="Price"
            numberType="currency"
            :value="item.price"
            @done="
              updateProductProperty({
                propName: 'price',
                value: $event,
                index,
              });
              validate();
            "
            :error-message="priceErrorMessage(index)"
            :allowNegativeNumber="false"
          ></cell-number-input>
        </td>
        <td class="numeric-cell">
          {{ item.priceWithProfitAndMisc | currencyUSD }}
        </td>
        <td class="numeric-cell">
          {{ item.amount | currencyUSD }}
        </td>
        <td class="text-align-center">
          <f7-checkbox
            :checked="!!item.discount"
            @change="
              updateProductProperty({
                propName: 'discount',
                value: $event.target.checked,
                index,
              })
            "
          ></f7-checkbox>
        </td>
        <td class="text-align-center">
          <f7-checkbox
            disabled
            :checked="!!item.tax"
          ></f7-checkbox>
        </td>
        <td
          class="numeric-cell"
          v-if="isEdit"
        >
          <f7-link
            class="margin-right-half"
            icon-f7="doc_on_doc"
            color="blue"
            @click="cloneItem(item)"
          ></f7-link>
          <f7-link
            icon-f7="xmark_circle"
            color="red"
            @click="deleteItem(index)"
          ></f7-link>
        </td>
      </template>
    </data-table>
    <f7-row>
      <f7-col
        v-if="$device.desktop"
        width="100"
        medium="40"
        class="display-flex"
      >
        <f7-button
          v-if="isEdit"
          fill
          color="primary"
          class="margin-right"
          @click="addAnotherLine"
        >
          Add another line
        </f7-button>
        <f7-button
          v-if="isEdit"
          fill
          color="primary"
          @click="selectFromPriceList"
        >
          Select from Price list
        </f7-button>
      </f7-col>
      <f7-col
        width="100"
        medium="20"
      >
        <!-- <div
          :class="{
            summary: !$device.desktop,
            'margin-bottom': !$device.desktop
          }"
          class="summary-container padding"
        >
          <f7-row class="margin-bottom-half">
            <f7-col width="50">Profit Margin</f7-col>
            <f7-col width="50" class="text-align-right">
              {{ invoiceDetail.crossProfitMarginRatio | percent }}
            </f7-col>
          </f7-row>
          <f7-row class="margin-bottom-half">
            <f7-col width="50">Misc</f7-col>
            <f7-col width="50" class="text-align-right">
              {{ invoiceDetail.miscPercent | percent }}
            </f7-col>
          </f7-row>
        </div> -->
      </f7-col>
      <f7-col
        width="100"
        medium="40"
      >
        <div :class="{ summary: !$device.desktop }">
          <slot name="summary"></slot>
        </div>
      </f7-col>
    </f7-row>

    <!-- popup -->
    <add-product-from-price-list-popup
      @selectedProduct="selectedProduct"
      ref="selectFromPriceList"
    >
    </add-product-from-price-list-popup>
  </div>
</template>

<script>
import DataTable from '@/components/datatables';
import CellNumberInput from '@/components/inputs/CellNumberInput.vue';
import AddProductFromPriceListPopup from '../popups/AddProductFromPriceListPopup.vue';
import _ from 'lodash';
import Vue from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minValue, helpers } from '@vuelidate/validators';
import commonMixins from '../../mixins/common-mixin';
import { mapGetters } from 'vuex';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  mixins: [commonMixins],
  components: {
    DataTable,
    CellNumberInput,
    AddProductFromPriceListPopup,
  },
  props: {
    isEdit: { type: Boolean, default: true },
    invoiceDetail: { type: Object, default: () => {} },
  },
  data() {
    return {
      currentProductItems: [],
      discountSelectAll: false,
    };
  },

  computed: {
    ...mapGetters({
      estimateById: 'invoices/estimate/estimateById',
    }),
    ...mapGetters({
      subCategoryObjects: 'invoices/subCategory/objectList',
      categories: 'invoices/category/objectList',
    }),
    ...mapGetters('invoices/project', ['projectById', 'project']),
    productTableHeaders() {
      const self = this;
      return [
        {
          text: 'Item Details',
          align: 'left',
          sortable: false,
          value: 'productName',
          width: '20%',
        },
        {
          text: 'Quantity',
          align: 'right',
          sortable: false,
          value: 'quantity',
          width: '5%',
        },
        {
          text: 'Origin Cost',
          align: 'right',
          sortable: false,
          value: 'price',
          width: '10%',
        },
        {
          text: 'Price',
          align: 'right',
          sortable: false,
          value: 'priceWithProfitAndMisc',
          width: '10%',
        },
        {
          text: 'Amount',
          align: 'right',
          sortable: false,
          value: 'amount',
          width: '13%',
        },
        {
          text: 'Discount',
          align: 'center',
          sortable: false,
          value: 'discount',
          width: '5%',
          selectAll: !_.isEmpty(self.currentProductItems)
            ? {
                value: self.discountSelectAll,
                onChange: value => {
                  self.discountSelectAll = value;
                  self.currentProductItems = self.currentProductItems.map(
                    r => ({ ...r, discount: value })
                  );
                  self.changeItems();
                },
                borderColor: 'white',
              }
            : false,
        },
        {
          text: 'Tax',
          align: 'center',
          sortable: false,
          value: 'tax',
          width: '10%',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'action',
          width: '7%',
        },
      ];
    },
    estimateDetail() {
      return this.estimateById(this.invoiceDetail.estimateId);
    },
    projectDetail() {
      return this.projectById(this.invoiceDetail.projectId) || this.project;
    },
    dataItems() {
      return this.items({
        ...this.invoiceDetail,
        itemDetails: this.currentProductItems,
      });
    },
    productQuantityErrorMessage() {
      return index => {
        if (
          this.v$.currentProductItems.$each.$response.$errors[index].quantity
            .length === 0
        )
          return null;
        return (
          this.v$.currentProductItems.$each.$response.$errors[index].quantity[0]
            .$message || ''
        );
      };
    },
    priceErrorMessage() {
      return index => {
        if (
          this.v$.currentProductItems.$each.$response.$errors[index].price
            .length === 0
        )
          return null;
        return (
          this.v$.currentProductItems.$each.$response.$errors[index].price[0]
            .$message || ''
        );
      };
    },
    productNameErrorMessage() {
      return index => {
        if (
          this.v$.currentProductItems.$each.$response.$errors[index].productName
            .length === 0
        )
          return null;
        return (
          this.v$.currentProductItems.$each.$response.$errors[index]
            .productName[0].$message || ''
        );
      };
    },
  },
  watch: {
    invoiceDetail: {
      deep: true,
      immediate: true,
      handler(value) {
        this.currentProductItems = _.cloneDeep(value.itemDetails);
      },
    },
    currentProductItems: {
      deep: true,
      immediate: true,
      handler(value) {
        if (!_.isEmpty(value)) {
          this.discountSelectAll =
            value.findIndex(r => !r.discount) > -1 ? false : true;
        }
      },
    },
  },
  methods: {
    validate() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return false;
      }
      return true;
    },
    changeItems() {
      this.$emit('onChangeItems', this.currentProductItems);
    },
    cloneItem(item) {
      this.currentProductItems.push(item);
      this.changeItems();
    },

    deleteItem(index) {
      this.currentProductItems.splice(index, 1);
      this.changeItems();
    },

    addAnotherLine() {
      this.currentProductItems.push({
        productName: '',
        discount: this.discountSelectAll,
        price: 0,
        quantity: 1,
        tax: true,
      });
      this.changeItems();
    },

    selectFromPriceList() {
      this.$refs.selectFromPriceList.open();
    },

    updateProductProperty({ propName, value, index }) {
      let newItem = _.cloneDeep(this.currentProductItems[index]);
      newItem[propName] = value;
      Vue.set(this.currentProductItems, index, newItem);
      this.changeItems();
    },
    subCategoryName(subCategoryId) {
      const subCategory = this.subCategoryObjects.find(
        r => r.id == subCategoryId
      );
      return (subCategory || {}).name;
    },
    categoryName(categoryId) {
      const category = (this.categories || []).find(r => r.id == categoryId);
      return (category || {}).name;
    },
    selectedProduct(data) {
      const doesApplyTaxForMaterial = this.doesApplyTaxForMaterial(
        this.projectDetail,
        {},
        this.estimateDetail
      );
      const doesApplyTaxForLabor = this.doesApplyTaxForLabor(
        this.projectDetail,
        {},
        this.estimateDetail
      );
      let items = data.map(r => {
        return {
          productName: r.productItem,
          discount: this.discountSelectAll,
          price: r.price,
          quantity: 1,
          category: this.categoryName(r.categoryId),
          tax:
            this.categoryName(r.categoryId) === 'Labor'
              ? doesApplyTaxForLabor
              : doesApplyTaxForMaterial,
        };
      });
      this.currentProductItems = this.currentProductItems.concat(items);
      this.changeItems();
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      currentProductItems: {
        $each: helpers.forEach({
          quantity: {
            required: helpers.withMessage(
              VALIDATION_MESSAGE.REQUIRED_FIELD,
              required
            ),
            minValue: helpers.withMessage(
              VALIDATION_MESSAGE.GREATER_THAN_ZERO,
              minValue(1)
            ),
          },
          price: {
            required: helpers.withMessage(
              VALIDATION_MESSAGE.REQUIRED_FIELD,
              required
            ),
            minValue: helpers.withMessage(
              VALIDATION_MESSAGE.GREATER_THAN_ZERO,
              minValue(0.01)
            ),
          },
          productName: {
            required: helpers.withMessage(
              VALIDATION_MESSAGE.REQUIRED_FIELD,
              required
            ),
          },
        }),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.error-input {
  border-bottom: 1px solid red;
}

.custom-input {
  background-color: var(--f7-page-bg-color);
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 100px;
  padding: 3px;

  &_label {
    width: 100%;
  }

  &_value {
    text-align: right;
    float: right;
  }
}

@media (max-width: 768px) {
  .dark-header::v-deep .card-content {
    min-width: 800px;
  }
}

.dark-header::v-deep .card-content table thead {
  background: #bbbbbb;

  th {
    color: white;
    font-weight: 500;
  }
}

.summary {
  margin-left: calc(
    var(--f7-card-margin-horizontal) + var(--f7-safe-area-left)
  );
  margin-right: calc(
    var(--f7-card-margin-horizontal) + var(--f7-safe-area-right)
  );
}

.summary-container {
  background: white;
  border-radius: 4px;
}
</style>
