import axiosService from '@/services/axios.service';
import { firebase } from '../../../services/firebase.service';

export default {
  async sendEmail({ mailTo, shareUrl, projectName, companyName }) {
    return axiosService
      .post('/notification/push-email', {
        to: mailTo,
        template: 'share-project-photo',
        subject: {
          project_name: projectName,
          company_name: companyName,
        },
        content: {
          url: shareUrl,
          project_name: projectName,
          company_name: companyName,
        },
        scheduleSendingTime: firebase.firestore.Timestamp.now(),
      })
      .then(() => {
        // TODO: this trigger will be removed when implement email mail service scheduler
        return axiosService.post('/notification/trigger-email-service', {
          sender: '',
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('[Send Email]', error);
        throw new Error(error.message);
      });
  },
  async sendSms({ phoneNumber, shareUrl, projectName }) {
    if (projectName && projectName.length > 60) {
      projectName.length = 60;
      projectName = projectName + '...';
    }
    return axiosService
      .post('/notification/send-sms', {
        to: phoneNumber,
        content: `${projectName}'s photos: ${shareUrl}`,
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('[Send sms]', error);
        throw new Error(error.message);
      });
  },
};
