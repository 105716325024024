import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const estimateActions = new FirebaseActions(
    `/system_client/${tenant}/estimate`,
    'estimate'
  );
  return {
    bindEstimateList: estimateActions.bindCollection,
    bindEstimateListBy: estimateActions.bindCollectionBy,
    bindEstimateListBys: estimateActions.bindCollectionBys,
    unbindEstimateList: estimateActions.unbindCollection,

    bindEstimate: estimateActions.bindDocument,
    unbindEstimate: estimateActions.unbindDocument,

    updateEstimate: estimateActions.updateDocument,

    getBuilding(_, { estimateId, buildingId }) {
      return FirebaseActions.getDocument(
        `/system_client/${tenant}/estimate/${estimateId}/building`,
        buildingId
      );
    },
  };
};
