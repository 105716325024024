<template>
  <f7-popup
    :opened="popupOpened"
    @popup:close="closePopup"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="closePopup">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title> {{ project.title || '' }} </f7-nav-title>
        <f7-nav-right> </f7-nav-right>
      </f7-navbar>
      <div>
        <div
          v-if="projectPhotoAlbumList.length === 0 && !loading"
          class="camera"
        >
          <div class="bg-no-data">
            <f7-icon
              f7="photo_on_rectangle"
              class="icon-no-data"
            ></f7-icon>
          </div>
          <p>No album to load</p>
        </div>
        <div v-if="projectPhotoAlbumList.length > 0 && !loading">
          <div class="margin-top margin-left">
            Selected {{ selectedIds.length }} items
          </div>
          <f7-block>
            <div class="img-grid">
              <div
                v-for="album in projectPhotoAlbumList"
                :key="album.id"
                @touchstart="handlePressAndHold(album.id)"
                @touchend="handleTouchEnd"
                @mouseenter="handleMouseEnter(album.id)"
                @mouseleave="handleSelected(null)"
              >
                <div
                  :style="
                    !album.photoLasted.thumbnailUrl &&
                    'background: var(--f7-color-img-neutral)'
                  "
                  class="img-item"
                  @click.stop="clickAlbum(album.id, true)"
                  style="border-radius: 4px"
                >
                  <img
                    v-if="album.photoLasted.thumbnailUrl"
                    class="image-show"
                    :src="album.photoLasted.thumbnailUrl"
                  />
                  <f7-icon
                    v-if="!album.photoLasted.thumbnailUrl"
                    f7="photo"
                    class="no-img"
                  ></f7-icon>
                  <div
                    v-if="
                      $device.desktop &&
                      selectionMode &&
                      hoveredIds.includes(album.id)
                    "
                    class="img-bg img-bg-select"
                  >
                    <div class="img-bg-content">
                      <f7-checkbox
                        style="
                          position: absolute;
                          top: 10%;
                          left: 10%;
                          transform: scale(1.5);
                        "
                        :checked="selectedIds.includes(album.id)"
                        @click.native.stop
                        @change.native.stop="clickAlbum(album.id)"
                      ></f7-checkbox>
                    </div>
                  </div>
                  <div
                    v-if="!$device.desktop && selectionMode"
                    class="img-bg img-bg-select"
                  >
                    <div class="img-bg-content">
                      <f7-checkbox
                        style="
                          position: absolute;
                          top: 10%;
                          left: 10%;
                          transform: scale(1.2);
                        "
                        :checked="selectedIds.includes(album.id)"
                        @click.native.stop
                        @change.native.stop="clickAlbum(album.id)"
                      ></f7-checkbox>
                    </div>
                  </div>
                </div>
                <div
                  style="font-size: 12px"
                  class="album-name"
                >
                  {{ album.name }}
                </div>
                <div
                  style="font-size: 10px"
                  color="gray"
                >
                  Photos: <b>{{ album.photoTotals }}</b>
                </div>
              </div>
            </div>
          </f7-block>
        </div>
        <template v-if="selectedIds.length > 0">
          <f7-fab
            :class="`tab-btn tab-btn_2 download-tooltips-${uuid}`"
            position="right-bottom"
            slot="fixed"
            color="default"
            @click="handleDownload"
          >
            <f7-icon f7="cloud_download"></f7-icon>
          </f7-fab>
        </template>
        <project-photo-popup ref="projectPhotoPopup"></project-photo-popup>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProjectPhotoPopup from './ProjectPhotoPopup.vue';
import { uuid } from 'vue-uuid';

export default {
  components: {
    ProjectPhotoPopup,
  },
  data: () => {
    return {
      popupOpened: false,
      loading: null,
      pressHoldEvent: null,
      uuid: uuid.v4(),
      selectedIds: [],
      hoveredIds: [],
      selectionMode: false,
    };
  },
  mounted() {
    this.createTooltip();
  },
  computed: {
    ...mapGetters('scheduling/album', ['projectPhotoAlbumList']),
    ...mapGetters('scheduling/project', ['project']),
  },
  methods: {
    ...mapActions('scheduling/project', ['getProject']),
    ...mapActions('scheduling/album', ['getProjectPhotoAlbumListByProject']),
    ...mapActions('scheduling/photo', ['downloadAlbum']),

    clickAlbum(id, isShowPhoto = false) {
      if (isShowPhoto) {
        this.$refs.projectPhotoPopup.openPopup(id);
        this.selectedIds = [];
      } else {
        this.onSelect(id);
      }
    },
    onSelect(id) {
      if (this.selectedIds.includes(id)) {
        var index = this.selectedIds.indexOf(id);
        if (index > -1) {
          this.selectedIds.splice(index, 1);
        }
      } else {
        this.selectedIds.push(id);
      }
    },
    closePopup() {
      this.popupOpened = false;
      this.loading = null;
    },
    openAlbumPhoto(projectId) {
      this.popupOpened = true;
      if (projectId) {
        this.$f7.preloader.show();
        this.loading = true;
        const promises = [];
        promises.push(this.getProject(projectId));
        promises.push(this.getProjectPhotoAlbumListByProject(projectId));
        Promise.all(promises).then(() => {
          this.loading = false;
          this.$f7.preloader.hide();
        });
      }
    },
    handleMouseEnter(id) {
      if (this.$device.desktop) {
        this.handleSelected(id);
      }
    },
    handlePressAndHold(id) {
      if (!this.$device.desktop) {
        this.pressHoldEvent = setTimeout(() => {
          this.handleSelected(id, true);
        }, 1000);
      }
    },
    handleTouchEnd() {
      clearTimeout(this.pressHoldEvent);
      this.pressHoldEvent = null;
    },
    handleSelected(id, isSelectPhoto = false) {
      this.createTooltip();
      if (this.selectedIds.length === 0 && id === null) {
        this.selectionMode = false;
        this.hoveredIds = [];
      } else if (id === null) {
        const lastIndex = this.hoveredIds.length - 1;
        if (
          lastIndex >= 0 &&
          this.hoveredIds[lastIndex] !== null &&
          !this.selectedIds.includes(this.hoveredIds[lastIndex])
        ) {
          this.hoveredIds.splice(lastIndex, 1);
        }
      } else if (id && !this.hoveredIds.includes(id)) {
        this.selectionMode = true;
        this.hoveredIds.push(id);
        if (isSelectPhoto) {
          this.clickAlbum(id);
        }
      }
    },
    createTooltip() {
      if (!this.$device.desktop) return;

      const tooltips = [
        {
          targetEl: `.download-tooltips-${this.uuid}`,
          text: `Download album`,
        },
      ];
      tooltips.forEach(tooltip => {
        const el = this.$f7.tooltip.get(tooltip.targetEl);
        if (el) {
          this.$f7.tooltip.destroy(tooltip.targetEl);
        }
        this.$f7.tooltip.create({
          targetEl: tooltip.targetEl,
          cssClass: 'tooltip-fab-button-photo',
          text: tooltip.text,
        });
      });
    },
    async handleDownload() {
      this.createTooltip();
      this.$f7.toast
        .create({
          text: 'Downloading albums...',
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 3000,
        })
        .open();
      const albumsSelected = this.projectPhotoAlbumList.filter(r =>
        this.selectedIds.includes(r.id)
      );
      albumsSelected.forEach(album => {
        this.downloadAlbum({ albumId: album.id, albumName: album.name });
      });
      this.cancelSelect();
    },
    cancelSelect() {
      this.selectionMode = false;
      this.selectedIds = [];
      this.hoveredIds = [];
      this.$nextTick(() => {
        this.createTooltip();
      });
    },
  },
};
</script>

<style scoped>
.bg-no-data {
  width: 150px;
  height: 150px;
  background: var(--f7-color-img-neutral);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-no-data {
  font-size: 100px;
  color: var(--f7-color-icon-neutral);
}
.camera {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
}
.img-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  cursor: pointer;
  background-position: 50% center;
  background: white;
}
.no-img {
  position: absolute;
  font-size: 35px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--f7-color-icon-neutral);
}
.img-bg {
  position: absolute;
  border-radius: 4px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.img-bg-select {
  transition: all 150ms ease-in-out 0s;
  background: rgba(0, 0, 0, 0.3);
}
.img-item:hover .img-bg-not-select {
  background: rgba(0, 0, 0, 0.2);
}
.img-bg-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 1;
}
.image-show {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  object-fit: contain;
}
.album-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
