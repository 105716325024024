import { FirebaseActions } from '../../../../services/firebase.service';
import { searchClient } from '../../../../services/search.service';
import invoiceService from '../../../../services/invoice.service';

import algoliaService from '../../../../services/algolia.service';
import * as types from './types';
import * as constant from '../../../../utility/const';

export default tenant => {
  const RecurringInvoiceActions = new FirebaseActions(
    `/system_client/${tenant}/recurring-invoice`,
    'recurringInvoice'
  );

  const recurringInvoiceIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  const recurringInvoiceIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    bindRecurringInvoiceList: RecurringInvoiceActions.bindCollection,
    bindRecurringInvoiceListBy: RecurringInvoiceActions.bindCollectionBy,
    unbindRecurringInvoiceList: RecurringInvoiceActions.unbindCollection,

    bindRecurringInvoice: RecurringInvoiceActions.bindDocument,
    unbindRecurringInvoice: RecurringInvoiceActions.unbindDocument,

    addRecurringInvoice: RecurringInvoiceActions.createDocument,
    saveRecurringInvoice: RecurringInvoiceActions.updateDocument,

    getRecurringInvoiceBys: RecurringInvoiceActions.getDocumentBys,

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchRecurringInvoice(
      { state, commit },
      { query, attributesToRetrieve, pageNumber, filters }
    ) {
      const index =
        state.order === 'asc'
          ? recurringInvoiceIndexAsc
          : recurringInvoiceIndexDesc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_RECURRING_INVOICE}`,
        attributesToRetrieve:
          constant.RECURRING_INVOICE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.RECURRING_INVOICE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (pageNumber) {
        requestOptions.page = pageNumber;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(query, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    goToPage({ state, commit }, { pageName, filters }) {
      const index =
        state.order === 'asc'
          ? recurringInvoiceIndexAsc
          : recurringInvoiceIndexDesc;
      let pageNumber = 0;
      switch (pageName) {
        case 'next':
          pageNumber = state.page + 1;
          break;

        case 'prev':
          pageNumber = state.page - 1;
          break;

        case 'first':
          pageNumber = 0;
          break;

        case 'last':
          pageNumber = state.nbPages - 1;
          break;

        default:
          pageName = 0;
          break;
      }
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: pageNumber,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_RECURRING_INVOICE}`,
        attributesToRetrieve:
          constant.RECURRING_INVOICE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.RECURRING_INVOICE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreRecurringInvoice(
      { state, commit },
      { query, attributesToRetrieve, page, filters }
    ) {
      const index =
        state.order === 'asc'
          ? recurringInvoiceIndexAsc
          : recurringInvoiceIndexDesc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_RECURRING_INVOICE}`,
        attributesToRetrieve:
          constant.RECURRING_INVOICE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.RECURRING_INVOICE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(query, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setRecurringInvoiceNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },

    setRecurringInvoiceSearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },

    // Allow search desc only
    // setRecurringInvoiceSearchOrder({ commit }, payload) {
    //   commit(types.SET_SEARCH_ORDER, payload);
    // },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    async loadCurrentPage({ state, dispatch }) {
      const statusFilters = state.selectedStatusFilter
        .map(s => `status:${s}`)
        .join(' OR ');
      await dispatch('searchRecurringInvoice', {
        query: state.searchText,
        pageNumber: state.page,
        filters: statusFilters,
      });
      const { hits, nbPages } = state;
      if (!hits.length && nbPages > 0) {
        await dispatch('searchRecurringInvoice', {
          query: state.searchText,
          pageNumber: nbPages - 1,
          filters: statusFilters,
        });
      }
    },

    async createRecurringInvoice(
      { dispatch, commit, rootGetters },
      { recurringInvoice, isInvoiceGrid = false }
    ) {
      const user = rootGetters['setting/app/profile/user'];
      const doc = await invoiceService.addRecurringInvoiceDoc(
        user.tenantId,
        recurringInvoice
      );
      if (isInvoiceGrid) {
        await algoliaService.algoliaUpdateItem({
          tenantId: tenant,
          collection: 'recurring-invoice',
          id: doc.id,
        });
        await dispatch('loadCurrentPage');
      } else {
        commit(types.RECURRING_INVOICE_LIST_ITEM_ADD, {
          ...recurringInvoice,
          id: doc.id,
          invoiceNumber: doc.docNumber,
          createdAt: doc.createdAt,
        });
      }
      return doc;
    },

    async updateRecurringInvoice({ dispatch, commit }, { id, doc }) {
      await dispatch('saveRecurringInvoice', { id, doc });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: 'recurring-invoice',
        id,
      });
      return commit(types.RECURRING_INVOICE_LIST_ITEM_UPDATE, {
        id,
        doc,
      });
    },
    async deleteRecurringInvoice(
      { dispatch, commit },
      { id, isInvoiceGrid = false }
    ) {
      await dispatch('saveRecurringInvoice', {
        id,
        doc: { [constant.PROP_IS_DELETED]: true },
      });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: 'recurring-invoice',
        id,
      });
      if (isInvoiceGrid) {
        return dispatch('loadCurrentPage');
      } else {
        return commit(types.RECURRING_INVOICE_LIST_ITEM_REMOVE, id);
      }
    },
    setSelectedStatusFilter({ commit }, payload) {
      commit(types.SET_SELECTED_STATUS_FILTER, payload);
    },

    async getRecurringInvoiceByInvoiceGroupId(
      { commit, dispatch },
      invoiceGroupId
    ) {
      if (!invoiceGroupId) return;
      const recurringInvoiceList = await dispatch('getRecurringInvoiceBys', [
        { prop: 'invoiceGroupId', op: '==', val: invoiceGroupId },
      ]);

      commit(types.SET_RECURRING_INVOICE_LIST, recurringInvoiceList);
    },
  };
};
