import { mapActions, mapGetters } from 'vuex';
// import {
//   BUILDING_PRODUCT_PAGE,
//   SUMMARY_ESTIMATE_PAGE,
//   PRODUCT_TABLE_TYPE,
//   SUMMARY_ESTIMATE_TABLE_TYPE
// } from "../utility/const";
import _ from 'lodash';
import calcEstimateMixin from './calc-estimate-mixin';

export default {
  mixins: [calcEstimateMixin],
  computed: {
    ...mapGetters({
      financingProposalDefaultList:
        'proposal/proposal/financingProposalDefaultList',
      financingProposalList: 'proposal/proposal/financingProposalList',
      proposal: 'proposal/proposal/proposal',
    }),
    hasFinancingProposal() {
      return !_.isEmpty(this.financingProposalList);
    },
    // project cost is total estimate of project in proposal
  },
  methods: {
    ...mapActions('proposal/estimate', ['getBuilding']),

    initFinancingProposal({ buildingIDs, project, estimate, isNew }) {
      let financingProposal = _.cloneDeep(this.financingProposalDefaultList);
      if (this.hasFinancingProposal) {
        financingProposal = _.cloneDeep(this.financingProposalList);
      }

      return this.getProjectCost({
        buildingIDs,
        project,
        estimate,
        isNew,
      }).then(projectCost => {
        financingProposal = financingProposal.map(r => {
          let downPayment = r.downPayment || 0;
          let financingProposalDefaultId = r.financingProposalDefaultId;
          if (!this.hasFinancingProposal) {
            downPayment = parseFloat(
              ((projectCost * r.depositPercent) / 100).toFixed(2)
            );
            financingProposalDefaultId = r.id;
          }

          const rate_per_period = r.interestRate / 100 / 12;
          const number_of_payments = r.term;
          const present_value = -(projectCost - downPayment);
          return {
            ...r,
            financingProposalDefaultId,
            projectCost,
            downPayment,
            monthlyPayment: parseFloat(
              this.pmt(
                rate_per_period,
                number_of_payments,
                present_value
              ).toFixed(2)
            ),
          };
        });

        return financingProposal;
      });
    },

    getProjectCost({ buildingIDs, project, estimate, isNew }) {
      const refs = [];
      const self = this;
      for (const buildingId of buildingIDs) {
        refs.push(this.getBuilding({ estimateId: estimate.id, buildingId }));
      }

      return Promise.all(refs).then(buildingList => {
        const total = buildingList.reduce((sum, building) => {
          let productList = [];
          if (isNew) {
            productList = this.productList(building);
          } else {
            productList = (
              (
                (this.proposal.products || []).find(
                  item => item.buildingId === building.id
                ) || {}
              ).productData || []
            )
              .filter(product => product.checked)
              .map(item => {
                return { ...item, tax: true };
              });
          }

          sum += self.total(project, building, estimate, productList);
          return sum;
        }, 0);

        return total;
      });
    },

    /**
     * Copy of Excel's PMT function.
     * Credit: http://stackoverflow.com/questions/2094967/excel-pmt-function-in-js
     *
     * @param rate_per_period       The interest rate for the loan.
     * @param number_of_payments    The total number of payments for the loan in months.
     * @param present_value         The present value, or the total amount that a series of future payments is worth now;
     *                              Also known as the principal.
     * @param future_value          The future value, or a cash balance you want to attain after the last payment is made.
     *                              If fv is omitted, it is assumed to be 0 (zero), that is, the future value of a loan is 0.
     * @param type                  Optional, defaults to 0. The number 0 (zero) or 1 and indicates when payments are due.
     *                              0 = At the end of period
     *                              1 = At the beginning of the period
     * @returns {number}
     */
    pmt(
      rate_per_period,
      number_of_payments,
      present_value,
      future_value,
      type
    ) {
      future_value = typeof future_value !== 'undefined' ? future_value : 0;
      type = typeof type !== 'undefined' ? type : 0;

      if (rate_per_period != 0.0) {
        // Interest rate exists
        var q = Math.pow(1 + rate_per_period, number_of_payments);
        return (
          -(rate_per_period * (future_value + q * present_value)) /
          ((-1 + q) * (1 + rate_per_period * type))
        );
      } else if (number_of_payments != 0.0) {
        // No interest rate, but number of payments exists
        return -(future_value + present_value) / number_of_payments;
      }

      return 0;
    },
  },
};
