<template>
  <f7-input
    :style="inputDarkClass"
    type="month"
    placeholder="Please choose..."
    :value="currentMonth"
    class="input"
    @change="onChangeMonth($event.target.value)"
  >
  </f7-input>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  data() {
    return {
      currentMonth: moment().format('YYYY-MM'),
    };
  },
  computed: {
    inputDarkClass() {
      let theme =
        localStorage.getItem('themeDark') === 'true' ? 'dark' : 'light';
      return `color-scheme: ${theme};`;
    },
  },
  methods: {
    onChangeMonth(value) {
      this.currentMonth = value;
      const dates = this.getDates(value);
      this.$emit('onChangeFilter', dates);
    },

    getDates(value) {
      const dates = [];
      let currentDate = _.cloneDeep(moment(value, 'YYYY-MM')).startOf('month');
      let endDate = _.cloneDeep(moment(value, 'YYYY-MM')).endOf('month');
      while (currentDate.isSameOrBefore(endDate, 'day')) {
        const date = _.cloneDeep(currentDate);
        dates.push({
          id: date.format('MMDDYYYY'),
          date: date.format('MM/DD/YYYY'),
          dateHeader: date.format('ddd DD'),
          dateMoment: date,
        });
        currentDate = currentDate.add(1, 'days');
      }

      return dates;
    },
  },

  mounted() {
    if (
      this.$f7route.query.startDate &&
      this.$f7route.query.view === 'monthly'
    ) {
      const startDate = moment(
        this.$f7route.query.startDate,
        'MM/DD/YYYY'
      ).startOf('month');
      if (startDate) {
        this.currentMonth = startDate.format('YYYY-MM');
        const dates = this.getDates(startDate);
        this.$emit('onChangeFilter', dates);
      }
    }
  },
};
</script>
<style scoped></style>
