<template>
  <div ref="avatarContainer">
    <f7-block-title>{{ mainLabel }}</f7-block-title>
    <f7-block
      v-show="activeUsers"
      strong
      class="display-flex"
      style="flex-wrap: wrap"
    >
      <div
        v-for="(uid, index) in value"
        :key="uid"
        :class="index < usersHidden ? 'assignee-avatar' : ''"
      >
        <user-avatar
          v-if="index < usersHidden"
          :avatarSize="35"
          :imageUrl="userInfo(uid) ? userInfo(uid).photoURL : ''"
          :name="userInfo(uid) ? userInfo(uid).displayName : ''"
          :uid="uid"
        ></user-avatar>
      </div>
      <div
        v-if="(value || value || []).length > usersHidden"
        :class="`assignee-avatar more-assignee-avatar more-assignee-user-tooltip-${uuid}`"
      >
        <span>{{ `+${(value || value).length - usersHidden}` }}</span>
      </div>
      <div
        class="assignee-avatar cursor-pointer"
        v-if="!readOnly"
      >
        <user-avatar
          :avatarSize="35"
          @click.native="addChip()"
          icon-add
        ></user-avatar>
      </div>
    </f7-block>

    <f7-popup
      :opened="popupOpened"
      @popup:closed="popupOpened = false"
      @popup:opened="handlePopupOpened"
    >
      <f7-page>
        <f7-navbar title="Select User">
          <f7-nav-right>
            <f7-link @click.native="closePopup">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-searchbar
          search-container=".search-list"
          search-in=".item-title"
          :disable-button="!$theme.aurora"
          disable-button-text="Cancel"
          placeholder="Search in users"
          :clear-button="true"
          ref="searchListPopup"
        ></f7-searchbar>

        <f7-list class="search-list">
          <f7-list-item
            v-for="user in activeUsers"
            :key="user.uid"
            checkbox
            :title="user.displayName"
            :checked="checkedUser(user.uid)"
            @change="selectUser(user.uid)"
          ></f7-list-item>
        </f7-list>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import UserAvatar from '@/components/avatars/UserAvatar.vue';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { uuid } from 'vue-uuid';
import { COLLECTION_OPRATION_CARD } from '@/utility/const';

export default {
  components: {
    UserAvatar,
  },

  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    card: {
      type: Object,
      default: () => {
        return {};
      },
    },
    modelType: { type: String, default: 'project' },
    readOnly: { type: Boolean, default: false },
    isNew: { type: Boolean, default: false },
  },

  data: () => {
    return {
      popupOpened: false,
      usersHidden: 0,
      tooltip: null,
      uuid: uuid.v4(),
    };
  },
  async created() {
    await this.getGroupList([{ prop: 'isDeleted', op: '==', val: false }]);
  },
  mounted() {
    this.$nextTick(() => {
      this.usersHidden = this.calculateUsersHidden();
      if (this.value.length > this.usersHidden) {
        this.createTooltip(this.value);
      }
    });
  },
  computed: {
    ...mapGetters('swimlane/user', ['activeUsers']),

    userInfo() {
      return uid =>
        this.activeUsers.find(user => {
          return user.uid === uid;
        });
    },

    userLetter() {
      return user => (user ? user.displayName.charAt(0) : '');
    },

    checkedUser() {
      return uid => this.value.includes(uid);
    },

    mainLabel() {
      return this.value && this.value.length > 1 ? 'Assignees' : 'Assignee';
    },
  },

  methods: {
    ...mapActions('swimlane/group', ['getGroupList']),
    ...mapActions('common/notification', ['createNotificationByType']),
    deleteChip(uid) {
      let list = _.cloneDeep(this.value);
      list = _.remove(list, id => {
        return uid != id;
      });

      this.$emit('input', list);
    },

    addChip() {
      this.popupOpened = true;
    },
    createTooltip(value) {
      if (!this.$device.desktop) return;

      if (this.tooltip) {
        this.tooltip.destroy();
      }
      const maxUsers = 10;
      let displayNames = value
        .slice(this.usersHidden)
        .map(uid => {
          const userInfoResult = this.userInfo(uid);
          return userInfoResult ? userInfoResult.displayName : 'Access denied';
        })
        .filter(name => name !== '');

      let moreUsersText = '';
      if (displayNames.length > maxUsers) {
        moreUsersText = `<br/>and more ${
          displayNames.length - maxUsers
        } users...`;
        displayNames = displayNames.slice(0, maxUsers);
      }

      displayNames = displayNames.join('<br/>');
      this.$nextTick(() => {
        this.tooltip = this.$f7.tooltip.create({
          targetEl: `.more-assignee-user-tooltip-${this.uuid}`,
          text: displayNames + moreUsersText,
        });
      });
    },
    calculateUsersHidden() {
      const avatarWidth = 40;
      let usersHidden = 5;
      if (this.$device.desktop || this.$device.ipad) {
        const widthPopup = window.innerWidth / 2;
        const containerWidth =
          this.$refs.avatarContainer.offsetWidth === 0
            ? widthPopup
            : this.$refs.avatarContainer.offsetWidth;
        usersHidden = Math.floor((containerWidth - 120) / avatarWidth);
      }
      return usersHidden;
    },
    selectUser(uid) {
      let list = _.cloneDeep(this.value);

      if (list.includes(uid)) {
        list = _.remove(list, item => {
          return item != uid;
        });
        if (!this.isNew) {
          this.addNotification(uid, 'remove-user');
        }
      } else {
        list.push(uid);
        if (!this.isNew) {
          this.addNotification(uid, 'assign-user');
        }
      }

      this.$emit('input', list);
    },
    addNotification(assignee, type) {
      this.createNotificationByType({
        data: {
          assignees: [assignee],
          project: {
            title: this.card.title,
            id: this.card.id,
            type: 'project',
            entityName: COLLECTION_OPRATION_CARD,
          },
        },
        type: type,
      });
    },
    closePopup() {
      this.popupOpened = false;
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$refs.searchListPopup.$el
          .querySelector('.searchbar input')
          .focus();
      }
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.usersHidden = this.calculateUsersHidden();
        if (newValue.length > this.usersHidden) {
          this.createTooltip(newValue);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  margin-right: 5px;
}

.assignee-avatar {
  margin-right: 5px;
}
.more-assignee-avatar {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--f7-color-chip-neutral);
  font-size: 12px;
  cursor: pointer;
}
</style>
