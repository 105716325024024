<template>
  <f7-view :class="`page ${dark ? 'theme-dark' : ''}`">
    <f7-page
      no-toolbar
      no-navbar
      no-swipeback
      login-screen
    >
      <!-- <form> -->
      <f7-login-screen-title>Success</f7-login-screen-title>
      <f7-list>
        <f7-block-footer
          >Your account is successfully created!<br />
          Click bellow button to start login in.</f7-block-footer
        >
      </f7-list>
      <f7-list>
        <f7-button
          block
          fill
          @click="toLoginPage"
          >Login</f7-button
        >
      </f7-list>
      <!-- </form> -->
    </f7-page>
  </f7-view>
</template>

<script>
export default {
  methods: {
    toLoginPage() {
      this.$f7router.navigate(`/login?email=${this.$f7route.query.email}`, {
        pushState: true,
        reloadAll: true,
      });
    },
  },
};
</script>

<style></style>
