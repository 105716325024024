export default {
  expense: state => state.expense,

  // Fulltext search
  order: state => state.order,
  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
  searchText: state => state.searchText,
  payeeId: state => state.payeeId,
  queryFilters: state => {
    if (state.payeeId === '') return;
    return `payeeId:${state.payeeId}`;
  },
};
