export default {
  scheduleValue: state => state.scheduleValue,
  scheduleColunms: state => (state.scheduleValue || {}).scheduleColunms || [],

  // Fulltext search
  order: state => state.order,
  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
  searchText: state => state.searchText,
};
