import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const invoiceTemplateActions = new FirebaseActions(
    `/system_client/${tenant}/invoice_template`,
    'invoiceTemplate'
  );
  const emailTemplateActions = new FirebaseActions(
    `/system_client/${tenant}/email_template`,
    'emailTemplate'
  );

  const emailNotificationActions = new FirebaseActions(
    `/system_client/${tenant}/email_notification`,
    'emailNotification'
  );

  return {
    getInvoiceEmailTemplate({ dispatch }) {
      return dispatch('getEmailTemplateBys', [
        {
          prop: 'template',
          op: '==',
          val: 'invoice',
        },
      ]).then(list => {
        return list[0] || {};
      });
    },
    bindInvoiceTemplateList: invoiceTemplateActions.bindCollection,
    bindInvoiceTemplateListBy: invoiceTemplateActions.bindCollectionBy,
    unbindInvoiceTemplateList: invoiceTemplateActions.unbindCollection,

    bindInvoiceTemplate: invoiceTemplateActions.bindDocument,
    unbindInvoiceTemplate: invoiceTemplateActions.unbindDocument,

    createInvoiceTemplate: invoiceTemplateActions.createDocument,
    updateInvoiceTemplate: invoiceTemplateActions.updateDocument,

    getEmailTemplateBys: emailTemplateActions.getDocumentBys,
    createEmailNotification: emailNotificationActions.createDocument,
  };
};
