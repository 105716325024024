export default {
  productsByPL: {
    order: 'asc',
    hits: [],
    hitsPerPage: 25,
    nbHits: 0,
    nbPages: 0,
    page: 0,

    // options for filter
    vendorIdFilterList: [],
    categoryIdFilterList: [],
    subCategoryIdFilterList: [],
    roofTypeFilterList: [],
  },

  productsDefault: {
    order: 'asc',
    hits: [],
    hitsPerPage: 25,
    nbHits: 0,
    nbPages: 0,
    page: 0,

    // options for filter
    vendorIdFilterList: [],
    categoryIdFilterList: [],
    subCategoryIdFilterList: [],
    roofTypeFilterList: [],
  },
};
