/* eslint-disable no-console */
import { Workbox } from 'workbox-window';

const registerSW = () => {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox(`roofer-intel-service-worker.js`);
    wb.addEventListener('waiting', () => {
      wb.addEventListener('controlling', () => {
        window.location.reload();
      });

      wb.messageSW({ type: 'SKIP_WAITING' });
      // notification.open();

      // document.getElementById("link-noti").addEventListener("click", () => {
      //   wb.addEventListener("controlling", () => {});
      // });
    });

    wb.register();
  }
};

export default registerSW;
