<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="popupOpened = false"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar title="Select User">
        <f7-nav-right>
          <f7-link @click.native="closePopup">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar
        search-container=".search-list"
        search-in=".item-title"
        :disable-button="!$theme.aurora"
        disable-button-text="Cancel"
        placeholder="Search in users"
        :clear-button="true"
        ref="searchUserPopup"
      ></f7-searchbar>

      <f7-list class="search-list">
        <f7-list-item
          link
          v-for="user in activeUsers"
          :key="user.uid"
          :title="user.displayName"
          @click.native="selectUser(user.uid)"
        ></f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => {
    return { popupOpened: false };
  },

  computed: {
    ...mapGetters('dashboard/project', ['activeUsers', 'card']),

    userInfo() {
      return uid =>
        this.activeUsers.find(user => {
          return user.uid === uid;
        });
    },
  },

  methods: {
    selectUser(uid) {
      let receiverInfo = this.userInfo(uid);
      this.$emit('selectInspector', receiverInfo.displayName);
      this.popupOpened = false;
    },

    closePopup() {
      this.popupOpened = false;
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$refs.searchUserPopup.$el
          .querySelector('.searchbar input')
          .focus();
      }
    },
    open() {
      this.popupOpened = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  margin-right: 5px;
}

.assignee-avatar {
  margin-right: 5px;
}
</style>
