import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const priceListActions = new FirebaseActions(
    `/system_client/${tenant}/product_price_list`,
    'priceListItem'
  );
  return {
    getPriceListItemListBys: priceListActions.getDocumentBys,
    unbindPriceListItemList: priceListActions.unbindCollection,

    bindPriceListItem: priceListActions.bindDocument,
    unbindPriceListItem: priceListActions.unbindDocument,
    updatePriceList: priceListActions.updateDocument,

    async getPriceListItemList({ commit, dispatch }) {
      const list = await dispatch('getPriceListItemListBys', [
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);
      commit(types.SET_PRICE_LIST_ITEM_LIST, list);
    },
  };
};
