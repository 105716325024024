import { auth } from '@/services/firebase.service';
import { ACTION_CODE_CONSTRUCTION } from '../../../../utility/const';
import _ from 'lodash';
import moment from 'moment';

export default {
  projectId: state => state.projectId,
  project: state => state.project,

  actionListSorted: state => {
    return _.cloneDeep(state.actionList)
      .filter(item => {
        return (
          item.boards.includes('sales') ||
          item.boards.includes('pre-construction') ||
          item.boards.includes('post-construction') ||
          item.code === ACTION_CODE_CONSTRUCTION
        );
      })
      .map(item => {
        if (item.code === ACTION_CODE_CONSTRUCTION) {
          item.title = 'Construction';
        }
        return item;
      })
      .sort((a, b) => {
        return a.code - b.code;
      });
  },
  actionByCode: state => actionCode => {
    return state.actionList.find(action => action.code === actionCode) || {};
  },

  projectFilter: state => state.projectFilter,
  searchText: state => state.searchText,
  // Fulltext search
  order: state => state.order,
  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,

  queryFilters: state => {
    const { jobTypes, collection, createdFromDate, createdToDate } =
      state.projectFilter;
    let filters = [];
    if (jobTypes.length > 0) {
      filters.push(
        `(${jobTypes.map(i => `projectBusinessCode:${i}`).join(' OR ')})`
      );
    }

    if (collection === 'project-open') {
      filters.push(`projectStatus:open`);
    } else if (collection === 'archived') {
      filters.push(
        `(${['cancel', 'close', 'lost-deal', 'put-a-lien']
          .map(i => `projectStatus:${i}`)
          .join(' OR ')})`
      );
    } else {
      filters.push(
        `(assigneeIDs:${auth.currentUser.uid} OR additionalAssigneeIDs:${auth.currentUser.uid})`
      );
    }

    if (!_.isEmpty(createdFromDate)) {
      filters.push(
        `createdAtUnixTime >= ${moment(createdFromDate[0], 'YYYY-MM-DD')
          .startOf('day')
          .unix()}`
      );
    }
    if (!_.isEmpty(createdToDate)) {
      filters.push(
        `createdAtUnixTime <= ${moment(createdToDate[0], 'YYYY-MM-DD')
          .endOf('day')
          .unix()}`
      );
    }
    return `${filters.join(' AND ')}`;
  },
};
