<template>
  <div :class="`user-main-tooltip-${uid}`">
    <img
      v-if="imageUrl"
      class="avatar"
      :class="{ 'cursor-pointer': uid }"
      :style="avatarClass"
      :src="imageUrl"
      :alt="name"
    />
    <div
      v-else
      class="avatar"
      :class="{ 'cursor-pointer': uid }"
      :style="`${avatarClass} background: var(--f7-page-bg-color); ${
        border
          ? 'border-color: gray; border-width: 1px !important; border-style: solid;'
          : ''
      }`"
    >
      <span
        v-if="!iconAdd"
        class="text-align-center"
        :style="`font-size: ${avatarSize / 2}px; color: grey; font-weight: bold;`"
        >{{ nameSymbol }}</span
      >
      <span
        v-else
        class="display-flex align-items-center"
        :style="`font-size: ${
          avatarSize / 2
        }px; color: var(--f7-theme-color); font-weight: bold;`"
        ><f7-icon f7="plus"></f7-icon
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imageUrl: String,
    avatarSize: Number,
    name: String,
    iconAdd: { type: Boolean, default: false },
    border: { type: Boolean, default: false },
    uid: { type: String, default: '' },
    isArchive: { type: Boolean, default: false },
  },
  mounted() {
    if (this.uid) {
      this.createTooltip();
    }
  },
  methods: {
    createTooltip() {
      if (!this.$device.desktop) return;

      const self = this;
      this.$f7.tooltip.create({
        targetEl: `.user-main-tooltip-${self.uid}`,
        text: self.name
          ? self.name
          : self.isArchive
            ? 'Account inactive'
            : 'Account inactive/Access denied',
      });
    },
  },
  computed: {
    avatarClass() {
      const { avatarSize } = this;
      return `width: ${avatarSize}px; height: ${avatarSize}px;`;
    },
    nameSymbol() {
      if (this.imageUrl) return null;
      if (!this.name) return '!';
      if (!this.name.trim().length) return '!';
      let words = this.name.split(' ');
      if (words.length == 1) {
        return this.name.substring(0, 2).toUpperCase();
      }
      return (words[0][0] + '' + words[words.length - 1][0]).toUpperCase();
    },
  },
};
</script>
<style scoped>
.avatar {
  vertical-align: middle;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>
