import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const protestInsuranceClaimActions = new FirebaseActions(
    `/system_client/${tenant}/protest_insurance_claim`,
    'protest'
  );

  return {
    bindProtestListBys: protestInsuranceClaimActions.bindCollectionBys,
    unbindProtest: protestInsuranceClaimActions.unbindDocument,

    createProtest: protestInsuranceClaimActions.createDocument,
    updateProtest: protestInsuranceClaimActions.updateDocument,
    deleteProtest: protestInsuranceClaimActions.deleteDocument,
    getProtest: protestInsuranceClaimActions.getDocument,
  };
};
