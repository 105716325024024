<template>
  <f7-popup
    :opened="isShow"
    @popup:closed="$emit('close')"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Employee</f7-nav-title>
        <f7-nav-right></f7-nav-right>
      </f7-navbar>

      <f7-searchbar
        disable-button-text
        placeholder="Search employee"
        :clear-button="true"
        :value="searchValue"
        @input="searchValue = $event.target.value"
        @searchbar:disable="searchValue = ''"
        class="search-list-popup"
      ></f7-searchbar>

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found"></f7-list-item>
      </f7-list>
      <f7-list class="search-list searchbar-found contact-list">
        <f7-list-group
          v-for="(group, index) in userGroup(searchValue)"
          :key="index"
        >
          <f7-list-item
            :title="group.key"
            group-title
          ></f7-list-item>
          <f7-list-item
            no-chevron
            link
            v-for="user in group.data"
            :key="user.uid"
            :title="user.displayName"
            @click.native="select(user.uid)"
          ></f7-list-item>
        </f7-list-group>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isShow: Boolean,
  },
  data: () => {
    return {
      value: '',
      searchValue: '',
    };
  },

  computed: {
    ...mapGetters('scheduling/user', ['userGroup']),
  },

  methods: {
    /**
     * select
     */
    select(userId) {
      this.$emit('onSelected', userId);
      this.$emit('close');
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list-popup.searchbar input').focus();
      }
    },
  },
};
</script>
