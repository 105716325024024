<template>
  <div>
    <f7-list>
      <f7-list-input
        label="Perimeter"
        placeholder="Enter Perimeter"
        type="text"
        :value="currentEstimate.perimeter"
        @input="setEstimateValue('perimeter', $event.target.value)"
      >
        <input-icon
          slot="media"
          icon="arrow_2_squarepath"
        ></input-icon>
      </f7-list-input>
    </f7-list>

    <f7-block-header
      >Please fill in the information for at least one of the following
      sections</f7-block-header
    >

    <f7-row>
      <f7-col width="50">
        <f7-card>
          <f7-card-header class="no-border">Termination Bar</f7-card-header>
          <f7-card-content>
            <f7-list>
              <f7-list-input
                label="Length"
                placeholder="Enter length"
                type="text"
                :value="
                  currentEstimate.terminationBar
                    ? currentEstimate.terminationBar.length
                    : ''
                "
                @input="
                  setValue('terminationBar', 'length', $event.target.value)
                "
              >
                <input-icon
                  slot="media"
                  icon="resize_h"
                ></input-icon>
              </f7-list-input>

              <f7-list-item title="Counter Flashing">
                <f7-icon
                  slot="media"
                  color="red"
                  f7="square_line_vertical_square_fill"
                ></f7-icon>
                <f7-toggle
                  slot="after"
                  :checked="
                    currentEstimate.terminationBar
                      ? currentEstimate.terminationBar.counterFlashing
                      : false
                  "
                  @toggle:change="
                    setValue('terminationBar', 'counterFlashing', $event)
                  "
                ></f7-toggle>
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-col>
      <f7-col width="50">
        <f7-card>
          <f7-card-header class="no-border">Coated Metal</f7-card-header>
          <f7-card-content>
            <f7-list>
              <f7-list-input
                label="Length"
                placeholder="Enter length"
                type="text"
                :value="
                  currentEstimate.coatedMetal
                    ? currentEstimate.coatedMetal.length
                    : ''
                "
                @input="setValue('coatedMetal', 'length', $event.target.value)"
              >
                <input-icon
                  slot="media"
                  icon="resize_h"
                ></input-icon>
              </f7-list-input>

              <f7-list-input
                type="select"
                label="What is stretch out?"
                :value="
                  currentEstimate.coatedMetal
                    ? currentEstimate.coatedMetal.stretchOut
                    : ''
                "
                @change="
                  setValue('coatedMetal', 'stretchOut', $event.target.value)
                "
              >
                <input-icon
                  slot="media"
                  icon="arrow_down_right"
                ></input-icon>
                <option
                  value=""
                  hidden
                >
                  Select stretch out
                </option>
                <option
                  v-for="(item, index) in ['<=6', '6<x<=8']"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </f7-list-input>

              <f7-list-item title="Applying Cleat (*)">
                <f7-icon
                  slot="media"
                  color="red"
                  f7="square_line_vertical_square_fill"
                ></f7-icon>
                <f7-toggle
                  slot="after"
                  :checked="
                    currentEstimate.coatedMetal
                      ? currentEstimate.coatedMetal.applyingCleat
                      : false
                  "
                  @toggle:change="
                    setValue('coatedMetal', 'applyingCleat', $event)
                  "
                ></f7-toggle>
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-col>
      <f7-col width="50">
        <f7-card>
          <f7-card-header class="no-border">Coping</f7-card-header>
          <f7-card-content>
            <f7-list>
              <f7-list-input
                type="select"
                label="What type of metal?"
                :value="
                  currentEstimate.coping
                    ? currentEstimate.coping.typeOfMetal
                    : ''
                "
                @change="setValue('coping', 'typeOfMetal', $event.target.value)"
              >
                <input-icon
                  slot="media"
                  icon="hexagon"
                ></input-icon>
                <option
                  value=""
                  hidden
                >
                  Select type of metal
                </option>
                <option
                  v-for="(item, index) in typeOfMetal"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </f7-list-input>

              <f7-list-input
                type="select"
                label="What color?"
                :value="
                  currentEstimate.coping ? currentEstimate.coping.color : ''
                "
                @change="setValue('coping', 'color', $event.target.value)"
              >
                <input-icon
                  slot="media"
                  icon="color_filter"
                ></input-icon>
                <option
                  value=""
                  hidden
                >
                  Select color
                </option>
                <option
                  v-for="(item, index) in colors"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </f7-list-input>

              <f7-list-input
                label="Length"
                placeholder="Enter length"
                type="text"
                :value="
                  currentEstimate.coping ? currentEstimate.coping.length : ''
                "
                @input="setValue('coping', 'length', $event.target.value)"
              >
                <input-icon
                  slot="media"
                  icon="resize_h"
                ></input-icon>
              </f7-list-input>

              <f7-list-input
                type="select"
                label="What is stretch out"
                :value="
                  currentEstimate.coping
                    ? currentEstimate.coping.stretchOut
                    : ''
                "
                @change="setValue('coping', 'stretchOut', $event.target.value)"
              >
                <input-icon
                  slot="media"
                  icon="arrow_down_right"
                ></input-icon>
                <option
                  value=""
                  hidden
                >
                  Select stretch out
                </option>
                <option
                  v-for="(item, index) in [
                    '<=6',
                    '6<x<=8',
                    '8<x<=12',
                    '12<x<=16',
                    '16<x<=24',
                    '24<x<=48',
                  ]"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </f7-list-input>

              <f7-list-item title="Applying Cleat (*)">
                <f7-icon
                  slot="media"
                  color="red"
                  f7="square_line_vertical_square_fill"
                ></f7-icon>
                <f7-toggle
                  slot="after"
                  :checked="
                    currentEstimate.coping
                      ? currentEstimate.coping.applyingCleat
                      : false
                  "
                  @toggle:change="setValue('coping', 'applyingCleat', $event)"
                ></f7-toggle>
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-col>
      <f7-col width="50">
        <f7-card>
          <f7-card-header class="no-border">Edge Metal</f7-card-header>
          <f7-card-content>
            <f7-list>
              <f7-list-input
                type="select"
                label="What type of metal?"
                :value="
                  currentEstimate.edgeMetal
                    ? currentEstimate.edgeMetal.typeOfMetal
                    : ''
                "
                @change="
                  setValue('edgeMetal', 'typeOfMetal', $event.target.value)
                "
              >
                <input-icon
                  slot="media"
                  icon="hexagon"
                ></input-icon>
                <option
                  value=""
                  hidden
                >
                  Select type of metal
                </option>
                <option
                  v-for="(item, index) in typeOfMetal"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </f7-list-input>

              <f7-list-input
                type="select"
                label="What color?"
                :value="
                  currentEstimate.edgeMetal
                    ? currentEstimate.edgeMetal.color
                    : ''
                "
                @change="setValue('edgeMetal', 'color', $event.target.value)"
              >
                <input-icon
                  slot="media"
                  icon="color_filter"
                ></input-icon>
                <option
                  value=""
                  hidden
                >
                  Select color
                </option>
                <option
                  v-for="(item, index) in colors"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </f7-list-input>

              <f7-list-input
                label="Length"
                placeholder="Enter length"
                type="text"
                :value="
                  currentEstimate.edgeMetal
                    ? currentEstimate.edgeMetal.length
                    : ''
                "
                @input="setValue('edgeMetal', 'length', $event.target.value)"
              >
                <input-icon
                  slot="media"
                  icon="resize_h"
                ></input-icon>
              </f7-list-input>

              <f7-list-input
                type="select"
                label="What is stretch out"
                :value="
                  currentEstimate.edgeMetal
                    ? currentEstimate.edgeMetal.stretchOut
                    : ''
                "
                @change="
                  setValue('edgeMetal', 'stretchOut', $event.target.value)
                "
              >
                <input-icon
                  slot="media"
                  icon="arrow_down_right"
                ></input-icon>
                <option
                  value=""
                  hidden
                >
                  Select stretch out
                </option>
                <option
                  v-for="(item, index) in ['<=6', '6<x<=8', '8<x<=12']"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </f7-list-input>

              <f7-list-item title="Applying Cleat (*)">
                <f7-icon
                  slot="media"
                  color="red"
                  f7="square_line_vertical_square_fill"
                ></f7-icon>
                <f7-toggle
                  slot="after"
                  :checked="
                    currentEstimate.edgeMetal
                      ? currentEstimate.edgeMetal.applyingCleat
                      : false
                  "
                  @toggle:change="
                    setValue('edgeMetal', 'applyingCleat', $event)
                  "
                ></f7-toggle>
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-col>
    </f7-row>
    <f7-block-header
      >(*) I the face is >= 3 we need to have cleat</f7-block-header
    >
  </div>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
import { mapGetters } from 'vuex';
import mixins from '../../utility/mixins';
import _ from 'lodash';

export default {
  components: {
    InputIcon,
  },

  mixins: [mixins],

  computed: {
    ...mapGetters('estimate/estimate-page/estimate', ['currentEstimate']),

    colors() {
      return ['Galvalume', 'Prefinished'];
    },

    typeOfMetal() {
      return ['22 GA', '24 GA', '26 GA'];
    },
  },

  methods: {
    validate() {},
    init() {},

    setValue(section, field, value) {
      let object = _.cloneDeep(this.currentEstimate[section]) || {};
      object[field] = value;
      this.setEstimateValue(section, object);
    },
  },
};
</script>

<style lang="scss">
.item-input select.input-invalid {
  color: var(--f7-color-text-neutral) !important;
}
</style>
