import Vue from 'vue';
import * as types from './types';

export default {
  setAgreementList(state, payload) {
    Vue.set(state, 'agreementList', payload);
  },
  setAgreementSubscribe(state, payload) {
    Vue.set(state, 'agreementSubscribe', payload);
  },
  [types.SET_AGREEMENT_LIST](state, payload) {
    Vue.set(state, 'agreementList', payload);
  },
};
