/* eslint-disable no-console */
import * as types from './types';
import userService from '../../../../services/user.service';
import quickbooksService from '@/services/quickbooks.service';
import { StorageActions } from '../../../../services/firebase.service';
import _ from 'lodash';

export default {
  getUserList({ commit }) {
    return userService.getUserList().then(users => {
      commit(types.SET_USERS_LIST, users);
      return;
    });
  },
  getQBUserList({ commit }) {
    quickbooksService
      .checkAuth()
      .then(data => {
        if (data.success) {
          return quickbooksService.getEmployee();
        }
      })
      .then(users => {
        commit(types.SET_QB_USERS_LIST, users);
      })
      .catch(error => {
        console.log('Error get list QB employee', error.message);
      });
  },
  removeUserList({ commit }) {
    commit(types.SET_USERS_LIST, []);
  },

  getUser({ commit }, uid) {
    return userService
      .getUser(uid)
      .then(user => {
        commit(types.SET_USER, user);
      })
      .catch(() => {
        commit(types.SET_USER, null);
      });
  },

  // eslint-disable-next-line no-unused-vars
  async getUserNotCommit({ commit }, uid) {
    return userService.getUser(uid);
  },

  updateUser({ commit }, { uid, user }) {
    return userService.updateUser(uid, user).then(updatedUser => {
      commit(types.SET_USER, updatedUser);
      return updatedUser;
    });
  },

  // eslint-disable-next-line no-unused-vars
  addUser({ commit }, user) {
    return userService.addUser(user);
  },

  deleteUser({ commit }, uid) {
    return userService.deleteUser(uid).then(() => {
      commit(types.REMOVE_USER_LIST, uid);
      return;
    });
  },

  async changeUserPassword({ state }, { newPassword }) {
    const { uid } = state.user;
    await userService.changeUserPassword(uid, newPassword);
  },

  resetUser({ commit }) {
    commit(types.RESET_USER);
  },

  // eslint-disable-next-line no-unused-vars
  uploadAvatar({ commit }, fileData) {
    const base64Data = fileData.dataUrl;
    const file = fileData.info;
    const name = +new Date() + file.name || '';
    const fullPath = `users/avatars/${name}`;
    return StorageActions.uploadFileBase64(fullPath, base64Data, file);
  },

  // eslint-disable-next-line no-unused-vars
  async removeAvatar({ commit }, fullPath) {
    return StorageActions.removeFile(fullPath);
  },
};
