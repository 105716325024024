<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="handlePopupClosed"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Assembly Drawing</f7-nav-title>
        <f7-nav-right @click.native="done">
          <f7-link> Done </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar
        disable-button-text
        placeholder="Search assembly drawing"
        :clear-button="true"
        :value="searchValue"
        @input="searchValue = $event.target.value"
        @searchbar:disable="searchValue = ''"
        class="search-list-popup"
      ></f7-searchbar>

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found"></f7-list-item>
      </f7-list>
      <!-- select contact for creating new card -->
      <f7-list class="search-list searchbar-found contact-list">
        <f7-list-item
          v-for="(item, index) in searchedList"
          :key="index"
          @click.native.prevent="toggleCheckbox(item.id)"
        >
          <!-- @click.native=" select(item.id)" -->
          <f7-checkbox
            slot="media"
            name="metal-checkbox"
            :checked="isChecked(item.id)"
            @click.stop
          ></f7-checkbox>

          <div slot="title">
            <div
              v-if="item.image"
              class="image-preview"
            >
              <img
                class="image-show"
                :src="item.image"
              />
            </div>
            <div
              v-else
              class="image-preview"
              :style="`background-image: url(${NO_IMAGE_AVAILABLE})`"
            ></div>
            <div class="text-align-center">
              {{ `${item.drawingNumber} - ${item.drawingName}` }}
            </div>
          </div>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapGetters } from 'vuex';
import { NO_IMAGE_AVAILABLE } from '@/utility/const';
import { applyFilter } from '@/utility/filter-tools';
import { sortEarliest } from '@/utility/date-time-tool';
import _ from 'lodash';

export default {
  props: { roofType: String },

  data: () => {
    return {
      popupOpened: false,
      searchValue: '',
      NO_IMAGE_AVAILABLE,
      selectedList: [],
      list: [],
      checkedItems: [],
    };
  },

  computed: {
    ...mapGetters('estimate/estimate-page/assembly-drawing', {
      assemblyList: 'assemblyDrawingListByRoofType',
      assemblyDrawingById: 'assemblyDrawingById',
    }),
    searchedList() {
      // list = _.cloneDeep(this.list || [] /*this.assemblyList(this.roofType)*/);
      let list = applyFilter(this.list, this.searchValue, [
        i => `${i.drawingNumber} - ${i.drawingName}`,
      ]);
      list = sortEarliest(list);
      return list;
    },
  },

  methods: {
    open() {
      this.list = _.cloneDeep(this.assemblyList(this.roofType));
      this.popupOpened = true;
    },

    // select(assemblyId) {
    //   this.$emit("onSelected", assemblyId);
    //   this.popupOpened = false;
    // },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list-popup.searchbar input').focus();
      }
    },
    toggleCheckbox(itemId) {
      const index = this.checkedItems.indexOf(itemId);
      if (index === -1) {
        this.checkedItems.push(itemId);
      } else {
        this.checkedItems.splice(index, 1);
      }
      this.onMetalCheckBoxChange(itemId, this.isChecked(itemId));
    },
    isChecked(itemId) {
      return this.checkedItems.includes(itemId);
    },
    /**
     * DEV-1230: Est-On the metal section, instead of individually adding each item, can you maybe put a check on each item to select the 4 items that I need and then I can go in and edit the information? (David requried Feb 15)
     */
    onMetalCheckBoxChange(id, checked) {
      const foundItemIndex = this.selectedList.findIndex(item => item === id);

      if (checked === true && foundItemIndex < 0) {
        this.selectedList.push(id);
      } else if (checked === false && foundItemIndex >= 0) {
        this.selectedList.splice(foundItemIndex, 1);
      }
    },

    /**
     * DEV-1230: Est-On the metal section, instead of individually adding each item, can you maybe put a check on each item to select the 4 items that I need and then I can go in and edit the information? (David requried Feb 15)
     */
    done() {
      if (!_.isEmpty(this.selectedList)) {
        this.$emit('onSelected', this.selectedList);
      }
      this.popupOpened = false;
    },

    handlePopupClosed() {
      this.selectedList = [];
      this.list = [];
      this.checkedItems = [];
      this.popupOpened = false;
    },
  },
};
</script>

<style></style>
