import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.renderBarChart();
  },
  computed: {
    data: function () {
      return this.chartData;
    },
  },
  methods: {
    renderBarChart() {
      this.renderChart(this.data, this.options);
    },
  },
  watch: {
    chartData() {
      this.renderBarChart();
    },
  },
};
