<template>
  <div class="data-table data-table-init card">
    <!-- Card Header -->
    <slot
      v-if="showSearchBar"
      name="search"
    ></slot>
    <div
      v-if="showTitle"
      class="card-header"
    >
      <slot name="title"></slot>
    </div>
    <!-- Card Content -->
    <div class="card-content">
      <table>
        <thead>
          <tr>
            <slot name="header"> </slot>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in data"
            :key="index"
          >
            <slot
              name="body"
              v-bind:item="item"
              v-bind:index="index"
            ></slot>
          </tr>
        </tbody>
      </table>
      <div
        class="no-data"
        v-if="data.length === 0"
      >
        No Data!
      </div>
    </div>
    <div
      v-if="data.length > 0"
      :class="[
        !$device.desktop && 'data-table-footer-mobile',
        'data-table-footer',
      ]"
    >
      <slot name="footer"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Array, default: () => [] },
    // limit: { type: Number, default: 50 },
    // pageNumber: { type: Number, default: 1 },
    // pages: { type: Array, default: () => [] },
    showTitle: { type: Boolean, default: false },
    showSearchBar: { type: Boolean, default: false },
  },

  methods: {
    // onChangeLimit(value) {
    //   this.$emit("onChangePageLimit", parseInt(value));
    // }
  },
};
</script>

<style scoped lang="scss">
.no-data {
  border-top-width: 1px;
  border-top-color: #e5e5e5;
  border-top-style: solid;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  color: grey;
}
.data-table .card-content table thead {
  position: sticky;
  top: 0;
  background-color: var(--f7-color-bg-neutral);
  z-index: 999;
}

.data-table .data-table-footer-mobile {
  min-height: 85px;

  // & .data-table-rows-select {
  //   margin-left: 6px;
  // }
  // & .data-table-pagination {
  //   margin-left: 0px;
  // }
}
</style>
