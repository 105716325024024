<template>
  <div>
    <f7-block-title v-show="$device.desktop">Extended Eave/Rake</f7-block-title>
    <f7-card :class="{ 'margin-top-half': !$device.desktop }">
      <f7-card-content>
        <div class="canvas-container">
          <!-- nail -->
          <img
            class="display-none"
            :id="`vertical-nail-1_${assemblyId}`"
            :src="imgNail1"
          />
          <img
            class="display-none"
            :id="`horizontal-nail-2_${assemblyId}`"
            :src="imgNail1"
          />

          <!-- canvas -->
          <canvas
            v-if="$device.desktop"
            :ref="assemblyId"
            :id="assemblyId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @mousedown="handleMouseDown"
            @mousewheel="handleMouseWheel"
          />
          <canvas
            v-else
            :ref="assemblyId"
            :id="assemblyId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @click="handleMouseDown"
            @pointerdown="handlePointerDown"
            @pointermove="handlePointerMove"
            @pointerup="handlePointerUp"
            @pointercancel="handlePointerUp"
            @pointerleave="handlePointerUp"
            @pointerout="handlePointerUp"
          />
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>
<script>
import _ from 'lodash';
import paper from 'paper';
import { evaluate } from 'mathjs';
import canvasMixin from '../../utility/canvas-mixin';
import imgNail1 from '@/assets/img/metal-diagrams/nail1.png';
const inchToPx = 60;
const radius = 5;

export default {
  mixins: [canvasMixin],

  props: {
    data: Object,
    assemblyId: String,
    photoSelected: String,
  },

  data() {
    return {
      imgNail1,
      scope: null,
      dataToPx: {},
      draws: {},
      yBase: 150,
      xBase: 100,
      space: 200,
      width: 1200,
      height: 1000,
      dark: localStorage.getItem('themeDark') === 'true',
    };
  },

  mounted() {
    this.scope = new paper.PaperScope();
    this.scope.setup(this.assemblyId);
    this.drawAll();
  },

  methods: {
    initCanvas() {
      this.removeProject(this.scope);
      this.scope = new paper.PaperScope();
      this.scope.setup(this.assemblyId);
      if (!_.isEmpty(this.scope.view)) {
        this.scope.view.viewSize = new paper.Size(
          this.$device.desktop ? this.width : this.widthMobile,
          this.$device.desktop ? this.height : this.heightMobile
        );
      }
    },

    reset() {
      this.scope && this.scope.project.activeLayer.removeChildren();
    },

    setDraws(name, value) {
      this.draws[name] = value;
    },

    handleDraw1() {
      let path1 = new paper.Path({
        strokeColor: this.dark ? 'white' : 'black',
        strokeWidth: '3',
      });
      let path2 = new paper.Path({
        strokeColor: this.dark ? 'white' : 'black',
        strokeWidth: '3',
      });

      const { a1, b1, c1, d1, angle } = this.dataToPx;
      const pointA = new paper.Point(this.xBase, this.yBase);
      const pointB = new paper.Point(pointA.x + a1 - b1, pointA.y);
      const pointC = new paper.Point(pointB.x + b1 - radius, pointB.y);
      const pointC1 = new paper.Point(pointC.x, pointC.y + radius * 2);
      const centerCC1 = this.pointOnPathByStart(pointC, pointC1, 2);
      const throughCC1 = pointC.rotate(90, centerCC1);

      const pointD = new paper.Point(pointB.x, pointB.y + radius * 2);
      let pointE = new paper.Point(pointD.x, pointD.y + c1 - d1);

      const pointE1 = new paper.Point(pointE.x, pointE.y - d1 + radius);
      const pointE2 = new paper.Point(pointE1.x + radius * 2, pointE1.y);
      const centerE1E2 = this.pointOnPathByStart(pointE1, pointE2, 2);
      const throughE1E2 = pointE1.rotate(90, centerE1E2);
      const pointE3 = new paper.Point(pointE2.x, pointE2.y + d1 - radius);

      path1.add(pointA, pointB, pointC);
      path1.arcTo(throughCC1, pointC1);
      path1.add(pointD);
      path1.rotate(90 - angle, pointD);
      path1.add(pointE);

      this.setDraws('draw1Paths', [path1]);

      this.handleDrawDescriptionDiagonal(
        throughCC1.rotate(90 - angle, pointD),
        pointA.rotate(90 - angle, pointD),
        180 - angle,
        radius,
        `a1: ${this.data.a1}`,
        'center',
        50,
        'minus'
      );

      this.handleDrawDescriptionDiagonal(
        throughCC1.rotate(90 - angle, pointD),
        pointB.rotate(90 - angle, pointD),
        180 - angle,
        radius,
        `b1: ${this.data.b1}`,
        'center',
        30,
        'minus'
      );
      this.handleDrawDescriptionRight(
        pointD,
        throughE1E2.rotate(135, pointE),
        `c1: ${this.data.c1}`
      );

      this.handleDrawDescriptionDiagonal(
        pointE3.rotate(135, pointE),
        throughE1E2.rotate(135, pointE),
        135,
        radius * -1,
        `d1: ${this.data.d1}`,
        'end'
      );

      path2.add(pointE, pointE1);
      path2.arcTo(throughE1E2, pointE2);
      path2.add(pointE3);
      path2.rotate(135, pointE);

      path1.join(path2);

      this.drawArc(
        new paper.Point(pointD.x + b1 / 2, pointD.y).rotate(90 - angle, pointD),
        pointD,
        new paper.Point(pointD.x, pointD.y + b1 / 2),
        angle
      );
    },

    handleDraw2() {
      let path = new paper.Path({
        strokeColor: this.dark ? 'white' : 'black',
        strokeWidth: '3',
      });

      const { a1, b1, a2, b2, c2, angle } = this.dataToPx;
      let pointA = new paper.Point(
        this.xBase + this.space + a1 + b1,
        this.yBase
      );
      const pointB = new paper.Point(pointA.x + a1 - b1, pointA.y);
      const pointD = new paper.Point(pointB.x, pointB.y + radius * 2);

      let pointF = new paper.Point(pointA.x + a1 - a2 - b1, pointA.y + 6);
      let pointG = new paper.Point(pointF.x + a2 - 7, pointF.y);
      pointF = pointF.rotate(90 - angle, pointD);
      let pointG1 = pointG.rotate(90 - angle, pointD);

      const distanceX = pointG1.x - pointG.x;

      let pointG2 = new paper.Point(pointG.x - distanceX, pointG.y);
      pointG2 = pointG2.rotate(90 - angle, pointD);

      let pointH = new paper.Point(
        pointG2.x,
        pointG2.y + b2 + 4 + pointG.y - pointG2.y
      );
      let pointI = new paper.Point(pointH.x, pointH.y - c2 + radius);
      pointI = pointI.rotate(135, pointH);

      path.add(pointF, pointG2, pointH, pointI);

      this.setDraws('draw2Paths', [path]);

      this.handleDrawDescriptionDiagonal(
        pointF,
        pointG2,
        180 - angle,
        radius,
        `a2: ${this.data.a2}`,
        'center',
        50,
        'minus'
      );

      this.handleDrawDescriptionRight(pointG2, pointH, `b2: ${this.data.b2}`);

      this.handleDrawDescriptionDiagonal(
        pointH,
        pointI,
        135,
        0,
        `c2: ${this.data.c2}`,
        'end'
      );
    },

    handleDraw3() {
      const { draw1Paths, draw2Paths } = this.draws;
      const { a1, b1, c1, d1, angle } = this.dataToPx;
      let path1 = draw1Paths[0].clone();
      let path2 = draw2Paths[0].clone();

      const y = this.yBase + c1 + 100;

      path1.position.y += y;
      path2.position.x -= this.space + a1 + b1;
      path2.position.y += y;

      const pointGPath2 = path2.segments[1].point;
      const pointHPath2 = path2.segments[2].point;
      const pointAPath1 = path1.segments[0].point;
      const pointBPath1 = path1.segments[1].point;
      const throughCC1Path1 = path1.segments[3].point;
      const pointDPath1 = path1.segments[5].point;

      let path3 = new paper.Path({
        strokeColor: 'grey',
        strokeWidth: '2',
      });

      let pointL = new paper.Point(this.xBase / 2, pointDPath1.y + 5);
      let pointM = new paper.Point(pointL.x + 50 + a1 - b1 - 15, pointL.y);
      pointL = pointL.rotate(90 - angle, pointM);
      pointM = pointM.rotate(90 - angle, pointM);
      const pointN = new paper.Point(pointM.x, pointM.y + c1 + d1 + 50);

      path3.add(pointL, pointM, pointN);

      // nail
      let nail1 = new paper.Raster(`vertical-nail-1_${this.assemblyId}`);
      nail1.size = new paper.Size(70, 20);
      nail1.rotate(90);
      let pointNail1 = new paper.Point(
        pointGPath2.x - (a1 - b1) / 3,
        pointGPath2.y
      );
      pointNail1 = pointNail1.rotate(90 - angle, pointGPath2);
      nail1.position = new paper.Point(pointNail1.x, pointNail1.y + 31);
      nail1.rotate(180 - angle - 90);

      let nail2 = new paper.Raster(`horizontal-nail-2_${this.assemblyId}`);
      nail2.size = new paper.Size(70, 20);
      nail2.rotate(180);
      nail2.position = new paper.Point(
        pointGPath2.x - 31,
        pointDPath1.y + (c1 - d1) / 2
      );

      // info product
      const start1 = this.pointOnPathByStart(pointAPath1, pointBPath1, 3);
      const end1 = new paper.Point(start1.x, start1.y - 70);
      this.drawInfoProduct([start1, end1], 'Standing Seam', 'bottom', 'right');

      const end2 = new paper.Point(throughCC1Path1.x + 100, throughCC1Path1.y);
      this.drawInfoProduct(
        [throughCC1Path1, end2],
        'Edge Metal',
        'left',
        'right'
      );

      const start3 = this.pointOnPathByStart(pointGPath2, pointHPath2, 3);
      const end3 = new paper.Point(start3.x + 200, start3.y);
      this.drawInfoProduct([start3, end3], 'Clip', 'left', 'right');
    },

    drawAll() {
      this.handleDraw1();
      this.handleDraw2();
      this.handleDraw3();
      this.resizableLayer();
    },

    selectedDraw(name) {
      if (name) {
        const lastChar = name.charAt(name.length - 1);
        (this.draws[`draw${lastChar}Paths`] || []).forEach(
          path => (path.selected = true)
        );
      } else {
        if (this.scope) {
          this.scope.project.activeLayer.selected = false;
        }
      }
    },

    // handleClick(event) {
    //   this.scope.project.activeLayer.selected = false;
    //   this.$emit("setPhotoSelected", "");

    //   const strokeBounds = Object.values(this.draws).map(
    //     r => r[0].strokeBounds
    //   );

    //   for (let i = 0; i < strokeBounds.length; i += 1) {
    //     if (
    //       event.point.x > strokeBounds[i].topLeft.x &&
    //       event.point.y > strokeBounds[i].topLeft.y &&
    //       event.point.x < strokeBounds[i].bottomRight.x &&
    //       event.point.y < strokeBounds[i].bottomRight.y
    //     ) {
    //       this.$emit("setPhotoSelected", `photo${i + 1}`);
    //       this.selectedDraw(`photo${i + 1}`);
    //     }
    //   }
    // }
  },

  watch: {
    data: {
      handler(val) {
        const keys = ['a1', 'b1', 'c1', 'd1', 'a2', 'b2', 'c2'];
        for (let i of keys) {
          let parseValue = this.convertStringToExpression(val[i]);
          this.dataToPx[i] = evaluate(parseValue) * inchToPx;
        }
        this.dataToPx.angle = evaluate(_.cloneDeep(val.angle));
      },
      deep: true,
      immediate: true,
    },
    photoSelected: {
      handler(val) {
        this.selectedDraw(val);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.canvas-container {
  position: relative;
}
canvas[resize] {
  width: 100%;
  height: 100%;
}
</style>
