<template>
  <div>
    <div class="tableFixHead">
      <table class="table">
        <thead>
          <tr>
            <th
              v-show="!isMyScheduling"
              width="200px"
            >
              <input-search
                :value="searchText"
                @input="onChangeSearchText($event)"
              ></input-search>
            </th>
            <th
              class="label-cell"
              v-for="(d, index) in currentWeek"
              :key="index"
            >
              <span class="table-head-label">{{ d.dateHeader }}</span>
            </th>
          </tr>
        </thead>

        <tbody v-if="dataSchedule.length > 0">
          <tr
            v-for="u in dataSchedule"
            :key="u.uid"
            class="no-border-top"
          >
            <td
              v-show="!isMyScheduling"
              class="label-cell"
              width="200px"
            >
              <div class="display-flex align-items-center">
                <user-avatar
                  :avatarSize="30"
                  :name="u.displayName"
                  :imageUrl="u.photoURL"
                />
                <div class="margin-left-half">{{ u.displayName }}</div>
              </div>
            </td>
            <td
              class="label-cell cursor-pointer"
              v-for="(day, dayIndex) in u.schedules"
              :key="dayIndex"
              @drop="handleDrop(u.uid, dayIndex)"
              @dragover.prevent
              @dragenter.prevent
            >
              <div
                v-show="isCopy"
                class="paste-button"
              >
                <circle-button
                  icon="doc_on_clipboard"
                  @onClick="paste({ userId: u.uid, date: day.date })"
                ></circle-button>
              </div>

              <div class="card-container">
                <!-- line > 4 to show more -->
                <div v-if="day.details.length > 4">
                  <schedule-card
                    :item="day.details[0]"
                    @openEditPopup="
                      openEditPopup({ userId: u.uid, date: day.date })
                    "
                    :isDrag="isDrag"
                    @dragstart="handleDragStart(u.uid, dayIndex, 0)"
                    @copy="copy($event)"
                    @handleDelete="handleDelete"
                    @openAlbumPhoto="openAlbumPhoto"
                    @openAddressOnMap="openAddressOnMap"
                  ></schedule-card>
                  <schedule-card
                    :item="day.details[1]"
                    @openEditPopup="
                      openEditPopup({ userId: u.uid, date: day.date })
                    "
                    :isDrag="isDrag"
                    @dragstart="handleDragStart(u.uid, dayIndex, 1)"
                    @copy="copy($event)"
                    @handleDelete="handleDelete"
                    @openAlbumPhoto="openAlbumPhoto"
                    @openAddressOnMap="openAddressOnMap"
                  ></schedule-card>
                  <schedule-card
                    :item="day.details[2]"
                    @openEditPopup="
                      openEditPopup({ userId: u.uid, date: day.date })
                    "
                    :isDrag="isDrag"
                    @dragstart="handleDragStart(u.uid, dayIndex, 2)"
                    @copy="copy($event)"
                    @handleDelete="handleDelete"
                    @openAlbumPhoto="openAlbumPhoto"
                    @openAddressOnMap="openAddressOnMap"
                  ></schedule-card>

                  <!-- show more -->
                  <f7-link
                    class="show-more"
                    :popover-open="`.show-popover_${u.uid}_${dayIndex}`"
                    >Show more</f7-link
                  >

                  <f7-popover
                    style="width: 350px"
                    :backdrop="false"
                    :class="`show-popover_${u.uid}_${dayIndex}`"
                  >
                    <f7-card>
                      <f7-card-header class="justify-content-flex-end">
                        {{ day.dateHeader }} - {{ u.displayName }}
                      </f7-card-header>
                      <f7-card-content>
                        <schedule-card
                          v-for="(detail, detailIndex) in day.details"
                          :key="detailIndex"
                          :item="detail"
                          :isDrag="isDrag"
                          @openEditPopup="
                            openEditPopup({ userId: u.uid, date: day.date })
                          "
                          @dragstart="
                            handleDragStart(u.uid, dayIndex, detailIndex)
                          "
                          @copy="copy($event)"
                          @handleDelete="handleDelete"
                          @openAlbumPhoto="openAlbumPhoto"
                          @openAddressOnMap="openAddressOnMap"
                        ></schedule-card>
                      </f7-card-content>
                    </f7-card>
                  </f7-popover>
                </div>

                <div v-else>
                  <schedule-card
                    v-for="(detail, detailIndex) in day.details"
                    :key="detailIndex"
                    :item="detail"
                    :isDrag="isDrag"
                    @openEditPopup="
                      openEditPopup({ userId: u.uid, date: day.date })
                    "
                    @dragstart="handleDragStart(u.uid, dayIndex, detailIndex)"
                    @copy="copy($event)"
                    @handleDelete="handleDelete"
                    @openAlbumPhoto="openAlbumPhoto"
                    @openAddressOnMap="openAddressOnMap"
                  ></schedule-card>
                </div>
              </div>

              <div class="add-button">
                <circle-button
                  icon="plus"
                  @onClick="openAddPopup({ userId: u.uid, date: day.date })"
                ></circle-button>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              class="text-align-center"
              colspan="8"
            >
              No data!
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <edit-schedule-popup
      :isShow="isShowEditPopup"
      @close="isShowEditPopup = false"
    ></edit-schedule-popup>
    <album-photo-popup ref="albumPhoto"></album-photo-popup>
    <view-address-popup ref="viewAddressPopup"></view-address-popup>
  </div>
</template>
<script>
import UserAvatar from '@/components/avatars/UserAvatar.vue';
import { mapActions, mapGetters } from 'vuex';
import { applyFilter } from '@/utility/filter-tools';
import EditSchedulePopup from '../popups/EditSchedulePopup.vue';
import ScheduleCard from '@/plugins/scheduling/components/cards/ScheduleCard.vue';
import InputSearch from '@/plugins/scheduling/components/inputs/InputSearch.vue';
import CircleButton from '../buttons/CircleButton.vue';
import AlbumPhotoPopup from '../popups/AlbumPhotoPopup.vue';
import ViewAddressPopup from '../popups/ViewAddressPopup.vue';
import _ from 'lodash';
import { auth } from '../../../../services/firebase.service';
import moment from 'moment';
import emailNotificationMixins from '../../mixins/email-notifications';
const TIME_FORMAT = 'HH:mm';
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

export default {
  props: {
    currentWeek: Array,
    isDrag: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditSchedulePopup,
    UserAvatar,
    ScheduleCard,
    InputSearch,
    CircleButton,
    AlbumPhotoPopup,
    ViewAddressPopup,
  },
  data: () => {
    return {
      searchText: '',
      isShowEditPopup: false,
      isCopy: false,
      dataCopy: {},
      draggedItem: null,
      draggedUserId: null,
      draggedDayIndex: null,
      draggedDetailIndex: null,
    };
  },
  mixins: [emailNotificationMixins],
  methods: {
    ...mapActions('scheduling/scheduling', [
      'selectItem',
      'setScheduleId',
      'createSchedule',
      'updateSchedule',
      'deleteSchedule',
      'getScheduleListBys',
      'getSchedulesByUserAndProject',
    ]),
    ...mapActions('scheduling/project', [
      'getProjectById',
      'handleUpdateAssigneeIdsWhenAddOrCopy',
      'updateAssigneeIdsProject',
      'handleUpdateAssigneeIdsWhenDelete',
      'updateAdditionalAssigneeIdsProjectAlgolia',
    ]),
    ...mapActions('scheduling/chain', [
      'deleteChain',
      'updateChain',
      'getScheduleChain',
    ]),

    convertDateToTimestamp(date) {
      return this.$google.firebase.firestore.Timestamp.fromDate(
        new Date(moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY'))
      );
    },

    handleDragStart(userId, dayIndex, detailIndex) {
      this.draggedItem = this.dataSchedule.find(
        u => u.uid === userId
      ).schedules[dayIndex].details[detailIndex];
      this.draggedUserId = userId;
      this.draggedDayIndex = dayIndex;
      this.draggedDetailIndex = detailIndex;
    },

    async handleDrop(userId, dayIndex) {
      if (!this.draggedItem) {
        this.clearDataDrag();
        return;
      }

      if (userId === this.draggedUserId && dayIndex === this.draggedDayIndex) {
        this.clearDataDrag();
        return;
      }

      const newDataSchedule = _.cloneDeep(this.dataSchedule);

      const dataScheduleUser = newDataSchedule.find(u => u.uid === userId)
        .schedules[dayIndex];

      if (this.checkTimeOverlap(this.draggedItem, dataScheduleUser.details)) {
        this.$ri.dialog.openErrorDialog({
          title: 'Error',
          content: 'Overlap detected. Please recheck time?',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
        this.clearDataDrag();
        return;
      }

      const newDraggedItem = {
        ...this.draggedItem,
        userId: userId,
        date: this.convertDateToTimestamp(dataScheduleUser.date),
      };

      this.$f7.preloader.show();
      const promises = [];

      if (userId === this.draggedUserId) {
        await this.updateSchedule({
          id: newDraggedItem.id,
          doc: newDraggedItem,
        });
        promises.push(this.sendUpdatedEmailNotification(newDraggedItem));
      } else {
        const res = await Promise.all([
          this.createSchedule(newDraggedItem),
          this.deleteSchedule(newDraggedItem.id),
        ]);
        promises.push(this.sendCreatedEmailNotification(newDraggedItem));
        promises.push(
          this.addRemindScheduleStartEmailNotification({
            ...newDraggedItem,
            id: res[0],
          })
        );
      }

      // update start/end of scheduleChain
      if (newDraggedItem.scheduleChainId) {
        promises.push(
          this.handleUpdateChainDate(newDraggedItem.scheduleChainId)
        );
      }
      await Promise.all(promises);
      this.$f7.preloader.hide();
      this.clearDataDrag();
    },

    clearDataDrag() {
      this.draggedItem = null;
      this.draggedUserId = null;
      this.draggedDayIndex = null;
      this.draggedDetailIndex = null;
    },

    checkTimeOverlap(draggedItem, scheduleDetail) {
      if (_.isEmpty(scheduleDetail)) return false;

      const timeDemo = '2024-01-01';
      const draggedItemStart = new Date(`${timeDemo} ${draggedItem.startTime}`);
      const draggedItemFinish = new Date(
        `${timeDemo} ${draggedItem.finishTime}`
      );

      for (const item of scheduleDetail) {
        const itemStart = new Date(`${timeDemo} ${item.startTime}`);
        const itemFinish = new Date(`${timeDemo} ${item.finishTime}`);

        if (
          (draggedItemStart >= itemStart && draggedItemStart < itemFinish) ||
          (draggedItemFinish > itemStart && draggedItemFinish <= itemFinish)
        ) {
          return true;
        }
      }

      return false;
    },

    async handleUpdateChainDate(scheduleChainId) {
      if (_.isEmpty(scheduleChainId)) return;
      let schedulesByChainId = await this.getScheduleListBys([
        {
          prop: 'scheduleChainId',
          val: scheduleChainId,
          op: '==',
        },
      ]);
      schedulesByChainId = this.sortLatestByDate(schedulesByChainId);
      let doc = {
        startDate: schedulesByChainId[schedulesByChainId.length - 1].date,
        endDate: schedulesByChainId[0].date,
      };
      return this.updateChain({
        id: scheduleChainId,
        doc,
      });
    },

    onChangeSearchText(value) {
      this.searchText = value;
    },
    openAddPopup(item) {
      this.$emit('openAddPopup');
      this.selectItem(item);
    },
    openEditPopup(item) {
      this.isShowEditPopup = true;
      this.selectItem(item);
    },
    copy(item) {
      this.isCopy = true;
      this.dataCopy = item;
    },

    validateOverTimeWhenCopy(data) {
      const start = moment(data.startTime, TIME_FORMAT);
      const end = moment(data.finishTime, TIME_FORMAT);

      const list =
        this.scheduleList.filter(
          s =>
            s.userId === data.userId &&
            s.date === moment(data.date.toDate()).format('MM/DD/YYYY')
        ) || [];

      if (list.length > 0) {
        for (const item of list) {
          const startTime = moment(item.startTime, TIME_FORMAT);
          const finishTime = moment(item.finishTime, TIME_FORMAT);
          if (
            startTime.isBetween(start, end) ||
            start.isBetween(startTime, finishTime) ||
            start.isSame(startTime)
          ) {
            return false;
          } else if (
            finishTime.isBetween(start, end) ||
            end.isBetween(startTime, finishTime) ||
            end.isSame(finishTime)
          ) {
            return false;
          }
        }
      }
      return true;
    },

    paste(item) {
      this.$f7.preloader.show();
      let promises = [];
      const data = {
        userId: item.userId,
        date: this.$google.firebase.firestore.Timestamp.fromDate(
          new Date(item.date)
        ),
        projectId: this.dataCopy.projectId,
        project: this.dataCopy.project,
        startTime: this.dataCopy.startTime,
        finishTime: this.dataCopy.finishTime,
        notes: this.dataCopy.notes,
        color: this.dataCopy.color || null,
        timeLogType: this.dataCopy.timeLogType || 'project',
        timeOffType: this.dataCopy.timeOffType || null,
        title: this.dataCopy.title || '',
      };

      if (this.validateOverTimeWhenCopy(data)) {
        // create data and update assignIds
        promises.push(
          this.handleUpdateAssigneeIdsWhenAddOrCopy({
            newSchedule: data,
            userIds: [data.userId],
          })
        );
        promises.push(this.createSchedule(data));
        this.sendCreatedEmailNotification(data);
        Promise.all(promises).then(() => {
          this.$f7.preloader.hide();
        });
      } else {
        this.$f7.preloader.hide();
        this.$ri.dialog.openErrorDialog({
          title: 'Error',
          content: 'Overlap detected. Please recheck time?',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      }
    },

    // update assigneeIds when delete schedule
    async handleUpdateAssigneeIdsWhenDelete(currentSchedule) {
      const { userId, projectId } = currentSchedule;
      let array = [];
      const oldProject =
        (projectId && (await this.getProjectById(projectId))) || {};
      if (oldProject.businessCode === 'service') {
        const oldSchedules = await this.getSchedulesByUserAndProject({
          userId,
          projectId,
        });

        if (oldSchedules.length === 0) {
          const newAssigneeIds = (oldProject.assigneeIDs || []).filter(
            r => r !== userId
          );
          array.push({
            projectId: projectId,
            assigneeIDs: newAssigneeIds,
          });
        }
      }
      return this.updateAssigneeIdsProject(array);
    },

    dateToValue(a) {
      return a.seconds * 1000 + a.nanoseconds / 1000000;
    },

    sortLatestByDate(list) {
      const listSort = (list || []).slice().sort((a, b) => {
        const aDate = a.date || { nanoseconds: 0, seconds: 0 };
        const bDate = b.date || { nanoseconds: 0, seconds: 0 };
        return (this.dateToValue(aDate) - this.dateToValue(bDate)) * -1;
      });
      return listSort;
    },

    async handleDelete(type) {
      let promises = [];
      let schedulesByChainId = [];
      const currentSchedule = _.cloneDeep(this.schedule);
      if (currentSchedule.scheduleChainId) {
        schedulesByChainId = await this.getScheduleListBys([
          {
            prop: 'scheduleChainId',
            val: currentSchedule.scheduleChainId,
            op: '==',
          },
        ]);
      }
      const chain =
        currentSchedule.scheduleChainId &&
        (await this.getScheduleChain(currentSchedule.scheduleChainId));
      switch (type) {
        case 'thisShiftAndAllRemaining': {
          const schedulesAfterSelectDate = schedulesByChainId.filter(
            r =>
              !moment(r.date.toDate()).isBefore(
                moment(moment(currentSchedule.date, 'MM/DD/YYYY'), 'day')
              )
          );

          let remainSchedules = _.differenceWith(
            schedulesByChainId,
            schedulesAfterSelectDate,
            _.isEqual
          );

          remainSchedules = this.sortLatestByDate(remainSchedules);

          if (remainSchedules.length === 0) {
            promises.push(this.deleteChain(currentSchedule.scheduleChainId));
          } else {
            promises.push(
              this.updateChain({
                id: currentSchedule.scheduleChainId,
                doc: {
                  endDate: remainSchedules[0].date,
                },
              })
            );
          }

          schedulesAfterSelectDate.forEach(r => {
            promises.push(this.deleteSchedule(r.id));
          });
          await Promise.all(promises);
          this.sendDeleteChainEmailNotification(
            chain,
            schedulesAfterSelectDate[0]
          );
          break;
        }
        case 'allShifts': {
          promises.push(this.deleteChain(currentSchedule.scheduleChainId));

          schedulesByChainId.forEach(r => {
            promises.push(this.deleteSchedule(r.id));
          });
          await Promise.all(promises);
          this.sendDeleteChainEmailNotification(chain, schedulesByChainId[0]);
          break;
        }
        case 'thisShift': {
          let remainSchedules = schedulesByChainId.filter(
            r => r.id !== this.scheduleId
          );
          remainSchedules = this.sortLatestByDate(remainSchedules);
          if (remainSchedules.length === 0) {
            promises.push(this.deleteChain(currentSchedule.scheduleChainId));
          } else {
            promises.push(
              this.updateChain({
                id: currentSchedule.scheduleChainId,
                doc: {
                  startDate: remainSchedules[remainSchedules.length - 1].date,
                  endDate: remainSchedules[0].date,
                },
              })
            );
          }
          let schedule = this.scheduleList.find(r => r.id === this.scheduleId);
          await this.deleteSchedule(this.scheduleId);
          this.sendDeleteScheduleEmailNotification(schedule);
          break;
        }
        default: {
          let schedule = this.scheduleList.find(r => r.id === this.scheduleId);
          await this.deleteSchedule(this.scheduleId);
          this.sendDeleteScheduleEmailNotification(schedule);
          break;
        }
      }
      await this.handleUpdateAssigneeIdsWhenDelete(currentSchedule); // call after delete schedule
    },

    openAlbumPhoto(projectId) {
      this.$refs.albumPhoto.openAlbumPhoto(projectId);
    },

    openAddressOnMap(fullAddress) {
      this.$refs.viewAddressPopup.openPopup(fullAddress);
    },
  },

  computed: {
    ...mapGetters('scheduling/user', ['filterUserList', 'userById']),
    ...mapGetters('scheduling/scheduling', [
      'scheduleList',
      'selectedItem',
      'isMyScheduling',
      'schedule',
      'scheduleId',
    ]),
    filteredUsers() {
      let list = [];
      if (this.isMyScheduling) {
        list = [this.userById(auth.currentUser.uid)];
        return list;
      }
      list = applyFilter(this.filterUserList, this.searchText, [
        'displayName',
        'email',
        i => i.customClaims.role,
      ]);
      return list.sort((a, b) => sortBy(a.displayName, b.displayName));
    },
    dataSchedule() {
      const list = this.filteredUsers.map(r => ({
        ...r,
        schedules: this.currentWeek.map(d => ({
          ...d,
          details: (
            this.scheduleList.filter(
              s => s.userId === (r && r.uid) && s.date === d.date
            ) || []
          ).sort((a, b) => sortBy(a.startTime, b.startTime)),
        })),
      }));
      return list;
    },
  },
};
</script>
<style lang="scss" scoped>
.add-button {
  display: none;
}
.paste-button {
  display: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.tableFixHead {
  overflow-y: auto;
  height: calc(
    100vh - var(--f7-navbar-height) - var(--f7-block-margin-vertical) - 48px -
      20px
  );
}
.tableFixHead thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.tableFixHead,
.tableFixHead td,
.tableFixHead th {
  box-shadow: inset 1px -1px var(--f7-color-border-neutral);
  box-sizing: border-box;
}
table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--f7-color-bg-4-neutral);
  table-layout: fixed;

  tr th:first-child > div {
    margin: 0 10px;
  }
  tr td:first-child {
    font-weight: 500;
    background: var(--f7-color-bg-3-neutral);
    word-break: break-word;

    & > div {
      margin: 0 10px;
    }
  }
  th {
    background: var(--f7-color-bg-3-neutral);
    height: 3rem;
    font-weight: 500;
    position: relative;
  }
  td {
    height: 9rem;
    position: relative;
  }
  td:not(:first-child):hover {
    .add-button {
      display: inline;
      position: absolute;
      bottom: 2px;
      right: 2px;
    }
    .paste-button {
      display: inline;
      position: absolute;
      bottom: 3px;
      right: 34px;
    }
  }
}
.card-container {
  height: inherit;
}
.popover-schedule-detail {
  width: 400px;
}
.show-more {
  font-size: 12px;
  font-weight: 600;
  margin-left: 9px;
}
</style>
