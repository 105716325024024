export default {
  order: state => state.order,
  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,

  // options for filter
  statusFilterList: state => state.statusFilterList,
};
