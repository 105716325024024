import * as types from './types';
import quickbooksService from '../../../../services/quickbooks.service';

export default {
  setCurrentBtnType({ commit }, type) {
    commit(types.SET_CURRENT_BTN_TYPE, type);
  },

  populateAccount({ commit }) {
    return quickbooksService.getAccount().then(accounts => {
      commit(types.SET_QB_ACCOUNT, accounts);
    });
  },

  populateVendor({ commit }) {
    return quickbooksService.getVendor().then(vendors => {
      commit(types.SET_QB_VENDOR, vendors);
    });
  },

  populateEmployee({ commit }) {
    return quickbooksService.getEmployee().then(employees => {
      commit(types.SET_QB_EMPLOYEE, employees);
    });
  },

  populateCustomer({ commit }) {
    return quickbooksService.getCustomer().then(customers => {
      commit(types.SET_QB_CUSTOMER, customers);
    });
  },

  setIsShowIntro({ commit }, status) {
    commit(types.SET_INTRO_STATE, status);
  },
};
