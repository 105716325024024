<template>
  <div>
    <f7-block>
      <filter-by-date @searchByDate="searchExpensesByDate"></filter-by-date>
    </f7-block>

    <history-table
      :dataSource="expenseHistory"
      @showDetail="handleShowDetailPopup"
    ></history-table>
    <expenses-history-popup
      :isShow="isShowHistoryPopup"
      title="Expenses DateTime"
      :dataSource="historyExpenses"
      @close="handleCloseDetailPopup"
    ></expenses-history-popup>
  </div>
</template>
<script>
import FilterByDate from '@/plugins/quickbooks/components/filter/FilterByDate.vue';
import ExpensesHistoryPopup from '../../components/popups/ExpensesHistoryPopup.vue';
import HistoryTable from '../../components/datatable/HistoryTable.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  lastWeekMonday,
  lastWeekSunday,
} from '../../../../utility/date-time-tool';

export default {
  components: {
    FilterByDate,
    ExpensesHistoryPopup,
    HistoryTable,
  },
  data: () => {
    return {
      isShowHistoryPopup: false,
      historyExpenses: [],
    };
  },

  created() {
    this.searchExpensesByDate({
      fromDate: [lastWeekMonday()],
      toDate: [lastWeekSunday()],
    });
  },

  methods: {
    ...mapActions('quickbooks/activity', ['popuplateExpenseHistory']),
    searchExpensesByDate(dateRange) {
      this.popuplateExpenseHistory(dateRange);
    },

    handleShowDetailPopup(value) {
      this.isShowHistoryPopup = true;
      this.historyExpenses = value;
    },

    handleCloseDetailPopup() {
      this.isShowHistoryPopup = false;
      this.historyExpenses = [];
    },
  },
  computed: {
    ...mapGetters('quickbooks/activity', ['expenseHistory']),
  },
};
</script>
