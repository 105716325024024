<template>
  <div
    class="image-container"
    :class="{ 'image-container-mobile': !$device.desktop }"
  >
    <div
      class="image-part"
      @click="onView"
    >
      <img
        class="image"
        :class="{ 'image-mobile': !$device.desktop }"
        :src="photo.photoUrl"
      />
    </div>
    <a
      @click="onDelete"
      class="btn-delete"
      ><i
        class="icon f7-icons"
        style="color: red; font-size: 18px"
        >xmark</i
      ></a
    >
    <div class="notes-container">
      <f7-input
        class="input-notes"
        label="Notes"
        placeholder="Enter Notes"
        :value="photo.notes"
        @change="onChangePhotoProp('notes', $event.target.value)"
        rows="2"
        type="textarea"
      >
      </f7-input>
    </div>
    <select-project-photo-popup
      ref="selectPhotos"
      :selectedPhotos="selectedPhotos"
    ></select-project-photo-popup>
    <photo-browser
      :photos="photos"
      theme="dark"
      ref="pageDark"
      :isShowComment="false"
    ></photo-browser>
  </div>
</template>

<script>
import SelectProjectPhotoPopup from '../popups/SelectProjectPhotoPopup.vue';
import PhotoBrowser from '@/components/photo/PhotoBrowser.vue';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      photos: [],
    };
  },
  props: {
    photo: { type: Object, default: () => {} },
    projectId: { type: String, default: '' },
    selectedPhotos: { type: Array, default: () => [] },
  },
  components: {
    SelectProjectPhotoPopup,
    PhotoBrowser,
  },
  computed: {
    ...mapGetters({
      projectPhotoList: 'invoices/photo/projectPhotoList',
    }),
  },
  methods: {
    ...mapActions({
      bindProjectPhotoListBy: 'invoices/photo/bindProjectPhotoListBy',
    }),
    onDelete() {
      this.$emit('onDelete', this.photo);
    },
    onChangePhotoProp(prop, value) {
      this.$emit('onChangePhotoProp', prop, value);
    },
    async onView() {
      if (_.isEmpty(this.projectPhotoList)) {
        await this.bindProjectPhotoListBy({
          prop: 'projectId',
          val: this.projectId,
          op: '==',
        });
      }
      this.photos = (this.projectPhotoList || [])
        .filter(r => r.id === this.photo.id)
        .map(({ photoUrl, comments, photoFullPath, ...rest }) => ({
          ...rest,
          url: photoUrl,
          comments,
          photoFullPath,
        }));
      this.$refs.pageDark.open(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 5px;
  margin: 10px;
}
.image-container-mobile {
  width: 170px;
  height: 170px;
}
.image-part {
  width: 100%;
  height: 195px;
  border-radius: 5px;
  border: 2px solid var(--f7-color-bg-12-neutral);
  display: grid;
  place-items: center;
  overflow: hidden;
  cursor: pointer;
}
/* Make the image to responsive */
.image {
  width: 100%;
  height: 100%;
  max-height: 200px;
  object-fit: cover;
}

.image-mobile {
  width: 100%;
  height: 100%;
  max-height: 200px;
  object-fit: cover;
  max-height: 170px;
}

.btn-delete {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  display: grid;
  place-items: center;
  background-color: var(--f7-color-bg-12-neutral);
  opacity: 0.7;
}

.btn-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* The overlay effect - lays on top of the container and over the image */
.overlay {
  position: absolute;
  top: 35%;
  left: 20%;
  height: 30%;
  width: 60%;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  font-weight: bold;
  font-size: 20px;
  color: #f1f1f1;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  i {
    font-size: 30px;
  }
}

/* When you mouse over the container, fade in the overlay title */
.image-container:hover .overlay {
  opacity: 1;
}

.notes-container {
  width: calc(100% + 4px);
  height: 40px;
  margin-top: 8px;
  border-radius: 2px;
  background-color: var(--f7-color-bg-12-neutral);

  .input-notes::v-deep {
    height: 100%;
    textarea {
      height: 100% !important;
      padding: 5px;
    }
  }
}
</style>
