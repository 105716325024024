<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="popupOpened = false"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Building</f7-nav-title>
        <f7-nav-right>
          <!-- <f7-link icon-f7="plus" @click.native="onAdd"></f7-link> -->
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar
        disable-button-text
        placeholder="Search Building"
        :clear-button="true"
        :value="searchValue"
        @input="searchValue = $event.target.value"
        @searchbar:disable="searchValue = ''"
        class="search-list-popup"
      ></f7-searchbar>

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found"></f7-list-item>
      </f7-list>
      <!-- select contact for creating new card -->
      <f7-list class="search-list searchbar-found contact-list">
        <f7-list-item
          no-chevron
          link
          v-for="item in filterBuildingList"
          :key="item.buildingId"
          :title="`${item.buildingName || ''}`"
          @click.native="select(item.buildingId)"
        ></f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { applyFilter } from '@/utility/filter-tools';

export default {
  props: {
    list: Array,
  },
  data: () => {
    return {
      popupOpened: false,
      value: '',
      currentRoofType: {},
      searchValue: '',
    };
  },
  computed: {
    filterBuildingList() {
      return applyFilter(this.list, this.searchValue, ['buildingName']);
    },
  },

  methods: {
    open() {
      this.popupOpened = true;
    },

    select(id) {
      this.$emit('onSelected', id);
      this.popupOpened = false;
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list-popup.searchbar input').focus();
      }
    },
  },
};
</script>

<style></style>
