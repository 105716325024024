<template>
  <f7-icon
    class="icon"
    v-bind:f7="icon"
    :style="`color: ${color}`"
  ></f7-icon>
</template>

<script>
export default {
  props: {
    icon: String,
    color: String,
  },
};
</script>

<style lang="scss" scoped>
.icon {
  color: var(--f7-theme-color);
  margin-top: var(--swimlane-icon-margin-top);
  margin-left: var(--swimlane-icon-margin-left);
  font-size: var(--swimlane-icon-font-size);

  margin-bottom: var(--swimlane-icon-margin-bottom);
}
</style>
