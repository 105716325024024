<template>
  <f7-popup
    tablet-fullscreen
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="close"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Set Default Product</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="save">Save</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-block-title
        class="display-flex justify-content-space-between align-items-center"
        style="flex-wrap: wrap; gap: 8px"
      >
        <div>Default Product Table</div>
        <f7-button
          fill
          @click="openAddDefaultProductPopup"
        >
          Add Default Product
        </f7-button>
      </f7-block-title>

      <default-product-table
        ref="defaultProductTable"
        id="default-table"
        dataType="products-default"
        :isCheckbox="false"
        :isShowActions="true"
        :filters="`priceListId:${currentPriceList && currentPriceList.id}`"
        @doAfterDelete="reloadDataPage"
      ></default-product-table>
      <add-default-product-popup
        ref="addDefaultProductPopup"
        @doAfterClose="reloadDataPage"
      ></add-default-product-popup>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DefaultProductTable from '../tables/DefaultProductTable.vue';
import AddDefaultProductPopup from './AddDefaultProductPopup.vue';
import _ from 'lodash';
import buildingMixins from '../../utility/building-mixins';

export default {
  mixins: [buildingMixins],
  components: {
    DefaultProductTable,
    AddDefaultProductPopup,
  },

  data() {
    return {
      popupOpened: false,
      oldDefault: [],
    };
  },

  computed: {
    ...mapGetters('estimate/estimate-page/price-list', ['currentPriceList']),
    ...mapGetters('estimate/estimate-page/estimate', [
      'currentBuilding',
      'estimate',
    ]),
    ...mapGetters({
      vendors: 'estimate/estimate-page/estimate/vendor/objectList',
    }),
    ...mapGetters('estimate/estimate-page/product-default', [
      'productsDefault',
    ]),
    vendorProductDefaultList() {
      if (_.isEmpty(this.vendors)) return [];

      return this.vendors.filter(r =>
        (this.productsDefault.vendorIdFilterList || []).includes(r.id)
      );
    },
  },

  methods: {
    ...mapActions('estimate/estimate-page/product-default', [
      'resetSearch',
      'getAllDefaultProductIds',
    ]),
    ...mapActions('estimate/estimate-page/product-item', ['getProduct']),

    async open() {
      this.popupOpened = true;
      this.$refs.defaultProductTable.onSearch();
      this.oldDefault = await this.getAllDefaultProductIds({
        priceListId: this.currentPriceList.id,
        roofType: this.currentBuilding.roofType,
      });
    },

    close() {
      this.popupOpened = false;
      this.oldDefault = [];
      this.$refs.defaultProductTable.resetData();
      this.resetSearch();
    },

    openAddDefaultProductPopup() {
      this.$refs.addDefaultProductPopup.open();
    },
    reloadDataPage() {
      this.$refs.defaultProductTable.onSearch();
    },
    async getDefaultProductsRemove(productIds) {
      // get redundant products
      const products = _.differenceWith(this.oldDefault, productIds, _.isEqual);
      if (_.isEmpty(products)) return [];

      return await this.getProductListByIds(products.map(r => r.id));
    },
    openDialogWarningAddServiceProduct() {
      this.$ri.dialog.openInfoDialog({
        title: 'Missing Products',
        content:
          'Only products matching the selected vendor (on step Roof/Work Type) will appear.',
        hideCancelButton: true,
        textButton: 'Got it',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
            this.close();
          }
        },
      });
    },
    save() {
      const vendorIdsProductDefault = (this.vendorProductDefaultList || []).map(
        e => e.id
      );
      if (_.isEmpty(vendorIdsProductDefault)) return;

      const commonIds = (this.currentBuilding.vendorIds || []).filter(id =>
        vendorIdsProductDefault.includes(id)
      );

      if (_.isEmpty(commonIds)) {
        this.openDialogWarningAddServiceProduct();
      } else {
        this.$ri.dialog.openInfoDialog({
          title: 'Add Service Product',
          content:
            'Do you want to include default service products in this building estimate?',
          hideCancelButton: false,
          onClick: async (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
              this.close();
            } else if (index === 1) {
              this.$f7.preloader.show();
              let productIds = await this.getAllDefaultProductIds({
                priceListId: this.currentPriceList.id,
                roofType: this.currentBuilding.roofType,
              });
              let products = await this.getProductListByIds(
                productIds.map(r => r.id)
              );
              products = products.filter(
                r => !_.isEmpty(r) && commonIds.includes(r.vendorId)
              );
              await this.$emit('onSaveProductItems', products);
              const productsRemove =
                await this.getDefaultProductsRemove(productIds);
              if (!_.isEmpty(productsRemove)) {
                await this.$emit('onRemoveProductItems', productsRemove);
              }
              _sefl.app.dialog.close();
              this.close();
              this.$f7.preloader.hide();
            }
          },
        });
      }
    },
  },
};
</script>

<style></style>
