export default {
  serviceItemSettingList: state => state.serviceItemSettingList,

  serviceItemOptions: (state, _, __, rootGetters) => {
    const qbServiceItems =
      rootGetters['time-tracking/quickbooks/serviceItemQBList'];
    return state.serviceItemSettingList.length
      ? qbServiceItems.filter(r =>
          state.serviceItemSettingList.some(i => i.value === r.value)
        )
      : qbServiceItems;
  },
};
