<template>
  <f7-popup
    class="demo-popup"
    :opened="isShow"
    @popup:closed="cancel"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title> Add New Project </f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="save()">Save</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list>
        <f7-list-input
          type="select"
          placeholder="Select Job Type"
          label="Job Type"
          :value="jobType"
          @change="onChangeJobType($event.target.value)"
        >
          <option
            value=""
            disabled
          >
            Select job type
          </option>
          <option value="commercial">Commercial</option>
          <option value="residential">Residential</option>
          <option value="service">Service</option>
        </f7-list-input>
        <div v-show="jobType === 'commercial'">
          <create-project-commercial
            :isNew="true"
            ref="commercialRef"
            @doClosePopup="cancel"
          >
          </create-project-commercial>
        </div>
        <div v-show="jobType === 'residential'">
          <create-project-residential
            ref="residentialRef"
            @doClosePopup="cancel"
          >
          </create-project-residential>
        </div>
        <div v-show="jobType === 'service'">
          <create-project-service
            ref="serviceRef"
            @doClosePopup="cancel"
          >
          </create-project-service>
        </div>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>
<script>
import { mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import CreateProjectCommercial from './components/CreateProjectCommercial.vue';
import CreateProjectResidential from './components/CreateProjectResidential.vue';
import CreateProjectService from './components/CreateProjectService.vue';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    CreateProjectCommercial,
    CreateProjectService,
    CreateProjectResidential,
  },
  created() {
    this.bindTemplateList();
  },
  props: {
    isShow: Boolean,
  },
  data: () => ({
    jobType: '',
  }),
  methods: {
    ...mapActions('photo/project', ['getActionList']),
    ...mapActions('photo/app-constant', ['getConstantTypeList']),
    ...mapActions('photo/client', [
      'setContact',
      'setCompany',
      'setProperty',
      'setInsuranceCompany',
    ]),
    ...mapActions('dashboard/template', ['bindTemplateList']),

    resetData() {
      this.jobType = '';
      this.v$.$reset();
      this.$refs.commercialRef.reset();
      this.$refs.residentialRef.reset();
      this.$refs.serviceRef.reset();
      this.setContact({});
      this.setCompany({});
      this.setProperty({});
      this.setInsuranceCompany({});
    },

    cancel() {
      this.resetData();
      this.$emit('cancel');
    },
    onChangeJobType(val) {
      this.resetData();
      if (val === 'commercial') {
        this.$refs.commercialRef.initActionId();
      }
      this.jobType = val;
    },
    invalidSubForm() {
      if (this.jobType === 'commercial') {
        return this.$refs.commercialRef.invalid();
      } else if (this.jobType === 'residential') {
        return this.$refs.residentialRef.invalid();
      } else {
        return this.$refs.serviceRef.invalid();
      }
    },
    save() {
      this.v$.$touch();
      if (this.v$.$invalid || this.invalidSubForm()) {
        return;
      } else {
        if (this.jobType === 'commercial') {
          this.$refs.commercialRef.create();
        } else if (this.jobType === 'residential') {
          this.$refs.residentialRef.create();
        } else {
          this.$refs.serviceRef.create();
        }
      }
    },
  },
  computed: {
    displayNameErrorMessage() {
      if (!this.v$.jobType.$error) return null;
      if (this.v$.jobType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  validations() {
    return {
      jobType: {
        required,
      },
    };
  },
  watch: {
    isShow: {
      handler(val) {
        if (val) {
          this.$f7.preloader.show();
          const promises = [];
          promises.push(this.getConstantTypeList());
          promises.push(this.getActionList());
          Promise.all(promises).then(() => {
            this.$f7.preloader.hide();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
