<template>
  <f7-view :class="`page ${dark ? 'theme-dark' : ''}`">
    <f7-page
      no-toolbar
      no-navbar
      no-swipeback
      login-screen
    >
      <!-- <form> -->
      <f7-login-screen-title
        >Please enter your email address to reset your
        password.</f7-login-screen-title
      >
      <f7-list>
        <f7-list-input
          ref="emailInput"
          :value="email"
          @input="email = $event.target.value.trim()"
          label="Email"
          type="email"
          placeholder="Your Email"
          @blur="v$.$touch()"
          clear-button
          error-message-force
          :error-message="emailErrorMessage"
          autofocus
          @keypress.native.enter="sendResetPasswordEmail"
        ></f7-list-input>
        <!-- <f7-list-button @click="sendResetPasswordEmail">Next</f7-list-button> -->
      </f7-list>
      <f7-block>
        <f7-button
          large
          round
          raised
          fill
          @click="sendResetPasswordEmail"
          >Next</f7-button
        >
        <br />
        <f7-button
          large
          round
          raised
          outline
          @click="toLoginPage"
          >Login</f7-button
        >
      </f7-block>
      <!-- </form> -->
    </f7-page>
  </f7-view>
</template>

<script>
import { mapState } from 'vuex';
import { auth } from '../services/firebase.service';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import {
  getLoginTenantId,
  resetUserPassword,
} from '../services/authentication.service';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  data: () => {
    return {
      email: '',
    };
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      email: {
        required,
        commonEmailRule: email,
      },
    };
  },

  computed: {
    ...mapState('setting/app/profile', ['dark']),
    emailErrorMessage() {
      if (!this.v$.email.$error) return null;
      if (this.v$.email.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.email.commonEmailRule.$invalid)
        return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },
  },

  methods: {
    sendResetPasswordEmail() {
      // Validate input controls
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.$f7.preloader.show();

      getLoginTenantId(this.email).then(tenantIds => {
        const loginTenantId = tenantIds[0] || '';
        if (loginTenantId !== '') {
          auth.tenantId = loginTenantId;
        }
        resetUserPassword(loginTenantId, this.email)
          .then(result => {
            if (result.code === 'success') {
              this.$f7router.navigate('/email-sent', {
                pushState: true,
                reloadAll: true,
              });
            } else if (result.code === 'notfounduser') {
              var notificationClickToClose = this.$f7.notification.create({
                icon: "<i class='color-red icon f7-icons'>exclamationmark_triangle_fill</i>",
                title: 'Reset password failure',
                text: 'Our system cannot find your email address, you may be entering the wrong one.',
                closeOnClick: true,
                closeButton: true,
                closeTimeout: 15000,
              });
              notificationClickToClose.open();
            }
          })
          .catch(() => {
            var notificationClickToClose = this.$f7.notification.create({
              icon: "<i class='color-red icon f7-icons'>exclamationmark_triangle_fill</i>",
              title: 'Reset password failure',
              text: 'An unknown error occurred. Please try again',
              closeOnClick: true,
              closeButton: true,
              closeTimeout: 15000,
            });
            notificationClickToClose.open();
          })
          .finally(() => {
            this.$f7.preloader.hide();
          });
      });
    },

    toLoginPage() {
      // window.location.href = "/login";
      this.$f7router.navigate('/login', {
        pushState: true,
        reloadAll: true,
      });
    },
  },
};
</script>

<style></style>
