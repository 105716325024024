import moment from 'moment';
import {
  FirebaseActions,
  firebase,
} from '../../../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/employee_pay_rate`,
    'payRate'
  );

  return {
    bindPayRateListBy: ActionsTemplate.bindCollectionBy,
    unbindPayRateList: ActionsTemplate.unbindCollection,

    createPayRate: ActionsTemplate.createDocument,
    updatePayRate: ActionsTemplate.updateDocument,
    deletePayRate: ActionsTemplate.deleteDocument,

    setPayRateId({ commit }, id) {
      commit(types.SET_PAY_RATE_ID, id);
    },

    getDataPayRateList(_, conditions) {
      const path = `/system_client/${tenant}/employee_pay_rate`;
      return FirebaseActions.getCollectionWithConditions(path, conditions).then(
        res => res
      );
    },

    getPayRateBys: ActionsTemplate.getDocumentBys,

    async getCurrentPayRateOfUser({ dispatch }, item) {
      let payRateList = await dispatch('getPayRateBys', [
        {
          prop: 'userId',
          val: item.user_id,
          op: '==',
        },
      ]);
      if (payRateList.length > 0) {
        payRateList = payRateList.map(r => ({
          ...r,
          effectiveDate: r.effectiveDate.toDate(),
        }));
        let sorted = payRateList.sort(
          (a, b) => moment(a.effectiveDate) - moment(b.effectiveDate)
        );

        // filter payRate has item.date between effectiveDate and next effectiveDate - 1
        const filteredPayRate = sorted.filter((rate, index) => {
          const effectiveDate = moment(rate.effectiveDate);
          const toDate =
            index < sorted.length - 1
              ? moment(sorted[index + 1].effectiveDate).subtract(1, 'days')
              : null;

          const itemDate = moment(item.date.toDate());

          const isHasPayrate = itemDate.isBetween(
            effectiveDate,
            toDate,
            null,
            '[]'
          );

          return isHasPayrate;
        });

        // return payrate
        if (filteredPayRate.length > 0) {
          return filteredPayRate[0];
        } else {
          return sorted[sorted.length - 1];
        }
      }
      return null;
    },
  };
};
