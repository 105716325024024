<template>
  <div>
    <f7-block>
      <filter-by-date @searchByDate="searchByDate"></filter-by-date>
    </f7-block>

    <history-table
      :dataSource="invoiceHistoryList"
      @showDetail="isShowHistoryPopup = true"
    ></history-table>
    <invoice-history-popup
      :isShow="isShowHistoryPopup"
      title="Payable DateTime"
      :dataSource="invoiceList"
      @close="isShowHistoryPopup = false"
    ></invoice-history-popup>
  </div>
</template>
<script>
import FilterByDate from '@/plugins/quickbooks/components/filter/FilterByDate.vue';
import InvoiceHistoryPopup from '@/plugins/quickbooks/components/popups/InvoiceHistoryPopup.vue';
import HistoryTable from '@/plugins/quickbooks/components/datatable/HistoryTable.vue';
export default {
  components: {
    FilterByDate,
    InvoiceHistoryPopup,
    HistoryTable,
  },
  data: () => {
    return {
      isShowHistoryPopup: false,
    };
  },
  methods: {
    searchByDate() {},
  },
  computed: {
    invoiceHistoryList() {
      return [
        {
          id: '1',
          patchName: 'Payable DateTime',
          reportedDate: new Date('8/15/2020'),
        },
        {
          id: '2',
          patchName: 'Payable DateTime',
          reportedDate: new Date('8/17/2020'),
        },
        {
          id: '2',
          patchName: 'Payable DateTime',
          reportedDate: new Date('8/19/2020'),
        },
      ];
    },
    invoiceList() {
      return [
        {
          id: '1',
          invoiceNumber: 'INV-6454135',
          invoiceDate: new Date('8/15/2020'),
          amount: '6000',
          vendorName: 'ABC Supply',
        },
        {
          id: '2',
          invoiceNumber: 'INV-6454136',
          invoiceDate: new Date('8/17/2020'),
          amount: '8500',
          vendorName: 'John Manufacturer',
        },
      ];
    },
  },
};
</script>
