export default {
  groupList: state => state.groupList || [],
  groups: state => state.groupList || [],

  groupListByUserId: (state, getters) => userId => {
    return getters.groupList.filter(group => {
      return !!group.disabled === false && group.userIDs.includes(userId);
    });
  },

  isAdminGroup: state => state.isAdminGroup,
};
