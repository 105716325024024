<template>
  <div class="card data-table">
    <table>
      <thead>
        <tr>
          <th>
            <span
              class="label-cell sortable-cell"
              :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
              @click="sort('cardNumber')"
              >Job#</span
            >
            <div class="input">
              <input
                :value="search.jobNumber"
                @input="search.jobNumber = $event.target.value.trim()"
                type="text"
                placeholder="Search job"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th class="label-cell">
            <span>Assignee</span>
            <div class="input input-dropdown">
              <select
                @change="changeValueFilter('assignee', $event)"
                v-model="selected"
              >
                <option value="0">All</option>
                <option
                  v-for="user in users"
                  :key="user.uid"
                  :value="user.uid"
                >
                  {{ user.displayName }}
                </option>
              </select>
            </div>
          </th>
          <th
            v-if="residentialGridCurrentTab === 'all'"
            class="label-cell"
          >
            <span>Current Action</span>
            <div class="input input-dropdown">
              <select
                @change="changeValueFilter('action', $event)"
                v-model="selected"
              >
                <option value="0">All</option>
                <option
                  v-for="action in filterActions(boardCode)"
                  :key="action.id"
                  :value="action.id"
                >
                  {{ action.title }}
                </option>
              </select>
            </div>
          </th>
          <th v-if="['all', 830, 860].includes(residentialGridCurrentTab)">
            <span
              class="label-cell sortable-cell"
              :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
              @click="sort('dueDate')"
              >Due Date</span
            >
            <div class="input">
              <input
                :value="search.dueDate"
                @input="search.dueDate = $event.target.value.trim()"
                type="text"
                placeholder="Search due date"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th v-if="residentialGridCurrentTab === 800">
            <span
              class="label-cell sortable-cell"
              :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
              @click="sort('dueDate')"
              >Due Of Loss</span
            >
            <div class="input">
              <input
                :value="search.dueOfLoss"
                @input="search.dueOfLoss = $event.target.value.trim()"
                type="text"
                placeholder="Search due of loss"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th v-if="residentialGridCurrentTab === 840">
            <span
              class="label-cell sortable-cell"
              :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
              @click="sort('dueDate')"
              >Build Date</span
            >
            <div class="input">
              <input
                :value="search.buildDate"
                @input="search.buildDate = $event.target.value.trim()"
                type="text"
                placeholder="Search build date"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th class="label-cell">
            <span
              class="sortable-cell"
              :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
              @click="sort('contactName')"
              >Contact Info</span
            >
            <div class="input">
              <input
                :value="search.contactName"
                @input="search.contactName = $event.target.value.trim()"
                type="text"
                placeholder="Search contact"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th class="label-cell">
            <span
              class="sortable-cell"
              :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
              @click="sort('contactPhone')"
              lang=""
              >Phone Number</span
            >
            <div class="input">
              <input
                :value="search.contactPhone"
                @input="search.contactPhone = $event.target.value.trim()"
                type="text"
                placeholder="Search phone"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th class="label-cell">
            <span
              class="sortable-cell"
              :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
              @click="sort('displayProjectAddress')"
              >Job Info</span
            >
            <div class="input">
              <input
                :value="search.jobInfor"
                @input="search.jobInfor = $event.target.value.trim()"
                type="text"
                placeholder="Search job info"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th
            v-if="['all', 820].includes(residentialGridCurrentTab)"
            class="label-cell"
          >
            <span>Ins. Approval</span>
            <div class="input input-dropdown">
              <select
                @change="changeValueFilter('approval', $event)"
                v-model="selected"
              >
                <option value="0">All</option>
                <option
                  v-for="(type, index) in insuranceApprovalStatusList"
                  :key="index"
                  :value="type.value"
                >
                  {{ type.displayName }}
                </option>
              </select>
            </div>
          </th>
          <th
            v-if="['all', 820].includes(residentialGridCurrentTab)"
            class="label-cell"
          >
            Ins. Approval Amount
          </th>
          <th
            v-if="residentialGridCurrentTab === 'all'"
            class="label-cell"
          >
            Job Cost
          </th>
          <th
            v-if="residentialGridCurrentTab === 800"
            class="label-cell"
          >
            <span>Lead Type</span>
            <div class="input input-dropdown">
              <select
                @change="changeValueFilter('leadType', $event)"
                v-model="selected"
              >
                <option value="0">All</option>
                <option
                  v-for="(type, index) in leadSourceResidentialList"
                  :key="index"
                  :value="type.value"
                >
                  {{ type.displayName }}
                </option>
              </select>
            </div>
          </th>
          <th
            v-if="residentialGridCurrentTab === 800"
            class="label-cell"
          >
            Note
          </th>
          <th
            v-if="residentialGridCurrentTab === 810"
            class="label-cell"
          >
            <span>Claim#</span>
            <div class="input">
              <input
                :value="search.claim"
                @input="search.claim = $event.target.value.trim()"
                type="text"
                placeholder="Search claim"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th
            v-if="residentialGridCurrentTab === 810"
            class="label-cell"
          >
            <span>Field Adjuster Name</span>
            <div class="input">
              <input
                :value="search.fieldAdjusterName"
                @input="search.fieldAdjusterName = $event.target.value.trim()"
                type="text"
                placeholder="Search adjuster name"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th
            v-if="residentialGridCurrentTab === 810"
            class="label-cell"
          >
            <span>Field Adjuster Phone</span>
            <div class="input">
              <input
                :value="search.fieldAdjusterPhone"
                @input="search.fieldAdjusterPhone = $event.target.value.trim()"
                type="text"
                placeholder="Search adjuster phone"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th
            v-if="residentialGridCurrentTab === 810"
            class="label-cell"
          >
            <span>Inspection Date/Time</span>
            <div class="input">
              <input
                :value="search.inspectionDateTime"
                @input="search.inspectionDateTime = $event.target.value.trim()"
                type="text"
                placeholder="Search inspectione date time"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th
            v-if="residentialGridCurrentTab === 820"
            class="label-cell"
          >
            <span>Suppl. Submitted Date</span>
            <div class="input">
              <input
                :value="search.submittedDate"
                @input="search.submittedDate = $event.target.value.trim()"
                type="text"
                placeholder="Search submitted date"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th
            v-if="residentialGridCurrentTab === 820"
            class="label-cell"
          >
            Suppl. Amount
          </th>
          <th
            v-if="residentialGridCurrentTab === 850"
            class="label-cell"
          >
            <span>QA Completed</span>
            <div class="input">
              <input
                :value="search.qaComplete"
                @input="search.qaComplete = $event.target.value.trim()"
                type="text"
                placeholder="Search qa completed"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th
            v-if="residentialGridCurrentTab === 850"
            class="label-cell"
          >
            <span>Completed Date</span>
            <div class="input">
              <input
                :value="search.completedDate"
                @input="search.completedDate = $event.target.value.trim()"
                type="text"
                placeholder="Search completed date"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th
            v-if="[830, 850, 860].includes(residentialGridCurrentTab)"
            class="label-cell"
          >
            Invoice
          </th>
          <th
            v-if="residentialGridCurrentTab === 830"
            class="label-cell"
          >
            <span>Contract</span>
            <div class="input">
              <input
                :value="search.contract"
                @input="search.contract = $event.target.value.trim()"
                type="text"
                placeholder="Search contract"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th
            v-if="residentialGridCurrentTab === 840"
            class="label-cell"
          >
            <span>Roof Size (SQFT)</span>
            <div class="input">
              <input
                :value="search.roofSize"
                @input="search.roofSize = $event.target.value.trim()"
                type="text"
                placeholder="Search roof size"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th
            v-if="residentialGridCurrentTab === 840"
            class="label-cell"
          >
            <span>Material Delivery Date</span>
            <div class="input">
              <input
                :value="search.deliveryDate"
                @input="search.deliveryDate = $event.target.value.trim()"
                type="text"
                placeholder="Search delivery date"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th
            v-if="residentialGridCurrentTab === 840"
            class="label-cell"
          >
            <span>Material Inspection</span>
            <div class="input">
              <input
                :value="search.materialInspection"
                @input="search.materialInspection = $event.target.value.trim()"
                type="text"
                placeholder="Search marerial inspection"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
          <th
            v-if="residentialGridCurrentTab === 850"
            class="label-cell"
          >
            Certificate of Completion
          </th>
          <th class="label-cell">
            <span>Sales Reps</span>
            <div class="input">
              <input
                :value="search.salesRep"
                @input="search.salesRep = $event.target.value.trim()"
                type="text"
                placeholder="Search sales reps"
              />
              <span class="input-clear-button"></span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="cursor-pointer"
          v-for="(card, index) in projectListCompleted"
          :key="`${card.id}-${index}`"
        >
          <td>
            <f7-link
              class="cursor-pointer"
              @click.native="
                $emit('openCardDetailsPopup', {
                  actionId: card.actionId,
                  cardNumber: card.cardNumber,
                })
              "
              >{{ card.cardNumber }}</f7-link
            >
          </td>
          <td class="label-cell">
            <div class="assignee-cell">
              <div
                v-for="(uid, index) in card.assigneeIDs"
                :key="uid"
                :class="index < maxUserDisplay ? 'assignee-avatar' : ''"
              >
                <user-avatar
                  :avatarSize="25"
                  v-if="index < maxUserDisplay"
                  :imageUrl="userInfo(uid) ? userInfo(uid).photoURL : ''"
                  :name="userInfo(uid) ? userInfo(uid).displayName : ''"
                  :uid="uid"
                ></user-avatar>
              </div>
              <div
                v-if="card.assigneeIDs?.length > maxUserDisplay"
                class="assignee-avatar more-assignee-avatar cursor more-user-tooltip"
                @mouseover="showRemainingAssignees = card.id"
                @mouseleave="showRemainingAssignees = ''"
              >
                <span>{{
                  `+${card.assigneeIDs.length - maxUserDisplay}`
                }}</span>
                <div
                  v-if="showRemainingAssignees === card.id"
                  class="remaining-assignees"
                >
                  <ul>
                    <li
                      v-for="uid in card.assigneeIDs.slice(maxUserDisplay)"
                      :key="uid"
                    >
                      {{ userInfo(uid) ? userInfo(uid).displayName : '' }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </td>
          <td
            v-if="residentialGridCurrentTab === 'all'"
            class="label-cell"
          >
            {{ card.actionTitle }}
          </td>
          <td
            v-if="['all', 830, 860].includes(residentialGridCurrentTab)"
            class="label-cell"
          >
            <f7-chip
              v-if="card.dueDate !== ''"
              slot="after"
              :text="toDisplayDateString(card.dueDate)"
              :color="dueDateColor(card.dueDate)"
            ></f7-chip>
          </td>
          <td
            v-if="residentialGridCurrentTab === 800"
            class="label-cell"
          >
            {{ toDisplayDateString(card.dateOfLoss) }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 840"
            class="label-cell"
          >
            {{ toDisplayDateString(card.productionDate) }}
          </td>
          <td class="label-cell">{{ card.contactName }}</td>
          <td class="label-cell">{{ card.contactPhone }}</td>
          <td class="label-cell">{{ card.displayProjectAddress }}</td>
          <td
            v-if="['all', 820].includes(residentialGridCurrentTab)"
            class="label-cell"
          >
            <f7-chip
              v-show="card.approvalStatus"
              slot="after"
              :text="insuranceApprovalStatusBy(card.approvalStatus).displayName"
              :color="insuranceApprovalStatusBy(card.approvalStatus).color"
            ></f7-chip>
          </td>
          <td
            v-if="['all', 820].includes(residentialGridCurrentTab)"
            class="label-cell"
          >
            {{
              card.approvalStatus === 'approved'
                ? card.insuranceBudget
                  ? formatPriceNumber(card.insuranceBudget.toString())
                  : ''
                : card.approvalStatus === 'supplement-needed'
                  ? card.insuranceFinalAmount
                    ? formatPriceNumber(card.insuranceFinalAmount.toString())
                    : ''
                  : ''
            }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 'all'"
            class="label-cell"
          >
            {{
              card.xactimateBudget
                ? formatPriceNumber(card.xactimateBudget.toString())
                : ''
            }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 800"
            class="label-cell"
          >
            {{ getLeadSource(card.leadSource).displayName || '' }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 800"
            class="label-cell"
          >
            <ul class="no-padding padding-left">
              <li
                v-for="(note, index) in getLeadNotes(card)"
                :key="index"
                v-html="note"
              ></li>
            </ul>
          </td>
          <td
            v-if="residentialGridCurrentTab === 810"
            class="label-cell"
          >
            {{ card.claim }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 810"
            class="label-cell"
          >
            {{ card.fieldAdjuster ? card.fieldAdjuster.name : '' }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 810"
            class="label-cell"
          >
            {{ card.fieldAdjuster ? card.fieldAdjuster.phone : '' }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 810"
            class="label-cell"
          >
            {{ toDisplayDateString(card.insuranceInspectDate) }}
            {{ toDisplayTime(card.insuranceInspectTime) }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 820"
            class="label-cell"
          >
            {{
              card.approvalStatus === 'supplement-needed'
                ? toDisplayDateString(card.supplementSubmittedDate)
                : ''
            }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 820"
            class="label-cell"
          >
            {{
              card.approvalStatus === 'supplement-needed' &&
              card.supplementAmount
                ? formatPriceNumber(card.supplementAmount.toString())
                : ''
            }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 850"
            class="label-cell"
          >
            {{ card.qaChecklist ? 'Completed' : '' }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 850"
            class="label-cell"
          >
            {{ toDisplayDateString(card.completedDate) }}
          </td>
          <td
            v-if="[830, 850, 860].includes(residentialGridCurrentTab)"
            class="label-cell"
          >
            <f7-link
              :class="`view-invoice-${card.id}`"
              class="cursor-pointer"
              @click.native="viewInvoicePopover(card.id)"
              >View Invoice</f7-link
            >
          </td>
          <td
            v-if="residentialGridCurrentTab === 830"
            class="label-cell"
          >
            <f7-link
              class="cursor-pointer"
              @click.native="openBuildContractPopup(card)"
            >
              {{ card.contractTitle || '' }}
            </f7-link>
          </td>
          <td
            v-if="residentialGridCurrentTab === 840"
            class="label-cell"
          >
            {{ card.roofSize || '' }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 840"
            class="label-cell"
          >
            {{ toDisplayDateString(card.deliveryDate) }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 840"
            class="label-cell"
          >
            {{ card.materialInspection }}
          </td>
          <td
            v-if="residentialGridCurrentTab === 850"
            class="label-cell"
          >
            <ul class="no-padding padding-left">
              <li
                v-for="(certificate, index) in certificatesByProjectId(card.id)"
                :key="index"
              >
                <f7-link
                  @click.native="openCertificatePreviewPopup(certificate)"
                  >{{ certificate.title }}</f7-link
                >
              </li>
            </ul>
          </td>
          <td class="label-cell">
            <input
              class="custom-input"
              @blur="updateSalesReps(card.id, $event.target.value.trim())"
              type="text"
              :value="card.salesRep"
              placeholder="Input sales reps"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <certificate-preview-popup
      ref="certificatePreviewPopup"
    ></certificate-preview-popup>

    <!-- contract preview -->
    <build-contract-popup
      @sendEmail="sendEmailBuildContract"
      ref="buildContractPopup"
    ></build-contract-popup>
    <send-email-popup
      ref="sendEmailPopup"
      popup-title="Send Build Contract"
      templateName="build-contract"
      :projectId="card && (card.id || '')"
      :attachments="card && (card.attachmentFiles || [])"
      :listEmails="listEmails"
      @closePopup="closeBuildContractPopup"
    ></send-email-popup>
  </div>
</template>

<script>
import UserAvatar from '@/components/avatars/UserAvatar.vue';
import { mapGetters, mapActions } from 'vuex';
import methodsMixins from '../../mixin/methods';
import computedsMixins from '../../mixin/computeds';
import sendEmail from '../../mixin/sendEmail';
import { getFullAddress } from '../../../../utility/address';
import CertificatePreviewPopup from '../../components/popup/CertificatePreviewPopup.vue';
import _ from 'lodash';
import moment from 'moment';
import { toDisplayDateString } from '../../../../utility/datetime';
import { sortLatest } from '../../../../utility/date-time-tool';
import commonInvoicesMixins from '../../../invoices/mixins/common-mixin';
import { currencyUSD } from '../../../../utility/config';
import BuildContractPopup from '../../components/popup/BuildContractPopup.vue';
import SendEmailPopup from '@/components/popups/SendEmailPopup.vue';

export default {
  mixins: [computedsMixins, methodsMixins, commonInvoicesMixins, sendEmail],

  components: {
    UserAvatar,
    CertificatePreviewPopup,
    BuildContractPopup,
    SendEmailPopup,
  },

  props: {
    projectList: Array,
  },

  data() {
    return {
      currentSort: null,
      currentSortDir: 'asc',
      invoicesByProject: [],
      search: {
        jobNumber: '',
        assigneeId: '0',
        filterActionId: '0',
        dueDate: '',
        contactName: '',
        contactPhone: '',
        jobInfor: '',
        approvalStatus: '0',
        salesRep: '',
        dueOfLoss: '',
        leadType: '0',
        claim: '',
        fieldAdjusterName: '',
        fieldAdjusterPhone: '',
        inspectionDateTime: '',
        submittedDate: '',
        contract: '',
        buildDate: '',
        roofSize: '',
        deliveryDate: '',
        materialInspection: '',
        qaComplete: '',
        completedDate: '',
      },
      selected: 0,
      maxUserDisplay: 5,
      showRemainingAssignees: '',
    };
  },

  created() {
    if (_.isEmpty(this.setting)) {
      this.bindSetting(this.tenantId);
    }
    this.bindTemplateList();
  },

  computed: {
    ...mapGetters('dashboard/project', [
      'filterCardsGrid',
      'filterAction',
      'filterActions',
    ]),
    ...mapGetters('dashboard/project', ['card', 'boardCode', 'users']),
    ...mapGetters('dashboard/client', ['contactById', 'listEmails']),
    ...mapGetters('dashboard/app-constant', [
      'insuranceApprovalStatusBy',
      'getLeadSource',
      'insuranceApprovalStatusList',
      'leadSourceResidentialList',
    ]),
    ...mapGetters('dashboard/certificate', ['certificatesByProjectId']),
    ...mapGetters('dashboard/common', ['residentialGridCurrentTab']),
    ...mapGetters('dashboard/contract', ['contract']),
    ...mapGetters('common/app-constant', ['tenantId']),

    displayInvoices() {
      let list = this.invoicesByProject.map(item => ({
        ...item,
        invoiceDate: toDisplayDateString(item.invoiceDate),
        paidDate: toDisplayDateString(item.paidDate),
        dueDate: toDisplayDateString(item.dueDate),
      }));
      list = sortLatest(list);
      return list || [];
    },

    userInfo() {
      return uid =>
        this.users.find(user => {
          return user.uid === uid;
        });
    },

    contactName() {
      return id => {
        let contact = this.contactById(id) || {};
        return `${contact.firstName || ''} ${contact.lastName || ''}`.trim();
      };
    },

    contactPhone() {
      return id => {
        let contact = this.contactById(id) || {};
        let phones = contact.phones || [];
        let phoneNumber =
          phones.find(item => item.code === 'main') || phones[0];
        return (phoneNumber || {}).value || '';
      };
    },

    actionNames() {
      return card => {
        let actions = card.actions;
        let actionsNames = [];

        for (const id of actions) {
          actionsNames.push(this.filterAction(id).title);
        }

        return actionsNames.join(', ').trim();
      };
    },

    projectListCompleted() {
      const filterCardsGrid = this.projectList.map(r => ({
        ...r,
        contactName: this.contactName(r.contactId),
        contactPhone: this.contactPhone(r.contactId),
        displayProjectAddress: this.getFullAddress(r.projectAddress),
        assigneeId: (r.assigneeIDs || []).map(
          id => (this.userInfo(id) || {}).uid
        )[0],
        displayInspectionDatetime:
          toDisplayDateString(r.insuranceInspectDate || {}) +
          ' ' +
          this.toDisplayTime(r.insuranceInspectTime),
        qaComplete: r.qaChecklist ? 'completed' : '',
      }));

      const listFilter = filterCardsGrid.filter(project => {
        return (
          (project.contactName
            .toLowerCase()
            .includes(this.search.contactName.toLowerCase()) ||
            this.search.contactName === '') &&
          ((project.displayProjectAddress || '')
            .toLowerCase()
            .includes(this.search.jobInfor.toLowerCase()) ||
            this.search.jobInfor === '') &&
          (project.cardNumber
            .toLowerCase()
            .includes(this.search.jobNumber.toLowerCase()) ||
            this.search.jobNumber === '') &&
          (project.assigneeId === this.search.assigneeId ||
            this.search.assigneeId === '0') &&
          (project.actionId === this.search.filterActionId ||
            this.search.filterActionId === '0') &&
          (toDisplayDateString(project.dueDate)
            .toLowerCase()
            .includes(this.search.dueDate.toLowerCase()) ||
            this.search.dueDate === '') &&
          ((project.contactPhone.replace(/[()+-\s]/g, '') || '')
            .toLowerCase()
            .includes(this.search.contactPhone.toLowerCase()) ||
            this.search.contactPhone === '') &&
          (project.approvalStatus === this.search.approvalStatus ||
            this.search.approvalStatus === '0') &&
          (project.createdBy
            .toLowerCase()
            .includes(this.search.salesRep.toLowerCase()) ||
            this.search.salesRep === '') &&
          ((toDisplayDateString(project.dateOfLoss) || '')
            .toLowerCase()
            .includes(this.search.dueOfLoss.toLowerCase()) ||
            this.search.dueOfLoss === '') &&
          (project.leadSource === this.search.leadType ||
            this.search.leadType === '0') &&
          ((project.claim || '')
            .toLowerCase()
            .includes(this.search.claim.toLowerCase()) ||
            this.search.claim === '') &&
          (((project.fieldAdjuster && project.fieldAdjuster.name) || '')
            .toLowerCase()
            .includes(this.search.fieldAdjusterName.toLowerCase()) ||
            this.search.fieldAdjusterName === '') &&
          ((
            (project.fieldAdjuster &&
              project.fieldAdjuster.phone.replace(/[()+-\s]/g, '')) ||
            ''
          )
            .toLowerCase()
            .includes(this.search.fieldAdjusterPhone.toLowerCase()) ||
            this.search.fieldAdjusterPhone === '') &&
          ((project.displayInspectionDatetime || '')
            .toLowerCase()
            .includes(this.search.inspectionDateTime.toLowerCase()) ||
            this.search.inspectionDateTime === '') &&
          ((toDisplayDateString(project.supplementSubmittedDate) || '')
            .toLowerCase()
            .includes(this.search.submittedDate.toLowerCase()) ||
            this.search.submittedDate === '') &&
          ((project.contractTitle || '')
            .toLowerCase()
            .includes(this.search.contract.toLowerCase()) ||
            this.search.contract === '') &&
          ((toDisplayDateString(project.productionDate) || '')
            .toLowerCase()
            .includes(this.search.buildDate.toLowerCase()) ||
            this.search.buildDate === '') &&
          ((project.roofSize || '')
            .toString()
            .toLowerCase()
            .includes(this.search.roofSize.toLowerCase()) ||
            this.search.roofSize === '') &&
          ((toDisplayDateString(project.deliveryDate) || '')
            .toLowerCase()
            .includes(this.search.deliveryDate.toLowerCase()) ||
            this.search.deliveryDate === '') &&
          ((project.materialInspection || '')
            .toLowerCase()
            .includes(this.search.materialInspection.toLowerCase()) ||
            this.search.materialInspection === '') &&
          ((project.qaComplete || '')
            .toLowerCase()
            .includes(this.search.qaComplete.toLowerCase()) ||
            this.search.qaComplete === '') &&
          ((toDisplayDateString(project.completedDate) || '')
            .toLowerCase()
            .includes(this.search.completedDate.toLowerCase()) ||
            this.search.completedDate === '')
        );
      });

      return sortLatest(listFilter);
    },
  },

  methods: {
    ...mapActions('dashboard/project', [
      'setCurrentCard',
      'getAction',
      'updateCard',
    ]),
    ...mapActions('dashboard/finance', ['getInvoiceListBys']),
    ...mapActions('dashboard/template', ['bindTemplateList']),
    ...mapActions('setting/app/system', ['bindSetting']),

    toDisplayTime(time) {
      return time ? moment(time, 'hh:mm').format('h:mm A') : '';
    },

    getLeadNotes(card) {
      const notes = (card.commentAndNote || []).filter(r => {
        return r.code === 'note' && r.title === 'Leads Note';
      });
      if (_.isEmpty(notes)) return '';
      return notes.map(r => r.htmlContent);
    },

    getFullAddress(address) {
      return getFullAddress(address);
    },

    openCertificatePreviewPopup(certificate) {
      this.$refs.certificatePreviewPopup.open(certificate);
    },

    async viewInvoicePopover(projectId) {
      this.invoicesByProject = await this.getInvoiceListBys([
        {
          prop: 'projectId',
          val: projectId,
          op: '==',
        },
      ]);
      let htmlContent = '';
      for (const invoice of this.displayInvoices) {
        htmlContent += `<tr>
                    <td class="label-cell">${invoice.invoiceNumber}</td>
                    <td class="label-cell">${currencyUSD(
                      this.total(invoice)
                    )}</td>
                    <td class="label-cell">${currencyUSD(
                      invoice.paidAmount
                    )}</td>
                    <td class="label-cell">${invoice.dueDate}</td>
                  </tr>`;
      }
      if (!htmlContent) {
        htmlContent =
          "<tr><td colspan='4' class='text-align-center'>No Data!<td></tr>";
      }
      this.$f7.popover
        .create({
          content: `
          <div class="popover" style="width:500px;">
            <div class="data-table" style="width: 100%; max-height:500px; overflow: auto">
              <table>
                <thead>
                  <tr>
                    <th class="label-cell">Invoice#</th>
                    <th class="label-cell">Invoice Amount</th>
                    <th class="label-cell">Paid Amount</th>
                    <th class="label-cell">Due Date</th>
                  </tr>
                </thead>
                <tbody>${htmlContent}</tbody>
              </table>
            </div>
          </div>`,
          targetEl: `.view-invoice-${projectId}`,
        })
        .open(true);
    },

    updateSalesReps(id, value) {
      if (value) {
        this.updateCard({
          id,
          doc: { createdBy: value },
        });
      }
    },

    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    changeValueFilter(type, event) {
      switch (type) {
        case 'action':
          this.search.filterActionId = event.target.value;
          break;
        case 'assignee':
          this.search.assigneeId = event.target.value;
          break;
        case 'approval':
          this.search.approvalStatus = event.target.value;
          break;
        case 'leadType':
          this.search.leadType = event.target.value;
          break;
      }
    },
    openBuildContractPopup(card) {
      this.$refs.buildContractPopup.open(card);
    },
    closeBuildContractPopup() {
      this.$refs.buildContractPopup.close();
    },
    sendEmailBuildContract() {
      this.sendBuildContract(this.contract.id);
    },
  },
  watch: {
    residentialGridCurrentTab: {
      deep: true,
      immediate: true,
      handler() {
        this.search = {
          jobNumber: '',
          assigneeId: '0',
          filterActionId: '0',
          dueDate: '',
          contactName: '',
          contactPhone: '',
          jobInfor: '',
          approvalStatus: '0',
          salesRep: '',
          dueOfLoss: '',
          leadType: '0',
          claim: '',
          fieldAdjusterName: '',
          fieldAdjusterPhone: '',
          inspectionDateTime: '',
          submittedDate: '',
          contract: '',
          buildDate: '',
          roofSize: '',
          deliveryDate: '',
          materialInspection: '',
          qaComplete: '',
          completedDate: '',
        };
        this.selected = 0;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.assignee-avatar {
  margin-right: 5px;
}

.assignee-cell {
  display: flex;
  max-width: 400px;
}
.sub-table::before {
  height: 0;
}
.custom-input {
  border: 1px solid #e2e2e2;
  padding: 0 4px;
}
.more-assignee-avatar {
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--f7-color-chip-neutral);
  font-size: 12px;
}
.remaining-assignees {
  position: absolute;
  width: 150px;
  top: 120%;
  left: -50%;
  transform: translateX(-50%);
  padding: 10px;
  z-index: 10;
  background-color: var(--f7-color-bg-15-neutral);
}

.remaining-assignees ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.remaining-assignees li {
  margin: 5px 0;
}
</style>
