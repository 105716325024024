export default {
  projectList: state => state.projectList,

  projectId: state => state.projectId,

  project: state => state.projectList.find(r => r.id === state.projectId) || {},

  projects: state => {
    let projectList = state.projectList.filter(
      ({ status }) => status === 'open'
    );

    projectList.sort((a, b) => sortBy(b.createdAt, a.createdAt));

    return projectList;
  },

  projectById:
    ({ projectList }) =>
    id =>
      projectList.find(r => r.id === id) || {},

  latestProject: state => {
    let sorted = [...state.projectList].sort((a, b) => {
      return b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime();
    });

    return sorted[0] || {};
  },
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
