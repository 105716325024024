import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';
import * as constant from '../../../../utility/const';

export default tenant => {
  const propertyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_property`,
    'property'
  );

  return {
    bindPropertyListBys: propertyActions.bindCollectionBys,
    bindPropertyList({ dispatch }) {
      return dispatch('bindPropertyListBys', [
        {
          prop: constant.PROP_IS_DELETED,
          op: '!=',
          val: true,
        },
      ]);
    },
    unbindPropertyList: propertyActions.unbindCollection,

    bindProperty: propertyActions.bindDocument,
    unbindProperty: propertyActions.unbindDocument,

    createProperty: propertyActions.createDocument,

    getPropertyById: propertyActions.getDocument,

    getProperty({ commit, dispatch }, id) {
      return dispatch('getPropertyById', id).then(data => {
        commit(types.SET_PROPERTY, data);
      });
    },

    resetProperty({ commit }) {
      return commit(types.SET_PROPERTY, {});
    },
  };
};
