import { sortLatest } from '../../../../utility/date-time-tool';

export default {
  estimateList: state => state.estimateList,

  estimateListByStatus: state => status => {
    const list =
      state.estimateList.filter(item => status.includes(item.status)) || [];
    return sortLatest(list);
  },

  estimate: state => state.estimate,
};
