/* eslint-disable no-unused-vars */
import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/product_product_items`,
    'productItem'
  );

  return {
    bindProductItemList: ActionsTemplate.bindCollection,
    bindProductItemListBy: ActionsTemplate.bindCollectionBy,
    bindProductItemListBys: ActionsTemplate.bindCollectionBys,
    unbindProductItemList: ActionsTemplate.unbindCollection,

    bindProductItem: ActionsTemplate.bindDocument,
    createProductItem: ActionsTemplate.createDocument,
    updateProductItem: ActionsTemplate.updateDocument,
    deletebindProductItemList: ActionsTemplate.deleteDocument,
  };
};
