import * as types from './types';
import { FirebaseActions } from '../../services/firebase.service';
import * as constant from '../../utility/const';
import { searchClient } from '../../services/search.service';
import algoliaService from '../../services/algolia.service';

export default tenant => {
  const propertyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_property`,
    'property'
  );

  const propertyIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );
  return {
    getPropertyListBys: propertyActions.getDocumentBys,
    getProperty: propertyActions.getDocument,
    addNewProperty: propertyActions.createDocument,
    saveProperty: propertyActions.updateDocument,
    setQueryFilters({ commit }, payload) {
      commit(types.SET_QUERY_FILTER, payload);
    },
    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchProperty({ state, commit, getters }, { attributesToRetrieve }) {
      const index = propertyIndexAsc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_OPRATION_PROPERTY}`,
        attributesToRetrieve:
          constant.OPERATION_PROPERTY_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: ['propertyName'],
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreProperty(
      { state, commit, getters },
      { attributesToRetrieve, page }
    ) {
      const index = propertyIndexAsc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_OPRATION_PROPERTY}`,
        attributesToRetrieve:
          constant.OPERATION_PROPERTY_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: ['propertyName'],
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setPropertyNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },

    setPropertySearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },

    setPropertySearchOrder({ commit }, payload) {
      commit(types.SET_SEARCH_ORDER, payload);
    },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    createNewProperty({ dispatch, commit }, property) {
      return dispatch('addNewProperty', property).then(id => {
        return commit(types.PROPERTY_LIST_ITEM_ADD, {
          ...property,
          id,
        });
      });
    },

    updateProperty({ dispatch, commit }, { id, doc }) {
      return dispatch('saveProperty', { id, doc }).then(() => {
        return commit(types.PROPERTY_LIST_ITEM_UPDATE, {
          id,
          doc,
        });
      });
    },

    deleteProperty({ dispatch, commit }, id) {
      return dispatch('saveProperty', {
        id,
        doc: { [constant.PROP_IS_DELETED]: true },
      }).then(() => {
        return commit(types.PROPERTY_LIST_ITEM_REMOVE, id);
      });
    },

    async createProperty({ dispatch }, property) {
      const id = await dispatch('addNewProperty', property);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: 'operation_property',
        id,
      });
      return id;
    },
  };
};
