import { auth } from '@/services/firebase.service';
import moment from 'moment';

function caclHourFromHHMM(time) {
  const timeMoment = moment(time, 'HH:mm');
  const hour = timeMoment.hour() + timeMoment.minute() / 60;
  return hour;
}

function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

export default {
  scheduleList: (state, _, __, rootGetters) => {
    const userList = rootGetters['scheduling/user/userList'];
    return state.scheduleList.map(r => ({
      ...r,
      date: moment(r.date.toDate()).format('MM/DD/YYYY'),
      user: userList.find(user => user.uid === r.userId),
      startHour: caclHourFromHHMM(r.startTime),
      finishHour: caclHourFromHHMM(r.finishTime),
    }));
  },
  scheduleId: state => state.scheduleId,
  schedule: (state, getters) => {
    const scheduleList = getters.scheduleList;
    const schedule = scheduleList.find(r => r.id === state.scheduleId);
    if (schedule) return schedule;
    if (scheduleList.length === 0) return {};
    return null;
  },
  selectedItem: state => state.selectedItem,
  filterBySelectedItem: ({ selectedItem }, getters) => {
    const scheduleList = getters.scheduleList;
    return (
      scheduleList.filter(
        s => s.userId === selectedItem.userId && s.date === selectedItem.date
      ) || []
    ).sort((a, b) => sortBy(a.startTime, b.startTime));
  },
  totalWorkTime: () => list => {
    let total = 0;
    for (let item of list) {
      const start = moment(item.startTime, 'HH:mm');
      const end = moment(item.finishTime, 'HH:mm');
      const duration = moment.duration(end.diff(start));
      const hours = duration.asHours();
      total += hours;
    }
    return total.toFixed(2);
  },
  isMyScheduling: state => state.isMyScheduling,
  editType: state => state.editType,
  isShowDetail: state => state.isShowDetail,
  schedulesByChainIdAndDate: state => state.schedulesByChainIdAndDate,
  schedulesByChainIdAndDateAndUser: state => {
    if (state.isMyScheduling) {
      return state.schedulesByChainIdAndDate.filter(
        r => r.userId === auth.currentUser.uid
      );
    }
    return state.schedulesByChainIdAndDate;
  },
  userIdsByChainIdAndDate: state =>
    [
      ...new Set((state.schedulesByChainIdAndDate || []).map(r => r.userId)),
    ].filter(r => r),
  projectIdsByChainIdAndDate: state =>
    [
      ...new Set((state.schedulesByChainIdAndDate || []).map(r => r.projectId)),
    ].filter(r => r),
};
