<template>
  <div class="display-flex">
    <f7-input
      class="spent-time margin-right"
      type="select"
      :value="spentTime.hours"
      @change="$emit('changeSpentTime', 'hours', $event.target.value)"
    >
      <option
        value="-1"
        disabled
        hidden
      >
        HH
      </option>
      <option
        v-for="h in listHourOptions"
        :key="h.value"
        :value="h.value"
      >
        {{ h.displayName }}
      </option>
    </f7-input>
    <f7-input
      class="spent-time"
      type="select"
      :value="spentTime.minutes"
      @change="$emit('changeSpentTime', 'minutes', $event.target.value)"
    >
      <option
        value="-1"
        disabled
        hidden
      >
        MM
      </option>
      <option
        v-for="m in minutesByHours"
        :key="m.value"
        :value="m.value"
      >
        {{ m.displayName }}
      </option>
    </f7-input>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    spentTime: Object,
    maxValue: { type: Number, default: 24 },
  },

  methods: {
    setMinutes() {
      if (this.spentTime.hours == this.maxValue) {
        this.spentTime.minutes = '0';
      } else if (this.spentTime.hours == '0' && this.spentTime.minutes == '0') {
        this.spentTime.minutes = '15';
      }
    },
  },

  computed: {
    ...mapGetters('time-tracking/app-constant', ['hourList', 'minuteList']),

    minutesByHours() {
      return this.spentTime.hours == this.maxValue
        ? [(this.minuteList || []).find(item => item.value == 0)]
        : this.spentTime.hours == 0
          ? (this.minuteList || []).filter(item => item.value != 0)
          : this.minuteList;
    },
    listHourOptions() {
      if (!this.maxValue) {
        return this.hourList;
      } else {
        return this.hourList.filter(r => r.value <= this.maxValue);
      }
    },
  },

  watch: {
    spentTime: {
      handler(val) {
        if (val.hours !== '') {
          this.setMinutes();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.spent-time {
  min-width: 60px;
  border-bottom: 1px solid black;
}
</style>
