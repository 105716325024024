import * as types from './types';
import Vue from 'vue';
import _ from 'lodash';

export default {
  [types.SET_ALBUM_TEMPLATE_LIST](state, list) {
    Vue.set(state, 'albumTemplateList', list);
  },
  [types.ALBUM_TEMPLATE_LIST_ITEM_UPDATE](state, { id, doc }) {
    const index = state.albumTemplateList.findIndex(item => item.id === id);

    if (index >= 0) {
      const item = _.cloneDeep(state.albumTemplateList[index]);

      for (const key in doc) {
        if (Object.hasOwnProperty.call(doc, key) && doc[key] !== undefined) {
          item[key] = doc[key];
        }
      }
      Vue.set(state.albumTemplateList, index, item);
    }
  },

  [types.ALBUM_TEMPLATE_LIST_ITEM_ADD](state, item) {
    state.albumTemplateList.push(item);
  },

  [types.ALBUM_TEMPLATE_LIST_ITEM_REMOVE](state, id) {
    const index = state.albumTemplateList.findIndex(item => item.id === id);
    if (index >= 0) {
      state.albumTemplateList.splice(index, 1);
    }
  },
  [types.RESET_DATA](state) {
    Vue.set(state, 'albumTemplateList', []);
    Vue.set(state, 'albumTemplate', {});
  },
};
