import * as types from './types';
import Vue from 'vue';

export default {
  [types.ADD_PRODUCT_ITEM](state, product) {
    state.productList.push(product);
  },
  [types.CLEAN_UP_PRODUCT_ITEMS](state) {
    state.productList = [];
  },
  [types.ADD_PRODUCT_LIST](state, list) {
    state.productList = list;
  },

  [types.SET_SEARCH_RESULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(state, 'hits', hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.ADD_MORE_RESULT](state, { hits, hitsPerPage, nbHits, nbPages, page }) {
    state.hits.push(...hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.SET_SEARCH_TEXT](state, searchText) {
    Vue.set(state, 'searchText', searchText);
  },

  [types.SET_NUMBER_OF_ROW](state, numberOfRow) {
    Vue.set(state, 'numberOfRow', numberOfRow);
  },
};
