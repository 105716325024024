export default {
  contract: state => {
    return state.contract;
  },
  // Fulltext search
  order: state => state.order,

  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
  selectedStatusFilter: state => state.selectedStatusFilter,

  queryFilters: state => {
    return state.selectedStatusFilter.map(s => `status:${s}`).join(' OR ');
  },
};
