<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Quickbooks Integration</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-block
      style="width: 700px"
      v-if="$f7.device.desktop || $f7.device.ipad"
    >
      <f7-segmented
        class="segment"
        strong
        tag="p"
      >
        <f7-button
          class="custom-hover"
          :active="activeTabId === 'export'"
          @click="goToMainTab('export')"
          >Export from Rooferintel to Quickbook</f7-button
        >
        <f7-button
          class="custom-hover"
          :active="activeTabId === 'import'"
          @click="goToMainTab('import')"
          >Import from Quickbook to Rooferintel</f7-button
        >
      </f7-segmented>
    </f7-block>

    <f7-block v-else>
      <f7-segmented
        class="segment"
        strong
        tag="p"
      >
        <f7-button
          class="custom-hover"
          :active="activeTabId === 'export'"
          @click="goToMainTab('export')"
          >Export from RI to QB</f7-button
        >
        <f7-button
          class="custom-hover"
          :active="activeTabId === 'import'"
          @click="goToMainTab('import')"
          >Import from QB to RI</f7-button
        >
      </f7-segmented>
    </f7-block>

    <TabsQB
      :activeTabId="this.activeTabId"
      :tabActive="this.tabActive"
      @onClick="onClick"
    >
    </TabsQB>

    <!-- <f7-block strong inset v-show="!hasCompanyId"
        >Before import/export company from Quickbooks. Please setup your
        Quickbooks online company ID by follow
        <f7-link href="/settings/integration/">this link.</f7-link></f7-block
      > -->

    <f7-tabs>
      <f7-tab
        id="importCompany"
        :tab-active="tabActive === 'import-company'"
      >
        <company-data-table
          :class="activeTabId === 'import' ? 'show' : 'display-none'"
          import-data
          tableTitle="Companies from Quickbooks Online"
          actionTitle="Import to RooferIntel"
          v-if="showImportTable"
          :companies="qbCustomers"
          :qbCompanyId="qbCompanyId"
          @onDone="importCompanyFromQB"
        ></company-data-table>
      </f7-tab>

      <f7-tab
        id="exportCompany"
        :tab-active="tabActive === 'export-company'"
      >
        <company-data-table
          :class="activeTabId === 'export' ? 'show' : 'display-none'"
          export-data
          tableTitle="Companies from RooferIntel"
          actionTitle="Export to Quickbooks Online"
          v-if="showExportTable"
          :companies="riCustomers"
          :qbCompanyId="qbCompanyId"
          @onDone="importCompanyFromApp"
        ></company-data-table>
      </f7-tab>

      <f7-tab
        id="exportWorking"
        :tab-active="tabActive === 'export-working'"
      >
        <!-- export working hours -->
        <export-working-hours-to-qb
          :class="activeTabId === 'export' ? 'show' : 'display-none'"
          v-if="showExportWorkingHours"
          @onDone="exportWorkingHoursToQB"
        ></export-working-hours-to-qb>
      </f7-tab>

      <f7-tab
        id="exportExpenses"
        :tab-active="tabActive === 'export-expenses'"
      >
        <!-- export expenses -->
        <export-expenses-to-qb
          :class="activeTabId === 'export' ? 'show' : 'display-none'"
          v-if="showExportExpense"
          @onDone="exportExpensesToQB"
        ></export-expenses-to-qb>
      </f7-tab>

      <f7-tab
        id="exportPO"
        :tab-active="tabActive === 'export-po'"
      >
        <!-- export payable invoice -->
        <export-invoice-to-qb
          :class="activeTabId === 'export' ? 'show' : 'display-none'"
          v-if="showExportInvoice"
        >
        </export-invoice-to-qb>
      </f7-tab>

      <f7-tab
        id="importCheckPO"
        :tab-active="tabActive === 'import-po'"
      >
        <!-- import check to roofer intel -->
        <import-check-to-roofer-intel
          :class="activeTabId === 'import' ? 'show' : 'display-none'"
          v-if="showImportCheck"
        ></import-check-to-roofer-intel>
      </f7-tab>

      <f7-tab
        id="mapProject"
        :tab-active="tabActive === 'map-project'"
      >
        <project-mapping-data-table
          :class="activeTabId === 'export' ? 'show' : 'display-none'"
          class="project-table"
          v-if="showProjectMapping"
          tableTitle="Project from RooferIntel"
          actionTitle="Map Project to Quickbooks Customer"
          @dataItemChange="handleQuickbooksCustomerChange"
          @onDone="loadProjects"
        ></project-mapping-data-table>
      </f7-tab>

      <f7-tab
        id="importInvoice"
        :tab-active="tabActive === 'import-invoice'"
      >
        <import-invoice-data-table
          :class="activeTabId === 'import' ? 'show' : 'display-none'"
          v-if="showImportInvoiceTable"
          :qbInvoices="qbInvoices"
          :projectList="projectListOptions"
          :propertyList="propertyListOptions"
          @doImport="doImportInvoiceFromQuickbooks"
        ></import-invoice-data-table>
      </f7-tab>

      <f7-tab
        id="importPayment"
        :tab-active="tabActive === 'import-payment'"
      >
        <import-payment-data-table
          :class="activeTabId === 'import' ? 'show' : 'display-none'"
          v-if="showImportPaymentTable"
          :qbPayments="qbPayments"
          @doImport="doImportPaymentFromQuickbooks"
        >
        </import-payment-data-table>
      </f7-tab>
    </f7-tabs>

    <progress-bar
      ref="progressBar"
      style="position: fixed"
    ></progress-bar>
    <introduction-popup ref="introductionPopup"></introduction-popup>
  </f7-page>
</template>

<script>
import CompanyDataTable from '../components/datatable/CompanyDataTable.vue';
import ImportInvoiceDataTable from '../components/datatable/ImportInvoiceDataTable.vue';
import ImportPaymentDataTable from '../components/datatable/ImportPaymentDataTable.vue';
import ProjectMappingDataTable from '../components/datatable/ProjectMappingDataTable.vue';

import ExportExpensesToQb from '../components/views/ExportExpensesToQb.vue';
import ExportWorkingHoursToQb from '@/plugins/quickbooks/components/views/ExportWorkingHoursToQb.vue';
import ExportInvoiceToQb from '@/plugins/quickbooks/components/views/ExportInvoiceToQb.vue';
import ImportCheckToRooferIntel from '@/plugins/quickbooks/components/views/ImportCheckToRooferIntel.vue';
import IntroductionPopup from '../components/popups/IntroductionPopup.vue';

import { mapGetters, mapActions } from 'vuex';
import quickbooksService from '../../../services/quickbooks.service';
import invoiceService from '../../../services/invoice.service';
import paymentRecordService from '@/services/payment-record.service';
import _ from 'lodash';
import Vue from 'vue';
import { DEFAULT_STATUS_INVOICE } from '../../../utility/const';
import { getFullAddress } from '@/utility/address';
import ProgressBar from '@/components/progress-bar/ProgressBar.vue';
import TabsQB from './TabsQB.vue';
import Menu from '../../../components/menu/Menu.vue';

export default {
  components: {
    CompanyDataTable,
    ProjectMappingDataTable,
    IntroductionPopup,
    ExportExpensesToQb,
    ExportWorkingHoursToQb,
    ExportInvoiceToQb,
    ImportCheckToRooferIntel,
    ProgressBar,
    ImportInvoiceDataTable,
    ImportPaymentDataTable,
    TabsQB,
    Menu,
  },

  data() {
    return {
      showImportCheck: false,
      showExportInvoice: false,
      showExportWorkingHours: false,
      showExportExpense: false,
      showExportTable: false,
      showImportTable: false,
      showProjectMapping: false,
      showImportInvoiceTable: false,
      showImportPaymentTable: false,
      qbCustomers: [],
      riCustomers: [],
      qbInvoices: [],
      qbPayments: [],
      sortFunc: (a, b) => {
        return (a.companyName ? a.companyName.charAt(0).toUpperCase() : '') <
          (b.companyName ? b.companyName.charAt(0).toUpperCase() : '')
          ? -1
          : 0;
      },
      sortIndeterminateFunc: (a, b) => {
        return a.indeterminate && !b.indeterminate ? 0 : -1;
      },
      activeTabId: 'export',
      tabActive: 'export-company',
      tab: '',
    };
  },

  created() {
    this.bindConstantTypeList();
    this.showMainTab();
  },

  mounted() {
    let dontShowQBInstruction = localStorage.getItem('dontShowQBInstruction');
    if (
      dontShowQBInstruction != 'true' &&
      this.$f7route.url.includes('/qb-contact') &&
      this.isShowIntro === true
    ) {
      this.$refs.introductionPopup.open();
      this.setIsShowIntro(false);
    }

    if (this.$f7route.url.includes('/qb-contact/import')) {
      this.activeTabId = 'import';
    } else {
      this.activeTabId = 'export';
    }

    if (this.$f7route.url === '/qb-contact/export') {
      this.tabActive = 'export-company';
    } else if (this.$f7route.url === '/qb-contact/import') {
      this.tabActive = 'import-company';
    } else {
      this.tabActive = this.$f7route.params.tabActive;
    }

    this.showData();
  },

  computed: {
    ...mapGetters('quickbooks/common', ['isShowIntro']),
    ...mapGetters('setting/app/system', ['setting']),
    ...mapGetters('quickbooks/company', ['companyList']),
    ...mapGetters('setting/app/profile', ['user']),
    ...mapGetters('quickbooks/project', ['projectList']),
    ...mapGetters('quickbooks/property', ['propertyList']),
    ...mapGetters({
      setting: 'setting/app/system/setting',
    }),
    ...mapGetters('invoices/invoice-template', ['invoiceDefaultTemplate']),
    qbCompanyId() {
      return (this.setting.quickbooks || {}).qbCompanyId;
    },

    tenantId() {
      return this.user.tenantId || localStorage.getItem('login_tenant');
    },

    projectListOptions() {
      return this.projectList.map(project => ({
        projectNumber: project.cardNumber,
        value: project.id,
        propertyId: project.propertyId || project.propertyId,
        displayName: project.title || '',
      }));
    },
    propertyListOptions() {
      //filter propery don't have project
      let propertyList = this.propertyList.filter(r =>
        this.projectListOptions.some(p => p.propertyId === r.id)
      );
      return propertyList.map(r => ({
        value: r.id,
        displayName: r.propertyName,
        address:
          r.addresses && r.addresses.length > 0
            ? getFullAddress(r.addresses[0])
            : '',
      }));
    },
  },

  methods: {
    ...mapActions('quickbooks/common', ['setIsShowIntro']),
    ...mapActions('quickbooks/company', [
      'bindCompanyList',
      'unbindCompanyList',
    ]),
    ...mapActions('quickbooks/employee-expenses', ['unbindExpenseList']),
    ...mapActions('quickbooks/time-tracking', ['unbindTimeTracking']),
    ...mapActions('quickbooks/user', ['getUserList']),
    ...mapActions('quickbooks/common', ['setCurrentBtnType']),
    ...mapActions('quickbooks/common', [
      'populateEmployee',
      'populateCustomer',
      'populateAccount',
      'populateVendor',
    ]),
    ...mapActions('quickbooks/app-constant', ['bindConstantTypeList']),
    ...mapActions('quickbooks/project', [
      'getProjects',
      'changeProjectItem',
      'unbindProjectList',
    ]),
    ...mapActions('quickbooks/property', [
      'bindPropertyList',
      'unbindPropertyList',
    ]),
    ...mapActions('quickbooks/payment-record', ['getPaymentRecordBys']),
    ...mapActions({
      bindSetting: 'setting/app/system/bindSetting',
    }),
    ...mapActions({
      getInvoicesBys: 'quickbooks/invoices/getDocumentbys',
      updateInvoice: 'quickbooks/invoices/updateInvoice',
      bindInvoiceTemplateList:
        'invoices/invoice-template/bindInvoiceTemplateList',
    }),
    importCompanyFromQB() {
      this.resetView();

      this.$f7.preloader.show();
      quickbooksService
        .checkAuth()
        .then(data => {
          if (data.success) {
            let customerRef = quickbooksService.getCustomer();
            let companyRef = this.bindCompanyList();

            return Promise.all([customerRef, companyRef]).then(data => {
              if (!_.isEmpty(data) && _.isArray(data)) {
                this.qbCustomers = _.cloneDeep(data[0]).sort(this.sortFunc);
              } else {
                this.qbCustomers = [];
              }

              // TODO: map company type 'general-contractor' for all
              this.qbCustomers.map(item => {
                item.companyType = ''; //"general-contractor";
              });

              // Find common elements
              const commonElements = _.intersectionWith(
                this.qbCustomers,
                this.companyList,
                (customer, company) => customer.Id == company.quickbooksId
              );

              commonElements.map(element => {
                element.indeterminate = true;
              });

              // TODO: map company type
              commonElements.forEach(customer => {
                let companyType =
                  ((
                    this.companyList.find(
                      company => company.quickbooksId === customer.Id
                    ) || {}
                  ).companyTypes || [])[0] || '';

                customer.companyType = companyType;
              });

              this.qbCustomers = this.qbCustomers.sort(
                this.sortIndeterminateFunc
              );

              this.showImportTable = true;
            });
          } else {
            this.loginQB();
          }
        })
        .catch(error => {
          if (error.name === 'unauthorized') {
            this.loginQB();
          } else {
            this.showErrorMessage(error.message);
          }
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    importCompanyFromApp() {
      this.resetView();

      this.$f7.preloader.show();
      this.bindCompanyList()
        .then(() => {
          this.riCustomers = _.cloneDeep(this.companyList).sort(this.sortFunc);

          // Find common elements
          this.riCustomers.map(element => {
            element.indeterminate = !!element.quickbooksId;
          });

          this.riCustomers = this.riCustomers.sort(this.sortIndeterminateFunc);

          this.showExportTable = true;
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    exportExpensesToQB() {
      this.resetView();
      this.setCurrentBtnType('expenses');

      quickbooksService
        .checkAuth()
        .then(data => {
          if (data.success) {
            this.showExportExpense = true;
            this.$f7.preloader.show();
            const refs = [];
            refs.push(this.populateAccount());
            refs.push(this.populateVendor());
            refs.push(this.getUserList());

            return Promise.all(refs);
          } else {
            this.loginQB();
          }
        })
        .catch(error => {
          if (error.name === 'unauthorized') {
            this.loginQB();
          } else {
            this.showErrorMessage(error.message);
          }
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    exportWorkingHoursToQB() {
      this.resetView();
      this.setCurrentBtnType('workingHours');

      quickbooksService
        .checkAuth()
        .then(data => {
          if (data.success) {
            this.showExportWorkingHours = true;
            this.$f7.preloader.show();
            const refs = [];
            refs.push(this.populateEmployee());
            refs.push(this.populateCustomer());
            refs.push(this.getUserList());

            if (_.isEmpty(this.projectList)) {
              refs.push(this.getProjects());
            }

            return Promise.all(refs);
          } else {
            this.loginQB();
          }
        })
        .catch(error => {
          if (error.name === 'unauthorized') {
            this.loginQB();
          } else {
            this.showErrorMessage(error.message);
          }
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    exportInvoiceToQB() {
      this.resetView();
      this.showExportInvoice = true;
      this.setCurrentBtnType('invoice');
    },

    importCheckToRooferIntel() {
      this.resetView();
      this.showImportCheck = true;
      this.setCurrentBtnType('check');
    },

    loadProjects() {
      this.resetView();
      this.$f7.preloader.show();

      quickbooksService
        .checkAuth()
        .then(data => {
          if (data.success) {
            const refs = [];
            refs.push(this.getProjects());
            refs.push(this.bindPropertyList());
            refs.push(this.populateCustomer(this.tenantId));
            return Promise.all(refs);
          } else {
            return this.loginQB();
          }
        })
        .then(() => {
          this.showProjectMapping = true;
        })
        .catch(error => {
          if (error.name === 'unauthorized') {
            this.loginQB();
          } else {
            this.showErrorMessage(error.message);
          }
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },
    async importInvoiceQuickbooks() {
      this.resetView();
      this.$f7.preloader.show();
      quickbooksService
        .checkAuth()
        .then(async data => {
          if (data.success) {
            let refs = [];
            refs.push(quickbooksService.getInvoices());
            if (_.isEmpty(this.projectList)) {
              refs.push(this.getProjects());
            }
            if (_.isEmpty(this.propertyList)) {
              refs.push(this.bindPropertyList());
            }
            let result = await Promise.all(refs);
            const qbInvoices = result[0];
            this.qbInvoices = this.mapQuickbookInvoices(qbInvoices);
            const importedInvoices =
              (await this.getInvoicesBys([
                {
                  prop: 'qbInvoiceId',
                  op: '!=',
                  val: '',
                },
              ])) || [];
            // Find common elements
            const commonElements = _.intersectionWith(
              this.qbInvoices,
              importedInvoices,
              (a, b) => a.qbInvoiceId == b.qbInvoiceId
            );
            commonElements.map(element => {
              element.indeterminate = true;
            });

            commonElements.forEach(invoice => {
              let existingInvoice =
                importedInvoices.find(
                  r => r.qbInvoiceId === invoice.qbInvoiceId
                ) || {};
              invoice.propertyId = existingInvoice.propertyId;
              invoice.projectId = existingInvoice.projectId;
            });

            this.qbInvoices = this.qbInvoices.sort((a, b) => {
              return a.indeterminate && !b.indeterminate ? 0 : -1;
            });

            this.$f7.preloader.hide();
            this.showImportInvoiceTable = true;
          } else {
            await this.loginQB();
          }
        })
        .catch(error => {
          if (error.name === 'unauthorized') {
            this.loginQB();
          } else {
            this.showErrorMessage(error.message);
          }
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },
    mapQuickbookInvoices(qbInvoices) {
      return (
        qbInvoices.map(r => {
          let doc = {
            qbInvoiceId: r.Id,
            qbDocNumber: r.DocNumber,
            totalAmount: r.TotalAmt,
            status: this.mapInvoiceStatus(r),
            invoiceDate: new Date(r.TxnDate),
            dueDate: new Date(r.DueDate),
            clientName: (r.CustomerRef || {}).name,
            clientAddress: this.getClientAddress(r),
            clientEmail: (r.BillEmail || {}).Address,
            notes: (r.CustomerMemo || {}).value,
            itemDetails: this.getItemDetails(r),
            discount: {
              type: 'cash',
              value:
                (r.Line.find(i => i.DetailType == 'DiscountLineDetail') || {})
                  .Amount || 0,
            },
            tax: {
              type: 'cash',
              value: r.TxnTaxDetail.TotalTax || 0,
            },
          };
          if (
            ['in-partial-paid', 'in-paid'].includes(this.mapInvoiceStatus(r))
          ) {
            doc.paidAmount = r.TotalAmt - r.Balance;
            doc.paidDate = new Date(r.MetaData.LastUpdatedTime);
          }
          return doc;
        }) || []
      );
    },
    async doImportInvoiceFromQuickbooks(invoices) {
      this.$refs.progressBar.show();
      this.$refs.progressBar.setText(`Preparing data...`);
      if (_.isEmpty(this.setting)) {
        await this.bindSetting(this.tenantId);
      }
      if (!this.invoiceDefaultTemplate) {
        await this.bindInvoiceTemplateList();
      }
      //const qbInvoices = await quickbooksService.getInvoices();
      if (_.isEmpty(invoices)) return;
      this.$refs.progressBar.setText(
        `Importing ${0}/${invoices.length} invoices`
      );
      if (invoices && invoices.length > 0) {
        for (let index = 0; index < invoices.length; index++) {
          const invoice = invoices[index];
          const customer = invoice.CustomerRef
            ? await quickbooksService.getCustomerDetail(
                invoice.CustomerRef.value
              )
            : null;
          const doc = {
            ...invoice,
            clientPhoneNumber: customer
              ? (customer.PrimaryPhone || {}).FreeFormNumber
              : '',
            roofingCompanyName: this.setting ? this.setting.companyName : '',
            roofingCompanyAddress: this.setting ? this.setting.address : '',
            roofingCompanyPhone: this.setting ? this.setting.phoneNumber : '',
            syncFromQB: true,
            logo: this.invoiceDefaultTemplate.logo,
          };
          delete doc.checked;
          delete doc.indeterminate;
          await invoiceService.addInvoiceDoc(this.tenantId, doc);
          this.$refs.progressBar.setValue((index * 100) / invoices.length);
          this.$refs.progressBar.setText(
            `Importing ${index + 1}/${invoices.length} invoices`
          );
        }
      }
      if (invoices && invoices.length == 0) {
        this.$refs.progressBar.setText(
          `Don't have any new invoices from Quickbooks`
        );
      } else {
        this.$refs.progressBar.setText(
          `Imported ${invoices.length} invoices from Quickbooks`
        );
        //refresh table
        this.importInvoiceQuickbooks();
      }
      setTimeout(() => this.$refs.progressBar.hide(), 1000);
    },
    getClientAddress(invoice) {
      if (!invoice.BillAddr) return '';
      return `${invoice.BillAddr.Line1 || ''}, ${
        invoice.BillAddr.City || ''
      }, ${
        invoice.BillAddr.CountrySubDivisionCode ||
        invoice.BillAddr.Country ||
        ''
      }`;
    },
    getItemDetails(invoice) {
      let itemDetails = [];
      const discount = (
        invoice.Line.find(r => r.DetailType == 'DiscountLineDetail') || {}
      ).Amount
        ? true
        : false;
      (invoice.Line || [])
        .filter(
          r =>
            !(
              r.DetailType == 'SubTotalLineDetail' ||
              r.DetailType == 'DiscountLineDetail'
            )
        )
        .forEach(line => {
          let lineData = {
            productName: line[line.DetailType]
              ? (line[line.DetailType].ItemRef || {}).name
              : '',
            price: line[line.DetailType]
              ? line[line.DetailType].UnitPrice || ''
              : line.UnitPrice || '',
            quantity: line[line.DetailType]
              ? line[line.DetailType].Qty || ''
              : line.Qty || '',
            discount,
            tax:
              line[line.DetailType] &&
              line[line.DetailType].TaxCodeRef &&
              line[line.DetailType].TaxCodeRef.value == 'TAX'
                ? true
                : false,
          };
          itemDetails.push(lineData);
        });
      return itemDetails;
    },
    getTaxLine(invoice) {
      return (
        (
          (((invoice.TxnTaxDetail || {}).TaxLine || [{}])[0] || {})
            .TaxLineDetail || {}
        ).TaxPercent || 0
      );
    },
    mapInvoiceStatus(invoice) {
      if (invoice.Balance == 0 && invoice.TotalAmt > 0) return 'in-paid';
      if (invoice.Balance < invoice.TotalAmt) return 'in-partial-paid';
      if (invoice.EmailStatus == 'EmailSent') return 'in-sent';

      return DEFAULT_STATUS_INVOICE;
    },
    handleQuickbooksCustomerChange(prop, customerRef, projectId) {
      let foundItem = _.cloneDeep(
        this.projectList.find(item => item.id === projectId)
      );
      Vue.set(foundItem, prop, customerRef);
      this.changeProjectItem({ id: projectId, item: foundItem });
    },
    async refreshPaymentImport() {
      this.resetView();
      const importedPayment =
        (await this.getPaymentRecordBys([
          {
            prop: 'qbPaymentId',
            op: '!=',
            val: '',
          },
        ])) || [];
      // Find common elements
      const commonElements = _.intersectionWith(
        this.qbPayments,
        importedPayment,
        (a, b) => a.qbPaymentId == b.qbPaymentId
      );
      commonElements.map(element => {
        element.indeterminate = true;
      });
      this.qbPayments = this.qbPayments.sort((a, b) => {
        return a.indeterminate && !b.indeterminate ? 0 : -1;
      });
      this.showImportPaymentTable = true;
    },
    async importPaymentQuickbooks() {
      this.resetView();
      this.$refs.progressBar.show();
      this.$f7.preloader.show();
      this.$refs.progressBar.setText(
        `Loading payments from Quickbook Online. Please wait...`
      );
      quickbooksService
        .checkAuth()
        .then(async data => {
          if (data.success) {
            let qbPayments = await quickbooksService.getPayments();
            this.qbPayments =
              await this.mapAndFilterQuickbookPayments(qbPayments);

            const importedPayment =
              (await this.getPaymentRecordBys([
                {
                  prop: 'qbPaymentId',
                  op: '!=',
                  val: '',
                },
              ])) || [];
            // Find common elements
            const commonElements = _.intersectionWith(
              this.qbPayments,
              importedPayment,
              (a, b) => a.qbPaymentId == b.qbPaymentId
            );
            commonElements.map(element => {
              element.indeterminate = true;
            });

            this.qbPayments = this.qbPayments.sort((a, b) => {
              return a.indeterminate && !b.indeterminate ? 0 : -1;
            });

            this.$refs.progressBar.hide();
            this.$f7.preloader.show();
            this.showImportPaymentTable = true;
          } else {
            await this.loginQB();
          }
        })
        .catch(error => {
          if (error.name === 'unauthorized') {
            this.loginQB();
          } else {
            this.showErrorMessage(error.message);
          }
        })
        .finally(() => {
          this.$f7.preloader.hide();
          this.$refs.progressBar.hide();
        });
    },
    async mapAndFilterQuickbookPayments(payments) {
      let result = [];
      const importedInvoices =
        (await this.getInvoicesBys([
          {
            prop: 'qbInvoiceId',
            op: '!=',
            val: '',
          },
        ])) || [];
      for (let index = 0; index < payments.length; index++) {
        //payments.forEach(async (payment, index) => {
        const payment = payments[index];
        const qbInvoiceId =
          payment.Line[0] && payment.Line[0].LinkedTxn[0]
            ? payment.Line[0].LinkedTxn[0].TxnId
            : 'None';
        //get invoiceNumber
        const invoice = importedInvoices.find(
          r => r.qbInvoiceId == qbInvoiceId
        );
        //filter out items not includes into imported invoice list
        if (invoice) {
          const paymentMethod = (payment.PaymentMethodRef || {}).value
            ? await quickbooksService.getPaymentMethod(
                (payment.PaymentMethodRef || {}).value
              )
            : {};
          const doc = {
            qbPaymentId: payment.Id,
            amountReceived: payment.TotalAmt,
            customerName: (payment.CustomerRef || {}).name,
            paymentDate: new Date(payment.TxnDate),
            invoiceNumber: invoice.invoiceNumber,
            invoiceId: invoice.id,
            jobName: invoice.jobName,
            propertyName: invoice.propertyName,
            clientName: invoice.clientName,
            qbInvoiceId: qbInvoiceId,
            paymentMode: this.mapPaymentMethod(paymentMethod.Name),
          };
          result.push(doc);
        }
      }
      return result;
    },
    updateInvoiceInfo(qbInvoices, doc) {
      let invoice = qbInvoices.find(r => r.qbInvoiceId == doc.qbInvoiceId);
      if (invoice) {
        this.updateInvoice({
          id: doc.invoiceId,
          doc: invoice,
        });
      }
    },
    async doImportPaymentFromQuickbooks(qbPayments) {
      this.$refs.progressBar.show();
      this.$refs.progressBar.setText(`Preparing data...`);
      if (_.isEmpty(this.setting)) {
        await this.bindSetting(this.tenantId);
      }
      this.$refs.progressBar.setText(
        `Importing ${0}/${qbPayments.length} payments`
      );
      let qbInvoices = await quickbooksService.getInvoices();
      qbInvoices = this.mapQuickbookInvoices(qbInvoices);
      if (qbPayments && qbPayments.length > 0) {
        for (let index = 0; index < qbPayments.length; index++) {
          const payment = qbPayments[index];
          const doc = {
            ...payment,
            syncFromQB: true,
          };
          delete doc.checked;
          delete doc.indeterminate;
          delete doc.jobName;
          delete doc.propertyName;
          delete doc.clientName;
          await paymentRecordService.addPaymentRecordDoc(this.tenantId, doc);
          this.updateInvoiceInfo(qbInvoices, doc);
          this.$refs.progressBar.setValue((index * 100) / qbPayments.length);
          this.$refs.progressBar.setText(
            `Importing ${index + 1}/${qbPayments.length} payments`
          );
        }
      }
      if (qbPayments && qbPayments.length == 0) {
        this.$refs.progressBar.setText(
          `Don't have any new payments from Quickbooks`
        );
      } else {
        this.$refs.progressBar.setText(
          `Imported ${qbPayments.length} payments from Quickbooks`
        );
        this.refreshPaymentImport();
      }
      setTimeout(() => this.$refs.progressBar.hide(), 1000);
    },
    mapPaymentMethod(method) {
      let result = '';
      switch (method) {
        case 'Check':
          result = 'check';
          break;
        case 'Cash':
          result = 'cash';
          break;
        default:
          result = 'others';
          break;
      }
      return result;
    },
    loginQB() {
      this.$ri.dialog.openInfoDialog({
        title: 'Connect to Quickbooks Online.',
        content:
          'You need to set up a connection between RooferIntel and Quickbook. It will take about 5 minutes. Do you want to proceed now?',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            quickbooksService.getAuthUri('qb-contact/export').then(uri => {
              window.location.href = uri;
            });
          }
        },
      });
    },

    showErrorMessage(messages) {
      this.$ri.dialog.openWarningDialog({
        title: 'Connect to Quickbooks Online.',
        content: messages,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            window.location.href = '/qb-contact/export';
          }
        },
      });
    },

    resetView() {
      this.showImportTable = false;
      this.showExportTable = false;
      this.showExportExpense = false;
      this.showExportWorkingHours = false;
      this.showExportInvoice = false;
      this.showImportCheck = false;

      this.showProjectMapping = false;
      this.showImportInvoiceTable = false;
      this.showImportPaymentTable = false;

      this.unbindCompanyList();
      this.unbindExpenseList();
      this.unbindProjectList();
      this.unbindPropertyList();
      this.unbindTimeTracking();
    },

    showTab(name) {
      this.tabActive = name;
    },

    showMainTab() {
      var isTab = this.$f7route.params.tabActive;
      if (!isTab && this.$f7route.url === '/qb-contact/export') {
        this.$f7router.navigate(`/qb-contact/export`, {
          reloadAll: true,
          pushState: true,
        });
        this.activeTabId = 'export';
      } else if (!isTab && this.$f7route.url === '/qb-contact/import') {
        this.$f7router.navigate(`/qb-contact/import`, {
          reloadAll: true,
          pushState: true,
        });
        this.activeTabId = 'import';
      }
    },

    goToMainTab(mainTab) {
      switch (mainTab) {
        case 'export':
          this.activeTabId = 'export';
          this.$f7router.navigate(`/qb-contact/export`, {
            reloadAll: true,
            pushState: true,
          });
          break;
        case 'import':
          this.activeTabId = 'import';
          this.$f7router.navigate(`/qb-contact/import`, {
            reloadAll: true,
            pushState: true,
          });
          break;
      }
    },

    goToTab() {
      if (this.activeTabId === 'export') {
        this.$f7router.navigate(`/qb-contact/export/${this.tabActive}`, {
          reloadAll: true,
          pushState: true,
        });
      } else if (this.activeTabId === 'import') {
        this.$f7router.navigate(`/qb-contact/import/${this.tabActive}`, {
          reloadAll: true,
          pushState: true,
        });
      }
    },

    showData() {
      if (this.activeTabId === 'export') {
        if (this.tabActive === 'export-company') {
          this.importCompanyFromApp();
        } else if (this.tabActive === 'export-working') {
          this.exportWorkingHoursToQB();
        } else if (this.tabActive === 'export-expenses') {
          this.exportExpensesToQB();
        } else if (this.tabActive === 'export-po') {
          this.exportInvoiceToQB();
        } else if (this.tabActive === 'map-project') {
          this.loadProjects();
        }
      } else if (this.activeTabId === 'import') {
        if (this.tabActive === 'import-company') {
          this.importCompanyFromQB();
        } else if (this.tabActive === 'import-invoice') {
          this.importInvoiceQuickbooks();
        } else if (this.tabActive === 'import-po') {
          this.importCheckToRooferIntel();
        } else if (this.tabActive === 'import-payment') {
          this.importPaymentQuickbooks();
        }
      }
    },

    onClick(tab) {
      if (this.activeTabId === 'export') {
        switch (tab) {
          case 'export-company':
            this.showTab('export-company');
            this.goToTab();
            break;
          case 'export-working':
            this.showTab('export-working');
            this.goToTab();
            break;
          case 'export-expenses':
            this.showTab('export-expenses');
            this.goToTab();
            break;
          case 'export-po':
            this.showTab('export-po');
            this.goToTab();
            break;
          case 'map-project':
            this.showTab('map-project');
            this.goToTab();
            break;
        }
      } else if (this.activeTabId === 'import') {
        switch (tab) {
          case 'import-company':
            this.showTab('import-company');
            this.goToTab();
            break;
          case 'import-invoice':
            this.showTab('import-invoice');
            this.goToTab();
            break;
          case 'import-po':
            this.showTab('import-po');
            this.goToTab();
            break;
          case 'import-payment':
            this.showTab('import-payment');
            this.goToTab();
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.project-table {
  overflow: auto;
}

.buttonn {
  margin-left: 5px;
  font-weight: 500;
  background: white;
}

.custom-link ::v-deep .tab-link:not(.tab-link-active) {
  background-color: white;
}

.tab-link-active {
  color: white;
  background: #f05034;
}

.toolbar {
  left: 10px;
}

.row1 {
  width: 680px;
}

.row2 {
  width: 540px;
}

.segment {
  margin-left: -6px;
}
</style>
