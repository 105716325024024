import { FirebaseActions } from '../../../../services/firebase.service';
import * as constant from '../../../../utility/const';

export default tenant => {
  const companyAction = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    'company'
  );

  return {
    bindCompanyListBys: companyAction.bindCollectionBys,
    bindCompanyList({ dispatch }) {
      return dispatch('bindCompanyListBys', [
        {
          prop: constant.PROP_IS_DELETED,
          op: '!=',
          val: true,
        },
      ]);
    },
    unbindCompanyList: companyAction.unbindCollection,
    updateProduct: companyAction.updateDocument,

    create: companyAction.createDocument,
    getCompanyById: companyAction.getDocument,
  };
};
