import _ from 'lodash';
export default {
  activityList: state => {
    return _.cloneDeep(state.activityList).sort((a, b) => {
      return a.createdAt && b.createdAt
        ? b.createdAt.seconds - a.createdAt.seconds
        : -1;
    });
  },

  activity: state => state.activity,
};
