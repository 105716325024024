import Vue from 'vue';

export default {
  setWorkOrderList(state, payload) {
    Vue.set(state, 'workOrderList', payload);
  },
  setWorkOrderSubscribe(state, payload) {
    Vue.set(state, 'workOrderSubscribe', payload);
  },
};
