<template>
  <f7-list class="no-margin">
    <div class="btn-upload">
      <!-- <input
        ref="avatarImageInput"
        type="file"
        v-show="isEdit"
        @change="onUploadImage"
      /> -->
      <image-uploader
        v-show="isEdit"
        :maxWidth="256"
        :preview="false"
        :quality="0.7"
        class="image-uploader"
        accept="image/*"
        doNotResize="gif"
        :autoRotate="false"
        outputFormat="verbose"
        @input="onUploadImage"
      ></image-uploader>

      <div
        class="bg-upload"
        v-show="isEdit"
      >
        <span style="color: white; text-align: center; font-size: 12px">{{
          isEdit ? textUpload : ''
        }}</span>
      </div>
      <user-avatar
        :avatarSize="avatarSize"
        :name="name || 'Upload'"
        :imageUrl="imageUrl"
        border
      />
    </div>
  </f7-list>
</template>
<script>
import UserAvatar from '@/components/avatars/UserAvatar';
// import ImageTools from "../../utility/image-tools";
export default {
  components: {
    UserAvatar,
  },

  props: {
    imageUrl: String,
    avatarSize: Number,
    name: String,
    textUpload: String,
    isEdit: { type: Boolean, default: false },
  },

  methods: {
    onUploadImage(data) {
      this.$emit('upload', data);
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-upload {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 25%;
  position: absolute;
  width: 70px;
  background: black;
  bottom: 0px;
  opacity: 0.5;
}
.btn-upload {
  position: relative;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
}

.image-uploader ::v-deep input[type='file'] {
  position: absolute;
  opacity: 0;
  z-index: 0;
  max-width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}
</style>
