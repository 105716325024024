import * as types from './types';
export default {
  [types.SET_STACKCT_PROJECTS](state, stackctProjects) {
    state.stackctProjects = stackctProjects;
  },

  [types.SET_STACKCT_TAKEOFFS](state, stackctTakeOffs) {
    state.stackctTakeoffs = stackctTakeOffs;
  },
  [types.SET_STACKCT_PAGES](state, stackctPages) {
    state.stackctPages = stackctPages;
  },
  [types.SET_STACKCT_PLANS](state, plans) {
    state.stackctPlans = plans;
  },
};
