import _ from 'lodash';
export default {
  expenseHistory: state => {
    return _.cloneDeep(state.expenseHistory).sort((a, b) => {
      return a.createdAt && b.createdAt
        ? b.createdAt.seconds - a.createdAt.seconds
        : -1;
    });
  },

  workingHoursHistory: state => {
    return _.cloneDeep(state.workingHoursHistory).sort((a, b) => {
      return a.createdAt && b.createdAt
        ? b.createdAt.seconds - a.createdAt.seconds
        : -1;
    });
  },
};
