<template>
  <f7-page v-if="team">
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Team Details</f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-if="isOwner || isAdminGroup || team.managerID === user.uid"
          @click="isShowTeamDetailPopup = true"
        >
          Edit
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block-title>Information</f7-block-title>
    <f7-list>
      <f7-list-item
        header="Team name"
        :title="team.teamName"
      ></f7-list-item>
      <f7-list-item
        header="Description"
        :title="team.description"
      ></f7-list-item>
    </f7-list>
    <f7-block-title>Actions</f7-block-title>
    <f7-list>
      <f7-list-item title="Active">
        <f7-toggle
          slot="after"
          disabled
          :checked="!team.disabled"
        ></f7-toggle>
      </f7-list-item>
    </f7-list>
    <f7-list>
      <f7-list-item header="Team Member">
        <chip-more
          slot="title"
          :deleteable="false"
          :data="teamUsers"
        />
      </f7-list-item>
    </f7-list>
    <f7-list>
      <f7-list-button
        v-if="team.managerID === user.uid"
        color="red"
        @click.native="remove"
      >
        Delete
      </f7-list-button>
    </f7-list>

    <team-detail-popup
      :isShow="isShowTeamDetailPopup"
      :isNew="false"
      @close="isShowTeamDetailPopup = false"
    ></team-detail-popup>
  </f7-page>
  <f7-page v-else>
    <f7-navbar backLink="Back">
      <f7-nav-title>Not found Team</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-block-title medium>The team could not be found</f7-block-title>
    <f7-block strong>
      <p>It may have been moved or deleted by another user.</p>
    </f7-block>
  </f7-page>
</template>
<script>
import ChipMore from '@/components/chips/ChipMore.vue';
import { mapActions, mapGetters } from 'vuex';
import TeamDetailPopup from '@/plugins/time-tracking/components/popups/TeamDetailPopup.vue';
import _ from 'lodash';

export default {
  components: {
    ChipMore,
    TeamDetailPopup,
  },

  data: () => {
    return {
      isShowTeamDetailPopup: false,
    };
  },

  computed: {
    ...mapGetters('time-tracking/user', ['userList']),
    ...mapGetters('common/team-management', ['team']),
    ...mapGetters('setting/app/profile', ['user', 'isOwner']),
    ...mapGetters('setting/app/group', ['isAdminGroup']),

    teamUsers() {
      const userIds = (this.team || {}).userIDs || [];
      let users = this.userList.filter(r => userIds.includes(r.uid));
      users = users.map(({ displayName: name, uid: id }) => ({
        id,
        name: this.team.managerID === id ? `${name} (Manager)` : name,
      }));
      users.forEach((user, index) => {
        if (user.id === this.team.managerID) {
          users.splice(index, 1);
          users.unshift(user);
        }
      });
      return users;
    },
  },

  methods: {
    ...mapActions('common/team-management', ['deleteTeam', 'bindTeam']),
    ...mapActions('common/administration-user', ['removeTeamRef']),
    remove() {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: 'Delete team',
        content: 'Are you sure you want to delete the team?',
        textButton: 'Delete',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$f7.preloader.show();
            const teamID = this.team.id;
            const userIDs = _.cloneDeep(this.team.userIDs);
            this.deleteTeam(teamID).then(() => {
              for (const id of userIDs) {
                this.removeTeamRef({
                  id,
                  teamID,
                });
              }

              app.$f7router.navigate('/team-management', {
                reloadAll: true,
              });
              _sefl.app.dialog.close();
              app.$f7.preloader.hide();
            });
          }
        },
      });
    },
    handleBack() {
      this.$f7router.back('/team-management', { force: true });
    },
  },

  created() {
    this.bindTeam(this.$f7route.params.id);
  },
};
</script>
<style scoped>
.list /deep/ .item-title {
  white-space: normal;
}
</style>
