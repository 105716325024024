<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="handlePopupClosed"
    @popup:opened="handlePopupOpened"
  >
    <div class="page">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title
          >{{ $props.isEdit ? 'Edit' : 'Add New' }} Certificate</f7-nav-title
        >
        <f7-nav-right>
          <f7-link
            v-if="!$props.isEdit"
            @click="onDone"
            >Done</f7-link
          >
          <f7-link
            v-else
            @click="onSave"
            >Save</f7-link
          >
        </f7-nav-right>
      </f7-navbar>
      <div
        media-list
        class="page-content"
      >
        <f7-list>
          <!-- Main list item -->
          <f7-list-item
            v-for="(item, index) in certificate.attachmentFiles"
            :key="index"
            :swipeout="!$device.desktop"
            text-color="blue"
          >
            <a
              class="cursor-pointer"
              slot="title"
            >
              {{ item.fileName }}
            </a>

            <!-- <div slot="after" v-if="selectDefault && item.default">Official</div> -->
            <f7-popover
              :class="
                $device.desktop
                  ? `popover-description-${index} popover-description-desktop`
                  : `popover-description-${index} popover-description-mobile`
              "
            >
              <f7-list class="cursor-pointer menu-actions">
                <f7-list-item popover-close>
                  <div
                    style="word-break: break-word"
                    v-html="item.description"
                  ></div>
                </f7-list-item>
              </f7-list>
            </f7-popover>
            <div
              v-show="$device.desktop"
              slot="after"
              class="add-button-icon cursor-pointer"
              @click="deleteFileWithConfirm(index)"
            >
              <f7-icon f7="trash"></f7-icon>
            </div>
          </f7-list-item>

          <!-- Add list item -->
          <f7-list-item @click.native="openFileChooser">
            <div
              slot="media"
              class="add-button-icon cursor-pointer"
            >
              <f7-icon f7="plus_circle"></f7-icon>
            </div>
            <div
              class="cursor-pointer"
              slot="title"
            >
              Upload an certificate
            </div>
            <input
              v-show="false"
              ref="fileInput"
              type="file"
              multiple
              @change="onUploadFile"
            />
          </f7-list-item>
        </f7-list>
        <f7-list
          :inline-labels="$device.desktop"
          class="margin-top"
          :class="{ 'no-margin': !$device.desktop }"
          style="border-radius: 3px; overflow: hidden"
          no-hairlines
        >
          <f7-list-input
            label="Certificate Name"
            placeholder="Enter certificate name"
            :value="certificate.certificateName"
            @input="onChangeCertificate('certificateName', $event.target.value)"
            type="text"
            error-message-force
            :error-message="validation.certificateName"
          >
            <required-asterisk slot="label" />
          </f7-list-input>
          <f7-list-input
            label="Certificate Authority"
            placeholder="Enter authority name"
            :value="certificate.certificateAuthority"
            @input="
              onChangeCertificate('certificateAuthority', $event.target.value)
            "
            type="text"
            error-message-force
            :error-message="validation.certificateAuthority"
          >
            <required-asterisk slot="label" />
          </f7-list-input>
        </f7-list>
        <f7-list
          :inline-labels="$device.desktop"
          class="margin-top"
          :class="{ 'no-margin': !$device.desktop }"
          style="border-radius: 3px; overflow: hidden"
          no-hairlines
        >
          <f7-list-input
            label="Certificate Number"
            placeholder="Enter certificate number"
            :value="certificate.certificateNumber"
            @input="
              onChangeCertificate('certificateNumber', $event.target.value)
            "
            type="text"
            error-message-force
          >
          </f7-list-input>
          <f7-list-input
            label="Completion Date"
            type="datepicker"
            placeholder="MM/DD/YYYY"
            :calendar-params="{
              backdrop: true,
              openIn: 'customModal',
              header: true,
              footer: false,
              dateFormat: 'mm/dd/yyyy',
              disabled: {
                to: new Date(new Date().getTime() - 86400000), // yesterdat
              },
            }"
            :value="certificate.completionDate || []"
            @calendar:change="
              onChangeCertificate('completionDate', $event);
              instance.proxy.$f7.calendar.close();
            "
            error-message-force
            :error-message="validation.completionDate"
          >
            <required-asterisk slot="label" />
            <span
              v-if="validation.completionDate"
              class="error-message"
              >{{ validation.completionDate }}</span
            >
          </f7-list-input>
          <f7-list-input
            label="Expired Date"
            type="datepicker"
            placeholder="MM/DD/YYYY"
            :calendar-params="{
              backdrop: true,
              openIn: 'customModal',
              header: true,
              footer: false,
              dateFormat: 'mm/dd/yyyy',
              disabled: {
                from: null,
                to: new Date(
                  new Date(certificate.completionDate.toString()).getTime() -
                    86400000
                ), //to completionDate
              },
            }"
            :value="certificate.expiredDate || []"
            @calendar:change="
              onChangeCertificate('expiredDate', $event);
              instance.proxy.$f7.calendar.close();
            "
            error-message-force
          >
          </f7-list-input>
        </f7-list>
        <f7-list
          :inline-labels="$device.desktop"
          class="margin-top"
          :class="{ 'no-margin': !$device.desktop }"
          style="border-radius: 3px; overflow: hidden"
          no-hairlines
        >
          <f7-list-input
            label="Note"
            placeholder="Enter note"
            :value="certificate.note"
            @input="onChangeCertificate('note', $event.target.value)"
            type="text"
            error-message-force
          >
          </f7-list-input>
        </f7-list>
      </div>
    </div>
  </f7-popup>
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  watch,
  getCurrentInstance,
  reactive,
  onMounted,
} from 'vue';
import { useMyCertificatesStore } from '../../../../stores/my-certificates/actions';
import {
  firebase,
  uploadFile,
  removeFile,
  auth,
} from '../../../../services/firebase.service';
import _ from 'lodash';

interface FileInput {
  name: string;
}
interface Certificate {
  certificateAuthority: string;
  certificateName: string;
  certificateNumber?: string;
  completionDate: Date[];
  expiredDate?: Date[];
  note?: string;
  status: string;
  attachmentFiles: Array<any>;
  id: string;
}
export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useMyCertificatesStore();
    const instance = getCurrentInstance() as any;
    const fileInput = ref<any>(null);
    const filesInput = ref([]);
    const certificate = ref<Certificate>({
      certificateName: '',
      certificateAuthority: '',
      certificateNumber: '',
      completionDate: [new Date()],
      expiredDate: [],
      note: '',
      status: 'active',
      attachmentFiles: [],
      id: '',
    });
    const attachments = ref<any>([]);
    const attachmentsRemove = ref<any>([]);
    const popupOpened = ref(false);
    const statusList = ref([
      { value: 'active', text: 'Active' },
      { value: 'inactive', text: 'Inactive' },
    ]);
    const userId = ref(auth.currentUser?.uid);
    /**
     * Open the popup
     */
    const dateToValue = (val: any) => {
      if (!val) return '';
      return val._seconds * 1000 + val._nanoseconds / 1000000;
    };
    const open = async (item: Certificate, uid: string) => {
      popupOpened.value = true;
      if (!_.isEmpty(item)) {
        if (uid) {
          userId.value = uid;
        }
        certificate.value = {
          ...item,
          completionDate: item.completionDate
            ? [new Date(dateToValue(item.completionDate))]
            : [],
          expiredDate:
            item.expiredDate?.length === 0
              ? []
              : [new Date(dateToValue(item.expiredDate))],
        };
      }
    };
    const openFileChooser = () => {
      fileInput.value.click();
    };
    /**
     * Handle popup opened
     */
    const handlePopupOpened = () => {};

    /**
     * Handle popup closed
     */
    const handlePopupClosed = () => {
      popupOpened.value = false;
      certificate.value = {
        certificateName: '',
        certificateAuthority: '',
        certificateNumber: '',
        completionDate: [new Date()],
        expiredDate: [],
        note: '',
        status: 'active',
        attachmentFiles: [],
        id: '',
      };

      // Clear the file inputs
      filesInput.value = [];
      attachments.value = [];
      if (attachmentsRemove.value.length > 0) {
        emit('closed');
      }
    };

    const onChangeCertificate = (prop: keyof Certificate, value: any) => {
      certificate.value[prop] = value;

      if (
        prop === 'completionDate' &&
        certificate.value.expiredDate &&
        new Date(value) > new Date(certificate.value.expiredDate.toString())
      ) {
        certificate.value.expiredDate = [];
      }
    };

    const onUploadFile = () => {
      const uploadFiles = fileInput.value.files;
      filesInput.value = Array.from(uploadFiles);
      for (const file of uploadFiles) {
        instance.proxy.$f7.preloader.show();
        const attachment = {
          fileName: file.name,
          fullPath: `my-certificates/${file.name}`,
          url: '',
          attachmentType: 'attachment',
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser?.displayName || auth.currentUser?.email,
        };
        certificate.value.certificateName = file.name.split('.')[0];
        uploadFile(attachment.fullPath, file).then(url => {
          attachment.url = url;
          certificate.value.attachmentFiles.push(attachment);
          instance.proxy.$f7.preloader.hide();
        });
      }
    };
    const deleteFileWithConfirm = (index: number) => {
      instance.proxy.$ri.dialog.openInfoDialog({
        title: 'Confirm delete!',
        content: 'Are you sure you want to delete this item?',
        textButton: 'Delete',
        onClick: (_sefl: any, indexx: number) => {
          if (indexx === 0) {
            _sefl.app.dialog.close();
          } else if (indexx === 1) {
            const file = certificate.value.attachmentFiles[index];
            attachmentsRemove.value.push(file);
            certificate.value.attachmentFiles.splice(index, 1);
          }
        },
      });
    };
    const deleteFile = (index: number) => {
      instance.proxy.$f7.preloader.show();
      const file = attachmentsRemove.value[index];
      removeFile(file.fullPath).then(() => {
        attachmentsRemove.value.splice(index, 1);
        instance.proxy.$f7.preloader.hide();
      });
    };
    const validation = reactive({
      certificateName: '',
      certificateAuthority: '',
      completionDate: '',
    });
    const validate = () => {
      let isValid = true;
      if (!certificate.value.certificateName) {
        validation.certificateName = 'Certificate Name is required';
        isValid = false;
      } else {
        validation.certificateName = '';
      }
      if (!certificate.value.certificateAuthority) {
        validation.certificateAuthority = 'Certificate Authority is required';
        isValid = false;
      } else {
        validation.certificateAuthority = '';
      }
      if (
        !certificate.value.completionDate ||
        certificate.value.completionDate.length === 0
      ) {
        validation.completionDate = 'Completion Date is required';
        isValid = false;
      } else {
        validation.completionDate = '';
      }
      return isValid;
    };
    /**
     * Emit done event with files and descriptions
     */
    const onDone = async () => {
      if (!validate()) return;
      instance.proxy.$f7.preloader.show();
      const filters = `userId:${auth.currentUser?.uid}`;

      const dataCreate = {
        ...certificate.value,
        userId: auth.currentUser?.uid,
        completionDate: firebase.firestore.Timestamp.fromDate(
          certificate.value.completionDate[0]
        ),
        expiredDate: certificate.value?.expiredDate?.length
          ? firebase.firestore.Timestamp.fromDate(
              certificate.value.expiredDate[0]
            )
          : [],
      };

      await store.createNewMyCertificate(dataCreate, filters);
      instance.proxy.$f7.preloader.hide();
      popupOpened.value = false;
    };
    const onSave = async () => {
      if (!validate()) return;
      const filters = `userId:${userId.value}`;
      instance.proxy.$f7.preloader.show();
      const dataUpdate = {
        certificateName: certificate.value.certificateName,
        certificateAuthority: certificate.value.certificateAuthority,
        certificateNumber: certificate.value.certificateNumber,
        note: certificate.value.note,
        completionDate: firebase.firestore.Timestamp.fromDate(
          certificate.value.completionDate[0]
        ),
        expiredDate: certificate.value?.expiredDate?.length
          ? firebase.firestore.Timestamp.fromDate(
              certificate.value.expiredDate[0]
            )
          : [],
        attachmentFiles: certificate.value.attachmentFiles,
        status: certificate.value.status,
      };
      if (attachmentsRemove.value.length > 0) {
        for (const file of attachmentsRemove.value) {
          const removeIndex = attachmentsRemove.value.findIndex(
            (item: any) => item === file
          );
          deleteFile(removeIndex);
        }
      }
      await store.updateMyCertificates(
        certificate.value.id,
        dataUpdate,
        filters
      );
      instance.proxy.$f7.preloader.hide();
      popupOpened.value = false;
    };
    onMounted(() => {
      fileInput.value = instance.proxy.$refs.fileInput;
    });
    return {
      popupOpened,
      instance,
      open,
      handlePopupOpened,
      handlePopupClosed,
      onDone,
      statusList,
      onChangeCertificate,
      openFileChooser,
      onUploadFile,
      attachments,
      certificate,
      deleteFileWithConfirm,
      onSave,
      validation,
      dateToValue,
    };
  },
});
</script>

<style scoped lang="scss">
.list ::v-deep ul::before,
.list ::v-deep ul::after {
  display: none;
}

.list ::v-deep ul {
  border-radius: 4px;
  margin: 0px 10px 0px 10px;
}
.list ::v-deep .item-label {
  font-size: 14px;
  font-weight: bold;
  max-width: 95%;
}
.item-label ::v-deep .icon {
  right: -80%;
}
i.icon-clear {
  margin-right: 2%;
  position: absolute;
  // top: calc(100vh - 885px);
  top: calc(10px);
  right: 0%;
}
.error-message {
  background-color: var(--f7-theme-color);
  padding: 8px 16px;
  color: white;
  display: flex;
  align-items: center;
}
</style>
