const roundUpNumber = numberOfFixedRounds => value => {
  value = Math.round(value * 1000) / 1000;
  const denominator = Math.pow(10, numberOfFixedRounds);
  const temp = parseFloat((value * denominator).toFixed(10)); //Remove zero after device
  return Math.ceil(temp) / denominator;
};

export default {
  estimateList: state =>
    state.estimateList?.map(estimate => {
      const sumNetSales = estimate.buildings?.reduce(
        (acc, building) =>
          acc +
          (building.netSales || 0) +
          ((building.netSales || 0) * (building.saleTax || 0)) / 100, // netSales is included total of buildings
        0
      );

      return {
        ...estimate,
        amount: roundUpNumber(2)(sumNetSales),
      };
    }),

  estimate: state => state.estimate,

  estimateByNumber: (state, getters) => estimateNumber => {
    return (
      getters.estimateList.find(r => r.estimateNumber === estimateNumber) || {}
    );
  },

  estimateById: (state, getters) => estimateId => {
    return getters.estimateList.find(r => r.id === estimateId) || {};
  },

  // Fulltext search
  order: state => state.order,
  searchText: state => state.searchText,

  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
};
