import * as types from './types';
import {
  FirebaseActions,
  StorageActions,
} from '../../../../services/firebase.service';
import { downloadFolder } from '../../../../services/jszip.service';

export default tenant => {
  const projectPhotoActions = new FirebaseActions(
    `/system_client/${tenant}/project_photo`,
    'projectPhoto'
  );

  return {
    bindProjectPhotoListBy: projectPhotoActions.bindCollectionBy,
    unbindProjectPhotoList: projectPhotoActions.unbindCollection,
    getProjectPhotoListBys: projectPhotoActions.getDocumentBys,

    createProjectPhoto: projectPhotoActions.createDocument,
    updateProjectPhoto: projectPhotoActions.updateDocument,
    deleteProjectPhoto: projectPhotoActions.deleteDocument,
    bindPhoto: projectPhotoActions.bindDocument,
    // eslint-disable-next-line no-unused-vars
    async uploadPhoto({ commit }, { file, projectId }) {
      const name = +new Date() + file.name;
      const fullPath = `project/photos/${projectId}/${name}`;
      return StorageActions.uploadFile(fullPath, file);
    },
    // // eslint-disable-next-line no-unused-vars
    // async uploadPhoto({ commit }, fileData) {
    //   const base64Data = fileData.dataUrl;
    //   const meta = fileData.info;
    //   const name = +new Date() + meta.name || "";
    //   const fullPath = `project/photos/${name}`;
    //   return StorageActions.uploadFileBase64(fullPath, base64Data, meta);
    // },
    // eslint-disable-next-line no-unused-vars
    downloadPhoto({ commit }, { fullPath, name, photoUrl = '' }) {
      return StorageActions.downloadFile({ fullPath, name, photoUrl });
    },
    // eslint-disable-next-line no-unused-vars
    uploadBase64Photo({ commit }, { base64Data, fullPath }) {
      return StorageActions.uploadFileBase64(fullPath, base64Data, {});
    },
    async downloadAlbum({ dispatch }, { albumId, albumName }) {
      const photosByAlbum = await dispatch('getProjectPhotoListBys', [
        {
          prop: 'albumId',
          val: albumId,
          op: '==',
        },
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);
      return downloadFolder(
        albumName,
        photosByAlbum.map(r => ({
          name: r.photoName,
          fullPath: r.photoFullPath,
          photoUrl: r.photoUrl,
        }))
      );
    },
    // eslint-disable-next-line no-unused-vars
    async removePhoto({ commit }, fullPath) {
      return StorageActions.removeFile(fullPath);
    },
    resetPhotoData({ commit }) {
      commit(types.RESET_SELECT_STATE);
    },
    changeSelectionMode({ commit }, mode) {
      commit(types.CHANGE_SELECT_MODE, mode);
    },
    cancelSelectionMode({ commit }) {
      commit(types.RESET_SELECT_STATE);
    },
    onSelect({ commit }, id) {
      commit(types.UPDATE_SELECTED_IDS, id);
    },
  };
};
