import * as types from './types';
import Vue from 'vue';
export default {
  [types.SET_PRODUCT_LIST](state, payload) {
    Vue.set(state, 'productList', payload);
  },

  resetProductList(state) {
    state.productList = [];
  },

  [types.SET_PRODUCT_BY_PRICELIST_REFS_LIST](state, payload) {
    Vue.set(state, 'productsByPriceListRefsList', payload);
  },

  [types.SET_SEARCH_RESULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(state, 'hits', hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.ADD_MORE_RESULT](state, { hits, hitsPerPage, nbHits, nbPages, page }) {
    state.hits.push(...hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.SET_SEARCH_TEXT](state, searchText) {
    Vue.set(state, 'searchText', searchText);
  },

  [types.SET_NUMBER_OF_ROW](state, numberOfRow) {
    Vue.set(state, 'numberOfRow', numberOfRow);
  },
};
