import _ from 'lodash';
import moment from 'moment';

export default {
  priceListItemList: state =>
    state.priceListItemList
      .filter(item => {
        const compareDate = moment();
        const startDate =
          item && item.startDate && moment(item.startDate.toDate());
        const isStatusValid =
          !item.status.includes('pl-inactive') &&
          !item.status.includes('pl-incomplete');
        if (item && item.endDate) {
          const endDate = moment(item.endDate.toDate());
          return (
            isStatusValid &&
            compareDate.isBetween(startDate, endDate, 'days', true)
          );
        } else {
          return isStatusValid && compareDate.isSameOrAfter(startDate, 'days');
        }
      })
      .sort((a, b) => a.createdAt - b.createdAt),

  priceListItem: state => id =>
    state.priceListItemList.find(item => item.id === id),

  productItems: (state, getters, rootState, rootGetters) => priceListId => {
    // console.log("productItems", priceListId); subCategoryList
    const subCategoryById =
      rootGetters['dashboard/sub-category/subCategoryById'];
    const categoryById = rootGetters['dashboard/category/categoryById'];
    const result = [];
    if (
      !_.isEmpty(priceListId) &&
      !_.isEmpty(getters.priceListItem(priceListId))
    ) {
      getters.priceListItem(priceListId).productRefs.forEach(ref => {
        const product = rootGetters['dashboard/product-item/objectList'].find(
          item => item.id === ref.productId
        );
        if (product) {
          const subCategory = subCategoryById(product.subCategoryId);
          const category = categoryById(product.categoryId);
          result.push({
            ...product,
            price: ref.price,
            categoryName: category.name || '',
            subCategoryName: subCategory.name || '',
          });
        }
      });
    }

    return result;
  },

  priceList: state => state.priceListItem,
};
