import {
  FirebaseActions,
  firebase,
} from '../../../../services/firebase.service';
import * as types from './types';
import _ from 'lodash';
import moment from 'moment';
import dashboardService from '../../../../services/dashboard.service';
import algoliaService from '../../../../services/algolia.service';
import schedulingService from '../../../../services/employee-scheduling.service';
import { BUSINESS_CODE_SERVICE } from '../../../../utility/const';

export default tenant => {
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    'project'
  );

  const boardActions = new FirebaseActions(
    `/system_client/${tenant}/swimlane_board`,
    'board'
  );

  const actionActions = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    'action'
  );

  return {
    getProjectBys: projectActions.getDocumentBys,
    updateProject: projectActions.updateDocument,

    getProjectById: projectActions.getDocument,
    getBoardById: boardActions.getDocument,
    getActionById: actionActions.getDocument,

    getCardInColumn({ dispatch }, columnId) {
      return dispatch('getProjectBys', [
        {
          prop: 'columnId',
          op: '==',
          val: columnId,
        },
      ]);
    },

    async createProject({ rootGetters }, project) {
      const user = rootGetters['setting/app/profile/user'];
      const doc = await dashboardService.addCardDoc(user.tenantId, project);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: 'operation_card',
        id: doc.id,
      });
      return doc;
    },

    getProject({ dispatch, commit }, id) {
      return dispatch('getProjectById', id).then(res => {
        return commit(types.SET_PROJECT, res);
      });
    },

    resetProject({ commit }) {
      return commit(types.SET_PROJECT, {});
    },

    updateAssigneeIdsProject({ dispatch }, array) {
      if (array.length > 0) {
        array.forEach(r => {
          dispatch('updateProject', {
            id: r.projectId,
            doc: { assigneeIDs: r.assigneeIDs },
          });
        });
      }
    },

    updateAdditionalAssigneeIdsProjectAlgolia(context, array) {
      if (array.length > 0) {
        array.forEach(r => {
          schedulingService.updateAdditionalAssigneeIDsForProjectAlgolia({
            tenantId: tenant,
            projectId: r.projectId,
            userIds: r.userIds,
            action: r.action,
          });
        });
      }
    },

    // update assigneeIds when add or copy schedule
    async handleUpdateAssigneeIdsWhenAddOrCopy(
      { dispatch },
      { newSchedule, userIds }
    ) {
      const { projectId } = newSchedule;
      if (!projectId) return;
      let array = [];
      const newProject =
        (projectId && (await dispatch('getProjectById', projectId))) || {};
      let newAssigneeIds = newProject.assigneeIDs || [];
      let assigneeIds = _.differenceWith(userIds, newAssigneeIds, _.isEqual);

      if (assigneeIds.length > 0) {
        newAssigneeIds = newAssigneeIds.concat(assigneeIds);
        array.push({
          projectId: projectId,
          assigneeIDs: newAssigneeIds,
        });
      }

      if (newProject.businessCode === 'service') {
        return dispatch('updateAssigneeIdsProject', array);
      } else {
        return dispatch('updateAdditionalAssigneeIdsProjectAlgolia', [
          { projectId, userIds, action: 'create' },
        ]);
      }
    },

    // update assigneeIds when delete schedule
    async handleUpdateAssigneeIdsWhenDelete(
      { dispatch, rootGetters },
      oldSchedule
    ) {
      let oldProjectIds = oldSchedule.projectId ? [oldSchedule.projectId] : [];
      let oldUserIds = [oldSchedule.userId];

      if (oldSchedule.scheduleChainId) {
        oldUserIds = _.cloneDeep(
          rootGetters['scheduling/scheduling/userIdsByChainIdAndDate']
        );
        oldProjectIds = _.cloneDeep(
          rootGetters['scheduling/scheduling/projectIdsByChainIdAndDate']
        );
      }
      if (_.isEmpty(oldProjectIds)) return;

      let array = [];
      let projectAdditionalAssigneeIds = [];

      if (!_.isEmpty(oldProjectIds)) {
        const getProjectPromises = [];
        for (const projectId of oldProjectIds) {
          getProjectPromises.push(dispatch('getProjectById', projectId));
        }
        const res = await Promise.all(getProjectPromises);
        const oldProjects = [].concat.apply([], res);

        for (const project of oldProjects) {
          let currentAssigneeIds = _.cloneDeep(project.assigneeIDs) || [];
          let additionalAssigneeIdsRemoved = [];
          for (const userId of oldUserIds) {
            const list = await dispatch(
              'scheduling/scheduling/getSchedulesByUserAndProject',
              {
                userId: userId,
                projectId: project.id,
              },
              { root: true }
            );
            if (list.length === 0) {
              currentAssigneeIds = (currentAssigneeIds || []).filter(
                r => r !== userId
              );
              additionalAssigneeIdsRemoved.push(userId);
            }
          }
          if (project.businessCode === BUSINESS_CODE_SERVICE) {
            array.push({
              projectId: project.id,
              assigneeIDs: currentAssigneeIds,
            });
          } else if (
            !_.isEmpty(additionalAssigneeIdsRemoved) &&
            project.businessCode !== BUSINESS_CODE_SERVICE
          ) {
            !_.isEmpty(additionalAssigneeIdsRemoved) &&
              projectAdditionalAssigneeIds.push({
                projectId: project.id,
                userIds: additionalAssigneeIdsRemoved,
                action: 'delete',
              });
          }
        }
      }
      const promises = [];
      if (array.length > 0) {
        promises.push(dispatch('updateAssigneeIdsProject', array));
      }
      if (projectAdditionalAssigneeIds.length > 0) {
        promises.push(
          dispatch(
            'updateAdditionalAssigneeIdsProjectAlgolia',
            projectAdditionalAssigneeIds
          )
        );
      }
      return Promise.all(promises);
    },

    async updateDueDate({ dispatch }, { projectId, endDate }) {
      // endDate is moment
      const project =
        (projectId && (await dispatch('getProjectById', projectId))) || {};
      if (!_.isEmpty(project) && project.dueDate) {
        const dueDate = moment(project.dueDate.toDate()).format('YYYY-MM-DD');
        const isValidEndDate = moment(dueDate).isBefore(
          endDate.format('YYYY-MM-DD'),
          'day'
        );

        if (isValidEndDate) {
          return dispatch('updateProject', {
            id: projectId,
            doc: {
              dueDate: firebase.firestore.Timestamp.fromDate(new Date(endDate)),
            },
          });
        }
      }
    },
  };
};
