<template>
  <div>
    <div
      v-if="projectPhotoAlbumList.length === 0 && !loading"
      class="camera"
    >
      <div class="bg-no-data">
        <f7-icon
          f7="photo_on_rectangle"
          class="icon-no-data"
        ></f7-icon>
      </div>
      <p>No album to load</p>
    </div>
    <div v-if="projectPhotoAlbumList.length > 0 && !loading">
      <div class="margin-top margin-left">
        Selected {{ selectedIds.length }} items
      </div>
      <f7-block>
        <div class="img-grid">
          <div
            v-for="album in projectPhotoAlbumList"
            :key="album.id"
            @touchstart="handlePressAndHold(album)"
            @touchend="handleTouchEnd"
            @mouseenter="handleMouseEnter(album)"
            @mouseleave="handleSelected(null)"
          >
            <div
              :style="
                !album.photoLasted.thumbnailUrl &&
                'background: var(--f7-color-img-neutral)'
              "
              class="img-item"
              style="border-radius: 4px"
              @click.stop="clickAlbum(album, true)"
            >
              <img
                v-if="album.photoLasted.thumbnailUrl"
                class="image-show"
                :src="album.photoLasted.thumbnailUrl"
              />
              <f7-icon
                v-if="!album.photoLasted.thumbnailUrl"
                f7="photo"
                class="no-img"
              ></f7-icon>
              <div
                v-if="
                  $device.desktop &&
                  selectionMode &&
                  hoveredIds.includes(album.id)
                "
                class="img-bg img-bg-select"
              >
                <div class="img-bg-content">
                  <f7-checkbox
                    style="
                      position: absolute;
                      top: 10%;
                      left: 10%;
                      transform: scale(1.5);
                    "
                    :checked="selectedIds.includes(album.id)"
                    @click.native.stop
                    @change.native.stop="clickAlbum(album)"
                  ></f7-checkbox>
                </div>
              </div>
              <div
                v-if="!$device.desktop && selectionMode"
                class="img-bg img-bg-select"
              >
                <div class="img-bg-content">
                  <f7-checkbox
                    style="
                      position: absolute;
                      top: 10%;
                      left: 10%;
                      transform: scale(1.2);
                    "
                    :checked="selectedIds.includes(album.id)"
                    @click.native.stop
                    @change.native.stop="clickAlbum(album)"
                  ></f7-checkbox>
                </div>
              </div>
            </div>
            <div
              style="font-size: 12px"
              class="album-name"
            >
              {{ album.name }}
            </div>
            <div
              style="font-size: 10px"
              color="gray"
            >
              Photos: <b>{{ album.photoTotals }}</b>
            </div>
          </div>
        </div>
      </f7-block>
      <template v-if="selectedIds.length > 0">
        <f7-fab
          :class="`tab-btn tab-btn_1 download-tooltip-${uuid}`"
          position="right-bottom"
          slot="fixed"
          color="default"
          @click="handleDownload"
        >
          <f7-icon f7="cloud_download"></f7-icon>
        </f7-fab>
      </template>
    </div>
    <project-photo-popup ref="projectPhotoPopup"></project-photo-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProjectPhotoPopup from './ProjectPhotoPopup.vue';
import { uuid } from 'vue-uuid';

export default {
  components: {
    ProjectPhotoPopup,
  },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      hoveredIds: [],
      selectedIds: [],
      selectionMode: false,
      pressHoldEvent: null,
      uuid: uuid.v4(),
    };
  },
  mounted() {
    this.createTooltip();
  },
  computed: {
    ...mapGetters('swimlane/album', ['projectPhotoAlbumList']),
  },
  methods: {
    ...mapActions('swimlane/photo', ['downloadAlbum']),
    clickAlbum(album, isShowAlbum = false) {
      if (isShowAlbum) {
        this.$refs.projectPhotoPopup.openPopup(album.id);
        this.selectedIds = [];
      } else {
        this.onSelect(album.id);
      }
    },
    handleMouseEnter(album) {
      if (this.$device.desktop) {
        this.handleSelected(album);
      }
    },
    handlePressAndHold(album) {
      if (!this.$device.desktop) {
        this.pressHoldEvent = setTimeout(() => {
          this.handleSelected(album, true);
        }, 1000);
      }
    },
    handleTouchEnd() {
      clearTimeout(this.pressHoldEvent);
      this.pressHoldEvent = null;
    },
    handleSelected(album, isSelectPhoto = false) {
      this.createTooltip();
      if (this.selectedIds.length === 0 && album === null) {
        this.selectionMode = false;
        this.hoveredIds = [];
      } else if (album === null) {
        const lastIndex = this.hoveredIds.length - 1;
        if (
          lastIndex >= 0 &&
          this.hoveredIds[lastIndex] !== null &&
          !this.selectedIds.includes(this.hoveredIds[lastIndex])
        ) {
          this.hoveredIds.splice(lastIndex, 1);
        }
      } else if (album.id && !this.hoveredIds.includes(album.id)) {
        this.selectionMode = true;
        this.hoveredIds.push(album.id);
        if (isSelectPhoto) {
          this.clickAlbum(album);
        }
      }
    },
    onSelect(id) {
      if (this.selectedIds.includes(id)) {
        var index = this.selectedIds.indexOf(id);
        if (index > -1) {
          this.selectedIds.splice(index, 1);
        }
      } else {
        this.selectedIds.push(id);
      }
    },
    async handleDownload() {
      this.$f7.toast
        .create({
          text: 'Downloading albums...',
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 3000,
        })
        .open();
      const albumsSelected = this.projectPhotoAlbumList.filter(r =>
        this.selectedIds.includes(r.id)
      );
      albumsSelected.forEach(album => {
        this.downloadAlbum({ albumId: album.id, albumName: album.name });
      });
      this.cancelSelect();
    },
    cancelSelect() {
      this.selectionMode = false;
      this.selectedIds = [];
      this.hoveredIds = [];
      this.$nextTick(() => {
        this.createTooltip();
      });
    },
    createTooltip() {
      if (!this.$device.desktop) return;

      this.$f7.tooltip.create({
        targetEl: `.download-tooltip-${this.uuid}-album`,
        cssClass: 'tooltip-fab-button-photo',
        text: 'Download album',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bg-no-data {
  width: 150px;
  height: 150px;
  background: var(--f7-color-img-neutral);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-no-data {
  font-size: 100px;
  color: var(--f7-color-icon-neutral);
}
.camera {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
}
.img-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  cursor: pointer;
  background-position: 50% center;
  background: white;
}
.no-img {
  position: absolute;
  font-size: 35px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--f7-color-icon-neutral);
}
.img-bg {
  position: absolute;
  border-radius: 4px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.img-bg-select {
  transition: all 150ms ease-in-out 0s;
  background: rgba(0, 0, 0, 0.3);
}
.img-item:hover .img-bg-not-select {
  background: rgba(0, 0, 0, 0.2);
}
.img-bg-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 1;
}
.image-show {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  object-fit: contain;
}
.album-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.checkbox ::v-deep i {
  border-color: white;
}
.tab-btn {
  right: var(--global-search-btn-right);
  &_1 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        (var(--f7-fab-size) - 60px) * 2
    );
  }
}
</style>
