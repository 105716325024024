/* eslint-disable no-unused-vars */
import {
  CODE_ROOF_TYPE,
  CODE_STATUS_OPTION,
  ENTITY_PURCHASE_ORDER,
  ENTITY_ESTIMATE,
} from '../../../../utility/const';

export default {
  roofTypeList: state =>
    state.typeList.filter(item => item.code === CODE_ROOF_TYPE),

  roofTypeBy: (state, getters, rootState, rootGetters) => value =>
    getters.roofTypeList.find(item => item.value === value) || {},

  purchaseOrderStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION &&
        item.entity === ENTITY_PURCHASE_ORDER
    ),

  purchaseOrderStatusBy: (state, getters, rootState, rootGetters) => value =>
    getters.purchaseOrderStatusList.find(item => item.value === value) || {},

  estimateStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION && item.entity === ENTITY_ESTIMATE
    ),

  estimateStatusBy: (state, getters, rootState, rootGetters) => value =>
    getters.estimateStatusList.find(item => item.value === value) || {},
};
