<template>
  <div>
    <f7-list>
      <!-- Add item -->
      <f7-list-item
        v-show="!(schedule && schedule.title)"
        @click.native="create"
      >
        <f7-icon
          class="cursor-pointer add-button-icon"
          slot="media"
          f7="plus_circle"
        ></f7-icon>
        <div
          class="cursor-pointer"
          slot="title"
        >
          Create Schedule of Value
        </div>
      </f7-list-item>
      <!-- ---------------------------------- -->
      <f7-list-input
        v-show="schedule && schedule.completedDate"
        type="text"
        label="Schedule of Value Completed Date"
        readonly
        :value="toDisplayDateString(schedule ? schedule.completedDate : [])"
        ><input-icon
          slot="media"
          icon="calendar"
        ></input-icon
      ></f7-list-input>
      <!-- ---------------------------------- -->
      <f7-list-item
        class="cursor-pointer"
        text-color="primary"
        v-show="schedule && schedule.title"
        @click.native="
          gotoSchedule({ id: schedule ? schedule.id : '', isNew: false })
        "
      >
        <div slot="title">
          {{ schedule ? schedule.title : '' }}
        </div>
        <div slot="footer">
          Created by
          {{ schedule ? schedule.createdBy : '' }}
        </div>
      </f7-list-item>

      <f7-list-item
        text-color="red"
        v-show="schedule && schedule.title"
        @click.native="del"
      >
        <f7-icon
          class="cursor-pointer"
          slot="media"
          f7="minus_circle_fill"
          color="red"
        ></f7-icon>
        <div
          class="cursor-pointer"
          slot="title"
        >
          Delete Schedule of Value
        </div>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import InputIcon from '../icon/InputIcon.vue';

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString,
} from '../../../../utility/datetime';

import { firebase } from '../../../../services/firebase.service';
import { mapActions, mapState, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  components: { InputIcon },

  props: {
    value: { type: String, default: '' },
  },

  computed: {
    ...mapState('dashboard/project', ['card']),
    ...mapState('dashboard/schedule', ['schedule']),

    ...mapGetters('dashboard/schedule-setting', ['scheduleSettingList']),

    defaultColumns() {
      return (
        this.scheduleSettingList.filter(r => r.createdBy === 'RooferIntel') ||
        []
      );
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.unbindSchedule();
        if (!_.isEmpty(val)) {
          this.bindSchedule(val);
        }
      },
    },
  },

  methods: {
    ...mapActions('dashboard/schedule', [
      'bindSchedule',
      'unbindSchedule',
      'createSchedule',
      'deleteSchedule',
    ]),

    ...mapActions('dashboard/schedule-setting', ['bindScheduleSettingList']),

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    gotoSchedule(id) {
      this.$emit('gotoDetails', id);
    },

    initScheduleInfo() {
      let percentage = {
        id: new Date().getTime() + 1,
        name: 'Percentage',
        isInputPercent: true,
      };
      this.defaultColumns.forEach(r => {
        percentage[r.id + 'material'] = 70;
        percentage[r.id + 'labor'] = 30;
      });
      let scheduleInfomation = [
        {
          id: new Date().getTime(),
          name: 'Project Cost by Roof Type',
        },
        percentage,
        {
          id: 1,
          name: 'Building 1',
        },
        {
          id: 2,
          name: 'Building 2',
        },
      ];
      return scheduleInfomation;
    },

    async create() {
      this.$f7.preloader.show();

      if (_.isEmpty(this.scheduleSettingList)) {
        await this.bindScheduleSettingList();
      }

      const projectName = this.card.title || '';
      this.createSchedule({
        title: `${projectName} - Schedule of Value`,
        completedDate: firebase.firestore.Timestamp.now(),

        projectId: this.card.id,
        scheduleColunms: this.defaultColumns.map(r => r.id) || [],
        scheduleInfomation: this.initScheduleInfo(),
      })
        .then(id => {
          this.$emit('input', id);
          this.gotoSchedule({ id: id, isNew: true });
          this.$f7.preloader.hide();
        })
        .catch(() => {
          this.$f7.preloader.hide();
        });
    },

    del() {
      this.$ri.dialog.openWarningDialog({
        title: 'Delete Schedule of Value.',
        content: 'Do you want to delete this Schedule of Value?',
        textButton: 'Delete',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$f7.preloader.show();
            this.deleteSchedule(this.value)
              .then(() => {
                this.$emit('input', '');
                this.$f7.preloader.hide();
              })
              .catch(() => {
                this.$f7.preloader.hide();
              });
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
