<template>
  <img
    src="@/assets/img/menu.svg"
    alt=""
  />
</template>

<script>
export default {};
</script>

<style></style>
