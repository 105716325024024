<template>
  <f7-popup
    :opened="isOpenSelectPopup"
    @popup:closed="
      isOpenSelectPopup = false;
      $emit('changed', selectedIds);
    "
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left> </f7-nav-left>
        <f7-nav-title>{{ title ? title : 'Select options' }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="done()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <data-table
        :pageSize="optionList.length"
        :headers="headers"
        :checkbox="true"
        :items="optionList"
        @selected:change="select"
        class="wrap-text-table"
      >
        <template slot="card-header">
          <f7-list>
            <f7-searchbar
              :placeholder="
                searchPlaceholder ? searchPlaceholder : 'Search in items'
              "
              :clear-button="true"
              disable-button-text
              :value="searchValue"
              @input="searchValue = $event.target.value"
              @searchbar:disable="searchValue = ''"
              class="search-list-popup"
            >
            </f7-searchbar>
          </f7-list>
        </template>
        <template v-slot:body="{ item }">
          <td>
            {{ item.value }}
          </td>
        </template>
      </data-table>
    </f7-page>
  </f7-popup>
</template>

<script>
import DataTable from '@/components/datatables';
import { applyFilter } from '@/utility/filter-tools';
import _ from 'lodash';

export default {
  components: {
    DataTable,
  },
  props: {
    searchPlaceholder: String,
    title: String,
    label: String,
    value: Array,
    options: Array,
  },
  watch: {
    // value(val) {
    //   this.selectedIds = _.cloneDeep(val);;
    // }
  },
  data() {
    return {
      selectedIds: [],
      isOpenSelectPopup: false,
      searchValue: '',
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'Select All',
          align: 'left',
          value: 'value',
          width: '95%',
        },
      ];
    },
    optionList() {
      return applyFilter(this.options, this.searchValue, ['value']).map(r => ({
        ...r,
        checked: (this.selectedIds || []).includes(r.id),
      }));
    },
  },
  methods: {
    openSelectPopup() {
      this.isOpenSelectPopup = true;
      this.selectedIds = _.cloneDeep(this.value);
    },
    closeSelectPopup() {
      this.isOpenSelectPopup = false;
    },
    done() {
      this.$emit('changed', this.selectedIds);
      this.closeSelectPopup();
    },
    select(value) {
      if (value.checked && !(this.selectedIds || []).includes(value.id)) {
        this.selectedIds.push(value.id);
      } else if (value.checked == false) {
        this.selectedIds = (this.selectedIds || []).filter(
          id => id != value.id
        );
      }
    },
    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list-popup.searchbar input').focus();
      }
    },
  },
};
</script>

<style></style>
