<template>
  <div class="title-input">
    <div class="title-input-icon">
      <slot name="icon">
        <f7-icon f7="envelope"></f7-icon>
      </slot>
    </div>
    <div class="title-input-content">
      <div class="title-input-lable">
        {{ this.title }}
      </div>
      <div class="title-input-wrap">
        <multiselect
          :placeholder="placeholder"
          v-model="titleValue"
          :internal-search="false"
          :multiple="true"
          :options="options"
          :taggable="true"
          :limit="3"
          :limit-text="limitText"
          :custom-label="customLabel"
          track-by="id"
          tag-placeholder="Add this as new tag"
          @input="change"
          @tag="addTag"
          @search-change="searchChange"
        >
          <template slot="afterList">
            <div
              v-observe-visibility="reachedEndOfList"
              class="text-align-center margin-vertical-half"
              v-if="hasMoreItems"
            >
              <f7-preloader></f7-preloader>
            </div>
          </template>
        </multiselect>
        <span class="input-clear-button"></span>
      </div>
      <div class="title-error-message">
        <slot name="error-message"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  props: {
    value: { type: Array, default: () => [] },
    title: { type: String, default: () => '' },
    placeholder: { type: String, default: () => '' },
    options: { type: Array, default: () => [] },
    type: { type: String, default: () => 'email' },
    hasMoreItems: Boolean,
  },
  components: {
    Multiselect,
  },
  data: () => {
    return {
      titleValue: [],
    };
  },
  watch: {
    value(val) {
      this.titleValue = val.length > 0 ? val : [];
    },
  },

  methods: {
    limitText(count) {
      return `and ${count} other ${count > 1 ? this.type + 's' : this.type}`;
    },
    addTag(newTag) {
      let option = {
        title: newTag,
        desc: '',
      };
      this.options && this.options.push(option);
      this.titleValue && this.titleValue.push(option);
      this.$emit('change', this.titleValue);
    },
    change(value) {
      this.$emit('change', value);
    },
    customLabel({ title, desc }) {
      return `${title} ${desc}`;
    },
    searchChange(value) {
      this.$emit('searchChange', {
        value,
        callback: this.scrollOptionsToTop(),
      });
    },
    scrollOptionsToTop() {
      this.$el
        .querySelector('.multiselect .multiselect__content-wrapper')
        .scrollTo({ top: 0 });
    },
    reachedEndOfList(reached) {
      if (reached) {
        this.$emit('loadMore');
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style language="scss">
.title-input {
  display: flex;
  padding: 2px;
  background-color: var(--f7-color-bg-10-neutral);
  border-bottom: 1px solid var(--f7-list-border-color);
}
.title-input .title-input-icon {
  padding: 0.25rem;
  width: 2rem;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  color: var(--f7-theme-color);
}
.title-input .title-input-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.title-input.subject .title-input-content {
  padding-right: 14px;
  box-sizing: border-box;
}
.title-input.subject .title-input-content input {
  padding-top: 8px;
  padding-bottom: 8px;
}
.title-input-content .title-input-lable {
  font-size: 12px;
}
.title-input-content .title-input-wrap {
  width: 100%;
  position: relative;
}
.title-input-content .title-input-wrap input {
  width: 100%;
  border: none;
}
.title-input-content .title-error-message {
  color: red;
  font-size: 12px;
}
.title-input-wrap .multiselect .multiselect__tags {
  border: none;
  background-color: var(--f7-color-bg-10-neutral);
  padding-left: 0px;
}
.title-input-wrap
  .multiselect
  .multiselect__tags
  .multiselect__content-wrapper {
  width: 100%;
}

.title-input-wrap .multiselect .multiselect__placeholder {
  /* background-color: gray; */
  color: gray;
  line-height: 1.4;
}
/* .multiselect__option {
  padding: 12px;
} */
</style>
