<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="() => closePopup()"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Lead Details</f7-nav-title>
      </f7-navbar>

      <contact-info-input
        :detail="contactInfo"
        :isNoteContent="isNoteContent"
      ></contact-info-input>

      <action-button @doAction="onDoAction"></action-button>
    </f7-page>
  </f7-popup>
</template>

<script>
import ContactInfoInput from '../input/ContactInfoInput.vue';
import ActionButton from '../button/ActionButton.vue';

import { mapGetters, mapActions } from 'vuex';
import { firebase, auth } from '../../../../services/firebase.service';

import {
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_SERVICE,
  BUSINESS_CODE_COMMERCIAL,
  COMPANY_TYPE_GENERAL_CONTRACTOR,
  LEAD_SOURCE_ONLINE,
} from '../../../../utility/const';
import moment from 'moment';

export default {
  components: { ContactInfoInput, ActionButton },

  data() {
    return { popupOpened: false, isNoteContent: false };
  },

  computed: {
    ...mapGetters('web-contact/web-contact-info', ['contactInfo']),
    ...mapGetters('setting/app/profile', ['user']),

    contactName() {
      return contact => {
        return contact
          ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim()
          : '';
      };
    },
  },

  methods: {
    ...mapActions('web-contact/web-contact-info', [
      'unbindContactInfo',
      'updateContactInfo',
    ]),

    ...mapActions('web-contact/project', [
      'getActionBys',
      'createProject',
      'getCardInColumn',
      'createCard',
    ]),

    ...mapActions('web-contact/client', [
      'createContact',
      'createCompany',
      'updateContact',
    ]),

    ...mapActions('web-contact/swimlane', [
      'getDefaultServiceBoard',
      'getFirstColumn',
    ]),

    clearData() {},

    closePopup(callback) {
      if (callback) {
        callback();
      }

      this.unbindContactInfo();
      this.clearData();
      this.popupOpened = false;
      this.isNoteContent = true;
    },

    async onDoAction(action) {
      this.$f7.preloader.show();
      const self = this;
      let projectInfo = {};
      let showConfirmDialog = false;
      try {
        switch (action) {
          case 'archive-bad-lead':
            projectInfo = await this.createArchiveCard(action);
            break;
          case 'archive-no-longer-interest':
            projectInfo = await this.createArchiveCard(action);
            break;

          case 'create-commercial-job':
            // projectInfo = await this.createCard({ status: "open" });
            projectInfo = await this.createCommercialCard(
              'open',
              BUSINESS_CODE_COMMERCIAL
            );
            projectInfo.businessCode = BUSINESS_CODE_COMMERCIAL;
            showConfirmDialog = true;
            break;

          case 'create-service-job':
            projectInfo = await this.createServiceCard(
              'open',
              BUSINESS_CODE_SERVICE
            );
            projectInfo.businessCode = BUSINESS_CODE_SERVICE;
            showConfirmDialog = true;
            break;

          case 'create-residential-job':
            projectInfo = await this.createCommercialCard(
              'open',
              BUSINESS_CODE_RESIDENTIAL
            );
            projectInfo.businessCode = BUSINESS_CODE_RESIDENTIAL;
            showConfirmDialog = true;
            break;

          default:
            this.popupOpened = false;
            return;
        }

        this.popupOpened = false;
        await this.updateContactInfo({
          id: this.contactInfo.id,
          doc: { status: 'close' },
        });

        if (showConfirmDialog) {
          // projectInfo.businessCode = this.contactInfo.businessCode;
          const app = this;
          this.$ri.dialog.openSuccessDialog({
            title: 'The job is created successfully',
            content: '',
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                app.$ri.dialog.openInfoDialog({
                  title: 'Navigate to details',
                  content: 'Do you want to navigate to your job details?',
                  textButton: 'Details',
                  onClick: (_sefl, index) => {
                    if (index === 0) {
                      _sefl.app.dialog.close();
                    } else if (index === 1) {
                      self.navigateToBoard(projectInfo);
                    }
                  },
                });
              }
            },
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },

    navigateToBoard({
      businessCode,
      boardNumber,
      actionNumber,
      projectNumber,
    }) {
      switch (businessCode) {
        case BUSINESS_CODE_SERVICE:
          return this.$f7router.navigate(
            `/dashboard/swimlane/service/${boardNumber}/card/${projectNumber}`,
            {
              reloadAll: true,
              pushState: true,
            }
          );

        case BUSINESS_CODE_RESIDENTIAL:
          return this.$f7router.navigate(
            `/dashboard/swimlane/residential/${projectNumber}/action/${actionNumber}`,
            {
              reloadAll: true,
              pushState: true,
            }
          );

        case BUSINESS_CODE_COMMERCIAL:
          return this.$f7router.navigate(
            `/dashboard/swimlane/commercial/sales/project/${projectNumber}/action/${actionNumber}`,
            {
              reloadAll: true,
              pushState: true,
            }
          );
      }
    },

    handlePopupOpened() {
      this.isNoteContent = false;
    },

    open() {
      this.popupOpened = true;
    },

    async createCommercialCard(status, businessCode) {
      const project = {};
      let actions = [];
      if (businessCode === BUSINESS_CODE_COMMERCIAL) {
        actions =
          (await this.getActionBys([{ prop: 'code', op: '==', val: 110 }])) ||
          [];
        project.contactedDate = firebase.firestore.Timestamp.now();
      } else if (businessCode === BUSINESS_CODE_RESIDENTIAL) {
        actions =
          (await this.getActionBys([{ prop: 'code', op: '==', val: 800 }])) ||
          [];
        project.inspectDate = firebase.firestore.Timestamp.now();
        project.inspectTime = moment().format('HH:mm');
      }

      if (!actions[0]) {
        return;
      }

      const action = actions[0];
      const contactId = await this.createContact({
        emails: [
          {
            code: 'main',
            title: 'Main',
            value: this.contactInfo.email,
          },
        ],
        phones: [
          {
            code: 'main',
            title: 'Main',
            value: this.contactInfo.phoneNumber,
          },
        ],
        firstName: this.contactInfo.firstName,
        lastName: this.contactInfo.lastName,
      });
      const companyId = await this.createCompany({
        companyTypes: [COMPANY_TYPE_GENERAL_CONTRACTOR],
        companyName: this.contactInfo.companyName,
      });

      const actionsHistory = [];
      actionsHistory.push({
        nextAction: 'Job Created',
        resolvedAt: firebase.firestore.Timestamp.now(),
        resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
        previousAction: '',
      });

      project.commentAndNote = [
        {
          code: 'note',
          title: 'Client Note',
          htmlContent: this.contactInfo.message,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        },
      ];

      project.actionsHistory = actionsHistory;
      project.status = status;
      project.actions = [action.id];
      project.dueDate = firebase.firestore.Timestamp.now();
      project.leadSource = LEAD_SOURCE_ONLINE;
      project.title = this.contactName(this.contactInfo);
      project.contactId = contactId;
      project.companyId = companyId;
      project.businessCode = businessCode; //this.contactInfo.businessCode;

      const { docNumber } = await this.createProject({
        tenantId: this.user.tenantId,
        project,
      });

      return {
        boardNumber: null,
        actionNumber: action.code,
        projectNumber: docNumber,
      };
    },

    async createServiceCard(status, businessCode) {
      const project = {};
      const defaultServiceBoard =
        await this.getDefaultServiceBoard(businessCode);
      if (!defaultServiceBoard) {
        return;
      }
      const firstColumn = await this.getFirstColumn(defaultServiceBoard.id);

      const cardsInColumn = await this.getCardInColumn(firstColumn.id);
      const minPriorityInColumn =
        Math.min(...[...cardsInColumn.map(r => r.priority), 0]) || 0;

      const actionsHistory = [
        {
          nextAction: 'Job Created',
          previousAction: '',
          resolvedAt: firebase.firestore.Timestamp.now(),
          resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
          resolvedById: auth.currentUser.uid,
        },
      ];

      const contactId = await this.createContact({
        emails: [
          {
            code: 'main',
            title: 'Main',
            value: this.contactInfo.email,
          },
        ],
        phones: [
          {
            code: 'main',
            title: 'Main',
            value: this.contactInfo.phoneNumber,
          },
        ],
        firstName: this.contactInfo.firstName,
        lastName: this.contactInfo.lastName,
      });

      let companyId = '';
      if (this.contactInfo.companyName) {
        companyId = await this.createCompany({
          companyTypes: [COMPANY_TYPE_GENERAL_CONTRACTOR],
          companyName: this.contactInfo.companyName,
          contactRefs: [{ id: contactId, optionalRole: '' }],
        });

        this.updateContact({
          id: contactId,
          doc: { companyRefs: [{ fromDate: '', toDate: '', id: companyId }] },
        });
      }

      project.title = this.contactName(this.contactInfo);
      project.description = this.contactInfo.message;
      project.priority = minPriorityInColumn - 1000;
      project.columnId = firstColumn.id;
      project.boardId = defaultServiceBoard.id;
      project.dueDate = firebase.firestore.Timestamp.now();
      project.contactId = contactId;
      project.companyId = companyId;
      project.status = status;
      project.actionsHistory = actionsHistory;
      project.businessCode = businessCode;
      project.customerType = BUSINESS_CODE_COMMERCIAL;

      const { docNumber } = await this.createProject({
        tenantId: this.user.tenantId,
        project,
      });

      return {
        boardNumber: defaultServiceBoard.boardNumber,
        actionNumber: null,
        projectNumber: docNumber,
      };
    },

    async createArchiveCard(archiveType) {
      const project = {};
      const contactId = await this.createContact({
        emails: [
          {
            code: 'main',
            title: 'Main',
            value: this.contactInfo.email,
          },
        ],
        phones: [
          {
            code: 'main',
            title: 'Main',
            value: this.contactInfo.phoneNumber,
          },
        ],
        firstName: this.contactInfo.firstName,
        lastName: this.contactInfo.lastName,
      });

      let companyId = '';
      if (this.contactInfo.companyName) {
        companyId = await this.createCompany({
          companyTypes: [COMPANY_TYPE_GENERAL_CONTRACTOR],
          companyName: this.contactInfo.companyName,
        });
      }

      project.webContactId = this.contactInfo.id;
      project.status = 'cancel';
      project.archiveDate = firebase.firestore.Timestamp.now();
      project.commentAndNote = [
        {
          code: 'note',
          title: 'Archive Note',
          htmlContent:
            archiveType === 'archive-bad-lead'
              ? '<span>Bad lead</span>'
              : '<span>No longer interest</span>',
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        },
      ];

      project.contactId = contactId;
      project.companyId = companyId;

      const result = await this.createCard(project);

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-bar {
  height: calc(100%);
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}
</style>
