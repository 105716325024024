/* eslint-disable no-unused-vars */
import * as types from './types';
import { FirebaseActions, firebase } from '@/services/firebase.service';
import _ from 'lodash';

export default tenant => {
  const contactActions = new FirebaseActions(
    `/system_client/${tenant}/operation_contact`,
    'contact'
  );

  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    'company'
  );

  const propertyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_property`,
    'property'
  );

  return {
    createContact: contactActions.createDocument,
    getContactById: contactActions.getDocument,

    createCompany: companyActions.createDocument,
    getCompanyById: companyActions.getDocument,

    createProperty: propertyActions.createDocument,
    getPropertyById: propertyActions.getDocument,

    async getContact({ commit, dispatch }, contactId) {
      const contact = await dispatch('getContactById', contactId);
      await commit(types.SET_CONTACT, contact);
      return contact;
    },

    setContact({ commit }, data) {
      return commit(types.SET_CONTACT, data);
    },

    async getCompany({ commit, dispatch }, companyId) {
      const company = await dispatch('getCompanyById', companyId);
      await commit(types.SET_COMPANY, company);
      return company;
    },

    setCompany({ commit }, data) {
      return commit(types.SET_COMPANY, data);
    },

    async getProperty({ commit, dispatch }, propertyId) {
      const property = await dispatch('getPropertyById', propertyId);
      await commit(types.SET_PROPERTY, property);
      return property;
    },

    setProperty({ commit }, data) {
      return commit(types.SET_PROPERTY, data);
    },

    // get contact detail by id
    async getContactDetailById({ dispatch, commit, getters }, id) {
      let contact = getters.contact;
      if (_.isEmpty(contact)) {
        contact = await dispatch('getContactById', id);
      }
      return contact;
    },

    // get property detail by id
    async getPropertyDetailById({ dispatch, commit, getters }, id) {
      let property = getters.property;
      if (_.isEmpty(property)) {
        property = await dispatch('getPropertyById', id);
      }
      return property;
    },

    resetClient({ commit }) {
      commit(types.RESET_DATA);
    },
  };
};
