import profile from './profile';
import system from './system';
import group from './group';
import appConstant from './app-constant';
import menu from './menu';
import _ from 'lodash';

export default tenant => {
  return {
    namespaced: true,
    modules: {
      'profile-page': {
        namespaced: true,
        modules: {
          profile: _.cloneDeep(profile),
          group: _.cloneDeep(group(tenant)),
          'app-constant': _.cloneDeep(appConstant(tenant)),
          menu: _.cloneDeep(menu),
        },
      },

      app: {
        namespaced: true,
        modules: {
          group: _.cloneDeep(group(tenant)),
          profile: _.cloneDeep(profile),
          system: _.cloneDeep(system),
          'app-constant': _.cloneDeep(appConstant(tenant)),
        },
      },
    },
  };
};
