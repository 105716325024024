import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const actionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/product_sub_category`,
    'object'
  );

  return {
    getBys: actionsTemplate.getDocumentBys,

    async getSubCategories({ commit, dispatch }) {
      const list = await dispatch('getBys');
      commit(types.SET_SUB_CATEGORY_LIST, list);
    },

    resetSubCategory({ commit }) {
      commit(types.SET_SUB_CATEGORY_LIST, []);
    },
  };
};
