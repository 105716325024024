<template>
  <div>
    <div class="data-table data-table-init card">
      <!-- Custom header -->
      <slot name="card-header"></slot>
      <!-- Table -->
      <div
        class="card-content"
        :style="`width: ${lengthWidth || '100%'}`"
      >
        <template v-if="$scopedSlots['multipleBody'] && dataTable.length > 0">
          <div
            class="child-table"
            v-for="(section, indexSection) in dataTable"
            :key="indexSection"
          >
            <template>
              <table
                class="data-table"
                v-if="(section.productList || []).length != 0"
              >
                <!-- visible headers, this only help to change width -->
                <thead style="visibility: collapse">
                  <tr>
                    <th
                      v-for="(header, index) in headers"
                      :key="index"
                    ></th>
                  </tr>
                </thead>
                <!-- sub categories header -->
                <thead>
                  <tr>
                    <td
                      :colspan="headers.length"
                      :style="`padding: 5px; background: ${color}; color: ${textColor}`"
                    >
                      <div
                        style="width: 100%; display: block"
                        class="margin-left-half align-items-center section-name"
                      >
                        {{ section.category }}
                      </div>
                    </td>
                  </tr>
                </thead>
                <f7-header-table
                  :headers="headers"
                  :checkbox="checkbox"
                  :indeterminate="indeterminate"
                  :checked="checked"
                  @sortColumn="sortColumn"
                  @selectCheckbox="selectCheckbox($event, dataTable)"
                />
                <tbody>
                  <tr v-if="(section.productList || []).length === 0">
                    <td
                      class="not-data"
                      :colspan="headers.length"
                    >
                      No Data!
                    </td>
                  </tr>
                  <template v-else>
                    <tr
                      v-for="(row, index) in section.productList || []"
                      :key="index"
                    >
                      <slot
                        name="multipleBody"
                        :item="row"
                        :sectionId="section.sectionId"
                      ></slot>
                    </tr>
                  </template>
                  <slot
                    name="footer-multiple"
                    :sectionId="section.sectionId"
                  ></slot>
                </tbody>
              </table>
            </template>
            <!-- <template v-if="$scopedSlots['profit-margin']"></template> -->
          </div>
          <!-- <div class="child-table">
            <table class="data-table">
              visible headers, this only help to change width
              <thead style="visibility: collapse">
                <tr>
                  <th v-for="(header, index) in headers" :key="index"></th>
                </tr>
              </thead>
            </table>
          </div> -->
        </template>

        <!-- No Data -->
        <div
          class="no-data"
          v-if="dataTable.length === 0"
        >
          No Data!
        </div>

        <!-- Footer Table -->
        <slot
          name="footer"
          v-if="$scopedSlots['footer']"
        ></slot>
        <f7-footer-table
          v-else-if="!$scopedSlots['footer']"
          :footerProps="footerProps"
          :totals="totals"
          :pagination="pagination"
          @changeRowPerPage="changeRowPerPage"
          @paginationNextPrev="paginationNextPrev"
        />
      </div>
    </div>
    <div
      class="card"
      style="max-width: 100%; overflow-x: auto"
    >
      <slot
        name="profit-margin"
        :headers="headers"
        :color="color"
        :textColor="textColor"
      ></slot>
    </div>
  </div>
</template>
<script>
import F7FooterTable from '../../../../components/datatables/F7FooterTable.vue';
import F7HeaderTable from '../../../../components/datatables/F7HeaderTable.vue';

export default {
  components: {
    F7HeaderTable,
    F7FooterTable,
  },
  props: {
    headers: Array,
    items: Array,
    checkbox: Boolean,
    iconSort: String,
    footerProps: Object,
    totals: Number,
    pageSize: Number,
    lengthWidth: [String, Number],
    color: String,
    textColor: String,
    isEditing: Boolean,
  },
  data() {
    return {
      checked: false,
      indeterminate: false,
      pagination: {
        descending: false,
        page: 1,
        pageSize: 10,
        sortBy: '',
        totalItems: 0,
      },
      dataTable: [],
      selected: [],
    };
  },
  methods: {
    // Pagination Common
    changePagination(options) {
      this.$emit('pagination', options);
    },

    // Sort
    sortColumn(props) {
      if (props.sortable) {
        this.pagination = {
          ...this.pagination,
          sortBy: props.value,
          descending: !this.pagination.descending,
        };
        this.changePagination(this.pagination);
      }
    },

    // Checkbox
    selectCheckbox(obj, values) {
      if (obj.type === 'header') {
        // Check the condition of the checkbox
        this.dataTable = this.dataTable.map(x => ({
          ...x,
          checked: obj.checked,
        }));
        this.indeterminate = null;
        this.checked = obj.checked;
        // The value is taken to emit outside
        if (obj.checked === true) {
          this.selected = values;
        } else {
          this.selected = [];
        }
      } else if (obj.type === 'body') {
        // Check the condition of the checkbox
        this.dataTable = this.dataTable.map(x => {
          if (x.id === values.id) {
            return { ...x, checked: obj.checked };
          }
          return x;
        });
        var everyCheckbox = this.dataTable.map(x => x.checked);
        if (
          everyCheckbox.filter(x => x === true).length === this.dataTable.length
        ) {
          this.checked = true;
          this.indeterminate = false;
        } else if (everyCheckbox.filter(x => x === true).length > 0) {
          this.checked = false;
          this.indeterminate = true;
        } else if (
          everyCheckbox.filter(x => x === false).length ===
          this.dataTable.length
        ) {
          this.checked = false;
          this.indeterminate = null;
        }
        // The value is taken to emit outside
        var checkboxExist = this.selected.some(item => item.id === values.id);
        if (checkboxExist) {
          this.selected = this.selected.filter(item => item.id !== values.id);
        } else {
          this.selected = [...this.selected, values];
        }
      }
      this.$emit('selected:checkbox', this.selected);
    },

    // Next or Prev Pagination
    paginationNextPrev(type) {
      this.pagination = {
        ...this.pagination,
        totalItems: this.items.length,
        page:
          type === 'prevPage' ? --this.pagination.page : ++this.pagination.page,
      };
      this.changePagination(this.pagination);
    },

    // Change Row Per Page
    changeRowPerPage(event) {
      this.changePagination(event);
      this.pagination = event;
    },

    // reset checked
    resetChecked() {
      this.dataTable = this.items.map(x => ({ ...x, checked: false }));
      this.selected = [];
      this.indeterminate = null;
      this.checked = false;
      this.$emit('selected:checkbox', this.selected);
    },
  },
  updated() {
    this.changePagination(this.pagination);
  },
  created() {
    this.dataTable = this.items.map(x => ({ ...x, checked: false }));
  },
  watch: {
    footerProps(val) {
      this.pagination = {
        ...this.pagination,
        pageSize:
          val && val.pageSizeOption && !this.pageSize
            ? val.pageSizeOption[0]
            : this.pageSize,
        totalItems: this.items.length,
      };
    },
    items(val) {
      this.dataTable = val.map(x => ({ ...x, checked: false }));
      this.resetChecked();
    },
  },
};
</script>
<style lang="scss" scoped>
.no-data {
  border-top-width: 1px;
  border-top-color: #e5e5e5;
  border-top-style: solid;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  color: grey;
}
.not-data {
  font-size: 14px;
  color: grey;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.section-name {
  font-size: 15px;
  font-weight: bold;
  align-items: center;
}

@media (min-width: 1920px) {
  .child-table {
    table-layout: fixed !important;
    width: 100%;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .data-table {
    table-layout: fixed !important;
    width: 100%;
  }
}

.child-table ::v-deep thead tr {
  //Product Item
  th:nth-child(1) {
    width: 200px;
  }
  //Note
  th:nth-child(2) {
    width: 200px;
  }

  // Qty
  th:nth-child(3) {
    width: 100px;
  }
  // Package Qty
  th:nth-child(4) {
    width: 100px;
  }
  // Price
  th:nth-child(5) {
    width: 100px;
  }
  // Total Cost
  th:nth-child(6) {
    width: 120px;
  }
  // Price Override
  th:nth-child(7) {
    width: 90px;
  }
  // Color
  th:nth-child(8) {
    width: 80px;
  }
  // Manufacturer
  th:nth-child(9) {
    width: 120px;
  }
  // Technical Data
  th:nth-child(10) {
    width: 200px;
  }
  // Waste Factor
  th:nth-child(11) {
    width: 100px;
  }

  // Action
  th:nth-child(12) {
    width: 70px;
  }
}

@media (max-width: 480px) and (orientation: portrait) {
  .child-table ::v-deep thead tr {
    th {
      padding: 0px 10px;
    }
    //Product Item
    th:nth-child(1) {
      width: 140px;
    }
    // Package Qty
    th:nth-child(2) {
      width: 100px;
    }
    // Price
    th:nth-child(3) {
      width: 100px;
    }
    // Total Cost
    th:nth-child(4) {
      width: 120px;
    }
  }
  .child-table ::v-deep tbody tr {
    td {
      padding: 0px 10px;
    }
  }
}

.child-table::v-deep tbody tr td:nth-child(5) {
  ul {
    width: 100%;
    padding-left: 20px;
    li {
      a {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
