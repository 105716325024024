const GOOGLE_MAPS_PLACE_JS_SDK_SRC = key =>
  `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=initGoogleApi&loading=async`;

let GoogleMapPlacesPlugin = {};

GoogleMapPlacesPlugin.install = function (Vue, options) {
  let dummy = document.createElement('div');
  dummy.class = 'prependedMap';
  let body = document.getElementsByTagName('body')[0];
  body.prepend(dummy);
  const { key } = options;
  window.initGoogleApi = function () {
    Vue.prototype.$google = Vue.prototype.$google || {};
    Vue.prototype.$google.maps = {
      ...Vue.prototype.$google.map,
      apiKey: key,
    };
    Vue.prototype.$placeService = new window.google.maps.places.PlacesService(
      dummy
    );
    Vue.prototype.$autoComplete =
      new window.google.maps.places.AutocompleteService();

    Vue.prototype.$geoCoder = new window.google.maps.Geocoder();
    Vue.prototype.$distanceService =
      new window.google.maps.DistanceMatrixService();
  };
  let s1 = document.createElement('script');
  let s0 = document.getElementsByTagName('script')[0];
  s1.async = true;
  s1.src = GOOGLE_MAPS_PLACE_JS_SDK_SRC(key);
  s1.text = 'text/javascript';
  s0.parentNode.insertBefore(s1, s0);
};

export default GoogleMapPlacesPlugin;

export const googlePlaceApiMixin = {
  methods: {
    async lookupAddress(latitude, longitude) {
      const lat = parseFloat(latitude);
      const lng = parseFloat(longitude);
      var location = new window.google.maps.LatLng(lat, lng);

      this.loading = true;
      var request = {
        location,
      };
      return new Promise((ac, reject) => {
        this.$geoCoder.geocode(request, (results, status) => {
          if (status == window.google.maps.places.PlacesServiceStatus.OK) {
            const firstPlace = results[0];
            ac({
              formattedAddress: firstPlace.formatted_address,
              placeId: firstPlace.place_id,
              address_components: firstPlace.address_components,
            });
          } else {
            reject();
          }
        });
      });
    },
    async calculateDistance(origin, destinations) {
      const gOrigin =
        typeof origin === 'string'
          ? origin
          : new window.google.maps.LatLng(
              parseFloat(origin.lat),
              parseFloat(origin.lng)
            );
      const gDestinations = destinations.map(destination =>
        typeof destination === 'string'
          ? destination
          : new window.google.maps.LatLng(
              parseFloat(destination.lat),
              parseFloat(destination.lng)
            )
      );

      return new Promise((resolve, reject) => {
        this.$distanceService.getDistanceMatrix(
          {
            origins: [gOrigin],
            destinations: gDestinations,
            travelMode: 'DRIVING',
          },
          (response, status) => {
            if (status == window.google.maps.places.PlacesServiceStatus.OK) {
              const rows = response.rows;

              const firstRow = rows[0];
              if (!firstRow) reject();
              const elements = firstRow.elements;
              resolve({
                gResult: response,
                distance: elements
                  .map(r => (r.distance || {}).value)
                  .reduce((a, b) => a + (b || 0), 0),

                duration: elements
                  .map(r => (r.duration || {}).value)
                  .reduce((a, b) => a + (b || 0), 0),
              });
            } else {
              reject();
            }
          }
        );
      });
    },
    async calculateDistanceFromThreePoints(
      property,
      startLocation,
      endLocation
    ) {
      const {
        distance: distanceFromPropertyToStart,
        gResult: gResultFromPropertyToStart,
      } = await this.calculateDistance(property, [startLocation]);
      const {
        distance: distanceFromStartToEnd,
        gResult: gResultFromStartToEnd,
      } = await this.calculateDistance(startLocation, [endLocation]);
      return {
        distance: distanceFromPropertyToStart + distanceFromStartToEnd,
        gResult: [gResultFromPropertyToStart, gResultFromStartToEnd],
      };
    },
    async geocode(options) {
      const geocoder = new window.google.maps.Geocoder();

      return new Promise((resolve, reject) => {
        if (!options.geometry) {
          geocoder.geocode(options, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              resolve(results);
            } else {
              reject(status);
            }
          });
        } else {
          resolve([options]);
        }
      });
    },
  },
};
