<template>
  <div>
    <f7-block-title>
      <div>{{ title }}</div>
    </f7-block-title>
    <f7-list>
      <f7-list-item>
        <f7-icon
          class="cursor-pointer add-button-icon"
          slot="media"
          f7="plus_circle"
        ></f7-icon>
        <f7-link
          class="cursor-pointer text-link"
          popover-open=".popover-create-invoice"
          slot="title"
        >
          Create New Invoice
        </f7-link>
      </f7-list-item>
      <f7-popover class="popover-create-invoice">
        <f7-list class="cursor-pointer">
          <f7-list-item
            link
            popover-close
            title="Default Invoice"
            @click="openCreateInvoicePopup"
          >
          </f7-list-item>
          <f7-list-item
            link
            popover-close
            title="Recurring Invoice"
            @click="openCreateRecurringInvoicePage"
          >
          </f7-list-item>
        </f7-list>
      </f7-popover>
      <f7-list-item>
        <f7-icon
          class="cursor-pointer add-button-icon"
          slot="media"
          f7="plus_circle"
        ></f7-icon>
        <div
          class="cursor-pointer"
          slot="title"
          @click="importInvoiceFromQB"
        >
          Import Invoice from QB
        </div>
      </f7-list-item>

      <f7-list-item>
        <f7-icon
          class="cursor-pointer add-button-icon"
          slot="media"
          f7="plus_circle"
        ></f7-icon>
        <div
          class="cursor-pointer"
          slot="title"
          @click="importPaymentFromQB"
        >
          Import Payment from QB
        </div>
      </f7-list-item>
    </f7-list>
    <invoice-collections-finance
      ref="invoiceData"
      :isShowCreateBtn="false"
      :isShowActionColumn="false"
      @doClosePopup="closeActionPopup"
    >
    </invoice-collections-finance>
    <new-invoice-popup
      ref="newInvoicePopup"
      :isEdit="isEdit"
      :propProjectId="card ? card.id : ''"
      :isDisable="true"
      @setIsEdit="setIsEdit"
      @done="getInvoicesAfterCreation"
      @onOpenDetail="openInvoiceGroupDetailPopup"
    ></new-invoice-popup>
    <new-recurring-invoice-popup
      ref="newRecurringInvoicePopup"
      @closePopup="getInvoicesAfterCreation"
    >
    </new-recurring-invoice-popup>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import InvoiceCollectionsFinance from '../finance/InvoiceCollectionsFinance.vue';
import NewInvoicePopup from '@/plugins/invoices/components/popups/NewInvoicePopup.vue';
import NewRecurringInvoicePopup from '@/plugins/invoices/components/popups/NewRecurringInvoicePopup.vue';
import {
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_RESIDENTIAL,
} from '../../../../utility/const';

export default {
  components: {
    InvoiceCollectionsFinance,
    NewInvoicePopup,
    NewRecurringInvoicePopup,
  },
  props: {
    title: { type: String, default: '' },
  },
  data() {
    return {
      isEdit: false,
    };
  },
  computed: {
    ...mapGetters('dashboard/project', ['card', 'action']),
    ...mapGetters('dashboard/estimate', ['estimateList']),
  },

  methods: {
    ...mapActions('dashboard/estimate', ['getEstimatesByProjectId']),
    ...mapActions('dashboard/finance', ['getInvoiceGroupByProjectId']),

    initData() {
      if (!_.isEmpty(this.$refs.invoiceData)) {
        this.$refs.invoiceData.initData(this.card.id);
      }
    },
    openCreateInvoicePopup() {
      this.$refs.newInvoicePopup.open();
    },

    async openCreateRecurringInvoicePage() {
      if (!_.isEmpty(this.card)) {
        const invoiceGroup = await this.getInvoiceGroupByProjectId(
          this.card.id
        );
        this.$refs.newRecurringInvoicePopup.open(
          !_.isEmpty(invoiceGroup) ? invoiceGroup[0].id : '',
          true,
          '',
          this.card.id
        );
      }
    },

    getInvoicesAfterCreation() {
      this.$refs.invoiceData.initData(this.card.id);
    },

    closeActionPopup(callback) {
      this.$emit('closePopup', callback);
    },

    getCreateNewInvoiceRouteName(type) {
      switch (this.$f7route.route.meta.businessCode) {
        case BUSINESS_CODE_COMMERCIAL:
          if (type === 'FromEstimate') {
            return 'new-invoice-from-estimate-swimlane-commercial';
          }
          return 'new-invoice-swimlane-commercial';
        case BUSINESS_CODE_RESIDENTIAL:
          if (type === 'FromEstimate') {
            return 'new-invoice-swimlane-residential-from-estimate';
          }
          return 'new-invoice-swimlane-residential-from-template';
      }
    },
    setIsEdit(value) {
      this.isEdit = value;
    },

    openInvoiceGroupDetailPopup(invoice) {
      this.$refs.invoiceData.openInvoiceGroupDetailPopup(invoice);
    },
    async createFromEstimate() {
      if (!_.isEmpty(this.card)) {
        await this.getEstimatesByProjectId(this.card.id);
      }
      if (
        _.isEmpty(
          (this.estimateList || []).filter(item => item.status === 'active')
        )
      ) {
        this.$ri.dialog.openWarningDialog({
          title: 'Create new Invoice',
          content: 'You do not have active estimate!',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
              return;
            }
          },
        });
      } else {
        this.$f7.preloader.show();
        const actionId = this.action.id;
        const projectId = this.card.id;
        const contactId = this.card.contactId || '-';
        const boardId = this.action.boards[0] || '';
        const self = this;

        this.closeActionPopup(() => {
          self.$f7.preloader.hide();
          const routeName = this.getCreateNewInvoiceRouteName('FromEstimate');
          self.$f7router.navigate(
            {
              name: routeName,
              params: {
                boardType: self.$f7route.route.meta.boardType,
                businessCode: self.$f7route.route.meta.businessCode,
                boardId,
                actionId,
                projectId,
                contactId,
                actionType: 'newInvoice',
              },
              query: {
                activeTab: 'tab-action',
              },
            },
            { reloadAll: true }
          );
        });
      }
    },

    createFromTemplate() {
      this.$f7.preloader.show();
      const actionId = this.action.id;
      const projectId = this.card.id;
      const contactId = this.card.contactId || '-';
      const boardId = this.action.boards[0] || '';
      const self = this;

      this.closeActionPopup(() => {
        self.$f7.preloader.hide();
        const routeName = this.getCreateNewInvoiceRouteName('FromTemplate');
        self.$f7router.navigate(
          {
            name: routeName,
            params: {
              boardType: self.$f7route.route.meta.boardType,
              businessCode: self.$f7route.route.meta.businessCode,
              boardId,
              actionId,
              projectId,
              contactId,
              actionType: 'newInvoice',
            },
            query: {
              activeTab: 'tab-action',
            },
          },
          { reloadAll: true }
        );
      });
    },

    importInvoiceFromQB() {
      this.$f7.preloader.show();
      const self = this;
      this.closeActionPopup(() => {
        self.$f7.preloader.hide();
        self.$f7router.navigate(
          {
            name: 'import-main-by-tab',
            params: {
              tabActive: 'import-invoice',
            },
          },
          { reloadAll: true }
        );
      });
    },

    importPaymentFromQB() {
      this.$f7.preloader.show();
      const self = this;
      this.closeActionPopup(() => {
        self.$f7.preloader.hide();
        self.$f7router.navigate(
          {
            name: 'import-main-by-tab',
            params: {
              tabActive: 'import-payment',
            },
          },
          { reloadAll: true }
        );
      });
    },
  },
};
</script>

<style>
.text-link {
  color: var(--f7-color-text-neutral);
}
</style>
