<template>
  <div>
    <f7-block-header
      :style="
        listItems.length === 0
          ? 'margin-bottom: var(--f7-block-margin-vertical);'
          : ''
      "
      class="display-flex align-items-center justify-content-space-between margin"
    >
      <div>{{ title }}</div>
      <div class="display-flex align-items-center">
        <div
          v-if="requiredErrorMessage != ''"
          class="item-error-message margin-right"
        >
          {{ requiredErrorMessage }}
        </div>
        <f7-button
          fill
          @click="$emit('openAddNewPopup')"
          class="display-flex align-items-center"
          v-if="listItems.length === 0"
        >
          <f7-icon
            f7="plus"
            class="margin-right-half"
          ></f7-icon>
          {{ addBtnTitle }}
        </f7-button>
      </div>
    </f7-block-header>

    <f7-list
      v-if="listItems.length != 0"
      class="no-margin"
    >
      <f7-list-item
        v-for="(item, index) in listItems"
        :key="index"
      >
        <f7-link
          style="white-space: break-spaces"
          @click.native="$emit('openEditPopup', item.id)"
          slot="title"
          >{{ item.title }}</f7-link
        >
        <div slot="footer">
          {{ item.status === 'draft' ? 'Created' : 'Sent' }} on
          {{ toDisplayDateString(item.createdAt) }} by
          {{ item.createdBy }}
        </div>
        <div
          slot="after"
          class="display-flex theme-color"
        >
          <div
            v-show="item.status === 'draft'"
            @click="$emit('openSendEmailPopup', item.id)"
          >
            <f7-icon
              class="margin-right cursor-pointer"
              f7="envelope"
            ></f7-icon>
          </div>
          <div @click="$emit('getPDF', item.id)">
            <f7-icon
              :class="{ 'margin-right': item.status !== 'signed' }"
              class="cursor-pointer"
              f7="square_arrow_down"
            ></f7-icon>
          </div>
          <div
            v-if="item.status !== 'signed'"
            @click="$emit('onDelete', { id: item.id })"
          >
            <f7-icon
              class="cursor-pointer"
              f7="trash"
            ></f7-icon>
          </div>
        </div>
      </f7-list-item>
    </f7-list>
  </div>
</template>
<script>
import { toDisplayDateString } from '../../../../utility/datetime';

export default {
  props: {
    title: String,
    addBtnTitle: String,
    listItems: { type: Array, default: () => [] },
    requiredErrorMessage: { type: String, default: '' },
  },

  methods: {
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },
  },
};
</script>
<style scoped>
.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}
</style>
