<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="cancel"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="cancel">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Share project's photos by SMS</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="send">Send</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div
        class="display-flex flex-direction-column"
        style="height: 100%"
      >
        <f7-list style="width: 99%">
          <custom-auto-complete-input
            :options="phoneOptions"
            :value="phoneNumbers"
            :hasMoreItems="hasMoreItems"
            title="To Phone Numbers"
            type="phone"
            placeholder="Enter phone numbers"
            @change="onChange"
            @searchChange="searchChange"
            @loadMore="loadMore"
          >
            <f7-icon
              slot="icon"
              f7="phone"
            ></f7-icon>
            <span slot="error-message">{{
              displayPhoneErrorMessage || phoneErrorMessage
            }}</span>
          </custom-auto-complete-input>
        </f7-list>
      </div>
    </f7-page>
  </f7-popup>
</template>
<script>
import { required } from '@vuelidate/validators';
import { mask } from 'vue-the-mask';
import notificationService from '../../services/notifications';
import { useVuelidate } from '@vuelidate/core';
import { mapActions, mapGetters } from 'vuex';
import CustomAutoCompleteInput from '@/components/inputs/CustomAutoCompleteInput.vue';
import { VALIDATION_MESSAGE } from '@/utility/const';
import _ from 'lodash';

export default {
  components: { CustomAutoCompleteInput },
  directives: { mask },
  data: () => ({
    popupOpened: false,
    phoneNumbers: [],
    phoneErrorMessage: '',

    allowInfinite: true,
    hasMoreItems: true,

    oldPhonesContact: [],
  }),
  methods: {
    ...mapActions('common/contact', [
      'searchContact',
      'loadMoreContact',
      'resetSearch',
      'setQueryFilters',
      'setContactSearchText',
    ]),

    autoLoadMore() {
      if (
        ((this.phonesOfContact || []).length > 0 &&
          (this.phonesOfContact || []).length ===
            (this.oldPhonesContact || []).length) ||
        (this.phonesOfContact || []).length * 40 <= 300
      ) {
        if (this.page + 1 < this.nbPages && this.nbPages > 1) {
          this.loadMore();
        }
      }
    },
    onSearch: _.debounce(function (callback) {
      return this.handleSearch(callback);
    }, 300),
    async handleSearch(callback) {
      const self = this;
      this.setQueryFilters('NOT phoneValues:?');
      this.hasMoreItems = true;
      this.allowInfinite = true;

      return this.searchContact({
        restrictSearchableAttributes: ['contactName', 'phoneValues'],
      }).then(() => {
        if (self.page + 1 === self.nbPages) {
          self.hasMoreItems = false;
          self.allowInfinite = false;
          return;
        }
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasMoreItems = false;
          self.allowInfinite = false;
          return;
        }
        callback;
        this.autoLoadMore();
      });
    },

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;

      this.loadMoreContact({
        page: this.page + 1,
        restrictSearchableAttributes: ['contactName', 'phoneValues'],
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
        this.autoLoadMore();
      });
    },

    onChange(value) {
      this.phoneNumbers = value;
      this.checkAllPhoneValidate(this.phoneNumbers);
      this.v$.$touch();
    },
    searchChange(data) {
      this.setContactSearchText(data.value);
      this.onSearch(data.callback);
    },
    open() {
      this.$f7.preloader.show();
      this.handleSearch()
        .then(() => {
          this.popupOpened = true;
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },
    cancel() {
      this.phoneNumbers = [];
      this.phoneErrorMessage = '';
      this.popupOpened = false;
      this.setContactSearchText('');
      this.setQueryFilters('');
      this.allowInfinite = true;
      this.hasMoreItems = true;
      this.oldPhoneContact = [];
      this.v$.$reset();
      this.resetSearch();
    },
    send() {
      this.v$.$touch();
      if (this.v$.$invalid || !this.checkAllPhoneValidate(this.phoneNumbers)) {
        return;
      } else {
        const tenant = btoa(this.tenantId);
        let shareUrl = `${import.meta.env.VITE_HOST_DOMAIN}/share-photo/${tenant}/${this.$f7route.params.id}`;
        notificationService.sendSms({
          phoneNumber: this.phoneNumbers.map(r => r.title).join('<'),
          shareUrl: shareUrl,
          projectName: this.project.title,
        });

        this.$f7.notification
          .create({
            icon: "<i class='color-green icon f7-icons'>checkmark_alt_circle_fill</i>",
            title: 'Share Successful',
            text: `An SMS was sent to ${this.phoneNumbers
              .map(r => r.title)
              .join(', ')}`,
            closeOnClick: true,
            closeButton: true,
            closeTimeout: 4000,
          })
          .open();
        this.cancel();
      }
    },
    validatePhoneNumber(input_str) {
      var re = /\+?1?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$/;
      return re.test(input_str);
    },
    checkAllPhoneValidate(phones) {
      let wrongPhones = phones.filter(r => !this.validatePhoneNumber(r.title));
      if (wrongPhones && wrongPhones.length > 0) {
        this.phoneErrorMessage = `The ${
          wrongPhones.length > 1 ? 'numbers' : 'number'
        } ${wrongPhones
          .map(r => r.title)
          .join(', ')} is not valid phone number please remove it.`;
        return false;
      }
      this.phoneErrorMessage = '';
      return true;
    },
  },
  computed: {
    ...mapGetters('photo/project', ['project']),
    ...mapGetters('common/contact', [
      'phonesOfContact',
      'hits',
      'nbPages',
      'page',
    ]),
    phoneOptions() {
      return this.phonesOfContact
        .filter(p => this.validatePhoneNumber(p.phone))
        .map(r => ({ id: r.phone + r.name, title: r.phone, desc: r.name }));
    },
    ...mapGetters('common/app-constant', ['tenantId']),

    displayPhoneErrorMessage() {
      if (!this.v$.phoneNumbers.$error) return null;
      if (this.v$.phoneNumbers.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  validations() {
    return {
      phoneNumbers: {
        required,
      },
    };
  },
  watch: {
    phonesOfContact: {
      handler(val, old) {
        this.oldPhonesContact = old;
      },
    },
  },
};
</script>
