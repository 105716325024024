/* eslint-disable no-unused-vars */
import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const contactInfoAction = new FirebaseActions(
    `/system_client/${tenant}/web_contact_info`,
    'contactInfo'
  );

  return {
    updateContactInfo: contactInfoAction.updateDocument,
    getContactInfo: contactInfoAction.getDocument,
  };
};
