<template>
  <f7-button
    color="gray"
    class="display-flex justify-content-center align-items-center btn-flus"
    @click="onClick"
    :icon-f7="icon"
  ></f7-button>
</template>
<script>
export default {
  props: {
    icon: String,
  },

  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>
<style scoped>
.btn-flus {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.btn-flus:hover {
  background-color: #f3f3f3 !important;
}
</style>
