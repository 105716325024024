/* eslint-disable no-unused-vars */
import _ from 'lodash';

const capitalizeFirstLetter = string => {
  if (string === '') {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default {
  projectList: (state, getters, rootState, rootGetters) => {
    const propertyById = rootGetters['quickbooks/property/propertyById'];

    return _.cloneDeep(state.projectList)
      .map(item => {
        return {
          ...item,
          title: item.title || '',
          propertyName: propertyById(item.propertyId).propertyName || '',
          businessCode: capitalizeFirstLetter(item.businessCode || ''),
        };
      })
      .sort((a, b) => a.createdAt - b.createdAt);
  },

  projectById: (state, getters, rootState, rootGetters) => id => {
    return state.projectList.find(item => item.id === id) || {};
  },
};
