/* eslint-disable no-unused-vars */
import { FirebaseActions } from '../../../../services/firebase.service';

export default tenant => {
  const roofTypeAction = new FirebaseActions(
    `/system_client/${tenant}/app_constant`,
    'roofType'
  );

  return {
    bindRoofTypeListBys: roofTypeAction.bindCollectionBys,
    unbindRoofTypeList: roofTypeAction.unbindCollection,

    bindRoofTypeList(
      { rootGetters, commit, dispatch, getters, rootState, state },
      payload
    ) {
      return dispatch('bindRoofTypeListBys', [
        {
          prop: 'code',
          op: '==',
          val: 'roof-type',
        },
      ]);
    },
  };
};
