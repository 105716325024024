import vendor from './vendor';
import product from './product';
import category from './category';
import subCategory from './sub-category';
import roofType from './roof-type';
import appConstant from './app-constant';
import priceList from './price-list';

import common from './common';
import _ from 'lodash';

export default tenant => {
  // console.log(">>>> init product vuex store");
  return {
    namespaced: true,
    modules: {
      'roof-type': roofType(tenant),

      'product-item-page': {
        namespaced: true,
        modules: {
          common: _.cloneDeep(common),
          vendor: _.cloneDeep(vendor(tenant)),
          category: _.cloneDeep(category(tenant)),
          'sub-category': _.cloneDeep(subCategory(tenant)),
          'product-item': _.cloneDeep(product(tenant)),
          'app-constant': _.cloneDeep(appConstant(tenant)),
          'price-list': _.cloneDeep(priceList(tenant)),
        },
      },

      'category-page': {
        namespaced: true,
        modules: {
          category: _.cloneDeep(category(tenant)),
          'sub-category': _.cloneDeep(subCategory(tenant)),
        },
      },
    },
  };
};
