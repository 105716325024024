<template>
  <f7-page>
    <!-- Navigation bar -->
    <construction-navbar></construction-navbar>

    <board-toolbar></board-toolbar>
    <construction-menu
      @onAddNewTask="handleAddNewTask"
      @onResolveConstructionAction="handleDoResolve"
    ></construction-menu>

    <workorder-grid-view-board
      v-if="constructionProjectId"
      style="background: transparent"
      @onOpenPopup="openCardDetailsPopup"
    ></workorder-grid-view-board>

    <!-- Popup -->
    <wo-task-details-popup
      ref="woTaskDetailsPopup"
      @navigateToBoard="navigateToBoard"
    ></wo-task-details-popup>

    <wo-task-new-popup
      ref="woTaskNewPopup"
      @gotoDetails="openCardDetailsPopup"
      @navigateToBoard="navigateToBoard"
    ></wo-task-new-popup>

    <construction-renderless
      ref="constructionRenderless"
    ></construction-renderless>
  </f7-page>
</template>

<script>
import BoardToolbar from '../../components/toolbar/BoardToolbar.vue';
import ConstructionMenu from '../../components/menu/ConstructionMenu.vue';
import WorkorderGridViewBoard from '../../pages/board/WorkorderGridViewBoard.vue';
import WoTaskNewPopup from '../../components/popup/WoTaskNewPopup.vue';
import WoTaskDetailsPopup from '../../components/popup/WoTaskDetailsPopup.vue';
import ConstructionNavbar from '../../components/navbar/ConstructionNavbar.vue';
import ConstructionRenderless from '../../components/renderless/ConstructionRenderless.vue';

import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  components: {
    ConstructionMenu,
    BoardToolbar,
    WorkorderGridViewBoard,
    WoTaskNewPopup,
    WoTaskDetailsPopup,
    ConstructionNavbar,
    ConstructionRenderless,
  },

  created() {
    this.$f7.preloader.show();
    let refs = [];
    // get project by cardNumber
    this.getProjectByCardNumber(this.$f7route.params.cardNumber).then(res => {
      if (!_.isEmpty(res)) {
        refs.push(
          this.bindWorkOrderTaskListBys([
            {
              op: '==',
              prop: 'projectId',
              val: this.constructionProjectId,
            },
          ])
        );

        if (_.isEmpty(this.users)) {
          refs.push(this.bindUserData());
        }

        // if (_.isEmpty(this.workOrderTaskList)) {
        //   refs.push(this.bindWorkOrderList());
        // }

        if (_.isEmpty(this.workOrderActionList)) {
          refs.push(this.bindWorkOrderActionList());
        }

        refs.push(this.getConstantTypeList());

        Promise.all(refs).then(() => {
          this.setCurrentCard(this.$f7route.params.cardNumber);
          this.$f7.preloader.hide();

          if (this.$f7route.route.meta.isNewTask === true) {
            // open add new task popup
            this.$refs.woTaskNewPopup.initPopupData();
            this.$refs.woTaskNewPopup.openPopup();
          }
        });
      } else {
        this.$f7.notification
          .create({
            title: 'Not found project',
            closeTimeout: 15000,
            closeButton: true,
            text: `Not found project <b class='color-red'>${this.$f7route.params.cardNumber}</b>. Please choose another project.`,
            closeOnClick: true,
          })
          .open();
        this.$f7.preloader.hide();
      }
    });
  },

  mounted() {
    if (this.$f7route.params.taskNumber && this.$f7route.params.actionCode) {
      // get action by action code
      this.getWorkOrderActionBys([
        {
          prop: 'code',
          val: Number(this.$f7route.params.actionCode),
          op: '==',
        },
      ]).then(action => {
        if (_.isEmpty(action)) {
          this.$f7.notification
            .create({
              title: 'Not found action',
              closeTimeout: 15000,
              closeButton: true,
              text: `Not found action code <b>${this.$f7route.params.actionCode}</b>. Please choose another action code.`,
              closeOnClick: true,
            })
            .open();
          return;
        }
        // open task details popup
        this.getWorkOrderTaskListBys([
          {
            prop: 'taskNumber',
            op: '==',
            val: this.$f7route.params.taskNumber,
          },
          {
            prop: 'actions',
            op: 'array-contains',
            val: action[0].id,
          },
        ]).then(docs => {
          if (docs.length > 0) {
            this.$refs.woTaskDetailsPopup.initCardData({
              projectId: this.constructionProjectId,
              actionId: action[0].id,
              taskId: docs[0].id,
            });
            this.$refs.woTaskDetailsPopup.openPopup();
          } else {
            this.getWorkOrderAction(action[0].id).then(res => {
              this.$f7.notification
                .create({
                  title: 'Not found project',
                  closeTimeout: 15000,
                  closeButton: true,
                  text: `Not found task <b>${this.$f7route.params.taskNumber}</b> in <b>${res.title}</b> action. Please choose another task.`,
                  closeOnClick: true,
                })
                .open();
            });
          }
        });
      });
    }
  },

  computed: {
    ...mapGetters('dashboard/project', ['users', 'constructionProjectId']),
    ...mapGetters('dashboard/work-order', [
      'workOrderActionList',
      'workOrderTaskList',
    ]),
  },

  methods: {
    ...mapActions('dashboard/project', [
      'bindUserData',
      'setCurrentCard',
      'setConstructionProjectId',
      'getProjectByCardNumber',
    ]),

    ...mapActions('dashboard/work-order', [
      'bindWorkOrderActionList',
      'bindWorkOrderTaskListBys',
      // "bindWorkOrderList",
      'getWorkOrderTaskListBys',
      'getWorkOrderAction',
      'getWorkOrderActionBys',
    ]),

    ...mapActions('dashboard/app-constant', ['getConstantTypeList']),

    async openCardDetailsPopup({ actionId, cardID, taskNumber }) {
      // get action by id
      const action = await this.getWorkOrderAction(actionId);
      window.history.pushState(
        '',
        '',
        `/dashboard/grid/commercial/construction/project/${this.$f7route.params.cardNumber}/action/${action.code}/task/${taskNumber}`
      );
      this.$refs.woTaskDetailsPopup.initCardData({
        projectId: this.constructionProjectId,
        actionId: actionId,
        taskId: cardID,
      });
      this.$refs.woTaskDetailsPopup.openPopup();
    },

    /**
     * open add new task popup
     */
    handleAddNewTask() {
      window.history.pushState(
        '',
        '',
        `/dashboard/grid/commercial/construction/project/${this.$f7route.params.cardNumber}/task/new`
      );
      this.$refs.woTaskNewPopup.initPopupData({
        projectId: this.constructionProjectId,
      });
      this.$refs.woTaskNewPopup.openPopup();
    },

    navigateToBoard() {
      window.history.pushState(
        '',
        '',
        `/dashboard/grid/commercial/construction/project/${this.$f7route.params.cardNumber}`
      );
      this.$refs.woTaskNewPopup.closePopup();
    },

    handleDoResolve() {
      this.$refs.constructionRenderless.doResolve();
    },
  },
};
</script>
