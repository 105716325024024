<template>
  <div>
    <f7-row class="row-action">
      <f7-col
        ><f7-row class="justify-content-flex-start align-items-center"
          ><f7-block-title>Assembly Drawing </f7-block-title></f7-row
        ></f7-col
      >
    </f7-row>
    <f7-list
      media-list
      :inset="$device.desktop"
      :class="{ 'no-margin': $device.desktop }"
    >
      <!-- assembly drawing name -->
      <f7-list-input
        label="Assembly Drawing Name"
        placeholder="Enter Assembly Drawing Name"
        type="text"
        :value="currentDrawing.drawingName"
        @input="changeDrawingField('drawingName', $event.target.value)"
        error-message-force
        validate
        validate-on-blur
        :error-message="drawingNameErrorMessage"
      >
        <required-asterisk slot="label"></required-asterisk>
      </f7-list-input>

      <!-- roof type -->
      <f7-list-input
        type="select"
        label="Roof Type"
        :value="currentDrawing.roofType"
        @change="changeDrawingField('roofType', $event.target.value)"
        error-message-force
        validate
        validate-on-blur
        :error-message="roofTypeErrorMessage"
      >
        <required-asterisk slot="label"></required-asterisk>
        <option
          value=""
          hidden
        >
          Select roof type
        </option>
        <option
          v-for="item in roofTypes"
          :key="item.id"
          :value="item.value"
        >
          {{ item.displayName }}
        </option>
      </f7-list-input>

      <!-- assembly teamplate -->
      <f7-list-input
        type="select"
        label="Assembly Template"
        :value="currentDrawing.assemblyTemplateId"
        @change="changeDrawingField('assemblyTemplateId', $event.target.value)"
        error-message-force
        validate
        validate-on-blur
        :error-message="assemblyTemplateErrorMessage"
      >
        <required-asterisk slot="label"></required-asterisk>
        <option
          value=""
          hidden
        >
          Select assembly template
        </option>
        <option
          v-for="item in assemblyTemplates"
          :key="item.id"
          :value="item.id"
        >
          {{
            `${item.templateNumber} -
          ${item.templateName}`
          }}
        </option>
      </f7-list-input>
      <!-- Price List -->
      <f7-list-item
        link
        @click.native="selectPriceList"
      >
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Price List<required-asterisk />
        </div>
        <div
          class="list-item-title"
          slot="title"
        >
          {{ getPriceListData || 'Select price list' }}
        </div>
        <div
          slot="text"
          style="color: red"
        >
          {{ selectPriceListMessage || priceListErrorMessage }}
        </div>
      </f7-list-item>

      <f7-list-input
        readonly
        label="Stretch Out calc (in)"
        :value="stretchOut"
      >
      </f7-list-input>

      <!-- status -->
      <f7-list-input
        type="select"
        label="Assembly Drawing Status"
        :value="currentDrawing.status"
        @change="changeDrawingField('status', $event.target.value)"
        error-message-force
        validate
        validate-on-blur
        :error-message="assemblyStatusErrorMessage"
      >
        <required-asterisk slot="label"></required-asterisk>
        <option
          value=""
          hidden
        >
          Select status
        </option>
        <option
          v-for="item in assemblyDrawingStatus"
          :key="item.value"
          :value="item.value"
        >
          {{ item.displayName }}
        </option>
      </f7-list-input>

      <f7-list-input
        readonly
        label="# of Bends per 10 foot item"
        :value="currentDrawing.numberOfBend"
      >
      </f7-list-input>

      <f7-list-input
        v-if="currentDrawing.numberOfCleat === 1"
        readonly
        label="Cleat Stretch out (in)"
        :value="cleatStretchOut"
      >
      </f7-list-input>

      <f7-list-input
        v-if="currentDrawing.numberOfCleat === 1"
        readonly
        label="# of Bends for Cleat"
        :value="currentDrawing.cleatNumberOfBend"
      >
      </f7-list-input>

      <f7-list-input
        v-if="currentDrawing.numberOfCleat === 2"
        readonly
        label="Front Face Cleat Stretch out (in)"
        :value="frontCleatStretchOut"
      >
      </f7-list-input>

      <f7-list-input
        v-if="currentDrawing.numberOfCleat === 2"
        readonly
        label="# of Bends for Front Face Cleat"
        :value="currentDrawing.cleatNumberOfBend[0]"
      >
      </f7-list-input>

      <f7-list-input
        v-if="currentDrawing.numberOfCleat === 2"
        readonly
        label="Back Face Cleat Stretch out (in)"
        :value="backCleatStretchOut"
      >
      </f7-list-input>

      <f7-list-input
        v-if="currentDrawing.numberOfCleat === 2"
        readonly
        label="# of Bends for Back Face Cleat"
        :value="currentDrawing.cleatNumberOfBend[1]"
      >
      </f7-list-input>
    </f7-list>
    <price-list-item-list-popup
      ref="selectPriceListItem"
      @onSelected="onSelectPriceList($event)"
    ></price-list-item-list-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PriceListItemListPopup from '../popups/PriceListItemListPopup.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import {
  getCleatStretchOut,
  getBackCleatStretchOut,
  getStretchOut,
} from '../../utility/stretch-out-helper';
import _ from 'lodash';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  props: {
    data: Object,
  },
  components: {
    PriceListItemListPopup,
  },
  data() {
    return {
      selectedFasteningSubstrates: null,
      currentDrawing: {},
      selectPriceListMessage: '',
      priceList: {},
    };
  },
  async created() {
    await this.getAssemblyTemplateListDoc([
      {
        prop: 'isDeleted',
        val: true,
        op: '!=',
      },
    ]);
  },
  computed: {
    ...mapGetters({
      assemblyDrawing: 'common/assembly-drawing/assemblyDrawing',
      assemblyTemplateList: 'common/assembly-template/assemblyTemplateList',
      roofTypeList: 'common/app-constant/roofTypeList',
      priceListItemList: 'common/price-list/priceListItemList',
      priceListItem: 'common/price-list/priceListItem',
      assemblyDrawingStatus: 'common/app-constant/assemblyDrawingStatus',
    }),
    roofTypes() {
      return this.roofTypeList
        .filter(type => {
          return type.workSteps && type.workSteps.commercial;
        })
        .sort((a, b) => {
          return a.displayName < b.displayName ? -1 : 0;
        });
    },
    assemblyTemplates() {
      return this.assemblyTemplateList
        .filter(r => {
          return this.currentDrawing.roofType
            ? r.roofTypes.includes(this.currentDrawing.roofType)
            : r;
        })
        .sort((a, b) => {
          return a.createdAt < b.createdAt ? -1 : 0;
        });
    },
    getPriceListData() {
      if (_.isEmpty(this.priceList)) {
        if (_.isEmpty(this.currentDrawing.priceListId))
          return 'Select price list';
        return _.cloneDeep(this.priceListItem).displayName;
      }
      return this.priceList.displayName;
    },
    drawingNameErrorMessage() {
      if (!this.v$.$error) return '';
      if (this.v$.currentDrawing.drawingName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    roofTypeErrorMessage() {
      if (!this.v$.$error) return '';
      if (this.v$.currentDrawing.roofType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    assemblyTemplateErrorMessage() {
      if (!this.v$.$error) return '';
      if (this.v$.currentDrawing.assemblyTemplateId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    priceListErrorMessage() {
      if (!this.v$.$error) return '';
      if (this.v$.currentDrawing.priceListId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    assemblyStatusErrorMessage() {
      if (!this.v$.$error) return '';
      if (this.v$.currentDrawing.status.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    stretchOut() {
      let value = getStretchOut.call(
        this.currentDrawing.measurement || {},
        this.currentDrawing.templateCode
      );
      return value;
    },

    cleatStretchOut() {
      let value = getCleatStretchOut.call(
        this.currentDrawing.measurement || {},
        this.currentDrawing.templateCode
      );
      return value;
    },

    frontCleatStretchOut() {
      return this.cleatStretchOut;
    },

    backCleatStretchOut() {
      let value = getBackCleatStretchOut.call(
        this.currentDrawing.measurement || {},
        this.currentDrawing.templateCode
      );
      return value;
    },
  },
  methods: {
    ...mapActions({
      getAssemblyTemplateListDoc:
        'common/assembly-template/getAssemblyTemplateListDoc',
    }),
    selectPriceList() {
      if (!this.currentDrawing.roofType) {
        this.selectPriceListMessage = 'Please select roof type first';
        return;
      }
      this.$refs.selectPriceListItem.open(this.currentDrawing.roofType);
    },
    changeDrawingField(key, value) {
      if (key === 'roofType') {
        this.currentDrawing.assemblyTemplateId = null;
        this.currentDrawing.priceListId = null;
        this.priceList = {};
      }
      this.currentDrawing[key] = value;
      this.$emit('changeDrawing', this.currentDrawing);
    },
    onSelectPriceList(priceListId) {
      this.changeDrawingField('priceListId', priceListId);
      this.priceList = this.priceListItemList.find(
        r => r.id === this.currentDrawing.priceListId
      );
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  validations: {
    currentDrawing: {
      drawingName: {
        required,
      },
      priceListId: {
        required,
      },
      roofType: {
        required,
      },
      assemblyTemplateId: {
        required,
      },
      status: {
        required,
      },
    },
  },
  watch: {
    roofType: {
      handler(val) {
        if (val) {
          this.selectPriceListMessage = '';
        }
      },
      deep: true,
      immediate: true,
    },
    data: {
      deep: true,
      immediate: true,
      handler(value) {
        this.currentDrawing = _.cloneDeep(value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.md .status-icon {
  margin-bottom: 16px;
}

.md .desc-icon {
  margin-bottom: 80px;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}
</style>
