<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>My Scheduling</f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <!-- web -->
    <template v-if="$device.desktop">
      <!-- header -->
      <f7-block
        strong
        class="display-flex align-items-center py-5"
      >
        <div class="border-shadow btn-container">
          <f7-button
            color="gray"
            icon-f7="arrowtriangle_left_fill"
            class="flex-center btn-container_prev"
            @click="prevWeek"
          ></f7-button>
          <f7-link
            color="black"
            class="btn-container_select"
            @click.native="selectCalendar"
          >
            {{ showWeekRange }}
          </f7-link>
          <f7-button
            color="gray"
            icon-f7="arrowtriangle_right_fill"
            class="flex-center btn-container_next"
            @click="nextWeek"
          ></f7-button>
        </div>

        <div class="border-shadow select-container margin-left">
          <f7-input
            style="width: 100%"
            type="select"
            placeholder="View"
            :value="currentView"
            @change="currentView = $event.target.value"
          >
            <option
              v-for="(view, index) in viewList"
              :key="index"
              :value="view.value"
            >
              {{ view.displayName }}
            </option>
          </f7-input>
        </div>
      </f7-block>

      <!-- calendar -->
      <div>
        <week
          :currentWeek="currentWeek"
          @openAddPopup="openAddPopup"
          :isDrag="true"
        ></week>
      </div>
    </template>

    <!-- mobile -->
    <template v-else>
      <!-- header -->
      <f7-block
        strong
        class="no-margin"
        style="min-height: 100%"
      >
        <div
          class="display-flex justify-content-space-between align-items-center margin-bottom"
        >
          <f7-segmented
            strong
            tag="p"
            class="no-margin"
            style="width: 120px"
          >
            <f7-button
              class="custom-hover"
              :active="currentView === 'day'"
              @click.native="onTabViewMobile('day')"
              >Day</f7-button
            >
            <f7-button
              class="custom-hover"
              :active="currentView === 'week'"
              @click.native="onTabViewMobile('week')"
              >Week</f7-button
            >
          </f7-segmented>
          <div
            class="display-flex align-items-center"
            style="gap: 8px"
          >
            <f7-button
              fill
              small
              @click.native="
                currentView === 'day'
                  ? openAddPopup(selectedItem)
                  : openAddPopup({ userId: selectedItem.userId })
              "
            >
              Add New Shift</f7-button
            >
            <f7-button
              v-show="isCopyMobile()"
              outline
              small
              @click.native="cancelPaste"
            >
              Cancel</f7-button
            >
          </div>
        </div>
        <div class="btn-container-mobile margin-bottom">
          <f7-button
            color="gray"
            small
            icon-size="20"
            icon-f7="arrowtriangle_left_fill"
            class="btn-container-mobile_prev"
            @click="prevWeek"
          ></f7-button>
          <f7-link
            color="gray"
            class="btn-container-mobile_select"
            @click.native="selectCalendar"
          >
            {{ showWeekRangeMobile }}
          </f7-link>
          <f7-button
            color="gray"
            small
            icon-size="20"
            icon-f7="arrowtriangle_right_fill"
            class="btn-container-mobile_next"
            @click="nextWeek"
          ></f7-button>
        </div>

        <!-- calendar -->
        <week-mobile
          ref="weekMobile"
          :currentView="currentView"
          :currentWeek="currentWeek"
          :currentEmployeeId="currentEmployeeId"
        ></week-mobile>
      </f7-block>
    </template>

    <!-- calendar input hidden -->
    <input
      class="display-none"
      type="text"
      readonly="readonly"
      id="calendar-input"
    />

    <add-new-schedule-popup
      :isShow="isShowAddPopup"
      @close="isShowAddPopup = false"
    ></add-new-schedule-popup>
  </f7-page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Week from '@/plugins/scheduling/components/views/Week.vue';
import WeekMobile from '@/plugins/scheduling/components/views/WeekMobile.vue';
import AddNewSchedulePopup from '@/plugins/scheduling/components/popups/AddNewSchedulePopup.vue';
import moment from 'moment';
import { auth } from '../../../services/firebase.service';
import Menu from '../../../components/menu/Menu.vue';
import _ from 'lodash';
import { SESSION_KEY } from '../utility/const';

function getCurrentWeek() {
  const startTime = sessionStorage.getItem(SESSION_KEY.MY_SCHEDULING);
  const weekStart = startTime
    ? moment(startTime).startOf('isoWeek')
    : moment().startOf('isoWeek');

  let days = [];
  for (let i = 0; i <= 6; i++) {
    days.push({
      date: moment(weekStart).add(i, 'days').format('MM/DD/YYYY'),
      dateHeader: moment(weekStart).add(i, 'days').format('ddd Do'),
      dateHeaderShort: moment(weekStart).add(i, 'days').format('dd DD'),
      dateFormatddd: moment(weekStart).add(i, 'days').format('ddd'),
      dateFormatDD: moment(weekStart).add(i, 'days').format('DD'),
    });
  }
  return days;
}

export default {
  components: {
    Week,
    WeekMobile,
    AddNewSchedulePopup,
    Menu,
  },
  data: () => {
    return {
      currentView: 'week',
      currentWeek: getCurrentWeek(),
      currentDate: [new Date()],
      currentEmployeeId: auth.currentUser.uid,
      isShowAddPopup: false,
    };
  },

  methods: {
    ...mapActions('scheduling/user', ['getUserList', 'resetUser']),
    ...mapActions('scheduling/scheduling', [
      'setIsMyScheduling',
      'selectItem',
      'getScheduleList',
      'resetScheduling',
    ]),
    ...mapActions('scheduling/app-constant', [
      'getConstantTypeList',
      'resetConstant',
    ]),
    ...mapActions('scheduling/album', ['resetAlbum']),
    ...mapActions('scheduling/photo', ['resetPhoto']),
    ...mapActions('scheduling/client', ['resetClient']),
    ...mapActions('scheduling/project', ['resetProject']),
    ...mapActions('scheduling/chain', ['resetChain']),
    ...mapActions('scheduling/team', ['resetTeam']),

    selectedDateInWeek() {
      return this.currentWeek
        .map(r => r.date)
        .includes(moment().format('MM/DD/YYYY'))
        ? moment().format('MM/DD/YYYY')
        : this.currentWeek[0].date;
    },

    onTabViewMobile(value) {
      this.currentView = value;
      if (value === 'day') {
        this.selectItem({
          userId: this.currentEmployeeId,
          date: this.selectedDateInWeek(),
        });
      }
      this.updateRoute();
    },

    openAddPopup(item = {}) {
      this.isShowAddPopup = true;
      this.selectItem(item);
    },

    updateRoute() {
      const currentView = this.currentView;
      const startWeek = this.currentWeek[0].date;
      const query = {
        currentView: currentView,
        startWeek: moment(startWeek, 'MM/DD/YYYY').format('MM-DD-YYYY'),
      };
      if (this.$f7route.query.currentUser) {
        query.currentUser = '';
      }
      const url = this.$f7router.generateUrl({
        name: 'my-scheduling',
        query,
      });
      this.$f7router.updateCurrentUrl(url);

      // updateUrl again to remove currentUser in query
      if (this.$f7route.query.currentUser == '') {
        const url = this.$f7router.generateUrl({
          name: 'my-scheduling',
          query: {
            ...query,
            currentUser: undefined,
          },
        });
        this.$f7router.updateCurrentUrl(url);
      }
    },

    selectCalendar() {
      this.$f7.calendar
        .create({
          inputEl: '#calendar-input',
          openIn: 'customModal',
          backdrop: true,
          cssClass: 'calendar-hover',
          value: this.currentDate,
          rangesClasses: [
            {
              cssClass: 'current-week',
              range: {
                from: new Date(this.currentWeek[0].date),
                to: new Date(this.currentWeek[6].date),
              },
            },
          ],
          on: {
            change: (calendar, value) => {
              if (
                !moment(this.currentDate[0]).isSame(moment(value[0]), 'day')
              ) {
                this.currentDate = value;
                sessionStorage.setItem(SESSION_KEY.MY_SCHEDULING, value[0]);

                const weekStart = moment(value[0], 'MM/DD/YYYY').startOf(
                  'isoWeek'
                );
                this.setCurrentWeek(weekStart);
                calendar.close();
                this.updateRoute();
              }
            },
          },
        })
        .open();
    },
    async setCurrentWeek(weekStart, isShowLoading = true) {
      let days = [];
      for (let i = 0; i <= 6; i++) {
        days.push({
          date: moment(weekStart).add(i, 'days').format('MM/DD/YYYY'),
          dateHeader: moment(weekStart).add(i, 'days').format('ddd Do'),
          dateHeaderShort: moment(weekStart).add(i, 'days').format('dd DD'),
          dateFormatddd: moment(weekStart).add(i, 'days').format('ddd'),
          dateFormatDD: moment(weekStart).add(i, 'days').format('DD'),
        });
      }
      this.currentWeek = days;
      isShowLoading && this.$f7.preloader.show();
      await this.getSchedulingByCurrentWeek();
      this.currentEmployeeId &&
        this.selectItem({
          userId: this.currentEmployeeId,
          date: this.selectedDateInWeek(),
        });
      isShowLoading && this.$f7.preloader.hide();
    },
    prevWeek() {
      const weekStart = moment(this.currentWeek[0].date, 'MM/DD/YYYY')
        .subtract(1, 'weeks')
        .startOf('isoWeek');
      this.currentDate = [new Date(weekStart)];
      this.setCurrentWeek(weekStart);
      this.updateRoute();
      sessionStorage.setItem(SESSION_KEY.MY_SCHEDULING, weekStart);
    },
    nextWeek() {
      const weekStart = moment(this.currentWeek[0].date, 'MM/DD/YYYY')
        .add(1, 'weeks')
        .startOf('isoWeek');
      this.currentDate = [new Date(weekStart)];
      this.setCurrentWeek(weekStart);
      this.updateRoute();
      sessionStorage.setItem(SESSION_KEY.MY_SCHEDULING, weekStart);
    },
    getSchedulingByCurrentWeek() {
      if (!_.isEmpty(this.currentWeek)) {
        const startDate = this.$google.firebase.firestore.Timestamp.fromDate(
          new Date(this.currentWeek[0].date)
        );
        const endDate = this.$google.firebase.firestore.Timestamp.fromDate(
          new Date(this.currentWeek[6].date)
        );
        let conditions = [
          {
            prop: 'date',
            val: startDate,
            op: '>=',
          },
          {
            prop: 'date',
            val: endDate,
            op: '<=',
          },
        ];
        if (this.isMyScheduling) {
          conditions.push({
            prop: 'userId',
            val: this.currentEmployeeId,
            op: '==',
          });
        }
        return this.getScheduleList(conditions);
      }
    },

    isCopyMobile() {
      return this.$refs.weekMobile && this.$refs.weekMobile.isCopy;
    },

    cancelPaste() {
      this.$refs.weekMobile.cancelPaste();
    },
  },

  computed: {
    ...mapGetters('scheduling/user', ['userById']),
    ...mapGetters('scheduling/app-constant', ['viewList']),
    ...mapGetters('scheduling/scheduling', ['isMyScheduling', 'selectedItem']),

    showWeekRange() {
      const from = moment(this.currentWeek[0].date, 'MM/DD/YYYY').format(
        'MMM Do'
      );
      const to = moment(this.currentWeek[6].date, 'MM/DD/YYYY').format(
        'MMM Do'
      );
      return `${from} - ${to}`;
    },

    showWeekRangeMobile() {
      return moment(this.currentWeek[0].date, 'MM/DD/YYYY').format('MMMM YYYY');
    },
  },

  mounted() {
    this.$f7.preloader.show();
    this.setIsMyScheduling(true);
    const promises = [];

    const currentView = this.$f7route.query.currentView;
    const startWeek = moment(
      this.$f7route.query.startWeek,
      'MM/DD/YYYY'
    ).startOf('isoWeek');
    if (currentView && startWeek) {
      this.currentDate = [new Date(startWeek)];
      this.currentView = currentView;
      promises.push(this.setCurrentWeek(startWeek, false));
    } else {
      promises.push(this.getSchedulingByCurrentWeek());
      setTimeout(() => {
        this.updateRoute();
      }, 100);
    }

    promises.push(this.getUserList());
    promises.push(this.getConstantTypeList());
    Promise.all(promises)
      .then(() => {
        this.currentEmployeeId &&
          this.selectItem({
            userId: this.currentEmployeeId,
            date: this.selectedDateInWeek(),
          });
      })
      .finally(() => {
        this.$f7.preloader.hide();
      });
  },

  beforeDestroy() {
    this.resetUser();
    this.resetConstant();
    this.resetScheduling();
    this.resetAlbum();
    this.resetPhoto();
    this.resetClient();
    this.resetProject();
    this.resetChain();
    this.resetTeam();
  },
};
</script>
<style lang="scss" scoped>
@import '../style/common.scss';
</style>
