import {
  firebase,
  FirebaseActions,
  StorageActions,
  firestore,
} from '../../../../services/firebase.service';
import * as types from './types';
import { PROP_IS_DELETED } from '../../../../utility/const';
import { downloadFolder } from '../../../../services/jszip.service';
import _ from 'lodash';

export default tenant => {
  const projectPhotoActions = new FirebaseActions(
    `/system_client/${tenant}/project_photo`,
    'projectPhoto'
  );

  return {
    getMoreProjectPhotoList: projectPhotoActions.getMorePage,
    getProjectPhotoListBys: projectPhotoActions.getDocumentBys,
    getPhoto: projectPhotoActions.getDocument,
    getProjectPhotosSizeBys: projectPhotoActions.getDocumentSizeBys,

    addProjectPhoto: projectPhotoActions.createDocument,
    saveProjectPhoto: projectPhotoActions.updateDocument,
    removeProjectPhoto: projectPhotoActions.deleteDocument,

    setPhotoFilterField({ commit }, { field, value }) {
      commit(types.SET_PROJECT_PHOTO_FILTER_FIELD, { field, value });
    },

    getMoreProjectPhotos({ dispatch, getters }, projectId) {
      const andConditions = [
        {
          prop: 'projectId',
          val: projectId,
          op: '==',
        },
        {
          prop: PROP_IS_DELETED,
          val: false,
          op: '==',
        },
      ];
      if (!_.isEmpty(getters.photoFilterConditions)) {
        andConditions.push(...getters.photoFilterConditions);
      }
      return dispatch('getMoreProjectPhotoList', {
        limit: 20,
        order: 'desc',
        andConditions,
      });
    },

    getTotalPhotoByProjectId({ dispatch, commit, getters }, projectId) {
      const andConditions = [
        {
          prop: 'projectId',
          val: projectId,
          op: '==',
        },
        {
          prop: PROP_IS_DELETED,
          val: false,
          op: '==',
        },
      ];
      if (!_.isEmpty(getters.photoFilterConditions)) {
        andConditions.push(...getters.photoFilterConditions);
      }
      return dispatch('getProjectPhotosSizeBys', andConditions).then(size => {
        return commit('PROJECT_PHOTOS_SIZE', size);
      });
    },

    getProjectPhotosByAlbum({ dispatch, commit, getters }, albumId) {
      const andConditions = [
        {
          prop: 'albumId',
          val: albumId,
          op: '==',
        },
        {
          prop: PROP_IS_DELETED,
          val: false,
          op: '==',
        },
      ];
      if (!_.isEmpty(getters.photoFilterConditions)) {
        andConditions.push(...getters.photoFilterConditions);
      }
      return dispatch('getProjectPhotoListBys', andConditions).then(res => {
        return commit(types.PROJECT_PHOTOS_BY_ALBUM, res);
      });
    },

    getProjectPhoto({ commit, dispatch }, id) {
      return dispatch('getPhoto', id).then(res => {
        return commit(types.PROJECT_PHOTO, res);
      });
    },

    // CRUD => update data list
    createProjectPhoto({ dispatch, commit }, data) {
      return dispatch('addProjectPhoto', data).then(id => {
        dispatch(
          'photo/project/updateTotalPhotosProject',
          {
            id: data.projectId,
            action: 'add',
          },
          { root: true }
        );
        return commit(types.PROJECT_PHOTO_LIST_ITEM_ADD, {
          ...data,
          createdAt: firebase.firestore.Timestamp.now(),
          id,
        });
      });
    },

    updateProjectPhoto({ dispatch, commit }, { id, doc }) {
      return dispatch('saveProjectPhoto', { id, doc }).then(() => {
        return commit(types.PROJECT_PHOTO_LIST_ITEM_UPDATE, {
          id,
          doc,
        });
      });
    },

    deleteProjectPhoto({ dispatch, commit }, photo) {
      return dispatch('removeProjectPhoto', photo.id).then(() => {
        dispatch(
          'photo/project/updateTotalPhotosProject',
          {
            id: photo.projectId,
            action: 'delete',
          },
          { root: true }
        );
        return commit(types.PROJECT_PHOTO_LIST_ITEM_REMOVE, photo.id);
      });
    },

    // eslint-disable-next-line no-unused-vars
    async uploadPhoto({ commit }, { file, projectId }) {
      const name = +new Date() + file.name;
      const fullPath = `project/photos/${projectId}/${name}`;
      return StorageActions.uploadFile(fullPath, file);
    },
    getProjectPhotoByTenant({ getters }, { id, tenant }) {
      const andConditions = [
        {
          prop: 'projectId',
          val: id,
          op: '==',
        },
      ];
      if (!_.isEmpty(getters.photoFilterConditions)) {
        andConditions.push(...getters.photoFilterConditions);
      }

      let collectionPath = `/system_client/${tenant}/project_photo`;
      let query = firestore.collection(collectionPath);

      andConditions.forEach(condition => {
        query = query.where(condition.prop, condition.op, condition.val);
      });

      return query.get().then(snap => {
        const docs = snap.docs.map(doc => {
          return { id: doc.id, ...doc.data() };
        });
        return docs;
      });
    },
    getAlbumPhotoByTenant({ getters }, { id, tenant }) {
      const andConditions = [
        {
          prop: 'albumId',
          val: id,
          op: '==',
        },
      ];
      if (!_.isEmpty(getters.photoFilterConditions)) {
        andConditions.push(...getters.photoFilterConditions);
      }

      let collectionPath = `/system_client/${tenant}/project_photo`;
      let query = firestore.collection(collectionPath);

      andConditions.forEach(condition => {
        query = query.where(condition.prop, condition.op, condition.val);
      });

      return query.get().then(snap => {
        const docs = snap.docs.map(doc => {
          return { id: doc.id, ...doc.data() };
        });
        return docs;
      });
    },
    // eslint-disable-next-line no-unused-vars
    uploadBase64Photo({ commit }, { base64Data, fullPath }) {
      return StorageActions.uploadFileBase64(fullPath, base64Data, {});
    },
    // eslint-disable-next-line no-unused-vars
    removePhoto({ commit }, fullPath) {
      return StorageActions.removeFile(fullPath);
    },
    // eslint-disable-next-line no-unused-vars
    downloadPhoto({ commit }, { fullPath, name, photoUrl = '' }) {
      return StorageActions.downloadFile({ fullPath, name, photoUrl });
    },

    async downloadAlbum({ dispatch }, { albumId, albumName }) {
      const photosByAlbum = await dispatch('getProjectPhotoListBys', [
        {
          prop: 'albumId',
          val: albumId,
          op: '==',
        },
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);
      return downloadFolder(
        albumName,
        photosByAlbum.map(r => ({
          name: r.photoName,
          fullPath: r.photoFullPath,
          photoUrl: r.photoUrl,
        }))
      );
    },
    // eslint-disable-next-line no-unused-vars
    async downloadAlbumByTenant({ dispatch }, { albumId, albumName, tenant }) {
      let collectionPath = `/system_client/${tenant}/project_photo`;
      let andConditions = [
        {
          prop: 'albumId',
          val: albumId,
          op: '==',
        },
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ];
      let ref = firestore.collection(collectionPath);
      andConditions.forEach(condition => {
        ref = ref.where(condition.prop, condition.op, condition.val);
      });
      let photosByAlbum = await ref.get().then(snap => {
        const docs = snap.docs.map(doc => {
          return { id: doc.id, ...doc.data() };
        });
        return docs;
      });
      return downloadFolder(
        albumName,
        photosByAlbum.map(r => ({
          name: r.photoName,
          fullPath: r.photoFullPath,
          photoUrl: r.photoUrl,
        }))
      );
    },

    resetPhotoFilter({ commit }) {
      commit(types.RESET_PHOTO_FILTER);
    },
    resetPhoto({ commit }) {
      commit(types.RESET_DATA);
    },
  };
};
