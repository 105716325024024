<template>
  <div class="margin-bottom-half">
    <div
      v-if="$device.desktop"
      class="display-flex justify-content-center"
      style="flex-wrap: wrap; gap: 8px"
    >
      <div
        class="action-button"
        v-for="(action, index) in actions"
        :key="index"
      >
        <f7-button
          raised
          fill
          @click="$emit('doAction', action)"
          >{{ action.displayName }}</f7-button
        >
      </div>
      <f7-button
        class="action-button"
        raised
        outline
        popover-open=".service-popover-share-link"
      >
        Share Link
      </f7-button>
    </div>

    <div v-else>
      <f7-block>
        <f7-row
          tag="p"
          v-for="(action, index) in actions"
          :key="index"
        >
          <f7-col tag="span">
            <f7-button
              raised
              fill
              @click="$emit('doAction', action)"
              >{{ action.displayName }}</f7-button
            >
          </f7-col>
        </f7-row>
        <f7-row tag="p">
          <f7-col>
            <f7-button
              raised
              outline
              popover-open=".service-popover-share-link"
              >Share Link</f7-button
            >
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
    <div style="height: 1px"></div>
    <f7-popover class="service-popover-share-link">
      <f7-list>
        <f7-list-item
          title="Copy link"
          link
          popover-close
          @click="copyLink"
        ></f7-list-item>
        <f7-list-item
          title="Send by email"
          link
          popover-close
          @click="sendLink(card.id)"
        ></f7-list-item>
      </f7-list>
    </f7-popover>
    <send-email-popup
      ref="sendEmailPopup"
      popup-title="Send By Email"
      templateName="link-to-job-card"
      :projectId="card && (card.id || '')"
      :attachments="card && (card.attachmentFiles || [])"
      :listEmails="emailList"
    ></send-email-popup>
  </div>
</template>

<script>
import SendEmailPopup from '@/components/popups/SendEmailPopup.vue';
import axiosService from '@/services/axios.service';
import { mapActions, mapGetters } from 'vuex';
import sendEmail from '../../mixin/sendEmail';
import _ from 'lodash';

export default {
  mixins: [sendEmail],
  components: {
    SendEmailPopup,
  },
  data() {
    return {
      actions: [
        {
          id: '1',
          displayName: 'On My way',
          value: 'on-my-way',
        },
        {
          id: '2',
          displayName: 'Job Completed',
          value: 'job-completed',
        },
        {
          id: '3',
          displayName: 'Lost Deal',
          value: 'lost-deal',
        },
        {
          id: '4',
          displayName: 'Cancel Job',
          value: 'cancel-job',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      card: 'swimlane/card/detail',
    }),
    ...mapGetters('common/common', ['emailList']),
  },

  methods: {
    ...mapActions('common/common', ['getEmailList']),
    async generateCardWebUrl() {
      const response = await axiosService.post('/tenant/get-anonymous-token', {
        entity: 'project',
        entityId: this.card.id,
      });
      if (response && response.status === 200) {
        return `${import.meta.env.VITE_HOST_DOMAIN}/service-card-view/?token=${
          response.data
        }&tenant=${btoa(this.tenantId)}&id=${this.card.id}`;
      }
      return '';
    },
    async copyLink(event) {
      const url = await this.generateCardWebUrl();
      event.preventDefault();
      const tempInput = document.createElement('input');
      tempInput.value = url;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      this.showToast('Copied!');
    },
    showToast(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000,
        })
        .open();
    },
    async sendLink(projectId) {
      if (_.isEmpty(this.emailList)) {
        this.$f7.preloader.show();
        await this.getEmailList();
        this.$f7.preloader.hide();
      }
      this.sendLinkToJobCard(projectId);
    },
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  min-width: 120px;
}
</style>
