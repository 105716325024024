import Vue from 'vue';

export default {
  setProjectPhotoList(state, payload) {
    Vue.set(state, 'projectPhotoList', payload);
  },
  setProjectPhotoSubscribe(state, payload) {
    Vue.set(state, 'projectPhotoSubscribe', payload);
  },
};
