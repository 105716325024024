import state from './state';
import actions from './action';
import mutations from './mutations';
import getters from './getter';

export default tenant => {
  return {
    namespaced: true,
    state,
    actions: actions(tenant),
    mutations,
    getters,
  };
};
