import { checkAuth } from '../../../services/authentication.service';

export default [
  {
    path: '',
    title: 'Scheduling',
    name: 'scheduling-main',
    displayRoles: ['owner', 'user'],
    routes: [
      {
        path: '/my-scheduling',
        title: 'My Scheduling',
        name: 'my-scheduling',
        parent: 'hr-main',
        displayRoles: ['owner', 'user'],
        icon: 'calendar_today',
        isShowMenuMobile: true,
        async: checkAuth(() => import('../pages/MyScheduling.vue')),
      },
      {
        path: '/employee-scheduling',
        title: 'Employee Scheduling',
        name: 'employee-scheduling',
        parent: 'hr-main',
        displayRoles: ['owner', 'user'],
        icon: 'calendar_badge_plus',
        async: checkAuth(() => import('../pages/EmployeeScheduling.vue')),
      },
    ],
  },
];
