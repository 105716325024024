import _ from 'lodash';

export default {
  expenseList: (state, getters, rootState, rootGetters) => {
    const users = rootGetters['common/user/userList'];
    const expenseList = _.cloneDeep(state.expenseList).sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return -1;
      }
      if (a.createdAt > b.createdAt) {
        return 1;
      }
      return 0;
    });

    const expenseResult = expenseList
      .map(expense => {
        const user =
          (users || []).find(user => user.uid === expense.userId) || {};
        return {
          ...expense,
          user,
        };
      })
      .sort((a, b) => {
        return a.qbBillId && !b.qbBillId ? 0 : -1;
      });
    return expenseResult;
  },

  expenseId: state => state.expenseId,
  expense: (state, getters) => {
    const expenseList = getters.expenseList;
    const expense = expenseList.find(r => r.id === state.expenseId);
    if (expense) return expense;
    if (expenseList.length === 0) return {};
    return null;
  },

  expenseById: state => id => state.expenseList.find(item => item.id === id),
};
