<template>
  <div>
    <div style="padding: 0 10px">
      <f7-segmented
        strong
        tag="p"
      >
        <f7-button
          :active="currentTab === 'invoice'"
          @click.native="currentTab = 'invoice'"
          >Invoice</f7-button
        >
        <f7-button
          :active="currentTab === 'recurring-invoice'"
          @click.native="currentTab = 'recurring-invoice'"
          >Recurring Invoice</f7-button
        >
      </f7-segmented>
    </div>
    <!-- Table invoice -->
    <data-table
      v-if="currentTab === 'invoice'"
      :headers="tableHeaders"
      :items="dataInvoiceList"
      :pageSize="(dataInvoiceList || []).length"
      class="wrap-text-table"
    >
      <template slot="card-header">
        <f7-block class="margin-top-half">
          <f7-row>
            <div>
              <strong>Total: </strong>
              <i>{{ totalPaidInvoiceAmount | currencyUSD }}</i>
            </div>
            <f7-button
              small
              fill
              v-show="isShowCreateBtn"
              @click="openCreateInvoicePopup"
              >{{ !$device.desktop ? '+' : 'Create' }} Invoice</f7-button
            >
          </f7-row>
        </f7-block>
      </template>

      <template v-slot:body="{ item }">
        <td class="label-cell">
          <f7-link @click="openInvoiceGroupDetailPopup(item)">{{
            item.invoiceNumber
          }}</f7-link>
        </td>
        <td>
          <f7-chip
            slot="after"
            :text="getStatusInvoice(item.status).displayName"
            :color="getStatusInvoice(item.status).color"
          ></f7-chip>
        </td>
        <td>{{ total(item) | currencyUSD }}</td>
        <td>{{ item.paidAmount | currencyUSD }}</td>
        <td>{{ item.invoiceDate }}</td>
        <td>{{ item.dueDate }}</td>
        <td>{{ item.paidDate }}</td>
        <td v-show="isShowActionColumn">{{ item.action }}</td>
      </template>
    </data-table>

    <!-- Table recurring invoice -->
    <data-table
      v-if="currentTab === 'recurring-invoice'"
      :headers="headerRecurringInvoice"
      :items="recurringInvoice || []"
      :pageSize="(recurringInvoice || []).length || 0"
      :isHoverRow="false"
      class="wrap-text-table margin-top"
    >
      <template v-slot:body="{ item }">
        <template>
          <td :class="{ 'p-1': !$device.desktop }">
            {{ item.recurringInvoiceNumber }}
          </td>
          <td>
            <f7-chip
              slot="after"
              :text="getStatusRecurringInvoice(item.status).displayName"
              :color="getStatusRecurringInvoice(item.status).color"
            ></f7-chip>
          </td>
          <td>
            {{ item.totalAmount | currencyUSD }}
          </td>
          <td>{{ item.startOn }}</td>
          <td>{{ item.endOn }}</td>
          <td style="text-align: center">
            <f7-link
              :popover-open="`.popover-actions_${item.id}`"
              @click.stop="() => {}"
              >View</f7-link
            >
            <f7-popover :class="`popover-actions_${item.id}`">
              <f7-list class="cursor-pointer menu-actions">
                <f7-list-item title="Invoice#"> </f7-list-item>
                <f7-list-item
                  v-for="invoice in item.invoiceList"
                  popover-close
                  :key="invoice.id"
                >
                  <div slot="title">{{ invoice.invoiceNumber }}</div>
                </f7-list-item>
              </f7-list>
            </f7-popover>
          </td>
          <td
            class="text-align-right"
            @click="openPopupNewRecurringInvoice(false, item.id)"
          >
            <f7-link>Edit</f7-link>
          </td>
        </template>
      </template>
    </data-table>
    <div>
      <new-invoice-popup
        ref="newInvoicePopup"
        :isEdit="isEdit"
        :propProjectId="projectIdJobCard"
        :isDisable="true"
        @setIsEdit="setIsEdit"
        @done="initData(projectIdJobCard)"
        @onOpenDetail="openInvoiceGroupDetailPopup"
      ></new-invoice-popup>
      <invoice-group-detail-popup
        ref="invoiceGroupDetailPopup"
        :propInvoiceSelected="invoiceSelected"
        @onClose="initData(projectIdJobCard)"
      />
      <new-recurring-invoice-popup
        ref="newRecurringInvoicePopup"
        @closePopup="initData(projectIdJobCard)"
      >
      </new-recurring-invoice-popup>
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/datatables';
import { mapActions, mapGetters } from 'vuex';
import { sortLatest } from '@/utility/date-time-tool';
import { INVOICE_STATUS_OVERDUE } from '../../../invoices/utility/const';
import methods from '../../mixin/methods';
import NewInvoicePopup from '@/plugins/invoices/components/popups/NewInvoicePopup.vue';
import invoiceMixins from '@/plugins/invoices/mixins/invoice-mixin';
import InvoiceGroupDetailPopup from '@/plugins/invoices/components/popups/InvoiceGroupDetailPopup.vue';
import commonInvoicesMixins from '../../../invoices/mixins/common-mixin';
import _ from 'lodash';
import NewRecurringInvoicePopup from '@/plugins/invoices/components/popups/NewRecurringInvoicePopup.vue';
import { convertDateUTCToLocalTimeZone } from '@/utility/datetime';

export default {
  mixins: [methods, commonInvoicesMixins, invoiceMixins],

  props: {
    isShowCreateBtn: { type: Boolean, default: true },
    isShowActionColumn: { type: Boolean, default: false },
  },
  components: {
    DataTable,
    NewInvoicePopup,
    InvoiceGroupDetailPopup,
    NewRecurringInvoicePopup,
  },

  data: () => {
    return {
      invoiceGroupDetail: null,
      invoiceSelected: null,
      isEdit: false,
      currentTab: 'invoice',
    };
  },

  computed: {
    ...mapGetters('dashboard/project', [
      'constructionProject',
      'card',
      'action',
    ]),
    ...mapGetters('dashboard/work-order', ['workOrderTask']),

    ...mapGetters('dashboard/finance', [
      'invoiceList',
      'invoiceIsPayment',
      'invoiceGroupList',
      'recurringInvoiceList',
    ]),
    ...mapGetters('dashboard/estimate', ['estimateList']),
    ...mapGetters('dashboard/app-constant', [
      'tabActive',
      'invoiceStatusList',
      'recurringInvoiceStatusList',
    ]),

    isExistEstimate() {
      return this.estimateList.length > 0;
    },

    headerRecurringInvoice() {
      return [
        {
          text: 'Invoice#',
          value: 'invoiceNumber',
          align: 'left',
        },
        {
          text: 'Status',
          value: 'status',
          align: 'left',
        },
        {
          text: 'Invoice Amount',
          value: 'totalAmount',
          align: 'left',
        },
        {
          text: 'Start On',
          value: 'startOn',
          align: 'left',
        },
        {
          text: 'End On',
          value: 'endOn',
          align: 'left',
        },
        {
          text: 'Child Invoice',
          value: 'childInvoice',
          align: 'left',
        },
        {
          text: 'Action',
          align: 'right',
          value: 'action',
        },
      ];
    },

    tableHeaders() {
      let headers = [
        {
          text: 'Invoice#',
          align: 'left',
          sortable: false,
          value: 'invoiceNumber',
        },
        {
          text: 'Status',
          align: 'left',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Invoice Amount',
          align: 'left',
          sortable: false,
          value: 'total',
        },
        {
          text: 'Paid Amount',
          align: 'left',
          sortable: false,
          value: 'paidAmount',
        },
        {
          text: 'Invoice Date',
          align: 'left',
          sortable: false,
          value: 'invoiceDate',
        },
        {
          text: 'Due Date',
          align: 'left',
          sortable: false,
          value: 'dueDate',
        },
        {
          text: 'Paid Date',
          align: 'left',
          sortable: false,
          value: 'paidDate',
        },
      ];
      if (this.isShowActionColumn) {
        headers.push({
          text: 'Action',
          align: 'left',
          sortable: false,
          value: 'action',
        });
      }
      return headers;
    },

    projectIdJobCard() {
      return this.getProjectId(this.constructionProject, this.card);
    },

    recurringInvoice() {
      if (!this.recurringInvoiceList.length) return [];

      const list = this.recurringInvoiceList.map(item => {
        return {
          ...item,
          startOn: item.startOn
            ? item.startOn._seconds
              ? this.convertDateTimeFromAlgolia(item.startOn)
              : convertDateUTCToLocalTimeZone(item.startOn.toDate())
            : '',
          endOn: item.endOn
            ? item.endOn._seconds
              ? this.convertDateTimeFromAlgolia(item.endOn)
              : convertDateUTCToLocalTimeZone(item.endOn.toDate())
            : '',
        };
      });

      return sortLatest(list);
    },

    totalPaidInvoiceAmount() {
      return this.invoiceList.reduce((acc, invoice) => {
        return invoice.status === 'in-paid' ||
          invoice.status === 'in-partial-paid'
          ? acc + invoice.paidAmount
          : acc;
      }, 0);
    },

    dataInvoiceList() {
      let list = this.invoiceList?.map(item => ({
        ...item,
        invoiceDate: item.invoiceDate
          ? convertDateUTCToLocalTimeZone(item.invoiceDate.toDate())
          : '',
        dueDate: item.dueDate
          ? convertDateUTCToLocalTimeZone(item.dueDate.toDate())
          : '',
        paidDate: item.paidDate
          ? convertDateUTCToLocalTimeZone(item.paidDate.toDate())
          : '',
        status:
          this.getOverdueDays(item) > 0 ? INVOICE_STATUS_OVERDUE : item.status,
      }));

      return sortLatest(list);
    },
  },

  methods: {
    ...mapActions('dashboard/finance', [
      'initInvoice',
      'resetInvoiceList',
      'getInvoiceGroupByProjectId',
      'getRecurringInvoiceByInvoiceGroupId',
    ]),

    async initData(projectId) {
      if (!projectId) return;
      this.$f7.preloader.show();

      await this.getInvoiceGroupByProjectId(projectId);

      if (_.isEmpty(this.invoiceGroupList)) {
        this.$f7.preloader.hide();
        return;
      }

      this.invoiceGroupDetail = _.isEmpty(this.invoiceGroupList)
        ? {}
        : this.invoiceGroupList.at(0);

      await Promise.all([
        this.initInvoice(this.invoiceGroupDetail.id),
        this.getRecurringInvoiceByInvoiceGroupId(this.invoiceGroupDetail.id),
      ]);

      this.$f7.preloader.hide();
    },
    setIsEdit(value) {
      this.isEdit = value;
    },
    openPopupNewRecurringInvoice(isNewRecurring, recurringInvoiceId = '') {
      this.$refs.newRecurringInvoicePopup.open(
        this.invoiceGroupDetail ? this.invoiceGroupDetail.id : '',
        isNewRecurring,
        recurringInvoiceId,
        this.projectIdJobCard
      );
    },

    openCreateInvoicePopup() {
      this.$refs.newInvoicePopup.open();
    },
    getStatusRecurringInvoice(status) {
      return (
        this.recurringInvoiceStatusList.find(x => x.value === status) || {}
      );
    },
    openInvoiceGroupDetailPopup(invoice) {
      if (!this.invoiceGroupDetail) return;
      this.invoiceSelected = invoice;

      this.$refs.invoiceGroupDetailPopup.open(this.invoiceGroupDetail, invoice);
    },

    gotoInvoiceDetail(invoiceNumber) {
      const projectId = this.getProjectId(this.constructionProject, this.card);
      const self = this;
      this.$emit('doClosePopup', () => {
        let url = `/dashboard/type/${
          self.$f7route.route.meta.boardType || 'swimlane'
        }/business/${self.$f7route.route.meta.businessCode}/board/${
          self.action.boards[0]
        }/action/${
          self.action.id
        }/project/${projectId}/invoice/${invoiceNumber}/?activeTab=${
          this.tabActive
        }`;

        if (self.action.boards[0] === 'construction') {
          url = `/dashboard/type/${
            self.$f7route.route.meta.boardType || 'swimlane'
          }/business/${
            self.$f7route.route.meta.businessCode
          }/board/${self.action.boards[0]}/project/${projectId}/action/${
            self.action.id
          }/task/${this.workOrderTask.id}/invoice/${invoiceNumber}/?activeTab=${
            this.tabActive
          }`;
        }

        this.$f7router.navigate(url, {
          pushState: true,
          reloadAll: true,
        });
      });
    },

    getCreateNewInvoiceRouteName(type) {
      if (type == 'FromEstimate') {
        return this.action.boards[0] === 'construction'
          ? 'new-invoice-from-estimate-swimlane-construction'
          : 'new-invoice-from-estimate-swimlane-commercial';
      } else {
        return this.action.boards[0] === 'construction'
          ? 'new-invoice-swimlane-construction'
          : 'new-invoice-swimlane-commercial';
      }
    },

    getStatusInvoice(status) {
      return this.invoiceStatusList.find(x => x.value === status) || {};
    },
  },

  watch: {
    projectIdJobCard: {
      handler(val) {
        if (val) {
          this.resetInvoiceList();
          this.initData(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.popover-create {
  width: 250px;
}
</style>
