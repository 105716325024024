import {
  FirebaseActions,
  firestore,
} from '../../../../services/firebase.service';

export default tenant => {
  const projectPhotoAlbumActions = new FirebaseActions(
    `/system_client/${tenant}/project_photo_album`,
    'projectPhotoAlbum'
  );

  return {
    bindProjectPhotoAlbumListBys: projectPhotoAlbumActions.bindCollectionBys,
    bindProjectPhotoAlbumListBy: projectPhotoAlbumActions.bindCollectionBy,
    unbindProjectPhotoAlbumList: projectPhotoAlbumActions.unbindCollection,
    getProjectPhotoAlbumListBys: projectPhotoAlbumActions.getDocumentBys,

    bindProjectPhotoAlbumItem: projectPhotoAlbumActions.bindDocument,
    unbindProjectPhotoAlbumItem: projectPhotoAlbumActions.unbindDocument,

    createProjectPhotoAlbum: projectPhotoAlbumActions.createDocument,
    updateProjectPhotoAlbum: projectPhotoAlbumActions.updateDocument,
    deleteProjectPhotoAlbum: projectPhotoAlbumActions.deleteDocument,

    getProjectPhotoAlbumByActionsId({ dispatch }, { projectId, actionId }) {
      return dispatch('getProjectPhotoAlbumListBys', [
        { prop: 'projectId', val: projectId, op: '==' },
        { prop: 'actionIds', val: actionId, op: 'array-contains' },
      ]).then(docs => docs[0] || {});
    },

    getProjectAlbumByTenant(context, { id, tenant }) {
      let collectionPath = `/system_client/${tenant}/project_photo_album`;
      return firestore
        .collection(collectionPath)
        .where('projectId', '==', id)
        .get()
        .then(snap => {
          const docs = snap.docs.map(doc => {
            return { id: doc.id, ...doc.data() };
          });
          return docs;
        });
    },
    getAlbumByTenant(context, { id, tenant }) {
      let collectionPath = `/system_client/${tenant}/project_photo_album`;
      return firestore
        .collection(collectionPath)
        .doc(id)
        .get()
        .then(snap => {
          if (snap.exists) {
            let doc = snap.data();
            doc.id = snap.id;
            return doc;
          }
        });
    },
  };
};
