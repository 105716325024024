import {
  FirebaseActions,
  StorageActions,
} from '../../../../services/firebase.service';
import { searchClient } from '../../../../services/search.service';
import algoliaService from '../../../../services/algolia.service';
import {
  COLLECTION_PRODUCT_PRODUCT_ITEMS,
  PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
  STATUS_PRODUCT_ITEM_WFA,
} from '../../../../utility/const';
import * as types from './types';
import _ from 'lodash';

export default tenant => {
  const productActions = new FirebaseActions(
    `/system_client/${tenant}/product_product_items`,
    'product'
  );

  const productItemIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  const productItemIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );
  const vendorAction = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    'vendor'
  );
  const categoryActions = new FirebaseActions(
    `/system_client/${tenant}/product_category`,
    'category'
  );
  const subCategoryActions = new FirebaseActions(
    `/system_client/${tenant}/product_sub_category`,
    'subCategory'
  );

  return {
    getVendor: vendorAction.getDocument,
    getCategory: categoryActions.getDocument,
    getSubCategory: subCategoryActions.getDocument,

    addProduct: productActions.createDocument,
    saveProduct: productActions.updateDocument,
    deleteProduct: productActions.deleteDocument,

    getProduct: productActions.getDocument,
    getProductBys: productActions.getDocumentBys,

    getProductWFTSizeBys: productActions.getDocumentSizeBys,
    setSearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },
    getTotalProductWFT({ dispatch }, priceListId) {
      return dispatch('getProductWFTSizeBys', [
        {
          prop: 'priceListRefs',
          val: priceListId,
          op: 'array-contains',
        },
        {
          prop: 'status',
          val: STATUS_PRODUCT_ITEM_WFA,
          op: '==',
        },
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);
    },

    // get data for filters
    getFilterData({ commit }, { dataType, filters }) {
      let hits = [];
      const requestOptions = {
        filters: `entityName:${COLLECTION_PRODUCT_PRODUCT_ITEMS}`,
        attributesToRetrieve: [
          'roofTypes',
          'categoryId',
          'subCategoryId',
          'vendorId',
          'status',
        ],
        batch: batch => {
          hits = hits.concat(batch);
        },
      };
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return productItemIndexAsc
        .browseObjects({
          query: '',
          ...requestOptions,
        })
        .then(() => {
          const roofTypes = _.uniqBy(
            [].concat.apply(
              [],
              hits.map(r => r.roofTypes).filter(r => r !== '?')
            )
          );
          const categoryIds = _.uniqBy(
            hits.map(r => r.categoryId).filter(r => r !== '?')
          );
          const subCategoryIds = _.uniqBy(
            hits.map(r => r.subCategoryId).filter(r => r !== '?')
          );
          const vendorIds = _.uniqBy(
            hits.map(r => r.vendorId).filter(r => r !== '?')
          );
          const status = _.uniqBy(
            hits.map(r => r.status).filter(r => r !== '?')
          );
          const result = {
            roofTypes,
            categoryIds,
            subCategoryIds,
            vendorIds,
            status,
          };
          switch (dataType) {
            case 'products-by-price-list':
              commit(types.SET_FILTER_DATA_RESULT_BY_PL, result);
              break;
            case 'products-library':
              commit(types.SET_FILTER_DATA_RESULT_BY_LIBRARY, result);
              break;
          }
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    // get all product by priceListRefs
    getProductsByPriceListRefsAndStatus(
      context,
      { priceListId, status = 'all' }
    ) {
      let hits = [];
      return productItemIndexAsc
        .browseObjects({
          query: '',
          filters: `entityName:${COLLECTION_PRODUCT_PRODUCT_ITEMS} AND priceListRefs:${priceListId}${
            status === 'all' ? '' : ` AND status:${status}`
          }`,
          attributesToRetrieve: ['id', 'price'],
          batch: batch => {
            hits = hits.concat(batch);
          },
        })
        .then(() => {
          return hits;
        });
    },

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchProductItems(
      { state, commit },
      {
        dataType,
        attributesToRetrieve,
        filters,
        restrictSearchableAttributes,
        isGetAll = false,
        pageNumber,
      }
    ) {
      let pagingState = {};
      switch (dataType) {
        case 'products-by-price-list':
          pagingState = state.productsByPL;
          break;
        case 'products-library':
          pagingState = state.productsLibrary;
          break;
      }
      const index = productItemIndexAsc;
      const requestOptions = {
        cacheable: false,
        filters: `entityName:${COLLECTION_PRODUCT_PRODUCT_ITEMS}`,
        attributesToRetrieve: PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          'productItem',
          'manufacturer',
          'sku',
          'price',
          'colorPhotos.colorName',
        ],
      };
      if (!isGetAll) {
        requestOptions.hitsPerPage = pagingState.hitsPerPage;
      }
      if (pageNumber) {
        requestOptions.page = pageNumber;
      }
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes =
          restrictSearchableAttributes;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          if (!isGetAll) {
            switch (dataType) {
              case 'products-by-price-list':
                commit(types.SET_SEARCH_RESULT_BY_PL, result);
                break;
              case 'products-library':
                commit(types.SET_SEARCH_RESULT_BY_LIBRARY, result);
                break;
            }
          }
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    goToPage(
      { state, commit },
      {
        dataType,
        pageName,
        attributesToRetrieve,
        filters,
        restrictSearchableAttributes,
      }
    ) {
      const index = productItemIndexAsc;
      let pagingState = {};
      switch (dataType) {
        case 'products-by-price-list':
          pagingState = state.productsByPL;
          break;
        case 'products-library':
          pagingState = state.productsLibrary;
          break;
      }
      let pageNumber = 0;
      switch (pageName) {
        case 'next':
          pageNumber = pagingState.page + 1;
          break;

        case 'prev':
          pageNumber = pagingState.page - 1;
          break;

        case 'first':
          pageNumber = 0;
          break;

        case 'last':
          pageNumber = pagingState.nbPages - 1;
          break;

        default:
          pageName = 0;
          break;
      }

      const requestOptions = {
        hitsPerPage: pagingState.hitsPerPage,
        page: pageNumber,
        cacheable: false,
        filters: `entityName:${COLLECTION_PRODUCT_PRODUCT_ITEMS}`,
        attributesToRetrieve: PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          'productItem',
          'manufacturer',
          'sku',
          'price',
          'colorPhotos.colorName',
        ],
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes =
          restrictSearchableAttributes;
      }

      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          switch (dataType) {
            case 'products-by-price-list':
              commit(types.SET_SEARCH_RESULT_BY_PL, result);
              break;
            case 'products-library':
              commit(types.SET_SEARCH_RESULT_BY_LIBRARY, result);
              break;
          }
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setProductNumberOfRows({ commit }, { dataType, value }) {
      switch (dataType) {
        case 'products-by-price-list':
          commit(types.SET_NUMBER_OF_ROWS_BY_PL, value);
          break;
        case 'products-library':
          commit(types.SET_NUMBER_OF_ROWS_BY_LIBRARY, value);
          break;
      }
    },

    setProductSearchOrder({ commit }, { dataType, value }) {
      switch (dataType) {
        case 'products-by-price-list':
          commit(types.SET_SEARCH_ORDER_BY_PL, value);
          break;
        case 'products-library':
          commit(types.SET_SEARCH_ORDER_BY_LIBRARY, value);
          break;
      }
    },

    resetSearch({ commit }, dataType) {
      switch (dataType) {
        case 'products-by-price-list':
          commit(types.RESET_SEARCH_BY_PL);
          break;
        case 'products-library':
          commit(types.RESET_SEARCH_BY_LIBRARY);
          break;
      }
    },

    async composeDataProduct({ dispatch }, doc) {
      let product = _.cloneDeep(doc);
      if (product.vendorId) {
        const vendor = await dispatch('getVendor', product.vendorId);
        product.vendorName = vendor ? vendor.companyName || '' : '';
      }
      if (product.categoryId) {
        const category = await dispatch('getCategory', product.categoryId);
        product.categoryName = category ? category.name || '' : '';
      }
      if (product.subCategoryId) {
        const subCategory = await dispatch(
          'getSubCategory',
          product.subCategoryId
        );
        product.subCategoryName = subCategory ? subCategory.name || '' : '';
      }
      return product;
    },

    async createProduct({ dispatch }, data) {
      const newProduct = await dispatch('composeDataProduct', data);
      const id = await dispatch('addProduct', newProduct);
      return id;
    },

    async updateProduct({ dispatch }, { id, doc }) {
      const newProduct = await dispatch('composeDataProduct', doc);
      await dispatch('saveProduct', { id, doc: newProduct });
    },

    // eslint-disable-next-line no-unused-vars
    async uploadPhoto({ commit }, fileData) {
      const base64Data = fileData.dataUrl;
      const file = fileData.info;
      const name = +new Date() + file.name || '';
      const fullPath = `product/photos/${name}`;
      return StorageActions.uploadFileBase64(fullPath, base64Data, file);
    },
    copyPhoto({ commit }, photo) {
      const toPath = `product/photos/${Date.now()}-${photo.name}`;
      return StorageActions.copyFile(photo.fullPath, toPath);
    },
    addPriceListRefs({ dispatch }, { id, priceListId }) {
      return dispatch('getProduct', id).then(product => {
        if (product) {
          const priceListRefs = _.union(product.priceListRefs || [], [
            priceListId,
          ]);
          return dispatch('updateProduct', {
            id,
            doc: { priceListRefs },
          });
        }
      });
    },

    removePriceListRefs({ dispatch }, { id, priceListId }) {
      return dispatch('getProduct', id).then(product => {
        if (product) {
          const priceListRefs = _.remove(product.priceListRefs || [], id => {
            return priceListId != id;
          });
          return dispatch('updateProduct', {
            id,
            doc: { priceListRefs },
          });
        }
      });
    },

    // update data on algolia
    async algoliaUpdateItems({ dispatch }, dateTimeChanged) {
      const itemsDeleted = await dispatch('getProductBys', [
        {
          prop: 'updatedAt',
          val: dateTimeChanged,
          op: '>=',
        },
        {
          prop: 'isDeleted',
          val: true,
          op: '==',
        },
      ]);
      const promises = [];
      if (!_.isEmpty(itemsDeleted)) {
        promises.push(
          productItemIndexDesc.deleteObjects(itemsDeleted.map(r => r.id)).wait()
        );
      }

      promises.push(
        algoliaService.algoliaUpdateItems({
          tenantId: tenant,
          collection: 'product_product_items',
          conditions: [
            {
              prop: 'updatedAt',
              val: dateTimeChanged,
              op: '>=',
            },
            {
              prop: 'isDeleted',
              val: false,
              op: '==',
            },
          ],
        })
      );
      return await Promise.all(promises);
    },

    // eslint-disable-next-line no-unused-vars
    async removePhoto({ commit }, fullPath) {
      return StorageActions.removeFile(fullPath);
    },
  };
};
