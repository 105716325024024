import { FirebaseActions } from '../../../../services/firebase.service';
import dashboardService from '../../../../services/dashboard.service';

export default tenant => {
  const dashboardActions = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    'action'
  );

  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    'project'
  );

  const contactActions = new FirebaseActions(
    `/system_client/${tenant}/operation_contact`,
    'contact'
  );
  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    'company'
  );
  const propertyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_property`,
    'property'
  );

  return {
    getActionBys: dashboardActions.getDocumentBys,

    getContact: contactActions.getDocument,
    getCompany: companyActions.getDocument,
    getProperty: propertyActions.getDocument,

    async createProject({ dispatch }, { tenantId, project }) {
      if (project.contactId) {
        const contact = await dispatch('getContact', project.contactId);
        project.contactName = contact
          ? ((contact.firstName || '') + ' ' + (contact.lastName || '')).trim()
          : '';
      }
      if (project.companyId) {
        const company = await dispatch('getCompany', project.companyId);
        project.companyName = company ? company.companyName || '' : '';
      }
      if (project.propertyId) {
        const property = await dispatch('getProperty', project.propertyId);
        project.propertyName = property ? property.propertyName || '' : '';
        project.propertyAddress =
          ((property && property.addresses) || []).find(
            r => r.code === 'main'
          ) ||
          ((property && property.addresses) || [])[0] ||
          {};
      }

      return dashboardService.addCardDoc(tenantId, project);
    },

    getProjectBys: projectActions.getDocumentBys,
    createCard: projectActions.createDocument,
    getCardInColumn({ dispatch }, columnId) {
      return dispatch('getProjectBys', [
        {
          prop: 'columnId',
          op: '==',
          val: columnId,
        },
      ]);
    },
  };
};
