/* eslint-disable no-unused-vars */
import * as types from './types';

export default {
  setDestroyFlag(
    { rootGetters, commit, dispatch, getters, rootState, state },
    payload
  ) {
    commit(types.SET_DESTROY_FLAG, payload);
  },
};
