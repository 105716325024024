import Vue from 'vue';
import * as types from './types';

export default {
  setObjectList(state, payload) {
    Vue.set(state, 'objectList', payload);
  },
  setObjectSubscribe(state, payload) {
    Vue.set(state, 'objectSubscribe', payload);
  },
  setViewport(state, { startDate, endDate }) {
    state.viewport = { ...state.viewport, startDate, endDate };
  },
  openTimerPopup(state) {
    state.showTimerPopup = true;
  },
  closeTimerPopup(state) {
    state.showTimerPopup = false;
  },
  setDetailUid(state, data) {
    state.detailUid = data;
  },
  setTeamFilter(state, value) {
    state.teamFilter = value;
  },
  setStatusFilter(state, value) {
    state.statusFilter = value;
  },
  setTimeLogTypeFilter(state, value) {
    state.timeLogTypeFilter = value;
  },
  setProjectFilter(state, value) {
    state.projectFilter = value;
  },
  setIsMyTimeLog(state, value) {
    state.isMyTimeLog = value;
  },

  [types.SET_TIME_TRACKING_LIST](state, payload) {
    Vue.set(state, 'objectList', payload);
  },

  [types.SET_CURRENT_WEEK](state, payload) {
    Vue.set(state, 'currentWeek', payload);
  },

  [types.SET_TIME_LOG_INFO_AVAILABLE](state, value) {
    Vue.set(state, 'timeLogInfoAvailable', value);
  },
};
