<template>
  <f7-view :class="`page ${dark ? 'theme-dark' : ''}`">
    <f7-page
      no-toolbar
      no-navbar
      no-swipeback
      login-screen
    >
      <!-- <form> -->
      <f7-login-screen-title>Email Verify</f7-login-screen-title>
      <f7-list>
        <f7-block-footer
          >You do not verify email yet. Please verify your
          email.</f7-block-footer
        >
      </f7-list>
      <f7-list>
        <f7-button
          block
          fill
          @click="toLoginPage"
          >Login</f7-button
        >
        <f7-list> </f7-list>
        <f7-button
          block
          fill
          @click="resendEmailVerify"
          >Re-send Email Verify</f7-button
        >
      </f7-list>
      <!-- </form> -->
    </f7-page>
  </f7-view>
</template>

<script>
import { auth } from '../services/firebase.service';
import { sendEmailVerification } from '../services/authentication.service';
export default {
  data() {
    return { dark: '' };
  },

  methods: {
    toLoginPage() {
      this.$f7router.navigate(`/login`, {
        pushState: true,
        reloadAll: true,
      });
    },

    resendEmailVerify() {
      const sefl = this;
      auth.onAuthStateChanged(user => {
        if (user) {
          sefl.$f7.preloader.show();
          sendEmailVerification(user.tenantId, user.uid)
            .then(function () {
              // Email sent.
              sefl.$f7.dialog.alert(
                'An email was sent to your inbox. Please check your inbox and verify your email by following the verifying step',
                'Email Verify.',
                () => {}
              );
            })
            .catch(error => {
              sefl.$f7.dialog.alert(error.message, 'Error', () => {});
            })
            .finally(() => {
              sefl.$f7.preloader.hide();
            });
        }
      });
    },
  },
};
</script>

<style></style>
