import {
  FirebaseActions,
  StorageActions,
  firestore,
} from '../../../../services/firebase.service';
import { TEMPLATE_TYPE_CONTRACT } from '../../../../utility/const';

export default tenant => {
  const ProposalTemplateActions = new FirebaseActions(
    `/system_client/${tenant}/proposal-template`,
    'proposalTemplate'
  );

  const emailTemplateActions = new FirebaseActions(
    `/system_client/${tenant}/email_template`,
    'emailTemplate'
  );

  const emailNotificationActions = new FirebaseActions(
    `/system_client/${tenant}/email_notification`,
    'emailNotification'
  );

  const templateActions = new FirebaseActions(
    `/system_client/${tenant}/template`,
    'template'
  );

  return {
    bindProposalTemplateList: ProposalTemplateActions.bindCollection,
    bindProposalTemplateListBy: ProposalTemplateActions.bindCollectionBy,
    unbindProposalTemplateList: ProposalTemplateActions.unbindCollection,

    bindProposalTemplate: ProposalTemplateActions.bindDocument,
    unbindProposalTemplate: ProposalTemplateActions.unbindDocument,

    createProposalTemplate: ProposalTemplateActions.createDocument,
    updateProposalTemplate: ProposalTemplateActions.updateDocument,
    deleteProposalTemplate: ProposalTemplateActions.deleteDocument,
    getProposalTemplateById: ProposalTemplateActions.getDocument,

    getProposalTemplateBys: ProposalTemplateActions.getDocumentBys,

    getEmailTemplateBys: emailTemplateActions.getDocumentBys,
    createEmailNotification: emailNotificationActions.createDocument,

    getTemplateListBys: templateActions.getDocumentBys,

    getProposalEmailTemplate({ dispatch }) {
      return dispatch('getEmailTemplateBys', [
        {
          prop: 'template',
          op: '==',
          val: 'proposal',
        },
      ]).then(list => {
        return list[0] || {};
      });
    },

    setProposalTemplateField({ commit, state }, payload) {
      if (payload.value != state.proposalTemplate[payload.fieldName]) {
        commit('SET_PROPOSAL_TEMPLATE_FIELD', payload);
      }
    },
    // eslint-disable-next-line no-unused-vars
    uploadPhoto({ commit }, { proposalId, fileData }) {
      const base64Data = fileData.dataUrl;
      const meta = fileData.info;
      const name = +new Date() + meta.name || '';
      const fullPath = `proposal/${proposalId}/${name}`;
      return StorageActions.uploadFileBase64(fullPath, base64Data, meta);
    },

    // eslint-disable-next-line no-unused-vars
    async removePhoto({ commit }, fullPath) {
      return StorageActions.removeFile(fullPath);
    },

    getProposalTemplateByTenant(context, { id, tenant }) {
      let collectionPath = `/system_client/${tenant}/proposal-template`;
      return firestore
        .collection(collectionPath)
        .doc(id)
        .get()
        .then(snap => {
          if (snap.exists) {
            let doc = snap.data();
            doc.id = snap.id;
            return doc;
          }
        });
    },

    async getContractTemplate({ dispatch, commit }) {
      const list = await dispatch('getTemplateListBys', [
        {
          prop: 'templateType',
          op: 'in',
          val: [TEMPLATE_TYPE_CONTRACT],
        },
      ]);

      commit('SET_CONTRACT_TEMPLATE', list && list[0]);
    },
  };
};
