import { checkAuth } from '../../../services/authentication.service';
// import ProposalClientViewPage from "../pages/ProposalClientViewPage.vue";

function routeList() {
  let routes = [];
  routes.push({
    path: '',
    title: 'Proposal V2',
    displayRoles: ['owner', 'user'],
    routes: [
      {
        path: '/proposal-template-v2',
        title: 'Proposal Template',
        parent: 'dashboard-main',
        name: 'proposal-template-main',
        displayRoles: ['owner', 'user'],
        icon: 'uiwindow_split_2x1',
        // eslint-disable-next-line no-unused-vars
        async: checkAuth(routeTo => {
          return import('../pages/ProposalTemplateList.vue');
        }),
        master: true,
        detailRoutes: [
          // new
          // {
          //   path: "/proposal/new",
          //   title: "New Proposal",
          //   async: checkAuth(() => import("../pages/ProposalNew.vue"))
          // },
          // from dashboard
          // {
          //   path:
          //     "/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/property/:propertyId/new-proposal",
          //   title: "New Proposal",
          //   name: "new-proposal-swimlane-commercial",
          //   async: checkAuth(() => import("../pages/ProposalNew.vue"))
          // },
          // from swimlane board
          // {
          //   path:
          //     "/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/contact/:contactId/new-proposal",
          //   title: "New Proposal",
          //   name: "new-proposal-swimlane-service",
          //   async: checkAuth(() => import("../pages/ProposalNew.vue"))
          // },
          // {
          //   path:
          //     "/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/new-proposal",
          //   title: "New Proposal",
          //   name: "new-proposal-swimlane",
          //   async: checkAuth(() => import("../pages/ProposalNew.vue"))
          // },
          // details
          {
            path: '/proposal-template-v2/:id',
            title: 'Proposal Template',
            async: checkAuth(() => import('../pages/ProposalTemplate.vue')),
          },
          // {
          //   path: "/proposal/:proposalNumber",
          //   title: "Proposal",
          //   async: checkAuth(() => import("../pages/ProposalDetail.vue"))
          // },
          // {
          //   path: "/proposal/:proposalNumber/preview",
          //   title: "Proposal",
          //   async: checkAuth(() => import("../pages/ProposalPreview.vue"))
          // },
          // {
          //   path: "/proposal/:proposalNumber/edit",
          //   title: "Proposal Template",
          //   meta: { isEditing: true },
          //   async: checkAuth(() => import("../pages/ProposalTemplate.vue"))
          // },
          // {
          //   path:
          //     "/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/proposal/:proposalNumber",
          //   title: "Proposal Details",
          //   async: checkAuth(() => import("../pages/ProposalDetail.vue"))
          // }
        ],
      },
    ],
  });
  // routes.push({
  //   path: "",
  //   title: "Proposal View",
  //   name: "proposal-view",
  //   routes: [
  //     {
  //       path: "/proposal-view/:tenantId/:proposalId",
  //       title: "proposal",
  //       name: "proposal",
  //       icon: "doc_text",
  //       component: ProposalClientViewPage
  //     }
  //   ]
  // });
  return routes;
}

export default routeList();
