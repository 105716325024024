<template>
  <li
    class="autocomplete-list-item"
    @focus="onFocus"
    @onBlur="onBlur"
  >
    <a
      href="#"
      @click.prevent="onClick"
      @mousedown="onClick"
      @focus="onFocus"
      @blur="onBlur"
      tabindex="0"
    >
      <span class="autocomplete-list-item-label"><slot /></span>
    </a>
  </li>
</template>

<script>
export default {
  name: 'auto-complete-list-item',

  props: {
    item: Object,
  },

  methods: {
    onBlur(event) {
      this.$emit('blur', event, this);
    },

    onClick(event) {
      this.$emit('click', event, this);
    },

    onFocus(event) {
      this.$emit('focus', event, this);
    },
  },
};
</script>

<style lang="scss">
.autocomplete-list-item {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  list-style: none;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  & > a {
    text-decoration: none;
    padding: 4px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #555;
    &:hover {
      text-decoration: none;
      background: rgba(0, 0, 0, 0.05);
    }
    &:focus {
      text-decoration: none;
      background: rgba(145, 29, 29, 0.05);
    }
  }
}
</style>
