<template>
  <div>
    <f7-card class="swimlane-column swimlane-column-light">
      <f7-card-header class="no-border">
        <strong>{{ action.title }}</strong>
      </f7-card-header>

      <DynamicScroller
        :ref="`actionColumn_${action.id}`"
        :items="cards"
        :min-item-size="54"
        :class="scrollerClass"
      >
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem
            :item="item"
            :active="active"
            :size-dependencies="[item.title]"
            :data-index="index"
            class="swimlane-column-scroller"
          >
            <f7-card
              class="swimlane-column-card"
              @click.native="clickCard(item.cardNumber, action.id)"
            >
              <f7-card-content class="swimlane-card-content">
                <!-- <div v-if="showState(item)" class="swimlane-card-title">
                  <f7-row tag="span">
                    <f7-col tag="span" class="swimlane-card-title_left"
                      >{{ item.title || "" }}
                    </f7-col>
                    <f7-col tag="span" class="swimlane-card-title_right">{{
                      item.status || ""
                    }}</f7-col>
                  </f7-row>
                </div> -->

                <div class="swimlane-card-title">
                  <f7-row tag="span">
                    <f7-col
                      tag="span"
                      class="swimlane-card-title_left"
                      >{{ item.title || '' }}
                    </f7-col>
                  </f7-row>
                </div>

                <div
                  class="display-flex justify-content-space-between align-items-center"
                >
                  <div>
                    <span
                      v-if="item.cardNumber"
                      class="card-number"
                      style="background-color: green"
                      >{{ item.cardNumber }}</span
                    >
                    <span
                      v-show="item.dueDate"
                      class="card-number"
                      :style="`background-color: ${dueDateColor(item.dueDate)}`"
                      >{{ toDisplayDateString(item.dueDate) || '' }}</span
                    >
                  </div>

                  <div class="display-flex">
                    <div
                      v-for="(uid, index) in item.assigneeIDs ||
                      item.assigneeIds"
                      :key="uid"
                      :class="index < 2 ? 'assignee-avatar' : ''"
                    >
                      <user-avatar
                        v-if="index < 2"
                        :avatarSize="27"
                        :imageUrl="userInfo(uid) ? userInfo(uid).photoURL : ''"
                        :name="userInfo(uid) ? userInfo(uid).displayName : ''"
                      ></user-avatar>
                    </div>
                    <div
                      v-if="
                        (item.assigneeIDs || item.assigneeIds || []).length > 2
                      "
                      class="assignee-avatar more-assignee-avatar"
                    >
                      <span>{{
                        `+${(item.assigneeIDs || item.assigneeIds).length - 2}`
                      }}</span>
                    </div>
                  </div>
                </div>
              </f7-card-content>
            </f7-card>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
      <br />
    </f7-card>
  </div>
</template>

<script>
import UserAvatar from '@/components/avatars/UserAvatar.vue';

import { setCustomComponentsTheme } from '../../../../utility/theme';
import { toDisplayDateString } from '../../../../utility/datetime';
import { mapGetters, mapState } from 'vuex';

import computedsMixins from '../../mixin/computeds';
import _ from 'lodash';

export default {
  components: {
    UserAvatar,
  },

  mixins: [computedsMixins],

  props: {
    action: { type: Object, default: () => {} },
    cards: { type: Array, default: () => [] },
  },

  computed: {
    ...mapState('dashboard/project', ['users']),
    ...mapGetters('dashboard/project', [
      'currActionIdsAfterMoveOrAdd',
      'activeUsers',
    ]),
    ...mapGetters('dashboard/project', ['boardCode']),
    scrollerClass() {
      const isDesktop = this.$device.desktop || this.$device.ipad;
      const isResidential = this.boardCode === 'residential';
      return `${isDesktop ? 'desktop' : 'mobile'}-${isResidential ? 'residential-' : ''}scroller`;
    },
    userInfo() {
      return uid =>
        this.activeUsers.find(user => {
          return user.uid === uid;
        });
    },
  },

  mounted() {
    setCustomComponentsTheme(
      this,
      'div.swimlane-column',
      'swimlane-column',
      localStorage.getItem('themeDark') === 'true' ? true : false
    );
    // scroll column after move or add project/job
    if (this.currActionIdsAfterMoveOrAdd.includes(this.action.id)) {
      this.scrollToElement(this.action.id);
    }
  },

  methods: {
    /**
     * Custom for Construction board
     */
    // showState(item) {
    //   if (
    //     !_.isEmpty(item.taskNumber) &&
    //     item.taskType === "purchase-order" &&
    //     this.action.code === 500 // Purchase Order action
    //   ) {
    //     return true;
    //   }
    //   return false;
    // },

    clickCard(cardNumber, actionId) {
      this.$emit('click', { actionId, cardNumber });
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    scrollToElement(actionId) {
      const el = this.$refs[`actionColumn_${actionId}`];

      if (!_.isEmpty(el)) {
        el.scrollToItem(0, top);
      }
    },

    // expiredDate(dueDate) {
    //   if (!dueDate) return false;
    //   return moment(dueDate.toDate()).isBefore(moment(), "day");
    // },

    // sameCurrentDate(dueDate) {
    //   if (!dueDate) return false;
    //   return moment(dueDate.toDate()).isSame(moment(), "day");
    // }
  },
  watch: {
    cards: {
      handler(val, old) {
        const listDiff =
          _.differenceWith(
            (val || []).map(r => r.id),
            (old || []).map(r => r.id),
            _.isEqual
          ) || [];
        if (
          !_.isEmpty(val) &&
          listDiff.length &&
          this.currActionIdsAfterMoveOrAdd.includes(this.action.id)
        ) {
          this.scrollToElement(this.action.id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.desktop-residential-scroller {
  max-height: calc(100vh - 218px);
}
.mobile-residential-scroller {
  max-height: calc(100vh - 312px);
}
.desktop-scroller {
  max-height: calc(100vh - 260px);
}
.mobile-scroller {
  max-height: calc(100vh - 342px);
}
.swimlane-column {
  width: 270px;
  margin-right: 0px;
}

.swimlane-column-light {
  background-color: rgba(239, 239, 244); // light
}

.swimlane-column-dark {
  background-color: rgba(23, 23, 23); //dark
}

.swimlane-column-scroller {
  padding-bottom: 1px;
}

.swimlane-column-card:hover {
  cursor: pointer;
  opacity: 0.7;
}

.swimlane-card-title {
  margin-bottom: 1em;
  // &_left {
  // font-weight: 500;
  // }

  &_right {
    color: var(--f7-theme-color);
    text-align: right;
  }
}

.swimlane-card-content {
  padding: 8px;
}

.assignee-avatar {
  margin-left: 3px;
}

.swimlane-card-duedate {
  color: var(--f7-theme-color);
  border-color: var(--f7-theme-color);
}
.card-number {
  border-radius: 4px;
  color: white;
  padding: 2px 5px;
  font-size: 12px;
  margin-right: 5px;
}
.more-assignee-avatar {
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--f7-color-chip-neutral);
  font-size: 12px;
}
</style>
