import Framework7 from 'framework7/framework7.esm.bundle.js';
import Framework7Vue from 'framework7-vue';
import Vue from 'vue';

Framework7.use(Framework7Vue);
var app = {};
app = new Vue();
const openInfoDialog = ({
  title,
  content,
  onClick,
  isCustomButton,
  hideCancelButton,
  cancelButtonCaption,
  textButton,
  customButton = [],
}) => {
  return app.$f7.dialog
    .create({
      title: `<i class="icon f7-icons size-20 color-blue">info_circle_fill</i><div>${title}</div>`,
      content: `<div class="dialog-content">${content}</div>`,
      cssClass: customButton.length > 0 ? 'auto-width-dialog' : '',
      buttons:
        customButton.length > 0
          ? customButton
          : [
              {
                text: cancelButtonCaption || 'Cancel',
                color: !isCustomButton ? 'gray' : 'orange',
                cssClass: hideCancelButton ? 'hide-button' : '',
              },
              {
                text: textButton || 'OK',
                cssClass: 'primary',
                color: !isCustomButton ? '' : 'green',
              },
            ],
      onClick,
    })
    .open();
};
const openSuccessDialog = ({
  title,
  content,
  onClick,
  hideCancelButton,
  textButton,
}) => {
  return app.$f7.dialog
    .create({
      title: `<i class="icon f7-icons size-20 color-green">checkmark_alt_circle_fill</i><div>${title}</div>`,
      content: `<div class="dialog-content">${content}</div>`,
      buttons: [
        {
          text: 'Cancel',
          color: 'gray',
          cssClass: hideCancelButton ? 'hide-button' : '',
        },
        {
          text: textButton ? textButton : 'OK',
          cssClass: 'primary',
        },
      ],
      onClick,
    })
    .open();
};
const openWarningDialog = ({
  title,
  content,
  onClick,
  hideCancelButton,
  textButton,
  textCancelButton,
  cssClass,
}) => {
  return app.$f7.dialog
    .create({
      title: `<i class="icon f7-icons size-20 color-yellow">exclamationmark_triangle_fill</i><div>${title}</div>`,
      content: `<div class="dialog-content">${content}</div>`,
      buttons: [
        {
          text: textCancelButton ? textCancelButton : 'Cancel',
          color: 'gray',
          cssClass: hideCancelButton ? 'hide-button' : '',
        },
        {
          text: textButton ? textButton : 'OK',
          cssClass: 'primary',
        },
      ],
      cssClass,
      onClick,
    })
    .open();
};
const openErrorDialog = ({
  title,
  content,
  onClick,
  hideCancelButton,
  textButton,
}) => {
  return app.$f7.dialog
    .create({
      title: `<i class="icon f7-icons size-20 color-red">xmark_circle_fill</i><div>${title}</div>`,
      content: `<div class="dialog-content">${content}</div>`,
      buttons: [
        {
          text: 'Cancel',
          color: 'gray',
          cssClass: hideCancelButton ? 'hide-button' : '',
        },
        {
          text: textButton ? textButton : 'OK',
          cssClass: 'primary',
        },
      ],
      onClick,
    })
    .open();
};

export {
  openInfoDialog,
  openSuccessDialog,
  openWarningDialog,
  openErrorDialog,
};
