/* eslint-disable no-unused-vars */
import { FirebaseActions } from '../../../../services/firebase.service';
import {
  CODE_PROPERTY_TYPE,
  CODE_JOB_TYPE,
  CODE_COMPANY_TYPE,
  CODE_PREPARATION_TASK_CHECKLIST,
  CODE_WO_TASK_TYPE,
  CODE_EXPENSE_TYPE,
  CODE_STATUS_OPTION,
  CODE_ROOF_TYPE,
  CODE_PAYMENT_MODE,
  CODE_LEAD_SOURCE,
} from '@/utility/const';
import _ from 'lodash';

import * as types from './types';

export default tenant => {
  const typeAction = new FirebaseActions(
    `/system_client/${tenant}/app_constant`,
    'type'
  );

  return {
    bindConstantTypeListBy: typeAction.bindCollectionBy,
    getConstantTypeListBys: typeAction.getDocumentBys,
    unbindConstantTypeList: typeAction.unbindCollection,
    // Use the in operator to combine up to 10 equality (==) clauses on the same field with a logical OR.
    // An in query returns documents where the given field matches any of the comparison values
    async getConstantTypeList({ state, dispatch, commit }) {
      if (_.isEmpty(state.typeList)) {
        const list = await dispatch('getConstantTypeListBys', [
          {
            prop: 'code',
            op: 'in',
            val: [
              CODE_PROPERTY_TYPE,
              CODE_JOB_TYPE,
              CODE_COMPANY_TYPE,
              CODE_PREPARATION_TASK_CHECKLIST,
              CODE_WO_TASK_TYPE,
              CODE_EXPENSE_TYPE,
              CODE_STATUS_OPTION,
              CODE_ROOF_TYPE,
              CODE_PAYMENT_MODE,
              CODE_LEAD_SOURCE,
            ],
          },
        ]);

        commit(types.SET_CONSTANT_LIST, list);
      }
    },

    setTabActive({ commit }, data) {
      commit(types.SET_TAB_ACTIVE, data);
    },
    setIsCloseArchiveBoardProjectDetailPopup({ commit }, data) {
      commit(types.SET_IS_CLOSE_ARCHIVE_BOARD_PROJECT_DETAIL_POPUP, data);
    },
    setIsOpenProjectPhotosViewPopup({ commit }, data) {
      commit(types.SET_IS_OPEN_PROJECT_PHOTOS_VIEW_POPUP, data);
    },
  };
};
