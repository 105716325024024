import * as types from './types';
import { FirebaseActions } from '../../../../services/firebase.service';
import userService from '../../../../services/user.service';
import _ from 'lodash';
import * as constant from '../../../../utility/const';

export default tenant => {
  const contactActions = new FirebaseActions(
    `/system_client/${tenant}/operation_contact`,
    'contact'
  );

  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    'company'
  );

  return {
    bindEmailList({ state, dispatch }) {
      if (_.isEmpty(state.contactList)) {
        dispatch('bindContactList');
      }
      if (_.isEmpty(state.companyList)) {
        dispatch('bindCompanyList');
      }
      if (_.isEmpty(state.users)) {
        dispatch('bindUserData');
      }
    },
    async bindUserData({ commit }) {
      userService.getUserList().then(users => {
        commit(types.SET_USERS_LIST, users);
      });
    },

    bindContactListBys: contactActions.bindCollectionBys,
    bindContactList({ dispatch }) {
      return dispatch('bindContactListBys', [
        {
          prop: constant.PROP_IS_DELETED,
          op: '!=',
          val: true,
        },
      ]);
    },
    unbindContactList: contactActions.unbindCollection,

    bindCompanyListBys: companyActions.bindCollectionBys,
    bindCompanyList({ dispatch }) {
      return dispatch('bindCompanyListBys', [
        {
          prop: constant.PROP_IS_DELETED,
          op: '!=',
          val: true,
        },
      ]);
    },
    unbindCompanyList: companyActions.unbindCollection,
    createContact: contactActions.createDocument,
    updateContact: contactActions.updateDocument,

    getContactById: contactActions.getDocument,
  };
};
