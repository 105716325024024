<template>
  <f7-fab
    position="right-bottom"
    slot="fixed"
    @click="$emit('handleClick')"
    color="default"
    :class="`delete-tooltip-${uuid}`"
  >
    <f7-icon f7="trash"></f7-icon>
    <f7-icon
      ios="f7:xmark"
      aurora="f7:xmark"
      md="material:close"
    ></f7-icon>
    <f7-fab-buttons position="top">
      <f7-fab-button
        label="Only Content"
        @click="handleDelete('photo')"
      >
        1
      </f7-fab-button>
      <f7-fab-button
        label="Only Album"
        @click="handleDelete('album')"
      >
        2
      </f7-fab-button>
      <f7-fab-button
        label="Album and Content"
        @click="handleDelete('album set')"
      >
        3
      </f7-fab-button>
    </f7-fab-buttons>
  </f7-fab>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { uuid } from 'vue-uuid';
import { TEMPLATE_TYPE } from '@/utility/const';
import _ from 'lodash';

export default {
  data: () => ({
    uuid: uuid.v4(),
  }),
  props: {
    selectedIds: Array,
  },
  mounted() {
    this.createTooltip();
  },
  methods: {
    ...mapActions('photo/photo', [
      'deleteProjectPhoto',
      'updateProjectPhoto',
      'removePhoto',
      'getProjectPhotoListBys',
    ]),
    ...mapActions('photo/album', [
      'deleteProjectPhotoAlbum',
      'getProjectPhotoAlbumListByProject',
    ]),
    createTooltip() {
      if (!this.$device.desktop) return;

      this.$f7.tooltip.create({
        targetEl: `.delete-tooltip-${this.uuid}`,
        cssClass: 'tooltip-fab-button',
        text: 'Delete album',
      });
    },

    showAlbumErrorMessage() {
      this.$ri.dialog.openErrorDialog({
        title:
          this.selectedIds.length === 1 &&
          this.selectedSystemAndLinkedAlbums.length
            ? `Can't delete album ${this.selectedSystemAndLinkedAlbums[0].name}`
            : 'Deletion failed',
        content:
          this.selectedIds.length === 1 &&
          this.selectedSystemAndLinkedAlbums[0]?.templateType ===
            TEMPLATE_TYPE.SYSTEM
            ? "Because this is the system's default album"
            : this.selectedIds.length === 1 &&
                this.selectedSystemAndLinkedAlbums.length
              ? "Because it's linked as a default in other parts of the system."
              : `Note: Can't delete albums: ${this.selectedSystemAndLinkedAlbums
                  .map(r => r.name)
                  .join(
                    ', '
                  )}. Because they are default albums/linked as a default in other parts of the system.`,
        hideCancelButton: true,
        textButton: 'Select again',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        },
      });
    },

    handleDelete(title) {
      const app = this;
      switch (title) {
        case 'photo': {
          this.$ri.dialog.openWarningDialog({
            title: 'Delete content only?',
            content: 'Album will be kept',
            textButton: 'Delete',
            onClick: async (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                this.$f7.preloader.show();
                const promises = [];
                for (const albumId of app.selectedIds) {
                  const listPhotoByAlbumId = await app.getProjectPhotoListBys([
                    {
                      prop: 'albumId',
                      val: albumId,
                      op: '==',
                    },
                  ]);
                  for (const photo of listPhotoByAlbumId) {
                    if (
                      photo.photoUrl.toLowerCase().includes('.mp4') ||
                      photo.photoUrl.toLowerCase().includes('.mov') ||
                      photo.photoUrl.toLowerCase().includes('.m4v') ||
                      photo.photoUrl.toLowerCase().includes('.webm')
                    ) {
                      if (photo.thumbnailFullPath)
                        promises.push(app.removePhoto(photo.thumbnailFullPath));
                      if (photo.photoFullPath)
                        promises.push(app.removePhoto(photo.photoFullPath));
                      promises.push(app.deleteProjectPhoto(photo));
                    } else {
                      promises.push(app.deleteProjectPhoto(photo));
                    }
                  }
                }
                Promise.all(promises).then(() => {
                  app.getProjectPhotoAlbumListByProject(this.project.id);
                  this.$f7.preloader.hide();
                  _sefl.app.dialog.close();
                  app.$emit('cancelSelect');
                });
              }
            },
          });
          break;
        }
        case 'album': {
          if (app.selectedSystemAndLinkedAlbums.length) {
            app.showAlbumErrorMessage();
            return;
          }
          this.$ri.dialog.openWarningDialog({
            title: 'Delete album only?',
            content: `Photos/videos will be kept on "Other" album`,
            textButton: 'Delete',
            onClick: async (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                this.$f7.preloader.show();
                const promises = [];
                for (const albumId of app.selectedIds) {
                  const listPhotoByAlbumId = await app.getProjectPhotoListBys([
                    {
                      prop: 'albumId',
                      val: albumId,
                      op: '==',
                    },
                  ]);
                  const otherAlbum =
                    app.projectPhotoAlbumList.find(
                      album =>
                        album.name === 'Other' &&
                        album.templateType === TEMPLATE_TYPE.SYSTEM
                    ) || {};
                  for (const photo of listPhotoByAlbumId) {
                    promises.push(
                      app.updateProjectPhoto({
                        id: photo.id,
                        doc: {
                          albumId: otherAlbum.id || null,
                        },
                      })
                    );
                  }
                  promises.push(app.deleteProjectPhotoAlbum(albumId));
                }
                Promise.all(promises).then(() => {
                  app.getProjectPhotoAlbumListByProject(this.project.id);
                  this.$f7.preloader.hide();
                  _sefl.app.dialog.close();
                  app.$emit('cancelSelect');
                });
              }
            },
          });
          break;
        }
        case 'album set': {
          if (app.selectedSystemAndLinkedAlbums.length) {
            app.showAlbumErrorMessage();
            return;
          }
          this.$ri.dialog.openWarningDialog({
            title: 'Delete album & content?',
            content: 'Album and content will be permanently deleted',
            textButton: 'Delete',
            onClick: async (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                this.$f7.preloader.show();
                const promises = [];
                for (const albumId of app.selectedIds) {
                  const listPhotoByAlbumId = await app.getProjectPhotoListBys([
                    {
                      prop: 'albumId',
                      val: albumId,
                      op: '==',
                    },
                  ]);
                  for (const photo of listPhotoByAlbumId) {
                    if (
                      photo.photoUrl.toLowerCase().includes('.mp4') ||
                      photo.photoUrl.toLowerCase().includes('.mov') ||
                      photo.photoUrl.toLowerCase().includes('.m4v') ||
                      photo.photoUrl.toLowerCase().includes('.webm')
                    ) {
                      if (photo.thumbnailFullPath)
                        promises.push(app.removePhoto(photo.thumbnailFullPath));
                      if (photo.photoFullPath)
                        promises.push(app.removePhoto(photo.photoFullPath));
                      promises.push(app.deleteProjectPhoto(photo));
                    } else {
                      promises.push(app.deleteProjectPhoto(photo));
                    }
                  }
                  promises.push(app.deleteProjectPhotoAlbum(albumId));
                }
                Promise.all(promises).then(() => {
                  app.getProjectPhotoAlbumListByProject(this.project.id);
                  this.$f7.preloader.hide();
                  _sefl.app.dialog.close();
                  app.$emit('cancelSelect');
                });
              }
            },
          });
          break;
        }
        default:
          break;
      }
    },
  },
  computed: {
    ...mapGetters('photo/photo', ['projectPhotoList']),
    ...mapGetters('photo/project', ['project']),
    ...mapGetters('photo/album', ['projectPhotoAlbumList']),

    selectedSystemAndLinkedAlbums() {
      const systemAndLinkedAlbums = this.projectPhotoAlbumList.filter(
        album =>
          this.selectedIds.includes(album.id) &&
          (album.templateType === TEMPLATE_TYPE.SYSTEM ||
            !_.isEmpty(album.linkedFroms))
      );
      return systemAndLinkedAlbums || [];
    },
  },
};
</script>
