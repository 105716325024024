// export const SET_ESTIMATE_DATA = "SET_ESTIMATE_DATA";
export const SET_ESTIMATE_FIELD = 'SET_ESTIMATE_FIELD';
export const SET_CURRENT_ESTIMATE_FIELD = 'SET_CURRENT_ESTIMATE_FIELD';
export const SET_CURRENT_BUILDING_FIELD = 'SET_CURRENT_BUILDING_FIELD';

export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';
export const SET_NUMBER_OF_ROWS = 'SET_NUMBER_OF_ROWS';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_SEARCH_ORDER = 'SET_SEARCH_ORDER';
export const RESET_SEARCH = 'RESET_SEARCH';
export const ADD_MORE_RESULT = 'ADD_MORE_RESULT';

export const SET_ESTIMATES_BY_PROJECT = 'SET_ESTIMATES_BY_PROJECT';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';

export const SET_CONTACT_FACETS = 'SET_CONTACT_FACETS';
export const SET_COMPANY_FACETS = 'SET_COMPANY_FACETS';
export const RESET_COMPANY_CONTACT_FACETS = 'RESET_COMPANY_CONTACT_FACETS';
export const SET_PARAMS_BACK = 'SET_PARAMS_BACK';
export const SET_QUERY_BACK = 'SET_QUERY_BACK';
export const SET_SELECTED_STATUS_FILTER = 'SET_SELECTED_STATUS_FILTER';

export const SET_TYPE_SWITCH_SCREEN = 'SET_TYPE_SWITCH_SCREEN';
export const SET_IS_ACTIVE_SUMMARY = 'SET_IS_ACTIVE_SUMMARY';
export const SET_CLIENT_INFO = 'SET_CLIENT_INFO';
