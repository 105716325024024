<template>
  <f7-popup
    :opened="isOpenSelectPopup"
    @popup:closed="isOpenSelectPopup = false"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left> </f7-nav-left>
        <f7-nav-title>{{ title ? title : 'Select options' }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="done()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <data-table
        :pageSize="optionList.length"
        :headers="headers"
        :checkbox="false"
        :items="optionList"
        @selected:change="select"
        class="wrap-text-table"
      >
        <template slot="card-header">
          <f7-list>
            <f7-searchbar
              :placeholder="
                searchPlaceholder ? searchPlaceholder : 'Search in items'
              "
              :clear-button="true"
              disable-button-text
              :value="searchValue"
              @input="searchValue = $event.target.value"
              @searchbar:disable="searchValue = ''"
              class="search-list-popup"
            >
            </f7-searchbar>
          </f7-list>
        </template>
        <template v-slot:body="{ item }">
          <td @click="select(item)">
            {{ item.displayName }}
          </td>
          <td
            class="numeric-cell"
            @click="select(item)"
          >
            {{ item.displayValue }}
          </td>
        </template>
      </data-table>
    </f7-page>
  </f7-popup>
</template>

<script>
import DataTable from '@/components/datatables';
import { applyFilter } from '@/utility/filter-tools';

export default {
  components: {
    DataTable,
  },
  props: {
    searchPlaceholder: String,
    title: String,
    label: String,
    value: Array,
    options: Array,
  },
  data() {
    return {
      isOpenSelectPopup: false,
      searchValue: '',
      property: '',
      type: '', // Area, Linear, Count, Volume,
      sections: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'Takeoff List',
          align: 'left',
          value: 'displayName',
        },
        {
          text: 'Measurement Value',
          align: 'right',
          value: 'displayValue',
        },
      ];
    },
    optionList() {
      let options = applyFilter(this.options || [], this.searchValue, [
        'display',
      ]).sort((a, b) => {
        return (a.display ? a.display.charAt(0).toUpperCase() : 0) <
          (b.display ? b.display.charAt(0).toUpperCase() : 0)
          ? -1
          : 0;
      });
      if (this.type) {
        options = options.filter(r => r.type.includes(this.type));
      }
      return options;
    },
  },
  methods: {
    openSelectPopup(property, type = '', sections = []) {
      this.property = property;
      this.type = type;
      this.isOpenSelectPopup = true;
      this.sections = sections;
    },
    closeSelectPopup() {
      this.isOpenSelectPopup = false;
    },
    done() {
      this.closeSelectPopup();
    },
    select(item) {
      this.$emit('changed', this.property, item.value, this.sections);
      this.closeSelectPopup();
    },
    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list-popup.searchbar input').focus();
      }
    },
  },
};
</script>

<style></style>
