import * as types from './types';
import Vue from 'vue';
import _ from 'lodash';

export default {
  [types.SET_COMPANY_TYPE_LIST](state, payload) {
    Vue.set(state, 'companyTypeList', payload);
  },

  // Fulltext search
  [types.SET_SEARCH_RESULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(state, 'hits', hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.SET_NUMBER_OF_ROWS](state, payload) {
    Vue.set(state, 'hitsPerPage', payload);
  },
  [types.SET_QUERY_FILTER](state, payload) {
    Vue.set(state, 'queryFilters', payload);
  },
  [types.SET_SEARCH_TEXT](state, payload) {
    Vue.set(state, 'companySearchText', payload);
  },

  [types.SET_SEARCH_ORDER](state, payload) {
    Vue.set(state, 'order', payload);
  },

  [types.DELETE_SEARCH_ITEM](state, payload) {
    const deletedItem = state.hits.findIndex(item => item.id === payload);
    state.hits.splice(deletedItem, 1);
  },

  [types.RESET_SEARCH](state) {
    Vue.set(state, 'order', 'desc');
    Vue.set(state, 'companySearchText', '');

    Vue.set(state, 'hits', []);
    Vue.set(state, 'hitsPerPage', 25);
    Vue.set(state, 'nbHits', 0);
    Vue.set(state, 'nbPages', 0);
    Vue.set(state, 'page', 0);
  },

  [types.ADD_MORE_RESULT](state, { hits, hitsPerPage, nbHits, nbPages, page }) {
    state.hits.push(...hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.COMPANY_LIST_ITEM_UPDATE](state, { id, doc }) {
    const index = state.hits.findIndex(item => item.id === id);

    if (index >= 0) {
      const est = _.cloneDeep(state.hits[index]);

      for (const key in doc) {
        if (Object.hasOwnProperty.call(doc, key) && doc[key] !== undefined) {
          est[key] = doc[key];
        }
      }
      Vue.set(state.hits, index, est);
    }
  },

  [types.COMPANY_LIST_ITEM_ADD](state, company) {
    state.hits.unshift(company);
  },

  [types.COMPANY_LIST_ITEM_REMOVE](state, id) {
    const index = state.hits.findIndex(item => item.id === id);
    if (index >= 0) {
      state.hits.splice(index, 1);
    }
  },
};
