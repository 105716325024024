<template>
  <div>
    <f7-popup
      :opened="isPopupOpened"
      @popup:closed="handlePopupClosed"
      @popup:opened="() => {}"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>Proposal Verbiage</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="saveAndClose">Save</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <!-- Products table -->
        <data-table
          :headers="headers"
          :items="blockListSearched"
          :pageSize="blockListSearched.length"
          :checkbox="true"
          @selected:change="handleCheckboxChecked"
        >
          <template slot="card-header">
            <f7-list>
              <f7-searchbar
                placeholder="Search in items"
                :clear-button="true"
                disable-button-text
                @input="searchtext = $event.target.value"
                @searchbar:disable="searchText = ''"
                ref="searchProductPopup"
              ></f7-searchbar>
            </f7-list>
          </template>
          <template v-slot:body="{ item }">
            <td>{{ item.name }}</td>
            <td>{{ item.partName }}</td>
            <td><div v-html="item.value"></div></td>
            <td>
              <f7-button
                large
                icon-f7="pencil"
                @click.stop="editItem(item)"
              ></f7-button>
            </td>
          </template>
        </data-table>
      </f7-page>
    </f7-popup>

    <verbiage-item-popup
      ref="verbiageItemPopup"
      @onDone="saveVerbiageItem"
    ></verbiage-item-popup>
  </div>
</template>

<script>
import DataTable from '@/components/datatables';
import VerbiageItemPopup from './VerbiageItemPopup.vue';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { applyFilter } from '@/utility/filter-tools';

export default {
  components: { DataTable, VerbiageItemPopup },
  data() {
    return {
      isPopupOpened: false,
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Part Name',
          value: 'name',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Details',
          value: 'value',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          align: 'rigth',
        },
      ],

      searchtext: '',
      blockData: [],
      blockList: [],
    };
  },

  computed: {
    ...mapGetters({
      proposal: 'proposal/proposal/proposal',
    }),

    blockListSearched() {
      return applyFilter([...this.blockList], this.searchtext, [
        'name',
        'partName',
        'value',
      ]);
    },
  },

  methods: {
    ...mapActions({ getBuilding: 'proposal/estimate/getBuilding' }),
    openPopup(estimateId, buildingId, sectionId) {
      this.buildingId = buildingId;
      this.sectionId = sectionId;
      // console.log(estimateId, buildingId);
      this.getBuilding({ estimateId, buildingId }).then(building => {
        this.building = building;

        const { blockData, blockList } = this.getVerbiageData();
        this.blockData = blockData;
        this.blockList = blockList;
        this.isPopupOpened = true;
      });
    },

    saveAndClose() {
      const self = this;
      this.$emit('onSave', {
        sectionId: this.sectionId,
        buildingId: this.buildingId,
        blockData: this.blockData,
        callback: () => {
          self.isPopupOpened = false;
        },
      });
    },

    handleCheckboxChecked(event) {
      const block = this.blockList.find(item => item.id === event.id);
      if (block) {
        block.checked = event.checked;
      }
    },

    handlePopupClosed() {
      this.isPopupOpened = false;
    },

    getVerbiageData() {
      const blockList = [];

      const section =
        (this.proposal.sections || []).find(
          item =>
            item.sectionId === this.sectionId &&
            item.buildingId === this.buildingId
        ) || {};

      const blockData = _.cloneDeep(section.blockData || []);
      blockData.forEach(item => {
        blockList.push(...item.contents);
      });

      return { blockData, blockList };
    },

    editItem(item) {
      this.$refs.verbiageItemPopup.openPopup(item);
    },

    saveVerbiageItem({ id, value, callback }) {
      const blockList = [];
      const blockData = _.cloneDeep(this.blockData || []);
      blockData.forEach(item => {
        blockList.push(...item.contents);
      });

      const block = blockList.find(item => item.id === id);

      if (block) {
        block.value = value;
      }
      this.blockList = blockList;
      this.blockData = blockData;

      callback();
    },
  },
};
</script>

<style></style>
