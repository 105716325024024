import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const propertyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_property`,
    'property'
  );

  return {
    getProperty({ commit, dispatch }, id) {
      return dispatch('getPropertyById', id).then(data => {
        commit(types.SET_PROPERTY, data);
      });
    },

    getPropertyById: propertyActions.getDocument,
  };
};
