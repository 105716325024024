import * as types from './types';
import Vue from 'vue';

export default {
  setAssemblyDrawingList(state, payload) {
    Vue.set(state, 'assemblyDrawingList', payload);
  },
  setAssemblyDrawingSubscribe(state, payload) {
    Vue.set(state, 'assemblyDrawingSubscribe', payload);
  },
  [types.SET_ASSEMBLY_DRAWING_LIST](state, payload) {
    Vue.set(state, 'assemblyDrawingList', payload);
  },
};
