import * as types from './types';
import Vue from 'vue';

export default {
  [types.SET_SEARCH_RESULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(state, 'hits', hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.RESET_SEARCH](state) {
    Vue.set(state, 'order', 'desc');
    Vue.set(state, 'searchText', '');

    Vue.set(state, 'hits', []);
    Vue.set(state, 'hitsPerPage', 25);
    Vue.set(state, 'nbHits', 0);
    Vue.set(state, 'nbPages', 0);
    Vue.set(state, 'page', 0);
  },
  [types.SET_SEARCH_TEXT](state, payload) {
    Vue.set(state, 'searchText', payload);
  },
  [types.ADD_MORE_RESULT](state, { hits, hitsPerPage, nbHits, nbPages, page }) {
    state.hits.push(...hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },
};
