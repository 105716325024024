<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="handlePopupClosed"
    @popup:opened="handlePopupOpened"
  >
    <div class="page">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Upload File Attachments</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onDone">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div
        media-list
        class="page-content"
      >
        <f7-list
          v-for="(file, index) in filesInput"
          :key="index"
          class="margin"
        >
          <f7-list-input
            type="textarea"
            :value="titles[index] = file.name.split('.')[0]"
            @input="updateTitle($event.target.value, index)"
            resizable
            placeholder="Description..."
            style="min-height: 120px; padding-top: 5px; white-space: pre-wrap"
          >
            <span slot="label">{{ file.name }}</span>
            <f7-icon
              @click.native="removeAttachment(index)"
              class="icon-clear"
              style="cursor: pointer"
              slot="content-end"
              color=""
              f7="xmark"
            ></f7-icon>
          </f7-list-input>
        </f7-list>
      </div>
    </div>
  </f7-popup>
</template>
<script lang="ts">
import { defineComponent, ref, getCurrentInstance } from 'vue';

interface FileInput {
  name: string;
}
export default defineComponent({
  props: {
    filesInput: { type: Array as () => FileInput[], default: () => [] },
  },
  setup(props, { emit }) {
    const popupOpened = ref(false);
    const titles = ref<string[]>([]);
    const instance = getCurrentInstance() as any;

    /**
     * Open the popup
     */
    const open = async () => {
      popupOpened.value = true;
    };

    /**
     * Handle popup opened
     */
    const handlePopupOpened = () => {
      titles.value = props.filesInput.map(() => '');
    };

    /**
     * Handle popup closed
     */
    const handlePopupClosed = () => {
      popupOpened.value = false;
      titles.value = [];
    };

    /**
     * Update description for specific index
     */
    const updateTitle = (value: string, index: number) => {
      titles.value[index] = value;
    };

    /**
     * Remove attachment at specific index
     */
    const removeAttachment = (indexx: number) => {
      instance.proxy.$ri.dialog.openWarningDialog({
        title: 'Remove Attachment',
        content: 'Do you want to remove this attachment?',
        onClick: (_self: any, index: number) => {
          if (index === 0) {
            _self.app.dialog.close();
          } else if (index === 1) {
            props.filesInput.splice(indexx, 1);
            titles.value.splice(indexx, 1);
          }
        },
      });
    };

    /**
     * Emit done event with files and descriptions
     */
    const onDone = () => {
      emit('done', props.filesInput, titles.value);
      popupOpened.value = false;
    };

    return {
      popupOpened,
      titles,
      open,
      handlePopupOpened,
      handlePopupClosed,
      updateTitle,
      removeAttachment,
      onDone,
    };
  },
});
</script>

<style scoped lang="scss">
.list ::v-deep ul::before,
.list ::v-deep ul::after {
  display: none;
}

.list ::v-deep ul {
  border-radius: 4px;
  border-left: solid 4px red;
}
.list ::v-deep .item-label {
  font-size: 14px;
  font-weight: bold;
  max-width: 95%;
}
.item-label ::v-deep .icon {
  right: -80%;
}
i.icon-clear {
  margin-right: 2%;
  position: absolute;
  // top: calc(100vh - 885px);
  top: calc(10px);
  right: 0%;
}
</style>
