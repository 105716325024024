<template>
  <div>
    <f7-block-header>Contact Information</f7-block-header>
    <f7-list media-list>
      <contact-name-input
        ref="selectContact"
        media-list
        :contactName="contactName"
        :isRequired="false"
        @onDeleteContact="
          setContact({}).then(() => {
            contactId = '';
          })
        "
        @onSelected="
          getContact($event).then(() => {
            contactId = $event;
          })
        "
        @onCreated="handleContactCreated"
        :autoFocus="true"
        :tabIndex="1"
      >
      </contact-name-input>

      <!-- Phone -->
      <f7-list-item
        v-for="(phone, index) in (contact || {}).phones"
        :key="`phone-${index}`"
      >
        <div slot="header">{{ phone.code }}</div>
        <div
          class="list-item-title"
          slot="title"
        >
          {{ phone.value }}
        </div>
        <input-icon
          slot="media"
          icon="phone"
        ></input-icon>
        <div slot="after-title">
          <f7-link
            class="external icon-link"
            :href="'tel:' + phone.value"
            icon-f7="phone_circle_fill"
          ></f7-link>
        </div>
      </f7-list-item>

      <!-- Email -->
      <f7-list-item
        v-for="(email, index) in (contact || {}).emails"
        :key="`email-${index}`"
      >
        <div slot="header">{{ email.code }}</div>
        <div
          class="list-item-title"
          slot="title"
        >
          {{ email.value }}
        </div>
        <input-icon
          slot="media"
          icon="envelope"
        ></input-icon>
        <div slot="after-title">
          <f7-link
            class="external icon-link"
            :href="'mailto:' + email.value"
            icon-f7="envelope_circle_fill"
          ></f7-link>
        </div>
      </f7-list-item>
    </f7-list>
    <company-name-input
      ref="selectCompany"
      media-list
      :displayCard="card"
      :defaultTypesSelected="[COMPANY_TYPE_INSURANCE]"
      :companyName="displayCompanyName"
      :queryFilters="`companyTypes:${COMPANY_TYPE_INSURANCE}`"
      :isOtherCompanyType="true"
      :isRequired="false"
      @onSelected="
        $event => {
          getInsuranceCompany($event).then(() => {
            insuranceCompanyId = $event;
          });
        }
      "
      @onCreated="handleInsuranceCompanyCreated"
      @onDeleteCompany="
        () => {
          setInsuranceCompany({});
          insuranceCompanyId = '';
        }
      "
    />
    <address-input-residential
      ref="addressInput"
      :addressesSuggestion="(contact || {}).addresses || []"
      @input="onInputAddress($event)"
      @select="onSelectAddress($event)"
    ></address-input-residential>

    <f7-list media-list>
      <f7-list-input
        label="Inspect Date"
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
          },
        }"
        :value="inspectDate"
        @calendar:change="
          inspectDate = $event;
          $f7.calendar.close();
        "
        error-message-force
        validate
        validate-on-blur
        :error-message="requireErrorMessage('inspectDate')"
      >
        <required-asterisk slot="label"></required-asterisk>
        <input-icon
          slot="media"
          icon="calendar"
        ></input-icon>
      </f7-list-input>

      <f7-list-input
        :class="
          ('inspect-time-web',
          {
            'inspect-time-ios': $device.ios,
            'inspect-time-android': $device.android,
            'inspect-time-desktop': $device.desktop || $device.ipad,
          })
        "
        label="Inspect Time"
        type="time"
        :style="inputDarkClass"
        :value="inspectTime"
        @input="inspectTime = $event.target.value"
        error-message-force
        validate
        validate-on-blur
        :error-message="requireErrorMessage('inspectTime')"
      >
        <required-asterisk slot="label"></required-asterisk>
        <input-icon
          slot="media"
          icon="clock"
        ></input-icon>
      </f7-list-input>
      <f7-list-input
        label="Lead Source"
        type="select"
        placeholder="Please choose..."
        :value="leadSource"
        @input="leadSource = $event.target.value"
        error-message-force
        validate
        validate-on-blur
        :error-message="requireErrorMessage('leadSource')"
      >
        <required-asterisk slot="label"></required-asterisk>
        <input-icon
          slot="media"
          icon="selection_pin_in_out"
        ></input-icon>
        <option
          v-for="(type, index) in leadSourceResidentialList"
          :key="index"
          :value="type.value"
        >
          {{ type.displayName }}
        </option>
      </f7-list-input>
    </f7-list>
    <!-- Note -->
    <note-editor
      :value="note"
      @onChange="saveNote($event)"
      :styleProp="`margin-top: 10px`"
    ></note-editor>
  </div>
</template>

<script>
import AddressInputResidential from '@/components/inputs/AddressInputResidential.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import InputIcon from './icon/InputIcon.vue';
import {
  BUSINESS_CODE_RESIDENTIAL,
  CONTRACT_CHECKLIST,
  LEAD_SOURCE_KNOCK_DOOR,
  COMPANY_TYPE_INSURANCE,
} from '../../../../../utility/const';

import { firebase, auth } from '../../../../../services/firebase.service';
import { toDateFirebase } from '../../../../../utility/datetime';
import { getFullAddress } from '../../../../../utility/address';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import onePageContract from '@/plugins/dashboard/mixin/onePageContract';
import axiosService from '@/services/axios.service';
import ContactNameInput from '@/components/inputs/ContactNameInput.vue';
import NoteEditor from '@/plugins/dashboard/components/note/NoteEditor.vue';
import CompanyNameInput from '@/components/inputs/CompanyNameInput.vue';

export default {
  components: {
    InputIcon,
    AddressInputResidential,
    ContactNameInput,
    NoteEditor,
    CompanyNameInput,
  },

  data: () => {
    return {
      contactId: '',
      projectAddress: {
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
      },
      leadSource: LEAD_SOURCE_KNOCK_DOOR,
      insuranceCompanyId: '',
      inspectDate: [new Date()],
      inspectTime: moment().format('HH:mm'),
      note: '',
    };
  },

  mixins: [onePageContract],

  computed: {
    ...mapGetters('photo/app-constant', ['leadSourceResidentialList']),
    ...mapGetters('photo/client', ['contact', 'insuranceCompany']),
    ...mapGetters('photo/project', ['actionByCode']),

    contactName() {
      return this.contact
        ? (
            (this.contact.firstName || '') +
            ' ' +
            (this.contact.lastName || '')
          ).trim()
        : '';
    },
    displayCompanyName() {
      return this.insuranceCompany ? this.insuranceCompany.companyName : '';
    },
    requireErrorMessage() {
      return prop => {
        if (!this.v$[prop].$error) return null;
        if (this.v$[prop].required.$invalid)
          return VALIDATION_MESSAGE.REQUIRED_FIELD;
        return null;
      };
    },
    actionLead() {
      return this.actionByCode(800) || {};
    },
    COMPANY_TYPE_INSURANCE() {
      return COMPANY_TYPE_INSURANCE;
    },
    inputDarkClass() {
      let theme =
        localStorage.getItem('themeDark') === 'true' ? 'dark' : 'light';
      return `color-scheme: ${theme};`;
    },
  },

  methods: {
    ...mapActions('photo/client', [
      'getContact',
      'setContact',
      'setInsuranceCompany',
      'getInsuranceCompany',
    ]),
    ...mapActions('photo/project', ['createNewProject']),
    ...mapActions('common/contract', ['createNewContract', 'updateContract']),
    ...mapActions('dashboard/project', ['updateCard']),
    reset() {
      this.contactId = '';
      this.projectAddress = {
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
      };
      this.leadSource = LEAD_SOURCE_KNOCK_DOOR;
      this.inspectDate = [new Date()];
      this.inspectTime = moment().format('HH:mm');
      this.note = '';
      this.v$.$reset();
      this.$refs.addressInput.clearData();
      this.$refs.addressInput.v$.$reset();
    },

    onInputAddress({ prop, value }) {
      this.projectAddress[prop] = value;
    },
    onSelectAddress(address) {
      this.projectAddress = address;
    },
    saveNote(event) {
      this.note = event.trim();
    },
    invalid() {
      this.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      if (this.v$.$invalid || this.$refs.addressInput.v$.$invalid) {
        return true;
      }
      return false;
    },
    async handleContactCreated(contactId) {
      await this.getContact(contactId);
      this.contactId = contactId;
    },
    async handleInsuranceCompanyCreated(companyId) {
      await this.getInsuranceCompany(companyId);
      this.insuranceCompanyId = companyId;
    },
    create() {
      this.$f7.preloader.show();
      let commentAndNote = [];
      if (this.note) {
        commentAndNote.push({
          code: 'note',
          title: 'Leads Note',
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        });
      }
      let actionsHistory = [];
      actionsHistory.push({
        nextAction: 'Job Created From Roofing Cam',
        resolvedAt: firebase.firestore.Timestamp.now(),
        resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
        previousAction: '',
      });
      let title = '';
      if (!_.isEmpty(this.contact)) {
        title = `${this.contactName} - `;
      }
      title += getFullAddress(this.projectAddress);
      const data = {
        businessCode: BUSINESS_CODE_RESIDENTIAL,
        title: title,
        contactId: this.contactId || null,
        insuranceCompanyId: this.insuranceCompanyId || '',
        projectAddress: this.projectAddress,
        leadSource: this.leadSource,
        inspectDate: toDateFirebase(this.inspectDate),
        inspectTime: this.inspectTime,
        assigneeIDs: [],
        commentAndNote,
        actionsHistory,
        dueDate: toDateFirebase(this.inspectDate),
        status: 'open',
        actions: [this.actionLead.id], // code 800 is column Leads
      };

      this.createNewProject(data)
        .then(async data => {
          await this.createContract(data.id, data.docNumber);
        })
        .finally(() => {
          this.reset();
          this.$emit('doClosePopup');
          this.$f7.preloader.hide();
        });
    },

    async createContract(projectId, docNumber) {
      const contractSections = this.parseContractContent(true);
      const contract = {
        contractChecklist: CONTRACT_CHECKLIST,
        contractTitle: `Roofing Build Contract - ${getFullAddress(
          this.projectAddress
        )}`,
        status: 'draft',
        project: {
          id: projectId,
          cardNumber: !_.isEmpty(docNumber) ? docNumber : '',
          title: getFullAddress(this.projectAddress) || '',
          businessCode: BUSINESS_CODE_RESIDENTIAL,
        },
        projectId: projectId,
        sections: contractSections,
      };
      if (!_.isEmpty(this.contact)) {
        contract.contactId = this.contact.id;
        contract.contactName =
          `${this.contact.firstName} ${this.contact.lastName}`.trim() || '';
        contract.project.title = `${contract.contactName} - ${contract.project.title}`;
      }
      const newContract = await this.createNewContract(contract);

      // update contractWebUrl for newContract
      const url = await this.generateContractWebUrl(projectId, newContract.id);
      await this.updateContract({
        id: newContract.id,
        doc: {
          contractWebUrl: url,
        },
      });

      // save contractId, contractNumber for project
      await this.updateCard({
        id: projectId,
        doc: {
          contractId: newContract.id,
          contractNumber: newContract.docNumber,
          contractTitle: newContract.contractTitle,
        },
      });
    },
    async generateContractWebUrl(projectId, contractId) {
      const projectResponse = await axiosService.post(
        '/tenant/get-anonymous-token',
        {
          entity: 'project',
          entityId: projectId,
        }
      );
      const contractResponse = await axiosService.post(
        '/tenant/get-anonymous-token',
        {
          entity: 'contract',
          entityId: contractId,
        }
      );
      if (
        projectResponse &&
        projectResponse.status === 200 &&
        contractResponse &&
        contractResponse.status === 200
      ) {
        return `${import.meta.env.VITE_HOST_DOMAIN}/contract-view/?projectToken=${projectResponse.data}&projectId=${projectId}&contractToken=${contractResponse.data}&contractId=${contractId}`;
      }
      return '';
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      inspectDate: {
        required,
      },
      inspectTime: {
        required,
      },
      leadSource: {
        required,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}

.inspect-time-desktop ::v-deep input[type='time'] {
  width: 100px;
}

.inspect-time-android ::v-deep input[type='time'] {
  width: 100%;
}

.inspect-time-ios ::v-deep input[type='time']::-webkit-date-and-time-value {
  text-align: left;
  width: 100%;
}

.inspect-time-web
  ::v-deep
  input[type='time']::-webkit-calendar-picker-indicator {
  filter: var(--f7-color-text-neutral);
}
</style>
