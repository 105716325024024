<template>
  <div
    class="data-table data-table-init card table-fixed-container"
    :style="!$device.desktop ? '' : 'margin-bottom: 80px;'"
  >
    <div class="card-header">
      <div
        class="data-table-header"
        text-color="primary"
      >
        <div class="data-table-title">
          {{
            checkedItemCount === 0
              ? tableTitle
              : selectedMessage(checkedItemCount)
          }}
        </div>

        <div class="data-table-actions">
          <f7-button
            v-show="editedItemCount !== 0"
            @click="onCancel"
            outline
            >Cancel</f7-button
          >
          <f7-button
            v-show="editedItemCount !== 0"
            @click="mapQBCustomerToProject"
            fill
            >Map/Remap</f7-button
          >
        </div>
      </div>
    </div>

    <div
      class="card-content card-content_mine"
      @scroll="handleScroll"
    >
      <table>
        <thead>
          <tr>
            <th class="label-cell">
              <span class="table-head-label">Project Number</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Project Number"
                  @input="searchProjectNumber = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span class="table-head-label">Project Name</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Project"
                  @input="searchProjectTitle = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span class="table-head-label">Project Type</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Project Type"
                  @input="searchProjectType = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span class="table-head-label">Property Name</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Property Name"
                  @input="searchPropertyName = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span class="table-head-label">QB Customer/Project</span>
            </th>
          </tr>
        </thead>

        <tbody style="overflow: hidden">
          <tr
            v-for="project in projectFiltered"
            :key="project.Id"
            :class="`${project.checked ? 'selected-cell' : ''}`"
          >
            <td class="label-cell">
              {{ project.cardNumber }}
            </td>

            <td class="label-cell">
              {{ project.title }}
            </td>

            <td class="label-cell">
              {{ project.businessCode }}
            </td>

            <td class="label-cell">
              {{ project.propertyName }}
            </td>

            <td>
              <auto-complete-input
                :id="project.id"
                :data="customerList"
                :value="
                  (project.customerRef || project.qbCustomerRef || {}).name
                "
                @selected="selectedCustomer(project, $event.target.value)"
              ></auto-complete-input>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import AutoCompleteInput from '../input/AutoCompleteInput.vue';

export default {
  components: { AutoCompleteInput },

  props: {
    tableTitle: String,
    actionTitle: String,
  },

  data() {
    return {
      masterCheckbox: false,
      editedItemCount: 0,

      searchProjectTitle: '',
      searchProjectType: '',
      searchPropertyName: '',
      searchProjectNumber: '',

      reloadOnCancel: false,
      checkedItemCount: 0,
      initialSortedList: [],
    };
  },

  computed: {
    ...mapGetters('quickbooks/common', ['qbCustomerList']),
    ...mapGetters('quickbooks/project', ['projectList']),

    customerList() {
      return this.qbCustomerList.map(item => {
        return {
          id: item.Id,
          name: item.DisplayName,
        };
      });
    },
    projectFiltered() {
      if (this.initialSortedList.length === 0 || !this.reloadOnCancel) {
        return this.filterAndSortProjects();
      }
      return this.initialSortedList.filter(project => {
        return (
          ((project.title.toLowerCase() || '').includes(
            this.searchProjectTitle.toLowerCase()
          ) ||
            this.searchProjectTitle === '') &&
          ((project.businessCode || '')
            .toLowerCase()
            .includes(this.searchProjectType.toLowerCase()) ||
            this.searchProjectType === '') &&
          ((project.propertyName || '')
            .toLowerCase()
            .includes(this.searchPropertyName.toLowerCase()) ||
            this.searchPropertyName === '') &&
          ((project.cardNumber || '')
            .toLowerCase()
            .includes(this.searchProjectNumber.toLowerCase()) ||
            this.searchProjectNumber === '')
        );
      });
    },

    selectedMessage() {
      return counter => {
        if (counter === 1) {
          return '1 item selected';
        } else {
          return `${counter} items selected`;
        }
      };
    },
  },

  methods: {
    ...mapActions('quickbooks/project', [
      'updateProject',
      'getProjects',
      'unbindProjectList',
    ]),
    handleScroll() {
      const activeElement = document.activeElement;
      if (activeElement && activeElement.tagName === 'INPUT') {
        activeElement.blur();
      }
    },
    filterAndSortProjects() {
      const filteredData = this.projectList.filter(project => {
        return (
          ((project.title.toLowerCase() || '').includes(
            this.searchProjectTitle.toLowerCase()
          ) ||
            this.searchProjectTitle === '') &&
          ((project.businessCode || '')
            .toLowerCase()
            .includes(this.searchProjectType.toLowerCase()) ||
            this.searchProjectType === '') &&
          ((project.propertyName || '')
            .toLowerCase()
            .includes(this.searchPropertyName.toLowerCase()) ||
            this.searchPropertyName === '') &&
          ((project.cardNumber || '')
            .toLowerCase()
            .includes(this.searchProjectNumber.toLowerCase()) ||
            this.searchProjectNumber === '')
        );
      });

      filteredData.sort((a, b) => {
        if (a.qbCustomerRef && !b.qbCustomerRef) {
          return 1;
        }
        if (!a.qbCustomerRef && b.qbCustomerRef) {
          return -1;
        }
        return 0;
      });

      // Store the sorted list in initialSortedList only if it was empty initially
      if (this.initialSortedList.length === 0) {
        this.initialSortedList = filteredData;
      }

      return filteredData;
    },
    onEdit(item) {
      const customerRef = _.cloneDeep(item.qbCustomerRef);
      this.$emit('dataItemChange', 'customerRef', customerRef, item.id);
      this.$emit('dataItemChange', 'qbCustomerRef', null, item.id);
      this.$emit('dataItemChange', 'edited', true, item.id);
      this.reloadOnCancel = true;
      Vue.set(item, 'edited', true);
      this.editedItemCount = this.projectFiltered.filter(
        item => item.edited === true
      ).length;
    },

    onCancel() {
      if (this.reloadOnCancel) {
        this.reloadProjects();
      } else {
        for (let i = 0; i < this.projectFiltered.length; i++) {
          let project = this.projectFiltered[i];
          if (project.edited) {
            this.$emit('dataItemChange', 'customerRef', null, project.id);
            this.$emit('dataItemChange', 'edited', false, project.id);
            this.$emit('dataItemChange', 'checked', false, project.id);

            let foundItem = _.cloneDeep(project);
            Vue.set(foundItem, 'edited', false);
            Vue.set(foundItem, 'checked', false);
            Vue.set(foundItem, 'customerRef', null);

            // Update the original item in projectFiltered
            Vue.set(this.projectFiltered, i, foundItem);
          }
        }

        this.reloadOnCancel = false;
        this.editedItemCount = 0;
      }
    },

    selectedCustomer(item, selected) {
      this.$emit('dataItemChange', 'customerRef', selected, item.id);
      this.$emit('dataItemChange', 'edited', true, item.id);
      this.$emit('dataItemChange', 'checked', true, item.id);

      Vue.set(item, 'edited', true);
      Vue.set(item, 'checked', true);
      Vue.set(item, 'customerRef', selected);
      this.editedItemCount = this.projectFiltered.filter(
        item => item.edited === true
      ).length;
    },

    mapQBCustomerToProject() {
      let projects = this.projectFiltered.filter(
        item => item.edited === true || item.checked === true
      );
      let refs = [];
      for (const project of projects) {
        refs.push(
          this.updateProject({
            id: project.id,
            doc: { qbCustomerRef: project.customerRef },
          })
        );
      }

      this.$f7.preloader.show();
      Promise.all(refs)
        .then(() => {
          const self = this;
          this.$ri.dialog.openSuccessDialog({
            title: 'Mapping Complete.',
            content: 'Map Project to Quickbooks Customer successfully!',
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                self.$emit('onDone');
              }
            },
          });
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },
    handleRemap(project) {
      let refs = [];

      refs.push(
        this.updateProject({
          id: project.id,
          doc: { qbCustomerRef: project.customerRef },
        })
      );

      this.$f7.preloader.show();
      Promise.all(refs)
        .then(() => {
          const self = this;
          this.$ri.dialog.openSuccessDialog({
            title: 'Mapping Complete.',
            content: 'Map Project to Quickbooks Customer successfully!',
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                self.$emit('onDone');
              }
            },
          });
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },
    reloadProjects() {
      this.unbindProjectList();
      this.$f7.preloader.show();
      this.getProjects()
        .then(() => {
          this.editedItemCount = 0;
          this.reloadOnCancel = false;
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },
    checkBoxChange(checked, id) {
      let item = this.projectFiltered.find(
        item => item.Id === id || item.id === id
      );
      Vue.set(item, 'checked', checked);
      this.checkedItemCount = this.projectFiltered.filter(
        item => item.checked === true
      ).length;
    },
    checkBoxAllChange(checked) {
      this.projectFiltered.map(item => {
        Vue.set(item, 'checked', checked);
      });
      this.checkedItemCount = this.projectFiltered.filter(
        item => item.checked === true
      ).length;
    },
    exportProject() {
      this.projectFiltered.map(item => {
        Vue.set(item, 'customerRef', item.qbCustomerRef);
      });
      this.$nextTick(() => {
        this.mapQBCustomerToProject();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-content_mine {
  min-height: calc(100vh - 300px);
  overflow-y: auto;
}
.selected-cell {
  color: var(--f7-theme-color);
}
.table-fixed-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 166px);
  overflow: hidden;
}
thead th {
  position: sticky;
  top: 0;
  background-color: var(--f7-color-bg-4-neutral);
  z-index: 20;
}
.table-head-label {
  color: var(--f7-color-text-neutral);
}
thead th::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: var(--f7-color-bg-select-neutral) 1px solid;
}
</style>
