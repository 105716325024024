import Vue from 'vue';

export default {
  setCustomTableList(state, payload) {
    Vue.set(state, 'customTableList', payload);
  },
  setCustomTableSubscribe(state, payload) {
    Vue.set(state, 'customTableSubscribe', payload);
  },
};
