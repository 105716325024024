import Vue from 'vue';

export default {
  setContactInfoList(state, payload) {
    Vue.set(state, 'contactInfoList', payload);
  },
  setContactInfoSubscribe(state, payload) {
    Vue.set(state, 'contactInfoSubscribe', payload);
  },
};
