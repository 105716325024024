import {
  FirebaseActions,
  StorageActions,
} from '../../../../services/firebase.service';

export default tenant => {
  const projectPhotoActions = new FirebaseActions(
    `/system_client/${tenant}/project_photo`,
    'projectPhoto'
  );

  return {
    bindProjectPhotoListBy: projectPhotoActions.bindCollectionBy,
    unbindProjectPhotoList: projectPhotoActions.unbindCollection,

    createProjectPhoto: projectPhotoActions.createDocument,
    updateProjectPhoto: projectPhotoActions.updateDocument,
    deleteProjectPhoto: projectPhotoActions.deleteDocument,
    bindPhoto: projectPhotoActions.bindDocument,
    // eslint-disable-next-line no-unused-vars
    async uploadPhoto({ commit }, { file, projectId }) {
      const name = +new Date() + file.name;
      const fullPath = `project/photos/${projectId}/${name}`;
      return StorageActions.uploadFile(fullPath, file);
    },
    // // eslint-disable-next-line no-unused-vars
    // uploadPhoto({ commit }, fileData) {
    //   const base64Data = fileData.dataUrl;
    //   const meta = fileData.info;
    //   const name = +new Date() + meta.name || "";
    //   const fullPath = `project/photos/${name}`;
    //   return StorageActions.uploadFileBase64(fullPath, base64Data, meta);
    // },
    // eslint-disable-next-line no-unused-vars
    copyPhoto({ commit }, photo) {
      const toPath = `invoice/${photo.photoFullPath}-${Date.now()}`;
      return StorageActions.copyFile(photo.photoFullPath, toPath);
    },
    // eslint-disable-next-line no-unused-vars
    uploadBase64Photo({ commit }, { base64Data, fullPath }) {
      return StorageActions.uploadFileBase64(fullPath, base64Data, {});
    },
    // eslint-disable-next-line no-unused-vars
    async removePhoto({ commit }, fullPath) {
      return StorageActions.removeFile(fullPath);
    },
  };
};
