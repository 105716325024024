<template>
  <div class="popup-scroll-bar">
    <div>
      <component
        ref="currentAction"
        v-bind:is="taskDetailComponentName"
        @doResolve="doResolve"
        v-on="$listeners"
      >
        <template v-slot:response>
          <component
            ref="responseButton"
            v-bind:is="taskResponseComponentName"
            :workOrderTask="workOrderTask"
            :actionCodes="actionCodes"
            :response="response"
            @doAction="doAction"
          ></component>
        </template>
      </component>
    </div>
  </div>
</template>

<script>
import PurchaseOrderWoTask from '../wo-tasks/PurchaseOrderWoTask.vue';
import PreparationWoTask from '../wo-tasks/PreparationWoTask.vue';
import QaqcWoTask from '../wo-tasks/QaqcWoTask.vue';
import GeneralWoTask from '../wo-tasks/GeneralWoTask.vue';

import PreparationWoResponse from '../wo-tasks/PreparationWoResponse.vue';
import PurchaseOrderWoResponse from '../wo-tasks/PurchaseOrderWoResponse.vue';
import QaqcWoResponse from '../wo-tasks/QaqcWoResponse.vue';
import GeneralWoResponse from '../wo-tasks/GeneralWoResponse.vue';

import { mapState, mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  components: {
    // Actions components
    PurchaseOrderWoTask,
    GeneralWoTask,
    PreparationWoTask,
    QaqcWoTask,

    PreparationWoResponse,
    PurchaseOrderWoResponse,
    QaqcWoResponse,
    GeneralWoResponse,
  },

  data: () => {
    return {
      activeButton: 'action-required',
    };
  },

  props: { afterResolve: Function },

  computed: {
    ...mapState('dashboard/project', ['users']),
    ...mapGetters('dashboard/project', ['filterAction']),

    ...mapGetters('dashboard/work-order', [
      'workOrder',
      'workOrderTask',
      'workOrderAction',
      'response',
    ]),

    ...mapState('dashboard/client', ['property']),
    ...mapGetters('dashboard/project', { project: 'constructionProject' }),
    ...mapGetters('dashboard/project', ['constructionActionCodeById']),

    userInfo() {
      return uid =>
        this.users.find(user => {
          return user.uid === uid;
        });
    },

    taskDetailComponentName() {
      switch (this.workOrderTask.taskType) {
        case 'preparation':
          return 'PreparationWoTask';
        case 'qa-qc':
          return 'QaqcWoTask';
        case 'purchase-order':
          return 'PurchaseOrderWoTask';
        case 'general':
          return 'GeneralWoTask';
      }

      return '';
    },

    taskResponseComponentName() {
      switch (this.workOrderTask.taskType) {
        case 'preparation':
          return 'PreparationWoResponse';
        case 'qa-qc':
          return 'QaqcWoResponse';
        case 'purchase-order':
          return 'PurchaseOrderWoResponse';
        case 'general':
          return 'GeneralWoResponse';
      }

      return '';
    },

    actionCodes() {
      return this.constructionActionCodeById(this.workOrderTask.actions || []);
    },
  },

  methods: {
    ...mapActions('dashboard/work-order', ['updateWorkOrderTask']),
    ...mapActions('dashboard/project', ['setCurrentActionIdsAfterMoveOrAdd']),

    doAction(response) {
      this.$refs.currentAction.doAction(response);
    },

    doResolve(response) {
      if (!_.isEmpty(response)) {
        // Calculate next actions
        let nextActionIds = this.calculateNextActions(response);

        // Assign the next action
        this.updateWorkOrderTask({
          id: this.workOrderTask.id,
          doc: {
            status: response.nextState,
            actions: nextActionIds, //response.nextActions,
            // actionsHistory: actionsHistory
          },
        });
      }

      // Close the popup
      this.afterResolve();
      // this.clearCurrentCard();
    },

    calculateNextActions(response) {
      let currActionId = this.workOrderAction.id;

      let currProjectActionIds = this.workOrderTask.actions;
      let nextResActionIds = response.nextActions;

      // remove current action id from project's actions
      if (currProjectActionIds.includes(currActionId)) {
        currProjectActionIds = currProjectActionIds.filter(
          id => id !== currActionId
        );
      }

      // include new action Ids
      currProjectActionIds = currProjectActionIds.concat(nextResActionIds);

      //  remove duplicates
      currProjectActionIds = currProjectActionIds.filter(
        (value, index, self) => {
          return self.indexOf(value) === index;
        }
      );

      // save actions after moving the card to scroll top columns of those actions
      this.setCurrentActionIdsAfterMoveOrAdd(currProjectActionIds);

      return currProjectActionIds;
    },

    clearActionData() {
      if (!_.isEmpty(this.$refs.currentAction)) {
        this.$refs.currentAction.clearData();
      }
    },

    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    },

    showMainContent() {
      if (this.$refs.currentAction) {
        this.$refs.currentAction.initData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-card-title {
  font-size: 16px;
}
</style>
