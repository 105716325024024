<template>
  <f7-page v-show="isExistedEstimate">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          v-show="!$device.desktop"
          @click.native="onClickBack"
          icon-f7="chevron_left"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ estimate.estimateNumber }} Summary</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>

    <new-proposal-button></new-proposal-button>

    <div>
      <div v-if="estimate.businessCode !== 'service'">
        <f7-block-title>Summary By Area</f7-block-title>
        <data-table
          :headers="headers(summaryEstimateByArea.headers)"
          :items="summaryEstimateByArea.data"
          :pageSize="summaryEstimateByArea.data.length"
        >
          <template v-slot:body="{ item }">
            <td
              v-for="(h, index) in headers(summaryEstimateByArea.headers)"
              :key="index"
              class="text-align-left"
            >
              <span
                v-if="h.field === 'name' || h.field === 'numberOfCopy'"
                :class="{ bold: item.isBold }"
                >{{ item[h.field] }}</span
              >
              <span
                :class="{ bold: item.isBold }"
                v-else-if="item.isCost"
                >{{ item[h.field] | currencyUSD }}</span
              >
              <span
                :class="{ bold: item.isBold }"
                v-else
                >{{ item[h.field] }}</span
              >
            </td>
          </template>
        </data-table>
      </div>

      <div
        :style="`margin: ${
          estimate.businessCode !== 'service' ? '2rem' : '0 0 2rem'
        } 0`"
      >
        <f7-block-title>Summary By Roof Type</f7-block-title>
        <data-table
          :headers="headers(summaryEstimateByRoofType.headers)"
          :items="summaryEstimateByRoofType.data"
          :pageSize="summaryEstimateByRoofType.data.length"
        >
          <template v-slot:body="{ item }">
            <td
              v-for="(h, index) in headers(summaryEstimateByRoofType.headers)"
              :key="index"
              class="text-align-left"
            >
              <span
                v-if="h.field === 'name' || h.field === 'numberOfCopy'"
                :class="{ bold: item.isBold }"
                >{{ item[h.field] }}</span
              >
              <span
                :class="{ bold: item.isBold }"
                v-else-if="h.field === 'profitMargin' && item.name !== 'Total'"
              >
                {{ item[h.field] | currencyUSD }}
                <i style="color: grey">{{
                  ` (${(item.crossProfitMarginRatio || 0).toFixed(3)}%)`
                }}</i>
              </span>
              <span
                :class="{ bold: item.isBold }"
                v-else
                >{{ item[h.field] | currencyUSD }}</span
              >
            </td>
          </template>
        </data-table>
      </div>

      <div>
        <f7-block-title>Summary By Cost Type</f7-block-title>
        <data-table
          :headers="headers(summaryEstimateByCostType.headers)"
          :items="summaryEstimateByCostType.data"
          :pageSize="summaryEstimateByCostType.data.length"
        >
          <template v-slot:body="{ item }">
            <td
              v-for="(h, index) in headers(summaryEstimateByCostType.headers)"
              :key="index"
              class="text-align-left"
            >
              <span
                v-if="h.field === 'name' || h.field === 'numberOfCopy'"
                :class="{ bold: item.isBold }"
                >{{ item[h.field] }}</span
              >
              <span
                :class="{ bold: item.isBold }"
                v-else-if="
                  h.field === 'totals' ||
                  h.field === 'totalCost' ||
                  h.field == 'taxAmount' ||
                  item.name == 'Total'
                "
                >{{ item[h.field] | currencyUSD }}</span
              >
              <span
                :class="{ bold: item.isBold }"
                v-else-if="h.field === 'profitMargin' && item.name !== 'Total'"
              >
                {{ item[h.field] | currencyUSD }}
                <i style="color: grey">{{
                  ` (${(item.crossProfitMarginRatio || 0).toFixed(3)}%)`
                }}</i>
              </span>
              <span
                :class="{ bold: item.isBold }"
                v-else
                >{{ item[h.field] | currencyUSD }}
                <i style="color: grey">{{
                  ` (${(item[h.field + '-percent'] || 0).toFixed(2)}%)`
                }}</i>
              </span>
            </td>
          </template>
        </data-table>
      </div>
    </div>
  </f7-page>
</template>

<script>
import DataTable from '@/components/datatables';
import NewProposalButton from '../components/buttons/NewProposalButton.vue';

import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';

export default {
  components: {
    DataTable,
    NewProposalButton,
  },

  created() {
    const params = this.$f7route.params;
    if (params.estimateNumber && !params.clientType && !params.clientId) {
      this.setCurrentView('estimate-list');
    } else {
      this.setCurrentView('client');
    }
    this.setEstIdLink(params.estimateNumber);

    // Bind current estimate by Number
    const estimate = this.estimateByNumber(params.estimateNumber);

    if (!_.isEmpty(estimate)) {
      this.initEstimate(estimate);
    } else {
      this.getEstimateBys([
        {
          prop: 'estimateNumber',
          val: params.estimateNumber,
          op: '==',
        },
      ]).then(docs => {
        this.initEstimate(docs[0]);
      });
    }
  },

  computed: {
    ...mapGetters('estimate/estimate-page/estimate', [
      'estimate',
      'estimateByNumber',
      'summaryEstimateByArea',
      'summaryEstimateByRoofType',
      'summaryEstimateByCostType',
      'hits',
    ]),
    ...mapGetters('estimate/estimate-page/common', ['currentView']),

    headers() {
      return headers => {
        return headers.map(x => ({
          field: x.value,
          text: x.displayName,
          align: 'left',
          sortable: false,
          value: x.value,
        }));
      };
    },
    isExistedEstimate() {
      return this.hits.some(r => r.id === this.estimate.id);
    },
  },

  methods: {
    ...mapActions('estimate/estimate-page/estimate', [
      'bindEstimate',
      'getEstimateBys',
      'bindBuildingList',
    ]),
    ...mapActions('estimate/estimate-page/project', ['getProject']),
    ...mapMutations('estimate/estimate-page/estimate', ['setEstIdLink']),
    ...mapActions('estimate/estimate-page/common', ['setCurrentView']),

    onClickBack() {
      if (this.currentView === 'estimate-list') {
        this.$f7router.navigate(`/estimate/${this.estimate.estimateNumber}`);
      } else {
        this.$f7router.navigate(
          `/estimate/${this.$f7route.params.clientType}/${this.$f7route.params.clientId}/${this.estimate.estimateNumber}`
        );
      }
    },

    initEstimate(estimate) {
      let refs = [];
      refs.push(this.bindEstimate(estimate.id));
      refs.push(this.bindBuildingList({ estimateId: estimate.id }));
      if (estimate.projectId) {
        refs.push(this.getProject(estimate.projectId));
      }

      this.$f7.preloader.show();
      return Promise.all(refs).then(() => {
        this.$f7.preloader.hide();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
::v-deep table {
  table-layout: fixed !important;
}
::v-deep table thead tr th {
  width: 150px;
}
::v-deep table thead tr th:last-child {
  text-align: right !important;
}
::v-deep table tbody tr td:last-child {
  text-align: right !important;
}
::v-deep table thead tr th:first-child {
  width: 200px;
}
::v-deep table thead tr th:last-child {
  width: 120px;
}
</style>
