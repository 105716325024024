<template>
  <f7-list class="property-input">
    <f7-list-input
      link
      :value="propertyValue"
      :clear-button="true"
      @input="
        setPropertySearchText($event.target.value);
        onSearch();
        propertyValue = $event.target.value;
      "
      @focus="handleFocus"
      @blur="blurAction"
      @input:clear="onDeleteProperty"
      placeholder="Input property name"
      :error-message="errorMessage"
      error-message-force
      :tabindex="tabIndex"
      autocomplete="new-password"
    >
      <div
        class="list-item-inner-start"
        slot="inner-start"
      >
        Property Name<required-asterisk></required-asterisk>
      </div>
      <input-icon
        slot="media"
        icon="globe"
      ></input-icon>
    </f7-list-input>
    <f7-list-item v-if="isAddNew">
      <add-new-property-button @onCreated="$emit('onCreated', $event)" />
    </f7-list-item>

    <div
      class="dropdown-content"
      @scroll="onScroll"
      v-show="isShowDropdown"
    >
      <div class="page-content">
        <f7-list class="no-margin">
          <f7-list-group
            v-for="(group, index) in propertyGroup"
            :key="index"
          >
            <f7-list-item
              :title="group.key"
              group-title
            ></f7-list-item>
            <f7-list-item
              no-chevron
              link
              v-for="(property, index) in group.data"
              :key="index"
              :title="property.propertyName"
              @click.native="select(property.id)"
            ></f7-list-item>
          </f7-list-group>
        </f7-list>
        <div class="text-align-center margin-vertical-half">
          <f7-preloader v-show="hasMoreItems"></f7-preloader>
        </div>
        <f7-block
          class="text-align-center"
          v-show="!hasData"
          >No Data</f7-block
        >
      </div>
    </div>
  </f7-list>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InputIcon from '../icons/InputIcon.vue';
import _ from 'lodash';
import AddNewPropertyButton from '@/components/buttons/AddNewPropertyButton.vue';

export default {
  components: {
    InputIcon,
    AddNewPropertyButton,
  },

  props: {
    errorMessage: {
      type: String,
      default: '',
    },

    displayCard: {
      type: Object,
      default: () => {},
    },

    propertyName: {
      type: String,
      default: '',
    },

    companyId: { type: String, default: '' },

    queryFilters: String,

    isHiddenAddNew: { type: Boolean, default: false },

    autoFocus: { type: Boolean, default: false },

    tabIndex: { type: Number, default: 0 },

    isAddNew: { type: Boolean, default: true },
  },

  data() {
    return {
      isFocus: false,

      allowInfinite: true,

      hasMoreItems: true,

      hasData: true,

      onlyAddNew: false,

      popupOpened: false,

      isNew: false,

      isNewSelected: false,

      initQueryFilter: '',
      propertyValue: '',
    };
  },

  computed: {
    ...mapGetters('common/property', [
      'propertyGroup',
      'hits',
      'nbPages',
      'page',
      'searchText',
    ]),

    isShowDropdown() {
      return this.isFocus && this.propertyGroup.length;
    },
  },

  mounted() {
    if (this.queryFilters) {
      this.setQueryFilters(this.queryFilters);
    }
  },

  watch: {
    propertyName() {
      this.propertyValue = this.propertyName;
    },

    searchText: {
      handler: _.debounce(function () {
        this.handleSearch();
      }, 500),
      immediate: true,
    },

    companyId: {
      handler(newCompanyId) {
        if (!newCompanyId) {
          this.handleSearch();
        }
      },
    },
  },

  methods: {
    ...mapActions('common/company', ['getCompany']),
    ...mapActions('common/property', [
      'searchProperty',
      'loadMoreProperty',
      'createProperty',
      'resetSearch',
      'getPropertyListBys',
      'setPropertySearchText',
      'setQueryFilters',
    ]),

    async autoFillProperty() {
      const company = this.companyId
        ? await this.getCompany(this.companyId)
        : null;
      if (company && !_.isEmpty(company.propertyRefs)) {
        try {
          this.setQueryFilters(this.queryFilters);
          await this.searchProperty({});
          if (!_.isEmpty(this.hits)) {
            this.select(this.hits[0].id);
          } else {
            this.select('');

            // if company have not property, show all property
            this.handleSearch();
          }
        } catch (e) {
          this.select('');
        }
      } else {
        this.select('');
      }
    },

    handleSearch() {
      const self = this;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      return this.searchProperty({}).then(() => {
        if (self.page + 1 === self.nbPages) {
          self.allowInfinite = false;
        }
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.allowInfinite = false;
        }
        self.hasMoreItems = false;
      });
    },

    open() {
      this.isFocus = true;
      this.onSearch('', true);
    },

    onSearch: _.debounce(function () {
      return this.handleSearch();
    }, 300),

    handleFocus() {
      setTimeout(() => {
        this.isFocus = true;
      }, 500);
    },

    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.loadMore();
      }
    },

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreProperty({
        page: this.page + 1,
      }).then(() => {
        self.hasMoreItems = false;

        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },

    blurAction() {
      setTimeout(() => {
        if (!this.isNewSelected) this.setPropertySearchText(this.propertyName);

        if (!this.searchValue) this.setPropertySearchText(this.propertyName);

        if (!this.displayCard.propertyId) this.setPropertySearchText('');

        this.isFocus = false;

        this.isNewSelected = false;
      }, 500);
    },

    select(propertyId) {
      if (propertyId) {
        this.$emit('onSelected', propertyId);
        this.isNewSelected = true;
        this.close();
      }
    },

    close() {
      this.popupOpened = false;

      this.allowInfinite = true;
      this.hasMoreItems = false;
      this.hasData = true;
    },

    onDeleteProperty(event) {
      this.$emit('onDeleteProperty', event);
    },

    onAdd() {
      this.isNew = true;
      this.$nextTick(() => {
        if (this.$device.desktop) {
          this.$el.querySelector('.first-input input').focus();
        }
      });
    },
  },
};
</script>

<style scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}

.icon {
  margin-top: 2px;
}

.contact-input {
  position: relative;
}

.dropdown-content {
  position: absolute;
  width: 85%;
  max-height: 400px;
  min-width: 200px;
  max-width: 600px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1000;
  top: 50px;
  left: 40px;
  z-index: 999;
  background: black;
}

.page-content {
  padding-top: 0;
}
</style>
