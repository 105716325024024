import Vue from 'vue';

export default {
  setCategoryList(state, payload) {
    Vue.set(state, 'categoryList', payload);
  },
  setCategorySubscribe(state, payload) {
    Vue.set(state, 'categorySubscribe', payload);
  },
};
