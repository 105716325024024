import * as types from './types';
import Vue from 'vue';

export default {
  setContactList(state, payload) {
    Vue.set(state, 'contactList', payload);
  },

  setContactSubscribe(state, payload) {
    Vue.set(state, 'contactSubscribe', payload);
  },

  setCompanyList(state, payload) {
    Vue.set(state, 'companyList', payload);
  },

  setCompanySubscribe(state, payload) {
    Vue.set(state, 'companySubscribe', payload);
  },

  setPropertyList(state, payload) {
    Vue.set(state, 'propertyList', payload);
  },

  setPropertySubscribe(state, payload) {
    Vue.set(state, 'propertySubscribe', payload);
  },

  [types.SET_CONTACT_LIST](state, payload) {
    Vue.set(state, 'contactList', payload);
  },

  [types.SET_COMPANY_LIST](state, payload) {
    Vue.set(state, 'companyList', payload);
  },

  [types.SET_PROPERTY_LIST](state, payload) {
    Vue.set(state, 'propertyList', payload);
  },

  [types.SET_CONTACT](state, payload) {
    Vue.set(state, 'contact', payload);
  },

  [types.SET_COMPANY](state, payload) {
    Vue.set(state, 'company', payload);
  },

  [types.SET_PROPERTY](state, payload) {
    Vue.set(state, 'property', payload);
  },

  [types.SET_INSURANCE_COMPANY](state, payload) {
    Vue.set(state, 'insuranceCompany', payload);
  },

  [types.PUSH_CONTACT](state, payload) {
    state.contactList.push(payload);
  },
};
