import * as types from './types';
import { FirebaseActions } from '../../services/firebase.service';
import * as constant from '../../utility/const';
import { searchClient } from '../../services/search.service';
import algoliaService from '../../services/algolia.service';
import _ from 'lodash';

export default tenant => {
  const contactActions = new FirebaseActions(
    `/system_client/${tenant}/operation_contact`,
    'contact'
  );

  const contactIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );
  return {
    getContact: contactActions.getDocument,
    addNewContact: contactActions.createDocument,
    saveContact: contactActions.updateDocument,
    getContactBys: contactActions.getDocumentBys,

    // get contact email list
    getContactEmails() {
      let hits = [];
      return contactIndexAsc
        .browseObjects({
          query: '',
          filters: `entityName:${constant.COLLECTION_OPRATION_CONTACT} AND NOT emailValues:?`,
          attributesToRetrieve: ['emailValues'],
          batch: batch => {
            hits = hits.concat(batch);
          },
        })
        .then(() => {
          hits = hits.reduce((old, current) => {
            const currentMapped = (
              (Array.isArray(current.emailValues) && current.emailValues) ||
              []
            ).map(r => r);
            return old.concat(currentMapped);
          }, []);
          hits = _.uniq(hits);
          return hits;
        });
    },
    setQueryFilters({ commit }, payload) {
      commit(types.SET_QUERY_FILTER, payload);
    },
    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchContact(
      { state, commit, getters },
      { attributesToRetrieve, restrictSearchableAttributes }
    ) {
      const index = contactIndexAsc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_OPRATION_CONTACT}`,
        attributesToRetrieve:
          constant.OPERATION_CONTACT_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: ['contactName', 'phoneValues'],
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (restrictSearchableAttributes) {
        requestOptions.restrictSearchableAttributes =
          restrictSearchableAttributes;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.contactSearchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreContact(
      { state, commit, getters },
      { attributesToRetrieve, restrictSearchableAttributes, page }
    ) {
      const index = contactIndexAsc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_OPRATION_CONTACT}`,
        attributesToRetrieve:
          constant.OPERATION_CONTACT_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: ['contactName'],
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (restrictSearchableAttributes) {
        requestOptions.restrictSearchableAttributes =
          restrictSearchableAttributes;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.contactSearchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setContactNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },

    setContactSearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },

    setContactSearchOrder({ commit }, payload) {
      commit(types.SET_SEARCH_ORDER, payload);
    },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    createNewContact({ dispatch, commit }, contact) {
      return dispatch('addNewContact', contact).then(id => {
        commit(types.CONTACT_LIST_ITEM_ADD, {
          ...contact,
          id,
        });

        return id;
      });
    },

    updateContact({ dispatch, commit }, { id, doc }) {
      return dispatch('saveContact', { id, doc }).then(() => {
        commit(types.CONTACT_LIST_ITEM_UPDATE, {
          id,
          doc,
        });

        return id;
      });
    },

    deleteContact({ dispatch, commit }, id) {
      return dispatch('saveContact', {
        id,
        doc: { [constant.PROP_IS_DELETED]: true },
      }).then(() => {
        return commit(types.CONTACT_LIST_ITEM_REMOVE, id);
      });
    },

    async createContact({ dispatch }, contact) {
      const id = await dispatch('addNewContact', contact);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: 'operation_contact',
        id,
      });
      return id;
    },

    async getContactListBys({ commit, dispatch }, payload) {
      const contactList = await dispatch('getContactBys', payload);
      commit(types.SET_CONTACT_LIST, contactList || []);
      return contactList;
    },
  };
};
