<template>
  <div>
    <invoice-overview></invoice-overview>
    <search-pagination-table
      :data="searchedList"
      :showTitle="false"
      :showSearchBar="true"
      class="search-fixed-container"
    >
      <template v-slot:search>
        <div class="search-fixed-header">
          <div
            class="display-flex justify-content-space-between align-items-center invoice-table-title"
          >
            <f7-searchbar
              style="flex: 1"
              placeholder="Search by Project/Job Name, Project/Job number, Client Name,..."
              :clear-button="true"
              disable-button-text
              @input="
                setInvoiceGroupSearchText($event.target.value.trim());
                onSearch();
              "
              @searchbar:clear="
                setInvoiceGroupSearchText('');
                onSearch();
              "
              @searchbar:disable="
                setInvoiceGroupSearchText('');
                onSearch();
              "
            >
            </f7-searchbar>
            <f7-button
              fill
              color="primary"
              popover-open=".popover-create-invoice"
              style="margin-right: 10px; display: flex"
            >
              <f7-icon
                f7="plus"
                size="20"
              ></f7-icon>
              <span v-if="$device.desktop">Create New Invoice</span>
            </f7-button>
            <f7-popover class="popover-create-invoice">
              <f7-list class="cursor-pointer">
                <f7-list-item
                  link
                  popover-close
                  title="Default Invoice"
                  @click="openCreateInvoicePage"
                >
                </f7-list-item>
                <f7-list-item
                  link
                  popover-close
                  title="Recurring Invoice"
                  @click="openCreateRecurringInvoicePage"
                >
                </f7-list-item>
              </f7-list>
            </f7-popover>
          </div>
        </div>
      </template>

      <template v-slot:header>
        <th
          v-for="(header, index) in headers"
          :key="index"
          :class="
            header.align ? `text-align-${header.align}` : 'text-align-left'
          "
        >
          <div style="align-items: center; gap: 4px">
            <span>{{ header.text }}</span>
            <f7-icon
              v-if="header.tooltip"
              f7="info_circle_fill"
              size="14"
              class="cursor-pointer"
              :class="header.tooltip.id"
            ></f7-icon>
          </div>
        </th>
      </template>

      <template v-slot:body="{ item }">
        <td
          :class="{
            'p-1': !$device.desktop,
          }"
        >
          <a
            href="javascript:void(0)"
            @click="openInvoiceGroupDetailPage(item)"
            >{{ item.jobName }}</a
          >
        </td>
        <td>
          {{ item.projectNumber }}
        </td>
        <td>{{ item.clientName }}</td>
        <td class="text-align-right">
          {{ item.totalInvoiceAmount | currencyUSD }}
        </td>
        <td class="text-align-right">
          {{ item.totalPaidInvoiceAmount | currencyUSD }}
        </td>
        <td class="text-align-right">
          {{ convertDateTime(item.lastPaidDate) }}
        </td>
        <td class="text-align-right">
          {{
            item.totalInvoiceAmount
              ? item.totalInvoiceAmount - (item.totalPaidInvoiceAmount || 0)
              : '' | currencyUSD
          }}
        </td>
        <td class="text-align-right">
          {{ item.unbilledAmount > 0 ? item.unbilledAmount : 0 | currencyUSD }}
        </td>
        <td class="text-align-right">
          {{ item.overdueAmount | currencyUSD }}
        </td>
        <!-- <td>{{ item.check }}</td> -->
      </template>

      <template v-slot:footer>
        <div
          class="data-table-footer"
          :class="$device.desktop ? '' : 'mobile-container'"
        >
          <div class="data-table-rows-select">
            Per page:
            <div class="input input-dropdown">
              <select
                @input="onChangeLimit($event.target.value.trim())"
                :value="hitsPerPage"
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
              </select>
            </div>
          </div>
          <div
            class="data-table-pagination"
            :style="$device.desktop ? '' : 'margin-left:-20px'"
          >
            <span
              v-if="page === 0"
              class="display-flex align-items-center margin-left color-gray"
              disabled
              ><f7-icon f7="chevron_left"></f7-icon>First</span
            >
            <a
              v-else
              @click="ongoToPage('first')"
              class="display-flex align-items-center margin-left"
              disabled
              ><f7-icon f7="chevron_left"></f7-icon>First</a
            >

            <span
              v-if="page === 0"
              class="margin-left color-gray"
              >Previous</span
            >
            <a
              v-else
              @click="ongoToPage('prev')"
              class="margin-left"
              >Previous</a
            >

            <span class="data-table-pagination-label margin-left">{{
              pagingMessage
            }}</span>

            <a
              v-if="page < nbPages - 1"
              @click="ongoToPage('next')"
              class="margin-left"
              >Next</a
            >
            <span
              v-else
              class="margin-left color-gray"
              >Next</span
            >

            <a
              v-if="page < nbPages - 1"
              @click="ongoToPage('last')"
              class="display-flex align-items-center margin-left"
              >Last<f7-icon f7="chevron_right"></f7-icon
            ></a>
            <span
              v-else
              class="display-flex align-items-center margin-left color-gray"
              >Last<f7-icon f7="chevron_right"></f7-icon
            ></span>
          </div>
        </div>
      </template>
    </search-pagination-table>

    <!-- popup -->
    <new-invoice-popup
      ref="newInvoicePopup"
      :isEdit="isEdit"
      @setIsEdit="setIsEdit"
      @onClose="onSearch"
    ></new-invoice-popup>
    <!-- popup -->
    <!-- <new-invoice-from-estimate-popup
      ref="newInvoiceFromEstimatePopup"
      :isEdit="isEdit"
    ></new-invoice-from-estimate-popup> -->
    <send-email-popup ref="sendEmailPopup"></send-email-popup>
  </div>
</template>

<script>
import SearchPaginationTable from '@/components/datatables/SearchPaginationTable.vue';
import InvoiceOverview from '@/components/overview/InvoiceOverview.vue';
import NewInvoicePopup from '../popups/NewInvoicePopup.vue';
import SendEmailPopup from '../popups/SendEmailPopup.vue';
import { mapActions, mapGetters } from 'vuex';
import { sortLatest } from '@/utility/date-time-tool';
import { comparationSort } from '@/utility/filter-tools';
import invoiceMixins from '../../mixins/invoice-mixin';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    SearchPaginationTable,
    NewInvoicePopup,
    SendEmailPopup,
    InvoiceOverview,
  },

  mixins: [invoiceMixins],

  data() {
    return {
      isEdit: false,
      gridFilter: {
        invoiceNumber: '',
        status: '',
        clientName: '',
        projectNumber: '',
        propertyName: '',
      },
      selectedStatuses: [],
      project: {},
    };
  },

  created() {
    if (_.isEmpty(this.invoiceStatusList)) {
      this.bindConstantTypeList();
    }
    !this.objectList?.length && this.getCategories();
  },

  mounted() {
    this.createTooltip();
  },

  computed: {
    ...mapGetters('invoices/invoice-group', [
      'searchText',
      'hits',
      'hitsPerPage',
      'nbHits',
      'nbPages',
      'page',
      'invoiceGroup',
      'selectedStatusFilter',
    ]),
    ...mapGetters('invoices/app-constant', ['invoiceStatusList']),

    ...mapGetters('invoices/category', ['objectList']),
    ...mapGetters('invoices/invoices', ['invoice', 'invoiceList']),
    searchedList() {
      return this.hits;
      // let list = this.hits.map(item => ({
      //   ...item,
      //   invoiceDate: item.invoiceDate
      //     ? item.invoiceDate._seconds
      //       ? this.convertDateTimeFromAlgolia(item.invoiceDate)
      //       : moment(item.invoiceDate).format("MM/DD/YYYY")
      //     : "",
      //   paidDate: item.paidDate
      //     ? item.paidDate._seconds
      //       ? this.convertDateTimeFromAlgolia(item.paidDate)
      //       : moment(item.paidDate).format("MM/DD/YYYY")
      //     : "",
      //   dueDate: item.dueDate
      //     ? item.dueDate._seconds
      //       ? this.convertDateTimeFromAlgolia(item.dueDate)
      //       : moment(item.dueDate).format("MM/DD/YYYY")
      //     : "",
      //   status:
      //     this.getOverdueDays(item, true) > 0
      //       ? INVOICE_STATUS_OVERDUE
      //       : item.status
      // }));
      // // if (this.listStatus.length === 0) {
      // return list;
      // // } else {
      // // return list.filter(item => this.listStatus.includes(item.status));
      // // }
    },

    headers() {
      let self = this;
      return [
        {
          text: 'Project/Job Name',
          align: 'left',
          sortable: false,
          value: 'invoiceNumber',
          filter: {
            type: 'text',
            placeholder: 'Invoice Number',
            value: self.gridFilter.invoiceNumber,
            onChange: value => {
              self.gridFilter.invoiceNumber = value;
            },
          },
        },
        {
          text: 'Project/Job number',
          align: 'left',
          sortable: false,
          value: 'status',
          filter: {
            type: 'select',
            value: self.gridFilter.status,
            selectSource: [
              { value: '', text: 'All statuses' },
              ...Object.values(this.invoiceStatusList)
                .map(r => ({
                  value: r.value,
                  text: r.displayName,
                }))
                .sort((a, b) => comparationSort(a.text, b.text)),
            ],
            onChange: value => {
              self.gridFilter.status = value;
            },
          },
        },
        {
          text: 'Client Name',
          align: 'left',
          sortable: false,
          value: 'clientName',
          filter: {
            type: 'text',
            placeholder: 'Client Name',
            value: self.gridFilter.clientName,
            onChange: value => {
              self.gridFilter.clientName = value;
            },
          },
        },
        {
          text: 'Invoiced',
          align: 'right',
          sortable: false,
          value: 'projectNumber',
          filter: {
            type: 'text',
            placeholder: 'Project/Job Number',
            value: self.gridFilter.projectNumber,
            onChange: value => {
              self.gridFilter.projectNumber = value;
            },
          },
        },
        {
          text: 'Paid',
          align: 'right',
          sortable: false,
          value: 'jobName',
        },
        {
          text: 'Recent Payment Date',
          align: 'right',
          sortable: false,
          value: 'totalAmount',
        },
        {
          text: 'UnPaid',
          align: 'right',
          sortable: false,
          value: 'paidAmount',
        },
        {
          text: 'Unbilled',
          align: 'right',
          sortable: false,
          value: 'invoiceDate',
          tooltip: {
            id: 'unbilled-tooltip-header',
            content: 'Please review and send to customer',
          },
        },
        {
          text: 'Overdue',
          align: 'right',
          sortable: false,
          value: 'paidDate',
        },
        // {
        //   text: "Check#",
        //   align: "left",
        //   sortable: false,
        //   value: "check"
        // },
      ];
    },

    pagingMessage() {
      const fromRow = this.page * this.hitsPerPage + 1;
      const toRow = this.page * this.hitsPerPage + this.hits.length;
      return `${fromRow} - ${toRow} of ${this.nbHits}`;
    },
  },
  methods: {
    ...mapActions('invoices/invoice-group', [
      'searchInvoiceGroup',
      'goToPage',
      'setInvoiceGroupNumberOfRows',
      'setInvoiceGroupSearchText',
      'bindInvoice',
      'setSelectedStatusFilter',
    ]),

    ...mapActions('invoices/app-constant', ['bindConstantTypeList']),
    ...mapActions('invoices/invoices', ['getInvoiceByGroupId']),
    ...mapActions('invoices/category', ['getCategories']),
    initData() {
      this.setInvoiceGroupSearchText('');
      this.onSearch();
    },

    createTooltip() {
      if (!this.$device.desktop) return;

      this.headers.forEach(header => {
        if (header.tooltip) {
          this.$f7.tooltip.create({
            targetEl: `.${header.tooltip.id}`,
            text: `${header.tooltip.content}`,
          });
        }
      });
    },

    openCreateRecurringInvoicePage() {
      this.$f7router.navigate(
        '/invoices-group/recurring-invoice/new-recurring-invoice'
      );
    },

    setIsEdit(value) {
      this.isEdit = value;
    },

    onSearch: _.debounce(function () {
      this.$f7.preloader.show();
      return this.searchInvoiceGroup({}).finally(() => {
        this.$f7.preloader.hide();
      });
    }, 500),

    ongoToPage(pageName) {
      this.$f7.preloader.show();
      this.goToPage({ pageName }).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    onChangeLimit(value) {
      this.setInvoiceGroupNumberOfRows(value);
      this.onSearch();
    },

    openCreateInvoicePage() {
      this.$f7router.navigate('/invoices-group/invoice/new-invoice');
    },

    openCreateInvoicePopover() {
      this.$refs.createInvoicePopover.open();
    },
    dateToValue(a) {
      return a._seconds * 1000 + a._nanoseconds / 1000000;
    },
    convertDateTime(date) {
      return date
        ? moment(new Date(this.dateToValue(date))).format('MM/DD/YYYY')
        : '';
    },
    async onSentEmail(item) {
      await this.bindInvoice(item.id);
      this.$refs.sendEmailPopup.open(_.cloneDeep(this.invoice));
    },

    async onDownloadPDF(item) {
      this.$f7.preloader.show();
      await this.bindInvoice(item.id);
      this.downloadPDF(this.invoice);
      this.$f7.preloader.hide();
    },

    /**
     *
     * @param {*} item invoice-group data information
     */
    async openInvoiceGroupDetailPage(item) {
      await this.getInvoiceByGroupId(item.id);
      this.$f7router.navigate(
        `/invoices-group/${item.id}/invoice/${
          sortLatest(this.invoiceList)[0].invoiceNumber
        }`
      );
    },

    filterByStatus(checked, status) {
      if (checked) {
        this.selectedStatuses.push(status);
      } else {
        this.selectedStatuses = this.selectedStatuses.filter(
          item => item !== status
        );
      }
      this.setSelectedStatusFilter(_.cloneDeep(this.selectedStatuses));
      this.onSearch();
    },

    removeFilter() {
      this.selectedStatuses = [];
      this.setSelectedStatusFilter([]);
      this.onSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
.popover-create {
  width: 250px;
}

.invoice-table-title {
  background-color: var(--f7-searchbar-bg-color, var(--f7-bars-bg-color));
  border-bottom: 1px solid
    var(--f7-searchbar-border-color, var(--f7-bars-border-color));
}

.searchbar:after {
  height: 0;
}

.search-fixed-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 110px);
  overflow: hidden;
}

.search-fixed-header {
  flex: 0 0 auto;
  background-color: white;
  z-index: 1;
}

.list ::v-deep .list-filter .item-inner {
  display: none;
}

.p-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mobile-container {
  width: 100%;
  padding: 10px;
  justify-content: center;
  flex-direction: column-reverse;
}
</style>
