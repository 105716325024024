export default {
  scheduleList: [],
  schedule: {},
  scheduleId: null,
  selectedItem: {},
  isMyScheduling: false,
  editType: null,
  isShowDetail: false,
  schedulesByChainIdAndDate: [],
};
