<template>
  <f7-view :class="`page ${dark ? 'theme-dark' : ''}`">
    <f7-page
      v-if="!tokenAlive"
      no-toolbar
      no-navbar
      no-swipeback
      login-screen
    >
      <!-- <form> -->
      <f7-login-screen-title
        >Your reset password link is expired or invalid. Please try
        again!</f7-login-screen-title
      >
      <f7-block>
        <f7-button
          large
          round
          raised
          fill
          @click="toResetPasswordPage"
          >Go to Reset password page</f7-button
        >
        <br />
      </f7-block>
      <!-- </form> -->
    </f7-page>
    <f7-page
      v-else
      no-toolbar
      no-navbar
      no-swipeback
      login-screen
    >
      <!-- <form> -->
      <f7-login-screen-title
        >Please enter your new password.</f7-login-screen-title
      >
      <f7-list>
        <f7-list-input
          label="New password"
          type="password"
          placeholder="Input new password"
          :value="newPassword"
          @input="newPassword = $event.target.value"
          :clear-button="false"
          :error-message="newPasswordErrorMessage"
          error-message-force
        ></f7-list-input>
        <f7-list-input
          label="Re-type password"
          type="password"
          placeholder="Retype new password"
          :value="confirmPassword"
          @input="confirmPassword = $event.target.value"
          :clear-button="false"
          :error-message="confirmPasswordErrorMessage"
          error-message-force
        ></f7-list-input>
      </f7-list>
      <f7-block>
        <f7-button
          large
          round
          raised
          fill
          @click="updateUserPasswordReset"
          >Next</f7-button
        >
        <br />
      </f7-block>
      <!-- </form> -->
    </f7-page>
  </f7-view>
</template>

<script>
import { mapState } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import {
  verifyUserResetPasswordToken,
  updateUserPasswordReset,
} from '../services/authentication.service';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  async beforeMount() {
    try {
      let code = this.$f7route.params.code;
      this.code = code;
      this.$f7.preloader.show();
      let verifyResult = await verifyUserResetPasswordToken(code);
      this.$f7.preloader.hide();
      if (verifyResult && verifyResult.code == 'success') {
        this.tokenAlive = true;
        this.userData = verifyResult.data;
      } else {
        this.tokenAlive = false;
      }
    } catch (error) {
      this.$f7.preloader.hide();
      this.tokenAlive = false;
    }
  },
  data: () => {
    return {
      newPassword: '',
      confirmPassword: '',
      tokenAlive: true,
      userData: '',
    };
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    const checkPassword = password => {
      const passwordRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      return passwordRegex.test(password);
    };

    return {
      newPassword: {
        required,
        commonPasswordRule: password => checkPassword(password),
      },
      confirmPassword: {
        sameAsPassword: value => value === this.newPassword,
      },
    };
  },

  computed: {
    ...mapState('setting/app/profile', ['dark']),
    newPasswordErrorMessage() {
      if (!this.v$.newPassword.$error) return null;
      if (this.v$.newPassword.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.newPassword.commonPasswordRule.$invalid)
        return VALIDATION_MESSAGE.PASSWORD_MIN_LENGTH_TWO;
      return null;
    },
    confirmPasswordErrorMessage() {
      if (!this.v$.confirmPassword.$error) return null;
      if (this.v$.confirmPassword.sameAsPassword.$invalid)
        return VALIDATION_MESSAGE.PASSWORD_MISMATCH_TWO;
      return null;
    },
  },

  methods: {
    updateUserPasswordReset() {
      // Validate input controls
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.$f7.preloader.show();
      updateUserPasswordReset(this.code, this.newPassword)
        .then(result => {
          if (result.code === 'success') {
            let notificationClickToClose = this.$f7.notification.create({
              icon: "<i class='color-green icon f7-icons'>info_circle_fill</i>",
              title: 'Update password successful',
              text: 'Your password updated please try to login again with your new password.',
              closeOnClick: true,
              closeButton: true,
              closeTimeout: 15000,
            });
            notificationClickToClose.open();
            this.$f7router.navigate('/login', {
              pushState: true,
              reloadAll: true,
            });
          } else {
            let notificationClickToClose = this.$f7.notification.create({
              icon: "<i class='color-red icon f7-icons'>exclamationmark_triangle_fill</i>",
              title: 'Reset password failure',
              text: 'Your reset password session expired or wrong verify code.',
              closeOnClick: true,
              closeButton: true,
              closeTimeout: 15000,
            });
            notificationClickToClose.open();
            this.tokenAlive = false;
          }
        })
        .catch(() => {
          let notificationClickToClose = this.$f7.notification.create({
            icon: "<i class='color-red icon f7-icons'>exclamationmark_triangle_fill</i>",
            title: 'Reset password failure',
            text: 'An unknown error occurred. Please try again',
            closeOnClick: true,
            closeButton: true,
            closeTimeout: 15000,
          });
          notificationClickToClose.open();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },
    toResetPasswordPage() {
      this.$f7router.navigate('/reset-password', {
        pushState: true,
        reloadAll: true,
      });
    },
  },
};
</script>

<style></style>
