import axiosService from './axios.service';

const pushNotification = async result => {
  try {
    return await axiosService.post('/notification/push-notification', {
      to: result.to,
      content: result.message,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[Send Notification]', error);
    throw new Error(error.message);
  }
};

export default {
  pushNotification,
};
