export default {
  projectPhotoList: [],
  projectPhotoLastRef: null,
  projectPhotoAllLoaded: false,
  projectPhoto: {},
  projectPhotosByAlbum: [],
  projectPhotosSize: 0,
  selectedPhotoIds: [],
  selectionMode: false,
};
