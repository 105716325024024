import { checkAuth } from '../../../services/authentication.service';

export default [
  {
    path: '',
    title: 'Finance',
    name: 'finance-main',
    displayRoles: ['owner', 'user'],
    routes: [
      {
        path: '/qb-contact/export',
        title: 'Quickbooks Integration',
        name: 'quickbooks-integration',
        parent: 'finance-main',
        displayRoles: ['owner', 'user'],
        iconImage:
          'PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iNy40IiBzdHJva2U9IiNGMDUwMzQiIHN0cm9rZS13aWR0aD0iMS4yIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTIuMiA1LjVIMTFWMTEuOVYxMi43VjEzLjFIMTMuMDAyNkMxMy44MzMgMTMuMSAxNC40NzU1IDEyLjcxNjYgMTQuODkyNiAxMi4xNTQzQzE1LjI5NjggMTEuNjA5MyAxNS40OCAxMC45MTQyIDE1LjQ4IDEwLjI1QzE1LjQ4IDkuNTg1ODEgMTUuMjk2OCA4Ljg5MDc1IDE0Ljg5MjYgOC4zNDU2OUMxNC40NzU1IDcuNzgzMzYgMTMuODMzIDcuNCAxMy4wMDI2IDcuNFY4LjZDMTMuNDIzOCA4LjYgMTMuNzIgOC43NzkxNCAxMy45Mjg3IDkuMDYwNTZDMTQuMTUwMyA5LjM1OTI1IDE0LjI4IDkuNzg5MTkgMTQuMjggMTAuMjVDMTQuMjggMTAuNzEwOCAxNC4xNTAzIDExLjE0MDcgMTMuOTI4NyAxMS40Mzk0QzEzLjcyIDExLjcyMDkgMTMuNDIzOCAxMS45IDEzLjAwMjYgMTEuOUgxMi4yVjUuNVoiIGZpbGw9IiNGMDUwMzQiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02Ljk3NzQyIDdDNi4xNDcwMSA3IDUuNTA0NTIgNy4zODMzNiA1LjA4NzQ0IDcuOTQ1NjlDNC42ODMxNyA4LjQ5MDc1IDQuNSA5LjE4NTgxIDQuNSA5Ljg1QzQuNSAxMC41MTQyIDQuNjgzMTcgMTEuMjA5MiA1LjA4NzQ0IDExLjc1NDNDNS41MDQ1MiAxMi4zMTY2IDYuMTQ3MDEgMTIuNyA2Ljk3NzQyIDEyLjdWMTEuNUM2LjU1NjIyIDExLjUgNi4yNiAxMS4zMjA5IDYuMDUxMjcgMTEuMDM5NEM1LjgyOTczIDEwLjc0MDggNS43IDEwLjMxMDggNS43IDkuODVDNS43IDkuMzg5MTkgNS44Mjk3MyA4Ljk1OTI1IDYuMDUxMjcgOC42NjA1NkM2LjI2IDguMzc5MTQgNi41NTYyMiA4LjIgNi45Nzc0MiA4LjJIOC4xMDVWMTQuNUg5LjMwNVY3LjAwMDAySDguOThWN0g2Ljk3NzQyWiIgZmlsbD0iI0YwNTAzNCIvPgo8L3N2Zz4K',
        async: checkAuth(() => import('../pages/ContactIntegration.vue')),
      },
      {
        path: '/qb-contact/export/:tabActive',
        name: 'export-main-by-tab',
        checkAccessPath: 'finance-main/quickbooks-integration',
        displayRoles: ['owner', 'user'],
        async: checkAuth(() => import('../pages/ContactIntegration.vue')),
      },
      {
        path: '/qb-contact/import/:tabActive',
        name: 'import-main-by-tab',
        checkAccessPath: 'finance-main/quickbooks-integration',
        displayRoles: ['owner', 'user'],
        async: checkAuth(() => import('../pages/ContactIntegration.vue')),
      },
      {
        path: '/qb-contact/export',
        name: 'export-main',
        checkAccessPath: 'finance-main/quickbooks-integration',
        displayRoles: ['owner', 'user'],
        async: checkAuth(() => import('../pages/ContactIntegration.vue')),
      },
      {
        path: '/qb-contact/import',
        name: 'import-main',
        checkAccessPath: 'finance-main/quickbooks-integration',
        displayRoles: ['owner', 'user'],
        async: checkAuth(() => import('../pages/ContactIntegration.vue')),
      },
      {
        path: '/qb-error',
        name: 'quickbooks-error',
        async: checkAuth(() => import('../pages/Error.vue')),
      },
      {
        path: '/qb-error/:error',
        name: 'quickbooks-error',
        async: checkAuth(() => import('../pages/Error.vue')),
      },
    ],
  },
];
